import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import {
  getBargeList,
  deleteBarge,
  getAllAssigneeUsersList,
  assignUserIntoBarge,
  adminDirectBreakInOutBarge,
} from "../../../../redux/admin/action";
import { Formik } from "formik";
import * as Yup from "yup";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { toast } from "react-toastify";

const Search = Index.styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: Index.alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: Index.alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const StyledInputBase = Index.styled(Index.InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

const ActionItem = Index.styled(Index.MenuItem)(() => ({
  fontFamily: "poppins",
  lineHeight: "15px",
  fontSize: "14px",
  fontWeight: "400",
  color: "#595F69",
}));
const HtmlTooltip = Index.styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}));

export const assigneeValidationSchema = Yup.object().shape({
  assigns: Yup.array().required("Please select assignee"),
});

function SupplyOrder() {
  const dispatch = Index.useDispatch();
  const navigate = Index.useNavigate();
  const [loading, setLoading] = useState(false);
  const [openAssigneeModel, setOpenAssigneeModel] = useState(false);
  const [assigneeUsersList, setAssigneeUsersList] = useState([]);
  const [bargeId, setBargeId] = useState("");
  const [assignUserId, setAssignUserId] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [actionIndex, setActionIndex] = useState();
  const [searchingText, setSearchingText] = useState("");
  const handleClickMenu = (e, index) => {
    setActionIndex(index);
    setAnchorEl(e.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  // initialValues
  let initialValues = {
    assigns: "",
  };
  if (assignUserId) {
    initialValues = {
      assigns: assignUserId,
    };
  }

  const handleView = (row) => {
    navigate(`/admin/craft/view/${row?._id}`);
  };
  const handleEdit = (row) => {
    navigate("/admin/craft/edit-craft", {
      state: { row },
    });
  };

  const [deleteOpen, setDeleteOpen] = useState(false);
  const [rowId, setRowId] = useState("");

  const handleDeleteOpen = () => {
    setAnchorEl(null);
    setDeleteOpen(true);
  };

  const handleDeleteClose = () => {
    setDeleteOpen(false);
  };
  const handleDeleteRecord = () => {
    dispatch(deleteBarge({ id: rowId }, handleDeleteClose));
  };

  //Assign to model
  const handleAssigneeModelOpen = () => {
    setOpenAssigneeModel(true);
  };
  const handleAssigneeModelClose = () => {
    setOpenAssigneeModel(false);
    setBargeId("");
  };
  // State for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState(0);
  const [paginationPerPage, setPaginationPerPage] = useState(50);

  // State for searching and set data
  const [searchedData, setSearchedData] = useState([]);
  const [currentRows, setCurrentRows] = useState([]);

  //handle assignee
  const handleAssigneeFormSubmit = (values) => {
    const urlencoded = new URLSearchParams();
    urlencoded.append("bargeId", bargeId);
    urlencoded.append("assigns", JSON.stringify(values?.assigns));
    try {
      dispatch(assignUserIntoBarge(urlencoded)).then((res) => {
        if (res?.status === 200) {
          toast.success(res?.message);
          getBarge();
          setSearchingText("");
        } else {
          toast.error(res?.message);
        }
      });
    } catch (error) {}
    handleAssigneeModelClose();
  };

  // Get User List
  const getUsers = () => {
    dispatch(getAllAssigneeUsersList()).then((res) => {
      if (res?.status === 200) {
        setAssigneeUsersList(res?.data);
      } else {
        setAssigneeUsersList([]);
      }
    });
  };
  // Get Barge List
  const getBarge = () => {
    setLoading(true);
    dispatch(getBargeList()).then((res) => {
      if (res?.status === 200) {
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  };
  useEffect(() => {
    getBarge();
    getUsers();
  }, []);

  // redux data
  const bargeList = Index.useSelector((state) => state.AdminReducer.bargeList);

  // Copy original data to searched data
  useEffect(() => {
    setSearchedData(bargeList?.reverse());
  }, [bargeList]);

  // Search on table
  const requestSearch = (searched) => {
    if (!searched) return setSearchedData(bargeList);
    setSearchedData(
      bargeList.filter(
        (item) =>
          item?.bargeName?.toLowerCase().includes(searched.toLowerCase()) ||
          item?.bargeCategory?.toLowerCase().includes(searched.toLowerCase()) ||
          (item?.portLimit &&
            item?.portLimit?.some((port) =>
              port?.portName?.toLowerCase().includes(searched.toLowerCase())
            )) ||
          item?.tankManagement?.[0]?.tankName
            ?.toLowerCase()
            .includes(searched.toLowerCase()) ||
          item?.tankManagement?.[0]?.tankType
            ?.toLowerCase()
            .includes(searched.toLowerCase()) ||
          item?.tankManagement?.[0]?.tankCapacity
            ?.toString()
            ?.toLowerCase()
            .includes(searched.toLowerCase())
      )
    );
    setCurrentPage(1);
  };

  // Pagination calculation start

  const PER_PAGE = paginationPerPage;
  useEffect(() => {
    //Page count
    const count = Math.ceil(searchedData?.length / PER_PAGE);
    setCount(count);
    const indexOfLastRow = currentPage * PER_PAGE;
    const indexOfFirstRow = indexOfLastRow - PER_PAGE;
    const modifiedRows = searchedData?.slice(indexOfFirstRow, indexOfLastRow);
    setCurrentRows(modifiedRows);
    if (modifiedRows?.length === 0 && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  }, [currentPage, searchedData, paginationPerPage, PER_PAGE]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };
  // Pagination calculation end

  // when the user Break In Barge then used...
  const handleBreakInBarge = (bargeId) => {
    dispatch(
      adminDirectBreakInOutBarge({ bargeInOutForBreakdown: true, bargeId })
    )
      .then((res) => {
        if (res.status === 200) {
          getBarge();
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // when the user Break Out Barge then used...
  const handleBreakOutBarge = (bargeId) => {
    dispatch(
      adminDirectBreakInOutBarge({ bargeInOutForBreakdown: false, bargeId })
    )
      .then((res) => {
        if (res.status === 200) {
          getBarge();
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <>
      <Index.DynamicTitle title="Craft Management" />
      <Index.Box className="page-content-box">
        <Index.Box className="barge-common-box">
          <Index.Box className="title-header">
            <Index.Box className="title-header-flex res-title-header-flex">
              <Index.Box className="title-main">
                <Index.Typography
                  variant="p"
                  component="p"
                  className="page-title"
                >
                  Craft Management
                </Index.Typography>
              </Index.Box>
              <Index.Box className="d-flex align-items-center res-set-search">
                <Search className="search">
                  <StyledInputBase
                    placeholder="Search"
                    inputProps={{ "aria-label": "search" }}
                    value={searchingText}
                    onChange={(e) => {
                      setSearchingText(e.target.value.trim());
                      setTimeout(() => {
                        requestSearch(e.target.value.trim());
                      }, 1000);
                    }}
                  />
                </Search>

                {/* <Index.Box className="common-button grey-button">
                  <Index.Button variant="contained">Export</Index.Button>
                </Index.Box> */}
                <Index.Box className="common-button blue-button res-blue-button">
                  <Index.RouteLink
                    to="/admin/craft/add-craft"
                    className="no-text-decoration"
                  >
                    {" "}
                    <Index.Button variant="contained" disableRipple>
                      Add Craft
                    </Index.Button>
                  </Index.RouteLink>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.Box className="">
            <Index.TableContainer
              component={Index.Paper}
              className="table-container"
            >
              <Index.Table
                aria-label="simple table"
                className="table-design-main barge-table"
              >
                <Index.TableHead>
                  <Index.TableRow>
                    <Index.TableCell align="left">Craft Name</Index.TableCell>
                    <Index.TableCell align="left">Company Name</Index.TableCell>
                    <Index.TableCell align="left">
                      Craft category
                    </Index.TableCell>
                    <Index.TableCell align="left">Port Limit</Index.TableCell>
                    {/* <Index.TableCell align="left">Tank Name</Index.TableCell>
                    <Index.TableCell align="left">Tank Type</Index.TableCell>
                    <Index.TableCell align="left">
                      Tank Capacity (In KL)
                    </Index.TableCell>
                    <Index.TableCell align="left">
                      Tank Capacity (In MT)
                    </Index.TableCell> */}
                    <Index.TableCell align="left">Assign To</Index.TableCell>
                    <Index.TableCell align="left">Action</Index.TableCell>
                  </Index.TableRow>
                </Index.TableHead>
                {loading ? (
                  <Index.TableBody>
                    <Index.TableRow>
                      <Index.TableCell
                        colSpan={12}
                        align="center"
                        className="loading-purchase"
                      >
                        <Index.CircularProgress color="secondary" size={20} />
                      </Index.TableCell>
                    </Index.TableRow>
                  </Index.TableBody>
                ) : (
                  <Index.TableBody className="tanker-table-body">
                    {currentRows?.length ? (
                      currentRows
                        ?.sort((a, b) => {
                          let x = a?.bargeName?.toLowerCase();
                          let y = b?.bargeName?.toLowerCase();
                          if (x > y) {
                            return 1;
                          } else if (y > x) {
                            return -1;
                          }
                          return 0;
                        })
                        ?.map((row, index) => (
                          <Index.TableRow
                            key={row?._id}
                            className={
                              row?.bargeInOutForBreakdown === true
                                ? "tanker-row-color"
                                : ""
                            }
                          >
                            {console.log("row", row)}
                            <Index.TableCell>
                              {row?.bargeName ? row?.bargeName : "-"}
                            </Index.TableCell>
                            <Index.TableCell>
                              {row?.companyId?.length
                                ? row?.companyId?.map((el) => (
                                    <li>{el.name}</li>
                                  ))
                                : "-"}
                            </Index.TableCell>
                            <Index.TableCell>
                              {row?.bargeCategory ? row?.bargeCategory : "-"}
                            </Index.TableCell>
                            <Index.TableCell align="left">
                              {row?.portLimit
                                ? Array.prototype.map
                                    .call(row?.portLimit, function (item) {
                                      return item.portName;
                                    })
                                    .join(", ")
                                : ""}
                            </Index.TableCell>
                            {/* <Index.TableCell align="left">
                              {row?.tankManagement?.[0]?.tankName}
                            </Index.TableCell>
                            <Index.TableCell align="left">
                              {row?.tankManagement?.[0]?.tankType}
                            </Index.TableCell>
                            <Index.TableCell align="left">
                              {row?.tankManagement?.[0]?.tankCapacity}
                            </Index.TableCell>
                            <Index.TableCell align="left">
                              {row?.tankManagement?.[0]?.tankCapacityMl}
                            </Index.TableCell> */}
                            <Index.TableCell align="left">
                              {row?.assignTo ? (
                                <HtmlTooltip
                                  title={row?.assignTo?.employeeName}
                                >
                                  <span
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    {row?.assignTo?.employeeName && (
                                      <>
                                        <span>
                                          {`${row?.assignTo?.employeeName.substring(
                                            0,
                                            5
                                          )}...`}
                                        </span>
                                        <Index.IconButton
                                          onClick={() => {
                                            setAssignUserId(row?.assigns);
                                            handleAssigneeModelOpen();
                                            setBargeId(row?._id);
                                          }}
                                          style={{ marginLeft: "5px" }}
                                        >
                                          <Index.EditIcon
                                            color="primary"
                                            fontSize="small"
                                          />
                                        </Index.IconButton>
                                      </>
                                    )}
                                  </span>
                                </HtmlTooltip>
                              ) : (
                                <Index.Box className="common-button blue-button res-blue-button assign-btn-align">
                                  <Index.Button
                                    variant="contained"
                                    className="assign-button-margin"
                                    disableRipple
                                    onClick={() => {
                                      setAssignUserId("");
                                      handleAssigneeModelOpen();
                                      setBargeId(row?._id);
                                    }}
                                  >
                                    Assign to
                                  </Index.Button>
                                </Index.Box>
                              )}
                            </Index.TableCell>

                            <Index.TableCell sx={{ display: "flex" }}>
                              <Index.IconButton
                                onClick={(e) => {
                                  handleClickMenu(e, index);
                                }}
                              >
                                <Index.MenuIcon className="action-menu-icon" />
                              </Index.IconButton>
                              <Index.Menu
                                id="demo-positioned-menu"
                                aria-labelledby="demo-positioned-button"
                                anchorEl={anchorEl}
                                keepMounted
                                elevation={2}
                                open={Boolean(anchorEl)}
                                onClose={handleCloseMenu}
                                anchorOrigin={{
                                  vertical: "top",
                                  horizontal: "left",
                                }}
                                transformOrigin={{
                                  vertical: "top",
                                  horizontal: "left",
                                }}
                              >
                                <ActionItem
                                  className="action-text"
                                  onClick={() =>
                                    handleView(currentRows?.[actionIndex])
                                  }
                                >
                                  View
                                </ActionItem>
                                <ActionItem
                                  className="action-text"
                                  onClick={() =>
                                    handleEdit(currentRows?.[actionIndex])
                                  }
                                >
                                  Edit
                                </ActionItem>

                                <ActionItem
                                  className="action-text"
                                  onClick={() => {
                                    setRowId(currentRows?.[actionIndex]?._id);
                                    handleDeleteOpen();
                                  }}
                                >
                                  Delete
                                </ActionItem>
                                {currentRows?.[actionIndex]
                                  ?.bargeInOutForBreakdown ? (
                                  <>
                                    <ActionItem
                                      className="action-text"
                                      onClick={() => {
                                        handleBreakOutBarge(
                                          currentRows?.[actionIndex]?._id
                                        );
                                      }}
                                    >
                                      Break Out
                                    </ActionItem>
                                  </>
                                ) : (
                                  <>
                                    <ActionItem
                                      className="action-text"
                                      onClick={() => {
                                        handleBreakInBarge(
                                          currentRows?.[actionIndex]?._id
                                        );
                                      }}
                                    >
                                      Break In
                                    </ActionItem>
                                  </>
                                )}
                              </Index.Menu>
                            </Index.TableCell>
                          </Index.TableRow>
                        ))
                    ) : (
                      <Index.TableRow>
                        <Index.TableCell
                          component="td"
                          variant="td"
                          scope="row"
                          className="no-data-in-list"
                          colSpan={12}
                          align="center"
                        >
                          No data available
                        </Index.TableCell>
                      </Index.TableRow>
                    )}
                  </Index.TableBody>
                )}
              </Index.Table>
            </Index.TableContainer>
          </Index.Box>
          <Index.Box className="pagination-design flex-end">
            <Index.Stack spacing={2}>
              <Index.Box className="pagination-count">
                <Index.Box className="input-design-div with-border">
                  <Index.Select
                    fullWidth
                    value={paginationPerPage}
                    onChange={(e) => {
                      setPaginationPerPage(e.target.value);
                    }}
                  >
                    <Index.MenuItem value={10}>10</Index.MenuItem>
                    <Index.MenuItem value={25}>25</Index.MenuItem>
                    <Index.MenuItem value={50}>50</Index.MenuItem>
                    <Index.MenuItem value={100}>100</Index.MenuItem>
                  </Index.Select>
                </Index.Box>
                <Index.Pagination
                  count={count}
                  page={currentPage}
                  onChange={handlePageChange}
                />
              </Index.Box>
            </Index.Stack>
          </Index.Box>
        </Index.Box>
      </Index.Box>
      <Index.DeleteModal
        deleteOpen={deleteOpen}
        handleDeleteRecord={handleDeleteRecord}
        handleDeleteClose={handleDeleteClose}
      />

      {/* Assign to Model */}
      <Index.Dialog
        open={openAssigneeModel}
        onClose={handleAssigneeModelClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="overflow-visible"
      >
        <Index.DialogContent className="expense-model-main  expense-model-main-box-set">
          <Index.Box className="title-main">
            <Formik
              initialValues={initialValues}
              // validationSchema={assigneeValidationSchema}
              onSubmit={handleAssigneeFormSubmit}
            >
              {({
                values,
                errors,
                touched,
                setFieldValue,
                handleChange,
                handleSubmit,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Index.Box className="title-main">
                    <Index.Box className="jus-between">
                      <Index.Typography
                        variant="h6"
                        component="h6"
                        className="input-label"
                        sx={{ marginBottom: "15px" }}
                      >
                        Assign To
                      </Index.Typography>
                      <Index.ClearIcon
                        className="model-close-icon"
                        onClick={handleAssigneeModelClose}
                      />
                    </Index.Box>
                    <Index.Grid container spacing={2}>
                      <Index.Grid item xs={12} sm={6} md={12}>
                        <Index.Typography
                          variant="label"
                          component="label"
                          className="input-label"
                        >
                          Users
                        </Index.Typography>
                        <Index.Box className="trader-selection">
                          <Index.Autocomplete
                            disablePortal
                            // className="invoice-select-drop"
                            id="tags-outlined"
                            options={assigneeUsersList || []}
                            getOptionLabel={(option) => option?.employeeName}
                            value={assigneeUsersList.filter((value) =>
                              // values.assigns.includes(value._id)
                              values?.assigns !== "" &&
                              values?.assigns !== null &&
                              values?.assigns?.length
                                ? values?.assigns?.some(
                                    (o2) => value?._id === o2?._id
                                  )
                                : ""
                            )}
                            renderOption={(props, option) => (
                              <>
                                <Index.Box
                                  component="li"
                                  key={option?._id}
                                  sx={{
                                    "& > img": { mr: 2, flexShrink: 0 },
                                  }}
                                  {...props}
                                >
                                  {option?.employeeName}
                                </Index.Box>
                              </>
                            )}
                            onChange={(event, newValue) => {
                              // const newSelectedIds = newValue.map(
                              //   (val) => val._id
                              // );
                              setFieldValue("assigns", newValue);
                            }}
                            renderInput={(params) => (
                              <Index.TextField
                                {...params}
                                placeholder="Select assignee"
                                variant="outlined"
                              />
                            )}
                            multiple
                          />
                          {/* {touched?.assigns && errors?.assigns && (
                            <Index.FormHelperText error>
                              {errors?.assigns}
                            </Index.FormHelperText>
                          )} */}
                        </Index.Box>
                      </Index.Grid>
                    </Index.Grid>
                    <Index.Grid
                      item
                      xs={12}
                      sm={6}
                      md={12}
                      // sx={{ marginTop: "105px" }}
                    >
                      <Index.Box
                        className="common-button blue-button flex-end save-btn mt-20"
                        sx={{
                          display: "flex",
                          alignItems: "flex-end",
                        }}
                      >
                        <Index.Button
                          variant="contained"
                          type="submit"
                          className="Particular_model_submit"
                        >
                          Submit
                        </Index.Button>
                      </Index.Box>
                    </Index.Grid>

                    {/* <Index.Box className="line" mt={3} mb={3}></Index.Box> */}
                  </Index.Box>
                </form>
              )}
            </Formik>
          </Index.Box>
        </Index.DialogContent>
      </Index.Dialog>
    </>
  );
}

export default SupplyOrder;
