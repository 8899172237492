import React from "react";
import Index from "../../../../Index";

function CrewMemberPrintPDF({ crewMemberData, componentRef }) {
  const printData =
    crewMemberData &&
    crewMemberData
      ?.filter((res) => res?.signOff === false)
      ?.map((ele, i) => {
        return {
          sNo: i + 1,
          ...ele,
        };
      });

  return (
    <>
      <Index.Box ref={componentRef}>
        <table
          style={{
            maxWidth: 1000,
            margin: "auto",
            borderCollapse: "collapse",
            width: "100%",
            marginTop: 20,
            marginBottom: 20,
          }}
        >
          <thead>
            <tr>
              <th colSpan={8}></th>
              <th
                colSpan={6}
                style={{
                  color: "#000000",
                  fontSize: 19,
                  lineHeight: 2,
                  fontWeight: 700,
                  fontFamily: '"Poppins", sans-serif',
                  verticalAlign: "center",
                  borderCollapse: "collapse",
                  padding: 5,
                }}
              >
                <u>IMO CREW LIST</u>
              </th>
              <th colSpan={8}></th>
            </tr>
            <tr>
              <th
                colSpan={8}
                style={{
                  color: "#000000",
                  fontSize: 14,
                  lineHeight: 2,
                  fontWeight: 700,
                  fontFamily: '"Poppins", sans-serif',
                  verticalAlign: "baseline",
                  borderCollapse: "collapse",
                  padding: 5,
                  textTransform: "uppercase",
                }}
              >
                <u>VESSEL'S NAME:</u>
                {` ${printData?.[0]?.craftId?.bargeName}`}
              </th>
              <th
                colSpan={4}
                style={{
                  color: "#000000",
                  fontSize: 14,
                  lineHeight: 2,
                  fontWeight: 700,
                  fontFamily: '"Poppins", sans-serif',
                  verticalAlign: "baseline",
                  borderCollapse: "collapse",
                  padding: 5,
                  textTransform: "uppercase",
                }}
              >
                <u>IMO NO:</u>
                {` ${printData?.[0]?.craftId?.imoNumber}`}
              </th>
              <th
                colSpan={4}
                style={{
                  color: "#000000",
                  fontSize: 14,
                  lineHeight: 2,
                  fontWeight: 700,
                  fontFamily: '"Poppins", sans-serif',
                  verticalAlign: "baseline",
                  borderCollapse: "collapse",
                  padding: 5,
                  textTransform: "uppercase",
                }}
              >
                <u>CALL SIGN:</u>
                {` ${printData?.[0]?.craftId?.callSign}`}
              </th>
              <th
                colSpan={6}
                style={{
                  color: "#000000",
                  fontSize: 14,
                  lineHeight: 2,
                  fontWeight: 700,
                  fontFamily: '"Poppins", sans-serif',
                  verticalAlign: "baseline",
                  borderCollapse: "collapse",
                  padding: 5,
                }}
              >
                <u>DATE:</u>
                {` ${Index.moment().format("DD.MM.YYYY")}`}
              </th>
            </tr>
            <tr>
              <th
                colSpan={2}
                style={{
                  color: "#000000",
                  fontSize: 13,
                  lineHeight: 2,
                  fontWeight: 700,
                  fontFamily: '"Poppins", sans-serif',
                  verticalAlign: "baseline",
                  borderCollapse: "collapse",
                  border: "1px solid black",
                  padding: 5,
                }}
              >
                S.NO
              </th>
              <th
                colSpan={2}
                style={{
                  color: "#000000",
                  fontSize: 13,
                  lineHeight: 2,
                  fontWeight: 700,
                  fontFamily: '"Poppins", sans-serif',
                  verticalAlign: "baseline",
                  borderCollapse: "collapse",
                  border: "1px solid black",
                  padding: 5,
                }}
              >
                Name
              </th>
              <th
                colSpan={2}
                style={{
                  color: "#000000",
                  fontSize: 13,
                  lineHeight: 2,
                  fontWeight: 700,
                  fontFamily: '"Poppins", sans-serif',
                  verticalAlign: "baseline",
                  borderCollapse: "collapse",
                  border: "1px solid black",
                  padding: 5,
                }}
              >
                RANK
              </th>
              <th
                colSpan={2}
                style={{
                  color: "#000000",
                  fontSize: 13,
                  lineHeight: 2,
                  fontWeight: 700,
                  fontFamily: '"Poppins", sans-serif',
                  verticalAlign: "baseline",
                  borderCollapse: "collapse",
                  border: "1px solid black",
                  padding: 5,
                }}
              >
                NATIONALITY
              </th>
              <th
                colSpan={2}
                style={{
                  color: "#000000",
                  fontSize: 13,
                  lineHeight: 2,
                  fontWeight: 700,
                  fontFamily: '"Poppins", sans-serif',
                  verticalAlign: "baseline",
                  borderCollapse: "collapse",
                  border: "1px solid black",
                  padding: 5,
                }}
              >
                D.O.B
              </th>
              <th
                colSpan={2}
                style={{
                  color: "#000000",
                  fontSize: 13,
                  lineHeight: 2,
                  fontWeight: 700,
                  fontFamily: '"Poppins", sans-serif',
                  verticalAlign: "baseline",
                  borderCollapse: "collapse",
                  border: "1px solid black",
                  padding: 5,
                }}
              >
                P.P.NO
              </th>
              <th
                colSpan={2}
                style={{
                  color: "#000000",
                  fontSize: 13,
                  lineHeight: 2,
                  fontWeight: 700,
                  fontFamily: '"Poppins", sans-serif',
                  verticalAlign: "baseline",
                  borderCollapse: "collapse",
                  border: "1px solid black",
                  padding: 5,
                }}
              >
                P.P.EXP.DATE
              </th>
              <th
                colSpan={2}
                style={{
                  color: "#000000",
                  fontSize: 13,
                  lineHeight: 2,
                  fontWeight: 700,
                  fontFamily: '"Poppins", sans-serif',
                  verticalAlign: "baseline",
                  borderCollapse: "collapse",
                  border: "1px solid black",
                  padding: 5,
                }}
              >
                CDC NO
              </th>
              <th
                colSpan={2}
                style={{
                  color: "#000000",
                  fontSize: 13,
                  lineHeight: 2,
                  fontWeight: 700,
                  fontFamily: '"Poppins", sans-serif',
                  verticalAlign: "baseline",
                  borderCollapse: "collapse",
                  border: "1px solid black",
                  padding: 5,
                }}
              >
                CDC EXP
              </th>
              <th
                colSpan={2}
                style={{
                  color: "#000000",
                  fontSize: 13,
                  lineHeight: 2,
                  fontWeight: 700,
                  fontFamily: '"Poppins", sans-serif',
                  verticalAlign: "baseline",
                  borderCollapse: "collapse",
                  border: "1px solid black",
                  padding: 5,
                }}
              >
                P.O.J
              </th>
              <th
                colSpan={2}
                style={{
                  color: "#000000",
                  fontSize: 13,
                  lineHeight: 2,
                  fontWeight: 700,
                  fontFamily: '"Poppins", sans-serif',
                  verticalAlign: "baseline",
                  borderCollapse: "collapse",
                  border: "1px solid black",
                  padding: 5,
                }}
              >
                D.O.J
              </th>
            </tr>
          </thead>
          <tbody>
            {crewMemberData &&
              crewMemberData?.length > 0 &&
              crewMemberData?.map((ele, index) => {
                return (
                  <>
                    <tr key={ele?._id}>
                      <td
                        colSpan={2}
                        style={{
                          color: "#000000",
                          fontSize: 12,
                          lineHeight: 1.8,
                          fontFamily: '"Poppins", sans-serif',
                          verticalAlign: "baseline",
                          borderCollapse: "collapse",
                          border: "1px solid black",
                          padding: 5,
                        }}
                      >
                        {ele?.sNo || "-"}
                      </td>
                      <td
                        colSpan={2}
                        style={{
                          color: "#000000",
                          fontSize: 12,
                          lineHeight: 1.8,
                          fontFamily: '"Poppins", sans-serif',
                          verticalAlign: "baseline",
                          borderCollapse: "collapse",
                          border: "1px solid black",
                          padding: 5,
                        }}
                      >
                        {ele?.name || "-"}
                      </td>
                      <td
                        colSpan={2}
                        style={{
                          color: "#000000",
                          fontSize: 12,
                          lineHeight: 1.8,
                          fontFamily: '"Poppins", sans-serif',
                          verticalAlign: "baseline",
                          borderCollapse: "collapse",
                          border: "1px solid black",
                          padding: 5,
                        }}
                      >
                        {ele?.rank || "-"}
                      </td>
                      <td
                        colSpan={2}
                        style={{
                          color: "#000000",
                          fontSize: 12,
                          lineHeight: 1.8,
                          fontFamily: '"Poppins", sans-serif',
                          verticalAlign: "center",
                          borderCollapse: "collapse",
                          border: "1px solid black",
                          padding: 5,
                        }}
                      >
                        {ele?.nationality || "-"}
                      </td>
                      <td
                        colSpan={2}
                        style={{
                          color: "#000000",
                          fontSize: 12,
                          lineHeight: 1.8,
                          fontFamily: '"Poppins", sans-serif',
                          verticalAlign: "baseline",
                          borderCollapse: "collapse",
                          border: "1px solid black",
                          padding: 5,
                        }}
                      >
                        {Index.moment(ele?.dob).format("DD/MM/YYYY") || "-"}
                      </td>
                      <td
                        colSpan={2}
                        style={{
                          color: "#000000",
                          fontSize: 12,
                          lineHeight: 1.8,
                          fontFamily: '"Poppins", sans-serif',
                          verticalAlign: "baseline",
                          borderCollapse: "collapse",
                          border: "1px solid black",
                          padding: 5,
                        }}
                      >
                        {ele?.ppNumber || "-"}
                      </td>
                      <td
                        colSpan={2}
                        style={{
                          color: "#000000",
                          fontSize: 12,
                          lineHeight: 1.8,
                          fontFamily: '"Poppins", sans-serif',
                          verticalAlign: "baseline",
                          borderCollapse: "collapse",
                          border: "1px solid black",
                          padding: 5,
                        }}
                      >
                        {ele?.ppExpiryDate
                          ? Index.moment(ele?.ppExpiryDate).format("DD/MM/YYYY")
                          : "-"}
                      </td>
                      <td
                        colSpan={2}
                        style={{
                          color: "#000000",
                          fontSize: 12,
                          lineHeight: 1.8,
                          fontFamily: '"Poppins", sans-serif',
                          verticalAlign: "baseline",
                          borderCollapse: "collapse",
                          border: "1px solid black",
                          padding: 5,
                        }}
                      >
                        {ele?.cdcNumber ? ele?.cdcNumber : "-"}
                      </td>
                      <td
                        colSpan={2}
                        style={{
                          color: "#000000",
                          fontSize: 12,
                          lineHeight: 1.8,
                          fontFamily: '"Poppins", sans-serif',
                          verticalAlign: "baseline",
                          borderCollapse: "collapse",
                          border: "1px solid black",
                          padding: 5,
                        }}
                      >
                        {ele?.cdcExpiryDate
                          ? Index.moment(ele?.cdcExpiryDate).format(
                              "DD/MM/YYYY"
                            )
                          : "-"}
                      </td>
                      <td
                        colSpan={2}
                        style={{
                          color: "#000000",
                          fontSize: 12,
                          lineHeight: 1.8,
                          fontFamily: '"Poppins", sans-serif',
                          verticalAlign: "baseline",
                          borderCollapse: "collapse",
                          border: "1px solid black",
                          padding: 5,
                        }}
                      >
                        {ele?.poj || "-"}
                      </td>
                      <td
                        colSpan={2}
                        style={{
                          color: "#000000",
                          fontSize: 12,
                          lineHeight: 1.8,
                          fontFamily: '"Poppins", sans-serif',
                          verticalAlign: "baseline",
                          borderCollapse: "collapse",
                          border: "1px solid black",
                          padding: 5,
                        }}
                      >
                        {Index.moment(ele?.doj).format("DD/MM/YYYY") || "-"}
                      </td>
                    </tr>
                  </>
                );
              })}
            <tr>
              <td colSpan={2}></td>
              <td
                colSpan={6}
                style={{
                  color: "#000000",
                  fontSize: 11,
                  lineHeight: "16px",
                  fontWeight: 700,
                  fontFamily: '"Poppins", sans-serif',
                  verticalAlign: "center",
                  borderCollapse: "collapse",
                  paddingTop: "25px",
                }}
              >
                <p>MASTER</p>
                M.T {printData?.[0]?.craftId?.bargeName}
              </td>
            </tr>
          </tbody>
        </table>
      </Index.Box>
    </>
  );
}

export default CrewMemberPrintPDF;
