import { useEffect, useState } from "react";
import Index from "../../../Index";
import { getResponsiblityWisePrevention } from "../../../../redux/admin/action";
import moment from "moment";
function ViewCraftPrevention() {
  const dispatch = Index.useDispatch();
  const navigate = Index.useNavigate();
  const { id } = Index.useParams();

  const [craftPreventionList, setCraftPreventionList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [preventiveName, setPreventiveName] = useState("");

  const handleGetResponsibilityWisePreventionData = (preventiveId) => {
    setLoading(true);
    dispatch(getResponsiblityWisePrevention({ preventiveId }))
      .then((res) => {
        if (res.status === 200) {
          // here is making array for perticular job wise
          setPreventiveName(res?.preventiveData);
          let preventionFinalArray = [];

          for (let preventionData of res?.data) {
            // here take bargeName and barge Id
            if (preventionData?.responsebility?.length) {
              for (let responsibilityData of preventionData?.responsebility) {
                // here take responsibilityName and responsibilityId
                if (responsibilityData?.subHead?.length) {
                  for (let subHeadData of responsibilityData?.subHead) {
                    // here take subHeadId and subHeadName
                    if (subHeadData?.jobArray?.length) {
                      for (let jobData of subHeadData?.jobArray) {
                        // here take jobID and JobName
                        preventionFinalArray.push({
                          bargeId: preventionData?.bargeId,
                          bargeName: preventionData?.bargeName,
                          responsibilityId:
                            responsibilityData?.responsibilityId,
                          responsibilityName:
                            responsibilityData?.responsibilityName,
                          subHeadId: subHeadData?.subHeadId,
                          subHeadName: subHeadData?.subHeadName,
                          jobId: jobData?.jobId,
                          jobName: jobData?.jobName,
                          monthArray: jobData?.monthArray,
                        });
                      }
                    }
                  }
                }
              }
            }
          }

          const newidCounts = {};

          // Calculate counts for each Craft ID , responsibility and subhead
          preventionFinalArray.forEach((item) => {
            const {
              bargeId,
              bargeName,
              responsibilityId,
              subHeadId,
              responsibilityName,
              subHeadName,
            } = item;

            if (!newidCounts[bargeId]) {
              newidCounts[bargeId] = {
                totalCraftCount: 1,
                responsibilityCounts: {},
                subHeadCounts: {},
                bargeCounts: {},
              };
            } else {
              newidCounts[bargeId].totalCraftCount++;
            }

            if (!newidCounts[bargeId].bargeCounts[bargeId]) {
              newidCounts[bargeId].bargeCounts[bargeId] = {
                count: 1,
                name: bargeName,
              };
            } else {
              newidCounts[bargeId].bargeCounts[bargeId].count++;
            }

            // Update counts for responsibility ID
            if (!newidCounts[bargeId].responsibilityCounts[responsibilityId]) {
              newidCounts[bargeId].responsibilityCounts[responsibilityId] = {
                count: 1,
                name: responsibilityName,
              };
            } else {
              newidCounts[bargeId].responsibilityCounts[responsibilityId]
                .count++;
            }

            // Update counts for sub-head ID
            if (!newidCounts[bargeId].subHeadCounts[subHeadId]) {
              newidCounts[bargeId].subHeadCounts[subHeadId] = {
                count: 1,
                name: subHeadName,
              };
            } else {
              newidCounts[bargeId].subHeadCounts[subHeadId].count++;
            }
          });

          // here is conver array instead of object
          const newFormattedPreventionCounts = Object.entries(newidCounts).map(
            ([
              id,
              {
                responsibilityCounts,
                subHeadCounts,
                totalCraftCount,
                bargeCounts,
              },
            ]) => ({
              id,
              responsibilityCounts: Object.entries(responsibilityCounts).map(
                ([id, { name, count }]) => ({
                  id,
                  name,
                  count,
                })
              ),
              subHeadCounts: Object.entries(subHeadCounts).map(
                ([id, { name, count }]) => ({
                  id,
                  name,
                  count,
                })
              ),
              bargeCounts: Object.entries(subHeadCounts).map(
                ([id, { name, count }]) => ({
                  id,
                  name,
                  count,
                })
              ),
              totalCraftCount,
            })
          );

          const newFinalPreventiveResultArray = [];
          preventionFinalArray?.map((finalResultData) => {
            if (newFinalPreventiveResultArray?.length) {
              const newFindCraftRecord = newFinalPreventiveResultArray?.find(
                (res) => res.bargeId === finalResultData?.bargeId
              );

              if (newFindCraftRecord === undefined) {
                let craftSpanCount = newFormattedPreventionCounts?.find(
                  (res) => res.id === finalResultData?.bargeId
                );
                let findResponsibilitySpan = newFormattedPreventionCounts
                  ?.find((res) => res.id === finalResultData?.bargeId)
                  ?.responsibilityCounts?.find(
                    (res) => res.id === finalResultData?.responsibilityId
                  );
                let findSubHeadSpan = newFormattedPreventionCounts
                  ?.find((res) => res.id === finalResultData?.bargeId)
                  ?.subHeadCounts?.find(
                    (res) => res.id === finalResultData?.subHeadId
                  );
                newFinalPreventiveResultArray.push({
                  ...finalResultData,
                  craftSpanCount: craftSpanCount?.totalCraftCount,
                  responsibilitySpan: findResponsibilitySpan?.count,
                  subHeadSpan: findSubHeadSpan?.count,
                });
              } else {
                // here is find responsibility wise data...
                const newFindResponsibilityWise =
                  newFinalPreventiveResultArray?.find(
                    (res) =>
                      res.bargeId === finalResultData?.bargeId &&
                      res.responsibilityId === finalResultData?.responsibilityId
                  );

                if (newFindResponsibilityWise === undefined) {
                  let findResponsibilitySpan = newFormattedPreventionCounts
                    ?.find((res) => res.id === finalResultData?.bargeId)
                    ?.responsibilityCounts?.find(
                      (res) => res.id === finalResultData?.responsibilityId
                    );
                  let findSubHeadSpan = newFormattedPreventionCounts
                    ?.find((res) => res.id === finalResultData?.bargeId)
                    ?.subHeadCounts?.find(
                      (res) => res.id === finalResultData?.subHeadId
                    );
                  newFinalPreventiveResultArray.push({
                    ...finalResultData,
                    responsibilitySpan: findResponsibilitySpan?.count,
                    subHeadSpan: findSubHeadSpan?.count,
                  });
                } else {
                  // here is find sub head wise....

                  const newFindResponsibilityWise =
                    newFinalPreventiveResultArray?.find(
                      (res) =>
                        res.bargeId === finalResultData?.bargeId &&
                        res.responsibilityId ===
                          finalResultData?.responsibilityId &&
                        res.subHeadId === finalResultData?.subHeadId
                    );

                  if (newFindResponsibilityWise === undefined) {
                    let findSubHeadSpan = newFormattedPreventionCounts
                      ?.find((res) => res.id === finalResultData?.bargeId)
                      ?.subHeadCounts?.find(
                        (res) => res.id === finalResultData?.subHeadId
                      );
                    newFinalPreventiveResultArray.push({
                      ...finalResultData,
                      subHeadSpan: findSubHeadSpan?.count,
                    });
                  } else {
                    newFinalPreventiveResultArray.push(finalResultData);
                  }
                }
              }
            } else {
              let craftSpanCount = newFormattedPreventionCounts?.find(
                (res) => res.id === finalResultData?.bargeId
              );
              let findResponsibilitySpan = newFormattedPreventionCounts
                ?.find((res) => res.id === finalResultData?.bargeId)
                ?.responsibilityCounts?.find(
                  (res) => res.id === finalResultData?.responsibilityId
                );
              let findSubHeadSpan = newFormattedPreventionCounts
                ?.find((res) => res.id === finalResultData?.bargeId)
                ?.subHeadCounts?.find(
                  (res) => res.id === finalResultData?.subHeadId
                );
              newFinalPreventiveResultArray.push({
                ...finalResultData,
                craftSpanCount: craftSpanCount?.totalCraftCount,
                responsibilitySpan: findResponsibilitySpan?.count,
                subHeadSpan: findSubHeadSpan?.count,
              });
            }
          });

          setCraftPreventionList(newFinalPreventiveResultArray);
          setLoading(false);
        } else {
          setCraftPreventionList([]);
          setLoading(false);
          setPreventiveName("");
        }
      })
      .catch((err) => {
        setCraftPreventionList([]);
        setPreventiveName("");
        setLoading(false);
        console.error(err);
      });
  };
  // when the page first time load then used...

  useEffect(() => {
    handleGetResponsibilityWisePreventionData(id);
  }, []);

  const backBtn = () => {
    navigate("/admin/craftPreventive");
  };

  return (
    <>
      <Index.DynamicTitle title="Craft Preventive View" />
      <Index.Box className="page-content-box">
        <Index.Box className="barge-common-box">
          <Index.Box className="title-header">
            <Index.Box className="title-header-flex res-title-header-flex">
              <Index.Box className="title-main">
                <Index.Typography
                  variant="p"
                  component="p"
                  className="page-title"
                >
                  Craft Preventive View
                </Index.Typography>
              </Index.Box>
              <Index.Box className="common-button blue-button save-btn ">
                <Index.Button
                  variant="contained"
                  type="submit"
                  className="Particular_model_submit ml-0"
                  onClick={backBtn}
                >
                  Back
                </Index.Button>
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Index.Box className="" sx={{ marginTop: "10px" }}>
            <Index.TableContainer
              component={Index.Paper}
              className="table-container"
            >
              <Index.Table
                aria-label="simple table"
                className="preventive-table"
              >
                <Index.TableHead>
                  {preventiveName?.length ? (
                    <>
                      <Index.TableRow className="table-preventive-header">
                        <Index.TableCell align="center" colSpan={17}>
                          {preventiveName}
                        </Index.TableCell>
                      </Index.TableRow>
                    </>
                  ) : (
                    <></>
                  )}
                  <Index.TableRow className="table-preventive-header">
                    {/* <Index.TableCell align="left">Sr No</Index.TableCell> */}
                    <Index.TableCell align="left">Craft</Index.TableCell>
                    <Index.TableCell align="left">
                      Responsibility
                    </Index.TableCell>
                    <Index.TableCell align="left">Item</Index.TableCell>
                    <Index.TableCell align="left">Job </Index.TableCell>
                    <Index.TableCell align="left">Jan</Index.TableCell>
                    <Index.TableCell align="left">Feb</Index.TableCell>
                    <Index.TableCell align="left">Mar</Index.TableCell>
                    <Index.TableCell align="left">Apr</Index.TableCell>
                    <Index.TableCell align="left">May</Index.TableCell>
                    <Index.TableCell align="left">Jun</Index.TableCell>
                    <Index.TableCell align="left">July</Index.TableCell>
                    <Index.TableCell align="left">Aug</Index.TableCell>
                    <Index.TableCell align="left">Sep</Index.TableCell>
                    <Index.TableCell align="left">Oct</Index.TableCell>
                    <Index.TableCell align="left">Nov</Index.TableCell>
                    <Index.TableCell align="left">Dec</Index.TableCell>
                  </Index.TableRow>
                </Index.TableHead>
                {loading ? (
                  <Index.TableBody>
                    <Index.TableRow>
                      <Index.TableCell
                        colSpan={17}
                        align="center"
                        className="loading-purchase"
                      >
                        <Index.CircularProgress color="secondary" size={20} />
                      </Index.TableCell>
                    </Index.TableRow>
                  </Index.TableBody>
                ) : (
                  <Index.TableBody>
                    {craftPreventionList?.length > 0 ? (
                      <>
                        {craftPreventionList?.map((item, index) => (
                          <Index.TableRow key={item?.bargeId + (index + 1)}>
                            {item.hasOwnProperty("craftSpanCount") ? (
                              <Index.TableCell rowSpan={item?.craftSpanCount}>
                                {item?.bargeName}
                              </Index.TableCell>
                            ) : (
                              <></>
                            )}

                            {item.hasOwnProperty("responsibilitySpan") ? (
                              <Index.TableCell
                                rowSpan={item?.responsibilitySpan}
                              >
                                {item?.responsibilityName}
                              </Index.TableCell>
                            ) : (
                              <></>
                            )}
                            {item.hasOwnProperty("subHeadSpan") ? (
                              <Index.TableCell rowSpan={item?.subHeadSpan}>
                                {item?.subHeadName}
                              </Index.TableCell>
                            ) : (
                              <></>
                            )}
                            <Index.TableCell>{item?.jobName}</Index.TableCell>
                            <Index.TableCell>
                              {item?.monthArray?.find(
                                (res) =>
                                  moment(res.startDate).format("MMMM") ===
                                  "January"
                              ) !== undefined ? (
                                item?.monthArray?.find(
                                  (res) =>
                                    moment(res.startDate).format("MMMM") ===
                                    "January"
                                ).preventiveStatusMonth === "Yes" ? (
                                  <>
                                    <li>&#10003;</li>
                                    <li>
                                      {
                                        item?.monthArray?.find(
                                          (res) =>
                                            moment(res.startDate).format(
                                              "MMMM"
                                            ) === "January"
                                        )?.preventiveType
                                      }
                                    </li>
                                    <li>
                                      {moment(
                                        item?.monthArray?.find(
                                          (res) =>
                                            moment(res.startDate).format(
                                              "MMMM"
                                            ) === "January"
                                        )?.preventiveDate
                                      ).format("DD-MM-YYYY")}
                                    </li>
                                  </>
                                ) : (
                                  <></> //No
                                )
                              ) : (
                                <></> //No
                              )}
                            </Index.TableCell>
                            <Index.TableCell>
                              {item?.monthArray?.find(
                                (res) =>
                                  moment(res.startDate).format("MMMM") ===
                                  "February"
                              ) !== undefined ? (
                                item?.monthArray?.find(
                                  (res) =>
                                    moment(res.startDate).format("MMMM") ===
                                    "February"
                                ).preventiveStatusMonth === "Yes" ? (
                                  <>
                                    <li>&#10003;</li>
                                    <li>
                                      {
                                        item?.monthArray?.find(
                                          (res) =>
                                            moment(res.startDate).format(
                                              "MMMM"
                                            ) === "February"
                                        )?.preventiveType
                                      }
                                    </li>
                                    <li>
                                      {moment(
                                        item?.monthArray?.find(
                                          (res) =>
                                            moment(res.startDate).format(
                                              "MMMM"
                                            ) === "February"
                                        )?.preventiveDate
                                      ).format("DD-MM-YYYY")}
                                    </li>
                                  </>
                                ) : (
                                  <></> //No
                                )
                              ) : (
                                <></> //No
                              )}
                            </Index.TableCell>
                            <Index.TableCell>
                              {item?.monthArray?.find(
                                (res) =>
                                  moment(res.startDate).format("MMMM") ===
                                  "March"
                              ) !== undefined ? (
                                item?.monthArray?.find(
                                  (res) =>
                                    moment(res.startDate).format("MMMM") ===
                                    "March"
                                ).preventiveStatusMonth === "Yes" ? (
                                  <>
                                    <li>&#10003;</li>
                                    <li>
                                      {
                                        item?.monthArray?.find(
                                          (res) =>
                                            moment(res.startDate).format(
                                              "MMMM"
                                            ) === "March"
                                        )?.preventiveType
                                      }
                                    </li>
                                    <li>
                                      {moment(
                                        item?.monthArray?.find(
                                          (res) =>
                                            moment(res.startDate).format(
                                              "MMMM"
                                            ) === "March"
                                        )?.preventiveDate
                                      ).format("DD-MM-YYYY")}
                                    </li>
                                  </>
                                ) : (
                                  <></> //No
                                )
                              ) : (
                                <></> //No
                              )}
                            </Index.TableCell>
                            <Index.TableCell>
                              {item?.monthArray?.find(
                                (res) =>
                                  moment(res.startDate).format("MMMM") ===
                                  "April"
                              ) !== undefined ? (
                                item?.monthArray?.find(
                                  (res) =>
                                    moment(res.startDate).format("MMMM") ===
                                    "April"
                                ).preventiveStatusMonth === "Yes" ? (
                                  <>
                                    <li>&#10003;</li>
                                    <li>
                                      {
                                        item?.monthArray?.find(
                                          (res) =>
                                            moment(res.startDate).format(
                                              "MMMM"
                                            ) === "April"
                                        )?.preventiveType
                                      }
                                    </li>
                                    <li>
                                      {moment(
                                        item?.monthArray?.find(
                                          (res) =>
                                            moment(res.startDate).format(
                                              "MMMM"
                                            ) === "April"
                                        )?.preventiveDate
                                      ).format("DD-MM-YYYY")}
                                    </li>
                                  </>
                                ) : (
                                  <></> //No
                                )
                              ) : (
                                <></> //No
                              )}
                            </Index.TableCell>
                            <Index.TableCell>
                              {item?.monthArray?.find(
                                (res) =>
                                  moment(res.startDate).format("MMMM") === "May"
                              ) !== undefined ? (
                                item?.monthArray?.find(
                                  (res) =>
                                    moment(res.startDate).format("MMMM") ===
                                    "May"
                                ).preventiveStatusMonth === "Yes" ? (
                                  <>
                                    <li>&#10003;</li>
                                    <li>
                                      {
                                        item?.monthArray?.find(
                                          (res) =>
                                            moment(res.startDate).format(
                                              "MMMM"
                                            ) === "May"
                                        )?.preventiveType
                                      }
                                    </li>
                                    <li>
                                      {moment(
                                        item?.monthArray?.find(
                                          (res) =>
                                            moment(res.startDate).format(
                                              "MMMM"
                                            ) === "May"
                                        )?.preventiveDate
                                      ).format("DD-MM-YYYY")}
                                    </li>
                                  </>
                                ) : (
                                  <></> //No
                                )
                              ) : (
                                <></> //No
                              )}
                            </Index.TableCell>
                            <Index.TableCell>
                              {item?.monthArray?.find(
                                (res) =>
                                  moment(res.startDate).format("MMMM") ===
                                  "June"
                              ) !== undefined ? (
                                item?.monthArray?.find(
                                  (res) =>
                                    moment(res.startDate).format("MMMM") ===
                                    "June"
                                ).preventiveStatusMonth === "Yes" ? (
                                  <>
                                    <li>&#10003;</li>
                                    <li>
                                      {
                                        item?.monthArray?.find(
                                          (res) =>
                                            moment(res.startDate).format(
                                              "MMMM"
                                            ) === "June"
                                        )?.preventiveType
                                      }
                                    </li>
                                    <li>
                                      {moment(
                                        item?.monthArray?.find(
                                          (res) =>
                                            moment(res.startDate).format(
                                              "MMMM"
                                            ) === "June"
                                        )?.preventiveDate
                                      ).format("DD-MM-YYYY")}
                                    </li>
                                  </>
                                ) : (
                                  <></> //No
                                )
                              ) : (
                                <></> //No
                              )}
                            </Index.TableCell>
                            <Index.TableCell>
                              {item?.monthArray?.find(
                                (res) =>
                                  moment(res.startDate).format("MMMM") ===
                                  "July"
                              ) !== undefined ? (
                                item?.monthArray?.find(
                                  (res) =>
                                    moment(res.startDate).format("MMMM") ===
                                    "July"
                                ).preventiveStatusMonth === "Yes" ? (
                                  <>
                                    <li>&#10003;</li>
                                    <li>
                                      {
                                        item?.monthArray?.find(
                                          (res) =>
                                            moment(res.startDate).format(
                                              "MMMM"
                                            ) === "July"
                                        )?.preventiveType
                                      }
                                    </li>
                                    <li>
                                      {moment(
                                        item?.monthArray?.find(
                                          (res) =>
                                            moment(res.startDate).format(
                                              "MMMM"
                                            ) === "July"
                                        )?.preventiveDate
                                      ).format("DD-MM-YYYY")}
                                    </li>
                                  </>
                                ) : (
                                  <></> //No
                                )
                              ) : (
                                <></> //No
                              )}
                            </Index.TableCell>
                            <Index.TableCell>
                              {item?.monthArray?.find(
                                (res) =>
                                  moment(res.startDate).format("MMMM") ===
                                  "August"
                              ) !== undefined ? (
                                item?.monthArray?.find(
                                  (res) =>
                                    moment(res.startDate).format("MMMM") ===
                                    "August"
                                ).preventiveStatusMonth === "Yes" ? (
                                  <>
                                    <li>&#10003;</li>
                                    <li>
                                      {
                                        item?.monthArray?.find(
                                          (res) =>
                                            moment(res.startDate).format(
                                              "MMMM"
                                            ) === "August"
                                        )?.preventiveType
                                      }
                                    </li>
                                    <li>
                                      {moment(
                                        item?.monthArray?.find(
                                          (res) =>
                                            moment(res.startDate).format(
                                              "MMMM"
                                            ) === "August"
                                        )?.preventiveDate
                                      ).format("DD-MM-YYYY")}
                                    </li>
                                  </>
                                ) : (
                                  <></> //No
                                )
                              ) : (
                                <></> //No
                              )}
                            </Index.TableCell>
                            <Index.TableCell>
                              {item?.monthArray?.find(
                                (res) =>
                                  moment(res.startDate).format("MMMM") ===
                                  "September"
                              ) !== undefined ? (
                                item?.monthArray?.find(
                                  (res) =>
                                    moment(res.startDate).format("MMMM") ===
                                    "September"
                                ).preventiveStatusMonth === "Yes" ? (
                                  <>
                                    <li>&#10003;</li>
                                    <li>
                                      {
                                        item?.monthArray?.find(
                                          (res) =>
                                            moment(res.startDate).format(
                                              "MMMM"
                                            ) === "September"
                                        )?.preventiveType
                                      }
                                    </li>
                                    <li>
                                      {moment(
                                        item?.monthArray?.find(
                                          (res) =>
                                            moment(res.startDate).format(
                                              "MMMM"
                                            ) === "September"
                                        )?.preventiveDate
                                      ).format("DD-MM-YYYY")}
                                    </li>
                                  </>
                                ) : (
                                  <></> //No
                                )
                              ) : (
                                <></> //No
                              )}
                            </Index.TableCell>
                            <Index.TableCell>
                              {item?.monthArray?.find(
                                (res) =>
                                  moment(res.startDate).format("MMMM") ===
                                  "October"
                              ) !== undefined ? (
                                item?.monthArray?.find(
                                  (res) =>
                                    moment(res.startDate).format("MMMM") ===
                                    "October"
                                ).preventiveStatusMonth === "Yes" ? (
                                  <>
                                    <li>&#10003;</li>
                                    <li>
                                      {
                                        item?.monthArray?.find(
                                          (res) =>
                                            moment(res.startDate).format(
                                              "MMMM"
                                            ) === "October"
                                        )?.preventiveType
                                      }
                                    </li>
                                    <li>
                                      {moment(
                                        item?.monthArray?.find(
                                          (res) =>
                                            moment(res.startDate).format(
                                              "MMMM"
                                            ) === "October"
                                        )?.preventiveDate
                                      ).format("DD-MM-YYYY")}
                                    </li>
                                  </>
                                ) : (
                                  <></> //No
                                )
                              ) : (
                                <></> //No
                              )}
                            </Index.TableCell>
                            <Index.TableCell>
                              {item?.monthArray?.find(
                                (res) =>
                                  moment(res.startDate).format("MMMM") ===
                                  "November"
                              ) !== undefined ? (
                                item?.monthArray?.find(
                                  (res) =>
                                    moment(res.startDate).format("MMMM") ===
                                    "November"
                                ).preventiveStatusMonth === "Yes" ? (
                                  <>
                                    <li>&#10003;</li>
                                    <li>
                                      {
                                        item?.monthArray?.find(
                                          (res) =>
                                            moment(res.startDate).format(
                                              "MMMM"
                                            ) === "November"
                                        )?.preventiveType
                                      }
                                    </li>
                                    <li>
                                      {moment(
                                        item?.monthArray?.find(
                                          (res) =>
                                            moment(res.startDate).format(
                                              "MMMM"
                                            ) === "November"
                                        )?.preventiveDate
                                      ).format("DD-MM-YYYY")}
                                    </li>
                                  </>
                                ) : (
                                  <></> //No
                                )
                              ) : (
                                <></> //No
                              )}
                            </Index.TableCell>
                            <Index.TableCell>
                              {item?.monthArray?.find(
                                (res) =>
                                  moment(res.startDate).format("MMMM") ===
                                  "December"
                              ) !== undefined ? (
                                item?.monthArray?.find(
                                  (res) =>
                                    moment(res.startDate).format("MMMM") ===
                                    "December"
                                ).preventiveStatusMonth === "Yes" ? (
                                  <>
                                    <li>&#10003;</li>
                                    <li>
                                      {
                                        item?.monthArray?.find(
                                          (res) =>
                                            moment(res.startDate).format(
                                              "MMMM"
                                            ) === "December"
                                        )?.preventiveType
                                      }
                                    </li>
                                    <li>
                                      {moment(
                                        item?.monthArray?.find(
                                          (res) =>
                                            moment(res.startDate).format(
                                              "MMMM"
                                            ) === "December"
                                        )?.preventiveDate
                                      ).format("DD-MM-YYYY")}
                                    </li>
                                  </>
                                ) : (
                                  <></> //No
                                )
                              ) : (
                                <></> //No
                              )}
                            </Index.TableCell>
                          </Index.TableRow>
                        ))}
                      </>
                    ) : (
                      <Index.TableRow>
                        <Index.TableCell
                          component="td"
                          variant="td"
                          scope="row"
                          className="no-data-in-list"
                          colSpan={17}
                          align="center"
                        >
                          No data available
                        </Index.TableCell>
                      </Index.TableRow>
                    )}
                  </Index.TableBody>
                )}
              </Index.Table>
            </Index.TableContainer>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
}

export default ViewCraftPrevention;
