import { useCallback, useEffect, useState } from "react";
import Index from "../../../Index";
import { Formik } from "formik";
import * as Yup from "yup";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import {
  assignToCraftPreventive,
  getAllBargeList,
  getCraftPreventiveList,
  removeCraftPreventive,
} from "../../../../redux/admin/action";

const Search = Index.styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: Index.alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: Index.alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const StyledInputBase = Index.styled(Index.InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

const HtmlTooltip = Index.styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}));

const ActionItem = Index.styled(Index.MenuItem)(() => ({
  fontFamily: "poppins",
  lineHeight: "15px",
  fontSize: "14px",
  fontWeight: "400",
  color: "#595F69",
}));

function CraftPreventionList() {
  const dispatch = Index.useDispatch();
  const navigate = Index.useNavigate();
  const [loading, setLoading] = useState(false);
  const [craftPreventionList, setCraftPreventionList] = useState([]);
  const [assignUserIds, setAssignUserIds] = useState([]);
  const [openAssigneeModel, setOpenAssigneeModel] = useState(false);

  const [bargeId, setBargeId] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [actionIndex, setActionIndex] = useState();

  const [deleteOpen, setDeleteOpen] = useState(false);
  const [rowId, setRowId] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState(0);
  const [paginationPerPage, setPaginationPerPage] = useState(50);
  const [searchingText, setSearchingText] = useState("");
  const [totalRecordsCount, setTotalRecordCount] = useState(0);

  const [allBargeList, setAllBargeList] = useState([]);

  let initialValues = {
    assignToBarge: "",
  };
  if (assignUserIds) {
    initialValues = {
      assignToBarge: assignUserIds,
    };
  }

  // when the get all the barge list for assignies then used...
  const handleGetAllBargeList = () => {
    dispatch(getAllBargeList())
      .then((res) => {
        if (res.status === 200) {
          setAllBargeList(res.data);
        } else {
          setAllBargeList([]);
        }
      })
      .catch((err) => {
        console.error(err);
        setAllBargeList([]);
      });
  };

  // when the first time page load then used...
  const handleGetCraftPreventionList = async (values) => {
    setLoading(true);
    dispatch(getCraftPreventiveList(values))
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          setCraftPreventionList(res.data);
          setTotalRecordCount(res.totalCount);
          setCurrentPage(parseInt(res?.currentPage));
        } else {
          setLoading(false);
          setCraftPreventionList([]);
          setTotalRecordCount(0);
          setCurrentPage(1);
        }
      })
      .catch((err) => {
        setLoading(false);
        setCraftPreventionList([]);
        setCurrentPage(1);
        setTotalRecordCount(0);
        console.error(err);
      });
  };

  // when the page first time load then used....
  useEffect(() => {
    const values = {
      page: 1,
      search: searchingText,
      size: paginationPerPage,
    };
    handleGetCraftPreventionList(values);

    // get all the barge list
    handleGetAllBargeList();
  }, []);

  const handleView = (row) => {
    navigate(`/admin/craftPreventive/view/${row?._id}`);
  };

  const handleClickMenu = (e, index) => {
    setActionIndex(index);
    setAnchorEl(e.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  //Assign to model
  const handleAssigneeModelOpen = () => {
    setOpenAssigneeModel(true);
  };

  const handleEdit = (row) => {
    navigate("/admin/craftPreventive/edit-craft-preventive", {
      state: { row },
    });
  };

  const handleAssigneeModelClose = () => {
    setOpenAssigneeModel(false);
    setBargeId("");
  };

  const handlePageChange = useCallback(
    (event, value) => {
      setCurrentPage(value);
      const values = {
        page: value,
        search: searchingText,
        size: paginationPerPage,
      };
      handleGetCraftPreventionList(values);
    },
    [paginationPerPage, searchingText, setCurrentPage]
  );

  const handleDeleteOpen = () => {
    setAnchorEl(null);
    setDeleteOpen(true);
  };

  const handleDeleteClose = () => {
    setDeleteOpen(false);
  };

  const handleAssigneeFormSubmit = (values) => {
    const urlencoded = new URLSearchParams();
    urlencoded.append("id", bargeId);
    urlencoded.append(
      "assignToBarge",
      values?.assignToBarge?.length ? JSON.stringify(values?.assignToBarge) : []
    );
    try {
      dispatch(assignToCraftPreventive(urlencoded)).then((res) => {
        if (res?.status === 200) {
          // here get the prevention listing...
          const values = {
            page: 1,
            search: searchingText,
            size: paginationPerPage,
          };
          handleGetCraftPreventionList(values);
          handleAssigneeModelClose();
        }
      });
    } catch (error) {}
  };

  // when delete record and api side pass data then used...
  const handleDeleteRecord = () => {
    dispatch(removeCraftPreventive({ id: rowId })).then((res) => {
      if (res.status === 200) {
        // here close the delete modal
        handleDeleteClose();
        // here get latest listing for preventive
        const values = {
          page: 1,
          search: searchingText,
          size: paginationPerPage,
        };
        handleGetCraftPreventionList(values);
      }
    });
  };

  // when the user change the per page record change then used..
  const handleChangePaginationPerChange = useCallback(
    (e) => {
      const { value } = e.target;
      setPaginationPerPage(value);
      const values = {
        page: 1,
        search: searchingText,
        size: value,
      };
      handleGetCraftPreventionList(values);
    },
    [searchingText, setPaginationPerPage]
  );

  // when the user search the purchase records then used...
  const handleSearchPurchaseRecord = useCallback(
    (e) => {
      const { value } = e.target;
      if (value?.length > 0) {
        setSearchingText(value);
        const values = {
          page: 1,
          search: value.trim(),
          size: paginationPerPage,
        };
        handleGetCraftPreventionList(values);
      } else {
        setSearchingText("");
        const values = {
          page: 1,
          search: "",
          size: paginationPerPage,
        };
        handleGetCraftPreventionList(values);
      }
    },
    [paginationPerPage, setSearchingText]
  );

  // when the user want to get preventive logs then used...
  const handleOpenPreventivLogs = (preventiveId) => {
    navigate(`/admin/craftPreventive/preventive-logs/${preventiveId}`);
  };

  return (
    <>
      <Index.DynamicTitle title="Craft Preventive" />
      <Index.Box className="page-content-box">
        <Index.Box className="barge-common-box">
          <Index.Box className="title-header">
            <Index.Box className="title-header-flex res-title-header-flex">
              <Index.Box className="title-main">
                <Index.Typography
                  variant="p"
                  component="p"
                  className="page-title"
                >
                  Craft Preventive
                </Index.Typography>
              </Index.Box>
              <Index.Box className="d-flex align-items-center res-set-search">
                <Search className="search">
                  <StyledInputBase
                    placeholder="Search"
                    inputProps={{ "aria-label": "search" }}
                    onChange={(e) => handleSearchPurchaseRecord(e)}
                  />
                </Search>

                <Index.Box className="common-button blue-button res-blue-button">
                  <Index.RouteLink
                    to="/admin/craftPreventive/add-craft-preventive"
                    className="no-text-decoration"
                  >
                    {" "}
                    <Index.Button variant="contained" disableRipple>
                      Add Craft Preventive
                    </Index.Button>
                  </Index.RouteLink>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Index.Box className="">
            <Index.TableContainer
              component={Index.Paper}
              className="table-container"
            >
              <Index.Table
                aria-label="simple table"
                className="table-design-main barge-table"
              >
                <Index.TableHead>
                  <Index.TableRow>
                    <Index.TableCell align="left">
                      Sr No (Unique No)
                    </Index.TableCell>
                    <Index.TableCell align="left">
                      Head of Preventive
                    </Index.TableCell>
                    <Index.TableCell align="left">Remark</Index.TableCell>
                    <Index.TableCell align="left">Assign To</Index.TableCell>
                    <Index.TableCell align="left">Action</Index.TableCell>
                  </Index.TableRow>
                </Index.TableHead>
                {loading ? (
                  <Index.TableBody>
                    <Index.TableRow>
                      <Index.TableCell
                        colSpan={12}
                        align="center"
                        className="loading-purchase"
                      >
                        <Index.CircularProgress color="secondary" size={20} />
                      </Index.TableCell>
                    </Index.TableRow>
                  </Index.TableBody>
                ) : (
                  <Index.TableBody className="tanker-table-body">
                    {craftPreventionList?.length ? (
                      craftPreventionList?.map((row, index) => (
                        <Index.TableRow
                          key={row?.serialNo}
                          //   className={
                          //     row?.underMaintenance === true
                          //       ? "tanker-row-color"
                          //       : ""
                          //   }
                        >
                          <Index.TableCell>
                            {row?.serialNo ? row?.serialNo : "-"}
                          </Index.TableCell>
                          <Index.TableCell>
                            {row?.headOfPreventive
                              ? row?.headOfPreventive
                              : "-"}
                          </Index.TableCell>
                          <Index.TableCell align="left">
                            {row?.remark}
                          </Index.TableCell>
                          <Index.TableCell align="left">
                            {row?.assignToBarge?.length ? (
                              <HtmlTooltip
                                title={row?.assignToBarge
                                  ?.map((el) => el.bargeName)
                                  .join(",")}
                              >
                                <span
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  {row?.assignToBarge[0]?.bargeName && (
                                    <>
                                      <span>
                                        {`${row?.assignToBarge[0]?.bargeName.substring(
                                          0,
                                          5
                                        )}...`}
                                      </span>
                                      <Index.IconButton
                                        onClick={() => {
                                          setAssignUserIds(
                                            row?.assignToBarge?.map((el) => ({
                                              _id: el._id,
                                              bargeName: el.bargeName,
                                            }))
                                          );
                                          handleAssigneeModelOpen();
                                          setBargeId(row?._id);
                                        }}
                                        style={{ marginLeft: "5px" }}
                                      >
                                        <Index.EditIcon
                                          color="primary"
                                          fontSize="small"
                                        />
                                      </Index.IconButton>
                                    </>
                                  )}
                                </span>
                              </HtmlTooltip>
                            ) : (
                              <Index.Box className="common-button blue-button res-blue-button assign-btn-align">
                                <Index.Button
                                  variant="contained"
                                  className="assign-button-margin"
                                  disableRipple
                                  onClick={() => {
                                    setAssignUserIds("");
                                    handleAssigneeModelOpen();
                                    setBargeId(row?._id);
                                  }}
                                >
                                  Assign to
                                </Index.Button>
                              </Index.Box>
                            )}
                          </Index.TableCell>

                          <Index.TableCell sx={{ display: "flex" }}>
                            <Index.IconButton
                              onClick={(e) => {
                                handleClickMenu(e, index);
                              }}
                            >
                              <Index.MenuIcon className="action-menu-icon" />
                            </Index.IconButton>
                            <Index.Menu
                              id="demo-positioned-menu"
                              aria-labelledby="demo-positioned-button"
                              anchorEl={anchorEl}
                              keepMounted
                              elevation={2}
                              open={Boolean(anchorEl)}
                              onClose={handleCloseMenu}
                              anchorOrigin={{
                                vertical: "top",
                                horizontal: "left",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "left",
                              }}
                            >
                              <ActionItem
                                className="action-text"
                                onClick={() =>
                                  handleView(craftPreventionList?.[actionIndex])
                                }
                              >
                                View
                              </ActionItem>
                              <ActionItem
                                className="action-text"
                                onClick={() =>
                                  handleEdit(craftPreventionList?.[actionIndex])
                                }
                              >
                                Edit
                              </ActionItem>
                              

                              <ActionItem
                                className="action-text"
                                onClick={() => {
                                  setRowId(
                                    craftPreventionList?.[actionIndex]?._id
                                  );
                                  handleDeleteOpen();
                                }}
                              >
                                Delete
                              </ActionItem>
                              <ActionItem
                                className="action-text"
                                onClick={() => {
                                  navigate(`/admin/craftPreventive/old-view`);
                                }}
                              >
                                old
                              </ActionItem>

                              <ActionItem
                                className="action-text"
                                onClick={() => {
                                  // setRowId(
                                  //   craftPreventionList?.[actionIndex]?._id
                                  // );
                                  // handleDeleteOpen();
                                  handleOpenPreventivLogs(
                                    craftPreventionList?.[actionIndex]?._id
                                  );
                                }}
                              >
                                Logs
                              </ActionItem>
                            </Index.Menu>
                          </Index.TableCell>
                        </Index.TableRow>
                      ))
                    ) : (
                      <Index.TableRow>
                        <Index.TableCell
                          component="td"
                          variant="td"
                          scope="row"
                          className="no-data-in-list"
                          colSpan={12}
                          align="center"
                        >
                          No data available
                        </Index.TableCell>
                      </Index.TableRow>
                    )}
                  </Index.TableBody>
                )}
              </Index.Table>
            </Index.TableContainer>
          </Index.Box>
          <Index.Box className="pagination-design flex-end">
            <Index.Stack spacing={2}>
              <Index.Box className="pagination-count">
                <Index.Box className="input-design-div with-border">
                  <Index.Select
                    fullWidth
                    value={paginationPerPage}
                    onChange={(e) => handleChangePaginationPerChange(e)}
                  >
                    <Index.MenuItem value={10}>10</Index.MenuItem>
                    <Index.MenuItem value={25}>25</Index.MenuItem>
                    <Index.MenuItem value={50}>50</Index.MenuItem>
                    <Index.MenuItem value={100}>100</Index.MenuItem>
                  </Index.Select>
                </Index.Box>
                {loading ? (
                  <Index.CircularProgress color="secondary" size={26} />
                ) : (
                  <>
                    <Index.Pagination
                      count={Math.ceil(totalRecordsCount / paginationPerPage)}
                      page={currentPage}
                      onChange={handlePageChange}
                    />
                  </>
                )}
              </Index.Box>
            </Index.Stack>
          </Index.Box>
        </Index.Box>
      </Index.Box>

      {/* Delete basis  */}
      <Index.DeleteModal
        deleteOpen={deleteOpen}
        handleDeleteRecord={handleDeleteRecord}
        handleDeleteClose={handleDeleteClose}
        // orderDocument={"orderDocument"}
        customMessage={"Do you want to delete this craft preventive ?"}
      />

      {/* assign Dialoag basis */}
      <Index.Dialog
        open={openAssigneeModel}
        onClose={handleAssigneeModelClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Index.DialogContent className="expense-model-main  expense-model-main-box-set craft-assign-model">
          <Index.Box className="title-main">
            <Formik
              initialValues={initialValues}
              // validationSchema={assigneeValidationSchema}
              onSubmit={handleAssigneeFormSubmit}
            >
              {({
                values,
                errors,
                touched,
                setFieldValue,
                handleChange,
                handleSubmit,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Index.Box className="title-main" sx={{ marginTop: "10px" }}>
                    <Index.ClearIcon
                      className="model-close-icon"
                      onClick={handleAssigneeModelClose}
                    />
                    <Index.Typography
                      variant="h6"
                      component="h6"
                      className="input-label"
                      sx={{ marginBottom: "15px" }}
                    >
                      Assign To
                    </Index.Typography>
                    <Index.Grid container spacing={2}>
                      <Index.Grid item xs={12} sm={6} md={12}>
                        <Index.Typography
                          variant="label"
                          component="label"
                          className="input-label"
                        >
                          Barges
                        </Index.Typography>
                        <Index.Box className="trader-selection">
                          <Index.Autocomplete
                            disablePortal
                            // className="invoice-select-drop"
                            id="tags-outlined"
                            options={allBargeList || []}
                            getOptionLabel={(option) => option?.bargeName}
                            value={allBargeList.filter((value) =>
                              // values.assignToBarge.includes(value._id)
                              values?.assignToBarge !== "" &&
                              values?.assignToBarge !== null &&
                              values?.assignToBarge?.length
                                ? values?.assignToBarge?.some(
                                    (o2) => value?._id === o2?._id
                                  )
                                : ""
                            )}
                            renderOption={(props, option) => (
                              <>
                                <Index.Box
                                  component="li"
                                  sx={{
                                    "& > img": { mr: 2, flexShrink: 0 },
                                  }}
                                  key={option?._id}
                                  {...props}
                                >
                                  {option?.bargeName}
                                </Index.Box>
                              </>
                            )}
                            onChange={(event, newValue) => {
                              // const newSelectedIds = newValue.map(
                              //   (val) => val._id
                              // );
                              setFieldValue("assignToBarge", newValue);
                            }}
                            renderInput={(params) => (
                              <Index.TextField
                                {...params}
                                placeholder="Select barges"
                                variant="outlined"
                              />
                            )}
                            multiple
                          />
                          {/* {touched?.assignToBarge && errors?.assignToBarge && (
                            <Index.FormHelperText error>
                              {errors?.assignToBarge}
                            </Index.FormHelperText>
                          )} */}
                        </Index.Box>
                      </Index.Grid>
                    </Index.Grid>
                    <Index.Grid
                      item
                      xs={12}
                      sm={6}
                      md={12}
                      sx={{ marginTop: "105px" }}
                    >
                      <Index.Box
                        className="common-button blue-button flex-end save-btn"
                        sx={{
                          display: "flex",
                          alignItems: "flex-end",
                        }}
                      >
                        <Index.Button
                          variant="contained"
                          type="submit"
                          className="Particular_model_submit"
                        >
                          Submit
                        </Index.Button>
                      </Index.Box>
                    </Index.Grid>

                    {/* <Index.Box className="line" mt={3} mb={3}></Index.Box> */}
                  </Index.Box>
                </form>
              )}
            </Formik>
          </Index.Box>
        </Index.DialogContent>
      </Index.Dialog>
    </>
  );
}

export default CraftPreventionList;
