import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import PageIndex from "../../../PageIndex";
import { getPartyName, deletePartyName } from "../../../../redux/admin/action";

const Search = Index.styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: Index.alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: Index.alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const StyledInputBase = Index.styled(Index.InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

const PartyList = () => {
  const dispatch = Index.useDispatch();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [rowId, setRowId] = useState("");
  const [partyModelData, setPartyModeldata] = useState();
  const [deleteOpen, setDeleteOpen] = useState(false);

  // for Party add  model open close
  const handleOpen = (row) => {
    setPartyModeldata(row);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  // State for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState(0);
  const [paginationPerPage, setPaginationPerPage] = useState(50);

  // State for searching and set data
  const [searchedData, setSearchedData] = useState([]);
  const [currentRows, setCurrentRows] = useState([]);

  // Get party list
  const getPartyList = () => {
    setLoading(true);
    dispatch(getPartyName()).then((res) => {
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    });
  };
  useEffect(() => {
    getPartyList();
  }, []);

  // redux data
  const partyNameList = Index.useSelector(
    (state) => state.AdminReducer.partyNameList
  );

  // pagination calculation
  const PER_PAGE = paginationPerPage;
  useEffect(() => {
    //Page count
    const count = Math.ceil(searchedData?.length / PER_PAGE);
    setCount(count);
    const indexOfLastRow = currentPage * PER_PAGE;
    const indexOfFirstRow = indexOfLastRow - PER_PAGE;
    const modifiedRows = searchedData?.slice(indexOfFirstRow, indexOfLastRow);
    setCurrentRows(modifiedRows);
    if (modifiedRows?.length === 0 && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  }, [currentPage, searchedData, paginationPerPage, PER_PAGE]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };
  // Pagination calculation end

  const handleDeleteOpen = () => {
    setDeleteOpen(true);
  };

  const handleDeleteClose = () => {
    setDeleteOpen(false);
  };

  const handleDeleteRecord = () => {
    dispatch(deletePartyName({ id: rowId }));
    handleDeleteClose();
  };

  // Copy original data to searched data
  useEffect(() => {
    setSearchedData(
      partyNameList?.sort((a, b) => b?.updatedAt?.localeCompare(a?.updatedAt))
    );
  }, [partyNameList]);

  // Search on table
  const requestSearch = (searched) => {
    if (!searched) return setSearchedData(partyNameList);
    setSearchedData(
      partyNameList?.filter(
        (item) =>
          item?.partyName
            ?.toString()
            .toLowerCase()
            .includes(searched.toLowerCase()) ||
          item?.email
            ?.toString()
            .toLowerCase()
            .includes(searched.toLowerCase()) ||
          item?.gstNo
            ?.toString()
            .toLowerCase()
            .includes(searched.toLowerCase()) ||
          item?.b2bB2c
            ?.toString()
            .toLowerCase()
            .includes(searched.toLowerCase())
      )
    );
    setCurrentPage(1);
  };

  const handleReloadPartyListing = () =>{
    getPartyList();
  }
  return (
    <div>
      <Index.DynamicTitle title="Party Management" />
      <Index.Box className="">
        <Index.Box className="barge-common-box">
          <Index.Box className="title-header">
            <Index.Box className="title-header-flex  res-title-header-flex">
              <Index.Box className="title-main">
                <Index.Typography
                  variant="p"
                  component="p"
                  className="page-title"
                >
                  Party Management
                </Index.Typography>
              </Index.Box>
              <Index.Box className="d-flex align-items-center res-set-search res-search-box">
                <Search className="search res-search">
                  <StyledInputBase
                    placeholder="Search"
                    inputProps={{ "aria-label": "search" }}
                    onChange={(e) =>
                      setTimeout(() => {
                        requestSearch(e.target.value.trim());
                      }, 1000)
                    }
                  />
                </Search>

                <Index.Box className="common-button blue-button res-blue-button">
                  <Index.RouteLink className="no-text-decoration">
                    {" "}
                    <Index.Button
                      variant="contained"
                      disableRipple
                      onClick={() => handleOpen()}
                    >
                      Add Party
                    </Index.Button>
                  </Index.RouteLink>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Index.Box className="">
            <Index.TableContainer
              component={Index.Paper}
              className="table-container"
            >
              <Index.Table
                aria-label="simple table"
                className="table-design-main party-management-table"
              >
                <Index.TableHead>
                  <Index.TableRow>
                    <Index.TableCell className="invoice_table_header">
                      Party Name
                    </Index.TableCell>
                    <Index.TableCell className="invoice_table_header">
                      Email
                    </Index.TableCell>
                    <Index.TableCell
                      align="left"
                      className="invoice_table_header"
                    >
                      GST No.
                    </Index.TableCell>
                    <Index.TableCell
                      align="left"
                      className="invoice_table_header"
                    >
                      B2B / B2C
                    </Index.TableCell>

                    <Index.TableCell
                      align="left"
                      className="invoice_table_header"
                    >
                      Action
                    </Index.TableCell>
                  </Index.TableRow>
                </Index.TableHead>
                {loading ? (
                  <Index.TableBody>
                    <Index.TableRow>
                      <Index.TableCell
                        colSpan={12}
                        align="center"
                        className="loading-purchase"
                      >
                        <Index.CircularProgress color="secondary" size={20} />
                      </Index.TableCell>
                    </Index.TableRow>
                  </Index.TableBody>
                ) : (
                  <Index.TableBody>
                    {currentRows?.length ? (
                      currentRows?.map((row, index) => (
                        <Index.TableRow key={row?._id}>
                          <Index.TableCell>
                            {row?.partyName ? row?.partyName : "-"}
                          </Index.TableCell>
                          <Index.TableCell>
                            {row?.email ? row?.email : "-"}
                          </Index.TableCell>
                          <Index.TableCell>
                            {row?.gstNo ? row?.gstNo : "-"}
                          </Index.TableCell>
                          <Index.TableCell>
                            {row?.b2bB2c ? row?.b2bB2c : "-"}
                          </Index.TableCell>
                          <Index.TableCell>
                            <Index.IconButton>
                              <Index.EditIcon
                                className="edit-icon-set"
                                onClick={() => handleOpen(row)}
                              />
                              
                            </Index.IconButton>
                            <Index.IconButton>
                            <Index.DeleteIcon
                                color="error"
                                onClick={() => {
                                  setRowId(row?._id);
                                  handleDeleteOpen();
                                }}
                              />
                            </Index.IconButton>
                          </Index.TableCell>
                        </Index.TableRow>
                      ))
                    ) : (
                      <Index.TableRow>
                        <Index.TableCell
                          component="td"
                          variant="td"
                          scope="row"
                          className="no-data-in-list"
                          colSpan={12}
                          align="center"
                        >
                          No data available
                        </Index.TableCell>
                      </Index.TableRow>
                    )}
                  </Index.TableBody>
                )}
              </Index.Table>
            </Index.TableContainer>
          </Index.Box>
          <Index.Box className="pagination-design flex-end">
            <Index.Stack spacing={1}>
              <Index.Box className="pagination-count">
                <Index.Box className="input-design-div with-border">
                  <Index.Select
                    fullWidth
                    value={paginationPerPage}
                    onChange={(e) => {
                      setPaginationPerPage(e.target.value);
                    }}
                  >
                    <Index.MenuItem value={10}>10</Index.MenuItem>
                    <Index.MenuItem value={25}>25</Index.MenuItem>
                    <Index.MenuItem value={50}>50</Index.MenuItem>
                    <Index.MenuItem value={100}>100</Index.MenuItem>
                  </Index.Select>
                </Index.Box>
                <Index.Pagination
                  count={count}
                  page={currentPage}
                  onChange={handlePageChange}
                />
              </Index.Box>
            </Index.Stack>
          </Index.Box>
        </Index.Box>
      </Index.Box>
      <Index.DeleteModal
        deleteOpen={deleteOpen}
        handleDeleteRecord={handleDeleteRecord}
        handleDeleteClose={handleDeleteClose}
      />
      <PageIndex.AddPartyModel
        handleOpen={handleOpen}
        handleClose={handleClose}
        setOpen={setOpen}
        open={open}
        partyModelData={partyModelData}
        handleReloadPartyListing={handleReloadPartyListing}
      />
    </div>
  );
};

export default PartyList;
