import React, { useEffect, useState } from "react";
import Index from "../../../Index"
import { getBankHistories } from "../../../../redux/admin/action";

function BankHistory() {
    const dispatch = Index.useDispatch()
    const [loading, setLoading] = useState(false);
    const [bankHistoryData, setBankHistoryData] = useState([])
    const [currentPage, setCurrentPage] = useState(1);
    const [paginationPerPage, setPaginationPerPage] = useState(50);
    const [totalRecordCount, setTotalRecordCount] = useState(0);


    const handleGetBankHistories = (values) => {
        setLoading(true);
        dispatch(getBankHistories(values))
            .then((res) => {
                if (res?.status === 200) {
                    setLoading(false);
                    setTotalRecordCount(res?.totalCount);
                    setCurrentPage(res?.currentPage);
                    setBankHistoryData(res?.data);
                } else {
                    setLoading(false);
                    setTotalRecordCount(0);
                    setCurrentPage(1);
                    setBankHistoryData([]);
                }
            })
            .catch((error) => console.log(error));
    }

    // Get Bank History list
    useEffect(() => {
        const values = {
            page: 1,
            size: paginationPerPage,
        };

        handleGetBankHistories(values);
    }, [dispatch]);

    // when the user change the page then used....
    const handlePageChange = React.useCallback(
        (event, value) => {
            setCurrentPage(value);
            const values = {
                page: value,
                size: paginationPerPage,
            };
            handleGetBankHistories(values);
        },
        [paginationPerPage, setCurrentPage]
    );

    // when the user change the per page pagination then used...
    const handleChangePaginationPerChange = (e) => {
        const { value } = e.target;
        setPaginationPerPage(value);
        const values = {
            page: 1,
            size: value,
        };
        handleGetBankHistories(values);
    };

    return (
        <>
            <Index.DynamicTitle title="Invoice Management" />
            <Index.Box className="">
                <Index.Box className="barge-common-box">
                    <Index.Box className="title-header">
                        <Index.Box className="title-header-flex  res-title-header-flex">
                            <Index.Box className="title-main">
                                <Index.Typography
                                    variant="p"
                                    component="p"
                                    className="page-title"
                                >
                                    Invoice Management
                                </Index.Typography>
                            </Index.Box>

                        </Index.Box>
                    </Index.Box>

                    <Index.Box className="">
                        <Index.TableContainer
                            component={Index.Paper}
                            className="table-container"
                        >
                            <Index.Table
                                aria-label="simple table"
                                className="table-design-main invoice-table invoice-table-set  "
                            >
                                <Index.TableHead>
                                    <Index.TableRow>
                                        <Index.TableCell className="invoice_table_header">
                                            No
                                        </Index.TableCell>
                                        <Index.TableCell className="invoice_table_header">
                                            Guid Id
                                        </Index.TableCell>
                                        <Index.TableCell className="invoice_table_header"   >
                                            Bank Name
                                        </Index.TableCell>
                                        <Index.TableCell className="invoice_table_header"   >
                                            Company Name
                                        </Index.TableCell>
                                    </Index.TableRow>
                                </Index.TableHead>

                                {loading ? (
                                    <Index.TableBody>
                                        <Index.TableRow>
                                            <Index.TableCell
                                                colSpan={15}
                                                align="center"
                                                className="loading-purchase"
                                            >
                                                <Index.CircularProgress color="secondary" size={20} />
                                            </Index.TableCell>
                                        </Index.TableRow>
                                    </Index.TableBody>
                                ) : (
                                    <Index.TableBody>
                                        {bankHistoryData?.length ? (
                                            bankHistoryData?.map((row, index) => (
                                                <Index.TableRow key={row?._id + (index + 1)}>
                                                    <Index.TableCell>
                                                        {index + 1}
                                                    </Index.TableCell>
                                                    <Index.TableCell>
                                                        {row?.guid ? row?.guid : "-"}
                                                    </Index.TableCell>
                                                    <Index.TableCell>
                                                        {row?.bankName
                                                            ? row?.bankName
                                                            : "-"}
                                                    </Index.TableCell>
                                                    <Index.TableCell>
                                                        {row?.companyName
                                                            ? row?.companyName
                                                            : "-"}
                                                    </Index.TableCell>
                                                </Index.TableRow>
                                            ))
                                        ) : (
                                            <Index.TableRow>
                                                <Index.TableCell
                                                    component="td"
                                                    variant="td"
                                                    scope="row"
                                                    className="no-data-in-list empty-record-align"
                                                    colSpan={19}
                                                    align="center"
                                                >
                                                    No data available
                                                </Index.TableCell>
                                            </Index.TableRow>
                                        )}
                                    </Index.TableBody>
                                )}
                            </Index.Table>
                        </Index.TableContainer>
                    </Index.Box>

                    <Index.Box className="pagination-design flex-end">
                        <Index.Stack spacing={2}>
                            <Index.Box className="pagination-count">
                                <Index.Box className="input-design-div with-border">
                                    <Index.Select
                                        fullWidth
                                        value={paginationPerPage}
                                        onChange={(e) => handleChangePaginationPerChange(e)}
                                    >
                                        <Index.MenuItem value={10}>10</Index.MenuItem>
                                        <Index.MenuItem value={25}>25</Index.MenuItem>
                                        <Index.MenuItem value={50}>50</Index.MenuItem>
                                        <Index.MenuItem value={100}>100</Index.MenuItem>
                                    </Index.Select>
                                </Index.Box>
                                {loading ? (
                                    <Index.CircularProgress
                                        color="secondary"
                                        sx={{ marginLeft: "10px" }}
                                        size={26}
                                    />
                                ) : (
                                    <>
                                        <Index.Pagination
                                            count={Math.ceil(totalRecordCount / paginationPerPage)}
                                            page={currentPage}
                                            onChange={handlePageChange}
                                        />
                                    </>
                                )}
                            </Index.Box>
                        </Index.Stack>
                    </Index.Box>
                </Index.Box>
            </Index.Box>
        </>
    )
}

export default BankHistory