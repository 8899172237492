import { useEffect, useState } from "react";
import Index from "../../../../Index";
import { getDocumentsHistory } from "../../../../../redux/admin/action";

function DocumentsHistory() {
  const dispatch = Index.useDispatch();
  const navigate = Index.useNavigate();
  const params = Index.useParams();

  let { state } = Index.useLocation();

  const [loading, setLoading] = useState(false);
  const [documentHistoryList, setDocumentHistoryList] = useState([]);

  const handleGetAllHistoryDocumentList = () => {
    setLoading(true);

    dispatch(getDocumentsHistory(params?.id))
      .then((res) => {
        if (res.status === 200) {
          setDocumentHistoryList(res.data);
          setLoading(false);
        } else {
          setDocumentHistoryList([]);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error(error);
        setDocumentHistoryList([]);
        setLoading(false);
      });
  };

  // when the page first time load then used...
  useEffect(() => {
    handleGetAllHistoryDocumentList();
  }, []);

  const backBtn = () => {
    navigate(`/admin/craft/view/${state?.craftId}`);
  };
  return (
    <>
      <Index.DynamicTitle title="Documents History" />
      <Index.Box className="page-content-box">
        <Index.Box className="barge-common-box">
          <Index.Box className="title-header">
            <Index.Box className="title-header-flex res-title-header-flex">
              <Index.Box className="title-main document-center-title">
                <Index.Typography
                  variant="p"
                  component="p"
                  className="page-title"
                >
                  Documents History
                </Index.Typography>
              </Index.Box>
              <Index.Box className="common-button blue-button save-btn ">
                <Index.Button
                  variant="contained"
                  type="submit"
                  className="Particular_model_submit ml-0"
                  onClick={backBtn}
                >
                  Back
                </Index.Button>
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Index.Box className="">
            <Index.TableContainer
              component={Index.Paper}
              className="table-container"
            >
              <Index.Table
                aria-label="simple table"
                className="table-design-main barge-table"
              >
                <Index.TableHead>
                  <Index.TableRow>
                    <Index.TableCell>S.No</Index.TableCell>
                    <Index.TableCell>Image/PDF</Index.TableCell>
                    <Index.TableCell>Document Title</Index.TableCell>
                    <Index.TableCell align="left">Expiry Date</Index.TableCell>
                    <Index.TableCell align="left">Created Date</Index.TableCell>
                    <Index.TableCell align="left">
                      Do Not Have Expiry Date
                    </Index.TableCell>
                    <Index.TableCell align="left">Timestamp</Index.TableCell>
                    <Index.TableCell align="left">Updated By</Index.TableCell>
                  </Index.TableRow>
                </Index.TableHead>
                {loading ? (
                  <Index.TableBody>
                    <Index.TableRow>
                      <Index.TableCell
                        colSpan={12}
                        align="center"
                        className="loading-purchase"
                      >
                        <Index.CircularProgress color="secondary" size={20} />
                      </Index.TableCell>
                    </Index.TableRow>
                  </Index.TableBody>
                ) : (
                  <Index.TableBody className="tanker-table-body">
                    {documentHistoryList?.length ? (
                      documentHistoryList?.map((item, index) => (
                        <Index.TableRow key={item?._id + (index + 1)}>
                          <Index.TableCell>
                            <Index.Typography>{index + 1}</Index.Typography>
                          </Index.TableCell>
                          <Index.TableCell>
                            {item?.craftDocuments?.map((documentData) => (
                              <li className="table-li">
                                <Index.Button
                                  onClick={() => {
                                    window.open(
                                      `${process.env.REACT_APP_IMAGE_URL}/craft/${documentData}`
                                    );
                                  }}
                                >
                                  {documentData
                                    ?.split("-")
                                    ?.slice(1)
                                    ?.join("_")}
                                </Index.Button>
                              </li>
                            ))}
                          </Index.TableCell>
                          <Index.TableCell align="left">
                            <Index.Typography>{item?.title}</Index.Typography>
                          </Index.TableCell>

                          <Index.TableCell>
                            <Index.Typography>
                              {item?.expireDate
                                ? Index.moment(item?.expireDate).format(
                                    "DD/MM/YYYY"
                                  )
                                : "-"}
                            </Index.Typography>
                          </Index.TableCell>

                          <Index.TableCell align="left">
                            <Index.Typography>
                              {Index.moment(item?.createdAt).format(
                                "DD/MM/YYYY"
                              )}
                            </Index.Typography>
                          </Index.TableCell>
                          <Index.TableCell>
                            <Index.Typography>
                              {item?.expireCheckBox ? "Yes" : "No"}
                            </Index.Typography>
                          </Index.TableCell>
                          <Index.TableCell align="left">
                            <Index.Typography>
                              {Index.moment(item?.createdAt).format(
                                "DD/MM/YYYY hh:mm:ss"
                              )}
                            </Index.Typography>
                          </Index.TableCell>
                          <Index.TableCell align="left">
                            <Index.Typography>
                              {item?.updatedBy?.firstName +
                                " " +
                                item?.updatedBy?.lastName}
                            </Index.Typography>
                          </Index.TableCell>
                        </Index.TableRow>
                      ))
                    ) : (
                      <Index.TableRow>
                        <Index.TableCell
                          component="td"
                          variant="td"
                          scope="row"
                          className="no-data-in-list"
                          colSpan={12}
                          align="center"
                        >
                          No data available
                        </Index.TableCell>
                      </Index.TableRow>
                    )}
                  </Index.TableBody>
                )}
              </Index.Table>
            </Index.TableContainer>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
}

export default DocumentsHistory;
