import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useLocation, useNavigate } from "react-router-dom";
import Invoice from "../Invoice";
import CreditNotInvoice from "../CreditNotInvoice";
import InvoiceBunker from "./InvoiceBunker";
import InvoiceTugs from "./InvoiceTugs";
import InvoiceWaterSupply from "./InvoiceWaterSupply";
import InvoiceSlopAndSlug from "./InvoiceSlopAndSlug";
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box className="tab-comman-padding">
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function InvoiceSectionTab() {
  let { state } = useLocation();
  const navigate = useNavigate();

  const [value, setValue] = useState(state?.value ? state?.value : 0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          className="dashboard-tab"
        >
          <Tab label="Bunker orders" {...a11yProps(0)} />
          <Tab label="Tugs Orders" {...a11yProps(1)} />
          <Tab label="Water Supply Orders " {...a11yProps(2)} />
          <Tab label="Slope & Sludge Orders " {...a11yProps(3)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <InvoiceBunker />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <InvoiceTugs />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <InvoiceWaterSupply />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <InvoiceSlopAndSlug />
      </TabPanel>
    </Box>
  );
}

export default InvoiceSectionTab;
