import React, { useState } from "react";
import Index from "../../../Index";
import { Formik } from "formik";
import { addDirectory } from "../../../../redux/admin/action";
import { directoryData } from "../../../../config/Directory";
import { directoryValidationSchema } from "../../../../validation/AdminValidation";

function DirectoryAdd() {
  const dispatch = Index.useDispatch();
  const navigate = Index.useNavigate();
  const { state } = Index.useLocation();
  const [loading, setLoading] = useState(false);
  let editData = state?.item;

  // edit data
  if (editData) {
    editData.id = editData?._id;
  }

  let initialValues = {};
  if (editData) {
    initialValues = editData;
  } else {
    initialValues = {
      name: "",
      contactNumber: "",
      supervisor: "",
      department: "",
    };
  }

  const handleFormSubmit = async (values) => {
    console.log("form submit time values", values);
    setLoading(true);
    const data = new URLSearchParams();
    if (values?.name) {
      data.append("name", values.name);
    }
    if (values?.contactNumber) {
      data.append("contactNumber", values.contactNumber);
    }
    if (values?.supervisor) {
      data.append("supervisor", values.supervisor);
    }
    // if (values?.department) {
    //   data.append("department", values.department);
    // }
    if (editData) {
      data.append("id", editData?._id);
    }
    data.forEach((obj, key) => {
      console.log(obj, ":::::::::", key);
    });
    dispatch(addDirectory(data, setLoading)).then((res) => {
      if (res?.status === 200 || res?.status === 201) {
        BackBtn();
      }
    });
  };

  const BackBtn = () => {
    navigate("/admin/directory");
  };

  return (
    <>
      <Index.Box className="">
        <Index.Box className="barge-common-box">
          <Index.Box className="title-header">
            <Index.Box className="title-header-flex">
              <Index.Box className="title-main mb-10">
                <Index.Typography
                  variant="p"
                  component="p"
                  className="page-title"
                >
                  {editData ? "Edit" : "Add"} Directory
                </Index.Typography>
              </Index.Box>
              <Index.Box className="common-button blue-button save-btn ">
                <Index.Button
                  variant="contained"
                  type="submit"
                  className="Particular_model_submit ml-0"
                  onClick={BackBtn}
                >
                  Back
                </Index.Button>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={directoryValidationSchema}
            onSubmit={handleFormSubmit}
          >
            {({
              values,
              errors,
              touched,
              setFieldValue,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <form onSubmit={handleSubmit}>
                <Index.Box className="">
                  <Index.Grid container spacing={2}>
                    <Index.Grid item xs={12} sm={6} md={3}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Name
                      </Index.Typography>
                      <Index.Box className="input-design-div with-border">
                        <Index.TextField
                          hiddenLabel
                          id="filled-hidden-label-normal"
                          placeholder="Name"
                          variant="filled"
                          className="admin-input-design input-placeholder"
                          name="name"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values?.name}
                          error={Boolean(
                            errors.name ? touched.name : undefined
                          )}
                          helperText={touched.name ? errors.name : undefined}
                        />
                      </Index.Box>
                    </Index.Grid>

                    <Index.Grid item xs={12} sm={6} md={3}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Contact No
                      </Index.Typography>
                      <Index.Box className="input-design-div with-border">
                        <Index.TextField
                          fullWidth
                          id="filled-hidden-label-normal"
                          type="number"
                          variant="filled"
                          placeholder="Contact no"
                          className="admin-input-design input-placeholder"
                          name="contactNumber"
                          onBlur={handleBlur}
                          value={values.contactNumber}
                          onChange={(e) => {
                            if (e.target.value.toString().length <= 10) {
                              setFieldValue("contactNumber", e.target.value);
                            }
                          }}
                          startAdornment={
                            <Index.InputAdornment position="start"></Index.InputAdornment>
                          }
                          error={Boolean(
                            errors.contactNumber
                              ? touched.contactNumber
                              : undefined
                          )}
                          helperText={
                            touched.contactNumber
                              ? errors.contactNumber
                              : undefined
                          }
                        />
                      </Index.Box>
                    </Index.Grid>

                    <Index.Grid item xs={12} sm={6} md={3}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Role
                      </Index.Typography>
                      <Index.Box className="input-design-div with-border">
                        <Index.Select
                          className="placeholder-set-text-box"
                          fullWidth
                          defaultValue={
                            values?.supervisor ? values?.supervisor : ""
                          }
                          displayEmpty
                          inputProps={{ "aria-label": "Without label" }}
                          onChange={(e) => {
                            setFieldValue("supervisor", e.target.value);
                          }}
                        >
                          <Index.MenuItem value={""}>
                            <Index.MenuItem
                              disabled
                              className="all-select-label"
                            >
                              Select
                            </Index.MenuItem>
                          </Index.MenuItem>
                          {directoryData?.map((items, index) => (
                            <Index.MenuItem value={items?.name} key={items?.id}>
                              {items?.name}
                            </Index.MenuItem>
                          ))}
                        </Index.Select>
                      </Index.Box>
                      {touched.supervisor && errors.supervisor && (
                        <Index.FormHelperText error>
                          {errors.supervisor}
                        </Index.FormHelperText>
                      )}
                    </Index.Grid>
                    {console.log("values", values)}

                    {/* <Index.Grid item xs={12} sm={6} md={3}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Department
                      </Index.Typography>
                      <Index.Box className="input-design-div with-border">
                        <Index.TextField
                          hiddenLabel
                          id="filled-hidden-label-normal"
                          placeholder="Department"
                          variant="filled"
                          className="admin-input-design input-placeholder"
                          name="department"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values?.department}
                          error={Boolean(
                            errors.department ? touched.department : undefined
                          )}
                          helperText={
                            touched.department ? errors.department : undefined
                          }
                        />
                      </Index.Box>
                    </Index.Grid> */}
                  </Index.Grid>
                </Index.Box>
                <Index.Box className="common-button blue-button flex-end save-btn user-submit-btn">
                  <Index.Button
                    variant="contained"
                    type="submit"
                    disabled={loading}
                  >
                    {editData ? "Update" : "Submit"}
                  </Index.Button>
                </Index.Box>
              </form>
            )}
          </Formik>
        </Index.Box>
      </Index.Box>
    </>
  );
}

export default DirectoryAdd;
