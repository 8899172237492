import { useEffect, useState } from "react";
import Index from "../../../../Index";
import { Formik } from "formik";
import { uploadCraftPhotoValidationSchema } from "../../../../../validation/AdminValidation";
import {
  addEditCraftPhoto,
  deleteCraftPhoto,
  getAllCraftPhotoList,
} from "../../../../../redux/admin/action";

// style for model
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function CraftPhotos({ craftId }) {
  const dispatch = Index.useDispatch();
  const [craftPhotosList, setCraftPhotosList] = useState([]);
  const [openPhotosModel, setOpenPhotosModel] = useState(false);
  const [uploadPhotosName, setUploadPhotosName] = useState("");
  const [openImage, setOpenImage] = useState(false);
  const [photosImage, setPhotosImg] = useState("");
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deletePhotoId, setDeletePhotoId] = useState("");
  const [editPhotoData, setEditPhotoData] = useState();

  // State for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState(0);
  const [paginationPerPage, setPaginationPerPage] = useState(50);
  const [currentRows, setCurrentRows] = useState([]);

  // Delete craft photo
  const handleDeleteOpen = () => {
    setDeleteOpen(true);
  };

  const handleDeleteClose = () => {
    setDeletePhotoId("");
    setDeleteOpen(false);
  };

  //Add document model
  const handleModelOpen = () => {
    setOpenPhotosModel(true);
  };
  const handleModelClose = () => {
    setOpenPhotosModel(false);
    setEditPhotoData();
  };

  //show image model
  const handleOpenImage = (img) => {
    setOpenImage(true);
    setPhotosImg(img);
  };
  const handleCloseImage = () => setOpenImage(false);

  let initialValues;
  if (editPhotoData) {
    initialValues = editPhotoData;
  } else {
    initialValues = {
      title: "",
      craftPhotos: [],
    };
  }
  const handleFormSubmit = (values) => {
    const formData = new FormData();
    if (editPhotoData) {
      formData.append("id", editPhotoData?.id);
    }
    formData.append("craftId", craftId);
    formData.append("title", values?.title);
    if (!editPhotoData) {
      values?.craftPhotos?.forEach((ele) => {
        formData.append("craftPhotos", ele);
      });
    } else {
      values?.craftPhotos?.forEach((ele) => {
        if (ele instanceof Blob) {
          formData.append("craftPhotos", ele);
        }
      });
    }

    dispatch(addEditCraftPhoto(formData)).then((res) => {
      if (res?.status === 200) {
        fetchAllPhotosList(craftId);
      }
    });
    handleModelClose();
  };

  //handle delete photo
  const handleDeleteRecord = () => {
    const urlencoded = new URLSearchParams();
    urlencoded.append("id", deletePhotoId);
    try {
      dispatch(deleteCraftPhoto(urlencoded)).then((res) => {
        if (res?.status === 200) {
          fetchAllPhotosList(craftId);
          setDeletePhotoId("");
        }
      });
    } catch (error) {}

    handleDeleteClose();
  };

  //get all photo list
  const fetchAllPhotosList = (id) => {
    setLoading(true);
    const urlencoded = new URLSearchParams();
    urlencoded.append("craftId", id);
    try {
      dispatch(getAllCraftPhotoList(urlencoded)).then((res) => {
        if (res?.status === 200) {
          setCraftPhotosList(res?.data);
          setTimeout(() => {
            setLoading(false);
          }, 1000);
        } else {
          setCraftPhotosList([]);
          setTimeout(() => {
            setLoading(false);
          }, 1000);
        }
      });
    } catch (error) {}
  };

  // Pagination calculation start

  const PER_PAGE = paginationPerPage;
  useEffect(() => {
    //Page count
    const count = Math.ceil(craftPhotosList?.length / PER_PAGE);
    setCount(count);
    const indexOfLastRow = currentPage * PER_PAGE;
    const indexOfFirstRow = indexOfLastRow - PER_PAGE;
    const modifiedRows = craftPhotosList?.slice(
      indexOfFirstRow,
      indexOfLastRow
    );
    const serialNumber = (currentPage - 1) * PER_PAGE + 1;
    // setCurrentRows(modifiedRows);
    setCurrentRows(
      modifiedRows &&
        modifiedRows.map((ele, index) => {
          return { ...ele, sNo: serialNumber + index };
        })
    );
    if (modifiedRows?.length === 0 && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  }, [currentPage, craftPhotosList, paginationPerPage, PER_PAGE]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  // Pagination calculation end

  useEffect(() => {
    fetchAllPhotosList(craftId);
  }, []);
  return (
    <>
      <Index.Box className="barge-common-box">
        <Index.Box className="title-header">
          <Index.Box className="title-header-flex res-title-header-flex">
            <Index.Box className="title-main">
              <Index.Typography
                variant="p"
                component="p"
                className="page-title"
              >
                {/* Document */}
              </Index.Typography>
            </Index.Box>
            <Index.Box className="common-button blue-button res-blue-button">
              <Index.Button
                variant="outlined"
                onClick={() => {
                  handleModelOpen();
                }}
                className="view-invoice-btn"
              >
                Upload Photo
              </Index.Button>
            </Index.Box>
          </Index.Box>
        </Index.Box>

        <Index.Box className="">
          <Index.TableContainer
            component={Index.Paper}
            className="table-container"
          >
            <Index.Table
              aria-label="simple table"
              className="table-design-main barge-table"
            >
              <Index.TableHead>
                <Index.TableRow>
                  <Index.TableCell>S.No</Index.TableCell>
                  <Index.TableCell>Image/PDF</Index.TableCell>
                  <Index.TableCell>Photo Title</Index.TableCell>
                  <Index.TableCell align="left">Created Date</Index.TableCell>
                  <Index.TableCell align="left">Action</Index.TableCell>
                </Index.TableRow>
              </Index.TableHead>
              {loading ? (
                <Index.TableBody>
                  <Index.TableRow>
                    <Index.TableCell
                      colSpan={12}
                      align="center"
                      className="loading-purchase"
                    >
                      <Index.CircularProgress color="secondary" size={20} />
                    </Index.TableCell>
                  </Index.TableRow>
                </Index.TableBody>
              ) : (
                <Index.TableBody>
                  {currentRows?.length ? (
                    currentRows?.map((item, index) => {
                      const fileExtension = item?.craftPhotos[0]
                        ?.split(".")
                        .pop()
                        .toLowerCase();
                      return (
                        <Index.TableRow key={item?._id}>
                          <Index.TableCell>
                            <Index.Typography>{item?.sNo}</Index.Typography>
                          </Index.TableCell>
                          <Index.TableCell>
                            <Index.Box>
                              {fileExtension === "jpg" ||
                              fileExtension === "jpeg" ||
                              fileExtension === "png" ? (
                                <Index.Button
                                  onClick={() => {
                                    handleOpenImage(item?.craftPhotos?.[0]);
                                  }}
                                >
                                  <Index.Box className="user-main-img user-img-open-align">
                                    <img
                                      src={`${
                                        process.env.REACT_APP_IMAGE_URL
                                      }/craft/${
                                        item?.craftPhotos?.[0]
                                          ? item?.craftPhotos?.[0]
                                          : ""
                                      }`}
                                      alt=""
                                      className="challan-img-model"
                                    />
                                  </Index.Box>
                                </Index.Button>
                              ) : (
                                <Index.Button
                                  onClick={() => {
                                    window.open(
                                      `${process.env.REACT_APP_IMAGE_URL}/craft/${item?.craftPhotos?.[0]}`
                                    );
                                  }}
                                >
                                  PDF
                                </Index.Button>
                              )}
                            </Index.Box>
                          </Index.TableCell>
                          <Index.TableCell>
                            <Index.Typography>{item?.title}</Index.Typography>
                          </Index.TableCell>

                          <Index.TableCell>
                            <Index.Typography>
                              {Index.moment(item?.createdAt).format(
                                "DD/MM/YYYY"
                              )}
                            </Index.Typography>
                          </Index.TableCell>

                          <Index.TableCell>
                            <Index.IconButton>
                              <Index.EditIcon
                                className="edit-icon-set"
                                onClick={() => {
                                  handleModelOpen();
                                  const editData = {
                                    id: item?._id,
                                    title: item?.title,
                                    craftPhotos: item?.craftPhotos,
                                  };
                                  setEditPhotoData(editData);
                                }}
                              />
                            </Index.IconButton>
                            <Index.IconButton>
                              <Index.DeleteIcon
                                color="error"
                                onClick={() => {
                                  setDeletePhotoId(item?._id);
                                  handleDeleteOpen();
                                }}
                              />
                            </Index.IconButton>
                          </Index.TableCell>
                        </Index.TableRow>
                      );
                    })
                  ) : (
                    <Index.TableRow>
                      <Index.TableCell
                        component="td"
                        variant="td"
                        scope="row"
                        className="no-data-in-list"
                        colSpan={12}
                        align="center"
                      >
                        No data available
                      </Index.TableCell>
                    </Index.TableRow>
                  )}
                </Index.TableBody>
              )}
            </Index.Table>
          </Index.TableContainer>
        </Index.Box>
        <Index.Box className="pagination-design flex-end">
          <Index.Stack spacing={2}>
            <Index.Box className="pagination-count">
              <Index.Box className="input-design-div with-border">
                <Index.Select
                  fullWidth
                  value={paginationPerPage}
                  onChange={(e) => {
                    setPaginationPerPage(e.target.value);
                  }}
                >
                  <Index.MenuItem value={10}>10</Index.MenuItem>
                  <Index.MenuItem value={25}>25</Index.MenuItem>
                  <Index.MenuItem value={50}>50</Index.MenuItem>
                  <Index.MenuItem value={100}>100</Index.MenuItem>
                </Index.Select>
              </Index.Box>
              <Index.Pagination
                count={count}
                page={currentPage}
                onChange={handlePageChange}
              />
            </Index.Box>
          </Index.Stack>
        </Index.Box>
      </Index.Box>

      {/* Show Image */}
      <Index.Modal
        open={openImage}
        onClose={handleCloseImage}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Index.Box sx={style}>
          <Index.ClearIcon
            className="model-close-icon"
            onClick={handleCloseImage}
          />
          <Index.Typography id="modal-modal-title" variant="h6" component="h2">
            <Index.Box>
              <img
                src={`${process.env.REACT_APP_IMAGE_URL}/craft/${photosImage}`}
                alt=""
                className="challan-img"
              />
            </Index.Box>
          </Index.Typography>
        </Index.Box>
      </Index.Modal>

      <Index.DeleteModal
        deleteOpen={deleteOpen}
        handleDeleteRecord={handleDeleteRecord}
        handleDeleteClose={handleDeleteClose}
      />
      {/* Craft Photo Upload */}
      <Index.Dialog
        open={openPhotosModel}
        onClose={handleModelClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Index.DialogContent className="expense-model-main  expense-model-main-box-set">
          <Index.Box className="title-main">
            <Formik
              enableReinitialize
              initialValues={initialValues}
              validationSchema={uploadCraftPhotoValidationSchema}
              onSubmit={handleFormSubmit}
            >
              {({
                values,
                errors,
                touched,
                setFieldValue,
                handleChange,
                handleSubmit,
                handleBlur,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Index.Box className="title-main">
                  <Index.Box className="jus-between">
                    
                    <Index.Typography
                      variant="h6"
                      component="h6"
                      className="input-label input-label-bold"
                      sx={{ marginBottom: "15px" }}
                    >
                      Photo Upload
                    </Index.Typography>
                    <Index.ClearIcon
                      className="model-close-icon"
                      onClick={handleModelClose}
                    />
                    </Index.Box>
                    <Index.Grid container spacing={2}>
                      <Index.Grid item xs={12} sm={6} md={12}>
                        <Index.Typography
                          variant="label"
                          component="label"
                          className="input-label"
                        >
                          Photo Title
                        </Index.Typography>
                        <Index.Box className="input-design-div with-border">
                          <Index.TextField
                            hiddenLabel
                            id="filled-hidden-label-normal"
                            placeholder="Enter Photo Title"
                            variant="filled"
                            className="admin-input-design input-placeholder"
                            name="title"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values?.title}
                          />
                          {touched?.title && errors?.title && (
                            <Index.FormHelperText error>
                              {errors?.title}
                            </Index.FormHelperText>
                          )}
                        </Index.Box>
                      </Index.Grid>

                      <Index.Grid item xs={12} sm={6} md={12} mt={2}>
                        <Index.Typography
                          variant="label"
                          component="label"
                          className="input-label"
                        >
                          Photo Upload
                        </Index.Typography>
                        <Index.Box className="input-design-div with-border position-relative">
                          <Index.Box className="pdf-design">
                            Upload File
                          </Index.Box>
                          <Index.TextField
                            fullWidth
                            type="file"
                            name="craftPhotos"
                            multiple
                            id="filled-hidden-label-normal"
                            variant="filled"
                            className="admin-input-design input-placeholder pdf-upload-design"
                            onChange={(event) => {
                              const files = event.target.files;
                              const existingBlobFiles =
                                values?.craftPhotos?.filter(
                                  (file) => file instanceof Blob
                                );

                              setFieldValue("craftPhotos", [
                                ...existingBlobFiles,
                                ...files,
                              ]);
                            }}
                            onBlur={handleBlur}
                            inputProps={{
                              multiple: true,
                            }}
                            FormHelperTextProps={{
                              className: "input_label_props",
                            }}
                          />
                          {touched?.craftPhotos &&
                            errors?.craftPhotos &&
                            errors?.craftPhotos == "Please select photo" && (
                              <Index.FormHelperText error>
                                {errors?.craftPhotos}
                              </Index.FormHelperText>
                            )}
                        </Index.Box>
                      </Index.Grid>

                      <Index.Grid item xs={12} sm={6} md={12}>
                        <Index.Box className="select-view-document-name">
                          {values?.craftPhotos?.map((file, index) => (
                            <Index.Box key={index}>
                              <Index.Box className="document-name" key={index}>
                                <Index.Typography
                                  style={{
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    window.open(
                                      file instanceof Blob
                                        ? URL.createObjectURL(file)
                                        : `${process.env.REACT_APP_IMAGE_URL}/craft/${file}`
                                    );
                                  }}
                                >
                                  {file instanceof Blob ? file.name : file}
                                </Index.Typography>
                                {/* <Index.IconButton
                                  onClick={() => {
                                    const updatedDocuments =
                                      values.craftPhotos.filter(
                                        (_, i) => i !== index
                                      );
                                    setFieldValue(
                                      "craftPhotos",
                                      updatedDocuments
                                    );
                                  }}
                                >
                                  <Index.ClearIcon className="muiico-cancel" />
                                </Index.IconButton> */}
                              </Index.Box>
                              {errors?.craftPhotos?.length > 0 &&
                                errors?.craftPhotos?.[index] && (
                                  <Index.FormHelperText error>
                                    {errors?.craftPhotos?.[index]}
                                  </Index.FormHelperText>
                                )}
                            </Index.Box>
                          ))}
                        </Index.Box>
                      </Index.Grid>

                      <Index.Grid item xs={12} sm={6} md={12}>
                        <Index.Box
                          className="common-button blue-button flex-end save-btn"
                          sx={{
                            display: "flex",
                            alignItems: "flex-end",
                          }}
                        >
                          <Index.Button
                            variant="contained"
                            type="submit"
                            className="Particular_model_submit"
                          >
                            Submit
                          </Index.Button>
                        </Index.Box>
                      </Index.Grid>

                      <Index.Grid item xs={12} sm={12} md={12}>
                        <Index.Typography>
                          {uploadPhotosName && (
                            <Index.Typography
                              variant="label"
                              component="label"
                              className="input-label"
                            >
                              {uploadPhotosName}
                            </Index.Typography>
                          )}
                        </Index.Typography>
                      </Index.Grid>
                    </Index.Grid>

                    {/* <Index.Box className="line" mt={3} mb={3}></Index.Box> */}
                  </Index.Box>
                </form>
              )}
            </Formik>
          </Index.Box>
        </Index.DialogContent>
      </Index.Dialog>
    </>
  );
}

export default CraftPhotos;
