import React, { useEffect, useState } from "react";
import Index from "../../../../Index";
import {
  addChallanEditReqStatus,
  addChallanISEditable,
  addChallanStatus,
  getChallanList,
  getInvoiceActivityLog,
  getSingleChallanEditRequest,
  getLoadingOrderTankerDetailsRequest,
  cancelOrder,
  getSingleTugOrder,
  getTugChallanList,
  addTugChallanStatus,
  getSingleTugChallanEditRequest,
  addTugChallanEditReqStatus,
  getSingleTugChallanTugTiming,
  cancelSingleTugOrder,
  getSingleWaterSupplyOrder,
  cancelSingleWaterSupplyOrder,
} from "../../../../../redux/admin/action";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useLocation } from "react-router-dom";
import OrderStatusModel from "../../../../../component/common/OrderStatusModel";
import TankerShiftModel from "../../orderadd/TankerShiftModel";
import WaterSupplyOrderDetail from "./WaterSupplyOrderDetail";
import WaterSupplyChallanList from "./WaterSupplyChallanList";
import WaterSupplyChallanEditRequest from "./WaterSupplyChallanEditRequest";
import WaterSupplyActivityLog from "./WaterSupplyActivityLog";
import WaterSupplyTiming from "./WaterSupplyTiming";

// Design Tab
function SingleOrderTab(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Index.Box sx={{ p: 3 }}>
          <Index.Typography>{children}</Index.Typography>
        </Index.Box>
      )}
    </div>
  );
}

SingleOrderTab.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function SingleWaterSupplyTab() {
  const navigate = Index.useNavigate();
  const dispatch = Index.useDispatch();
  const { id } = Index.useParams();
  const { state, pathname } = useLocation();
  const tabType = state?.type;
console.log(state,"singleSupply")
  // state
  const [viewData, setViewData] = useState({});
  const [challanData, setChallanData] = useState([]);
  const [value, setValue] = useState(state?.value ? state?.value : 0);
  const [challanRequestData, setChallanRequestData] = useState([]);

  const [isShifted, setIsShifted] = useState(false);
  const [cancelOpen, setCancelOpen] = useState(false);
  const [status, setStatus] = useState(true);

  const [tankerOpen, setTankerOpen] = useState(false);
  const [loadingSingleData, setLoadingSingleData] = useState(false);

  // state for checkbox
  const [tankerCheckId, setTankerCheckId] = useState([]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // let singleData = state?.row;
  const backBtn = () => {
    let tabValue = 0;
    if (tabType === "cancelOrder") {
      tabValue = 1;
    } else if (tabType === "completedOrder") {
      tabValue = 2;
    }
    navigate("/admin/waterSupply", { state: { value: tabValue } });
  };

  const handleEdit = (row) => {
    navigate("/admin/waterSupply/edit", {
      state: { row, type: tabType },
    });
  };

  const getSingleOrderList = () => {
    setLoadingSingleData(true);
    dispatch(getSingleWaterSupplyOrder({ id })).then((res) => {
      setViewData(res?.data);
      if (res?.status === 200) {
        getOrderLog();
        setTimeout(() => {
          setLoadingSingleData(false);
        }, 1000);
      } else {
        setTimeout(() => {
          setLoadingSingleData(false);
        }, 1000);
      }
    });
  };

  const getAllChallanList = () => {
    const data = new URLSearchParams();
    const tugOrderId = id;
    dispatch(getTugChallanList(tugOrderId)).then((res) => {
      setChallanData(res?.data);
      getOrderLog();
    });
  };

  // get challan list
  useEffect(() => {
    getAllChallanList();
    getSingleOrderList();
  }, [isShifted]);

  const getOrderLog = () => {
    const logData = {
      activityTypeId: id,
      type: "tugOrder",
    };
    dispatch(getInvoiceActivityLog(logData));
  };

  useEffect(() => {
    getOrderLog();
  }, []);

  // get single challan edit request
  const singleChallanEditRequest = () => {
    if (challanData?.length > 0) {
      const challanID = challanData?.[0]?._id;
      const id = challanID;
      dispatch(getSingleTugChallanEditRequest(id)).then((res) => {
        setChallanRequestData(res?.data);
      });
    }
  };
  useEffect(() => {
    if (challanData?.length > 0) {
      singleChallanEditRequest();
    }
  }, [challanData]);

  // cancel order popup
  const cancelOrderModelOpen = () => {
    // setAnchorEl(null);
    setCancelOpen(true);
  };

  const cancelOrderModelClose = () => {
    setCancelOpen(false);
    // setStatus(true);
  };

  // cancel Order button
  const cancelOrderBtn = (status, comment) => {
    const data = new URLSearchParams();
    data.append("id", id);
    data.append("cancelStatus", "Cancel");
    data.append("comment", comment);

    dispatch(cancelSingleWaterSupplyOrder(data)).then((res) => {
      if (res?.status === 200) {
        getSingleOrderList();
      }
    });
  };

  // model open for shift tanker
  const shiftTankerHandleOpen = (id) => {
    setTankerOpen(true);
  };
  const shiftTankerHandleClose = () => {
    setTankerOpen(false);
  };
  
  return (
    <>
      {loadingSingleData ? (
        <>
          <Index.Table>
            <Index.TableBody>
              <Index.TableRow>
                <Index.TableCell
                  colSpan={13}
                  align="center"
                  className="loading-purchase"
                >
                  <Index.CircularProgress color="secondary" size={20} />
                </Index.TableCell>
              </Index.TableRow>
            </Index.TableBody>
          </Index.Table>
        </>
      ) : (
        <>
          <Index.Box className="invoice-details">
            <Index.Box className="barge-common-box mb-20">
              <Index.Box className="title-header-flex flex-column-res">
                <Index.Box className="invoice-back-title">
                  <Index.Button
                    type="submit"
                    className="Particular_model_submit back-invoice-btn"
                    onClick={backBtn}
                  >
                    <Index.ArrowBackIcon />
                  </Index.Button>
                  <Index.Typography
                    variant="span"
                    component="span"
                    className="invoice-title"
                  >
                    {/* Tug Order Detail */}
                    {tabType === "cancelOrder"
                      ? "Water Supply Cancelled Order Details"
                      : tabType === "completedOrder"
                      ? "Water Supply Completed Order Details"
                      : "Water Supply Order Details"}
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="common-button blue-button save-btn res-button-width">
                  <Index.Button
                    variant="contained"
                    type="submit"
                    className="Particular_model_submit edit-invoice-btn"
                    onClick={() => handleEdit(viewData)}
                  >
                    <Index.EditIcon />
                  </Index.Button>
                  {viewData?.supplyOrderStatus !== "Cancel" ? (
                    <Index.Button
                      variant="contained"
                      disableRipple
                      onClick={(e) => {
                        cancelOrderModelOpen();
                      }}
                    >
                      Cancel Order
                    </Index.Button>
                  ) : (
                    ""
                  )}
                </Index.Box>
              </Index.Box>

              <Index.Box className="mt-10">
                <Index.Box className="mb-5 invoice-detail-item">
                  <Index.Box className="input-label opacity-60">
                    Name of Vessel:
                  </Index.Box>
                  <Index.Box className="input-label">
                    {viewData?.vesselName ? viewData?.vesselName : "-"}
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>

            <Index.Box className="mb-20">
              {/* Design Tab */}
              <Index.Box sx={{ width: "100%" }}>
                <Index.Box
                  className="tab-button-box"
                  sx={{ borderBottom: 1, borderColor: "divider" }}
                >
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    className="dashboard-tab"
                  >
                    <Tab
                      className="tab-button"
                      label="Details"
                      {...a11yProps(0)}
                    />

                    <Tab
                      className="tab-button"
                      label="Challan List"
                      {...a11yProps(1)}
                    />

                    <Tab
                      className="tab-button"
                      label="Water Supply Timing"
                      {...a11yProps(2)}
                    />

                    <Tab
                      className="tab-button"
                      label="Challan Edit Request"
                      {...a11yProps(3)}
                    />

                    <Tab
                      className="tab-button"
                      label="Activity"
                      {...a11yProps(4)}
                    />
                  </Tabs>
                </Index.Box>

                <SingleOrderTab
                  value={value}
                  index={0}
                  className="barge-common-box tab-content-box"
                >
                  <WaterSupplyOrderDetail viewData={viewData} />
                </SingleOrderTab>

                <SingleOrderTab
                  value={value}
                  index={1}
                  className="barge-common-box tab-content-box"
                >
                  <WaterSupplyChallanList id={id} />
                </SingleOrderTab>

                <SingleOrderTab
                  value={value}
                  index={2}
                  className="barge-common-box tab-content-box"
                >
                  <WaterSupplyTiming id={id} value={value} />
                </SingleOrderTab>

                <SingleOrderTab
                  value={value}
                  index={3}
                  className="barge-common-box tab-content-box"
                >
                  <WaterSupplyChallanEditRequest id={id} />
                </SingleOrderTab>

                <SingleOrderTab
                  value={value}
                  index={4}
                  className="barge-common-box tab-content-box"
                >
                  <WaterSupplyActivityLog id={id} />
                </SingleOrderTab>
              </Index.Box>
              {/* Design Tab */}
            </Index.Box>
          </Index.Box>
        </>
      )}
      <OrderStatusModel
        updateOpen={cancelOpen}
        handleClose={cancelOrderModelClose}
        inquiryStatusUpdate={cancelOrderBtn}
        modalType={status ? "Comment" : ""}
      />
      <TankerShiftModel
        shiftTankerHandleOpen={shiftTankerHandleOpen}
        shiftTankerHandleClose={shiftTankerHandleClose}
        tankerOpen={tankerOpen}
        setTankerOpen={setTankerOpen}
        orderId={id}
        status={viewData?.orderStatus}
        shiftTankerStatus={viewData?.tankerShifted}
        getSingleOrderList={getSingleOrderList}
        setIsShifted={setIsShifted}
        tankerCheckId={tankerCheckId}
        // setUpdateTanker={setUpdateTanker}
      />
    </>
  );
}

export default SingleWaterSupplyTab;
