import React, { useEffect, useCallback, useState } from "react";
import { getDashboardDocumentList } from "../../../../../redux/admin/action";
import moment from "moment";
import Index from "../../../../Index";
import { NEW_EXPORT_EXCEL } from "../../ExportAll";
import { useSearchParams } from "react-router-dom";

function DueDocumentList() {
  const dispatch = Index.useDispatch();
  const [searchParams] = useSearchParams();

  const [dueDocumentList, setDueDocumentList] = useState([]);
  const [loading, setLoading] = useState(false);

  // when the get the due documents list then used...
  const handleGetDueDocumentList = () => {
    setLoading(true);
    dispatch(getDashboardDocumentList(`craftId=${searchParams.get("bargeId")}`))
      .then((res) => {
        if (res.status === 200) {
          setDueDocumentList(res.data);
          setLoading(false);
        } else {
          setDueDocumentList([]);
          setLoading(false);
        }
      })
      .catch((error) => {
        setDueDocumentList([]);
        setLoading(false);
        console.error(error);
      });
  };

  // when the first time page load then used...
  useEffect(() => {
    handleGetDueDocumentList();
  }, []);

  return (
    <>
      <Index.DynamicTitle title="Due Document List" />
      <Index.Box className="page-content-box">
        <Index.Box className="barge-common-box">
          <Index.Box className="title-header">
            <Index.Box className="title-header-flex res-title-header-flex">
              <Index.Box className="title-main document-center-title">
                <Index.Typography
                  variant="p"
                  component="p"
                  className="page-title"
                >
                  Due Document List
                </Index.Typography>
              </Index.Box>
              <Index.Box className="d-flex align-items-center res-set-search ">
                <Index.Box className="common-button grey-button">
                  <Index.Button
                    variant="contained"
                    onClick={() => {
                      let curDate = Index.moment(new Date()).format(
                        "YYYY-MM-DD"
                      );
                      NEW_EXPORT_EXCEL(
                        "/admin/export-document-dashboard",
                        `Due_Document_${curDate}`,
                        {
                          craftId: searchParams.get("bargeId"),
                        }
                      );
                    }}
                  >
                    Export
                  </Index.Button>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Index.Box className="">
            <Index.TableContainer
              component={Index.Paper}
              className="table-container"
            >
              <Index.Table
                aria-label="simple table"
                className="table-design-main barge-table"
              >
                <Index.TableHead>
                  <Index.TableRow>
                    <Index.TableCell align="left">Days</Index.TableCell>
                    <Index.TableCell align="left">Barge / Tugs</Index.TableCell>
                    <Index.TableCell align="left">To be done</Index.TableCell>
                    <Index.TableCell align="left">Due Date</Index.TableCell>
                    <Index.TableCell align="left">Updated On</Index.TableCell>
                    <Index.TableCell align="left">Status</Index.TableCell>
                    <Index.TableCell align="left">Name</Index.TableCell>
                  </Index.TableRow>
                </Index.TableHead>
                {loading ? (
                  <Index.TableBody>
                    <Index.TableRow>
                      <Index.TableCell
                        colSpan={12}
                        align="center"
                        className="loading-purchase"
                      >
                        <Index.CircularProgress color="secondary" size={20} />
                      </Index.TableCell>
                    </Index.TableRow>
                  </Index.TableBody>
                ) : (
                  <Index.TableBody className="tanker-table-body">
                    {dueDocumentList?.length ? (
                      dueDocumentList?.map((row, index) => (
                        <Index.TableRow key={row?._id}>
                          <Index.TableCell>
                            {row?.differenceInDays
                              ? row?.differenceInDays
                              : "-"}
                          </Index.TableCell>
                          <Index.TableCell>
                            {/* craftId */}

                            {row?.craftId?.bargeName
                              ? row?.craftId?.bargeName
                              : "-"}
                          </Index.TableCell>
                          <Index.TableCell align="left">
                            {row?.title ? row?.title : "-"}
                          </Index.TableCell>
                          <Index.TableCell>
                            {row?.expireDate
                              ? moment(row?.expireDate).format("DD-MM-YYYY")
                              : "-"}
                          </Index.TableCell>
                          <Index.TableCell>
                            {row?.updatedOn
                              ? moment(row?.updatedOn).format("DD-MM-YYYY")
                              : "-"}
                          </Index.TableCell>

                          <Index.TableCell align="left">
                            {row?.status?.length ? row?.status : "-"}
                          </Index.TableCell>
                          <Index.TableCell>
                            {row?.addedBy
                              ? row.addedBy?.firstName +
                                " " +
                                row.addedBy?.lastName
                              : "-"}
                          </Index.TableCell>
                        </Index.TableRow>
                      ))
                    ) : (
                      <Index.TableRow>
                        <Index.TableCell
                          component="td"
                          variant="td"
                          scope="row"
                          className="no-data-in-list"
                          colSpan={12}
                          align="center"
                        >
                          No data available
                        </Index.TableCell>
                      </Index.TableRow>
                    )}
                  </Index.TableBody>
                )}
              </Index.Table>
            </Index.TableContainer>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
}

export default DueDocumentList;
