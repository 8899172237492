import { useState } from "react";
import { companyValidationSchema } from "../../../../validation/AdminValidation";
import Index from "../../../Index";
import { Formik } from "formik";
import { AdminAddCompany } from "../../../../redux/admin/action";

function AddCompanies() {
  const navigate = Index.useNavigate();
  const dispatch = Index.useDispatch();
  const { state } = Index.useLocation();

  let editData = state?.row;

  const [loading, setLoading] = useState(false);
  //   const editData = {};

  let initialValues;
  if (editData) {
    initialValues = editData;
  } else {
    initialValues = {
      fullName: "",
      tallyCompanyName: "",
      name: "",
    };
  }

  const BackBtn = () => {
    navigate("/admin/companies");
  };

  const handleFormSubmit = (values) => {
    setLoading(true);
    const data = new URLSearchParams();

    if (editData) {
      data.append("id", editData?._id);
    }
    data.append("fullName", values.fullName);
    data.append("tallyCompanyName", values.tallyCompanyName);
    data.append("name", values.name);

    dispatch(AdminAddCompany(data))
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          setLoading(false);
          navigate("/admin/companies");
        } else {
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  return (
    <>
      <Index.Box className="">
        <Index.Box className="barge-common-box">
          <Index.Box className="title-header">
            <Index.Box className="title-header-flex">
              <Index.Box className="title-main mb-10">
                <Index.Typography
                  variant="p"
                  component="p"
                  className="page-title"
                >
                  {editData ? "Edit" : "Add"} Company
                </Index.Typography>
              </Index.Box>
              <Index.Box className="common-button blue-button save-btn ">
                <Index.Button
                  variant="contained"
                  type="submit"
                  className="Particular_model_submit ml-0"
                  onClick={BackBtn}
                >
                  Back
                </Index.Button>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={companyValidationSchema}
            onSubmit={handleFormSubmit}
          >
            {({
              values,
              errors,
              touched,
              setFieldValue,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <form onSubmit={handleSubmit}>
                <Index.Box className="">
                  <Index.Grid container spacing={2}>
                    <Index.Grid item xs={12} sm={6} md={3}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Company
                      </Index.Typography>
                      <Index.Box className="input-design-div with-border">
                        <Index.TextField
                          hiddenLabel
                          id="filled-hidden-label-normal"
                          placeholder="Company"
                          variant="filled"
                          className="admin-input-design input-placeholder"
                          name="fullName"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values?.fullName}
                          error={Boolean(
                            errors.fullName ? touched.fullName : undefined
                          )}
                          helperText={
                            touched.fullName ? errors.fullName : undefined
                          }
                        />
                      </Index.Box>
                    </Index.Grid>
                    <Index.Grid item xs={12} sm={6} md={3}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Tally Company
                      </Index.Typography>
                      <Index.Box className="input-design-div with-border">
                        <Index.TextField
                          hiddenLabel
                          id="filled-hidden-label-normal"
                          placeholder="Tally Company"
                          variant="filled"
                          className="admin-input-design input-placeholder"
                          name="tallyCompanyName"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values?.tallyCompanyName}
                          error={Boolean(
                            errors.tallyCompanyName
                              ? touched.tallyCompanyName
                              : undefined
                          )}
                          helperText={
                            touched.tallyCompanyName
                              ? errors.tallyCompanyName
                              : undefined
                          }
                        />
                      </Index.Box>
                    </Index.Grid>
                    <Index.Grid item xs={12} sm={6} md={3}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Short Company Name
                      </Index.Typography>
                      <Index.Box className="input-design-div with-border">
                        <Index.TextField
                          hiddenLabel
                          id="filled-hidden-label-normal"
                          placeholder="Short Company Name"
                          variant="filled"
                          className="admin-input-design input-placeholder"
                          name="name"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values?.name}
                          error={Boolean(
                            errors.name ? touched.name : undefined
                          )}
                          helperText={touched.name ? errors.name : undefined}
                        />
                      </Index.Box>
                    </Index.Grid>
                  </Index.Grid>
                </Index.Box>
                <Index.Box className="common-button blue-button flex-end save-btn user-submit-btn">
                  <Index.Button
                    variant="contained"
                    type="submit"
                    disabled={loading}
                  >
                    {editData ? "Update" : "Submit"}
                  </Index.Button>
                </Index.Box>
              </form>
            )}
          </Formik>
        </Index.Box>
      </Index.Box>
    </>
  );
}
export default AddCompanies;
