import Index from "../../../Index";
import { FieldArray, Formik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import {
  addEditCraftMaintenance,
  getAllAssigneeUsersList,
  getAllMaintenanceList,
  addMaintenanceResolveRemark,
  getCraftBreakdownReacords,
  adminDispatchCraft,
  adminDirectRejectBreakDownRequest,
} from "../../../../redux/admin/action";
import { maintenanceResolveValidationSchema } from "../../../../validation/AdminValidation";
import { NEW_EXPORT_EXCEL } from "../ExportAll";
import { SingleInputDateRangeField } from "@mui/x-date-pickers-pro/SingleInputDateRangeField";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import dayjs from "dayjs";

const Search = Index.styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: Index.alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: Index.alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const StyledInputBase = Index.styled(Index.InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));
const shortcutsItems = [
  {
    label: "This Week",
    getValue: () => {
      const today = dayjs();
      return [today.startOf("week"), today.endOf("week")];
    },
  },
  {
    label: "Last Week",
    getValue: () => {
      const today = dayjs();
      const prevWeek = today.subtract(7, "day");
      return [prevWeek.startOf("week"), prevWeek.endOf("week")];
    },
  },
  {
    label: "Last 7 Days",
    getValue: () => {
      const today = dayjs();
      return [today.subtract(7, "day"), today];
    },
  },
  {
    label: "Current Month",
    getValue: () => {
      const today = dayjs();
      return [today.startOf("month"), today.endOf("month")];
    },
  },
  {
    label: "Next Month",
    getValue: () => {
      const today = dayjs();
      const startOfNextMonth = today.endOf("month").add(1, "day");
      return [startOfNextMonth, startOfNextMonth.endOf("month")];
    },
  },
  { label: "Reset", getValue: () => [null, null] },
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function MaintenanceList() {
  const dispatch = Index.useDispatch();
  const navigate = Index.useNavigate();
  const [loading, setLoading] = useState(false);
  const [openModel, setOpenModel] = useState(false);
  const [editMaintenanceData, setEditMaintenanceData] = useState();
  const [workingUserList, setWorkingUserList] = useState([]);
  const [openModelRemark, setOpenModelRemark] = useState(false);
  const [openModelResolve, setOpenModelResolve] = useState(false);
  const [maintenanceId, setMaintenanceId] = useState("");
  const [remarkData, setRemarkData] = useState();

  const [searchingText, setSearchingText] = useState("");
  const [totalRecordCount, setTotalRecordCount] = useState(0);
  const [allCraftBreakdownList, setAllCraftBreakdownList] = useState([]);
  const [serachedDateRange, setSearchedDateRange] = useState("");
  const [selectedJobType, setSelectedJobType] = useState("");

  const jobTypeList = [
    { id: 1, name: "Electrical" },
    { id: 2, name: "Mechanical" },
    { id: 3, name: "Fabrication" },
    { id: 4, name: "Procurement" },
  ];
  const handleView = (row) => {
    navigate(`/admin/maintenances/view/${row?._id}`, {
      state: { row },
    });
  };

  const initialValuesResolve = {
    resolveRemark: "",
  };

  // Mark as resolve
  const handleModelResolveOpen = () => {
    setOpenModelResolve(true);
  };
  const handleModelResolveClose = () => {
    setOpenModelResolve(false);
    setMaintenanceId("");
  };

  const handleAddMaintenanceResolveSubmit = (values) => {
    const newDate = Index.moment(new Date()).format("DD-MM-YYYY");
    const urlencoded = new URLSearchParams();
    urlencoded.append("maintenanceId", maintenanceId);
    urlencoded.append("resolveRemark", values?.resolveRemark);
    urlencoded.append("resolveDate", newDate);
    dispatch(addMaintenanceResolveRemark(urlencoded)).then((res) => {
      if (res?.status === 201 || res?.status === 200) {
        const values = {
          page: 1,
          search: "",
          size: paginationPerPage,
          to:
            serachedDateRange?.length > 0
              ? serachedDateRange[0] !== null
                ? serachedDateRange[0].format("YYYY-MM-DD")
                : ""
              : "",
          from:
            serachedDateRange?.length > 0
              ? serachedDateRange[1] !== null
                ? serachedDateRange[1].format("YYYY-MM-DD")
                : ""
              : "",
          jobType: selectedJobType,
        };
        getCraftBreakdownList(values);
        handleModelResolveClose();
      } else {
        handleModelResolveClose();
      }
    });
  };

  // Add edit maintenance Model
  const handleModelOpen = () => {
    setOpenModel(true);
  };
  const handleModelClose = () => {
    setOpenModel(false);
    setEditMaintenanceData();
  };

  //Remark text
  const handleModelRemarkOpen = () => {
    setOpenModelRemark(true);
  };
  const handleModelRemarkClose = () => {
    setOpenModelRemark(false);
    setRemarkData();
  };

  let initialValues;
  if (editMaintenanceData) {
    initialValues = {
      whatIsDone: editMaintenanceData ? editMaintenanceData?.whatIsDone : "",
      remark: editMaintenanceData ? editMaintenanceData?.remark : "",
      workingUser:
        editMaintenanceData && editMaintenanceData?.workingUser?.length > 0
          ? editMaintenanceData?.workingUser?.map((ele) => {
              return ele?._id;
            })
          : [],
      jobType: editMaintenanceData ? editMaintenanceData?.jobType : "",
      planedDate: editMaintenanceData ? editMaintenanceData?.planedDate : "",
      startDate: editMaintenanceData ? editMaintenanceData?.startDate : "",
      needToRepair: editMaintenanceData
        ? editMaintenanceData?.needToRepair?.length
          ? editMaintenanceData?.needToRepair
          : [{ point: "" }]
        : [{ point: "" }],
      needToSpare: editMaintenanceData
        ? editMaintenanceData?.needToSpare?.length
          ? editMaintenanceData?.needToSpare
          : [{ point: "" }]
        : [{ point: "" }],
      quantity: editMaintenanceData ? editMaintenanceData?.quantity : 0,
      unit: editMaintenanceData ? editMaintenanceData?.unit : 0,
    };
  } else {
    initialValues = {
      whatIsDone: "",
      remark: "",
      workingUser: [],
      jobType: "",
      planedDate: "",
      startDate: "",
      needToRepair: [{ point: "" }],
      needToSpare: [{ point: "" }],
      quantity: 0,
      unit: 0,
    };
  }

  const handleFormSubmit = (values) => {
    const urlencoded = new URLSearchParams();
    if (editMaintenanceData) {
      urlencoded.append("maintenanceId", editMaintenanceData?._id);
    }
    urlencoded.append("barge", editMaintenanceData?.barge);
    urlencoded.append("type", editMaintenanceData?.type);
    for (let keys in values) {
      if (
        keys !== "needToRepair" &&
        keys !== "needToSpare" &&
        keys !== "workingUser" &&
        values[keys]
      ) {
        urlencoded.append(keys, values[keys]);
      }
    }

    values?.workingUser?.forEach((ele, i) => {
      urlencoded.append(`workingUser`, ele);
    });
    values?.needToRepair?.forEach((ele, i) => {
      if (ele?.point) {
        urlencoded.append(`needToRepair[${i}][point]`, ele?.point);
      }
    });
    values?.needToSpare?.forEach((ele, i) => {
      if (ele?.point) {
        urlencoded.append(`needToSpare[${i}][point]`, ele?.point);
      }
    });
    dispatch(addEditCraftMaintenance(urlencoded)).then((res) => {
      if (res?.status == 200 || res?.status == 201) {
        const values = {
          page: 1,
          search: "",
          size: paginationPerPage,
          to:
            serachedDateRange?.length > 0
              ? serachedDateRange[0] !== null
                ? serachedDateRange[0].format("YYYY-MM-DD")
                : ""
              : "",
          from:
            serachedDateRange?.length > 0
              ? serachedDateRange[1] !== null
                ? serachedDateRange[1].format("YYYY-MM-DD")
                : ""
              : "",
          jobType: selectedJobType,
        };
        getCraftBreakdownList(values);
        setSearchingText("");
      }
    });
    handleModelClose();
  };
  // State for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [paginationPerPage, setPaginationPerPage] = useState(50);

  // Get User List
  const getUsers = () => {
    dispatch(getAllAssigneeUsersList()).then((res) => {
      if (res?.status === 200) {
        setWorkingUserList(res?.data);
      } else {
        setWorkingUserList([]);
      }
    });
  };

  const getCraftBreakdownList = (values) => {
    setLoading(true);
    // getTugsList
    dispatch(getCraftBreakdownReacords(values)).then((res) => {
      if (res?.status === 200) {
        setLoading(false);
        setTotalRecordCount(res?.totalCount);
        setCurrentPage(res?.currentPage);
        setAllCraftBreakdownList(res?.data);
      } else {
        setLoading(false);
        setTotalRecordCount(0);
        setCurrentPage(1);
        setAllCraftBreakdownList([]);
      }
    });
  };

  useEffect(() => {
    const values = {
      page: 1,
      search: "",
      size: paginationPerPage,
      to:
        serachedDateRange?.length > 0
          ? serachedDateRange[0] !== null
            ? serachedDateRange[0].format("YYYY-MM-DD")
            : ""
          : "",
      from:
        serachedDateRange?.length > 0
          ? serachedDateRange[1] !== null
            ? serachedDateRange[1].format("YYYY-MM-DD")
            : ""
          : "",
      jobType: selectedJobType,
    };
    getCraftBreakdownList(values);
    getUsers();
  }, []);

  const handlePageChange = React.useCallback(
    (event, value) => {
      setCurrentPage(value);
      const values = {
        page: value,
        search: searchingText,
        size: paginationPerPage,
        to:
          serachedDateRange?.length > 0
            ? serachedDateRange[0] !== null
              ? serachedDateRange[0].format("YYYY-MM-DD")
              : ""
            : "",
        from:
          serachedDateRange?.length > 0
            ? serachedDateRange[1] !== null
              ? serachedDateRange[1].format("YYYY-MM-DD")
              : ""
            : "",
        jobType: selectedJobType,
      };
      getCraftBreakdownList(values);
    },
    [
      searchingText,
      paginationPerPage,
      serachedDateRange,
      selectedJobType,
      setCurrentPage,
    ]
  );

  // when the user change the per page record change then used..
  const handleChangePaginationPerChange = (e) => {
    const { value } = e.target;
    setPaginationPerPage(value);
    const values = {
      page: 1,
      search: searchingText,
      size: value,
      to:
        serachedDateRange?.length > 0
          ? serachedDateRange[0] !== null
            ? serachedDateRange[0].format("YYYY-MM-DD")
            : ""
          : "",
      from:
        serachedDateRange?.length > 0
          ? serachedDateRange[1] !== null
            ? serachedDateRange[1].format("YYYY-MM-DD")
            : ""
          : "",
      jobType: selectedJobType,
    };
    getCraftBreakdownList(values);
  };

  // when the user search the journal records then used...
  const handleSearchCompletedListData = (e) => {
    const { value } = e.target;
    setSearchingText(value);
    if (value?.length > 0) {
      const values = {
        page: 1,
        search: value,
        size: paginationPerPage,
        to:
          serachedDateRange?.length > 0
            ? serachedDateRange[0] !== null
              ? serachedDateRange[0].format("YYYY-MM-DD")
              : ""
            : "",
        from:
          serachedDateRange?.length > 0
            ? serachedDateRange[1] !== null
              ? serachedDateRange[1].format("YYYY-MM-DD")
              : ""
            : "",
        jobType: selectedJobType,
      };
      getCraftBreakdownList(values);
    } else {
      const values = {
        page: 1,
        search: "",
        size: paginationPerPage,
        to:
          serachedDateRange?.length > 0
            ? serachedDateRange[0] !== null
              ? serachedDateRange[0].format("YYYY-MM-DD")
              : ""
            : "",
        from:
          serachedDateRange?.length > 0
            ? serachedDateRange[1] !== null
              ? serachedDateRange[1].format("YYYY-MM-DD")
              : ""
            : "",
        jobType: selectedJobType,
      };
      getCraftBreakdownList(values);
    }
  };

  const getOverDueDays = (NewDate) => {
    const startDate = Index.moment(new Date());
    var currentDate = Index.moment(NewDate);
    var days = startDate.diff(currentDate, "days");
    return days;
  };

  // when the user dispatch the craft then used....
  const handleDispatchCraft = (e, craftData) => {
    const values = {
      id: craftData?._id,
      dispatchDate: Date.now(),
    };
    dispatch(adminDispatchCraft(values))
      .then((res) => {
        if (res.status === 200) {
          const values = {
            page: 1,
            search: "",
            size: paginationPerPage,
            to:
              serachedDateRange?.length > 0
                ? serachedDateRange[0] !== null
                  ? serachedDateRange[0].format("YYYY-MM-DD")
                  : ""
                : "",
            from:
              serachedDateRange?.length > 0
                ? serachedDateRange[1] !== null
                  ? serachedDateRange[1].format("YYYY-MM-DD")
                  : ""
                : "",
            jobType: selectedJobType,
          };
          getCraftBreakdownList(values);
          searchingText("");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // when the use change the date then used...
  const handleChangeDate = React.useCallback(
    (data) => {
      setSearchedDateRange(data);
      if (data[0] !== null && data[1] !== null) {
        const values = {
          page: 1,
          search: searchingText,
          size: paginationPerPage,
          to: data[0] !== null ? data[0].format("YYYY-MM-DD") : "",
          from: data[1] !== null ? data[1].format("YYYY-MM-DD") : "",
          jobType: selectedJobType,
        };
        getCraftBreakdownList(values);
      } else {
        const values = {
          page: 1,
          search: searchingText,
          size: paginationPerPage,
          to:
            serachedDateRange?.length > 0
              ? serachedDateRange[0] !== null
                ? serachedDateRange[0].format("YYYY-MM-DD")
                : ""
              : "",
          from:
            serachedDateRange?.length > 0
              ? serachedDateRange[1] !== null
                ? serachedDateRange[1].format("YYYY-MM-DD")
                : ""
              : "",
          jobType: selectedJobType,
        };
        getCraftBreakdownList(values);
      }
    },
    [setSearchedDateRange, searchingText, selectedJobType, paginationPerPage]
  );

  // when the user filter job type wise searching then used...
  const handleChangeJobType = (value) => {
    setSelectedJobType(value);
    if (value?.length) {
      const values = {
        page: 1,
        search: searchingText,
        size: paginationPerPage,
        to:
          serachedDateRange?.length > 0
            ? serachedDateRange[0] !== null
              ? serachedDateRange[0].format("YYYY-MM-DD")
              : ""
            : "",
        from:
          serachedDateRange?.length > 0
            ? serachedDateRange[1] !== null
              ? serachedDateRange[1].format("YYYY-MM-DD")
              : ""
            : "",
        jobType: value,
      };
      getCraftBreakdownList(values);
    } else {
      const values = {
        page: 1,
        search: searchingText,
        size: paginationPerPage,
        to:
          serachedDateRange?.length > 0
            ? serachedDateRange[0] !== null
              ? serachedDateRange[0].format("YYYY-MM-DD")
              : ""
            : "",
        from:
          serachedDateRange?.length > 0
            ? serachedDateRange[1] !== null
              ? serachedDateRange[1].format("YYYY-MM-DD")
              : ""
            : "",
        jobType: "",
      };
      getCraftBreakdownList(values);
    }
  };

  // when the user reject the breakdown request then used...
  const handleRejectMaintenanceRequest = (e, craftData) => {
    dispatch(adminDirectRejectBreakDownRequest(craftData?._id))
      .then((res) => {
        if (res.status === 200) {
          const values = {
            page: 1,
            search: "",
            size: paginationPerPage,
            to:
              serachedDateRange?.length > 0
                ? serachedDateRange[0] !== null
                  ? serachedDateRange[0].format("YYYY-MM-DD")
                  : ""
                : "",
            from:
              serachedDateRange?.length > 0
                ? serachedDateRange[1] !== null
                  ? serachedDateRange[1].format("YYYY-MM-DD")
                  : ""
                : "",
            jobType: selectedJobType,
          };
          getCraftBreakdownList(values);
          setSearchingText("");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <>
      <Index.DynamicTitle title="Craft Breakdown Maintenance" />
      <Index.Box className="page-content-box">
        <Index.Box className="barge-common-box">
          <Index.Box className="title-header">
            <Index.Box className="title-header-flex res-title-header-flex">
              <Index.Box className="title-main">
                {/* <Index.Typography
                  variant="p"
                  component="p"
                  className="page-title"
                >
                  Craft Breakdown Maintenance
                </Index.Typography> */}
              </Index.Box>
              <Index.Box className="d-flex align-items-center res-set-search">
                <Index.Box className="input-design-div">
                  <Index.Box className="set-date set-date-picker remove-name">
                    <Index.LocalizationProvider
                      dateAdapter={Index.AdapterDayjs}
                    >
                      <Index.DemoContainer
                        components={["SingleInputDateRangeField"]}
                      >
                        <DateRangePicker
                          slots={{ field: SingleInputDateRangeField }}
                          sx={false}
                          slotProps={{
                            shortcuts: {
                              items: shortcutsItems,
                            },
                            actionBar: { actions: [] },
                          }}
                          onChange={(value) => handleChangeDate(value)}
                          name="allowedRange"
                          format="DD/MM/YYYY"
                        />
                      </Index.DemoContainer>
                    </Index.LocalizationProvider>
                  </Index.Box>
                </Index.Box>

                {/* <Index.Box className="input-design-div with-border breakdown-maintanance-margin">
                  <Index.Select
                    className="placeholder-set-text-box"
                    fullWidth
                    name="bargeCategory"
                    value={selectedJobType}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    onChange={(e) => {
                      // setFieldValue("bargeCategory", e.target.value);
                      handleChangeJobType(e);
                    }}
                  >
                    <Index.MenuItem value={""}>
                      <Index.MenuItem disabled className="all-select-label">
                        Select Job Type
                      </Index.MenuItem>
                    </Index.MenuItem>
                    {jobTypeList?.map((items, index) => (
                      <Index.MenuItem value={items?.name} key={items?._id}>
                        {items?.name}
                      </Index.MenuItem>
                    ))}
                  </Index.Select>
                </Index.Box> */}
                <Search className="search">
                  <StyledInputBase
                    placeholder="Search"
                    inputProps={{ "aria-label": "search" }}
                    value={searchingText}
                    onChange={(e) => handleSearchCompletedListData(e)}
                  />
                </Search>

                <Index.Box
                  className="common-button grey-button"
                  sx={{
                    marginLeft: "14px",
                  }}
                >
                  <Index.Button
                    variant="contained"
                    onClick={() => {
                      let curDate = Index.moment(new Date()).format(
                        "DD-MM-YYYY"
                      );
                      NEW_EXPORT_EXCEL(
                        "/admin/export-maintenance",
                        `Craft_Breakdown_Maintenance_${curDate}`,
                        {
                          search: searchingText,
                        }
                      );
                    }}
                  >
                    Export
                  </Index.Button>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Index.Box className="title-header job-type-tab">
            <Index.Button
              className={`craft-main-btn ${
                selectedJobType === "" ? `selected-job-type` : ``
              }`}
              onClick={() => {
                handleChangeJobType("");
              }}
            >
              All
            </Index.Button>
            {jobTypeList?.map((items, index) => (
              <Index.Button
                className={`craft-main-btn ${
                  selectedJobType === items?.name ? `selected-job-type` : ``
                }`}
                key={items?._id}
                onClick={() => {
                  handleChangeJobType(items?.name);
                }}
              >
                {items?.name}
              </Index.Button>
            ))}
          </Index.Box>

          <Index.Box className="">
            <Index.TableContainer
              component={Index.Paper}
              className="table-container"
            >
              <Index.Table
                aria-label="simple table"
                className="table-design-main barge-table"
              >
                <Index.TableHead>
                  <Index.TableRow>
                    <Index.TableCell>S. No</Index.TableCell>
                    <Index.TableCell align="left">Craft Name</Index.TableCell>
                    <Index.TableCell align="left">
                      Reported Date
                    </Index.TableCell>
                    <Index.TableCell align="left">Working Team</Index.TableCell>
                    <Index.TableCell align="left">Job Type</Index.TableCell>
                    <Index.TableCell align="left">Planned Date</Index.TableCell>
                    <Index.TableCell align="left">Start Date</Index.TableCell>
                    <Index.TableCell align="left">Remark</Index.TableCell>
                    <Index.TableCell align="left">What Is Done</Index.TableCell>
                    <Index.TableCell align="left">
                      Pending(In Days)
                    </Index.TableCell>
                    <Index.TableCell align="left">Type</Index.TableCell>
                    <Index.TableCell align="left">
                      Need To Repair
                    </Index.TableCell>
                    <Index.TableCell align="left">
                      Need To Spare
                    </Index.TableCell>
                    <Index.TableCell align="left">
                      Resolve | Dispatch
                    </Index.TableCell>
                    <Index.TableCell align="left">
                      Dispatched User
                    </Index.TableCell>
                    <Index.TableCell align="left">
                      Received User
                    </Index.TableCell>

                    <Index.TableCell align="left">Reject</Index.TableCell>
                    <Index.TableCell align="left">Action</Index.TableCell>
                  </Index.TableRow>
                </Index.TableHead>
                {loading ? (
                  <Index.TableBody>
                    <Index.TableRow>
                      <Index.TableCell
                        colSpan={20}
                        align="center"
                        className="loading-purchase"
                      >
                        <Index.CircularProgress color="secondary" size={20} />
                      </Index.TableCell>
                    </Index.TableRow>
                  </Index.TableBody>
                ) : (
                  <Index.TableBody>
                    {allCraftBreakdownList?.length ? (
                      allCraftBreakdownList?.map((item, index) => (
                        <Index.TableRow key={item?._id}>
                          <Index.TableCell align="left">
                            {item?.maintenanceNumber || "-"}
                          </Index.TableCell>
                          <Index.TableCell align="left">
                            {item?.bargeName || "-"}
                          </Index.TableCell>
                          <Index.TableCell align="left">
                            {item?.createdAt
                              ? Index.moment(item?.createdAt).format(
                                  "DD/MM/YYYY hh:mm A"
                                )
                              : "-"}
                          </Index.TableCell>
                          <Index.TableCell align="left">
                            {item?.workingUser && item?.workingUser?.length > 0
                              ? Array.prototype.map
                                  .call(item?.workingUser, function (item) {
                                    return item.firstName;
                                  })
                                  .join(", ")
                              : "-"}
                          </Index.TableCell>
                          <Index.TableCell align="left">
                            {item?.jobType || "-"}
                          </Index.TableCell>
                          <Index.TableCell align="left">
                            {item?.planedDate
                              ? Index.moment(item?.planedDate).format(
                                  "DD/MM/YYYY"
                                )
                              : "-"}
                          </Index.TableCell>
                          <Index.TableCell align="left">
                            {item?.startDate
                              ? Index.moment(item?.startDate).format(
                                  "DD/MM/YYYY"
                                )
                              : "-"}
                          </Index.TableCell>
                          <Index.TableCell
                            align="left"
                            sx={{
                              cursor:
                                item?.remark && item?.remark?.length > 10
                                  ? "pointer"
                                  : "",
                            }}
                            onClick={() => {
                              if (item?.remark && item?.remark?.length > 10) {
                                handleModelRemarkOpen();
                                setRemarkData(item?.remark);
                              }
                            }}
                          >
                            {item?.remark
                              ? item?.remark?.length > 10
                                ? `${item?.remark?.slice(0, 9)}...`
                                : item?.remark
                              : "-"}
                          </Index.TableCell>

                          <Index.TableCell align="left">
                            {item?.whatIsDone || "-"}
                          </Index.TableCell>
                          <Index.TableCell align="left">
                            {item?.resolveDate
                              ? "-"
                              : getOverDueDays(item?.createdAt) + " Days"}
                          </Index.TableCell>
                          <Index.TableCell align="left">
                            {item?.type || "-"}
                          </Index.TableCell>
                          <Index.TableCell align="left">
                            {item?.needToRepair?.length
                              ? item?.needToRepair[0]?.point
                              : "-"}
                          </Index.TableCell>
                          <Index.TableCell align="left">
                            {item?.needToSpare?.length
                              ? item?.needToSpare[0]?.point
                              : "-"}
                          </Index.TableCell>
                          {!item?.isReject &&
                          item?.hasOwnProperty("jobType") ? (
                            <>
                              {item?.jobType !== "Procurement" &&
                              !item?.resolveRemark ? (
                                <>
                                  {item?.startDate && item?.planedDate ? (
                                    <>
                                      <Index.TableCell>
                                        <Index.Box className="common-button blue-button res-blue-button">
                                          <Index.Button
                                            variant="contained"
                                            disableRipple
                                            onClick={() => {
                                              handleModelResolveOpen();
                                              setMaintenanceId(item?._id);
                                            }}
                                          >
                                            Resolve
                                          </Index.Button>
                                        </Index.Box>
                                      </Index.TableCell>
                                    </>
                                  ) : (
                                    <>
                                      <Index.TableCell>-</Index.TableCell>
                                    </>
                                  )}
                                </>
                              ) : item?.jobType === "Procurement" &&
                                item?.hasOwnProperty("dispatchDate") &&
                                !item?.hasOwnProperty("receivedDate") ? (
                                <>
                                  <Index.TableCell>
                                    <Index.Box className="common-button blue-button res-blue-button">
                                      Dispatched{" "}
                                      {Index.moment(item?.dispatchDate).format(
                                        "DD/MM/YYYY hh:mm A"
                                      )}
                                    </Index.Box>
                                  </Index.TableCell>
                                </>
                              ) : item?.jobType === "Procurement" &&
                                item?.hasOwnProperty("receivedDate") ? (
                                <>
                                  <Index.TableCell>
                                    <Index.Box className="common-button blue-button res-blue-button">
                                      Received{" "}
                                      {Index.moment(item?.receivedDate).format(
                                        "DD/MM/YYYY hh:mm A"
                                      )}
                                    </Index.Box>
                                  </Index.TableCell>
                                </>
                              ) : item?.jobType === "Procurement" ? (
                                <>
                                  <Index.TableCell>
                                    <Index.Box className="common-button blue-button res-blue-button">
                                      <Index.Button
                                        variant="contained"
                                        disableRipple
                                        onClick={(e) => {
                                          handleDispatchCraft(e, item);
                                        }}
                                      >
                                        Dispatch
                                      </Index.Button>
                                    </Index.Box>
                                  </Index.TableCell>
                                </>
                              ) : (
                                <Index.TableCell>
                                  {item?.resolveRemark
                                    ? item?.resolveRemark
                                    : "-"}
                                </Index.TableCell>
                              )}
                            </>
                          ) : (
                            <>
                              <Index.TableCell>-</Index.TableCell>
                            </>
                          )}

                          <Index.TableCell align="left">
                            {item?.disptachedBy?.hasOwnProperty("firstName") &&
                            item?.disptachedBy?.hasOwnProperty("lastName")
                              ? item?.disptachedBy?.firstName +
                                " " +
                                item?.disptachedBy?.lastName
                              : "-"}
                          </Index.TableCell>
                          <Index.TableCell align="left">
                            {item?.receivedBy?.hasOwnProperty("firstName") &&
                            item?.receivedBy?.hasOwnProperty("lastName")
                              ? item?.receivedBy?.firstName +
                                " " +
                                item?.receivedBy?.lastName
                              : "-"}
                          </Index.TableCell>
                          <Index.TableCell>
                            {(!item?.isReject && item?.resolveRemark) ||
                            (item?.disptachedBy?.hasOwnProperty("firstName") &&
                              item?.disptachedBy?.hasOwnProperty("lastName")) ||
                            (item?.receivedBy?.hasOwnProperty("firstName") &&
                              item?.receivedBy?.hasOwnProperty("lastName")) ? (
                              <>-</>
                            ) : item?.isReject ? (
                              <>
                                <Index.Box className="common-button blue-button res-blue-button">
                                  Rejected{" "}
                                  {Index.moment(item?.rejectedDate).format(
                                    "DD/MM/YYYY hh:mm A"
                                  )}
                                </Index.Box>
                              </>
                            ) : (
                              <>
                                <Index.Box className="common-button blue-button res-blue-button">
                                  <Index.Button
                                    variant="contained"
                                    disableRipple
                                    onClick={(e) => {
                                      handleRejectMaintenanceRequest(e, item);
                                    }}
                                  >
                                    Reject
                                  </Index.Button>
                                </Index.Box>
                              </>
                            )}
                          </Index.TableCell>

                          <Index.TableCell>
                            <Index.Box sx={{ display: "flex" }}>
                              <Index.IconButton
                                onClick={() => {
                                  handleModelOpen();
                                  setEditMaintenanceData(item);
                                }}
                              >
                                <Index.EditIcon
                                  color="primary"
                                  fontSize="small"
                                />
                              </Index.IconButton>

                              <Index.IconButton>
                                <Index.VisibilitySharpIcon
                                  onClick={() => handleView(item)}
                                />
                              </Index.IconButton>
                            </Index.Box>
                          </Index.TableCell>
                        </Index.TableRow>
                      ))
                    ) : (
                      <Index.TableRow>
                        <Index.TableCell
                          component="td"
                          variant="td"
                          scope="row"
                          className="no-data-in-list"
                          colSpan={20}
                          align="center"
                        >
                          No data available
                        </Index.TableCell>
                      </Index.TableRow>
                    )}
                  </Index.TableBody>
                )}
              </Index.Table>
            </Index.TableContainer>
          </Index.Box>
          <Index.Box className="pagination-design flex-end">
            <Index.Stack spacing={2}>
              <Index.Box className="pagination-count">
                <Index.Box className="input-design-div with-border">
                  <Index.Select
                    fullWidth
                    value={paginationPerPage}
                    onChange={(e) => handleChangePaginationPerChange(e)}
                  >
                    <Index.MenuItem value={10}>10</Index.MenuItem>
                    <Index.MenuItem value={25}>25</Index.MenuItem>
                    <Index.MenuItem value={50}>50</Index.MenuItem>
                    <Index.MenuItem value={100}>100</Index.MenuItem>
                  </Index.Select>
                </Index.Box>
                {loading ? (
                  <Index.CircularProgress
                    color="secondary"
                    sx={{ marginLeft: "10px" }}
                    size={26}
                  />
                ) : (
                  <>
                    <Index.Pagination
                      count={Math.ceil(totalRecordCount / paginationPerPage)}
                      page={currentPage}
                      onChange={handlePageChange}
                    />
                  </>
                )}
              </Index.Box>
            </Index.Stack>
          </Index.Box>
        </Index.Box>
      </Index.Box>

      {/* Edit Maintenance Model */}
      <Index.Dialog
        open={openModel}
        onClose={handleModelClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Index.DialogContent className="expense-model-main  expense-model-main-box-set">
          <Index.Box className="title-main">
            <Formik
              enableReinitialize
              initialValues={initialValues}
              onSubmit={handleFormSubmit}
            >
              {({
                values,
                errors,
                touched,
                setFieldValue,
                handleChange,
                handleBlur,
                handleSubmit,
                handleFocus,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Index.Box className="title-main">
                    <Index.Box className="jus-between">
                      <Index.Typography
                        variant="h6"
                        component="h6"
                        className="input-label input-label-bold"
                        sx={{ marginBottom: "15px" }}
                      >
                        Edit Maintenance
                      </Index.Typography>
                      <Index.ClearIcon
                        className="model-close-icon"
                        onClick={handleModelClose}
                      />
                    </Index.Box>
                    <Index.Grid container spacing={2}>
                      <Index.Grid item xs={12} sm={6} md={12}>
                        <Index.Typography
                          variant="label"
                          component="label"
                          className="input-label"
                        >
                          Fault
                        </Index.Typography>
                        <Index.Box className="input-design-div with-border">
                          <Index.TextField
                            hiddenLabel
                            id="filled-hidden-label-normal"
                            placeholder="Enter Fault"
                            variant="filled"
                            className="admin-input-design input-placeholder"
                            name="whatIsDone"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values?.whatIsDone}
                          />
                        </Index.Box>
                      </Index.Grid>
                      <Index.Grid item xs={12} sm={12} md={12}>
                        <Index.Typography
                          variant="label"
                          component="label"
                          className="input-label"
                        >
                          Remark
                        </Index.Typography>
                        <Index.Box className="input-design-div with-border">
                          <Index.TextareaAutosize
                            hiddenLabel
                            minRows={3}
                            id="filled-hidden-label-normal"
                            className="admin-input-design input-placeholder input-placeholder-box  input-placeholder-box-set "
                            placeholder="Enter Remark"
                            variant="filled"
                            value={values?.remark}
                            name="remark"
                            onChange={handleChange}
                          />
                        </Index.Box>
                      </Index.Grid>

                      <Index.Grid item xs={12} sm={6} md={12}>
                        <Index.Typography
                          variant="label"
                          component="label"
                          className="input-label"
                        >
                          Working Team
                        </Index.Typography>
                        <Index.Box className="input-design-div multiselect-input-placeholder with-border">
                          <Index.Select
                            labelId="demo-multiple-name-label"
                            id="demo-multiple-name"
                            fullWidth
                            name="proposedBarge"
                            multiple
                            displayEmpty
                            inputProps={{ "aria-label": "without label" }}
                            MenuProps={MenuProps}
                            value={
                              values?.workingUser ? values?.workingUser : []
                            }
                            renderValue={
                              values?.workingUser != ""
                                ? undefined
                                : () => "Select Working Team"
                            }
                            onFocus={handleFocus}
                            onChange={(event) => {
                              const {
                                target: { value },
                              } = event;
                              setFieldValue(
                                "workingUser",
                                typeof value === "string"
                                  ? value.split(",")
                                  : value
                              );
                            }}
                          >
                            <Index.MenuItem disabled value="">
                              <em>Select Working Team</em>
                            </Index.MenuItem>
                            {workingUserList?.length === 0 ? (
                              <Index.MenuItem disabled>
                                <Index.Box className="multiselect-item">
                                  No data found
                                </Index.Box>
                              </Index.MenuItem>
                            ) : (
                              workingUserList.map((item) => (
                                <Index.MenuItem
                                  key={item?._id}
                                  value={item?._id}
                                >
                                  <Index.Box className="multiselect-item">
                                    {item?.employeeName}
                                  </Index.Box>
                                </Index.MenuItem>
                              ))
                            )}
                          </Index.Select>
                          {touched?.proposedBarge && errors?.proposedBarge && (
                            <Index.FormHelperText error>
                              {errors?.proposedBarge}
                            </Index.FormHelperText>
                          )}
                        </Index.Box>
                      </Index.Grid>
                      <Index.Grid item xs={12} sm={6} md={12}>
                        <Index.Typography
                          variant="label"
                          component="label"
                          className="input-label"
                        >
                          Job Type
                        </Index.Typography>
                        <Index.Box className="input-design-div with-border">
                          <Index.Autocomplete
                            disablePortal
                            className="invoice-select-drop"
                            id="combo-box-demo"
                            options={jobTypeList}
                            getOptionLabel={(option) => option?.name}
                            renderOption={(props, option) => (
                              <Index.Box
                                component="li"
                                sx={{
                                  "& > img": { mr: 2, flexShrink: 0 },
                                }}
                                {...props}
                              >
                                {option?.name}
                              </Index.Box>
                            )}
                            value={
                              jobTypeList && jobTypeList?.length > 0
                                ? jobTypeList?.find(
                                    (value) => value?.name == values?.jobType
                                  )
                                : ""
                            }
                            onChange={(event, newValue) => {
                              setFieldValue("jobType", newValue?.name);
                            }}
                            renderInput={(params) => (
                              <Index.TextField
                                {...params}
                                placeholder="Select Job Type"
                                variant="outlined"
                              />
                            )}
                          />
                        </Index.Box>
                      </Index.Grid>
                      <Index.Grid item xs={12} sm={6} md={12}>
                        <Index.Typography
                          variant="label"
                          component="label"
                          className="input-label"
                        >
                          Planned Date
                        </Index.Typography>
                        <Index.Box className="input-design-div">
                          <Index.Box className="set-date set-date-picker">
                            <Index.LocalizationProvider
                              dateAdapter={Index.AdapterDayjs}
                            >
                              <Index.DemoContainer components={["DatePicker"]}>
                                <Index.DemoItem label="">
                                  <Index.DatePicker
                                    format="DD/MM/YYYY"
                                    defaultValue={
                                      editMaintenanceData
                                        ? Index.dayjs(
                                            values?.planedDate
                                              ? Index.moment(
                                                  values?.planedDate
                                                ).format("YYYY-MM-DD")
                                              : "-"
                                          )
                                        : ""
                                    }
                                    onChange={(value) => {
                                      const formatedDate = Index.moment(
                                        value.$d
                                      ).format("YYYY-MM-DD");
                                      setFieldValue("planedDate", formatedDate);
                                    }}
                                  />
                                </Index.DemoItem>
                              </Index.DemoContainer>
                            </Index.LocalizationProvider>
                          </Index.Box>
                        </Index.Box>
                      </Index.Grid>
                      <Index.Grid item xs={12} sm={6} md={12}>
                        <Index.Typography
                          variant="label"
                          component="label"
                          className="input-label"
                        >
                          Start Date
                        </Index.Typography>
                        <Index.Box className="input-design-div">
                          <Index.Box className="set-date set-date-picker">
                            <Index.LocalizationProvider
                              dateAdapter={Index.AdapterDayjs}
                            >
                              <Index.DemoContainer components={["DatePicker"]}>
                                <Index.DemoItem label="">
                                  <Index.DatePicker
                                    format="DD/MM/YYYY"
                                    defaultValue={
                                      editMaintenanceData
                                        ? Index.dayjs(
                                            values?.startDate
                                              ? Index.moment(
                                                  values?.startDate
                                                ).format("YYYY-MM-DD")
                                              : "-"
                                          )
                                        : ""
                                    }
                                    onChange={(value) => {
                                      const formatedDate = Index.moment(
                                        value.$d
                                      ).format("YYYY-MM-DD");
                                      setFieldValue("startDate", formatedDate);
                                    }}
                                  />
                                </Index.DemoItem>
                              </Index.DemoContainer>
                            </Index.LocalizationProvider>
                          </Index.Box>
                        </Index.Box>
                      </Index.Grid>
                      {/* <Index.Grid item xs={12} sm={6} md={12}>
                        <Index.Typography
                          variant="label"
                          component="label"
                          className="input-label"
                        >
                          Need To Repair
                        </Index.Typography> */}
                      {values.jobType?.length &&
                      values?.jobType !== "Procurement" ? (
                        <>
                          <Index.Grid item xs={12} sm={6} md={12}>
                            <Index.Typography
                              variant="label"
                              component="label"
                              className="input-label"
                            >
                              Need To Repair
                            </Index.Typography>
                            <FieldArray name="needToRepair">
                              {({ push, remove }) => (
                                <>
                                  {values?.needToRepair?.map((row, index) => (
                                    <Index.Grid
                                      container
                                      spacing={2}
                                      key={index}
                                    >
                                      <Index.Grid item xs={12} sm={6} md={8}>
                                        <Index.Box className="input-design-div with-border">
                                          <Index.TextField
                                            hiddenLabel
                                            id="filled-hidden-label-normal"
                                            placeholder="Enter Need To Repair"
                                            variant="filled"
                                            className="admin-input-design input-placeholder"
                                            name={`needToRepair.${index}.point`}
                                            value={
                                              values.needToRepair[index].point
                                            }
                                            autoComplete="off"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                          />
                                        </Index.Box>
                                      </Index.Grid>
                                      {/* <Index.Grid item xs={12} sm={6} md={3}>
                                    <Index.Box className="incre-btn-main incre-btn-maintenance-main">
                                      {values?.needToRepair?.length - 1 ==
                                        index && (
                                        <Index.Button
                                          sx={{ fontSize: "20px" }}
                                          className="button-add inc-dic-btn"
                                          type="button"
                                          onClick={() =>
                                            push({
                                              point: "",
                                            })
                                          }
                                        >
                                          +
                                        </Index.Button>
                                      )}
                                      {values?.needToRepair?.length > 1 && (
                                        <Index.Button
                                          sx={{ fontSize: "20px" }}
                                          type="button"
                                          className="button-remove inc-dic-btn"
                                          disabled={
                                            values?.needToRepair?.length > 1
                                              ? false
                                              : true
                                          }
                                          onClick={() => remove(index)}
                                        >
                                          -
                                        </Index.Button>
                                      )}
                                    </Index.Box>
                                  </Index.Grid> */}
                                    </Index.Grid>
                                  ))}
                                </>
                              )}
                            </FieldArray>
                          </Index.Grid>
                        </>
                      ) : (
                        <></>
                      )}

                      {values.jobType?.length &&
                      values?.jobType === "Procurement" ? (
                        <>
                          <Index.Grid item xs={12} sm={6} md={12}>
                            <Index.Typography
                              variant="label"
                              component="label"
                              className="input-label"
                            >
                              Need To Spare
                            </Index.Typography>
                            <FieldArray name="needToSpare">
                              {({ push, remove }) => (
                                <>
                                  {values.needToSpare.map((row, index) => (
                                    <Index.Grid
                                      container
                                      spacing={2}
                                      key={index}
                                    >
                                      <Index.Grid item xs={12} sm={6} md={8}>
                                        <Index.Box className="input-design-div with-border">
                                          <Index.TextField
                                            hiddenLabel
                                            id="filled-hidden-label-normal"
                                            placeholder="Enter Need To Spare"
                                            variant="filled"
                                            className="admin-input-design input-placeholder"
                                            name={`needToSpare.${index}.point`}
                                            value={
                                              values.needToSpare[index].point
                                            }
                                            autoComplete="off"
                                            onChange={handleChange}
                                          />
                                        </Index.Box>
                                      </Index.Grid>
                                      {/* <Index.Grid item xs={12} sm={6} md={3}>
                                        <Index.Box className="incre-btn-main incre-btn-maintenance-main">
                                          {values?.needToSpare?.length - 1 ==
                                            index && (
                                            <Index.Button
                                              sx={{ fontSize: "20px" }}
                                              className="button-add inc-dic-btn"
                                              type="button"
                                              onClick={() =>
                                                push({
                                                  point: "",
                                                })
                                              }
                                            >
                                              +
                                            </Index.Button>
                                          )}
                                          {values?.needToSpare?.length > 1 && (
                                            <Index.Button
                                              sx={{ fontSize: "20px" }}
                                              type="button"
                                              className="button-remove inc-dic-btn"
                                              disabled={
                                                values?.needToSpare?.length > 1
                                                  ? false
                                                  : true
                                              }
                                              onClick={() => remove(index)}
                                            >
                                              -
                                            </Index.Button>
                                          )}
                                        </Index.Box>
                                      </Index.Grid> */}
                                    </Index.Grid>
                                  ))}
                                </>
                              )}
                            </FieldArray>
                          </Index.Grid>
                          <Index.Grid item xs={12} sm={6} md={12}>
                            <Index.Typography
                              variant="label"
                              component="label"
                              className="input-label"
                            >
                              QTY
                            </Index.Typography>
                            <Index.Box className="input-design-div with-border">
                              <Index.TextField
                                hiddenLabel
                                type="number"
                                id="filled-hidden-label-normal"
                                variant="filled"
                                placeholder="QTY"
                                className="admin-input-design input-placeholder"
                                name="quantity"
                                value={values.quantity}
                                onChange={handleChange}
                              />
                            </Index.Box>
                          </Index.Grid>
                          <Index.Grid item xs={12} sm={6} md={12}>
                            <Index.Typography
                              variant="label"
                              component="label"
                              className="input-label"
                            >
                              Unit
                            </Index.Typography>
                            <Index.Box className="input-design-div with-border">
                              <Index.TextField
                                hiddenLabel
                                type="number"
                                id="filled-hidden-label-normal"
                                variant="filled"
                                placeholder="Unit"
                                className="admin-input-design input-placeholder"
                                name="unit"
                                value={values.unit}
                                onChange={handleChange}
                              />
                            </Index.Box>
                          </Index.Grid>
                        </>
                      ) : (
                        <></>
                      )}

                      <Index.Grid item xs={12} sm={6} md={12}>
                        <Index.Box
                          className="common-button blue-button flex-end save-btn mt-10"
                          sx={{
                            display: "flex",
                            alignItems: "flex-end",
                          }}
                        >
                          <Index.Button
                            variant="contained"
                            type="submit"
                            className="Particular_model_submit"
                          >
                            Update
                          </Index.Button>
                        </Index.Box>
                      </Index.Grid>
                    </Index.Grid>

                    {/* <Index.Box className="line" mt={3} mb={3}></Index.Box> */}
                  </Index.Box>
                </form>
              )}
            </Formik>
          </Index.Box>
        </Index.DialogContent>
      </Index.Dialog>

      {/* Add Mask as resolve */}
      <Index.Dialog
        open={openModelResolve}
        onClose={handleModelResolveClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Index.DialogContent className="expense-model-main  expense-model-main-box-set">
          <Index.Box className="title-main">
            <Formik
              //   enableReinitialize
              initialValues={initialValuesResolve}
              validationSchema={maintenanceResolveValidationSchema}
              onSubmit={handleAddMaintenanceResolveSubmit}
            >
              {({
                errors,
                touched,
                setFieldValue,
                handleChange,
                handleSubmit,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Index.Box className="title-main" sx={{ marginTop: "10px" }}>
                    <Index.ClearIcon
                      className="model-close-icon"
                      onClick={handleModelResolveClose}
                    />
                    <Index.Typography
                      variant="h6"
                      component="h6"
                      className="input-label"
                      sx={{ marginBottom: "15px" }}
                    >
                      Add Resolve
                    </Index.Typography>
                    <Index.Grid container spacing={2}>
                      <Index.Grid item xs={12} sm={6} md={12}>
                        <Index.Typography
                          variant="label"
                          component="label"
                          className="input-label"
                        >
                          Remark
                        </Index.Typography>
                        <Index.Box className="input-design-div with-border">
                          <Index.TextareaAutosize
                            hiddenLabel
                            minRows={3}
                            id="filled-hidden-label-normal"
                            className="admin-input-design input-placeholder input-placeholder-box input-placeholder-box-set "
                            placeholder="Remark"
                            variant="filled"
                            name="resolveRemark"
                            onChange={handleChange}
                          />
                          {touched.resolveRemark && errors.resolveRemark && (
                            <Index.FormHelperText error>
                              {errors.resolveRemark}
                            </Index.FormHelperText>
                          )}
                        </Index.Box>
                      </Index.Grid>

                      <Index.Grid item xs={12} sm={6} md={12}>
                        <Index.Box
                          className="common-button blue-button flex-end save-btn"
                          sx={{
                            display: "flex",
                            alignItems: "flex-end",
                          }}
                        >
                          <Index.Button
                            variant="contained"
                            type="submit"
                            className="Particular_model_submit"
                          >
                            Submit
                          </Index.Button>
                        </Index.Box>
                      </Index.Grid>
                    </Index.Grid>

                    <Index.Box className="line" mt={3} mb={3}></Index.Box>
                  </Index.Box>
                </form>
              )}
            </Formik>
          </Index.Box>
        </Index.DialogContent>
      </Index.Dialog>

      {/* Remark  text */}
      <Index.Dialog
        open={openModelRemark}
        onClose={handleModelRemarkClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Index.DialogContent className="expense-model-main  expense-model-main-box-set">
          <Index.Box className="title-main">
            <Index.Box className="title-main" sx={{ marginTop: "10px" }}>
              <Index.ClearIcon
                className="model-close-icon"
                onClick={handleModelRemarkClose}
              />
              <Index.Typography
                variant="h6"
                component="h6"
                className="input-label"
                sx={{ marginBottom: "15px" }}
              >
                Remark
              </Index.Typography>
              <Index.Grid container spacing={2}>
                <Index.Grid item xs={12} sm={6} md={12}>
                  <Index.Typography
                    variant="label"
                    component="label"
                    className="input-label"
                  >
                    {remarkData}
                  </Index.Typography>
                </Index.Grid>
              </Index.Grid>
            </Index.Box>
          </Index.Box>
        </Index.DialogContent>
      </Index.Dialog>
    </>
  );
}

export default MaintenanceList;
