import React from "react";
import Index from "../../../Index";
import { Formik } from "formik";
import {
  addBankRemark,
  getHDFCBankList,
  getKotakBankList,
} from "../../../../redux/admin/action";

const RemarkModel = ({
  modelOpen,
  handleClose,
  remarkData,
  type,
  handleReloadHDFCRecords,
}) => {
  const dispatch = Index.useDispatch();

  // initialValues
  let initialValues = {
    remark: remarkData?.remark ? remarkData?.remark : "",
  };

  // remark model submit handler
  const handleFormSubmit = (values) => {
    let data = new URLSearchParams();
    data.append("type", type);
    data.append("guid", remarkData.guid);
    data.append("remark", values.remark);
    dispatch(addBankRemark(data)).then((res) => {
      if (res.status === 200 || res.status === 201) {
        handleReloadHDFCRecords();
        // if (type === "hdfc") {
        //   // dispatch(getHDFCBankList());
        //   handleReloadHDFCRecords();
        // } else if (type === "kotak") {
        //   // dispatch(getKotakBankList());
        //   handleReloadHDFCRecords();
        // }
        handleClose();
      }
    });
  };

  return (
    <div>
      <Index.Dialog
        open={modelOpen}
        onclose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Index.DialogContent>
          <Index.Box className="title-main mb-10">
            <Formik
              enableReinitialize
              initialValues={initialValues}
              onSubmit={handleFormSubmit}
            >
              {({ values, handleChange, handleBlur, handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                  <Index.Box className="title-main">
                    <Index.Grid container spacing={2}>
                      <Index.Grid item xs={12} sm={12} md={12}>
                        <Index.Typography
                          variant="label"
                          component="label"
                          className="input-label"
                        >
                          Add Remark
                        </Index.Typography>
                        <Index.Box className="input-design-div with-border input-textarea">
                          <Index.TextareaAutosize
                            hiddenLabel
                            id="filled-hidden-label-normal"
                            placeholder="Remark"
                            variant="filled"
                            className="admin-input-design input-placeholder remark-box"
                            name="remark"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values?.remark}
                            inputProps={{ min: 1, step: 0.001 }}
                            minRows={2}
                          />
                        </Index.Box>
                      </Index.Grid>

                      <Index.Grid item xs={12} sm={12} md={12}>
                        <Index.Box className="common-button blue-button save-btn add-remark-btn">
                          <Index.Button
                            variant="contained"
                            type="submit"
                            className="Particular_model_submit ml-0"
                          >
                            {remarkData?.remark ? "Update" : "Submit"}
                          </Index.Button>

                          <Index.Button
                            variant="contained"
                            type="button"
                            className="Particular_model_submit"
                            onClick={() => {
                              handleClose();
                            }}
                          >
                            Cancel
                          </Index.Button>
                        </Index.Box>
                      </Index.Grid>
                    </Index.Grid>
                  </Index.Box>
                </form>
              )}
            </Formik>
          </Index.Box>
        </Index.DialogContent>
      </Index.Dialog>
    </div>
  );
};

export default RemarkModel;
