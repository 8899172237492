import { useEffect, useState } from "react";
import Index from "../../../../Index";
import DirectionsBoatFilledIcon from "@mui/icons-material/DirectionsBoatFilled";
import {
  getDashboardTankerBreakdownList,
  getDashboardCraftBreakdownList,
} from "../../../../../redux/admin/action";
import { createSearchParams } from "react-router-dom";

Index.moment.updateLocale("en", {
  relativeTime: {
    future: "in %s",
    past: "%s ago",
    s: "a few sec",
    ss: "%d sec",
    m: "a min",
    mm: "%d min",
    h: "an hrs",
    hh: "%d hrs",
    d: "a day",
    dd: "%d days",
    M: "1 month",
    MM: "%d months",
    y: "a year",
    yy: "%d years",
  },
});

function BreakDwonDetails({ breakDownOptionValue }) {
  const dispatch = Index.useDispatch();
  const navigate = Index.useNavigate();
  const [finalBreakDown, setFinalBreakDown] = useState([]);
  const [loading, setLoading] = useState(true);
  const { activeCompany } = Index.useSelector((state) => state.AdminReducer);

  const breakDownCraft = () => {
    try {
      dispatch(getDashboardCraftBreakdownList()).then((res) => {
        if (res?.status === 200) {
          setFinalBreakDown(res?.data);
          setLoading(false);
        } else {
          setFinalBreakDown([]);
          setLoading(false);
        }
      });
    } catch (error) {}
  };
  const BreakDownTanker = () => {
    try {
      dispatch(getDashboardTankerBreakdownList()).then((res) => {
        if (res?.status == 200) {
          setFinalBreakDown(res?.data);
          setLoading(false);
        } else {
          setFinalBreakDown([]);
          setLoading(false);
        }
      });
    } catch (error) {}
  };

  useEffect(() => {
    if (breakDownOptionValue === "Tanker") {
      setLoading(true);
      BreakDownTanker();
    } else if (breakDownOptionValue === "Craft") {
      setLoading(true);
      breakDownCraft();
    }
  }, [breakDownOptionValue]);

  const getOverDueDays = (NewDate) => {
    const startDate = Index.moment(new Date());
    var currentDate = Index.moment(NewDate);
    var days = startDate.diff(currentDate, "days");
    return days;
  };

  // when the user want to craft's maintenance then used...
  const handleOpenCraftMaintenance = (craftDetails) => {
    const searchParams = createSearchParams({
      bargeId: craftDetails?._id,
      craftName: craftDetails?.bargeName,
      companyId: activeCompany?._id,
    }).toString();

    const url = `/admin/maintenances/craft?${searchParams}`;
    window.open(url, "_blank");
  };

  return (
    <>
      <Index.Box className="cate-table-main cate-table-main-box">
        <Index.TableContainer component={Index.Paper}>
          <Index.Table sx={{ minWidth: 650 }} aria-label="cate-table-container">
            <Index.TableHead>
              <Index.TableRow className="tanker-dashboard-div">
                <Index.TableCell>No.</Index.TableCell>
                <Index.TableCell align="left">
                  {breakDownOptionValue === "Tanker" ? "Tanker Number" : "Name"}
                </Index.TableCell>
                <Index.TableCell align="left">Type</Index.TableCell>
                <Index.TableCell align="left">No of days</Index.TableCell>
              </Index.TableRow>
            </Index.TableHead>
            <Index.TableBody>
              {loading ? (
                <Index.TableRow>
                  <Index.TableCell
                    colSpan={12}
                    align="center"
                    className="loading-purchase"
                  >
                    <Index.CircularProgress color="secondary" size={20} />
                  </Index.TableCell>
                </Index.TableRow>
              ) : (
                <>
                  {finalBreakDown?.map((items, index) => {
                    return (
                      <Index.TableRow
                        sx={{ cursor: "pointer" }}
                        onClick={(e) => {}}
                      >
                        <Index.TableCell>{index + 1}</Index.TableCell>
                        <Index.TableCell
                          onClick={() => {
                            if (breakDownOptionValue == "Craft") {
                              handleOpenCraftMaintenance(items);
                            }
                          }}
                        >
                          {breakDownOptionValue == "Tanker"
                            ? items?.tankerId?.tankerNumber
                              ? items?.tankerId?.tankerNumber
                              : "-"
                            : breakDownOptionValue == "Craft"
                            ? items?.bargeName
                              ? items?.bargeName
                              : "-"
                            : "-"}
                        </Index.TableCell>
                        <Index.TableCell>
                          <Index.Box className="free-tanker-flex-box">
                            <Index.IconButton className="free-tanker-icon-btn">
                              {breakDownOptionValue == "Tanker" ? (
                                <img
                                  src={Index.Png.freeTanker}
                                  className="free-tanker-free-icon"
                                />
                              ) : breakDownOptionValue == "Craft" ? (
                                // <img
                                //   src={Index.Png.freeTanker}
                                //   className="free-tanker-free-icon"
                                // />
                                <DirectionsBoatFilledIcon />
                              ) : (
                                "-"
                              )}
                            </Index.IconButton>
                          </Index.Box>
                        </Index.TableCell>
                        <Index.TableCell>
                          {Index.moment(items?.breakdownInDate).fromNow()}
                        </Index.TableCell>
                      </Index.TableRow>
                    );
                  })}
                </>
              )}
            </Index.TableBody>
          </Index.Table>
        </Index.TableContainer>
      </Index.Box>
    </>
  );
}

export default BreakDwonDetails;
