import { DateTimePicker } from "@mui/x-date-pickers";
import { Formik } from "formik";
import dayjs from "dayjs";
import { terminalData } from "../../../../../config/CommonTerminalItems";
import { useEffect, useState } from "react";
import Index from "../../../../Index";

function UdpateOrderTerminalModal({
  openUpdateDatePopUp,
  handleCloseDateEditModal,
  handleUpdateDateViaApi,
  editTimeDateData,
  editTimeTerminalData,
  isSingleFieldUpdate,
}) {
  const initialValues = {
    terminal: editTimeTerminalData,
  };

  const [allTerminalData, setAllTerminalData] = useState([]);

  useEffect(() => {
    setAllTerminalData(
      terminalData?.sort((a, b) => a?.item?.localeCompare(b?.item))
    );
  }, [terminalData]);
  return (
    <>
      <Index.Dialog
        open={openUpdateDatePopUp}
        onClose={handleCloseDateEditModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Index.DialogContent className="expense-model-main modal-update-main">
          <>
            <Formik
              enableReinitialize
              initialValues={initialValues}
              // validationSchema={vesselValidationSchema}
              onSubmit={(values) =>
                handleUpdateDateViaApi(values, editTimeDateData)
              }
            >
              {({
                values,
                errors,
                touched,
                setFieldValue,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldTouched,
                handleFocus,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Index.Box className="title-main">
                    <Index.Box className="jus-between">
                      <Index.Typography
                        variant="h6"
                        component="h6"
                        className="input-label"
                      >
                        Edit Terminal
                      </Index.Typography>
                      <Index.ClearIcon
                        className="model-close-icon"
                        onClick={handleCloseDateEditModal}
                      />
                    </Index.Box>
                    <Index.Box className="update-model-main-box-set">
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Terminal
                      </Index.Typography>
                      <Index.Box className="input-design-div with-border">
                        <Index.Autocomplete
                          disablePortal
                          className="invoice-select-drop"
                          // className="placeholder-set-text-box"
                          id="combo-box-demo"
                          options={allTerminalData}
                          value={
                            allTerminalData[
                              allTerminalData.findIndex(
                                (e) => e.item === values?.terminal
                              )
                            ]
                          }
                          getOptionLabel={(option) => option?.item}
                          renderOption={(props, option) => (
                            <Index.Box
                              className="all-select-label select-label-font"
                              component="li"
                              sx={{
                                "& > img": { mr: 2, flexShrink: 0 },
                              }}
                              {...props}
                            >
                              {option?.item}
                            </Index.Box>
                          )}
                          onChange={(event, newValue) => {
                            setFieldValue("terminal", newValue?.item);
                          }}
                          renderInput={(params) => (
                            <Index.TextField
                              {...params}
                              placeholder="Terminal name"
                              variant="outlined"
                            />
                          )}
                        />
                      </Index.Box>
                      <Index.Box
                        className="common-button blue-button flex-end save-btn modal-update-btn"
                      >
                        <Index.Button
                          variant="contained"
                          type="submit"
                          className="Particular_model_submit"
                          disabled={isSingleFieldUpdate ? true : false}
                        >
                          Update
                        </Index.Button>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </form>
              )}
            </Formik>
          </>
        </Index.DialogContent>
      </Index.Dialog>
    </>
  );
}
export default UdpateOrderTerminalModal;
