import React, { useEffect, useState, useRef } from "react";
import Index from "../../../Index";
import {
  expenseRequestApproveReject,
  getSingleExpenseBargeRequest,
  getInvoiceActivityLog,
  getSingleBargeExpense,
  approveRejectBargeExpense,
} from "../../../../redux/admin/action";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useReactToPrint } from "react-to-print";
import OtherExpensePrintPDF from "./OtherExpensePrintPDF";
import { useLocation } from "react-router-dom";
import ApproveRejectModal from "./ApproveRejectModal";

// Design Tab
function SingleOrderTab(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Index.Box sx={{ p: 3 }}>
          <Index.Typography>{children}</Index.Typography>
        </Index.Box>
      )}
    </div>
  );
}

SingleOrderTab.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
// Design Tab

function OtherExpenseDetail() {
  const navigate = Index.useNavigate();
  const dispatch = Index.useDispatch();
  const { id } = Index.useParams();
  const { state } = useLocation();
  const tabType = state?.type;
  const { invoiceActivityLogList } = Index.useSelector(
    (state) => state.AdminReducer
  );
  const [viewData, setViewData] = useState({});
  const [value, setValue] = useState(0);
  const [requestSingleData, setRequestSingleData] = useState([]);

  const [openApproveRejectModal, setOpenApproveRejectModal] = useState(false);
  const [selectedExpenseId, setSelectedExpenseId] = useState("");
  const [selectedExpenseType, setSelectedExpenseType] = useState("");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const backBtn = () => {
    navigate("/admin/expenseList");
    if (tabType === "otherExpense") {
      navigate("/admin/expenseList", { state: { value: 1 } });
    }
  };

  // filter activity log
  const expenseLogData = invoiceActivityLogList?.filter((item) => {
    return item.activityTypeId === viewData?._id;
  });

  // status approved
  const statusApproved = (item) => {
    let data = {
      id: item?._id,
      editStatus: "Approved",
    };
    dispatch(expenseRequestApproveReject(data)).then((res) => {
      if (res?.status === 200) {
        getSingleBargeRequestById();
        bargeExpenseActivityLog();
      }
    });
  };

  // status Reject
  const statusRejected = (item) => {
    let data = {
      id: item?._id,
      editStatus: "Rejected",
    };
    dispatch(expenseRequestApproveReject(data)).then((res) => {
      if (res?.status === 200) {
        getSingleBargeRequestById();
        bargeExpenseActivityLog();
      }
    });
  };

  //barge expense activity log
  const bargeExpenseActivityLog = () => {
    const bargeExpenseLogData = {
      activityTypeId: id,
      type: "bargeExpense",
    };
    dispatch(getInvoiceActivityLog(bargeExpenseLogData));
  };

  useEffect(() => {
    bargeExpenseActivityLog();
  }, []);

  // get expense details
  const getSingleExpense = () => {
    dispatch(getSingleBargeExpense({ id })).then((res) => {
      setViewData(res?.data);
    });
  };

  // get single expense
  useEffect(() => {
    getSingleExpense();
  }, []);

  useEffect(() => {
    bargeExpenseActivityLog();
  }, []);

  // expense details
  const totalAmount = viewData?.otherExpense?.reduce((start, current) => {
    if (current?.price) {
      return start + current?.price;
    } else {
      return start + 0;
    }
  }, 0);

  // get expense details
  const getSingleBargeRequestById = () => {
    dispatch(getSingleExpenseBargeRequest({ id })).then((res) => {
      setRequestSingleData(res?.data);
    });
  };

  useEffect(() => {
    getSingleBargeRequestById();
  }, []);

  // when the use approved then used...
  const handleApprove = (values) => {
    // const values = {
    //   id: expenseId,
    //   status: "Approved",
    // };
    dispatch(approveRejectBargeExpense(values)).then((res) => {
      if (res.status === 200) {
        handleCloseApproveRejectModal();
        getSingleExpense();
      }
    });
  };

  // when the user reject then used...
  const handleReject = (values) => {
    // const values = {
    //   id: expenseId,
    //   status: "Rejected",
    // };
    dispatch(approveRejectBargeExpense(values)).then((res) => {
      if (res.status === 200) {
        handleCloseApproveRejectModal();
        getSingleExpense();
      }
    });
  };

  // when the close approve and reject modal then used...
  const handleCloseApproveRejectModal = () => {
    setOpenApproveRejectModal(false);
    setSelectedExpenseId("");
    setSelectedExpenseType("");
  };

  // when the open modal then used...
  const handleOpenModal = (expenseId, expenseType) => {
    setOpenApproveRejectModal(true);
    setSelectedExpenseId(expenseId);
    setSelectedExpenseType(expenseType);
  };


  return (
    <>
      <Index.Box className="invoice-details">
        <Index.Box className="barge-common-box mb-20">
          <Index.Box className="title-header-flex flex-column-res">
            <Index.Box className="invoice-back-title">
              <Index.Button
                type="submit"
                className="Particular_model_submit back-invoice-btn"
                onClick={backBtn}
              >
                <Index.ArrowBackIcon />
              </Index.Button>
              <Index.Typography
                variant="span"
                component="span"
                className="invoice-title"
              >
                Other Expense Detail
              </Index.Typography>
            </Index.Box>

            <Index.Box className="common-button blue-button save-btn res-button-width">
              <Index.Box className="pdfDownload" sx={{ display: "none" }}>
                <OtherExpensePrintPDF
                  expenseData={viewData}
                  componentRef={componentRef}
                />
              </Index.Box>
              {viewData?.status === "Approved" ||
              viewData?.status === "Rejected" ? (
                <></>
              ) : (
                <>
                  <Index.Button
                    variant="contained"
                    type="submit"
                    className="Particular_model_submit view-invoice-btn"
                    onClick={() =>
                      handleOpenModal(viewData?._id, viewData?.expenseType)
                    }
                  >
                    Approve | Reject
                  </Index.Button>
                </>
              )}

              <Index.Button
                variant="contained"
                type="submit"
                className="Particular_model_submit view-invoice-btn"
                onClick={() => handlePrint()}
              >
                Print PDF
              </Index.Button>
            </Index.Box>
          </Index.Box>

          <Index.Box className="">
            <Index.Grid container spacing={2}>
              <Index.Grid item xs={12} sm={6} md={4}>
                <Index.Box>
                  <Index.Box className="invoice-detail-item">
                    <Index.Typography
                      variant="label"
                      component="label"
                      className="input-label"
                    >
                      Created By:
                    </Index.Typography>
                    <Index.Box className="input-design-div with-border">
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        {viewData?.createdBy?.firstName
                          ? viewData?.createdBy?.firstName
                          : "-"}
                      </Index.Typography>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Grid>

              <Index.Grid item xs={12} sm={6} md={4}>
                <Index.Box>
                  <Index.Box className="invoice-detail-item">
                    <Index.Typography
                      variant="label"
                      component="label"
                      className="input-label"
                    >
                      Grand Total:
                    </Index.Typography>
                    <Index.Box className="input-design-div with-border">
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        {totalAmount ? `₹ ${totalAmount}` : "-"}
                      </Index.Typography>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Grid>

              <Index.Grid item xs={12} sm={6} md={4}>
                <Index.Box>
                  <Index.Box className="invoice-detail-item">
                    <Index.Typography
                      variant="label"
                      component="label"
                      className="input-label"
                    >
                      Created Date:
                    </Index.Typography>
                    <Index.Box className="input-design-div with-border">
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        {viewData?.createdAt
                          ? Index.moment(viewData?.createdAt).format(
                              "DD/MM/YYYY h:mm:ss a"
                            )
                          : "-"}
                      </Index.Typography>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Grid>

              <Index.Grid item xs={12} sm={6} md={4}>
                <Index.Box>
                  <Index.Box className="invoice-detail-item">
                    <Index.Typography
                      variant="label"
                      component="label"
                      className="input-label"
                    >
                      Approved:
                    </Index.Typography>
                    <Index.Box className="input-design-div with-border">
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        {viewData?.activeStatus ? "Yes" : "No"}
                      </Index.Typography>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Grid>
            </Index.Grid>
          </Index.Box>
        </Index.Box>

        <Index.Box className="mb-20">
          {/* Design Tab */}
          <Index.Box sx={{ width: "100%" }}>
            <Index.Box
              className="tab-button-box"
              sx={{ borderBottom: 1, borderColor: "divider" }}
            >
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                className="dashboard-tab"
              >
                <Tab className="tab-button" label="Details" {...a11yProps(0)} />
                <Tab
                  className="tab-button"
                  label="Edit Request"
                  {...a11yProps(1)}
                />
                <Tab
                  className="tab-button"
                  label="Activity"
                  {...a11yProps(2)}
                />
              </Tabs>
            </Index.Box>

            <SingleOrderTab
              value={value}
              index={0}
              className="barge-common-box tab-content-box"
            >
              <Index.Grid container spacing={1}>
                <Index.Box className="order-active-log-header expensedetails-table order-active-table-set">
                  <Index.TableContainer>
                    <Index.Table className="table-design-main activity-log-table-set invoice-activity-log">
                      <Index.TableHead>
                        <Index.TableRow>
                          <Index.TableCell>S. No</Index.TableCell>
                          <Index.TableCell>Detail</Index.TableCell>
                          <Index.TableCell>Amount</Index.TableCell>
                        </Index.TableRow>
                      </Index.TableHead>
                      <Index.TableBody>
                        {viewData?.otherExpense ? (
                          viewData?.otherExpense &&
                          viewData?.otherExpense?.map((items, index) => {
                            return (
                              <Index.TableRow>
                                <Index.TableCell>
                                  <Index.Typography>
                                    {index + 1}
                                  </Index.Typography>
                                </Index.TableCell>
                                <Index.TableCell>
                                  <Index.Typography>
                                    {items?.description
                                      ? items?.description
                                      : "-"}
                                  </Index.Typography>
                                </Index.TableCell>
                                <Index.TableCell>
                                  <Index.Typography>
                                    {items?.price ? `₹ ${items.price}` : "-"}
                                  </Index.Typography>
                                </Index.TableCell>
                              </Index.TableRow>
                            );
                          })
                        ) : (
                          <Index.TableRow>
                            <Index.TableCell
                              component="td"
                              variant="td"
                              scope="row"
                              className="no-data-in-list"
                              colSpan={6}
                              align="center"
                            >
                              No data available
                            </Index.TableCell>
                          </Index.TableRow>
                        )}
                      </Index.TableBody>
                    </Index.Table>
                  </Index.TableContainer>
                </Index.Box>
              </Index.Grid>
            </SingleOrderTab>

            <SingleOrderTab
              value={value}
              index={1}
              className="barge-common-box tab-content-box"
            >
              <Index.TableContainer>
                <Index.Table className="table-design-main challan-table challan-table-set challan-table-font ">
                  <Index.TableHead>
                    <Index.TableRow>
                      <Index.TableCell>Created Date</Index.TableCell>
                      <Index.TableCell>Updated Date</Index.TableCell>
                      <Index.TableCell>Status</Index.TableCell>
                      <Index.TableCell>Approved / Rejected</Index.TableCell>
                    </Index.TableRow>
                  </Index.TableHead>
                  <Index.TableBody>
                    {requestSingleData?.length ? (
                      requestSingleData &&
                      requestSingleData?.map((items, index) => {
                        return (
                          <Index.TableRow>
                            <Index.TableCell>
                              <Index.Typography>
                                {Index.moment(items?.createdAt).format(
                                  "DD/MM/YYYY h:mm:ss a"
                                )}
                              </Index.Typography>
                            </Index.TableCell>

                            <Index.TableCell>
                              <Index.Typography>
                                {Index.moment(items?.updatedAt).format(
                                  "DD/MM/YYYY h:mm:ss a"
                                )}
                              </Index.Typography>
                            </Index.TableCell>

                            <Index.TableCell>
                              {items?.requestStatus
                                ? items?.requestStatus
                                : "-"}
                            </Index.TableCell>
                            <Index.TableCell>
                              <Index.Button
                                variant="contained"
                                onClick={() => statusApproved(items)}
                                className="challan-status"
                                disabled={items?.checkRequest === true}
                              >
                                Approved
                              </Index.Button>
                              <Index.Button
                                variant="contained"
                                align="left"
                                className="challan-status reject-btn-align"
                                onClick={() => statusRejected(items)}
                                disabled={items?.checkRequest === true}
                              >
                                Rejected
                              </Index.Button>
                            </Index.TableCell>
                          </Index.TableRow>
                        );
                      })
                    ) : (
                      <Index.TableRow>
                        <Index.TableCell
                          component="td"
                          variant="td"
                          scope="row"
                          className="no-data-in-list"
                          colSpan={6}
                          align="center"
                        >
                          No data available
                        </Index.TableCell>
                      </Index.TableRow>
                    )}
                  </Index.TableBody>
                </Index.Table>
              </Index.TableContainer>
            </SingleOrderTab>

            <SingleOrderTab
              value={value}
              index={2}
              className="barge-common-box tab-content-box"
            >
              <Index.TableContainer>
                <Index.Table className="table-design-main invoice-activity-log invoice-activity-log-set">
                  <Index.TableHead>
                    <Index.TableRow>
                      <Index.TableCell>Activity Type</Index.TableCell>
                      <Index.TableCell>Details</Index.TableCell>
                      <Index.TableCell>Date & Time</Index.TableCell>
                    </Index.TableRow>
                  </Index.TableHead>
                  <Index.TableBody>
                    {expenseLogData?.length ? (
                      expenseLogData &&
                      expenseLogData?.map((items, index) => {
                        return (
                          <Index.TableRow>
                            <Index.TableCell>
                              <Index.Typography>
                                {items?.activityType}
                              </Index.Typography>
                            </Index.TableCell>
                            <Index.TableCell>
                              <Index.Typography>
                                {items?.details}
                              </Index.Typography>
                            </Index.TableCell>
                            <Index.TableCell>
                              <Index.Typography>
                                {Index.moment(items?.createdAt).format(
                                  "DD/MM/YYYY h:mm:ss a"
                                )}
                              </Index.Typography>
                            </Index.TableCell>
                          </Index.TableRow>
                        );
                      })
                    ) : (
                      <Index.TableRow>
                        <Index.TableCell
                          component="td"
                          variant="td"
                          scope="row"
                          className="no-data-in-list"
                          colSpan={15}
                          align="center"
                        >
                          No data available
                        </Index.TableCell>
                      </Index.TableRow>
                    )}
                  </Index.TableBody>
                </Index.Table>
              </Index.TableContainer>
            </SingleOrderTab>
          </Index.Box>
          {/* Design Tab */}
        </Index.Box>
        <ApproveRejectModal
          openApproveRejectModal={openApproveRejectModal}
          handleClose={handleCloseApproveRejectModal}
          handleReject={handleReject}
          handleApprove={handleApprove}
          selectedExpenseId={selectedExpenseId}
          selectedExpenseType={selectedExpenseType}
        />
      </Index.Box>
    </>
  );
}

export default OtherExpenseDetail;
