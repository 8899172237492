import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import {
  AddPaymentStatus,
  getCreditNoteInvoice,
} from "../../../../redux/admin/action";
import { NEW_EXPORT_EXCEL } from "../ExportAll";
import { CircularProgress } from "@mui/material";
import dayjs from "dayjs";
import { SingleInputDateRangeField } from "@mui/x-date-pickers-pro/SingleInputDateRangeField";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { toast } from "react-toastify";

const label = { inputProps: { "aria-label": "Switch demo" } };
const Search = Index.styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: Index.alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: Index.alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const StyledInputBase = Index.styled(Index.InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

const shortcutsItems = [
  {
    label: "This Week",
    getValue: () => {
      const today = dayjs();
      return [today.startOf("week"), today.endOf("week")];
    },
  },
  {
    label: "Last Week",
    getValue: () => {
      const today = dayjs();
      const prevWeek = today.subtract(7, "day");
      return [prevWeek.startOf("week"), prevWeek.endOf("week")];
    },
  },
  {
    label: "Last 7 Days",
    getValue: () => {
      const today = dayjs();
      return [today.subtract(7, "day"), today];
    },
  },
  {
    label: "Current Month",
    getValue: () => {
      const today = dayjs();
      return [today.startOf("month"), today.endOf("month")];
    },
  },
  {
    label: "Next Month",
    getValue: () => {
      const today = dayjs();
      const startOfNextMonth = today.endOf("month").add(1, "day");
      return [startOfNextMonth, startOfNextMonth.endOf("month")];
    },
  },
  { label: "Reset", getValue: () => [null, null] },
];

function CreditNotInvoice() {
  const dispatch = Index.useDispatch();
  const navigate = Index.useNavigate();
  const { activeCompany } = Index.useSelector((state) => state.AdminReducer);

  const [loading, setLoading] = useState(false);
  const [loadingRowId, setLoadingRowId] = useState();
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [serachedDateRange, setSearchedDateRange] = useState("");
  const [creditNoteInvoiceData, setCreditNoteInvoiceData] = useState([]);
  const [searchingText, setSearchingText] = useState("");
  const [totalRecordCount, setTotalRecordCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [paginationPerPage, setPaginationPerPage] = useState(50);

  const handleGetCreditNoteInvoices = (values) => {
    setLoading(true);
    dispatch(getCreditNoteInvoice({ ...values, companyId: activeCompany?._id }))
      .then((res) => {
        if (res?.status === 200) {
          setLoading(false);
          setTotalRecordCount(res?.totalCount);
          setCurrentPage(res?.currentPage);
          setCreditNoteInvoiceData(res?.data);
        } else {
          setLoading(false);
          setTotalRecordCount(0);
          setCurrentPage(1);
          setCreditNoteInvoiceData([]);
        }
      })
      .catch((error) => console.log(error));
  };

  // Get invoice list
  useEffect(() => {
    const values = {
      page: 1,
      search: "",
      size: paginationPerPage,
      to:
        serachedDateRange?.length > 0
          ? serachedDateRange[0].format("YYYY-MM-DD")
          : "",
      from:
        serachedDateRange?.length > 0
          ? serachedDateRange[1].format("YYYY-MM-DD")
          : "",
    };

    handleGetCreditNoteInvoices(values);
  }, []);

  const handlePageChange = React.useCallback(
    (event, value) => {
      setCurrentPage(value);
      const values = {
        page: value,
        search: searchingText,
        size: paginationPerPage,
        to:
          serachedDateRange?.length > 0
            ? serachedDateRange[0].format("YYYY-MM-DD")
            : "",
        from:
          serachedDateRange?.length > 0
            ? serachedDateRange[1].format("YYYY-MM-DD")
            : "",
      };
      handleGetCreditNoteInvoices(values);
    },
    [searchingText, paginationPerPage, serachedDateRange, setCurrentPage]
  );

  const handleDeleteClose = () => {
    setDeleteOpen(false);
  };

  const getOverDueDays = (NewDate) => {
    const startDate = Index.moment(new Date());
    var currentDate = Index.moment(NewDate);
    var days = startDate.diff(currentDate, "days");
    // console.log(days, startDate, NewDate, currentDate,  "Date console");
    return days;
  };

  // handle payment status
  const handlePaymentstatus = (id) => {
    const data = new URLSearchParams();
    setLoadingRowId(id);
    data.append("id", id);
    dispatch(AddPaymentStatus(data)).then((res) => {
      if (res?.status === 200) {
        const values = {
          page: 1,
          search: "",
          size: paginationPerPage,
          to:
            serachedDateRange?.length > 0
              ? serachedDateRange[0].format("YYYY-MM-DD")
              : "",
          from:
            serachedDateRange?.length > 0
              ? serachedDateRange[1].format("YYYY-MM-DD")
              : "",
        };

        handleGetCreditNoteInvoices(values);
      } else {
        toast.error(res.message);
      }
    });
  };

  // when the user change the per page record change then used..
  const handleChangePaginationPerChange = (e) => {
    const { value } = e.target;
    setPaginationPerPage(value);
    const values = {
      page: 1,
      search: searchingText,
      size: value,
      to:
        serachedDateRange?.length > 0
          ? serachedDateRange[0].format("YYYY-MM-DD")
          : "",
      from:
        serachedDateRange?.length > 0
          ? serachedDateRange[1].format("YYYY-MM-DD")
          : "",
    };
    handleGetCreditNoteInvoices(values);
  };

  // when the use change the date then used...
  const handleChangeDate = React.useCallback(
    (data) => {
      console.log("data", data);
      setSearchedDateRange(data);
      if (data[0] !== null && data[1] !== null) {
        const values = {
          page: 1,
          search: searchingText,
          size: paginationPerPage,
          to: data[0] !== null ? data[0].format("YYYY-MM-DD") : "",
          from: data[1] !== null ? data[1].format("YYYY-MM-DD") : "",
        };
        handleGetCreditNoteInvoices(values);
      } else if (data[0] === null && data[1] === null) {
        const values = {
          page: 1,
          search: "",
          size: paginationPerPage,
          to:
            serachedDateRange?.length > 0
              ? serachedDateRange[0].format("YYYY-MM-DD")
              : "",
          from:
            serachedDateRange?.length > 0
              ? serachedDateRange[1].format("YYYY-MM-DD")
              : "",
        };
        handleGetCreditNoteInvoices(values);
      }
    },
    [setSearchedDateRange, searchingText, paginationPerPage]
  );

  // when the user search the journal records then used...
  const handleSearchCompletedListData = (e) => {
    const { value } = e.target;
    setSearchingText(value);
    if (value?.length > 0) {
      const values = {
        page: 1,
        search: value,
        size: paginationPerPage,
        to:
          serachedDateRange?.length > 0
            ? serachedDateRange[0].format("YYYY-MM-DD")
            : "",
        from:
          serachedDateRange?.length > 0
            ? serachedDateRange[1].format("YYYY-MM-DD")
            : "",
      };
      handleGetCreditNoteInvoices(values);
    } else {
      const values = {
        page: 1,
        search: "",
        size: paginationPerPage,
        to:
          serachedDateRange?.length > 0
            ? serachedDateRange[0].format("YYYY-MM-DD")
            : "",
        from:
          serachedDateRange?.length > 0
            ? serachedDateRange[1].format("YYYY-MM-DD")
            : "",
      };
      handleGetCreditNoteInvoices(values);
    }
  };

  return (
    <div>
      <Index.DynamicTitle title="Credit Note" />
      <Index.Box className="">
        <Index.Box className="barge-common-box">
          {/* Filter */}
          <Index.Box className="title-header">
            <Index.Box className="title-header-flex  res-title-header-flex">
              <Index.Box className="title-main">
                <Index.Typography
                  variant="p"
                  component="p"
                  className="page-title"
                >
                  Credit Note
                </Index.Typography>
              </Index.Box>
              <Index.Box className="d-flex align-items-center res-set-search">
                <Index.Box className="input-design-div">
                  <Index.Box className="set-date set-date-picker remove-name">
                    <Index.LocalizationProvider
                      dateAdapter={Index.AdapterDayjs}
                    >
                      <Index.DemoContainer
                        components={["SingleInputDateRangeField"]}
                        slotProps={{ textField: { size: "small" } }}
                      >
                        <DateRangePicker
                          slots={{ field: SingleInputDateRangeField }}
                          slotProps={{
                            shortcuts: {
                              items: shortcutsItems,
                            },
                            textField: {
                              readOnly: true,
                              size: "small",
                            },
                            actionBar: { actions: [] },
                          }}
                          disableFuture
                          onChange={(value) => handleChangeDate(value)}
                          name="allowedRange"
                          format="DD/MM/YYYY"
                        />
                      </Index.DemoContainer>
                    </Index.LocalizationProvider>
                  </Index.Box>
                </Index.Box>
                <Search className="search">
                  <StyledInputBase
                    placeholder="Search"
                    inputProps={{ "aria-label": "search" }}
                    onChange={(e) => handleSearchCompletedListData(e)}
                  />
                </Search>

                <Index.Box className="common-button grey-button">
                  <Index.Button
                    variant="contained"
                    onClick={() => {
                      let curDate = Index.moment(new Date()).format(
                        "DD-MM-YYYY"
                      );
                      NEW_EXPORT_EXCEL(
                        "/admin/export-credit-invoice",
                        `Invoice_Credit_Note_List_${curDate}`,
                        {
                          search: searchingText,
                          to:
                            serachedDateRange?.length > 0
                              ? serachedDateRange[0].format("YYYY-MM-DD")
                              : "",
                          from:
                            serachedDateRange?.length > 0
                              ? serachedDateRange[1].format("YYYY-MM-DD")
                              : "",
                          companyId: activeCompany?._id,
                        }
                      );
                    }}
                  >
                    Export
                  </Index.Button>
                </Index.Box>
                <Index.Box className="common-button blue-button res-blue-button">
                  <Index.RouteLink
                    to="/admin/invoice/add-invoice"
                    className="no-text-decoration"
                  >
                    {" "}
                    <Index.Button variant="contained" disableRipple>
                      Add invoice
                    </Index.Button>
                  </Index.RouteLink>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>

          {/* Listing */}
          <Index.Box className="">
            <Index.TableContainer
              component={Index.Paper}
              className="table-container"
            >
              <Index.Table
                aria-label="simple table"
                className="table-design-main invoice-table invoice-table-set  "
              >
                <Index.TableHead>
                  <Index.TableRow>
                    <Index.TableCell className="invoice_table_header">
                      Credit Note No
                    </Index.TableCell>
                    <Index.TableCell className="invoice_table_header">
                      Credit Note Stamp
                    </Index.TableCell>
                    <Index.TableCell className="invoice_table_header">
                      Invoice No
                    </Index.TableCell>
                    <Index.TableCell
                      align="left"
                      className="invoice_table_header"
                    >
                      Invoice Date
                    </Index.TableCell>
                    <Index.TableCell
                      align="left"
                      className="invoice_table_header"
                    >
                      Party Name
                    </Index.TableCell>
                    <Index.TableCell
                      align="left"
                      className="invoice_table_header"
                    >
                      Particulars
                    </Index.TableCell>
                    <Index.TableCell
                      align="left"
                      className="invoice_table_header"
                    >
                      Rate
                    </Index.TableCell>
                    <Index.TableCell
                      align="left"
                      className="invoice_table_header"
                    >
                      Taxable Value
                    </Index.TableCell>

                    <Index.TableCell
                      align="left"
                      className="invoice_table_header"
                    >
                      CGST
                    </Index.TableCell>

                    <Index.TableCell
                      align="left"
                      className="invoice_table_header"
                    >
                      SGST
                    </Index.TableCell>

                    <Index.TableCell
                      align="left"
                      className="invoice_table_header"
                    >
                      IGST
                    </Index.TableCell>

                    <Index.TableCell
                      align="left"
                      className="invoice_table_header"
                    >
                      Invoice Amount
                    </Index.TableCell>

                    <Index.TableCell
                      align="left"
                      className="invoice_table_header"
                    >
                      Over Due (InDays)
                    </Index.TableCell>

                    <Index.TableCell
                      align="left"
                      className="invoice_table_header"
                    >
                      Credit Note Status
                    </Index.TableCell>
                    <Index.TableCell
                      align="left"
                      className="invoice_table_header"
                    >
                      Payment Status
                    </Index.TableCell>

                    <Index.TableCell
                      align="left"
                      className="invoice_table_header"
                    >
                      Action
                    </Index.TableCell>
                  </Index.TableRow>
                </Index.TableHead>

                {loading ? (
                  <Index.TableBody>
                    <Index.TableRow>
                      <Index.TableCell
                        colSpan={17}
                        align="center"
                        className="loading-purchase"
                      >
                        <Index.CircularProgress color="secondary" size={20} />
                      </Index.TableCell>
                    </Index.TableRow>
                  </Index.TableBody>
                ) : (
                  <Index.TableBody>
                    {creditNoteInvoiceData?.length ? (
                      creditNoteInvoiceData?.map((row, index) => (
                        <Index.TableRow key={row?._id}>
                          <Index.TableCell>
                            {row?.creditNoteNo ? row?.creditNoteNo : "-"}
                          </Index.TableCell>
                          <Index.TableCell>
                            {row?.creditNoteStamp
                              ? Index.moment(row?.creditNoteStamp).format(
                                  "DD/MM/YYYY hh:mm A"
                                )
                              : "-"}
                          </Index.TableCell>
                          <Index.TableCell
                            className="invoice-view-cursor"
                            onClick={() => {
                              navigate(
                                `/admin/invoice/view-invoice/${row?._id}`
                              );
                            }}
                          >
                            {row?.isPaid ? (
                              <Index.Box className="paiddot"></Index.Box>
                            ) : (
                              <Index.Box className="unpaiddot"></Index.Box>
                            )}
                            {row?.invoiceNo ? row?.invoiceNo : "-"}
                          </Index.TableCell>
                          <Index.TableCell>
                            {row?.invoiceDate
                              ? Index.moment(row?.invoiceDate).format(
                                  "DD/MM/YYYY"
                                )
                              : "-"}
                          </Index.TableCell>{" "}
                          <Index.TableCell>
                            {row?.partyName ? row?.partyName : "-"}
                          </Index.TableCell>
                          <Index.TableCell>
                            {row?.particular ? row?.particular : "-"}
                          </Index.TableCell>
                          <Index.TableCell>
                            {row?.rate
                              ? row?.rate?.toLocaleString("en-IN", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : 0?.toLocaleString("en-IN", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                          </Index.TableCell>
                          <Index.TableCell>
                            {row?.totalTaxableValue
                              ? row?.totalTaxableValue?.toLocaleString(
                                  "en-IN",
                                  {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  }
                                )
                              : 0?.toLocaleString("en-IN", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                          </Index.TableCell>
                          <Index.TableCell>
                            {row?.totalCGSTAmount
                              ? row?.totalCGSTAmount?.toLocaleString("en-IN", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : 0?.toLocaleString("en-IN", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                          </Index.TableCell>
                          <Index.TableCell>
                            {row?.totalSGSTAmount
                              ? row?.totalSGSTAmount?.toLocaleString("en-IN", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : 0?.toLocaleString("en-IN", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                          </Index.TableCell>
                          <Index.TableCell>
                            {row?.totalIGSTAmount
                              ? row?.totalIGSTAmount?.toLocaleString("en-IN", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : 0?.toLocaleString("en-IN", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                          </Index.TableCell>
                          <Index.TableCell>
                            {row?.invoiceAmount
                              ? row?.invoiceAmount?.toLocaleString("en-IN", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : 0?.toLocaleString("en-IN", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                          </Index.TableCell>
                          <Index.TableCell>
                            {/* {row?.overDue && row?.isPaid === false
                            ? row?.overDue + " Days"
                            : "-"} */}
                            {row?.isPaid === false
                              ? getOverDueDays(row?.invoiceDate) + " Days"
                              : "-"}
                          </Index.TableCell>
                          <Index.TableCell>
                            {row?.creditNote == true ? "True" : "False"}
                          </Index.TableCell>
                          <Index.TableCell>
                            {loading && loadingRowId === row?._id ? (
                              <CircularProgress
                                className="payment-loader"
                                color="secondary"
                                size={20}
                                sx={{ marginLeft: "18px" }}
                              />
                            ) : (
                              <Index.Switch
                                {...label}
                                checked={row?.isPaid}
                                onClick={() => handlePaymentstatus(row?._id)}
                                color="success"
                                size="small"
                              />
                            )}
                          </Index.TableCell>
                          <Index.TableCell sx={{ display: "flex" }}>
                            <Index.IconButton>
                              <Index.VisibilitySharpIcon
                                onClick={() => {
                                  navigate(
                                    `/admin/invoice/view-invoice/${row?._id}`
                                  );
                                }}
                              />
                            </Index.IconButton>
                          </Index.TableCell>
                        </Index.TableRow>
                      ))
                    ) : (
                      <Index.TableRow>
                        <Index.TableCell
                          component="td"
                          variant="td"
                          scope="row"
                          className="no-data-in-list empty-record-align"
                          colSpan={19}
                          align="center"
                        >
                          No data available
                        </Index.TableCell>
                      </Index.TableRow>
                    )}
                  </Index.TableBody>
                )}
              </Index.Table>
            </Index.TableContainer>
          </Index.Box>
          <Index.Box className="pagination-design flex-end">
            <Index.Stack spacing={2}>
              <Index.Box className="pagination-count">
                <Index.Box className="input-design-div with-border">
                  <Index.Select
                    fullWidth
                    value={paginationPerPage}
                    onChange={(e) => handleChangePaginationPerChange(e)}
                  >
                    <Index.MenuItem value={10}>10</Index.MenuItem>
                    <Index.MenuItem value={25}>25</Index.MenuItem>
                    <Index.MenuItem value={50}>50</Index.MenuItem>
                    <Index.MenuItem value={100}>100</Index.MenuItem>
                  </Index.Select>
                </Index.Box>
                {loading ? (
                  <Index.CircularProgress
                    color="secondary"
                    sx={{ marginLeft: "10px" }}
                    size={26}
                  />
                ) : (
                  <>
                    <Index.Pagination
                      count={Math.ceil(totalRecordCount / paginationPerPage)}
                      page={currentPage}
                      onChange={handlePageChange}
                    />
                  </>
                )}
              </Index.Box>
            </Index.Stack>
          </Index.Box>
        </Index.Box>
      </Index.Box>
      <Index.DeleteModal
        deleteOpen={deleteOpen}
        // handleDeleteRecord={handleDeleteRecord}
        handleDeleteClose={handleDeleteClose}
      />
      <Index.ParticularModel
        deleteOpen={deleteOpen}
        // handleDeleteRecord={handleDeleteRecord}
        handleDeleteClose={handleDeleteClose}
      />
    </div>
  );
}

export default CreditNotInvoice;
