import React, { useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Index from "../../../../Index";
import BreakDown from "./BreakDown";

// Design Tab
function SingleOrderTab(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Index.Box sx={{ px: 3, py: 2 }}>
          <Index.Typography>{children}</Index.Typography>
        </Index.Box>
      )}
    </div>
  );
}

SingleOrderTab.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function MaintenanceTab({ craftId }) {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Index.Box className="invoice-details">
        <Index.Box className="mb-20">
          {/* Design Tab */}
          <Index.Box sx={{ width: "100%" }}>
            <Index.Box
              className="tab-button-box"
              sx={{ borderBottom: 1, borderColor: "divider" }}
            >
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                className="dashboard-tab"
              >
                <Tab
                  className="tab-button"
                  label="BreakDown"
                  {...a11yProps(0)}
                />
                <Tab
                  className="tab-button"
                  label="Preventive"
                  {...a11yProps(1)}
                />
              </Tabs>
            </Index.Box>
            <SingleOrderTab
              value={value}
              index={0}
              className="barge-common-box tab-content-box"
            >
              <BreakDown craftId={craftId} />
            </SingleOrderTab>
            <SingleOrderTab
              value={value}
              index={1}
              className="barge-common-box tab-content-box"
            >
              {/* <CrewList craftId={craftData?._id}  /> */}
            </SingleOrderTab>
          </Index.Box>
          {/* Design Tab */}
        </Index.Box>
      </Index.Box>
    </>
  );
}

export default MaintenanceTab;
