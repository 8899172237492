import React, { useEffect, useState } from "react";
import Index from "../../../../Index";
import { FieldArray, Formik } from "formik";
import {
  addEditCraftMaintenance,
  getAllAssigneeUsersList,
  getAllCraftMaintenanceList,
} from "../../../../../redux/admin/action";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function BreakDown({ craftId }) {
  const dispatch = Index.useDispatch();
  const [breakDownDataList, setBreakDownDataList] = useState([]);
  const [workingUserList, setWorkingUserList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openModel, setOpenModel] = useState(false);
  const [deleteMaintenanceId, setDeleteMaintenanceId] = useState("");
  const [editMaintenanceData, setEditMaintenanceData] = useState();
  const [openModelRemark, setOpenModelRemark] = useState(false);
  const [remarkData, setRemarkData] = useState();

  // State for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState(0);
  const [paginationPerPage, setPaginationPerPage] = useState(50);
  // State for searching and set data
  const [currentRows, setCurrentRows] = useState([]);

  const jobTypeList = [
    { id: 1, name: "Electrical" },
    { id: 2, name: "Mechanical" },
    { id: 3, name: "Fabrication" },
    { id: 4, name: "Procurement" },
  ];

  // Add edit maintenance Model
  const handleModelOpen = () => {
    setOpenModel(true);
  };
  const handleModelClose = () => {
    setOpenModel(false);
    setEditMaintenanceData();
  };
  //Remark text
  const handleModelRemarkOpen = () => {
    setOpenModelRemark(true);
  };
  const handleModelRemarkClose = () => {
    setOpenModelRemark(false);
    setRemarkData();
  };

  let initialValues;
  if (editMaintenanceData) {
    // initialValues = editMaintenanceData;
    initialValues = {
      whatIsDone: editMaintenanceData ? editMaintenanceData?.whatIsDone : "",
      remark: editMaintenanceData ? editMaintenanceData?.remark : "",
      workingUser:
        editMaintenanceData && editMaintenanceData?.workingUser?.length > 0
          ? editMaintenanceData?.workingUser?.map((ele) => {
              return ele?._id;
            })
          : [],
      jobType: editMaintenanceData ? editMaintenanceData?.jobType : "",
      planedDate: editMaintenanceData ? editMaintenanceData?.planedDate : "",
      startDate: editMaintenanceData ? editMaintenanceData?.startDate : "",
      needToRepair:
        editMaintenanceData && editMaintenanceData?.needToRepair.length > 0
          ? editMaintenanceData?.needToRepair
          : [{ point: "" }],
      needToSpare:
        editMaintenanceData && editMaintenanceData?.needToSpare.length > 0
          ? editMaintenanceData?.needToSpare
          : [{ point: "" }],
    };
  } else {
    initialValues = {
      whatIsDone: "",
      remark: "",
      workingUser: [],
      jobType: "",
      planedDate: "",
      startDate: "",
      needToRepair: [{ point: "" }],
      needToSpare: [{ point: "" }],
    };
  }

  const handleFormSubmit = (values) => {
    const urlencoded = new URLSearchParams();
    if (editMaintenanceData) {
      urlencoded.append("maintenanceId", editMaintenanceData?._id);
    }
    urlencoded.append("barge", craftId);
    urlencoded.append("type", "Breakdown");
    for (let keys in values) {
      if (
        keys !== "needToRepair" &&
        keys !== "needToSpare" &&
        keys !== "workingUser" &&
        values[keys]
      ) {
        urlencoded.append(keys, values[keys]);
      }
    }

    values?.workingUser?.forEach((ele, i) => {
      urlencoded.append(`workingUser`, ele);
    });
    values?.needToRepair?.forEach((ele, i) => {
      if (ele?.point) {
        urlencoded.append(`needToRepair[${i}][point]`, ele?.point);
      }
    });
    values?.needToSpare?.forEach((ele, i) => {
      if (ele?.point) {
        urlencoded.append(`needToSpare[${i}][point]`, ele?.point);
      }
    });

    dispatch(addEditCraftMaintenance(urlencoded)).then((res) => {
      if (res?.status == 200 || res?.status == 201) {
        fetchAllCraftMaintenance();
      }
    });
    handleModelClose();
  };

  // Get User List
  const getUsers = () => {
    dispatch(getAllAssigneeUsersList()).then((res) => {
      if (res?.status === 200) {
        setWorkingUserList(res?.data);
      } else {
        setWorkingUserList([]);
      }
    });
  };

  //get all craft maintenance
  const fetchAllCraftMaintenance = () => {
    setLoading(true);
    let data = {
      id: craftId,
      type: "Breakdown",
    };
    try {
      dispatch(getAllCraftMaintenanceList(data)).then((res) => {
        if (res?.status === 200) {
          setBreakDownDataList(res?.data);
          setTimeout(() => {
            setLoading(false);
          }, 1000);
        } else {
          setTimeout(() => {
            setLoading(false);
          }, 1000);
        }
      });
    } catch (error) {}
  };

  // Pagination calculation start

  const PER_PAGE = paginationPerPage;
  useEffect(() => {
    //Page count
    const count = Math.ceil(breakDownDataList?.length / PER_PAGE);
    setCount(count);
    const indexOfLastRow = currentPage * PER_PAGE;
    const indexOfFirstRow = indexOfLastRow - PER_PAGE;
    const modifiedRows = breakDownDataList?.slice(
      indexOfFirstRow,
      indexOfLastRow
    );
    setCurrentRows(modifiedRows);
    if (modifiedRows?.length === 0 && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  }, [currentPage, breakDownDataList, paginationPerPage, PER_PAGE]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  // Pagination calculation end

  useEffect(() => {
    fetchAllCraftMaintenance();
    getUsers();
  }, []);
  return (
    <>
      <Index.Box className="barge-common-box">
        <Index.Box className="title-header">
          <Index.Box className="title-header-flex res-title-header-flex">
            <Index.Box className="title-main">
              <Index.Typography
                variant="p"
                component="p"
                className="page-title"
              >
                {/* Document */}
              </Index.Typography>
            </Index.Box>
            <Index.Box className="common-button blue-button res-blue-button">
              <Index.Button
                variant="outlined"
                onClick={() => {
                  handleModelOpen();
                }}
                className="view-invoice-btn"
              >
                Add
              </Index.Button>
            </Index.Box>
          </Index.Box>
        </Index.Box>
        <Index.Box className="">
          <Index.Grid container spacing={1}>
            <Index.Box className="order-active-log-header expensedetails-table order-active-table-set">
              <Index.Box className="">
                <Index.TableContainer
                  component={Index.Paper}
                  className="table-container"
                >
                  <Index.Table
                    aria-label="simple table"
                    className="table-design-main supply-table one-line-table"
                  >
                    <Index.TableHead>
                      <Index.TableRow>
                        <Index.TableCell>S. No</Index.TableCell>
                        <Index.TableCell align="left">
                          Working Team
                        </Index.TableCell>
                        <Index.TableCell align="left">Job Type</Index.TableCell>
                        <Index.TableCell align="left">
                          Planned Date
                        </Index.TableCell>
                        <Index.TableCell align="left">
                          Start Date
                        </Index.TableCell>
                        <Index.TableCell align="left">Remark</Index.TableCell>
                        <Index.TableCell align="left">
                          What Is Done
                        </Index.TableCell>
                        <Index.TableCell align="left">Action</Index.TableCell>
                      </Index.TableRow>
                    </Index.TableHead>

                    {loading ? (
                      <Index.TableBody>
                        <Index.TableRow>
                          <Index.TableCell colSpan={14} align="center">
                            <Index.CircularProgress
                              color="secondary"
                              size={20}
                            />
                          </Index.TableCell>
                        </Index.TableRow>
                      </Index.TableBody>
                    ) : (
                      <Index.TableBody>
                        {currentRows?.length ? (
                          currentRows?.map((item, index) => (
                            <Index.TableRow key={item?._id}>
                              <Index.TableCell align="left">
                                {item?.maintenanceNumber || "-"}
                              </Index.TableCell>
                              <Index.TableCell align="left">
                                {item?.workingUser &&
                                item?.workingUser?.length > 0
                                  ? Array.prototype.map
                                      .call(item?.workingUser, function (item) {
                                        return item.firstName;
                                      })
                                      .join(", ")
                                  : "-"}
                              </Index.TableCell>
                              <Index.TableCell align="left">
                                {item?.jobType || "-"}
                              </Index.TableCell>
                              <Index.TableCell align="left">
                                {item?.planedDate
                                  ? Index.moment(item?.planedDate).format(
                                      "DD/MM/YYYY"
                                    )
                                  : "-"}
                              </Index.TableCell>
                              <Index.TableCell align="left">
                                {item?.startDate
                                  ? Index.moment(item?.startDate).format(
                                      "DD/MM/YYYY"
                                    )
                                  : "-"}
                              </Index.TableCell>
                              <Index.TableCell
                                align="left"
                                sx={{
                                  cursor:
                                    item?.remark && item?.remark?.length > 10
                                      ? "pointer"
                                      : "",
                                }}
                                onClick={() => {
                                  if (
                                    item?.remark &&
                                    item?.remark?.length > 10
                                  ) {
                                    handleModelRemarkOpen();
                                    setRemarkData(item?.remark);
                                  }
                                }}
                              >
                                {item?.remark
                                  ? item?.remark?.length > 10
                                    ? `${item?.remark?.slice(0, 10)}...`
                                    : item?.remark
                                  : "-"}
                              </Index.TableCell>

                              <Index.TableCell align="left">
                                {item?.whatIsDone || "-"}
                              </Index.TableCell>

                              <Index.TableCell>
                                <Index.IconButton>
                                  <Index.EditIcon
                                    className="edit-icon-set"
                                    onClick={() => {
                                      handleModelOpen();
                                      setEditMaintenanceData(item);
                                    }}
                                  />
                                </Index.IconButton>
                              </Index.TableCell>
                            </Index.TableRow>
                          ))
                        ) : (
                          <Index.TableRow>
                            <Index.TableCell
                              component="td"
                              variant="td"
                              scope="row"
                              className="no-data-in-list"
                              colSpan={15}
                              align="center"
                            >
                              No data available
                            </Index.TableCell>
                          </Index.TableRow>
                        )}
                      </Index.TableBody>
                    )}
                  </Index.Table>
                </Index.TableContainer>
              </Index.Box>
              <Index.Box className="pagination-design flex-end">
                <Index.Stack spacing={2}>
                  <Index.Box className="pagination-count">
                    <Index.Box className="input-design-div with-border">
                      <Index.Select
                        fullWidth
                        value={paginationPerPage}
                        onChange={(e) => {
                          setPaginationPerPage(e.target.value);
                        }}
                      >
                        <Index.MenuItem value={10}>10</Index.MenuItem>
                        <Index.MenuItem value={25}>25</Index.MenuItem>
                        <Index.MenuItem value={50}>50</Index.MenuItem>
                        <Index.MenuItem value={100}>100</Index.MenuItem>
                      </Index.Select>
                    </Index.Box>
                    <Index.Pagination
                      count={count}
                      page={currentPage}
                      onChange={handlePageChange}
                    />
                  </Index.Box>
                </Index.Stack>
              </Index.Box>
            </Index.Box>
          </Index.Grid>
        </Index.Box>
      </Index.Box>

      {/* Add Crew Model */}
      <Index.Dialog
        open={openModel}
        onClose={handleModelClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Index.DialogContent className="expense-model-main  expense-model-main-box-set">
          <Index.Box className="title-main">
            <Formik
              enableReinitialize
              initialValues={initialValues}
              onSubmit={handleFormSubmit}
            >
              {({
                values,
                errors,
                touched,
                setFieldValue,
                handleChange,
                handleBlur,
                handleSubmit,
                handleFocus,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Index.Box className="title-main">
                    <Index.Box
                      className="cus-between"
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <Index.Typography
                        variant="h6"
                        component="h6"
                        className="input-label input-label-bold p-0"
                        sx={{ paddingBottom: "0px" }}
                      >
                        {`${editMaintenanceData ? "Edit" : "Add"} Maintenance`}
                      </Index.Typography>
                      <Index.ClearIcon
                        className="model-close-icon"
                        onClick={handleModelClose}
                      />
                    </Index.Box>
                    <Index.Grid container spacing={2}>
                      <Index.Grid item xs={12} sm={6} md={12}>
                        <Index.Typography
                          variant="label"
                          component="label"
                          className="input-label"
                        >
                          Fault
                        </Index.Typography>
                        <Index.Box className="input-design-div with-border">
                          <Index.TextField
                            hiddenLabel
                            id="filled-hidden-label-normal"
                            placeholder="Enter Fault"
                            variant="filled"
                            className="admin-input-design input-placeholder"
                            name="whatIsDone"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values?.whatIsDone}
                          />
                        </Index.Box>
                      </Index.Grid>
                      <Index.Grid item xs={12} sm={12} md={12}>
                        <Index.Typography
                          variant="label"
                          component="label"
                          className="input-label"
                        >
                          Remark
                        </Index.Typography>
                        <Index.Box className="input-design-div with-border">
                          <Index.TextareaAutosize
                            hiddenLabel
                            minRows={3}
                            id="filled-hidden-label-normal"
                            className="admin-input-design input-placeholder input-placeholder-box  input-placeholder-box-set "
                            placeholder="Enter Remark"
                            variant="filled"
                            value={values?.remark}
                            name="remark"
                            onChange={handleChange}
                          />
                        </Index.Box>
                      </Index.Grid>

                      <Index.Grid item xs={12} sm={6} md={12}>
                        <Index.Typography
                          variant="label"
                          component="label"
                          className="input-label"
                        >
                          Working Team
                        </Index.Typography>
                        <Index.Box className="input-design-div multiselect-input-placeholder with-border">
                          <Index.Select
                            fullWidth
                            name="proposedBarge"
                            multiple
                            displayEmpty
                            inputProps={{ "aria-label": "without label" }}
                            value={
                              values?.workingUser ? values?.workingUser : []
                            }
                            renderValue={
                              values?.workingUser != ""
                                ? undefined
                                : () => "Select Working Team"
                            }
                            onFocus={handleFocus}
                            MenuProps={MenuProps}
                            onChange={(event) => {
                              const {
                                target: { value },
                              } = event;
                              setFieldValue(
                                "workingUser",
                                typeof value === "string"
                                  ? value.split(",")
                                  : value
                              );
                            }}
                          >
                            <Index.MenuItem disabled value="">
                              <em>Select Working Team</em>
                            </Index.MenuItem>
                            {workingUserList?.length === 0 ? (
                              <Index.MenuItem disabled>
                                <Index.Box className="multiselect-item">
                                  No data found
                                </Index.Box>
                              </Index.MenuItem>
                            ) : (
                              workingUserList.map((item) => (
                                <Index.MenuItem
                                  key={item?._id}
                                  value={item?._id}
                                >
                                  <Index.Box className="multiselect-item">
                                    {item?.employeeName}
                                  </Index.Box>
                                </Index.MenuItem>
                              ))
                            )}
                          </Index.Select>
                          {touched?.proposedBarge && errors?.proposedBarge && (
                            <Index.FormHelperText error>
                              {errors?.proposedBarge}
                            </Index.FormHelperText>
                          )}
                        </Index.Box>
                      </Index.Grid>
                      <Index.Grid item xs={12} sm={6} md={12}>
                        <Index.Typography
                          variant="label"
                          component="label"
                          className="input-label"
                        >
                          Job Type
                        </Index.Typography>
                        <Index.Box className="input-design-div with-border">
                          <Index.Autocomplete
                            disablePortal
                            className="invoice-select-drop"
                            id="combo-box-demo"
                            options={jobTypeList}
                            getOptionLabel={(option) => option?.name}
                            renderOption={(props, option) => (
                              <Index.Box
                                component="li"
                                sx={{
                                  "& > img": { mr: 2, flexShrink: 0 },
                                }}
                                {...props}
                              >
                                {option?.name}
                              </Index.Box>
                            )}
                            value={
                              jobTypeList && jobTypeList?.length > 0
                                ? jobTypeList?.find(
                                    (value) => value?.name == values?.jobType
                                  )
                                : ""
                            }
                            onChange={(event, newValue) => {
                              setFieldValue("jobType", newValue?.name);
                            }}
                            renderInput={(params) => (
                              <Index.TextField
                                {...params}
                                placeholder="Select Job Type"
                                variant="outlined"
                              />
                            )}
                          />
                        </Index.Box>
                      </Index.Grid>
                      <Index.Grid item xs={12} sm={6} md={12}>
                        <Index.Typography
                          variant="label"
                          component="label"
                          className="input-label"
                        >
                          Planned Date
                        </Index.Typography>
                        <Index.Box className="input-design-div">
                          <Index.Box className="set-date set-date-picker">
                            <Index.LocalizationProvider
                              dateAdapter={Index.AdapterDayjs}
                            >
                              <Index.DemoContainer components={["DatePicker"]}>
                                <Index.DemoItem label="">
                                  <Index.DatePicker
                                    format="DD/MM/YYYY"
                                    defaultValue={
                                      editMaintenanceData
                                        ? Index.dayjs(
                                            values?.planedDate
                                              ? Index.moment(
                                                  values?.planedDate
                                                ).format("YYYY-MM-DD")
                                              : "-"
                                          )
                                        : ""
                                    }
                                    onChange={(value) => {
                                      const formatedDate = Index.moment(
                                        value.$d
                                      ).format("YYYY-MM-DD");
                                      setFieldValue("planedDate", formatedDate);
                                    }}
                                  />
                                </Index.DemoItem>
                              </Index.DemoContainer>
                            </Index.LocalizationProvider>
                          </Index.Box>
                        </Index.Box>
                      </Index.Grid>
                      <Index.Grid item xs={12} sm={6} md={12}>
                        <Index.Typography
                          variant="label"
                          component="label"
                          className="input-label"
                        >
                          Start Date
                        </Index.Typography>
                        <Index.Box className="input-design-div">
                          <Index.Box className="set-date set-date-picker">
                            <Index.LocalizationProvider
                              dateAdapter={Index.AdapterDayjs}
                            >
                              <Index.DemoContainer components={["DatePicker"]}>
                                <Index.DemoItem label="">
                                  <Index.DatePicker
                                    format="DD/MM/YYYY"
                                    defaultValue={
                                      editMaintenanceData
                                        ? Index.dayjs(
                                            values?.startDate
                                              ? Index.moment(
                                                  values?.startDate
                                                ).format("YYYY-MM-DD")
                                              : "-"
                                          )
                                        : ""
                                    }
                                    onChange={(value) => {
                                      const formatedDate = Index.moment(
                                        value.$d
                                      ).format("YYYY-MM-DD");
                                      setFieldValue("startDate", formatedDate);
                                    }}
                                  />
                                </Index.DemoItem>
                              </Index.DemoContainer>
                            </Index.LocalizationProvider>
                          </Index.Box>
                        </Index.Box>
                      </Index.Grid>
                      {values?.jobType?.length &&
                      values?.jobType !== "Procurement" ? (
                        <>
                          <Index.Grid item xs={12} sm={6} md={12}>
                            <Index.Typography
                              variant="label"
                              component="label"
                              className="input-label"
                            >
                              Need To Repair
                            </Index.Typography>
                            {values?.needToRepair?.length > 0 && (
                              <FieldArray name="needToRepair">
                                {({ push, remove }) => (
                                  <>
                                    {values.needToRepair.map((row, index) => (
                                      <Index.Grid
                                        container
                                        spacing={2}
                                        key={index}
                                      >
                                        <Index.Grid item xs={12} sm={6} md={8}>
                                          <Index.Box className="input-design-div with-border">
                                            <Index.TextField
                                              hiddenLabel
                                              id="filled-hidden-label-normal"
                                              placeholder="Enter Need To Repair"
                                              variant="filled"
                                              className="admin-input-design input-placeholder"
                                              name={`needToRepair.${index}.point`}
                                              value={
                                                values.needToRepair[index].point
                                              }
                                              autoComplete="off"
                                              onBlur={handleBlur}
                                              onChange={handleChange}
                                            />
                                          </Index.Box>
                                        </Index.Grid>
                                        {/* <Index.Grid item xs={12} sm={6} md={3}>
                                          <Index.Box className="incre-btn-main incre-btn-maintenance-main">
                                            {values?.needToRepair?.length - 1 ==
                                              index && (
                                              <Index.Button
                                                sx={{ fontSize: "20px" }}
                                                className="button-add inc-dic-btn"
                                                type="button"
                                                onClick={() =>
                                                  push({
                                                    point: "",
                                                  })
                                                }
                                              >
                                                +
                                              </Index.Button>
                                            )}
                                            {values?.needToRepair?.length >
                                              1 && (
                                              <Index.Button
                                                sx={{ fontSize: "20px" }}
                                                type="button"
                                                className="button-remove inc-dic-btn"
                                                disabled={
                                                  values?.needToRepair?.length >
                                                  1
                                                    ? false
                                                    : true
                                                }
                                                onClick={() => remove(index)}
                                              >
                                                -
                                              </Index.Button>
                                            )}
                                          </Index.Box>
                                        </Index.Grid> */}
                                      </Index.Grid>
                                    ))}
                                  </>
                                )}
                              </FieldArray>
                            )}
                          </Index.Grid>
                        </>
                      ) : (
                        <></>
                      )}
                      {values?.jobType?.length &&
                      values?.jobType === "Procurement" ? (
                        <>
                          <Index.Grid item xs={12} sm={6} md={12}>
                            <Index.Typography
                              variant="label"
                              component="label"
                              className="input-label"
                            >
                              Need To Spare
                            </Index.Typography>
                            {values?.needToSpare?.length > 0 && (
                              <FieldArray name="needToSpare">
                                {({ push, remove }) => (
                                  <>
                                    {values.needToSpare.map((row, index) => (
                                      <Index.Grid
                                        container
                                        spacing={2}
                                        key={index}
                                      >
                                        <Index.Grid item xs={12} sm={6} md={8}>
                                          <Index.Box className="input-design-div with-border">
                                            <Index.TextField
                                              hiddenLabel
                                              id="filled-hidden-label-normal"
                                              placeholder="Enter Need To Spare"
                                              variant="filled"
                                              className="admin-input-design input-placeholder"
                                              name={`needToSpare.${index}.point`}
                                              value={
                                                values.needToSpare[index].point
                                              }
                                              autoComplete="off"
                                              onChange={handleChange}
                                            />
                                          </Index.Box>
                                        </Index.Grid>
                                        {/* <Index.Grid item xs={12} sm={6} md={3}>
                                          <Index.Box className="incre-btn-main incre-btn-maintenance-main">
                                            {values?.needToSpare?.length - 1 ==
                                              index && (
                                              <Index.Button
                                                sx={{ fontSize: "20px" }}
                                                className="button-add inc-dic-btn"
                                                type="button"
                                                onClick={() =>
                                                  push({
                                                    point: "",
                                                  })
                                                }
                                              >
                                                +
                                              </Index.Button>
                                            )}
                                            {values?.needToSpare?.length >
                                              1 && (
                                              <Index.Button
                                                sx={{ fontSize: "20px" }}
                                                type="button"
                                                className="button-remove inc-dic-btn"
                                                disabled={
                                                  values?.needToSpare?.length >
                                                  1
                                                    ? false
                                                    : true
                                                }
                                                onClick={() => remove(index)}
                                              >
                                                -
                                              </Index.Button>
                                            )}
                                          </Index.Box>
                                        </Index.Grid> */}
                                      </Index.Grid>
                                    ))}
                                  </>
                                )}
                              </FieldArray>
                            )}
                          </Index.Grid>
                        </>
                      ) : (
                        <></>
                      )}

                      <Index.Grid item xs={12} sm={6} md={12}>
                        <Index.Box
                          className="common-button blue-button flex-end save-btn"
                          sx={{
                            display: "flex",
                            alignItems: "flex-end",
                          }}
                        >
                          <Index.Button
                            variant="contained"
                            type="submit"
                            className="Particular_model_submit"
                          >
                            Submit
                          </Index.Button>
                        </Index.Box>
                      </Index.Grid>
                    </Index.Grid>

                    {/* <Index.Box className="line" mt={3} mb={3}></Index.Box> */}
                  </Index.Box>
                </form>
              )}
            </Formik>
          </Index.Box>
        </Index.DialogContent>
      </Index.Dialog>

      {/* Remark  text */}
      <Index.Dialog
        open={openModelRemark}
        onClose={handleModelRemarkClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Index.DialogContent className="expense-model-main  expense-model-main-box-set">
          <Index.Box className="title-main">
            <Index.Box className="title-main" sx={{ marginTop: "10px" }}>
              <Index.ClearIcon
                className="model-close-icon"
                onClick={handleModelRemarkClose}
              />
              <Index.Typography
                variant="h6"
                component="h6"
                className="input-label"
                sx={{ marginBottom: "15px" }}
              >
                Remark
              </Index.Typography>
              <Index.Grid container spacing={2}>
                <Index.Grid item xs={12} sm={6} md={12}>
                  <Index.Typography
                    variant="label"
                    component="label"
                    className="input-label"
                  >
                    {remarkData}
                  </Index.Typography>
                </Index.Grid>
              </Index.Grid>
            </Index.Box>
          </Index.Box>
        </Index.DialogContent>
      </Index.Dialog>
    </>
  );
}

export default BreakDown;
