
import React from "react";
import Index from "../../../Index";
import { Formik } from "formik";
import { addTankerMaster } from "../../../../redux/admin/action";
import { tankerMasterValidationSchema } from "../../../../validation/AdminValidation";

function TankerAdd() {
  const dispatch = Index.useDispatch();
  const navigate = Index.useNavigate();
  const { state } = Index.useLocation();
  // edit data
  let editData = state?.row;
  if (editData) {
    editData.id = editData?._id;
  }
  let initialValues = {};
  if (editData) {
    initialValues = editData;
  } else {
    initialValues = {
      tankerName: "",
      tankerNumber: "",
      capacity: "",
    };
  }
  const handleFormSubmit = async (values) => {
    let data = new URLSearchParams();
    if (values?.tankerName) {
      data.append("tankerName", values.tankerName);
    }
    if (values?.tankerNumber) {
      data.append("tankerNumber", values.tankerNumber);
    }
    if (values?.capacity) {
      data.append("capacity", values.capacity);
    }
    if (editData) {
      data.append("id", editData?._id);
    }
    dispatch(addTankerMaster(data, navigate));
  };
  const BackBtn = () => {
    navigate("/admin/tankers");
  };

  return (
    <>
      <Index.Box className="">
        <Index.Box className="barge-common-box">
          <Index.Box className="title-header">
            <Index.Box className="title-header-flex">
              <Index.Box className="title-main mb-10">
                <Index.Typography
                  variant="p"
                  component="p"
                  className="page-title"
                >
                  {editData ? "Edit" : "Add"} Tanker
                </Index.Typography>
              </Index.Box>
              <Index.Box className="common-button blue-button save-btn ">
                <Index.Button
                  variant="contained"
                  type="submit"
                  className="Particular_model_submit ml-0"
                  onClick={BackBtn}
                >
                  Back
                </Index.Button>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Formik
            // enableReinitialize
            initialValues={initialValues}
            validationSchema={tankerMasterValidationSchema}
            onSubmit={handleFormSubmit}
          >
            {({
              values,
              errors,
              touched,
              setFieldValue,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <form onSubmit={handleSubmit}>
                <Index.Box className="">
                  <Index.Grid container spacing={2} className="grid-flex-start">
                    <Index.Grid item xs={12} sm={6} md={3}>
                      {/* <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Tanker Name
                      </Index.Typography> */}
                      <Index.Box className="input-design-div with-border">
                        <Index.TextField
                          hiddenLabel
                          id="filled-hidden-label-normal"
                          placeholder="Tanker name"
                          variant="filled"
                          className="admin-input-design input-placeholder"
                          name="tankerName"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values?.tankerName}
                          error={Boolean(
                            errors.tankerName ? touched.tankerName : undefined
                          )}
                          helperText={
                            touched.tankerName ? errors.tankerName : undefined
                          }
                        />
                      </Index.Box>
                    </Index.Grid>

                    <Index.Grid item xs={12} sm={6} md={3}>
                      {/* <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Tanker Number
                      </Index.Typography> */}
                      <Index.Box className="input-design-div with-border">
                        <Index.TextField
                          fullWidth
                          id="fullWidth"
                          placeholder="Tanker number"
                          variant="filled"
                          className="admin-input-design input-placeholder"
                          name="tankerNumber"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values?.tankerNumber}
                          error={Boolean(
                            errors.tankerNumber
                              ? touched.tankerNumber
                              : undefined
                          )}
                          helperText={
                            touched.tankerNumber
                              ? errors.tankerNumber
                              : undefined
                          }
                        />
                      </Index.Box>
                    </Index.Grid>

                    <Index.Grid item xs={12} sm={6} md={3}>
                      {/* <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Tanker Capacity
                      </Index.Typography> */}
                      <Index.Box className="input-design-div with-border">
                        <Index.TextField
                          fullWidth
                          id="fullWidth"
                          type="number"
                          placeholder="Tanker capacity"
                          variant="filled"
                          className="admin-input-design input-placeholder"
                          name="capacity"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values?.capacity}
                          error={Boolean(
                            errors.capacity ? touched.capacity : undefined
                          )}
                          helperText={
                            touched.capacity ? errors.capacity : undefined
                          }
                        />
                      </Index.Box>
                    </Index.Grid>
                    <Index.Grid item xs={12} sm={6} md={3} className="submit-btn-sec">
                      <Index.Box className="common-button blue-button save-btn flex-col">
                        <Index.Button variant="contained" type="submit">
                          {editData ? "Update" : "Submit"}
                        </Index.Button>
                      </Index.Box>
                    </Index.Grid>
                  </Index.Grid>
                </Index.Box>

              </form>
            )}
          </Formik>
        </Index.Box>
      </Index.Box>
    </>
  );
}

export default TankerAdd;
