import React, { useState } from "react";
import Index from "../../../../container/Index";
import { Formik } from "formik";
import * as yup from "yup";
import {
  updateAndConfirmOneTraderEnquiryStatus,
  getInquiryList,
} from "../../../../redux/admin/action";
const traderValidationSchema = yup.object().shape({
  traderIds: yup.string().required("Please select the Trader"),
});
const CurrentEnquiryStatusModal = ({
  updateOpen,
  handleClose,
  inquiryStatusUpdate,
  currentEnquiry,
  enquiriesData,
}) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
  };
  const [comment, setComment] = useState("");
  const dispatch = Index.useDispatch();

  const { salesTraderList } = Index.useSelector((state) => state.AdminReducer);

  const initialValues = {
    traderIds: "",
    comment: "",
  };

  // when the user submit the form  then used...
  const handleFormSubmit = (data) => {
    if (enquiriesData?.[0]?.traderIds?.length > 0) {
      const values = {
        inquiryStatus: "Confirm",
        id: enquiriesData?.[0]?._id,
        traderId: data?.traderIds,
        comment: data?.comment,
      };
      // return;
      dispatch(updateAndConfirmOneTraderEnquiryStatus(values))
        .then((res) => {
          if (res.status === 200) {
            handleClose();
            dispatch(getInquiryList());
          }
        })
        .catch((err) => console.log(err));
    } else {
      inquiryStatusUpdate("Confirm", data?.comment);
    }
  };

  return (
    <Index.Modal
      open={updateOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="modal-delete modal"
    >
      <Index.Box sx={style} className="delete-modal-inner-main modal-inner">
        <Index.ClearIcon className="model-close-icon" onClick={handleClose} />
        <Index.Box className="modal-circle-main">
          <img src={Index.Svg.close} className="user-circle-img" alt="icon" />
        </Index.Box>

        <Index.Typography
          className="delete-modal-title"
          component="h2"
          variant="h2"
        >
          Are you sure?
        </Index.Typography>
        <Index.Typography
          className="delete-modal-para common-para"
          component="p"
          variant="p"
        >
          Are you sure you want to update this enquiry?
        </Index.Typography>
        <Formik
          initialValues={initialValues}
          validationSchema={
            enquiriesData?.[0]?.traderIds?.length > 0
              ? traderValidationSchema
              : ""
          }
          onSubmit={handleFormSubmit}
        >
          {({ values, errors, setFieldValue, handleSubmit, handleChange }) => (
            <form onSubmit={handleSubmit}>
              {enquiriesData?.[0]?.traderIds?.length > 0 ? (
                <>
                  {" "}
                  <Index.Box className="title-main">
                    <Index.Grid item xs={12} sm={12} md={12}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Trader's Names
                      </Index.Typography>

                      <Index.Box className="input-design-div with-border">
                        <Index.Autocomplete
                          disablePortal
                          className="invoice-select-drop"
                          id="tags-outlined"
                          options={salesTraderList
                            .filter((res) =>
                              enquiriesData[0].traderIds?.includes(res._id)
                            )
                            .sort((a, b) =>
                              a?.traderName?.localeCompare(b?.traderName)
                            )}
                          getOptionLabel={(option) => option?.traderName}
                          value={
                            salesTraderList[
                              salesTraderList.findIndex(
                                (e) => e._id === values?.traderIds
                              )
                            ]
                          }
                          renderOption={(props, option) => (
                            <Index.Box
                              component="li"
                              sx={{
                                "& > img": { mr: 2, flexShrink: 0 },
                              }}
                              {...props}
                            >
                              {option?.traderName}
                            </Index.Box>
                          )}
                          onChange={(event, newValue) => {
                            setFieldValue("traderIds", newValue?._id);
                          }}
                          renderInput={(params) => (
                            <Index.TextField
                              {...params}
                              placeholder="Trader name"
                              variant="outlined"
                            />
                          )}
                        />

                        {errors.traderIds && (
                          <Index.FormHelperText error>
                            {errors.traderIds}
                          </Index.FormHelperText>
                        )}
                      </Index.Box>
                    </Index.Grid>

                    <Index.Box className="line" mt={3} mb={3}></Index.Box>
                  </Index.Box>
                </>
              ) : (
                <></>
              )}

              <Index.Box className="title-main">
                <Index.Grid item xs={12} sm={12} md={12}>
                  <Index.Typography
                    variant="label"
                    component="label"
                    className="input-label"
                  >
                    Comment
                  </Index.Typography>
                  <Index.Box className="input-design-div with-border">
                    <Index.TextareaAutosize
                      hiddenLabel
                      minRows={3}
                      id="filled-hidden-label-normal"
                      className="admin-input-design input-placeholder input-placeholder-box  input-placeholder-box-set "
                      placeholder="Comment"
                      variant="filled"
                      name="comment"
                      onChange={handleChange}
                    />
                  </Index.Box>
                </Index.Grid>

                <Index.Box className="line" mt={3} mb={3}></Index.Box>
              </Index.Box>
              <Index.Box className="delete-modal-btn-flex">
                <Index.Button
                  className="modal-inquiry-success-btn modal-btn"
                  type="Submit"
                  // onClick={() =>
                  //   inquiryStatusUpdate(
                  //     currentEnquiry == "current" ? "Confirm" : "CurrentEnquiry",
                  //     comment
                  //   )
                  // }
                >
                  {/* Confirm */}
                  {currentEnquiry == "lost" ? "Put Back" : "Confirm"}
                </Index.Button>
                <Index.Button
                  className="modal-inquiry-error-btn modal-btn"
                  onClick={() =>
                    currentEnquiry == "current"
                      ? inquiryStatusUpdate("Lost", comment)
                      : handleClose()
                  }
                >
                  {currentEnquiry == "current" ? "Lost" : "Cancel"}
                </Index.Button>
              </Index.Box>
            </form>
          )}
        </Formik>
      </Index.Box>
    </Index.Modal>
  );
};

export default CurrentEnquiryStatusModal;
