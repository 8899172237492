import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import { NEW_EXPORT_EXCEL } from "../ExportAll";
import {
  changePurchaseDirectIndirectType,
  getAllCompanyList,
  getJournalList,
  getNewJournalList,
} from "../../../../redux/admin/action";
import dayjs from "dayjs";
import { SingleInputDateRangeField } from "@mui/x-date-pickers-pro/SingleInputDateRangeField";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const Search = Index.styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: Index.alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: Index.alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const StyledInputBase = Index.styled(Index.InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

const shortcutsItems = [
  {
    label: "This Week",
    getValue: () => {
      const today = dayjs();
      return [today.startOf("week"), today.endOf("week")];
    },
  },
  {
    label: "Last Week",
    getValue: () => {
      const today = dayjs();
      const prevWeek = today.subtract(7, "day");
      return [prevWeek.startOf("week"), prevWeek.endOf("week")];
    },
  },
  {
    label: "Last 7 Days",
    getValue: () => {
      const today = dayjs();
      return [today.subtract(7, "day"), today];
    },
  },
  {
    label: "Current Month",
    getValue: () => {
      const today = dayjs();
      return [today.startOf("month"), today.endOf("month")];
    },
  },
  {
    label: "Next Month",
    getValue: () => {
      const today = dayjs();
      const startOfNextMonth = today.endOf("month").add(1, "day");
      return [startOfNextMonth, startOfNextMonth.endOf("month")];
    },
  },
  { label: "Reset", getValue: () => [null, null] },
];

const JournalList = () => {
  const dispatch = Index.useDispatch();
  const [loading, setLoading] = useState(false);
  const [purchaseExpenseType, setPurchaseExpenseType] = React.useState([]);

  // State for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [paginationPerPage, setPaginationPerPage] = useState(50);
  const [searchingText, setSearchingText] = useState("");
  const [totalRecordCount, setTotalRecordCount] = useState(0);
  const [serachedDateRange, setSearchedDateRange] = useState();
  const [journalRecordList, setJournalRecordList] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(
    "Ms. Bapus Shipping Jamnagar Pvt. Ltd. (15-18)"
  );

  const handlePageChange = React.useCallback(
    (event, value) => {
      setCurrentPage(value);
      const values = {
        page: value,
        search: searchingText,
        size: paginationPerPage,
        to:
          serachedDateRange?.length > 0
            ? serachedDateRange[0].format("YYYY-MM-DD")
            : "",
        from:
          serachedDateRange?.length > 0
            ? serachedDateRange[1].format("YYYY-MM-DD")
            : "",
      };
      getJournal(values);
    },
    [searchingText, paginationPerPage, serachedDateRange, setCurrentPage]
  );

  // when the get all the company list then used...
  const handleGetAllCompanyList = () => {
    dispatch(getAllCompanyList())
      .then((res) => {
        if (res.status === 200) {
          setCompanyList(res.data);
        } else {
          setCompanyList([]);
        }
      })
      .catch((error) => {
        setCompanyList([]);
        console.error(error);
      });
  };

  // get journal list
  const getJournal = (values) => {
    setLoading(true);
    // dispatch(getNewJournalList(values))
    dispatch(getJournalList(values))
      .then((res) => {
        if (res?.status === 200) {
          setTotalRecordCount(res?.totalPurchaseRecord);
          setCurrentPage(res?.currentPage);
          setJournalRecordList(res.data);
          setLoading(false);
          setPurchaseExpenseType([]);
        } else {
          setLoading(false);
          setPurchaseExpenseType([]);
          setJournalRecordList([]);
        }
      })
      .catch((err) => {
        setLoading(false);
        setTotalRecordCount(0);
        setCurrentPage(1);
        setPurchaseExpenseType([]);
        setJournalRecordList([]);
      });
  };

  useEffect(() => {
    // handleGetAllCompanyList();

    const values = {
      page: 1,
      search: "",
      size: paginationPerPage,
      to:
        serachedDateRange?.length > 0
          ? serachedDateRange[0].format("YYYY-MM-DD")
          : "",
      from:
        serachedDateRange?.length > 0
          ? serachedDateRange[1].format("YYYY-MM-DD")
          : "",
      // companyName: selectedCompany,
    };
    getJournal(values);
  }, []);

  // when the user search the journal records then used...
  const handleSearchJournalRecords = (e) => {
    const { value } = e.target;
    setSearchingText(value);
    if (value?.length > 0) {
      const values = {
        page: 1,
        search: value,
        size: paginationPerPage,
        to:
          serachedDateRange?.length > 0
            ? serachedDateRange[0].format("YYYY-MM-DD")
            : "",
        from:
          serachedDateRange?.length > 0
            ? serachedDateRange[1].format("YYYY-MM-DD")
            : "",
      };
      getJournal(values);
    } else {
      const values = {
        page: 1,
        search: "",
        size: paginationPerPage,
        to:
          serachedDateRange?.length > 0
            ? serachedDateRange[0].format("YYYY-MM-DD")
            : "",
        from:
          serachedDateRange?.length > 0
            ? serachedDateRange[1].format("YYYY-MM-DD")
            : "",
      };
      getJournal(values);
    }
  };

  // when the user change the per page record change then used..
  const handleChangePaginationPerChange = (e) => {
    const { value } = e.target;
    setPaginationPerPage(value);
    const values = {
      page: 1,
      search: searchingText,
      size: value,
      to:
        serachedDateRange?.length > 0
          ? serachedDateRange[0].format("YYYY-MM-DD")
          : "",
      from:
        serachedDateRange?.length > 0
          ? serachedDateRange[1].format("YYYY-MM-DD")
          : "",
    };
    getJournal(values);
  };

  // when the use change the date then used...
  const handleChangeDate = React.useCallback(
    (data) => {
      setSearchedDateRange(data);
      if (data[0] !== null && data[1] !== null) {
        const values = {
          page: 1,
          search: searchingText,
          size: paginationPerPage,
          to: data[0] !== null ? data[0].format("YYYY-MM-DD") : "",
          from: data[1] !== null ? data[1].format("YYYY-MM-DD") : "",
        };
        getJournal(values);
      } else {
        const values = {
          page: 1,
          search: searchingText,
          size: paginationPerPage,
          to:
            serachedDateRange?.length > 0
              ? serachedDateRange[0].format("YYYY-MM-DD")
              : "",
          from:
            serachedDateRange?.length > 0
              ? serachedDateRange[1].format("YYYY-MM-DD")
              : "",
        };
        getJournal(values);
      }
    },
    [setSearchedDateRange, searchingText, paginationPerPage]
  );

  // when the user change the expense type then used...
  const handleChangeExpenseType = (e, data) => {
    const values = {
      guid: data?.guid,
      expenseType: e.target.value,
      typeOfPurchase: data?.voucher_type,
    };
    setPurchaseExpenseType((prev) => [
      ...prev.filter((res) => res.guid !== data?.guid),
      values,
    ]);
    dispatch(changePurchaseDirectIndirectType(values));
  };

  return (
    <div>
      <Index.Box className="">
        <Index.Box className="barge-common-box">
          <Index.Box className="title-header">
            <Index.Box className="title-header-flex  res-title-header-flex res-title-header">
              <Index.Box className="title-main">
                <Index.Typography
                  variant="p"
                  component="p"
                  className="page-title"
                >
                  Journal Management
                </Index.Typography>
              </Index.Box>
              <Index.Box className="d-flex align-items-center res-set-search res-search-box">
                <Index.Box className="input-design-div">
                  <Index.Box className="set-date set-date-picker remove-name">
                    <Index.LocalizationProvider
                      dateAdapter={Index.AdapterDayjs}
                    >
                      <Index.DemoContainer
                        components={["SingleInputDateRangeField"]}
                      >
                        <DateRangePicker
                          slots={{ field: SingleInputDateRangeField }}
                          sx={false}
                          slotProps={{
                            shortcuts: {
                              items: shortcutsItems,
                            },
                            actionBar: { actions: [] },
                          }}
                          onChange={(value) => handleChangeDate(value)}
                          name="allowedRange"
                          format="DD/MM/YYYY"
                        />
                      </Index.DemoContainer>
                    </Index.LocalizationProvider>
                  </Index.Box>
                </Index.Box>

                {/* <Index.Box>
                  <Index.Box className="input-design-div with-border">
                    <Index.Select
                      className="placeholder-set-text-box"
                      fullWidth
                      name="companyId"
                      displayEmpty
                      inputProps={{ "aria-label": "without label" }}
                      value={selectedCompany}
                      MenuProps={MenuProps}
                      onChange={(event) => {
                        const {
                          target: { value },
                        } = event;
                        console.log("value", value);
                        setSelectedCompany(value);
                      }}
                    >
                      <Index.MenuItem value={""}>
                        <Index.MenuItem disabled className="all-select-label">
                          Select Company
                        </Index.MenuItem>
                      </Index.MenuItem>

                      {companyList?.map((item) => (
                        <Index.MenuItem
                          key={item?._id}
                          value={item?.tallyCompanyName}
                        >
                          {item?.tallyCompanyName}
                        </Index.MenuItem>
                      ))}
                    </Index.Select>
                  </Index.Box>
                </Index.Box> */}

                <Search className="search res-search">
                  <StyledInputBase
                    placeholder="Search"
                    inputProps={{ "aria-label": "search" }}
                    onChange={(e) => handleSearchJournalRecords(e)}
                  />
                </Search>

                {journalRecordList?.length ? (
                  <Index.Box className="common-button grey-button ">
                    <Index.Button
                      variant="contained"
                      onClick={() => {
                        let curDate = Index.moment(new Date()).format(
                          "DD-MM-YYYY"
                        );
                        // Export_excel(
                        //   exportDetails,
                        //   `Journal List (${curDate})`
                        // );
                        NEW_EXPORT_EXCEL(
                          "/admin/get-all-journal-data",
                          "Journal_List",
                          {
                            search: searchingText,
                            to:
                              serachedDateRange?.length > 0
                                ? serachedDateRange[0].format("YYYY-MM-DD")
                                : "",
                            from:
                              serachedDateRange?.length > 0
                                ? serachedDateRange[1].format("YYYY-MM-DD")
                                : "",
                          }
                        );
                      }}
                    >
                      Export
                    </Index.Button>
                  </Index.Box>
                ) : (
                  ""
                )}
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Index.Box className="">
            <Index.TableContainer
              component={Index.Paper}
              className="table-container"
            >
              <Index.Table
                aria-label="simple table"
                className="table-design-main invoice-table journal-table-data journal-manag-table"
              >
                <Index.TableHead>
                  <Index.TableRow>
                    <Index.TableCell className="invoice_table_header">
                      Date
                    </Index.TableCell>
                    <Index.TableCell
                      align="left"
                      className="invoice_table_header"
                    >
                      Party Name
                    </Index.TableCell>
                    <Index.TableCell
                      align="left"
                      className="invoice_table_header"
                    >
                      Expense Head
                    </Index.TableCell>
                    <Index.TableCell
                      align="left"
                      className="invoice_table_header"
                    >
                      Voucher Number
                    </Index.TableCell>
                    <Index.TableCell
                      align="left"
                      className="invoice_table_header"
                    >
                      Barges Or Tugs
                    </Index.TableCell>
                    <Index.TableCell
                      align="left"
                      className="invoice_table_header"
                    >
                      CGST
                    </Index.TableCell>
                    <Index.TableCell
                      align="left"
                      className="invoice_table_header"
                    >
                      SGST
                    </Index.TableCell>
                    <Index.TableCell
                      align="left"
                      className="invoice_table_header"
                    >
                      TDS
                    </Index.TableCell>
                    <Index.TableCell
                      align="left"
                      className="invoice_table_header"
                    >
                      IGST
                    </Index.TableCell>
                    <Index.TableCell
                      align="left"
                      className="invoice_table_header"
                    >
                      Taxable Value
                    </Index.TableCell>
                    <Index.TableCell
                      align="left"
                      className="invoice_table_header"
                    >
                      Invoice Value
                    </Index.TableCell>

                    <Index.TableCell
                      align="left"
                      className="invoice_table_header"
                    >
                      Expense Type
                    </Index.TableCell>
                  </Index.TableRow>
                </Index.TableHead>
                {loading ? (
                  <Index.TableBody>
                    <Index.TableRow>
                      <Index.TableCell
                        colSpan={12}
                        align="center"
                        className="loading-purchase"
                      >
                        <Index.CircularProgress color="secondary" size={20} />
                      </Index.TableCell>
                    </Index.TableRow>
                  </Index.TableBody>
                ) : (
                  <Index.TableBody>
                    {journalRecordList?.length ? (
                      journalRecordList?.map((row, index) => (
                        <Index.TableRow key={index}>
                          <Index.TableCell>
                            {Index.moment(row?.date).format("DD/MM/YYYY")}
                          </Index.TableCell>
                          <Index.TableCell>
                            {row?.party_name ? row?.party_name : "-"}
                          </Index.TableCell>
                          <Index.TableCell>
                            {row?.expense_head ? row?.expense_head : "-"}
                          </Index.TableCell>
                          <Index.TableCell>
                            {row?.voucher_number ? row?.voucher_number : "-"}
                          </Index.TableCell>
                          <Index.TableCell>
                            {row?.cost_center ? row?.cost_center : "-"}
                          </Index.TableCell>
                          <Index.TableCell>
                            {row?.cgst
                              ? row?.cgst?.toLocaleString("en-IN", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : 0?.toLocaleString("en-IN", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                          </Index.TableCell>
                          <Index.TableCell>
                            {row?.sgst
                              ? row?.sgst?.toLocaleString("en-IN", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : 0?.toLocaleString("en-IN", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                          </Index.TableCell>
                          <Index.TableCell>
                            {row?.tds
                              ? row?.tds?.toLocaleString("en-IN", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : 0?.toLocaleString("en-IN", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                          </Index.TableCell>
                          <Index.TableCell>
                            {row?.igst
                              ? row?.igst?.toLocaleString("en-IN", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : 0?.toLocaleString("en-IN", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                          </Index.TableCell>
                          <Index.TableCell>
                            {row?.taxable_value
                              ? row?.taxable_value?.toLocaleString("en-IN", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : 0?.toLocaleString("en-IN", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                          </Index.TableCell>
                          <Index.TableCell>
                            {row?.invoice_amount
                              ? row?.invoice_amount?.toLocaleString("en-IN", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : 0?.toLocaleString("en-IN", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                          </Index.TableCell>

                          <Index.TableCell>
                            <Index.Box className="input-design-div with-border">
                              <Index.Select
                                fullWidth
                                value={
                                  purchaseExpenseType?.length > 0 &&
                                  purchaseExpenseType.find(
                                    (res) => res?.guid === row?.guid
                                  ) !== undefined
                                    ? purchaseExpenseType.find(
                                        (res) => res?.guid === row?.guid
                                      ).expenseType
                                    : row?.expense_type === "Purchase Accounts"
                                    ? "Purchase Accounts"
                                    : row?.expense_type === "Direct Expenses"
                                    ? "Purchase Accounts"
                                    : row?.expense_type === "Indirect Expenses"
                                    ? "Indirect Expenses"
                                    : row?.expense_type === "Admin Cost"
                                    ? "Admin Cost"
                                    : ""
                                }
                                onChange={(e) =>
                                  handleChangeExpenseType(e, row)
                                }
                              >
                                <Index.MenuItem value="Purchase Accounts">
                                  Direct
                                </Index.MenuItem>
                                <Index.MenuItem value="Indirect Expenses">
                                  Indirect
                                </Index.MenuItem>
                                <Index.MenuItem value="Admin Cost">
                                  Admin Cost
                                </Index.MenuItem>
                              </Index.Select>
                            </Index.Box>
                          </Index.TableCell>
                        </Index.TableRow>
                      ))
                    ) : (
                      <Index.TableRow>
                        <Index.TableCell
                          component="td"
                          variant="td"
                          scope="row"
                          className="no-data-in-list empty-record-align"
                          colSpan={12}
                          align="center"
                        >
                          No data available
                        </Index.TableCell>
                      </Index.TableRow>
                    )}
                  </Index.TableBody>
                )}
              </Index.Table>
            </Index.TableContainer>
          </Index.Box>
          <Index.Box className="pagination-design flex-end">
            <Index.Stack spacing={2}>
              <Index.Box className="pagination-count pagination-loader">
                <Index.Box className="input-design-div with-border">
                  <Index.Select
                    fullWidth
                    value={paginationPerPage}
                    onChange={(e) => handleChangePaginationPerChange(e)}
                  >
                    <Index.MenuItem value={10}>10</Index.MenuItem>
                    <Index.MenuItem value={25}>25</Index.MenuItem>
                    <Index.MenuItem value={50}>50</Index.MenuItem>
                    <Index.MenuItem value={100}>100</Index.MenuItem>
                  </Index.Select>
                </Index.Box>
                {loading ? (
                  <Index.CircularProgress color="secondary" size={26} />
                ) : (
                  <>
                    <Index.Pagination
                      count={Math.ceil(totalRecordCount / paginationPerPage)}
                      page={currentPage}
                      onChange={handlePageChange}
                    />
                  </>
                )}
              </Index.Box>
            </Index.Stack>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </div>
  );
};

export default JournalList;
