import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import InquiryStatusModal from "../../../../component/common/InquiryStatusModel";
import {
  deleteTraderEnquiry,
  getTraderEnquiryList,
  getTraderInquiryList,
  updateTraderInquiryStatus,
  getTraderEnquiryListById,
} from "../../../../redux/admin/action";
import TraderEnquiryStatusModal from "./TraderEnquiryStatusModal";

const Search = Index.styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: Index.alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: Index.alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const StyledInputBase = Index.styled(Index.InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

function TraderEnqList() {
  const navigate = Index.useNavigate();
  const dispatch = Index.useDispatch();
  const [loading, setLoading] = useState(false);
  const { id } = Index.useParams();

  // filter
  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  // state for trader list
  const [traderList, setTraderList] = useState([]);

  // state for checkbox
  const [enquiryCheckId, setEnquiryCheckId] = useState([]);
  console.log(enquiryCheckId, 59);

  // State for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState(0);
  const [paginationPerPage, setPaginationPerPage] = useState(50);

  // State for searching and set data
  const [searchedData, setSearchedData] = useState([]);
  const [currentRows, setCurrentRows] = useState([]);
  const [updateOpen, setUpdateOpen] = useState(false);
  const [statusId, setStatusId] = useState("");
  const [checkTabvalue, setCheckTabvalue] = useState("current");

  const [rowId, setRowId] = useState("");
  const [deleteOpen, setDeleteOpen] = useState(false);
  const { traderInquiryListById } = Index.useSelector(
    (state) => state.AdminReducer
  );

  console.log("traderInquiryListById", traderInquiryListById);

  const handleOpen = () => {
    // setAnchorEl(null);
    setUpdateOpen(true);
  };

  const handleClose = () => {
    setUpdateOpen(false);
    setStatusId("");
  };

  // trader enq lost confirm status
  const inquiryMultipleStatusUpdate = (status) => {
    const data = new URLSearchParams();
    enquiryCheckId?.forEach((items) => {
      data.append("id[]", items);
    });
    // data.append("inquiryStatus", status);

    data.forEach((key, obj) => {
      console.log(key, ":::::", obj);
    });
    // return
    dispatch(updateTraderInquiryStatus(data))
      .then((res) => {
        if (res?.status === 200) {
          setEnquiryCheckId([]);
          singleTraderList();
        }
      })
      .catch((err) => {});
    handleClose();
  };

  //get single trader list
  const singleTraderList = () => {
    setLoading(true);
    const data = new URLSearchParams();
    data.append("traderId", id);

    // dispatch(getTraderEnquiryList(data))
    dispatch(getTraderEnquiryListById(id))
      .then((res) => {
        // setTraderList(res?.data);
        if (res?.status === 200) {
          setTimeout(() => {
            setLoading(false);
          }, 2000);
        } else {
          setTimeout(() => {
            setLoading(false);
          }, 2000);
        }
      })
      .catch((err) => {});
  };

  // get single trader list api
  useEffect(() => {
    singleTraderList();
  }, [dispatch]);

  function isValidDateFormat(input) {
    // Regular expression for YYYY-MM-DD format
    const dateFormat = /^\d{4}-\d{2}-\d{2}$/;
    return dateFormat.test(input);
  }

  // Copy original data to searched data
  useEffect(() => {
    // setSearchedData(traderList?.reverse());
    setSearchedData(traderInquiryListById?.reverse());
    // }, [traderList]);
  }, [traderInquiryListById]);

  // Search on table
  const requestSearch = (searched) => {
    if (!searched) return setSearchedData(traderInquiryListById);
    // if (!searched) return setSearchedData(traderList);
    setSearchedData(
      // traderList?.filter(
      traderInquiryListById?.filter(
        (item) =>
          item?.inquiryNo
            ?.toString()
            .toLowerCase()
            .includes(searched?.toLowerCase()) ||
          item?.vesselName?.toLowerCase().includes(searched?.toLowerCase()) ||
          item?.traderId?.traderName
            ?.toString()
            .toLowerCase()
            .includes(searched?.toLowerCase()) ||
          item?.lowerQuantity
            ?.toString()
            .toLowerCase()
            ?.includes(searched?.toLowerCase()) ||
          item?.higherQuantity
            ?.toString()
            .toLowerCase()
            .includes(searched.toLowerCase()) ||
          item?.port?.portName
            ?.toLowerCase()
            .includes(searched?.toLowerCase()) ||
          item?.terminal?.toLowerCase().includes(searched?.toLowerCase()) ||
          item?.CF?.toLowerCase().includes(searched?.toLowerCase()) ||
          item?.product?.toLowerCase().includes(searched?.toLowerCase()) ||
          Index.moment(item?.eta)
            .format("DD/MM/YYYY")
            ?.toString()
            ?.toLowerCase()
            .includes(searched.toString()?.toLowerCase()) ||
          Index.moment(item?.laycanStartDate)
            .format("DD/MM/YYYY")
            ?.toString()
            ?.toLowerCase()
            .includes(searched.toString()?.toLowerCase()) ||
          Index.moment(item?.laycanEndDate)
            .format("DD/MM/YYYY")
            ?.toString()
            ?.toLowerCase()
            .includes(searched.toString()?.toLowerCase())
      )
    );
    setCurrentPage(1);
  };

  // Pagination calculation start
  const PER_PAGE = paginationPerPage;
  useEffect(() => {
    //Page count
    const count = Math.ceil(searchedData?.length / PER_PAGE);
    setCount(count);
    const indexOfLastRow = currentPage * PER_PAGE;
    const indexOfFirstRow = indexOfLastRow - PER_PAGE;
    const modifiedRows = searchedData?.slice(indexOfFirstRow, indexOfLastRow);
    setCurrentRows(modifiedRows);
    if (modifiedRows?.length === 0 && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  }, [currentPage, searchedData, paginationPerPage, PER_PAGE]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  // Pagination calculation end

  // edit enq
  const handleEdit = (row) => {
    navigate(`/trader/enq/add/${id}`, {
      state: { row },
    });
  };
  const handleDeleteOpen = () => {
    setDeleteOpen(true);
  };

  const handleDeleteClose = () => {
    setDeleteOpen(false);
  };

  const handleDeleteRecord = () => {
    dispatch(deleteTraderEnquiry({ id: rowId })).then((res) => {
      if (res?.status === 200) {
        singleTraderList();
      }
    });
    handleDeleteClose();
  };

  return (
    <>
      <Index.DynamicTitle title="Enquiry" />
      <Index.Box className="">
        <Index.Box className="barge-common-box barge-common-box-set">
          <Index.Box className="title-header">
            <Index.Box className="title-header-flex res-title-header-flex">
              <Index.Box className="title-main">
                <Index.Typography
                  variant="p"
                  component="p"
                  className="page-title"
                >
                  Current Enquiry
                </Index.Typography>
              </Index.Box>
              <Index.Box className="d-flex align-items-center res-set-search">
                <Search className="search ">
                  <StyledInputBase
                    placeholder="Search"
                    inputProps={{ "aria-label": "search" }}
                    onChange={(e) => requestSearch(e.target.value)}
                  />
                </Search>

                <Index.Box className="common-button blue-button res-blue-button">
                  {currentRows?.length && enquiryCheckId?.length ? (
                    <Index.Button
                      variant="contained"
                      disableRipple
                      onClick={(e) => {
                        handleOpen();
                      }}
                    >
                      Status Update
                    </Index.Button>
                  ) : (
                    ""
                  )}

                  <Index.RouteLink
                    to={`/trader/enq/add/${id}`}
                    className="no-text-decoration"
                  >
                    {" "}
                    <Index.Button variant="contained" disableRipple>
                      Add Enquiry
                    </Index.Button>
                  </Index.RouteLink>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Index.Box className="">
            <Index.TableContainer
              component={Index.Paper}
              className="table-container"
            >
              <Index.Table
                aria-label="simple table"
                className="table-design-main supply-table one-line-table"
              >
                <Index.TableHead>
                  <Index.TableRow>
                    <Index.TableCell></Index.TableCell>

                    <Index.TableCell>Enquiry No</Index.TableCell>
                    <Index.TableCell>Status</Index.TableCell>
                    <Index.TableCell align="left">
                      Name of Vessel
                    </Index.TableCell>
                    <Index.TableCell align="left">Trader Name</Index.TableCell>
                    <Index.TableCell align="left">Port</Index.TableCell>
                    <Index.TableCell align="left">Terminal</Index.TableCell>
                    <Index.TableCell align="left">C/F</Index.TableCell>
                    <Index.TableCell align="left">Quantity(MT)</Index.TableCell>
                    <Index.TableCell align="left">Product</Index.TableCell>
                    <Index.TableCell align="left">ETA</Index.TableCell>
                    <Index.TableCell align="left">EDD</Index.TableCell>
                    <Index.TableCell align="left">Laycan </Index.TableCell>
                    <Index.TableCell align="left">Remark </Index.TableCell>
                    <Index.TableCell align="left">Action</Index.TableCell>
                  </Index.TableRow>
                </Index.TableHead>
                {loading ? (
                  <Index.TableBody>
                    <Index.TableRow>
                      <Index.TableCell colSpan={16} align="center">
                        <Index.CircularProgress color="secondary" size={20} />
                      </Index.TableCell>
                    </Index.TableRow>
                  </Index.TableBody>
                ) : (
                  <Index.TableBody>
                    {currentRows?.length ? (
                      currentRows?.map((item, index) => (
                        <Index.TableRow key={item?._id}>
                          <Index.TableCell>
                            {item?.dateConfirm && !item.isTraderConfirm ? (
                              <>
                                <Index.FormControlLabel
                                  control={
                                    <Index.Checkbox
                                      // checked={enquiryCheck}
                                      onChange={(event) => {
                                        // setEnquiryCheck(event.target.checked);
                                        if (event.target.checked === true) {
                                          setEnquiryCheckId([
                                            ...enquiryCheckId,
                                            item?._id,
                                          ]);
                                        } else {
                                          setEnquiryCheckId(
                                            enquiryCheckId?.filter((ele) => {
                                              return ele !== item?._id;
                                            })
                                          );
                                        }
                                      }}
                                    />
                                  }
                                  // label="Shipping Bill"
                                />
                              </>
                            ) : (
                              <></>
                            )}
                          </Index.TableCell>
                          <Index.TableCell
                            onClick={() => {
                              handleEdit(item);
                            }}
                            sx={{ cursor: "pointer" }}
                          >
                            {item?.inquiryNo ? item?.inquiryNo : "-"}
                          </Index.TableCell>
                          <Index.TableCell
                            onClick={() => {
                              handleEdit(item);
                            }}
                            sx={{ cursor: "pointer" }}
                          >
                            {item?.traderEnquiryStatus ? (
                              <Index.Button variant="contained" size="small">
                                {item?.traderEnquiryStatus}
                              </Index.Button>
                            ) : (
                              "-"
                            )}
                          </Index.TableCell>
                          <Index.TableCell
                            align="left"
                            onClick={() => {
                              handleEdit(item);
                            }}
                            sx={{ cursor: "pointer" }}
                          >
                            {item?.vesselName ? item?.vesselName : "-"}
                          </Index.TableCell>
                          <Index.TableCell
                            align="left"
                            onClick={() => {
                              handleEdit(item);
                            }}
                            sx={{ cursor: "pointer" }}
                          >
                            {item?.traderId?.traderName
                              ? item?.traderId?.traderName
                              : "-"}
                          </Index.TableCell>
                          <Index.TableCell
                            align="left"
                            onClick={() => {
                              handleEdit(item);
                            }}
                            sx={{ cursor: "pointer" }}
                          >
                            {item?.port?.portName ? item?.port?.portName : "-"}
                          </Index.TableCell>
                          <Index.TableCell
                            align="left"
                            onClick={() => {
                              handleEdit(item);
                            }}
                            sx={{ cursor: "pointer" }}
                          >
                            {item?.terminal ? item?.terminal : "-"}
                          </Index.TableCell>
                          <Index.TableCell
                            align="left"
                            onClick={() => {
                              handleEdit(item);
                            }}
                            sx={{ cursor: "pointer" }}
                          >
                            {item?.CF ? item?.CF : "-"}
                          </Index.TableCell>
                          <Index.TableCell
                            align="left"
                            onClick={() => {
                              handleEdit(item);
                            }}
                            sx={{ cursor: "pointer" }}
                          >
                            {item?.orderDetails?.length > 0
                              ? item?.orderDetails
                                  .map((items, index) => {
                                    if (
                                      items.lowerQuantity &&
                                      items.higherQuantity != 0
                                    ) {
                                      return `${items.lowerQuantity} - ${items.higherQuantity}`;
                                    } else if (items.lowerQuantity) {
                                      return items.lowerQuantity.toString();
                                    } else {
                                      return "-";
                                    }
                                  })
                                  .join(" ")
                              : "-"}
                          </Index.TableCell>
                          <Index.TableCell
                            align="left"
                            onClick={() => {
                              handleEdit(item);
                            }}
                            sx={{ cursor: "pointer" }}
                          >
                            {item?.orderDetails?.length
                              ? item?.orderDetails &&
                                item?.orderDetails?.map((items, index) => {
                                  if (items?.product) {
                                    return items?.product + " ";
                                  } else {
                                    return "-";
                                  }
                                })
                              : "-"}
                          </Index.TableCell>
                          <Index.TableCell
                            align="left"
                            onClick={() => {
                              handleEdit(item);
                            }}
                            sx={{ cursor: "pointer" }}
                          >
                            {item?.eta
                              ? Index.moment(item?.eta).format("DD/MM/YYYY")
                              : "-"}
                          </Index.TableCell>
                          <Index.TableCell
                            align="left"
                            // onClick={() => {
                            //   handleEdit(item);
                            // }}
                            sx={{ cursor: "pointer" }}
                          >
                            {item?.edd
                              ? Index.moment(item?.eta).format("DD/MM/YYYY")
                              : "-"}
                          </Index.TableCell>

                          <Index.TableCell
                            align="left"
                            onClick={() => {
                              handleEdit(item);
                            }}
                            sx={{ cursor: "pointer" }}
                          >
                            {item?.laycanStartDate || item?.laycanEndDate ? (
                              <>
                                {Index.moment(item?.laycanStartDate).format(
                                  "DD/MM/YYYY"
                                )}{" "}
                                -{" "}
                                {Index.moment(item?.laycanEndDate).format(
                                  "DD/MM/YYYY"
                                )}
                              </>
                            ) : (
                              "-"
                            )}
                          </Index.TableCell>

                          <Index.TableCell
                            align="left"
                            onClick={() => {
                              handleEdit(item);
                            }}
                            sx={{ cursor: "pointer" }}
                          >
                            <Index.Tooltip title={item?.remark} arrow>
                              <Index.Button className="remark-text">
                                {item?.remark ? (
                                  <>
                                    {item?.remark?.length > 10 ? (
                                      <>{`${item?.remark?.substring(
                                        0,
                                        10
                                      )}...`}</>
                                    ) : (
                                      item?.remark
                                    )}
                                  </>
                                ) : (
                                  "-"
                                )}
                              </Index.Button>
                            </Index.Tooltip>
                          </Index.TableCell>
                          <Index.TableCell>
                            <Index.IconButton>
                              <Index.DeleteIcon
                                color="error"
                                onClick={() => {
                                  setRowId(item?._id);
                                  handleDeleteOpen();
                                }}
                              />
                            </Index.IconButton>
                          </Index.TableCell>
                        </Index.TableRow>
                      ))
                    ) : (
                      <Index.TableRow>
                        <Index.TableCell
                          component="td"
                          variant="td"
                          scope="row"
                          className="no-data-in-list"
                          colSpan={16}
                          align="center"
                        >
                          No data available
                        </Index.TableCell>
                      </Index.TableRow>
                    )}
                  </Index.TableBody>
                )}
              </Index.Table>
            </Index.TableContainer>
          </Index.Box>

          <Index.Box className="pagination-design flex-end">
            <Index.Stack spacing={2}>
              <Index.Box className="pagination-count">
                <Index.Box className="input-design-div with-border">
                  <Index.Select
                    fullWidth
                    value={paginationPerPage}
                    onChange={(e) => {
                      setPaginationPerPage(e.target.value);
                    }}
                  >
                    <Index.MenuItem value={10}>10</Index.MenuItem>
                    <Index.MenuItem value={25}>25</Index.MenuItem>
                    <Index.MenuItem value={50}>50</Index.MenuItem>
                    <Index.MenuItem value={100}>100</Index.MenuItem>
                  </Index.Select>
                </Index.Box>
                <Index.Pagination
                  count={count}
                  page={currentPage}
                  onChange={handlePageChange}
                />
              </Index.Box>
            </Index.Stack>
          </Index.Box>
        </Index.Box>
      </Index.Box>

      {/* <InquiryStatusModal
        updateOpen={updateOpen}
        handleClose={handleClose}
        inquiryStatusUpdate={inquiryMultipleStatusUpdate}
        currentEnquiry={checkTabvalue}
      /> */}

      <TraderEnquiryStatusModal
        updateOpen={updateOpen}
        handleClose={handleClose}
        inquiryStatusUpdate={inquiryMultipleStatusUpdate}
        currentEnquiry={checkTabvalue}
      />

      <Index.DeleteModal
        deleteOpen={deleteOpen}
        handleDeleteRecord={handleDeleteRecord}
        handleDeleteClose={handleDeleteClose}
      />
    </>
  );
}

export default TraderEnqList;
