import { Formik } from "formik";
import { terminalData } from "../../../../../config/CommonTerminalItems";
import { useEffect, useState } from "react";
import Index from "../../../../Index";
import { commonCFData } from "../../../../../config/CommonCF";

function UpdateOrderCFModal({
  openUpdateDatePopUp,
  handleCloseDateEditModal,
  handleUpdateDateViaApi,
  editTimeDateData,
  editTimeCFData,
  isSingleFieldUpdate,
}) {
  const initialValues = {
    foreign: editTimeCFData,
  };

  return (
    <>
      <Index.Dialog
        open={openUpdateDatePopUp}
        onClose={handleCloseDateEditModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Index.DialogContent className="expense-model-main  modal-update-main">
          <Index.Box className="title-main">
            <Formik
              enableReinitialize
              initialValues={initialValues}
              // validationSchema={vesselValidationSchema}
              onSubmit={(values) =>
                handleUpdateDateViaApi(values, editTimeDateData)
              }
            >
              {({
                values,
                errors,
                touched,
                setFieldValue,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldTouched,
                handleFocus,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Index.Box className="title-main">
                    <Index.Box className="jus-between">
                      <Index.Typography
                        variant="h6"
                        component="h6"
                        className="input-label"
                      >
                        Edit C/F
                      </Index.Typography>
                      <Index.ClearIcon
                        className="model-close-icon"
                        onClick={handleCloseDateEditModal}
                      />
                    </Index.Box>
                    <Index.Box className="update-model-main-box-set">
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        C/F
                      </Index.Typography>
                      <Index.Box className="input-design-div with-border">
                        <Index.Autocomplete
                          disablePortal
                          className="invoice-select-drop"
                          id="combo-box-demo"
                          options={commonCFData}
                          value={
                            commonCFData[
                              commonCFData.findIndex(
                                (e) => e.name === values?.foreign
                              )
                            ]
                          }
                          getOptionLabel={(option) => option?.name}
                          renderOption={(props, option) => (
                            <Index.Box
                              component="li"
                              sx={{
                                "& > img": { mr: 2, flexShrink: 0 },
                              }}
                              {...props}
                            >
                              {option?.name}
                            </Index.Box>
                          )}
                          onChange={(event, newValue) => {
                            setFieldValue("foreign", newValue?.name);
                          }}
                          renderInput={(params) => (
                            <Index.TextField
                              {...params}
                              placeholder="C/F name"
                              variant="outlined"
                            />
                          )}
                        />
                      </Index.Box>

                      <Index.Box className="common-button blue-button flex-end save-btn  modal-update-btn">
                        <Index.Button
                          variant="contained"
                          type="submit"
                          className="Particular_model_submit"
                          disabled={isSingleFieldUpdate ? true : false}
                        >
                          Update
                        </Index.Button>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </form>
              )}
            </Formik>
          </Index.Box>
        </Index.DialogContent>
      </Index.Dialog>
    </>
  );
}
export default UpdateOrderCFModal;
