import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import { Formik } from "formik";
import { addEmployee, getEmployeeNameList } from "../../../../redux/admin/action";
import { employeeValidationSchema } from "../../../../validation/AdminValidation";

function AddEmployee() {
  const dispatch = Index.useDispatch();
  const navigate = Index.useNavigate();
  const { state } = Index.useLocation();
  const editData = state?.data;
  // state
  const [employeeList, setEmployeeList] = useState([]);
  const [employeeNameList, setEmployeeNameList] = useState([]);
  // console.log(employeeNameList, "employeeNameList");

  //initialValues
  let initialValues = {
    employeeId: editData?.employeeId ? editData?.employeeId : "",
    advanceAmount: editData?.advanceAmount ? editData?.advanceAmount : "",
  };

  // formHandler
  const handleFormSubmit = async (values) => {
    let data = new URLSearchParams();
    if (values?.employeeId) {
      data.append("employeeId", values.employeeId);
    }
    if (values?.advanceAmount) {
      data.append("advanceAmount", values.advanceAmount);
    }
    if (editData) {
      data.append("id", editData?.employeeId);
    }
    dispatch(addEmployee(data, navigate));
  };
  const backBtn = () => {
    navigate("/admin/bunker");
  };

  // get employee list
  const getEmployeeList = () => {
    dispatch(getEmployeeNameList()).then((res) => {
      if (res?.status === 200) {
        setEmployeeList(res?.data);
      }
    });
  };

  useEffect(() => {
    getEmployeeList();
  }, []);

  // employeename sorting
    useEffect(() => {
      setEmployeeNameList(
        employeeList?.sort((a, b) =>
          a?.employeeName?.localeCompare(b?.employeeName)
        )
      );
    }, [employeeList]);

  return (
    <>
      <Index.Box className="">
        <Index.Box className="barge-common-box">
          <Index.Box className="title-header">
            <Index.Box className="title-header-flex">
              <Index.Box className="title-main mb-10">
                <Index.Typography
                  variant="p"
                  component="p"
                  className="page-title"
                >
                  {editData ? "Edit" : "Add"} Employee
                </Index.Typography>
              </Index.Box>
              <Index.Box className="common-button blue-button save-btn ">
                <Index.Button
                  variant="contained"
                  type="submit"
                  className="Particular_model_submit ml-0"
                  onClick={backBtn}
                >
                  Back
                </Index.Button>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Formik
            // enableReinitialize
            initialValues={initialValues}
            validationSchema={employeeValidationSchema}
            onSubmit={handleFormSubmit}
          >
            {({
              values,
              errors,
              touched,
              setFieldValue,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <form onSubmit={handleSubmit}>
                <Index.Box className="">
                  <Index.Grid container spacing={2} className="grid-flex-start">
                    <Index.Grid item xs={12} sm={6} md={3}>
                      {/* <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Employee Name
                      </Index.Typography> */}
                      <Index.Box className="input-design-div with-border">
                        {editData && values?.employeeId ? (
                          employeeNameList?.length && (
                            <Index.Autocomplete
                              disablePortal
                              className="invoice-select-drop"
                              id="combo-box-demo"
                              options={employeeNameList}
                              getOptionLabel={(option) => option?.employeeName}
                              value={
                                values?.employeeId
                                  ? employeeNameList.find(
                                      (e) => e._id == values?.employeeId
                                    )
                                  : null
                              }
                              renderOption={(props, option) => (
                                <Index.Box
                                  component="li"
                                  sx={{
                                    "& > img": { mr: 2, flexShrink: 0 },
                                  }}
                                  {...props}
                                >
                                  {option?.employeeName}
                                </Index.Box>
                              )}
                              onChange={(event, newValue) => {
                                // setFieldValue("portId", newValue?._id);
                                setFieldValue("employeeId", [newValue?._id]);
                                // handlePoNumber(newValue?._id, setFieldValue);
                              }}
                              renderInput={(params) => (
                                <Index.TextField
                                  {...params}
                                  placeholder="Employee name"
                                  variant="outlined"
                                />
                              )}
                            />
                          )
                        ) : (
                          <Index.Autocomplete
                            disablePortal
                            className="invoice-select-drop"
                            id="combo-box-demo"
                            options={employeeNameList}
                            getOptionLabel={(option) => option?.employeeName}
                            renderOption={(props, option) => (
                              <Index.Box
                                component="li"
                                sx={{
                                  "& > img": { mr: 2, flexShrink: 0 },
                                }}
                                {...props}
                              >
                                {option?.employeeName}
                              </Index.Box>
                            )}
                            onChange={(event, newValue) => {
                              setFieldValue("employeeId", newValue?._id);
                              // handlePoNumber(newValue?._id, setFieldValue);
                            }}
                            renderInput={(params) => (
                              <Index.TextField
                                {...params}
                                placeholder="Employee name"
                                variant="outlined"
                              />
                            )}
                          />
                        )}

                        {touched.employeeId && errors.employeeId && (
                          <Index.FormHelperText error>
                            {errors.employeeId}
                          </Index.FormHelperText>
                        )}
                      </Index.Box>
                    </Index.Grid>

                    <Index.Grid item xs={12} sm={6} md={3}>
                      {/* <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Advance Amount Given
                      </Index.Typography> */}
                      <Index.Box className="input-design-div with-border">
                        <Index.TextField
                          fullWidth
                          id="fullWidth"
                          placeholder="Advance amount given"
                          type="number"
                          variant="filled"
                          className="admin-input-design input-placeholder"
                          name="advanceAmount"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values?.advanceAmount}
                          error={Boolean(
                            errors?.advanceAmount
                              ? touched?.advanceAmount
                              : undefined
                          )}
                          helperText={
                            touched?.advanceAmount
                              ? errors?.advanceAmount
                              : undefined
                          }
                        />
                      </Index.Box>
                    </Index.Grid>
                    <Index.Grid item xs={12} sm={6} md={6} className="submit-btn-sec">
                    <Index.Box className="common-button blue-button save-btn flex-col">
                  <Index.Button variant="contained" type="submit">
                    {editData ? "Update" : "Submit"}
                  </Index.Button>
                </Index.Box>
                    </Index.Grid>
                  </Index.Grid>
                </Index.Box>
                
              </form>
            )}
          </Formik>
        </Index.Box>
      </Index.Box>
    </>
  );
}

export default AddEmployee;
