import React, { useState } from "react";
import Index from "../../../Index";
import { Formik } from "formik";
import { updateAdminProfile } from "../../../../redux/admin/action";
import { profileValidationSchema } from "../../../../validation/AdminValidation";

const Item = Index.styled(Index.Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

function Profile() {
  const dispatch = Index.useDispatch();
  // state for image upload
  const [image, setImage] = useState("");
  // redux data
  const { adminDetails } = Index.useSelector((state) => state.AdminReducer);

  // initial values
  const initialValues = {
    firstName: adminDetails ? adminDetails.firstName : "",
    lastName: adminDetails ? adminDetails.lastName : "",
    email: adminDetails ? adminDetails.email : "",
    mobileNumber: adminDetails ? adminDetails.mobileNumber : "",
    address: adminDetails ? adminDetails.address : "",
    image: adminDetails ? adminDetails.image : "",
  };

  // update admin  profile handler
  const handleFormSubmit = async (values) => {
    const data = new FormData();
    data.append("id", adminDetails?._id);
    data.append("firstName", values.firstName);
    data.append("lastName", values.lastName);
    data.append("email", values.email);
    data.append("mobileNumber", values.mobileNumber);
    data.append("address", values.address);
    data.append("image", image);
    dispatch(updateAdminProfile(data));
  };

  console.log("image", image);
  console.log("adminDetails?.image", adminDetails?.image );
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={profileValidationSchema}
      onSubmit={handleFormSubmit}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
      }) => (
        <form onSubmit={handleSubmit}>
          <Index.Box className="background-ed profile-content flex-center ">
            <Index.Box className=" h-100">
              <Index.Box className="card-center">
                <Index.Box className="card-main profile-card-main">
                  <Index.Box className="title-main mb-15">
                    <Index.Typography
                      variant="p"
                      component="p"
                      className="page-title"
                    >
                      Update Profile
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="profile-image text-center mb-20">
                    {image ? (
                      <>
                        <img
                          src={URL.createObjectURL(image)}
                          alt=""
                          className="profile-img mb-15"
                        />
                      </>
                    ) : (
                      <>
                        {adminDetails?.image ? (
                          <img
                            // src={`${process.env.REACT_APP_IMAGE_URL}/users/${adminDetails?.image}`}
                            src={
                              adminDetails?.image
                                ? `${process.env.REACT_APP_IMAGE_URL}/users/${adminDetails?.image}`
                                : Index.Svg.headprofile
                            }
                            alt=""
                            className="profile-img mb-15"
                          />
                        ) : (
                          <img
                            // src={`${process.env.REACT_APP_IMAGE_URL}/users/${adminDetails?.image}`}
                            src={Index.Svg.headprofile}
                            alt=""
                            className="profile-img mb-15"
                          />
                          // <img src="" alt="" className="profile-img mb-15" />
                        )}
                      </>
                    )}
                    <Index.Box className="flex-center">
                      <Index.Box className="common-button grey-button change-profile">
                        <Index.Button variant="contained" type="button">
                          Change Image
                        </Index.Button>
                        <input
                          type="file"
                          className="change-profile-input"
                          accept="image/*"
                          name="image"
                          onChange={(event) => {
                            setImage(event.currentTarget.files[0]);
                          }}
                        />
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="profile-inner">
                    <Index.Box className="">
                      <Index.Grid
                        container
                        columnSpacing={{ xs: 3.75, sm: 3.75, md: 3.75 }}
                      >
                        <Index.Grid item xs={12} sm={6} md={12} lg={6}>
                          <Item className="dashboard-item">
                            <Index.Box>
                              <Index.Typography
                                variant="label"
                                component="label"
                                className="change-input-label"
                              >
                                First Name
                              </Index.Typography>
                              <Index.Box className="input-design-div with-border mb-15">
                                <Index.TextField
                                  hiddenLabel
                                  id="filled-hidden-label-normal"
                                  placeholder=""
                                  variant="filled"
                                  className="admin-input-design input-placeholder"
                                  name="firstName"
                                  onBlur={handleBlur}
                                  value={values.firstName}
                                  onChange={handleChange}
                                  helperText={
                                    touched.firstName && errors.firstName
                                  }
                                  error={Boolean(
                                    errors.firstName && touched.firstName
                                  )}
                                />
                              </Index.Box>
                            </Index.Box>
                          </Item>
                        </Index.Grid>
                        <Index.Grid item xs={12} sm={6} md={12} lg={6}>
                          <Item className="dashboard-item">
                            <Index.Box>
                              <Index.Typography
                                variant="label"
                                component="label"
                                className="input-label"
                              >
                                Last Name
                              </Index.Typography>
                              <Index.Box className="input-design-div with-border mb-15">
                                <Index.TextField
                                  hiddenLabel
                                  id="filled-hidden-label-normal"
                                  placeholder=""
                                  variant="filled"
                                  className="admin-input-design input-placeholder"
                                  name="lastName"
                                  onBlur={handleBlur}
                                  value={values.lastName}
                                  onChange={handleChange}
                                  helperText={
                                    touched.lastName && errors.lastName
                                  }
                                  error={Boolean(
                                    errors.lastName && touched.lastName
                                  )}
                                />
                              </Index.Box>
                            </Index.Box>
                          </Item>
                        </Index.Grid>
                        <Index.Grid item xs={12} sm={6} md={12} lg={6}>
                          <Item className="dashboard-item">
                            <Index.Box>
                              <Index.Typography
                                variant="label"
                                component="label"
                                className="input-label"
                              >
                                Email
                              </Index.Typography>
                              <Index.Box className="input-design-div with-border mb-15">
                                <Index.TextField
                                  hiddenLabel
                                  id="filled-hidden-label-normal"
                                  placeholder=""
                                  variant="filled"
                                  className="admin-input-design input-placeholder"
                                  name="email"
                                  onBlur={handleBlur}
                                  value={values.email}
                                  onChange={handleChange}
                                  helperText={touched.email && errors.email}
                                  error={Boolean(errors.email && touched.email)}
                                />
                              </Index.Box>
                            </Index.Box>
                          </Item>
                        </Index.Grid>
                        <Index.Grid item xs={12} sm={6} md={12} lg={6}>
                          <Item className="dashboard-item">
                            <Index.Box>
                              <Index.Typography
                                variant="label"
                                component="label"
                                className="input-label"
                              >
                                Mobile Number
                              </Index.Typography>
                              <Index.Box className="input-design-div with-border mb-15">
                                <Index.TextField
                                  hiddenLabel
                                  id="filled-hidden-label-normal"
                                  placeholder=""
                                  variant="filled"
                                  className="admin-input-design input-placeholder"
                                  name="mobileNumber"
                                  onBlur={handleBlur}
                                  value={values.mobileNumber}
                                  onChange={handleChange}
                                  helperText={
                                    touched.mobileNumber && errors.mobileNumber
                                  }
                                  error={Boolean(
                                    errors.mobileNumber && touched.mobileNumber
                                  )}
                                />
                              </Index.Box>
                            </Index.Box>
                          </Item>
                        </Index.Grid>
                        <Index.Grid item xs={12}>
                          <Index.Box className="set-text-area mb-20">
                            <Index.Typography
                              variant="label"
                              component="label"
                              className="input-label"
                            >
                              Address
                            </Index.Typography>
                            <Index.Box className="set-textarea-box-top">
                              <Index.TextField
                                className="set-textarea-box"
                                aria-label="empty textarea"
                                placeholder=""
                                multiline
                                name="address"
                                onBlur={handleBlur}
                                value={values.address}
                                onChange={handleChange}
                                helperText={touched.address && errors.address}
                                error={Boolean(
                                  errors.address && touched.address
                                )}
                              />
                            </Index.Box>
                          </Index.Box>
                        </Index.Grid>
                      </Index.Grid>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="common-button blue-button change-password-btn update_priofile_btn">
                    <Index.Button
                      variant="contained"
                      disableRipple
                      type="submit"
                    >
                      Update Profile
                    </Index.Button>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </form>
      )}
    </Formik>
  );
}

export default Profile;
