import React, { useEffect, useState } from "react";
import {
  getSlopeSludgeChallanEditRequestList,
  getSlopeSludgeChallanList,
  slopeSludgeChallanEditRequestStatus,
} from "../../../../../redux/admin/action";
import Index from "../../../../Index";
import { useDispatch } from "react-redux";

const SlopeAndSludgeChallanEditRequest = ({ id }) => {
  const dispatch = useDispatch();
  const [challanRequestData, setChallanRequestData] = useState([]);
  const [loading, setLoading] = useState(false);

  // change challan request status
  const changeSlopeSludgeChallanEditRequestStatus = (id, status) => {
    const urlencoded = new URLSearchParams();
    urlencoded.append("id", id);
    urlencoded.append("editStatus", status);
    dispatch(slopeSludgeChallanEditRequestStatus(urlencoded)).then((res) => {
      if (res?.status === 200) {
        getAllChallanList();
      }
    });
  };

  // get single challan edit request
  const singleChallanEditRequest = (id) => {
    setLoading(true);
    dispatch(getSlopeSludgeChallanEditRequestList(id)).then((res) => {
      if (res?.status === 200) {
        setChallanRequestData(res?.data);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      } else {
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      }
    });
  };

  const getAllChallanList = () => {
    dispatch(getSlopeSludgeChallanList(id)).then((res) => {
      if (res?.status === 200 && res?.data?.length) {
        singleChallanEditRequest(res?.data?.[0]?._id);
      }
    });
  };

  useEffect(() => {
    getAllChallanList();
  }, []);
  return (
    <>
      <Index.TableContainer>
        <Index.Table className="challan-table challan-table-set challan-table-font edit-req-table">
          <Index.TableHead>
            <Index.TableRow>
              <Index.TableCell>Created Date</Index.TableCell>
              <Index.TableCell>Updated Date</Index.TableCell>
              <Index.TableCell>Status</Index.TableCell>
              <Index.TableCell>Approved / Rejected</Index.TableCell>
            </Index.TableRow>
          </Index.TableHead>
          <Index.TableBody>
            {loading ? (
              <Index.TableRow>
                <Index.TableCell
                  colSpan={6}
                  align="center"
                  className="loading-purchase"
                >
                  <Index.CircularProgress color="secondary" size={20} />
                </Index.TableCell>
              </Index.TableRow>
            ) : challanRequestData?.length ? (
              challanRequestData &&
              challanRequestData?.map((items, index) => {
                return (
                  <Index.TableRow>
                    <Index.TableCell>
                      <Index.Typography>
                        {Index.moment(items?.createdAt).format(
                          "DD/MM/YYYY h:mm:ss A"
                        )}
                      </Index.Typography>
                    </Index.TableCell>

                    <Index.TableCell>
                      <Index.Typography>
                        {Index.moment(items?.updatedAt).format(
                          "DD/MM/YYYY h:mm:ss A"
                        )}
                      </Index.Typography>
                    </Index.TableCell>

                    <Index.TableCell>
                      {items?.requestStatus ? items?.requestStatus : "-"}
                    </Index.TableCell>
                    <Index.TableCell>
                      <Index.Button
                        variant="contained"
                        onClick={() =>
                          changeSlopeSludgeChallanEditRequestStatus(
                            items?._id,
                            "Approved"
                          )
                        }
                        className="challan-status"
                        disabled={items?.checkRequest === true}
                      >
                        Approved
                      </Index.Button>
                      <Index.Button
                        variant="contained"
                        align="left"
                        className="challan-status reject-btn-align"
                        onClick={() =>
                          changeSlopeSludgeChallanEditRequestStatus(
                            items?._id,
                            "Rejected"
                          )
                        }
                        disabled={items?.checkRequest === true}
                      >
                        Rejected
                      </Index.Button>
                    </Index.TableCell>
                  </Index.TableRow>
                );
              })
            ) : (
              <Index.TableRow>
                <Index.TableCell
                  component="td"
                  variant="td"
                  scope="row"
                  className="no-data-in-list"
                  colSpan={6}
                  align="center"
                >
                  No data available
                </Index.TableCell>
              </Index.TableRow>
            )}
          </Index.TableBody>
        </Index.Table>
      </Index.TableContainer>
    </>
  );
};

export default SlopeAndSludgeChallanEditRequest;
