import "./App.css";
import Routes from "./routes/Routes";
import "./assets/style/admin.css";
import "./assets/style/meet.css";
import "./assets/style/responsive.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { DatePickerProvider } from "./context/DatePickerContext";

function App() {
  
  return (
    <div className="App">
      <ToastContainer />
      <DatePickerProvider>
        <Routes />
      </DatePickerProvider>
    </div>
  );
}

export default App;
