import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import * as Yup from "yup";
import { Formik } from "formik";
import CrewList from "./crew/CrewList";
import CraftDocument from "./document/CraftDocument";
import CraftPhotos from "./photos/CraftPhotos";
import MaintenanceTab from "./maintenance/MaintenanceTab";
import {
  addBarge,
  getSingleBarge,
  getCraftDetailActivityLog,
} from "../../../../redux/admin/action";
import { updateCraftCrewValidationSchema } from "../../../../validation/AdminValidation";

// Design Tab
function SingleOrderTab(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Index.Box sx={{ p: 3 }}>
          <Index.Typography>{children}</Index.Typography>
        </Index.Box>
      )}
    </div>
  );
}

SingleOrderTab.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function ViewCraft() {
  const navigate = Index.useNavigate();
  const dispatch = Index.useDispatch();
  const { id } = Index.useParams();

  const [craftDetails, setCraftDetails] = useState({});
  const [craftActivityList, setCraftActivityList] = useState([]);
  const [openCraftModel, setOpenCraftModel] = useState(false);
  const [value, setValue] = useState(0);

  let initialValues = {};
  if (craftDetails) {
    initialValues = craftDetails;
  } else {
    initialValues = {
      imoNumber: "",
      callSign: "",
    };
  }

  const handleFormSubmit = (values) => {
    const urlencoded = new URLSearchParams();
    if (craftDetails) {
      urlencoded.append("id", id);
    }
    urlencoded.append("imoNumber", values?.imoNumber);
    urlencoded.append("callSign", values?.callSign);
    dispatch(addBarge(urlencoded)).then((res) => {
      if (res?.status === 200) {
        getSingleBargeData();
      }
    });
    handleCraftModelClose();
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const backBtn = () => {
    navigate("/admin/craft");
  };

  const handleUpdateCraftModelOpen = () => {
    setOpenCraftModel(true);
  };

  const handleCraftModelClose = () => {
    setOpenCraftModel(false);
  };

  const fetchCraftActivityLogs = () => {
    const urlencoded = new URLSearchParams();
    urlencoded.append("activityTypeId", id);
    urlencoded.append("type", "barge");
    try {
      dispatch(getCraftDetailActivityLog(urlencoded)).then((res) => {
        if (res?.status === 200) {
          setCraftActivityList(res?.data);
        } else {
          setCraftActivityList([]);
        }
      });
    } catch (error) {}
  };

  const getSingleBargeData = () => {
    const urlencoded = new URLSearchParams();
    urlencoded.append("id", id);
    dispatch(getSingleBarge(urlencoded)).then((res) => {
      if (res?.status === 200) {
        setCraftDetails(res?.data);
      }
    });
  };

  useEffect(() => {
    getSingleBargeData();
    fetchCraftActivityLogs();
  }, [id]);

  return (
    <>
      <Index.Box className="invoice-details">
        <Index.Box className="barge-common-box mb-20">
          <Index.Box className="title-header-flex flex-column-res">
            <Index.Box className="invoice-back-title">
              <Index.Button
                type="submit"
                className="Particular_model_submit back-invoice-btn"
                onClick={backBtn}
              >
                <Index.ArrowBackIcon />
              </Index.Button>
              <Index.Typography
                variant="span"
                component="span"
                className="invoice-title"
              >
                Craft Detail
              </Index.Typography>
            </Index.Box>

            <Index.Box className="common-button blue-button res-blue-button">
              <Index.Button
                variant="outlined"
                onClick={() => {
                  handleUpdateCraftModelOpen();
                }}
                className="view-invoice-btn"
              >
                Update
              </Index.Button>
            </Index.Box>
          </Index.Box>

          <Index.Box className="mt-10">
            <Index.Box className="mb-5 invoice-detail-item">
              <Index.Box className="input-label opacity-60">
                Craft Name :
              </Index.Box>
              <Index.Box className="input-label">
                {craftDetails?.bargeName || "-"}
              </Index.Box>
            </Index.Box>

            <Index.Box className="mb-5 invoice-detail-item">
              <Index.Box className="input-label opacity-60">
                Craft Category :
              </Index.Box>
              <Index.Box className="input-label">
                {craftDetails?.bargeCategory || "-"}
              </Index.Box>
            </Index.Box>

            <Index.Box className="mb-5 invoice-detail-item">
              <Index.Box className="input-label opacity-60">
                Port Limit :
              </Index.Box>
              <Index.Box className="input-label">
                {craftDetails?.portLimit?.map((item, index) => {
                  let str =
                    index == craftDetails?.portLimit?.length - 1
                      ? item?.portName
                      : item?.portName + ", ";
                  return str?.toString();
                }) || "-"}
              </Index.Box>
            </Index.Box>

            <Index.Box className="mb-5 invoice-detail-item">
              <Index.Box className="input-label opacity-60">
                IMO Number :
              </Index.Box>
              <Index.Box className="input-label">
                {craftDetails?.imoNumber || "-"}
              </Index.Box>
            </Index.Box>

            <Index.Box className="mb-5 invoice-detail-item">
              <Index.Box className="input-label opacity-60">
                Call Sign :
              </Index.Box>
              <Index.Box className="input-label">
                {craftDetails?.callSign || "-"}
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>

        <Index.Box className="mb-20">
          {/* Design Tab */}
          <Index.Box sx={{ width: "100%" }}>
            <Index.Box
              className="tab-button-box"
              sx={{ borderBottom: 1, borderColor: "divider" }}
            >
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                className="dashboard-tab"
              >
                <Tab className="tab-button" label="Crew" {...a11yProps(0)} />
                <Tab
                  className="tab-button"
                  label="Documents"
                  {...a11yProps(1)}
                />
                <Tab className="tab-button" label="Photos" {...a11yProps(2)} />
                <Tab
                  className="tab-button"
                  label="Maintenance"
                  {...a11yProps(3)}
                />
                <Tab
                  className="tab-button"
                  label="Activity"
                  {...a11yProps(4)}
                />
              </Tabs>
            </Index.Box>
            <SingleOrderTab
              value={value}
              index={0}
              className="barge-common-box tab-content-box"
            >
              <CrewList craftId={id} />
            </SingleOrderTab>
            <SingleOrderTab
              value={value}
              index={1}
              className="barge-common-box tab-content-box"
            >
              <CraftDocument craftId={id} />
            </SingleOrderTab>

            <SingleOrderTab
              value={value}
              index={2}
              className="barge-common-box tab-content-box"
            >
              <CraftPhotos craftId={id} />
            </SingleOrderTab>
            <SingleOrderTab
              value={value}
              index={3}
              className="barge-common-box tab-content-box"
            >
              <MaintenanceTab craftId={id} />
            </SingleOrderTab>

            <SingleOrderTab
              value={value}
              index={4}
              className="barge-common-box tab-content-box"
            >
              {/* <Index.TableContainer>
                <Index.Table className="invoice-activity-log invoice-activity-log-set">
                  <Index.TableHead>
                    <Index.TableRow>
                      <Index.TableCell>Activity Type</Index.TableCell>
                      <Index.TableCell>Details</Index.TableCell>
                      <Index.TableCell>Date & Time</Index.TableCell>
                    </Index.TableRow>
                  </Index.TableHead>
                  <Index.TableBody>
                    {craftActivityList?.length ? (
                      craftActivityList &&
                      craftActivityList?.reverse()?.map((items, index) => {
                        return (
                          <Index.TableRow>
                            <Index.TableCell>
                              <Index.Typography>
                                {items?.activityType}
                              </Index.Typography>
                            </Index.TableCell>
                            <Index.TableCell>
                              <Index.Typography>
                                {items?.details}
                              </Index.Typography>
                            </Index.TableCell>
                            <Index.TableCell>
                              <Index.Typography>
                                {Index.moment(items?.createdAt).format(
                                  "DD/MM/YYYY h:mm:ss a"
                                )}
                              </Index.Typography>
                            </Index.TableCell>
                          </Index.TableRow>
                        );
                      })
                    ) : (
                      <Index.TableRow>
                        <Index.TableCell
                          component="td"
                          variant="td"
                          scope="row"
                          className="no-data-in-list"
                          colSpan={15}
                          align="center"
                        >
                          No data available
                        </Index.TableCell>
                      </Index.TableRow>
                    )}
                  </Index.TableBody>
                </Index.Table>
              </Index.TableContainer> */}

              <Index.Grid container spacing={1}>
                <Index.Box className="order-active-log-header expensedetails-table order-active-table-set">
                  <Index.TableContainer
                    component={Index.Paper}
                    className="table-container"
                  >
                    <Index.Table
                      aria-label="simple table"
                      className="table-design-main supply-table one-line-table invoice-table-set user-management-table tanker-list my-tanker-table"
                    >
                      <Index.TableHead>
                        <Index.TableRow>
                          <Index.TableCell>Activity Type</Index.TableCell>
                          <Index.TableCell>Details</Index.TableCell>
                          <Index.TableCell>Date & Time</Index.TableCell>
                        </Index.TableRow>
                      </Index.TableHead>

                      <Index.TableBody>
                        {craftActivityList?.length ? (
                          craftActivityList &&
                          craftActivityList?.map((items, index) => {
                            return (
                              <Index.TableRow>
                                <Index.TableCell>
                                  <Index.Typography>
                                    {items?.activityType}
                                  </Index.Typography>
                                </Index.TableCell>
                                <Index.TableCell>
                                  <Index.Typography>
                                    {items?.details}
                                  </Index.Typography>
                                </Index.TableCell>
                                <Index.TableCell>
                                  <Index.Typography>
                                    {Index.moment(items?.createdAt).format(
                                      "DD/MM/YYYY h:mm:ss a"
                                    )}
                                  </Index.Typography>
                                </Index.TableCell>
                              </Index.TableRow>
                            );
                          })
                        ) : (
                          <Index.TableRow>
                            <Index.TableCell
                              component="td"
                              variant="td"
                              scope="row"
                              className="no-data-in-list"
                              colSpan={15}
                              align="center"
                            >
                              No data available
                            </Index.TableCell>
                          </Index.TableRow>
                        )}
                      </Index.TableBody>
                    </Index.Table>
                  </Index.TableContainer>
                </Index.Box>
              </Index.Grid>
            </SingleOrderTab>
          </Index.Box>
          {/* Design Tab */}
        </Index.Box>
      </Index.Box>

      {/* Update Crew Model */}
      <Index.Dialog
        open={openCraftModel}
        onClose={handleCraftModelClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Index.DialogContent className="expense-model-main  expense-model-main-box-set">
          <Index.Box className="title-main">
            <Formik
              enableReinitialize
              initialValues={initialValues}
              validationSchema={updateCraftCrewValidationSchema}
              onSubmit={handleFormSubmit}
            >
              {({
                values,
                errors,
                touched,
                setFieldValue,
                handleChange,
                handleBlur,
                handleSubmit,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Index.Box className="title-main">
                   <Index.Box className="jus-between">
                    <Index.Typography
                      variant="h6"
                      component="h6"
                      className="input-label"
                      sx={{ marginBottom: "15px" }}
                    >
                      {`${id ? "Update" : "Add"} Craft`}
                    </Index.Typography>
                    <Index.ClearIcon
                      className="model-close-icon"
                      onClick={handleCraftModelClose}
                    />
                    </Index.Box>
                    <Index.Grid container spacing={2}>
                      <Index.Grid item xs={12} sm={6} md={6}>
                        <Index.Typography
                          variant="label"
                          component="label"
                          className="input-label"
                        >
                          IMO Number
                        </Index.Typography>
                        <Index.Box className="input-design-div with-border">
                          <Index.TextField
                            hiddenLabel
                            id="filled-hidden-label-normal"
                            placeholder="IMO Number"
                            variant="filled"
                            className="admin-input-design input-placeholder"
                            name="imoNumber"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values?.imoNumber}
                          />
                          {touched?.imoNumber && errors?.imoNumber && (
                            <Index.FormHelperText error>
                              {errors?.imoNumber}
                            </Index.FormHelperText>
                          )}
                        </Index.Box>
                      </Index.Grid>

                      <Index.Grid item xs={12} sm={6} md={6}>
                        <Index.Typography
                          variant="label"
                          component="label"
                          className="input-label"
                        >
                          Call Sign
                        </Index.Typography>
                        <Index.Box className="input-design-div with-border">
                          <Index.TextField
                            hiddenLabel
                            id="filled-hidden-label-normal"
                            placeholder="Call Sign"
                            variant="filled"
                            className="admin-input-design input-placeholder"
                            name="callSign"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values?.callSign}
                          />
                          {touched?.callSign && errors?.callSign && (
                            <Index.FormHelperText error>
                              {errors?.callSign}
                            </Index.FormHelperText>
                          )}
                        </Index.Box>
                      </Index.Grid>

                      <Index.Grid item xs={12} sm={6} md={12}>
                        <Index.Box
                          className="common-button blue-button flex-end save-btn"
                          sx={{
                            display: "flex",
                            alignItems: "flex-end",
                          }}
                        >
                          <Index.Button
                            variant="contained"
                            type="submit"
                            className="Particular_model_submit"
                          >
                            Submit
                          </Index.Button>
                        </Index.Box>
                      </Index.Grid>
                    </Index.Grid>

                    {/* <Index.Box className="line" mt={3} mb={3}></Index.Box> */}
                  </Index.Box>
                </form>
              )}
            </Formik>
          </Index.Box>
        </Index.DialogContent>
      </Index.Dialog>
    </>
  );
}
export default ViewCraft;
