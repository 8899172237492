import React, { useState } from "react";
import Index from "../../../Index";
import { Formik } from "formik";
import {
  addEDDOfTraderEnquriy,
  getTraderEnquiryList,
  getTraderEnquiriesList,
} from "../../../../redux/admin/action";
import * as yup from "yup";
import moment from "moment";
const EDDModalValidationSchema = yup.object().shape({
  edd: yup.date().required("EDD is required"),
});
function EnquiryEDDModal({
  isEDDModalOpen,
  handleClose,
  eddModalTimeData,
  handleReloadAfterAddEnquiry,
}) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
  };
  const [comment, setComment] = useState("");
  const dispatch = Index.useDispatch();

  let initialValues = {
    edd:
      eddModalTimeData !== undefined &&
      eddModalTimeData !== null &&
      eddModalTimeData?.hasOwnProperty("edd")
        ? Index.dayjs(eddModalTimeData?.edd)
        : "",
  };

  // when the user submit the form then used..
  const handleFormSubmit = React.useCallback(
    (data) => {
      const values = {
        id: eddModalTimeData._id,
        edd: data.edd,
      };
      dispatch(addEDDOfTraderEnquriy(values))
        .then((res) => {
          if (res.status === 200) {
            handleClose();
            // here through we get the updated listing
            handleReloadAfterAddEnquiry();
          }
        })
        .catch((err) => console.log("err-123", err));
    },
    [eddModalTimeData, dispatch]
  );

  return (
    <Index.Modal
      open={isEDDModalOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="modal-delete modal"
    >
      <Index.Box sx={style} className="delete-modal-inner-main modal-inner">
        <Index.ClearIcon className="model-close-icon" onClick={handleClose} />
        <Index.Box className="modal-circle-main">
          <img src={Index.Svg.close} className="user-circle-img" alt="icon" />
        </Index.Box>

        <Index.Typography
          className="delete-modal-title"
          component="h2"
          variant="h2"
        >
          Are you sure?
        </Index.Typography>
        <Index.Typography
          className="delete-modal-para common-para"
          component="p"
          variant="p"
        >
          Are you sure you want to update this record?
        </Index.Typography>
        <Formik
          initialValues={initialValues}
          validationSchema={EDDModalValidationSchema}
          onSubmit={handleFormSubmit}
        >
          {({
            values,
            errors,
            touched,
            setFieldValue,
            handleChange,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit}>
              <Index.Box className="title-main">
                <Index.Grid item xs={12} sm={12} md={12}>
                  <Index.Typography
                    variant="label"
                    component="label"
                    className="input-label"
                  >
                    EDD
                  </Index.Typography>
                  <Index.Box className="input-design-div">
                    <Index.Box className="set-date set-date-picker">
                      <Index.LocalizationProvider
                        dateAdapter={Index.AdapterDayjs}
                      >
                        <Index.DemoContainer components={["DatePicker"]}>
                          <Index.DemoItem label="">
                            <Index.DatePicker
                              format="DD/MM/YYYY"
                              defaultValue={
                                eddModalTimeData !== undefined &&
                                eddModalTimeData !== null &&
                                eddModalTimeData?.hasOwnProperty("edd")
                                  ? Index.dayjs(eddModalTimeData?.edd)
                                  : ""
                              }
                              onChange={(value) => {
                                const formatedDate = Index.moment(
                                  value.$d
                                ).format("YYYY-MM-DD");
                                setFieldValue("edd", formatedDate);
                              }}
                              // disablePast={true}
                            />
                          </Index.DemoItem>
                        </Index.DemoContainer>
                      </Index.LocalizationProvider>
                    </Index.Box>
                    {touched?.edd && errors?.edd && (
                      <Index.FormHelperText error>
                        {errors?.edd}
                      </Index.FormHelperText>
                    )}
                  </Index.Box>
                </Index.Grid>

                <Index.Box className="line" mt={3} mb={3}></Index.Box>
              </Index.Box>
              <Index.Box className="delete-modal-btn-flex">
                <Index.Button
                  className="modal-inquiry-success-btn modal-btn"
                  type="submit"
                >
                  Submit
                </Index.Button>
                <Index.Button
                  className="modal-inquiry-error-btn modal-btn"
                  onClick={handleClose}
                >
                  Close
                </Index.Button>
              </Index.Box>
            </form>
          )}
        </Formik>
      </Index.Box>
    </Index.Modal>
  );
}

export default EnquiryEDDModal;
