import React from "react";
import Index from "../../../Index";
import { Formik } from "formik";
import { addExpense } from "../../../../redux/admin/action";
import { expenseValidationSchema } from "../../../../validation/AdminValidation";

const AddExpenseModel = ({
  handleClose,
  setOpen,
  open,
  expenseModelData,
}) => {
  const dispatch = Index.useDispatch();
  // initialValues
  let initialValues = {
    expenseName: "",
    defaultPrice: "",
    expenseType: "",
  };

  if (expenseModelData) {
    initialValues = {
      expenseName: expenseModelData?._id ? expenseModelData?.expenseName : "",
      defaultPrice: expenseModelData?._id ? expenseModelData?.defaultPrice : "",
      expenseType: expenseModelData?.expenseType
        ? expenseModelData?.expenseType
        : "",
    };
  }

  // party model submit handler
  const handleFormSubmit = (values) => {
    let data = new URLSearchParams();
    data.append("expenseName", values?.expenseName);
    data.append("defaultPrice", values?.defaultPrice);
    data.append("expenseType", values?.expenseType);
    if (expenseModelData?._id) {
      data.append("id", expenseModelData?._id);
    }
    dispatch(addExpense(data));
    handleClose();
  };

  return (
    <div>
      <Index.Dialog
        open={open}
        onclose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="expense-add-main"
      >
        <Index.DialogContent className="expense-model-main" sx={{ maxWidth: 700 }}>
          <Index.Box className="title-main">
            <Formik
              enableReinitialize
              initialValues={initialValues}
              validationSchema={expenseValidationSchema}
              onSubmit={handleFormSubmit}
            >
              {({
                values,
                errors,
                touched,
                setFieldValue,
                handleChange,
                handleBlur,
                handleSubmit,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Index.Box className="title-main">
                    <Index.Box className="jus-between">
                      <Index.Typography
                        variant="h6"
                        component="h6"
                        className="input-label"
                        sx={{ marginBottom: "15px" }}
                      >
                        {
                          expenseModelData?._id ? "Edit" : "Add"
                        } Expense
                      </Index.Typography>
                      <Index.ClearIcon
                        className="model-close-icon"
                        onClick={handleClose}
                      />
                    </Index.Box>
                    <Index.Grid container spacing={3}>
                      <Index.Grid item xs={12} sm={6} md={6}>
                        <Index.Typography
                          variant="label"
                          component="label"
                          className="input-label"
                        >
                          Expense Name
                        </Index.Typography>
                        <Index.Box className="input-design-div with-border">
                          <Index.TextField
                            hiddenLabel
                            id="filled-hidden-label-normal"
                            placeholder="Expense name"
                            variant="filled"
                            className="admin-input-design input-placeholder"
                            name="expenseName"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values?.expenseName}
                            error={Boolean(
                              errors?.expenseName
                                ? touched?.expenseName
                                : undefined
                            )}
                            helperText={
                              touched?.expenseName
                                ? errors?.expenseName
                                : undefined
                            }
                          />
                        </Index.Box>
                      </Index.Grid>

                      <Index.Grid item xs={12} sm={6} md={6}>
                        <Index.Typography
                          variant="label"
                          component="label"
                          className="input-label"
                        >
                          Default Price
                        </Index.Typography>
                        <Index.Box className="input-design-div with-border">
                          <Index.TextField
                            hiddenLabel
                            id="filled-hidden-label-normal"
                            placeholder="Default price"
                            variant="filled"
                            className="admin-input-design input-placeholder"
                            name="defaultPrice"
                            type="number"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values?.defaultPrice}
                            error={Boolean(
                              errors?.defaultPrice
                                ? touched?.defaultPrice
                                : undefined
                            )}
                            helperText={
                              touched?.defaultPrice
                                ? errors?.defaultPrice
                                : undefined
                            }
                          />
                        </Index.Box>
                      </Index.Grid>

                      <Index.Grid item xs={12} sm={12} md={12}>
                        <Index.Typography
                          variant="label"
                          component="label"
                          className="input-label"
                        >
                          Expense Type
                        </Index.Typography>
                        <Index.Box className="input-design-div expense-type with-border">
                          <Index.RadioGroup
                            className="radio-button-options"
                            name="expenseType"
                            value={values?.expenseType}
                            onChange={(e) => {
                              setFieldValue("expenseType", e.target.value);
                            }}
                          >
                            <Index.FormControlLabel
                              value="Order"
                              control={<Index.Radio />}
                              label="Order"
                            />
                            <Index.FormControlLabel
                              value="Other"
                              control={<Index.Radio />}
                              label="Other"
                            />
                          </Index.RadioGroup>
                          {touched?.expenseType && errors?.expenseType && (
                            <Index.FormHelperText error>
                              {errors?.expenseType}
                            </Index.FormHelperText>
                          )}
                        </Index.Box>
                      </Index.Grid>

                      <Index.Grid item xs={12} sm={12} md={12}>
                        <Index.Box className="common-button blue-button flex-end save-btn party_btn add-ex-modal-btn gap-10">
                          <Index.Button
                            variant="contained"
                            type="submit"
                            className="Particular_model_submit party_submit_btn"
                          >
                            {expenseModelData?._id ? "Update" : "Submit"}
                          </Index.Button>
                          <Index.Button
                            variant="contained"
                            type="button"
                            className="Particular_model_submit"
                            onClick={() => handleClose(setOpen(false))}
                          >
                            Cancel
                          </Index.Button>
                        </Index.Box>
                      </Index.Grid>
                    </Index.Grid>
                  </Index.Box>
                </form>
              )}
            </Formik>
          </Index.Box>
        </Index.DialogContent>
      </Index.Dialog>
    </div>
  );
};

export default AddExpenseModel;
