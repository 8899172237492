import React, { useEffect, useState } from "react";
import Index from "../../../../../Index";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { getPuchaseInvoiceDetails } from "../../../../../../redux/admin/action";
import { useSearchParams } from "react-router-dom";
import { NEW_EXPORT_EXCEL } from "../../../ExportAll";
function PurchaseInvoice() {
  const params = Index.useParams();
  const dispatch = Index.useDispatch();
  const navigate = Index.useNavigate();
  const [searchParams] = useSearchParams();

  const [purchaseInvoiceRecords, setPurchaseInvoiceRecords] = React.useState(
    []
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState(0);
  const [paginationPerPage, setPaginationPerPage] = useState(50);
  const [totalRecoredsCount, setTotalRecoredsCount] = React.useState(0);

  const [loading, setLoading] = React.useState(false);

  const [currentRows, setCurrentRows] = useState([]);

  const backBtn = () => {
    navigate("/admin/companyDashboard");
  };

  //   const getOverDueDays = (NewDate) => {
  //     const startDate = Index.moment(new Date());
  //     var currentDate = Index.moment(NewDate);
  //     var days = startDate.diff(currentDate, "days");
  //     // console.log(days, startDate, NewDate, currentDate,  "Date console");
  //     return days;
  //   };

  const handleGetPurchaseInvoiceDetails = async (values) => {
    setLoading(true);

    dispatch(
      getPuchaseInvoiceDetails({
        ...values,
        companyId: searchParams.get("companyId"),
      })
    )
      .then((res) => {
        if (res?.status === 200) {
          setLoading(false);
          setPurchaseInvoiceRecords(res?.data);
          setTotalRecoredsCount(res?.totalCount);
          setCurrentPage(res?.currentPage);
        } else {
          setPurchaseInvoiceRecords([]);
          setLoading(false);
          setTotalRecoredsCount(0);
          setCurrentPage(1);
        }
      })
      .catch((err) => {
        setPurchaseInvoiceRecords([]);
        setLoading(false);
        setTotalRecoredsCount(0);
        setCurrentPage(1);
      });
  };

  React.useEffect(() => {
    const values = {
      bargeId: searchParams.get("bargeId"),
      bargName: searchParams.get("bargName"),
      to: searchParams.get("to"),
      from: searchParams.get("from"),
      page: 1,
      size: 50,
    };
    handleGetPurchaseInvoiceDetails(values);
  }, []);

  const handlePageChange = React.useCallback(
    (event, value) => {
      const values = {
        bargeId: searchParams.get("bargeId"),
        bargName: searchParams.get("bargName"),
        to: searchParams.get("to"),
        from: searchParams.get("from"),
        page: value,
        size: paginationPerPage,
      };
      handleGetPurchaseInvoiceDetails(values);
      setCurrentPage(value);
    },
    [setCurrentPage, paginationPerPage]
  );

  const handleChangePaginationPerPage = React.useCallback(
    (e) => {
      const values = {
        bargeId: searchParams.get("bargeId"),
        bargName: searchParams.get("bargName"),
        to: searchParams.get("to"),
        from: searchParams.get("from"),
        page: currentPage,
        size: e.target.value,
      };
      handleGetPurchaseInvoiceDetails(values);
      setPaginationPerPage(e.target.value);
    },
    [currentPage, setPaginationPerPage]
  );

  return (
    <div>
      <Index.DynamicTitle title="Purchase Invoice Management" />
      <Index.Box className="">
        <Index.Box className="barge-common-box">
          <Index.Box className="invoice-details title-header">
            <Index.Box className="title-header-flex  res-title-header-flex">
              <Index.Box className="invoice-back-title title-main">
                <Index.Button
                  type="submit"
                  className="Particular_model_submit back-invoice-btn"
                  onClick={backBtn}
                >
                  <ArrowBackIcon />
                </Index.Button>

                <Index.Typography
                  variant="p"
                  component="p"
                  className="page-title"
                >
                  {/* {
                    // Type check and show title and other details
                    type === "invoice" ? (
                      <>
                        {typeName} : {invoiceData ? invoiceData?.count : ""} -
                        (₹{" "}
                        {invoiceData?.totalTaxableAmount?.toLocaleString(
                          "en-IN",
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }
                        )}
                        )
                      </>
                    ) : type === "bunker" ? (
                      <>
                        {typeName} : {invoiceData ? invoiceData?.count : ""} -
                        {invoiceData?.itemName}. ({invoiceData?.totalQty})
                      </>
                    ) : (
                      <>
                        {typeName} : {invoiceData ? invoiceData?._id : ""} - (₹{" "}
                        {invoiceData?.amount?.toLocaleString("en-IN", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                        )
                      </>
                    )
                  } */}
                  Barge Wise : {searchParams.get("bargName")}
                </Index.Typography>
              </Index.Box>

              <Index.Box className="common-button grey-button ">
                <Index.Button
                  variant="contained"
                  onClick={() => {
                    let curDate = Index.moment(new Date()).format("DD-MM-YYYY");
                    NEW_EXPORT_EXCEL(
                      "/admin/barge-purchase-all",
                      "Purchase-Invoice-Details",
                      {
                        bargeId: searchParams.get("bargeId"),
                        bargName: searchParams.get("bargName"),
                        to: searchParams.get("to"),
                        from: searchParams.get("from"),
                        companyId: searchParams.get("companyId"),
                      }
                    );
                  }}
                >
                  Export
                </Index.Button>
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Index.Box className="">
            <Index.TableContainer
              component={Index.Paper}
              className="table-container"
            >
              <Index.Table
                aria-label="simple table"
                className="table-design-main invoice-table invoice-table-set  "
              >
                <Index.TableHead>
                  <Index.TableRow>
                    <Index.TableCell className="invoice_table_header">
                      Date
                    </Index.TableCell>
                    <Index.TableCell
                      align="left"
                      className="invoice_table_header"
                    >
                      Party Name
                    </Index.TableCell>
                    <Index.TableCell
                      align="left"
                      className="invoice_table_header"
                    >
                      Expense Head
                    </Index.TableCell>
                    <Index.TableCell
                      align="left"
                      className="invoice_table_header"
                    >
                      Voucher Number
                    </Index.TableCell>
                    <Index.TableCell
                      align="left"
                      className="invoice_table_header"
                    >
                      Amount
                    </Index.TableCell>
                    <Index.TableCell
                      align="left"
                      className="invoice_table_header"
                    >
                      Expense Type
                    </Index.TableCell>
                  </Index.TableRow>
                </Index.TableHead>
                {loading ? (
                  <Index.TableBody>
                    <Index.TableRow>
                      <Index.TableCell
                        colSpan={12}
                        align="center"
                        className="loading-purchase"
                      >
                        <Index.CircularProgress color="secondary" size={20} />
                      </Index.TableCell>
                    </Index.TableRow>
                  </Index.TableBody>
                ) : (
                  <Index.TableBody>
                    {purchaseInvoiceRecords?.length ? (
                      purchaseInvoiceRecords &&
                      purchaseInvoiceRecords?.map((item, index) => {
                        let row = item?.matchedData[0];
                        return (
                          <Index.TableRow
                            key={
                              row?._id +
                              new Date(row?.invoiceDate).getMilliseconds() +
                              index
                            }
                          >
                            <Index.TableCell>
                              {row?.date
                                ? Index.moment(row?.date).format("DD/MM/YYYY")
                                : "-"}
                            </Index.TableCell>
                            <Index.TableCell>
                              {row?.party_name ? row?.party_name : "-"}
                            </Index.TableCell>
                            <Index.TableCell>
                              {row?.expense_head ? row?.expense_head : "-"}
                            </Index.TableCell>
                            <Index.TableCell>
                              {row?.voucher_number ? row?.voucher_number : "-"}
                            </Index.TableCell>
                            <Index.TableCell>
                              {item?.amount
                                ? item?.amount?.toLocaleString("en-IN", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })
                                : 0?.toLocaleString("en-IN", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}
                            </Index.TableCell>

                            <Index.TableCell>
                              {row?.expense_type === "Purchase Accounts"
                                ? "Direct"
                                : row?.expense_type === "Direct Expenses"
                                ? "Direct"
                                : row?.expense_type === "Indirect Expenses"
                                ? "Indirect"
                                : ""}
                            </Index.TableCell>
                          </Index.TableRow>
                        );
                      })
                    ) : (
                      <Index.TableRow>
                        <Index.TableCell
                          component="td"
                          variant="td"
                          scope="row"
                          className="no-data-in-list empty-record-align"
                          colSpan={12}
                          align="center"
                        >
                          No data available
                        </Index.TableCell>
                      </Index.TableRow>
                    )}
                  </Index.TableBody>
                )}
              </Index.Table>
            </Index.TableContainer>
          </Index.Box>

          <Index.Box className="pagination-design flex-end">
            <Index.Stack spacing={2}>
              <Index.Box className="pagination-count pagination-loader">
                <Index.Box className="input-design-div with-border">
                  <Index.Select
                    fullWidth
                    value={paginationPerPage}
                    onChange={(e) => handleChangePaginationPerPage(e)}
                  >
                    <Index.MenuItem value={10}>10</Index.MenuItem>
                    <Index.MenuItem value={25}>25</Index.MenuItem>
                    <Index.MenuItem value={50}>50</Index.MenuItem>
                    <Index.MenuItem value={100}>100</Index.MenuItem>
                  </Index.Select>
                </Index.Box>
                {loading ? (
                  <Index.CircularProgress color="secondary" size={26} />
                ) : (
                  <Index.Pagination
                    count={Math.ceil(totalRecoredsCount / paginationPerPage)}
                    page={currentPage}
                    onChange={handlePageChange}
                  />
                )}
              </Index.Box>
            </Index.Stack>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </div>
  );
}

export default PurchaseInvoice;
