import React, { useCallback, useEffect, useState } from "react";
import Index from "../../../Index";
import {
  addChallanEditReqStatus,
  addChallanISEditable,
  addChallanStatus,
  getChallanList,
  getInvoiceActivityLog,
  getSingleChallanEditRequest,
  getLoadingOrderTankerDetailsRequest,
  cancelOrder,
  getSingleTugOrder,
  getTugChallanList,
  addTugChallanStatus,
  getSingleTugChallanEditRequest,
  addTugChallanEditReqStatus,
  getSingleTugChallanTugTiming,
  cancelSingleTugOrder,
} from "../../../../redux/admin/action";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useLocation } from "react-router-dom";
// import OrderDocument from "./OrderDocument";
// import OrderStatusModel from "../../../../component/common/OrderStatusModel";
import OrderStatusModel from "../../../../component/common/OrderStatusModel";
import TankerShiftModel from "../orderadd/TankerShiftModel";
// import TankerShiftModel from "./TankerShiftModel";

// Design Tab
function SingleOrderTab(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Index.Box sx={{ p: 3 }}>
          <Index.Typography>{children}</Index.Typography>
        </Index.Box>
      )}
    </div>
  );
}

SingleOrderTab.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
//  style for model
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function SingleTugOrder() {
  const navigate = Index.useNavigate();
  const dispatch = Index.useDispatch();
  const { id } = Index.useParams();
  const { state } = useLocation();
  const tabType = state?.type;
  const { invoiceActivityLogList } = Index.useSelector(
    (state) => state.AdminReducer
  );

  console.log("state-555", state);

  // state
  const [viewData, setViewData] = useState({});
  const [challanData, setChallanData] = useState([]);
  const [challanImg, setChallanImg] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingRowId, setLoadingRowId] = useState();
  const [value, setValue] = useState(state?.value ? state?.value : 0);
  const [open, setOpen] = useState(false);
  const [challanRequestData, setChallanRequestData] = useState([]);

  const [showModal, setShowModal] = useState(true);
  const [isShifted, setIsShifted] = useState(false);
  const [tankerLoadingDetail, setTankerLoadingDetail] = useState({});
  const [loadingTankers, setLoadingTankers] = useState([]);
  const [openModalId, setOpenModalId] = useState();
  const [cancelOpen, setCancelOpen] = useState(false);
  const [status, setStatus] = useState(true);

  console.log(challanData, 96);

  console.log(challanData?.[0]?._id, 80);

  console.log(challanImg, 1099);

  console.log(viewData, 107);
  const [tankerOpen, setTankerOpen] = useState(false);
  const [loadingSingleData, setLoadingSingleData] = useState(false);
  // const [updateTanker, setUpdateTanker] = useState(false);

  // console.log(openModalId, 99);

  // state for checkbox
  const [tankerCheckId, setTankerCheckId] = useState([]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // let singleData = state?.row;
  const backBtn = () => {
    navigate("/admin/tugsOrder");
    if (tabType === "cancelOrder") {
      navigate("/admin/tugsOrder", { state: { value: 1 } });
    }
    if (tabType === "completedOrder") {
      navigate("/admin/tugsOrder", { state: { value: 2 } });
    }
  };

  const handleEdit = useCallback(() => {
    console.log("checking row data....", viewData);
    navigate("/admin/tugsOrder/add", {
      state: { row: { ...viewData } },
    });
    if (tabType === "cancelOrder") {
      navigate("/admin/tugsOrder/add", {
        state: { row: viewData, type: "cancelOrder" },
      });
    }
    if (tabType === "completedOrder") {
      navigate("/admin/tugsOrder/add", {
        state: { row: viewData, type: "completedOrder" },
      });
    }
  }, [viewData, tabType]);

  const handleOpen = (img) => {
    setOpen(true);
    setChallanImg(img);
  };
  const handleClose = () => setOpen(false);

  const getSingleOrderList = () => {
    setLoadingSingleData(true);
    dispatch(getSingleTugOrder({ id })).then((res) => {
      setViewData(res?.data);
      if (res?.status === 200) {
        getOrderLog();
        setTimeout(() => {
          setLoadingSingleData(false);
        }, 1000);
      } else {
        setTimeout(() => {
          setLoadingSingleData(false);
        }, 1000);
      }
    });
  };

  // status approved
  const statusApproved = (item) => {
    let data = {
      id: item?._id,
      status: "Approved",
    };
    dispatch(addTugChallanStatus(data)).then((res) => {
      if (res?.status === 200) {
        getAllChallanList();
        getOrderLog();
      }
    });
  };

  // status Reject
  const statusRejected = (item) => {
    let data = {
      id: item?._id,
      status: "Rejected",
    };
    dispatch(addTugChallanStatus(data)).then((res) => {
      if (res?.status === 200) {
        getAllChallanList();
        getOrderLog();
      }
    });
  };

  // edit challan request approved
  const challanEditRequestStatusApproved = (item) => {
    let data = {
      id: item?._id,
      editStatus: "Approved",
    };
    dispatch(addTugChallanEditReqStatus(data)).then((res) => {
      if (res?.status === 200) {
        singleChallanEditRequest();
        getOrderLog();
      }
    });
  };

  const showTankerDetails = (item, action) => {
    setOpenModalId(item?._id);
    if (openModalId === item?._id && action === "down") {
      setShowModal(false);
    } else {
      setShowModal(true);
    }
    setTankerLoadingDetail(item);
  };

  // edit challan request reject
  const challanEditRequestStatusReject = (item) => {
    let data = {
      id: item?._id,
      editStatus: "Rejected",
    };
    dispatch(addTugChallanEditReqStatus(data)).then((res) => {
      if (res?.status === 200) {
        singleChallanEditRequest();
        getOrderLog();
      }
    });
  };

  const getAllChallanList = () => {
    const data = new URLSearchParams();
    const tugOrderId = id;
    dispatch(getTugChallanList(tugOrderId)).then((res) => {
      if (res?.data?.[0]?.tugChallanImage) {
        setChallanData(res?.data);
      } else {
        setChallanData([]);
      }
      getOrderLog();
    });
  };

  // const getSingleTugChallanTugTimingData = (id) => {
  //   const data = new URLSearchParams();
  //   data.append("id", id); // order id
  //   dispatch(getSingleTugChallanTugTiming(data)).then((res) => {
  //     setLoadingTankers(res?.data);
  //     setIsShifted(false);
  //   });
  // };

  // const getLoadingTankerDetails = (id) => {
  //   const data = new URLSearchParams();
  //   data.append("id", id); // order id
  //   dispatch(getLoadingOrderTankerDetailsRequest(data)).then((res) => {
  //     setLoadingTankers(res?.data);
  //     setIsShifted(false);
  //   });
  // };

  // get challan list
  useEffect(() => {
    getAllChallanList();
    // getLoadingTankerDetails(id); // Loading tanker details
    getSingleOrderList();
  }, [isShifted]);

  // filter for activity log
  const filterOrderLogData = invoiceActivityLogList.filter((item) => {
    return item.activityTypeId === viewData?._id;
  });

  const getOrderLog = () => {
    const logData = {
      activityTypeId: id,
      type: "tugOrder",
    };
    dispatch(getInvoiceActivityLog(logData));
  };

  useEffect(() => {
    getOrderLog();
  }, []);

  const handleChallanISEditable = (id) => {
    const data = new URLSearchParams();
    setLoadingRowId(id);
    setLoading(true);
    data.append("id", id);
    dispatch(addChallanISEditable(data)).then((res) => {
      if (res === 200 || 201) {
        getAllChallanList();
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      } else {
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      }
    });
  };

  // get single challan edit request
  const singleChallanEditRequest = () => {
    if (challanData?.length > 0) {
      const challanID = challanData?.[0]?._id;
      // const data = new URLSearchParams();
      // data.append("id", challanID);
      const id = challanID;
      dispatch(getSingleTugChallanEditRequest(id)).then((res) => {
        setChallanRequestData(res?.data);
      });
    }
  };
  useEffect(() => {
    if (challanData?.length > 0) {
      singleChallanEditRequest();
    }
  }, [challanData]);

  const handleEditBunkerTiming = (row) => {
    console.log(row, 324);
    row[0].orderId = id;
    navigate("/admin/tugsOrder/tug-timing", {
      state: { row, type: "editTug", value: value },
    });
    // navigate("/admin/tugsOrder/tug-timing");
  };

  // cancel order popup
  const cancelOrderModelOpen = () => {
    // setAnchorEl(null);
    setCancelOpen(true);
  };

  const cancelOrderModelClose = () => {
    setCancelOpen(false);
    // setStatus(true);
  };

  // cancel Order button
  const cancelOrderBtn = (status, comment) => {
    const data = new URLSearchParams();
    data.append("id", id);
    data.append("cancelStatus", "Cancel");
    data.append("comment", comment);

    dispatch(cancelSingleTugOrder(data, navigate));
  };

  // model open for shift tanker
  const shiftTankerHandleOpen = (id) => {
    setTankerOpen(true);
  };
  const shiftTankerHandleClose = () => {
    setTankerOpen(false);
  };

  // tanker dahboard view page
  const handleTankerDashboardView = (id) => {
    console.log(id, 355);
    navigate(`/admin/tankers/dashboard/view/${id}`);
  };
  return (
    <>
      {loadingSingleData ? (
        <>
          <Index.Table>
            <Index.TableBody>
              <Index.TableRow>
                <Index.TableCell
                  colSpan={13}
                  align="center"
                  className="loading-purchase"
                >
                  <Index.CircularProgress color="secondary" size={20} />
                </Index.TableCell>
              </Index.TableRow>
            </Index.TableBody>
          </Index.Table>
        </>
      ) : (
        <>
          <Index.Box className="invoice-details">
            <Index.Box className="barge-common-box mb-20">
              <Index.Box className="title-header-flex flex-column-res">
                <Index.Box className="invoice-back-title">
                  <Index.Button
                    type="submit"
                    className="Particular_model_submit back-invoice-btn"
                    onClick={backBtn}
                  >
                    <Index.ArrowBackIcon />
                  </Index.Button>
                  <Index.Typography
                    variant="span"
                    component="span"
                    className="invoice-title"
                  >
                    {/* Tug Order Detail */}
                    {tabType === "cancelOrder"
                      ? "Tug Cancelled Order Details"
                      : tabType === "completedOrder"
                      ? "Tug Completed Order Details"
                      : "Tug Order Details"}
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="common-button blue-button save-btn res-button-width">
                  <Index.Button
                    variant="contained"
                    type="submit"
                    className="Particular_model_submit edit-invoice-btn"
                    onClick={handleEdit}
                  >
                    <Index.EditIcon />
                  </Index.Button>
                  {viewData?.tugOrderStatus !== "Cancel" ? (
                    <Index.Button
                      variant="contained"
                      disableRipple
                      onClick={(e) => {
                        cancelOrderModelOpen();
                      }}
                    >
                      Cancel Order
                    </Index.Button>
                  ) : (
                    ""
                  )}
                </Index.Box>
              </Index.Box>

              <Index.Box className="mt-10">
                <Index.Box className="mb-5 invoice-detail-item">
                  <Index.Box className="input-label opacity-60">
                    Name of Vessel:
                  </Index.Box>
                  <Index.Box className="input-label">
                    {viewData?.vesselName ? viewData?.vesselName : "-"}
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>

            <Index.Box className="mb-20">
              {/* Design Tab */}
              <Index.Box sx={{ width: "100%" }}>
                <Index.Box
                  className="tab-button-box"
                  sx={{ borderBottom: 1, borderColor: "divider" }}
                >
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    className="dashboard-tab"
                  >
                    <Tab
                      className="tab-button"
                      label="Details"
                      {...a11yProps(0)}
                    />

                    <Tab
                      className="tab-button"
                      label="Challan List"
                      {...a11yProps(1)}
                    />

                    <Tab
                      className="tab-button"
                      label="Tug Timing"
                      {...a11yProps(2)}
                    />

                    <Tab
                      className="tab-button"
                      label=" Challan Edit Request"
                      {...a11yProps(3)}
                    />

                    <Tab
                      className="tab-button"
                      label="Activity"
                      {...a11yProps(4)}
                    />
                  </Tabs>
                </Index.Box>

                <SingleOrderTab
                  value={value}
                  index={0}
                  className="barge-common-box tab-content-box"
                >
                  <Index.Grid container spacing={1}>
                    <Index.Grid item xs={12} sm={6}>
                      <Index.Box className="invoice-detail-item">
                        <Index.Box className="input-label opacity-60">
                          Agent Name:
                        </Index.Box>
                        <Index.Box className="input-label">
                          {viewData?.agentName ? viewData?.agentName : "-"}
                        </Index.Box>
                      </Index.Box>
                    </Index.Grid>

                    <Index.Grid item xs={12} sm={6}>
                      <Index.Box className="invoice-detail-item">
                        <Index.Box className="input-label opacity-60">
                          Port:
                        </Index.Box>
                        <Index.Box className="input-label">
                          {viewData?.port?.tugPortName
                            ? viewData?.port?.tugPortName
                            : "-"}
                        </Index.Box>
                      </Index.Box>
                    </Index.Grid>
                    <Index.Grid item xs={12} sm={6}>
                      <Index.Box className="invoice-detail-item">
                        <Index.Box className="input-label opacity-60">
                          Booking Date:
                        </Index.Box>
                        <Index.Box className="input-label">
                          {viewData?.bookingDate
                            ? Index.moment(viewData.bookingDate).format(
                                "DD/MM/YYYY HH:mm"
                              )
                            : "-"}
                        </Index.Box>
                      </Index.Box>
                    </Index.Grid>
                    <Index.Grid item xs={12} sm={6}>
                      <Index.Box className="invoice-detail-item">
                        <Index.Box className="input-label opacity-60">
                          Sailing Date:
                        </Index.Box>
                        <Index.Box className="input-label">
                          {viewData?.sailingDate
                            ? Index.moment(viewData.sailingDate).format(
                                "DD/MM/YYYY HH:mm"
                              )
                            : "-"}
                        </Index.Box>
                      </Index.Box>
                    </Index.Grid>
                  </Index.Grid>
                </SingleOrderTab>

                <SingleOrderTab
                  value={value}
                  index={1}
                  className="barge-common-box tab-content-box"
                >
                  <Index.TableContainer>
                    <Index.Table className="challan-table challan-table-set challan-table-font challan-table-header">
                      <Index.TableHead>
                        <Index.TableRow>
                          <Index.TableCell>Created Date</Index.TableCell>
                          <Index.TableCell className="img-header-align">
                            Image
                          </Index.TableCell>
                          <Index.TableCell>Updated Date</Index.TableCell>
                          {/* <Index.TableCell>Is Editable</Index.TableCell> */}
                          <Index.TableCell>Status</Index.TableCell>
                          <Index.TableCell>Approve / Reject</Index.TableCell>
                        </Index.TableRow>
                      </Index.TableHead>
                      <Index.TableBody>
                        {challanData?.length ? (
                          challanData &&
                          challanData?.map((items, index) => {
                            console.log(items);
                            return (
                              <Index.TableRow>
                                <Index.TableCell>
                                  <Index.Typography>
                                    {Index.moment(items?.createdAt).format(
                                      "DD/MM/YYYY h:mm:ss A"
                                    )}
                                  </Index.Typography>
                                </Index.TableCell>
                                <Index.TableCell style={{ padding: 0 }}>
                                  <div>
                                    <Index.Button
                                      onClick={() => {
                                        handleOpen(items?.tugChallanImage);
                                      }}
                                    >
                                      {items?.tugChallanImage ? (
                                        <img
                                          src={`${
                                            process.env.REACT_APP_IMAGE_URL
                                          }/tugChallan/${
                                            items?.tugChallanImage
                                              ? items?.tugChallanImage
                                              : ""
                                          }`}
                                          alt="Image Preview"
                                          className="challan-img-model"
                                        />
                                      ) : (
                                        <Index.Box className="user-no-img">
                                          No Image
                                        </Index.Box>
                                      )}
                                    </Index.Button>
                                    <Index.Modal
                                      open={open}
                                      onClose={handleClose}
                                      aria-labelledby="modal-modal-title"
                                      aria-describedby="modal-modal-description"
                                    >
                                      <Index.Box sx={style}>
                                        <Index.ClearIcon
                                          className="model-close-icon"
                                          onClick={handleClose}
                                        />
                                        <Index.Typography
                                          id="modal-modal-title"
                                          variant="h6"
                                          component="h2"
                                        >
                                          {challanImg ? (
                                            <img
                                              src={`${
                                                process.env.REACT_APP_IMAGE_URL
                                              }/tugChallan/${
                                                challanImg ? challanImg : ""
                                              }`}
                                              alt="Image Preview"
                                              className="challan-img"
                                              // className="img-header-align"
                                            />
                                          ) : (
                                            <Index.Box className="user-no-img">
                                              No Image
                                            </Index.Box>
                                          )}
                                        </Index.Typography>
                                      </Index.Box>
                                    </Index.Modal>
                                  </div>
                                </Index.TableCell>
                                <Index.TableCell>
                                  <Index.Typography>
                                    {Index.moment(items?.updatedAt).format(
                                      "DD/MM/YYYY h:mm:ss A"
                                    )}
                                  </Index.Typography>
                                </Index.TableCell>
                                {/* <Index.TableCell>
                              {loading && loadingRowId === items?._id ? (
                                <Index.Box className="challan-iseditable-main">
                                  <Index.CircularProgress
                                    className="challan-iseditable"
                                    color="secondary"
                                    size={20}
                                  />
                                </Index.Box>
                              ) : (
                                <Index.Switch
                                  {...label}
                                  checked={items?.isEditable}
                                  onClick={() =>
                                    handleChallanISEditable(items?._id)
                                  }
                                  color="success"
                                />
                              )}
                            </Index.TableCell> */}
                                <Index.TableCell>
                                  {items?.challanStatus
                                    ? items?.challanStatus
                                    : "-"}
                                </Index.TableCell>
                                <Index.TableCell>
                                  <Index.Button
                                    variant="contained"
                                    onClick={() => statusApproved(items)}
                                    className="challan-status"
                                    disabled={items?.actionStatus === true}
                                  >
                                    Approve
                                  </Index.Button>
                                  <Index.Button
                                    variant="contained"
                                    align="left"
                                    className="challan-status reject-btn-align"
                                    onClick={() => statusRejected(items)}
                                    disabled={items?.actionStatus === true}
                                  >
                                    Reject
                                  </Index.Button>
                                </Index.TableCell>
                              </Index.TableRow>
                            );
                          })
                        ) : (
                          <Index.TableRow>
                            <Index.TableCell
                              component="td"
                              variant="td"
                              scope="row"
                              className="no-data-in-list"
                              colSpan={24}
                              align="center"
                            >
                              No data available
                            </Index.TableCell>
                          </Index.TableRow>
                        )}
                      </Index.TableBody>
                    </Index.Table>
                  </Index.TableContainer>
                </SingleOrderTab>

                <SingleOrderTab
                  value={value}
                  index={2}
                  className="barge-common-box tab-content-box"
                >
                  <Index.Box className="common-button blue-button save-btn res-button-width res-button-add-set">
                    {challanData?.[0]?._id ? (
                      <>
                        <Index.Button
                          className="set-active-btn"
                          variant="contained"
                          type="submit"
                          onClick={() => handleEditBunkerTiming(challanData)}
                        >
                          Edit Tug Timing
                        </Index.Button>
                      </>
                    ) : (
                      ""
                    )}
                  </Index.Box>
                  <Index.Box className="">
                    <Index.Grid container spacing={2}>
                      {challanData?.length ? (
                        challanData &&
                        challanData?.map((items, index) => {
                          return (
                            <>
                              <Index.Grid item xs={12} sm={6} md={4}>
                                <Index.Box>
                                  <Index.Box>
                                    <Index.Typography
                                      variant="label"
                                      component="label"
                                      className="input-label opacity-60"
                                    >
                                      Anchor Awigh
                                    </Index.Typography>
                                  </Index.Box>
                                </Index.Box>

                                <Index.Box className="input-design-div with-border">
                                  <Index.Typography
                                    variant="label"
                                    component="label"
                                    className="input-label"
                                  >
                                    {items?.anchorAweighForSupply
                                      ? Index.moment(
                                          items?.anchorAweighForSupply
                                        ).format("DD/MM/YYYY HH:mm")
                                      : "-"}
                                  </Index.Typography>
                                </Index.Box>
                              </Index.Grid>

                              <Index.Grid item xs={12} sm={6} md={4}>
                                <Index.Box>
                                  <Index.Box>
                                    <Index.Typography
                                      variant="label"
                                      component="label"
                                      className="input-label opacity-60"
                                    >
                                      Alongside On Vessel
                                    </Index.Typography>
                                  </Index.Box>
                                </Index.Box>

                                <Index.Box className="input-design-div with-border">
                                  <Index.Typography
                                    variant="label"
                                    component="label"
                                    className="input-label"
                                  >
                                    {items?.alongsideOnVessel
                                      ? Index.moment(
                                          items?.alongsideOnVessel
                                        ).format("DD/MM/YYYY HH:mm")
                                      : "-"}
                                  </Index.Typography>
                                </Index.Box>
                              </Index.Grid>
                              <Index.Grid item xs={12} sm={6} md={4}>
                                <Index.Box>
                                  <Index.Box>
                                    <Index.Typography
                                      variant="label"
                                      component="label"
                                      className="input-label opacity-60"
                                    >
                                      Cast Off
                                    </Index.Typography>
                                  </Index.Box>
                                </Index.Box>

                                <Index.Box className="input-design-div with-border">
                                  <Index.Typography
                                    variant="label"
                                    component="label"
                                    className="input-label"
                                  >
                                    {/* {items?.alongsideOnVessel} */}
                                    {items?.castOff
                                      ? Index.moment(items?.castOff).format(
                                          "DD/MM/YYYY HH:mm"
                                        )
                                      : "-"}
                                  </Index.Typography>
                                </Index.Box>
                              </Index.Grid>
                              <Index.Grid item xs={12} sm={6} md={4}>
                                <Index.Box>
                                  <Index.Box>
                                    <Index.Typography
                                      variant="label"
                                      component="label"
                                      className="input-label opacity-60"
                                    >
                                      Issue Challan Number
                                    </Index.Typography>
                                  </Index.Box>
                                </Index.Box>

                                <Index.Box className="input-design-div with-border">
                                  <Index.Typography
                                    variant="label"
                                    component="label"
                                    className="input-label"
                                  >
                                    {/* {items?.pumpingStart} */}
                                    {items?.issuedChallanNumber
                                      ? items?.issuedChallanNumber
                                      : "-"}
                                  </Index.Typography>
                                </Index.Box>
                              </Index.Grid>
                              <Index.Grid item xs={12} sm={6} md={4}>
                                <Index.Box>
                                  <Index.Box>
                                    <Index.Typography
                                      variant="label"
                                      component="label"
                                      className="input-label opacity-60"
                                    >
                                      Issue Challan Date
                                    </Index.Typography>
                                  </Index.Box>
                                </Index.Box>

                                <Index.Box className="input-design-div with-border">
                                  <Index.Typography
                                    variant="label"
                                    component="label"
                                    className="input-label"
                                  >
                                    {/* {items?.pumpingCompleted} */}
                                    {items?.issuedChallanDate
                                      ? Index.moment(
                                          items?.issuedChallanDate
                                        ).format("DD/MM/YYYY HH:mm")
                                      : "-"}
                                  </Index.Typography>
                                </Index.Box>
                              </Index.Grid>

                              <Index.Grid item xs={12} sm={6} md={4}>
                                <Index.Box>
                                  <Index.Box>
                                    <Index.Typography
                                      variant="label"
                                      component="label"
                                      className="input-label opacity-60"
                                    >
                                      Anchor Dropped At Area Bunder
                                    </Index.Typography>
                                  </Index.Box>
                                </Index.Box>

                                <Index.Box className="input-design-div with-border">
                                  <Index.Typography
                                    variant="label"
                                    component="label"
                                    className="input-label"
                                  >
                                    {/* {items?.issuedChallanDate} */}
                                    {items?.anchorDroppedAtAreaBunder
                                      ? Index.moment(
                                          items?.anchorDroppedAtAreaBunder
                                        ).format("DD/MM/YYYY HH:mm")
                                      : "-"}
                                  </Index.Typography>
                                </Index.Box>
                              </Index.Grid>
                              <Index.Grid item xs={12} sm={6} md={4}>
                                <Index.Box>
                                  <Index.Box>
                                    <Index.Typography
                                      variant="label"
                                      component="label"
                                      className="input-label opacity-60"
                                    >
                                      RCVD BDN
                                    </Index.Typography>
                                  </Index.Box>
                                </Index.Box>

                                <Index.Box className="input-design-div with-border">
                                  <Index.Typography
                                    variant="label"
                                    component="label"
                                    className="input-label"
                                  >
                                    {/* {items?.castOff} */}
                                    {items?.rcvdBdn
                                      ? Index.moment(items?.rcvdBdn).format(
                                          "DD/MM/YYYY HH:mm"
                                        )
                                      : "-"}
                                  </Index.Typography>
                                </Index.Box>
                              </Index.Grid>
                              <Index.Grid item xs={12} sm={6} md={4}>
                                <Index.Box>
                                  <Index.Box>
                                    <Index.Typography
                                      variant="label"
                                      component="label"
                                      className="input-label opacity-60"
                                    >
                                      Craft Speed (Nautical Mileage)
                                    </Index.Typography>
                                  </Index.Box>
                                </Index.Box>

                                <Index.Box className="input-design-div with-border">
                                  <Index.Typography
                                    variant="label"
                                    component="label"
                                    className="input-label"
                                  >
                                    {items?.craftSpeed
                                      ? items?.craftSpeed
                                      : "-"}
                                  </Index.Typography>
                                </Index.Box>
                              </Index.Grid>
                              <Index.Grid item xs={12} sm={6} md={4}>
                                <Index.Box>
                                  <Index.Box>
                                    <Index.Typography
                                      variant="label"
                                      component="label"
                                      className="input-label opacity-60"
                                    >
                                      Distance (In Nautical Miles)
                                    </Index.Typography>
                                  </Index.Box>
                                </Index.Box>

                                <Index.Box className="input-design-div with-border">
                                  <Index.Typography
                                    variant="label"
                                    component="label"
                                    className="input-label"
                                  >
                                    {items?.distance ? items?.distance : "-"}
                                  </Index.Typography>
                                </Index.Box>
                              </Index.Grid>
                              <Index.Grid item xs={12} sm={6} md={4}>
                                <Index.Box>
                                  <Index.Box>
                                    <Index.Typography
                                      variant="label"
                                      component="label"
                                      className="input-label opacity-60"
                                    >
                                      Diesel Consumption
                                    </Index.Typography>
                                  </Index.Box>
                                </Index.Box>

                                <Index.Box className="input-design-div with-border">
                                  <Index.Typography
                                    variant="label"
                                    component="label"
                                    className="input-label"
                                  >
                                    {/* {items?.issuedChallanDate} */}
                                    {items?.dieselConsumption
                                      ? items?.dieselConsumption
                                      : "-"}
                                  </Index.Typography>
                                </Index.Box>
                              </Index.Grid>

                              <Index.Grid item xs={12} sm={6} md={4}>
                                <Index.Box>
                                  <Index.Box>
                                    <Index.Typography
                                      variant="label"
                                      component="label"
                                      className="input-label opacity-60"
                                    >
                                      Tugs Challan Image
                                    </Index.Typography>
                                  </Index.Box>
                                </Index.Box>

                                <Index.Box className="input-design-div with-border">
                                  <Index.Typography
                                    variant="label"
                                    component="label"
                                    className="input-label"
                                  >
                                    <img
                                      src={`${
                                        process.env.REACT_APP_IMAGE_URL
                                      }/tugChallan/${
                                        items?.tugChallanImage
                                          ? items?.tugChallanImage
                                          : "-"
                                      }`}
                                      alt="Image Preview"
                                      className="challan-img-model"
                                    />
                                  </Index.Typography>
                                </Index.Box>
                              </Index.Grid>
                            </>
                          );
                        })
                      ) : (
                        <Index.Table>
                          <Index.TableRow>
                            <Index.TableCell
                              component="td"
                              variant="td"
                              scope="row"
                              className="no-data-in-list loading-purchase"
                              colSpan={6}
                              align="center"
                            >
                              No data available
                            </Index.TableCell>
                          </Index.TableRow>
                        </Index.Table>
                      )}
                    </Index.Grid>
                    {challanData?.length > 0 ? (
                      <>
                        <Index.Grid
                          container
                          spacing={2}
                          className="set-edit-timing-box"
                        >
                          {challanData?.length ? (
                            challanData &&
                            challanData?.map((items, index) => {
                              return (
                                <>
                                  {/* <Index.Box sx={{border:"2px red"}}> */}
                                  <Index.Grid item xs={12} sm={6} md={4}>
                                    <Index.Box>
                                      <Index.Box>
                                        <Index.Typography
                                          variant="label"
                                          component="label"
                                          className="input-label opacity-60"
                                        >
                                          Return Type
                                        </Index.Typography>
                                      </Index.Box>
                                    </Index.Box>

                                    <Index.Box className="input-design-div with-border">
                                      <Index.Typography
                                        variant="label"
                                        component="label"
                                        className="input-label"
                                      >
                                        {items?.returnType
                                          ? items?.returnType
                                          : "-"}
                                      </Index.Typography>
                                    </Index.Box>
                                  </Index.Grid>

                                  <Index.Grid item xs={12} sm={6} md={4}>
                                    <Index.Box>
                                      <Index.Box>
                                        <Index.Typography
                                          variant="label"
                                          component="label"
                                          className="input-label opacity-60"
                                        >
                                          Return Cast Off
                                        </Index.Typography>
                                      </Index.Box>
                                    </Index.Box>

                                    <Index.Box className="input-design-div with-border">
                                      <Index.Typography
                                        variant="label"
                                        component="label"
                                        className="input-label"
                                      >
                                        {items?.returnData?.returnCastOff &&
                                        items?.returnData?.returnCastOff !=
                                          "Invalid date"
                                          ? Index.moment(
                                              items?.returnData?.returnCastOff
                                            ).format("DD/MM/YYYY HH:mm")
                                          : "-"}
                                      </Index.Typography>
                                    </Index.Box>
                                  </Index.Grid>

                                  <Index.Grid item xs={12} sm={6} md={4}>
                                    <Index.Box>
                                      <Index.Box>
                                        <Index.Typography
                                          variant="label"
                                          component="label"
                                          className="input-label opacity-60"
                                        >
                                          Return Anchor Drop
                                        </Index.Typography>
                                      </Index.Box>
                                    </Index.Box>

                                    <Index.Box className="input-design-div with-border">
                                      <Index.Typography
                                        variant="label"
                                        component="label"
                                        className="input-label"
                                      >
                                        {items?.returnData?.returnAnchorDrop &&
                                        items?.returnData?.returnAnchorDrop !=
                                          "Invalid date"
                                          ? Index.moment(
                                              items?.returnData
                                                ?.returnAnchorDrop
                                            ).format("DD/MM/YYYY HH:mm")
                                          : "-"}
                                      </Index.Typography>
                                    </Index.Box>
                                  </Index.Grid>

                                  <Index.Grid item xs={12} sm={6} md={4}>
                                    <Index.Box>
                                      <Index.Box>
                                        <Index.Typography
                                          variant="label"
                                          component="label"
                                          className="input-label opacity-60"
                                        >
                                          Return Diesel Consumption
                                        </Index.Typography>
                                      </Index.Box>
                                    </Index.Box>

                                    <Index.Box className="input-design-div with-border">
                                      <Index.Typography
                                        variant="label"
                                        component="label"
                                        className="input-label"
                                      >
                                        {items?.returnData
                                          ?.returnDieselConsumption
                                          ? items?.returnData
                                              ?.returnDieselConsumption
                                          : "-"}
                                      </Index.Typography>
                                    </Index.Box>
                                  </Index.Grid>
                                  <Index.Grid item xs={12} sm={6} md={4}>
                                    <Index.Box>
                                      <Index.Box>
                                        <Index.Typography
                                          variant="label"
                                          component="label"
                                          className="input-label opacity-60"
                                        >
                                          Return Reason
                                        </Index.Typography>
                                      </Index.Box>
                                    </Index.Box>

                                    <Index.Box className="input-design-div with-border">
                                      <Index.Typography
                                        variant="label"
                                        component="label"
                                        className="input-label"
                                      >
                                        {items?.returnData?.returnReason
                                          ? items?.returnData?.returnReason
                                          : "-"}
                                      </Index.Typography>
                                    </Index.Box>
                                  </Index.Grid>
                                </>
                              );
                            })
                          ) : (
                            <Index.Table>
                              <Index.TableRow>
                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  scope="row"
                                  className="no-data-in-list loading-purchase"
                                  colSpan={6}
                                  align="center"
                                >
                                  No data available
                                </Index.TableCell>
                              </Index.TableRow>
                            </Index.Table>
                          )}
                        </Index.Grid>
                      </>
                    ) : (
                      ""
                    )}
                    {challanData?.length > 0 ? (
                      <>
                        <Index.Grid
                          container
                          spacing={2}
                          className="set-edit-timing-box"
                        >
                          {challanData?.length ? (
                            challanData &&
                            challanData?.map((items, index) => {
                              return (
                                <>
                                  {/* <Index.Box sx={{border:"2px red"}}> */}

                                  {/* </Index.Box> */}
                                  <Index.Grid item xs={12} sm={6} md={4}>
                                    <Index.Box>
                                      <Index.Box>
                                        <Index.Typography
                                          variant="label"
                                          component="label"
                                          className="input-label opacity-60"
                                        >
                                          Waiting
                                        </Index.Typography>
                                      </Index.Box>
                                    </Index.Box>

                                    <Index.Box className="input-design-div with-border">
                                      <Index.Typography
                                        variant="label"
                                        component="label"
                                        className="input-label"
                                      >
                                        {items?.waiting === true ? "Yes" : "No"}
                                      </Index.Typography>
                                    </Index.Box>
                                  </Index.Grid>

                                  <Index.Grid item xs={12} sm={6} md={4}>
                                    <Index.Box>
                                      <Index.Box>
                                        <Index.Typography
                                          variant="label"
                                          component="label"
                                          className="input-label opacity-60"
                                        >
                                          Drop Anchor At Anchorage
                                        </Index.Typography>
                                      </Index.Box>
                                    </Index.Box>

                                    <Index.Box className="input-design-div with-border">
                                      <Index.Typography
                                        variant="label"
                                        component="label"
                                        className="input-label"
                                      >
                                        {items?.dropAnchorAtAnchorage &&
                                        items?.dropAnchorAtAnchorage !=
                                          "Invalid date"
                                          ? Index.moment(
                                              items?.dropAnchorAtAnchorage
                                            ).format("DD/MM/YYYY HH:mm")
                                          : "-"}
                                      </Index.Typography>
                                    </Index.Box>
                                  </Index.Grid>

                                  <Index.Grid item xs={12} sm={6} md={4}>
                                    <Index.Box>
                                      <Index.Box>
                                        <Index.Typography
                                          variant="label"
                                          component="label"
                                          className="input-label opacity-60"
                                        >
                                          Anchor Awigh From Anchorage
                                        </Index.Typography>
                                      </Index.Box>
                                    </Index.Box>

                                    <Index.Box className="input-design-div with-border">
                                      <Index.Typography
                                        variant="label"
                                        component="label"
                                        className="input-label"
                                      >
                                        {items?.anchorAwighFromAnchorage &&
                                        items?.anchorAwighFromAnchorage !=
                                          "Invalid date"
                                          ? Index.moment(
                                              items?.anchorAwighFromAnchorage
                                            ).format("DD/MM/YYYY HH:mm")
                                          : "-"}
                                      </Index.Typography>
                                    </Index.Box>
                                  </Index.Grid>
                                </>
                              );
                            })
                          ) : (
                            <Index.Table>
                              <Index.TableRow>
                                <Index.TableCell
                                  component="td"
                                  variant="td"
                                  scope="row"
                                  className="no-data-in-list loading-purchase"
                                  colSpan={6}
                                  align="center"
                                >
                                  No data available
                                </Index.TableCell>
                              </Index.TableRow>
                            </Index.Table>
                          )}
                        </Index.Grid>
                      </>
                    ) : (
                      ""
                    )}
                  </Index.Box>
                </SingleOrderTab>

                <SingleOrderTab
                  value={value}
                  index={3}
                  className="barge-common-box tab-content-box"
                >
                  <Index.TableContainer>
                    <Index.Table className="challan-table challan-table-set challan-table-font edit-req-table">
                      <Index.TableHead>
                        <Index.TableRow>
                          <Index.TableCell>Created Date</Index.TableCell>
                          <Index.TableCell>Updated Date</Index.TableCell>
                          <Index.TableCell>Status</Index.TableCell>
                          <Index.TableCell>Approved / Rejected</Index.TableCell>
                        </Index.TableRow>
                      </Index.TableHead>
                      <Index.TableBody>
                        {challanRequestData?.length ? (
                          challanRequestData &&
                          challanRequestData?.map((items, index) => {
                            return (
                              <Index.TableRow>
                                <Index.TableCell>
                                  <Index.Typography>
                                    {Index.moment(items?.createdAt).format(
                                      "DD/MM/YYYY h:mm:ss A"
                                    )}
                                  </Index.Typography>
                                </Index.TableCell>

                                <Index.TableCell>
                                  <Index.Typography>
                                    {Index.moment(items?.updatedAt).format(
                                      "DD/MM/YYYY h:mm:ss A"
                                    )}
                                  </Index.Typography>
                                </Index.TableCell>

                                <Index.TableCell>
                                  {items?.requestStatus
                                    ? items?.requestStatus
                                    : "-"}
                                </Index.TableCell>
                                <Index.TableCell>
                                  <Index.Button
                                    variant="contained"
                                    onClick={() =>
                                      challanEditRequestStatusApproved(items)
                                    }
                                    className="challan-status"
                                    disabled={items?.checkRequest === true}
                                  >
                                    Approved
                                  </Index.Button>
                                  <Index.Button
                                    variant="contained"
                                    align="left"
                                    className="challan-status reject-btn-align"
                                    onClick={() =>
                                      challanEditRequestStatusReject(items)
                                    }
                                    disabled={items?.checkRequest === true}
                                  >
                                    Rejected
                                  </Index.Button>
                                </Index.TableCell>
                              </Index.TableRow>
                            );
                          })
                        ) : (
                          <Index.TableRow>
                            <Index.TableCell
                              component="td"
                              variant="td"
                              scope="row"
                              className="no-data-in-list"
                              colSpan={6}
                              align="center"
                            >
                              No data available
                            </Index.TableCell>
                          </Index.TableRow>
                        )}
                      </Index.TableBody>
                    </Index.Table>
                  </Index.TableContainer>
                </SingleOrderTab>

                <SingleOrderTab
                  value={value}
                  index={4}
                  className="barge-common-box tab-content-box"
                >
                  <Index.TableContainer>
                    {/* <Index.Typography component="h1" className="activity-log-header">
                  <b>Activity Log</b>
                </Index.Typography> */}

                    <Index.Table className="invoice-activity-log invoice-activity-log-set order-activity-log">
                      <Index.TableHead>
                        <Index.TableRow>
                          <Index.TableCell>Activity Type</Index.TableCell>
                          <Index.TableCell>Details</Index.TableCell>
                          <Index.TableCell>Date & Time</Index.TableCell>
                        </Index.TableRow>
                      </Index.TableHead>
                      <Index.TableBody>
                        {filterOrderLogData &&
                          filterOrderLogData?.map((items, index) => {
                            return (
                              <Index.TableRow>
                                <Index.TableCell>
                                  <Index.Typography>
                                    {items?.activityType}
                                  </Index.Typography>
                                </Index.TableCell>
                                <Index.TableCell>
                                  <Index.Typography>
                                    {items?.details}
                                  </Index.Typography>
                                </Index.TableCell>
                                <Index.TableCell>
                                  <Index.Typography>
                                    {Index.moment(items?.createdAt).format(
                                      "DD/MM/YYYY h:mm:ss A"
                                    )}
                                  </Index.Typography>
                                </Index.TableCell>
                              </Index.TableRow>
                            );
                          })}
                      </Index.TableBody>
                    </Index.Table>
                  </Index.TableContainer>
                </SingleOrderTab>
              </Index.Box>
              {/* Design Tab */}
            </Index.Box>
          </Index.Box>
        </>
      )}
      <OrderStatusModel
        updateOpen={cancelOpen}
        handleClose={cancelOrderModelClose}
        inquiryStatusUpdate={cancelOrderBtn}
        modalType={status ? "Comment" : ""}
      />
      <TankerShiftModel
        shiftTankerHandleOpen={shiftTankerHandleOpen}
        shiftTankerHandleClose={shiftTankerHandleClose}
        tankerOpen={tankerOpen}
        setTankerOpen={setTankerOpen}
        orderId={id}
        status={viewData?.orderStatus}
        shiftTankerStatus={viewData?.tankerShifted}
        getSingleOrderList={getSingleOrderList}
        setIsShifted={setIsShifted}
        tankerCheckId={tankerCheckId}
        // setUpdateTanker={setUpdateTanker}
      />
    </>
  );
}

export default SingleTugOrder;
