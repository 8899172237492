import React, { useEffect, useState } from "react";
import Index from "../../../../Index";
import { useDispatch } from "react-redux";
import { getWaterSupplyActivityLogList } from "../../../../../redux/admin/action";

const WaterSupplyActivityLog = ({ id }) => {
  const dispatch = useDispatch();

  const [activityLogList, setActivityLogList] = useState([]);
  const [loading, setLoading] = useState(false);
  const activityLogs = () => {
    setLoading(true);
    const urlencoded = new URLSearchParams();
    urlencoded.append("activityTypeId", id);
    urlencoded.append("type", "supplyOrder");

    dispatch(getWaterSupplyActivityLogList(urlencoded)).then((res) => {
      if (res?.status === 200) {
        setActivityLogList(res?.data?.reverse());
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      } else {
        setActivityLogList([]);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      }
    });
  };

  useEffect(() => {
    activityLogs();
  }, []);
  return (
    <>
      <Index.TableContainer>
        <Index.Table className="invoice-activity-log invoice-activity-log-set order-activity-log">
          <Index.TableHead>
            <Index.TableRow>
              <Index.TableCell>Activity Type</Index.TableCell>
              <Index.TableCell>Details</Index.TableCell>
              <Index.TableCell>Date & Time</Index.TableCell>
            </Index.TableRow>
          </Index.TableHead>
          <Index.TableBody>
            {loading ? (
              <Index.TableRow>
                <Index.TableCell
                  colSpan={13}
                  align="center"
                  className="loading-purchase"
                >
                  <Index.CircularProgress color="secondary" size={20} />
                </Index.TableCell>
              </Index.TableRow>
            ) : activityLogList?.length ? (
              activityLogList &&
              activityLogList?.map((items, index) => {
                return (
                  <Index.TableRow>
                    <Index.TableCell>
                      <Index.Typography>{items?.activityType}</Index.Typography>
                    </Index.TableCell>
                    <Index.TableCell>
                      <Index.Typography>{items?.details}</Index.Typography>
                    </Index.TableCell>
                    <Index.TableCell>
                      <Index.Typography>
                        {Index.moment(items?.createdAt).format(
                          "DD/MM/YYYY h:mm:ss A"
                        )}
                      </Index.Typography>
                    </Index.TableCell>
                  </Index.TableRow>
                );
              })
            ) : (
              <Index.TableRow>
                <Index.TableCell
                  component="td"
                  variant="td"
                  scope="row"
                  className="no-data-in-list"
                  colSpan={13}
                  align="center"
                >
                  No data available
                </Index.TableCell>
              </Index.TableRow>
            )}
          </Index.TableBody>
        </Index.Table>
      </Index.TableContainer>
    </>
  );
};

export default WaterSupplyActivityLog;
