import React, { useState } from "react";
import Index from "../../../../Index";
import { deleteTankerDocumentById } from "../../../../../redux/admin/action";

// style for model
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const TankerDocument = ({
  docTitle,
  tankerDocData,
  tankerId,
  singleTankerDocuments,
}) => {
  const dispatch = Index.useDispatch();
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [documentId, setDocumentId] = useState("");

  // Delete tanker document
  const handleDeleteOpen = () => {
    setDeleteOpen(true);
  };

  const handleDeleteClose = () => {
    setDocumentId("");
    setDeleteOpen(false);
  };

  const handleDeleteRecord = () => {
    dispatch(deleteTankerDocumentById({ tankerDocumentId: documentId })).then(
      (res) => {
        if (res?.status === 200) {
          singleTankerDocuments(tankerId); // call get all tanker document api
        }
      }
    );
    handleDeleteClose();
  };

  const [openImage, setOpenImage] = useState(false);
  const [documentImage, setDocumentImg] = useState("");
  const handleOpenImage = (img) => {
    setOpenImage(true);
    setDocumentImg(img);
  };
  const handleCloseImage = () => setOpenImage(false);

  return (
    <>
      <Index.Box className="tanker-title-document">
        <Index.Box className="title-header">
          <Index.Box className="title-header-flex res-title-header-flex">
            <Index.Box className="title-main">
              <Index.Typography
                variant="p"
                component="p"
                className="page-title"
              >
                {docTitle} Document
              </Index.Typography>
            </Index.Box>
          </Index.Box>
        </Index.Box>

        <Index.Grid container spacing={1}>
          <Index.Box className="order-active-log-header expensedetails-table order-active-table-set">
            <Index.TableContainer
              component={Index.Paper}
              className="table-container"
            >
              <Index.Table
                aria-label="simple table"
                className="table-design-main supply-table one-line-table invoice-table-set user-management-table tanker-list my-tanker-table"
              >
                <Index.TableHead>
                  <Index.TableRow>
                    <Index.TableCell align="left">S. No</Index.TableCell>
                    <Index.TableCell align="left">Image/PDF</Index.TableCell>
                    <Index.TableCell align="left">Expiry Date</Index.TableCell>
                    <Index.TableCell align="left">Created Date</Index.TableCell>
                    <Index.TableCell align="left">
                    Do Not Have Expiry Date
                    </Index.TableCell>
                    <Index.TableCell align="left">Action</Index.TableCell>
                  </Index.TableRow>
                </Index.TableHead>
                {/* {loading ? (
                  <Index.TableBody>
                    <Index.TableRow>
                      <Index.TableCell colSpan={14} align="center">
                        <Index.CircularProgress color="secondary" size={20} />
                      </Index.TableCell>
                    </Index.TableRow>
                  </Index.TableBody>
                ) : ( */}
                <Index.TableBody>
                  {tankerDocData?.length ? (
                    tankerDocData?.map((item, index) => {
                      const fileExtension = item?.tankerDocumentImage
                        ?.split(".")
                        .pop()
                        .toLowerCase();
                      // console.log(fileExtension, "fileExtension");
                      return (
                        <Index.TableRow key={index}>
                          <Index.TableCell>
                            <Index.Typography>{index + 1}</Index.Typography>
                          </Index.TableCell>
                          <Index.TableCell>
                            {fileExtension === "jpg" ||
                            fileExtension === "jpeg" ||
                            fileExtension === "png" ? (
                              <Index.Button
                                onClick={() => {
                                  handleOpenImage(item?.tankerDocumentImage);
                                }}
                              >
                                <Index.Box className="user-main-img user-img-open-align">
                                  <img
                                    src={`${
                                      process.env.REACT_APP_IMAGE_URL
                                    }/tanker/${
                                      item?.tankerDocumentImage
                                        ? item?.tankerDocumentImage
                                        : ""
                                    }`}
                                    alt=""
                                    className="challan-img-model img-view-list"
                                  />
                                </Index.Box>
                              </Index.Button>
                            ) : (
                              <Index.Button
                                onClick={() => {
                                  window.open(
                                    `${process.env.REACT_APP_IMAGE_URL}/tanker/${item?.tankerDocumentImage}`
                                  );
                                }}
                              >
                                PDF
                              </Index.Button>
                            )}
                          </Index.TableCell>
                          <Index.TableCell>
                            <Index.Typography>
                              {item?.expireDate
                                ? Index.moment(item?.expireDate).format(
                                    "DD/MM/YYYY"
                                  )
                                : "-"}
                            </Index.Typography>
                          </Index.TableCell>

                          <Index.TableCell>
                            <Index.Typography>
                              {Index.moment(item?.createdAt).format(
                                "DD/MM/YYYY h:mm:ss A"
                              )}
                            </Index.Typography>
                          </Index.TableCell>

                          <Index.TableCell>
                            <Index.Typography>
                              {item?.expireCheckBox ? "True" : "False"}
                            </Index.Typography>
                          </Index.TableCell>

                          <Index.TableCell>
                            <Index.IconButton>
                              <Index.DeleteIcon
                                color="error"
                                onClick={() => {
                                  setDocumentId(item?._id);
                                  handleDeleteOpen();
                                }}
                              />
                            </Index.IconButton>
                          </Index.TableCell>
                        </Index.TableRow>
                      );
                    })
                  ) : (
                    <Index.TableRow>
                      <Index.TableCell
                        component="td"
                        variant="td"
                        scope="row"
                        className="no-data-in-list"
                        colSpan={6}
                        align="center"
                      >
                        No data available
                      </Index.TableCell>
                    </Index.TableRow>
                  )}
                </Index.TableBody>
                {/* )} */}
              </Index.Table>
            </Index.TableContainer>
          </Index.Box>
        </Index.Grid>

        <Index.Modal
          open={openImage}
          onClose={handleCloseImage}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Index.Box sx={style}>
            <Index.ClearIcon
              className="model-close-icon"
              onClick={handleCloseImage}
            />
            <Index.Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
            >
              <Index.Box>
                <img
                  src={`${process.env.REACT_APP_IMAGE_URL}/tanker/${documentImage}`}
                  alt=""
                  className="challan-img"
                />
              </Index.Box>
            </Index.Typography>
          </Index.Box>
        </Index.Modal>
      </Index.Box>

      <Index.DeleteModal
        deleteOpen={deleteOpen}
        handleDeleteRecord={handleDeleteRecord}
        handleDeleteClose={handleDeleteClose}
      />
    </>
  );
};

export default TankerDocument;
