import React from "react";
import Index from "../../../Index";
import { Formik } from "formik";
import { AddPartyName, getPartyName } from "../../../../redux/admin/action";
import { partyValidationSchema } from "../../../../validation/AdminValidation";

const AddPartyModel = ({
  handleClose,
  setOpen,
  open,
  partyModelData,
  setValue,
  handleReloadPartyListing
}) => {
  const dispatch = Index.useDispatch();

  // initialValues
  let initialValues = {
    partyName: partyModelData?._id ? partyModelData?.partyName : "",
    email: partyModelData?._id ? partyModelData?.email : "",
    gstNo: partyModelData?._id ? partyModelData?.gstNo : "",
    b2bB2c: partyModelData?._id ? partyModelData?.b2bB2c : "",
  };

  // party model submit handler
  const handleFormSubmit = (values) => {
    let data = new URLSearchParams();
    data.append("partyName", values?.partyName);
    data.append("email", values?.email);
    data.append("gstNo", values?.gstNo);
    data.append("b2bB2c", values?.b2bB2c);
    if (partyModelData?._id) {
      data.append("id", partyModelData?._id);
    }
    dispatch(AddPartyName(data)).then(res => {
      if (res.status === 200 || res.status === 201) {
        handleReloadPartyListing()
      }
    });
    handleClose();
  };

  return (
    <div>
      <Index.Dialog
        open={open}
        onclose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Index.DialogContent>
          <Index.Box className="title-main mb-10">
            <Formik
              enableReinitialize
              initialValues={initialValues}
              validationSchema={partyValidationSchema}
              onSubmit={handleFormSubmit}
            >
              {({
                values,
                errors,
                touched,
                setFieldValue,
                handleChange,
                handleBlur,
                handleSubmit,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Index.Box className="title-main">
                    <Index.Box className="jus-between">
                      <Index.Typography
                        variant="h6"
                        component="h6"
                        className="input-label"
                        sx={{ marginBottom: "15px" }}
                      >
                        {partyModelData?._id ? "Edit" : "Add"}  Party
                      </Index.Typography>
                      <Index.ClearIcon
                        className="model-close-icon"
                        onClick={handleClose}
                      />
                    </Index.Box>
                    <Index.Grid container spacing={2}>
                      <Index.Grid item xs={12} sm={6} md={6}>
                        <Index.Typography
                          variant="label"
                          component="label"
                          className="input-label"
                        >
                          Party Name
                        </Index.Typography>
                        <Index.Box className="input-design-div with-border">
                          <Index.TextField
                            hiddenLabel
                            id="filled-hidden-label-normal"
                            placeholder="Party name"
                            variant="filled"
                            className="admin-input-design input-placeholder"
                            name="partyName"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values?.partyName}
                            error={Boolean(
                              errors?.partyName ? touched?.partyName : undefined
                            )}
                            helperText={
                              touched?.partyName ? errors?.partyName : undefined
                            }
                          />
                        </Index.Box>
                      </Index.Grid>
                      <Index.Grid item xs={12} sm={6} md={6}>
                        <Index.Typography
                          variant="label"
                          component="label"
                          className="input-label"
                        >
                          Email
                        </Index.Typography>
                        <Index.Box className="input-design-div with-border">
                          <Index.TextField
                            hiddenLabel
                            id="filled-hidden-label-normal"
                            placeholder="Email"
                            variant="filled"
                            className="admin-input-design input-placeholder"
                            name="email"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values?.email}
                          // error={Boolean(
                          //   errors?.email ? touched?.email : undefined
                          // )}
                          // helperText={
                          //   touched?.email ? errors?.email : undefined
                          // }
                          />
                        </Index.Box>
                      </Index.Grid>
                      <Index.Grid item xs={12} sm={6} md={6}>
                        <Index.Typography
                          variant="label"
                          component="label"
                          className="input-label"
                        >
                          GST Number
                        </Index.Typography>
                        <Index.Box className="input-design-div with-border">
                          <Index.TextField
                            hiddenLabel
                            id="filled-hidden-label-normal"
                            placeholder="GST number"
                            variant="filled"
                            className="admin-input-design input-placeholder"
                            name="gstNo"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values?.gstNo}
                            error={Boolean(
                              errors?.gstNo ? touched?.gstNo : undefined
                            )}
                            helperText={
                              touched?.gstNo ? errors?.gstNo : undefined
                            }
                          />
                        </Index.Box>
                      </Index.Grid>
                      <Index.Grid item xs={12} sm={6} md={6}>
                        <Index.Typography
                          variant="label"
                          component="label"
                          className="input-label"
                        >
                          B2B / B2C
                        </Index.Typography>
                        <Index.Box className="input-design-div with-border">
                          <Index.Select
                            className="placeholder-set-text-box"
                            fullWidth
                            name="b2bB2c"
                            displayEmpty
                            value={values?.b2bB2c}
                            onChange={(e) => {
                              setFieldValue("b2bB2c", e.target.value);
                            }}
                          >
                            <Index.MenuItem value={""}>
                              <Index.MenuItem
                                disabled
                                className="all-select-label"
                              >
                                Select B2B/B2C
                              </Index.MenuItem>
                            </Index.MenuItem>
                            <Index.MenuItem value="B2B">B2B</Index.MenuItem>
                            <Index.MenuItem value="B2C">B2C</Index.MenuItem>
                          </Index.Select>
                          {touched?.b2bB2c && errors?.b2bB2c && (
                            <Index.FormHelperText error>
                              {errors?.b2bB2c}
                            </Index.FormHelperText>
                          )}
                        </Index.Box>
                      </Index.Grid>
                      <Index.Grid item xs={12} sm={12} md={12}>
                        <Index.Box className="common-button blue-button flex-end save-btn gap-10">
                          <Index.Button
                            variant="contained"
                            type="submit"
                            className="Particular_model_submit party_submit_btn"
                          >
                            {partyModelData?._id ? "Update" : "Submit"}
                          </Index.Button>
                          <Index.Button
                            variant="contained"
                            type="button"
                            className="Particular_model_submit"
                            onClick={() => handleClose(setOpen(false))}
                          >
                            Cancel
                          </Index.Button>
                        </Index.Box>
                      </Index.Grid>
                    </Index.Grid>

                    {/* <Index.Box className="line" mt={3} mb={3}></Index.Box> */}
                  </Index.Box>
                </form>
              )}
            </Formik>
          </Index.Box>
        </Index.DialogContent>
      </Index.Dialog>
    </div>
  );
};

export default AddPartyModel;
