import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import {
  getTankerMasterList,
  deleteTankerMaster,
} from "../../../../redux/admin/action";
// import copyTextToClipboard from "copy-text-to-clipboard";
// import { toast } from "react-toastify";

const Search = Index.styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: Index.alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: Index.alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));
const ActionItem = Index.styled(Index.MenuItem)(() => ({
  fontFamily: "poppins",
  lineHeight: "15px",
  fontSize: "14px",
  fontWeight: "400",
  color: "#595F69",
}));
const StyledInputBase = Index.styled(Index.InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

function TankerList() {
  const dispatch = Index.useDispatch();
  const navigate = Index.useNavigate();
  const [loading, setLoading] = useState(false);
  //   const { traderMasterList } = Index.useSelector((state) => state.AdminReducer);
  const [tankerMasterList, setTankerMasterList] = useState([]);
  // Delete record state
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [rowId, setRowId] = useState("");
  // State for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState(0);
  const [paginationPerPage, setPaginationPerPage] = useState(50);
  // State for searching and set data
  const [searchedData, setSearchedData] = useState([]);
  const [currentRows, setCurrentRows] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [actionIndex, setActionIndex] = useState();

  const handleClickMenu = (e, index) => {
    setActionIndex(index);
    setAnchorEl(e.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const getTankerList = () => {
    setLoading(true);
    dispatch(getTankerMasterList()).then((res) => {
      if (res?.status === 200) {
        setTankerMasterList(res?.data);
        setTimeout(() => {
          setLoading(false);
        }, 2000);
      } else {
        setTimeout(() => {
          setLoading(false);
        }, 2000);
      }
    });
  };

  useEffect(() => {
    getTankerList();
  }, []);

  // Copy original data to searched data
  useEffect(() => {
    setSearchedData(
      tankerMasterList?.sort((a, b) =>
        b?.updatedAt?.localeCompare(a?.updatedAt)
      )
    );
  }, [tankerMasterList]);

  // Search on table
  const requestSearch = (searched) => {
    if (!searched) return setSearchedData(tankerMasterList);
    setSearchedData(
      tankerMasterList?.filter(
        (item) =>
          item?.tankerName
            ?.toString()
            .toLowerCase()
            .includes(searched.toLowerCase()) ||
          item?.tankerNumber
            ?.toString()
            .toLowerCase()
            .includes(searched?.toLowerCase()) ||
          item?.capacity
            ?.toString()
            .toLowerCase()
            .includes(searched?.toLowerCase())
      )
    );
    setCurrentPage(1);
  };

  // Pagination calculation start
  const PER_PAGE = paginationPerPage;
  useEffect(() => {
    //Page count
    const count = Math.ceil(searchedData?.length / PER_PAGE);
    setCount(count);
    const indexOfLastRow = currentPage * PER_PAGE;
    const indexOfFirstRow = indexOfLastRow - PER_PAGE;
    const modifiedRows = searchedData?.slice(indexOfFirstRow, indexOfLastRow);
    setCurrentRows(modifiedRows);
    if (modifiedRows?.length === 0 && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  }, [currentPage, searchedData, paginationPerPage, PER_PAGE]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };
  // Pagination calculation end

  const handleDeleteOpen = () => {
    setAnchorEl(null);
    setDeleteOpen(true);
  };

  const handleDeleteClose = () => {
    setDeleteOpen(false);
  };

  const handleDeleteRecord = () => {
    const data = {
      id: rowId,
    };
    dispatch(deleteTankerMaster(data)).then((res) => {
      if (res?.status === 200) {
        getTankerList();
      }
    });
    handleDeleteClose();
  };

  const handleEdit = (item) => {
    navigate("/admin/tankers/add", {
      state: { row: item },
    });
  };

  const handleTankerView = (id) => {
    // console.log(id, ' ids');
    navigate(`/admin/tankers/view/${id}`);
  };

  // tanker trip list function to navigate

  const tankerTripsList = (item) => {
    navigate(`/admin/tankers/trips/${item?._id}`, {
      state: item,
    });
  };

  // when the user open the map then used...
  const handleOpenMap = (e, currentData) => {
    console.log("currentData", currentData);
    // https://www.google.com/maps/place/23.026611,72.524537

    window.open(
      `https://www.google.com/maps/place/${currentData?.latitude},${currentData?.longitude}`,
      "_blank",
      "noreferrer"
    );
  };

  return (
    <>
      <Index.DynamicTitle title="Tanker Master" />
      <Index.Box className="">
        <Index.Box className="barge-common-box">
          <Index.Box className="title-header">
            <Index.Box className="title-header-flex res-title-header-flex">
              <Index.Box className="title-main">
                <Index.Typography
                  variant="p"
                  component="p"
                  className="page-title"
                >
                  Tanker List
                </Index.Typography>
              </Index.Box>
              <Index.Box className="d-flex align-items-center res-set-search">
                <Search className="search ">
                  <StyledInputBase
                    placeholder="Search"
                    inputProps={{ "aria-label": "search" }}
                    onChange={(e) =>
                      setTimeout(() => {
                        requestSearch(e.target.value.trim());
                      }, 1000)
                    }
                  />
                </Search>

                <Index.Box className="common-button blue-button res-blue-button">
                  <Index.RouteLink
                    to="/admin/tankers/add"
                    className="no-text-decoration"
                  >
                    {" "}
                    <Index.Button variant="contained" disableRipple>
                      Add Tanker
                    </Index.Button>
                  </Index.RouteLink>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Index.Box className="">
            <Index.TableContainer
              component={Index.Paper}
              className="table-container"
            >
              <Index.Table
                aria-label="simple table"
                className="table-design-main supply-table one-line-table invoice-table-set user-management-table tanker-list my-tanker-table"
              >
                <Index.TableHead>
                  <Index.TableRow>
                    <Index.TableCell align="left">Sr. No</Index.TableCell>
                    {/* <Index.TableCell align="left">Tanker Name</Index.TableCell> */}
                    <Index.TableCell align="left">
                      Tanker Number
                    </Index.TableCell>
                    <Index.TableCell align="left">
                      Tanker Capacity
                    </Index.TableCell>
                    <Index.TableCell align="left">Tanker Trips</Index.TableCell>
                    <Index.TableCell align="left">Action</Index.TableCell>
                  </Index.TableRow>
                </Index.TableHead>
                {loading ? (
                  <Index.TableBody>
                    <Index.TableRow>
                      <Index.TableCell colSpan={14} align="center">
                        <Index.CircularProgress color="secondary" size={20} />
                      </Index.TableCell>
                    </Index.TableRow>
                  </Index.TableBody>
                ) : (
                  <Index.TableBody className="tanker-table-body">
                    {currentRows?.length ? (
                      currentRows
                        ?.sort((a, b) => {
                          let x = a?.tankerName?.toLowerCase();
                          let y = b?.tankerName?.toLowerCase();
                          if (x > y) {
                            return 1;
                          } else if (y > x) {
                            return -1;
                          }
                          return 0;
                        })
                        ?.map((item, index) => (
                          <Index.TableRow
                            key={item?._id}
                            className={
                              item?.isBreakdown === true
                                ? "tanker-row-color"
                                : ""
                            }
                          >
                            <Index.TableCell>{index + 1}</Index.TableCell>
                            {/* <Index.TableCell>
                              {item?.tankerName ? item?.tankerName : "-"}
                            </Index.TableCell> */}

                            <Index.TableCell align="left">
                              {item?.tankerNumber ? item?.tankerNumber : "-"}
                            </Index.TableCell>

                            <Index.TableCell align="left">
                              {item?.capacity ? item?.capacity : "-"}
                            </Index.TableCell>
                            <Index.TableCell
                              align="left"
                              sx={{
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                tankerTripsList(item);
                              }}
                            >
                              {item?.tripsCount ? item?.tripsCount : "-"}
                            </Index.TableCell>
                            <Index.TableCell sx={{ display: "flex" }}>
                              <Index.IconButton
                                onClick={(e) => {
                                  handleClickMenu(e, index);
                                }}
                              >
                                <Index.MenuIcon className="action-menu-icon" />
                              </Index.IconButton>
                              <Index.Menu
                                id="demo-positioned-menu"
                                aria-labelledby="demo-positioned-button"
                                anchorEl={anchorEl}
                                keepMounted
                                elevation={2}
                                open={Boolean(anchorEl)}
                                onClose={handleCloseMenu}
                                anchorOrigin={{
                                  vertical: "top",
                                  horizontal: "left",
                                }}
                                transformOrigin={{
                                  vertical: "top",
                                  horizontal: "left",
                                }}
                              >
                                {console.log(
                                  "@@@@@@@",
                                  currentRows?.[actionIndex],
                                  "Condition",
                                  currentRows?.[actionIndex]?.hasOwnProperty(
                                    "latitude"
                                  ) &&
                                    currentRows?.[actionIndex]?.hasOwnProperty(
                                      "longitude"
                                    ) &&
                                    currentRows?.[actionIndex]?.latitude !==
                                      null &&
                                    currentRows?.[actionIndex]?.latitude !== ""
                                )}
                                <ActionItem
                                  className="action-text"
                                  onClick={() => {
                                    handleTankerView(
                                      currentRows?.[actionIndex]?._id
                                    );
                                  }}
                                >
                                  View
                                </ActionItem>
                                <ActionItem
                                  className="action-text"
                                  onClick={() => {
                                    handleEdit(currentRows?.[actionIndex]);
                                  }}
                                >
                                  Edit
                                </ActionItem>
                                <ActionItem
                                  className="action-text"
                                  onClick={() => {
                                    setRowId(currentRows?.[actionIndex]?._id);
                                    handleDeleteOpen();
                                  }}
                                >
                                  Delete
                                </ActionItem>
                                {
                                  // latitude
                                  // longitude
                                  currentRows?.[actionIndex]?.hasOwnProperty(
                                    "latitude"
                                  ) &&
                                  currentRows?.[actionIndex]?.hasOwnProperty(
                                    "longitude"
                                  ) &&
                                  currentRows?.[actionIndex]?.latitude !==
                                    null &&
                                  currentRows?.[actionIndex]?.latitude !== "" &&
                                  currentRows?.[actionIndex]?.longitude !==
                                    null &&
                                  currentRows?.[actionIndex]?.longitude !==
                                    "" ? (
                                    <>
                                      <ActionItem
                                        className="action-text"
                                        onClick={(e) =>
                                          handleOpenMap(
                                            e,
                                            currentRows?.[actionIndex]
                                          )
                                        }
                                        // onClick={() => {
                                        //   setRowId(currentRows?.[actionIndex]?._id);
                                        //   handleDeleteOpen();
                                        // }}
                                      >
                                        Map
                                      </ActionItem>
                                    </>
                                  ) : (
                                    <></>
                                  )
                                }
                              </Index.Menu>
                            </Index.TableCell>
                          </Index.TableRow>
                        ))
                    ) : (
                      <Index.TableRow>
                        <Index.TableCell
                          component="td"
                          variant="td"
                          scope="row"
                          className="no-data-in-list"
                          colSpan={15}
                          align="center"
                        >
                          No data available
                        </Index.TableCell>
                      </Index.TableRow>
                    )}
                  </Index.TableBody>
                )}
              </Index.Table>
            </Index.TableContainer>
          </Index.Box>
          <Index.Box className="pagination-design flex-end">
            <Index.Stack spacing={2}>
              <Index.Box className="pagination-count">
                <Index.Box className="input-design-div with-border">
                  <Index.Select
                    fullWidth
                    value={paginationPerPage}
                    onChange={(e) => {
                      setPaginationPerPage(e.target.value);
                    }}
                  >
                    <Index.MenuItem value={10}>10</Index.MenuItem>
                    <Index.MenuItem value={25}>25</Index.MenuItem>
                    <Index.MenuItem value={50}>50</Index.MenuItem>
                    <Index.MenuItem value={100}>100</Index.MenuItem>
                  </Index.Select>
                </Index.Box>
                <Index.Pagination
                  count={count}
                  page={currentPage}
                  onChange={handlePageChange}
                />
              </Index.Box>
            </Index.Stack>
          </Index.Box>
        </Index.Box>
      </Index.Box>

      <Index.DeleteModal
        deleteOpen={deleteOpen}
        handleDeleteRecord={handleDeleteRecord}
        handleDeleteClose={handleDeleteClose}
      />
    </>
  );
}

export default TankerList;
