import { DateTimePicker } from "@mui/x-date-pickers";
import { Formik } from "formik";
import dayjs from "dayjs";
import { terminalData } from "../../../../../config/CommonTerminalItems";
import { useEffect, useState } from "react";
import Index from "../../../../Index";
import { getWaterSupplyPortList } from "../../../../../redux/admin/action";

function WaterSupplyUpdatePortModel({
  openUpdateDatePopUp,
  handleCloseDateEditModal,
  handleUpdateDateViaApi,
  editTimePortData,
  isSingleFieldUpdate,
}) {
  const dispatch = Index.useDispatch();
  const initialValues = {
    port: editTimePortData,
  };

  const [allPortList, setAllPortList] = useState([]);

  const fetchWaterSupplyPortList = () => {
    dispatch(getWaterSupplyPortList()).then((res) => {
      if (res?.status !== 200) setAllPortList([]);
      const sortedData = res?.data?.sort((a, b) =>
        a?.supplyPortName?.localeCompare(b?.supplyPortName)
      );
      if (!sortedData?.length) setAllPortList([]);
      setAllPortList(sortedData);
    });
  };
  useEffect(() => {
    fetchWaterSupplyPortList();
  }, [terminalData]);
  return (
    <>
      <Index.Dialog
        open={openUpdateDatePopUp}
        onClose={handleCloseDateEditModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Index.DialogContent className="expense-model-main  modal-update-main">
          <Index.Box className="title-main">
            <Formik
              enableReinitialize
              initialValues={initialValues}
              // validationSchema={vesselValidationSchema}
              onSubmit={(values) => handleUpdateDateViaApi(values)}
            >
              {({
                values,
                errors,
                touched,
                setFieldValue,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldTouched,
                handleFocus,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Index.Box className="title-main">
                    <Index.Box className="jus-between">
                      <Index.Typography
                        variant="h6"
                        component="h6"
                        className="input-label"
                      >
                        Edit Port
                      </Index.Typography>
                      <Index.ClearIcon
                        className="model-close-icon"
                        onClick={handleCloseDateEditModal}
                      />
                    </Index.Box>
                    <Index.Box className="update-model-main-box-set">
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Port
                      </Index.Typography>
                      <Index.Box className="input-design-div with-border">
                        <Index.Autocomplete
                          disablePortal
                          className="invoice-select-drop"
                          id="combo-box-demo"
                          options={allPortList}
                          value={
                            allPortList[
                              allPortList.findIndex(
                                (e) => e._id === values?.port
                              )
                            ]
                          }
                          getOptionLabel={(option) => option?.supplyPortName}
                          renderOption={(props, option) => (
                            <Index.Box
                              component="li"
                              sx={{
                                "& > img": { mr: 2, flexShrink: 0 },
                              }}
                              {...props}
                            >
                              {option?.supplyPortName}
                            </Index.Box>
                          )}
                          onChange={(event, newValue) => {
                            setFieldValue("port", newValue?._id);
                          }}
                          renderInput={(params) => (
                            <Index.TextField
                              {...params}
                              placeholder="Port"
                              variant="outlined"
                            />
                          )}
                        />
                      </Index.Box>

                      <Index.Box className="common-button blue-button flex-end save-btn  modal-update-btn">
                        <Index.Button
                          variant="contained"
                          type="submit"
                          className="Particular_model_submit"
                          disabled={isSingleFieldUpdate ? true : false}
                        >
                          Update
                        </Index.Button>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </form>
              )}
            </Formik>
          </Index.Box>
        </Index.DialogContent>
      </Index.Dialog>
    </>
  );
}
export default WaterSupplyUpdatePortModel;
