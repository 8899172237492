import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import {
  getTankerActivityLog,
  getSingleTankerMaster,
  getSingleTankerDocuments,
  tankerDocumentUpload,
} from "../../../../redux/admin/action";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TankerDocument from "./documents/TankerDocument";
import { tankerDocumentType } from "../../../../config/TankerDocumentType";
import * as Yup from "yup";
import { Formik } from "formik";

// Design Tab
function SingleOrderTab(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Index.Box sx={{ p: 3 }}>
          <Index.Typography>{children}</Index.Typography>
        </Index.Box>
      )}
    </div>
  );
}

SingleOrderTab.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function TankerView() {
  const navigate = Index.useNavigate();
  const dispatch = Index.useDispatch();
  const { id } = Index.useParams();
  const { tankerActivityLogList } = Index.useSelector(
    (state) => state.AdminReducer
  );
  const [tankerData, setTankerData] = useState({});
  const [tankerDocuments, setTankerDocuments] = useState([]);
  const [value, setValue] = useState(0);
  const [openPdfModel, setOpnePdfmodel] = useState(false);
  const [uploadDocumentName, setUploadDocumentName] = useState("");

  const [taxReceiptDocuments, setTaxReceiptDocuments] = useState([]);
  const [calibrationDocuments, setCalibrationDocuments] = useState([]);
  const [fitnessDocuments, setFitnessDocuments] = useState([]);
  const [permitDocuments, setPermitDocuments] = useState([]);
  const [insuranceDocuments, setInsuranceDocuments] = useState([]);
  const [rcBookDocuments, setRcBookDocuments] = useState([]);
  const [particularDocuments, setParticularDocuments] = useState([]);
  const [pucDocuments, setPucDocuments] = useState([]);

  let initialValues = {
    documentType: "",
    tankerDocumentImage: "",
    expireDate: "",
    expireCheckBox: false,
  };

  const validationSchema = Yup.object().shape({
    documentType: Yup.string().required("Document type is required"),
    expireDate: Yup.string().when("expireCheckBox", {
      is: false,
      then: () => Yup.string().required("Expiry Date is required"),
      otherwise: () => Yup.string().notRequired(),
    }),
    tankerDocumentImage: Yup.mixed()
      .required("Document is required")
      .test(
        "FILE_FORMAT",
        "Allow only jpg, jpeg ,png, pdf file",
        (value) =>
          !value ||
          (["jpg", "jpeg", "png", "pdf"].includes(
            value?.name?.substr(value?.name?.lastIndexOf(".") + 1)
          ) &&
            [
              "image/jpg",
              "image/jpeg",
              "image/png",
              "application/pdf",
            ].includes(value?.type))
      )
      .test(
        "fileSize",
        "File size too large, max file size is 2.048 Mb",
        (file) => {
          if (file) {
            return file.size <= 2048000;
          } else {
            return true;
          }
        }
      ),
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // let singleData = state?.row;
  const backBtn = () => {
    navigate("/admin/tankers");
  };

  // filter activity log
  const expenseLogData = tankerActivityLogList?.filter((item) => {
    return item.activityTypeId === id;
  });

  //Tanker activity log function
  const tankerActivityLog = (id) => {
    const tankerLogData = {
      activityTypeId: id,
      type: "tanker",
    };
    dispatch(getTankerActivityLog(tankerLogData));
  };

  // Single Tanker Detail function
  const getSingleTankerDetails = (id) => {
    const data = {
      id: id,
    };
    dispatch(getSingleTankerMaster(data)).then((res) => {
      if (res?.status === 200) {
        setTankerData(res?.data?.findData);
      }
    });
  };

  // get single tanker documents function
  const singleTankerDocuments = (id) => {
    const data = {
      tankerId: id,
    };
    dispatch(getSingleTankerDocuments(data)).then((res) => {
      if (res?.status === 200) {
        setTankerDocuments(res?.data);
      }
    });
  };

  const handleClickPdfOpen = () => {
    setOpnePdfmodel(true);
  };
  const handlePdfClose = () => {
    setOpnePdfmodel(false);
    setUploadDocumentName("");
  };

  // Single Order PDF Save
  const handleFormSubmit = (values) => {
    const data = new FormData();
    data.append("documentType", values?.documentType);
    data.append("tankerId", id);
    data.append("tankerDocumentImage", values?.tankerDocumentImage);
    data.append("expireDate", values?.expireDate);
    data.append("expireCheckBox", values?.expireCheckBox);
    dispatch(tankerDocumentUpload(data)).then((response) => {
      if (response) {
        singleTankerDocuments(id); // call get all tanker document api
        handlePdfClose();
      }
    });
  };

  useEffect(() => {
    if (tankerDocuments) {
      for (var key in tankerDocuments) {
        if (key === "taxReceipt") {
          setTaxReceiptDocuments(tankerDocuments[key]);
        } else if (key === "calibration") {
          setCalibrationDocuments(tankerDocuments[key]);
        } else if (key === "fitness") {
          setFitnessDocuments(tankerDocuments[key]);
        } else if (key === "permit") {
          setPermitDocuments(tankerDocuments[key]);
        } else if (key === "insurance") {
          setInsuranceDocuments(tankerDocuments[key]);
        } else if (key === "rcBook") {
          setRcBookDocuments(tankerDocuments[key]);
        } else if (key === "particular") {
          setParticularDocuments(tankerDocuments[key]);
        } else if (key === "puc") {
          setPucDocuments(tankerDocuments[key]);
        }
      }
    }
  }, [tankerDocuments]);

  //Driver Section

  //Handle single driver view
  const handleDriverView = (item) => {
    navigate("/admin/tankers/driver/view", {
      state: { row: item, tankerId: id, tankerData: tankerData },
    });
  };

  useEffect(() => {
    getSingleTankerDetails(id);
    tankerActivityLog(id);
    singleTankerDocuments(id);
  }, [id, value]);

  return (
    <>
      <Index.Box className="invoice-details">
        <Index.Box className="barge-common-box mb-20">
          <Index.Box className="title-header-flex flex-column-res">
            <Index.Box className="invoice-back-title">
              <Index.Button
                type="submit"
                className="Particular_model_submit back-invoice-btn"
                onClick={backBtn}
              >
                <Index.ArrowBackIcon />
              </Index.Button>
              <Index.Typography
                variant="span"
                component="span"
                className="invoice-title"
              >
                Tanker Details
              </Index.Typography>
            </Index.Box>
          </Index.Box>

          <Index.Box className="mt-10">
            <Index.Grid container spacing={1}>
              <Index.Grid item xs={12} sm={12} md={4}>
                <Index.Box className="mb-5 invoice-detail-item">
                  <Index.Box className="input-label opacity-60">
                    Tanker Name :
                  </Index.Box>
                  <Index.Box className="input-label">
                    {tankerData?.tankerName ? tankerData?.tankerName : "-"}
                  </Index.Box>
                </Index.Box>
              </Index.Grid>
              <Index.Grid item xs={12} sm={12} md={4}>
                <Index.Box className="mb-5 invoice-detail-item">
                  <Index.Box className="input-label opacity-60">
                    Tanker Number :
                  </Index.Box>
                  <Index.Box className="input-label">
                    {tankerData?.tankerNumber ? tankerData?.tankerNumber : "-"}
                  </Index.Box>
                </Index.Box>
              </Index.Grid>
              <Index.Grid item xs={12} sm={12} md={4}>
                <Index.Box className="mb-5 invoice-detail-item">
                  <Index.Box className="input-label opacity-60">
                    Capacity :
                  </Index.Box>
                  <Index.Box className="input-label">
                    {tankerData?.capacity ? tankerData?.capacity : "-"}
                  </Index.Box>
                </Index.Box>
              </Index.Grid>
              <Index.Grid item xs={12} sm={12} md={4}>
                <Index.Box className="invoice-detail-item">
                  <Index.Box className="input-label opacity-60">
                    Created Date :
                  </Index.Box>
                  <Index.Box className="input-label pdf-input">
                    {tankerData?.createdAt
                      ? Index.moment(tankerData?.createdAt).format(
                        "DD/MM/YYYY hh:mm:ss A"
                      )
                      : "-"}
                  </Index.Box>
                </Index.Box>
              </Index.Grid>
            </Index.Grid>
          </Index.Box>
        </Index.Box>
        <Index.Box className="mb-20">
          {/* Design Tab */}
          <Index.Box sx={{ width: "100%" }}>
            <Index.Box
              className="tab-button-box"
              sx={{ borderBottom: 1, borderColor: "divider" }}
            >
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                className="dashboard-tab"
              >
                <Tab
                  className="tab-button"
                  label="Documents"
                  {...a11yProps(0)}
                />
                <Tab className="tab-button" label="Driver" {...a11yProps(1)} />
                <Tab
                  className="tab-button"
                  label="Activity"
                  {...a11yProps(2)}
                />
              </Tabs>
            </Index.Box>

            <SingleOrderTab
              value={value}
              index={0}
              className="barge-common-box tab-content-box"
            >
              <Index.Box className="upload-view">
                <Index.Box className="title-header">
                  <Index.Box className="title-header-flex res-title-header-flex">
                    <Index.Box className="title-main">
                      <Index.Typography
                        variant="p"
                        component="p"
                        className="page-title"
                      >
                        {/* Document */}
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="common-button blue-button res-blue-button">
                      <Index.Button
                        variant="outlined"
                        onClick={() => {
                          handleClickPdfOpen();
                        }}
                        className="view-invoice-btn"
                      >
                        Upload Document
                      </Index.Button>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <TankerDocument
                docTitle="Tax Receipt"
                tankerDocData={taxReceiptDocuments}
                tankerId={id}
                singleTankerDocuments={singleTankerDocuments}
              />
              <TankerDocument
                docTitle="Calibration"
                tankerDocData={calibrationDocuments}
                tankerId={id}
                singleTankerDocuments={singleTankerDocuments}
              />
              <TankerDocument
                docTitle="Fitness"
                tankerDocData={fitnessDocuments}
                tankerId={id}
                singleTankerDocuments={singleTankerDocuments}
              />
              <TankerDocument
                docTitle="Permit"
                tankerDocData={permitDocuments}
                tankerId={id}
                singleTankerDocuments={singleTankerDocuments}
              />
              <TankerDocument
                docTitle="Insurance"
                tankerDocData={insuranceDocuments}
                tankerId={id}
                singleTankerDocuments={singleTankerDocuments}
              />
              <TankerDocument
                docTitle="RC book"
                tankerDocData={rcBookDocuments}
                tankerId={id}
                singleTankerDocuments={singleTankerDocuments}
              />
              <TankerDocument
                docTitle="Particular"
                tankerDocData={particularDocuments}
                tankerId={id}
                singleTankerDocuments={singleTankerDocuments}
              />
              <TankerDocument
                docTitle="PUC"
                tankerDocData={pucDocuments}
                tankerId={id}
                singleTankerDocuments={singleTankerDocuments}
              />
            </SingleOrderTab>

            <SingleOrderTab
              value={value}
              index={1}
              className="barge-common-box tab-content-box"
            >
              <Index.Grid container spacing={1}>
                <Index.Box className="order-active-log-header expensedetails-table order-active-table-set">
                  <Index.TableContainer
                    component={Index.Paper}
                    className="table-container"
                  >
                    <Index.Table
                      aria-label="simple table"
                      className="table-design-main supply-table one-line-table invoice-table-set user-management-table tanker-list my-tanker-table"
                    >
                      <Index.TableHead>
                        <Index.TableRow>
                          <Index.TableCell align="left">S. No</Index.TableCell>
                          <Index.TableCell align="left">
                            Driver Name
                          </Index.TableCell>
                          <Index.TableCell align="left">
                            Driver Mobile No
                          </Index.TableCell>
                          <Index.TableCell align="left">
                            Created Date
                          </Index.TableCell>
                          <Index.TableCell align="left">
                            Updated Date
                          </Index.TableCell>
                          <Index.TableCell align="left">Action</Index.TableCell>
                        </Index.TableRow>
                      </Index.TableHead>

                      <Index.TableBody>
                        {tankerData?.driverId?.length ? (
                          tankerData?.driverId
                            ?.reverse()
                            ?.map((item, index) => {
                              return (
                                <Index.TableRow key={index}>
                                  <Index.TableCell>
                                    <Index.Typography>
                                      {index + 1}
                                    </Index.Typography>
                                  </Index.TableCell>
                                  <Index.TableCell>
                                    <Index.Typography>
                                      {item?.driverName
                                        ? item?.driverName
                                        : "-"}
                                    </Index.Typography>
                                  </Index.TableCell>
                                  <Index.TableCell>
                                    <Index.Typography>
                                      {item?.driverMobileNumber
                                        ? item?.driverMobileNumber
                                        : "-"}
                                    </Index.Typography>
                                  </Index.TableCell>
                                  <Index.TableCell>
                                    <Index.Typography>
                                      {Index.moment(item?.createdAt).format(
                                        "DD/MM/YYYY h:mm:ss A"
                                      )}
                                    </Index.Typography>
                                  </Index.TableCell>

                                  <Index.TableCell>
                                    <Index.Typography>
                                      {Index.moment(item?.updatedAt).format(
                                        "DD/MM/YYYY h:mm:ss A"
                                      )}
                                    </Index.Typography>
                                  </Index.TableCell>
                                  <Index.TableCell>
                                    <Index.IconButton>
                                      <Index.VisibilitySharpIcon
                                        onClick={() => {
                                          handleDriverView(item);
                                        }}
                                      />
                                    </Index.IconButton>
                                  </Index.TableCell>
                                </Index.TableRow>
                              );
                            })
                        ) : (
                          <Index.TableRow>
                            <Index.TableCell
                              component="td"
                              variant="td"
                              scope="row"
                              className="no-data-in-list"
                              colSpan={15}
                              align="center"
                            >
                              No data available
                            </Index.TableCell>
                          </Index.TableRow>
                        )}
                      </Index.TableBody>
                    </Index.Table>
                  </Index.TableContainer>
                </Index.Box>
              </Index.Grid>
            </SingleOrderTab>

            <SingleOrderTab
              value={value}
              index={2}
              className="barge-common-box tab-content-box"
            >
              <Index.Grid container spacing={1}>
                <Index.Box className="order-active-log-header expensedetails-table order-active-table-set">
                  <Index.TableContainer
                    component={Index.Paper}
                    className="table-container"
                  >
                    <Index.Table
                      aria-label="simple table"
                      className="table-design-main supply-table one-line-table invoice-table-set user-management-table tanker-list my-tanker-table"
                    >
                      <Index.TableHead>
                        <Index.TableRow>
                          <Index.TableCell>Activity Type</Index.TableCell>
                          <Index.TableCell>Details</Index.TableCell>
                          <Index.TableCell>Date & Time</Index.TableCell>
                        </Index.TableRow>
                      </Index.TableHead>

                      <Index.TableBody>
                        {expenseLogData?.length ? (
                          expenseLogData &&
                          expenseLogData?.map((items, index) => {
                            return (
                              <Index.TableRow>
                                <Index.TableCell>
                                  <Index.Typography>
                                    {items?.activityType}
                                  </Index.Typography>
                                </Index.TableCell>
                                <Index.TableCell>
                                  <Index.Typography>
                                    {items?.details}
                                  </Index.Typography>
                                </Index.TableCell>
                                <Index.TableCell>
                                  <Index.Typography>
                                    {Index.moment(items?.createdAt).format(
                                      "DD/MM/YYYY h:mm:ss A"
                                    )}
                                  </Index.Typography>
                                </Index.TableCell>
                              </Index.TableRow>
                            );
                          })
                        ) : (
                          <Index.TableRow>
                            <Index.TableCell
                              component="td"
                              variant="td"
                              scope="row"
                              className="no-data-in-list"
                              colSpan={15}
                              align="center"
                            >
                              No data available
                            </Index.TableCell>
                          </Index.TableRow>
                        )}
                      </Index.TableBody>
                    </Index.Table>
                  </Index.TableContainer>
                </Index.Box>
              </Index.Grid>
            </SingleOrderTab>
          </Index.Box>
          {/* Design Tab */}
        </Index.Box>
      </Index.Box>

      {/* Tanker Document Upload Files */}
      <Index.Dialog
        open={openPdfModel}
        onClose={handlePdfClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Index.DialogContent className="expense-model-main  expense-model-main-box-set">
          <Index.Box className="title-main">
            <Formik
              enableReinitialize
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleFormSubmit}
            >
              {({
                values,
                errors,
                touched,
                setFieldValue,
                handleSubmit,
                handleBlur,
              }) => (
                <form onSubmit={handleSubmit}>
                  {console.log(values, "values_______________")}
                  <Index.Box className="title-main" sx={{ marginTop: "0px" }}>
                    <Index.Box className="jus-between">
                      <Index.Typography
                        variant="h6"
                        component="h6"
                        className="input-label input-label-bold"
                        sx={{ marginBottom: "15px" }}
                      >
                        Document Upload
                      </Index.Typography>
                      <Index.ClearIcon
                        className="model-close-icon"
                        onClick={handlePdfClose}
                      />
                    </Index.Box>
                    <Index.Grid container spacing={2}>
                      <Index.Grid item xs={12} sm={6} md={12}>
                        <Index.Typography
                          variant="label"
                          component="label"
                          className="input-label"
                        >
                          Document Type
                        </Index.Typography>

                        <Index.Box className="input-design-div with-border">
                          <Index.Autocomplete
                            disablePortal
                            className="invoice-select-drop"
                            id="combo-box-demo"
                            options={
                              tankerDocumentType.sort((a, b) =>
                                a.name.localeCompare(b.name)
                              ) || []
                            }
                            getOptionLabel={(option) => option?.name}
                            renderOption={(props, option) => (
                              <Index.Box
                                component="li"
                                sx={{
                                  "& > img": { mr: 2, flexShrink: 0 },
                                }}
                                {...props}
                              >
                                {option?.name}
                              </Index.Box>
                            )}
                            onChange={(event, newValue) => {
                              setFieldValue("documentType", newValue?.type);
                              if (newValue?.type === "adharCard") {
                                setFieldValue("expireDate", "");
                                setFieldValue("expireCheckBox", false);
                              }
                              // handleChange(event);
                            }}
                            onBlur={handleBlur}
                            renderInput={(params) => (
                              <Index.TextField
                                {...params}
                                placeholder="Select Document Type"
                                variant="outlined"
                              />
                            )}
                          />
                          {touched.documentType && errors.documentType && (
                            <Index.FormHelperText error>
                              {errors.documentType}
                            </Index.FormHelperText>
                          )}
                        </Index.Box>
                      </Index.Grid>

                      <Index.Grid item xs={12} sm={6} md={12} mt={2}>
                        <Index.Typography
                          variant="label"
                          component="label"
                          className="input-label"
                        >
                          Document Upload
                        </Index.Typography>
                        <Index.Box className="input-design-div with-border position-relative">
                          <Index.Box className="pdf-design">
                            Upload File
                          </Index.Box>
                          <Index.TextField
                            fullWidth
                            type="file"
                            name="tankerDocumentImage"
                            // multiple
                            id="filled-hidden-label-normal"
                            variant="filled"
                            className="admin-input-design input-placeholder pdf-upload-design"
                            onChange={(e) => {
                              setFieldValue(
                                "tankerDocumentImage",
                                e.target?.files[0]
                              );
                              setUploadDocumentName(e.target?.files[0]?.name);
                            }}
                            onBlur={handleBlur}
                            // inputProps={{
                            //   multiple: true,
                            // }}
                            FormHelperTextProps={{
                              className: "input_label_props",
                            }}
                          />
                        </Index.Box>

                        {touched?.tankerDocumentImage &&
                          errors?.tankerDocumentImage && (
                            <Index.FormHelperText error>
                              {errors?.tankerDocumentImage}
                            </Index.FormHelperText>
                          )}
                      </Index.Grid>

                      <Index.Grid item xs={12} sm={12} md={12}>
                        <Index.Typography>
                          {uploadDocumentName && (
                            <Index.Typography
                              variant="label"
                              component="label"
                              className="input-label"
                            >
                              {uploadDocumentName}
                            </Index.Typography>
                          )}
                        </Index.Typography>
                      </Index.Grid>

                      <Index.Grid item xs={12} sm={6} md={12}>
                        <Index.Typography
                          variant="label"
                          component="label"
                          className="input-label"
                        >
                          Expiry Date
                        </Index.Typography>
                        <Index.Box className="input-design-div">
                          <Index.Box className="set-date set-date-picker">
                            <Index.LocalizationProvider
                              dateAdapter={Index.AdapterDayjs}
                            >
                              <Index.DemoContainer components={["DatePicker"]}>
                                <Index.DemoItem label="">
                                  <Index.DatePicker
                                    format="DD/MM/YYYY"
                                    value={
                                      values?.expireDate
                                        ? Index.dayjs(
                                          Index.moment(
                                            values?.expireDate
                                          ).format("YYYY-MM-DD")
                                        )
                                        : ""
                                    }
                                    onChange={(value) => {
                                      const formatedDate = Index.moment(
                                        value.$d
                                      ).format("YYYY-MM-DD");
                                      setFieldValue("expireDate", formatedDate);
                                      setFieldValue("expireCheckBox", false);
                                    }}
                                  />
                                </Index.DemoItem>
                              </Index.DemoContainer>
                            </Index.LocalizationProvider>
                            {touched?.expireDate &&
                              errors?.expireDate &&
                              !values?.expireCheckBox && (
                                <Index.FormHelperText error>
                                  {errors?.expireDate}
                                </Index.FormHelperText>
                              )}
                          </Index.Box>
                        </Index.Box>
                      </Index.Grid>

                      <Index.Grid item xs={12} sm={6} md={12}>
                        <Index.Box className="input-design-div with-border">
                          <Index.FormControlLabel
                            control={
                              <Index.Checkbox
                                checked={values?.expireCheckBox}
                                onChange={(event) => {
                                  setFieldValue(
                                    "expireCheckBox",
                                    event.target.checked
                                  );
                                  console.log(
                                    event.target.checked,
                                    "event.target.checked"
                                  );
                                  setFieldValue("expireDate", "");
                                }}
                              />
                            }
                            label="Doesn't have expiry"
                          />
                        </Index.Box>
                      </Index.Grid>
                      <Index.Grid item xs={12} sm={6} md={12}>
                        <Index.Box
                          className="common-button blue-button flex-end save-btn"
                          sx={{
                            display: "flex",
                            alignItems: "flex-end",
                          }}
                        >
                          <Index.Button
                            variant="contained"
                            type="submit"
                            className="Particular_model_submit"
                          >
                            Submit
                          </Index.Button>
                        </Index.Box>
                      </Index.Grid>
                    </Index.Grid>

                    {/* <Index.Box className="line" mt={3} mb={3}></Index.Box> */}
                  </Index.Box>
                </form>
              )}
            </Formik>
          </Index.Box>
        </Index.DialogContent>
      </Index.Dialog>
    </>
  );
}

export default TankerView;
