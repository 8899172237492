import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { SingleInputDateRangeField } from "@mui/x-date-pickers-pro/SingleInputDateRangeField";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import {
  getWaterSupplyList,
  updateWaterSupplyOrderStatus,
} from "../../../../../redux/admin/action";
import { NEW_EXPORT_EXCEL } from "../../ExportAll";
import Index from "../../../../Index";

const Search = Index.styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: Index.alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: Index.alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const StyledInputBase = Index.styled(Index.InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

const shortcutsItems = [
  {
    label: "This Week",
    getValue: () => {
      const today = dayjs();
      return [today.startOf("week"), today.endOf("week")];
    },
  },
  {
    label: "Last Week",
    getValue: () => {
      const today = dayjs();
      const prevWeek = today.subtract(7, "day");
      return [prevWeek.startOf("week"), prevWeek.endOf("week")];
    },
  },
  {
    label: "Last 7 Days",
    getValue: () => {
      const today = dayjs();
      return [today.subtract(7, "day"), today];
    },
  },
  {
    label: "Current Month",
    getValue: () => {
      const today = dayjs();
      return [today.startOf("month"), today.endOf("month")];
    },
  },
  {
    label: "Next Month",
    getValue: () => {
      const today = dayjs();
      const startOfNextMonth = today.endOf("month").add(1, "day");
      return [startOfNextMonth, startOfNextMonth.endOf("month")];
    },
  },
  { label: "Reset", getValue: () => [null, null] },
];

function InvoiceWaterSupply() {
  const dispatch = Index.useDispatch();
  const [loading, setLoading] = useState(false);
  const [orderCheckId, setOrderCheckId] = useState([]);

  // State for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [paginationPerPage, setPaginationPerPage] = useState(50);
  const [searchingText, setSearchingText] = useState("");
  const [totalRecordCount, setTotalRecordCount] = useState(0);
  //   const [serachedDateRange, setSearchedDateRange] = useState("");
  const [waterSupplyCompletedOrdersList, setWaterSupplyCompletedOrdersList] =
    useState([]);
  const [waterSupplyOrderActionType, setWaterSupplyOrderActionType] =
    React.useState([]);

  const handlePageChange = React.useCallback(
    (event, value) => {
      setCurrentPage(value);
      const values = {
        page: value,
        search: searchingText,
        size: paginationPerPage,
        // to:
        //   serachedDateRange?.length > 0
        //     ? serachedDateRange[0].format("YYYY-MM-DD")
        //     : "",
        // from:
        //   serachedDateRange?.length > 0
        //     ? serachedDateRange[1].format("YYYY-MM-DD")
        //     : "",
      };
      getWaterSupplyCompletedOrderList(values);
    },
    [searchingText, paginationPerPage, setCurrentPage]
  );

  // get completed order list
  const getWaterSupplyCompletedOrderList = (values) => {
    setLoading(true);
    // getTugsList
    dispatch(getWaterSupplyList({ ...values, type: "Completed" })).then(
      (res) => {
        if (res?.status === 200) {
          setLoading(false);
          setTotalRecordCount(res?.totalCount);
          setCurrentPage(res?.currentPage);
          setWaterSupplyCompletedOrdersList(res?.data);
        } else {
          setLoading(false);
          setTotalRecordCount(0);
          setCurrentPage(1);
          setWaterSupplyCompletedOrdersList([]);
        }
      }
    );
  };

  useEffect(() => {
    const values = {
      page: 1,
      search: "",
      size: paginationPerPage,
      //   to:
      //     serachedDateRange?.length > 0
      //       ? serachedDateRange[0].format("YYYY-MM-DD")
      //       : "",
      //   from:
      //     serachedDateRange?.length > 0
      //       ? serachedDateRange[1].format("YYYY-MM-DD")
      //       : "",
    };
    getWaterSupplyCompletedOrderList(values);
  }, []);

  // when the use change the date then used...
  //   const handleChangeDate = React.useCallback(
  //     (data) => {
  //       setSearchedDateRange(data);
  //       if (data[0] !== null && data[1] !== null) {
  //         const values = {
  //           page: 1,
  //           search: searchingText,
  //           size: paginationPerPage,
  //           to: data[0] !== null ? data[0].format("YYYY-MM-DD") : "",
  //           from: data[1] !== null ? data[1].format("YYYY-MM-DD") : "",
  //         };
  //         getWaterSupplyCompletedOrderList(values);
  //       }
  //     },
  //     [setSearchedDateRange, searchingText, paginationPerPage]
  //   );

  // when the user change the per page record change then used..
  const handleChangePaginationPerChange = (e) => {
    const { value } = e.target;
    setPaginationPerPage(value);
    const values = {
      page: 1,
      search: searchingText,
      size: value,
      //   to:
      //     serachedDateRange?.length > 0
      //       ? serachedDateRange[0].format("YYYY-MM-DD")
      //       : "",
      //   from:
      //     serachedDateRange?.length > 0
      //       ? serachedDateRange[1].format("YYYY-MM-DD")
      //       : "",
    };
    getWaterSupplyCompletedOrderList(values);
  };

  // when the user search the journal records then used...
  const handleSearchCompletedListData = (e) => {
    const { value } = e.target;
    setSearchingText(value);
    if (value?.length > 0) {
      const values = {
        page: 1,
        search: value,
        size: paginationPerPage,
        // to:
        //   serachedDateRange?.length > 0
        //     ? serachedDateRange[0].format("YYYY-MM-DD")
        //     : "",
        // from:
        //   serachedDateRange?.length > 0
        //     ? serachedDateRange[1].format("YYYY-MM-DD")
        //     : "",
      };
      getWaterSupplyCompletedOrderList(values);
    } else {
      const values = {
        page: 1,
        search: "",
        size: paginationPerPage,
        // to:
        //   serachedDateRange?.length > 0
        //     ? serachedDateRange[0].format("YYYY-MM-DD")
        //     : "",
        // from:
        //   serachedDateRange?.length > 0
        //     ? serachedDateRange[1].format("YYYY-MM-DD")
        //     : "",
      };
      getWaterSupplyCompletedOrderList(values);
    }
  };

  // when the user change the status then used...
  const handleChangeWaterSupplyAction = (e, data) => {
    const values = {
      id: data?._id,
      invoiceStatus: e.target.value,
    };

    dispatch(updateWaterSupplyOrderStatus(values)).then((res) => {
      if (res.status === 200) {
        setWaterSupplyOrderActionType((prev) => [
          ...prev.filter((res) => res.id !== data?._id),
          values,
        ]);
      }
    });
  };

  return (
    <>
      <Index.DynamicTitle title="Water Supply Completed Orders" />
      <Index.Box className="">
        <Index.Box className="barge-common-box">
          <Index.Box className="title-header">
            <Index.Box className="title-header-flex res-title-header-flex">
              <Index.Box className="title-main">
                <Index.Typography
                  variant="p"
                  component="p"
                  className="page-title"
                >
                  Water Supply Completed Orders
                </Index.Typography>
              </Index.Box>
              <Index.Box className="d-flex align-items-center res-set-search">
                {/* <Index.Box className="input-design-div">
                  <Index.Box className="set-date set-date-picker remove-name">
                    <Index.LocalizationProvider
                      dateAdapter={Index.AdapterDayjs}
                    >
                      <Index.DemoContainer
                        components={["SingleInputDateRangeField"]}
                        slotProps={{ textField: { size: "small" } }}
                      >
                        <DateRangePicker
                          slots={{ field: SingleInputDateRangeField }}
                          slotProps={{
                            shortcuts: {
                              items: shortcutsItems,
                            },
                            textField: {
                              readOnly: true,
                              size: "small",
                            },
                            actionBar: { actions: [] },
                          }}
                          onChange={(value) => handleChangeDate(value)}
                          name="allowedRange"
                          // defaultValue={serachedDateRange}
                        />
                      </Index.DemoContainer>
                    </Index.LocalizationProvider>
                  </Index.Box>
                </Index.Box> */}
                <Search className="search ">
                  <StyledInputBase
                    placeholder="Search"
                    inputProps={{ "aria-label": "search" }}
                    onChange={(e) => handleSearchCompletedListData(e)}
                  />
                </Search>

                {/* <Index.Box className="common-button grey-button">
                  <Index.Button
                    variant="contained"
                    // disabled={exportDetails?.length ? false : true}
                    onClick={() => {
                      let curDate = Index.moment(new Date()).format(
                        "DD-MM-YYYY"
                      );
                      NEW_EXPORT_EXCEL(
                        "/admin/export-completed-order",
                        `Complete_Order_List_${curDate}`,
                        {
                          search: searchingText,
                          to:
                            serachedDateRange?.length > 0
                              ? serachedDateRange[0].format("YYYY-MM-DD")
                              : "",
                          from:
                            serachedDateRange?.length > 0
                              ? serachedDateRange[1].format("YYYY-MM-DD")
                              : "",
                        }
                      );
                    }}
                  >
                    Export
                  </Index.Button>
                </Index.Box> */}
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Index.Box className="">
            <Index.TableContainer
              component={Index.Paper}
              className="table-container"
            >
              <Index.Table
                aria-label="simple table"
                className="table-design-main supply-table one-line-table invoice-table-set "
              >
                <Index.TableHead>
                  <Index.TableRow>
                    <Index.TableCell>S. No (Unique no.)</Index.TableCell>
                    <Index.TableCell></Index.TableCell>
                    <Index.TableCell align="left">
                      Name of Vessel
                    </Index.TableCell>
                    <Index.TableCell align="left">Agent</Index.TableCell>
                    <Index.TableCell align="left">Port</Index.TableCell>
                    <Index.TableCell align="left"> Vessel ETA</Index.TableCell>
                    <Index.TableCell align="left"> Vessel ETB</Index.TableCell>
                    <Index.TableCell align="left"> Vessel ETD</Index.TableCell>
                    <Index.TableCell align="left"> Coastal</Index.TableCell>
                    <Index.TableCell align="left"> Quantity</Index.TableCell>
                    <Index.TableCell align="left"> Water Type</Index.TableCell>
                    <Index.TableCell align="left"> Order By</Index.TableCell>
                    <Index.TableCell align="left">
                      {" "}
                      Supply Date & Time
                    </Index.TableCell>
                    <Index.TableCell align="left">Action</Index.TableCell>
                  </Index.TableRow>
                </Index.TableHead>
                {loading ? (
                  <Index.TableBody>
                    <Index.TableRow>
                      <Index.TableCell colSpan={14} align="center">
                        <Index.CircularProgress color="secondary" size={20} />
                      </Index.TableCell>
                    </Index.TableRow>
                  </Index.TableBody>
                ) : (
                  <Index.TableBody className="tanker-table-body">
                    {waterSupplyCompletedOrdersList?.length ? (
                      waterSupplyCompletedOrdersList
                        ?.sort(
                          (a, b) =>
                            Index.moment(b.updatedAt).valueOf() -
                            Index.moment(a.updatedAt).valueOf()
                        )
                        .map((item, index) => (
                          <Index.TableRow
                            key={item?._id}
                            className={
                              item?.bunkerCall === true
                                ? "tanker-row-color"
                                : ""
                            }
                            sx={{ cursor: "pointer" }}
                          >
                            <Index.TableCell>{item?.serialNo}</Index.TableCell>
                            {/* <Index.TableCell>
                              <Index.FormControlLabel
                                control={
                                  <Index.Checkbox
                                    onChange={(event) => {
                                      if (event.target.checked === true) {
                                        setOrderCheckId([
                                          ...orderCheckId,
                                          item?._id,
                                        ]);
                                      } else {
                                        setOrderCheckId(
                                          orderCheckId?.filter((ele) => {
                                            return ele !== item?._id;
                                          })
                                        );
                                      }
                                    }}
                                  />
                                }
                                // label="Shipping Bill"
                              />
                            </Index.TableCell> */}
                            <Index.TableCell>
                              {" "}
                              <Index.Box className="challan-icon-align">
                                <Index.Box className="d-flex">
                                  {item?.supplyChallanAvailable === false ? (
                                    <Index.ReceiptLongIcon
                                      fontSize="small"
                                      sx={{ color: "black" }}
                                      className="challan-icon icon challan-icon-hidden"
                                    />
                                  ) : item?.supplyChallanStatus === "" ? (
                                    <Index.ReceiptLongIcon
                                      fontSize="small"
                                      sx={{ color: "black" }}
                                      className="challan-icon"
                                    />
                                  ) : item?.supplyChallanStatus ===
                                    "Approved" ? (
                                    <Index.ReceiptLongIcon
                                      fontSize="small"
                                      color="success"
                                      className="challan-icon"
                                    />
                                  ) : (
                                    <Index.ReceiptLongIcon
                                      fontSize="small"
                                      color="error"
                                      className="challan-icon"
                                    />
                                  )}
                                </Index.Box>
                              </Index.Box>
                            </Index.TableCell>

                            <Index.TableCell align="left">
                              {item?.vesselName ? item?.vesselName : "-"}
                            </Index.TableCell>

                            <Index.TableCell align="left">
                              {item?.agentName ? item?.agentName : "-"}
                            </Index.TableCell>

                            <Index.TableCell align="left">
                              {item?.port?.supplyPortName
                                ? item?.port?.supplyPortName
                                : "-"}
                            </Index.TableCell>

                            <Index.TableCell align="left">
                              {item?.vesselEta
                                ? Index.moment(item?.vesselEta).format(
                                    "DD/MM/YYYY HH:mm"
                                  )
                                : "-"}
                            </Index.TableCell>
                            <Index.TableCell align="left">
                              {item?.vesselEtb
                                ? Index.moment(item?.vesselEtb).format(
                                    "DD/MM/YYYY HH:mm"
                                  )
                                : "-"}
                            </Index.TableCell>
                            <Index.TableCell align="left">
                              {item?.vesselEtd
                                ? Index.moment(item?.vesselEtd).format(
                                    "DD/MM/YYYY HH:mm"
                                  )
                                : "-"}
                            </Index.TableCell>
                            <Index.TableCell align="left">
                              {item?.coastal ? item?.coastal : "-"}
                            </Index.TableCell>
                            <Index.TableCell align="left">
                              {item?.qty ? item?.qty : "-"}
                            </Index.TableCell>
                            <Index.TableCell align="left">
                              {item?.waterType ? item?.waterType : "-"}
                            </Index.TableCell>
                            <Index.TableCell align="left">
                              {item?.orderBy ? item?.orderBy : "-"}
                            </Index.TableCell>
                            <Index.TableCell align="left">
                              {item?.supplyDateTime
                                ? Index.moment(item?.supplyDateTime).format(
                                    "DD/MM/YYYY HH:mm"
                                  )
                                : "-"}
                            </Index.TableCell>
                            <Index.TableCell>
                              <Index.Box className="input-design-div with-border">
                                <Index.Select
                                  fullWidth
                                  value={
                                    waterSupplyOrderActionType?.length > 0 &&
                                    waterSupplyOrderActionType.find(
                                      (res) => res?.id === item?._id
                                    ) !== undefined
                                      ? waterSupplyOrderActionType.find(
                                          (res) => res?.id === item?._id
                                        ).invoiceStatus
                                      : item?.invoiceStatus === "Done"
                                      ? "Done"
                                      : item?.invoiceStatus === "Remaining"
                                      ? "Remaining"
                                      : ""
                                  }
                                  onChange={(e) =>
                                    handleChangeWaterSupplyAction(e, item)
                                  }
                                >
                                  <Index.MenuItem value="Done">
                                    Done
                                  </Index.MenuItem>
                                  <Index.MenuItem value="Remaining">
                                    Remaining
                                  </Index.MenuItem>
                                </Index.Select>
                              </Index.Box>
                            </Index.TableCell>
                          </Index.TableRow>
                        ))
                    ) : (
                      <Index.TableRow>
                        <Index.TableCell
                          component="td"
                          variant="td"
                          scope="row"
                          className="no-data-in-list empty-record-align"
                          colSpan={18}
                          align="center"
                        >
                          No data available
                        </Index.TableCell>
                      </Index.TableRow>
                    )}
                  </Index.TableBody>
                )}
              </Index.Table>
            </Index.TableContainer>
          </Index.Box>
          <Index.Box className="pagination-design flex-end">
            <Index.Stack spacing={2}>
              <Index.Box className="pagination-count">
                <Index.Box className="input-design-div with-border">
                  <Index.Select
                    fullWidth
                    value={paginationPerPage}
                    onChange={(e) => handleChangePaginationPerChange(e)}
                  >
                    <Index.MenuItem value={10}>10</Index.MenuItem>
                    <Index.MenuItem value={25}>25</Index.MenuItem>
                    <Index.MenuItem value={50}>50</Index.MenuItem>
                    <Index.MenuItem value={100}>100</Index.MenuItem>
                  </Index.Select>
                </Index.Box>
                {loading ? (
                  <Index.CircularProgress
                    color="secondary"
                    sx={{ marginLeft: "10px" }}
                    size={26}
                  />
                ) : (
                  <>
                    <Index.Pagination
                      count={Math.ceil(totalRecordCount / paginationPerPage)}
                      page={currentPage}
                      onChange={handlePageChange}
                    />
                  </>
                )}
              </Index.Box>
            </Index.Stack>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
}

export default InvoiceWaterSupply;
