import { useEffect, useState } from "react";
import Index from "../../../Index";
import { getDashboardBankDetails } from "../../../../redux/admin/action";

function AllDashboardBankData() {
  const dispatch = Index.useDispatch();
  const [loading, setLoading] = useState(false);
  const [dashbaordBankData, setDashboardBankData] = useState([]);

  //   when the get dashboard bank data then used...
  const handleGetDashboardBankData = () => {
    setLoading(true);
    dispatch(getDashboardBankDetails())
      .then((res) => {
        if (res.status === 200) {
          setDashboardBankData(res.data);
          setLoading(false);
        } else {
          setDashboardBankData([]);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error(error);
        setDashboardBankData([]);
        setLoading(false);
      });
  };

  //   when the page first time load then used...
  useEffect(() => {
    handleGetDashboardBankData();
  }, []);
  return (
    <>
      <Index.Box className="chart-graph-devider cattable-devider cattable-port">
        <Index.Box className="cate-table-main cate-table-main-box">
          <Index.TableContainer component={Index.Paper}>
            <Index.Table sx={{ minWidth: 650 }} aria-label="simple table">
              <Index.TableHead>
                <Index.TableRow>
                  <Index.TableCell width="10%">No.</Index.TableCell>
                  <Index.TableCell align="left" width="70%">
                    Bank Name
                  </Index.TableCell>
                  <Index.TableCell align="right" width="25%">
                    Balance
                  </Index.TableCell>
                </Index.TableRow>
              </Index.TableHead>
              <Index.TableBody>
                {loading ? (
                  <Index.TableRow>
                    <Index.TableCell
                      colSpan={12}
                      align="center"
                      className="loading-purchase"
                    >
                      <Index.CircularProgress color="secondary" size={20} />
                    </Index.TableCell>
                  </Index.TableRow>
                ) : (
                  <>
                    {dashbaordBankData?.map((items, index) => {
                      return (
                        <Index.TableRow
                          className=""
                          //   onClick={(e) => {
                          //     handleSalesWise(e, items);
                          //   }}
                        >
                          <Index.TableCell>{index + 1}</Index.TableCell>
                          <Index.TableCell sx={{ marginRight: "2px" }}>
                            <Index.Box className="">{items?.name}</Index.Box>
                            <Index.Box className="tanker-child-vessel">
                              {items?.company_name ? items?.company_name : "-"}
                            </Index.Box>
                          </Index.TableCell>
                          <Index.TableCell sx={{ marginLeft: "5px" }}>
                            <Index.Box className="tanker-child-vessel">
                              ₹
                              {items?.closingBalance < 0
                                ? (items?.closingBalance * -1)?.toLocaleString(
                                    "en-IN",
                                    {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }
                                  )
                                : items?.closingBalance?.toLocaleString(
                                    "en-IN",
                                    {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }
                                  )}
                            </Index.Box>
                          </Index.TableCell>
                        </Index.TableRow>
                      );
                    })}
                  </>
                )}
              </Index.TableBody>
            </Index.Table>
          </Index.TableContainer>
        </Index.Box>
      </Index.Box>
    </>
  );
}
export default AllDashboardBankData;
