import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import {
  getInvoiceActivityLog,
  getSingleEmployee,
} from "../../../../redux/admin/action";

function SingleEmployee() {
  const navigate = Index.useNavigate();
  const dispatch = Index.useDispatch();
  const { id } = Index.useParams();
  const [viewData, setViewData] = useState({});

  const BackBtn = () => {
    navigate("/admin/bunker");
  };

  // const handleEdit = (row) => {
  //   let data = {
  //     employeeId: id,
  //     advanceAmount: row?.totalAdvanceAmount,
  //   };
  //   navigate("/admin/employee-add", {
  //     state: { data },
  //   });
  // };

  // get single user
  const getSingleEmployeeDetail = () => {
    const data = {employeeId :id}
    dispatch(getSingleEmployee(data)).then((res) => {
      setViewData(res?.data);
    });
  };

  // redux data
  const { invoiceActivityLogList } = Index.useSelector(
    (state) => state.AdminReducer
  );
  // filter activity log
  const ADV_ExpenseLog = invoiceActivityLogList.filter((item) => {
    return item.activityTypeId === viewData?.employeeId?._id;
  });

  // user activity log
  const userActivityLog = () => {
    const userLogData = {
      activityTypeId: id,
      type: "admin",
    };
    dispatch(getInvoiceActivityLog(userLogData));
  };

  // get activity log
  useEffect(() => {
    getSingleEmployeeDetail(); // get single order
    userActivityLog(); // get activity log
  }, [dispatch, id]);


  
  const expenseViewPage = (id, expType) => {
    // console.log(id, expType,64);
    if (expType === "bunkerExpense") {
      navigate(`/admin/expenseList/view-barge-expense/${id}`);
    } else {
      navigate(`/admin/expenseList/view-other-expense/${id}`);
    }

  };
  return (
    <>
      <Index.Box className="barge-common-box">
        <Index.Box className="">
          <Index.Box className="title-header">
            <Index.Box className="title-header-flex flex-column-res">
              <Index.Box className="title-main mb-10 invoice_number">
                <Index.Box>
                  <Index.Typography
                    variant="p"
                    component="p"
                    className="page-title"
                  >
                    Employee Details
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
              <Index.Box className="common-button blue-button save-btn res-button-width">
                {/* <Index.Button
                  variant="contained"
                  type="submit"
                  className="Particular_model_submit view-invoice-btn"
                  onClick={() => handleEdit(viewData)}
                >
                  Edit
                </Index.Button> */}
                <Index.Button
                  variant="contained"
                  type="submit"
                  className="Particular_model_submit view-invoice-btn"
                  onClick={BackBtn}
                >
                  Back
                </Index.Button>
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Index.Box className="invoice-details">
            <Index.Grid container spacing={2}>
              <Index.Grid item xs={12} sm={6} md={3}>
                <Index.Box>
                  <Index.Box className="invoice-detail-item">
                    <Index.Typography
                      variant="label"
                      component="label"
                      className="input-label"
                    >
                      Employee Name:
                    </Index.Typography>
                    <Index.Box className="input-design-div with-border">
                  <Index.Typography
                    variant="label"
                    component="label"
                    className="input-label"
                  >
                    {viewData?.employeeId?.employeeName
                      ? viewData?.employeeId?.employeeName
                      : "-"}
                  </Index.Typography>
                </Index.Box>
                  </Index.Box>
                </Index.Box>

                
              </Index.Grid>

              <Index.Grid item xs={12} sm={6} md={3}>
              <Index.Box className="invoice-detail-item">
                <Index.Typography
                  variant="label"
                  component="label"
                  className="input-label"
                >
                  Advance Given:
                </Index.Typography>
                <Index.Box className="input-design-div with-border">
                  <Index.Typography
                    variant="label"
                    component="label"
                    className="input-label"
                  >
                    {viewData?.totalAdvanceAmount
                      ? `₹ ${viewData?.totalAdvanceAmount}`
                      : "-"}
                  </Index.Typography>
                </Index.Box>
                </Index.Box>
              </Index.Grid>

              <Index.Grid item xs={12} sm={6} md={3}>
              <Index.Box className="invoice-detail-item">
                <Index.Typography
                  variant="label"
                  component="label"
                  className="input-label"
                >
                  Total Expense:
                </Index.Typography>
                <Index.Box className="input-design-div with-border">
                  <Index.Typography
                    variant="label"
                    component="label"
                    className="input-label"
                  >
                    {viewData?.totalExpense
                      ? `₹ ${viewData?.totalExpense}`
                      : "-"}
                  </Index.Typography>
                </Index.Box>
                </Index.Box>
              </Index.Grid>

              <Index.Grid item xs={12} sm={6} md={3}>
              <Index.Box className="invoice-detail-item">
                <Index.Typography
                  variant="label"
                  component="label"
                  className="input-label"
                >
                  Net Available Balance:
                </Index.Typography>
                <Index.Box className="input-design-div with-border">
                  <Index.Typography
                    variant="label"
                    component="label"
                    className="input-label"
                  >
                    ₹{" "}
                    {(viewData?.totalAdvanceAmount
                      ? viewData?.totalAdvanceAmount
                      : 0) -
                      (viewData?.totalExpense ? viewData?.totalExpense : 0)}
                  </Index.Typography>
                </Index.Box>
                </Index.Box>
              </Index.Grid>
            </Index.Grid>
          </Index.Box>
        </Index.Box>
      </Index.Box>
      <Index.Box className="barge-common-box mt-20">
      <Index.Box className="order-active-log-header mt-0">
          <Index.TableContainer>
            {/* <Index.Typography component="h1" className="activity-log-header">
              <b>Activity Log</b>
            </Index.Typography> */}
            <Index.Box className="title-main mb-10 invoice_number">
                <Index.Box>
                  <Index.Typography
                    variant="p"
                    component="p"
                    className="page-title"
                  >
                    Activity Log
                  </Index.Typography>
                </Index.Box>
              </Index.Box>

            <Index.Table className="table-design-main activity-log-table-set invoice-activity-log">
              <Index.TableHead>
                <Index.TableRow>
                  <Index.TableCell>
                    Activity Type
                  </Index.TableCell>
                  <Index.TableCell>
                    Details
                  </Index.TableCell>
                  <Index.TableCell>
                    Date & Time
                  </Index.TableCell>
                  <Index.TableCell>
                    Action
                  </Index.TableCell>
                </Index.TableRow>
              </Index.TableHead>
              <Index.TableBody>
                {ADV_ExpenseLog?.length ? (
                  ADV_ExpenseLog &&
                  ADV_ExpenseLog?.map((items, index) => {
                    return (
                      <Index.TableRow>
                        <Index.TableCell>
                          <Index.Typography>
                            {items?.activityType}
                          </Index.Typography>
                        </Index.TableCell>
                        <Index.TableCell>
                          <Index.Typography>{items?.details}</Index.Typography>
                        </Index.TableCell>
                        <Index.TableCell>
                          <Index.Typography>
                            {Index.moment(items?.createdAt).format(
                              "Do MMMM YYYY h:mm:ss a"
                            )}
                          </Index.Typography>
                        </Index.TableCell>
                        <Index.TableCell>
                          {(items?.activityType === "Updated" ||
                            items?.activityType === "Created") &&
                          (items?.expenseType === "bunkerExpense" ||
                            items?.expenseType === "otherExpense") ? (
                            <Index.IconButton>
                              <Index.VisibilitySharpIcon
                                onClick={() => {
                                  expenseViewPage(
                                    items?.expenseId,
                                    items?.expenseType
                                  );
                                }}
                              />
                            </Index.IconButton>
                          ) : (
                            "-"
                          )}
                        </Index.TableCell>
                      </Index.TableRow>
                    );
                  })
                ) : (
                  <Index.TableRow>
                    <Index.TableCell
                      component="td"
                      variant="td"
                      scope="row"
                      className="no-data-in-list"
                      colSpan={6}
                      align="center"
                    >
                      No data available
                    </Index.TableCell>
                  </Index.TableRow>
                )}
              </Index.TableBody>
            </Index.Table>
          </Index.TableContainer>
        </Index.Box>
        </Index.Box>
    </>
  );
}

export default SingleEmployee;
