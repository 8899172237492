import React from "react";
import Index from "../../../Index";
import moment from "moment";

const OtherExpensePrintPDF = ({ expenseData, componentRef }) => {
  const totalAmount = expenseData?.otherExpense?.reduce((start, current) => {
    if (current?.price) {
      return start + current?.price;
    } else {
      return start + 0;
    }
  }, 0);

  return (
    <Index.Box ref={componentRef}>
      <table
        style={{
          maxWidth: 600,
          margin: "auto",
          borderCollapse: "collapse",
          width: "100%",
          marginTop: 50,
          marginBottom: 50,
        }}
      >
        <tbody>
          <tr>
            <td
              colSpan={2}
              style={{
                color: "#000000",
                fontSize: 15,
                lineHeight: 2,
                fontWeight: 700,
                fontFamily: '"Poppins", sans-serif',
                verticalAlign: "baseline",
                borderCollapse: "collapse",
                border: "2px solid black",
                padding: 5,
              }}
            >
              Name
            </td>
            <td
              colSpan={2}
              style={{
                width: "40%",
                height: 18,
                color: "#000000",
                fontSize: 15,
                lineHeight: 2,
                fontWeight: 500,
                fontFamily: '"Poppins", sans-serif',
                verticalAlign: "baseline",
                borderCollapse: "collapse",
                border: "2px solid black",
                padding: 5,
              }}
            >
              {expenseData?.createdBy?.firstName
                ? expenseData?.createdBy.firstName
                : "-"}
            </td>
          </tr>

          <tr>
            <td
              colSpan={2}
              style={{
                color: "#000000",
                fontSize: 15,
                lineHeight: 2,
                fontWeight: 700,
                fontFamily: '"Poppins", sans-serif',
                verticalAlign: "baseline",
                borderCollapse: "collapse",
                border: "2px solid black",
                padding: 5,
              }}
            >
              Date
            </td>
            <td
              colSpan={2}
              style={{
                width: "40%",
                height: 18,
                color: "#000000",
                fontSize: 15,
                lineHeight: 2,
                fontWeight: 500,
                fontFamily: '"Poppins", sans-serif',
                verticalAlign: "baseline",
                borderCollapse: "collapse",
                border: "2px solid black",
                padding: 5,
              }}
            >
              {expenseData?.createdAt
                ? Index.moment(expenseData?.createdAt).format("DD/MM/YYYY")
                : "-"}
            </td>
          </tr>

          <tr>
            <td
              colSpan={2}
              style={{
                color: "#000000",
                fontSize: 15,
                lineHeight: 2,
                fontWeight: 700,
                fontFamily: '"Poppins", sans-serif',
                verticalAlign: "baseline",
                borderCollapse: "collapse",
                border: "2px solid black",
                padding: 5,
              }}
            >
              Approved By
            </td>
            <td
              colSpan={2}
              style={{
                width: "40%",
                height: 18,
                color: "#000000",
                fontSize: 15,
                lineHeight: 2,
                fontWeight: 500,
                fontFamily: '"Poppins", sans-serif',
                verticalAlign: "baseline",
                borderCollapse: "collapse",
                border: "2px solid black",
                padding: 5,
              }}
            >
              {expenseData?.actionBy?.firstName
                ? expenseData?.actionBy?.firstName +
                  " " +
                  expenseData?.actionBy?.lastName
                : "-"}
            </td>
          </tr>

          <tr>
            <td
              colSpan={2}
              style={{
                color: "#000000",
                fontSize: 15,
                lineHeight: 2,
                fontWeight: 700,
                fontFamily: '"Poppins", sans-serif',
                verticalAlign: "baseline",
                borderCollapse: "collapse",
                border: "2px solid black",
                padding: 5,
              }}
            >
              Approved By Time
            </td>
            <td
              colSpan={2}
              style={{
                width: "40%",
                height: 18,
                color: "#000000",
                fontSize: 15,
                lineHeight: 2,
                fontWeight: 500,
                fontFamily: '"Poppins", sans-serif',
                verticalAlign: "baseline",
                borderCollapse: "collapse",
                border: "2px solid black",
                padding: 5,
              }}
            >
              {expenseData?.actionByTimeStamps
                ? moment(expenseData?.actionByTimeStamps).format(
                    "DD/MM/YYYY hh:mm:ss A"
                  )
                : "-"}
            </td>
          </tr>

          <tr>
            <br />
            <br />
          </tr>

          <tr>
            <th
              style={{
                color: "#000000",
                fontSize: 18,
                lineHeight: 2,
                fontWeight: 700,
                fontFamily: '"Poppins", sans-serif',
                verticalAlign: "baseline",
                borderCollapse: "collapse",
                border: "2px solid black",
                padding: 5,
              }}
            >
              Sr No
            </th>
            <th
              colSpan={2}
              style={{
                color: "#000000",
                fontSize: 18,
                lineHeight: 2,
                fontWeight: 700,
                fontFamily: '"Poppins", sans-serif',
                verticalAlign: "baseline",
                borderCollapse: "collapse",
                border: "2px solid black",
                padding: 5,
              }}
            >
              Detail
            </th>
            <th
              style={{
                color: "#000000",
                fontSize: 18,
                lineHeight: 2,
                fontWeight: 700,
                fontFamily: '"Poppins", sans-serif',
                verticalAlign: "baseline",
                borderCollapse: "collapse",
                border: "2px solid black",
                padding: 5,
              }}
            >
              Amount
            </th>
          </tr>

          {expenseData?.otherExpense &&
            expenseData?.otherExpense?.map((items, index) => {
              return (
                <>
                  <tr>
                    <td
                      style={{
                        textAlign: "center",
                        fontSize: 13,
                        color: "black",
                        lineHeight: 3,
                        fontWeight: 600,
                        fontFamily: '"Poppins", sans-serif',
                        verticalAlign: "baseline",
                        borderCollapse: "collapse",
                        border: "2px solid black",
                        padding: 5,
                      }}
                    >
                      {index + 1}
                    </td>
                    <td
                      colSpan={2}
                      style={{
                        textAlign: "center",
                        fontSize: 13,
                        color: "black",
                        lineHeight: 3,
                        fontWeight: 600,
                        fontFamily: '"Poppins", sans-serif',
                        verticalAlign: "baseline",
                        borderCollapse: "collapse",
                        border: "2px solid black",
                        padding: 5,
                        textTransform: "uppercase",
                      }}
                    >
                      {items?.description ? items?.description : "-"}
                    </td>
                    <td
                      style={{
                        textAlign: "center",
                        fontSize: 13,
                        color: "black",
                        lineHeight: 3,
                        fontWeight: 600,
                        fontFamily: '"Poppins", sans-serif',
                        verticalAlign: "baseline",
                        borderCollapse: "collapse",
                        border: "2px solid black",
                        padding: 5,
                      }}
                    >
                      {items?.price ? `₹ ${items.price}` : "-"}
                    </td>
                  </tr>
                </>
              );
            })}
          <tr>
            <td
              colSpan={3}
              style={{
                color: "#000000",
                fontSize: 15,
                lineHeight: 2,
                fontWeight: 700,
                fontFamily: '"Poppins", sans-serif',
                verticalAlign: "baseline",
                borderCollapse: "collapse",
                border: "2px solid black",
                padding: 5,
                textAlign: "center",
              }}
            >
              TOTAL
            </td>
            <td
              colSpan={3}
              style={{
                color: "#000000",
                fontSize: 15,
                lineHeight: 2,
                fontWeight: 700,
                fontFamily: '"Poppins", sans-serif',
                verticalAlign: "baseline",
                borderCollapse: "collapse",
                border: "2px solid black",
                padding: 5,
                textAlign: "center",
              }}
            >
              {totalAmount ? `₹ ${totalAmount}` : "-"}
            </td>
          </tr>
        </tbody>
      </table>
    </Index.Box>
  );
};

export default OtherExpensePrintPDF;
