import usericon from "./images/png/user.png";
import logo from "./images/png/logo.png";
import backlogin from "./images/png/backlogin.png";
import img1 from "./images/png/img1.png";
import img2 from "./images/png/img2.png";
import img3 from "./images/png/img3.png";
import img4 from "./images/png/img4.png";
import img5 from "./images/png/img5.png";
import img6 from "./images/png/img6.png";
import profileimg from "./images/png/profileimg.png";
import arrow from "./images/png/arrow.png";
import compny2 from "./images/png/compny2.png";
import compny3 from "./images/png/compny3.png";
import compny4 from "./images/png/compny4.png";
import modalcom1 from "./images/png/modalcom1.png";
import modalcom2 from "./images/png/modalcom2.png";
import modalcom3 from "./images/png/modalcom3.png";

import card1 from "./images/png/card1.png";
import card2 from "./images/png/card2.png";
import card3 from "./images/png/card3.png";
import card4 from "./images/png/card4.png";
import graph from "./images/png/graph.png";
import invoice from "./images/png/invoice.png";
import invoice2 from "./images/png/invoice2.png";
import state from "./images/png/state.png";
import sidebar from "./images/png/sidebar-logo.png";
import location from "./images/png/location-icon.png";
import cargoShip from "./images/png/cargo-ship.png";
import freeTanker from "./images/png/free-tanker.png";
import NoImage from "./images/png/No_Image.png";

const Png = {
  sidebar,
  state,
  invoice,
  invoice2,
  graph,
  card1,
  card2,
  card3,
  card4,
  usericon,
  logo,
  backlogin,
  img1,
  img2,
  img3,
  img4,
  img5,
  img6,
  arrow,
  profileimg,
  compny2,
  compny3,
  compny4,
  modalcom1,
  modalcom2,
  modalcom3,
  location,
  cargoShip,
  freeTanker,
  NoImage
};

export default Png;
