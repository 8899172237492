import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import * as Yup from "yup";
import { Formik } from "formik";
import { NEW_EXPORT_EXCEL } from "../ExportAll";
import {
  allCancelOrder,
  getInvoiceActivityLog,
  getOrder,
  orderShippingBillBoxCategory,
  addOrderPdfFile,
  getNotifications,
  updateMarkAsCompleteOrder,
  addOrderProformaPdfFile,
  deleteOrderDocument,
  deleteCurrentOrderDocument,
  addOrder,
  addEditOrder,
  handleGetUpdateSingleOrderField,
} from "../../../../redux/admin/action";
import OrderStatusModel from "../../../../component/common/OrderStatusModel";
import dayjs from "dayjs";
import { SingleInputDateRangeField } from "@mui/x-date-pickers-pro/SingleInputDateRangeField";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import SingleFieldUpdatePopUp from "../../../../component/common/SigleFieldUpdatePopUp";
import UpdateVesselNamePopUp from "./UpdateVesselNamePopUp";
import SingleUpdateDatePopUp from "../../../../component/common/SingleUpdateDatePopUp";
import SingleUpdateDateTimePopUp from "../../../../component/common/SingleUpdateDateTimePopUp";
import SingleUpdateTextFieldPopUp from "../../../../component/common/SingleUpdateTextFieldPopUp";
import UdpateOrderTerminalModal from "./EditModels/UpdateOrderTerminalModal";
import UpdateOrderTraderModal from "./EditModels/UpdateOrderTraderModal";
import UpdateOrderPortModal from "./EditModels/UpdateOrderPortModal";
import UdpateOrderProposedBargeModal from "./EditModels/UdpateOrderProposedBargeModal";
import UpdateOrderCFModal from "./EditModels/UpdateOrderCFModal";
import UpdateOrderProductModal from "./EditModels/UpdateOrderProductModal";
import UpdateOrderNominationQTYModal from "./EditModels/UpdateOrderNominationQTYModal";

const Search = Index.styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: Index.alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: Index.alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const StyledInputBase = Index.styled(Index.InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

const shortcutsItems = [
  {
    label: "This Week",
    getValue: () => {
      const today = dayjs();
      return [today.startOf("week"), today.endOf("week")];
    },
  },
  {
    label: "Last Week",
    getValue: () => {
      const today = dayjs();
      const prevWeek = today.subtract(7, "day");
      return [prevWeek.startOf("week"), prevWeek.endOf("week")];
    },
  },
  {
    label: "Last 7 Days",
    getValue: () => {
      const today = dayjs();
      return [today.subtract(7, "day"), today];
    },
  },
  {
    label: "Current Month",
    getValue: () => {
      const today = dayjs();
      return [today.startOf("month"), today.endOf("month")];
    },
  },
  {
    label: "Next Month",
    getValue: () => {
      const today = dayjs();
      const startOfNextMonth = today.endOf("month").add(1, "day");
      return [startOfNextMonth, startOfNextMonth.endOf("month")];
    },
  },
  { label: "Reset", getValue: () => [null, null] },
];

function SupplyOrder() {
  const navigate = Index.useNavigate();
  const dispatch = Index.useDispatch();
  const [updateOpen, setUpdateOpen] = useState(false);

  const [loading, setLoading] = useState(false);
  const [pdfName, setPdfName] = useState("");

  const [status, setStatus] = useState(true);
  const [orderCheckId, setOrderCheckId] = useState([]);
  const [openPdfModel, setOpnePdfmodel] = useState(false);
  const [isWharfedPdfUploaded, setIsWharfedPdfUploaded] = useState(false);
  const [openProformaPdfModel, setOpenProformaPdfModel] = useState(false);
  const [isParfomaPdfUploaded, setIsParfomaPdfUploaded] = useState(false);

  const [orderId, setOrderId] = useState(null);

  // State for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [paginationPerPage, setPaginationPerPage] = useState(50);
  const [searchingText, setSearchingText] = useState("");
  const [totalRecordCount, setTotalRecordCount] = useState(0);
  const [serachedDateRange, setSearchedDateRange] = useState("");
  const [currentOrderList, setCurrentOrderList] = useState([]);

  // when the update vessel name then used...
  const [openVesselEditPopUp, setOpenVesselEditPopUp] = useState("");
  const [editTimeVesselName, setEditTimeVesselName] = useState("");
  const [editTimeOrderId, setEditTimeOrderId] = useState("");

  // when the date should be update then used...
  const [openUpdateDatePopUp, setOpenUpdateDatePopUp] = useState(false);
  const [editTimeDateData, setEditTimeDateData] = useState({
    fieldName: "",
    fieldValue: "",
    fieldLabel: "",
    popUpTitle: "",
    fieldInitialValues: "",
    fieldPlaceholder: "",
  });

  // when the date and time should be update then used...
  const [openUpdateDateTimePopUp, setOpenUpdateDateTimePopUp] = useState(false);

  // when the single textfield should be update then used...
  const [openUpdateTextFieldPopUp, setOpenUpdateTextFieldPopUp] =
    useState(false);

  // when the open terminal pop up then used...
  const [openUpdateOrderTerminalPopUp, setOpenUpdateOrderTerminalPopUp] =
    useState(false);
  const [editTimeTerminalData, setEditTimeTerminalData] = useState("");

  // when the open trader pop up then used...
  const [openUpdateOrderTraderPopUp, setOpenUpdateOrderTraderPopUp] =
    useState(false);
  const [editTimeTraderData, setEditTimeTraderData] = useState("");

  // when the open port pop up then used...
  const [openUpdateOrderPortPopUp, setOpenUpdateOrderPortPopUp] =
    useState(false);
  const [editTimePortData, setEditTimePortData] = useState("");

  // when the open proposed Barge pop up then used...
  const [
    openUpdateOrderProposedBargePopUp,
    setOpenUpdateOrderProposedBargePopUp,
  ] = useState(false);
  const [editTimeProposedBargeData, setEditTimeProposedBargeData] =
    useState("");
  const [proposedBargeTimePortData, setProposedBargeTimePortData] =
    useState("");

  // when the open CF pop up then used...
  const [openUpdateOrderCFPopUp, setOpenUpdateOrderCFPopUp] = useState(false);
  const [editTimeCFData, setEditTimeCFData] = useState("");

  // when the open CF pop up then used...
  const [openUpdateOrderProductPopUp, setOpenUpdateOrderProductPopUp] =
    useState(false);
  const [editTimeProductData, setEditTimeProductData] = useState("");

  // when the open NominationQTY pop up then used...
  const [
    openUpdateOrderNominationQTYPopUp,
    setOpenUpdateOrderNominationQTYPopUp,
  ] = useState(false);
  const [editTimeNominationQTYData, setEditTimeNominationQTYData] =
    useState("");

  // when the single field update then used...
  const [isSingleFieldUpdate, setIsSingleFieldUpdate] = useState(false);

  const [openSingleUpdatePopUp, setOpenSingleUpdatePopUp] = useState(false);
  const [openSingleUpdateData, setOpenSingleUpdateData] = useState({
    fieldName: "",
    orderId: "",
    fieldPlaceholder: "",
    fieldType: "",
    initialValues: "",
    validationSchema: "",
  });

  // initialValues
  let initialValues = {
    wharfageFile: [],
  };

  // initialValues for proforma
  let initialValuesProforma = {
    nominationPdf: "",
  };

  // get order list
  const getOrderList = (values) => {
    setLoading(true);
    dispatch(getOrder(values)).then((res) => {
      if (res?.status === 200) {
        setLoading(false);
        setTotalRecordCount(res?.totalCount);
        setCurrentPage(res?.currentPage);
        setCurrentOrderList(res?.data);
      } else {
        setLoading(false);
        setTotalRecordCount(0);
        setCurrentPage(1);
        setCurrentOrderList([]);
      }
    });
  };

  useEffect(() => {
    const values = {
      page: 1,
      search: "",
      size: paginationPerPage,
      to:
        serachedDateRange?.length > 0
          ? serachedDateRange[0].format("YYYY-MM-DD")
          : "",
      from:
        serachedDateRange?.length > 0
          ? serachedDateRange[1].format("YYYY-MM-DD")
          : "",
    };
    getOrderList(values);
  }, []);

  // validationSchema for wharfageFile
  const validationSchema = Yup.object().shape({
    wharfageFile: Yup.array()
      .min(1, "Select at least one PDF file")
      .test("fileType", "Only PDF allowed", (value) => {
        // Check if each file has a valid image extension
        if (!value) return false;
        for (const file of value) {
          const validExtensions = ["pdf"]; // Add more valid extensions as needed
          const fileExtension = file.name?.split(".").pop().toLowerCase();
          if (!validExtensions.includes(fileExtension)) {
            return false;
          }
        }
        return true;
      }),
  });

  // validationSchema for proforma
  const validationSchemaProforma = Yup.object().shape({
    nominationPdf: Yup.mixed()
      .required("Please select PDF file")
      .test("FileType", "Only PDF allow", (value) => {
        if (!value) return true;
        if (!value?.name) {
          const file = value.split(".")[1];
          return ["pdf"].includes(file); //edit
        } else {
          return ["application/pdf"].includes(value?.type); //add
        }
      }),
  });

  const handlePageChange = React.useCallback(
    (event, value) => {
      setCurrentPage(value);
      const values = {
        page: value,
        search: searchingText,
        size: paginationPerPage,
        to:
          serachedDateRange?.length > 0
            ? serachedDateRange[0].format("YYYY-MM-DD")
            : "",
        from:
          serachedDateRange?.length > 0
            ? serachedDateRange[1].format("YYYY-MM-DD")
            : "",
      };
      getOrderList(values);
    },
    [searchingText, paginationPerPage, serachedDateRange, setCurrentPage]
  );

  // order view page
  const orderView = (id) => {
    window.open(`/admin/order/view-order/${id}`, "_blank");
  };

  const orderCheckPermission = (status, id, name) => {
    const data = new URLSearchParams();
    data.append("id", id);
    if (name === "wharfage") {
      data.append("wharfage", status);
    }
    if (name === "shippingBill") {
      data.append("shippingBill", status);
    }
    if (name === "harbourMaster") {
      data.append("harbourMaster", status);
    }

    dispatch(orderShippingBillBoxCategory(data, getOrder));
  };

  // get order log
  const getOrderLog = () => {
    dispatch(getInvoiceActivityLog(orderCheckId));
  };

  useEffect(() => {
    getOrderLog();
  }, []);

  // Single Order PDF Save
  const handleFormSubmit = (values) => {
    setIsWharfedPdfUploaded(true);
    const data = new FormData();
    values.wharfageFile.forEach((ele, i) => {
      data.append("wharfageFile", ele);
    });
    data.append("id", orderId);
    dispatch(addOrderPdfFile(data, navigate)).then((response) => {
      if (response) {
        setTimeout(() => {
          setIsWharfedPdfUploaded(false);

          const values = {
            page: 1,
            search: "",
            size: paginationPerPage,
            to:
              serachedDateRange?.length > 0
                ? serachedDateRange[0].format("YYYY-MM-DD")
                : "",
            from:
              serachedDateRange?.length > 0
                ? serachedDateRange[1].format("YYYY-MM-DD")
                : "",
          };
          getOrderList(values);
          handlePdfClose();
          dispatch(getNotifications(navigate));
        }, 2000);
      }
    });
  };

  // handleProformaFormSubmit

  const handleProformaFormSubmit = (values) => {
    setIsParfomaPdfUploaded(true);
    const data = new FormData();
    // values.nominationPdf.forEach((ele, i) => {
    //   data.append("nominationPdf", ele);
    // });
    if (values?.nominationPdf) {
      data.append("nominationPdf", values?.nominationPdf);
    }
    data.append("id", orderId);
    dispatch(addOrderProformaPdfFile(data, navigate)).then((response) => {
      if (response) {
        setIsParfomaPdfUploaded(false);
        const values = {
          page: 1,
          search: "",
          size: paginationPerPage,
          to:
            serachedDateRange?.length > 0
              ? serachedDateRange[0].format("YYYY-MM-DD")
              : "",
          from:
            serachedDateRange?.length > 0
              ? serachedDateRange[1].format("YYYY-MM-DD")
              : "",
        };
        getOrderList(values);
        proFormaHandlePdfClose();
        dispatch(getNotifications(navigate));
      }
    });
  };

  const handleClickPdfOpen = () => {
    setOpnePdfmodel(true);
  };
  const handlePdfClose = () => {
    setOpnePdfmodel(false);
  };

  // proforma
  const proFormaHandleClickPdfOpen = () => {
    setOpenProformaPdfModel(true);
  };
  const proFormaHandlePdfClose = () => {
    setOpenProformaPdfModel(false);
  };

  // cancel order popup
  const handleOpen = () => {
    // setAnchorEl(null);
    setUpdateOpen(true);
  };

  const handleClose = () => {
    setUpdateOpen(false);
    setStatus(true);
  };

  const orderMultipleStatusCancel = (status, comment) => {
    const data = new URLSearchParams();
    orderCheckId?.forEach((items) => {
      data.append("id[]", items);
    });
    data.append("orderStatus", "Cancel");
    data.append("comment", comment);
    dispatch(allCancelOrder(data)).then((res) => {
      if (res === 200) {
        setOrderCheckId([]);
        getOrderLog();
        handleClose();

        setSearchingText("");
        const values = {
          page: 1,
          search: "",
          size: paginationPerPage,
          to:
            serachedDateRange?.length > 0
              ? serachedDateRange[0].format("YYYY-MM-DD")
              : "",
          from:
            serachedDateRange?.length > 0
              ? serachedDateRange[1].format("YYYY-MM-DD")
              : "",
        };
        getOrderList(values);
      }
    });
  };

  const completedMultipleOrder = () => {
    const data = new URLSearchParams();
    orderCheckId?.forEach((items) => {
      data.append("id[]", items);
    });
    data.append("orderStatus", "Completed");
    dispatch(updateMarkAsCompleteOrder(data, navigate)).then((res) => {
      if (res === 200) {
        setOrderCheckId([]);
        getOrderLog();
        setSearchingText("");
        const values = {
          page: 1,
          search: "",
          size: paginationPerPage,
          to:
            serachedDateRange?.length > 0
              ? serachedDateRange[0].format("YYYY-MM-DD")
              : "",
          from:
            serachedDateRange?.length > 0
              ? serachedDateRange[1].format("YYYY-MM-DD")
              : "",
        };
        getOrderList(values);
      }
    });
  };

  // when the user search the journal records then used...
  const handleSearchJournalRecords = React.useCallback(
    (e) => {
      const { value } = e.target;
      setSearchingText(value);
      if (value?.length > 0) {
        const values = {
          page: 1,
          search: value,
          size: paginationPerPage,
          to:
            serachedDateRange?.length > 0
              ? serachedDateRange[0].format("YYYY-MM-DD")
              : "",
          from:
            serachedDateRange?.length > 0
              ? serachedDateRange[1].format("YYYY-MM-DD")
              : "",
        };
        getOrderList(values);
      } else {
        const values = {
          page: 1,
          search: "",
          size: paginationPerPage,
          to:
            serachedDateRange?.length > 0
              ? serachedDateRange[0].format("YYYY-MM-DD")
              : "",
          from:
            serachedDateRange?.length > 0
              ? serachedDateRange[1].format("YYYY-MM-DD")
              : "",
        };
        getOrderList(values);
      }
    },
    [paginationPerPage, serachedDateRange, setSearchingText]
  );

  // when the user change the per page record change then used..
  const handleChangePaginationPerChange = (e) => {
    const { value } = e.target;
    setPaginationPerPage(value);
    const values = {
      page: 1,
      search: searchingText,
      size: value,
      to:
        serachedDateRange?.length > 0
          ? serachedDateRange[0].format("YYYY-MM-DD")
          : "",
      from:
        serachedDateRange?.length > 0
          ? serachedDateRange[1].format("YYYY-MM-DD")
          : "",
    };
    getOrderList(values);
  };

  // when the use change the date then used...
  const handleChangeDate = React.useCallback(
    (data) => {
      setSearchedDateRange(data);
      if (data[0] !== null && data[1] !== null) {
        const values = {
          page: 1,
          search: searchingText,
          size: paginationPerPage,
          to: data[0] !== null ? data[0].format("YYYY-MM-DD") : "",
          from: data[1] !== null ? data[1].format("YYYY-MM-DD") : "",
        };
        getOrderList(values);
      } else if (data[0] === null && data[1] === null) {
        const values = {
          page: 1,
          search: "",
          size: paginationPerPage,
          to:
            serachedDateRange?.length > 0
              ? serachedDateRange[0].format("YYYY-MM-DD")
              : "",
          from:
            serachedDateRange?.length > 0
              ? serachedDateRange[1].format("YYYY-MM-DD")
              : "",
        };
        getOrderList(values);
      }
    },
    [setSearchedDateRange, searchingText, paginationPerPage]
  );

  // when the remove wharfate pdf then used...
  const handleRemoveWharfagePdf = (wharfageData) => {
    const values = {
      id: wharfageData?._id,
      file: wharfageData?.wharfageFile[0],
      wharfageFile: "wharfageFile",
    };
    // deleteCurrentOrderDocument
    dispatch(deleteCurrentOrderDocument(values)).then((res) => {
      if (res.status === 200) {
        const values = {
          page: 1,
          search: "",
          size: paginationPerPage,
          to:
            serachedDateRange?.length > 0
              ? serachedDateRange[0].format("YYYY-MM-DD")
              : "",
          from:
            serachedDateRange?.length > 0
              ? serachedDateRange[1].format("YYYY-MM-DD")
              : "",
        };
        getOrderList(values);
      }
    });
  };

  // when the remove nomination pdf then used...
  const handleRemoveNominationPdf = (nominationData) => {
    const values = {
      id: nominationData?._id,
      file: nominationData?.nominationPdf,
      nominationPdf: "nominationPdf",
    };
    // deleteOrderDocument
    dispatch(deleteCurrentOrderDocument(values)).then((res) => {
      if (res.status === 200) {
        const values = {
          page: 1,
          search: "",
          size: paginationPerPage,
          to:
            serachedDateRange?.length > 0
              ? serachedDateRange[0].format("YYYY-MM-DD")
              : "",
          from:
            serachedDateRange?.length > 0
              ? serachedDateRange[1].format("YYYY-MM-DD")
              : "",
        };
        getOrderList(values);
      }
    });
  };

  // when the user edit the vesselname then used...
  const handleOpenEditVesselNamePopUp = (orderData) => {
    setOpenVesselEditPopUp(true);
    setEditTimeOrderId(orderData?._id);
    setEditTimeVesselName(orderData?.vesselName);
  };

  const handleCloseEditVesselNamePopUp = () => {
    setOpenVesselEditPopUp(false);
    setEditTimeOrderId("");
    setEditTimeVesselName("");
  };

  // when the user update the vessel name then used...
  const handleUpdateVesselNameViaApi = (values) => {
    setIsSingleFieldUpdate(true);
    const data = new FormData();
    data.append("id", editTimeOrderId);
    if (values?.vesselName) {
      data.append("vesselName", values?.vesselName?.trim());
    }

    dispatch(handleGetUpdateSingleOrderField(data))
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          // reset fields
          handleCloseEditVesselNamePopUp();
          setIsSingleFieldUpdate(false);

          // here recall the current order api
          const values = {
            page: 1,
            search: "",
            size: paginationPerPage,
            to:
              serachedDateRange?.length > 0
                ? serachedDateRange[0].format("YYYY-MM-DD")
                : "",
            from:
              serachedDateRange?.length > 0
                ? serachedDateRange[1].format("YYYY-MM-DD")
                : "",
          };
          getOrderList(values);
        }
      })
      .catch((error) => {
        console.error(error);
        setIsSingleFieldUpdate(false);
      });
  };

  // when the user update the date field then used...
  const handleOpenDateEditModal = (orderData, editTimeData) => {
    setOpenUpdateDatePopUp(true);
    setEditTimeDateData({
      fieldName: editTimeData?.fieldName,
      fieldValue: editTimeData?.fieldValue,
      fieldLabel: editTimeData?.fieldLabel,
      popUpTitle: editTimeData?.popUpTitle,
      fieldInitialValues: editTimeData?.initialValues,
    });
    setEditTimeOrderId(orderData?._id);
  };

  // when the user close the edit date modal then used...
  const handleCloseDateEditModal = () => {
    setOpenUpdateDatePopUp(false);
    setEditTimeDateData({
      fieldName: "",
      fieldValue: "",
      fieldTitle: "",
      popUpTitle: "",
      fieldInitialValues: "",
      fieldPlaceholder: "",
    });
    setEditTimeOrderId("");
  };

  // when the user update the date data then used...
  const handleUpdateDateViaApi = (formValues, editTimeDetails) => {
    setIsSingleFieldUpdate(true);
    const data = new FormData();
    data.append("id", editTimeOrderId);
    if (formValues[editTimeDetails?.fieldName]) {
      data.append(
        editTimeDetails?.fieldName,
        formValues[editTimeDetails?.fieldName]
      );
      // data.append("fieldName", editTimeDetails?.fieldName);
      // data.append("fieldValue", formValues[editTimeDetails?.fieldName]);
    }

    dispatch(handleGetUpdateSingleOrderField(data))
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          // reset fields
          handleCloseDateEditModal();
          setIsSingleFieldUpdate(false);

          // here recall the current order api
          const values = {
            page: 1,
            search: "",
            size: paginationPerPage,
            to:
              serachedDateRange?.length > 0
                ? serachedDateRange[0].format("YYYY-MM-DD")
                : "",
            from:
              serachedDateRange?.length > 0
                ? serachedDateRange[1].format("YYYY-MM-DD")
                : "",
          };
          getOrderList(values);
        }
      })
      .catch((error) => {
        console.error(error);
        setIsSingleFieldUpdate(false);
      });
  };

  // when the user open update date and time modal then used...
  const handleOpenDateTimeEditModal = (orderData, editTimeData) => {
    setOpenUpdateDateTimePopUp(true);
    setEditTimeDateData({
      fieldName: editTimeData?.fieldName,
      fieldValue: editTimeData?.fieldValue,
      fieldLabel: editTimeData?.fieldLabel,
      popUpTitle: editTimeData?.popUpTitle,
      fieldInitialValues: editTimeData?.initialValues,
    });
    setEditTimeOrderId(orderData?._id);
  };

  // when the user close update date and time modal then used...
  const handleCloseDateTimeEditModal = () => {
    setOpenUpdateDateTimePopUp(false);
    setEditTimeDateData({
      fieldName: "",
      fieldValue: "",
      fieldTitle: "",
      popUpTitle: "",
      fieldInitialValues: "",
      fieldPlaceholder: "",
    });
    setEditTimeOrderId("");
  };

  // when the user update the date and time  data then used...
  const handleUpdateDateTimeViaApi = (formValues, editTimeDetails) => {
    setIsSingleFieldUpdate(true);

    const data = new FormData();
    data.append("id", editTimeOrderId);
    if (formValues[editTimeDetails?.fieldName]) {
      data.append(
        editTimeDetails?.fieldName,
        formValues[editTimeDetails?.fieldName]
      );
      // data.append("fieldName", editTimeDetails?.fieldName);
      // data.append("fieldValue", formValues[editTimeDetails?.fieldName]);
    }

    dispatch(handleGetUpdateSingleOrderField(data))
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          // reset fields
          handleCloseDateTimeEditModal();
          setIsSingleFieldUpdate(false);

          // here recall the current order api
          const values = {
            page: 1,
            search: "",
            size: paginationPerPage,
            to:
              serachedDateRange?.length > 0
                ? serachedDateRange[0].format("YYYY-MM-DD")
                : "",
            from:
              serachedDateRange?.length > 0
                ? serachedDateRange[1].format("YYYY-MM-DD")
                : "",
          };
          getOrderList(values);
        }
      })
      .catch((error) => {
        console.error(error);
        setIsSingleFieldUpdate(false);
      });
  };

  // when the user open  edit the single text field pop up then used...
  const handleOpenTextFieldEditModal = (orderData, editTimeData) => {
    setOpenUpdateTextFieldPopUp(true);
    setEditTimeDateData({
      fieldName: editTimeData?.fieldName,
      fieldValue: editTimeData?.fieldValue,
      fieldLabel: editTimeData?.fieldLabel,
      popUpTitle: editTimeData?.popUpTitle,
      fieldInitialValues: editTimeData?.initialValues,
      fieldPlaceholder: editTimeData?.fieldPlaceholder,
    });
    setEditTimeOrderId(orderData?._id);
  };

  // when the user close  edit the single text field pop up then used...
  const handleCloseTextFieldEditModal = () => {
    setOpenUpdateTextFieldPopUp(false);
    setEditTimeDateData({
      fieldName: "",
      fieldValue: "",
      fieldTitle: "",
      popUpTitle: "",
      fieldInitialValues: "",
      fieldPlaceholder: "",
    });
    setEditTimeOrderId("");
  };

  // when the user update the date and time  data then used...
  const handleUpdateTextFieldViaApi = (formValues, editTimeDetails) => {
    setIsSingleFieldUpdate(true);

    const data = new FormData();
    data.append("id", editTimeOrderId);

    if (formValues[editTimeDetails?.fieldName]) {
      data.append(
        editTimeDetails?.fieldName,
        formValues[editTimeDetails?.fieldName]
      );
    }

    dispatch(handleGetUpdateSingleOrderField(data))
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          // reset fields
          handleCloseTextFieldEditModal();
          setIsSingleFieldUpdate(false);

          // here recall the current order api
          const values = {
            page: 1,
            search: "",
            size: paginationPerPage,
            to:
              serachedDateRange?.length > 0
                ? serachedDateRange[0].format("YYYY-MM-DD")
                : "",
            from:
              serachedDateRange?.length > 0
                ? serachedDateRange[1].format("YYYY-MM-DD")
                : "",
          };
          getOrderList(values);
        }
      })
      .catch((error) => {
        console.error(error);
        setIsSingleFieldUpdate(false);
      });
  };

  // when the user open the update terminal modal then used...
  const handleOpenUpdateOrderTerminalModal = (orderData) => {
    setOpenUpdateOrderTerminalPopUp(true);
    setEditTimeTerminalData(orderData?.terminal);
    setEditTimeOrderId(orderData?._id);
  };

  // when the user close the update terminal modal then used...
  const handleCloseUpdateOrderTerminalModal = () => {
    setOpenUpdateOrderTerminalPopUp(false);
    setEditTimeTerminalData("");
    setEditTimeOrderId("");
  };

  // when the user update the terminal then used...
  const handleUpdateOrderTerminalViaApi = (formValues) => {
    setIsSingleFieldUpdate(true);

    const data = new FormData();
    data.append("id", editTimeOrderId);

    if (formValues?.terminal) {
      data.append("terminal", formValues?.terminal);
    }

    dispatch(handleGetUpdateSingleOrderField(data))
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          // reset fields
          handleCloseUpdateOrderTerminalModal();
          setIsSingleFieldUpdate(false);

          // here recall the current order api
          const values = {
            page: 1,
            search: "",
            size: paginationPerPage,
            to:
              serachedDateRange?.length > 0
                ? serachedDateRange[0].format("YYYY-MM-DD")
                : "",
            from:
              serachedDateRange?.length > 0
                ? serachedDateRange[1].format("YYYY-MM-DD")
                : "",
          };
          getOrderList(values);
        }
      })
      .catch((error) => {
        console.error(error);
        setIsSingleFieldUpdate(false);
      });
  };

  // when the user open the update trader modal then used...
  const handleOpenUpdateOrderTraderModal = (orderData) => {
    setOpenUpdateOrderTraderPopUp(true);
    setEditTimeTraderData(orderData?.traderId?._id);
    setEditTimeOrderId(orderData?._id);
  };

  // when the user close the update trader modal then used...
  const handleCloseUpdateOrderTraderModal = () => {
    setOpenUpdateOrderTraderPopUp(false);
    setEditTimeTraderData("");
    setEditTimeOrderId("");
  };

  // when the user update the trader then used...
  const handleUpdateOrderTraderViaApi = (formValues) => {
    setIsSingleFieldUpdate(true);

    const data = new FormData();
    data.append("id", editTimeOrderId);

    if (formValues?.traderId) {
      data.append("traderId", formValues?.traderId);
    }

    dispatch(handleGetUpdateSingleOrderField(data))
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          // reset fields
          handleCloseUpdateOrderTraderModal();
          setIsSingleFieldUpdate(false);

          // here recall the current order api
          const values = {
            page: 1,
            search: "",
            size: paginationPerPage,
            to:
              serachedDateRange?.length > 0
                ? serachedDateRange[0].format("YYYY-MM-DD")
                : "",
            from:
              serachedDateRange?.length > 0
                ? serachedDateRange[1].format("YYYY-MM-DD")
                : "",
          };
          getOrderList(values);
        }
      })
      .catch((error) => {
        console.error(error);
        setIsSingleFieldUpdate(false);
      });
  };

  // when the user open the update port modal then used...
  const handleOpenUpdateOrderPortModal = (orderData) => {
    setOpenUpdateOrderPortPopUp(true);
    setEditTimePortData(orderData?.port?._id);
    setEditTimeOrderId(orderData?._id);
  };

  // when the user close the update port modal then used...
  const handleCloseUpdateOrderPortModal = () => {
    setOpenUpdateOrderPortPopUp(false);
    setEditTimePortData("");
    setEditTimeOrderId("");
  };

  // when the user update the port then used...
  const handleUpdateOrderPortViaApi = (formValues) => {
    setIsSingleFieldUpdate(true);

    const data = new FormData();
    data.append("id", editTimeOrderId);

    if (formValues?.port) {
      data.append("port", formValues?.port);
    }

    dispatch(handleGetUpdateSingleOrderField(data))
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          // reset fields
          handleCloseUpdateOrderPortModal();
          setIsSingleFieldUpdate(false);

          // here recall the current order api
          const values = {
            page: 1,
            search: "",
            size: paginationPerPage,
            to:
              serachedDateRange?.length > 0
                ? serachedDateRange[0].format("YYYY-MM-DD")
                : "",
            from:
              serachedDateRange?.length > 0
                ? serachedDateRange[1].format("YYYY-MM-DD")
                : "",
          };
          getOrderList(values);
        }
      })
      .catch((error) => {
        console.error(error);
        setIsSingleFieldUpdate(false);
      });
  };

  // when the user open the update proposed barge modal then used...
  const handleOpenUpdateOrderProposedBargeModal = (orderData) => {
    setOpenUpdateOrderProposedBargePopUp(true);
    setEditTimeOrderId(orderData?._id);
    setEditTimeProposedBargeData(orderData?.proposedBarge);
    setProposedBargeTimePortData(orderData?.port?._id);
  };

  // when the user close the update port modal then used...
  const handleCloseUpdateOrderProposedBargeModal = () => {
    setOpenUpdateOrderProposedBargePopUp(false);
    setEditTimePortData("");
    setEditTimeOrderId("");
    setProposedBargeTimePortData("");
  };

  // when the user update the port then used...
  const handleUpdateOrderProposedBargeViaApi = (formValues) => {
    setIsSingleFieldUpdate(true);

    const data = new FormData();
    data.append("id", editTimeOrderId);

    if (formValues?.proposedBarge) {
      data.append("proposedBarge", formValues?.proposedBarge);
    }

    dispatch(handleGetUpdateSingleOrderField(data))
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          // reset fields
          handleCloseUpdateOrderProposedBargeModal();
          setIsSingleFieldUpdate(false);

          // here recall the current order api
          const values = {
            page: 1,
            search: "",
            size: paginationPerPage,
            to:
              serachedDateRange?.length > 0
                ? serachedDateRange[0].format("YYYY-MM-DD")
                : "",
            from:
              serachedDateRange?.length > 0
                ? serachedDateRange[1].format("YYYY-MM-DD")
                : "",
          };
          getOrderList(values);
        }
      })
      .catch((error) => {
        console.error(error);
        setIsSingleFieldUpdate(false);
      });
  };

  // when the user open the CF  modal then used...
  const handleOpenUpdateOrderCFModal = (orderData) => {
    setOpenUpdateOrderCFPopUp(true);
    setEditTimeOrderId(orderData?._id);
    setEditTimeCFData(orderData?.foreign);
  };

  // when the user close the update CF modal then used...
  const handleCloseUpdateOrderCFModal = () => {
    setOpenUpdateOrderCFPopUp(false);
    setEditTimeOrderId("");
    setEditTimeCFData("");
  };

  // when the user update the CF then used...
  const handleUpdateOrderCFViaApi = (formValues) => {
    setIsSingleFieldUpdate(true);

    const data = new FormData();
    data.append("id", editTimeOrderId);

    if (formValues?.foreign) {
      data.append("foreign", formValues?.foreign);
    }

    dispatch(handleGetUpdateSingleOrderField(data))
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          // reset fields
          handleCloseUpdateOrderCFModal();
          setIsSingleFieldUpdate(false);

          // here recall the current order api
          const values = {
            page: 1,
            search: "",
            size: paginationPerPage,
            to:
              serachedDateRange?.length > 0
                ? serachedDateRange[0].format("YYYY-MM-DD")
                : "",
            from:
              serachedDateRange?.length > 0
                ? serachedDateRange[1].format("YYYY-MM-DD")
                : "",
          };
          getOrderList(values);
        }
      })
      .catch((error) => {
        console.error(error);
        setIsSingleFieldUpdate(false);
      });
  };

  // when the user open the Product  modal then used...
  const handleOpenUpdateOrderProductModal = (orderData) => {
    setOpenUpdateOrderProductPopUp(true);
    setEditTimeOrderId(orderData?._id);
    setEditTimeProductData(orderData?.product);
  };

  // when the user close the update Product modal then used...
  const handleCloseUpdateOrderProductModal = () => {
    setOpenUpdateOrderProductPopUp(false);
    setEditTimeOrderId("");
    setEditTimeProductData("");
  };

  // when the user update the Product then used...
  const handleUpdateOrderProductViaApi = (formValues) => {
    setIsSingleFieldUpdate(true);

    const data = new FormData();
    data.append("id", editTimeOrderId);

    if (formValues?.product) {
      data.append("product", formValues?.product);
    }

    dispatch(handleGetUpdateSingleOrderField(data))
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          // reset fields
          handleCloseUpdateOrderProductModal();
          setIsSingleFieldUpdate(false);

          // here recall the current order api
          const values = {
            page: 1,
            search: "",
            size: paginationPerPage,
            to:
              serachedDateRange?.length > 0
                ? serachedDateRange[0].format("YYYY-MM-DD")
                : "",
            from:
              serachedDateRange?.length > 0
                ? serachedDateRange[1].format("YYYY-MM-DD")
                : "",
          };
          getOrderList(values);
        }
      })
      .catch((error) => {
        console.error(error);
        setIsSingleFieldUpdate(false);
      });
  };

  // when the user open the NominationQTY  modal then used...
  const handleOpenUpdateOrderNominationQTYModal = (orderData) => {
    setOpenUpdateOrderNominationQTYPopUp(true);
    setEditTimeOrderId(orderData?._id);
    setEditTimeNominationQTYData(orderData?.lowerQuantity);
  };

  // when the user close the update NominationQTY modal then used...
  const handleCloseUpdateOrderNominationQTYModal = () => {
    setOpenUpdateOrderNominationQTYPopUp(false);
    setEditTimeOrderId("");
    setEditTimeNominationQTYData("");
  };

  // when the user update the NominationQTY then used...
  const handleUpdateOrderNominationQTYViaApi = (formValues) => {
    const data = new FormData();
    data.append("id", editTimeOrderId);
    setIsSingleFieldUpdate(true);

    if (formValues?.nominatedQty) {
      data.append("nominatedQty", formValues?.nominatedQty);
    }

    dispatch(handleGetUpdateSingleOrderField(data))
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          // reset fields
          handleCloseUpdateOrderNominationQTYModal();
          setIsSingleFieldUpdate(false);

          // here recall the current order api
          const values = {
            page: 1,
            search: "",
            size: paginationPerPage,
            to:
              serachedDateRange?.length > 0
                ? serachedDateRange[0].format("YYYY-MM-DD")
                : "",
            from:
              serachedDateRange?.length > 0
                ? serachedDateRange[1].format("YYYY-MM-DD")
                : "",
          };
          getOrderList(values);
        }
      })
      .catch((error) => {
        console.error(error);
        setIsSingleFieldUpdate(false);
      });
  };

  return (
    <>
      <Index.DynamicTitle title="Current Orders" />
      <Index.Box className="">
        <Index.Box className="barge-common-box">
          <Index.Box className="title-header">
            <Index.Box
              className="title-header-flex res-title-header-flex"
              sx={{
                alignItems: "center",
              }}
            >
              <Index.Box className="title-main" sx={{ width: "25%" }}>
                <Index.Typography
                  variant="p"
                  component="p"
                  className="page-title"
                >
                  Current Orders
                </Index.Typography>
              </Index.Box>
              <Index.Box
                className="d-flex  align-items-center res-set-search"
                sx={{
                  width: "75%",
                  justifyContent: "flex-end",
                  flexWrap: "wrap",
                }}
              >
                <Index.Box className="input-design-div">
                  <Index.Box className="set-date set-date-picker remove-name">
                    <Index.LocalizationProvider
                      dateAdapter={Index.AdapterDayjs}
                    >
                      <Index.DemoContainer
                        components={["SingleInputDateRangeField"]}
                        slotProps={{ textField: { size: "small" } }}
                      >
                        <DateRangePicker
                          slots={{ field: SingleInputDateRangeField }}
                          slotProps={{
                            shortcuts: {
                              items: shortcutsItems,
                            },
                            textField: {
                              readOnly: true,
                              size: "small",
                            },
                            actionBar: { actions: [] },
                          }}
                          onChange={(value) => handleChangeDate(value)}
                          name="allowedRange"
                          format="DD/MM/YYYY"
                        />
                      </Index.DemoContainer>
                    </Index.LocalizationProvider>
                  </Index.Box>
                </Index.Box>
                <Search className="search ">
                  <StyledInputBase
                    placeholder="Search"
                    // key={searchingText}
                    inputProps={{ "aria-label": "search" }}
                    value={searchingText}
                    onChange={(e) => handleSearchJournalRecords(e)}
                  />
                </Search>

                <Index.Box className="common-button grey-button">
                  <Index.Button
                    variant="contained"
                    // disabled={exportDetails?.length ? false : true}
                    onClick={() => {
                      let curDate = Index.moment(new Date()).format(
                        "DD-MM-YYYY"
                      );
                      NEW_EXPORT_EXCEL(
                        "/admin/export-current-order",
                        `Current_Order_List_${curDate}`,
                        {
                          search: searchingText,
                          to:
                            serachedDateRange?.length > 0
                              ? serachedDateRange[0].format("YYYY-MM-DD")
                              : "",
                          from:
                            serachedDateRange?.length > 0
                              ? serachedDateRange[1].format("YYYY-MM-DD")
                              : "",
                        }
                      );
                    }}
                  >
                    Export
                  </Index.Button>
                </Index.Box>
                <Index.Box className="common-button blue-button res-blue-button">
                  <Index.RouteLink
                    className="no-text-decoration"
                    to="/admin/order/add"
                  >
                    <Index.Button variant="contained" disableRipple>
                      Add order
                    </Index.Button>
                  </Index.RouteLink>
                </Index.Box>

                <Index.Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Index.Box className="common-button blue-button res-blue-button">
                    {" "}
                    {currentOrderList?.length && orderCheckId?.length ? (
                      <Index.Button
                        variant="contained"
                        disableRipple
                        onClick={(e) => {
                          handleOpen();
                          setStatus(false);
                        }}
                      >
                        Order Completed
                      </Index.Button>
                    ) : (
                      ""
                    )}
                  </Index.Box>
                  <Index.Box className="common-button blue-button res-blue-button">
                    {" "}
                    {currentOrderList?.length && orderCheckId?.length ? (
                      <Index.Button
                        variant="contained"
                        disableRipple
                        onClick={(e) => {
                          handleOpen();
                        }}
                      >
                        Cancel Selected Order
                      </Index.Button>
                    ) : (
                      ""
                    )}
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Index.Box className="">
            <Index.TableContainer
              component={Index.Paper}
              className="table-container table-max-width"
            >
              <Index.Table
                stickyHeader
                aria-label="sticky table"
                className="table-design-main supply-table one-line-table invoice-table-set "
              >
                <Index.TableHead>
                  <Index.TableRow>
                    <Index.TableCell>No</Index.TableCell>
                    <Index.TableCell></Index.TableCell>
                    <Index.TableCell></Index.TableCell>
                    <Index.TableCell align="left">Status</Index.TableCell>

                    <Index.TableCell align="left">Vessel Name</Index.TableCell>
                    <Index.TableCell align="left">Terminal</Index.TableCell>
                    <Index.TableCell align="left">Trader Name</Index.TableCell>
                    <Index.TableCell align="left">Port</Index.TableCell>
                    <Index.TableCell align="left">
                      Proposed Barge
                    </Index.TableCell>
                    <Index.TableCell align="left">Agent</Index.TableCell>
                    <Index.TableCell align="left">C/F</Index.TableCell>
                    <Index.TableCell align="left">Product Name</Index.TableCell>
                    <Index.TableCell align="left">
                      Nominated Qty (In MT)
                    </Index.TableCell>
                    <Index.TableCell align="left">
                      Loading Planned Date
                    </Index.TableCell>
                    <Index.TableCell align="left">
                      ETA for Vessel
                    </Index.TableCell>
                    <Index.TableCell align="left">
                      Basis Taken On
                    </Index.TableCell>
                    <Index.TableCell align="left">Wharfage PDF</Index.TableCell>
                    <Index.TableCell align="left">
                      Proforma Invoice PDF
                    </Index.TableCell>
                    <Index.TableCell align="left">Remark</Index.TableCell>
                  </Index.TableRow>
                </Index.TableHead>
                {loading ? (
                  <Index.TableBody>
                    <Index.TableRow>
                      <Index.TableCell colSpan={35} align="center">
                        <Index.CircularProgress color="secondary" size={20} />
                      </Index.TableCell>
                    </Index.TableRow>
                  </Index.TableBody>
                ) : (
                  <Index.TableBody className="tanker-table-body">
                    {currentOrderList?.length ? (
                      currentOrderList?.map((item, index) => (
                        <Index.TableRow
                          key={item?._id + (index + 1)}
                          // onClick={() => {
                          //   orderView(item?._id);
                          // }}
                          className={
                            item?.bunkerCall === true ? "tanker-row-color" : ""
                          }
                          sx={{ cursor: "pointer" }}
                        >
                          <Index.TableCell>
                            {paginationPerPage * (currentPage - 1) +
                              (index + 1)}
                          </Index.TableCell>

                          <Index.TableCell>
                            <Index.FormControlLabel
                              control={
                                <Index.Checkbox
                                  onChange={(event) => {
                                    if (event.target.checked === true) {
                                      setOrderCheckId([
                                        ...orderCheckId,
                                        item?._id,
                                      ]);
                                    } else {
                                      setOrderCheckId(
                                        orderCheckId?.filter((ele) => {
                                          return ele !== item?._id;
                                        })
                                      );
                                    }
                                  }}
                                />
                              }
                              // label="Shipping Bill"
                            />
                          </Index.TableCell>
                          <Index.TableCell>
                            {" "}
                            <Index.Box className="challan-icon-align">
                              <Index.Box className="d-flex">
                                {item?.challanAvailable === false ? (
                                  <Index.ReceiptLongIcon
                                    fontSize="small"
                                    sx={{ color: "black" }}
                                    className="challan-icon icon challan-icon-hidden"
                                  />
                                ) : item?.challanStatus === "" ? (
                                  <Index.ReceiptLongIcon
                                    fontSize="small"
                                    sx={{ color: "black" }}
                                    className="challan-icon"
                                  />
                                ) : item?.challanStatus === "Approve" ? (
                                  <Index.ReceiptLongIcon
                                    fontSize="small"
                                    color="success"
                                    className="challan-icon"
                                  />
                                ) : (
                                  <Index.ReceiptLongIcon
                                    fontSize="small"
                                    color="error"
                                    className="challan-icon"
                                  />
                                )}
                              </Index.Box>
                            </Index.Box>
                          </Index.TableCell>
                          <Index.TableCell
                            align="left"
                            onClick={() => {
                              orderView(item?._id);
                            }}
                          >
                            {item?.orderStatusForAdmin ? (
                              <Index.Typography
                                className={` common-status-details ${
                                  item?.orderStatusForAdmin === "Under Loading"
                                    ? `blue-status`
                                    : item?.orderStatusForAdmin === "Loaded"
                                    ? `green-status`
                                    : ""
                                }`}
                              >
                                {item?.orderStatusForAdmin}
                              </Index.Typography>
                            ) : (
                              "-"
                            )}
                          </Index.TableCell>
                          <Index.TableCell align="left">
                            <Index.Box className="nm-tag">
                              {item?.hasOwnProperty("challan") &&
                              item?.challan?.length ? (
                                item?.challan.some(
                                  (item) =>
                                    item.distance !== undefined &&
                                    item.distance > 4
                                ) ? (
                                  <span className="nm-tag-span">NM</span>
                                ) : (
                                  <></>
                                )
                              ) : (
                                <></>
                              )}
                            </Index.Box>

                            <Index.Box className="single-edit-box">
                              <Index.Box
                                className="single-edit-text"
                                onClick={() => {
                                  orderView(item?._id);
                                }}
                              >
                                {item?.vesselName ? item?.vesselName : "-"}
                              </Index.Box>
                              <Index.Box className="single-edit-btn-box">
                                <Index.Button
                                  className="seperate-edit-icon"
                                  type="button"
                                  onClick={() =>
                                    handleOpenEditVesselNamePopUp(item)
                                  }
                                >
                                  <Index.EditIcon className="edit-btn-icon" />
                                </Index.Button>
                              </Index.Box>
                            </Index.Box>
                          </Index.TableCell>
                          <Index.TableCell
                            align="left"
                            // onClick={() => {
                            //   orderView(item?._id);
                            // }}
                          >
                            <Index.Box className="single-edit-box">
                              <Index.Box
                                className="single-edit-text"
                                onClick={() => {
                                  orderView(item?._id);
                                }}
                              >
                                {item?.terminal ? item?.terminal : "-"}
                              </Index.Box>
                              <Index.Box className="single-edit-btn-box">
                                <Index.Button
                                  className="seperate-edit-icon"
                                  type="button"
                                  onClick={() =>
                                    handleOpenUpdateOrderTerminalModal(item)
                                  }
                                >
                                  <Index.EditIcon className="edit-btn-icon" />
                                </Index.Button>
                              </Index.Box>
                            </Index.Box>
                          </Index.TableCell>
                          <Index.TableCell
                            align="left"
                            // onClick={() => {
                            //   orderView(item?._id);
                            // }}
                          >
                            <Index.Box className="single-edit-box">
                              <Index.Box
                                className="single-edit-text"
                                onClick={() => {
                                  orderView(item?._id);
                                }}
                              >
                                {item?.trader
                                  ? item?.trader
                                  : item?.traderId?.traderName
                                  ? item?.traderId?.traderName
                                  : "-"}
                              </Index.Box>
                              <Index.Box className="single-edit-btn-box">
                                <Index.Button
                                  className="seperate-edit-icon"
                                  type="button"
                                  onClick={() =>
                                    handleOpenUpdateOrderTraderModal(item)
                                  }
                                >
                                  <Index.EditIcon className="edit-btn-icon" />
                                </Index.Button>
                              </Index.Box>
                            </Index.Box>
                          </Index.TableCell>
                          <Index.TableCell
                            align="left"
                            // onClick={() => {
                            //   orderView(item?._id);
                            // }}
                          >
                            <Index.Box className="single-edit-box">
                              <Index.Box
                                className="single-edit-text"
                                onClick={() => {
                                  orderView(item?._id);
                                }}
                              >
                                {item?.port?.portName
                                  ? item?.port?.portName
                                  : "-"}
                              </Index.Box>
                              <Index.Box className="single-edit-btn-box">
                                <Index.Button
                                  className="seperate-edit-icon"
                                  type="button"
                                  onClick={() =>
                                    handleOpenUpdateOrderPortModal(item)
                                  }
                                >
                                  <Index.EditIcon className="edit-btn-icon" />
                                </Index.Button>
                              </Index.Box>
                            </Index.Box>
                          </Index.TableCell>
                          <Index.TableCell
                            align="left"
                            // onClick={() => {
                            //   orderView(item?._id);
                            // }}
                          >
                            <Index.Box className="single-edit-box">
                              <Index.Box
                                className="single-edit-text"
                                onClick={() => {
                                  orderView(item?._id);
                                }}
                              >
                                {item?.proposedBarge?.length
                                  ? item?.proposedBarge
                                    ? Array.prototype.map
                                        .call(
                                          item?.proposedBarge,
                                          function (ele) {
                                            return ele.bargeName;
                                          }
                                        )
                                        .join(",")
                                    : ""
                                  : "-"}
                              </Index.Box>
                              <Index.Box className="single-edit-btn-box">
                                <Index.Button
                                  className="seperate-edit-icon"
                                  type="button"
                                  onClick={() =>
                                    handleOpenUpdateOrderProposedBargeModal(
                                      item
                                    )
                                  }
                                >
                                  <Index.EditIcon className="edit-btn-icon" />
                                </Index.Button>
                              </Index.Box>
                            </Index.Box>
                          </Index.TableCell>
                          <Index.TableCell
                            align="left"
                            // onClick={() => {
                            //   orderView(item?._id);
                            // }}
                          >
                            <Index.Box className="single-edit-box">
                              <Index.Box
                                className="single-edit-text"
                                onClick={() => {
                                  orderView(item?._id);
                                }}
                              >
                                {item?.agent ? item?.agent : "-"}
                              </Index.Box>
                              <Index.Box className="single-edit-btn-box">
                                <Index.Button
                                  className="seperate-edit-icon"
                                  type="button"
                                  onClick={() =>
                                    handleOpenTextFieldEditModal(item, {
                                      fieldName: "agent",
                                      fieldValue: item?.agent,
                                      fieldLabel: "Agent",
                                      popUpTitle: "Edit Agent",
                                      fieldPlaceholder: "Agent",
                                      initialValues: {
                                        agent: item?.agent,
                                      },
                                    })
                                  }
                                >
                                  <Index.EditIcon className="edit-btn-icon" />
                                </Index.Button>
                              </Index.Box>
                            </Index.Box>
                          </Index.TableCell>
                          <Index.TableCell
                            align="left"
                            // onClick={() => {
                            //   orderView(item?._id);
                            // }}
                          >
                            <Index.Box className="single-edit-box">
                              <Index.Box
                                className="single-edit-text"
                                onClick={() => {
                                  orderView(item?._id);
                                }}
                              >
                                {item?.foreign ? item?.foreign : "-"}
                              </Index.Box>
                              <Index.Box className="single-edit-btn-box">
                                <Index.Button
                                  className="seperate-edit-icon"
                                  type="button"
                                  onClick={() =>
                                    handleOpenUpdateOrderCFModal(item)
                                  }
                                >
                                  <Index.EditIcon className="edit-btn-icon" />
                                </Index.Button>
                              </Index.Box>
                            </Index.Box>
                          </Index.TableCell>
                          <Index.TableCell
                            align="left"
                            // onClick={() => {
                            //   orderView(item?._id);
                            // }}
                          >
                            <Index.Box className="single-edit-box">
                              <Index.Box
                                className="single-edit-text"
                                onClick={() => {
                                  orderView(item?._id);
                                }}
                              >
                                {item?.product ? item?.product : "-"}
                              </Index.Box>
                              <Index.Box className="single-edit-btn-box">
                                <Index.Button
                                  className="seperate-edit-icon"
                                  type="button"
                                  onClick={() =>
                                    handleOpenUpdateOrderProductModal(item)
                                  }
                                >
                                  <Index.EditIcon className="edit-btn-icon" />
                                </Index.Button>
                              </Index.Box>
                            </Index.Box>
                          </Index.TableCell>
                          <Index.TableCell
                            align="left"
                            // onClick={() => {
                            //   orderView(item?._id);
                            // }}
                          >
                            <Index.Box className="single-edit-box">
                              <Index.Box
                                className="single-edit-text"
                                onClick={() => {
                                  orderView(item?._id);
                                }}
                              >
                                {item?.lowerQuantity === "0" &&
                                item?.higherQuantity === "0"
                                  ? "-"
                                  : item?.lowerQuantity &&
                                    item?.higherQuantity != "0"
                                  ? item?.lowerQuantity +
                                    " - " +
                                    item?.higherQuantity
                                  : item?.lowerQuantity}
                              </Index.Box>
                              <Index.Box className="single-edit-btn-box">
                                <Index.Button
                                  className="seperate-edit-icon"
                                  type="button"
                                  onClick={() =>
                                    handleOpenUpdateOrderNominationQTYModal(
                                      item
                                    )
                                  }
                                >
                                  <Index.EditIcon className="edit-btn-icon" />
                                </Index.Button>
                              </Index.Box>
                            </Index.Box>
                          </Index.TableCell>
                          <Index.TableCell
                            align="left"
                            // onClick={() => {
                            //   orderView(item?._id);
                            // }}
                          >
                            <Index.Box className="single-edit-box">
                              <Index.Box
                                className="single-edit-text"
                                onClick={() => {
                                  orderView(item?._id);
                                }}
                              >
                                {item?.loadingDate
                                  ? Index.moment(item?.loadingDate).format(
                                      "DD/MM/YYYY"
                                    )
                                  : "-"}
                              </Index.Box>
                              <Index.Box className="single-edit-btn-box">
                                <Index.Button
                                  className="seperate-edit-icon"
                                  type="button"
                                  onClick={() =>
                                    handleOpenDateEditModal(item, {
                                      fieldName: "loadingDate",
                                      fieldValue: item?.loadingDate,
                                      fieldLabel: "Loading Planned Date",
                                      popUpTitle: "Edit Loading Planned Date",
                                      initialValues: {
                                        loadingDate: item?.loadingDate,
                                      },
                                    })
                                  }
                                >
                                  <Index.EditIcon className="edit-btn-icon" />
                                </Index.Button>
                              </Index.Box>
                            </Index.Box>
                          </Index.TableCell>
                          <Index.TableCell
                            align="left"
                            // onClick={() => {
                            //   orderView(item?._id);
                            // }}
                          >
                            <Index.Box className="single-edit-box">
                              <Index.Box
                                className="single-edit-text"
                                onClick={() => {
                                  orderView(item?._id);
                                }}
                              >
                                {item?.laycam
                                  ? Index.moment(item?.laycam).format(
                                      "DD/MM/YYYY HH:mm"
                                    )
                                  : "-"}
                              </Index.Box>
                              <Index.Box className="single-edit-btn-box">
                                <Index.Button
                                  className="seperate-edit-icon"
                                  type="button"
                                  onClick={() =>
                                    handleOpenDateTimeEditModal(item, {
                                      fieldName: "laycam",
                                      fieldValue: item?.laycam,
                                      fieldLabel: "ETA for Vessel",
                                      popUpTitle: "Edit ETA for Vessel",
                                      initialValues: {
                                        laycam: item?.laycam,
                                      },
                                    })
                                  }
                                >
                                  <Index.EditIcon className="edit-btn-icon" />
                                </Index.Button>
                              </Index.Box>
                            </Index.Box>
                          </Index.TableCell>
                          <Index.TableCell
                            align="left"
                            // onClick={() => {
                            //   orderView(item?._id);
                            // }}
                          >
                            <Index.Box className="single-edit-box">
                              <Index.Box
                                className="single-edit-text"
                                onClick={() => {
                                  orderView(item?._id);
                                }}
                              >
                                {item?.basisTakenOn
                                  ? Index.moment(item?.basisTakenOn).format(
                                      "DD/MM/YYYY"
                                    )
                                  : "-"}
                              </Index.Box>
                              <Index.Box className="single-edit-btn-box">
                                <Index.Button
                                  className="seperate-edit-icon"
                                  type="button"
                                  onClick={() =>
                                    handleOpenDateEditModal(item, {
                                      fieldName: "basisTakenOn",
                                      fieldValue: item?.basisTakenOn,
                                      fieldLabel: "Basis Taken On",
                                      popUpTitle: "Edit Basis Taken On",
                                      initialValues: {
                                        basisTakenOn: item?.basisTakenOn,
                                      },
                                    })
                                  }
                                >
                                  <Index.EditIcon className="edit-btn-icon" />
                                </Index.Button>
                              </Index.Box>
                            </Index.Box>
                          </Index.TableCell>
                          <Index.TableCell
                            className="common-button blue-button save-btn res-button-width"
                            align="left"
                          >
                            {item?.wharfageFile?.length ? (
                              <>
                                {item?.wharfageFile?.map((el, index) => (
                                  <div className="order-pdf-list-view">
                                    <strong>&#8594;</strong>
                                    {el}
                                    <Index.DeleteIcon
                                      color="error"
                                      fontSize="small"
                                      onClick={() =>
                                        handleRemoveWharfagePdf(item)
                                      }
                                    />
                                  </div>
                                ))}
                              </>
                            ) : (
                              <Index.Button
                                variant="outlined"
                                onClick={() => {
                                  setOrderId(null); // clear older state
                                  setOrderId(item?._id); // update state with order id
                                  handleClickPdfOpen();
                                }}
                                className="view-invoice-btn"
                              >
                                Upload File
                              </Index.Button>
                            )}
                          </Index.TableCell>

                          <Index.TableCell
                            className="common-button blue-button save-btn res-button-width"
                            align="left"
                          >
                            {item?.nominationPdf ? (
                              <div className="order-pdf-list-view">
                                <strong>&#8594;</strong>
                                {item?.nominationPdf}

                                <Index.DeleteIcon
                                  color="error"
                                  fontSize="small"
                                  onClick={() =>
                                    handleRemoveNominationPdf(item)
                                  }
                                />
                              </div>
                            ) : (
                              <Index.Button
                                variant="outlined"
                                onClick={() => {
                                  setOrderId(null); // clear older state
                                  setOrderId(item?._id); // update state with order id
                                  proFormaHandleClickPdfOpen();
                                }}
                                className="view-invoice-btn"
                              >
                                Upload File
                              </Index.Button>
                            )}
                          </Index.TableCell>
                          <Index.TableCell
                            align="left"
                            onClick={() => {
                              orderView(item?._id);
                            }}
                          >
                            <Index.Tooltip title={item?.remark} arrow>
                              <Index.Button className="remark-text">
                                {item?.remark ? (
                                  <>
                                    {item.remark?.length > 10 ? (
                                      <>{`${item.remark.substring(
                                        0,
                                        10
                                      )}...`}</>
                                    ) : (
                                      item.remark
                                    )}
                                  </>
                                ) : (
                                  "-"
                                )}
                              </Index.Button>
                            </Index.Tooltip>
                          </Index.TableCell>
                          {/* <Index.TableCell
                            align="left"
                            onClick={() => {
                              orderView(item?._id);
                            }}
                          >
                            {item?.salesId?.salesName
                              ? item?.salesId?.salesName
                              : "-"}
                          </Index.TableCell>

                          <Index.TableCell
                            align="left"
                            onClick={() => {
                              orderView(item?._id);
                            }}
                          >
                            {item?.transportation ? item?.transportation : "-"}
                          </Index.TableCell>

                          <Index.TableCell
                            align="left"
                            onClick={() => {
                              orderView(item?._id);
                            }}
                          >
                            {item?.foreign ? item?.foreign : "-"}
                          </Index.TableCell>

                          <Index.TableCell
                            align="left"
                            onClick={() => {
                              orderView(item?._id);
                            }}
                          >
                            {item?.supplyDate
                              ? Index.moment(item?.supplyDate).format(
                                  "DD/MM/YYYY HH:mm"
                                )
                              : "-"}
                          </Index.TableCell>

                          <Index.TableCell
                            align="left"
                            onClick={() => {
                              orderView(item?._id);
                            }}
                          >
                            {item?.stayHours ? item?.stayHours : "-"}
                          </Index.TableCell>

                          <Index.TableCell
                            align="left"
                            onClick={() => {
                              orderView(item?._id);
                            }}
                          >
                            {item?.etb
                              ? Index.moment(item?.etb).format(
                                  "DD/MM/YYYY HH:mm"
                                )
                              : "-"}
                          </Index.TableCell>
                          <Index.TableCell
                            align="left"
                            onClick={() => {
                              orderView(item?._id);
                            }}
                          >
                            {item?.exmiOrDelivered
                              ? item?.exmiOrDelivered
                              : "-"}
                          </Index.TableCell>

                          <Index.TableCell
                            align="left"
                            onClick={() => {
                              orderView(item?._id);
                            }}
                          >
                            {item?.layStartDate || item?.layEndDate ? (
                              <>
                                {Index.moment(item?.layStartDate).format(
                                  "DD/MM/YYYY"
                                )}{" "}
                                -{" "}
                                {Index.moment(item?.layEndDate).format(
                                  "DD/MM/YYYY"
                                )}
                              </>
                            ) : (
                              "-"
                            )}
                          </Index.TableCell>

                          <Index.TableCell align="left">
                            {item?.createdAt
                              ? Index.moment(item?.createdAt).format(
                                  "DD/MM/YYYY HH:mm:ss"
                                )
                              : "-"}
                          </Index.TableCell>
                          <Index.TableCell
                            onClick={() => {
                              orderView(item?._id);
                            }}
                          >
                            <Index.Box className="challan-icon-align">
                              {item?.inquiryNo ? item?.inquiryNo : "-"}
                            </Index.Box>
                          </Index.TableCell>

                          <Index.TableCell
                            align="left"
                            onClick={() => {
                              orderView(item?._id);
                            }}
                          >
                            {item?.nominationNo ? item?.nominationNo : "-"}
                          </Index.TableCell>

                          <Index.TableCell
                            align="left"
                            onClick={() => {
                              orderView(item?._id);
                            }}
                          >
                            {item?.nomination_rec_date
                              ? Index.moment(item?.nomination_rec_date).format(
                                  "DD/MM/YYYY"
                                )
                              : "-"}
                          </Index.TableCell>
                          <Index.TableCell
                            align="left"
                            onClick={() => {
                              orderView(item?._id);
                            }}
                          >
                            <Index.Tooltip title={item?.remark} arrow>
                              <Index.Button className="remark-text">
                                {item?.remark ? (
                                  <>
                                    {item.remark?.length > 10 ? (
                                      <>{`${item.remark.substring(
                                        0,
                                        10
                                      )}...`}</>
                                    ) : (
                                      item.remark
                                    )}
                                  </>
                                ) : (
                                  ""
                                )}
                              </Index.Button>
                            </Index.Tooltip>
                          </Index.TableCell>

                          <Index.TableCell align="left">
                            <Index.FormControlLabel
                              control={
                                <Index.Checkbox
                                  checked={item?.shippingBill}
                                  onChange={(event) => {
                                    orderCheckPermission(
                                      event.target.checked,
                                      item?._id,
                                      "shippingBill"
                                    );
                                  }}
                                />
                              }
                            />
                          </Index.TableCell>

                          <Index.TableCell align="center">
                            <Index.FormControlLabel
                              control={
                                <Index.Checkbox
                                  checked={item?.wharfage}
                                  onChange={(event) => {
                                    orderCheckPermission(
                                      event.target.checked,
                                      item?._id,
                                      "wharfage"
                                    );
                                  }}
                                />
                              }
                            />
                          </Index.TableCell>

                          <Index.TableCell align="center">
                            <Index.FormControlLabel
                              control={
                                <Index.Checkbox
                                  checked={item?.harbourMaster}
                                  onChange={(event) => {
                                    orderCheckPermission(
                                      event.target.checked,
                                      item?._id,
                                      "harbourMaster"
                                    );
                                  }}
                                />
                              }
                            />
                          </Index.TableCell>

                          <Index.TableCell align="left">
                            {item?.challanStatus ? (
                              <b>{item?.challanStatus}</b>
                            ) : (
                              "-"
                            )}
                          </Index.TableCell> */}
                        </Index.TableRow>
                      ))
                    ) : (
                      <Index.TableRow>
                        <Index.TableCell
                          component="td"
                          variant="td"
                          scope="row"
                          className="no-data-in-list empty-record-align"
                          colSpan={31}
                          align="center"
                        >
                          No data available
                        </Index.TableCell>
                      </Index.TableRow>
                    )}
                  </Index.TableBody>
                )}
              </Index.Table>
            </Index.TableContainer>
          </Index.Box>
          <Index.Box className="pagination-design flex-end">
            <Index.Stack spacing={2}>
              <Index.Box className="pagination-count">
                <Index.Box className="input-design-div with-border">
                  <Index.Select
                    fullWidth
                    value={paginationPerPage}
                    onChange={(e) => handleChangePaginationPerChange(e)}
                  >
                    <Index.MenuItem value={10}>10</Index.MenuItem>
                    <Index.MenuItem value={25}>25</Index.MenuItem>
                    <Index.MenuItem value={50}>50</Index.MenuItem>
                    <Index.MenuItem value={100}>100</Index.MenuItem>
                  </Index.Select>
                </Index.Box>
                {loading ? (
                  <Index.CircularProgress
                    color="secondary"
                    sx={{ marginLeft: "10px" }}
                    size={26}
                  />
                ) : (
                  <>
                    <Index.Pagination
                      count={Math.ceil(totalRecordCount / paginationPerPage)}
                      page={currentPage}
                      onChange={handlePageChange}
                    />
                  </>
                )}
              </Index.Box>
            </Index.Stack>
          </Index.Box>
        </Index.Box>
      </Index.Box>

      {/* PDF Upload Files */}
      <Index.Dialog
        open={openPdfModel}
        onClose={handlePdfClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Index.DialogContent className="expense-model-main  expense-model-main-box-set">
          <Index.Box className="title-main">
            <Formik
              enableReinitialize
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleFormSubmit}
            >
              {({
                values,
                errors,
                touched,
                setFieldValue,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldTouched,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Index.Box className="title-main">
                    <Index.Box className="jus-between">
                      <Index.Typography
                        variant="h6"
                        component="h6"
                        className="input-label"
                      >
                        Upload File
                      </Index.Typography>
                      <Index.ClearIcon
                        className="model-close-icon"
                        onClick={handlePdfClose}
                      />
                    </Index.Box>
                    <Index.Typography
                      variant="label"
                      component="label"
                      className="input-label"
                    >
                      Wharfage PDF
                    </Index.Typography>
                    <Index.Grid container spacing={2}>
                      <Index.Grid item xs={12} sm={12} md={12}>
                        <Index.Box className="input-design-div with-border position-relative">
                          <Index.Box className="pdf-design">
                            Upload File
                          </Index.Box>
                          <Index.TextField
                            fullWidth
                            type="file"
                            name="wharfageFile"
                            multiple
                            id="filled-hidden-label-normal"
                            variant="filled"
                            className="admin-input-design input-placeholder pdf-upload-design"
                            onChange={(event) => {
                              const files = Array.from(event.target.files);
                              setFieldValue("wharfageFile", [...files]);
                            }}
                            inputProps={{
                              multiple: true,
                              accept: "application/pdf",
                            }}
                            FormHelperTextProps={{
                              className: "input_label_props",
                            }}
                          />
                        </Index.Box>

                        {errors?.wharfageFile && (
                          <Index.FormHelperText error>
                            {errors?.wharfageFile}
                          </Index.FormHelperText>
                        )}
                        <Index.Typography className="mt-5">
                          {values?.wharfageFile?.map((items, index) => {
                            return (
                              <Index.Typography
                                variant="label"
                                component="label"
                                className="input-label"
                              >
                                {index + 1}. {items.name}
                              </Index.Typography>
                            );
                          })}
                        </Index.Typography>
                      </Index.Grid>
                      {/* <Index.Grid item xs={12} sm={12} md={12}>
                        
                      </Index.Grid> */}
                      <Index.Grid item xs={12} sm={12} md={12}>
                        <Index.Box
                          className="common-button blue-button flex-end save-btn"
                          sx={{
                            display: "flex",
                            alignItems: "flex-end",
                          }}
                        >
                          <Index.Button
                            variant="contained"
                            type="submit"
                            className="Particular_model_submit"
                          >
                            Submit{" "}
                            {isWharfedPdfUploaded ? (
                              <Index.CircularProgress
                                color="secondary"
                                size={20}
                                sx={{ marginLeft: "5px" }}
                              />
                            ) : (
                              ""
                            )}
                          </Index.Button>
                        </Index.Box>
                      </Index.Grid>
                    </Index.Grid>

                    {/* <Index.Box className="line" mt={3} mb={3}></Index.Box> */}
                  </Index.Box>
                </form>
              )}
            </Formik>
          </Index.Box>
        </Index.DialogContent>
      </Index.Dialog>

      {/* proforma pdf */}
      <Index.Dialog
        open={openProformaPdfModel}
        onClose={setOpenProformaPdfModel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Index.DialogContent className="expense-model-main  expense-model-main-box-set">
          <Index.Box className="title-main">
            <Formik
              enableReinitialize
              initialValues={initialValuesProforma}
              validationSchema={validationSchemaProforma}
              onSubmit={handleProformaFormSubmit}
            >
              {({
                values,
                errors,
                touched,
                setFieldValue,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldTouched,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Index.Box className="title-main" sx={{ marginTop: "10px" }}>
                    <Index.ClearIcon
                      className="model-close-icon"
                      onClick={proFormaHandlePdfClose}
                    />
                    <Index.Typography
                      variant="label"
                      component="label"
                      className="input-label"
                    >
                      Proforma Invoice PDF
                    </Index.Typography>
                    <Index.Grid container spacing={2}>
                      <Index.Grid item xs={12} sm={6} md={6}>
                        <Index.Box className="input-design-div with-border position-relative">
                          <Index.Box className="pdf-design">
                            Upload File
                          </Index.Box>
                          <Index.TextField
                            fullWidth
                            type="file"
                            name="nominationPdf"
                            id="filled-hidden-label-normal"
                            variant="filled"
                            // onBlur={handleBlur}
                            className="admin-input-design input-placeholder pdf-upload-design"
                            // onBlur={() => {
                            //   if (!values.nominationPdf?.length) {
                            //     setFieldTouched("nominationPdf", false);
                            //   }
                            // }}
                            onChange={(e) => {
                              setFieldValue(
                                "nominationPdf",
                                e.target?.files[0]
                              );
                              setPdfName(e.target?.files[0]?.name);
                            }}
                            inputProps={{
                              className: "input_props",
                              multiple: true,
                              accept: "application/pdf",
                            }}
                            FormHelperTextProps={{
                              className: "input_label_props",
                            }}
                          />
                        </Index.Box>
                        {touched.nominationPdf && errors.nominationPdf && (
                          <Index.FormHelperText error>
                            {errors.nominationPdf}
                          </Index.FormHelperText>
                        )}
                      </Index.Grid>
                      <Index.Grid item xs={12} sm={6} md={6}>
                        <Index.Box
                          className="common-button blue-button flex-end save-btn"
                          sx={{
                            display: "flex",
                            alignItems: "flex-end",
                          }}
                        >
                          <Index.Button
                            variant="contained"
                            type="submit"
                            className="Particular_model_submit"
                          >
                            Submit{" "}
                            {isParfomaPdfUploaded ? (
                              <Index.CircularProgress
                                color="secondary"
                                size={20}
                                sx={{ marginLeft: "5px" }}
                              />
                            ) : (
                              ""
                            )}
                          </Index.Button>
                        </Index.Box>
                      </Index.Grid>

                      <Index.Grid item xs={12} sm={12} md={12}>
                        <Index.Typography>
                          {pdfName && (
                            <>
                              <Index.Typography
                                variant="label"
                                component="label"
                                className="input-label"
                              >
                                {pdfName}
                              </Index.Typography>
                            </>
                          )}
                        </Index.Typography>
                      </Index.Grid>
                    </Index.Grid>

                    <Index.Box className="line" mt={3} mb={3}></Index.Box>
                  </Index.Box>
                </form>
              )}
            </Formik>
          </Index.Box>
        </Index.DialogContent>
      </Index.Dialog>

      <UpdateVesselNamePopUp
        openVesselEditPopUp={openVesselEditPopUp}
        handleCloseEditVesselNamePopUp={handleCloseEditVesselNamePopUp}
        orderId={editTimeOrderId}
        handleUpdateVesselNameViaApi={handleUpdateVesselNameViaApi}
        editTimeVesselName={editTimeVesselName}
        isSingleFieldUpdate={isSingleFieldUpdate}
      />

      <SingleUpdateDatePopUp
        openUpdateDatePopUp={openUpdateDatePopUp}
        handleCloseDateEditModal={handleCloseDateEditModal}
        editTimeOrderId={editTimeOrderId}
        handleUpdateDateViaApi={handleUpdateDateViaApi}
        editTimeDateData={editTimeDateData}
        isSingleFieldUpdate={isSingleFieldUpdate}
      />

      <SingleUpdateDateTimePopUp
        openUpdateDatePopUp={openUpdateDateTimePopUp}
        handleCloseDateEditModal={handleCloseDateTimeEditModal}
        editTimeOrderId={editTimeOrderId}
        handleUpdateDateViaApi={handleUpdateDateTimeViaApi}
        editTimeDateData={editTimeDateData}
        isSingleFieldUpdate={isSingleFieldUpdate}
      />

      <SingleUpdateTextFieldPopUp
        openUpdateDatePopUp={openUpdateTextFieldPopUp}
        handleCloseDateEditModal={handleCloseTextFieldEditModal}
        editTimeOrderId={editTimeOrderId}
        handleUpdateDateViaApi={handleUpdateTextFieldViaApi}
        editTimeDateData={editTimeDateData}
        isSingleFieldUpdate={isSingleFieldUpdate}
      />

      <UdpateOrderTerminalModal
        openUpdateDatePopUp={openUpdateOrderTerminalPopUp}
        handleCloseDateEditModal={handleCloseUpdateOrderTerminalModal}
        handleUpdateDateViaApi={handleUpdateOrderTerminalViaApi}
        editTimeDateData={editTimeDateData}
        editTimeTerminalData={editTimeTerminalData}
        isSingleFieldUpdate={isSingleFieldUpdate}
      />

      <UpdateOrderTraderModal
        openUpdateDatePopUp={openUpdateOrderTraderPopUp}
        handleCloseDateEditModal={handleCloseUpdateOrderTraderModal}
        handleUpdateDateViaApi={handleUpdateOrderTraderViaApi}
        editTimeDateData={editTimeDateData}
        editTimeTraderData={editTimeTraderData}
        isSingleFieldUpdate={isSingleFieldUpdate}
      />

      <UpdateOrderPortModal
        openUpdateDatePopUp={openUpdateOrderPortPopUp}
        handleCloseDateEditModal={handleCloseUpdateOrderPortModal}
        handleUpdateDateViaApi={handleUpdateOrderPortViaApi}
        editTimeDateData={editTimeDateData}
        editTimePortData={editTimePortData}
        isSingleFieldUpdate={isSingleFieldUpdate}
      />

      <UdpateOrderProposedBargeModal
        openUpdateDatePopUp={openUpdateOrderProposedBargePopUp}
        handleCloseDateEditModal={handleCloseUpdateOrderProposedBargeModal}
        handleUpdateDateViaApi={handleUpdateOrderProposedBargeViaApi}
        editTimeDateData={editTimeDateData}
        editTimeProposedBargeData={editTimeProposedBargeData}
        proposedBargeTimePortData={proposedBargeTimePortData}
        isSingleFieldUpdate={isSingleFieldUpdate}
      />

      <UpdateOrderCFModal
        openUpdateDatePopUp={openUpdateOrderCFPopUp}
        handleCloseDateEditModal={handleCloseUpdateOrderCFModal}
        handleUpdateDateViaApi={handleUpdateOrderCFViaApi}
        editTimeDateData={editTimeDateData}
        editTimeCFData={editTimeCFData}
        isSingleFieldUpdate={isSingleFieldUpdate}
      />

      <UpdateOrderProductModal
        openUpdateDatePopUp={openUpdateOrderProductPopUp}
        handleCloseDateEditModal={handleCloseUpdateOrderProductModal}
        handleUpdateDateViaApi={handleUpdateOrderProductViaApi}
        editTimeDateData={editTimeDateData}
        editTimeProductData={editTimeProductData}
        isSingleFieldUpdate={isSingleFieldUpdate}
      />

      <UpdateOrderNominationQTYModal
        openUpdateDatePopUp={openUpdateOrderNominationQTYPopUp}
        handleCloseDateEditModal={handleCloseUpdateOrderNominationQTYModal}
        handleUpdateDateViaApi={handleUpdateOrderNominationQTYViaApi}
        editTimeDateData={editTimeDateData}
        editTimeNominationQTYData={editTimeNominationQTYData}
        isSingleFieldUpdate={isSingleFieldUpdate}
      />

      <OrderStatusModel
        updateOpen={updateOpen}
        handleClose={handleClose}
        inquiryStatusUpdate={
          status ? orderMultipleStatusCancel : completedMultipleOrder
        }
        modalType={status ? "Comment" : ""}
      />
    </>
  );
}
export default SupplyOrder;
