import { useEffect, useState } from "react";
import Index from "../../../Index";
import { FieldArray, Formik } from "formik";
import {
  addEditCraftMaintenance,
  getAllAssigneeUsersList,
} from "../../../../redux/admin/action";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function MaintenanceView() {
  const dispatch = Index.useDispatch();
  const navigate = Index.useNavigate();
  const { state } = Index.useLocation();
  const [loading, setLoading] = useState(false);
  const [openModel, setOpenModel] = useState(false);
  const [workingUserList, setWorkingUserList] = useState([]);

  const maintenanceData = state?.row;
  //Go back
  const backBtn = () => {
    navigate("/admin/maintenances");
  };

  const jobTypeList = [
    { id: 1, name: "Electrical" },
    { id: 2, name: "Mechanical" },
    { id: 3, name: "Fabrication" },
    { id: 4, name: "Procurement" },
  ];

  // Add edit maintenance Model
  const handleModelOpen = () => {
    setOpenModel(true);
  };
  const handleModelClose = () => {
    setOpenModel(false);
  };

  const initialValues = {
    whatIsDone: "",
    remark: "",
    workingUser: [],
    jobType: "",
    planedDate: "",
    startDate: "",
    needToRepair: [{ point: "" }],
    needToSpare: [{ point: "" }],
    quantity: 0,
    unit: 0,
  };

  const handleFormSubmit = (values) => {
    const urlencoded = new URLSearchParams();
    urlencoded.append("barge", maintenanceData?.barge);
    urlencoded.append("type", maintenanceData?.type);
    for (let keys in values) {
      if (
        keys !== "needToRepair" &&
        keys !== "needToSpare" &&
        keys !== "workingUser" &&
        values[keys]
      ) {
        urlencoded.append(keys, values[keys]);
      }
    }

    values?.workingUser?.forEach((ele, i) => {
      urlencoded.append(`workingUser`, ele);
    });
    values?.needToRepair?.forEach((ele, i) => {
      if (ele?.point) {
        urlencoded.append(`needToRepair[${i}][point]`, ele?.point);
      }
    });
    values?.needToSpare?.forEach((ele, i) => {
      if (ele?.point) {
        urlencoded.append(`needToSpare[${i}][point]`, ele?.point);
      }
    });
    dispatch(addEditCraftMaintenance(urlencoded)).then((res) => {
      if (res?.status == 200 || res?.status == 201) {
        // getMaintenance();
      }
    });

    handleModelClose();
  };
  // Get User List
  const getUsers = () => {
    dispatch(getAllAssigneeUsersList()).then((res) => {
      if (res?.status === 200) {
        setWorkingUserList(res?.data);
      } else {
        setWorkingUserList([]);
      }
    });
  };

  useEffect(() => {
    getUsers();
  }, []);
  return (
    <>
      <Index.Box className="invoice-details">
        <Index.Box className="barge-common-box mb-20">
          <Index.Box className="title-header-flex flex-column-res">
            <Index.Box className="invoice-back-title">
              <Index.Button
                type="submit"
                className="Particular_model_submit back-invoice-btn"
                onClick={backBtn}
              >
                <Index.ArrowBackIcon />
              </Index.Button>
              <Index.Typography
                variant="span"
                component="span"
                className="invoice-title"
              >
                Maintenance Detail
              </Index.Typography>
            </Index.Box>
            <Index.Box className="common-button blue-button res-blue-button">
              <Index.Button
                variant="contained"
                disableRipple
                onClick={handleModelOpen}
              >
                Add Maintenance
              </Index.Button>
            </Index.Box>
          </Index.Box>
          <Index.Box className="">
            <Index.Grid container spacing={2}>
              <Index.Grid item xs={12} sm={6} md={4}>
                <Index.Typography
                  variant="label"
                  component="label"
                  className="input-label"
                >
                  Maintenance Number
                </Index.Typography>
                <Index.Box className="input-design-div with-border">
                  <Index.TextField
                    hiddenLabel
                    id="filled-hidden-label-normal"
                    placeholder="BDN"
                    variant="filled"
                    className="admin-input-design input-placeholder maintenance-view-disabled-input"
                    disabled
                    value={maintenanceData?.maintenanceNumber || "-"}
                  />
                </Index.Box>
              </Index.Grid>
              <Index.Grid item xs={12} sm={6} md={4}>
                <Index.Typography
                  variant="label"
                  component="label"
                  className="input-label"
                >
                  Barge Name
                </Index.Typography>
                <Index.Box className="input-design-div with-border">
                  <Index.TextField
                    hiddenLabel
                    id="filled-hidden-label-normal"
                    placeholder="BDN"
                    variant="filled"
                    className="admin-input-design input-placeholder maintenance-view-disabled-input"
                    disabled
                    value={maintenanceData?.bargeName || "-"}
                  />
                </Index.Box>
              </Index.Grid>
              <Index.Grid item xs={12} sm={6} md={4}>
                <Index.Typography
                  variant="label"
                  component="label"
                  className="input-label"
                >
                  Working Team Member Name
                </Index.Typography>
                <Index.Box className="input-design-div with-border">
                  <Index.TextField
                    hiddenLabel
                    id="filled-hidden-label-normal"
                    placeholder="BDN"
                    variant="filled"
                    className="admin-input-design input-placeholder maintenance-view-disabled-input"
                    disabled
                    value={
                      maintenanceData?.workingUser?.length
                        ? maintenanceData?.workingUser?.map(
                            (el) => el.firstName + " " + el.lastName
                          )
                        : "-"
                    }
                  />
                </Index.Box>
              </Index.Grid>
              <Index.Grid item xs={12} sm={6} md={4}>
                <Index.Typography
                  variant="label"
                  component="label"
                  className="input-label"
                >
                  Job Type
                </Index.Typography>
                <Index.Box className="input-design-div with-border">
                  <Index.TextField
                    hiddenLabel
                    id="filled-hidden-label-normal"
                    placeholder="BDN"
                    variant="filled"
                    className="admin-input-design input-placeholder maintenance-view-disabled-input"
                    disabled
                    value={maintenanceData?.jobType || "-"}
                  />
                </Index.Box>
              </Index.Grid>
              <Index.Grid item xs={12} sm={6} md={4}>
                <Index.Typography
                  variant="label"
                  component="label"
                  className="input-label"
                >
                  What Is Done
                </Index.Typography>
                <Index.Box className="input-design-div with-border">
                  <Index.TextField
                    hiddenLabel
                    id="filled-hidden-label-normal"
                    placeholder="BDN"
                    variant="filled"
                    className="admin-input-design input-placeholder maintenance-view-disabled-input"
                    disabled
                    value={maintenanceData?.whatIsDone || "-"}
                  />
                </Index.Box>
              </Index.Grid>
              <Index.Grid item xs={12} sm={6} md={4}>
                <Index.Typography
                  variant="label"
                  component="label"
                  className="input-label"
                >
                  Planed Date
                </Index.Typography>
                <Index.Box className="input-design-div with-border">
                  <Index.TextField
                    hiddenLabel
                    id="filled-hidden-label-normal"
                    placeholder="BDN"
                    variant="filled"
                    className="admin-input-design input-placeholder maintenance-view-disabled-input"
                    disabled
                    value={
                      maintenanceData?.planedDate
                        ? Index.moment(maintenanceData?.planedDate).format(
                            "DD/MM/YYYY"
                          )
                        : "-"
                    }
                  />
                </Index.Box>
              </Index.Grid>
              <Index.Grid item xs={12} sm={6} md={4}>
                <Index.Typography
                  variant="label"
                  component="label"
                  className="input-label"
                >
                  Start Date
                </Index.Typography>
                <Index.Box className="input-design-div with-border">
                  <Index.TextField
                    hiddenLabel
                    id="filled-hidden-label-normal"
                    placeholder="BDN"
                    variant="filled"
                    className="admin-input-design input-placeholder maintenance-view-disabled-input"
                    disabled
                    value={
                      maintenanceData?.startDate
                        ? Index.moment(maintenanceData?.startDate).format(
                            "DD/MM/YYYY"
                          )
                        : "-"
                    }
                  />
                </Index.Box>
              </Index.Grid>
              <Index.Grid item xs={12} sm={6} md={12}>
                <Index.Typography
                  variant="label"
                  component="label"
                  className="input-label"
                >
                  Remark
                </Index.Typography>
                <Index.Box className="input-design-div with-border">
                  <Index.TextareaAutosize
                    hiddenLabel
                    disabled
                    minRows={3}
                    id="filled-hidden-label-normal"
                    className="admin-input-design input-placeholder input-placeholder-box  input-placeholder-box-set textarea-input-disabled "
                    placeholder="Remark"
                    variant="filled"
                    value={maintenanceData?.remark || "-"}
                  />
                </Index.Box>
              </Index.Grid>

              {maintenanceData?.jobType !== "Procurement" &&
              maintenanceData?.needToRepair?.length ? (
                <>
                  <Index.Grid item xs={12} sm={6} md={4}>
                    <Index.Typography
                      variant="label"
                      component="label"
                      className="input-label"
                    >
                      Need To Repair
                    </Index.Typography>
                    {maintenanceData?.needToRepair?.map((ele) => {
                      return (
                        <Index.Box className="input-design-div with-border maintenance-view-input-box">
                          <Index.TextField
                            hiddenLabel
                            id="filled-hidden-label-normal"
                            placeholder="BDN"
                            variant="filled"
                            className="admin-input-design input-placeholder maintenance-view-disabled-input"
                            disabled
                            value={ele?.point || "-"}
                          />
                        </Index.Box>
                      );
                    })}
                  </Index.Grid>
                </>
              ) : (
                <></>
              )}
              {maintenanceData?.jobType === "Procurement" &&
              maintenanceData?.needToSpare?.length ? (
                <>
                  <Index.Grid item xs={12} sm={6} md={4}>
                    <Index.Typography
                      variant="label"
                      component="label"
                      className="input-label"
                    >
                      Need To Spare
                    </Index.Typography>
                    {maintenanceData?.needToSpare?.map((ele) => {
                      return (
                        <Index.Box className="input-design-div with-border maintenance-view-input-box">
                          <Index.TextField
                            hiddenLabel
                            id="filled-hidden-label-normal"
                            placeholder="BDN"
                            variant="filled"
                            className="admin-input-design input-placeholder maintenance-view-disabled-input"
                            disabled
                            value={ele?.point || "-"}
                          />
                        </Index.Box>
                      );
                    })}
                  </Index.Grid>
                </>
              ) : (
                <></>
              )}
              {maintenanceData?.jobType === "Procurement" ? (
                <>
                  <Index.Grid item xs={12} sm={6} md={4}>
                    <Index.Typography
                      variant="label"
                      component="label"
                      className="input-label"
                    >
                      QTY
                    </Index.Typography>
                    <Index.Box className="input-design-div with-border">
                      <Index.TextField
                        hiddenLabel
                        type="number"
                        id="filled-hidden-label-normal"
                        variant="filled"
                        placeholder="QTY"
                        className="admin-input-design input-placeholder"
                        name="quantity"
                        value={maintenanceData.quantity}
                      />
                    </Index.Box>
                  </Index.Grid>
                  <Index.Grid item xs={12} sm={6} md={4}>
                    <Index.Typography
                      variant="label"
                      component="label"
                      className="input-label"
                    >
                      Unit
                    </Index.Typography>
                    <Index.Box className="input-design-div with-border">
                      <Index.TextField
                        hiddenLabel
                        type="number"
                        id="filled-hidden-label-normal"
                        variant="filled"
                        placeholder="Unit"
                        className="admin-input-design input-placeholder"
                        name="unit"
                        value={maintenanceData.unit}
                      />
                    </Index.Box>
                  </Index.Grid>
                </>
              ) : (
                <></>
              )}
            </Index.Grid>
          </Index.Box>
        </Index.Box>
      </Index.Box>

      {/* Add Maintenance Model */}
      <Index.Dialog
        open={openModel}
        onClose={handleModelClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Index.DialogContent className="expense-model-main  expense-model-main-box-set">
          <Index.Box className="title-main">
            <Formik
              enableReinitialize
              initialValues={initialValues}
              onSubmit={handleFormSubmit}
            >
              {({
                values,
                errors,
                touched,
                setFieldValue,
                handleChange,
                handleBlur,
                handleSubmit,
                handleFocus,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Index.Box className="title-main">
                    <Index.Box className="jus-between">
                      <Index.Typography
                        variant="h6"
                        component="h6"
                        className="input-label input-label-bold"
                        sx={{ marginBottom: "15px" }}
                      >
                        Add Maintenance
                      </Index.Typography>
                      <Index.ClearIcon
                        className="model-close-icon"
                        onClick={handleModelClose}
                      />
                    </Index.Box>
                    <Index.Grid container spacing={2}>
                      <Index.Grid item xs={12} sm={6} md={12}>
                        <Index.Typography
                          variant="label"
                          component="label"
                          className="input-label"
                        >
                          Fault
                        </Index.Typography>
                        <Index.Box className="input-design-div with-border">
                          <Index.TextField
                            hiddenLabel
                            id="filled-hidden-label-normal"
                            placeholder="Enter Fault"
                            variant="filled"
                            className="admin-input-design input-placeholder"
                            name="whatIsDone"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values?.whatIsDone}
                          />
                        </Index.Box>
                      </Index.Grid>
                      <Index.Grid item xs={12} sm={12} md={12}>
                        <Index.Typography
                          variant="label"
                          component="label"
                          className="input-label"
                        >
                          Remark
                        </Index.Typography>
                        <Index.Box className="input-design-div with-border">
                          <Index.TextareaAutosize
                            hiddenLabel
                            minRows={3}
                            id="filled-hidden-label-normal"
                            className="admin-input-design input-placeholder input-placeholder-box  input-placeholder-box-set "
                            placeholder="Enter Remark"
                            variant="filled"
                            value={values?.remark}
                            name="remark"
                            onChange={handleChange}
                          />
                        </Index.Box>
                      </Index.Grid>

                      <Index.Grid item xs={12} sm={6} md={12}>
                        <Index.Typography
                          variant="label"
                          component="label"
                          className="input-label"
                        >
                          Working Team
                        </Index.Typography>
                        <Index.Box className="input-design-div multiselect-input-placeholder with-border">
                          <Index.Select
                            fullWidth
                            name="proposedBarge"
                            multiple
                            displayEmpty
                            MenuProps={MenuProps}
                            inputProps={{ "aria-label": "without label" }}
                            value={
                              values?.workingUser ? values?.workingUser : []
                            }
                            renderValue={
                              values?.workingUser != ""
                                ? undefined
                                : () => "Select Working Team"
                            }
                            onFocus={handleFocus}
                            onChange={(event) => {
                              const {
                                target: { value },
                              } = event;
                              setFieldValue(
                                "workingUser",
                                typeof value === "string"
                                  ? value.split(",")
                                  : value
                              );
                            }}
                          >
                            <Index.MenuItem disabled value="">
                              <em>Select Working Team</em>
                            </Index.MenuItem>
                            {workingUserList?.length === 0 ? (
                              <Index.MenuItem disabled>
                                <Index.Box className="multiselect-item">
                                  No data found
                                </Index.Box>
                              </Index.MenuItem>
                            ) : (
                              workingUserList.map((item) => (
                                <Index.MenuItem
                                  key={item?._id}
                                  value={item?._id}
                                >
                                  <Index.Box className="multiselect-item">
                                    {item?.employeeName}
                                  </Index.Box>
                                </Index.MenuItem>
                              ))
                            )}
                          </Index.Select>
                          {touched?.proposedBarge && errors?.proposedBarge && (
                            <Index.FormHelperText error>
                              {errors?.proposedBarge}
                            </Index.FormHelperText>
                          )}
                        </Index.Box>
                      </Index.Grid>
                      <Index.Grid item xs={12} sm={6} md={12}>
                        <Index.Typography
                          variant="label"
                          component="label"
                          className="input-label"
                        >
                          Job Type
                        </Index.Typography>
                        <Index.Box className="input-design-div with-border">
                          <Index.Autocomplete
                            disablePortal
                            className="invoice-select-drop"
                            id="combo-box-demo"
                            options={jobTypeList}
                            getOptionLabel={(option) => option?.name}
                            renderOption={(props, option) => (
                              <Index.Box
                                component="li"
                                sx={{
                                  "& > img": { mr: 2, flexShrink: 0 },
                                }}
                                {...props}
                              >
                                {option?.name}
                              </Index.Box>
                            )}
                            value={
                              jobTypeList && jobTypeList?.length > 0
                                ? jobTypeList?.find(
                                    (value) => value?.name == values?.jobType
                                  )
                                : ""
                            }
                            onChange={(event, newValue) => {
                              setFieldValue("jobType", newValue?.name);
                            }}
                            renderInput={(params) => (
                              <Index.TextField
                                {...params}
                                placeholder="Select Job Type"
                                variant="outlined"
                              />
                            )}
                          />
                        </Index.Box>
                      </Index.Grid>
                      <Index.Grid item xs={12} sm={6} md={12}>
                        <Index.Typography
                          variant="label"
                          component="label"
                          className="input-label"
                        >
                          Planned Date
                        </Index.Typography>
                        <Index.Box className="input-design-div">
                          <Index.Box className="set-date set-date-picker">
                            <Index.LocalizationProvider
                              dateAdapter={Index.AdapterDayjs}
                            >
                              <Index.DemoContainer components={["DatePicker"]}>
                                <Index.DemoItem label="">
                                  <Index.DatePicker
                                    format="DD/MM/YYYY"
                                    onChange={(value) => {
                                      const formatedDate = Index.moment(
                                        value.$d
                                      ).format("YYYY-MM-DD");
                                      setFieldValue("planedDate", formatedDate);
                                    }}
                                  />
                                </Index.DemoItem>
                              </Index.DemoContainer>
                            </Index.LocalizationProvider>
                          </Index.Box>
                        </Index.Box>
                      </Index.Grid>
                      <Index.Grid item xs={12} sm={6} md={12}>
                        <Index.Typography
                          variant="label"
                          component="label"
                          className="input-label"
                        >
                          Start Date
                        </Index.Typography>
                        <Index.Box className="input-design-div">
                          <Index.Box className="set-date set-date-picker">
                            <Index.LocalizationProvider
                              dateAdapter={Index.AdapterDayjs}
                            >
                              <Index.DemoContainer components={["DatePicker"]}>
                                <Index.DemoItem label="">
                                  <Index.DatePicker
                                    format="DD/MM/YYYY"
                                    onChange={(value) => {
                                      const formatedDate = Index.moment(
                                        value.$d
                                      ).format("YYYY-MM-DD");
                                      setFieldValue("startDate", formatedDate);
                                    }}
                                  />
                                </Index.DemoItem>
                              </Index.DemoContainer>
                            </Index.LocalizationProvider>
                          </Index.Box>
                        </Index.Box>
                      </Index.Grid>

                      {values?.jobType?.length &&
                      values?.jobType !== "Procurement" ? (
                        <>
                          <Index.Grid item xs={12} sm={6} md={12}>
                            <Index.Typography
                              variant="label"
                              component="label"
                              className="input-label"
                            >
                              Need To Repair
                            </Index.Typography>
                            {values?.needToRepair?.length > 0 && (
                              <FieldArray name="needToRepair">
                                {({ push, remove }) => (
                                  <>
                                    {values.needToRepair.map((row, index) => (
                                      <Index.Grid
                                        container
                                        spacing={2}
                                        key={index}
                                      >
                                        <Index.Grid
                                          item
                                          xs={12}
                                          sm={6}
                                          md={8}
                                          className="maintenance-view-grid-row"
                                        >
                                          <Index.Box className="input-design-div with-border">
                                            <Index.TextField
                                              hiddenLabel
                                              id="filled-hidden-label-normal"
                                              placeholder="Enter Need To Repair"
                                              variant="filled"
                                              className="admin-input-design input-placeholder"
                                              name={`needToRepair.${index}.point`}
                                              value={
                                                values.needToRepair[index].point
                                              }
                                              autoComplete="off"
                                              onBlur={handleBlur}
                                              onChange={handleChange}
                                            />
                                          </Index.Box>
                                        </Index.Grid>
                                        {/* <Index.Grid item xs={12} sm={6} md={3}>
                                      <Index.Box className="incre-btn-main incre-btn-maintenance-main">
                                        {values?.needToRepair?.length - 1 ==
                                          index && (
                                          <Index.Button
                                            sx={{ fontSize: "20px" }}
                                            className="button-add inc-dic-btn"
                                            type="button"
                                            onClick={() =>
                                              push({
                                                point: "",
                                              })
                                            }
                                          >
                                            +
                                          </Index.Button>
                                        )}
                                        {values?.needToRepair?.length > 1 && (
                                          <Index.Button
                                            sx={{ fontSize: "20px" }}
                                            type="button"
                                            className="button-remove inc-dic-btn"
                                            disabled={
                                              values?.needToRepair?.length > 1
                                                ? false
                                                : true
                                            }
                                            onClick={() => remove(index)}
                                          >
                                            -
                                          </Index.Button>
                                        )}
                                      </Index.Box>
                                    </Index.Grid> */}
                                      </Index.Grid>
                                    ))}
                                  </>
                                )}
                              </FieldArray>
                            )}
                          </Index.Grid>
                        </>
                      ) : (
                        <></>
                      )}

                      {values?.jobType?.length &&
                      values?.jobType === "Procurement" ? (
                        <>
                          <Index.Grid item xs={12} sm={6} md={12}>
                            <Index.Typography
                              variant="label"
                              component="label"
                              className="input-label"
                            >
                              Need To Spare
                            </Index.Typography>
                            {values?.needToSpare?.length > 0 && (
                              <FieldArray name="needToSpare">
                                {({ push, remove }) => (
                                  <>
                                    {values.needToSpare.map((row, index) => (
                                      <Index.Grid
                                        container
                                        spacing={2}
                                        key={index}
                                      >
                                        <Index.Grid item xs={12} sm={6} md={8}>
                                          <Index.Box className="input-design-div with-border">
                                            <Index.TextField
                                              hiddenLabel
                                              id="filled-hidden-label-normal"
                                              placeholder="Enter Need To Spare"
                                              variant="filled"
                                              className="admin-input-design input-placeholder"
                                              name={`needToSpare.${index}.point`}
                                              value={
                                                values.needToSpare[index].point
                                              }
                                              autoComplete="off"
                                              onChange={handleChange}
                                            />
                                          </Index.Box>
                                        </Index.Grid>
                                        {/* <Index.Grid item xs={12} sm={6} md={3}>
                                      <Index.Box className="incre-btn-main incre-btn-maintenance-main">
                                        {values?.needToSpare?.length - 1 ==
                                          index && (
                                          <Index.Button
                                            sx={{ fontSize: "20px" }}
                                            className="button-add inc-dic-btn"
                                            type="button"
                                            onClick={() =>
                                              push({
                                                point: "",
                                              })
                                            }
                                          >
                                            +
                                          </Index.Button>
                                        )}
                                        {values?.needToSpare?.length > 1 && (
                                          <Index.Button
                                            sx={{ fontSize: "20px" }}
                                            type="button"
                                            className="button-remove inc-dic-btn"
                                            disabled={
                                              values?.needToSpare?.length > 1
                                                ? false
                                                : true
                                            }
                                            onClick={() => remove(index)}
                                          >
                                            -
                                          </Index.Button>
                                        )}
                                      </Index.Box>
                                    </Index.Grid> */}
                                      </Index.Grid>
                                    ))}
                                  </>
                                )}
                              </FieldArray>
                            )}
                          </Index.Grid>

                          <Index.Grid item xs={12} sm={6} md={12}>
                            <Index.Typography
                              variant="label"
                              component="label"
                              className="input-label"
                            >
                              QTY
                            </Index.Typography>
                            <Index.Box className="input-design-div with-border">
                              <Index.TextField
                                hiddenLabel
                                type="number"
                                id="filled-hidden-label-normal"
                                variant="filled"
                                placeholder="QTY"
                                className="admin-input-design input-placeholder"
                                name="quantity"
                                value={values.quantity}
                                onChange={handleChange}
                              />
                            </Index.Box>
                          </Index.Grid>
                          <Index.Grid item xs={12} sm={6} md={12}>
                            <Index.Typography
                              variant="label"
                              component="label"
                              className="input-label"
                            >
                              Unit
                            </Index.Typography>
                            <Index.Box className="input-design-div with-border">
                              <Index.TextField
                                hiddenLabel
                                type="number"
                                id="filled-hidden-label-normal"
                                variant="filled"
                                placeholder="Unit"
                                className="admin-input-design input-placeholder"
                                name="unit"
                                value={values.unit}
                                onChange={handleChange}
                              />
                            </Index.Box>
                          </Index.Grid>
                        </>
                      ) : (
                        <></>
                      )}

                      <Index.Grid item xs={12} sm={6} md={12}>
                        <Index.Box
                          className="common-button blue-button flex-end save-btn"
                          sx={{
                            display: "flex",
                            alignItems: "flex-end",
                          }}
                        >
                          <Index.Button
                            variant="contained"
                            type="submit"
                            className="Particular_model_submit"
                          >
                            Submit
                          </Index.Button>
                        </Index.Box>
                      </Index.Grid>
                    </Index.Grid>

                    {/* <Index.Box className="line" mt={3} mb={3}></Index.Box> */}
                  </Index.Box>
                </form>
              )}
            </Formik>
          </Index.Box>
        </Index.DialogContent>
      </Index.Dialog>
    </>
  );
}

export default MaintenanceView;
