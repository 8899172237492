export const craftCategoryData = [
  {
    id: 1,
    item: "BUNKER BARGE",
  },
  {
    id: 2,
    item: "FRESH WATER BARGE",
  },
  {
    id: 3,
    item: "SLOPE / SLUDGE BARGE",
  },
  {
    id: 4,
    item: "TUG",
  },
];
