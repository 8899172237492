import React, { useState } from "react";
import Index from "../../../component/Index";
import { forgotPassword } from "../../../redux/admin/action";
import * as Yup from "yup";
import { Formik } from "formik";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

function ForgotPasswordComponent() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // state for continue button disabled
  const [loading, setLoading] = useState(false);

  // Initital values
  let initialValues = {
    email: "",
  };

  // Validation schema
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Enter the valid email")
      .matches(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        "Enter the valid email"
      )
      .required("Email is required"),
  });

  // Forgot password
  const handleFormSubmit = async (values) => {
    setLoading(true);
    dispatch(forgotPassword(values, navigate, setLoading));
  };

  return (
    <div>
      <Index.Box>
        <Index.Box className="main-login">
          <Index.Box>
            <Index.Box className=" white-login-main">
              <Index.Box className="white-login-box">
                <Index.Box className="logo-set2">
                  <img src={Index.Svg.logo} alt="logo" className="" />
                </Index.Box>
                <Index.Box className="main-box">
                  <Index.Box>
                    <Index.Box className="box-text bluebox-text">
                      <Index.Typography
                        variant="body1"
                        component="p"
                        className=""
                      >
                        Forgot Password
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="box-login-text bluebox-text">
                      <Index.Typography
                        variant="body1"
                        component="p"
                        className=""
                      >
                        Please enter your credentials to Forgot Password
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="input-design-div admin-design-div login-input-design-div">
                      <Formik
                        enableReinitialize
                        onSubmit={handleFormSubmit}
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                      >
                        {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                        }) => (
                          <Index.Stack
                            component="form"
                            spacing={2}
                            noValidate
                            autoComplete="off"
                            onSubmit={handleSubmit}
                          >
                            <Index.TextField
                              hiddenLabel
                              id="filled-hidden-label-normal"
                              placeholder="Enter email"
                              variant="filled"
                              className="admin-input-design input-placeholder"
                              name="email"
                              autoComplete="off"
                              onBlur={handleBlur}
                              onFocus={() => setLoading(false)}
                              value={values.email}
                              onChange={handleChange}
                              helperText={touched.email && errors.email}
                              error={Boolean(errors.email && touched.email)}
                              sx={{ mb: 3 }}
                            />
                            <Index.Box className="orange-btn login-btn login-btn-main">
                              <Index.Button
                                variant="contained"
                                type="submit"
                                disableRipple
                                disabled={loading}
                              >
                                Continue
                              </Index.Button>
                            </Index.Box>
                            <Index.Box
                              className="box-login-text forgot bluebox-text"
                              sx={{ mt: 3 }}
                            >
                              <Link to="/">
                                <Index.Typography
                                  variant="body1"
                                  component="p"
                                  className="forgot_password"
                                >
                                  Back to login
                                </Index.Typography>
                              </Link>
                            </Index.Box>
                          </Index.Stack>
                        )}
                      </Formik>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </div>
  );
}

export default ForgotPasswordComponent;
