import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import dayjs from "dayjs";

import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import Index from "../../../../Index";
import { editWaterSupplyChallan } from "../../../../../redux/admin/action";

//  style for model
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function EditWaterSupplyTiming() {
  let { state } = Index.useLocation();
  let editData = state?.row;
  const tabType = state?.type;
  const valueTabData = state?.value;
  let orderId = editData?.[0].orderId;
  const dispatch = Index.useDispatch();
  const navigate = Index.useNavigate();
  const [loading, setLoading] = useState(false);
  const [challanImg, setChallanImg] = useState("");

  //View Image Modal
  const [openImg, setOpenImg] = useState(false);
  const handleImgOpen = (img) => {
    setOpenImg(true);
    setChallanImg(img);
  };
  const handleImgClose = () => {
    setOpenImg(false);
    setChallanImg("");
  };

  let initialValues = {
    id: editData?.[0]?._id ? editData?.[0]?._id : "",
    supplyChallanimage: editData?.[0]?.supplyChallanimage
      ? editData?.[0]?.supplyChallanimage
      : "",
    pumpingRate: editData?.[0]?.pumpingRate ? editData?.[0]?.pumpingRate : "",
    craftSpeed: editData?.[0]?.craftSpeed ? editData?.[0]?.craftSpeed : "",
    distance: editData?.[0]?.distance ? editData?.[0]?.distance : "",
    dieselConsumption: editData?.[0]?.dieselConsumption
      ? editData?.[0]?.dieselConsumption
      : "",
    anchorAweighForSupply: editData?.[0]?.anchorAweighForSupply
      ? Index.moment(editData[0]?.anchorAweighForSupply).format(
          "DD/MM/YYYY HH:mm"
        )
      : "",
    alongsideOnVessel: editData?.[0]?.alongsideOnVessel
      ? Index.moment(editData[0]?.alongsideOnVessel).format("DD/MM/YYYY HH:mm")
      : "",
    pumpingStart: editData?.[0]?.pumpingStart
      ? Index.moment(editData[0]?.pumpingStart).format("DD/MM/YYYY HH:mm")
      : "",
    pumpingCompleted: editData?.[0]?.pumpingCompleted
      ? Index.moment(editData[0]?.pumpingCompleted).format("DD/MM/YYYY HH:mm")
      : "",
    castOff: editData?.[0]?.castOff
      ? Index.moment(editData[0]?.castOff).format("DD/MM/YYYY HH:mm")
      : "",
    suppliedQty: editData?.[0]?.suppliedQty ? editData?.[0]?.suppliedQty : "",
    issuedChallanNumber: editData?.[0]?.issuedChallanNumber
      ? editData?.[0]?.issuedChallanNumber
      : "",
    issuedChallanDate: editData?.[0]?.issuedChallanDate
      ? Index.moment(editData[0]?.issuedChallanDate).format("DD/MM/YYYY HH:mm")
      : "",
    rcvdBdn: editData?.[0]?.rcvdBdn
      ? Index.moment(editData[0]?.rcvdBdn).format("DD/MM/YYYY HH:mm")
      : "",
    lopIssue: editData?.[0]?.lopIssue ? editData?.[0]?.lopIssue : "",
    remarkOfLop: editData?.[0]?.remarkOfLop ? editData?.[0]?.remarkOfLop : "",
    returnType: editData?.[0]?.returnType ? editData?.[0]?.returnType : "",
    returnCastOff: editData?.[0]?.returnData?.returnCastOff
      ? Index.moment(editData?.[0]?.returnData?.returnCastOff).format(
          "DD/MM/YYYY HH:mm"
        )
      : "",
    returnAnchorDrop: editData?.[0]?.returnData?.returnAnchorDrop
      ? Index.moment(editData?.[0]?.returnData?.returnAnchorDrop).format(
          "DD/MM/YYYY HH:mm"
        )
      : "",
    returnDieselConsumption: editData?.[0].returnData?.returnDieselConsumption
      ? editData?.[0]?.returnData?.returnDieselConsumption
      : "",
    returnReason: editData?.[0].returnData?.returnReason
      ? editData?.[0]?.returnData?.returnReason
      : "",
    waiting: editData?.[0]?.waiting === true ? "Yes" : "No",

    dropAnchorAtAnchorage: editData?.[0]?.dropAnchorAtAnchorage
      ? editData[0]?.dropAnchorAtAnchorage
      : "",
    anchorAwighFromAnchorage: editData?.[0]?.anchorAwighFromAnchorage
      ? editData[0]?.anchorAwighFromAnchorage
      : "",
  };

  const handleFormSubmit = async (values) => {
    setLoading(true);
    const data = new FormData();
    if (values?.supplyChallanimage) {
      data.append("supplyChallanimage", values?.supplyChallanimage);
    }
    if (values?.pumpingRate) {
      data.append("pumpingRate", values?.pumpingRate);
    }

    if (values?.craftSpeed) {
      data.append("craftSpeed", values?.craftSpeed);
    }
    if (values?.distance) {
      data.append("distance", values?.distance);
    }
    if (values?.dieselConsumption) {
      data.append("dieselConsumption", values?.dieselConsumption);
    }
    if (values?.anchorAweighForSupply) {
      data.append(
        "anchorAweighForSupply",
        Index.moment(values?.anchorAweighForSupply, "DD/MM/YYYY HH:mm").format(
          "YYYY-MM-DDTHH:mm:ssZZ"
        )
      );
    }
    if (values?.alongsideOnVessel) {
      data.append(
        "alongsideOnVessel",
        Index.moment(values?.alongsideOnVessel, "DD/MM/YYYY HH:mm").format(
          "YYYY-MM-DDTHH:mm:ssZZ"
        )
      );
    }
    if (values?.pumpingStart) {
      data.append(
        "pumpingStart",
        Index.moment(values?.pumpingStart, "DD/MM/YYYY HH:mm").format(
          "YYYY-MM-DDTHH:mm:ssZZ"
        )
      );
    }
    if (values?.pumpingCompleted) {
      data.append(
        "pumpingCompleted",
        Index.moment(values?.pumpingCompleted, "DD/MM/YYYY HH:mm").format(
          "YYYY-MM-DDTHH:mm:ssZZ"
        )
      );
    }
    if (values?.castOff) {
      data.append(
        "castOff",
        Index.moment(values?.castOff, "DD/MM/YYYY HH:mm").format(
          "YYYY-MM-DDTHH:mm:ssZZ"
        )
      );
    }
    if (values?.suppliedQty) {
      data.append("suppliedQty", values?.suppliedQty);
    }
    if (values?.issuedChallanNumber) {
      data.append("issuedChallanNumber", values?.issuedChallanNumber);
    }
    if (values?.issuedChallanDate) {
      data.append(
        "issuedChallanDate",
        Index.moment(values?.issuedChallanDate, "DD/MM/YYYY HH:mm").format(
          "YYYY-MM-DDTHH:mm:ssZZ"
        )
      );
    }
    if (values?.rcvdBdn) {
      data.append(
        "rcvdBdn",
        Index.moment(values?.rcvdBdn, "DD/MM/YYYY HH:mm").format(
          "YYYY-MM-DDTHH:mm:ssZZ"
        )
      );
    }

    if (values?.lopIssue) {
      data.append("lopIssue", values?.lopIssue);
    }
    if (values?.lopIssue === "Yes") {
      data.append("remarkOfLop", values?.remarkOfLop);
    }
    data.append("id", editData[0]?._id);

    if (values?.returnType) {
      data.append("returnType", values?.returnType);
    }
    if (values?.returnType === "Yes") {
      data.append(
        "returnCastOff",
        values?.returnCastOff?.toLowerCase() !== "invalid date" &&
          !values?.returnCastOff
          ? ""
          : Index.moment(values?.returnCastOff, "DD/MM/YYYY HH:mm").format(
              "YYYY-MM-DDTHH:mm:ssZZ"
            )
      );
      data.append(
        "returnAnchorDrop",
        values?.returnAnchorDrop?.toLowerCase() !== "invalid date" &&
          !values?.returnAnchorDrop
          ? ""
          : Index.moment(values?.returnAnchorDrop, "DD/MM/YYYY HH:mm").format(
              "YYYY-MM-DDTHH:mm:ssZZ"
            )
      );
      data.append("returnDieselConsumption", values?.returnDieselConsumption);
      data.append("returnReason", values?.returnReason);
    }

    if (values?.waiting) {
      data.append("waiting", values?.waiting === "Yes" ? true : false);
    }

    if (values?.waiting == "Yes") {
      data.append(
        "dropAnchorAtAnchorage",
        values?.dropAnchorAtAnchorage?.toLowerCase() !== "invalid date" &&
          !values?.dropAnchorAtAnchorage
          ? ""
          : Index.moment(values?.dropAnchorAtAnchorage).format(
              "YYYY-MM-DDTHH:mm:ssZZ"
            )
      );
      data.append(
        "anchorAwighFromAnchorage",
        values?.anchorAwighFromAnchorage?.toLowerCase() !== "invalid date" &&
          !values?.anchorAwighFromAnchorage
          ? ""
          : Index.moment(values?.anchorAwighFromAnchorage).format(
              "YYYY-MM-DDTHH:mm:ssZZ"
            )
      );
    }

    dispatch(editWaterSupplyChallan(data)).then((res) => {
      if (res?.status === 200) {
        // setLoading(false);
        navigate(`/admin/waterSupply/view-order/${orderId}`, {
          state: { type: "EditTiming", value: valueTabData },
        });
      } else {
        setLoading(false);
      }
    });
  };

  const backBtn = () => {
    if (tabType === "editWaterSupply") {
      navigate(`/admin/waterSupply/view-order/${orderId}`, {
        state: { value: valueTabData },
      });
    }
    // navigate(`/admin/waterSupply/view-order/${orderId}`);
  };

  return (
    <>
      <Index.Box className="">
        <Index.Box className="barge-common-box">
          <Index.Box className="title-header">
            <Index.Box className="title-header-flex">
              <Index.Box className="title-main mb-10">
                <Index.Typography
                  variant="p"
                  component="p"
                  className="page-title"
                >
                  Edit Water Supply Details
                </Index.Typography>
              </Index.Box>
              <Index.Box className="common-button blue-button save-btn ">
                <Index.Button
                  variant="contained"
                  type="submit"
                  className="Particular_model_submit ml-0"
                  onClick={backBtn}
                >
                  Back
                </Index.Button>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            onSubmit={handleFormSubmit}
          >
            {({
              values,
              errors,
              touched,
              setFieldValue,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldError,
            }) => (
              <form onSubmit={handleSubmit}>
                {console.log(values, "values>>>>>>298")}
                <Index.Box className="">
                  <Index.Grid container spacing={2}>
                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Anchor Aweigh
                      </Index.Typography>
                      <Index.Box className="input-design-div">
                        <Index.Box className="set-date set-date-picker">
                          <Index.LocalizationProvider
                            dateAdapter={Index.AdapterDayjs}
                          >
                            <Index.DemoContainer
                              components={["Index.DateTimePicker"]}
                            >
                              <DateTimePicker
                                disableFuture
                                timeSteps={{
                                  hours: 1,
                                  minutes: 1,
                                }}
                                defaultValue={
                                  editData
                                    ? Index.dayjs(
                                        editData?.[0]?.anchorAweighForSupply
                                          ? editData?.[0]?.anchorAweighForSupply
                                          : "-"
                                      )
                                    : ""
                                }
                                onChange={(value) => {
                                  const formatedDate = Index.moment(
                                    value.$d
                                  ).format("DD/MM/YYYY HH:mm");
                                  setFieldValue(
                                    "anchorAweighForSupply",
                                    formatedDate
                                  );
                                }}
                                slotProps={{
                                  textField: {
                                    readOnly: true,
                                  },
                                }}
                                format="DD/MM/YYYY HH:mm"
                              />
                            </Index.DemoContainer>
                          </Index.LocalizationProvider>
                        </Index.Box>
                      </Index.Box>
                    </Index.Grid>

                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Alongside On Vessel
                      </Index.Typography>
                      <Index.Box className="input-design-div">
                        <Index.Box className="set-date set-date-picker">
                          <Index.LocalizationProvider
                            dateAdapter={Index.AdapterDayjs}
                          >
                            <Index.DemoContainer
                              components={["Index.DateTimePicker"]}
                            >
                              <DateTimePicker
                                disableFuture
                                timeSteps={{
                                  hours: 1,
                                  minutes: 1,
                                }}
                                defaultValue={
                                  editData
                                    ? Index.dayjs(
                                        editData?.[0]?.alongsideOnVessel
                                          ? editData?.[0]?.alongsideOnVessel
                                          : "-"
                                      )
                                    : ""
                                }
                                onChange={(value) => {
                                  const formatedDate = Index.moment(
                                    value.$d
                                  ).format("DD/MM/YYYY HH:mm");
                                  setFieldValue(
                                    "alongsideOnVessel",
                                    formatedDate
                                  );
                                }}
                                slotProps={{
                                  textField: {
                                    readOnly: true,
                                  },
                                }}
                                format="DD/MM/YYYY HH:mm"
                              />
                            </Index.DemoContainer>
                          </Index.LocalizationProvider>
                        </Index.Box>
                      </Index.Box>
                    </Index.Grid>
                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Pumping Start
                      </Index.Typography>
                      <Index.Box className="input-design-div">
                        <Index.Box className="set-date set-date-picker">
                          <Index.LocalizationProvider
                            dateAdapter={Index.AdapterDayjs}
                          >
                            <Index.DemoContainer
                              components={["Index.DateTimePicker"]}
                            >
                              <DateTimePicker
                                disableFuture
                                timeSteps={{
                                  hours: 1,
                                  minutes: 1,
                                }}
                                defaultValue={
                                  editData
                                    ? Index.dayjs(
                                        editData?.[0]?.pumpingStart
                                          ? editData?.[0]?.pumpingStart
                                          : "-"
                                      )
                                    : ""
                                }
                                onChange={(value) => {
                                  const formatedDate = Index.moment(
                                    value.$d
                                  ).format("DD/MM/YYYY HH:mm");
                                  setFieldValue("pumpingStart", formatedDate);
                                }}
                                slotProps={{
                                  textField: {
                                    readOnly: true,
                                  },
                                }}
                                format="DD/MM/YYYY HH:mm"
                              />
                            </Index.DemoContainer>
                          </Index.LocalizationProvider>
                        </Index.Box>
                      </Index.Box>
                    </Index.Grid>
                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Pumping Completed
                      </Index.Typography>
                      <Index.Box className="input-design-div">
                        <Index.Box className="set-date set-date-picker">
                          <Index.LocalizationProvider
                            dateAdapter={Index.AdapterDayjs}
                          >
                            <Index.DemoContainer
                              components={["Index.DateTimePicker"]}
                            >
                              <DateTimePicker
                                disableFuture
                                timeSteps={{
                                  hours: 1,
                                  minutes: 1,
                                }}
                                defaultValue={
                                  editData
                                    ? Index.dayjs(
                                        editData?.[0]?.pumpingCompleted
                                          ? editData?.[0]?.pumpingCompleted
                                          : "-"
                                      )
                                    : ""
                                }
                                onChange={(value) => {
                                  const formatedDate = Index.moment(
                                    value.$d
                                  ).format("DD/MM/YYYY HH:mm");
                                  setFieldValue(
                                    "pumpingCompleted",
                                    formatedDate
                                  );
                                }}
                                slotProps={{
                                  textField: {
                                    readOnly: true,
                                  },
                                }}
                                format="DD/MM/YYYY HH:mm"
                              />
                            </Index.DemoContainer>
                          </Index.LocalizationProvider>
                        </Index.Box>
                      </Index.Box>
                    </Index.Grid>
                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Cast Off
                      </Index.Typography>
                      <Index.Box className="input-design-div">
                        <Index.Box className="set-date set-date-picker">
                          <Index.LocalizationProvider
                            dateAdapter={Index.AdapterDayjs}
                          >
                            <Index.DemoContainer
                              components={["Index.DateTimePicker"]}
                            >
                              <DateTimePicker
                                disableFuture
                                timeSteps={{
                                  hours: 1,
                                  minutes: 1,
                                }}
                                defaultValue={
                                  editData
                                    ? Index.dayjs(
                                        editData?.[0]?.castOff
                                          ? editData?.[0]?.castOff
                                          : "-"
                                      )
                                    : ""
                                }
                                onChange={(value) => {
                                  const formatedDate = Index.moment(
                                    value.$d
                                  ).format("DD/MM/YYYY HH:mm");
                                  setFieldValue("castOff", formatedDate);
                                }}
                                slotProps={{
                                  textField: {
                                    readOnly: true,
                                  },
                                }}
                                format="DD/MM/YYYY HH:mm"
                              />
                            </Index.DemoContainer>
                          </Index.LocalizationProvider>
                        </Index.Box>
                      </Index.Box>
                    </Index.Grid>

                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Supplied Quantity
                      </Index.Typography>
                      <Index.Box className="input-design-div with-border">
                        <Index.TextField
                          hiddenLabel
                          type="number"
                          id="filled-hidden-label-normal"
                          placeholder="Supplied quantity"
                          variant="filled"
                          className="admin-input-design input-placeholder"
                          name="suppliedQty"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values?.suppliedQty}
                        />
                      </Index.Box>
                    </Index.Grid>
                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Issued Challan Number
                      </Index.Typography>
                      <Index.Box className="input-design-div with-border">
                        <Index.TextField
                          hiddenLabel
                          // type="number"
                          id="filled-hidden-label-normal"
                          placeholder="Issued challan number"
                          variant="filled"
                          className="admin-input-design input-placeholder"
                          name="issuedChallanNumber"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values?.issuedChallanNumber}
                        />
                      </Index.Box>
                    </Index.Grid>
                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Issued Challan Date
                      </Index.Typography>
                      <Index.Box className="input-design-div">
                        <Index.Box className="set-date set-date-picker">
                          <Index.LocalizationProvider
                            dateAdapter={Index.AdapterDayjs}
                          >
                            <Index.DemoContainer
                              components={["Index.DateTimePicker"]}
                            >
                              <DateTimePicker
                                disableFuture
                                timeSteps={{
                                  hours: 1,
                                  minutes: 1,
                                }}
                                defaultValue={
                                  editData
                                    ? Index.dayjs(
                                        editData?.[0]?.issuedChallanDate
                                          ? editData?.[0]?.issuedChallanDate
                                          : "-"
                                      )
                                    : ""
                                }
                                onChange={(value) => {
                                  const formatedDate = Index.moment(
                                    value.$d
                                  ).format("DD/MM/YYYY HH:mm");
                                  setFieldValue(
                                    "issuedChallanDate",
                                    formatedDate
                                  );
                                }}
                                slotProps={{
                                  textField: {
                                    readOnly: true,
                                  },
                                }}
                                format="DD/MM/YYYY HH:mm"
                              />
                            </Index.DemoContainer>
                          </Index.LocalizationProvider>
                        </Index.Box>
                      </Index.Box>
                    </Index.Grid>

                    {/* new one add */}
                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        RCVD BDN
                      </Index.Typography>
                      <Index.Box className="input-design-div">
                        <Index.Box className="set-date set-date-picker">
                          <Index.LocalizationProvider
                            dateAdapter={Index.AdapterDayjs}
                          >
                            <Index.DemoContainer
                              components={["Index.DateTimePicker"]}
                            >
                              <DateTimePicker
                                disableFuture
                                timeSteps={{
                                  hours: 1,
                                  minutes: 1,
                                }}
                                defaultValue={
                                  editData
                                    ? Index.dayjs(
                                        editData?.[0]?.rcvdBdn
                                          ? editData?.[0]?.rcvdBdn
                                          : "-"
                                      )
                                    : ""
                                }
                                onChange={(value) => {
                                  const formatedDate = Index.moment(
                                    value.$d
                                  ).format("DD/MM/YYYY HH:mm");
                                  setFieldValue("rcvdBdn", formatedDate);
                                }}
                                slotProps={{
                                  textField: {
                                    readOnly: true,
                                  },
                                }}
                                format="DD/MM/YYYY HH:mm"
                              />
                            </Index.DemoContainer>
                          </Index.LocalizationProvider>
                        </Index.Box>
                      </Index.Box>
                    </Index.Grid>

                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Pumping Rate (In Hours)
                      </Index.Typography>
                      <Index.Box className="input-design-div with-border">
                        <Index.TextField
                          hiddenLabel
                          type="number"
                          id="filled-hidden-label-normal"
                          placeholder="Pumping rate (In Hours)"
                          variant="filled"
                          className="admin-input-design input-placeholder"
                          name="pumpingRate"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values?.pumpingRate}
                        />
                        {/* {touched?.pumpingRate && errors?.pumpingRate && (
                          <Index.FormHelperText error>
                            {errors?.pumpingRate}
                          </Index.FormHelperText>
                        )} */}
                      </Index.Box>
                    </Index.Grid>
                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Craft Speed (Nautical Mileage)
                      </Index.Typography>
                      <Index.Box className="input-design-div with-border">
                        <Index.TextField
                          hiddenLabel
                          type="number"
                          id="filled-hidden-label-normal"
                          placeholder="Craft speed (Nautical Mileage)"
                          variant="filled"
                          className="admin-input-design input-placeholder"
                          name="craftSpeed"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values?.craftSpeed}
                        />
                      </Index.Box>
                    </Index.Grid>
                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Distance (In Nautical Miles)
                      </Index.Typography>
                      <Index.Box className="input-design-div with-border">
                        <Index.TextField
                          hiddenLabel
                          type="number"
                          id="filled-hidden-label-normal"
                          placeholder="Distance (In Nautical Miles)"
                          variant="filled"
                          className="admin-input-design input-placeholder"
                          name="distance"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values?.distance}
                        />
                      </Index.Box>
                    </Index.Grid>
                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Diesel Consumption
                      </Index.Typography>
                      <Index.Box className="input-design-div with-border">
                        <Index.TextField
                          hiddenLabel
                          type="number"
                          id="filled-hidden-label-normal"
                          placeholder="Diesel consumption"
                          variant="filled"
                          className="admin-input-design input-placeholder"
                          name="dieselConsumption"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values?.dieselConsumption}
                        />
                      </Index.Box>
                    </Index.Grid>

                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Lop Issue
                      </Index.Typography>
                      <Index.Box className="input-design-div with-border">
                        <FormControl>
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            onChange={(e) => {
                              setFieldValue("lopIssue", e.target.value);
                            }}
                          >
                            <FormControlLabel
                              value="Yes"
                              name="lopIssue"
                              false
                              control={<Radio />}
                              label="Yes"
                              checked={values.lopIssue === "Yes" ? true : false}
                            />
                            <FormControlLabel
                              value="No"
                              name="lopIssue"
                              control={<Radio />}
                              label="No"
                              checked={values.lopIssue === "No" ? true : false}
                            />
                          </RadioGroup>
                        </FormControl>
                      </Index.Box>
                    </Index.Grid>
                    {values?.lopIssue === "Yes" && (
                      <Index.Grid item xs={12} sm={6} md={4}>
                        <Index.Typography
                          variant="label"
                          component="label"
                          className="input-label"
                        >
                          Lop Issue(Remark)
                        </Index.Typography>
                        <Index.Box className="input-design-div with-border">
                          <Index.TextField
                            hiddenLabel
                            //   type="number"
                            id="filled-hidden-label-normal"
                            placeholder="Lop issue(Remark)"
                            variant="filled"
                            className="admin-input-design input-placeholder Lop Issue(Remark)"
                            name="remarkOfLop"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values?.remarkOfLop}
                          />
                        </Index.Box>
                      </Index.Grid>
                    )}

                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Return
                      </Index.Typography>
                      <Index.Box className="input-design-div with-border">
                        <FormControl>
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            onChange={(e) => {
                              setFieldValue("returnType", e.target.value);
                            }}
                          >
                            <FormControlLabel
                              value="Yes"
                              name="returnType"
                              false
                              control={<Radio />}
                              label="Yes"
                              checked={
                                values.returnType === "Yes" ? true : false
                              }
                            />
                            <FormControlLabel
                              value="No"
                              name="returnType"
                              control={<Radio />}
                              label="No"
                              checked={
                                values.returnType === "No" ? true : false
                              }
                            />
                          </RadioGroup>
                        </FormControl>
                      </Index.Box>
                    </Index.Grid>

                    {/* {values?.returnType === "Yes" && ( */}
                    {/* <> */}
                    {values?.returnType === "Yes" && (
                      <>
                        <Index.Grid item xs={12} sm={6} md={4}>
                          <Index.Typography
                            variant="label"
                            component="label"
                            className="input-label"
                          >
                            Return Cast Off
                          </Index.Typography>
                          <Index.Box className="input-design-div">
                            <Index.Box className="set-date set-date-picker">
                              <Index.LocalizationProvider
                                dateAdapter={Index.AdapterDayjs}
                              >
                                <Index.DemoContainer
                                  components={["Index.DateTimePicker"]}
                                >
                                  <DateTimePicker
                                    disableFuture
                                    timeSteps={{
                                      hours: 1,
                                      minutes: 1,
                                    }}
                                    defaultValue={
                                      editData
                                        ? Index.dayjs(
                                            editData?.[0]?.returnData
                                              ?.returnCastOff
                                              ? editData?.[0]?.returnData
                                                  ?.returnCastOff
                                              : "-"
                                          )
                                        : ""
                                    }
                                    onChange={(value) => {
                                      const formatedDate = Index.moment(
                                        value.$d
                                      ).format("DD/MM/YYYY HH:mm");
                                      setFieldValue(
                                        "returnCastOff",
                                        formatedDate
                                      );
                                    }}
                                    slotProps={{
                                      textField: {
                                        readOnly: true,
                                      },
                                    }}
                                    format="DD/MM/YYYY HH:mm"
                                  />
                                </Index.DemoContainer>
                              </Index.LocalizationProvider>
                            </Index.Box>
                          </Index.Box>
                        </Index.Grid>

                        <Index.Grid item xs={12} sm={6} md={4}>
                          <Index.Typography
                            variant="label"
                            component="label"
                            className="input-label"
                          >
                            Return Anchor Drop
                          </Index.Typography>
                          <Index.Box className="input-design-div">
                            <Index.Box className="set-date set-date-picker">
                              <Index.LocalizationProvider
                                dateAdapter={Index.AdapterDayjs}
                              >
                                <Index.DemoContainer
                                  components={["Index.DateTimePicker"]}
                                >
                                  <DateTimePicker
                                    disableFuture
                                    timeSteps={{
                                      hours: 1,
                                      minutes: 1,
                                    }}
                                    defaultValue={
                                      editData
                                        ? Index.dayjs(
                                            editData?.[0]?.returnData
                                              ?.returnAnchorDrop
                                              ? editData?.[0]?.returnData
                                                  ?.returnAnchorDrop
                                              : "-"
                                          )
                                        : ""
                                    }
                                    onChange={(value) => {
                                      const formatedDate = Index.moment(
                                        value.$d
                                      ).format("DD/MM/YYYY HH:mm");
                                      setFieldValue(
                                        "returnAnchorDrop",
                                        formatedDate
                                      );
                                    }}
                                    slotProps={{
                                      textField: {
                                        readOnly: true,
                                      },
                                    }}
                                    format="DD/MM/YYYY HH:mm"
                                  />
                                </Index.DemoContainer>
                              </Index.LocalizationProvider>
                            </Index.Box>
                          </Index.Box>
                        </Index.Grid>

                        <Index.Grid item xs={12} sm={6} md={4}>
                          <Index.Typography
                            variant="label"
                            component="label"
                            className="input-label"
                          >
                            Return Diesel Consumption
                          </Index.Typography>
                          <Index.Box className="input-design-div with-border">
                            <Index.TextField
                              hiddenLabel
                              type="number"
                              id="filled-hidden-label-normal"
                              placeholder="Return diesel consumption"
                              variant="filled"
                              className="admin-input-design input-placeholder"
                              name="returnDieselConsumption"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values?.returnDieselConsumption}
                            />
                          </Index.Box>
                        </Index.Grid>

                        <Index.Grid item xs={12} sm={6} md={4}>
                          <Index.Typography
                            variant="label"
                            component="label"
                            className="input-label"
                          >
                            Return Reason
                          </Index.Typography>
                          <Index.Box className="input-design-div with-border">
                            <Index.TextField
                              hiddenLabel
                              // type="number"
                              id="filled-hidden-label-normal"
                              placeholder="Return reason"
                              variant="filled"
                              className="admin-input-design input-placeholder"
                              name="returnReason"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values?.returnReason}
                            />
                          </Index.Box>
                        </Index.Grid>
                      </>
                    )}

                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Waiting
                      </Index.Typography>
                      <Index.Box className="input-design-div with-border">
                        <FormControl>
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            onChange={(e) => {
                              setFieldValue("waiting", e.target.value);
                              if (e?.target?.value === "No") {
                                setFieldValue("dropAnchorAtAnchorage", "");
                                setFieldValue("anchorAwighFromAnchorage", "");
                              }
                            }}
                          >
                            <FormControlLabel
                              value="Yes"
                              name="waiting"
                              false
                              control={<Radio />}
                              label="Yes"
                              checked={values.waiting === "Yes" ? true : false}
                            />
                            <FormControlLabel
                              value="No"
                              name="waiting"
                              control={<Radio />}
                              label="No"
                              checked={values.waiting === "No" ? true : false}
                            />
                          </RadioGroup>
                        </FormControl>
                      </Index.Box>
                    </Index.Grid>

                    {values?.waiting === "Yes" && (
                      <>
                        {" "}
                        <Index.Grid item xs={12} sm={6} md={4}>
                          <Index.Typography
                            variant="label"
                            component="label"
                            className="input-label"
                          >
                            Drop Anchor At Anchorage
                          </Index.Typography>
                          <Index.Box className="input-design-div">
                            <Index.Box className="set-date set-date-picker">
                              <Index.LocalizationProvider
                                dateAdapter={Index.AdapterDayjs}
                              >
                                <Index.DemoContainer
                                  components={["Index.DateTimePicker"]}
                                >
                                  <DateTimePicker
                                    disableFuture
                                    timeSteps={{
                                      hours: 1,
                                      minutes: 1,
                                    }}
                                    defaultValue={
                                      editData
                                        ? Index.dayjs(
                                            values?.dropAnchorAtAnchorage
                                              ? Index.moment(
                                                  values?.dropAnchorAtAnchorage
                                                ).format("YYYY/MM/DD HH:mm")
                                              : "-"
                                          )
                                        : ""
                                    }
                                    onChange={(value) => {
                                      const formatedDate = Index.moment(
                                        value.$d
                                      ).format("DD/MM/YYYY HH:mm");
                                      setFieldValue(
                                        "dropAnchorAtAnchorage",
                                        formatedDate
                                      );
                                    }}
                                    slotProps={{
                                      textField: {
                                        readOnly: true,
                                      },
                                    }}
                                    format="DD/MM/YYYY HH:mm"
                                  />
                                </Index.DemoContainer>
                              </Index.LocalizationProvider>
                            </Index.Box>
                          </Index.Box>
                        </Index.Grid>
                        <Index.Grid item xs={12} sm={6} md={4}>
                          <Index.Typography
                            variant="label"
                            component="label"
                            className="input-label"
                          >
                            Anchor Awigh From Anchorage
                          </Index.Typography>
                          <Index.Box className="input-design-div">
                            <Index.Box className="set-date set-date-picker">
                              <Index.LocalizationProvider
                                dateAdapter={Index.AdapterDayjs}
                              >
                                <Index.DemoContainer
                                  components={["Index.DateTimePicker"]}
                                >
                                  <DateTimePicker
                                    disableFuture
                                    timeSteps={{
                                      hours: 1,
                                      minutes: 1,
                                    }}
                                    defaultValue={
                                      editData
                                        ? Index.dayjs(
                                            values?.anchorAwighFromAnchorage
                                              ? Index.moment(
                                                  values?.anchorAwighFromAnchorage
                                                ).format("YYYY/MM/DD HH:mm")
                                              : "-"
                                          )
                                        : ""
                                    }
                                    onChange={(value) => {
                                      const formatedDate = Index.moment(
                                        value.$d
                                      ).format("DD/MM/YYYY HH:mm");
                                      setFieldValue(
                                        "anchorAwighFromAnchorage",
                                        formatedDate
                                      );
                                    }}
                                    slotProps={{
                                      textField: {
                                        readOnly: true,
                                      },
                                    }}
                                    format="DD/MM/YYYY HH:mm"
                                  />
                                </Index.DemoContainer>
                              </Index.LocalizationProvider>
                            </Index.Box>
                          </Index.Box>
                        </Index.Grid>
                      </>
                    )}

                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Water Supply Challan Image
                      </Index.Typography>
                      <Index.Box className="input-design-div with-border">
                        <Index.Grid container spacing={2}>
                          <Index.Grid item xs={6} sm={6} md={6}>
                            <Index.Box className="input-design-div with-border position-relative">
                              <Index.Box className="pdf-design">
                                Upload File
                              </Index.Box>
                              <Index.TextField
                                fullWidth
                                type="file"
                                accept="image/*"
                                name="supplyChallanimage"
                                id="filled-hidden-label-normal"
                                variant="filled"
                                className="admin-input-design input-placeholder pdf-upload-design"
                                onChange={(event) => {
                                  if (
                                    [
                                      "image/jpg",
                                      "image/jpeg",
                                      "image/png",
                                    ].includes(
                                      event.currentTarget.files[0]?.type
                                    )
                                  ) {
                                    setFieldValue(
                                      "supplyChallanimage",
                                      event.currentTarget.files[0]
                                    );
                                    setFieldError();
                                  } else {
                                    setFieldError(
                                      "supplyChallanimage",
                                      "Invalid file formate"
                                    );
                                  }
                                }}
                                inputProps={{
                                  className: "input_props",
                                }}
                                FormHelperTextProps={{
                                  className: "input_label_props",
                                }}
                              />
                            </Index.Box>
                          </Index.Grid>

                          <Index.Grid item xs={6} sm={6} md={4}>
                            <Index.Box
                              className="user-img-main"
                              onClick={() => {
                                handleImgOpen(values?.supplyChallanimage);
                              }}
                            >
                              <img
                                src={
                                  values?.supplyChallanimage
                                    ? values?.supplyChallanimage instanceof Blob
                                      ? URL.createObjectURL(
                                          values?.supplyChallanimage
                                        )
                                      : `${process.env.REACT_APP_IMAGE_URL}/supplyChallan/${values?.supplyChallanimage}`
                                    : ""
                                }
                                alt=""
                                className="user-img"
                              />
                            </Index.Box>
                          </Index.Grid>
                        </Index.Grid>
                      </Index.Box>
                    </Index.Grid>
                  </Index.Grid>
                </Index.Box>
                <Index.Box className="common-button blue-button flex-end save-btn inquiry-submit-btn">
                  <Index.Button
                    variant="contained"
                    type="submit"
                    disabled={loading}
                  >
                    {loading ? (
                      <Index.CircularProgress color="secondary" size={20} />
                    ) : editData ? (
                      "Update"
                    ) : (
                      "Submit"
                    )}
                  </Index.Button>
                </Index.Box>
              </form>
            )}
          </Formik>
        </Index.Box>
      </Index.Box>

      <Index.Modal
        open={openImg}
        onClose={handleImgClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Index.Box sx={style}>
          <Index.ClearIcon
            className="model-close-icon"
            onClick={handleImgClose}
          />
          <Index.Typography id="modal-modal-title" variant="h6" component="h2">
            {challanImg ? (
              <img
                src={
                  challanImg
                    ? challanImg instanceof Blob
                      ? URL.createObjectURL(challanImg)
                      : `${process.env.REACT_APP_IMAGE_URL}/supplyChallan/${challanImg}`
                    : ""
                }
                alt="Image Preview"
                className="challan-img"
                // className="img-header-align"
              />
            ) : (
              <Index.Box className="user-no-img">No Image</Index.Box>
            )}
          </Index.Typography>
        </Index.Box>
      </Index.Modal>
    </>
  );
}

export default EditWaterSupplyTiming;
