import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import { getEmployee } from "../../../../redux/admin/action";

const Search = Index.styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: Index.alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: Index.alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));
const ActionItem = Index.styled(Index.MenuItem)(() => ({
  fontFamily: "poppins",
  lineHeight: "15px",
  fontSize: "14px",
  fontWeight: "400",
  color: "#595F69",
}));
const StyledInputBase = Index.styled(Index.InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

function BunkerDailyExpenseList() {
  const dispatch = Index.useDispatch();
  const navigate = Index.useNavigate();
  const [loading, setLoading] = useState(false);

  const [employeeList, setEmployeeList] = useState([]);

  // State for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState(0);
  const [paginationPerPage, setPaginationPerPage] = useState(50);
  // State for searching and set data
  const [searchedData, setSearchedData] = useState([]);
  const [currentRows, setCurrentRows] = useState([]);

  // get employeelist
  const getEmployeeList = () => {
    setLoading(true);
    dispatch(getEmployee()).then((res) => {
      if (res?.status === 200) {
        setEmployeeList(res?.data);
        setTimeout(() => {
          setLoading(false);
        }, 2000);
      } else {
        setTimeout(() => {
          setLoading(false);
        }, 2000);
      }
    });
  };

  useEffect(() => {
    getEmployeeList();
  }, []);

  // Copy original data to searched data
  useEffect(() => {
    setSearchedData(employeeList?.reverse());
  }, [employeeList]);

  // Search on table
  const requestSearch = (searched) => {
    if (!searched) return setSearchedData(employeeList);
    setSearchedData(
      employeeList?.filter(
        (item) =>
          item?.employeeId?.employeeName
            ?.toString()
            .toLowerCase()
            .includes(searched.toLowerCase()) ||
          item?.totalAdvanceAmount
            ?.toString()
            .toLowerCase()
            .includes(searched?.toLowerCase()) ||
          item?.totalExpense
            ?.toString()
            .toLowerCase()
            .includes(searched?.toLowerCase()) ||
          (
            (item?.totalAdvanceAmount ? item?.totalAdvanceAmount : 0) -
            (item?.totalExpense ? item?.totalExpense : 0)
          )
            ?.toString()
            .toLowerCase()
            .includes(searched?.toLowerCase())
      )
    );
    setCurrentPage(1);
  };

  // Pagination calculation start
  const PER_PAGE = paginationPerPage;
  useEffect(() => {
    //Page count
    const count = Math.ceil(searchedData?.length / PER_PAGE);
    setCount(count);
    const indexOfLastRow = currentPage * PER_PAGE;
    const indexOfFirstRow = indexOfLastRow - PER_PAGE;
    const modifiedRows = searchedData?.slice(indexOfFirstRow, indexOfLastRow);
    setCurrentRows(modifiedRows);
    if (modifiedRows?.length === 0 && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  }, [currentPage, searchedData, paginationPerPage, PER_PAGE]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };
  // Pagination calculation end

  const employeeDetails = (id) => {
    navigate(`/admin/bunker/employee/view/${id}`);
  };
  return (
    <>
      <Index.DynamicTitle title="Bunker Expense (ADV)" />
      <Index.Box className="">
        <Index.Box className="barge-common-box">
          <Index.Box className="title-header">
            <Index.Box className="title-header-flex res-title-header-flex">
              <Index.Box className="title-main">
                <Index.Typography
                  variant="p"
                  component="p"
                  className="page-title"
                >
                  Bunker Expense (ADV)
                </Index.Typography>
              </Index.Box>
              <Index.Box className="d-flex align-items-center res-set-search">
                <Search className="search ">
                  <StyledInputBase
                    placeholder="Search"
                    inputProps={{ "aria-label": "search" }}
                    onChange={(e) =>
                      setTimeout(() => {
                        requestSearch(e.target.value.trim());
                      }, 1000)
                    }
                  />
                </Search>

                <Index.Box className="common-button blue-button res-blue-button">
                  <Index.RouteLink
                    to="/admin/bunker/employee/add"
                    className="no-text-decoration"
                  >
                    {" "}
                    <Index.Button variant="contained" disableRipple>
                      Add Employee
                    </Index.Button>
                  </Index.RouteLink>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Index.Box className="">
            <Index.TableContainer
              component={Index.Paper}
              className="table-container"
            >
              <Index.Table
                aria-label="simple table"
                className="table-design-main supply-table one-line-table invoice-table-set user-management-table tanker-list "
              >
                <Index.TableHead>
                  <Index.TableRow>
                    <Index.TableCell align="left">Sr. No</Index.TableCell>
                    <Index.TableCell align="left">
                      Employee Name
                    </Index.TableCell>
                    <Index.TableCell align="left">
                      Advance Given
                    </Index.TableCell>
                    <Index.TableCell align="left">
                      Total Expense
                    </Index.TableCell>
                    <Index.TableCell align="left">
                      Net Available Balance
                    </Index.TableCell>
                    <Index.TableCell align="left">Action</Index.TableCell>
                  </Index.TableRow>
                </Index.TableHead>
                {loading ? (
                  <Index.TableBody>
                    <Index.TableRow>
                      <Index.TableCell colSpan={14} align="center">
                        <Index.CircularProgress color="secondary" size={20} />
                      </Index.TableCell>
                    </Index.TableRow>
                  </Index.TableBody>
                ) : (
                  <Index.TableBody>
                    {currentRows?.length ? (
                      currentRows?.map((item, index) => (
                        <Index.TableRow key={item?._id}>
                          <Index.TableCell>{index + 1}</Index.TableCell>
                          <Index.TableCell>
                            {item?.employeeId?.employeeName
                              ? item?.employeeId?.employeeName
                              : "-"}
                          </Index.TableCell>

                          <Index.TableCell align="left">
                            {item?.totalAdvanceAmount
                              ? `₹ ${item?.totalAdvanceAmount}`
                              : "-"}
                          </Index.TableCell>

                          <Index.TableCell align="left">
                            {item?.totalExpense
                              ? `₹ ${item?.totalExpense}`
                              : "-"}
                          </Index.TableCell>
                          <Index.TableCell align="left">
                            ₹{" "}
                            {(item?.totalAdvanceAmount
                              ? item?.totalAdvanceAmount
                              : 0) -
                              (item?.totalExpense ? item?.totalExpense : 0)}
                          </Index.TableCell>

                          <Index.TableCell>
                            <Index.IconButton>
                              <Index.VisibilitySharpIcon
                                onClick={() => {
                                  employeeDetails(item?.employeeId?._id);
                                }}
                              />
                            </Index.IconButton>
                          </Index.TableCell>
                        </Index.TableRow>
                      ))
                    ) : (
                      <Index.TableRow>
                        <Index.TableCell
                          component="td"
                          variant="td"
                          scope="row"
                          className="no-data-in-list"
                          colSpan={15}
                          align="center"
                        >
                          No data available
                        </Index.TableCell>
                      </Index.TableRow>
                    )}
                  </Index.TableBody>
                )}
              </Index.Table>
            </Index.TableContainer>
          </Index.Box>
          <Index.Box className="pagination-design flex-end">
            <Index.Stack spacing={2}>
              <Index.Box className="pagination-count">
                <Index.Box className="input-design-div with-border">
                  <Index.Select
                    fullWidth
                    value={paginationPerPage}
                    onChange={(e) => {
                      setPaginationPerPage(e.target.value);
                    }}
                  >
                    <Index.MenuItem value={10}>10</Index.MenuItem>
                    <Index.MenuItem value={25}>25</Index.MenuItem>
                    <Index.MenuItem value={50}>50</Index.MenuItem>
                    <Index.MenuItem value={100}>100</Index.MenuItem>
                  </Index.Select>
                </Index.Box>
                <Index.Pagination
                  count={count}
                  page={currentPage}
                  onChange={handlePageChange}
                />
              </Index.Box>
            </Index.Stack>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
}

export default BunkerDailyExpenseList;
