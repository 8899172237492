import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import { Formik } from "formik";
import { addRoleMaster } from "../../../../redux/admin/action";
import {
  webRoleMaster,
  appRoleMaster,
} from "../../../../config/CommonRoleMaster";
import { Checkbox, FormControlLabel } from "@mui/material";
import { roleMasterValidationSchema } from "../../../../validation/AdminValidation";

function RoleAdd() {
  const dispatch = Index.useDispatch();
  const navigate = Index.useNavigate();
  const { state } = Index.useLocation();
  const [loading, setLoading] = useState(false);
  // edit data
  let editData = state?.row;
  if (editData) {
    editData.id = editData?._id;
  }
  let initialValues = {};
  if (editData) {
    initialValues = editData;
  } else {
    initialValues = {
      roleName: "",
      appPermission: {
        expense: false,
        bunkerTiming: false,
        loading: false,
        supplyDocs: false,
        maintenance: false,
        tankerList: false,
        appBreakdown: false,
        directory: false,
        tankerDashboard: false,
        tankerSetup: false,
        tugsTimings: false,
        waterSupplyTimings: false,
        slopeSludgeTimings: false,
        pipeline: false,
      },
      webPermission: {
        companyDashboard: false,
        tankerDashboard: false,
        enquiry: false,
        order: false,
        expenseList: false,
        invoice: false,
        banks: false,
        gstr: false,
        gstr2: false,
        tds: false,
        purchase: false,
        bunker: false,
        users: false,
        port: false,
        craft: false,
        rate: false,
        party: false,
        traders: false,
        expense: false,
        tankers: false,
        salesMaster: false,
        notification: false,
        tasks: false,
        breakdowns: false,
        maintenances: false,
        directory: false,
        activeUsers: false,
        tankertrips: false,
        waterSupply: false,
        wharfage:false
      },
    };
  }

  useEffect(()=> {
    if(editData){
      // App Permission Check 
      const appValuesAreTrue = Object.values(editData?.appPermission).every((value) => value === true);
      setAppCheckAll(appValuesAreTrue);

      // Web Permission Check
      // console.log(editData?.webPermission, 'permission');
      const webValuesAreTrue = Object.values(editData?.webPermission).every((value) => value === true);
      setWebCheckAll(webValuesAreTrue);
    }
  }, [editData])

  const handleFormSubmit = async (values) => {
    setLoading(true)
    dispatch(addRoleMaster(values, navigate, setLoading));
  };

  const BackBtn = () => {
    navigate("/admin/roles");
  };

  // Check All
  const [appCheckAll, setAppCheckAll] = useState(false);
  const [webCheckAll, setWebCheckAll] = useState(false);

  // App Permission functions 
  const handleCheckAppPermission = (e, setFieldValue) => {
    setAppCheckAll(e.target.checked);
    // Set the checked state for all individual checkboxes
    const updatedAppPermission = {};
    appRoleMaster.forEach((item) => {
      updatedAppPermission[item.tag] = e.target.checked;
    });
    setFieldValue("appPermission", updatedAppPermission);
  };

  // App permission single checkbox checked then function call
  const handleAppPermissionChecked = (tag,isChecked,setFieldValue,values) => {
    const updatedAppPermission = values;
    updatedAppPermission[tag] = isChecked;
    // Check if all values in updatedAppPermission are true
    const allValuesAreTrue = Object.values(updatedAppPermission).every(
      (value) => value === true
    );
    setAppCheckAll(allValuesAreTrue);
    setFieldValue("appPermission", updatedAppPermission);
  };


  // WebPermission functions 
  const handleCheckWebPermission = (e, setFieldValue) => {
    setWebCheckAll(e.target.checked);
    // Set the checked state for all individual checkboxes
    const updatedWebPermission = {};
    webRoleMaster.forEach((item) => {
      updatedWebPermission[item.tag] = e.target.checked;
    });

    // Child Role master checkbox checked
    webRoleMaster?.length &&
      webRoleMaster?.map(
        (item) =>
        item?.child?.length &&
        item?.child?.map((value) => {
            return (
            updatedWebPermission[value.tag] = e.target.checked
            )
          })
      );
    // console.log(webRoleMaster, "webRoleMaster", updatedWebPermission);
    setFieldValue("webPermission", updatedWebPermission);
  };

  // Web permission single checkbox checked then function call
  const handleWebPermissionChecked = (tag,isChecked,setFieldValue,values) => {
    const updatedWebPermission = values;
    updatedWebPermission[tag] = isChecked;
    // Check if all values in updatedWebPermission are true
    const allValuesAreTrue = Object.values(updatedWebPermission).every(
      (value) => value === true
    );
    setWebCheckAll(allValuesAreTrue);
    setFieldValue("webPermission", updatedWebPermission);
  };

  return (
    <>
      <Index.Box className="">
        <Index.Box className="barge-common-box">
          <Index.Box className="title-header">
            <Index.Box className="title-header-flex">
              <Index.Box className="title-main mb-10">
                <Index.Typography
                  variant="p"
                  component="p"
                  className="page-title"
                >
                  {editData ? "Edit" : "Add"} Role Master
                </Index.Typography>
              </Index.Box>
              <Index.Box className="common-button blue-button save-btn ">
                <Index.Button
                  variant="contained"
                  type="submit"
                  className="Particular_model_submit ml-0"
                  onClick={BackBtn}
                >
                  Back
                </Index.Button>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Formik
            // enableReinitialize
            initialValues={initialValues}
            validationSchema={roleMasterValidationSchema}
            onSubmit={handleFormSubmit}
          >
            {({
              values,
              errors,
              touched,
              setFieldValue,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <form onSubmit={handleSubmit}>
                <Index.Box className="">
                  <Index.Grid container spacing={2}>
                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Role Name
                      </Index.Typography>
                      <Index.Box className="input-design-div with-border">
                        <Index.TextField
                          hiddenLabel
                          id="filled-hidden-label-normal"
                          placeholder="Role name"
                          variant="filled"
                          className="admin-input-design input-placeholder"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="roleName"
                          value={values?.roleName}
                          error={Boolean(
                            errors.roleName ? touched.roleName : undefined
                          )}
                          helperText={
                            touched.roleName ? errors.roleName : undefined
                          }
                        />
                      </Index.Box>
                    </Index.Grid>
                  </Index.Grid>

                  {/* {console.log(values.appPermission, 'Values', appCheckAll)} */}

                  <Index.Box className="main-permission-box">
                    <Index.Typography
                      variant="label"
                      component="label"
                      className="input-label"
                    >
                      App permission
                    </Index.Typography>

                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={appCheckAll}
                          onChange={(e) =>
                            handleCheckAppPermission(e, setFieldValue)
                          }
                        />
                      }
                      label="Check All"
                    />
                    <Index.Grid container spacing={2}>
                      {appRoleMaster?.length &&
                        appRoleMaster?.map((item, index) => (
                          <Index.Grid key={index} item xs={12} sm={4} md={4}>
                            <Index.Box className="">
                              <Index.Box className="input-design-div with-border role-design">
                                <FormControlLabel
                                  name={item?.tag}
                                  control={
                                    <Checkbox
                                      onChange={async (e) => {
                                        await setFieldValue(
                                          `appPermission.${item.tag}`,
                                          e.target.checked
                                        );
                                        handleAppPermissionChecked(
                                          e.target.name,
                                          e.target.checked,
                                          setFieldValue,
                                          values.appPermission
                                        );
                                      }}
                                      checked={values.appPermission[item.tag]}
                                    />
                                  }
                                  label={item?.name}
                                  labelPlacement=""
                                />
                              </Index.Box>
                            </Index.Box>
                          </Index.Grid>
                        ))}
                    </Index.Grid>
                  </Index.Box>

                  <Index.Box className="main-permission-box">
                    <Index.Typography
                      variant="label"
                      component="label"
                      className="input-label"
                    >
                      Web permission
                    </Index.Typography>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={webCheckAll}
                          onChange={(e) =>
                            handleCheckWebPermission(e, setFieldValue)
                          }
                        />
                      }
                      label="Check All"
                    />
                    <Index.Grid container spacing={2}>
                      {webRoleMaster?.length &&
                        webRoleMaster?.map(
                          (item, index) =>
                            !item?.child?.length && ( // Setup checkbox not showing
                              <Index.Grid
                                key={index}
                                item
                                xs={12}
                                sm={4}
                                md={4}
                              >
                                <Index.Box className="">
                                  <Index.Box className="input-design-div with-border role-design">
                                    <FormControlLabel
                                      name={item?.tag}
                                      control={
                                        <Checkbox
                                          onChange={async (e) => {
                                            await setFieldValue(
                                              `webPermission.${item.tag}`,
                                              e.target.checked
                                            );
                                            handleWebPermissionChecked(
                                              e.target.name,
                                              e.target.checked,
                                              setFieldValue,
                                              values.webPermission
                                            );
                                          }}
                                          checked={
                                            values.webPermission[item.tag]
                                          }
                                        />
                                      }
                                      label={item?.name}
                                      labelPlacement=""
                                    />
                                  </Index.Box>
                                </Index.Box>
                              </Index.Grid>
                            )
                        )}
                      {/* Set Up Child Master Loop */}
                      {webRoleMaster?.length &&
                        webRoleMaster?.map((item) =>
                          item?.child?.map((value, index2) => (
                            <Index.Grid key={index2} item xs={12} sm={4} md={4}>
                              <Index.Box className="">
                                <Index.Box className="input-design-div with-border role-design">
                                  <FormControlLabel
                                    name={value?.tag}
                                    control={
                                      <Checkbox
                                        onChange={async(e) => {
                                          await setFieldValue(
                                            `webPermission.${value.tag}`,
                                            e.target.checked
                                          );
                                          handleWebPermissionChecked(
                                            e.target.name,
                                            e.target.checked,
                                            setFieldValue,
                                            values.webPermission
                                          );
                                        }}
                                        checked={
                                          values.webPermission[value.tag]
                                        }
                                      />
                                    }
                                    label={`${value?.name} (${item?.name})`}
                                    labelPlacement=""
                                  />
                                </Index.Box>
                              </Index.Box>
                            </Index.Grid>
                          ))
                        )}
                    </Index.Grid>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="common-button blue-button flex-end save-btn user-submit-btn">
                  <Index.Button variant="contained" type="submit" disabled={loading}>
                    {editData ? "Update" : "Submit"}
                  </Index.Button>
                </Index.Box>
              </form>
            )}
          </Formik>
        </Index.Box>
      </Index.Box>
    </>
  );
}

export default RoleAdd;
