import React from "react";
import Index from "../../../Index";

const EnquiryPopupModel = ({ handleClose, setOpen, open, currentRows }) => {
  return (
    <div>
      <Index.Dialog
        open={open}
        onclose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="expense-add-main"
      >
        <Index.DialogContent className="expense-model-main">
          <Index.Box className="main-pending-enquiry">
            <Index.Box className="title-main">
              <Index.Typography variant="p" component="p" className="page-title">
                Pending Enquiries
              </Index.Typography>
            </Index.Box>
            <Index.Button className="modal-circle-main user-circle-icon-btn">
              <img src={Index.Svg.close} onClick={() => handleClose(setOpen(false))} className="user-circle-img user-circle-icon" alt="icon" sx={{cursor:"pointer"}} />
            </Index.Button>
          </Index.Box>
          
          <Index.Box className="title-main">
            <Index.Box className="">
              <Index.Grid container spacing={3}>
                <Index.Grid item xs={12} sm={12} md={12}>
                  <Index.TableContainer>
                    <Index.Table>
                      <Index.TableRow>
                        <Index.TableCell>Enquiry No</Index.TableCell>
                        <Index.TableCell align="left">
                          Vessel Name
                        </Index.TableCell>
                      </Index.TableRow>

                      <Index.TableBody>
                        {currentRows?.length ? (
                          currentRows?.map((item, index) => (
                            <Index.TableRow key={item?._id}>
                              <Index.TableCell>
                                {item?.inquiryNo ? item?.inquiryNo : "-"}
                              </Index.TableCell>
                              <Index.TableCell align="left">
                                {item?.vesselName ? item?.vesselName : "-"}
                              </Index.TableCell>
                            </Index.TableRow>
                          ))
                        ) : (
                          <Index.TableRow>
                            <Index.TableCell
                              component="td"
                              variant="td"
                              scope="row"
                              className="no-data-in-list"
                              colSpan={15}
                              align="center"
                            >
                              No data available
                            </Index.TableCell>
                          </Index.TableRow>
                        )}
                      </Index.TableBody>
                    </Index.Table>
                  </Index.TableContainer>
                </Index.Grid>
                <Index.Grid item xs={12} sm={12} md={12}>
                  {/* <Index.Box className="common-button blue-button flex-end save-btn party_btn add-ex-modal-btn">
                    <Index.Button
                      variant="contained"
                      type="button"
                      className="Particular_model_submit"
                      onClick={() => handleClose(setOpen(false))}
                    >
                      Cancel
                    </Index.Button>
                  </Index.Box> */}
                </Index.Grid>
              </Index.Grid>
            </Index.Box>
          </Index.Box>
        </Index.DialogContent>
      </Index.Dialog>
    </div>
  );
};

export default EnquiryPopupModel;
