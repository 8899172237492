import { useEffect, useState } from "react";
import {
  addEditCraftPreventive,
  getAllPreventionResponsibilityList,
  getAllPreventionSubHeadList,
} from "../../../../redux/admin/action";
import Index from "../../../Index";
import { FieldArray, Formik } from "formik";
import { preventiveValidationShcema } from "../../../../validation/AdminValidation";
function AddCraftPrevention() {
  const navigate = Index.useNavigate();
  const dispatch = Index.useDispatch();
  const [responsibilityCategory, setResponsibilityCategory] = useState([]);
  const [subHeadOptionsList, setSubHeadOptionsList] = useState([]);

  let { state } = Index.useLocation();
  let editData = state?.row;

  // when the get all the prevention responsibility
  const handleGetAllResponsibility = async () => {
    dispatch(getAllPreventionResponsibilityList())
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          setResponsibilityCategory(res.data);
        } else {
          setResponsibilityCategory([]);
        }
      })
      .catch((err) => {
        setResponsibilityCategory([]);
        console.error(err);
      });
  };

  // when the get all the prevention sub heads
  const handleGetAllSubHead = async () => {
    dispatch(getAllPreventionSubHeadList())
      .then((res) => {
        if (res.status === 200) {
          setSubHeadOptionsList(res.data);
        } else {
          setSubHeadOptionsList([]);
        }
      })
      .catch((error) => {
        setSubHeadOptionsList([]);
        console.error(error);
      });
  };

  // when the page first time load then used...
  useEffect(() => {
    handleGetAllResponsibility();
    handleGetAllSubHead();
  }, []);

  let initialValues = {};
  if (editData) {
    if (editData?.response?.length) {
      let preventionArray = [];
      editData?.response?.map((item, index) => {
        preventionArray.push({
          responsibility: item?.responsibilityId,
          subHeadOfPreventive: item?.subHeadId,
          jobName: item?.jobName,
          jobId: item?.jobId,
        });
      });
      console.log("preventionArray", preventionArray);
      if (preventionArray?.length) {
        initialValues = {
          id: editData?.preventiveId,
          title: editData?.preventiveName,
          remark: editData?.remark,
          preventionManagement: preventionArray,
        };
      }
    }
  } else {
    initialValues = {
      title: "",
      remark: "",
      preventionManagement: [
        {
          responsibility: "",
          subHeadOfPreventive: "",
          jobName: "",
        },
      ],
    };
  }

  // when the user submit the form then used...
  const handleFormSubmit = (values) => {
    const newPreventiveArray = values?.preventionManagement?.filter(
      (el) => el?.responsibility?.length
    );
    let newValues = {
      ...values,
      preventionManagement: newPreventiveArray,
    };
    dispatch(addEditCraftPreventive(newValues)).then((res) => {
      if (res.status === 200 || res.status === 201) {
        navigate("/admin/craftPreventive");
      }
    });
  };

  const backBtn = () => {
    navigate("/admin/craftPreventive");
  };

  // when the user change the responsibility then used...
  const handleChanResponsibility = (
    e,
    responsibilityValue,
    index,
    setFieldValue
  ) => {
    setFieldValue(
      `preventionManagement.${index}.responsibility`,
      responsibilityValue
    );
  };

  return (
    <>
      <Index.Box className="page-content-box">
        <Index.Box className="barge-common-box">
          <Index.Box className="title-header">
            <Index.Box className="title-header-flex">
              <Index.Box className="title-main mb-10">
                <Index.Typography
                  variant="p"
                  component="p"
                  className="page-title"
                >
                  {editData ? "Edit" : "Add"} Craft Preventive
                </Index.Typography>
              </Index.Box>
              <Index.Box className="common-button blue-button save-btn ">
                <Index.Button
                  variant="contained"
                  type="submit"
                  className="Particular_model_submit ml-0"
                  onClick={backBtn}
                >
                  Back
                </Index.Button>
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Formik
            enableReinitialize
            onSubmit={handleFormSubmit}
            initialValues={initialValues}
            validationSchema={preventiveValidationShcema}
          >
            {({
              values,
              errors,
              touched,
              setFieldValue,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <form onSubmit={handleSubmit}>
                <Index.Box className="">
                  {console.log("errors", errors)}
                  <Index.Grid container spacing={2}>
                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Head Of Preventive
                      </Index.Typography>
                      <Index.Box className="input-design-div with-border">
                        <Index.TextField
                          hiddenLabel
                          id="filled-hidden-label-normal"
                          placeholder="Enter Head Of Preventive"
                          variant="filled"
                          className="admin-input-design input-placeholder"
                          name="title"
                          autoComplete="off"
                          onBlur={handleBlur}
                          value={values?.title}
                          onChange={handleChange}
                          helperText={touched?.title && errors?.title}
                          error={Boolean(errors?.title && touched?.title)}
                        />
                      </Index.Box>
                    </Index.Grid>
                  </Index.Grid>

                  {values?.preventionManagement?.length > 0 && (
                    <FieldArray name="preventionManagement">
                      {({ push, remove }) => (
                        <>
                          {values.preventionManagement.map((row, index) => (
                            <Index.Grid
                              container
                              spacing={2}
                              mt={2}
                              key={index}
                            >
                              <Index.Grid item xs={12} sm={6} md={4}>
                                <Index.Typography
                                  variant="label"
                                  component="label"
                                  className="input-label"
                                >
                                  Responsibility
                                </Index.Typography>
                                <Index.Box className="input-design-div with-border">
                                  <Index.Select
                                    className="placeholder-set-text-box"
                                    fullWidth
                                    name={`preventionManagement.${index}.responsibility`}
                                    value={
                                      values.preventionManagement[index]
                                        .responsibility
                                    }
                                    displayEmpty
                                    inputProps={{
                                      "aria-label": "Without label",
                                    }}
                                    onBlur={handleBlur}
                                    onChange={(e) =>
                                      handleChanResponsibility(
                                        e,
                                        e.target.value,
                                        index,
                                        setFieldValue
                                      )
                                    }
                                    // helperText={
                                    //   touched &&
                                    //   touched.preventionManagement &&
                                    //   touched.preventionManagement[index] &&
                                    //   touched.preventionManagement[index]
                                    //     .responsibility &&
                                    //   errors &&
                                    //   errors.preventionManagement &&
                                    //   errors.preventionManagement[index] &&
                                    //   errors.preventionManagement[index]
                                    //     .responsibility
                                    // }
                                    // error={Boolean(
                                    //   touched &&
                                    //     touched.preventionManagement &&
                                    //     touched.preventionManagement[index] &&
                                    //     touched.preventionManagement[index]
                                    //       .responsibility &&
                                    //     errors &&
                                    //     errors.preventionManagement &&
                                    //     errors.preventionManagement[index] &&
                                    //     errors.preventionManagement[index]
                                    //       .responsibility
                                    // )}
                                  >
                                    <Index.MenuItem value={""}>
                                      <Index.MenuItem
                                        disabled
                                        className="all-select-label"
                                      >
                                        Select Responsibility
                                      </Index.MenuItem>
                                    </Index.MenuItem>
                                    {responsibilityCategory?.map(
                                      (items, index) => (
                                        <Index.MenuItem
                                          value={items?._id}
                                          key={items?._id}
                                        >
                                          {items?.name}
                                        </Index.MenuItem>
                                      )
                                    )}
                                  </Index.Select>
                                </Index.Box>
                                {errors &&
                                  errors.preventionManagement &&
                                  errors.preventionManagement[index] &&
                                  errors.preventionManagement[index]
                                    .responsibility &&
                                  touched &&
                                  touched.preventionManagement &&
                                  touched.preventionManagement[index] &&
                                  touched.preventionManagement[index]
                                    .responsibility && (
                                    <p className="error-message">
                                      {
                                        errors.preventionManagement[index]
                                          .responsibility
                                      }
                                    </p>
                                  )}
                              </Index.Grid>

                              <Index.Grid item xs={12} sm={6} md={4}>
                                <Index.Typography
                                  variant="label"
                                  component="label"
                                  className="input-label"
                                >
                                  Sub Head Of Preventive
                                </Index.Typography>
                                <Index.Box className="input-design-div with-border">
                                  <Index.Select
                                    className="placeholder-set-text-box"
                                    fullWidth
                                    name={`preventionManagement.${index}.subHeadOfPreventive`}
                                    value={
                                      values.preventionManagement[index]
                                        .subHeadOfPreventive
                                    }
                                    displayEmpty
                                    inputProps={{
                                      "aria-label": "Without label",
                                    }}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    // helperText={
                                    //   touched &&
                                    //   touched.preventionManagement &&
                                    //   touched.preventionManagement[index] &&
                                    //   touched.preventionManagement[index]
                                    //     .subHeadOfPreventive &&
                                    //   errors &&
                                    //   errors.preventionManagement &&
                                    //   errors.preventionManagement[index] &&
                                    //   errors.preventionManagement[index]
                                    //     .subHeadOfPreventive
                                    // }
                                    // error={Boolean(
                                    //   errors &&
                                    //     errors.preventionManagement &&
                                    //     errors.preventionManagement[index] &&
                                    //     errors.preventionManagement[index]
                                    //       .subHeadOfPreventive &&
                                    //     touched &&
                                    //     touched.preventionManagement &&
                                    //     touched.preventionManagement[index] &&
                                    //     touched.preventionManagement[index]
                                    //       .subHeadOfPreventive
                                    // )}
                                  >
                                    <Index.MenuItem value={""}>
                                      <Index.MenuItem
                                        disabled
                                        className="all-select-label"
                                      >
                                        Select Sub Head Of Preventive
                                      </Index.MenuItem>
                                    </Index.MenuItem>
                                    {subHeadOptionsList?.map((items, index) => (
                                      <Index.MenuItem
                                        value={items?._id}
                                        key={items?._id}
                                      >
                                        {items?.name}
                                      </Index.MenuItem>
                                    ))}
                                  </Index.Select>
                                  {errors &&
                                    errors.preventionManagement &&
                                    errors.preventionManagement[index] &&
                                    errors.preventionManagement[index]
                                      .subHeadOfPreventive &&
                                    touched &&
                                    touched.preventionManagement &&
                                    touched.preventionManagement[index] &&
                                    touched.preventionManagement[index]
                                      .subHeadOfPreventive && (
                                      <p className="error-message">
                                        {
                                          errors.preventionManagement[index]
                                            .subHeadOfPreventive
                                        }
                                      </p>
                                    )}
                                </Index.Box>
                              </Index.Grid>

                              <Index.Grid item xs={12} sm={6} md={2}>
                                <Index.Typography
                                  variant="label"
                                  component="label"
                                  className="input-label"
                                >
                                  Job Name
                                </Index.Typography>
                                <Index.Box className="input-design-div with-border">
                                  <Index.TextField
                                    hiddenLabel
                                    id="filled-hidden-label-normal"
                                    placeholder="Enter Job Name"
                                    variant="filled"
                                    className="admin-input-design input-placeholder"
                                    name={`preventionManagement.${index}.jobName`}
                                    value={
                                      values.preventionManagement[index].jobName
                                    }
                                    autoComplete="off"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    helperText={
                                      touched &&
                                      touched.preventionManagement &&
                                      touched.preventionManagement[index] &&
                                      touched.preventionManagement[index]
                                        .jobName &&
                                      errors &&
                                      errors.preventionManagement &&
                                      errors.preventionManagement[index] &&
                                      errors.preventionManagement[index].jobName
                                    }
                                    error={Boolean(
                                      errors &&
                                        errors.preventionManagement &&
                                        errors.preventionManagement[index] &&
                                        errors.preventionManagement[index]
                                          .jobName &&
                                        touched &&
                                        touched.preventionManagement &&
                                        touched.preventionManagement[index] &&
                                        touched.preventionManagement[index]
                                          .jobName
                                    )}
                                  />
                                </Index.Box>
                              </Index.Grid>

                              {!editData ? (
                                <>
                                  <Index.Grid item xs={12} sm={6} md={2}>
                                    <Index.Typography
                                      variant="label"
                                      component="label"
                                      className="input-label"
                                    ></Index.Typography>

                                    <Index.Box className="incre-btn-main">
                                      {index ===
                                      values?.preventionManagement?.length -
                                        1 ? (
                                        <>
                                          <Index.Button
                                            sx={{ fontSize: "20px" }}
                                            className="button-add inc-dic-btn"
                                            type="button"
                                            onClick={() =>
                                              push({
                                                responsibility: "",
                                                subHeadOfPreventive: "",
                                                jobName: "",
                                              })
                                            }
                                          >
                                            +
                                          </Index.Button>
                                        </>
                                      ) : (
                                        <></>
                                      )}

                                      {values?.preventionManagement?.length >
                                        1 && (
                                        <Index.Button
                                          sx={{ fontSize: "20px" }}
                                          type="button"
                                          className="button-remove inc-dic-btn"
                                          disabled={
                                            values?.preventionManagement
                                              ?.length > 1
                                              ? false
                                              : true
                                          }
                                          onClick={() => remove(index)}
                                        >
                                          -
                                        </Index.Button>
                                      )}
                                    </Index.Box>
                                  </Index.Grid>
                                </>
                              ) : (
                                <></>
                              )}
                            </Index.Grid>
                          ))}
                        </>
                      )}
                    </FieldArray>
                  )}

                  <Index.Grid container spacing={2} sx={{ marginTop: "20px" }}>
                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Remark
                      </Index.Typography>
                      <Index.Box className="input-design-div with-border">
                        <Index.TextField
                          hiddenLabel
                          id="filled-hidden-label-normal"
                          placeholder="Enter Remark"
                          variant="filled"
                          className="admin-input-design input-placeholder"
                          name="remark"
                          autoComplete="off"
                          onBlur={handleBlur}
                          value={values?.remark}
                          onChange={handleChange}
                          helperText={touched?.remark && errors?.remark}
                          error={Boolean(errors?.remark && touched?.remark)}
                        />
                      </Index.Box>
                    </Index.Grid>
                  </Index.Grid>

                  <Index.Box className="line" mt={3} mb={3}></Index.Box>
                  <Index.Box className="common-button blue-button flex-end save-btn">
                    <Index.Button variant="contained" type="submit">
                      {editData ? "Update" : "Submit"}
                    </Index.Button>
                  </Index.Box>
                </Index.Box>
              </form>
            )}
          </Formik>
        </Index.Box>
      </Index.Box>
    </>
  );
}

export default AddCraftPrevention;
