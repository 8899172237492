import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import { getSalesMasterList, deleteSalesMaster } from "../../../../redux/admin/action";
import copyTextToClipboard from "copy-text-to-clipboard";
import { toast } from "react-toastify";

const Search = Index.styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: Index.alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: Index.alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));
const ActionItem = Index.styled(Index.MenuItem)(() => ({
  fontFamily: "poppins",
  lineHeight: "15px",
  fontSize: "14px",
  fontWeight: "400",
  color: "#595F69",
}));
const StyledInputBase = Index.styled(Index.InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

function SalesMasterList() {
  const dispatch = Index.useDispatch();
  const navigate = Index.useNavigate();
  const [loading,setLoading] = useState(false)
  const { salesMasterList } = Index.useSelector((state) => state.AdminReducer);

  // Delete record state
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [rowId, setRowId] = useState("");

  // State for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState(0);
  const [paginationPerPage, setPaginationPerPage] = useState(50);

  // State for searching and set data
  const [searchedData, setSearchedData] = useState([]);
  const [currentRows, setCurrentRows] = useState([]);

  const [anchorEl, setAnchorEl] = useState(null);
  const [actionIndex, setActionIndex] = useState();

  const handleClickMenu = (e, index) => {
    setActionIndex(index);
    setAnchorEl(e.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  // get sales master
  const getSalesList = () => {
    setLoading(true)
    dispatch(getSalesMasterList()).then((res) => {
      if (res?.status === 200) {
        setTimeout(() => {
            setLoading(false);
        },2000)
      } else {
         setTimeout(() => {
           setLoading(false);
         }, 2000);
      }
    });
  }

  useEffect(() => {
    getSalesList();
  }, []);

  // Copy original data to searched data
  useEffect(() => {
    setSearchedData(salesMasterList?.sort((a, b) => b?.updatedAt?.localeCompare(a?.updatedAt)));
  }, [salesMasterList]);

  // Search on table
  const requestSearch = (searched) => {
    if (!searched) return setSearchedData(salesMasterList);
    setSearchedData(
      salesMasterList?.filter(
        (item) =>
          item?.salesName
            ?.toString()
            .toLowerCase()
            .includes(searched.toLowerCase()) ||
          item?.salesEmail
            ?.toString()
            ?.toLowerCase()
            .includes(searched.toLowerCase()) ||
          item?.phoneNumber
            ?.toString()
            .toLowerCase()
            .includes(searched?.toLowerCase()) ||
          item?.terminal
            ?.toString()
            .toLowerCase()
            .includes(searched?.toLowerCase())
      )
    );
    setCurrentPage(1);
  };

  // Pagination calculation start
  const PER_PAGE = paginationPerPage;
  useEffect(() => {
    //Page count
    const count = Math.ceil(searchedData?.length / PER_PAGE);
    setCount(count);
    const indexOfLastRow = currentPage * PER_PAGE;
    const indexOfFirstRow = indexOfLastRow - PER_PAGE;
    const modifiedRows = searchedData?.slice(indexOfFirstRow, indexOfLastRow);
    setCurrentRows(modifiedRows);
    if (modifiedRows?.length === 0 && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  }, [currentPage, searchedData, paginationPerPage, PER_PAGE]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };
  // Pagination calculation end

  const handleDeleteOpen = () => {
    setAnchorEl(null);
    setDeleteOpen(true);
  };

  const handleDeleteClose = () => {
    setDeleteOpen(false);
  };

  const handleDeleteRecord = () => {
    const data = {
      id: rowId,
    };
    dispatch(deleteSalesMaster(data));
    handleDeleteClose();
  };

  const handleEdit = (item) => {
    navigate("/admin/salesMaster/add", {
      state: { row: item },
    });
  };
  
  const handleCopyClick = (item) => {
    const id = item?._id;
    copyTextToClipboard(
      `${process.env.REACT_APP_FRONTEND_URL}sales/enq/list/${id}`
    );
    // Show a success message when text is copied
    toast.success("Link copied !", {
      position: toast.POSITION.TOP_RIGHT, // You can change the position as needed
      autoClose: 2000, // Optional: Close the message after 2 seconds
    });
  };
  
  return (
    <>
      <Index.DynamicTitle title="Sales Master" />
      <Index.Box className="">
        <Index.Box className="barge-common-box">
          <Index.Box className="title-header">
            <Index.Box className="title-header-flex res-title-header-flex">
              <Index.Box className="title-main">
                <Index.Typography
                  variant="p"
                  component="p"
                  className="page-title"
                >
                  Sales Master List
                </Index.Typography>
              </Index.Box>
              <Index.Box className="d-flex align-items-center res-set-search">
                <Search className="search ">
                  <StyledInputBase
                    placeholder="Search"
                    inputProps={{ "aria-label": "search" }}
                    onChange={(e) =>
                      setTimeout(() => {
                        requestSearch(e.target.value.trim());
                      }, 1000)
                    }
                  />
                </Search>

                <Index.Box className="common-button blue-button res-blue-button">
                  <Index.RouteLink
                    to="/admin/salesMaster/add"
                    className="no-text-decoration"
                  >
                    {" "}
                    <Index.Button variant="contained" disableRipple>
                      Add Sales
                    </Index.Button>
                  </Index.RouteLink>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Index.Box className="">
            <Index.TableContainer
              component={Index.Paper}
              className="table-container"
            >
              <Index.Table
                aria-label="simple table"
                className="table-design-main supply-table one-line-table invoice-table-set user-management-table trader-list sales-copy-btn-main"
              >
                <Index.TableHead>
                  <Index.TableRow>
                    <Index.TableCell align="left">Sr. No</Index.TableCell>
                    <Index.TableCell align="left">Sales Name</Index.TableCell>
                    <Index.TableCell align="left">Email</Index.TableCell>
                    <Index.TableCell align="left">Phone Number</Index.TableCell>
                    <Index.TableCell align="left">Terminal</Index.TableCell>
                    <Index.TableCell align="left">URL Link</Index.TableCell>
                    <Index.TableCell align="left">Action</Index.TableCell>
                  </Index.TableRow>
                </Index.TableHead>
                {loading ? (
                  <Index.TableBody>
                    <Index.TableRow>
                      <Index.TableCell colSpan={14} align="center">
                        <Index.CircularProgress color="secondary" size={20} />
                      </Index.TableCell>
                    </Index.TableRow>
                  </Index.TableBody>
                ) : (
                  <Index.TableBody>
                    {currentRows?.length ? (
                      currentRows?.map((item, index) => (
                        <Index.TableRow key={item?._id}>
                          <Index.TableCell>{index + 1}</Index.TableCell>
                          <Index.TableCell>
                            {item?.salesName ? item?.salesName : "-"}
                          </Index.TableCell>

                          <Index.TableCell>
                            {item?.salesEmail ? item?.salesEmail : "-"}
                          </Index.TableCell>

                          <Index.TableCell align="left">
                            {item?.phoneNumber ? item?.phoneNumber : "-"}
                          </Index.TableCell>

                          <Index.TableCell align="left">
                            {item?.terminal ? item?.terminal : "-"}
                          </Index.TableCell>

                          <Index.TableCell
                            // align="left"
                            // onClick={() => {
                            //   handleCopyClick(item);
                            // }}
                            // sx={{ cursor: "pointer" }}
                          >
                            {/* Copy Link */}
                            <Index.Tooltip title="Copy Link">
                           <Index.IconButton
                           onClick={() => {
                              handleCopyClick(item);
                            }}
                           >
                              <Index.ContentCopyIcon
                                className="edit-icon-set"
                                // onClick={() => handleOpen(row)}
                              />
                              
                            </Index.IconButton>
                            </Index.Tooltip>
                          </Index.TableCell>

                          <Index.TableCell sx={{ display: "flex" }}>
                            <Index.IconButton
                              onClick={(e) => {
                                handleClickMenu(e, index);
                              }}
                            >
                              <Index.MenuIcon className="action-menu-icon" />
                            </Index.IconButton>
                            <Index.Menu
                              id="demo-positioned-menu"
                              aria-labelledby="demo-positioned-button"
                              anchorEl={anchorEl}
                              keepMounted
                              elevation={2}
                              open={Boolean(anchorEl)}
                              onClose={handleCloseMenu}
                              anchorOrigin={{
                                vertical: "top",
                                horizontal: "left",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "left",
                              }}
                            >
                              <ActionItem
                                className="action-text"
                                onClick={() => {
                                  handleEdit(currentRows?.[actionIndex]);
                                }}
                              >
                                Edit
                              </ActionItem>
                              <ActionItem
                                className="action-text"
                                onClick={() => {
                                  setRowId(currentRows?.[actionIndex]?._id);
                                  handleDeleteOpen();
                                }}
                              >
                                Delete
                              </ActionItem>
                            </Index.Menu>
                          </Index.TableCell>
                        </Index.TableRow>
                      ))
                    ) : (
                      <Index.TableRow>
                        <Index.TableCell
                          component="td"
                          variant="td"
                          scope="row"
                          className="no-data-in-list"
                          colSpan={15}
                          align="center"
                        >
                          No data available
                        </Index.TableCell>
                      </Index.TableRow>
                    )}
                  </Index.TableBody>
                )}
              </Index.Table>
            </Index.TableContainer>
          </Index.Box>
          <Index.Box className="pagination-design flex-end">
            <Index.Stack spacing={2}>
              <Index.Box className="pagination-count">
                <Index.Box className="input-design-div with-border">
                  <Index.Select
                    fullWidth
                    value={paginationPerPage}
                    onChange={(e) => {
                      setPaginationPerPage(e.target.value);
                    }}
                  >
                    <Index.MenuItem value={10}>10</Index.MenuItem>
                    <Index.MenuItem value={25}>25</Index.MenuItem>
                    <Index.MenuItem value={50}>50</Index.MenuItem>
                    <Index.MenuItem value={100}>100</Index.MenuItem>
                  </Index.Select>
                </Index.Box>
                <Index.Pagination
                  count={count}
                  page={currentPage}
                  onChange={handlePageChange}
                />
              </Index.Box>
            </Index.Stack>
          </Index.Box>
        </Index.Box>
      </Index.Box>

      <Index.DeleteModal
        deleteOpen={deleteOpen}
        handleDeleteRecord={handleDeleteRecord}
        handleDeleteClose={handleDeleteClose}
      />
    </>
  );
}

export default SalesMasterList;
