import React, { useState, useEffect } from "react";
import Index from "../../../Index";
import {
  getCompaniesWiseBankDetails,
  getCompanyTroughTallyBanks,
  getTallyBankThorughBankRecords,
} from "../../../../redux/admin/action";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 150,
    },
  },
};

function TallyCompanyWiseBankData() {
  const dispatch = Index.useDispatch();
  const navigate = Index.useNavigate();
  const { id } = Index.useParams();
  const [loading, setLoading] = useState(false);

  const [banksOptions, setBankOptions] = useState([]);
  const [selectedBankOption, setSelectedBankOption] = useState("select-bank");

  const [bankOptionsData, setBankOptionsData] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [paginationPerPage, setPaginationPerPage] = useState(50);
  const [totalRecordCount, setTotalRecordCount] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);

  const [bankDetails, setBankDetails] = useState("");

  //   when the get bank option through data then used...
  const handleGetBankOptionThorughData = (values) => {
    setLoading(true);
    dispatch(getTallyBankThorughBankRecords(values))
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          setBankOptionsData(res.data);
          setLoading(false);
          setCurrentPage(res.currentPage);
          setTotalRecordCount(res.totalCount);
          setTotalAmount(res?.financeBalance || 0);
        } else {
          setBankOptionsData([]);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error(error);
        setBankOptionsData([]);
        setLoading(false);
      });
  };

  // When the get bank wise full detail of bank data then used...
  const handleGetBankDetails = (partyName) => {
    dispatch(getCompaniesWiseBankDetails({ partyName }))
      .then((res) => {
        if (res.status === 200) {
          setBankDetails(res.data);
        } else {
          setBankDetails("");
        }
      })
      .catch((error) => {
        setBankDetails("");
        console.error(error);
      });
  };

  //   when the get company list then used...
  const handleGetCompanyList = () => {
    dispatch(getCompanyTroughTallyBanks({ companyName: id }))
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          setBankOptions(res.data);
          if (res.data?.length > 0) {
            const values = {
              page: 1,
              size: paginationPerPage,
              partyName: res?.data[0]?.guid,
            };
            handleGetBankOptionThorughData(values);
            // when the get bank details then used...
            handleGetBankDetails(res?.data[0]?.guid);
            setSelectedBankOption(res?.data[0]);
          }
        } else {
          setBankOptions([]);
        }
      })
      .catch((error) => {
        console.error(error);
        setBankOptions([]);
      });
  };

  //   whent the firstime page is loading then used...
  useEffect(() => {
    handleGetCompanyList();
  }, []);

  // when the user change the bank option then used...
  const handleChangeBankOption = (event) => {
    const { value } = event.target;
    setSelectedBankOption(value);

    // when the get bank details then used...
    handleGetBankDetails(value?.guid);

    if (value !== "select-bank") {
      const values = {
        page: 1,
        size: paginationPerPage,
        partyName: value?.guid,
        // guid:
      };
      handleGetBankOptionThorughData(values);
    } else if (value === "select-bank") {
      // when the user select this then default set blank
      setBankOptionsData([]);
    }
  };

  // when the user change the per page record change then used..
  const handleChangePaginationPerChange = (e) => {
    const { value } = e.target;
    setPaginationPerPage(value);
    const values = {
      page: 1,
      size: value,
      partyName: selectedBankOption?.guid,
    };
    handleGetBankOptionThorughData(values);
  };

  // when the user change the page then used...
  const handlePageChange = React.useCallback(
    (event, value) => {
      setCurrentPage(value);
      const values = {
        page: value,
        size: paginationPerPage,
        partyName: selectedBankOption?.guid,
      };
      handleGetBankOptionThorughData(values);
    },
    [paginationPerPage, setCurrentPage, selectedBankOption]
  );

  return (
    <>
      <Index.DynamicTitle title="Tally Company Wise Bank Data" />
      <Index.Box className="">
        <Index.Box className="barge-common-box">
          <Index.Box className="title-header">
            <Index.Box className="title-header-flex res-title-header-flex">
              <Index.Box className="title-main">
                <Index.Typography
                  variant="p"
                  component="p"
                  className="page-title"
                >
                  Tally Company Wise Bank Data
                </Index.Typography>
              </Index.Box>
              <Index.Box className="d-flex align-items-center res-set-search">
                <Index.Box className="common-button blue-button res-blue-button">
                  <Index.Select
                    value={selectedBankOption}
                    onChange={handleChangeBankOption}
                    sx={{ minWidth: "135px" }}
                    MenuProps={MenuProps}
                    className="placeholder-set-text-box"
                  >
                    <Index.MenuItem value="select-bank">
                      Select Bank
                    </Index.MenuItem>
                    {banksOptions?.map((item) => (
                      <Index.MenuItem value={item} key={item?.guid}>
                        {item?.name}
                      </Index.MenuItem>
                    ))}
                  </Index.Select>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          {/* <Index.Box className="d-flex align-items-center res-set-search flex-bank-details"> */}
          {bankDetails !== "" ? (
            <>
              <Index.Box className="mini-card-main mini-card-main-bank-details card-wrapper">
                <Index.Box className="mini-card-bank-details">
                  <Index.Box className="card-right-content">
                    <Index.Typography className="card-total" component="p">
                      Opening Balance
                    </Index.Typography>
                    <Index.Typography className="card-price">
                      ₹
                      {bankDetails?.openingBalance.toLocaleString("en-IN", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="mini-card-bank-details">
                  <Index.Box className="card-right-content">
                    <Index.Typography className="card-total" component="p">
                      Total Payment
                    </Index.Typography>
                    <Index.Typography className="card-price">
                      ₹
                      {parseInt(bankDetails?.paymentTotal).toLocaleString(
                        "en-IN",
                        {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }
                      )}
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="mini-card-bank-details">
                  <Index.Box className="card-right-content">
                    <Index.Typography className="card-total" component="p">
                      Total Receipt
                    </Index.Typography>
                    <Index.Typography className="card-price">
                      ₹
                      {bankDetails?.receiptTotal.toLocaleString("en-IN", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="mini-card-bank-details">
                  <Index.Box className="card-right-content">
                    <Index.Typography className="card-total" component="p">
                      Total Contra
                    </Index.Typography>
                    <Index.Typography className="card-price">
                      ₹
                      {bankDetails?.contraTotal.toLocaleString("en-IN", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
                <Index.Box className="mini-card-bank-details">
                  <Index.Box className="card-right-content">
                    <Index.Typography className="card-total" component="p">
                      Closing Balance
                    </Index.Typography>
                    <Index.Typography className="card-price">
                      ₹
                      {bankDetails?.closingBalance.toLocaleString("en-IN", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </>
          ) : (
            <></>
          )}
          {/* </Index.Box> */}
          <Index.Box className="">
            <Index.TableContainer
              component={Index.Paper}
              className="table-container"
            >
              <Index.Table
                aria-label="simple table"
                className="table-design-main supply-table one-line-table invoice-table-set user-management-table "
              >
                <Index.TableHead>
                  <Index.TableRow>
                    <Index.TableCell>No</Index.TableCell>
                    <Index.TableCell>Date</Index.TableCell>
                    <Index.TableCell align="right">
                      Credit Amount
                    </Index.TableCell>
                    <Index.TableCell align="right">
                      Debit Amount
                    </Index.TableCell>
                    <Index.TableCell>Party Name</Index.TableCell>
                    <Index.TableCell>Ledger</Index.TableCell>
                    <Index.TableCell>Voucher Type</Index.TableCell>
                    <Index.TableCell align="left">Narration</Index.TableCell>
                  </Index.TableRow>
                </Index.TableHead>
                {loading ? (
                  <Index.TableBody>
                    <Index.TableRow>
                      <Index.TableCell
                        colSpan={12}
                        align="center"
                        className="loading-purchase"
                      >
                        <Index.CircularProgress color="secondary" size={20} />
                      </Index.TableCell>
                    </Index.TableRow>
                  </Index.TableBody>
                ) : (
                  <Index.TableBody>
                    {bankOptionsData?.length ? (
                      bankOptionsData?.map((item, index) => (
                        <Index.TableRow key={item?._id}>
                          <Index.TableCell>{index + 1}</Index.TableCell>
                          <Index.TableCell>
                            {item?.date
                              ? Index.moment(item?.date).format("DD/MM/YYYY")
                              : "-"}
                          </Index.TableCell>
                          <Index.TableCell className="invoice-view-cursor ">
                            {/* {item?.voucher_type === "Payment" ||
                            item?.voucher_type === "Contra"
                              ? item?.amount
                                ? item?.amount
                                : "-"
                              : "-"} */}
                            {item?.voucher_type === "Payment"
                              ? item?.amount
                                ? item?.amount
                                : "-"
                              : item?.voucher_type === "Contra"
                              ? item?.amount > 0
                                ? item?.amount
                                : "-"
                              : "-"}
                          </Index.TableCell>
                          <Index.TableCell className="invoice-view-cursor ">
                            {item?.voucher_type === "Receipt"
                              ? item?.amount
                                ? item?.amount * -1
                                : "-"
                              : item?.voucher_type === "Contra"
                              ? item?.amount < 0
                                ? item?.amount * -1
                                : "-"
                              : "-"}
                          </Index.TableCell>
                          <Index.TableCell className="invoice-view-cursor">
                            {item?.party_name ? item?.party_name : "-"}
                          </Index.TableCell>
                          <Index.TableCell>
                            {item?.ledger ? item?.ledger : "-"}
                          </Index.TableCell>
                          <Index.TableCell>
                            {item?.voucher_type ? item?.voucher_type : "-"}
                          </Index.TableCell>
                          <Index.TableCell>
                            {item?.narration ? item?.narration : "-"}
                          </Index.TableCell>
                        </Index.TableRow>
                      ))
                    ) : (
                      <Index.TableRow>
                        <Index.TableCell
                          component="td"
                          variant="td"
                          scope="row"
                          className="no-data-in-list"
                          colSpan={15}
                          align="center"
                        >
                          No data available
                        </Index.TableCell>
                      </Index.TableRow>
                    )}
                  </Index.TableBody>
                )}
              </Index.Table>
            </Index.TableContainer>
          </Index.Box>
          <Index.Box className="pagination-design flex-end">
            <Index.Stack spacing={2}>
              <Index.Box className="pagination-count">
                <Index.Box className="input-design-div with-border">
                  <Index.Select
                    fullWidth
                    value={paginationPerPage}
                    onChange={(e) => handleChangePaginationPerChange(e)}
                  >
                    <Index.MenuItem value={10}>10</Index.MenuItem>
                    <Index.MenuItem value={25}>25</Index.MenuItem>
                    <Index.MenuItem value={50}>50</Index.MenuItem>
                    <Index.MenuItem value={100}>100</Index.MenuItem>
                    <Index.MenuItem value={1000}>1000</Index.MenuItem>
                  </Index.Select>
                </Index.Box>
                {loading ? (
                  <Index.CircularProgress
                    color="secondary"
                    sx={{ marginLeft: "10px" }}
                    size={26}
                  />
                ) : (
                  <>
                    <Index.Pagination
                      count={Math.ceil(totalRecordCount / paginationPerPage)}
                      page={currentPage}
                      onChange={handlePageChange}
                    />
                  </>
                )}
              </Index.Box>
            </Index.Stack>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
}

export default TallyCompanyWiseBankData;
