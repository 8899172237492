import { useEffect, useState } from "react";
import Index from "../../../../Index";
import { getOrderWiseTransferDetails } from "../../../../../redux/admin/action";

function OrderTransfer({ params }) {
  const dispatch = Index.useDispatch();
  const [orderTransferList, setOrderTransferList] = useState([]);
  const [loading, setLoading] = useState(false);

  // when the page first time called...
  useEffect(() => {
    handleGetOrderTransferList(params);
  }, []);

  // when the user want to order transfer list then used..
  const handleGetOrderTransferList = (values) => {
    setLoading(true);
    dispatch(getOrderWiseTransferDetails(values))
      .then((res) => {
        if (res.status === 200) {
          setOrderTransferList(res.data);
          setLoading(false);
        } else {
          setOrderTransferList([]);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error(error);
        setOrderTransferList([]);
        setLoading(false);
      });
  };

  return (
    <>
      <Index.TableContainer>
        <Index.Table className="challan-table challan-table-set challan-table-font loading-table ">
          <Index.TableHead>
            <Index.TableRow>
              <Index.TableCell>Time Stamp</Index.TableCell>
              <Index.TableCell>Vessel Name</Index.TableCell>
              <Index.TableCell>QTY</Index.TableCell>
              <Index.TableCell>Transfer Start time</Index.TableCell>
              <Index.TableCell>Transfer completed time</Index.TableCell>
              <Index.TableCell>Remark</Index.TableCell>
            </Index.TableRow>
          </Index.TableHead>
          {loading ? (
            <Index.TableBody>
              <Index.TableRow>
                <Index.TableCell
                  colSpan={8}
                  align="center"
                  className="loading-purchase"
                >
                  <Index.CircularProgress color="secondary" size={20} />
                </Index.TableCell>
              </Index.TableRow>
            </Index.TableBody>
          ) : (
            <Index.TableBody>
              {orderTransferList?.length ? (
                orderTransferList?.map((row, index) => {
                  return (
                    <Index.TableRow key={row?._id}>
                      <Index.TableCell>
                        {row?.createdAt
                          ? Index.moment(row?.createdAt).format(
                              "DD/MM/YYYY hh:mm:ss"
                            )
                          : "-"}
                      </Index.TableCell>

                      <Index.TableCell>
                        {row?.orderId?.vesselName
                          ? row?.orderId?.vesselName
                          : "-"}
                      </Index.TableCell>
                      <Index.TableCell>
                        {row?.quantity ? row?.quantity : "-"}
                      </Index.TableCell>
                      <Index.TableCell>
                        {row?.transferStart
                          ? Index.moment(row?.transferStart).format(
                              "DD/MM/YYYY hh:mm:ss"
                            )
                          : "-"}
                      </Index.TableCell>
                      <Index.TableCell>
                        {row?.transferComplete
                          ? Index.moment(row?.transferComplete).format(
                              "DD/MM/YYYY hh:mm:ss"
                            )
                          : "-"}
                      </Index.TableCell>
                      <Index.TableCell>
                        {row?.remark ? row?.remark : "-"}
                      </Index.TableCell>
                    </Index.TableRow>
                  );
                })
              ) : (
                <>
                  <Index.TableRow>
                    <Index.TableCell
                      component="td"
                      variant="td"
                      scope="row"
                      className="no-data-in-list empty-record-align"
                      colSpan={8}
                      align="center"
                    >
                      No data available
                    </Index.TableCell>
                  </Index.TableRow>
                </>
              )}
            </Index.TableBody>
          )}
        </Index.Table>
      </Index.TableContainer>
    </>
  );
}

export default OrderTransfer;
