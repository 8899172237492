import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import * as Yup from "yup";
import { Formik } from "formik";
import { Export_excel, NEW_EXPORT_EXCEL } from "../ExportAll";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import {
  addKotakBanksheet,
  deleteKotakBankSheet,
  deleteKotakSheet,
  getKotakBankList,
  getDashboardKOTAKBankList,
} from "../../../../redux/admin/action";
import PageIndex from "../../../PageIndex";
import dayjs from "dayjs";
import { SingleInputDateRangeField } from "@mui/x-date-pickers-pro/SingleInputDateRangeField";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";

const HtmlTooltip = Index.styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}));

const Search = Index.styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: Index.alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: Index.alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));
const shortcutsItems = [
  {
    label: "This Week",
    getValue: () => {
      const today = dayjs();
      return [today.startOf("week"), today.endOf("week")];
    },
  },
  {
    label: "Last Week",
    getValue: () => {
      const today = dayjs();
      const prevWeek = today.subtract(7, "day");
      return [prevWeek.startOf("week"), prevWeek.endOf("week")];
    },
  },
  {
    label: "Last 7 Days",
    getValue: () => {
      const today = dayjs();
      return [today.subtract(7, "day"), today];
    },
  },
  {
    label: "Current Month",
    getValue: () => {
      const today = dayjs();
      return [today.startOf("month"), today.endOf("month")];
    },
  },
  {
    label: "Next Month",
    getValue: () => {
      const today = dayjs();
      const startOfNextMonth = today.endOf("month").add(1, "day");
      return [startOfNextMonth, startOfNextMonth.endOf("month")];
    },
  },
  { label: "Reset", getValue: () => [null, null] },
];

const StyledInputBase = Index.styled(Index.InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

const KotakBankList = () => {
  const dispatch = Index.useDispatch();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [openSheetModel, setOpenSheetModel] = useState(false);
  const [exportDetails, setExportDetails] = useState([]);
  const [nominationFromDate, setNominationFromDate] = useState();
  const [nominationToDate, setNominationToDate] = useState();
  const [nominationFromError, setNominationFromError] = useState();
  const [nominationToError, setNominationToError] = useState();
  const [rowId, setRowId] = useState("");
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [deleteSheetModelOpen, setDeleteSheetModelOpen] = useState(false);
  const [remarkData, setRemarkData] = useState("");

  // State for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState(0);
  const [paginationPerPage, setPaginationPerPage] = useState(50);

  const [kotakBankDataList, setKotakBankDataList] = useState([]);
  const [serachedDateRange, setSearchedDateRange] = useState("");
  const [searchingText, setSearchingText] = useState("");

  // State for searching and set data
  const [searchedData, setSearchedData] = useState([]);
  const [currentRows, setCurrentRows] = useState([]);

  // filter
  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const handleClose = () => {
    setOpen(false);
  };

  // delete sheet model close
  const handleSheetDeleteClose = () => {
    setDeleteSheetModelOpen(false);
  };

  // when the user get the kotak bank data then used....
  const handleGetKotakBankData = (values) => {
    setLoading(true);
    dispatch(getDashboardKOTAKBankList(values))
      .then((res) => {
        if (res.status === 200) {
          setKotakBankDataList(res.data);
          setLoading(false);
          setCurrentPage(parseInt(res?.page));
          setCount(res?.countData);
        } else {
          setKotakBankDataList([]);
          setLoading(false);
        }
      })
      .catch((error) => {
        setKotakBankDataList([]);
        setLoading(false);
        console.error("Kotak bank data fetch time error", error);
      });
  };

  // when the page first time load then used...
  useEffect(() => {
    const values = {
      page: 1,
      search: "",
      size: paginationPerPage,
      to:
        serachedDateRange?.length > 0
          ? serachedDateRange[0].format("YYYY-MM-DD")
          : "",
      from:
        serachedDateRange?.length > 0
          ? serachedDateRange[1].format("YYYY-MM-DD")
          : "",
    };
    handleGetKotakBankData(values);
  }, []);

  const handlePageChange = React.useCallback(
    (event, value) => {
      setCurrentPage(value);
      const values = {
        page: value,
        search: searchingText,
        size: paginationPerPage,
        to:
          serachedDateRange?.length > 0
            ? serachedDateRange[0].format("YYYY-MM-DD")
            : "",
        from:
          serachedDateRange?.length > 0
            ? serachedDateRange[1].format("YYYY-MM-DD")
            : "",
      };
      handleGetKotakBankData(values);
    },
    [paginationPerPage, searchingText, serachedDateRange, setCurrentPage]
  );

  const handleOpen = (id) => {
    setRemarkData(id);
    setOpen(true);
  };
  const deleteSheet = () => {
    const data = new URLSearchParams();
    data.append("type", "kotak");
    dispatch(deleteKotakSheet(data));
    handleSheetDeleteClose();
  };

  // when the user change the per page record change then used..
  const handleChangePaginationPerChange = React.useCallback(
    (e) => {
      const { value } = e.target;
      setPaginationPerPage(value);
      const values = {
        page: 1,
        search: searchingText,
        size: value,
        to:
          serachedDateRange?.length > 0
            ? serachedDateRange[0].format("YYYY-MM-DD")
            : "",
        from:
          serachedDateRange?.length > 0
            ? serachedDateRange[1].format("YYYY-MM-DD")
            : "",
      };
      handleGetKotakBankData(values);
    },
    [serachedDateRange, searchingText, setPaginationPerPage]
  );

  // when the user search the purchase records then used...
  const handleSearchPurchaseRecord = React.useCallback(
    (e) => {
      const { value } = e.target;
      if (value?.length > 0) {
        setSearchingText(value);
        const values = {
          page: 1,
          search: value.trim(),
          size: paginationPerPage,
          to:
            serachedDateRange?.length > 0
              ? serachedDateRange[0].format("YYYY-MM-DD")
              : "",
          from:
            serachedDateRange?.length > 0
              ? serachedDateRange[1].format("YYYY-MM-DD")
              : "",
        };
        handleGetKotakBankData(values);
      } else {
        setSearchingText("");
        const values = {
          page: 1,
          search: "",
          size: paginationPerPage,
          to:
            serachedDateRange?.length > 0
              ? serachedDateRange[0].format("YYYY-MM-DD")
              : "",
          from:
            serachedDateRange?.length > 0
              ? serachedDateRange[1].format("YYYY-MM-DD")
              : "",
        };
        handleGetKotakBankData(values);
      }
    },
    [paginationPerPage, serachedDateRange, setSearchingText]
  );

  // when the use change the date then used...
  const handleChangeDate = React.useCallback(
    (data) => {
      setSearchedDateRange(data);
      if (data[0] !== null && data[1] !== null) {
        const values = {
          page: 1,
          search: searchingText,
          size: paginationPerPage,
          to: data[0] !== null ? data[0].format("YYYY-MM-DD") : "",
          from: data[1] !== null ? data[1].format("YYYY-MM-DD") : "",
        };
        handleGetKotakBankData(values);
      } else {
        const values = {
          page: 1,
          search: searchingText,
          size: paginationPerPage,
          to:
            serachedDateRange?.length > 0
              ? serachedDateRange[0].format("YYYY-MM-DD")
              : "",
          from:
            serachedDateRange?.length > 0
              ? serachedDateRange[1].format("YYYY-MM-DD")
              : "",
        };
        handleGetKotakBankData(values);
      }
    },
    [setSearchedDateRange, searchingText, paginationPerPage]
  );

  const handleReloadHDFCRecords = () => {
    const values = {
      page: 1,
      search: "",
      size: paginationPerPage,
      to:
        serachedDateRange?.length > 0
          ? serachedDateRange[0].format("YYYY-MM-DD")
          : "",
      from:
        serachedDateRange?.length > 0
          ? serachedDateRange[1].format("YYYY-MM-DD")
          : "",
    };
    handleGetKotakBankData(values);
    setRemarkData("");
  };

  return (
    <div>
      <div>
        <Index.Box className="">
          <Index.Box className="barge-common-box">
            <Index.Box className="title-header">
              <Index.Box className="title-header-flex  res-title-header-flex res-title-header">
                <Index.Box className="title-main">
                  <Index.Typography
                    variant="p"
                    component="p"
                    className="page-title"
                  >
                    Kotak Bank Management
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="d-flex align-items-center res-set-search res-search-box">
                  <Index.Box className="input-design-div">
                    <Index.Box className="set-date set-date-picker remove-name">
                      <Index.LocalizationProvider
                        dateAdapter={Index.AdapterDayjs}
                      >
                        <Index.DemoContainer
                          components={["SingleInputDateRangeField"]}
                        >
                          <DateRangePicker
                            slots={{ field: SingleInputDateRangeField }}
                            sx={false}
                            slotProps={{
                              shortcuts: {
                                items: shortcutsItems,
                              },
                              actionBar: { actions: [] },
                            }}
                            onChange={(value) => handleChangeDate(value)}
                            name="allowedRange"
                            format="DD/MM/YYYY"
                          />
                        </Index.DemoContainer>
                      </Index.LocalizationProvider>
                    </Index.Box>
                  </Index.Box>
                  <Search className="search res-search">
                    <StyledInputBase
                      placeholder="Search"
                      inputProps={{ "aria-label": "search" }}
                      onChange={(e) => handleSearchPurchaseRecord(e)}
                    />
                  </Search>
                  {kotakBankDataList?.length ? (
                    <Index.Box className="common-button grey-button grey-button-list">
                      <Index.Button
                        variant="contained"
                        onClick={() => {
                          let curDate = Index.moment(new Date()).format(
                            "DD-MM-YYYY"
                          );
                          NEW_EXPORT_EXCEL(
                            "/admin/export-kotak",
                            `KOTAK_LIST_${curDate}`,
                            {
                              search: searchingText,
                              to:
                                serachedDateRange?.length > 0
                                  ? serachedDateRange[0].format("YYYY-MM-DD")
                                  : "",
                              from:
                                serachedDateRange?.length > 0
                                  ? serachedDateRange[1].format("YYYY-MM-DD")
                                  : "",
                            }
                          );
                        }}
                      >
                        Export
                      </Index.Button>
                    </Index.Box>
                  ) : (
                    ""
                  )}
                </Index.Box>
              </Index.Box>
            </Index.Box>

            <Index.Box className="">
              <Index.TableContainer
                component={Index.Paper}
                className="table-container"
              >
                <Index.Table
                  aria-label="simple table"
                  className="table-design-main invoice-table"
                >
                  <Index.TableHead>
                    <Index.TableRow>
                      <Index.TableCell className="invoice_table_header">
                        Date
                      </Index.TableCell>
                      <Index.TableCell
                        align="left"
                        className="invoice_table_header"
                      >
                        Party Name
                      </Index.TableCell>
                      <Index.TableCell
                        align="left"
                        className="invoice_table_header"
                      >
                        Voucher Type
                      </Index.TableCell>
                      <Index.TableCell
                        align="left"
                        className="invoice_table_header"
                      >
                        Voucher Number
                      </Index.TableCell>
                      <Index.TableCell
                        align="left"
                        className="invoice_table_header"
                      >
                        Reciept
                      </Index.TableCell>
                      <Index.TableCell
                        align="left"
                        className="invoice_table_header"
                      >
                        Payment
                      </Index.TableCell>
                      <Index.TableCell
                        align="left"
                        className="invoice_table_header"
                      >
                        Remark
                      </Index.TableCell>
                    </Index.TableRow>
                  </Index.TableHead>
                  {loading ? (
                    <Index.TableBody>
                      <Index.TableRow>
                        <Index.TableCell
                          colSpan={14}
                          align="center"
                          className="loading-purchase"
                        >
                          <Index.CircularProgress color="secondary" size={20} />
                        </Index.TableCell>
                      </Index.TableRow>
                    </Index.TableBody>
                  ) : (
                    <Index.TableBody>
                      {kotakBankDataList?.length ? (
                        kotakBankDataList?.map((row, index) => (
                          <Index.TableRow key={row?._id}>
                            <Index.TableCell>
                              {row?.date
                                ? Index.moment(row?.date).format("DD/MM/YYYY")
                                : "-"}
                            </Index.TableCell>
                            <Index.TableCell>
                              {row?.partyName ? row?.partyName : "-"}
                            </Index.TableCell>
                            <Index.TableCell>
                              {row?.vchType ? row?.vchType : "-"}
                            </Index.TableCell>
                            <Index.TableCell>
                              {row?.vchNo ? row?.vchNo : "-"}
                            </Index.TableCell>
                            <Index.TableCell>
                              {row?.amount < 0
                                ? Math.abs(row?.amount)?.toLocaleString(
                                    "en-IN",
                                    {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }
                                  )
                                : // )
                                  0?.toLocaleString("en-IN", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}
                            </Index.TableCell>
                            <Index.TableCell>
                              {row?.amount > 0
                                ? row?.amount?.toLocaleString("en-IN", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })
                                : 0?.toLocaleString("en-IN", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}
                            </Index.TableCell>
                            <Index.TableCell>
                              <Index.Button
                                className="set-list-button remark-text"
                                type="button"
                                onClick={() => handleOpen(row)}
                              >
                                <HtmlTooltip title={row?.remark}>
                                  <Index.Box className="edit-flex-btn">
                                    <Index.Box className="edit-btn-bank">
                                      <Index.EditIcon className="edit-btn-icon" />
                                    </Index.Box>
                                    <Index.Box className="flex-edittext-color">
                                      {row?.remark ? (
                                        <>
                                          {row?.remark?.length > 10 ? (
                                            <>
                                              {`${row?.remark?.substring(
                                                0,
                                                10
                                              )}...`}
                                            </>
                                          ) : (
                                            row?.remark
                                          )}
                                        </>
                                      ) : (
                                        ""
                                      )}
                                    </Index.Box>
                                  </Index.Box>
                                </HtmlTooltip>
                              </Index.Button>
                            </Index.TableCell>
                          </Index.TableRow>
                        ))
                      ) : (
                        <Index.TableRow>
                          <Index.TableCell
                            component="td"
                            variant="td"
                            scope="row"
                            className="no-data-in-list empty-record-align"
                            colSpan={14}
                            align="center"
                          >
                            No data available
                          </Index.TableCell>
                        </Index.TableRow>
                      )}
                    </Index.TableBody>
                  )}
                </Index.Table>
              </Index.TableContainer>
            </Index.Box>
            <Index.Box className="pagination-design flex-end">
              <Index.Stack spacing={2}>
                <Index.Box className="pagination-count">
                  <Index.Box className="input-design-div with-border">
                    <Index.Select
                      fullWidth
                      value={paginationPerPage}
                      onChange={(e) => handleChangePaginationPerChange(e)}
                    >
                      <Index.MenuItem value={10}>10</Index.MenuItem>
                      <Index.MenuItem value={25}>25</Index.MenuItem>
                      <Index.MenuItem value={50}>50</Index.MenuItem>
                      <Index.MenuItem value={100}>100</Index.MenuItem>
                    </Index.Select>
                  </Index.Box>
                  {loading ? (
                    <Index.CircularProgress color="secondary" size={26} />
                  ) : (
                    <Index.Pagination
                      count={Math.ceil(count / paginationPerPage)}
                      page={currentPage}
                      onChange={handlePageChange}
                    />
                  )}
                </Index.Box>
              </Index.Stack>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </div>
      <PageIndex.RemarkModel
        modelOpen={open}
        handleClose={handleClose}
        remarkData={remarkData}
        handleReloadHDFCRecords={handleReloadHDFCRecords}
        type="kotak"
      />
    </div>
  );
};

export default KotakBankList;
