import { useEffect, useState } from "react";
import Index from "../../../Index";
import { getSurveyourSuppliesList } from "../../../../redux/admin/action";

function SurveyourSupplyTiming({ id }) {
  const dispatch = Index.useDispatch();
  const [surveyourSupplyData, setSurveyourSupplyData] = useState("");

  // when the get pipeline surveyour listing then used...
  const handleGetSurveyourSupplies = () => {
    dispatch(getSurveyourSuppliesList({ orderId: id }))
      .then((res) => {
        if (res.status === 200) {
          setSurveyourSupplyData(res.data);
        } else {
          setSurveyourSupplyData("");
        }
      })
      .catch((error) => {
        console.error(error);
        setSurveyourSupplyData("");
      });
  };

  // when the page first time get then used...
  useEffect(() => {
    handleGetSurveyourSupplies();
  }, []);
  return (
    <>
      {/* anchorAweighForSupply,
alongsideOnVessel,
pumpingStart,
pumpingCompleted,
castOff,
suppliedQty, */}
      <Index.Grid container spacing={1}>
        <Index.Grid item xs={12} sm={6}>
          <Index.Box className="invoice-detail-item">
            <Index.Box className="input-label opacity-60">
              Anchor Aweigh For Supply:
            </Index.Box>
            <Index.Box className="input-label">
              {surveyourSupplyData?.anchorAweighForSupply
                ? Index.moment(
                    surveyourSupplyData?.anchorAweighForSupply
                  ).format("DD/MM/YYYY hh:mm:ss A")
                : "-"}
            </Index.Box>
          </Index.Box>
        </Index.Grid>
        <Index.Grid item xs={12} sm={6}>
          <Index.Box className="invoice-detail-item">
            <Index.Box className="input-label opacity-60">
              Along Side Vessel:
            </Index.Box>
            <Index.Box className="input-label">
              {surveyourSupplyData?.alongsideOnVessel
                ? Index.moment(surveyourSupplyData?.alongsideOnVessel).format(
                    "DD/MM/YYYY hh:mm:ss A"
                  )
                : "-"}
            </Index.Box>
          </Index.Box>
        </Index.Grid>
        <Index.Grid item xs={12} sm={6}>
          <Index.Box className="invoice-detail-item">
            <Index.Box className="input-label opacity-60">
              Pumping Start:
            </Index.Box>
            <Index.Box className="input-label">
              {surveyourSupplyData?.pumpingStart
                ? Index.moment(surveyourSupplyData?.pumpingStart).format(
                    "DD/MM/YYYY hh:mm:ss A"
                  )
                : "-"}
            </Index.Box>
          </Index.Box>
        </Index.Grid>
        <Index.Grid item xs={12} sm={6}>
          <Index.Box className="invoice-detail-item">
            <Index.Box className="input-label opacity-60">
              Pumping Completed:
            </Index.Box>
            <Index.Box className="input-label">
              {surveyourSupplyData?.pumpingCompleted
                ? Index.moment(surveyourSupplyData?.pumpingCompleted).format(
                    "DD/MM/YYYY hh:mm:ss A"
                  )
                : "-"}
            </Index.Box>
          </Index.Box>
        </Index.Grid>
        <Index.Grid item xs={12} sm={6}>
          <Index.Box className="invoice-detail-item">
            <Index.Box className="input-label opacity-60">Cast Off:</Index.Box>
            <Index.Box className="input-label">
              {surveyourSupplyData?.castOff
                ? Index.moment(surveyourSupplyData?.castOff).format(
                    "DD/MM/YYYY hh:mm:ss A"
                  )
                : "-"}
            </Index.Box>
          </Index.Box>
        </Index.Grid>
        <Index.Grid item xs={12} sm={6}>
          <Index.Box className="invoice-detail-item">
            <Index.Box className="input-label opacity-60">
              Supply Qty:
            </Index.Box>
            <Index.Box className="input-label">
              {surveyourSupplyData?.suppliedQty
                ? surveyourSupplyData?.suppliedQty
                : "-"}
            </Index.Box>
          </Index.Box>
        </Index.Grid>
      </Index.Grid>
    </>
  );
}
export default SurveyourSupplyTiming;
