import {
  Box,
  FormHelperText,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
  Button,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Link,
  List,
  ListItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextareaAutosize,
  Select,
  MenuItem,
  FormControl,
  RadioGroup,
  Radio,
  Tabs,
  Tab,
  Switch,
  styled,
  alpha,
  Modal,
  Menu,
  Collapse,
  Stack,
  Pagination,
  SwipeableDrawer,
  InputBase,
  Tooltip,
} from "@mui/material";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import SearchIcon from "@mui/icons-material/Search";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import Svg from "../assets/Svg";
import Png from "../assets/Png";
// import Sidebar from "../component/admin/defaulLayout/Sidebar";
// import Header from "../component/admin/defaulLayout/Header";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import PaidLable from "../component/common/PaidLable";
import FailedLable from "../component/common/FailedLable";
import PendingLable from "../component/common/PendingLable";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import PrimaryButton from "../component/common/PrimaryButton";
import Grid from "@mui/material/Grid";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ReceiptIcon from "@mui/icons-material/Receipt";
import DeleteModal from "../component/common/DeleteModal";
import DeleteSheetModal from "../component/common/DeleteSheetModel";
import ParticularModel from "../container/admin/pages/invoice/ParticularModel";
import InputLabel from "@mui/material/InputLabel";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import {
  useNavigate,
  useParams,
  useLocation,
  Link as RouteLink,
  Navigate,
  Outlet,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import VisibilitySharpIcon from "@mui/icons-material/VisibilitySharp";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Autocomplete from "@mui/material/Autocomplete";
import ClearIcon from "@mui/icons-material/Clear";
import MenuIcon from "@mui/icons-material/MoreVert";
import DynamicTitle from "../component/common/DynamicTitle";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import CircularProgress from "@mui/material/CircularProgress";
import PropTypes from "prop-types";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import { tooltipClasses } from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/Info";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import HistoryIcon from "@mui/icons-material/History";
import ImageIcon from "@mui/icons-material/Image";
// import PrintIcon from '@material-ui/icons/Print';
import PrintIcon from "@mui/icons-material/Print";

// const Roles = {
//   SuperAdmin: 'SuperAdmin',
//   Operation: 'Operation',
//   Finance: 'Finance'
// }

const SideMenuIcon = {
  companyDashboard: Svg.dashboard2,
  tankerDashboard: Svg.dashboard2,
  enquiry: Svg.enquiry,
  users: Svg.sidebar2,
  order: Svg.orders,
  tugsOrder: Svg.orders,
  slopeSludgeOrder: Svg.orders,
  expenseList: Svg.expense1,
  craft: Svg.sidebar3,
  invoice: Svg.invoices,
  purchase: Svg.purchase,
  purchase1: Svg.purchase1,
  bunker: Svg.bunkerExpense,
  banks: Svg.banks,
  bankHistory: Svg.banks,
  directory: Svg.directory,
  rate: Svg.gstRate,
  party: Svg.thirdParty,
  setup: Svg.setting,
  reports: Svg.sidebar6,
  expense: Svg.expenses,
  port: Svg.port,
  traders: Svg.traderMaster,
  roles: Svg.roleMaster,
  tankers: Svg.tankers,
  salesMaster: Svg.salesMaster,
  notification: Svg.notifications,
  gstr: Svg.gst,
  gstr2: Svg.gst,
  tds: Svg.tds,
  tasks: Svg.tds,
  breakdowns: Svg.breakDowns,
  maintenances: Svg.maintenance,
  activeUsers: Svg.sidebar2,
  tankertrips: Svg.breakDowns,
  waterSupply: Svg.orders,
  wharfage: Svg.bunkerExpense,
  craftPreventive: Svg.maintenance,
  documentCenter: Svg.directory,
  transfer: Svg.bunkerExpense,
  preventiveSubhead: Svg.bunkerExpense,
  tallyInvoice: Svg.invoices,
  companies: Svg.banks,
  purchaseBeta: Svg.purchase,
};

export default {
  Grid,
  InputLabel,
  Box,
  FormHelperText,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
  Button,
  IconButton,
  InputAdornment,
  OutlinedInput,
  VisibilityOff,
  Visibility,
  Link,
  List,
  ListItem,
  Svg,
  Png,
  SideMenuIcon,
  // Sidebar,
  // Header,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  PaidLable,
  FailedLable,
  PendingLable,
  TextareaAutosize,
  Select,
  MenuItem,
  FormControl,
  RadioGroup,
  Radio,
  Tabs,
  Tab,
  Switch,
  styled,
  alpha,
  Modal,
  Menu,
  ExpandLess,
  ExpandMore,
  Collapse,
  Stack,
  Pagination,
  SwipeableDrawer,
  InputBase,
  PrimaryButton,
  SearchIcon,
  AccessTimeIcon,
  DeleteIcon,
  HistoryIcon,
  EditIcon,
  ImageIcon,
  ReceiptIcon,
  DeleteModal,
  ParticularModel,
  useDispatch,
  useSelector,
  moment,
  dayjs,
  Dialog,
  DialogContent,
  LocalizationProvider,
  AdapterDayjs,
  DatePicker,
  VisibilitySharpIcon,
  CloudDownloadIcon,
  useNavigate,
  Navigate,
  Outlet,
  useParams,
  useLocation,
  RouteLink,
  DemoContainer,
  DemoItem,
  AddCircleIcon,
  Autocomplete,
  ClearIcon,
  MenuIcon,
  DeleteSheetModal,
  DynamicTitle,
  // Roles,
  Tooltip,
  ReceiptLongIcon,
  CircularProgress,
  PropTypes,
  ArrowBackIcon,
  ImageList,
  ImageListItem,
  KeyboardArrowDownIcon,
  KeyboardArrowUpIcon,
  tooltipClasses,
  InfoIcon,
  ContentCopyIcon,
  PrintIcon,
};
