import React, { useEffect, useMemo, useState } from "react";
import Index from "../../../Index";
import { FieldArray, Formik } from "formik";
import { addEditBunkerTiming } from "../../../../redux/admin/action";
import dayjs from "dayjs";

import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

function EditBunkerTiming() {
  let { state } = Index.useLocation();
  let editData = state?.row;
  // console.log(editData?.[0]?.returnAnchorDrop, 19);
  const tabType = state?.type
  const valueTabData = state?.value
  let orderId = editData?.[0].orderId;
  const dispatch = Index.useDispatch();
  const navigate = Index.useNavigate();
  const [loading, setLoading] = useState(false);

  let initialValues = {
    id: editData?.[0]?._id ? editData?.[0]?._id : "",
    // loadingCommence: editData?.[0]?.loadingCommence
    //   ? Index.moment(editData[0]?.loadingCommence).format("DD/MM/YYYY HH:mm")
    //   : "",
    // loadingCompleted: editData?.[0]?.loadingCompleted
    //   ? Index.moment(editData[0]?.loadingCompleted).format("DD/MM/YYYY HH:mm")
    //   : "",
    // loadedQty: editData?.[0]?.loadedQty ? editData?.[0]?.loadedQty : "",
    // hsdConsumption: editData?.[0]?.hsdConsumption
    //   ? editData?.[0]?.hsdConsumption
    //   : "",
    pumpingRate: editData?.[0]?.pumpingRate ? editData?.[0]?.pumpingRate : "",
    craftSpeed: editData?.[0]?.craftSpeed ? editData?.[0]?.craftSpeed : "",
    distance: editData?.[0]?.distance ? editData?.[0]?.distance : "",
    dieselConsumption: editData?.[0]?.dieselConsumption
      ? editData?.[0]?.dieselConsumption
      : "",
    supplyDocsReceivedOnBoard:
      editData?.[0]?.supplyDocsReceivedOnBoard == false ? "No" : "Yes",
    anchorAweighForSupply: editData?.[0]?.anchorAweighForSupply
      ? Index.moment(editData[0]?.anchorAweighForSupply).format(
          "DD/MM/YYYY HH:mm"
        )
      : "",
    alongsideOnVessel: editData?.[0]?.alongsideOnVessel
      ? Index.moment(editData[0]?.alongsideOnVessel).format("DD/MM/YYYY HH:mm")
      : "",
    pumpingStart: editData?.[0]?.pumpingStart
      ? Index.moment(editData[0]?.pumpingStart).format("DD/MM/YYYY HH:mm")
      : "",
    pumpingCompleted: editData?.[0]?.pumpingCompleted
      ? Index.moment(editData[0]?.pumpingCompleted).format("DD/MM/YYYY HH:mm")
      : "",
    castOff: editData?.[0]?.castOff
      ? Index.moment(editData[0]?.castOff).format("DD/MM/YYYY HH:mm")
      : "",
    suppliedQty: editData?.[0]?.suppliedQty ? editData?.[0]?.suppliedQty : "",
    issuedChallanNumber: editData?.[0]?.issuedChallanNumber
      ? editData?.[0]?.issuedChallanNumber
      : "",
    issuedChallanDate: editData?.[0]?.issuedChallanDate
      ? Index.moment(editData[0]?.issuedChallanDate).format("DD/MM/YYYY HH:mm")
      : "",
     rcvdBdn: editData?.[0]?.rcvdBdn
      ? Index.moment(editData[0]?.rcvdBdn).format("DD/MM/YYYY HH:mm")
      : "",
    lopIssue: editData?.[0]?.lopIssue ? editData?.[0]?.lopIssue : "",
    remarkOfLop: editData?.[0]?.remarkOfLop ? editData?.[0]?.remarkOfLop : "",
    dispute: editData?.[0]?.dispute ? editData?.[0]?.dispute : "",
    remarkofDispute: editData?.[0]?.remarkofDispute
      ? editData?.[0]?.remarkofDispute
      : "",
    roughWeather: editData?.[0]?.roughWeather
      ? editData?.[0]?.roughWeather
      : "",
    wind: editData?.[0]?.wind ? editData?.[0]?.wind : "",
    swel: editData?.[0]?.swel ? editData?.[0]?.swel : "",
    rain: editData?.[0]?.rain ? editData?.[0]?.rain : "",
    returnType: editData?.[0]?.returnType ? editData?.[0]?.returnType : "",
    returnCastOff: editData?.[0]?.returnData?.returnCastOff
      ? Index.moment(editData?.[0]?.returnData?.returnCastOff).format(
          "DD/MM/YYYY HH:mm"
        )
      : "",
    returnAnchorDrop: editData?.[0]?.returnData?.returnAnchorDrop
      ? Index.moment(editData?.[0]?.returnData?.returnAnchorDrop).format(
          "DD/MM/YYYY HH:mm"
        )
      : "",

    returnDieselConsumption: editData?.[0].returnData?.returnDieselConsumption
      ? editData?.[0]?.returnData?.returnDieselConsumption
      : "",
    returnReason: editData?.[0].returnData?.returnReason
      ? editData?.[0]?.returnData?.returnReason
      : "",
    waiting: editData?.[0]?.waiting === true ? "Yes" : "No",

    dropAnchorAtAnchorage: editData?.[0]?.dropAnchorAtAnchorage
      ? Index.moment(editData[0]?.dropAnchorAtAnchorage).format(
          "DD/MM/YYYY HH:mm"
        )
      : "",
    anchorAwighFromAnchorage: editData?.[0]?.anchorAwighFromAnchorage
      ? Index.moment(editData[0]?.anchorAwighFromAnchorage).format(
          "DD/MM/YYYY HH:mm"
        )
      : "",
  };


  // console.log(initialValues,116);
  const handleFormSubmit = async (values) => {
    setLoading(true);
    // formatate one date foramte to another
    // console.log(
    //   Index.moment(values?.loadingCommence, "DD/MM/YYYY HH:mm").format(
    //     "YYYY-MM-DDTHH:mm:ssZZ"
    //   ),
    //   94
    // );
    const data = new URLSearchParams();
    // if (values?.loadingCommence) {
    //   data.append(
    //     "loadingCommence",
    //     Index.moment(values?.loadingCommence, "DD/MM/YYYY HH:mm").format(
    //       "YYYY-MM-DDTHH:mm:ssZZ"
    //     )
    //   );
    // }
    // if (values?.loadingCompleted) {
    //   data.append(
    //     "loadingCompleted",
    //     Index.moment(values?.loadingCompleted, "DD/MM/YYYY HH:mm").format(
    //       "YYYY-MM-DDTHH:mm:ssZZ"
    //     )
    //   );
    // }
    // if (values?.loadedQty) {
    //   data.append("loadedQty", values?.loadedQty);
    // }
    // if (values?.hsdConsumption) {
    //   data.append("hsdConsumption", values?.hsdConsumption);
    // }
    if (values?.pumpingRate) {
      data.append("pumpingRate", values?.pumpingRate);
    }

    if (values?.craftSpeed) {
      data.append("craftSpeed", values?.craftSpeed);
    }
    if (values?.distance) {
      data.append("distance", values?.distance);
    }
    if (values?.dieselConsumption) {
      data.append("dieselConsumption", values?.dieselConsumption);
    }
    if (values?.supplyDocsReceivedOnBoard) {
      data.append(
        "supplyDocsReceivedOnBoard",
        values?.supplyDocsReceivedOnBoard === "Yes" ? true : false
      );
    }
    if (values?.anchorAweighForSupply) {
      data.append(
        "anchorAweighForSupply",
        Index.moment(values?.anchorAweighForSupply, "DD/MM/YYYY HH:mm").format(
          "YYYY-MM-DDTHH:mm:ssZZ"
        )
      );
    }
    if (values?.alongsideOnVessel) {
      data.append(
        "alongsideOnVessel",
        Index.moment(values?.alongsideOnVessel, "DD/MM/YYYY HH:mm").format(
          "YYYY-MM-DDTHH:mm:ssZZ"
        )
      );
    }
    if (values?.pumpingStart) {
      data.append(
        "pumpingStart",
        Index.moment(values?.pumpingStart, "DD/MM/YYYY HH:mm").format(
          "YYYY-MM-DDTHH:mm:ssZZ"
        )
      );
    }
    if (values?.pumpingCompleted) {
      data.append(
        "pumpingCompleted",
        Index.moment(values?.pumpingCompleted, "DD/MM/YYYY HH:mm").format(
          "YYYY-MM-DDTHH:mm:ssZZ"
        )
      );
    }
    if (values?.castOff) {
      data.append(
        "castOff",
        Index.moment(values?.castOff, "DD/MM/YYYY HH:mm").format(
          "YYYY-MM-DDTHH:mm:ssZZ"
        )
      );
    }
    if (values?.suppliedQty) {
      data.append("suppliedQty", values?.suppliedQty);
    }
    if (values?.issuedChallanNumber) {
      data.append("issuedChallanNumber", values?.issuedChallanNumber);
    }
    if (values?.issuedChallanDate) {
      data.append(
        "issuedChallanDate",
        Index.moment(values?.issuedChallanDate, "DD/MM/YYYY HH:mm").format(
          "YYYY-MM-DDTHH:mm:ssZZ"
        )
      );
    }
if (values?.rcvdBdn) {
  data.append(
    "rcvdBdn",
    Index.moment(values?.rcvdBdn, "DD/MM/YYYY HH:mm").format(
      "YYYY-MM-DDTHH:mm:ssZZ"
    )
  );
}

    if (values?.lopIssue) {
      data.append("lopIssue", values?.lopIssue);
    }
    if (values?.dispute) {
      data.append("dispute", values?.dispute);
    }

    if (values?.lopIssue === "Yes") {
      data.append("remarkOfLop", values?.remarkOfLop);
    }
    if (values?.dispute === "Yes") {
      data.append("remarkofDispute", values?.remarkofDispute);
    }
    if (values?.roughWeather) {
      data.append("roughWeather", values?.roughWeather);
    }
    if (values?.roughWeather === "Yes") {
      data.append("wind", values?.wind);
      data.append("swel", values?.swel);
      data.append("rain", values?.rain);
    }
    data.append("id", editData[0]?._id);
    
    if (values?.returnType) {
      data.append("returnType", values?.returnType);
    }
    if (values?.returnType === "Yes") {
      data.append(
        "returnCastOff",
        Index.moment(values?.returnCastOff, "DD/MM/YYYY HH:mm").format(
          "YYYY-MM-DDTHH:mm:ssZZ"
        )
      );
      data.append(
        "returnAnchorDrop",
        Index.moment(values?.returnAnchorDrop, "DD/MM/YYYY HH:mm").format(
          "YYYY-MM-DDTHH:mm:ssZZ"
        )
      );
      data.append("returnDieselConsumption", values?.returnDieselConsumption);
      data.append("returnReason", values?.returnReason);
    }
    
  if (values?.waiting) {
    data.append("waiting", values?.waiting === "Yes" ? true :false);
    }

 if (values?.waiting == "Yes") {
data.append(
  "dropAnchorAtAnchorage",
  Index.moment(values?.dropAnchorAtAnchorage, "DD/MM/YYYY HH:mm").format(
    "YYYY-MM-DDTHH:mm:ssZZ"
  )
);
data.append(
  "anchorAwighFromAnchorage",
  Index.moment(values?.anchorAwighFromAnchorage, "DD/MM/YYYY HH:mm").format(
    "YYYY-MM-DDTHH:mm:ssZZ"
  )
);
 }
    
    dispatch(addEditBunkerTiming(data, navigate)).then((res) => {
      if (res?.status === 200) {
        // setLoading(false);
        navigate(`/admin/order/view-order/${orderId}`, {
          state: { type: "EditTiming" , value : valueTabData },
        });
      } else {
        setLoading(false);
      }
    });
  };

  const backBtn = () => {
    navigate(`/admin/order/view-order/${orderId}`);
     if (tabType === "editBunker") {
       navigate(`/admin/order/view-order/${orderId}`, {
         state: { value: valueTabData },
       });
     }
  };

  return (
    <>
      <Index.Box className="">
        <Index.Box className="barge-common-box">
          <Index.Box className="title-header">
            <Index.Box className="title-header-flex">
              <Index.Box className="title-main mb-10">
                <Index.Typography
                  variant="p"
                  component="p"
                  className="page-title"
                >
                  Edit Bunker Details
                </Index.Typography>
              </Index.Box>
              <Index.Box className="common-button blue-button save-btn ">
                <Index.Button
                  variant="contained"
                  type="submit"
                  className="Particular_model_submit ml-0"
                  onClick={backBtn}
                >
                  Back
                </Index.Button>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            onSubmit={handleFormSubmit}
          >
            {({
              values,
              errors,
              touched,
              setFieldValue,
              handleChange,
              handleBlur,
              handleSubmit,
              getFieldProps,
            }) => (
              <form onSubmit={handleSubmit}>
                <Index.Box className="">
                  <Index.Grid container spacing={2}>
                    {/* <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Loading Commence
                      </Index.Typography>
                      <Index.Box className="input-design-div">
                        <Index.Box className="set-date set-date-picker">
                          <Index.LocalizationProvider
                            dateAdapter={Index.AdapterDayjs}
                          >
                            <Index.DemoContainer
                              components={["Index.DateTimePicker"]}
                            >
                              <DateTimePicker
                                disableFuture
                                // value={dayjs(
                                //   Index.moment(values?.loadingCommence).format(
                                //     "DD/MM/YYYY HH:mm"
                                //   )
                                // )}
                                timeSteps={{
                                  hours: 1,
                                  minutes: 1,
                                }}
                                defaultValue={
                                  editData
                                    ? Index.dayjs(
                                        editData?.[0]?.loadingCommence
                                          ? editData?.[0]?.loadingCommence
                                          : "-"
                                      )
                                    : ""
                                }
                                onChange={(value) => {
                                  const formatedDate = Index.moment(
                                    value.$d
                                  ).format("DD/MM/YYYY HH:mm");
                                  setFieldValue(
                                    "loadingCommence",
                                    formatedDate
                                  );
                                }}
                                slotProps={{
                                  textField: {
                                    readOnly: true,
                                  },
                                }}
                                format="DD/MM/YYYY HH:mm"
                              />
                            </Index.DemoContainer>
                          </Index.LocalizationProvider>
                        </Index.Box>
                      </Index.Box>
                    </Index.Grid> */}
                    {/* <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Loading Completed
                      </Index.Typography>
                      <Index.Box className="input-design-div">
                        <Index.Box className="set-date set-date-picker">
                          <Index.LocalizationProvider
                            dateAdapter={Index.AdapterDayjs}
                          >
                            <Index.DemoContainer
                              components={["Index.DateTimePicker"]}
                            >
                              <DateTimePicker
                                disableFuture
                                // disablePast
                                // value={dayjs(
                                //   Index.moment(values?.loadingCompleted).format(
                                //     "DD/MM/YYYY HH:mm"
                                //   )
                                // )}
                                timeSteps={{
                                  hours: 1,
                                  minutes: 1,
                                }}
                                defaultValue={
                                  editData
                                    ? Index.dayjs(
                                        editData?.[0]?.loadingCompleted
                                          ? editData?.[0]?.loadingCompleted
                                          : "-"
                                      )
                                    : ""
                                }
                                onChange={(value) => {
                                  const formatedDate = Index.moment(
                                    value.$d
                                  ).format("DD/MM/YYYY HH:mm");
                                  setFieldValue(
                                    "loadingCompleted",
                                    formatedDate
                                  );
                                }}
                                slotProps={{
                                  textField: {
                                    readOnly: true,
                                  },
                                }}
                                format="DD/MM/YYYY HH:mm"
                              />
                            </Index.DemoContainer>
                          </Index.LocalizationProvider>
                        </Index.Box>
                      </Index.Box>
                    </Index.Grid> */}
                    {/* <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Loaded Quantity
                      </Index.Typography>
                      <Index.Box className="input-design-div with-border">
                        <Index.TextField
                          hiddenLabel
                          type="number"
                          id="filled-hidden-label-normal"
                          placeholder="Loaded Quantity"
                          variant="filled"
                          className="admin-input-design input-placeholder"
                          name="loadedQty"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values?.loadedQty}
                        />
                      </Index.Box>
                    </Index.Grid> */}
                    {/* <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        HSD Consumption (In Liters)
                      </Index.Typography>
                      <Index.Box className="input-design-div with-border">
                        <Index.TextField
                          hiddenLabel
                          // type="number"
                          id="filled-hidden-label-normal"
                          placeholder=" HSD Consumption (In Liters)"
                          variant="filled"
                          className="admin-input-design input-placeholder"
                          name="hsdConsumption"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values?.hsdConsumption}
                        />
                      </Index.Box>
                    </Index.Grid> */}
                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Pumping Rate (In Hours)
                      </Index.Typography>
                      <Index.Box className="input-design-div with-border">
                        <Index.TextField
                          hiddenLabel
                          type="number"
                          id="filled-hidden-label-normal"
                          placeholder="Pumping Rate (In Hours)"
                          variant="filled"
                          className="admin-input-design input-placeholder"
                          name="pumpingRate"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values?.pumpingRate}
                        />
                        {/* {touched?.pumpingRate && errors?.pumpingRate && (
                          <Index.FormHelperText error>
                            {errors?.pumpingRate}
                          </Index.FormHelperText>
                        )} */}
                      </Index.Box>
                    </Index.Grid>
                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Craft Speed (Nautical Mileage)
                      </Index.Typography>
                      <Index.Box className="input-design-div with-border">
                        <Index.TextField
                          hiddenLabel
                          type="number"
                          id="filled-hidden-label-normal"
                          placeholder="Craft Speed (Nautical Mileage)"
                          variant="filled"
                          className="admin-input-design input-placeholder"
                          name="craftSpeed"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values?.craftSpeed}
                        />
                      </Index.Box>
                    </Index.Grid>
                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Distance (In Nautical Miles)
                      </Index.Typography>
                      <Index.Box className="input-design-div with-border">
                        <Index.TextField
                          hiddenLabel
                          type="number"
                          id="filled-hidden-label-normal"
                          placeholder="Distance (In Nautical Miles)"
                          variant="filled"
                          className="admin-input-design input-placeholder"
                          name="distance"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values?.distance}
                        />
                      </Index.Box>
                    </Index.Grid>
                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Diesel Consumption
                      </Index.Typography>
                      <Index.Box className="input-design-div with-border">
                        <Index.TextField
                          hiddenLabel
                          type="number"
                          id="filled-hidden-label-normal"
                          placeholder="Diesel Consumption"
                          variant="filled"
                          className="admin-input-design input-placeholder"
                          name="dieselConsumption"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values?.dieselConsumption}
                        />
                      </Index.Box>
                    </Index.Grid>
                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Supply Docs Received On Board
                      </Index.Typography>
                      <Index.Box className="input-design-div with-border">
                        <FormControl>
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            onChange={(e) => {
                              setFieldValue(
                                "supplyDocsReceivedOnBoard",
                                e.target.value
                              );
                            }}
                          >
                            <FormControlLabel
                              value="Yes"
                              name="supplyDocsReceivedOnBoard"
                              false
                              control={<Radio />}
                              label="Yes"
                              checked={
                                values.supplyDocsReceivedOnBoard === "Yes"
                                  ? true
                                  : false
                              }
                            />
                            <FormControlLabel
                              value="No"
                              name="supplyDocsReceivedOnBoard"
                              control={<Radio />}
                              label="No"
                              checked={
                                values.supplyDocsReceivedOnBoard === "No"
                                  ? true
                                  : false
                              }
                            />
                          </RadioGroup>
                        </FormControl>
                      </Index.Box>
                    </Index.Grid>
                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Anchor Aweigh
                      </Index.Typography>
                      <Index.Box className="input-design-div">
                        <Index.Box className="set-date set-date-picker">
                          <Index.LocalizationProvider
                            dateAdapter={Index.AdapterDayjs}
                          >
                            <Index.DemoContainer
                              components={["Index.DateTimePicker"]}
                            >
                              <DateTimePicker
                                disableFuture
                                // value={dayjs(
                                //   Index.moment(
                                //     values?.anchorAweighForSupply
                                //   ).format("DD/MM/YYYY HH:mm")
                                // )}
                                timeSteps={{
                                  hours: 1,
                                  minutes: 1,
                                }}
                                defaultValue={
                                  editData
                                    ? Index.dayjs(
                                        editData?.[0]?.anchorAweighForSupply
                                          ? editData?.[0]?.anchorAweighForSupply
                                          : "-"
                                      )
                                    : ""
                                }
                                onChange={(value) => {
                                  const formatedDate = Index.moment(
                                    value.$d
                                  ).format("DD/MM/YYYY HH:mm");
                                  setFieldValue(
                                    "anchorAweighForSupply",
                                    formatedDate
                                  );
                                }}
                                slotProps={{
                                  textField: {
                                    readOnly: true,
                                  },
                                }}
                                format="DD/MM/YYYY HH:mm"
                              />
                            </Index.DemoContainer>
                          </Index.LocalizationProvider>
                        </Index.Box>
                      </Index.Box>
                    </Index.Grid>
                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Alongside On Vessel
                      </Index.Typography>
                      <Index.Box className="input-design-div">
                        <Index.Box className="set-date set-date-picker">
                          <Index.LocalizationProvider
                            dateAdapter={Index.AdapterDayjs}
                          >
                            <Index.DemoContainer
                              components={["Index.DateTimePicker"]}
                            >
                              <DateTimePicker
                                disableFuture
                                // disablePast
                                // value={dayjs(
                                //   Index.moment(
                                //     values?.alongsideOnVessel
                                //   ).format("DD/MM/YYYY HH:mm")
                                // )}
                                timeSteps={{
                                  hours: 1,
                                  minutes: 1,
                                }}
                                defaultValue={
                                  editData
                                    ? Index.dayjs(
                                        editData?.[0]?.alongsideOnVessel
                                          ? editData?.[0]?.alongsideOnVessel
                                          : "-"
                                      )
                                    : ""
                                }
                                onChange={(value) => {
                                  const formatedDate = Index.moment(
                                    value.$d
                                  ).format("DD/MM/YYYY HH:mm");
                                  setFieldValue(
                                    "alongsideOnVessel",
                                    formatedDate
                                  );
                                }}
                                slotProps={{
                                  textField: {
                                    readOnly: true,
                                  },
                                }}
                                format="DD/MM/YYYY HH:mm"
                              />
                            </Index.DemoContainer>
                          </Index.LocalizationProvider>
                        </Index.Box>
                      </Index.Box>
                    </Index.Grid>
                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Pumping Start
                      </Index.Typography>
                      <Index.Box className="input-design-div">
                        <Index.Box className="set-date set-date-picker">
                          <Index.LocalizationProvider
                            dateAdapter={Index.AdapterDayjs}
                          >
                            <Index.DemoContainer
                              components={["Index.DateTimePicker"]}
                            >
                              <DateTimePicker
                                disableFuture
                                // disablePast
                                // value={dayjs(
                                //   Index.moment(values?.pumpingStart).format(
                                //     "DD/MM/YYYY HH:mm"
                                //   )
                                // )}
                                timeSteps={{
                                  hours: 1,
                                  minutes: 1,
                                }}
                                defaultValue={
                                  editData
                                    ? Index.dayjs(
                                        editData?.[0]?.pumpingStart
                                          ? editData?.[0]?.pumpingStart
                                          : "-"
                                      )
                                    : ""
                                }
                                onChange={(value) => {
                                  const formatedDate = Index.moment(
                                    value.$d
                                  ).format("DD/MM/YYYY HH:mm");
                                  setFieldValue("pumpingStart", formatedDate);
                                }}
                                slotProps={{
                                  textField: {
                                    readOnly: true,
                                  },
                                }}
                                format="DD/MM/YYYY HH:mm"
                              />
                            </Index.DemoContainer>
                          </Index.LocalizationProvider>
                        </Index.Box>
                      </Index.Box>
                    </Index.Grid>
                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Pumping Completed
                      </Index.Typography>
                      <Index.Box className="input-design-div">
                        <Index.Box className="set-date set-date-picker">
                          <Index.LocalizationProvider
                            dateAdapter={Index.AdapterDayjs}
                          >
                            <Index.DemoContainer
                              components={["Index.DateTimePicker"]}
                            >
                              <DateTimePicker
                                disableFuture
                                // disablePast
                                // value={dayjs(
                                //   Index.moment(values?.pumpingCompleted).format(
                                //     "DD/MM/YYYY HH:mm"
                                //   )
                                // )}
                                timeSteps={{
                                  hours: 1,
                                  minutes: 1,
                                }}
                                defaultValue={
                                  editData
                                    ? Index.dayjs(
                                        editData?.[0]?.pumpingCompleted
                                          ? editData?.[0]?.pumpingCompleted
                                          : "-"
                                      )
                                    : ""
                                }
                                onChange={(value) => {
                                  const formatedDate = Index.moment(
                                    value.$d
                                  ).format("DD/MM/YYYY HH:mm");
                                  setFieldValue(
                                    "pumpingCompleted",
                                    formatedDate
                                  );
                                }}
                                slotProps={{
                                  textField: {
                                    readOnly: true,
                                  },
                                }}
                                format="DD/MM/YYYY HH:mm"
                              />
                            </Index.DemoContainer>
                          </Index.LocalizationProvider>
                        </Index.Box>
                      </Index.Box>
                    </Index.Grid>
                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Cast Off
                      </Index.Typography>
                      <Index.Box className="input-design-div">
                        <Index.Box className="set-date set-date-picker">
                          <Index.LocalizationProvider
                            dateAdapter={Index.AdapterDayjs}
                          >
                            <Index.DemoContainer
                              components={["Index.DateTimePicker"]}
                            >
                              <DateTimePicker
                                disableFuture
                                // disablePast
                                // value={dayjs(
                                //   Index.moment(values?.castOff).format(
                                //     "DD/MM/YYYY HH:mm"
                                //   )
                                // )}
                                timeSteps={{
                                  hours: 1,
                                  minutes: 1,
                                }}
                                defaultValue={
                                  editData
                                    ? Index.dayjs(
                                        editData?.[0]?.castOff
                                          ? editData?.[0]?.castOff
                                          : "-"
                                      )
                                    : ""
                                }
                                onChange={(value) => {
                                  const formatedDate = Index.moment(
                                    value.$d
                                  ).format("DD/MM/YYYY HH:mm");
                                  setFieldValue("castOff", formatedDate);
                                }}
                                slotProps={{
                                  textField: {
                                    readOnly: true,
                                  },
                                }}
                                format="DD/MM/YYYY HH:mm"
                              />
                            </Index.DemoContainer>
                          </Index.LocalizationProvider>
                        </Index.Box>
                      </Index.Box>
                    </Index.Grid>
                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Supplied Quantity
                      </Index.Typography>
                      <Index.Box className="input-design-div with-border">
                        <Index.TextField
                          hiddenLabel
                          type="number"
                          id="filled-hidden-label-normal"
                          placeholder="Supplied Quantity"
                          variant="filled"
                          className="admin-input-design input-placeholder"
                          name="suppliedQty"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values?.suppliedQty}
                        />
                      </Index.Box>
                    </Index.Grid>
                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Issued Challan Number
                      </Index.Typography>
                      <Index.Box className="input-design-div with-border">
                        <Index.TextField
                          hiddenLabel
                          // type="number"
                          id="filled-hidden-label-normal"
                          placeholder="Issued Challan Number"
                          variant="filled"
                          className="admin-input-design input-placeholder"
                          name="issuedChallanNumber"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values?.issuedChallanNumber}
                        />
                      </Index.Box>
                    </Index.Grid>
                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Issued Challan Date
                      </Index.Typography>
                      <Index.Box className="input-design-div">
                        <Index.Box className="set-date set-date-picker">
                          <Index.LocalizationProvider
                            dateAdapter={Index.AdapterDayjs}
                          >
                            <Index.DemoContainer
                              components={["Index.DateTimePicker"]}
                            >
                              <DateTimePicker
                                // defaultValue={dayjs().
                                //   format("YYYY-MM-DD HH:mm:ss")
                                // }
                                disableFuture
                                // disablePast
                                // value={dayjs(
                                //   Index.moment(
                                //     values?.issuedChallanDate
                                //   ).format("DD/MM/YYYY HH:mm")
                                // )}
                                timeSteps={{
                                  hours: 1,
                                  minutes: 1,
                                }}
                                defaultValue={
                                  editData
                                    ? Index.dayjs(
                                        editData?.[0]?.issuedChallanDate
                                          ? editData?.[0]?.issuedChallanDate
                                          : "-"
                                      )
                                    : ""
                                }
                                onChange={(value) => {
                                  const formatedDate = Index.moment(
                                    value.$d
                                  ).format("DD/MM/YYYY HH:mm");
                                  setFieldValue(
                                    "issuedChallanDate",
                                    formatedDate
                                  );
                                }}
                                slotProps={{
                                  textField: {
                                    readOnly: true,
                                  },
                                }}
                                format="DD/MM/YYYY HH:mm"
                              />
                            </Index.DemoContainer>
                          </Index.LocalizationProvider>
                        </Index.Box>
                      </Index.Box>
                    </Index.Grid>

                    {/* new one add */}
                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        RCVD BDN
                      </Index.Typography>
                      <Index.Box className="input-design-div">
                        <Index.Box className="set-date set-date-picker">
                          <Index.LocalizationProvider
                            dateAdapter={Index.AdapterDayjs}
                          >
                            <Index.DemoContainer
                              components={["Index.DateTimePicker"]}
                            >
                              <DateTimePicker
                                disableFuture
                                timeSteps={{
                                  hours: 1,
                                  minutes: 1,
                                }}
                                defaultValue={
                                  editData
                                    ? Index.dayjs(
                                        editData?.[0]?.rcvdBdn
                                          ? editData?.[0]?.rcvdBdn
                                          : "-"
                                      )
                                    : ""
                                }
                                onChange={(value) => {
                                  const formatedDate = Index.moment(
                                    value.$d
                                  ).format("DD/MM/YYYY HH:mm");
                                  setFieldValue("rcvdBdn", formatedDate);
                                }}
                                slotProps={{
                                  textField: {
                                    readOnly: true,
                                  },
                                }}
                                format="DD/MM/YYYY HH:mm"
                              />
                            </Index.DemoContainer>
                          </Index.LocalizationProvider>
                        </Index.Box>
                      </Index.Box>
                    </Index.Grid>

                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Lop Issue
                      </Index.Typography>
                      <Index.Box className="input-design-div with-border">
                        <FormControl>
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            onChange={(e) => {
                              setFieldValue("lopIssue", e.target.value);
                            }}
                          >
                            <FormControlLabel
                              value="Yes"
                              name="lopIssue"
                              false
                              control={<Radio />}
                              label="Yes"
                              checked={values.lopIssue === "Yes" ? true : false}
                            />
                            <FormControlLabel
                              value="No"
                              name="lopIssue"
                              control={<Radio />}
                              label="No"
                              checked={values.lopIssue === "No" ? true : false}
                            />
                          </RadioGroup>
                        </FormControl>
                      </Index.Box>
                    </Index.Grid>
                    {values?.lopIssue === "Yes" && (
                      <Index.Grid item xs={12} sm={6} md={4}>
                        <Index.Typography
                          variant="label"
                          component="label"
                          className="input-label"
                        >
                          Lop Issue (Remark)
                        </Index.Typography>
                        <Index.Box className="input-design-div with-border">
                          <Index.TextField
                            hiddenLabel
                            //   type="number"
                            id="filled-hidden-label-normal"
                            placeholder="Lop Issue(Remark)"
                            variant="filled"
                            className="admin-input-design input-placeholder Lop Issue(Remark)"
                            name="remarkOfLop"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values?.remarkOfLop}
                          />
                        </Index.Box>
                      </Index.Grid>
                    )}
                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Dispute
                      </Index.Typography>
                      <Index.Box className="input-design-div with-border">
                        <FormControl>
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            onChange={(e) => {
                              setFieldValue("dispute", e.target.value);
                            }}
                          >
                            <FormControlLabel
                              value="Yes"
                              name="dispute"
                              false
                              control={<Radio />}
                              label="Yes"
                              checked={values.dispute === "Yes" ? true : false}
                            />
                            <FormControlLabel
                              value="No"
                              name="dispute"
                              control={<Radio />}
                              label="No"
                              checked={values.dispute === "No" ? true : false}
                            />
                          </RadioGroup>
                        </FormControl>
                      </Index.Box>
                    </Index.Grid>
                    {values?.dispute === "Yes" && (
                      <Index.Grid item xs={12} sm={6} md={4}>
                        <Index.Typography
                          variant="label"
                          component="label"
                          className="input-label"
                        >
                          Dispute (Remark)
                        </Index.Typography>
                        <Index.Box className="input-design-div with-border">
                          <Index.TextField
                            hiddenLabel
                            id="filled-hidden-label-normal"
                            placeholder="Dispute (Remark)"
                            variant="filled"
                            className="admin-input-design input-placeholder Lop Issue(Remark)"
                            name="remarkofDispute"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values?.remarkofDispute}
                          />
                        </Index.Box>
                      </Index.Grid>
                    )}
                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Rough Weather
                      </Index.Typography>
                      <Index.Box className="input-design-div with-border">
                        <FormControl>
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            onChange={(e) => {
                              setFieldValue("roughWeather", e.target.value);
                            }}
                          >
                            <FormControlLabel
                              value="Yes"
                              name="roughWeather"
                              false
                              control={<Radio />}
                              label="Yes"
                              checked={
                                values.roughWeather === "Yes" ? true : false
                              }
                            />
                            <FormControlLabel
                              value="No"
                              name="roughWeather"
                              control={<Radio />}
                              label="No"
                              checked={
                                values.roughWeather === "No" ? true : false
                              }
                            />
                          </RadioGroup>
                        </FormControl>
                      </Index.Box>
                    </Index.Grid>
                    {values?.roughWeather === "Yes" && (
                      <>
                        <Index.Grid item xs={12} sm={6} md={4}>
                          <Index.Typography
                            variant="label"
                            component="label"
                            className="input-label"
                          >
                            Rough Weather Wind (Knots)
                          </Index.Typography>
                          <Index.Box className="input-design-div with-border">
                            <Index.TextField
                              hiddenLabel
                              type="number"
                              id="filled-hidden-label-normal"
                              placeholder="Rough Weather Wind (Knots)"
                              variant="filled"
                              className="admin-input-design input-placeholder"
                              name="wind"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values?.wind}
                            />
                          </Index.Box>
                        </Index.Grid>
                        <Index.Grid item xs={12} sm={6} md={4}>
                          <Index.Typography
                            variant="label"
                            component="label"
                            className="input-label"
                          >
                            Rough Weather Swel (mtr)
                          </Index.Typography>
                          <Index.Box className="input-design-div with-border">
                            <Index.TextField
                              hiddenLabel
                              type="number"
                              id="filled-hidden-label-normal"
                              placeholder="Rough Weather Swel (mtr)"
                              variant="filled"
                              className="admin-input-design input-placeholder"
                              name="swel"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values?.swel}
                            />
                          </Index.Box>
                        </Index.Grid>
                        <Index.Grid item xs={12} sm={6} md={4}>
                          <Index.Typography
                            variant="label"
                            component="label"
                            className="input-label"
                          >
                            Rough Weather Rain (visibility)
                          </Index.Typography>
                          <Index.Box className="input-design-div with-border">
                            <Index.TextField
                              hiddenLabel
                              type="number"
                              id="filled-hidden-label-normal"
                              placeholder="Rough Weather Rain (visibility)"
                              variant="filled"
                              className="admin-input-design input-placeholder"
                              name="rain"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values?.rain}
                            />
                          </Index.Box>
                        </Index.Grid>
                      </>
                    )}
                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Return
                      </Index.Typography>
                      <Index.Box className="input-design-div with-border">
                        <FormControl>
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            onChange={(e) => {
                              setFieldValue("returnType", e.target.value);
                            }}
                          >
                            <FormControlLabel
                              value="Yes"
                              name="returnType"
                              false
                              control={<Radio />}
                              label="Yes"
                              checked={
                                values.returnType === "Yes" ? true : false
                              }
                            />
                            <FormControlLabel
                              value="No"
                              name="returnType"
                              control={<Radio />}
                              label="No"
                              checked={
                                values.returnType === "No" ? true : false
                              }
                            />
                          </RadioGroup>
                        </FormControl>
                      </Index.Box>
                    </Index.Grid>

                    {/* {values?.returnType === "Yes" && ( */}
                    {/* <> */}
                    {values?.returnType === "Yes" && (
                      <>
                        <Index.Grid item xs={12} sm={6} md={4}>
                          <Index.Typography
                            variant="label"
                            component="label"
                            className="input-label"
                          >
                            Return Cast Off
                          </Index.Typography>
                          <Index.Box className="input-design-div">
                            <Index.Box className="set-date set-date-picker">
                              <Index.LocalizationProvider
                                dateAdapter={Index.AdapterDayjs}
                              >
                                <Index.DemoContainer
                                  components={["Index.DateTimePicker"]}
                                >
                                  <DateTimePicker
                                    disableFuture
                                    // disablePast
                                    // value={dayjs(
                                    //   Index.moment(
                                    //     values?.returnCastOff
                                    //   ).format("DD/MM/YYYY HH:mm")
                                    // )}
                                    timeSteps={{
                                      hours: 1,
                                      minutes: 1,
                                    }}
                                    defaultValue={
                                      editData
                                        ? Index.dayjs(
                                            editData?.[0]?.returnData
                                              ?.returnCastOff
                                              ? editData?.[0]?.returnData
                                                  ?.returnCastOff
                                              : "-"
                                          )
                                        : ""
                                    }
                                    onChange={(value) => {
                                      const formatedDate = Index.moment(
                                        value.$d
                                      ).format("DD/MM/YYYY HH:mm");
                                      setFieldValue(
                                        "returnCastOff",
                                        formatedDate
                                      );
                                    }}
                                    slotProps={{
                                      textField: {
                                        readOnly: true,
                                      },
                                    }}
                                    format="DD/MM/YYYY HH:mm"
                                  />
                                </Index.DemoContainer>
                              </Index.LocalizationProvider>
                            </Index.Box>
                          </Index.Box>
                        </Index.Grid>

                        <Index.Grid item xs={12} sm={6} md={4}>
                          <Index.Typography
                            variant="label"
                            component="label"
                            className="input-label"
                          >
                            Return Anchor Drop
                          </Index.Typography>
                          <Index.Box className="input-design-div">
                            <Index.Box className="set-date set-date-picker">
                              <Index.LocalizationProvider
                                dateAdapter={Index.AdapterDayjs}
                              >
                                <Index.DemoContainer
                                  components={["Index.DateTimePicker"]}
                                >
                                  <DateTimePicker
                                    disableFuture
                                    // disablePast
                                    // value={dayjs(
                                    //   Index.moment(
                                    //     values?.returnAnchorDrop
                                    //   ).format("DD/MM/YYYY HH:mm")
                                    // )}
                                    timeSteps={{
                                      hours: 1,
                                      minutes: 1,
                                    }}
                                    defaultValue={
                                      editData
                                        ? Index.dayjs(
                                            editData?.[0]?.returnData
                                              ?.returnAnchorDrop
                                              ? editData?.[0]?.returnData
                                                  ?.returnAnchorDrop
                                              : "-"
                                          )
                                        : ""
                                    }
                                    onChange={(value) => {
                                      const formatedDate = Index.moment(
                                        value.$d
                                      ).format("DD/MM/YYYY HH:mm");
                                      setFieldValue(
                                        "returnAnchorDrop",
                                        formatedDate
                                      );
                                    }}
                                    slotProps={{
                                      textField: {
                                        readOnly: true,
                                      },
                                    }}
                                    format="DD/MM/YYYY HH:mm"
                                  />
                                </Index.DemoContainer>
                              </Index.LocalizationProvider>
                            </Index.Box>
                          </Index.Box>
                        </Index.Grid>

                        <Index.Grid item xs={12} sm={6} md={4}>
                          <Index.Typography
                            variant="label"
                            component="label"
                            className="input-label"
                          >
                            Return Diesel Consumption
                          </Index.Typography>
                          <Index.Box className="input-design-div with-border">
                            <Index.TextField
                              hiddenLabel
                              type="number"
                              id="filled-hidden-label-normal"
                              placeholder="Return Diesel Consumption"
                              variant="filled"
                              className="admin-input-design input-placeholder"
                              name="returnDieselConsumption"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values?.returnDieselConsumption}
                            />
                          </Index.Box>
                        </Index.Grid>

                        <Index.Grid item xs={12} sm={6} md={4}>
                          <Index.Typography
                            variant="label"
                            component="label"
                            className="input-label"
                          >
                            Return Reason
                          </Index.Typography>
                          <Index.Box className="input-design-div with-border">
                            <Index.TextField
                              hiddenLabel
                              // type="number"
                              id="filled-hidden-label-normal"
                              placeholder="Return Reason"
                              variant="filled"
                              className="admin-input-design input-placeholder"
                              name="returnReason"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values?.returnReason}
                            />
                          </Index.Box>
                        </Index.Grid>
                      </>
                    )}

                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Waiting
                      </Index.Typography>
                      <Index.Box className="input-design-div with-border">
                        <FormControl>
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            onChange={(e) => {
                              setFieldValue("waiting", e.target.value);
                            }}
                          >
                            <FormControlLabel
                              value="Yes"
                              name="waiting"
                              false
                              control={<Radio />}
                              label="Yes"
                              checked={values.waiting === "Yes" ? true : false}
                            />
                            <FormControlLabel
                              value="No"
                              name="waiting"
                              control={<Radio />}
                              label="No"
                              checked={values.waiting === "No" ? true : false}
                            />
                          </RadioGroup>
                        </FormControl>
                      </Index.Box>
                    </Index.Grid>

                    {values?.waiting === "Yes" && (
                      <>
                        {" "}
                        <Index.Grid item xs={12} sm={6} md={4}>
                          <Index.Typography
                            variant="label"
                            component="label"
                            className="input-label"
                          >
                            Drop Anchor At Anchorage
                          </Index.Typography>
                          <Index.Box className="input-design-div">
                            <Index.Box className="set-date set-date-picker">
                              <Index.LocalizationProvider
                                dateAdapter={Index.AdapterDayjs}
                              >
                                <Index.DemoContainer
                                  components={["Index.DateTimePicker"]}
                                >
                                  <DateTimePicker
                                    disableFuture
                                    // disablePast
                                    // value={dayjs(
                                    //   Index.moment(
                                    //     values?.dropAnchorAtAnchorage
                                    //   ).format("DD/MM/YYYY HH:mm")
                                    // )}
                                    timeSteps={{
                                      hours: 1,
                                      minutes: 1,
                                    }}
                                    defaultValue={
                                      editData
                                        ? Index.dayjs(
                                            editData?.[0]?.dropAnchorAtAnchorage
                                              ? editData?.[0]
                                                  ?.dropAnchorAtAnchorage
                                              : "-"
                                          )
                                        : ""
                                    }
                                    onChange={(value) => {
                                      const formatedDate = Index.moment(
                                        value.$d
                                      ).format("DD/MM/YYYY HH:mm");
                                      setFieldValue(
                                        "dropAnchorAtAnchorage",
                                        formatedDate
                                      );
                                    }}
                                    slotProps={{
                                      textField: {
                                        readOnly: true,
                                      },
                                    }}
                                    format="DD/MM/YYYY HH:mm"
                                  />
                                </Index.DemoContainer>
                              </Index.LocalizationProvider>
                            </Index.Box>
                          </Index.Box>
                        </Index.Grid>
                        <Index.Grid item xs={12} sm={6} md={4}>
                          <Index.Typography
                            variant="label"
                            component="label"
                            className="input-label"
                          >
                            Anchor Awigh From Anchorage
                          </Index.Typography>
                          <Index.Box className="input-design-div">
                            <Index.Box className="set-date set-date-picker">
                              <Index.LocalizationProvider
                                dateAdapter={Index.AdapterDayjs}
                              >
                                <Index.DemoContainer
                                  components={["Index.DateTimePicker"]}
                                >
                                  <DateTimePicker
                                    disableFuture
                                    // disablePast
                                    // value={dayjs(
                                    //   Index.moment(
                                    //     values?.anchorAwighFromAnchorage
                                    //   ).format("DD/MM/YYYY HH:mm")
                                    // )}
                                    timeSteps={{
                                      hours: 1,
                                      minutes: 1,
                                    }}
                                    defaultValue={
                                      editData
                                        ? Index.dayjs(
                                            editData?.[0]
                                              ?.anchorAwighFromAnchorage
                                              ? editData?.[0]
                                                  ?.anchorAwighFromAnchorage
                                              : "-"
                                          )
                                        : ""
                                    }
                                    onChange={(value) => {
                                      const formatedDate = Index.moment(
                                        value.$d
                                      ).format("DD/MM/YYYY HH:mm");
                                      setFieldValue(
                                        "anchorAwighFromAnchorage",
                                        formatedDate
                                      );
                                    }}
                                    slotProps={{
                                      textField: {
                                        readOnly: true,
                                      },
                                    }}
                                    format="DD/MM/YYYY HH:mm"
                                  />
                                </Index.DemoContainer>
                              </Index.LocalizationProvider>
                            </Index.Box>
                          </Index.Box>
                        </Index.Grid>
                      </>
                    )}
                  </Index.Grid>
                </Index.Box>
                <Index.Box className="common-button blue-button flex-end save-btn inquiry-submit-btn">
                  <Index.Button
                    variant="contained"
                    type="submit"
                    disabled={loading}
                  >
                    {loading ? (
                      <Index.CircularProgress color="secondary" size={20} />
                    ) : editData ? (
                      "Update"
                    ) : (
                      "Submit"
                    )}
                  </Index.Button>
                </Index.Box>
              </form>
            )}
          </Formik>
        </Index.Box>
      </Index.Box>
    </>
  );
}

export default EditBunkerTiming;
