import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import {
  getInvoiceActivityLog,
  getSingleUser,
} from "../../../../redux/admin/action";

function SingleUser() {
  const navigate = Index.useNavigate();
  const dispatch = Index.useDispatch();
  const { id } = Index.useParams();
  const [viewData, setViewData] = useState({});

  const backBtn = () => {
    navigate("/admin/users");
  };

  // console.log(viewData, 'viewData')
  const handleEdit = () => {
    navigate("/admin/users/add", {
      state: { item: viewData }
    });
  };

  // get single user
  const getSingleUsers = () => {
    dispatch(getSingleUser({ id })).then((res) => {
      setViewData(res?.data);
    });
  };

  // redux data
  const { invoiceActivityLogList } = Index.useSelector(
    (state) => state.AdminReducer
  );

  // filter activity log
  const filterchallanLogData = invoiceActivityLogList.filter((item) => {
    return item.activityTypeId === viewData?._id;
  });


  // user activity log
  const userActivityLog = () => {
    const userLogData = {
      activityTypeId: id,
      type: "user"
    };
    dispatch(getInvoiceActivityLog(userLogData));
  }


  // get activity log
  useEffect(() => {
    getSingleUsers(); // get single order
    userActivityLog(); // get activity log
  }, [dispatch, id]);

  return (
    <>
      <Index.Box className="barge-common-box">
        <Index.Box className="">
          <Index.Box className="title-header">
            <Index.Box className="title-header-flex flex-column-res">
              <Index.Box className="title-main mb-10 invoice_number">
                <Index.Box>
                  <Index.Typography
                    variant="p"
                    component="p"
                    className="page-title"
                  >
                    User
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
              <Index.Box className="common-button blue-button save-btn res-button-width">
                <Index.Button
                  variant="contained"
                  type="submit"
                  className="Particular_model_submit view-invoice-btn"
                  onClick={() => handleEdit()}
                >
                  Edit
                </Index.Button>
                <Index.Button
                  variant="contained"
                  type="submit"
                  className="Particular_model_submit view-invoice-btn"
                  onClick={backBtn}
                >
                  Back
                </Index.Button>
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Index.Box className="invoice-details">
            <Index.Grid container spacing={2}>
              <Index.Grid item xs={12} sm={6} md={4}>
                <Index.Box className="invoice-detail-item">
                  <Index.Typography
                    variant="label"
                    component="label"
                    className="input-label user-img-open-align"
                  >
                    Image :
                  </Index.Typography>
                  <Index.Box className="input-design-div">
                    <Index.Box className="set-date set-date-picker">
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        <img
                          src={`${process.env.REACT_APP_IMAGE_URL}/users/${viewData?.image ? viewData?.image : ""
                            }`}

                          alt="Image Preview"
                          className="user-img"
                        />
                      </Index.Typography>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Grid>

              <Index.Grid item xs={12} sm={6} md={4}>
                <Index.Box>
                  <Index.Box className="invoice-detail-item">
                    <Index.Typography
                      variant="label"
                      component="label"
                      className="input-label"
                    >
                      First Name :
                    </Index.Typography>
                    <Index.Box className="input-design-div with-border">
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        {viewData?.firstName ? viewData?.firstName : "-"}
                      </Index.Typography>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>


              </Index.Grid>

              <Index.Grid item xs={12} sm={6} md={4}>
              <Index.Box className="invoice-detail-item">
                <Index.Typography
                  variant="label"
                  component="label"
                  className="input-label"
                >
                 Last Name :
                </Index.Typography>
                <Index.Box className="input-design-div with-border">
                  <Index.Typography
                    variant="label"
                    component="label"
                    className="input-label"
                  >
                    {viewData?.lastName ? viewData?.lastName : "-"}
                  </Index.Typography>
                </Index.Box>
                </Index.Box>
              </Index.Grid>

              <Index.Grid item xs={12} sm={6} md={4}>
              <Index.Box className="invoice-detail-item">
                <Index.Typography
                  variant="label"
                  component="label"
                  className="input-label"
                >
                  Email :
                </Index.Typography>
                <Index.Box className="input-design-div with-border">
                  <Index.Typography
                    variant="label"
                    component="label"
                    className="input-label"
                  >
                    {viewData?.email ? viewData?.email : "-"}
                  </Index.Typography>
                </Index.Box>
                </Index.Box>
              </Index.Grid>

              <Index.Grid item xs={12} sm={6} md={4}>
              <Index.Box className="invoice-detail-item">
                <Index.Typography
                  variant="label"
                  component="label"
                  className="input-label"
                >
                  Mobile Number :
                </Index.Typography>
                <Index.Box className="input-design-div with-border">
                  <Index.Typography
                    variant="label"
                    component="label"
                    className="input-label"
                  >
                    {viewData?.mobileNumber ? viewData?.mobileNumber : "-"}
                  </Index.Typography>
                </Index.Box>
                </Index.Box>
              </Index.Grid>

              <Index.Grid item xs={12} sm={6} md={4}>
              <Index.Box className="invoice-detail-item">
                <Index.Typography
                  variant="label"
                  component="label"
                  className="input-label"
                >
                  Address :
                </Index.Typography>
                <Index.Box className="input-design-div with-border">
                  <Index.Typography
                    variant="label"
                    component="label"
                    className="input-label"
                  >
                    {viewData?.address ? viewData?.address : "-"}
                  </Index.Typography>
                </Index.Box>
                </Index.Box>
              </Index.Grid>

              <Index.Grid item xs={12} sm={6} md={4}>
              <Index.Box className="invoice-detail-item">
                <Index.Typography
                  variant="label"
                  component="label"
                  className="input-label"
                >
                  Role :
                </Index.Typography>
                <Index.Box className="input-design-div with-border">
                  <Index.Typography
                    variant="label"
                    component="label"
                    className="input-label"
                  >
                    {viewData?.permission?.roleName ? viewData?.permission?.roleName : "-"}
                  </Index.Typography>
                </Index.Box>
                </Index.Box>
              </Index.Grid>
            </Index.Grid>
          </Index.Box>
        </Index.Box>
       
      </Index.Box>
      <Index.Box className="barge-common-box mt-20">
      <Index.Box className="">
          <Index.TableContainer>
          <Index.Box className="title-main mb-10 invoice_number">
                <Index.Box>
                  <Index.Typography
                    variant="p"
                    component="p"
                    className="page-title"
                  >
                    Activity Log
                  </Index.Typography>
                </Index.Box>
              </Index.Box>

            <Index.Table className="table-design-main supply-table tug-supply-table one-line-table invoice-table-set">
              <Index.TableHead>
                <Index.TableRow>
                  <Index.TableCell>
                    Activity Type
                  </Index.TableCell>
                  <Index.TableCell>
                    Details
                  </Index.TableCell>
                  <Index.TableCell>
                    Date & Time
                  </Index.TableCell>
                </Index.TableRow>
              </Index.TableHead>
              <Index.TableBody>
                {filterchallanLogData?.length ? (
                  filterchallanLogData &&
                  filterchallanLogData?.map((items, index) => {
                    return (
                      <Index.TableRow>
                        <Index.TableCell>
                          {/* <Index.Typography> */}
                            {items?.activityType}
                          {/* </Index.Typography> */}
                        </Index.TableCell>
                        <Index.TableCell>
                          {/* <Index.Typography> */}
                          {items?.details}
                          {/* </Index.Typography> */}
                        </Index.TableCell>
                        <Index.TableCell>
                          {/* <Index.Typography> */}
                            {Index.moment(items?.createdAt).format(
                              "Do MMMM YYYY h:mm:ss a"
                            )}
                          {/* </Index.Typography> */}
                        </Index.TableCell>
                      </Index.TableRow>
                    );
                  })
                ) : (
                  <Index.TableRow>
                    <Index.TableCell
                      component="td"
                      variant="td"
                      scope="row"
                      className="no-data-in-list"
                      colSpan={6}
                      align="center"
                    >
                      No data available
                    </Index.TableCell>
                  </Index.TableRow>
                )}
              </Index.TableBody>
            </Index.Table>
          </Index.TableContainer>
        </Index.Box>
      </Index.Box>
    </>
  );
}

export default SingleUser;
