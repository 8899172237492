import React, { useState } from "react";
import Index from "../../../Index";
import { Formik } from "formik";
import { addTraderMaster } from "../../../../redux/admin/action";
import { traderMasterValidationSchema } from "../../../../validation/AdminValidation";

function AddTrader() {
  const dispatch = Index.useDispatch();
  const navigate = Index.useNavigate();
  const { state } = Index.useLocation();
  const [loading, setLoading] = useState(false);
  // edit data
  let editData = state?.row;
  if (editData) {
    editData.id = editData?._id;
  }
  let initialValues = {};
  if (editData) {
    initialValues = editData;
  } else {
    initialValues = {
      traderName: "",
      phoneNumber: "",
      email: "",
    };
  }
  const handleFormSubmit = async (values) => {
    setLoading(true);
    let data = new URLSearchParams();
    if (values?.traderName) {
      data.append("traderName", values.traderName);
    }
    if (values?.email) {
      data.append("email", values.email);
    }
    if (values?.phoneNumber) {
      data.append("phoneNumber", values.phoneNumber);
    }
    if (editData) {
      data.append("id", editData?._id);
    }
    dispatch(addTraderMaster(data, navigate, setLoading));
  };
  const BackBtn = () => {
    navigate("/admin/traders");
  };

  return (
    <>
      <Index.Box className="">
        <Index.Box className="barge-common-box">
          <Index.Box className="title-header">
            <Index.Box className="title-header-flex">
              <Index.Box className="title-main mb-10">
                <Index.Typography
                  variant="p"
                  component="p"
                  className="page-title"
                >
                  {editData ? "Edit" : "Add"} Trader
                </Index.Typography>
              </Index.Box>
              <Index.Box className="common-button blue-button save-btn ">
                <Index.Button
                  variant="contained"
                  type="submit"
                  className="Particular_model_submit ml-0"
                  onClick={BackBtn}
                >
                  Back
                </Index.Button>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Formik
            // enableReinitialize
            initialValues={initialValues}
            validationSchema={traderMasterValidationSchema}
            onSubmit={handleFormSubmit}
          >
            {({
              values,
              errors,
              touched,
              setFieldValue,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <form onSubmit={handleSubmit}>
                <Index.Box className="">
                  <Index.Grid container spacing={2} className="grid-flex-start">
                    <Index.Grid item xs={12} sm={6} md={3}>
                      {/* <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Trader Name
                      </Index.Typography> */}
                      <Index.Box className="input-design-div with-border">
                        <Index.TextField
                          hiddenLabel
                          id="filled-hidden-label-normal"
                          placeholder="Trader name"
                          variant="filled"
                          className="admin-input-design input-placeholder"
                          name="traderName"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values?.traderName}
                          error={Boolean(
                            errors.traderName ? touched.traderName : undefined
                          )}
                          helperText={
                            touched.traderName ? errors.traderName : undefined
                          }
                        />
                      </Index.Box>
                    </Index.Grid>

                    <Index.Grid item xs={12} sm={6} md={3}>
                      {/* <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Email
                      </Index.Typography> */}
                      <Index.Box className="input-design-div with-border">
                        <Index.TextField
                          className="admin-input-design input-placeholder user-email-field"
                          hiddenLabel
                          id="filled-hidden-label-normal"
                          placeholder="Email"
                          variant="filled"
                          autoComplete="off"
                          name="email"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values?.email}
                          error={Boolean(
                            errors?.email ? touched?.email : undefined
                          )}
                          helperText={
                            touched?.email ? errors?.email : undefined
                          }
                        />
                      </Index.Box>
                    </Index.Grid>

                    <Index.Grid item xs={12} sm={6} md={3}>
                      {/* <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Phone Number
                      </Index.Typography> */}
                      <Index.Box className="input-design-div with-border">
                        <Index.TextField
                          fullWidth
                          id="fullWidth"
                          type="number"
                          placeholder="Phone number"
                          variant="filled"
                          className="form-control custom-control"
                          name="phoneNumber"
                          onBlur={handleBlur}
                          value={values?.phoneNumber}
                          onChange={(e) => {
                            if (e.target.value.toString().length <= 10) {
                              setFieldValue("phoneNumber", e.target.value);
                            }
                          }}
                          startAdornment={
                            <Index.InputAdornment position="start"></Index.InputAdornment>
                          }
                          error={Boolean(
                            errors.phoneNumber ? touched.phoneNumber : undefined
                          )}
                          helperText={
                            touched.phoneNumber ? errors.phoneNumber : undefined
                          }
                        />
                      </Index.Box>
                    </Index.Grid>
                    <Index.Grid item xs={12} sm={6} md={3} className="submit-btn-sec">
                    <Index.Box className="common-button blue-button save-btn flex-col">
                  <Index.Button
                    variant="contained"
                    type="submit"
                    disabled={loading}
                  >
                    {editData ? "Update" : "Submit"}
                  </Index.Button>
                </Index.Box>
                    </Index.Grid>
                  </Index.Grid>
                </Index.Box>
                
              </form>
            )}
          </Formik>
        </Index.Box>
      </Index.Box>
    </>
  );
}

export default AddTrader;
