import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import PageIndex from "../../../PageIndex";
import {
  getExpenseList,
  updateExpenseStatus,
  deleteExpense,
} from "../../../../redux/admin/action";

const label = { inputProps: { "aria-label": "Switch demo" } };
const Search = Index.styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: Index.alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: Index.alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const StyledInputBase = Index.styled(Index.InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

function List() {
  const dispatch = Index.useDispatch();
  const {expenseList} = Index.useSelector((state) => state.AdminReducer);
  const [open, setOpen] = useState(false);
  const [expenseModelData, setExpenseModeldata] = useState();
  const [loading, setLoading] = useState(false);
  const [listLoading,setListLoading] = useState(false);
  const [loadingRowId, setLoadingRowId] = useState();

  // Delete record state
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [rowId, setRowId] = useState("");

  // State for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState(0);
  const [paginationPerPage, setPaginationPerPage] = useState(50);

  // State for searching and set data
  const [searchedData, setSearchedData] = useState([]);
  const [currentRows, setCurrentRows] = useState([]);



  // get expense
  const getExpense = () => {
    setListLoading(true);
    dispatch(getExpenseList()).then((res) => {
      if (res?.status === 200) {
        setTimeout(() => {
              setListLoading(false);
        },2000)
      }
   });
}
  useEffect(() => {
    getExpense();
  }, []);

  // Copy original data to searched data
  useEffect(() => {
    setSearchedData(expenseList?.sort((a, b) => b?.updatedAt?.localeCompare(a?.updatedAt)));
  }, [expenseList]);

  // Search on table
  const requestSearch = (searched) => {
    if (!searched) return setSearchedData(expenseList);
    setSearchedData(
      expenseList?.filter(
        (item) =>
          item?.expenseName
            ?.toString()
            .toLowerCase()
            .includes(searched.toLowerCase()) ||
          item?.expenseType
            ?.toString()
            .toLowerCase()
            .includes(searched.toLowerCase())
      )
    );
    setCurrentPage(1);
  };

  // Pagination calculation start
  const PER_PAGE = paginationPerPage;
  useEffect(() => {
    //Page count
    const count = Math.ceil(searchedData?.length / PER_PAGE);
    setCount(count);
    const indexOfLastRow = currentPage * PER_PAGE;
    const indexOfFirstRow = indexOfLastRow - PER_PAGE;
    const modifiedRows = searchedData?.slice(indexOfFirstRow, indexOfLastRow);
    setCurrentRows(modifiedRows);
    if (modifiedRows?.length === 0 && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  }, [currentPage, searchedData, paginationPerPage, PER_PAGE]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };
  // Pagination calculation end

  const handleOpen = () => {
    setOpen(true);
    setExpenseModeldata("");
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleEdit = (item) => {
    setOpen(true);
    setExpenseModeldata(item);
  };
  const handleDeleteOpen = () => {
    // setAnchorEl(null);
    setDeleteOpen(true);
  };
  const handleDeleteClose = () => {
    setDeleteOpen(false);
  };
  const handleDeleteRecord = () => {
    const data = {
      id: rowId,
    };
    dispatch(deleteExpense(data));
    handleDeleteClose();
  };
  const handlePaymentStatus = (id, status) => {
    const data = new URLSearchParams();
    setLoadingRowId(id);
    setLoading(true);
    data.append("id", id);
    data.append("isActive", status);
    dispatch(updateExpenseStatus(data)).then((res) => {
      if (res?.status === 200) {
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      } else {
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      }
    });
  };

  return (
    <>
      <Index.DynamicTitle title="Expenses" />
      <Index.Box className="">
        <Index.Box className="barge-common-box">
          <Index.Box className="title-header">
            <Index.Box className="title-header-flex res-title-header-flex">
              <Index.Box className="title-main">
                <Index.Typography
                  variant="p"
                  component="p"
                  className="page-title"
                >
                  Expenses
                </Index.Typography>
              </Index.Box>
              <Index.Box className="d-flex align-items-center res-set-search">
                <Search className="search ">
                  <StyledInputBase
                    placeholder="Search"
                    inputProps={{ "aria-label": "search" }}
                    onChange={(e) =>
                      setTimeout(() => {
                        requestSearch(e.target.value.trim());
                      }, 1000)
                    }
                  />
                </Search>

                <Index.Box className="common-button blue-button res-blue-button">
                  <Index.RouteLink className="no-text-decoration">
                    <Index.Button
                      variant="contained"
                      disableRipple
                      onClick={() => handleOpen()}
                    >
                      Add
                    </Index.Button>
                  </Index.RouteLink>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Index.Box className="">
            <Index.TableContainer
              component={Index.Paper}
              className="table-container"
            >
              <Index.Table
                aria-label="simple table"
                className="table-design-main supply-table one-line-table invoice-table-set user-management-table expenses-table "
              >
                <Index.TableHead>
                  <Index.TableRow>
                    <Index.TableCell>Expense Name</Index.TableCell>
                    <Index.TableCell>Expense Type</Index.TableCell>
                    <Index.TableCell>Default Price</Index.TableCell>
                    <Index.TableCell>Status</Index.TableCell>
                    <Index.TableCell align="left">Action</Index.TableCell>
                  </Index.TableRow>
                </Index.TableHead>
                {listLoading ? (
                  <Index.TableBody>
                    <Index.TableRow>
                      <Index.TableCell colSpan={14} align="center">
                        <Index.CircularProgress color="secondary" size={20} />
                      </Index.TableCell>
                    </Index.TableRow>
                  </Index.TableBody>
                ) : (
                  <Index.TableBody>
                    {currentRows?.length ? (
                      currentRows?.map((item, index) => (
                        <Index.TableRow key={item?._id}>
                          <Index.TableCell>{item?.expenseName}</Index.TableCell>
                          <Index.TableCell>{item?.expenseType}</Index.TableCell>
                          <Index.TableCell>
                            ₹ {item?.defaultPrice}
                          </Index.TableCell>
                          <Index.TableCell>
                            {loading && loadingRowId === item?._id ? (
                              <Index.CircularProgress
                                color="secondary"
                                size={20}
                              />
                            ) : (
                              <Index.Switch
                                {...label}
                                checked={item?.isActive}
                                onClick={() =>
                                  handlePaymentStatus(
                                    item?._id,
                                    item?.isActive ? false : true
                                  )
                                }
                                color="success"
                                size="small"
                              />
                            )}
                          </Index.TableCell>

                          <Index.TableCell>
                            <Index.IconButton>
                              <Index.EditIcon
                                className="edit-icon-set"
                                onClick={() => handleEdit(item)}
                              />
                              {/* <Index.DeleteIcon
                                color="error"
                                onClick={() => {
                                  setRowId(item?._id);
                                  handleDeleteOpen();
                                }}
                              /> */}
                            </Index.IconButton>
                            <Index.IconButton>
                              {/* <Index.EditIcon
                                className="edit-icon-set"
                                onClick={() => handleEdit(item)}
                              /> */}
                              <Index.DeleteIcon
                                color="error"
                                onClick={() => {
                                  setRowId(item?._id);
                                  handleDeleteOpen();
                                }}
                              />
                            </Index.IconButton>
                          </Index.TableCell>
                        </Index.TableRow>
                      ))
                    ) : (
                      <Index.TableRow>
                        <Index.TableCell
                          component="td"
                          variant="td"
                          scope="row"
                          className="no-data-in-list"
                          colSpan={15}
                          align="center"
                        >
                          No data available
                        </Index.TableCell>
                      </Index.TableRow>
                    )}
                  </Index.TableBody>
                )}
              </Index.Table>
            </Index.TableContainer>
          </Index.Box>
          <Index.Box className="pagination-design flex-end">
            <Index.Stack spacing={2}>
              <Index.Box className="pagination-count">
                <Index.Box className="input-design-div with-border">
                  <Index.Select
                    fullWidth
                    value={paginationPerPage}
                    onChange={(e) => {
                      setPaginationPerPage(e.target.value);
                    }}
                  >
                    <Index.MenuItem value={10}>10</Index.MenuItem>
                    <Index.MenuItem value={25}>25</Index.MenuItem>
                    <Index.MenuItem value={50}>50</Index.MenuItem>
                    <Index.MenuItem value={100}>100</Index.MenuItem>
                  </Index.Select>
                </Index.Box>
                <Index.Pagination
                  count={count}
                  page={currentPage}
                  onChange={handlePageChange}
                />
              </Index.Box>
            </Index.Stack>
          </Index.Box>
        </Index.Box>
      </Index.Box>

      <Index.DeleteModal
        deleteOpen={deleteOpen}
        handleDeleteRecord={handleDeleteRecord}
        handleDeleteClose={handleDeleteClose}
      />

      <PageIndex.AddExpenseModel
        handleOpen={handleOpen}
        handleClose={handleClose}
        setOpen={setOpen}
        open={open}
        expenseModelData={expenseModelData}
      />
    </>
  );
}

export default List;
