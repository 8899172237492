import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { SingleInputDateRangeField } from "@mui/x-date-pickers-pro/SingleInputDateRangeField";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import {
  allCancelOrder,
  getInvoiceActivityLog,
  orderShippingBillBoxCategory,
  completedOrderList,
  updateBunkerOrderStatus,
} from "../../../../../redux/admin/action";
import { NEW_EXPORT_EXCEL } from "../../ExportAll";
import Index from "../../../../Index";

const Search = Index.styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: Index.alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: Index.alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const StyledInputBase = Index.styled(Index.InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

const shortcutsItems = [
  {
    label: "This Week",
    getValue: () => {
      const today = dayjs();
      return [today.startOf("week"), today.endOf("week")];
    },
  },
  {
    label: "Last Week",
    getValue: () => {
      const today = dayjs();
      const prevWeek = today.subtract(7, "day");
      return [prevWeek.startOf("week"), prevWeek.endOf("week")];
    },
  },
  {
    label: "Last 7 Days",
    getValue: () => {
      const today = dayjs();
      return [today.subtract(7, "day"), today];
    },
  },
  {
    label: "Current Month",
    getValue: () => {
      const today = dayjs();
      return [today.startOf("month"), today.endOf("month")];
    },
  },
  {
    label: "Next Month",
    getValue: () => {
      const today = dayjs();
      const startOfNextMonth = today.endOf("month").add(1, "day");
      return [startOfNextMonth, startOfNextMonth.endOf("month")];
    },
  },
  { label: "Reset", getValue: () => [null, null] },
];

function InvoiceBunker() {
  const dispatch = Index.useDispatch();
  const [loading, setLoading] = useState(false);
  const [orderCheckId, setOrderCheckId] = useState([]);

  // State for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [paginationPerPage, setPaginationPerPage] = useState(50);
  const [searchingText, setSearchingText] = useState("");
  const [totalRecordCount, setTotalRecordCount] = useState(0);
  const [serachedDateRange, setSearchedDateRange] = useState("");
  const [completedOrdersList, setCompletedOrdersList] = useState([]);
  const [bunkerOrderActionType, setBunkerOrderActionType] = React.useState([]);

  // multiple order cancel
  const cancelMultipleOrder = () => {
    const data = new URLSearchParams();
    orderCheckId?.forEach((items) => {
      data.append("id[]", items);
    });
    data.append("orderStatus", "Cancel");
    dispatch(allCancelOrder(data)).then((res) => {
      if (res == 200) {
        setOrderCheckId([]);
        getCompletedOrderList();
        getOrderLog();
      }
    });
  };

  const handlePageChange = React.useCallback(
    (event, value) => {
      setCurrentPage(value);
      const values = {
        page: value,
        search: searchingText,
        size: paginationPerPage,
        // to:
        //   serachedDateRange?.length > 0
        //     ? serachedDateRange[0].format("YYYY-MM-DD")
        //     : "",
        // from:
        //   serachedDateRange?.length > 0
        //     ? serachedDateRange[1].format("YYYY-MM-DD")
        //     : "",
      };
      getCompletedOrderList(values);
    },
    [searchingText, paginationPerPage, serachedDateRange, setCurrentPage]
  );

  // order permission
  const orderCheckPermission = (status, id, name) => {
    const data = new URLSearchParams();
    data.append("id", id);
    if (name === "wharfage") {
      data.append("wharfage", status);
    }
    if (name === "shippingBill") {
      data.append("shippingBill", status);
    }
    if (name === "harbourMaster") {
      data.append("harbourMaster", status);
    }

    dispatch(orderShippingBillBoxCategory(data, completedOrderList));
  };

  // get log for order
  const getOrderLog = () => {
    dispatch(getInvoiceActivityLog(orderCheckId));
  };

  // get completed order list
  const getCompletedOrderList = (values) => {
    setLoading(true);
    dispatch(completedOrderList(values)).then((res) => {
      if (res?.status === 200) {
        setLoading(false);
        setTotalRecordCount(res?.totalCount);
        setCurrentPage(res?.currentPage);
        setCompletedOrdersList(res?.data);
      } else {
        setLoading(false);
        setTotalRecordCount(0);
        setCurrentPage(1);
        setCompletedOrdersList([]);
      }
    });
  };

  useEffect(() => {
    const values = {
      page: 1,
      search: "",
      size: paginationPerPage,
      //   to:
      //     serachedDateRange?.length > 0
      //       ? serachedDateRange[0].format("YYYY-MM-DD")
      //       : "",
      //   from:
      //     serachedDateRange?.length > 0
      //       ? serachedDateRange[1].format("YYYY-MM-DD")
      //       : "",
    };
    getCompletedOrderList(values);
    getOrderLog();
  }, []);

  // when the use change the date then used...
  //   const handleChangeDate = React.useCallback(
  //     (data) => {
  //       setSearchedDateRange(data);
  //       if (data[0] !== null && data[1] !== null) {
  //         const values = {
  //           page: 1,
  //           search: searchingText,
  //           size: paginationPerPage,
  //           to: data[0] !== null ? data[0].format("YYYY-MM-DD") : "",
  //           from: data[1] !== null ? data[1].format("YYYY-MM-DD") : "",
  //         };
  //         getCompletedOrderList(values);
  //       }
  //     },
  //     [setSearchedDateRange, searchingText, paginationPerPage]
  //   );

  // when the user change the per page record change then used..
  const handleChangePaginationPerChange = (e) => {
    const { value } = e.target;
    setPaginationPerPage(value);
    const values = {
      page: 1,
      search: searchingText,
      size: value,
      //   to:
      //     serachedDateRange?.length > 0
      //       ? serachedDateRange[0].format("YYYY-MM-DD")
      //       : "",
      //   from:
      //     serachedDateRange?.length > 0
      //       ? serachedDateRange[1].format("YYYY-MM-DD")
      //       : "",
    };
    getCompletedOrderList(values);
  };

  // when the user search the journal records then used...
  const handleSearchCompletedListData = (e) => {
    const { value } = e.target;
    setSearchingText(value);
    if (value?.length > 0) {
      const values = {
        page: 1,
        search: value,
        size: paginationPerPage,
        // to:
        //   serachedDateRange?.length > 0
        //     ? serachedDateRange[0].format("YYYY-MM-DD")
        //     : "",
        // from:
        //   serachedDateRange?.length > 0
        //     ? serachedDateRange[1].format("YYYY-MM-DD")
        //     : "",
      };
      getCompletedOrderList(values);
    } else {
      const values = {
        page: 1,
        search: "",
        size: paginationPerPage,
        // to:
        //   serachedDateRange?.length > 0
        //     ? serachedDateRange[0].format("YYYY-MM-DD")
        //     : "",
        // from:
        //   serachedDateRange?.length > 0
        //     ? serachedDateRange[1].format("YYYY-MM-DD")
        //     : "",
      };
      getCompletedOrderList(values);
    }
  };

  // when the user change the status then used...
  const handleChangeBunkerAction = (e, data) => {
    const values = {
      id: data?._id,
      invoiceStatus: e.target.value,
    };

    dispatch(updateBunkerOrderStatus(values)).then((res) => {
      if (res.status === 200) {
        setBunkerOrderActionType((prev) => [
          ...prev.filter((res) => res.id !== data?._id),
          values,
        ]);
      }
    });
  };

  return (
    <>
      <Index.DynamicTitle title="Bunker Completed Orders" />
      <Index.Box className="">
        <Index.Box className="barge-common-box">
          <Index.Box className="title-header">
            <Index.Box className="title-header-flex res-title-header-flex">
              <Index.Box className="title-main">
                <Index.Typography
                  variant="p"
                  component="p"
                  className="page-title"
                >
                  Bunker Completed Orders
                </Index.Typography>
              </Index.Box>
              <Index.Box className="d-flex align-items-center res-set-search">
                {/* <Index.Box className="input-design-div">
                  <Index.Box className="set-date set-date-picker remove-name">
                    <Index.LocalizationProvider
                      dateAdapter={Index.AdapterDayjs}
                    >
                      <Index.DemoContainer
                        components={["SingleInputDateRangeField"]}
                        slotProps={{ textField: { size: "small" } }}
                      >
                        <DateRangePicker
                          slots={{ field: SingleInputDateRangeField }}
                          slotProps={{
                            shortcuts: {
                              items: shortcutsItems,
                            },
                            textField: {
                              readOnly: true,
                              size: "small",
                            },
                            actionBar: { actions: [] },
                          }}
                          onChange={(value) => handleChangeDate(value)}
                          name="allowedRange"
                          // defaultValue={serachedDateRange}
                        />
                      </Index.DemoContainer>
                    </Index.LocalizationProvider>
                  </Index.Box>
                </Index.Box> */}
                <Search className="search ">
                  <StyledInputBase
                    placeholder="Search"
                    inputProps={{ "aria-label": "search" }}
                    onChange={(e) => handleSearchCompletedListData(e)}
                  />
                </Search>

                {/* <Index.Box className="common-button grey-button">
                  <Index.Button
                    variant="contained"
                    // disabled={exportDetails?.length ? false : true}
                    onClick={() => {
                      let curDate = Index.moment(new Date()).format(
                        "DD-MM-YYYY"
                      );
                      NEW_EXPORT_EXCEL(
                        "/admin/export-completed-order",
                        `Complete_Order_List_${curDate}`,
                        {
                          search: searchingText,
                          to:
                            serachedDateRange?.length > 0
                              ? serachedDateRange[0].format("YYYY-MM-DD")
                              : "",
                          from:
                            serachedDateRange?.length > 0
                              ? serachedDateRange[1].format("YYYY-MM-DD")
                              : "",
                        }
                      );
                    }}
                  >
                    Export
                  </Index.Button>
                </Index.Box> */}
                <Index.Box className="common-button blue-button res-blue-button">
                  {" "}
                  {completedOrdersList?.length && orderCheckId?.length ? (
                    <Index.Button
                      variant="contained"
                      disableRipple
                      onClick={cancelMultipleOrder}
                    >
                      Cancel Selected Order
                    </Index.Button>
                  ) : (
                    ""
                  )}
                </Index.Box>

                {/* <Index.Box className="filter-main">
                  {["right"].map((anchor) => (
                    <React.Fragment key={anchor}>
                      <Index.Box className="filter-btn-main common-button filter-btn blue-button ">
                        <Index.Button
                          className="filter-btn"
                          onClick={toggleDrawer(anchor, true)}
                          disableRipple
                        >
                          <img src={Index.Svg.filter} alt="" />
                        </Index.Button>
                      </Index.Box>
                      <Index.SwipeableDrawer
                        className="filter-main"
                        anchor={anchor}
                        open={state[anchor]}
                        onClose={toggleDrawer(anchor, false)}
                        onOpen={toggleDrawer(anchor, true)}
                      >
                        <Index.Box className="filter-header">
                          <Index.Box className="d-flex align-items-center filter-header-inner">
                            <Index.Typography className="filter-title">
                              Completed Orders
                            </Index.Typography>
                            <Index.Box className="close-btn filter-close-btn">
                              <img
                                src={Index.Svg.close}
                                onClick={toggleDrawer(anchor, false)}
                                alt=""
                              />
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                        <Index.Box className="filter-inner-main">
                          <Index.Box>
                            <Index.Box>
                              <Index.Box>
                                <Index.Typography
                                  variant="label"
                                  component="label"
                                  className="input-label"
                                >
                                  Completed Orders From
                                </Index.Typography>
                                <Index.Box className="input-design-div with-border filter-date-overflow">
                                  <Index.LocalizationProvider
                                    dateAdapter={Index.AdapterDayjs}
                                  >
                                    <Index.DemoContainer
                                      components={["DatePicker"]}
                                      className="filter-demo-item"
                                    >
                                      <Index.DemoItem label="">
                                        <Index.DatePicker
                                          fullWidth
                                          disableFuture
                                          format="DD/MM/YYYY"
                                          value={nominationFromDate}
                                          className="filter-datepicker"
                                          // defaultValue={nominationFromDate}
                                          onChange={(value) => {
                                            const formatedDate = Index.moment(
                                              value.$d
                                            ).format("YYYY-MM-DD");
                                            setNominationFromError("");
                                            setNominationFromDate(formatedDate);
                                          }}
                                        />
                                      </Index.DemoItem>
                                    </Index.DemoContainer>
                                  </Index.LocalizationProvider>
                                  <Index.FormHelperText error>
                                    {nominationFromError}
                                  </Index.FormHelperText>
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                          <Index.Box className="mt-10">
                            <Index.Box>
                              <Index.Box>
                                <Index.Typography
                                  variant="label"
                                  component="label"
                                  className="input-label"
                                >
                                  Completed Orders To
                                </Index.Typography>
                                <Index.Box className="input-design-div with-border filter-date-overflow">
                                  <Index.LocalizationProvider
                                    dateAdapter={Index.AdapterDayjs}
                                  >
                                    <Index.DemoContainer
                                      components={["DatePicker"]}
                                    >
                                      <Index.DemoItem label="">
                                        <Index.DatePicker
                                          fullWidth
                                          disableFuture
                                          format="DD/MM/YYYY"
                                          value={nominationToDate}
                                          name="nomination_rec_date"
                                          className="filter-datepicker"
                                          minDate={Index.dayjs(
                                            nominationFromDate
                                          )}
                                          onChange={(value) => {
                                            const formatedDate = Index.moment(
                                              value.$d
                                            ).format("YYYY-MM-DD");
                                            setNominationToError("");
                                            setNominationToDate(formatedDate);
                                          }}
                                        />
                                      </Index.DemoItem>
                                    </Index.DemoContainer>
                                  </Index.LocalizationProvider>
                                  <Index.FormHelperText error>
                                    {nominationToError}
                                  </Index.FormHelperText>
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                        <Index.Box className="filter-footer ">
                          <Index.Box className="flex-end">
                            <Index.Box className="common-button blue-button mr-15">
                              <Index.Button
                                variant="contained"
                                disableRipple
                                onClick={() => handleFilterSubmit(anchor)}
                              >
                                Filter
                              </Index.Button>
                            </Index.Box>
                            <Index.Box className="common-button outline-button ">
                              <Index.Button
                                variant="contained"
                                disableRippl
                                onClick={() => handleFilterClear(anchor)}
                              >
                                Clear All
                              </Index.Button>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.SwipeableDrawer>
                    </React.Fragment>
                  ))}
                </Index.Box> */}
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Index.Box className="">
            <Index.TableContainer
              component={Index.Paper}
              className="table-container"
            >
              <Index.Table
                aria-label="simple table"
                className="table-design-main supply-table one-line-table invoice-table-set "
              >
                <Index.TableHead>
                  <Index.TableRow>
                    <Index.TableCell></Index.TableCell>
                    <Index.TableCell align="left">Vessel Name</Index.TableCell>
                    <Index.TableCell align="left">Sales Person</Index.TableCell>
                    <Index.TableCell align="left">Terminal</Index.TableCell>
                    <Index.TableCell align="left">Trader Name</Index.TableCell>
                    <Index.TableCell align="left">Port</Index.TableCell>
                    <Index.TableCell align="left">
                      Proposed Barge
                    </Index.TableCell>
                    <Index.TableCell align="left">
                      Transportation
                    </Index.TableCell>
                    <Index.TableCell align="left">Agent</Index.TableCell>
                    <Index.TableCell align="left">C/F</Index.TableCell>
                    <Index.TableCell align="left">Product Name</Index.TableCell>
                    <Index.TableCell align="left">
                      Nominated Qty (In MT)
                    </Index.TableCell>
                    <Index.TableCell align="left">
                      Loading Planned Date
                    </Index.TableCell>
                    <Index.TableCell align="left">Supply Date</Index.TableCell>
                    <Index.TableCell align="left">
                      Basis Taken On
                    </Index.TableCell>
                    <Index.TableCell align="left">Stay Hours</Index.TableCell>
                    <Index.TableCell align="left">
                      ETA for Vessel
                    </Index.TableCell>
                    <Index.TableCell align="left">ETB</Index.TableCell>
                    <Index.TableCell align="left">
                      Exmi or Delivered
                    </Index.TableCell>
                    <Index.TableCell align="left">
                      Laycan From to To
                    </Index.TableCell>
                    <Index.TableCell align="left">Created Date</Index.TableCell>
                    <Index.TableCell align="left">En_No</Index.TableCell>
                    <Index.TableCell align="left">Nomi. No</Index.TableCell>
                    <Index.TableCell align="left">
                      Nomi. Rec. Date
                    </Index.TableCell>
                    <Index.TableCell align="left">Remark</Index.TableCell>
                    <Index.TableCell align="left">
                      Challan Status
                    </Index.TableCell>
                    <Index.TableCell align="left">Action</Index.TableCell>
                  </Index.TableRow>
                </Index.TableHead>
                {loading ? (
                  <Index.TableBody>
                    <Index.TableRow>
                      <Index.TableCell colSpan={30} align="center">
                        <Index.CircularProgress color="secondary" size={20} />
                      </Index.TableCell>
                    </Index.TableRow>
                  </Index.TableBody>
                ) : (
                  <Index.TableBody className="tanker-table-body">
                    {completedOrdersList?.length ? (
                      completedOrdersList
                        ?.sort(
                          (a, b) =>
                            Index.moment(b.updatedAt).valueOf() -
                            Index.moment(a.updatedAt).valueOf()
                        )
                        .map((item, index) => (
                          <Index.TableRow
                            key={item?._id * (index + 1)}
                            className={
                              item?.bunkerCall === true
                                ? "tanker-row-color"
                                : ""
                            }
                            sx={{ cursor: "pointer" }}
                          >
                            {/* <Index.TableCell>
                              <Index.FormControlLabel
                                control={
                                  <Index.Checkbox
                                    onChange={(event) => {
                                      if (event.target.checked === true) {
                                        setOrderCheckId([
                                          ...orderCheckId,
                                          item?._id,
                                        ]);
                                      } else {
                                        setOrderCheckId(
                                          orderCheckId?.filter((ele) => {
                                            return ele !== item?._id;
                                          })
                                        );
                                      }
                                    }}
                                  />
                                }
                              />
                            </Index.TableCell> */}
                            <Index.TableCell>
                              {" "}
                              <Index.Box className="challan-icon-align">
                                <Index.Box className="d-flex">
                                  {item?.challanAvailable === false ? (
                                    <Index.ReceiptLongIcon
                                      fontSize="small"
                                      sx={{ color: "black" }}
                                      className="challan-icon icon challan-icon-hidden"
                                    />
                                  ) : item?.challanStatus === "" ? (
                                    <Index.ReceiptLongIcon
                                      fontSize="small"
                                      sx={{ color: "black" }}
                                      className="challan-icon"
                                    />
                                  ) : item?.challanStatus === "Approve" ? (
                                    <Index.ReceiptLongIcon
                                      fontSize="small"
                                      color="success"
                                      className="challan-icon"
                                    />
                                  ) : (
                                    <Index.ReceiptLongIcon
                                      fontSize="small"
                                      color="error"
                                      className="challan-icon"
                                    />
                                  )}
                                </Index.Box>
                              </Index.Box>
                            </Index.TableCell>

                            <Index.TableCell align="left">
                              {item?.vesselName ? item?.vesselName : "-"}
                            </Index.TableCell>
                            <Index.TableCell align="left">
                              {item?.salesId?.salesName
                                ? item?.salesId?.salesName
                                : "-"}
                            </Index.TableCell>
                            <Index.TableCell align="left">
                              {item?.terminal ? item?.terminal : "-"}
                            </Index.TableCell>
                            <Index.TableCell align="left">
                              {item?.trader
                                ? item?.trader
                                : item?.traderId?.traderName
                                ? item?.traderId?.traderName
                                : "-"}
                            </Index.TableCell>
                            <Index.TableCell align="left">
                              {item?.port?.portName
                                ? item?.port?.portName
                                : "-"}
                            </Index.TableCell>
                            <Index.TableCell align="left">
                              {item?.proposedBarge?.length
                                ? item?.proposedBarge
                                  ? Array.prototype.map
                                      .call(
                                        item?.proposedBarge,
                                        function (ele) {
                                          return ele.bargeName;
                                        }
                                      )
                                      .join(",")
                                  : ""
                                : "-"}
                            </Index.TableCell>
                            <Index.TableCell align="left">
                              {item?.transportation
                                ? item?.transportation
                                : "-"}
                            </Index.TableCell>
                            <Index.TableCell align="left">
                              {item?.agent ? item?.agent : "-"}
                            </Index.TableCell>
                            <Index.TableCell align="left">
                              {item?.foreign ? item?.foreign : "-"}
                            </Index.TableCell>
                            <Index.TableCell align="left">
                              {item?.product ? item?.product : "-"}
                            </Index.TableCell>
                            <Index.TableCell align="left">
                              {item?.lowerQuantity === "0" &&
                              item?.higherQuantity === "0"
                                ? "-"
                                : item?.lowerQuantity &&
                                  item?.higherQuantity != "0"
                                ? item?.lowerQuantity +
                                  " - " +
                                  item?.higherQuantity
                                : item?.lowerQuantity}
                            </Index.TableCell>
                            <Index.TableCell align="left">
                              {item?.loadingDate
                                ? Index.moment(item?.loadingDate).format(
                                    "DD/MM/YYYY"
                                  )
                                : "-"}
                            </Index.TableCell>
                            <Index.TableCell align="left">
                              {item?.supplyDate
                                ? Index.moment(item?.supplyDate).format(
                                    "DD/MM/YYYY hh:mm:ss"
                                  )
                                : "-"}
                            </Index.TableCell>
                            <Index.TableCell align="left">
                              {item?.basisTakenOn
                                ? Index.moment(item?.basisTakenOn).format(
                                    "DD/MM/YYYY"
                                  )
                                : "-"}
                            </Index.TableCell>
                            <Index.TableCell align="left">
                              {item?.stayHours ? item?.stayHours : "-"}
                            </Index.TableCell>
                            <Index.TableCell align="left">
                              {item?.laycam
                                ? Index.moment(item?.laycam).format(
                                    "DD/MM/YYYY hh:mm:ss"
                                  )
                                : "-"}
                            </Index.TableCell>
                            <Index.TableCell align="left">
                              {item?.etb
                                ? Index.moment(item?.etb).format(
                                    "DD/MM/YYYY hh:mm:ss"
                                  )
                                : "-"}
                            </Index.TableCell>
                            <Index.TableCell align="left">
                              {item?.exmiOrDelivered
                                ? item?.exmiOrDelivered
                                : "-"}
                            </Index.TableCell>

                            <Index.TableCell align="left">
                              {item?.layStartDate || item?.layEndDate ? (
                                <>
                                  {Index.moment(item?.layStartDate).format(
                                    "DD/MM/YYYY"
                                  )}{" "}
                                  -{" "}
                                  {Index.moment(item?.layEndDate).format(
                                    "DD/MM/YYYY"
                                  )}
                                </>
                              ) : (
                                "-"
                              )}
                            </Index.TableCell>

                            <Index.TableCell align="left">
                              {item?.createdAt
                                ? Index.moment(item?.createdAt).format(
                                    "DD/MM/YYYY hh:mm:ss"
                                  )
                                : "-"}
                            </Index.TableCell>
                            <Index.TableCell>
                              <Index.Box className="challan-icon-align">
                                {item?.inquiryNo ? item?.inquiryNo : "-"}
                              </Index.Box>
                            </Index.TableCell>

                            <Index.TableCell align="left">
                              {item?.nominationNo ? item?.nominationNo : "-"}
                            </Index.TableCell>

                            <Index.TableCell align="left">
                              {item?.nomination_rec_date
                                ? Index.moment(
                                    item?.nomination_rec_date
                                  ).format("DD/MM/YYYY")
                                : "-"}
                            </Index.TableCell>

                            <Index.TableCell align="left">
                              <Index.Tooltip title={item?.remark} arrow>
                                <Index.Button className="remark-text">
                                  {item?.remark ? (
                                    <>
                                      {item.remark?.length > 10 ? (
                                        <>{`${item.remark.substring(
                                          0,
                                          10
                                        )}...`}</>
                                      ) : (
                                        item.remark
                                      )}
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </Index.Button>
                              </Index.Tooltip>
                            </Index.TableCell>

                            <Index.TableCell align="left">
                              {item?.challanStatus ? (
                                <b>{item?.challanStatus}</b>
                              ) : (
                                "-"
                              )}
                            </Index.TableCell>
                            <Index.TableCell>
                              <Index.Box className="input-design-div with-border">
                                <Index.Select
                                  fullWidth
                                  value={
                                    bunkerOrderActionType?.length > 0 &&
                                    bunkerOrderActionType.find(
                                      (res) => res?.id === item?._id
                                    ) !== undefined
                                      ? bunkerOrderActionType.find(
                                          (res) => res?.id === item?._id
                                        ).invoiceStatus
                                      : item?.invoiceStatus === "Done"
                                      ? "Done"
                                      : item?.invoiceStatus === "Remaining"
                                      ? "Remaining"
                                      : ""
                                  }
                                  onChange={(e) =>
                                    handleChangeBunkerAction(e, item)
                                  }
                                >
                                  <Index.MenuItem value="Done">
                                    Done
                                  </Index.MenuItem>
                                  <Index.MenuItem value="Remaining">
                                    Remaining
                                  </Index.MenuItem>
                                </Index.Select>
                              </Index.Box>
                            </Index.TableCell>
                          </Index.TableRow>
                        ))
                    ) : (
                      <Index.TableRow>
                        <Index.TableCell
                          component="td"
                          variant="td"
                          scope="row"
                          className="no-data-in-list empty-record-align"
                          colSpan={30}
                          align="center"
                        >
                          No data available
                        </Index.TableCell>
                      </Index.TableRow>
                    )}
                  </Index.TableBody>
                )}
              </Index.Table>
            </Index.TableContainer>
          </Index.Box>
          <Index.Box className="pagination-design flex-end">
            <Index.Stack spacing={2}>
              <Index.Box className="pagination-count">
                <Index.Box className="input-design-div with-border">
                  <Index.Select
                    fullWidth
                    value={paginationPerPage}
                    onChange={(e) => handleChangePaginationPerChange(e)}
                  >
                    <Index.MenuItem value={10}>10</Index.MenuItem>
                    <Index.MenuItem value={25}>25</Index.MenuItem>
                    <Index.MenuItem value={50}>50</Index.MenuItem>
                    <Index.MenuItem value={100}>100</Index.MenuItem>
                  </Index.Select>
                </Index.Box>
                {loading ? (
                  <Index.CircularProgress
                    color="secondary"
                    sx={{ marginLeft: "10px" }}
                    size={26}
                  />
                ) : (
                  <>
                    <Index.Pagination
                      count={Math.ceil(totalRecordCount / paginationPerPage)}
                      page={currentPage}
                      onChange={handlePageChange}
                    />
                  </>
                )}
              </Index.Box>
            </Index.Stack>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
}

export default InvoiceBunker;
