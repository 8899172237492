import { DateTimePicker } from "@mui/x-date-pickers";
import { Formik } from "formik";
import dayjs from "dayjs";
import { terminalData } from "../../../../../config/CommonTerminalItems";
import { useEffect, useState } from "react";
import Index from "../../../../Index";
import { getBargeListByCategory } from "../../../../../redux/admin/action";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function UdpateOrderProposedBargeModal({
  openUpdateDatePopUp,
  handleCloseDateEditModal,
  handleUpdateDateViaApi,
  editTimeDateData,
  editTimeProposedBargeData,
  proposedBargeTimePortData,
  isSingleFieldUpdate,
}) {
  const dispatch = Index.useDispatch();

  const initialValues = {
    proposedBarge: editTimeProposedBargeData?.length
      ? editTimeProposedBargeData?.map((item) => item?._id)
      : [],
  };

  const [bargeListCatData, setBargeListCatData] = useState([]);

  const bargeListByCategory = (id) => {
    dispatch(getBargeListByCategory(id)).then((res) => {
      if (res) {
        setBargeListCatData(
          res?.data?.sort((a, b) => a?.bargeName?.localeCompare(b?.bargeName))
        );
      }
    });
  };

  // when the page first time load then used...
  useEffect(() => {
    bargeListByCategory(proposedBargeTimePortData);
  }, [proposedBargeTimePortData]);

  return (
    <>
      <Index.Dialog
        open={openUpdateDatePopUp}
        onClose={handleCloseDateEditModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Index.DialogContent className="expense-model-main  modal-update-main">
          <Index.Box className="title-main">
            <Formik
              enableReinitialize
              initialValues={initialValues}
              // validationSchema={vesselValidationSchema}
              onSubmit={(values) =>
                handleUpdateDateViaApi(values, editTimeDateData)
              }
            >
              {({
                values,
                errors,
                touched,
                setFieldValue,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldTouched,
                handleFocus,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Index.Box className="title-main">
                    <Index.Box className="jus-between">
                      <Index.Typography
                        variant="h6"
                        component="h6"
                        className="input-label"
                      >
                        Edit Proposed Barge
                      </Index.Typography>
                      <Index.ClearIcon
                        className="model-close-icon"
                        onClick={handleCloseDateEditModal}
                      />
                    </Index.Box>
                    <Index.Box className="update-model-main-box-set">
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Proposed Barge
                      </Index.Typography>
                      <Index.Box className="input-design-div with-border">
                        <Index.Select
                          className="propose-drop"
                          fullWidth
                          name="proposedBarge"
                          multiple
                          displayEmpty
                          MenuProps={MenuProps}
                          inputProps={{ "aria-label": "without label" }}
                          value={
                            values?.proposedBarge ? values?.proposedBarge : []
                          }
                          renderValue={
                            values?.proposedBarge != ""
                              ? undefined
                              : () => "Please select barge"
                          }
                          onFocus={handleFocus}
                          onChange={(event) => {
                            const {
                              target: { value },
                            } = event;
                            setFieldValue(
                              "proposedBarge",
                              typeof value === "string"
                                ? value.split(",")
                                : value
                            );
                          }}
                        >
                          <Index.MenuItem disabled value="">
                            {bargeListCatData?.length > 0 ? (
                              <em>Please select a barge</em>
                            ) : (
                              ""
                            )}
                          </Index.MenuItem>
                          {bargeListCatData?.length === 0 ? (
                            <Index.MenuItem disabled>
                              <Index.Box className="multiselect-item">
                                No data found
                              </Index.Box>
                            </Index.MenuItem>
                          ) : (
                            bargeListCatData.map((item) => (
                              <Index.MenuItem key={item?._id} value={item?._id}>
                                <Index.Box className="multiselect-item">
                                  {item?.bargeName}
                                </Index.Box>
                              </Index.MenuItem>
                            ))
                          )}
                        </Index.Select>
                      </Index.Box>

                      <Index.Box className="common-button blue-button flex-end save-btn  modal-update-btn">
                        <Index.Button
                          variant="contained"
                          type="submit"
                          className="Particular_model_submit"
                          disabled={isSingleFieldUpdate ? true : false}
                        >
                          Update
                        </Index.Button>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </form>
              )}
            </Formik>
          </Index.Box>
        </Index.DialogContent>
      </Index.Dialog>
    </>
  );
}
export default UdpateOrderProposedBargeModal;
