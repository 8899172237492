import React, { useState } from "react";
import Index from "../../../Index";
import {
  changeWharfageActionType,
  getWharfageList,
} from "../../../../redux/admin/action";

const Search = Index.styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: Index.alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: Index.alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));
const StyledInputBase = Index.styled(Index.InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

function WharfageList() {
  const dispatch = Index.useDispatch();
  const [wharfageData, setWharfageData] = useState([]);
  const [searchingText, setSearchingText] = useState("");
  const [loading, setLoading] = useState(false);

  const [wharfageActionType, setWharfageActionType] = React.useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [paginationPerPage, setPaginationPerPage] = useState(50);
  const [totalRecordsCount, setTotalRecordsCount] = useState(0);

  const handleGetWharfageRecords = (values) => {
    setLoading(true);
    dispatch(getWharfageList(values)).then((res) => {
      if (res?.status === 200) {
        setWharfageData(res?.data);
        setTotalRecordsCount(res?.totalCount);
        setLoading(false);
        setCurrentPage(parseInt(res?.currentPage));
        setWharfageActionType([]);
      } else {
        setLoading(false);
        setWharfageData([]);
        setCurrentPage(1);
        setTotalRecordsCount(0);
        setWharfageActionType([]);
      }
    });
  };

  // when the page first time load then used..
  React.useEffect(() => {
    const values = {
      page: 1,
      size: paginationPerPage,
      search: searchingText,
    };
    handleGetWharfageRecords(values);
  }, []);

  // when the user search wharfage records then used...
  const handleSearchWharfageRecords = React.useCallback(
    (e) => {
      const { value } = e.target;
      if (value?.length > 0) {
        setSearchingText(value);
        const values = {
          page: 1,
          search: value.trim(),
          size: paginationPerPage,
        };
        handleGetWharfageRecords(values);
      } else {
        setSearchingText("");
        const values = {
          page: 1,
          search: "",
          size: paginationPerPage,
        };
        handleGetWharfageRecords(values);
      }
    },
    [paginationPerPage, setSearchingText]
  );

  // when the user change the paid action type then used...
  const handleChangePaidActionType = React.useCallback(
    (e, data) => {
      const values = {
        id: data?._id,
        wharfageStatus: e.target.value,
      };
      dispatch(changeWharfageActionType(values)).then((res) => {
        if (res?.status === 200) {
          setWharfageActionType((prev) => [
            ...prev.filter((res) => res.id !== data?._id),
            {
              ...values,
              wharfageTime:
                data?._id === res?.data?._id
                  ? res?.wharfageTime
                  : data?.wharfageTime,
            },
          ]);
        }
      });
    },
    [dispatch, setWharfageActionType]
  );

  // when the user change the per page records then used...
  const handleChangePaginationPerChange = React.useCallback(
    (e) => {
      const { value } = e.target;
      setPaginationPerPage(value);
      const values = {
        page: 1,
        search: searchingText,
        size: value,
      };
      handleGetWharfageRecords(values);
    },
    [searchingText, setPaginationPerPage]
  );

  // when the user change the page then used...
  const handlePageChange = React.useCallback(
    (event, value) => {
      setCurrentPage(value);
      const values = {
        page: value,
        search: searchingText,
        size: paginationPerPage,
      };
      handleGetWharfageRecords(values);
    },
    [paginationPerPage, searchingText, setCurrentPage]
  );

  return (
    <>
      <div>
        <Index.DynamicTitle title="Purchase Management" />
        <Index.Box className="">
          <Index.Box className="barge-common-box">
            <Index.Box className="title-header">
              <Index.Box className="title-header-flex  res-title-header-flex">
                <Index.Box className="title-main">
                  <Index.Typography
                    variant="p"
                    component="p"
                    className="page-title"
                  >
                    Wharfage Management
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="d-flex align-items-center res-set-search">
                  <Search className="search">
                    <StyledInputBase
                      placeholder="Search"
                      inputProps={{ "aria-label": "search" }}
                      onChange={(e) => handleSearchWharfageRecords(e)}
                    />
                  </Search>
                </Index.Box>
              </Index.Box>
            </Index.Box>

            <Index.Box className="">
              <Index.TableContainer
                component={Index.Paper}
                className="table-container"
              >
                <Index.Table
                  aria-label="simple table"
                  className="table-design-main invoice-table  "
                >
                  <Index.TableHead>
                    <Index.TableRow>
                      <Index.TableCell className="invoice_table_header">
                        Date
                      </Index.TableCell>
                      <Index.TableCell
                        align="left"
                        className="invoice_table_header"
                      >
                        Vessel Name
                      </Index.TableCell>
                      <Index.TableCell
                        align="left"
                        className="invoice_table_header"
                      >
                        Nomination Number
                      </Index.TableCell>
                      <Index.TableCell
                        align="left"
                        className="invoice_table_header"
                      >
                        Wharfage PDF
                      </Index.TableCell>
                      <Index.TableCell
                        align="left"
                        className="invoice_table_header"
                      >
                        Status Time
                      </Index.TableCell>

                      <Index.TableCell
                        align="left"
                        className="invoice_table_header"
                      >
                        Status
                      </Index.TableCell>
                    </Index.TableRow>
                  </Index.TableHead>
                  {loading ? (
                    <Index.TableBody>
                      <Index.TableRow>
                        <Index.TableCell
                          colSpan={12}
                          align="center"
                          className="loading-purchase"
                        >
                          <Index.CircularProgress color="secondary" size={20} />
                        </Index.TableCell>
                      </Index.TableRow>
                    </Index.TableBody>
                  ) : (
                    <Index.TableBody>
                      {wharfageData?.length ? (
                        wharfageData?.map((row, index) => (
                          <Index.TableRow key={row?._id}>
                            <Index.TableCell>
                              {row?.createdAt
                                ? Index.moment(row?.createdAt).format(
                                    "DD/MM/YYYY"
                                  )
                                : "-"}
                            </Index.TableCell>
                            <Index.TableCell>
                              {row?.vesselName ? row?.vesselName : "-"}
                            </Index.TableCell>
                            <Index.TableCell>
                              {row?.nominationNo ? row?.nominationNo : "-"}
                            </Index.TableCell>
                            <Index.TableCell>
                              {row?.wharfageFile?.length ? (
                                <>
                                  {row?.wharfageFile?.map((el) => (
                                    <Index.Button
                                      sx={{ margin: "2px", fontSize: "10px" }}
                                      variant="contained"
                                      onClick={() => {
                                        window.open(
                                          `${process.env.REACT_APP_IMAGE_URL}/wharfage/${el}`,
                                          "_blank"
                                        );
                                      }}
                                    >
                                      {el}
                                    </Index.Button>
                                  ))}
                                </>
                              ) : (
                                "-"
                              )}
                            </Index.TableCell>
                            <Index.TableCell>
                              {wharfageActionType?.length > 0 &&
                              wharfageActionType.find(
                                (res) => res?.id === row?._id
                              ) !== undefined
                                ? Index.moment(
                                    wharfageActionType.find(
                                      (res) => res?.id === row?._id
                                    ).wharfageTime
                                  ).format("DD/MM/YYYY hh:mm:ss")
                                : row?.wharfageTime
                                ? Index.moment(row?.wharfageTime).format(
                                    "DD/MM/YYYY hh:mm:ss"
                                  )
                                : "-"}
                            </Index.TableCell>
                            <Index.TableCell>
                              <Index.Box className="input-design-div with-border">
                                <Index.Select
                                  fullWidth
                                  value={
                                    wharfageActionType?.length > 0 &&
                                    wharfageActionType.find(
                                      (res) => res?.id === row?._id
                                    ) !== undefined
                                      ? wharfageActionType.find(
                                          (res) => res?.id === row?._id
                                        ).wharfageStatus
                                      : row?.wharfageStatus === "Paid"
                                      ? "Paid"
                                      : row?.wharfageStatus === "Not Paid"
                                      ? "Not Paid"
                                      : row?.wharfageStatus === "Decline"
                                      ? "Decline"
                                      : ""
                                  }
                                  onChange={(e) =>
                                    handleChangePaidActionType(e, row)
                                  }
                                >
                                  <Index.MenuItem value="Paid">
                                    Paid
                                  </Index.MenuItem>
                                  <Index.MenuItem value="Not Paid">
                                    Not Paid
                                  </Index.MenuItem>
                                  <Index.MenuItem value="Decline">
                                    Decline
                                  </Index.MenuItem>
                                </Index.Select>
                              </Index.Box>
                            </Index.TableCell>
                          </Index.TableRow>
                        ))
                      ) : (
                        <Index.TableRow>
                          <Index.TableCell
                            component="td"
                            variant="td"
                            scope="row"
                            className="no-data-in-list empty-record-align"
                            colSpan={14}
                            align="center"
                          >
                            No data available
                          </Index.TableCell>
                        </Index.TableRow>
                      )}
                    </Index.TableBody>
                  )}
                </Index.Table>
              </Index.TableContainer>
            </Index.Box>
            <Index.Box className="pagination-design flex-end">
              <Index.Stack spacing={2}>
                <Index.Box className="pagination-count pagination-loader">
                  <Index.Box className="input-design-div with-border">
                    <Index.Select
                      fullWidth
                      value={paginationPerPage}
                      onChange={(e) => handleChangePaginationPerChange(e)}
                    >
                      <Index.MenuItem value={10}>10</Index.MenuItem>
                      <Index.MenuItem value={25}>25</Index.MenuItem>
                      <Index.MenuItem value={50}>50</Index.MenuItem>
                      <Index.MenuItem value={100}>100</Index.MenuItem>
                    </Index.Select>
                  </Index.Box>
                  {loading ? (
                    <Index.CircularProgress color="secondary" size={26} />
                  ) : (
                    <>
                      <Index.Pagination
                        count={Math.ceil(totalRecordsCount / paginationPerPage)}
                        page={currentPage}
                        onChange={handlePageChange}
                      />
                    </>
                  )}
                </Index.Box>
              </Index.Stack>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </div>
    </>
  );
}

export default WharfageList;
