import React, {useState} from "react";
import PropTypes from "prop-types";
import BankList from "./List";
import KotakBankList from "./KotakList";
import Index from "../../../Index";
import AddDashboardData from "./AddDashboardData";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Index.Box className="tab-comman-padding">
          <Index.Typography>{children}</Index.Typography>
        </Index.Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const BankTab = () => {
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Index.Box sx={{ width: "100%" }}>
        <Index.Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Index.Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            className="dashboard-tab"
          >
            <Index.Tab label="HDFC Bank" {...a11yProps(0)} />
            <Index.Tab label="Kotak Bank" {...a11yProps(1)} />
            {/* <Index.Tab label="Dashboard Data" {...a11yProps(2)} /> */}
          </Index.Tabs>
        </Index.Box>
        <TabPanel value={value} index={0}>
          <BankList />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <KotakBankList />
        </TabPanel>
        {/* <TabPanel value={value} index={2}>
          <AddDashboardData />
        </TabPanel> */}
      </Index.Box>
    </>
  );
};

export default BankTab;
