import { DateTimePicker } from "@mui/x-date-pickers";
import { Formik } from "formik";
import dayjs from "dayjs";
import { terminalData } from "../../../../../config/CommonTerminalItems";
import { useEffect, useState } from "react";
import { getWaterSupplyPortList } from "../../../../../redux/admin/action";
import Index from "../../../../Index";

const waterTypeList = [
  { id: 1, type: "Freshwater" },
  { id: 2, type: "Mix" },
  { id: 3, type: "RO" },
];

function WaterSupplyUpdateWaterTypeModel({
  openUpdateDatePopUp,
  handleCloseDateEditModal,
  handleUpdateDateViaApi,
  editTimeWaterTypeData,
  isSingleFieldUpdate,
}) {
  const dispatch = Index.useDispatch();
  const initialValues = {
    waterType: editTimeWaterTypeData,
  };

  return (
    <>
      <Index.Dialog
        open={openUpdateDatePopUp}
        onClose={handleCloseDateEditModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Index.DialogContent className="expense-model-main  modal-update-main">
          <Index.Box className="title-main">
            <Formik
              enableReinitialize
              initialValues={initialValues}
              // validationSchema={vesselValidationSchema}
              onSubmit={(values) => handleUpdateDateViaApi(values)}
            >
              {({
                values,
                errors,
                touched,
                setFieldValue,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldTouched,
                handleFocus,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Index.Box className="title-main">
                    <Index.Box className="jus-between">
                      <Index.Typography
                        variant="h6"
                        component="h6"
                        className="input-label"
                      >
                        Edit Water Type
                      </Index.Typography>
                      <Index.ClearIcon
                        className="model-close-icon"
                        onClick={handleCloseDateEditModal}
                      />
                    </Index.Box>
                    <Index.Box className="update-model-main-box-set">
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Water Type
                      </Index.Typography>
                      <Index.Box className="input-design-div with-border">
                        <Index.Select
                          className="placeholder-set-text-box"
                          fullWidth
                          name="waterType"
                          value={
                            // waterTypeList[
                            //   waterTypeList.findIndex(
                            //     (e) =>
                            //       e.type.toString() ===
                            //       values?.waterType.toString()
                            //   )
                            // ]
                            values?.waterType
                          }
                          displayEmpty
                          inputProps={{ "aria-label": "Without label" }}
                          // defaultValue={values ? values?.waterType : ""}
                          onChange={(e) => {
                            setFieldValue("waterType", e.target.value);
                          }}
                        >
                          <Index.MenuItem value={""}>
                            <Index.MenuItem
                              disabled
                              className="all-select-label select-label-font"
                            >
                              Select water type
                            </Index.MenuItem>
                          </Index.MenuItem>
                          {waterTypeList?.map((items, index) => (
                            <Index.MenuItem value={items?.type} key={items?.id}>
                              {items?.type}
                            </Index.MenuItem>
                          ))}
                        </Index.Select>
                      </Index.Box>

                      <Index.Box className="common-button blue-button flex-end save-btn  modal-update-btn">
                        <Index.Button
                          variant="contained"
                          type="submit"
                          className="Particular_model_submit"
                          disabled={isSingleFieldUpdate ? true : false}
                        >
                          Update
                        </Index.Button>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </form>
              )}
            </Formik>
          </Index.Box>
        </Index.DialogContent>
      </Index.Dialog>
    </>
  );
}
export default WaterSupplyUpdateWaterTypeModel;
