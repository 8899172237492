import React, { useState } from "react";
import Index from "../../Index";
// import { useDispatch } from "react-redux";
// import { styled, alpha } from "@mui/material/styles";
// import { Link } from "react-router-dom";
// import { adminLogout } from "../../../redux/admin/action";
// const preventDefault = (event) => event.preventDefault();

function Header(props) {
  // const dispatch = useDispatch();
  // const [anchorEl, setAnchorEl] = useState(null);
  // const open = Boolean(anchorEl);
  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  //   document.body.classList["add"]("menu-set-main");
  // };
  // const handleClose = () => {
  //   setAnchorEl(null);
  //   document.body.classList["remove"]("menu-set-main");
  // };

  const [openSidebar, setOpenSidebar] = useState(false);
  const handleSidebarToogle = () => {
    setOpenSidebar(!openSidebar);
  };

  return (
    <div>
      <Index.Box className="header-main header-flex-main">
        <Index.Box className="header">
          <Index.Box className="header-flex">
            <Index.Box className="logo">
              <img src={Index.Svg.logo} alt="logo" className="logo-img" />
            </Index.Box>
            <Index.Box className="header-right-content">
              <Index.Box className="d-flex align-items-center">
                <Index.Box
                  className={
                    openSidebar
                      ? "header-ul-main"
                      : "header-ul-main header-ul-main-open"
                  }
                >
                  <Index.Button
                    className="res-close-btn"
                    onClick={() => handleSidebarToogle()}
                  >
                    {" "}
                    <img src={Index.Svg.closeold} className="res-close-img" />
                  </Index.Button>
                </Index.Box>

                {/* <Index.Box className="profile-drop">Dropdown</Index.Box> */}
                {/* <Index.Box className="admin-header-right">
                  <Index.Box className="admin-header-drop-main">
                    <Index.Button
                      className="drop-header-btn"
                      id="basic-button"
                      aria-controls={open ? "basic-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                      onClick={handleClick}
                    >
                      <Index.Box className="flex-drop-main">
                        <img
                          src={Index.Png.profileimg}
                          className="admin-header-profile-icon"
                          alt="dashboard bell icon"
                        ></img>
                        <Index.Box className="title-admin-drop">
                          <Index.Typography
                            variant=""
                            component=""
                            className="admin-header-drop profile-set"
                          >
                            Admin
                          </Index.Typography>
                        </Index.Box>
                        <img
                          src={Index.Png.arrow}
                          className="arrow-profile-icon"
                          alt="dashboard bell icon"
                        ></img>
                      </Index.Box>
                    </Index.Button>
                  </Index.Box>
                  <Index.Menu
                    className="drop-header-menu admin-header-profile-ul"
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    <Link to="/admin/profile">
                      <Index.MenuItem
                        onClick={handleClose}
                        className="drop-header-menuitem"
                      >
                        {" "}
                        <Index.Box className="admin-header-drop-main">
                          <Index.Button
                            className="drop-header-btn drop-header-btn-set"
                            id="basic-button"
                            aria-controls={open ? "basic-menu" : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? "true" : undefined}
                            onClick={handleClick}
                          >
                            <Index.Box className="flex-drop-main">
                              <img
                                src={Index.Png.profileimg}
                                className="admin-header-profile-icon set-admin-header-profile-icon"
                                alt="dashboard bell icon"
                              ></img>
                              <Index.Box className="title-admin-drop">
                                <Index.Box>
                                  <Index.Typography
                                    variant="p"
                                    component="p"
                                    className="admin-header-drop set-profile-set "
                                  >
                                    Admin
                                  </Index.Typography>
                                  <Index.Typography
                                    variant="p"
                                    component="p"
                                    className="admin-header-drop set-profile-inner  "
                                  >
                                    Senior Account Manager
                                  </Index.Typography>
                                </Index.Box>
                              </Index.Box>
                              <img
                                src={Index.Png.arrow}
                                className="arrow-profile-icon"
                                alt="dashboard bell icon"
                              ></img>
                            </Index.Box>
                          </Index.Button>
                        </Index.Box>
                      </Index.MenuItem>
                    </Link>

                    <Index.MenuItem
                      onClick={() => {
                        handleClose();
                        dispatch(adminLogout());
                      }}
                      className="drop-header-menuitem logout-profile pink-btn-menu"
                    >
                      {" "}
                      <img src={Index.Svg.edit} className="drop-header " />{" "}
                      Logout
                    </Index.MenuItem>
                  </Index.Menu>
                </Index.Box> */}
                <Index.Box className="res-menu-icon">
                  <Index.MenuIcon
                    className="res-menu"
                    onClick={() => handleSidebarToogle()}
                  />
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </div>
  );
}

export default Header;
