export const getFractionValue = (value) => {
  let fractionValue = 0;
  value
    ? (fractionValue = value?.toLocaleString("en-IN", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }))
    : (fractionValue = 0?.toLocaleString("en-IN", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }));

  return "₹" + fractionValue;
};
