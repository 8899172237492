import React, { useEffect, useState, useRef } from "react";
import Index from "../../../Index";
import { getBargeExpenseList } from "../../../../redux/admin/action";
import { useReactToPrint } from "react-to-print";
import ExpensePrintPDF from "./ExpensePrintPDF";
import { NEW_EXPORT_EXCEL } from "../ExportAll";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { SingleInputDateRangeField } from "@mui/x-date-pickers-pro/SingleInputDateRangeField";
import dayjs from "dayjs";

const Search = Index.styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: Index.alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: Index.alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const StyledInputBase = Index.styled(Index.InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

const shortcutsItems = [
  {
    label: "This Week",
    getValue: () => {
      const today = dayjs();
      return [today.startOf("week"), today.endOf("week")];
    },
  },
  {
    label: "Last Week",
    getValue: () => {
      const today = dayjs();
      const prevWeek = today.subtract(7, "day");
      return [prevWeek.startOf("week"), prevWeek.endOf("week")];
    },
  },
  {
    label: "Last 7 Days",
    getValue: () => {
      const today = dayjs();
      return [today.subtract(7, "day"), today];
    },
  },
  {
    label: "Current Month",
    getValue: () => {
      const today = dayjs();
      return [today.startOf("month"), today.endOf("month")];
    },
  },
  {
    label: "Next Month",
    getValue: () => {
      const today = dayjs();
      const startOfNextMonth = today.endOf("month").add(1, "day");
      return [startOfNextMonth, startOfNextMonth.endOf("month")];
    },
  },
  { label: "Reset", getValue: () => [null, null] },
];

function ExpenseList() {
  const navigate = Index.useNavigate();
  const dispatch = Index.useDispatch();
  const [bargeExpenseData, setBargeExpenseData] = useState([]);
  const [open, setOpen] = useState(false);
  const [printID, setPrintID] = useState("");

  // State for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [paginationPerPage, setPaginationPerPage] = useState(50);
  const [searchingText, setSearchingText] = useState("");
  const [totalRecordCount, setTotalRecordCount] = React.useState(0);

  // state for loading
  const [loading, setLoading] = useState(false);
  const [viewData, setViewData] = useState([]);
  const [singleExpenseData, setSingleExpenseData] = useState({});
  const [serachedDateRange, setSearchedDateRange] = useState("");

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const expenseListData = (values) => {
    setLoading(true);
    dispatch(getBargeExpenseList(values)).then((res) => {
      if (res?.status == 200) {
        setTotalRecordCount(res?.totalExpenseRecord);
        setCurrentPage(res?.currentPage);
        setLoading(false);
      } else {
        setLoading(false);
      }
      setViewData(res?.data);
      setBargeExpenseData(
        res?.data?.map((item) => {
          let total = 0;
          let ammountTotal = item?.details?.reduce((acc, cur) => {
            let total = 0;
            if (cur?.amount) {
              total = acc + cur?.amount;
              return total;
            } else {
              total = acc + 0;
              return total;
            }
          }, 0);
          let extraTotal = item?.details?.reduce((acc, cur) => {
            let total = 0;
            if (cur?.extra) {
              total = acc + cur?.extra;
              return total;
            } else {
              total = acc + 0;
              return total;
            }
          }, 0);
          total = ammountTotal + extraTotal;
          return {
            _id: item?._id,
            serialNumber: item?.serialNumber,
            nominationNo: item?.nominationNo?.nominationNo,
            companyName: item?.nominationNo?.companyName,
            nominationNId: item?.nominationNo?._id,
            expenseType: item?.expenseType,
            createdBy: item?.createdBy?.firstName,
            createdAt: item?.createdAt,
            totalAmount: total,
            totalExtraAmount: extraTotal,
            vesselName: item?.nominationNo?.vesselName,
            product: item?.nominationNo?.product,
            portName: item?.nominationNo?.port?.portName,
            lowerQuantity:
              item?.nominationNo?.lowerQuantity != 0 ||
              item?.nominationNo?.lowerQuantity != 0
                ? item?.nominationNo?.lowerQuantity
                : "",
            higherQuantity:
              item?.nominationNo?.higherQuantity != 0 ||
              item?.nominationNo?.higherQuantity != 0
                ? item?.nominationNo?.higherQuantity
                : "",
            isEditable: item?.isEditable,
            actionByTimeStamps: item?.actionByTimeStamps,
            actionBy: item?.actionBy,
          };
        })
      );
    });
  };
  useEffect(() => {
    const values = {
      page: 1,
      search: "",
      size: paginationPerPage,
      to:
        serachedDateRange?.length > 0
          ? serachedDateRange[0].format("YYYY-MM-DD")
          : "",
      from:
        serachedDateRange?.length > 0
          ? serachedDateRange[1].format("YYYY-MM-DD")
          : "",
    };
    expenseListData(values);
  }, []);

  const handlePageChange = React.useCallback(
    (event, value) => {
      setCurrentPage(value);
      const values = {
        page: value,
        search: searchingText,
        size: paginationPerPage,
        to:
          serachedDateRange?.length > 0
            ? serachedDateRange[0].format("YYYY-MM-DD")
            : "",
        from:
          serachedDateRange?.length > 0
            ? serachedDateRange[1].format("YYYY-MM-DD")
            : "",
      };
      expenseListData(values);
    },
    [searchingText, paginationPerPage, serachedDateRange]
  );

  // for order view page from expense list
  const orderView = (item) => {
    const id = item?.nominationNId;
    if (item?.expenseType === "Order") {
      navigate(`/admin/order/view-order/${id}`);
    }
  };

  // expense Details
  const expenseDetails = (id) => {
    navigate(`/admin/expenseList/view-barge-expense/${id}`);
  };

  // for upload sheet model open
  const handleClickOpen = (id) => {
    setPrintID(id);
    setOpen(true);
  };

  // for upload sheet model open
  const handleClose = () => {
    setOpen(false);
  };

  // when the user search the expenses then used..
  const handleSearchExpenseRecord = React.useCallback(
    (e) => {
      const { value } = e.target;

      if (value?.length > 0) {
        setSearchingText(value);
        const values = {
          page: 1,
          search: value,
          size: paginationPerPage,
          to:
            serachedDateRange?.length > 0
              ? serachedDateRange[0].format("YYYY-MM-DD")
              : "",
          from:
            serachedDateRange?.length > 0
              ? serachedDateRange[1].format("YYYY-MM-DD")
              : "",
        };
        expenseListData(values);
      } else {
        setSearchingText("");
        const values = {
          page: 1,
          search: "",
          size: paginationPerPage,
          to:
            serachedDateRange?.length > 0
              ? serachedDateRange[0].format("YYYY-MM-DD")
              : "",
          from:
            serachedDateRange?.length > 0
              ? serachedDateRange[1].format("YYYY-MM-DD")
              : "",
        };
        expenseListData(values);
      }
    },
    [serachedDateRange, paginationPerPage]
  );

  // when the user change the per page record change then used..
  const handleChangePaginationPerChange = React.useCallback(
    (e) => {
      const { value } = e.target;
      setPaginationPerPage(value);
      const values = {
        page: 1,
        search: searchingText,
        size: value,
        to:
          serachedDateRange?.length > 0
            ? serachedDateRange[0].format("YYYY-MM-DD")
            : "",
        from:
          serachedDateRange?.length > 0
            ? serachedDateRange[1].format("YYYY-MM-DD")
            : "",
      };
      expenseListData(values);
    },
    [searchingText, serachedDateRange]
  );

  // when the use change the date then used...
  const handleChangeDate = React.useCallback(
    (data) => {
      setSearchedDateRange(data);
      if (data[0] !== null && data[1] !== null) {
        const values = {
          page: 1,
          search: searchingText,
          size: paginationPerPage,
          to: data[0] !== null ? data[0].format("YYYY-MM-DD") : "",
          from: data[1] !== null ? data[1].format("YYYY-MM-DD") : "",
        };
        expenseListData(values);
      } else {
        const values = {
          page: 1,
          search: searchingText,
          size: paginationPerPage,
          to:
            serachedDateRange?.length > 0
              ? serachedDateRange[0].format("YYYY-MM-DD")
              : "",
          from:
            serachedDateRange?.length > 0
              ? serachedDateRange[1].format("YYYY-MM-DD")
              : "",
        };
        expenseListData(values);
      }
    },
    [setSearchedDateRange, searchingText, paginationPerPage]
  );

  return (
    <>
      <Index.DynamicTitle title="Bunker Expense" />
      <Index.Box className="">
        <Index.Box className="barge-common-box">
          <Index.Box className="title-header">
            <Index.Box className="title-header-flex res-title-header-flex">
              <Index.Box className="title-main">
                <Index.Typography
                  variant="p"
                  component="p"
                  className="page-title"
                >
                  Bunker Expense
                </Index.Typography>
              </Index.Box>
              <Index.Box className="d-flex align-items-center res-set-search">
                {/* Date range start */}
                <Index.Box className="input-design-div">
                  <Index.Box className="set-date set-date-picker remove-name">
                    <Index.LocalizationProvider
                      dateAdapter={Index.AdapterDayjs}
                    >
                      <Index.DemoContainer
                        components={["SingleInputDateRangeField"]}
                      >
                        <DateRangePicker
                          slots={{ field: SingleInputDateRangeField }}
                          sx={false}
                          slotProps={{
                            shortcuts: {
                              items: shortcutsItems,
                            },
                            textField: {
                              readOnly: true,
                            },
                            actionBar: { actions: [] },
                          }}
                          onChange={(value) => handleChangeDate(value)}
                          name="allowedRange"
                          format="DD/MM/YYYY"
                        />
                      </Index.DemoContainer>
                    </Index.LocalizationProvider>
                  </Index.Box>
                </Index.Box>
                {/* Date range end */}
                <Search className="search ">
                  <StyledInputBase
                    placeholder="Search"
                    inputProps={{ "aria-label": "search" }}
                    onChange={(e) => handleSearchExpenseRecord(e)}
                  />
                </Search>
                {bargeExpenseData?.length ? (
                  <Index.Box className="common-button grey-button">
                    <Index.Button
                      variant="contained"
                      onClick={() => {
                        let curDate = Index.moment(new Date()).format(
                          "YYYY-MM-DD"
                        );
                        NEW_EXPORT_EXCEL(
                          "/admin/export-barge-expense",
                          "Expense_List",
                          {
                            search: searchingText,
                            to:
                              serachedDateRange?.length > 0
                                ? serachedDateRange[0].format("YYYY-MM-DD")
                                : "",
                            from:
                              serachedDateRange?.length > 0
                                ? serachedDateRange[1].format("YYYY-MM-DD")
                                : "",
                          }
                        );
                      }}
                    >
                      Export
                    </Index.Button>
                  </Index.Box>
                ) : (
                  ""
                )}
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Index.Box className="">
            <Index.TableContainer
              component={Index.Paper}
              className="table-container"
            >
              <Index.Table
                aria-label="simple table"
                className="table-design-main supply-table one-line-table expense-table"
              >
                <Index.TableHead>
                  <Index.TableRow>
                    <Index.TableCell>Bill No</Index.TableCell>
                    <Index.TableCell align="left">Nomi. No</Index.TableCell>
                    <Index.TableCell align="left">Company Name</Index.TableCell>
                    <Index.TableCell align="left">Created By</Index.TableCell>
                    <Index.TableCell align="left">Total Amount</Index.TableCell>
                    <Index.TableCell align="left">Extra</Index.TableCell>
                    <Index.TableCell align="left">Vessel Name</Index.TableCell>
                    <Index.TableCell align="left">Approved By</Index.TableCell>
                    <Index.TableCell align="left">
                      Approved By Time
                    </Index.TableCell>
                    <Index.TableCell align="left">Products</Index.TableCell>
                    <Index.TableCell align="left">Port</Index.TableCell>
                    <Index.TableCell align="left">Quantity</Index.TableCell>
                    <Index.TableCell align="left">Created Date</Index.TableCell>
                    <Index.TableCell align="left">Print</Index.TableCell>
                    <Index.TableCell align="left">Action</Index.TableCell>
                  </Index.TableRow>
                </Index.TableHead>
                {loading ? (
                  <Index.TableBody>
                    <Index.TableRow>
                      <Index.TableCell colSpan={13} align="center">
                        <Index.CircularProgress color="secondary" size={20} />
                      </Index.TableCell>
                    </Index.TableRow>
                  </Index.TableBody>
                ) : (
                  <Index.TableBody>
                    {bargeExpenseData?.length ? (
                      bargeExpenseData?.map((item, index) => (
                        <Index.TableRow key={item?._id}>
                          <Index.TableCell align="left">
                            {item?.serialNumber}
                          </Index.TableCell>

                          <Index.TableCell
                            align="left"
                            onClick={() => {
                              item?.expenseType === "Order" &&
                                item?.nominationNo &&
                                item?.nominationNo !== undefined &&
                                orderView(item);
                            }}
                            sx={{ cursor: "pointer" }}
                          >
                            {item?.nominationNo ? item?.nominationNo : "-"}
                          </Index.TableCell>

                          <Index.TableCell
                            align="left"
                            onClick={() => {
                              expenseDetails(item?._id);
                            }}
                            sx={{ cursor: "pointer" }}
                          >
                            {item?.companyName ? item?.companyName : "-"}
                          </Index.TableCell>

                          <Index.TableCell
                            align="left"
                            onClick={() => {
                              expenseDetails(item?._id);
                            }}
                            sx={{ cursor: "pointer" }}
                          >
                            {item?.createdBy ? item?.createdBy : "-"}
                          </Index.TableCell>

                          <Index.TableCell
                            align="left"
                            onClick={() => {
                              expenseDetails(item?._id);
                            }}
                            sx={{ cursor: "pointer" }}
                          >
                            {item?.totalAmount ? `₹ ${item?.totalAmount}` : "-"}
                          </Index.TableCell>
                          <Index.TableCell
                            align="left"
                            onClick={() => {
                              expenseDetails(item?._id);
                            }}
                            sx={{ cursor: "pointer" }}
                          >
                            {item?.totalExtraAmount
                              ? `₹ ${item?.totalExtraAmount}`
                              : "-"}
                          </Index.TableCell>

                          <Index.TableCell
                            align="left"
                            onClick={() => {
                              expenseDetails(item?._id);
                            }}
                            sx={{ cursor: "pointer" }}
                          >
                            {item?.vesselName ? item?.vesselName : "-"}
                          </Index.TableCell>
                          <Index.TableCell
                            align="left"
                            onClick={() => {
                              expenseDetails(item?._id);
                            }}
                            sx={{ cursor: "pointer" }}
                          >
                            {item?.actionBy?.hasOwnProperty("firstName") &&
                            item?.actionBy?.hasOwnProperty("lastName")
                              ? item?.actionBy?.firstName +
                                " " +
                                item?.actionBy?.lastName
                              : "-"}
                          </Index.TableCell>
                          <Index.TableCell
                            align="left"
                            onClick={() => {
                              expenseDetails(item?._id);
                            }}
                            sx={{ cursor: "pointer" }}
                          >
                            {item?.actionByTimeStamps
                              ? Index.moment(item?.actionByTimeStamps).format(
                                  "DD/MM/YYYY hh:mm:ss A"
                                )
                              : "-"}
                          </Index.TableCell>

                          <Index.TableCell
                            align="left"
                            onClick={() => {
                              expenseDetails(item?._id);
                            }}
                            sx={{ cursor: "pointer" }}
                          >
                            {item?.product ? item?.product : "-"}
                          </Index.TableCell>

                          <Index.TableCell
                            align="left"
                            onClick={() => {
                              expenseDetails(item?._id);
                            }}
                            sx={{ cursor: "pointer" }}
                          >
                            {item?.portName ? item?.portName : "-"}
                          </Index.TableCell>

                          <Index.TableCell
                            align="left"
                            onClick={() => {
                              expenseDetails(item?._id);
                            }}
                            sx={{ cursor: "pointer" }}
                          >
                            {(item?.lowerQuantity === "0" &&
                              !item?.higherQuantity) ||
                            (item?.lowerQuantity === "" &&
                              !item?.higherQuantity)
                              ? "-"
                              : item?.lowerQuantity && item?.higherQuantity
                              ? `${item.lowerQuantity} - ${item.higherQuantity}`
                              : item?.lowerQuantity
                              ? item.lowerQuantity.toString()
                              : "-"}
                          </Index.TableCell>

                          <Index.TableCell
                            align="left"
                            onClick={() => {
                              expenseDetails(item?._id);
                            }}
                            sx={{ cursor: "pointer" }}
                          >
                            {item?.createdAt
                              ? Index.moment(item?.createdAt).format(
                                  "DD/MM/YYYY"
                                )
                              : "-"}
                          </Index.TableCell>

                          <Index.TableCell className="print-expense-btn-main">
                            <div>
                              {/* <Index.Tooltip title="Print"> */}
                              <Index.IconButton
                                className="print-expense"
                                onClick={async () => {
                                  await setSingleExpenseData({});
                                  await setSingleExpenseData(viewData[index]);
                                  handlePrint();
                                }}
                              >
                                <Index.PrintIcon />
                              </Index.IconButton>
                              {/* </Index.Tooltip> */}
                            </div>
                          </Index.TableCell>

                          <Index.TableCell>
                            {/* <Index.Tooltip title="View"> */}
                            <Index.IconButton>
                              <Index.VisibilitySharpIcon
                                onClick={() => {
                                  expenseDetails(item?._id);
                                }}
                              />
                            </Index.IconButton>
                            {/* </Index.Tooltip> */}
                          </Index.TableCell>
                        </Index.TableRow>
                      ))
                    ) : (
                      <Index.TableRow>
                        <Index.TableCell
                          component="td"
                          variant="td"
                          scope="row"
                          className="no-data-in-list empty-record-align"
                          colSpan={18}
                          align="center"
                        >
                          No data available
                        </Index.TableCell>
                      </Index.TableRow>
                    )}
                  </Index.TableBody>
                )}
              </Index.Table>
            </Index.TableContainer>
          </Index.Box>
          <Index.Box className="pagination-design flex-end">
            <Index.Stack spacing={2}>
              <Index.Box className="pagination-count pagination-loader">
                <Index.Box className="input-design-div with-border">
                  <Index.Select
                    fullWidth
                    value={paginationPerPage}
                    onChange={
                      (e) => handleChangePaginationPerChange(e)
                      //   {
                      //   setPaginationPerPage(e.target.value);
                      // }
                    }
                  >
                    <Index.MenuItem value={10}>10</Index.MenuItem>
                    <Index.MenuItem value={25}>25</Index.MenuItem>
                    <Index.MenuItem value={50}>50</Index.MenuItem>
                    <Index.MenuItem value={100}>100</Index.MenuItem>
                  </Index.Select>
                </Index.Box>
                {loading ? (
                  <Index.CircularProgress color="secondary" size={26} />
                ) : (
                  <>
                    <Index.Pagination
                      count={Math.ceil(totalRecordCount / paginationPerPage)}
                      page={currentPage}
                      onChange={handlePageChange}
                    />
                  </>
                )}
              </Index.Box>
            </Index.Stack>
          </Index.Box>
        </Index.Box>
      </Index.Box>
      <Index.Box className="pdfDownload" sx={{ display: "none" }}>
        <ExpensePrintPDF
          expenseData={singleExpenseData}
          componentRef={componentRef}
        />
      </Index.Box>
    </>
  );
}

export default ExpenseList;
