import React, { useState } from 'react'

import { Outlet } from 'react-router-dom';
import Index from '../../../Index';
import Header from '../../../../component/admin/defaulLayout/Header';


export default function Dashboard() {

  const [open, setOpen] = useState(true);

  return (
    <div>
      <Index.Box className="dashboard-main">
        {/* <Index.Box className={`dashboard-left-main ${open ? "active" : "admin-sidebar-deactive"}`}>
          <Index.Sidebar open={open} setOpen={setOpen}/>
        </Index.Box> */}
        <Index.Box className="dashboard-right-main">
          {/* <Header setOpen={setOpen} open={open} /> */}
          {/* <Index.Box className="dashboard-containt-main"> */}
            <Outlet />
          {/* </Index.Box> */}
        </Index.Box>
      </Index.Box>
    </div >
  )
}
