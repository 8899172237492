export const commonCFData = [
    {
      id: 1,
      value: "Coastal",
      name: "Coastal",
    },
    {
      id: 2,
      value: "Foreign",
      name: "Foreign",
    },
  ];