import React, { useState, useEffect } from "react";
import Index from "../../../Index";
import { FieldArray, Formik } from "formik";
import {
  addBarge,
  getAllCompanyList,
  getPortList,
} from "../../../../redux/admin/action";
import { bargeValidationSchema } from "../../../../validation/AdminValidation";
import { craftCategoryData } from "../../../../config/CommonCraftCategoryItems";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function AddBargeComponent() {
  const dispatch = Index.useDispatch();
  const navigate = Index.useNavigate();
  const [craftCategory, setCraftCategory] = useState([]);
  const [allPortList, setAllPortList] = useState([]);
  const portList = Index.useSelector((state) => state.AdminReducer.portList);
  let { state } = Index.useLocation();
  let editData = state?.row;
  const [companyList, setCompanyList] = useState([]);

  if (editData) {
    editData.id = editData?._id;
    let portIds = editData ? editData?.portLimit?.map((item) => item?._id) : [];
    if (portIds[0]) {
      editData.portLimit = portIds;
    }
  }

  // when the get all the company list then used...
  const handleGetAllCompanyList = () => {
    dispatch(getAllCompanyList())
      .then((res) => {
        if (res.status === 200) {
          setCompanyList(res.data);
        } else {
          setCompanyList([]);
        }
      })
      .catch((error) => {
        setCompanyList([]);
        console.error(error);
      });
  };

  useEffect(() => {
    dispatch(getPortList());
    handleGetAllCompanyList();
  }, [dispatch]);

  useEffect(() => {
    setCraftCategory(
      craftCategoryData?.sort((a, b) => a?.item?.localeCompare(b?.item))
    );
    setAllPortList(
      portList?.sort((a, b) => a?.portName?.localeCompare(b?.portName))
    );
  }, [craftCategoryData, portList]);

  // condition for manage Add/Edit together
  let initialValues = {};
  if (editData) {
    initialValues = editData;
    let tankeManagementArray = [];
    if (editData?.tankManagement?.length) {
      editData?.tankManagement?.map((el) => {
        tankeManagementArray?.push({
          tankName: el?.tankName ? el?.tankName : "",
          tankType: el?.tankType ? el?.tankType : "",
          tankCapacity: el?.tankCapacity ? el?.tankCapacity : "",
          tankCapacityMl: el?.tankCapacityMl ? el?.tankCapacityMl : "",
        });
      });
    }
    initialValues = {
      ...initialValues,
      tankManagement: tankeManagementArray,
      bargeCategory: editData?.bargeCategory
        ? craftCategory?.find((res) => res.item === editData?.bargeCategory) !==
          undefined
          ? craftCategory?.find((res) => res.item === editData?.bargeCategory)
              .item
          : ""
        : "",
      companyId: editData?.companyId?.length
        ? editData?.companyId?.map((el) => el?._id)
        : [],
    };
  } else {
    initialValues = {
      bargeName: "",
      bargeCategory: "",
      portLimit: [],
      tellyBargeName: "",
      companyId: [],
      tankManagement: [
        { tankName: "", tankType: "", tankCapacity: "", tankCapacityMl: "" },
      ],
    };
  }

  // Add Barge
  const handleFormSubmit = async (values) => {
    dispatch(addBarge(values)).then((res) => {
      if (res?.status === 200) {
        navigate("/admin/craft");
      }
    });
  };

  const backBtn = () => {
    navigate("/admin/craft");
  };

  return (
    <>
      <Index.Box className="page-content-box">
        <Index.Box className="barge-common-box">
          <Index.Box className="title-header">
            <Index.Box className="title-header-flex">
              <Index.Box className="title-main mb-10">
                <Index.Typography
                  variant="p"
                  component="p"
                  className="page-title"
                >
                  {editData ? "Edit" : "Add"} Craft
                </Index.Typography>
              </Index.Box>
              <Index.Box className="common-button blue-button save-btn ">
                <Index.Button
                  variant="contained"
                  type="submit"
                  className="Particular_model_submit ml-0"
                  onClick={backBtn}
                >
                  Back
                </Index.Button>
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Formik
            enableReinitialize
            onSubmit={handleFormSubmit}
            initialValues={initialValues}
            validationSchema={bargeValidationSchema}
          >
            {({
              values,
              errors,
              touched,
              setFieldValue,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <form onSubmit={handleSubmit}>
                <Index.Box className="">
                  <Index.Grid container spacing={2}>
                    <Index.Grid item xs={12} sm={6} md={3}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Craft Name
                      </Index.Typography>
                      <Index.Box className="input-design-div with-border">
                        <Index.TextField
                          hiddenLabel
                          id="filled-hidden-label-normal"
                          placeholder="Enter craft name"
                          variant="filled"
                          className="admin-input-design input-placeholder"
                          name="bargeName"
                          autoComplete="off"
                          onBlur={handleBlur}
                          value={values?.bargeName}
                          onChange={handleChange}
                          helperText={touched?.bargeName && errors?.bargeName}
                          error={Boolean(
                            errors?.bargeName && touched?.bargeName
                          )}
                        />
                      </Index.Box>
                    </Index.Grid>

                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Craft Category
                      </Index.Typography>
                      <Index.Box className="input-design-div with-border">
                        <Index.Select
                          className="placeholder-set-text-box"
                          fullWidth
                          name="bargeCategory"
                          value={
                            craftCategory?.find(
                              (res) => res.item === values?.bargeCategory
                            ) !== undefined
                              ? craftCategory?.find(
                                  (res) => res.item === values?.bargeCategory
                                ).item
                              : ""
                          }
                          displayEmpty
                          inputProps={{ "aria-label": "Without label" }}
                          // defaultValue={values ? values?.bargeCategory : ""}
                          onChange={(e) => {
                            setFieldValue("bargeCategory", e.target.value);
                          }}
                        >
                          <Index.MenuItem value={""}>
                            <Index.MenuItem
                              disabled
                              className="all-select-label"
                            >
                              Select craft category
                            </Index.MenuItem>
                          </Index.MenuItem>
                          {craftCategory?.map((items, index) => (
                            <Index.MenuItem
                              value={items?.item}
                              key={items?._id}
                            >
                              {items?.item}
                            </Index.MenuItem>
                          ))}
                        </Index.Select>
                        {touched?.bargeCategory && errors?.bargeCategory && (
                          <Index.FormHelperText error>
                            {errors?.bargeCategory}
                          </Index.FormHelperText>
                        )}
                      </Index.Box>
                    </Index.Grid>
                    <Index.Grid item xs={12} sm={6} md={3}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Port Limit
                      </Index.Typography>
                      <Index.Box className="input-design-div with-border">
                        <Index.Select
                          className="placeholder-set-text-box"
                          fullWidth
                          name="portLimit"
                          multiple
                          displayEmpty
                          inputProps={{ "aria-label": "without label" }}
                          defaultValue={values ? values?.portLimit : ""}
                          value={values?.portLimit}
                          MenuProps={MenuProps}
                          onChange={(event) => {
                            const {
                              target: { value },
                            } = event;
                            const newValue =
                              value[value?.length - 1] === ""
                                ? []
                                : typeof value === "string"
                                ? value.split(",")
                                : value;
                            setFieldValue("portLimit", newValue);
                          }}
                          renderValue={(selected, value) => {
                            if (selected.length === 0) {
                              return (
                                <Index.MenuItem
                                  disabled
                                  className="all-select-label"
                                >
                                  Select port
                                </Index.MenuItem>
                              );
                            }

                            const finalResult = [];
                            selected?.map((el) => {
                              let result = allPortList.find(
                                (res) => res?._id === el
                              );
                              if (result !== undefined) {
                                finalResult.push(result?.portName);
                              }
                            });

                            return finalResult.join(", ");
                          }}
                        >
                          <Index.MenuItem value={""}>
                            <Index.MenuItem
                              disabled
                              className="all-select-label"
                            >
                              Select port
                            </Index.MenuItem>
                          </Index.MenuItem>

                          {allPortList?.map((item) => (
                            <Index.MenuItem key={item?._id} value={item?._id}>
                              {item?.portName}
                            </Index.MenuItem>
                          ))}
                        </Index.Select>
                        {touched?.portLimit && errors?.portLimit && (
                          <Index.FormHelperText error>
                            {errors?.portLimit}
                          </Index.FormHelperText>
                        )}
                      </Index.Box>
                    </Index.Grid>
                    <Index.Grid item xs={12} sm={6} md={3}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Tally Craft Name
                      </Index.Typography>
                      <Index.Box className="input-design-div with-border">
                        <Index.TextField
                          hiddenLabel
                          id="filled-hidden-label-normal"
                          placeholder="Enter tally craft name"
                          variant="filled"
                          className="admin-input-design input-placeholder"
                          name="tellyBargeName"
                          autoComplete="off"
                          onBlur={handleBlur}
                          value={values?.tellyBargeName}
                          onChange={handleChange}
                          helperText={
                            touched?.tellyBargeName && errors?.tellyBargeName
                          }
                          error={Boolean(
                            errors?.tellyBargeName && touched?.tellyBargeName
                          )}
                        />
                      </Index.Box>
                    </Index.Grid>
                    <Index.Grid item xs={12} sm={6} md={3}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Company
                      </Index.Typography>
                      <Index.Box className="input-design-div with-border">
                        <Index.Select
                          className="placeholder-set-text-box"
                          fullWidth
                          name="companyId"
                          multiple
                          displayEmpty
                          inputProps={{ "aria-label": "without label" }}
                          // defaultValue={values ? values?.portLimit : ""}
                          value={values?.companyId}
                          MenuProps={MenuProps}
                          onChange={(event) => {
                            const {
                              target: { value },
                            } = event;
                            const newValue =
                              value[value?.length - 1] === ""
                                ? []
                                : typeof value === "string"
                                ? value.split(",")
                                : value;
                            setFieldValue("companyId", newValue);
                          }}
                          renderValue={(selected, value) => {
                            if (selected.length === 0) {
                              return (
                                <Index.MenuItem
                                  disabled
                                  className="all-select-label"
                                >
                                  Select Company
                                </Index.MenuItem>
                              );
                            }

                            const finalResult = [];
                            selected?.map((el) => {
                              let result = companyList.find(
                                (res) => res?._id === el
                              );
                              if (result !== undefined) {
                                finalResult.push(result?.name);
                              }
                            });

                            return finalResult.join(", ");
                          }}
                        >
                          <Index.MenuItem value={""}>
                            <Index.MenuItem
                              disabled
                              className="all-select-label"
                            >
                              Select Company
                            </Index.MenuItem>
                          </Index.MenuItem>

                          {companyList?.map((item) => (
                            <Index.MenuItem key={item?._id} value={item?._id}>
                              {item?.name}
                            </Index.MenuItem>
                          ))}
                        </Index.Select>
                        {touched?.companyId && errors?.companyId && (
                          <Index.FormHelperText error>
                            {errors?.companyId}
                          </Index.FormHelperText>
                        )}
                      </Index.Box>
                    </Index.Grid>
                  </Index.Grid>
                  {values?.tankManagement?.length > 0 && (
                    <FieldArray name="tankManagement">
                      {({ push, remove }) => (
                        <>
                          {values.tankManagement.map((row, index) => (
                            <Index.Grid
                              container
                              spacing={2}
                              mt={2}
                              key={index}
                            >
                              <Index.Grid item xs={12} sm={6} md={2}>
                                <Index.Typography
                                  variant="label"
                                  component="label"
                                  className="input-label"
                                >
                                  Tank Name
                                </Index.Typography>
                                <Index.Box className="input-design-div with-border">
                                  <Index.TextField
                                    hiddenLabel
                                    id="filled-hidden-label-normal"
                                    placeholder="Enter tank name"
                                    variant="filled"
                                    className="admin-input-design input-placeholder"
                                    name={`tankManagement.${index}.tankName`}
                                    key={index}
                                    value={
                                      values.tankManagement[index].tankName
                                    }
                                    autoComplete="off"
                                    onBlur={handleBlur}
                                    // defaultValue=""
                                    onChange={(e) => {
                                      if (e.target.value?.length <= 30) {
                                        setFieldValue(
                                          `tankManagement.${index}.tankName`,
                                          e.target.value
                                        );
                                      }
                                    }}
                                    helperText={
                                      touched &&
                                      touched.tankManagement &&
                                      touched.tankManagement[index] &&
                                      touched.tankManagement[index].tankName &&
                                      errors &&
                                      errors.tankManagement &&
                                      errors.tankManagement[index] &&
                                      errors.tankManagement[index].tankName
                                    }
                                    error={Boolean(
                                      errors &&
                                        errors.tankManagement &&
                                        errors.tankManagement[index] &&
                                        errors.tankManagement[index].tankName &&
                                        touched &&
                                        touched.tankManagement &&
                                        touched.tankManagement[index] &&
                                        touched.tankManagement[index].tankName
                                    )}
                                  />
                                </Index.Box>
                              </Index.Grid>

                              <Index.Grid item xs={12} sm={6} md={2}>
                                <Index.Typography
                                  variant="label"
                                  component="label"
                                  className="input-label"
                                >
                                  Tank Type
                                </Index.Typography>
                                <Index.Box className="input-design-div with-border">
                                  <Index.TextField
                                    hiddenLabel
                                    id="filled-hidden-label-normal"
                                    placeholder="Enter tank type"
                                    variant="filled"
                                    className="admin-input-design input-placeholder"
                                    name={`tankManagement.${index}.tankType`}
                                    key={index}
                                    value={
                                      values.tankManagement[index].tankType
                                    }
                                    autoComplete="off"
                                    onBlur={handleBlur}
                                    // defaultValue=""
                                    onChange={handleChange}
                                    helperText={
                                      touched &&
                                      touched.tankManagement &&
                                      touched.tankManagement[index] &&
                                      touched.tankManagement[index].tankType &&
                                      errors &&
                                      errors.tankManagement &&
                                      errors.tankManagement[index] &&
                                      errors.tankManagement[index].tankType
                                    }
                                    error={Boolean(
                                      errors &&
                                        errors.tankManagement &&
                                        errors.tankManagement[index] &&
                                        errors.tankManagement[index].tankType &&
                                        touched &&
                                        touched.tankManagement &&
                                        touched.tankManagement[index] &&
                                        touched.tankManagement[index].tankType
                                    )}
                                  />
                                </Index.Box>
                              </Index.Grid>

                              <Index.Grid item xs={12} sm={6} md={3}>
                                <Index.Typography
                                  variant="label"
                                  component="label"
                                  className="input-label"
                                >
                                  Tank Capacity (In KL)
                                </Index.Typography>
                                <Index.Box className="input-design-div with-border">
                                  <Index.TextField
                                    hiddenLabel
                                    type="number"
                                    id="filled-hidden-label-normal"
                                    placeholder="Tank capacity (In KL)"
                                    key={index}
                                    variant="filled"
                                    // InputProps={{
                                    //   inputProps: { min: 0, max: 10 },
                                    // }}
                                    className="admin-input-design input-placeholder"
                                    name={`tankManagement.${index}.tankCapacity`}
                                    value={
                                      values.tankManagement[index].tankCapacity
                                    }
                                    autoComplete="off"
                                    onBlur={handleBlur}
                                    // defaultValue=""
                                    onChange={handleChange}
                                    helperText={
                                      touched &&
                                      touched.tankManagement &&
                                      touched.tankManagement[index] &&
                                      touched.tankManagement[index]
                                        .tankCapacity &&
                                      errors &&
                                      errors.tankManagement &&
                                      errors.tankManagement[index] &&
                                      errors.tankManagement[index].tankCapacity
                                    }
                                    error={Boolean(
                                      errors &&
                                        errors.tankManagement &&
                                        errors.tankManagement[index] &&
                                        errors.tankManagement[index]
                                          .tankCapacity &&
                                        touched &&
                                        touched.tankManagement &&
                                        touched.tankManagement[index] &&
                                        touched.tankManagement[index]
                                          .tankCapacity
                                    )}
                                  />
                                </Index.Box>
                              </Index.Grid>

                              <Index.Grid item xs={12} sm={6} md={3}>
                                <Index.Typography
                                  variant="label"
                                  component="label"
                                  className="input-label"
                                >
                                  Tank Capacity (In MT)
                                </Index.Typography>
                                <Index.Box className="input-design-div with-border">
                                  <Index.TextField
                                    hiddenLabel
                                    type="number"
                                    id="filled-hidden-label-normal"
                                    placeholder="Tank capacity (In MT)"
                                    variant="filled"
                                    key={index}
                                    // InputProps={{
                                    //   inputProps: { min: 0, max: 10 },
                                    // }}
                                    className="admin-input-design input-placeholder"
                                    name={`tankManagement.${index}.tankCapacityMl`}
                                    value={
                                      values.tankManagement[index]
                                        .tankCapacityMl
                                    }
                                    autoComplete="off"
                                    onBlur={handleBlur}
                                    // defaultValue=""
                                    onChange={handleChange}
                                    helperText={
                                      touched &&
                                      touched.tankManagement &&
                                      touched.tankManagement[index] &&
                                      touched.tankManagement[index]
                                        .tankCapacityMl &&
                                      errors &&
                                      errors.tankManagement &&
                                      errors.tankManagement[index] &&
                                      errors.tankManagement[index]
                                        .tankCapacityMl
                                    }
                                    error={Boolean(
                                      errors &&
                                        errors.tankManagement &&
                                        errors.tankManagement[index] &&
                                        errors.tankManagement[index]
                                          .tankCapacityMl &&
                                        touched &&
                                        touched.tankManagement &&
                                        touched.tankManagement[index] &&
                                        touched.tankManagement[index]
                                          .tankCapacityMl
                                    )}
                                  />
                                </Index.Box>
                              </Index.Grid>

                              <Index.Grid item xs={12} sm={6} md={2}>
                                <Index.Typography
                                  variant="label"
                                  component="label"
                                  className="input-label"
                                ></Index.Typography>

                                <Index.Box className="incre-btn-main">
                                  {index ===
                                  values?.tankManagement?.length - 1 ? (
                                    <>
                                      <Index.Button
                                        sx={{ fontSize: "20px" }}
                                        className="button-add inc-dic-btn"
                                        type="button"
                                        onClick={() =>
                                          push({
                                            tankName: "",
                                            tankType: "",
                                            tankCapacity: "",
                                            tankCapacityMl: "",
                                          })
                                        }
                                      >
                                        +
                                      </Index.Button>
                                    </>
                                  ) : (
                                    <></>
                                  )}

                                  {values?.tankManagement?.length > 1 && (
                                    <Index.Button
                                      sx={{ fontSize: "20px" }}
                                      type="button"
                                      className="button-remove inc-dic-btn"
                                      disabled={
                                        values?.tankManagement?.length > 1
                                          ? false
                                          : true
                                      }
                                      onClick={() => remove(index)}
                                    >
                                      -
                                    </Index.Button>
                                  )}
                                </Index.Box>
                              </Index.Grid>
                            </Index.Grid>
                          ))}
                        </>
                      )}
                    </FieldArray>
                  )}
                  <Index.Box className="line" mt={3} mb={3}></Index.Box>
                  <Index.Box className="common-button blue-button flex-end save-btn">
                    <Index.Button variant="contained" type="submit">
                      {editData ? "Update" : "Submit"}
                    </Index.Button>
                  </Index.Box>
                </Index.Box>
              </form>
            )}
          </Formik>
        </Index.Box>
      </Index.Box>
    </>
  );
}

export default AddBargeComponent;
