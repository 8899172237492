import { useState } from "react";
import { addPreventiveSubHeadValidationSchema } from "../../../../../validation/AdminValidation";
import Index from "../../../../Index";
import { Formik } from "formik";
import { addPreventiveSubHead } from "../../../../../redux/admin/action";

function AddPreventiveSubHead() {
  const navigate = Index.useNavigate();
  const dispatch = Index.useDispatch();
  const [loading, setLoading] = useState(false);

  const BackBtn = () => {
    navigate("/admin/preventiveSubhead");
  };

  const initialValues = {
    subHead: "",
  };

  //  when the user submit the sub head then used...
  const handleFormSubmit = (values) => {
    const data = new URLSearchParams();

    if (values?.subHead) {
      data.append("name", values.subHead);
    }

    // api calling for sub head
    dispatch(addPreventiveSubHead(data))
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          navigate("/admin/preventiveSubhead");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <>
      <Index.Box className="">
        <Index.Box className="barge-common-box">
          <Index.Box className="title-header">
            <Index.Box className="title-header-flex">
              <Index.Box className="title-main mb-10">
                <Index.Typography
                  variant="p"
                  component="p"
                  className="page-title"
                >
                  Add User
                </Index.Typography>
              </Index.Box>
              <Index.Box className="common-button blue-button save-btn ">
                <Index.Button
                  variant="contained"
                  type="submit"
                  className="Particular_model_submit ml-0"
                  onClick={BackBtn}
                >
                  Back
                </Index.Button>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={addPreventiveSubHeadValidationSchema}
            onSubmit={handleFormSubmit}
          >
            {({
              values,
              errors,
              touched,
              setFieldValue,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <form onSubmit={handleSubmit}>
                <Index.Box className="">
                  <Index.Grid container spacing={2} className="grid-flex-start">
                    <Index.Grid item xs={12} sm={6} md={3}>
                      {/* <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Sub Head
                      </Index.Typography> */}
                      <Index.Box className="input-design-div with-border">
                        <Index.TextField
                          hiddenLabel
                          id="filled-hidden-label-normal"
                          placeholder="Sub Head"
                          variant="filled"
                          className="admin-input-design input-placeholder"
                          name="subHead"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values?.subHead}
                          error={Boolean(
                            errors.subHead ? touched.subHead : undefined
                          )}
                          helperText={
                            touched.subHead ? errors.subHead : undefined
                          }
                        />
                      </Index.Box>
                    </Index.Grid>
                    <Index.Grid item xs={12} sm={6} md={3} className="submit-btn-sec">
                      <Index.Box className="common-button blue-button save-btn">
                        <Index.Button
                          variant="contained"
                          type="submit"
                          disabled={loading}
                        >
                          Submit
                        </Index.Button>
                      </Index.Box>
                    </Index.Grid>
                  </Index.Grid>
                </Index.Box>
              </form>
            )}
          </Formik>
        </Index.Box>
      </Index.Box>
    </>
  );
}

export default AddPreventiveSubHead;
