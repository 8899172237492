import React, { useEffect, useState } from "react";
import Index from "../../../component/Index";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import { getLocation, login } from "../../../redux/admin/action";
import { useDispatch, useSelector } from "react-redux";
import { messaging } from "../../../firebase";
import { getToken, isSupported } from "firebase/messaging";

function Authenticate() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // state for login button disabled
  const [loading, setLoading] = useState(false);
  const [deviceToken, setDeviceToken] = useState("");

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  // Initital values
  let initialValues = {
    email: "",
    password: "",
  };

  // Validation schema
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Enter the valid email")
      .matches(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        "Enter the valid email"
      )
      .required("Email is required"),
    password: Yup.string()
      .required("Password is required")
      .min(8, "Password must be at least 8 characters")
      .max(16, "Password must be at most 16 characters"),
  });

  // firebase code old
  async function requestPermission() {
    const permission = await Notification.requestPermission();
    //console.log(permission,51);
    if (permission == "granted") {
      var token = await getToken(messaging, {
        vapidKey:
          "BBNCatD6oXli6KgK8rYHld4S2Q90gawF71k6bSiwgOfuN_FSaSiYDvj6RbAbqnEsISBOgjYJcqTvBsiGh8XBaKc",
      });
      setDeviceToken(token);
      // console.log("token1", token);
      //console.log("test1");

      // let tdata = token;
    } else if (permission === "denied") {
      alert("You denied for the notification");
    }
  }
  useEffect(() => {
    requestPermission();
  }, []);

  // handleFocus for login button disabled
  const handleFocus = () => {
    setLoading(false);
  };

  //  get location start
  function success(position) {
    const latitude = position.coords.latitude;
    const longitude = position.coords.longitude;
    // console.log(latitude,longitude,99);
    const location = {
      lat: latitude,
      long: longitude,
    };
    dispatch(getLocation(location));
    // console.log(`Latitude: ${latitude}, Longitude: ${longitude}`);
  }
  function error() {
    // console.log("Unable to retrieve your location");
  }
  // location code end


  // Login
  const handleFormSubmit = async (values) => {
    setLoading(true);
    requestPermission();
    const data = new URLSearchParams();
    data.append("email", values?.email);
    data.append("password", values?.password);
    if (deviceToken) {
      data.append("deviceToken", deviceToken);
    }
    if (deviceToken) {
      data.append("fcmToken", deviceToken);
    }
    data.append("type", "web");
    dispatch(login(data, navigate, setLoading));

    // location code
     if (navigator.geolocation) {
       navigator.geolocation.getCurrentPosition(success, error);
     } else {
      //  console.log("Geolocation not supported");
     }
  };

  // get location code
  const { latitude, longitude, isAdminLogin } = useSelector(
    (state) => state.AdminReducer
  );
  console.log(latitude, longitude, "LOGIN");
  console.log(isAdminLogin,123);

  // get location code end

  return (
        <Index.Box className="main-login">
            <Index.Box className=" white-login-main">
              <Index.Box className="white-login-box">
                <Index.Box className="logo-set2">
                  <img src={Index.Svg.logo} alt="" className="" />
                </Index.Box>
                <Index.Box className="main-box">
                  <Index.Box>
                    <Index.Box className="box-text bluebox-text">
                      <Index.Typography
                        variant="body1"
                        component="p"
                        className=""
                      >
                        Welcome back!
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="box-login-text bluebox-text">
                      <Index.Typography
                        variant="body1"
                        component="p"
                        className=""
                      >
                        Login your account.
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="input-design-div admin-design-div login-input-design-div">
                      <Formik
                        enableReinitialize
                        onSubmit={handleFormSubmit}
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                      >
                        {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                        }) => (
                          <Index.Stack
                            component="form"
                            spacing={2}
                            noValidate
                            autoComplete="off"
                            onSubmit={handleSubmit}
                          >
                            <Index.TextField
                              hiddenLabel
                              id="filled-hidden-label-normal"
                              placeholder="Email"
                              variant="filled"
                              className="admin-input-design input-placeholder"
                              name="email"
                              autoComplete="off"
                              onBlur={handleBlur}
                              onFocus={handleFocus}
                              value={values.email}
                              onChange={handleChange}
                              helperText={touched.email && errors.email}
                              error={Boolean(errors.email && touched.email)}
                            />
                            <Index.TextField
                              hiddenLabel
                              id="filled-hidden-label-normal"
                              placeholder="Password"
                              variant="filled"
                              className="admin-input-design input-placeholder password"
                              name="password"
                              onFocus={handleFocus}
                              type={showPassword ? "text" : "password"}
                              autoComplete="off"
                              inputProps={{
                                className: "input_props",
                              }}
                              InputLabelProps={{ className: "add-formlabel" }}
                              FormHelperTextProps={{
                                className: "input_label_props",
                              }}
                              onBlur={handleBlur}
                              value={values.password}
                              onChange={handleChange}
                              helperText={touched.password && errors.password}
                              error={Boolean(
                                errors.password && touched.password
                              )}
                              sx={{ mb: 3 }}
                              InputProps={{
                                // <-- This is where the toggle button is added.
                                endAdornment: (
                                  <Index.InputAdornment position="end">
                                    <Index.IconButton
                                      aria-label="toggle password visibility"
                                      onClick={handleClickShowPassword}
                                      edge="end"
                                    >
                                      {showPassword ? (
                                        <Index.VisibilityOff />
                                      ) : (
                                        <Index.Visibility />
                                      )}
                                    </Index.IconButton>
                                  </Index.InputAdornment>
                                ),
                              }}
                            />
                            <Index.Box className="orange-btn login-btn login-btn-main">
                              <Index.Button
                                type="submit"
                                variant="contained"
                                disableRipple
                                disabled={loading}
                              >
                                Login
                              </Index.Button>
                            </Index.Box>
                            <Index.Box
                              className="box-login-text forgot bluebox-text"
                              sx={{ mt: 3 }}
                            >
                              <Link to="/forgot-password">
                                <Index.Typography
                                  variant="body1"
                                  component="p"
                                  className="forgot_password"
                                >
                                  Forgot password?
                                </Index.Typography>
                              </Link>
                            </Index.Box>
                          </Index.Stack>
                        )}
                      </Formik>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
        </Index.Box>
  );
}

export default Authenticate;
