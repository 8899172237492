import Index from "../../../../Index";
import React, { useState, useEffect } from "react";
import APIService from "../../../../../redux/APIService";
import { createSearchParams } from "react-router-dom";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";

const BorderLinearProgress = Index.styled(LinearProgress)(({ theme }) => ({
  height: 5,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#E8B153" : "#DFE0E1",
  },
}));

const OrderView = ({ filterOption }) => {
  const navigate = Index.useNavigate();
  const [tripData, setTripData] = useState([]);

  const { tripDashboardList } = Index.useSelector(
    (state) => state.AdminReducer
  );

  function calculatePercentage(numb1, numb2) {
    const percentage = (numb1 / numb2) * 100;
    return Math.round(percentage);
  }

  const orderView = (id) => {
    navigate(`/admin/order/view-order/${id}`);
  };
  useEffect(() => {
    if (tripDashboardList) {
      // Today trips
      const next1Days = Index.moment()
        .utc()
        .add(1, "days")
        .format("YYYY-MM-DD");
      const next10Days = Index.moment()
        .utc()
        .add(10, "days")
        .format("YYYY-MM-DD");

      const todayDate = Index.moment().utc().format("YYYY-MM-DD");
      const prev10Days = Index.moment()
        .utc()
        .subtract(10, "days")
        .format("YYYY-MM-DD");

      let todayTripsData =
        filterOption === "today"
          ? tripDashboardList.filter((item) => {
              // Check if loadingDate is defined and not null
              if (item?.loadingDate && item.orderStatus === "") {
                const loadingDate = Index.moment
                  .utc(item.loadingDate)
                  .format("YYYY-MM-DD");
                const todayDate = Index.moment().utc().format("YYYY-MM-DD");
                return (
                  loadingDate === todayDate ||
                  (loadingDate === next1Days && item?.tanker.length > 0)
                );
              }
              return false; // Exclude items with undefined or null loadingDate
            })
          : filterOption === "ongoing"
          ? tripDashboardList
              .filter((item) => {
                // Check if loadingDate is defined and not null
                if (item?.loadingDate && item.orderStatus === "") {
                  const loadingDate = Index.moment
                    .utc(item.loadingDate)
                    .format("YYYY-MM-DD");
                  const todayDate = Index.moment().utc().format("YYYY-MM-DD");
                  return loadingDate > todayDate && loadingDate <= next10Days;
                }
                return false; // Exclude items with undefined or null loadingDate
              })
              .sort((a, b) => {
                // Sort by loadingDate in descending order
                const dateA = Index.moment.utc(a.loadingDate);
                const dateB = Index.moment.utc(b.loadingDate);
                return dateB - dateA;
              })
          : filterOption === "completed"
          ? tripDashboardList
              .filter((item) => {
                // Check if loadingDate is defined and not null, and if the orderStatus is "Completed"
                if (
                  (item?.loadingDate && item?.orderStatus === "Completed") ||
                  item?.challanAvailable === true
                ) {
                  const loadingDate = Index.moment
                    .utc(item.loadingDate)
                    .format("YYYY-MM-DD");
                  return loadingDate >= prev10Days && loadingDate < todayDate;
                }
                return false; // Exclude items with undefined or null loadingDate or orderStatus not "Completed"
              })
              .sort((a, b) => {
                // Sort by loadingDate in descending order
                const dateA = Index.moment.utc(a.loadingDate);
                const dateB = Index.moment.utc(b.loadingDate);
                return dateB - dateA;
              })
          : "";
      setTripData(todayTripsData);
    }
  }, [tripDashboardList, filterOption]);

  return (
    <div>
      <Index.Box className="tree-analysis-main-order-view">
        <Index.List className="tree-list-analysis">
          {tripData &&
            tripData?.map(
              (item, index) =>
                item?._id !== null && (
                  // <Index.ListItem className="tree-listitem-analysis">
                  <Index.Box
                    key={index}
                    className="dashboard-card dashboard-card-order-view order-view-dash"
                  >
                    <Index.Box className="dashboard-left-main">
                      <Index.Box className="dashboard-image-box ">
                        <img
                          src={Index.Png.cargoShip}
                          alt=""
                          className="dashboard-trip-icon order-view-ship-icon order-view-"
                        />
                      </Index.Box>
                      <Index.Box className="progress-bar-main-order-view-ship">
                        <BorderLinearProgress
                          variant="determinate"
                          // value={25}
                          value={calculatePercentage(
                            item?.totalTankerQty,
                            Number(item?.lowerQuantity)
                          )}
                          className="progress-bar"
                        />
                      </Index.Box>
                      <Index.Typography className="trip-total-text">
                        {Number(item?.totalTankerQty).toFixed(0)}/
                        {Number(item?.lowerQuantity).toFixed(0)} MT
                      </Index.Typography>
                    </Index.Box>

                    <Index.Box
                      className="ordersdashboard-right-main"
                      sx={{ cursor: "pointer" }}
                      onClick={() => {
                        orderView(item?._id);
                      }}
                    >
                      {/* <Index.Box className="dashboard-content-flex">
                        <Index.Typography className="dashboard-content-label">
                          Trader :
                        </Index.Typography>
                        <Index.Typography className="dashboard-content-data">
                          {item?.trader ? item?.trader : "-"}
                        </Index.Typography>
                      </Index.Box> */}
                      <Index.Box className="dashboard-content-flex">
                        <Index.Typography className="dashboard-content-label">
                          Vessel :
                        </Index.Typography>
                        <Index.Typography className="dashboard-content-data">
                          {item?.vesselName ? item?.vesselName : "-"}
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="dashboard-content-flex">
                        <Index.Typography className="dashboard-content-label">
                          Date :
                        </Index.Typography>
                        <Index.Typography className="dashboard-content-data">
                          {item?.loadingDate
                            ? Index.moment(item?.loadingDate).format(
                                "DD/MM/YYYY"
                              )
                            : "-"}
                        </Index.Typography>
                      </Index.Box>

                      <Index.Box className="dashboard-content-flex">
                        <Index.Typography className="dashboard-content-label">
                          Items :
                        </Index.Typography>
                        <Index.Typography className="dashboard-content-data">
                          {item?.product ? item?.product : "-"}
                        </Index.Typography>
                      </Index.Box>

                      <Index.Box className="dashboard-content-flex">
                        <Index.Typography className="dashboard-content-label">
                          Terminal :
                        </Index.Typography>
                        <Index.Typography className="dashboard-content-data">
                          {item?.terminal ? item?.terminal : "-"}
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="dashboard-content-flex">
                        <Index.Typography className="dashboard-content-label">
                          Barge Name :
                        </Index.Typography>
                        <Index.Typography className="dashboard-content-data">
                          {item?.proposedBarge?.length
                            ? item?.proposedBarge
                              ? Array.prototype.map
                                  .call(item?.proposedBarge, function (ele) {
                                    return ele.bargeName;
                                  })
                                  .join(",")
                              : ""
                            : "-"}
                        </Index.Typography>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                  // </Index.ListItem>
                )
            )}
        </Index.List>
      </Index.Box>
    </div>
  );
};

export default OrderView;
