import React, { useState } from "react";
import Index from "../../../Index";

const Search = Index.styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: Index.alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: Index.alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const StyledInputBase = Index.styled(Index.InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

function TankerTrucks() {
  // filter
  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  return (
    <>
      <Index.Box className="page-content-box">
        <Index.Box className="barge-common-box">
          <Index.Box className="title-header">
            <Index.Box className="title-header-flex res-title-header-flex">
              <Index.Box className="title-main">
                <Index.Typography
                  variant="p"
                  component="p"
                  className="page-title"
                >
                  Tanker Trucks
                </Index.Typography>
              </Index.Box>
              <Index.Box className="d-flex align-items-center res-set-search">
                <Search className="search">
                  <StyledInputBase
                    placeholder="Search"
                    inputProps={{ "aria-label": "search" }}
                  />
                </Search>

                <Index.Box className="common-button grey-button">
                  <Index.Button variant="contained">Export</Index.Button>
                </Index.Box>
                <Index.Box className="common-button blue-button res-blue-button">
                  <Index.Button variant="contained">Add Trunks</Index.Button>
                </Index.Box>
                <Index.Box className="filter-main">
                  {["right"].map((anchor) => (
                    <React.Fragment key={anchor}>
                      <Index.Box className="filter-btn-main common-button filter-btn blue-button">
                        <Index.Button
                          className="filter-btn"
                          onClick={toggleDrawer(anchor, true)}
                          disableRipple
                        >
                          <img src={Index.Svg.filter} alt="" />
                        </Index.Button>
                      </Index.Box>
                      <Index.SwipeableDrawer
                        className="filter-main"
                        anchor={anchor}
                        open={state[anchor]}
                        onClose={toggleDrawer(anchor, false)}
                        onOpen={toggleDrawer(anchor, true)}
                      >
                        <Index.Box className="filter-header">
                          <Index.Box className="d-flex align-items-center filter-header-inner">
                            <Index.Typography className="filter-title">
                              Tanker Trucks
                            </Index.Typography>
                            <Index.Box className="close-btn filter-close-btn">
                              <img src={Index.Svg.close} alt="" />
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                        <Index.Box className="filter-inner-main">
                          <Index.Box>
                            <Index.Box>
                              <Index.Box>
                                <Index.Typography
                                  variant="label"
                                  component="label"
                                  className="input-label"
                                >
                                  Invoice No
                                </Index.Typography>
                                <Index.Box className="input-design-div with-border mb-15">
                                  <Index.TextField
                                    hiddenLabel
                                    id="filled-hidden-label-normal"
                                    placeholder=""
                                    variant="filled"
                                    className="admin-input-design input-placeholder"
                                    defaultvalue={"barge@admin.com"}
                                  />
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                        <Index.Box className="filter-footer ">
                          <Index.Box className="flex-end">
                            <Index.Box className="common-button blue-button mr-15">
                              <Index.Button variant="contained" disableRipple>
                                Filter
                              </Index.Button>
                            </Index.Box>
                            <Index.Box className="common-button outline-button ">
                              <Index.Button variant="contained" disableRipple>
                                Clear All
                              </Index.Button>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.SwipeableDrawer>
                    </React.Fragment>
                  ))}
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Index.Box className="">
            <Index.TableContainer
              component={Index.Paper}
              className="table-container"
            >
              <Index.Table
                aria-label="simple table"
                className="table-design-main tanker-table"
              >
                <Index.TableHead>
                  <Index.TableRow>
                    <Index.TableCell>PI No</Index.TableCell>
                    <Index.TableCell align="left">Vessel Name</Index.TableCell>
                    <Index.TableCell align="left">Invoice No</Index.TableCell>
                    <Index.TableCell align="left">Invoice Date</Index.TableCell>
                    <Index.TableCell align="left">TT No.</Index.TableCell>
                    <Index.TableCell align="left">Qty</Index.TableCell>
                    <Index.TableCell align="left">Amount</Index.TableCell>
                  </Index.TableRow>
                </Index.TableHead>
                <Index.TableBody>
                  <Index.TableRow>
                    <Index.TableCell>PI-151</Index.TableCell>
                    <Index.TableCell align="left">
                      Thalassini Niki
                    </Index.TableCell>
                    <Index.TableCell align="left">1</Index.TableCell>
                    <Index.TableCell align="left">12/05/2023</Index.TableCell>
                    <Index.TableCell align="left">GJ 12 Z 4695</Index.TableCell>
                    <Index.TableCell align="left">32,597</Index.TableCell>
                    <Index.TableCell align="left">5668,175</Index.TableCell>
                  </Index.TableRow>
                  <Index.TableRow>
                    <Index.TableCell>PI-151</Index.TableCell>
                    <Index.TableCell align="left">
                      Thalassini Niki
                    </Index.TableCell>
                    <Index.TableCell align="left">1</Index.TableCell>
                    <Index.TableCell align="left">12/05/2023</Index.TableCell>
                    <Index.TableCell align="left">GJ 12 Z 4695</Index.TableCell>
                    <Index.TableCell align="left">32,597</Index.TableCell>
                    <Index.TableCell align="left">5668,175</Index.TableCell>
                  </Index.TableRow>
                  <Index.TableRow>
                    <Index.TableCell>PI-151</Index.TableCell>
                    <Index.TableCell align="left">
                      Thalassini Niki
                    </Index.TableCell>
                    <Index.TableCell align="left">1</Index.TableCell>
                    <Index.TableCell align="left">12/05/2023</Index.TableCell>
                    <Index.TableCell align="left">GJ 12 Z 4695</Index.TableCell>
                    <Index.TableCell align="left">32,597</Index.TableCell>
                    <Index.TableCell align="left">5668,175</Index.TableCell>
                  </Index.TableRow>
                  <Index.TableRow>
                    <Index.TableCell>PI-151</Index.TableCell>
                    <Index.TableCell align="left">
                      Thalassini Niki
                    </Index.TableCell>
                    <Index.TableCell align="left">1</Index.TableCell>
                    <Index.TableCell align="left">12/05/2023</Index.TableCell>
                    <Index.TableCell align="left">GJ 12 Z 4695</Index.TableCell>
                    <Index.TableCell align="left">32,597</Index.TableCell>
                    <Index.TableCell align="left">5668,175</Index.TableCell>
                  </Index.TableRow>
                  <Index.TableRow>
                    <Index.TableCell>PI-151</Index.TableCell>
                    <Index.TableCell align="left">
                      Thalassini Niki
                    </Index.TableCell>
                    <Index.TableCell align="left">1</Index.TableCell>
                    <Index.TableCell align="left">12/05/2023</Index.TableCell>
                    <Index.TableCell align="left">GJ 12 Z 4695</Index.TableCell>
                    <Index.TableCell align="left">32,597</Index.TableCell>
                    <Index.TableCell align="left">5668,175</Index.TableCell>
                  </Index.TableRow>
                  <Index.TableRow>
                    <Index.TableCell>PI-151</Index.TableCell>
                    <Index.TableCell align="left">
                      Thalassini Niki
                    </Index.TableCell>
                    <Index.TableCell align="left">1</Index.TableCell>
                    <Index.TableCell align="left">12/05/2023</Index.TableCell>
                    <Index.TableCell align="left">GJ 12 Z 4695</Index.TableCell>
                    <Index.TableCell align="left">32,597</Index.TableCell>
                    <Index.TableCell align="left">5668,175</Index.TableCell>
                  </Index.TableRow>
                  <Index.TableRow>
                    <Index.TableCell>PI-151</Index.TableCell>
                    <Index.TableCell align="left">
                      Thalassini Niki
                    </Index.TableCell>
                    <Index.TableCell align="left">1</Index.TableCell>
                    <Index.TableCell align="left">12/05/2023</Index.TableCell>
                    <Index.TableCell align="left">GJ 12 Z 4695</Index.TableCell>
                    <Index.TableCell align="left">32,597</Index.TableCell>
                    <Index.TableCell align="left">5668,175</Index.TableCell>
                  </Index.TableRow>
                  <Index.TableRow>
                    <Index.TableCell>PI-151</Index.TableCell>
                    <Index.TableCell align="left">
                      Thalassini Niki
                    </Index.TableCell>
                    <Index.TableCell align="left">1</Index.TableCell>
                    <Index.TableCell align="left">12/05/2023</Index.TableCell>
                    <Index.TableCell align="left">GJ 12 Z 4695</Index.TableCell>
                    <Index.TableCell align="left">32,597</Index.TableCell>
                    <Index.TableCell align="left">5668,175</Index.TableCell>
                  </Index.TableRow>
                  <Index.TableRow>
                    <Index.TableCell>PI-151</Index.TableCell>
                    <Index.TableCell align="left">
                      Thalassini Niki
                    </Index.TableCell>
                    <Index.TableCell align="left">1</Index.TableCell>
                    <Index.TableCell align="left">12/05/2023</Index.TableCell>
                    <Index.TableCell align="left">GJ 12 Z 4695</Index.TableCell>
                    <Index.TableCell align="left">32,597</Index.TableCell>
                    <Index.TableCell align="left">5668,175</Index.TableCell>
                  </Index.TableRow>
                  <Index.TableRow>
                    <Index.TableCell>PI-151</Index.TableCell>
                    <Index.TableCell align="left">
                      Thalassini Niki
                    </Index.TableCell>
                    <Index.TableCell align="left">1</Index.TableCell>
                    <Index.TableCell align="left">12/05/2023</Index.TableCell>
                    <Index.TableCell align="left">GJ 12 Z 4695</Index.TableCell>
                    <Index.TableCell align="left">32,597</Index.TableCell>
                    <Index.TableCell align="left">5668,175</Index.TableCell>
                  </Index.TableRow>
                  <Index.TableRow>
                    <Index.TableCell>PI-151</Index.TableCell>
                    <Index.TableCell align="left">
                      Thalassini Niki
                    </Index.TableCell>
                    <Index.TableCell align="left">1</Index.TableCell>
                    <Index.TableCell align="left">12/05/2023</Index.TableCell>
                    <Index.TableCell align="left">GJ 12 Z 4695</Index.TableCell>
                    <Index.TableCell align="left">32,597</Index.TableCell>
                    <Index.TableCell align="left">5668,175</Index.TableCell>
                  </Index.TableRow>
                  <Index.TableRow>
                    <Index.TableCell>PI-151</Index.TableCell>
                    <Index.TableCell align="left">
                      Thalassini Niki
                    </Index.TableCell>
                    <Index.TableCell align="left">1</Index.TableCell>
                    <Index.TableCell align="left">12/05/2023</Index.TableCell>
                    <Index.TableCell align="left">GJ 12 Z 4695</Index.TableCell>
                    <Index.TableCell align="left">32,597</Index.TableCell>
                    <Index.TableCell align="left">5668,175</Index.TableCell>
                  </Index.TableRow>
                  <Index.TableRow>
                    <Index.TableCell>PI-151</Index.TableCell>
                    <Index.TableCell align="left">
                      Thalassini Niki
                    </Index.TableCell>
                    <Index.TableCell align="left">1</Index.TableCell>
                    <Index.TableCell align="left">12/05/2023</Index.TableCell>
                    <Index.TableCell align="left">GJ 12 Z 4695</Index.TableCell>
                    <Index.TableCell align="left">32,597</Index.TableCell>
                    <Index.TableCell align="left">5668,175</Index.TableCell>
                  </Index.TableRow>
                  <Index.TableRow>
                    <Index.TableCell>PI-151</Index.TableCell>
                    <Index.TableCell align="left">
                      Thalassini Niki
                    </Index.TableCell>
                    <Index.TableCell align="left">1</Index.TableCell>
                    <Index.TableCell align="left">12/05/2023</Index.TableCell>
                    <Index.TableCell align="left">GJ 12 Z 4695</Index.TableCell>
                    <Index.TableCell align="left">32,597</Index.TableCell>
                    <Index.TableCell align="left">5668,175</Index.TableCell>
                  </Index.TableRow>
                  <Index.TableRow>
                    <Index.TableCell>PI-151</Index.TableCell>
                    <Index.TableCell align="left">
                      Thalassini Niki
                    </Index.TableCell>
                    <Index.TableCell align="left">1</Index.TableCell>
                    <Index.TableCell align="left">12/05/2023</Index.TableCell>
                    <Index.TableCell align="left">GJ 12 Z 4695</Index.TableCell>
                    <Index.TableCell align="left">32,597</Index.TableCell>
                    <Index.TableCell align="left">5668,175</Index.TableCell>
                  </Index.TableRow>
                  <Index.TableRow>
                    <Index.TableCell>PI-151</Index.TableCell>
                    <Index.TableCell align="left">
                      Thalassini Niki
                    </Index.TableCell>
                    <Index.TableCell align="left">1</Index.TableCell>
                    <Index.TableCell align="left">12/05/2023</Index.TableCell>
                    <Index.TableCell align="left">GJ 12 Z 4695</Index.TableCell>
                    <Index.TableCell align="left">32,597</Index.TableCell>
                    <Index.TableCell align="left">5668,175</Index.TableCell>
                  </Index.TableRow>
                  <Index.TableRow>
                    <Index.TableCell>PI-151</Index.TableCell>
                    <Index.TableCell align="left">
                      Thalassini Niki
                    </Index.TableCell>
                    <Index.TableCell align="left">1</Index.TableCell>
                    <Index.TableCell align="left">12/05/2023</Index.TableCell>
                    <Index.TableCell align="left">GJ 12 Z 4695</Index.TableCell>
                    <Index.TableCell align="left">32,597</Index.TableCell>
                    <Index.TableCell align="left">5668,175</Index.TableCell>
                  </Index.TableRow>
                  <Index.TableRow>
                    <Index.TableCell>PI-151</Index.TableCell>
                    <Index.TableCell align="left">
                      Thalassini Niki
                    </Index.TableCell>
                    <Index.TableCell align="left">1</Index.TableCell>
                    <Index.TableCell align="left">12/05/2023</Index.TableCell>
                    <Index.TableCell align="left">GJ 12 Z 4695</Index.TableCell>
                    <Index.TableCell align="left">32,597</Index.TableCell>
                    <Index.TableCell align="left">5668,175</Index.TableCell>
                  </Index.TableRow>
                  <Index.TableRow>
                    <Index.TableCell>PI-151</Index.TableCell>
                    <Index.TableCell align="left">
                      Thalassini Niki
                    </Index.TableCell>
                    <Index.TableCell align="left">1</Index.TableCell>
                    <Index.TableCell align="left">12/05/2023</Index.TableCell>
                    <Index.TableCell align="left">GJ 12 Z 4695</Index.TableCell>
                    <Index.TableCell align="left">32,597</Index.TableCell>
                    <Index.TableCell align="left">5668,175</Index.TableCell>
                  </Index.TableRow>
                  <Index.TableRow>
                    <Index.TableCell>PI-151</Index.TableCell>
                    <Index.TableCell align="left">
                      Thalassini Niki
                    </Index.TableCell>
                    <Index.TableCell align="left">1</Index.TableCell>
                    <Index.TableCell align="left">12/05/2023</Index.TableCell>
                    <Index.TableCell align="left">GJ 12 Z 4695</Index.TableCell>
                    <Index.TableCell align="left">32,597</Index.TableCell>
                    <Index.TableCell align="left">5668,175</Index.TableCell>
                  </Index.TableRow>
                </Index.TableBody>
              </Index.Table>
            </Index.TableContainer>
          </Index.Box>
          <Index.Box className="pagination-design flex-end">
            <Index.Stack spacing={2}>
              <Index.Pagination count={10} />
            </Index.Stack>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
}

export default TankerTrucks;
