import { DateTimePicker } from "@mui/x-date-pickers";
import { Formik } from "formik";
import dayjs from "dayjs";
import { terminalData } from "../../../../../config/CommonTerminalItems";
import { useEffect, useState } from "react";
import Index from "../../../../Index";
import { getBargeListForTugs } from "../../../../../redux/admin/action";

function TugAllocatedTugUpdateModal({
  openUpdateDatePopUp,
  handleCloseDateEditModal,
  handleUpdateDateViaApi,
  editTimeAllocatedTugData,
  isSingleFieldUpdate,
}) {
  const dispatch = Index.useDispatch();

  const initialValues = {
    allocatedTugs: editTimeAllocatedTugData,
  };

  const [allPortList, setAllPortList] = useState([]);
  const [allBargeListForTugs, setAllBargeListForTugs] = useState([]);

  useEffect(() => {
    dispatch(getBargeListForTugs())
      .then((res) => {
        if (res.status === 200) {
          setAllBargeListForTugs(res.data);
        } else {
          setAllBargeListForTugs([]);
        }
      })
      .catch((error) => {
        console.error(error);
        setAllBargeListForTugs([]);
      });
  }, []);
  return (
    <>
      <Index.Dialog
        open={openUpdateDatePopUp}
        onClose={handleCloseDateEditModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Index.DialogContent className="expense-model-main  modal-update-main">
          <Index.Box className="title-main">
            <Formik
              enableReinitialize
              initialValues={initialValues}
              // validationSchema={vesselValidationSchema}
              onSubmit={(values) => handleUpdateDateViaApi(values)}
            >
              {({
                values,
                errors,
                touched,
                setFieldValue,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldTouched,
                handleFocus,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Index.Box className="title-main">
                    <Index.Box className="jus-between">
                      <Index.Typography
                        variant="h6"
                        component="h6"
                        className="input-label"
                      >
                        Edit Allocate Tug
                      </Index.Typography>
                      <Index.ClearIcon
                        className="model-close-icon"
                        onClick={handleCloseDateEditModal}
                      />
                    </Index.Box>
                    <Index.Box className="update-model-main-box-set">
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Allocate Tug
                      </Index.Typography>
                      <Index.Box className="input-design-div with-border">
                        <Index.Autocomplete
                          disablePortal
                          className="invoice-select-drop"
                          id="combo-box-demo"
                          options={allBargeListForTugs}
                          value={
                            allBargeListForTugs[
                              allBargeListForTugs.findIndex(
                                (e) => e._id === values?.allocatedTugs
                              )
                            ]
                          }
                          getOptionLabel={(option) => option?.bargeName}
                          renderOption={(props, option) => (
                            <Index.Box
                              component="li"
                              sx={{
                                "& > img": { mr: 2, flexShrink: 0 },
                              }}
                              {...props}
                            >
                              {option?.bargeName}
                            </Index.Box>
                          )}
                          onChange={(event, newValue) => {
                            setFieldValue("allocatedTugs", newValue?._id);
                          }}
                          renderInput={(params) => (
                            <Index.TextField
                              {...params}
                              placeholder="Allocate Tug"
                              variant="outlined"
                            />
                          )}
                        />
                      </Index.Box>
                      <Index.Box className="common-button blue-button flex-end save-btn  modal-update-btn">
                        <Index.Button
                          variant="contained"
                          type="submit"
                          className="Particular_model_submit"
                          disabled={isSingleFieldUpdate ? true : false}
                        >
                          Update
                        </Index.Button>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </form>
              )}
            </Formik>
          </Index.Box>
        </Index.DialogContent>
      </Index.Dialog>
    </>
  );
}
export default TugAllocatedTugUpdateModal;
