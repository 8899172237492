import { DateTimePicker } from "@mui/x-date-pickers";
import Index from "../../container/Index";
import { Formik } from "formik";
import dayjs from "dayjs";

function SingleUpdateDatePopUp({
  openUpdateDatePopUp,
  handleCloseDateEditModal,
  editTimeOrderId,
  handleUpdateDateViaApi,
  editTimeDateData,
  isSingleFieldUpdate,
}) {
  return (
    <>
      <Index.Dialog
        open={openUpdateDatePopUp}
        onClose={handleCloseDateEditModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Index.DialogContent className="expense-model-main  modal-update-main">
          <Index.Box className="title-main">
            <Formik
              enableReinitialize
              initialValues={editTimeDateData?.fieldInitialValues}
              // validationSchema={vesselValidationSchema}
              onSubmit={(values) =>
                handleUpdateDateViaApi(values, editTimeDateData)
              }
            >
              {({
                values,
                errors,
                touched,
                setFieldValue,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldTouched,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Index.Box className="title-main">
                    <Index.Box className="jus-between">
                      <Index.Typography
                        variant="h6"
                        component="h6"
                        className="input-label"
                      >
                        {editTimeDateData?.popUpTitle}
                      </Index.Typography>
                      <Index.ClearIcon
                        className="model-close-icon"
                        onClick={handleCloseDateEditModal}
                      />
                    </Index.Box>
                    <Index.Box className="update-model-main-box-set">
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        {editTimeDateData?.fieldLabel}
                      </Index.Typography>
                      <Index.Box className="input-design-div">
                        <Index.Box className="set-date set-date-picker">
                          <Index.LocalizationProvider
                            dateAdapter={Index.AdapterDayjs}
                          >
                            <Index.DemoContainer components={["DatePicker"]}>
                              <Index.DemoItem label="">
                                <Index.DatePicker
                                  name={editTimeDateData?.fieldName}
                                  format="DD/MM/YYYY"
                                  defaultValue={
                                    editTimeDateData
                                      ? Index.dayjs(
                                          editTimeDateData?.fieldValue
                                            ? editTimeDateData?.fieldValue
                                            : "-"
                                        )
                                      : ""
                                  }
                                  onChange={(value) => {
                                    const formatedDate = Index.moment(
                                      value.$d
                                    ).format("YYYY-MM-DD");
                                    setFieldValue(
                                      editTimeDateData?.fieldName,
                                      formatedDate
                                    );
                                  }}
                                  slotProps={{
                                    textField: {
                                      readOnly: true,
                                    },
                                  }}
                                />
                              </Index.DemoItem>
                            </Index.DemoContainer>
                          </Index.LocalizationProvider>
                        </Index.Box>
                      </Index.Box>

                      <Index.Box className="common-button blue-button flex-end save-btn  modal-update-btn">
                        <Index.Button
                          variant="contained"
                          type="submit"
                          className="Particular_model_submit"
                          disabled={isSingleFieldUpdate ? true : false}
                        >
                          Update
                        </Index.Button>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </form>
              )}
            </Formik>
          </Index.Box>
        </Index.DialogContent>
      </Index.Dialog>
    </>
  );
}
export default SingleUpdateDatePopUp;
