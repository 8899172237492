import React from "react";
import Index from "../Index";
const PageNotFound = () => {
  return (
    <>
      <Index.Box>
        <Index.Box className="main-login">
          <Index.Box>
            <Index.Box className=" white-login-main">
              <Index.Box className="white-login-box">
                <Index.Box className="logo-set2">
                  <img src={Index.Svg.logo} alt="" className="" />
                </Index.Box>
                <Index.Box className="main-box">
                  <Index.Box>
                    <Index.Box className="box-text bluebox-text">
                      <Index.Typography
                        variant="body1"
                        component="p"
                        className="page-not-found"
                      >
                      404 Page Not Found !
                      </Index.Typography>
                    </Index.Box>

                    <Index.Box className="input-design-div admin-design-div login-input-design-div">
                      <Index.Box
                        className="box-login-text forgot bluebox-text"
                        sx={{ mt: 3 }}
                      >
                        <Index.Link to="/">
                          <Index.Typography
                            variant="body1"
                            component="p"
                            className="forgot_password"
                          >
                            Back to login
                          </Index.Typography>
                        </Index.Link>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
};

export default PageNotFound;
