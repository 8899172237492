import React from "react";
import Index from "../../../Index";

const UserDashboard = () => {
  return (
    <>
      <Index.DynamicTitle title="Dashboard" />
      <div className="main-dashboard">
        <Index.Box className="">
          <Index.Typography className="head-title" component="h1">
            User Dashboard
          </Index.Typography>
        </Index.Box>
      </div>
    </>
  );
};

export default UserDashboard;
