import { useEffect, useState } from "react";
import Index from "../../../Index";
import { getShiftTankersLog } from "../../../../redux/admin/action";

function HistoryOfShifTanker() {
  const navigate = Index.useNavigate();
  const dispatch = Index.useDispatch();
  const { id } = Index.useParams();
  const [loading, setLoading] = useState(false);
  const [tankersData, setTankersData] = useState([]);

  //   when the get shift tanker's histories then used...
  const handleGetShiftTankers = () => {
    setLoading(true);
    dispatch(getShiftTankersLog({ oldId: id }))
      .then((res) => {
        if (res.status === 200) {
          setTankersData(res.data);
          setLoading(false);
        } else {
          setTankersData([]);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error(error);
        setTankersData([]);
        setLoading(false);
      });
  };

  //   when the page first time load then used...
  useEffect(() => {
    handleGetShiftTankers();
  }, []);

  return (
    <>
      <Index.DynamicTitle title="History Of Shift Tankers" />
      <Index.Box className="">
        <Index.Box className="barge-common-box mb-20">
          {/* <Index.Box className="title-header"> */}
          <Index.Box className="title-header-flex res-title-header-flex mb-20">
            <Index.Box className="title-main">
              <Index.Typography
                variant="p"
                component="p"
                className="page-title"
              >
                History Of Shift Tankers
              </Index.Typography>
            </Index.Box>

            <Index.Box className="common-button blue-button save-btn res-button-width">
              <Index.Button
                variant="contained"
                disableRipple
                // className="Particular_model_submit edit-invoice-btn"
                onClick={() => {
                  navigate(`/admin/order/view-order/${id}`);
                }}
              >
                Back
              </Index.Button>
            </Index.Box>
          </Index.Box>
          {/* </Index.Box> */}

          <Index.Grid container spacing={1}>
            <Index.Grid item xs={12} sm={6}>
              <Index.Box className="invoice-detail-item-shift-tanker">
                <Index.Box className="input-label opacity-60">
                  Nomination No.:
                </Index.Box>
                <Index.Box className="input-label invoice-detail-shift-tanker-label">
                  {tankersData?.[0]?.hasOwnProperty("oldId")
                    ? tankersData?.[0]?.oldId?.hasOwnProperty("nominationNo")
                      ? tankersData?.[0]?.oldId?.nominationNo
                      : "-"
                    : "-"}
                </Index.Box>
              </Index.Box>
            </Index.Grid>
            <Index.Grid item xs={12} sm={6}>
              <Index.Box className="invoice-detail-item-shift-tanker">
                <Index.Box className="input-label opacity-60">
                  Vessel Name:
                </Index.Box>
                <Index.Box className="input-label invoice-detail-shift-tanker-label">
                  {tankersData?.[0]?.hasOwnProperty("oldId")
                    ? tankersData?.[0]?.oldId?.hasOwnProperty("vesselName")
                      ? tankersData?.[0]?.oldId?.vesselName
                      : "-"
                    : "-"}
                </Index.Box>
              </Index.Box>
            </Index.Grid>

            <Index.Grid item xs={12} sm={6}>
              <Index.Box className="invoice-detail-item-shift-tanker">
                <Index.Box className="input-label opacity-60">
                  Exmi Or Delivered:
                </Index.Box>
                <Index.Box className="input-label invoice-detail-shift-tanker-label">
                  {tankersData?.[0]?.hasOwnProperty("oldId")
                    ? tankersData?.[0]?.oldId?.hasOwnProperty("exmiOrDelivered")
                      ? tankersData?.[0]?.oldId?.exmiOrDelivered
                      : "-"
                    : "-"}
                </Index.Box>
              </Index.Box>
            </Index.Grid>

            <Index.Grid item xs={12} sm={6}>
              <Index.Box className="invoice-detail-item-shift-tanker">
                <Index.Box className="input-label opacity-60">
                  Total Quantity:
                </Index.Box>
                <Index.Box className="input-label invoice-detail-shift-tanker-label">
                  {tankersData?.[0]?.hasOwnProperty("oldId")
                    ? tankersData?.[0]?.oldId?.hasOwnProperty("lowerQuantity")
                      ? tankersData?.[0]?.oldId?.lowerQuantity
                      : "-"
                    : "-"}
                </Index.Box>
              </Index.Box>
            </Index.Grid>
            <Index.Grid item xs={12} sm={6}>
              <Index.Box className="invoice-detail-item-shift-tanker">
                <Index.Box className="input-label opacity-60">
                  Loading Completed Quantity:
                </Index.Box>
                <Index.Box className="input-label invoice-detail-shift-tanker-label">
                  {tankersData?.[0]?.hasOwnProperty("oldId")
                    ? tankersData?.[0]?.oldId?.hasOwnProperty(
                        "loadingCompletedQuantity"
                      )
                      ? tankersData?.[0]?.oldId?.loadingCompletedQuantity
                      : "-"
                    : "-"}
                </Index.Box>
              </Index.Box>
            </Index.Grid>
            <Index.Grid item xs={12} sm={6}>
              <Index.Box className="invoice-detail-item-shift-tanker">
                <Index.Box className="input-label opacity-60">
                  Status:
                </Index.Box>
                <Index.Box className="input-label invoice-detail-shift-tanker-label">
                  {tankersData?.[0]?.hasOwnProperty("oldId")
                    ? tankersData?.[0]?.oldId?.hasOwnProperty("status")
                      ? tankersData?.[0]?.oldId?.status
                      : "-"
                    : "-"}
                </Index.Box>
              </Index.Box>
            </Index.Grid>
          </Index.Grid>

          <Index.Box className="mb-20">
            <Index.TableContainer
              component={Index.Paper}
              className="table-container table-max-width"
            >
              <Index.Table
                // aria-label="simple table"
                stickyHeader
                aria-label="sticky table"
                className="table-design-main supply-table tug-supply-table one-line-table invoice-table-set "
              >
                <Index.TableHead>
                  <Index.TableRow>
                    <Index.TableCell align="left">
                      Tanker Number
                    </Index.TableCell>
                    <Index.TableCell align="left">Shift Date</Index.TableCell>
                    <Index.TableCell align="left">
                      Transfered Vessel Name
                    </Index.TableCell>
                    <Index.TableCell align="left">
                      Transfered By
                    </Index.TableCell>
                  </Index.TableRow>
                </Index.TableHead>
                {loading ? (
                  <Index.TableBody>
                    <Index.TableRow>
                      <Index.TableCell colSpan={14} align="center">
                        <Index.CircularProgress color="secondary" size={20} />
                      </Index.TableCell>
                    </Index.TableRow>
                  </Index.TableBody>
                ) : (
                  <Index.TableBody className="tanker-table-body">
                    {tankersData?.length ? (
                      tankersData?.map((item, index) => (
                        <Index.TableRow key={item?._id}>
                          <Index.TableCell align="left">
                            {item?.hasOwnProperty("loadingTankerId")
                              ? item?.loadingTankerId?.hasOwnProperty("tanker")
                                ? item?.loadingTankerId?.tanker?.hasOwnProperty(
                                    "tankerNumber"
                                  )
                                  ? item?.loadingTankerId?.tanker?.tankerNumber
                                  : "-"
                                : "-"
                              : "-"}
                          </Index.TableCell>

                          <Index.TableCell align="left">
                            {item?.createdAt
                              ? Index.moment(item?.createdAt).format(
                                  "DD/MM/YYYY hh:mm:ss A"
                                )
                              : "-"}
                          </Index.TableCell>

                          <Index.TableCell align="left">
                            {item?.newId?.vesselName
                              ? item?.newId?.vesselName
                              : "-"}
                          </Index.TableCell>

                          <Index.TableCell align="left">
                            {item?.transferBy?.hasOwnProperty("firstName") &&
                            item?.transferBy?.hasOwnProperty("lastName")
                              ? item?.transferBy?.firstName +
                                " " +
                                item?.transferBy?.lastName
                              : "-"}
                          </Index.TableCell>
                        </Index.TableRow>
                      ))
                    ) : (
                      <Index.TableRow>
                        <Index.TableCell
                          component="td"
                          variant="td"
                          scope="row"
                          className="no-data-in-list empty-record-align"
                          colSpan={18}
                          align="center"
                        >
                          No data available
                        </Index.TableCell>
                      </Index.TableRow>
                    )}
                  </Index.TableBody>
                )}
              </Index.Table>
            </Index.TableContainer>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
}
export default HistoryOfShifTanker;
