import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useLocation, useNavigate } from "react-router-dom";
import WaterSupplyCurrent from "./WaterSupplyCurrent";
import WaterSupplyCancelled from "./WaterSupplyCancelled";
import WaterSupplyCompleted from "./WaterSupplyCompleted";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box className="tab-comman-padding">
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const WaterSupplyTab = () => {
  let { state, pathname } = useLocation();
  const navigate = useNavigate();

  console.log({state})

  const [value, setValue] = useState(state?.value ? state?.value : 0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    navigate(pathname, { state: null });
  }, [navigate, pathname]);

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            className="dashboard-tab"
          >
            <Tab label="Water Supply Current Orders" {...a11yProps(0)} />
            <Tab label="Water Supply Cancelled Orders" {...a11yProps(1)} />
            <Tab label="Water Supply Completed Orders" {...a11yProps(2)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <WaterSupplyCurrent value={value} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <WaterSupplyCancelled />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <WaterSupplyCompleted />
        </TabPanel>
      </Box>
    </>
  );
};

export default WaterSupplyTab;
