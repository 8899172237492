import React from 'react';
import Index from '../../../Index';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

const BorderLinearProgress = Index.styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
  },
}));

const Item = Index.styled(Index.Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

function Home() {
  return (
    <>
    <Index.Box className="barge-dashboard-page">
      <Index.Box className="barge-dashboard-box">
        <Index.Box className="main-dashboard-box">
            <Index.Box className='dashboard-text'>
              <Index.Typography
                variant="body1"
                component="p"
                className=""
              >
                Dashboard
              </Index.Typography>

          </Index.Box>
          <Index.Box className="set-dash-main">
            <Index.Box className="main-box-dash">
              <Index.Box className="dash-text-box-set">
                <Index.Box className="logo-dash">
                  <img src={Index.Png.img1} alt="logo" className="" />
                </Index.Box>
                <Index.Box className="dash-text">
                  <Index.Box className='text-main'>
                    <Index.Typography
                      variant="body1"
                      component="p"
                      className=""
                    >
                      Total Sales
                    </Index.Typography>

                  </Index.Box>
                  <Index.Box className='number-text'>
                    <Index.Typography
                      variant="body1"
                      component="p"
                      className=""
                    >
                      ₹11,257,700.00
                    </Index.Typography>

                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Box className="main-box-dash">
              <Index.Box className="dash-text-box-set">
                <Index.Box className="logo-dash">
                  <img src={Index.Png.img5} alt="logo" className="" />
                </Index.Box>
                <Index.Box className="dash-text">
                  <Index.Box className='text-main'>
                    <Index.Typography
                      variant="body1"
                      component="p"
                      className=""
                    >
                      Amount Paid
                    </Index.Typography>

                  </Index.Box>
                  <Index.Box className='number-text'>
                    <Index.Typography
                      variant="body1"
                      component="p"
                      className=""
                    >
                      ₹00.00
                    </Index.Typography>

                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Box className="main-box-dash">
              <Index.Box className="dash-text-box-set">
                <Index.Box className="logo-dash">
                  <img src={Index.Png.img3} alt="logo" className="" />
                </Index.Box>
                <Index.Box className="dash-text">
                  <Index.Box className='text-main'>
                    <Index.Typography
                      variant="body1"
                      component="p"
                      className=""
                    >
                      Amount Unpaid
                    </Index.Typography>

                  </Index.Box>
                  <Index.Box className='number-text'>
                    <Index.Typography
                      variant="body1"
                      component="p"
                      className=""
                    >
                      ₹11,257,700.00
                    </Index.Typography>

                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Box className="main-box-dash">
              <Index.Box className="dash-text-box-set">
                <Index.Box className="logo-dash">
                  <img src={Index.Png.img2} alt="logo" className="" />
                </Index.Box>
                <Index.Box className="dash-text">
                  <Index.Box className='text-main'>
                    <Index.Typography
                      variant="body1"
                      component="p"
                      className=""
                    >
                      Total Invoice
                    </Index.Typography>

                  </Index.Box>
                  <Index.Box className='number-text'>
                    <Index.Typography
                      variant="body1"
                      component="p"
                      className=""
                    >
                      654
                    </Index.Typography>

                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Box className="main-box-dash">
              <Index.Box className="dash-text-box-set">
                <Index.Box className="logo-dash">
                  <img src={Index.Png.img4} alt="logo" className="" />
                </Index.Box>
                <Index.Box className="dash-text">
                  <Index.Box className='text-main'>
                    <Index.Typography
                      variant="body1"
                      component="p"
                      className=""
                    >
                      Unpaid Invoice
                    </Index.Typography>

                  </Index.Box>
                  <Index.Box className='number-text'>
                    <Index.Typography
                      variant="body1"
                      component="p"
                      className=""
                    >
                      500
                    </Index.Typography>

                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>



          </Index.Box>
        </Index.Box>
        <Index.Box className="main-bargebox">
          <Index.Box className="barge-main-dashboard">

            <Index.Box className="dashboard-row-main-div">
              <Index.Grid container className="dashboard-row-main" rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>

                <Index.Grid item xs={12} sm={12} md={6} lg={6} >
                  <Item className="dashboard-item">
                    <Index.Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
                      <Index.Grid item xs={12}>
                        <Index.Box>
                          <Index.Box className='dashboard-text'>
                            <Index.Typography
                              variant="body1"
                              component="p"
                              className=""
                            >
                              Barge List
                            </Index.Typography>

                          </Index.Box>
                        </Index.Box>
                      </Index.Grid>
                      <Index.Grid item xs={12} sm={6} md={6} lg={6}
                      >
                        <Index.Box className="barge-list-main-box ">
                          <Index.Box className='main-barge-box'>
                            <Index.Box className='brge-img-set'>
                              <Index.Box className="brge-icon">
                                <img src={Index.Png.img6} alt="logo" className="brge-icon-set" />
                              </Index.Box>
                              <Index.Box>
                                <Index.Box sx={{ flexGrow: 1 }} className="set-progress">
                                  <BorderLinearProgress variant="determinate" value={50} />
                                </Index.Box>
                              </Index.Box>
                              <Index.Box className='brge-number'>
                                <Index.Typography
                                  variant="body1"
                                  component="p"
                                  className="text"
                                >
                                  250/800 MT
                                </Index.Typography>

                              </Index.Box>
                            </Index.Box>
                            <Index.Box className='barge-set-text-box'>
                              <Index.Box>
                                <Index.Box className="set-scheduled  set-scheduled-back">
                                  <Index.Typography
                                    variant="body1"
                                    component="p"
                                    className="text"
                                  >
                                    Scheduled
                                  </Index.Typography>
                                </Index.Box>
                                <Index.Box className="set-scheduled-text">
                                  <Index.Typography
                                    variant="body1"
                                    component="p"
                                    className="text-name"
                                  >
                                    Name : <sapn className='text-span'>Dwarkesh</sapn>
                                  </Index.Typography>
                                </Index.Box>

                                <Index.Box className="set-scheduled-text pt-5">
                                  <Index.Typography
                                    variant="body1"
                                    component="p"
                                    className="text-name"
                                  >
                                    Destination Port : <sapn className='text-span'>Kandla to Sikka</sapn>
                                  </Index.Typography>
                                </Index.Box>
                                <Index.Box className="set-scheduled-text pt-5">
                                  <Index.Typography
                                    variant="body1"
                                    component="p"
                                    className="text-name"
                                  >
                                    Schedule Date : <sapn className='text-span'>06/05/2023</sapn>
                                  </Index.Typography>
                                </Index.Box>
                                <Index.Box className="set-scheduled-text pt-5">
                                  <Index.Typography
                                    variant="body1"
                                    component="p"
                                    className="text-name"
                                  >
                                    Items : <sapn className='text-span'>Diesel, FO </sapn>
                                  </Index.Typography>
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Grid>
                      <Index.Grid item  xs={12} sm={6} md={6} lg={6}><Index.Box className="barge-list-main-box ">
                        <Index.Box className='main-barge-box'>
                          <Index.Box className='brge-img-set'>
                            <Index.Box className="brge-icon">
                              <img src={Index.Png.img6} alt="logo" className="brge-icon-set" />
                            </Index.Box>
                            <Index.Box>
                              <Index.Box sx={{ flexGrow: 1 }} className="set-progress">
                                <BorderLinearProgress variant="determinate" value={0} />
                              </Index.Box>
                            </Index.Box>
                            <Index.Box className='brge-number'>
                              <Index.Typography
                                variant="body1"
                                component="p"
                                className="text"
                              >
                                -/800 MT
                              </Index.Typography>

                            </Index.Box>
                          </Index.Box>
                          <Index.Box className='barge-set-text-box'>
                            <Index.Box>
                              <Index.Box className="set-scheduled set-available-back">
                                <Index.Typography
                                  variant="body1"
                                  component="p"
                                  className="text"
                                >
                                  Available
                                </Index.Typography>
                              </Index.Box>
                              <Index.Box className="set-scheduled-text">
                                <Index.Typography
                                  variant="body1"
                                  component="p"
                                  className="text-name"
                                >
                                  Name : <sapn className='text-span'>Vailankanni</sapn>
                                </Index.Typography>
                              </Index.Box>

                              <Index.Box className="set-scheduled-text pt-5">
                                <Index.Typography
                                  variant="body1"
                                  component="p"
                                  className="text-name"
                                >
                                  Destination Port : <sapn className='text-span'></sapn>
                                </Index.Typography>
                              </Index.Box>
                              <Index.Box className="set-scheduled-text pt-5">
                                <Index.Typography
                                  variant="body1"
                                  component="p"
                                  className="text-name"
                                >
                                  Schedule Date : <sapn className='text-span'></sapn>
                                </Index.Typography>
                              </Index.Box>
                              <Index.Box className="set-scheduled-text pt-5">
                                <Index.Typography
                                  variant="body1"
                                  component="p"
                                  className="text-name"
                                >
                                  Items : <sapn className='text-span'> </sapn>
                                </Index.Typography>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box></Index.Grid>
                      <Index.Grid item  xs={12} sm={6} md={6} lg={6}><Index.Box className="barge-list-main-box ">
                        <Index.Box className='main-barge-box'>
                          <Index.Box className='brge-img-set'>
                            <Index.Box className="brge-icon">
                              <img src={Index.Png.img6} alt="logo" className="brge-icon-set" />
                            </Index.Box>
                            <Index.Box>
                              <Index.Box sx={{ flexGrow: 1 }} className="set-progress">
                                <BorderLinearProgress variant="determinate" value={50} />
                              </Index.Box>
                            </Index.Box>
                            <Index.Box className='brge-number'>
                              <Index.Typography
                                variant="body1"
                                component="p"
                                className="text"
                              >
                                250/800 MT
                              </Index.Typography>

                            </Index.Box>
                          </Index.Box>
                          <Index.Box className='barge-set-text-box'>
                            <Index.Box>
                              <Index.Box className="set-scheduled  set-scheduled-back">
                                <Index.Typography
                                  variant="body1"
                                  component="p"
                                  className="text"
                                >
                                  Scheduled
                                </Index.Typography>
                              </Index.Box>
                              <Index.Box className="set-scheduled-text">
                                <Index.Typography
                                  variant="body1"
                                  component="p"
                                  className="text-name"
                                >
                                  Name : <sapn className='text-span'>Dwarkesh</sapn>
                                </Index.Typography>
                              </Index.Box>

                              <Index.Box className="set-scheduled-text pt-5">
                                <Index.Typography
                                  variant="body1"
                                  component="p"
                                  className="text-name"
                                >
                                  Destination Port : <sapn className='text-span'>Kandla to Sikka</sapn>
                                </Index.Typography>
                              </Index.Box>
                              <Index.Box className="set-scheduled-text pt-5">
                                <Index.Typography
                                  variant="body1"
                                  component="p"
                                  className="text-name"
                                >
                                  Schedule Date : <sapn className='text-span'>06/05/2023</sapn>
                                </Index.Typography>
                              </Index.Box>
                              <Index.Box className="set-scheduled-text pt-5">
                                <Index.Typography
                                  variant="body1"
                                  component="p"
                                  className="text-name"
                                >
                                  Items : <sapn className='text-span'>Diesel, FO </sapn>
                                </Index.Typography>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box></Index.Grid>
                      <Index.Grid item  xs={12} sm={6} md={6} lg={6}> <Index.Box className="barge-list-main-box ">
                        <Index.Box className='main-barge-box'>
                          <Index.Box className='brge-img-set'>
                            <Index.Box className="brge-icon">
                              <img src={Index.Png.img6} alt="logo" className="brge-icon-set" />
                            </Index.Box>
                            <Index.Box>
                              <Index.Box sx={{ flexGrow: 1 }} className="set-progress ">
                                <BorderLinearProgress variant="determinate" value={0} />
                              </Index.Box>
                            </Index.Box>
                            <Index.Box className='brge-number'>
                              <Index.Typography
                                variant="body1"
                                component="p"
                                className="text"
                              >
                                -/800 MT
                              </Index.Typography>

                            </Index.Box>
                          </Index.Box>
                          <Index.Box className='barge-set-text-box'>
                            <Index.Box>
                              <Index.Box className="set-scheduled  set-scheduled-back">
                                {/* <Index.Typography
                      variant="body1"
                      component="p"
                      className="text"
                    >
                       Scheduled
                    </Index.Typography> */}
                              </Index.Box>
                              <Index.Box className="set-scheduled-text">
                                <Index.Typography
                                  variant="body1"
                                  component="p"
                                  className="text-name"
                                >
                                  Name : <sapn className='text-span'>Vailankanni</sapn>
                                </Index.Typography>
                              </Index.Box>

                              <Index.Box className="set-scheduled-text pt-5">
                                <Index.Typography
                                  variant="body1"
                                  component="p"
                                  className="text-name"
                                >
                                  Destination Port : <sapn className='text-span'></sapn>
                                </Index.Typography>
                              </Index.Box>
                              <Index.Box className="set-scheduled-text pt-5">
                                <Index.Typography
                                  variant="body1"
                                  component="p"
                                  className="text-name"
                                >
                                  Schedule Date : <sapn className='text-span'></sapn>
                                </Index.Typography>
                              </Index.Box>
                              <Index.Box className="set-scheduled-text pt-5">
                                <Index.Typography
                                  variant="body1"
                                  component="p"
                                  className="text-name"
                                >
                                  Items : <sapn className='text-span'> </sapn>
                                </Index.Typography>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box></Index.Grid>
                      <Index.Grid item xs={12} sm={6} md={6} lg={6}><Index.Box className="barge-list-main-box ">
                        <Index.Box className='main-barge-box'>
                          <Index.Box className='brge-img-set'>
                            <Index.Box className="brge-icon">
                              <img src={Index.Png.img6} alt="logo" className="brge-icon-set" />
                            </Index.Box>
                            <Index.Box>
                              <Index.Box sx={{ flexGrow: 1 }} className="set-progress">
                                <BorderLinearProgress variant="determinate" value={50} />
                              </Index.Box>
                            </Index.Box>
                            <Index.Box className='brge-number'>
                              <Index.Typography
                                variant="body1"
                                component="p"
                                className="text"
                              >
                                250/800 MT
                              </Index.Typography>

                            </Index.Box>
                          </Index.Box>
                          <Index.Box className='barge-set-text-box'>
                            <Index.Box>
                              <Index.Box className="set-scheduled  set-scheduled-back">
                                <Index.Typography
                                  variant="body1"
                                  component="p"
                                  className="text"
                                >
                                  Scheduled
                                </Index.Typography>
                              </Index.Box>
                              <Index.Box className="set-scheduled-text">
                                <Index.Typography
                                  variant="body1"
                                  component="p"
                                  className="text-name"
                                >
                                  Name : <sapn className='text-span'>Dwarkesh</sapn>
                                </Index.Typography>
                              </Index.Box>

                              <Index.Box className="set-scheduled-text pt-5">
                                <Index.Typography
                                  variant="body1"
                                  component="p"
                                  className="text-name"
                                >
                                  Destination Port : <sapn className='text-span'>Kandla to Sikka</sapn>
                                </Index.Typography>
                              </Index.Box>
                              <Index.Box className="set-scheduled-text pt-5">
                                <Index.Typography
                                  variant="body1"
                                  component="p"
                                  className="text-name"
                                >
                                  Schedule Date : <sapn className='text-span'>06/05/2023</sapn>
                                </Index.Typography>
                              </Index.Box>
                              <Index.Box className="set-scheduled-text pt-5">
                                <Index.Typography
                                  variant="body1"
                                  component="p"
                                  className="text-name"
                                >
                                  Items : <sapn className='text-span'>Diesel, FO </sapn>
                                </Index.Typography>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box></Index.Grid>
                      <Index.Grid item xs={12} sm={6} md={6} lg={6}>
                        <Index.Box className="barge-list-main-box ">
                          <Index.Box className='main-barge-box'>
                            <Index.Box className='brge-img-set'>
                              <Index.Box className="brge-icon">
                                <img src={Index.Png.img6} alt="logo" className="brge-icon-set" />
                              </Index.Box>
                              <Index.Box>
                                <Index.Box sx={{ flexGrow: 1 }} className="set-progress">
                                  <BorderLinearProgress variant="determinate" value={50} />
                                </Index.Box>
                              </Index.Box>
                              <Index.Box className='brge-number'>
                                <Index.Typography
                                  variant="body1"
                                  component="p"
                                  className="text"
                                >
                                  -/800 MT
                                </Index.Typography>

                              </Index.Box>
                            </Index.Box>
                            <Index.Box className='barge-set-text-box'>
                              <Index.Box>
                                <Index.Box className="set-scheduled  set-scheduled-back">
                                  {/* <Index.Typography
                      variant="body1"
                      component="p"
                      className="text"
                    >
                       Scheduled
                    </Index.Typography> */}
                                </Index.Box>
                                <Index.Box className="set-scheduled-text">
                                  <Index.Typography
                                    variant="body1"
                                    component="p"
                                    className="text-name"
                                  >
                                    Name : <sapn className='text-span'>Vailankanni</sapn>
                                  </Index.Typography>
                                </Index.Box>

                                <Index.Box className="set-scheduled-text pt-5">
                                  <Index.Typography
                                    variant="body1"
                                    component="p"
                                    className="text-name"
                                  >
                                    Destination Port : <sapn className='text-span'></sapn>
                                  </Index.Typography>
                                </Index.Box>
                                <Index.Box className="set-scheduled-text pt-5">
                                  <Index.Typography
                                    variant="body1"
                                    component="p"
                                    className="text-name"
                                  >
                                    Schedule Date : <sapn className='text-span'></sapn>
                                  </Index.Typography>
                                </Index.Box>
                                <Index.Box className="set-scheduled-text pt-5">
                                  <Index.Typography
                                    variant="body1"
                                    component="p"
                                    className="text-name"
                                  >
                                    Items : <sapn className='text-span'> </sapn>
                                  </Index.Typography>
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Grid>
                      <Index.Grid item  xs={12} sm={6} md={6} lg={6}>
                        <Index.Box className="barge-list-main-box ">
                          <Index.Box className='main-barge-box'>
                            <Index.Box className='brge-img-set'>
                              <Index.Box className="brge-icon">
                                <img src={Index.Png.img6} alt="logo" className="brge-icon-set" />
                              </Index.Box>
                              <Index.Box>
                                <Index.Box sx={{ flexGrow: 1 }} className="set-progress">
                                  <BorderLinearProgress variant="determinate" value={50} />
                                </Index.Box>
                              </Index.Box>
                              <Index.Box className='brge-number'>
                                <Index.Typography
                                  variant="body1"
                                  component="p"
                                  className="text"
                                >
                                  250/800 MT
                                </Index.Typography>

                              </Index.Box>
                            </Index.Box>
                            <Index.Box className='barge-set-text-box'>
                              <Index.Box>
                                <Index.Box className="set-scheduled  set-scheduled-back">
                                  <Index.Typography
                                    variant="body1"
                                    component="p"
                                    className="text"
                                  >
                                    Scheduled
                                  </Index.Typography>
                                </Index.Box>
                                <Index.Box className="set-scheduled-text">
                                  <Index.Typography
                                    variant="body1"
                                    component="p"
                                    className="text-name"
                                  >
                                    Name : <sapn className='text-span'>Dwarkesh</sapn>
                                  </Index.Typography>
                                </Index.Box>

                                <Index.Box className="set-scheduled-text pt-5">
                                  <Index.Typography
                                    variant="body1"
                                    component="p"
                                    className="text-name"
                                  >
                                    Destination Port : <sapn className='text-span'>Kandla to Sikka</sapn>
                                  </Index.Typography>
                                </Index.Box>
                                <Index.Box className="set-scheduled-text pt-5">
                                  <Index.Typography
                                    variant="body1"
                                    component="p"
                                    className="text-name"
                                  >
                                    Schedule Date : <sapn className='text-span'>06/05/2023</sapn>
                                  </Index.Typography>
                                </Index.Box>
                                <Index.Box className="set-scheduled-text pt-5">
                                  <Index.Typography
                                    variant="body1"
                                    component="p"
                                    className="text-name"
                                  >
                                    Items : <sapn className='text-span'>Diesel, FO </sapn>
                                  </Index.Typography>
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Grid>
                    </Index.Grid>
                  </Item>
                </Index.Grid>
                <Index.Grid item xs={12} sm={12} md={3} lg={3}>
                  <Item className="dashboard-item">

                    <Index.Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
                      <Index.Grid item xs={12}>
                        <Index.Box>
                          <Index.Box className='dashboard-text'>
                            <Index.Typography
                              variant="body1"
                              component="p"
                              className=""
                            >
                              Today Trip
                            </Index.Typography>

                          </Index.Box>
                        </Index.Box>
                      </Index.Grid>
                      <Index.Grid item xs={12} sm={6}  md={12} lg={12}>
                        <Index.Box className="barge-main-dashboard">
                          <Index.Box>
                          </Index.Box>
                          <Index.Box className="todaytrip-set-dash-main">
                            <Index.Box className=" todaytrip-list-main-box ">
                              <Index.Box className='main-barge-box'>
                                <Index.Box className='brge-img-set'>
                                  <Index.Box className="brge-icon">
                                    <img src={Index.Png.img6} alt="logo" className="brge-icon-set" />
                                  </Index.Box>
                                  <Index.Box>
                                    <Index.Box sx={{ flexGrow: 1 }} className="set-progress today-set-progress">
                                      <BorderLinearProgress variant="determinate" value={60} />
                                    </Index.Box>
                                  </Index.Box>
                                  <Index.Box className='brge-number'>
                                    <Index.Typography
                                      variant="body1"
                                      component="p"
                                      className="text"
                                    >
                                      250/800 MT
                                    </Index.Typography>

                                  </Index.Box>
                                </Index.Box>
                                <Index.Box className='barge-set-text-box today-trip-text'>
                                  <Index.Box>
                                    <Index.Box className="set-scheduled-text">
                                      <Index.Typography
                                        variant="body1"
                                        component="p"
                                        className="text-name"
                                      >
                                        Trader :<sapn className='text-span'> Rellience </sapn>
                                      </Index.Typography>
                                    </Index.Box>

                                    <Index.Box className="d-flex">
                                      <Index.Box className="set-scheduled-text pt-5 mr-10">
                                        <Index.Typography
                                          variant="body1"
                                          component="p"
                                          className="text-name"
                                        >
                                          Date : <sapn className='text-span'>05/05/2023 </sapn>
                                        </Index.Typography>
                                      </Index.Box>
                                      <Index.Box className="set-scheduled-text pt-5">
                                        <Index.Typography
                                          variant="body1"
                                          component="p"
                                          className="text-name"
                                        >
                                         Items : <sapn className='text-span'>Diesel, FO </sapn>
                                        </Index.Typography>
                                      </Index.Box>
                                    </Index.Box>




                                    <Index.Box className="set-scheduled-text pt-5">
                                      <Index.Typography
                                        variant="body1"
                                        component="p"
                                        className="text-name"
                                      >
                                        Vessel : <sapn className='text-span'>PRO Sapphire</sapn>
                                      </Index.Typography>
                                    </Index.Box>
                                    <Index.Box className="set-scheduled-text pt-5">
                                      <Index.Typography
                                        variant="body1"
                                        component="p"
                                        className="text-name"
                                      >
                                        Barge Name :<sapn className='text-span'> Dwarkesh</sapn>
                                      </Index.Typography>
                                    </Index.Box>
                                    <Index.Box className="set-scheduled-text pt-5">
                                      <Index.Typography
                                        variant="body1"
                                        component="p"
                                        className="text-name"
                                      >
                                        Port :<sapn className='text-span'> Sikka to Bedi</sapn>
                                      </Index.Typography>
                                    </Index.Box>
                                  </Index.Box>
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>




                          </Index.Box>
                        </Index.Box>
                      </Index.Grid>
                      <Index.Grid item xs={12} sm={6}  md={12} lg={12}>
                        <Index.Box className="barge-main-dashboard">
                          <Index.Box>
                          </Index.Box>
                          <Index.Box className="todaytrip-set-dash-main">
                            <Index.Box className=" todaytrip-list-main-box ">
                              <Index.Box className='main-barge-box'>
                                <Index.Box className='brge-img-set'>
                                  <Index.Box className="brge-icon">
                                    <img src={Index.Png.img6} alt="logo" className="brge-icon-set" />
                                  </Index.Box>
                                  <Index.Box>
                                    <Index.Box sx={{ flexGrow: 1 }} className="set-progress today-set-progress">
                                      <BorderLinearProgress variant="determinate" value={60} />
                                    </Index.Box>
                                  </Index.Box>
                                  <Index.Box className='brge-number'>
                                    <Index.Typography
                                      variant="body1"
                                      component="p"
                                      className="text"
                                    >
                                      250/800 MT
                                    </Index.Typography>

                                  </Index.Box>
                                </Index.Box>
                                <Index.Box className='barge-set-text-box today-trip-text'>
                                  <Index.Box>
                                    <Index.Box className="set-scheduled-text">
                                      <Index.Typography
                                        variant="body1"
                                        component="p"
                                        className="text-name"
                                      >
                                        Trader :<sapn className='text-span'> Rellience </sapn>
                                      </Index.Typography>
                                    </Index.Box>

                                    <Index.Box className="d-flex">
                                      <Index.Box className="set-scheduled-text pt-5 mr-10">
                                        <Index.Typography
                                          variant="body1"
                                          component="p"
                                          className="text-name"
                                        >
                                          Date : <sapn className='text-span'>05/05/2023 </sapn>
                                        </Index.Typography>
                                      </Index.Box>
                                      <Index.Box className="set-scheduled-text pt-5">
                                        <Index.Typography
                                          variant="body1"
                                          component="p"
                                          className="text-name"
                                        >
                                         Items : <sapn className='text-span'>Diesel, FO </sapn>
                                        </Index.Typography>
                                      </Index.Box>
                                    </Index.Box>




                                    <Index.Box className="set-scheduled-text pt-5">
                                      <Index.Typography
                                        variant="body1"
                                        component="p"
                                        className="text-name"
                                      >
                                        Vessel : <sapn className='text-span'>PRO Sapphire</sapn>
                                      </Index.Typography>
                                    </Index.Box>
                                    <Index.Box className="set-scheduled-text pt-5">
                                      <Index.Typography
                                        variant="body1"
                                        component="p"
                                        className="text-name"
                                      >
                                        Barge Name :<sapn className='text-span'> Dwarkesh</sapn>
                                      </Index.Typography>
                                    </Index.Box>
                                    <Index.Box className="set-scheduled-text pt-5">
                                      <Index.Typography
                                        variant="body1"
                                        component="p"
                                        className="text-name"
                                      >
                                        Port :<sapn className='text-span'> Sikka to Bedi</sapn>
                                      </Index.Typography>
                                    </Index.Box>
                                  </Index.Box>
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>




                          </Index.Box>
                        </Index.Box>
                      </Index.Grid>
                    </Index.Grid>

                  </Item>
                </Index.Grid>
                <Index.Grid item xs={12} sm={12} md={3} lg={3}>
                  <Item className="dashboard-item">

                    <Index.Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
                      <Index.Grid item xs={12}>
                        <Index.Box>
                          <Index.Box className='dashboard-text'>
                            <Index.Typography
                              variant="body1"
                              component="p"
                              className=""
                            >
                              Upcoming Trip
                            </Index.Typography>

                          </Index.Box>
                        </Index.Box>
                      </Index.Grid>
                      <Index.Grid item xs={12} sm={6}  md={12} lg={12}>
                        <Index.Box className="barge-main-dashboard">
                          <Index.Box>
                          </Index.Box>
                          <Index.Box className="todaytrip-set-dash-main">
                            <Index.Box className=" todaytrip-list-main-box ">
                              <Index.Box className='main-barge-box'>
                                <Index.Box className='brge-img-set'>
                                  <Index.Box className="brge-icon">
                                    <img src={Index.Png.img6} alt="logo" className="brge-icon-set" />
                                  </Index.Box>
                                  <Index.Box>
                                    <Index.Box sx={{ flexGrow: 1 }} className="set-progress today-set-progress">
                                      <BorderLinearProgress variant="determinate" value={60} />
                                    </Index.Box>
                                  </Index.Box>
                                  <Index.Box className='brge-number'>
                                    <Index.Typography
                                      variant="body1"
                                      component="p"
                                      className="text"
                                    >
                                      250/800 MT
                                    </Index.Typography>

                                  </Index.Box>
                                </Index.Box>
                                <Index.Box className='barge-set-text-box today-trip-text'>
                                  <Index.Box>
                                    <Index.Box className="set-scheduled-text">
                                      <Index.Typography
                                        variant="body1"
                                        component="p"
                                        className="text-name"
                                      >
                                        Trader :<sapn className='text-span'> Rellience </sapn>
                                      </Index.Typography>
                                    </Index.Box>

                                    <Index.Box className="d-flex">
                                      <Index.Box className="set-scheduled-text pt-5 mr-10">
                                        <Index.Typography
                                          variant="body1"
                                          component="p"
                                          className="text-name"
                                        >
                                          Date : <sapn className='text-span'>05/05/2023 </sapn>
                                        </Index.Typography>
                                      </Index.Box>
                                      <Index.Box className="set-scheduled-text pt-5">
                                        <Index.Typography
                                          variant="body1"
                                          component="p"
                                          className="text-name"
                                        >
                                         Items : <sapn className='text-span'>Diesel, FO </sapn>
                                        </Index.Typography>
                                      </Index.Box>
                                    </Index.Box>




                                    <Index.Box className="set-scheduled-text pt-5">
                                      <Index.Typography
                                        variant="body1"
                                        component="p"
                                        className="text-name"
                                      >
                                        Vessel : <sapn className='text-span'>PRO Sapphire</sapn>
                                      </Index.Typography>
                                    </Index.Box>
                                    <Index.Box className="set-scheduled-text pt-5">
                                      <Index.Typography
                                        variant="body1"
                                        component="p"
                                        className="text-name"
                                      >
                                        Barge Name :<sapn className='text-span'> Dwarkesh</sapn>
                                      </Index.Typography>
                                    </Index.Box>
                                    <Index.Box className="set-scheduled-text pt-5">
                                      <Index.Typography
                                        variant="body1"
                                        component="p"
                                        className="text-name"
                                      >
                                        Port :<sapn className='text-span'> Sikka to Bedi</sapn>
                                      </Index.Typography>
                                    </Index.Box>
                                  </Index.Box>
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>




                          </Index.Box>
                        </Index.Box>
                      </Index.Grid>
                      <Index.Grid item xs={12} sm={6}  md={12} lg={12}>
                        <Index.Box className="barge-main-dashboard">
                          <Index.Box>
                          </Index.Box>
                          <Index.Box className="todaytrip-set-dash-main">
                            <Index.Box className=" todaytrip-list-main-box ">
                              <Index.Box className='main-barge-box'>
                                <Index.Box className='brge-img-set'>
                                  <Index.Box className="brge-icon">
                                    <img src={Index.Png.img6} alt="logo" className="brge-icon-set" />
                                  </Index.Box>
                                  <Index.Box>
                                    <Index.Box sx={{ flexGrow: 1 }} className="set-progress today-set-progress">
                                      <BorderLinearProgress variant="determinate" value={60} />
                                    </Index.Box>
                                  </Index.Box>
                                  <Index.Box className='brge-number'>
                                    <Index.Typography
                                      variant="body1"
                                      component="p"
                                      className="text"
                                    >
                                      250/800 MT
                                    </Index.Typography>

                                  </Index.Box>
                                </Index.Box>
                                <Index.Box className='barge-set-text-box today-trip-text'>
                                  <Index.Box>
                                    <Index.Box className="set-scheduled-text">
                                      <Index.Typography
                                        variant="body1"
                                        component="p"
                                        className="text-name"
                                      >
                                        Trader :<sapn className='text-span'> Rellience </sapn>
                                      </Index.Typography>
                                    </Index.Box>

                                    <Index.Box className="d-flex">
                                      <Index.Box className="set-scheduled-text pt-5 mr-10">
                                        <Index.Typography
                                          variant="body1"
                                          component="p"
                                          className="text-name"
                                        >
                                          Date : <sapn className='text-span'>05/05/2023 </sapn>
                                        </Index.Typography>
                                      </Index.Box>
                                      <Index.Box className="set-scheduled-text pt-5">
                                        <Index.Typography
                                          variant="body1"
                                          component="p"
                                          className="text-name"
                                        >
                                         Items : <sapn className='text-span'>Diesel, FO </sapn>
                                        </Index.Typography>
                                      </Index.Box>
                                    </Index.Box>




                                    <Index.Box className="set-scheduled-text pt-5">
                                      <Index.Typography
                                        variant="body1"
                                        component="p"
                                        className="text-name"
                                      >
                                        Vessel : <sapn className='text-span'>PRO Sapphire</sapn>
                                      </Index.Typography>
                                    </Index.Box>
                                    <Index.Box className="set-scheduled-text pt-5">
                                      <Index.Typography
                                        variant="body1"
                                        component="p"
                                        className="text-name"
                                      >
                                        Barge Name :<sapn className='text-span'> Dwarkesh</sapn>
                                      </Index.Typography>
                                    </Index.Box>
                                    <Index.Box className="set-scheduled-text pt-5">
                                      <Index.Typography
                                        variant="body1"
                                        component="p"
                                        className="text-name"
                                      >
                                        Port :<sapn className='text-span'> Sikka to Bedi</sapn>
                                      </Index.Typography>
                                    </Index.Box>
                                  </Index.Box>
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>




                          </Index.Box>
                        </Index.Box>
                      </Index.Grid>
                    </Index.Grid>
                  </Item>
                </Index.Grid>
              </Index.Grid>
            </Index.Box>
          </Index.Box>
          {/* second */}

          {/* end */}
        </Index.Box>
      </Index.Box>
      </Index.Box>
    </>
  )
}

export default Home