import { useEffect, useState } from "react";
import Index from "../../../Index";
import { getAllCompaniesList } from "../../../../redux/admin/action";

const ActionItem = Index.styled(Index.MenuItem)(() => ({
  fontFamily: "poppins",
  lineHeight: "15px",
  fontSize: "14px",
  fontWeight: "400",
  color: "#595F69",
}));

function CompaniesList() {
  const navigate = Index.useNavigate();
  const dispatch = Index.useDispatch();
  const [loading, setLoading] = useState(false);
  const [companyList, setCompanyList] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [actionIndex, setActionIndex] = useState();

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  //   get all companies list
  const handleGetAllCompanies = () => {
    setLoading(true);
    dispatch(getAllCompaniesList())
      .then((res) => {
        if (res.status === 200) {
          setCompanyList(res.data);
          setLoading(false);
        } else {
          setCompanyList([]);
          setLoading(false);
        }
      })
      .catch((error) => {
        setCompanyList([]);
        setLoading(false);
        console.error(error);
      });
  };

  //  when the page first time load then used...
  useEffect(() => {
    handleGetAllCompanies();
  }, []);

  //   when the user open the action menu then used...
  const handleClickMenu = (e, index) => {
    setActionIndex(index);
    setAnchorEl(e.currentTarget);
  };

  const handleEdit = (row) => {
    navigate("/admin/companies/add", {
      state: { row },
    });
  };

  return (
    <>
      <Index.DynamicTitle title="Companies" />
      <Index.Box className="">
        <Index.Box className="barge-common-box">
          <Index.Box className="title-header">
            <Index.Box className="title-header-flex res-title-header-flex">
              <Index.Box className="title-main">
                <Index.Typography
                  variant="p"
                  component="p"
                  className="page-title"
                >
                  Companies
                </Index.Typography>
              </Index.Box>
              <Index.Box className="d-flex align-items-center res-set-search">
                <Index.Box className="common-button blue-button res-blue-button">
                  <Index.RouteLink
                    to="/admin/companies/add"
                    className="no-text-decoration"
                  >
                    {" "}
                    <Index.Button variant="contained" disableRipple>
                      Add Company
                    </Index.Button>
                  </Index.RouteLink>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Index.Box className="">
            <Index.TableContainer
              component={Index.Paper}
              className="table-container"
            >
              <Index.Table
                aria-label="simple table"
                className="table-design-main supply-table one-line-table invoice-table-set user-management-table "
              >
                <Index.TableHead>
                  <Index.TableRow>
                    <Index.TableCell>No</Index.TableCell>
                    <Index.TableCell>Company Name</Index.TableCell>
                    <Index.TableCell>Tally Company Name</Index.TableCell>
                    <Index.TableCell align="left">Date</Index.TableCell>
                    <Index.TableCell align="left">Action</Index.TableCell>
                  </Index.TableRow>
                </Index.TableHead>
                {loading ? (
                  <Index.TableBody>
                    <Index.TableRow>
                      <Index.TableCell
                        colSpan={12}
                        align="center"
                        className="loading-purchase"
                      >
                        <Index.CircularProgress color="secondary" size={20} />
                      </Index.TableCell>
                    </Index.TableRow>
                  </Index.TableBody>
                ) : (
                  <Index.TableBody>
                    {companyList?.length ? (
                      companyList?.map((item, index) => (
                        <Index.TableRow key={item?._id}>
                          <Index.TableCell>{index + 1}</Index.TableCell>
                          <Index.TableCell>
                            {item?.fullName ? item?.fullName : "-"}
                          </Index.TableCell>
                          <Index.TableCell
                            className="invoice-view-cursor"
                            onClick={() => {
                              navigate(
                                `/admin/companies/${item?.tallyCompanyName}`
                              );
                            }}
                          >
                            {item?.tallyCompanyName
                              ? item?.tallyCompanyName
                              : "-"}
                          </Index.TableCell>
                          <Index.TableCell>
                            {item?.createdAt
                              ? Index.moment(item?.createdAt).format(
                                  "DD/MM/YYYY"
                                )
                              : "-"}
                          </Index.TableCell>

                          <Index.TableCell sx={{ display: "flex" }}>
                            <Index.IconButton
                              onClick={(e) => {
                                handleClickMenu(e, index);
                              }}
                            >
                              <Index.MenuIcon className="action-menu-icon" />
                            </Index.IconButton>
                            <Index.Menu
                              id="demo-positioned-menu"
                              aria-labelledby="demo-positioned-button"
                              anchorEl={anchorEl}
                              keepMounted
                              elevation={2}
                              open={Boolean(anchorEl)}
                              onClose={handleCloseMenu}
                              anchorOrigin={{
                                vertical: "top",
                                horizontal: "left",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "left",
                              }}
                            >
                              <ActionItem
                                className="action-text"
                                onClick={() => {
                                  handleEdit(companyList?.[actionIndex]);
                                }}
                              >
                                Edit
                              </ActionItem>
                              <ActionItem
                                className="action-text"
                                // onClick={() => {
                                //   handlePurchaseCondition();
                                // }}
                                onClick={() => {
                                  navigate(
                                    `/admin/companies/purchase-selection/${companyList?.[actionIndex]?.tallyCompanyName}`
                                  );
                                }}
                              >
                                Purchase
                              </ActionItem>
                              <ActionItem
                                className="action-text"
                                onClick={() => {
                                  navigate(
                                    `/admin/companies/journal-selection/${companyList?.[actionIndex]?.tallyCompanyName}`
                                  );
                                }}
                              >
                                Journal
                              </ActionItem>
                            </Index.Menu>
                          </Index.TableCell>
                        </Index.TableRow>
                      ))
                    ) : (
                      <Index.TableRow>
                        <Index.TableCell
                          component="td"
                          variant="td"
                          scope="row"
                          className="no-data-in-list"
                          colSpan={15}
                          align="center"
                        >
                          No data available
                        </Index.TableCell>
                      </Index.TableRow>
                    )}
                  </Index.TableBody>
                )}
              </Index.Table>
            </Index.TableContainer>
          </Index.Box>
          {/* <Index.Box className="pagination-design flex-end">
            <Index.Stack spacing={2}>
              <Index.Box className="pagination-count">
                <Index.Box className="input-design-div with-border">
                  <Index.Select
                    fullWidth
                    value={paginationPerPage}
                    onChange={(e) => {
                      setPaginationPerPage(e.target.value);
                    }}
                  >
                    <Index.MenuItem value={10}>10</Index.MenuItem>
                    <Index.MenuItem value={25}>25</Index.MenuItem>
                    <Index.MenuItem value={50}>50</Index.MenuItem>
                    <Index.MenuItem value={100}>100</Index.MenuItem>
                  </Index.Select>
                </Index.Box>
                <Index.Pagination
                  count={count}
                  page={currentPage}
                  onChange={handlePageChange}
                />
              </Index.Box>
            </Index.Stack>
          </Index.Box> */}
        </Index.Box>
      </Index.Box>
    </>
  );
}
export default CompaniesList;
