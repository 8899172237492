import Index from "../../../../Index";
import React, { useState, useEffect } from "react";
import APIService from "../../../../../redux/APIService";
import { createSearchParams } from "react-router-dom";
const SalesTreeMap = (props) => {
  const { financialYear, days } = props;
  const { salsePartywise, activeCompany } = Index.useSelector(
    (state) => state.AdminReducer
  );
  const [treeData, setTreeData] = useState(salsePartywise || []);
  const [dateRange, setDateRange] = React.useState([]);

  function getLastDayOfMonth(year, month) {
    // Create a Date object with the next month's first day
    var nextMonthFirstDay = new Date(year, month, 1);
    // Subtract one day from the next month's first day
    var lastDayOfMonth = new Date(nextMonthFirstDay - 1);
    // Return the date part of the last day
    return lastDayOfMonth.getDate();
  }

  const getTreeChartData = async () => {
    let startDate = "";
    let endDate = "";
    if (financialYear !== "") {
      if (days === "all") {
        const dates = financialYear.split("-");

        startDate = `${dates[0]}-04-01`;
        endDate = `${dates[1]}-03-31`;
      } else if (days >= "01" && days <= "03") {
        const dates = financialYear.split("-");

        const endMonthLastDay = getLastDayOfMonth(dates[1], days);

        startDate = `${dates[1]}-${days}-01`;
        endDate = `${dates[1]}-${days}-${endMonthLastDay}`;
      } else {
        const dates = financialYear.split("-");

        const endMonthLastDay = getLastDayOfMonth(dates[1], days);

        startDate = `${dates[0]}-${days}-01`;
        endDate = `${dates[0]}-${days}-${endMonthLastDay}`;
      }

      setDateRange([startDate, endDate]);
      const newParams = createSearchParams({
        to: startDate,
        from: endDate,
        companyId: activeCompany?._id,
      }).toString();

      let data = await APIService.doGet(
        `/admin/sales-party-wise/?${newParams}`
      );
      let arr = data?.data?.data?.filter((data, index) => {
        return data;
      });
      setTreeData(arr);
    }
  };

  useEffect(() => {
    getTreeChartData();
  }, [days, financialYear, activeCompany]);

  const handleSalesWise = (event, data) => {
    event.preventDefault();
    const searchParams = createSearchParams({
      partyId: data?.partyId,
      partyName: data?._id,
      to: dateRange[0],
      from: dateRange[1],
      companyId: activeCompany?._id,
    }).toString();

    const url = `/admin/top-sales-party-wise?${searchParams}`;
    window.open(url, "_blank");
  };

  // const getBargePuchaseInvoiceDetails = React.useCallback(
  //     (event, bargeId, bargeName) => {
  //         const searchParams = createSearchParams({
  //             month: props.days,
  //             bargeId: bargeId,
  //             bargName: bargeName,
  //             to: dateRange[0],
  //             from: dateRange[1],
  //         }).toString();

  //         const url = `/admin/invoice/purchase-invoice-details?${searchParams}`;
  //         window.open(url, "_blank");
  //     },
  //     [dateRange]
  // );

  return (
    <Index.Box className="chart-graph-devider cattable-devider cattable-port">
      <Index.Box className="cate-table-main cate-table-main-box">
        <Index.TableContainer component={Index.Paper}>
          <Index.Table sx={{ minWidth: 650 }} aria-label="simple table">
            <Index.TableHead>
              <Index.TableRow>
                <Index.TableCell>No.</Index.TableCell>
                <Index.TableCell align="left">Party name</Index.TableCell>
                <Index.TableCell align="left">Price</Index.TableCell>
              </Index.TableRow>
            </Index.TableHead>
            <Index.TableBody>
              {treeData?.map((items, index) => {
                return (
                  <Index.TableRow
                    sx={{ cursor: "pointer" }}
                    onClick={(e) => {
                      handleSalesWise(e, items);
                    }}
                  >
                    <Index.TableCell>{index + 1}</Index.TableCell>
                    <Index.TableCell>{items?._id}</Index.TableCell>
                    <Index.TableCell>
                      ₹{" "}
                      {items?.amount?.toLocaleString("en-IN", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </Index.TableCell>
                  </Index.TableRow>
                );
              })}
            </Index.TableBody>
          </Index.Table>
        </Index.TableContainer>
      </Index.Box>
    </Index.Box>
  );
};

export default SalesTreeMap;
