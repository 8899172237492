import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import { getTripDashboardList } from "../../../../redux/admin/action";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";

const BorderLinearProgress = Index.styled(LinearProgress)(({ theme }) => ({
  height: 5,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#E8B153" : "#DFE0E1",
  },
}));

const TripDashboard = () => {
  const navigate = Index.useNavigate();
  const dispatch = Index.useDispatch();
  const { tripDashboardList } = Index.useSelector(
    (state) => state.AdminReducer
  );

  const [todayTrips, setTodayTrips] = useState([]);
  const [upcomingTrips, setUpcomingTrips] = useState([]);
  const [completedTrips, setCompletedTrips] = useState([]);

  useEffect(() => {
    dispatch(getTripDashboardList());
  }, []);

  useEffect(() => {
    if (tripDashboardList) {
      // Today trips
      let todayTripsData = tripDashboardList.filter((item) => {
        const next1Days = Index.moment()
          .utc()
          .add(1, "days")
          .format("YYYY-MM-DD");
        // Check if loadingDate is defined and not null
        if (item?.loadingDate && item.orderStatus === "") {
          const loadingDate = Index.moment
            .utc(item.loadingDate)
            .format("YYYY-MM-DD");
          const todayDate = Index.moment().utc().format("YYYY-MM-DD");
          return (
            loadingDate === todayDate ||
            (loadingDate === next1Days && item?.tanker.length > 0)
          );
        }
        return false; // Exclude items with undefined or null loadingDate
      });
      setTodayTrips(todayTripsData);

      // Upcoming trips. Upcoming only next 10 days data show
      const next10Days = Index.moment()
        .utc()
        .add(10, "days")
        .format("YYYY-MM-DD");
      let upcomingTripsData = tripDashboardList
        .filter((item) => {
          // Check if loadingDate is defined and not null
          if (item?.loadingDate && item.orderStatus === "") {
            const loadingDate = Index.moment
              .utc(item.loadingDate)
              .format("YYYY-MM-DD");
            const todayDate = Index.moment().utc().format("YYYY-MM-DD");
            return loadingDate > todayDate && loadingDate <= next10Days;
          }
          return false; // Exclude items with undefined or null loadingDate
        })
        .sort((a, b) => {
          // Sort by loadingDate in descending order
          const dateA = Index.moment.utc(a.loadingDate);
          const dateB = Index.moment.utc(b.loadingDate);
          return dateB - dateA;
        });
      setUpcomingTrips(upcomingTripsData);

      // Completed trips. Completed previous 10 days data show
      const todayDate = Index.moment().utc().format("YYYY-MM-DD");
      const prev10Days = Index.moment()
        .utc()
        .subtract(10, "days")
        .format("YYYY-MM-DD");
      let completedTripsData = tripDashboardList
        .filter((item) => {
          // Check if loadingDate is defined and not null, and if the orderStatus is "Completed"
          if (
            (item?.loadingDate && item?.orderStatus === "Completed") ||
            item?.challanAvailable === true
          ) {
            const loadingDate = Index.moment
              .utc(item.loadingDate)
              .format("YYYY-MM-DD");
            return loadingDate >= prev10Days && loadingDate < todayDate;
          }
          return false; // Exclude items with undefined or null loadingDate or orderStatus not "Completed"
        })
        .sort((a, b) => {
          // Sort by loadingDate in descending order
          const dateA = Index.moment.utc(a.loadingDate);
          const dateB = Index.moment.utc(b.loadingDate);
          return dateB - dateA;
        });
      setCompletedTrips(completedTripsData);
    }
  }, [tripDashboardList]);

  function calculatePercentage(numb1, numb2) {
    const percentage = (numb1 / numb2) * 100;
    return Math.round(percentage);
  }

  const orderView = (id) => {
    navigate(`/admin/order/view-order/${id}`);
  };
  return (
    <>
      <Index.DynamicTitle title="Trip Dashboard" />
      <div className="">
        <Index.Box className="">
          <Index.Typography className="head-title" component="h1">
            Orders Dashboard
          </Index.Typography>

          <Index.Box className="dashboard-main">
            <Index.Box className="dashboard-row">
              {/* Today trip */}
              <Index.Box className="dashboard-colm">
                <Index.Typography className="dashboard-trip-title">
                  Today Orders
                </Index.Typography>
                <Index.Box className="dashboard-card-scroll">
                  {todayTrips.length ? (
                    todayTrips?.map((item, index) => (
                      <Index.Box key={index} className="dashboard-card">
                        <Index.Box className="dashboard-left-main">
                          <Index.Box className="dashboard-image-box">
                            <img
                              src={Index.Png.cargoShip}
                              alt=""
                              className="dashboard-trip-icon"
                            />
                          </Index.Box>
                          <Index.Box className="progress-bar-main">
                            <BorderLinearProgress
                              variant="determinate"
                              // value={25}
                              value={calculatePercentage(
                                item?.totalTankerQty,
                                Number(item?.lowerQuantity)
                              )}
                              className="progress-bar"
                            />
                          </Index.Box>
                          <Index.Typography className="trip-total-text">
                            {Number(item?.totalTankerQty).toFixed(3)}/
                            {Number(item?.lowerQuantity).toFixed(3)} MT
                          </Index.Typography>
                        </Index.Box>

                        <Index.Box
                          className="ordersdashboard-right-main"
                          sx={{ cursor: "pointer" }}
                          onClick={() => {
                            orderView(item?._id);
                          }}
                        >
                          <Index.Box className="dashboard-content-flex">
                            <Index.Typography className="dashboard-content-label">
                              Trader :
                            </Index.Typography>
                            <Index.Typography className="dashboard-content-data">
                              {item?.trader ? item?.trader : "-"}
                            </Index.Typography>
                          </Index.Box>
                          <Index.Box className="dashboard-content-flex dashboard-content-inner-main">
                            <Index.Box className="dashboard-content-inner-flex">
                              <Index.Typography className="dashboard-content-label">
                                Date :
                              </Index.Typography>
                              <Index.Typography className="dashboard-content-data">
                                {item?.loadingDate
                                  ? Index.moment(item?.loadingDate).format(
                                      "DD/MM/YYYY"
                                    )
                                  : "-"}
                              </Index.Typography>
                            </Index.Box>
                            <Index.Box className="dashboard-content-inner-flex">
                              <Index.Typography className="dashboard-content-label">
                                Items :
                              </Index.Typography>
                              <Index.Typography className="dashboard-content-data">
                                {item?.product ? item?.product : "-"}
                              </Index.Typography>
                            </Index.Box>
                          </Index.Box>
                          <Index.Box className="dashboard-content-flex">
                            <Index.Typography className="dashboard-content-label">
                              Vessel :
                            </Index.Typography>
                            <Index.Typography className="dashboard-content-data">
                              {item?.vesselName ? item?.vesselName : "-"}
                            </Index.Typography>
                          </Index.Box>
                          <Index.Box className="dashboard-content-flex">
                            <Index.Typography className="dashboard-content-label">
                              Terminal :
                            </Index.Typography>
                            <Index.Typography className="dashboard-content-data">
                              {item?.terminal ? item?.terminal : "-"}
                            </Index.Typography>
                          </Index.Box>
                          <Index.Box className="dashboard-content-flex">
                            <Index.Typography className="dashboard-content-label">
                              Barge Name :
                            </Index.Typography>
                            <Index.Typography className="dashboard-content-data">
                              {item?.proposedBarge?.length
                                ? item?.proposedBarge
                                  ? Array.prototype.map
                                      .call(
                                        item?.proposedBarge,
                                        function (ele) {
                                          return ele.bargeName;
                                        }
                                      )
                                      .join(",")
                                  : ""
                                : "-"}
                            </Index.Typography>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    ))
                  ) : (
                    <Index.Box className="record-notfound-main">
                      <Index.Typography className="record-notfound-text">
                        There are no orders today
                      </Index.Typography>
                    </Index.Box>
                  )}
                </Index.Box>
              </Index.Box>

              {/* Upcomming trip */}
              <Index.Box className="dashboard-colm">
                <Index.Typography className="dashboard-trip-title">
                  Upcomming Orders
                </Index.Typography>
                <Index.Box className="dashboard-card-scroll">
                  {upcomingTrips.length ? (
                    upcomingTrips?.map((item, index) => (
                      <Index.Box key={index} className="dashboard-card">
                        <Index.Box className="dashboard-left-main">
                          <Index.Box className="dashboard-image-box">
                            <img
                              src={Index.Png.cargoShip}
                              alt=""
                              className="dashboard-trip-icon"
                            />
                          </Index.Box>
                          <Index.Box className="progress-bar-main">
                            <BorderLinearProgress
                              variant="determinate"
                              // value={500}
                              value={calculatePercentage(
                                item?.totalTankerQty,
                                Number(item?.lowerQuantity)
                              )}
                              className="progress-bar"
                            />
                          </Index.Box>
                          <Index.Typography className="trip-total-text">
                            {Number(item?.totalTankerQty).toFixed(3)}/
                            {Number(item?.lowerQuantity).toFixed(3)} MT
                          </Index.Typography>
                        </Index.Box>

                        <Index.Box
                          className="ordersdashboard-right-main"
                          sx={{ cursor: "pointer" }}
                          onClick={() => {
                            orderView(item?._id);
                          }}
                        >
                          <Index.Box className="dashboard-content-flex">
                            <Index.Typography className="dashboard-content-label">
                              Trader :
                            </Index.Typography>
                            <Index.Typography className="dashboard-content-data">
                              {item?.trader ? item?.trader : "-"}
                            </Index.Typography>
                          </Index.Box>
                          <Index.Box className="dashboard-content-flex dashboard-content-inner-main">
                            <Index.Box className="dashboard-content-inner-flex">
                              <Index.Typography className="dashboard-content-label">
                                Date :
                              </Index.Typography>
                              <Index.Typography className="dashboard-content-data">
                                {item?.loadingDate
                                  ? Index.moment(item?.loadingDate).format(
                                      "DD/MM/YYYY"
                                    )
                                  : "-"}
                              </Index.Typography>
                            </Index.Box>
                            <Index.Box className="dashboard-content-inner-flex">
                              <Index.Typography className="dashboard-content-label">
                                Items :
                              </Index.Typography>
                              <Index.Typography className="dashboard-content-data">
                                {item?.product ? item?.product : "-"}
                              </Index.Typography>
                            </Index.Box>
                          </Index.Box>
                          <Index.Box className="dashboard-content-flex">
                            <Index.Typography className="dashboard-content-label">
                              Vessel :
                            </Index.Typography>
                            <Index.Typography className="dashboard-content-data">
                              {item?.vesselName ? item?.vesselName : "-"}
                            </Index.Typography>
                          </Index.Box>
                          <Index.Box className="dashboard-content-flex">
                            <Index.Typography className="dashboard-content-label">
                              Terminal :
                            </Index.Typography>
                            <Index.Typography className="dashboard-content-data">
                              {item?.terminal ? item?.terminal : "-"}
                            </Index.Typography>
                          </Index.Box>
                          <Index.Box className="dashboard-content-flex">
                            <Index.Typography className="dashboard-content-label">
                              Barge Name :
                            </Index.Typography>
                            <Index.Typography className="dashboard-content-data">
                              {item?.proposedBarge?.length
                                ? item?.proposedBarge
                                  ? Array.prototype.map
                                      .call(
                                        item?.proposedBarge,
                                        function (ele) {
                                          return ele.bargeName;
                                        }
                                      )
                                      .join(",")
                                  : ""
                                : "-"}
                            </Index.Typography>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    ))
                  ) : (
                    <Index.Box className="record-notfound-main">
                      <Index.Typography className="record-notfound-text">
                        There are no upcomming orders
                      </Index.Typography>
                    </Index.Box>
                  )}
                </Index.Box>
              </Index.Box>

              {/* Completed trip */}
              <Index.Box className="dashboard-colm">
                <Index.Typography className="dashboard-trip-title">
                  Completed Orders
                </Index.Typography>
                <Index.Box className="dashboard-card-scroll">
                  {completedTrips.length ? (
                    completedTrips?.map((item, index) => (
                      <Index.Box key={index} className="dashboard-card">
                        <Index.Box className="dashboard-left-main">
                          <Index.Box className="dashboard-image-box">
                            <img
                              src={Index.Png.cargoShip}
                              alt=""
                              className="dashboard-trip-icon"
                            />
                          </Index.Box>
                          <Index.Box className="progress-bar-main">
                            <BorderLinearProgress
                              variant="determinate"
                              // value={80}
                              value={calculatePercentage(
                                item?.totalTankerQty,
                                Number(item?.lowerQuantity)
                              )}
                              className="progress-bar"
                            />
                          </Index.Box>
                          <Index.Typography className="trip-total-text">
                            {Number(item?.totalTankerQty).toFixed(3)}/
                            {Number(item?.lowerQuantity).toFixed(3)} MT
                          </Index.Typography>
                        </Index.Box>

                        <Index.Box
                          className="ordersdashboard-right-main"
                          sx={{ cursor: "pointer" }}
                          onClick={() => {
                            orderView(item?._id);
                          }}
                        >
                          <Index.Box className="dashboard-content-flex">
                            <Index.Typography className="dashboard-content-label">
                              Trader :
                            </Index.Typography>
                            <Index.Typography className="dashboard-content-data">
                              {item?.trader ? item?.trader : "-"}
                            </Index.Typography>
                          </Index.Box>
                          <Index.Box className="dashboard-content-flex dashboard-content-inner-main">
                            <Index.Box className="dashboard-content-inner-flex">
                              <Index.Typography className="dashboard-content-label">
                                Date :
                              </Index.Typography>
                              <Index.Typography className="dashboard-content-data">
                                {item?.loadingDate
                                  ? Index.moment(item?.loadingDate).format(
                                      "DD/MM/YYYY"
                                    )
                                  : "-"}
                              </Index.Typography>
                            </Index.Box>
                            <Index.Box className="dashboard-content-inner-flex">
                              <Index.Typography className="dashboard-content-label">
                                Items :
                              </Index.Typography>
                              <Index.Typography className="dashboard-content-data">
                                {item?.product ? item?.product : "-"}
                              </Index.Typography>
                            </Index.Box>
                          </Index.Box>
                          <Index.Box className="dashboard-content-flex">
                            <Index.Typography className="dashboard-content-label">
                              Vessel :
                            </Index.Typography>
                            <Index.Typography className="dashboard-content-data">
                              {item?.vesselName ? item?.vesselName : "-"}
                            </Index.Typography>
                          </Index.Box>
                          <Index.Box className="dashboard-content-flex">
                            <Index.Typography className="dashboard-content-label">
                              Terminal :
                            </Index.Typography>
                            <Index.Typography className="dashboard-content-data">
                              {item?.terminal ? item?.terminal : "-"}
                            </Index.Typography>
                          </Index.Box>
                          <Index.Box className="dashboard-content-flex">
                            <Index.Typography className="dashboard-content-label">
                              Barge Name :
                            </Index.Typography>
                            <Index.Typography className="dashboard-content-data">
                              {item?.proposedBarge?.length
                                ? item?.proposedBarge
                                  ? Array.prototype.map
                                      .call(
                                        item?.proposedBarge,
                                        function (ele) {
                                          return ele.bargeName;
                                        }
                                      )
                                      .join(",")
                                  : ""
                                : "-"}
                            </Index.Typography>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    ))
                  ) : (
                    <Index.Box className="record-notfound-main">
                      <Index.Typography className="record-notfound-text">
                        There are no completed orders
                      </Index.Typography>
                    </Index.Box>
                  )}
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </div>
    </>
  );
};

export default TripDashboard;
