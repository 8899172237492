export const productData = [
  {
    id: 1,
    item: "VLSFO(0.5)",
  },
  {
    id: 2,
    item: "HSFO(3.5)",
  },
  {
    id: 3,
    item: "HSD",
  },
  {
    id: 4,
    item: "FW",
  },
];

export const SlopeSludgeProductData = [
  {
    id: 1,
    item: "Slope",
  },
  {
    id: 2,
    item: "Sludge",
  },
];
export const transportationData = [
  {
    id: 1,
    name: "PP",
  },
  {
    id: 2,
    name: "OTHER",
  },
  {
    id: 3,
    name: "PIPELINE",
  },
];

export const purposeOfTugs = [
  {
    id: 1,
    name: "PC DELIVERY",
  },
  {
    id: 2,
    name: "PHO BOARDING",
  },
  {
    id: 3,
    name: "CUSTOM BOARDING",
  },
  {
    id: 4,
    name: "SHIP STORE/PROVISSION",
  },
  {
    id: 5,
    name: "TECHNICIAN ONBOARD",
  },
  {
    id: 6,
    name: "SURVEYOR ONBOARD",
  },
  {
    id: 7,
    name: "TOWING",
  },
  {
    id: 8,
    name: "Other",
  },
];
