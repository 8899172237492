import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import dayjs from "dayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
// import Index from "../../../../Index";
// import { editWaterSupplyChallan } from "../../../../../redux/admin/action";
import Index from "../../../Index";
import { addEditPipeLineDetails } from "../../../../redux/admin/action";

//  style for model
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function EditOrderPipeLine() {
  let { state } = Index.useLocation();
  let editData = state?.row;
  const [image, setImage] = useState("");
  const [slopeChallanImage, setSlopeChallanImage] = useState("");

  const tabType = state?.type;
  const valueTabData = state?.value;
  let orderId = editData?.orderId;
  const dispatch = Index.useDispatch();
  const navigate = Index.useNavigate();
  const [loading, setLoading] = useState(false);
  const [challanImg, setChallanImg] = useState("");
  const [pdfFilesName, setPdfFilesName] = useState([]);

  //View Image Modal
  const [openImg, setOpenImg] = useState(false);
  const handleImgOpen = (img) => {
    setOpenImg(true);
    setChallanImg(img);
  };
  const handleImgClose = () => {
    setOpenImg(false);
    setChallanImg("");
  };

  let initialValues = {
    anchorsAweigh: "",
    alongsideAtOJ06: "",
    hoseConnect: "",
    loadingStart: "",
    loadingCompleted: "",
    loadingRate: "",
    loadedQty: "",
    castOff: "",
    dropAnchorage: "",
    bdnCopy: [],
    surveyorReachedOJ06: "",
    bargeAlongside: "",
    hoseConnection: "",
    pumpingStartFromTerminal: "",
    pumpingCompleted: "",
    bargeSounding: "",
    loadedQtySurveyor: "",
    bargeCastOff: "",
    bdnQty: "",
  };

  if (editData) {
    initialValues = editData;
  }
  useEffect(() => {
     if (editData?.bdnCopy?.length) {
       setSlopeChallanImage(editData?.bdnCopy);
     }
  },[editData])
   
  const handleFormSubmit = async (values) => {
    setLoading(true);
    const data = new FormData();
    if (values?.anchorsAweigh) {
      data.append(
        "anchorsAweigh",
        values?.anchorsAweigh?.toString()?.toLowerCase() == "invalid date"  || 
         !values?.anchorsAweigh
         ? "" :
        Index.moment(values?.anchorsAweigh).format(
          "YYYY-MM-DDTHH:mm:ssZZ"
        )
      );
    }
    if (values?.alongsideAtOJ06) {
      data.append(
        "alongsideAtOJ06",
        values?.alongsideAtOJ06?.toString()?.toLowerCase() == "invalid date" ||
          !values?.alongsideAtOJ06
          ? ""
          : Index.moment(values?.alongsideAtOJ06).format(
              "YYYY-MM-DDTHH:mm:ssZZ"
            )
      );
    }
    if (values?.hoseConnect) {
      data.append(
        "hoseConnect",
        values?.hoseConnect?.toString()?.toLowerCase() == "invalid date" ||
          !values?.hoseConnect
          ? ""
          : Index.moment(values?.hoseConnect).format(
              "YYYY-MM-DDTHH:mm:ssZZ"
            )
      );
    }
    if (values?.loadingStart) {
      data.append(
        "loadingStart",
        values?.loadingStart?.toString()?.toLowerCase() == "invalid date" ||
          !values?.loadingStart
          ? ""
          : Index.moment(values?.loadingStart).format(
              "YYYY-MM-DDTHH:mm:ssZZ"
            )
      );
    }
    if (values?.loadingCompleted) {
      data.append(
        "loadingCompleted",
        values?.loadingCompleted?.toString()?.toLowerCase() ==
          "invalid date" || !values?.loadingCompleted
          ? ""
          : Index.moment(values?.loadingCompleted).format(
              "YYYY-MM-DDTHH:mm:ssZZ"
            )
      );
    }
  
    if (values?.loadingRate) {
      data.append("loadingRate", values?.loadingRate);
    }
    if (values?.loadedQty) {
      data.append("loadedQty", values?.loadedQty);
    }
    if (values?.castOff) {
      data.append(
        "castOff",
        values?.castOff?.toString()?.toLowerCase() == "invalid date" ||
          !values?.castOff
          ? ""
          : Index.moment(values?.castOff).format(
              "YYYY-MM-DDTHH:mm:ssZZ"
            )
      );
    }
    if (values?.dropAnchorage) {
      data.append(
        "dropAnchorage",
        values?.dropAnchorage?.toString()?.toLowerCase() == "invalid date" ||
          !values?.dropAnchorage
          ? ""
          : Index.moment(values?.dropAnchorage).format(
              "YYYY-MM-DDTHH:mm:ssZZ"
            )
      );
    }

    if (pdfFilesName?.length > 0) {
      values.bdnCopy.forEach((ele, i) => {
        data.append("bdnCopy", ele);
      });
    }

    if (values?.craftSpeed) {
      data.append("craftSpeed", values?.craftSpeed);
    }
    if (values?.surveyorReachedOJ06) {
      data.append(
        "surveyorReachedOJ06",
        values?.surveyorReachedOJ06?.toString()?.toLowerCase() ==
          "invalid date" || !values?.surveyorReachedOJ06
          ? ""
          : Index.moment(
              values?.surveyorReachedOJ06
            ).format("YYYY-MM-DDTHH:mm:ssZZ")
      );
    }
    if (values?.bargeAlongside) {
      data.append(
        "bargeAlongside",
        values?.bargeAlongside?.toString()?.toLowerCase() == "invalid date" ||
          !values?.bargeAlongside
          ? ""
          : Index.moment(values?.bargeAlongside).format(
              "YYYY-MM-DDTHH:mm:ssZZ"
            )
      );
    }
    if (values?.hoseConnection) {
      data.append(
        "hoseConnection",
        values?.hoseConnection?.toString()?.toLowerCase() == "invalid date" ||
          !values?.hoseConnection
          ? ""
          : Index.moment(values?.hoseConnection).format(
              "YYYY-MM-DDTHH:mm:ssZZ"
            )
      );
    }
    if (values?.pumpingStartFromTerminal) {
      data.append(
        "pumpingStartFromTerminal",
        values?.pumpingStartFromTerminal?.toString()?.toLowerCase() ==
          "invalid date" || !values?.pumpingStartFromTerminal
          ? ""
          : Index.moment(
              values?.pumpingStartFromTerminal
            ).format("YYYY-MM-DDTHH:mm:ssZZ")
      );
    }
    if (values?.pumpingCompleted) {
      data.append(
        "pumpingCompleted",
        values?.pumpingCompleted?.toString()?.toLowerCase() ==
          "invalid date" || !values?.pumpingCompleted
          ? ""
          : Index.moment(values?.pumpingCompleted).format(
              "YYYY-MM-DDTHH:mm:ssZZ"
            )
      );
    }

    if (values?.bargeSounding) {
      data.append("bargeSounding", values?.bargeSounding);
    }
    if (values?.loadedQtySurveyor) {
      data.append("loadedQtySurveyor", values?.loadedQtySurveyor);
    }
    if (values?.bargeCastOff) {
      data.append(
        "bargeCastOff",
        values?.bargeCastOff?.toString()?.toLowerCase() == "invalid date" ||
          !values?.bargeCastOff
          ? ""
          : Index.moment(values?.bargeCastOff).format(
              "YYYY-MM-DDTHH:mm:ssZZ"
            )
      );
    }
    if (values?.bdnQty) {
      data.append("bdnQty", values?.bdnQty);
    }

    data.append("id", editData?._id);

    dispatch(addEditPipeLineDetails(data)).then((res) => {
      if (res?.status === 200) {
        navigate(`/admin/order/view-order/${orderId}`, { state: { value: 5 } });
      } else {
        setLoading(false);
        
      }
    });
  };
 useEffect(() => {
   if (pdfFilesName) {
     setSlopeChallanImage(pdfFilesName);
   }
 }, [pdfFilesName]);
  const backBtn = () => {
    if (orderId) {
      navigate(`/admin/order/view-order/${orderId}`, {
        state: { value: 5 },
      });
  }
  
  };

  return (
    <>
      <Index.Box className="">
        <Index.Box className="barge-common-box">
          <Index.Box className="title-header">
            <Index.Box className="title-header-flex">
              <Index.Box className="title-main mb-10">
                <Index.Typography
                  variant="p"
                  component="p"
                  className="page-title"
                >
                  Edit Pipe Line Details
                </Index.Typography>
              </Index.Box>
              <Index.Box className="common-button blue-button save-btn ">
                <Index.Button
                  variant="contained"
                  type="submit"
                  className="Particular_model_submit ml-0"
                  onClick={backBtn}
                >
                  Back
                </Index.Button>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            onSubmit={handleFormSubmit}
          >
            {({
              values,
              errors,
              touched,
              setFieldValue,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldError,
            }) => (
              <form onSubmit={handleSubmit}>
                <Index.Box className="">
                  <Index.Grid container spacing={2}>
                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Anchor Aweigh
                      </Index.Typography>
                      <Index.Box className="input-design-div">
                        <Index.Box className="set-date set-date-picker">
                          <Index.LocalizationProvider
                            dateAdapter={Index.AdapterDayjs}
                          >
                            <Index.DemoContainer
                              components={["Index.DateTimePicker"]}
                            >
                              <DateTimePicker
                                disableFuture
                                timeSteps={{
                                  hours: 1,
                                  minutes: 1,
                                }}
                                defaultValue={
                                  editData
                                    ? Index.dayjs(
                                        editData?.anchorsAweigh
                                          ? Index.moment(
                                              editData?.anchorsAweigh
                                            ).format("YYYY/MM/DD HH:mm")
                                          : "-"
                                      )
                                    : ""
                                }
                                onChange={(value) => {
                                  setFieldValue("anchorsAweigh", value.$d);
                                }}
                                slotProps={{
                                  textField: {
                                    readOnly: true,
                                  },
                                }}
                                format="DD/MM/YYYY HH:mm"
                              />
                            </Index.DemoContainer>
                          </Index.LocalizationProvider>
                        </Index.Box>
                      </Index.Box>
                    </Index.Grid>

                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Alongside AT OJ 06
                      </Index.Typography>
                      <Index.Box className="input-design-div">
                        <Index.Box className="set-date set-date-picker">
                          <Index.LocalizationProvider
                            dateAdapter={Index.AdapterDayjs}
                          >
                            <Index.DemoContainer
                              components={["Index.DateTimePicker"]}
                            >
                              <DateTimePicker
                                disableFuture
                                timeSteps={{
                                  hours: 1,
                                  minutes: 1,
                                }}
                                defaultValue={
                                  editData
                                    ? Index.dayjs(
                                        editData?.alongsideAtOJ06
                                          ? Index.moment(
                                              editData?.alongsideAtOJ06
                                            ).format("YYYY/MM/DD HH:mm")
                                          : "-"
                                      )
                                    : ""
                                }
                                onChange={(value) => {
                                  setFieldValue("alongsideAtOJ06", value.$d);
                                }}
                                slotProps={{
                                  textField: {
                                    readOnly: true,
                                  },
                                }}
                                format="DD/MM/YYYY HH:mm"
                              />
                            </Index.DemoContainer>
                          </Index.LocalizationProvider>
                        </Index.Box>
                      </Index.Box>
                    </Index.Grid>

                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Hose Connect
                      </Index.Typography>
                      <Index.Box className="input-design-div">
                        <Index.Box className="set-date set-date-picker">
                          <Index.LocalizationProvider
                            dateAdapter={Index.AdapterDayjs}
                          >
                            <Index.DemoContainer
                              components={["Index.DateTimePicker"]}
                            >
                              <DateTimePicker
                                disableFuture
                                timeSteps={{
                                  hours: 1,
                                  minutes: 1,
                                }}
                                defaultValue={
                                  editData
                                    ? Index.dayjs(
                                        editData?.hoseConnect
                                          ? Index.moment(
                                              editData?.hoseConnect
                                            ).format("YYYY/MM/DD HH:mm")
                                          : "-"
                                      )
                                    : ""
                                }
                                onChange={(value) => {
                                  setFieldValue("hoseConnect", value.$d);
                                }}
                                slotProps={{
                                  textField: {
                                    readOnly: true,
                                  },
                                }}
                                format="DD/MM/YYYY HH:mm"
                              />
                            </Index.DemoContainer>
                          </Index.LocalizationProvider>
                        </Index.Box>
                      </Index.Box>
                    </Index.Grid>

                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Loading Start
                      </Index.Typography>
                      <Index.Box className="input-design-div">
                        <Index.Box className="set-date set-date-picker">
                          <Index.LocalizationProvider
                            dateAdapter={Index.AdapterDayjs}
                          >
                            <Index.DemoContainer
                              components={["Index.DateTimePicker"]}
                            >
                              <DateTimePicker
                                disableFuture
                                timeSteps={{
                                  hours: 1,
                                  minutes: 1,
                                }}
                                defaultValue={
                                  editData
                                    ? Index.dayjs(
                                        editData?.loadingStart
                                          ? Index.moment(
                                              editData?.loadingStart
                                            ).format("YYYY/MM/DD HH:mm")
                                          : "-"
                                      )
                                    : ""
                                }
                                onChange={(value) => {
                                  setFieldValue("loadingStart", value.$d);
                                }}
                                slotProps={{
                                  textField: {
                                    readOnly: true,
                                  },
                                }}
                                format="DD/MM/YYYY HH:mm"
                              />
                            </Index.DemoContainer>
                          </Index.LocalizationProvider>
                        </Index.Box>
                      </Index.Box>
                    </Index.Grid>

                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Loading Completed
                      </Index.Typography>
                      <Index.Box className="input-design-div">
                        <Index.Box className="set-date set-date-picker">
                          <Index.LocalizationProvider
                            dateAdapter={Index.AdapterDayjs}
                          >
                            <Index.DemoContainer
                              components={["Index.DateTimePicker"]}
                            >
                              <DateTimePicker
                                disableFuture
                                timeSteps={{
                                  hours: 1,
                                  minutes: 1,
                                }}
                                defaultValue={
                                  editData
                                    ? Index.dayjs(
                                        editData?.loadingCompleted
                                          ? Index.moment(
                                              editData?.loadingCompleted
                                            ).format("YYYY/MM/DD HH:mm")
                                          : "-"
                                      )
                                    : ""
                                }
                                onChange={(value) => {
                                  setFieldValue("loadingCompleted", value.$d);
                                }}
                                slotProps={{
                                  textField: {
                                    readOnly: true,
                                  },
                                }}
                                format="DD/MM/YYYY HH:mm"
                              />
                            </Index.DemoContainer>
                          </Index.LocalizationProvider>
                        </Index.Box>
                      </Index.Box>
                    </Index.Grid>

                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Loading Rate
                      </Index.Typography>
                      <Index.Box className="input-design-div with-border">
                        <Index.TextField
                          hiddenLabel
                          // type="number"
                          id="filled-hidden-label-normal"
                          placeholder="Loading Rate"
                          variant="filled"
                          className="admin-input-design input-placeholder"
                          name="loadingRate"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values?.loadingRate}
                        />
                      </Index.Box>
                    </Index.Grid>

                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Loaded Quantity
                      </Index.Typography>
                      <Index.Box className="input-design-div with-border">
                        <Index.TextField
                          hiddenLabel
                          // type="number"
                          id="filled-hidden-label-normal"
                          placeholder="Loaded Quantity"
                          variant="filled"
                          className="admin-input-design input-placeholder"
                          name="loadedQty"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values?.loadedQty}
                        />
                      </Index.Box>
                    </Index.Grid>

                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Cast Off
                      </Index.Typography>
                      <Index.Box className="input-design-div">
                        <Index.Box className="set-date set-date-picker">
                          <Index.LocalizationProvider
                            dateAdapter={Index.AdapterDayjs}
                          >
                            <Index.DemoContainer
                              components={["Index.DateTimePicker"]}
                            >
                              <DateTimePicker
                                disableFuture
                                timeSteps={{
                                  hours: 1,
                                  minutes: 1,
                                }}
                                defaultValue={
                                  editData
                                    ? Index.dayjs(
                                        editData?.castOff
                                          ? Index.moment(
                                              editData?.castOff
                                            ).format("YYYY/MM/DD HH:mm")
                                          : "-"
                                      )
                                    : ""
                                }
                                onChange={(value) => {
                                  setFieldValue("castOff", value.$d);
                                }}
                                slotProps={{
                                  textField: {
                                    readOnly: true,
                                  },
                                }}
                                format="DD/MM/YYYY HH:mm"
                              />
                            </Index.DemoContainer>
                          </Index.LocalizationProvider>
                        </Index.Box>
                      </Index.Box>
                    </Index.Grid>

                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Drop Anchorage
                      </Index.Typography>
                      <Index.Box className="input-design-div">
                        <Index.Box className="set-date set-date-picker">
                          <Index.LocalizationProvider
                            dateAdapter={Index.AdapterDayjs}
                          >
                            <Index.DemoContainer
                              components={["Index.DateTimePicker"]}
                            >
                              <DateTimePicker
                                disableFuture
                                timeSteps={{
                                  hours: 1,
                                  minutes: 1,
                                }}
                                defaultValue={
                                  editData
                                    ? Index.dayjs(
                                        editData?.dropAnchorage
                                          ? Index.moment(
                                              editData?.dropAnchorage
                                            ).format("YYYY/MM/DD HH:mm")
                                          : "-"
                                      )
                                    : ""
                                }
                                onChange={(value) => {
                                  const formatedDate = Index.moment(
                                    value.$d
                                  ).format("DD/MM/YYYY HH:mm");
                                  setFieldValue("dropAnchorage", value.$d);
                                }}
                                slotProps={{
                                  textField: {
                                    readOnly: true,
                                  },
                                }}
                                format="DD/MM/YYYY HH:mm"
                              />
                            </Index.DemoContainer>
                          </Index.LocalizationProvider>
                        </Index.Box>
                      </Index.Box>
                    </Index.Grid>

                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Surveyor Reached OJ 06
                      </Index.Typography>
                      <Index.Box className="input-design-div">
                        <Index.Box className="set-date set-date-picker">
                          <Index.LocalizationProvider
                            dateAdapter={Index.AdapterDayjs}
                          >
                            <Index.DemoContainer
                              components={["Index.DateTimePicker"]}
                            >
                              <DateTimePicker
                                disableFuture
                                timeSteps={{
                                  hours: 1,
                                  minutes: 1,
                                }}
                                // defaultValue={
                                //   editData
                                //     ? Index.dayjs(
                                //         editData?.surveyorReachedOJ06
                                //           ? editData?.surveyorReachedOJ06
                                //           : "-"
                                //       )
                                //     : ""
                                // }
                                defaultValue={
                                  editData
                                    ? Index.dayjs(
                                        editData?.surveyorReachedOJ06
                                          ? Index.moment(
                                              editData?.surveyorReachedOJ06
                                            ).format("YYYY/MM/DD HH:mm")
                                          : "-"
                                      )
                                    : ""
                                }
                                onChange={(value) => {
                                  setFieldValue(
                                    "surveyorReachedOJ06",
                                    value.$d
                                  );
                                }}
                                slotProps={{
                                  textField: {
                                    readOnly: true,
                                  },
                                }}
                                format="DD/MM/YYYY HH:mm"
                              />
                            </Index.DemoContainer>
                          </Index.LocalizationProvider>
                        </Index.Box>
                      </Index.Box>
                    </Index.Grid>

                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Barge Alongside
                      </Index.Typography>
                      <Index.Box className="input-design-div">
                        <Index.Box className="set-date set-date-picker">
                          <Index.LocalizationProvider
                            dateAdapter={Index.AdapterDayjs}
                          >
                            <Index.DemoContainer
                              components={["Index.DateTimePicker"]}
                            >
                              <DateTimePicker
                                disableFuture
                                timeSteps={{
                                  hours: 1,
                                  minutes: 1,
                                }}
                                defaultValue={
                                  editData
                                    ? Index.dayjs(
                                        editData?.bargeAlongside
                                          ? Index.moment(
                                              editData?.bargeAlongside
                                            ).format("YYYY/MM/DD HH:mm")
                                          : "-"
                                      )
                                    : ""
                                }
                                onChange={(value) => {
                                  setFieldValue("bargeAlongside", value.$d);
                                }}
                                slotProps={{
                                  textField: {
                                    readOnly: true,
                                  },
                                }}
                                format="DD/MM/YYYY HH:mm"
                              />
                            </Index.DemoContainer>
                          </Index.LocalizationProvider>
                        </Index.Box>
                      </Index.Box>
                    </Index.Grid>

                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Hose Connection
                      </Index.Typography>
                      <Index.Box className="input-design-div">
                        <Index.Box className="set-date set-date-picker">
                          <Index.LocalizationProvider
                            dateAdapter={Index.AdapterDayjs}
                          >
                            <Index.DemoContainer
                              components={["Index.DateTimePicker"]}
                            >
                              <DateTimePicker
                                disableFuture
                                timeSteps={{
                                  hours: 1,
                                  minutes: 1,
                                }}
                                defaultValue={
                                  editData
                                    ? Index.dayjs(
                                        editData?.hoseConnection
                                          ? Index.moment(
                                              editData?.hoseConnection
                                            ).format("YYYY/MM/DD HH:mm")
                                          : "-"
                                      )
                                    : ""
                                }
                                onChange={(value) => {
                                  setFieldValue("hoseConnection", value.$d);
                                }}
                                slotProps={{
                                  textField: {
                                    readOnly: true,
                                  },
                                }}
                                format="DD/MM/YYYY HH:mm"
                              />
                            </Index.DemoContainer>
                          </Index.LocalizationProvider>
                        </Index.Box>
                      </Index.Box>
                    </Index.Grid>

                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Pumping Start From Terminal
                      </Index.Typography>
                      <Index.Box className="input-design-div">
                        <Index.Box className="set-date set-date-picker">
                          <Index.LocalizationProvider
                            dateAdapter={Index.AdapterDayjs}
                          >
                            <Index.DemoContainer
                              components={["Index.DateTimePicker"]}
                            >
                              <DateTimePicker
                                disableFuture
                                timeSteps={{
                                  hours: 1,
                                  minutes: 1,
                                }}
                                defaultValue={
                                  editData
                                    ? Index.dayjs(
                                        editData?.pumpingStartFromTerminal
                                          ? Index.moment(
                                              editData?.pumpingStartFromTerminal
                                            ).format("YYYY/MM/DD HH:mm")
                                          : "-"
                                      )
                                    : ""
                                }
                                onChange={(value) => {
                                  setFieldValue(
                                    "pumpingStartFromTerminal",
                                    value.$d
                                  );
                                }}
                                slotProps={{
                                  textField: {
                                    readOnly: true,
                                  },
                                }}
                                format="DD/MM/YYYY HH:mm"
                              />
                            </Index.DemoContainer>
                          </Index.LocalizationProvider>
                        </Index.Box>
                      </Index.Box>
                    </Index.Grid>

                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Pumping Completed
                      </Index.Typography>
                      <Index.Box className="input-design-div">
                        <Index.Box className="set-date set-date-picker">
                          <Index.LocalizationProvider
                            dateAdapter={Index.AdapterDayjs}
                          >
                            <Index.DemoContainer
                              components={["Index.DateTimePicker"]}
                            >
                              <DateTimePicker
                                disableFuture
                                timeSteps={{
                                  hours: 1,
                                  minutes: 1,
                                }}
                                defaultValue={
                                  editData
                                    ? Index.dayjs(
                                        editData?.pumpingCompleted
                                          ? Index.moment(
                                              editData?.pumpingCompleted
                                            ).format("YYYY/MM/DD HH:mm")
                                          : "-"
                                      )
                                    : ""
                                }
                                onChange={(value) => {
                                  setFieldValue("pumpingCompleted", value.$d);
                                }}
                                slotProps={{
                                  textField: {
                                    readOnly: true,
                                  },
                                }}
                                format="DD/MM/YYYY HH:mm"
                              />
                            </Index.DemoContainer>
                          </Index.LocalizationProvider>
                        </Index.Box>
                      </Index.Box>
                    </Index.Grid>
                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Barge Sounding
                      </Index.Typography>
                      <Index.Box className="input-design-div with-border">
                        <Index.TextField
                          hiddenLabel
                          // type="number"
                          id="filled-hidden-label-normal"
                          placeholder="Barge Sounding"
                          variant="filled"
                          className="admin-input-design input-placeholder"
                          name="bargeSounding"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values?.bargeSounding}
                        />
                      </Index.Box>
                    </Index.Grid>
                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Loaded Quantity
                      </Index.Typography>
                      <Index.Box className="input-design-div with-border">
                        <Index.TextField
                          hiddenLabel
                          // type="number"
                          id="filled-hidden-label-normal"
                          placeholder="Loaded Quantity"
                          variant="filled"
                          className="admin-input-design input-placeholder"
                          name="loadedQtySurveyor"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values?.loadedQtySurveyor}
                        />
                      </Index.Box>
                    </Index.Grid>
                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Barge Cast Off
                      </Index.Typography>
                      <Index.Box className="input-design-div">
                        <Index.Box className="set-date set-date-picker">
                          <Index.LocalizationProvider
                            dateAdapter={Index.AdapterDayjs}
                          >
                            <Index.DemoContainer
                              components={["Index.DateTimePicker"]}
                            >
                              <DateTimePicker
                                disableFuture
                                timeSteps={{
                                  hours: 1,
                                  minutes: 1,
                                }}
                                defaultValue={
                                  editData
                                    ? Index.dayjs(
                                        editData?.bargeCastOff
                                          ? Index.moment(
                                              editData?.bargeCastOff
                                            ).format("YYYY/MM/DD HH:mm")
                                          : "-"
                                      )
                                    : ""
                                }
                                onChange={(value) => {
                                  const formatedDate = Index.moment(
                                    value.$d
                                  ).format("DD/MM/YYYY HH:mm");
                                  setFieldValue("bargeCastOff", value.$d);
                                }}
                                slotProps={{
                                  textField: {
                                    readOnly: true,
                                  },
                                }}
                                format="DD/MM/YYYY HH:mm"
                              />
                            </Index.DemoContainer>
                          </Index.LocalizationProvider>
                        </Index.Box>
                      </Index.Box>
                    </Index.Grid>

                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        BDN Quantity
                      </Index.Typography>
                      <Index.Box className="input-design-div with-border">
                        <Index.TextField
                          hiddenLabel
                          type="number"
                          id="filled-hidden-label-normal"
                          placeholder="BDN Quantity"
                          variant="filled"
                          className="admin-input-design input-placeholder"
                          name="bdnQty"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values?.bdnQty}
                        />
                      </Index.Box>
                    </Index.Grid>

                    {/* <Index.Grid item xs={12} sm={6} md={3}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        BDN Copy
                      </Index.Typography>
                      <Index.Box className="input-design-div with-border">
                        <Index.Grid container spacing={2}>
                          <Index.Grid item xs={6} sm={6} md={8}>
                            <Index.Box className="input-design-div with-border position-relative">
                              <Index.Box className="pdf-design">
                                Upload File
                              </Index.Box>
                              <Index.TextField
                                fullWidth
                                type="file"
                                name="bdnCopy"
                                id="filled-hidden-label-normal"
                                variant="filled"
                                className="admin-input-design input-placeholder pdf-upload-design"
                                onChange={(event) => {
                                  setImage(event.currentTarget.files[0]);
                                  setFieldValue(
                                    "bdnCopy",
                                    event.currentTarget.files[0]
                                  );
                                }}
                                inputProps={{
                                  className: "input_props",
                                }}
                                FormHelperTextProps={{
                                  className: "input_label_props",
                                }}
                              />
                            </Index.Box>
                          </Index.Grid>

                          <Index.Grid item xs={6} sm={6} md={4}>
                            <Index.Box className="user-img-main">
                              <img
                                src={
                                  image
                                    ? URL.createObjectURL(image)
                                    : editData?.bdnCopy
                                    ? `${process.env.REACT_APP_IMAGE_URL}/pipelineDoc/${editData?.bdnCopy}`
                                    : ""
                                }
                                alt=""
                                className="user-img"
                              />
                            </Index.Box>
                          </Index.Grid>
                        </Index.Grid>
                      </Index.Box>
                    </Index.Grid> */}
                    <Index.Grid item xs={12} sm={6} md={6}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        BDN Copy
                      </Index.Typography>
                      <Index.Box className="input-design-div with-border position-relative">
                        <Index.Box className="pdf-design">
                          Upload PDF :
                        </Index.Box>
                        <Index.TextField
                          fullWidth
                          type="file"
                          name="bdnCopy"
                          multiple
                          id="filled-hidden-label-normal"
                          variant="filled"
                          // onFocus={handleFocus}
                          onBlur={handleBlur}
                          className="admin-input-design input-placeholder pdf-upload-design"
                          onChange={(event) => {
                            const files = Array.from(event.target.files);
                            setFieldValue("bdnCopy", [...files]);
                            const fileNames = files.map((item) => item.name);
                            // console.log(files, 'files', fileNames);
                            setPdfFilesName(fileNames);
                          }}
                          inputProps={{
                            multiple: true,
                          }}
                          FormHelperTextProps={{
                            className: "input_label_props",
                          }}
                        />
                        {touched?.bdnCopy && errors?.bdnCopy && (
                          <Index.FormHelperText error>
                            {errors?.bdnCopy}
                          </Index.FormHelperText>
                        )}
                        <br />
                        {slopeChallanImage?.length > 0 &&
                          slopeChallanImage?.map((item, i) => {
                            return (
                              <>
                                <Index.Typography
                                  key={i}
                                  variant="label"
                                  component="label"
                                  className="input-label"
                                >
                                  {i + 1}. {item}
                                </Index.Typography>
                              </>
                            );
                          })}
                      </Index.Box>
                    </Index.Grid>
                  </Index.Grid>
                </Index.Box>
                <Index.Box className="common-button blue-button flex-end save-btn inquiry-submit-btn">
                  <Index.Button variant="contained" type="submit">
                    {loading ? (
                      <Index.CircularProgress color="secondary" size={20} />
                    ) : editData ? (
                      "Update"
                    ) : (
                      "Submit"
                    )}
                  </Index.Button>
                </Index.Box>
              </form>
            )}
          </Formik>
        </Index.Box>
      </Index.Box>
    </>
  );
}

export default EditOrderPipeLine;
