import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import { Formik } from "formik";
import {
  addBreakdown,
  addBreakdownRevoke,
  getAllBreakdowns,
  getAllTankersForBreakdown,
} from "../../../../redux/admin/action";
import {
  addBreakDownRevokeValidationSchema,
  addBreakDownValidationSchema,
} from "../../../../validation/AdminValidation";

const Search = Index.styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: Index.alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: Index.alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const StyledInputBase = Index.styled(Index.InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

function BreakDownList() {
  const expenseDetails = "";
  const navigate = Index.useNavigate();
  const dispatch = Index.useDispatch();
  const [breakDownList, setBreakDownList] = useState([]);
  const [tankersList, setTankersList] = useState([]);
  const [remarkData, setRemarkData] = useState({
    remarkType: "",
    remark: "",
  });
  const [breakDownId, setBreakDownId] = useState("");
  const [openModel, setOpenModel] = useState(false);
  const [openModelRevoke, setOpenModelRevoke] = useState(false);
  const [openModelRemark, setOpenModelRemark] = useState(false);

  // State for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState(0);
  const [paginationPerPage, setPaginationPerPage] = useState(50);

  // State for searching and set data
  const [searchedData, setSearchedData] = useState([]);
  const [currentRows, setCurrentRows] = useState([]);

  // state for loading
  const [loading, setLoading] = useState(false);

  //Model
  //Add BreakDown
  const handleModelOpen = () => {
    setOpenModel(true);
  };
  const handleModelClose = () => {
    setOpenModel(false);
  };
  //Revoke
  const handleModelRevokeOpen = () => {
    setOpenModelRevoke(true);
  };
  const handleModelRevokeClose = () => {
    setOpenModelRevoke(false);
    setBreakDownId("");
  };
  //Remark text
  const handleModelRemarkOpen = () => {
    setOpenModelRemark(true);
  };
  const handleModelRemarkClose = () => {
    setOpenModelRemark(false);
    setRemarkData({
      remarkType: "",
      remark: "",
    });
  };

  // Copy original data to searched data
  useEffect(() => {
    setSearchedData(breakDownList);
  }, [breakDownList]);

  // Search on table
  const requestSearch = (searched) => {
    if (!searched) return setSearchedData(breakDownList);
    setSearchedData(
      breakDownList?.filter(
        (item) =>
          item?.tankerId?.tankerName
            ?.toString()
            .toLowerCase()
            .includes(searched?.toLowerCase()) ||
          item?.tankerId?.tankerNumber
            ?.toString()
            .toLowerCase()
            .includes(searched?.toLowerCase()) ||
          item?.tankerId?.capacity
            ?.toString()
            .toLowerCase()
            .includes(searched?.toLowerCase()) ||
          Index.moment(item?.createdAt)
            .format("DD/MM/YYYY")
            ?.toString()
            .toLowerCase()
            .includes(searched?.toLowerCase()) ||
          item?.breakdownRemark
            ?.toString()
            .toLowerCase()
            .includes(searched?.toLowerCase()) ||
          Index.moment(item?.revokedDate)
            .format("DD/MM/YYYY")
            ?.toString()
            .toLowerCase()
            .includes(searched?.toLowerCase()) ||
          item?.revokeRemark
            ?.toString()
            .toLowerCase()
            .includes(searched?.toLowerCase())
      )
    );
    setCurrentPage(1);
  };

  // Pagination calculation start
  const PER_PAGE = paginationPerPage;
  useEffect(() => {
    //Page count
    const count = Math.ceil(searchedData?.length / PER_PAGE);
    setCount(count);
    const indexOfLastRow = currentPage * PER_PAGE;
    const indexOfFirstRow = indexOfLastRow - PER_PAGE;
    const modifiedRows = searchedData?.slice(indexOfFirstRow, indexOfLastRow);
    const serialNumber = (currentPage - 1) * PER_PAGE + 1;
    setCurrentRows(
      modifiedRows &&
        modifiedRows.map((ele, index) => {
          return { ...ele, sNo: serialNumber + index };
        })
    );
    if (modifiedRows?.length === 0 && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  }, [currentPage, searchedData, paginationPerPage, PER_PAGE]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const initialValues = {
    tankerId: "",
    breakdownRemark: "",
  };
  const initialValuesRevoke = {
    revokeRemark: "",
  };

  const handleAddBreakdownSubmit = (values) => {
    const urlencoded = new URLSearchParams();
    urlencoded.append("tankerId", values?.tankerId);
    urlencoded.append("breakdownRemark", values?.breakdownRemark);
    dispatch(addBreakdown(urlencoded)).then((res) => {
      if (res?.status == 200) {
        handleModelClose();
        fetchAllBreakDowns();
      }
    });
  };
  const handleAddBreakdownRevokeSubmit = (values, {}) => {
    const urlencoded = new URLSearchParams();
    urlencoded.append("tankerBreakdownId", breakDownId);
    urlencoded.append("revokeRemark", values?.revokeRemark);
    dispatch(addBreakdownRevoke(urlencoded)).then((res) => {
      if (res?.status == 200) {
        handleModelRevokeClose();
        fetchAllBreakDowns();
      } else {
        handleModelRevokeClose();
      }
    });
  };

  //get all tankers
  const fetchAllTankers = () => {
    try {
      dispatch(getAllTankersForBreakdown()).then((res) => {
        if (res?.status == 200) {
          setTankersList(res?.data);
        } else {
          setTankersList([]);
        }
      });
    } catch (error) {}
  };
  //get all breakDown
  const fetchAllBreakDowns = () => {
    setLoading(true);
    try {
      dispatch(getAllBreakdowns()).then((res) => {
        if (res?.status == 200) {
          setBreakDownList(res?.data);
          setTimeout(() => {
            setLoading(false);
          }, 1000);
        } else {
          setBreakDownList([]);
          setTimeout(() => {
            setLoading(false);
          }, 1000);
        }
      });
    } catch (error) {}
  };

  useEffect(() => {
    fetchAllTankers();
    fetchAllBreakDowns();
  }, []);

  return (
    <>
      <Index.DynamicTitle title="Break Down" />
      <Index.Box className="">
        <Index.Box className="barge-common-box">
          <Index.Box className="title-header">
            <Index.Box className="title-header-flex res-title-header-flex">
              <Index.Box className="title-main">
                <Index.Typography
                  variant="p"
                  component="p"
                  className="page-title"
                >
                  Tanker Breakdown Management
                </Index.Typography>
              </Index.Box>
              <Index.Box className="d-flex align-items-center res-set-search">
                <Search className="search ">
                  <StyledInputBase
                    placeholder="Search"
                    inputProps={{ "aria-label": "search" }}
                    onChange={(e) =>
                      setTimeout(() => {
                        requestSearch(e.target.value.trim());
                      }, 1000)
                    }
                  />
                </Search>
                <Index.Box className="common-button blue-button res-blue-button">
                  <Index.RouteLink
                    // to="/admin/invoice/add-invoice"
                    className="no-text-decoration"
                  >
                    {" "}
                    <Index.Button
                      variant="contained"
                      disableRipple
                      onClick={handleModelOpen}
                    >
                      Add
                    </Index.Button>
                  </Index.RouteLink>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Index.Box className="">
            <Index.TableContainer
              component={Index.Paper}
              className="table-container"
            >
              <Index.Table
                aria-label="simple table"
                className="table-design-main supply-table one-line-table expense-table"
              >
                <Index.TableHead>
                  <Index.TableRow>
                    <Index.TableCell>S. No</Index.TableCell>
                    <Index.TableCell align="left">
                      Tanker Number
                    </Index.TableCell>
                    <Index.TableCell align="left">
                      In Breakdown Added By
                    </Index.TableCell>
                    <Index.TableCell align="left">
                      BreakDown Date
                    </Index.TableCell>
                    <Index.TableCell align="left">
                      BreakDown Remark
                    </Index.TableCell>
                    <Index.TableCell align="left">Revoked By</Index.TableCell>
                    <Index.TableCell align="left">Revoke Date</Index.TableCell>
                    <Index.TableCell align="left">
                      Revoke Remark
                    </Index.TableCell>
                    <Index.TableCell align="left">Action</Index.TableCell>
                  </Index.TableRow>
                </Index.TableHead>
                {loading ? (
                  <Index.TableBody>
                    <Index.TableRow>
                      <Index.TableCell colSpan={11} align="center">
                        <Index.CircularProgress color="secondary" size={20} />
                      </Index.TableCell>
                    </Index.TableRow>
                  </Index.TableBody>
                ) : (
                  <Index.TableBody>
                    {currentRows?.length ? (
                      currentRows?.map((item, index) => (
                        <Index.TableRow key={item?._id}>
                          <Index.TableCell align="left">
                            {item?.sNo}
                          </Index.TableCell>

                          <Index.TableCell align="left">
                            {item?.tankerId?.tankerNumber || "-"}
                          </Index.TableCell>
                          <Index.TableCell align="left">
                            {item?.hasOwnProperty("addedBy")
                              ? item?.addedBy?.firstName +
                                " " +
                                item?.addedBy?.lastName
                              : ""}
                          </Index.TableCell>

                          {/* <Index.TableCell align="left">
                            {item?.tankerId?.capacity || "-"}
                          </Index.TableCell> */}

                          <Index.TableCell align="left">
                            {item?.createdAt
                              ? Index.moment(item?.createdAt).format(
                                  "DD/MM/YYYY hh:mm:ss A"
                                )
                              : "-"}
                          </Index.TableCell>

                          <Index.TableCell
                            align="left"
                            sx={{
                              cursor: item?.breakdownRemark ? "pointer" : "",
                            }}
                            onClick={() => {
                              if (item?.breakdownRemark) {
                                handleModelRemarkOpen();
                                setRemarkData({
                                  remarkType: "BreakDown Remark",
                                  remark: item?.breakdownRemark,
                                });
                              }
                            }}
                          >
                            {item?.breakdownRemark
                              ? item?.breakdownRemark?.length > 20
                                ? `${item?.breakdownRemark?.slice(0, 20)}...`
                                : item?.breakdownRemark
                              : "-"}
                          </Index.TableCell>
                          <Index.TableCell align="left">
                            {item?.hasOwnProperty("revokedBy")
                              ? item?.revokedBy?.firstName +
                                " " +
                                item?.revokedBy?.lastName
                              : ""}
                          </Index.TableCell>
                          <Index.TableCell
                            align="left"
                            sx={{ cursor: "pointer" }}
                          >
                            {item?.revokedDate
                              ? Index.moment(item?.revokedDate).format(
                                  "DD/MM/YYYY hh:mm:ss A"
                                )
                              : "-"}
                          </Index.TableCell>

                          <Index.TableCell
                            align="left"
                            sx={{ cursor: item?.revokeRemark ? "pointer" : "" }}
                            onClick={() => {
                              if (item?.revokeRemark) {
                                handleModelRemarkOpen();
                                setRemarkData({
                                  remarkType: "Revoke Remark",
                                  remark: item?.revokeRemark,
                                });
                              }
                            }}
                          >
                            {item?.revokeRemark
                              ? item?.revokeRemark?.length > 20
                                ? `${item?.revokeRemark?.slice(0, 20)}...`
                                : item?.revokeRemark
                              : "-"}
                          </Index.TableCell>
                          {!item?.revokeRemark && !item?.revokedDate ? (
                            <Index.TableCell>
                              <Index.Box className="common-button blue-button res-blue-button">
                                <Index.Button
                                  variant="contained"
                                  disableRipple
                                  onClick={() => {
                                    handleModelRevokeOpen();
                                    setBreakDownId(item?._id);
                                  }}
                                >
                                  Revoke
                                </Index.Button>
                              </Index.Box>
                            </Index.TableCell>
                          ) : (
                            <Index.TableCell></Index.TableCell>
                          )}
                        </Index.TableRow>
                      ))
                    ) : (
                      <Index.TableRow>
                        <Index.TableCell
                          component="td"
                          variant="td"
                          scope="row"
                          className="no-data-in-list empty-record-align"
                          colSpan={18}
                          align="center"
                        >
                          No data available
                        </Index.TableCell>
                      </Index.TableRow>
                    )}
                  </Index.TableBody>
                )}
              </Index.Table>
            </Index.TableContainer>
          </Index.Box>
          <Index.Box className="pagination-design flex-end">
            <Index.Stack spacing={2}>
              <Index.Box className="pagination-count">
                <Index.Box className="input-design-div with-border">
                  <Index.Select
                    fullWidth
                    value={paginationPerPage}
                    onChange={(e) => {
                      setPaginationPerPage(e.target.value);
                    }}
                  >
                    <Index.MenuItem value={10}>10</Index.MenuItem>
                    <Index.MenuItem value={25}>25</Index.MenuItem>
                    <Index.MenuItem value={50}>50</Index.MenuItem>
                    <Index.MenuItem value={100}>100</Index.MenuItem>
                  </Index.Select>
                </Index.Box>
                <Index.Pagination
                  count={count}
                  page={currentPage}
                  onChange={handlePageChange}
                />
              </Index.Box>
            </Index.Stack>
          </Index.Box>
        </Index.Box>
      </Index.Box>

      {/* Add Break Down  */}
      <Index.Dialog
        open={openModel}
        onClose={handleModelClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Index.DialogContent className="expense-model-main  expense-model-main-box-set">
          <Index.Box className="title-main">
            <Formik
              enableReinitialize
              initialValues={initialValues}
              validationSchema={addBreakDownValidationSchema}
              onSubmit={handleAddBreakdownSubmit}
            >
              {({
                errors,
                touched,
                setFieldValue,
                handleChange,
                handleSubmit,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Index.Box className="title-main">
                    <Index.Box className="jus-between">
                      <Index.Typography
                        variant="h6"
                        component="h6"
                        className="input-label input-label-bold"
                        sx={{ marginBottom: "15px" }}
                      >
                        Add BreakDown
                      </Index.Typography>
                      <Index.ClearIcon
                        className="model-close-icon"
                        onClick={handleModelClose}
                      />
                    </Index.Box>
                    <Index.Grid container spacing={2}>
                      <Index.Grid item xs={12} sm={6} md={12}>
                        <Index.Typography
                          variant="label"
                          component="label"
                          className="input-label"
                        >
                          Tanker
                        </Index.Typography>
                        <Index.Box className="input-design-div with-border">
                          <Index.Autocomplete
                            disablePortal
                            className="invoice-select-drop"
                            id="combo-box-demo"
                            options={tankersList}
                            getOptionLabel={(option) => option?.tankerName}
                            renderOption={(props, option) => (
                              <Index.Box
                                component="li"
                                sx={{
                                  "& > img": { mr: 2, flexShrink: 0 },
                                }}
                                {...props}
                              >
                                {option?.tankerName}
                              </Index.Box>
                            )}
                            onChange={(event, newValue) => {
                              setFieldValue("tankerId", newValue?._id);
                            }}
                            // onBlur={handleBlur}
                            renderInput={(params) => (
                              <Index.TextField
                                {...params}
                                placeholder="Select Tanke"
                                variant="outlined"
                              />
                            )}
                          />
                          {touched.tankerId && errors.tankerId && (
                            <Index.FormHelperText error>
                              {errors.tankerId}
                            </Index.FormHelperText>
                          )}
                        </Index.Box>
                      </Index.Grid>
                      <Index.Grid item xs={12} sm={6} md={12}>
                        <Index.Typography
                          variant="label"
                          component="label"
                          className="input-label"
                        >
                          Remark
                        </Index.Typography>
                        <Index.Box className="input-design-div with-border">
                          <Index.TextareaAutosize
                            hiddenLabel
                            minRows={3}
                            id="filled-hidden-label-normal"
                            className="admin-input-design input-placeholder input-placeholder-box  input-placeholder-box-set "
                            placeholder="Remark"
                            variant="filled"
                            name="breakdownRemark"
                            onChange={handleChange}
                          />
                          {touched.breakdownRemark &&
                            errors.breakdownRemark && (
                              <Index.FormHelperText error>
                                {errors.breakdownRemark}
                              </Index.FormHelperText>
                            )}
                        </Index.Box>
                      </Index.Grid>

                      <Index.Grid item xs={12} sm={6} md={12}>
                        <Index.Box
                          className="common-button blue-button flex-end save-btn"
                          sx={{
                            display: "flex",
                            alignItems: "flex-end",
                          }}
                        >
                          <Index.Button
                            variant="contained"
                            type="submit"
                            className="Particular_model_submit"
                          >
                            Submit
                          </Index.Button>
                        </Index.Box>
                      </Index.Grid>
                    </Index.Grid>

                    {/* <Index.Box className="line" mt={3} mb={3}></Index.Box> */}
                  </Index.Box>
                </form>
              )}
            </Formik>
          </Index.Box>
        </Index.DialogContent>
      </Index.Dialog>

      {/* Add Break Down  */}
      <Index.Dialog
        open={openModelRevoke}
        onClose={handleModelRevokeClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Index.DialogContent className="expense-model-main  expense-model-main-box-set">
          <Index.Box className="title-main">
            <Formik
              //   enableReinitialize
              initialValues={initialValuesRevoke}
              validationSchema={addBreakDownRevokeValidationSchema}
              onSubmit={handleAddBreakdownRevokeSubmit}
            >
              {({
                errors,
                touched,
                setFieldValue,
                handleChange,
                handleSubmit,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Index.Box className="title-main">
                    <Index.Box className="jus-between">
                      <Index.Typography
                        variant="h6"
                        component="h6"
                        className="input-label input-label-bold"
                      >
                        Add Revoke
                      </Index.Typography>
                      <Index.ClearIcon
                        className="model-close-icon"
                        onClick={handleModelRevokeClose}
                      />
                    </Index.Box>
                    <Index.Grid container spacing={2}>
                      <Index.Grid item xs={12} sm={6} md={12}>
                        <Index.Typography
                          variant="label"
                          component="label"
                          className="input-label"
                        >
                          Remark
                        </Index.Typography>
                        <Index.Box className="input-design-div with-border">
                          <Index.TextareaAutosize
                            hiddenLabel
                            minRows={3}
                            id="filled-hidden-label-normal"
                            className="admin-input-design input-placeholder input-placeholder-box  input-placeholder-box-set "
                            placeholder="Remark"
                            variant="filled"
                            name="revokeRemark"
                            onChange={handleChange}
                          />
                          {touched.revokeRemark && errors.revokeRemark && (
                            <Index.FormHelperText error>
                              {errors.revokeRemark}
                            </Index.FormHelperText>
                          )}
                        </Index.Box>
                      </Index.Grid>

                      <Index.Grid item xs={12} sm={6} md={12}>
                        <Index.Box
                          className="common-button blue-button flex-end save-btn"
                          sx={{
                            display: "flex",
                            alignItems: "flex-end",
                          }}
                        >
                          <Index.Button
                            variant="contained"
                            type="submit"
                            className="Particular_model_submit"
                          >
                            Submit
                          </Index.Button>
                        </Index.Box>
                      </Index.Grid>
                    </Index.Grid>

                    {/* <Index.Box className="line" mt={3} mb={3}></Index.Box> */}
                  </Index.Box>
                </form>
              )}
            </Formik>
          </Index.Box>
        </Index.DialogContent>
      </Index.Dialog>
      {/* BreakDown Remark And Revoke text */}
      <Index.Dialog
        open={openModelRemark}
        onClose={handleModelRemarkClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Index.DialogContent className="expense-model-main  expense-model-main-box-set">
          <Index.Box className="title-main">
            <Index.Box className="title-main" sx={{ marginTop: "10px" }}>
              <Index.ClearIcon
                className="model-close-icon"
                onClick={handleModelRemarkClose}
              />
              <Index.Typography
                variant="h6"
                component="h6"
                className="input-label"
                sx={{ marginBottom: "15px" }}
              >
                {remarkData?.remarkType}
              </Index.Typography>
              <Index.Grid container spacing={2}>
                <Index.Grid item xs={12} sm={6} md={12}>
                  <Index.Typography
                    variant="label"
                    component="label"
                    className="input-label"
                  >
                    {remarkData?.remark}
                  </Index.Typography>
                </Index.Grid>
              </Index.Grid>
            </Index.Box>
          </Index.Box>
        </Index.DialogContent>
      </Index.Dialog>
    </>
  );
}

export default BreakDownList;
