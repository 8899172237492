export const tankerDocumentType = [
  {
    id: 1,
    name: "Tax Receipt",
    type: "taxReceipt",
  },
  {
    id: 2,
    name: "Calibration",
    type: "calibration",
  },
  {
    id: 3,
    name: "Fitness",
    type: "fitness",
  },
  {
    id: 4,
    name: "Permit",
    type: "permit",
  },
  {
    id: 5,
    name: "Insurance",
    type: "insurance",
  },
  {
    id: 6,
    name: "RC Book",
    type: "rcBook",
  },
  {
    id: 7,
    name: "Particular",
    type: "particular",
  },
  {
    id: 8,
    name: "PUC",
    type: "puc",
  }
];