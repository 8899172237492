import React, { useEffect, useState } from "react";
import Index from "../../../../Index";
import TankerDriverDocument from "./TankerDriverDocument";
import { values } from "lodash";
import { Formik } from "formik";
import { uploadDriverDocumentValidationSchema } from "../../../../../validation/AdminValidation";
import {
  addDriversDocument,
  getAllDriversDocuments,
} from "../../../../../redux/admin/action";
import dayjs from "dayjs";

// Design Tab
function SingleOrderTab(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Index.Box sx={{ p: 3 }}>
          <Index.Typography>{children}</Index.Typography>
        </Index.Box>
      )}
    </div>
  );
}

function TankerDriverView() {
  const navigate = Index.useNavigate();
  const dispatch = Index.useDispatch();
  const { state } = Index.useLocation();
  const [documentTypeList, setDocumentTypeList] = useState([
    { id: 1, name: "Aadhar Card", value: "aadhar card" },
    { id: 2, name: "Chemical Certificate", value: "chemical certificate" },
    { id: 3, name: "Licence", value: "licence" },
  ]);
  const [driverDocumentsList, setDriverDocumentsList] = useState();
  const [uploadDocumentName, setUploadDocumentName] = useState();
  const [openModel, setOpenModel] = useState(false);
  const [loading, setLoading] = useState(true);
  let id = "";
  const driverData = state?.row;
  const tankerData = state?.tankerData;
  //Model
  const handleModelOpen = () => {
    setOpenModel(true);
  };
  const handleModelClose = () => {
    setOpenModel(false);
    setUploadDocumentName();
  };

  //Handle back
  const backBtn = () => {
    navigate(`/admin/tankers/view/${state?.tankerId}`);
  };

  const initialValues = {
    documentType: "",
    driverDocument: "",
    expireDate: "",
    expireCheckBox: false,
  };
  const handleDocumentFormSubmit = (values) => {
    const formData = new FormData();
    formData.append("driverId", driverData?._id);
    formData.append("tankerId", tankerData?._id);
    formData.append("documentType", values?.documentType);
    formData.append("driverDocument", values?.driverDocument);
    if (values?.documentType !== "aadhar card") {
      formData.append("expireDate", values?.expireDate);
      formData.append("expireCheckBox", values?.expireCheckBox);
    }

    dispatch(addDriversDocument(formData)).then((res) => {
      if (res?.status == 200) {
        handleModelClose();
        fetAllDocuments(driverData?._id);
      }
    });
  };

  //get all documents
  const fetAllDocuments = (id) => {
    setLoading(true);
    const urlencoded = new URLSearchParams();
    urlencoded.append("driverId", id);
    try {
      dispatch(getAllDriversDocuments(urlencoded)).then((res) => {
        if (res?.status == 200) {
          setDriverDocumentsList(res?.data);
          setTimeout(() => {
            setLoading(false);
          }, 1000);
        } else {
          setDriverDocumentsList();
          setTimeout(() => {
            setLoading(false);
          }, 1000);
        }
      });
    } catch (error) {}
  };

  useEffect(() => {
    fetAllDocuments(driverData?._id);
  }, []);
  return (
    <>
      <Index.Box className="invoice-details">
        <Index.Box className="barge-common-box mb-20">
          <Index.Box className="title-header-flex flex-column-res">
            <Index.Box className="invoice-back-title">
              <Index.Button
                type="submit"
                className="Particular_model_submit back-invoice-btn"
                onClick={backBtn}
              >
                <Index.ArrowBackIcon />
              </Index.Button>
              <Index.Typography
                variant="span"
                component="span"
                className="invoice-title"
              >
                Driver Detail
              </Index.Typography>
            </Index.Box>
          </Index.Box>

          <Index.Box className="mt-10">
            <Index.Box className="mb-5 invoice-detail-item">
              <Index.Box className="input-label opacity-60">
                Driver Name :
              </Index.Box>
              <Index.Box className="input-label">
                {driverData?.driverName || "-"}
              </Index.Box>
            </Index.Box>

            <Index.Box className="mb-5 invoice-detail-item">
              <Index.Box className="input-label opacity-60">
                Driver Mobile No :
              </Index.Box>
              <Index.Box className="input-label">
                {driverData?.driverMobileNumber || "-"}
              </Index.Box>
            </Index.Box>

            <Index.Box className="invoice-detail-item">
              <Index.Box className="input-label opacity-60">
                Created Date :
              </Index.Box>
              <Index.Box className="input-label pdf-input">
                {driverData?.createdAt
                  ? Index.moment(driverData?.createdAt).format(
                      "DD/MM/YYYY hh:mm:ss A"
                    )
                  : "-"}
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>

        <Index.Box className="mb-20">
          {/* Design Tab */}
          <Index.Box sx={{ width: "100%" }}>
            <SingleOrderTab className="barge-common-box tab-content-box">
              <Index.Box className="barge-common-box">
                <Index.Box className="title-header">
                  <Index.Box className="title-header-flex res-title-header-flex">
                    <Index.Box className="title-main">
                      <Index.Typography
                        variant="p"
                        component="p"
                        className="page-title"
                      >
                        {/* Document */}
                      </Index.Typography>
                    </Index.Box>
                    <Index.Box className="common-button blue-button res-blue-button">
                      <Index.Button
                        variant="outlined"
                        onClick={() => {
                          handleModelOpen();
                        }}
                        className="view-invoice-btn"
                      >
                        Upload Document
                      </Index.Button>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              {loading ? (
                <Index.Box className="barge-common-box driver-loading-box">
                  <Index.CircularProgress color="secondary" size={20} />
                </Index.Box>
              ) : (
                <>
                  <TankerDriverDocument
                    docTitle="Aadhar Card"
                    driverDocData={driverDocumentsList?.adharCard}
                    driverId={driverData?._id}
                    setDriverDocumentsList={setDriverDocumentsList}
                    setLoading={setLoading}
                    tankerData={tankerData}
                  />
                  <TankerDriverDocument
                    docTitle="Chemical Certificate"
                    driverDocData={driverDocumentsList?.chemicalCertificate}
                    driverId={driverData?._id}
                    setDriverDocumentsList={setDriverDocumentsList}
                    setLoading={setLoading}
                    tankerData={tankerData}
                  />
                  <TankerDriverDocument
                    docTitle="Licence"
                    driverDocData={driverDocumentsList?.licence}
                    driverId={driverData?._id}
                    setDriverDocumentsList={setDriverDocumentsList}
                    setLoading={setLoading}
                    tankerData={tankerData}
                  />
                </>
              )}
            </SingleOrderTab>
          </Index.Box>
          {/* Design Tab */}
        </Index.Box>
      </Index.Box>

      {/* Tanker Document Upload Files */}
      <Index.Dialog
        open={openModel}
        onClose={handleModelClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Index.DialogContent className="expense-model-main  expense-model-main-box-set">
          <Index.Box className="title-main">
            <Formik
              enableReinitialize
              initialValues={initialValues}
              validationSchema={uploadDriverDocumentValidationSchema}
              onSubmit={handleDocumentFormSubmit}
            >
              {({
                values,
                errors,
                touched,
                setFieldValue,
                handleSubmit,
                handleBlur,
                setErrors,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Index.Box className="title-main" sx={{ marginTop: "10px" }}>
                    <Index.ClearIcon
                      className="model-close-icon"
                      onClick={handleModelClose}
                    />
                    <Index.Typography
                      variant="h6"
                      component="h6"
                      className="input-label input-label-bold"
                      sx={{ marginBottom: "15px" }}
                    >
                      Document Upload
                    </Index.Typography>
                    <Index.Grid container spacing={2}>
                      <Index.Grid item xs={12} sm={6} md={12}>
                        <Index.Typography
                          variant="label"
                          component="label"
                          className="input-label"
                        >
                          Document Type
                        </Index.Typography>
                        <Index.Box className="input-design-div with-border">
                          <Index.Autocomplete
                            disablePortal
                            className="invoice-select-drop"
                            id="combo-box-demo"
                            options={documentTypeList}
                            getOptionLabel={(option) => option?.name}
                            renderOption={(props, option) => (
                              <Index.Box
                                component="li"
                                sx={{
                                  "& > img": { mr: 2, flexShrink: 0 },
                                }}
                                {...props}
                              >
                                {option?.name}
                              </Index.Box>
                            )}
                            onChange={(event, newValue) => {
                              setFieldValue("documentType", newValue?.value);
                              if (newValue?.value == "aadhar card") {
                                setErrors({ expireDate: "" });
                              }
                            }}
                            onBlur={handleBlur}
                            renderInput={(params) => (
                              <Index.TextField
                                {...params}
                                placeholder="Select Document Type"
                                variant="outlined"
                              />
                            )}
                          />
                          {touched.documentType && errors.documentType && (
                            <Index.FormHelperText error>
                              {errors.documentType}
                            </Index.FormHelperText>
                          )}
                        </Index.Box>
                      </Index.Grid>

                      <Index.Grid item xs={12} sm={6} md={12} mt={2}>
                        <Index.Typography
                          variant="label"
                          component="label"
                          className="input-label"
                        >
                          Document Upload
                        </Index.Typography>
                        <Index.Box className="input-design-div with-border position-relative">
                          <Index.Box className="pdf-design">
                            Upload File
                          </Index.Box>
                          <Index.TextField
                            fullWidth
                            type="file"
                            name="driverDocument"
                            // multiple
                            id="filled-hidden-label-normal"
                            variant="filled"
                            className="admin-input-design input-placeholder pdf-upload-design"
                            onChange={(e) => {
                              setFieldValue(
                                "driverDocument",
                                e.target?.files[0]
                              );
                              setUploadDocumentName(e.target?.files[0]?.name);
                            }}
                            onBlur={handleBlur}
                            FormHelperTextProps={{
                              className: "input_label_props",
                            }}
                          />
                        </Index.Box>

                        {touched.driverDocument && errors?.driverDocument && (
                          <Index.FormHelperText error>
                            {errors?.driverDocument}
                          </Index.FormHelperText>
                        )}
                      </Index.Grid>
                      <Index.Grid item xs={12} sm={12} md={12}>
                        <Index.Typography>
                          {uploadDocumentName && (
                            <Index.Typography
                              variant="label"
                              component="label"
                              className="input-label"
                            >
                              {uploadDocumentName}
                            </Index.Typography>
                          )}
                        </Index.Typography>
                      </Index.Grid>

                      {values?.documentType !== "aadhar card" && (
                        <>
                          <Index.Grid item xs={12} sm={6} md={12}>
                            <Index.Typography
                              variant="label"
                              component="label"
                              className="input-label"
                            >
                              Expiry Date
                            </Index.Typography>
                            <Index.Box className="input-design-div">
                              <Index.Box className="set-date set-date-picker">
                                <Index.LocalizationProvider
                                  dateAdapter={Index.AdapterDayjs}
                                >
                                  <Index.DemoContainer
                                    components={["DatePicker"]}
                                  >
                                    <Index.DemoItem label="">
                                      <Index.DatePicker
                                        format="DD/MM/YYYY"
                                        value={dayjs(values.expireDate)}
                                        onChange={(value) => {
                                          const formatedDate = Index.moment(
                                            value.$d
                                          ).format("YYYY-MM-DD");
                                          setFieldValue(
                                            "expireDate",
                                            formatedDate
                                          );
                                          if (formatedDate) {
                                            setErrors({ expireDate: "" });
                                          }
                                          setFieldValue(
                                            "expireCheckBox",
                                            false
                                          );
                                        }}
                                      />
                                    </Index.DemoItem>
                                  </Index.DemoContainer>
                                </Index.LocalizationProvider>
                                {touched?.expireDate &&
                                  errors?.expireDate &&
                                  !values?.expireCheckBox && (
                                    <Index.FormHelperText error>
                                      {errors?.expireDate}
                                    </Index.FormHelperText>
                                  )}
                              </Index.Box>
                            </Index.Box>
                          </Index.Grid>

                          <Index.Grid item xs={12} sm={6} md={12}>
                            <Index.Box className="input-design-div with-border">
                              <Index.FormControlLabel
                                control={
                                  <Index.Checkbox
                                    checked={values?.expireCheckBox}
                                    onChange={(event) => {
                                      setFieldValue(
                                        "expireCheckBox",
                                        event.target.checked
                                      );
                                      setFieldValue("expireDate", "");
                                    }}
                                  />
                                }
                                label="Do not have expiry date"
                              />
                              {touched.expireCheckBox &&
                                errors.expireCheckBox && (
                                  <Index.FormHelperText error>
                                    {errors.expireCheckBox}
                                  </Index.FormHelperText>
                                )}
                            </Index.Box>
                          </Index.Grid>
                        </>
                      )}

                      <Index.Grid item xs={12} sm={6} md={12}>
                        <Index.Box
                          className="common-button blue-button flex-end save-btn"
                          sx={{
                            display: "flex",
                            alignItems: "flex-end",
                          }}
                        >
                          <Index.Button
                            variant="contained"
                            type="submit"
                            className="Particular_model_submit"
                          >
                            Submit
                          </Index.Button>
                        </Index.Box>
                      </Index.Grid>
                    </Index.Grid>

                    <Index.Box className="line" mt={3} mb={3}></Index.Box>
                  </Index.Box>
                </form>
              )}
            </Formik>
          </Index.Box>
        </Index.DialogContent>
      </Index.Dialog>
    </>
  );
}

export default TankerDriverView;
