import { useEffect, useRef, useState } from "react";
import Index from "../../../../Index";
import { Formik } from "formik";
import { addEditCraftCrewValidationSchema } from "../../../../../validation/AdminValidation";
import {
  addEditCraftCrew,
  deleteCraftCrew,
  deleteCurrentOrderDocument,
  getAllCraftCrewList,
} from "../../../../../redux/admin/action";
import { useReactToPrint } from "react-to-print";
import CrewMemberPrintPDF from "./CrewMemberPrintPDF";

function CrewList({ craftId }) {
  const dispatch = Index.useDispatch();
  const [openModel, setOpenModel] = useState(false);
  const [editData, setEditData] = useState();
  const [deleteOpen, setDeleteOpen] = useState(false);

  const [loading, setLoading] = useState(false);
  const [deleteCrewId, setDeleteCrewId] = useState("");
  const [craftCrewList, setCraftCrewList] = useState([]);
  const [editCrewData, setEditCrewData] = useState();
  const [crewMembarImageName, setCrewMemberImageName] = useState("");
  const [crewDocumentsName, setCrewDocumentsName] = useState([]);

  // State for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState(0);
  const [paginationPerPage, setPaginationPerPage] = useState(50);
  const [currentRows, setCurrentRows] = useState([]);

  //Model
  const handleAddCrewOpen = () => {
    setOpenModel(true);
    setCrewDocumentsName([]);
    setCrewMemberImageName("");
  };
  const handleAddCrewClose = () => {
    setOpenModel(false);
    setEditData();
    setEditCrewData();
  };

  //Print crew members
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  let initialValues;
  if (editCrewData) {
    initialValues = {
      name: editCrewData?.name || "",
      mobileNumber: editCrewData?.mobileNumber || "",
      rank: editCrewData?.rank || "",
      nationality: editCrewData?.nationality || "",
      dob: editCrewData?.dob || null,
      ppNumber: editCrewData?.ppNumber || "",
      ppExpiryDate: editCrewData?.ppExpiryDate || "",
      cdcNumber: editCrewData?.cdcNumber || "",
      cdcExpiryDate: editCrewData?.cdcExpiryDate || "",
      poj: editCrewData?.poj || "",
      doj: editCrewData?.doj || null,
      crewDocuments: [],
      crewMemberImage: "",
    };
  } else {
    initialValues = {
      name: "",
      mobileNumber: "",
      rank: "",
      nationality: "",
      dob: "",
      ppNumber: "",
      ppExpiryDate: "",
      cdcNumber: "",
      cdcExpiryDate: "",
      poj: "",
      doj: "",
      crewDocuments: [],
      crewMemberImage: "",
    };
  }
  const handleFormSubmit = (values) => {
    const urlencoded = new FormData();
    if (editCrewData) {
      urlencoded.append("id", editCrewData?._id);
    }
    urlencoded.append("craftId", craftId);
    if (values?.crewDocuments?.length) {
      values?.crewDocuments?.map((el) => {
        urlencoded.append("crewDocuments", el);
      });
    }

    for (let keys in values) {
      urlencoded.append(keys, values[keys]);
    }

    dispatch(addEditCraftCrew(urlencoded)).then((res) => {
      if (res?.status == 200) {
        fetchAllCrewList(craftId);
      }
    });
    handleAddCrewClose();
  };

  const handleDeleteOpen = () => {
    setDeleteOpen(true);
  };

  const handleDeleteClose = () => {
    setDeleteOpen(false);
  };

  //handle delete crew
  const handleDeleteRecord = () => {
    const urlencoded = new URLSearchParams();
    urlencoded.append("id", deleteCrewId);
    try {
      dispatch(deleteCraftCrew(urlencoded)).then((res) => {
        if (res?.status === 200) {
          fetchAllCrewList(craftId);
          setDeleteCrewId("");
        }
      });
    } catch (error) {}

    handleDeleteClose();
  };

  //get all photo list
  const fetchAllCrewList = (id) => {
    setLoading(true);
    const urlencoded = new URLSearchParams();
    urlencoded.append("craftId", id);
    try {
      dispatch(getAllCraftCrewList(urlencoded)).then((res) => {
        if (res?.status === 200) {
          setCraftCrewList(res?.data);
          setTimeout(() => {
            setLoading(false);
          }, 1000);
        } else {
          setCraftCrewList([]);
          setTimeout(() => {
            setLoading(false);
          }, 1000);
        }
      });
    } catch (error) {}
  };

  // Pagination calculation start

  const PER_PAGE = paginationPerPage;
  useEffect(() => {
    //Page count
    const count = Math.ceil(craftCrewList?.length / PER_PAGE);
    setCount(count);
    const indexOfLastRow = currentPage * PER_PAGE;
    const indexOfFirstRow = indexOfLastRow - PER_PAGE;
    const modifiedRows = craftCrewList?.slice(indexOfFirstRow, indexOfLastRow);
    const serialNumber = (currentPage - 1) * PER_PAGE + 1;
    // setCurrentRows(modifiedRows);
    setCurrentRows(
      modifiedRows &&
        modifiedRows.map((ele, index) => {
          return { ...ele, sNo: serialNumber + index };
        })
    );
    if (modifiedRows?.length === 0 && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  }, [currentPage, craftCrewList, paginationPerPage, PER_PAGE]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  // Pagination calculation end

  useEffect(() => {
    fetchAllCrewList(craftId);
  }, []);

  // when the remove crew documents then used...
  const handleRemoveCrewDocument = (
    itemData,
    editTimeData,
    setFieldValue,
    crewDocumentsData
  ) => {
    if (editTimeData?.hasOwnProperty("_id")) {
      const values = {
        id: editTimeData?._id,
        file: itemData,
        crewDocument: "crewDocument",
      };
      dispatch(deleteCurrentOrderDocument(values)).then((res) => {
        if (res.status === 200) {
          // here through get latest data for edit time
          setCrewDocumentsName((prev) => [
            ...prev.filter((data) => data !== itemData),
          ]);
        }
      });
    } else {
      setFieldValue(
        "crewDocuments",
        crewDocumentsData?.filter((res) => res.name !== itemData)
      );
      setCrewDocumentsName((prev) => [
        ...prev.filter((data) => data !== itemData),
      ]);
    }
  };

  // when the remove crew memebers image then used...
  const handleRemoveCrewMemberImage = (
    e,
    imageName,
    editTimeData,
    setFieldValue
  ) => {
    if (editTimeData?.hasOwnProperty("_id")) {
      const values = {
        id: editTimeData?._id,
        file: imageName,
        crewMemberImage: "crewMemberImage",
      };
      dispatch(deleteCurrentOrderDocument(values)).then((res) => {
        if (res.status === 200) {
          // here through get latest data for edit time
          setFieldValue("crewMemberImage", "");
          setCrewMemberImageName("");
        }
      });
    } else {
      setFieldValue("crewMemberImage", "");
      setCrewMemberImageName("");
    }
  };

  return (
    <>
      <Index.Box className="barge-common-box">
        <Index.Box className="title-header">
          <Index.Box className="title-header-flex res-title-header-flex">
            <Index.Box className="title-main">
              <Index.Typography
                variant="p"
                component="p"
                className="page-title"
              >
                {/* Document */}
              </Index.Typography>
            </Index.Box>
            <Index.Box className="common-button blue-button res-blue-button">
              <Index.Box className="pdfDownload" sx={{ display: "none" }}>
                <CrewMemberPrintPDF
                  crewMemberData={currentRows?.filter(
                    (res) => res?.signOff === false
                  )}
                  componentRef={componentRef}
                />
              </Index.Box>
              <Index.Button
                variant="outlined"
                onClick={() => {
                  handlePrint();
                }}
                className="view-invoice-btn"
              >
                Print
              </Index.Button>
              <Index.Button
                variant="outlined"
                onClick={() => {
                  handleAddCrewOpen();
                }}
                className="view-invoice-btn"
              >
                Add
              </Index.Button>
            </Index.Box>
          </Index.Box>
        </Index.Box>
        <Index.Box className="">
          <Index.Grid container spacing={1}>
            <Index.Box className="order-active-log-header expensedetails-table order-active-table-set">
              <Index.Box className="">
                <Index.TableContainer
                  component={Index.Paper}
                  className="table-container"
                >
                  <Index.Table
                    aria-label="simple table"
                    className="table-design-main supply-table one-line-table"
                  >
                    <Index.TableHead>
                      <Index.TableRow>
                        <Index.TableCell>S. No</Index.TableCell>
                        <Index.TableCell align="left">Name</Index.TableCell>
                        <Index.TableCell align="left">
                          Mobile Number
                        </Index.TableCell>
                        <Index.TableCell align="left">Rank</Index.TableCell>
                        <Index.TableCell align="left">
                          Nationality
                        </Index.TableCell>
                        <Index.TableCell align="left">D.O.B</Index.TableCell>
                        <Index.TableCell align="left">P.P. No</Index.TableCell>
                        <Index.TableCell align="left">
                          P.P.Exp. Date
                        </Index.TableCell>
                        <Index.TableCell align="left">CDC No</Index.TableCell>
                        <Index.TableCell align="left">CDC Exp</Index.TableCell>
                        <Index.TableCell align="left">P.O.J</Index.TableCell>
                        <Index.TableCell align="left">D.O.J</Index.TableCell>
                        <Index.TableCell align="left">
                          Sign Off By
                        </Index.TableCell>
                        <Index.TableCell align="left">
                          Sign Off Date
                        </Index.TableCell>
                        <Index.TableCell align="left">Action</Index.TableCell>
                      </Index.TableRow>
                    </Index.TableHead>

                    {loading ? (
                      <Index.TableBody>
                        <Index.TableRow>
                          <Index.TableCell colSpan={14} align="center">
                            <Index.CircularProgress
                              color="secondary"
                              size={20}
                            />
                          </Index.TableCell>
                        </Index.TableRow>
                      </Index.TableBody>
                    ) : (
                      <Index.TableBody className="crew-table-body">
                        {currentRows?.length ? (
                          currentRows?.map((item, index) => (
                            <Index.TableRow
                              key={item?._id}
                              className={item?.signOff ? "red-crew-row" : ""}
                            >
                              <Index.TableCell align="left">
                                {item?.sNo}
                              </Index.TableCell>
                              <Index.TableCell align="left">
                                {item?.name || "-"}
                              </Index.TableCell>
                              <Index.TableCell align="left">
                                {item?.mobileNumber || "-"}
                              </Index.TableCell>
                              <Index.TableCell align="left">
                                {item?.rank || "-"}
                              </Index.TableCell>
                              <Index.TableCell align="left">
                                {item?.nationality || "-"}
                              </Index.TableCell>
                              <Index.TableCell align="left">
                                {item?.dob
                                  ? Index.moment(item?.dob).format("DD/MM/YYYY")
                                  : "-"}
                              </Index.TableCell>
                              <Index.TableCell align="left">
                                {item?.ppNumber || "-"}
                              </Index.TableCell>
                              <Index.TableCell align="left">
                                {item?.ppExpiryDate
                                  ? Index.moment(item?.ppExpiryDate).format(
                                      "DD/MM/YYYY"
                                    )
                                  : "-"}
                              </Index.TableCell>
                              <Index.TableCell align="left">
                                {item?.cdcNumber || "-"}
                              </Index.TableCell>
                              <Index.TableCell align="left">
                                {item?.cdcExpiryDate
                                  ? Index.moment(item?.cdcExpiryDate).format(
                                      "DD/MM/YYYY"
                                    )
                                  : "-"}
                              </Index.TableCell>
                              <Index.TableCell align="left">
                                {item?.poj || "-"}
                              </Index.TableCell>
                              <Index.TableCell align="left">
                                {item?.doj
                                  ? Index.moment(item?.doj).format("DD/MM/YYYY")
                                  : "-"}
                              </Index.TableCell>
                              <Index.TableCell align="left">
                                {item?.signOffBy &&
                                item?.signOffBy?.hasOwnProperty("firstName") &&
                                item?.signOffBy?.hasOwnProperty("lastName")
                                  ? item?.signOffBy.firstName +
                                    " " +
                                    item?.signOffBy.lastName
                                  : "-"}
                              </Index.TableCell>
                              <Index.TableCell align="left">
                                {item?.signOffDate
                                  ? Index.moment(item?.signOffDate).format(
                                      "DD/MM/YYYY hh:mm:ss"
                                    )
                                  : "-"}
                              </Index.TableCell>

                              <Index.TableCell className="sign-off-td">
                                <Index.IconButton>
                                  <Index.EditIcon
                                    className="edit-icon-set"
                                    onClick={() => {
                                      handleAddCrewOpen();
                                      setEditCrewData(item);
                                      setCrewDocumentsName(
                                        item?.crewDocument?.length
                                          ? item?.crewDocument
                                          : []
                                      );
                                      setCrewMemberImageName(
                                        item?.crewMemberImage
                                      );
                                    }}
                                  />
                                </Index.IconButton>
                                {item.hasOwnProperty("signOff") &&
                                !item?.signOff ? (
                                  <>
                                    <Index.Button
                                      className="sign-off-btn"
                                      variant="outlined"
                                      onClick={() => {
                                        setDeleteCrewId(item?._id);
                                        handleDeleteOpen();
                                      }}
                                    >
                                      Sign Off
                                    </Index.Button>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </Index.TableCell>
                            </Index.TableRow>
                          ))
                        ) : (
                          <Index.TableRow>
                            <Index.TableCell
                              component="td"
                              variant="td"
                              scope="row"
                              className="no-data-in-list"
                              colSpan={15}
                              align="center"
                            >
                              No data available
                            </Index.TableCell>
                          </Index.TableRow>
                        )}
                      </Index.TableBody>
                    )}
                  </Index.Table>
                </Index.TableContainer>
              </Index.Box>
              <Index.Box className="pagination-design flex-end">
                <Index.Stack spacing={2}>
                  <Index.Box className="pagination-count">
                    <Index.Box className="input-design-div with-border">
                      <Index.Select
                        fullWidth
                        value={paginationPerPage}
                        onChange={(e) => {
                          setPaginationPerPage(e.target.value);
                        }}
                      >
                        <Index.MenuItem value={10}>10</Index.MenuItem>
                        <Index.MenuItem value={25}>25</Index.MenuItem>
                        <Index.MenuItem value={50}>50</Index.MenuItem>
                        <Index.MenuItem value={100}>100</Index.MenuItem>
                      </Index.Select>
                    </Index.Box>
                    <Index.Pagination
                      count={count}
                      page={currentPage}
                      onChange={handlePageChange}
                    />
                  </Index.Box>
                </Index.Stack>
              </Index.Box>
            </Index.Box>
          </Index.Grid>
        </Index.Box>
      </Index.Box>

      {/* Add Crew Model */}
      <Index.Dialog
        open={openModel}
        onClose={handleAddCrewClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Index.DialogContent className="expense-model-main  expense-model-main-box-set">
          <Index.Box className="title-main">
            <Formik
              enableReinitialize
              initialValues={initialValues}
              validationSchema={addEditCraftCrewValidationSchema}
              onSubmit={handleFormSubmit}
            >
              {({
                values,
                errors,
                touched,
                setFieldValue,
                handleChange,
                handleBlur,
                handleSubmit,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Index.Box className="title-main">
                    <Index.Box className="jus-between">
                      <Index.Typography
                        variant="h6"
                        component="h6"
                        className="input-label input-label-bold"
                        sx={{ marginBottom: "15px" }}
                      >
                        {`${editCrewData ? "Edit" : "Add"} Crew`}
                      </Index.Typography>
                      <Index.ClearIcon
                        className="model-close-icon"
                        onClick={handleAddCrewClose}
                      />
                    </Index.Box>
                    <Index.Grid container spacing={2}>
                      <Index.Grid item xs={12} sm={6} md={6}>
                        <Index.Typography
                          variant="label"
                          component="label"
                          className="input-label"
                        >
                          Name
                        </Index.Typography>
                        <Index.Box className="input-design-div with-border">
                          <Index.TextField
                            hiddenLabel
                            id="filled-hidden-label-normal"
                            placeholder="Name"
                            variant="filled"
                            className="admin-input-design input-placeholder"
                            name="name"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values?.name}
                          />
                          {touched?.name && errors?.name && (
                            <Index.FormHelperText error>
                              {errors?.name}
                            </Index.FormHelperText>
                          )}
                        </Index.Box>
                      </Index.Grid>
                      <Index.Grid item xs={12} sm={6} md={6}>
                        <Index.Typography
                          variant="label"
                          component="label"
                          className="input-label"
                        >
                          Mobile Number
                        </Index.Typography>
                        <Index.Box className="input-design-div with-border">
                          <Index.TextField
                            hiddenLabel
                            id="filled-hidden-label-normal"
                            placeholder="Mobile Number"
                            variant="filled"
                            className="admin-input-design input-placeholder"
                            name="mobileNumber"
                            onChange={(e) => {
                              const value = e.target.value
                                .replace(/\D/g, "")
                                ?.slice(0, 10);
                              setFieldValue("mobileNumber", value);
                            }}
                            onBlur={handleBlur}
                            value={values?.mobileNumber}
                          />
                          {touched?.mobileNumber && errors?.mobileNumber && (
                            <Index.FormHelperText error>
                              {errors?.mobileNumber}
                            </Index.FormHelperText>
                          )}
                        </Index.Box>
                      </Index.Grid>
                      <Index.Grid item xs={12} sm={6} md={6}>
                        <Index.Typography
                          variant="label"
                          component="label"
                          className="input-label"
                        >
                          Rank
                        </Index.Typography>
                        <Index.Box className="input-design-div with-border">
                          <Index.TextField
                            hiddenLabel
                            id="filled-hidden-label-normal"
                            placeholder="Rank"
                            variant="filled"
                            className="admin-input-design input-placeholder"
                            name="rank"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values?.rank}
                          />
                          {touched?.rank && errors?.rank && (
                            <Index.FormHelperText error>
                              {errors?.rank}
                            </Index.FormHelperText>
                          )}
                        </Index.Box>
                      </Index.Grid>
                      <Index.Grid item xs={12} sm={6} md={6}>
                        <Index.Typography
                          variant="label"
                          component="label"
                          className="input-label"
                        >
                          Nationality
                        </Index.Typography>
                        <Index.Box className="input-design-div with-border">
                          <Index.TextField
                            hiddenLabel
                            id="filled-hidden-label-normal"
                            placeholder="Nationality"
                            variant="filled"
                            className="admin-input-design input-placeholder"
                            name="nationality"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values?.nationality}
                          />
                          {touched?.nationality && errors?.nationality && (
                            <Index.FormHelperText error>
                              {errors?.nationality}
                            </Index.FormHelperText>
                          )}
                        </Index.Box>
                      </Index.Grid>
                      <Index.Grid item xs={12} sm={6} md={6}>
                        <Index.Typography
                          variant="label"
                          component="label"
                          className="input-label"
                        >
                          D.O.B
                        </Index.Typography>
                        <Index.Box className="input-design-div">
                          <Index.Box className="set-date set-date-picker">
                            <Index.LocalizationProvider
                              dateAdapter={Index.AdapterDayjs}
                            >
                              <Index.DemoContainer components={["DatePicker"]}>
                                <Index.DemoItem label="">
                                  <Index.DatePicker
                                    format="DD/MM/YYYY"
                                    maxDate={Index.dayjs(
                                      Index.moment()
                                        .subtract(18, "years")
                                        .format("YYYY-MM-DD")
                                    )}
                                    defaultValue={
                                      editCrewData
                                        ? Index.dayjs(
                                            values?.dob
                                              ? Index.moment(
                                                  values?.dob
                                                ).format("YYYY-MM-DD")
                                              : "-"
                                          )
                                        : null
                                    }
                                    onChange={(value) => {
                                      if (value !== null) {
                                        const formatedDate = Index.moment(
                                          value.$d
                                        ).format("YYYY-MM-DD");
                                        setFieldValue("dob", formatedDate);
                                      } else if (value === null) {
                                        setFieldValue("dob", null);
                                      }
                                    }}
                                  />
                                </Index.DemoItem>
                              </Index.DemoContainer>
                            </Index.LocalizationProvider>
                          </Index.Box>
                          {touched?.dob && errors?.dob && (
                            <Index.FormHelperText error>
                              {errors?.dob}
                            </Index.FormHelperText>
                          )}
                        </Index.Box>
                      </Index.Grid>
                      <Index.Grid item xs={12} sm={6} md={6}>
                        <Index.Typography
                          variant="label"
                          component="label"
                          className="input-label"
                        >
                          P.P. No
                        </Index.Typography>
                        <Index.Box className="input-design-div with-border">
                          <Index.TextField
                            hiddenLabel
                            id="filled-hidden-label-normal"
                            placeholder="P.P. No"
                            variant="filled"
                            className="admin-input-design input-placeholder"
                            name="ppNumber"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values?.ppNumber}
                          />
                          {touched?.ppNumber && errors?.ppNumber && (
                            <Index.FormHelperText error>
                              {errors?.ppNumber}
                            </Index.FormHelperText>
                          )}
                        </Index.Box>
                      </Index.Grid>
                      <Index.Grid item xs={12} sm={6} md={6}>
                        <Index.Typography
                          variant="label"
                          component="label"
                          className="input-label"
                        >
                          P.P.Exp. Date
                        </Index.Typography>
                        <Index.Box className="input-design-div">
                          <Index.Box className="set-date set-date-picker">
                            <Index.LocalizationProvider
                              dateAdapter={Index.AdapterDayjs}
                            >
                              <Index.DemoContainer components={["DatePicker"]}>
                                <Index.DemoItem label="">
                                  <Index.DatePicker
                                    format="DD/MM/YYYY"
                                    defaultValue={
                                      editCrewData
                                        ? Index.dayjs(
                                            values?.ppExpiryDate
                                              ? Index.moment(
                                                  values?.ppExpiryDate
                                                ).format("YYYY-MM-DD")
                                              : "-"
                                          )
                                        : ""
                                    }
                                    onChange={(value) => {
                                      const formatedDate = Index.moment(
                                        value.$d
                                      ).format("YYYY-MM-DD");
                                      setFieldValue(
                                        "ppExpiryDate",
                                        formatedDate
                                      );
                                    }}
                                  />
                                </Index.DemoItem>
                              </Index.DemoContainer>
                            </Index.LocalizationProvider>
                            {touched?.ppExpiryDate && errors?.ppExpiryDate && (
                              <Index.FormHelperText error>
                                {errors?.ppExpiryDate}
                              </Index.FormHelperText>
                            )}
                          </Index.Box>
                        </Index.Box>
                      </Index.Grid>
                      <Index.Grid item xs={12} sm={6} md={6}>
                        <Index.Typography
                          variant="label"
                          component="label"
                          className="input-label"
                        >
                          CDC No
                        </Index.Typography>
                        <Index.Box className="input-design-div with-border">
                          <Index.TextField
                            hiddenLabel
                            id="filled-hidden-label-normal"
                            placeholder="CDC No"
                            variant="filled"
                            className="admin-input-design input-placeholder"
                            name="cdcNumber"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values?.cdcNumber}
                          />
                          {touched?.cdcNumber && errors?.cdcNumber && (
                            <Index.FormHelperText error>
                              {errors?.cdcNumber}
                            </Index.FormHelperText>
                          )}
                        </Index.Box>
                      </Index.Grid>
                      <Index.Grid item xs={12} sm={6} md={6}>
                        <Index.Typography
                          variant="label"
                          component="label"
                          className="input-label"
                        >
                          CDC Exp
                        </Index.Typography>
                        <Index.Box className="input-design-div">
                          <Index.Box className="set-date set-date-picker">
                            <Index.LocalizationProvider
                              dateAdapter={Index.AdapterDayjs}
                            >
                              <Index.DemoContainer components={["DatePicker"]}>
                                <Index.DemoItem label="">
                                  <Index.DatePicker
                                    format="DD/MM/YYYY"
                                    defaultValue={
                                      editCrewData
                                        ? Index.dayjs(
                                            values?.cdcExpiryDate
                                              ? Index.moment(
                                                  values?.cdcExpiryDate
                                                ).format("YYYY-MM-DD")
                                              : "-"
                                          )
                                        : ""
                                    }
                                    onChange={(value) => {
                                      const formatedDate = Index.moment(
                                        value.$d
                                      ).format("YYYY-MM-DD");
                                      setFieldValue(
                                        "cdcExpiryDate",
                                        formatedDate
                                      );
                                    }}
                                  />
                                </Index.DemoItem>
                              </Index.DemoContainer>
                            </Index.LocalizationProvider>
                            {touched?.cdcExpiryDate &&
                              errors?.cdcExpiryDate && (
                                <Index.FormHelperText error>
                                  {errors?.cdcExpiryDate}
                                </Index.FormHelperText>
                              )}
                          </Index.Box>
                        </Index.Box>
                      </Index.Grid>
                      <Index.Grid item xs={12} sm={6} md={6}>
                        <Index.Typography
                          variant="label"
                          component="label"
                          className="input-label"
                        >
                          P.O.J
                        </Index.Typography>
                        <Index.Box className="input-design-div with-border">
                          <Index.TextField
                            hiddenLabel
                            id="filled-hidden-label-normal"
                            placeholder="P.O.J"
                            variant="filled"
                            className="admin-input-design input-placeholder"
                            name="poj"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values?.poj}
                          />
                          {touched?.poj && errors?.poj && (
                            <Index.FormHelperText error>
                              {errors?.poj}
                            </Index.FormHelperText>
                          )}
                        </Index.Box>
                      </Index.Grid>
                      <Index.Grid item xs={12} sm={6} md={6}>
                        <Index.Typography
                          variant="label"
                          component="label"
                          className="input-label"
                        >
                          D.O.J
                        </Index.Typography>
                        <Index.Box className="input-design-div">
                          <Index.Box className="set-date set-date-picker">
                            <Index.LocalizationProvider
                              dateAdapter={Index.AdapterDayjs}
                            >
                              <Index.DemoContainer components={["DatePicker"]}>
                                <Index.DemoItem label="">
                                  <Index.DatePicker
                                    format="DD/MM/YYYY"
                                    disablePast
                                    defaultValue={
                                      editCrewData
                                        ? Index.dayjs(
                                            values?.doj
                                              ? Index.moment(
                                                  values?.doj
                                                ).format("YYYY-MM-DD")
                                              : "-"
                                          )
                                        : null
                                    }
                                    onChange={(value) => {
                                      if (value !== null) {
                                        const formatedDate = Index.moment(
                                          value.$d
                                        ).format("YYYY-MM-DD");
                                        setFieldValue("doj", formatedDate);
                                      } else {
                                        setFieldValue("doj", null);
                                      }
                                    }}
                                  />
                                </Index.DemoItem>
                              </Index.DemoContainer>
                            </Index.LocalizationProvider>
                          </Index.Box>
                          {touched?.doj && errors?.doj && (
                            <Index.FormHelperText error>
                              {errors?.doj}
                            </Index.FormHelperText>
                          )}
                        </Index.Box>
                      </Index.Grid>
                      <Index.Grid item xs={12} sm={12} md={12}>
                        <Index.Typography
                          variant="label"
                          component="label"
                          className="input-label"
                        >
                          Crew Member
                        </Index.Typography>
                        <Index.Box className="input-design-div with-border position-relative">
                          <Index.Box className="pdf-design">
                            Upload File :
                          </Index.Box>
                          <Index.TextField
                            fullWidth
                            type="file"
                            name="crewMemberImage"
                            multiple
                            id="filled-hidden-label-normal"
                            variant="filled"
                            // onFocus={handleFocus}
                            onBlur={handleBlur}
                            className="admin-input-design input-placeholder pdf-upload-design"
                            onChange={(e) => {
                              setFieldValue(
                                "crewMemberImage",
                                e.target?.files[0]
                              );
                              setCrewMemberImageName(e.target?.files[0]?.name);
                            }}
                            inputProps={{
                              multiple: true,
                            }}
                            FormHelperTextProps={{
                              className: "input_label_props",
                            }}
                          />
                          {/* {touched?.crewMemberImage && errors?.crewMemberImage && (
                          <Index.FormHelperText error>
                            {errors?.crewMemberImage}
                          </Index.FormHelperText>
                        )} */}
                          <br />
                          <Index.Box sx={{ display: "flex" }}>
                            <Index.Typography
                              // key={i}
                              variant="label"
                              component="label"
                              className="input-label"
                            >
                              {crewMembarImageName}
                            </Index.Typography>
                            {crewMembarImageName?.length ? (
                              <>
                                <Index.DeleteIcon
                                  color="error"
                                  fontSize="small"
                                  sx={{ zIndex: 10 }}
                                  onClick={(e) =>
                                    handleRemoveCrewMemberImage(
                                      e,
                                      crewMembarImageName,
                                      editCrewData,
                                      setFieldValue
                                    )
                                  }
                                />
                              </>
                            ) : (
                              <></>
                            )}
                          </Index.Box>
                        </Index.Box>
                      </Index.Grid>
                      <Index.Grid item xs={12} sm={12} md={12}>
                        <Index.Typography
                          variant="label"
                          component="label"
                          className="input-label"
                        >
                          Crew Documents
                        </Index.Typography>
                        <Index.Box className="input-design-div with-border position-relative">
                          <Index.Box className="pdf-design">
                            Upload File :
                          </Index.Box>
                          <Index.TextField
                            fullWidth
                            type="file"
                            name="crewDocuments"
                            multiple
                            id="filled-hidden-label-normal"
                            variant="filled"
                            // onFocus={handleFocus}
                            onBlur={handleBlur}
                            className="admin-input-design input-placeholder pdf-upload-design"
                            onChange={(e) => {
                              const files = Array.from(e.target.files);
                              setFieldValue("crewDocuments", [...files]);
                              const fileNames = files.map((item) => item.name);
                              setCrewDocumentsName(fileNames);
                            }}
                            inputProps={{
                              multiple: true,
                            }}
                            FormHelperTextProps={{
                              className: "input_label_props",
                            }}
                          />
                          {/* {touched?.crewDocuments && errors?.crewMemberImage && (
                          <Index.FormHelperText error>
                            {errors?.crewMemberImage}
                          </Index.FormHelperText>
                        )} */}
                          <br />
                          {crewDocumentsName?.length > 0 &&
                            crewDocumentsName?.map((item, i) => (
                              <>
                                <Index.Box
                                  sx={{
                                    display: "flex",
                                  }}
                                  key={item}
                                >
                                  <Index.Typography
                                    key={i}
                                    variant="label"
                                    component="label"
                                    className="input-label"
                                  >
                                    {i + 1}. {item}
                                  </Index.Typography>

                                  <Index.DeleteIcon
                                    key={i}
                                    color="error"
                                    fontSize="small"
                                    sx={{ zIndex: 100 }}
                                    onClick={() => {
                                      handleRemoveCrewDocument(
                                        item,
                                        editCrewData,
                                        setFieldValue,
                                        values.crewDocuments
                                      );
                                    }}
                                  />
                                </Index.Box>
                              </>
                            ))}
                        </Index.Box>
                      </Index.Grid>

                      <Index.Grid item xs={12} sm={6} md={12}>
                        <Index.Box
                          className="common-button blue-button flex-end save-btn"
                          sx={{
                            display: "flex",
                            alignItems: "flex-end",
                          }}
                        >
                          <Index.Button
                            variant="contained"
                            type="submit"
                            className="Particular_model_submit"
                          >
                            Submit
                          </Index.Button>
                        </Index.Box>
                      </Index.Grid>
                    </Index.Grid>

                    {/* <Index.Box className="line" mt={3} mb={3}></Index.Box> */}
                  </Index.Box>
                </form>
              )}
            </Formik>
          </Index.Box>
        </Index.DialogContent>
      </Index.Dialog>

      {/* Delete Model */}
      <Index.DeleteModal
        deleteOpen={deleteOpen}
        handleDeleteRecord={handleDeleteRecord}
        handleDeleteClose={handleDeleteClose}
        customMessage="Do you want to sign off these record"
        customButtonName="Sign Off"
      />
    </>
  );
}

export default CrewList;
