import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  AddPaymentStatus,
  InvoiceSendMail,
  addInvoiceFile,
  getInvoiceActivityLog,
  getSingleInvoice,
  putInCreditNotInvoice,
} from "../../../../redux/admin/action";
import PropTypes from "prop-types";
import OrderStatusModel from "../../../../component/common/OrderStatusModel";

// Design Tab
function SingleInvoiceTab(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Index.Box sx={{ p: 3 }}>
          <Index.Typography>{children}</Index.Typography>
        </Index.Box>
      )}
    </div>
  );
}

SingleInvoiceTab.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
// Design Tab

const label = { inputProps: { "aria-label": "Switch demo" } };

function SingleInvoice() {
  const [loadingSingleData, setLoadingSingleData] = useState(false);
  const navigate = Index.useNavigate();
  const dispatch = Index.useDispatch();
  const { state } = Index.useLocation();

  const tabType = state?.type;
  const { id } = Index.useParams();
  const { invoiceActivityLogList } = Index.useSelector(
    (state) => state.AdminReducer
  );
  // Design Tab
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [updateOpen, setUpdateOpen] = useState(false);
  const [creditNoteRemark, setCreditNoteRemark] = useState("");

  // Design Tab
  const [loading, setLoading] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState();
  // const [invoiceActiveLog, setInvoiceActiveLog] = useState();
  // let { state } = useLocation();
  const [opensheetmodel, setOpnesheetmodel] = useState(false);
  const [viewData, setViewData] = useState({});
  // let singleData = state?.row;
  const backBtn = () => {
    navigate("/admin/invoice");
  };

  const handleEdit = (row) => {
    navigate("/admin/invoice/add-invoice", {
      state: { row },
    });
  };

  // invoice activity log
  const invoiceActiveLog = () => {
    const invoiceLogData = {
      activityTypeId: id,
      type: "invoice",
    };
    dispatch(getInvoiceActivityLog(invoiceLogData));
  };

  const getSingleInvoiceData = () => {
    setLoadingSingleData(true);
    dispatch(getSingleInvoice({ id })).then((res) => {
      setViewData(res?.data);
      if (res?.status === 200) {
        invoiceActiveLog();
        setTimeout(() => {
          setLoadingSingleData(false);
        }, 1000);
      } else {
        setTimeout(() => {
          setLoadingSingleData(false);
        }, 1000);
      }
    });
  };

  useEffect(() => {
    getSingleInvoiceData();
  }, [paymentStatus]);

  // initialValues for upload file
  let initialValues = {
    image: [],
  };

  const handleFormSubmit = (values) => {
    const data = new FormData();
    values.image.forEach((ele, i) => {
      data.append("image", ele);
    });
    data.append("id", viewData._id);
    dispatch(addInvoiceFile(data)).then((response) => {
      if (response) {
        getSingleInvoiceData();
      }
    });
    handleSheetClose();
  };

  // console.log(viewData, 'viewData')
  const sendMail = () => {
    setLoading(true);
    const data = new URLSearchParams();
    data.append("id", viewData._id);
    dispatch(InvoiceSendMail(data)).then(() => {
      setLoading(false);
      invoiceActiveLog();
    });
  };

  // Validation schema
  // const supportedFileTypes = [
  //   "image/jpeg",
  //   "image/png",
  //   "image/gif",
  //   "application/pdf",
  // ];

  const validationSchema = Yup.object().shape({
    image: Yup.array()
      .min(1, "Select at least one pdf or image")
      .test("fileType", "Unsupported file format", (value) => {
        // Check if each file has a valid image extension
        if (!value) return false;
        for (const file of value) {
          const validExtensions = ["jpg", "jpeg", "png", "gif", "pdf"]; // Add more valid extensions as needed
          const fileExtension = file.name.split(".").pop().toLowerCase();
          if (!validExtensions.includes(fileExtension)) {
            return false;
          }
        }
        return true;
      }),
  });

  // for file open close model
  const handleClickSheetOpen = () => {
    setOpnesheetmodel(true);
  };
  const handleSheetClose = () => {
    setOpnesheetmodel(false);
  };

  // payment status toggle
  const handlePaymentstatus = () => {
    const data = new URLSearchParams();
    data.append("id", viewData?._id);
    dispatch(AddPaymentStatus(data)).then((response) => {
      setPaymentStatus(response);
    });
  };

  const getOverDueDays = (NewDate) => {
    const startDate = Index.moment(new Date());
    var currentDate = Index.moment(NewDate);
    var days = startDate.diff(currentDate, "days");
    return days;
  };

  // filter for activity log
  const filterChallanLogData = invoiceActivityLogList.filter((item) => {
    return item.activityTypeId === viewData?._id;
  });

  useEffect(() => {
    invoiceActiveLog();
  }, []);

  // add credit not
  const addCreditNotInvoiceBtn = (comment, remark) => {
    const data = new URLSearchParams();
    data.append("id", id);
    data.append("creditNote", !viewData?.creditNote);
    data.append("creditNoteRemark", remark);

    dispatch(putInCreditNotInvoice(data)).then((res) => {
      if (res) {
        getSingleInvoiceData();
        // dispatch(getCreditNotInvoice());
      }
    });
  };

  // credit note reverte

  const creditNoteRevertedHandler = () => {
    const data = new URLSearchParams();
    data.append("id", id);
    data.append("creditNote", !viewData?.creditNote);
    dispatch(putInCreditNotInvoice(data)).then((res) => {
      if (res) {
        getSingleInvoiceData();
      }
    });
  };

  // cancel order popup
  const handleOpen = () => {
    // setAnchorEl(null);
    setUpdateOpen(true);
  };

  const handleClose = () => {
    setUpdateOpen(false);
    setCreditNoteRemark(true);
  };
  return (
    <>
      {loadingSingleData ? (
        <>
          <Index.Table>
            <Index.TableBody>
              <Index.TableRow>
                <Index.TableCell
                  colSpan={13}
                  align="center"
                  className="loading-purchase"
                >
                  <Index.CircularProgress color="secondary" size={20} />
                </Index.TableCell>
              </Index.TableRow>
            </Index.TableBody>
          </Index.Table>
        </>
      ) : (
        <>
          <Index.Box className="invoice-details">
            <Index.Box className="barge-common-box mb-20">
              <Index.Box className="title-header-flex flex-column-res">
                <Index.Box className="invoice-back-title">
                  <Index.Button
                    type="submit"
                    className="Particular_model_submit back-invoice-btn"
                    onClick={backBtn}
                  >
                    <Index.ArrowBackIcon />
                  </Index.Button>
                  <Index.Typography
                    variant="span"
                    component="span"
                    className="invoice-title"
                  >
                    INVOICE
                  </Index.Typography>
                </Index.Box>
                {/* {viewData?.creditNote && <></>} */}
                <Index.Box className="common-button blue-button save-btn res-button-width">
                  {!viewData?.creditNote && (
                    <>
                      <Index.Button
                        variant="contained"
                        type="submit"
                        className="Particular_model_submit view-invoice-btn"
                        onClick={(e) => {
                          handleOpen();
                        }}
                      >
                        Add Credit Note
                      </Index.Button>
                    </>
                  )}
                  {viewData?.creditNote && tabType != "invoice" && (
                    <>
                      <Index.Button
                        variant="contained"
                        type="submit"
                        className="Particular_model_submit view-invoice-btn"
                        onClick={(e) => {
                          creditNoteRevertedHandler();
                        }}
                      >
                        Remove As Credit Note
                      </Index.Button>
                    </>
                  )}

                  <Index.Button
                    variant="contained"
                    type="submit"
                    className="Particular_model_submit view-invoice-btn"
                    onClick={sendMail}
                  >
                    {loading ? (
                      <Index.CircularProgress color="secondary" size={20} />
                    ) : (
                      "Send Mail"
                    )}
                  </Index.Button>

                  <Index.Button
                    variant="outlined"
                    onClick={handleClickSheetOpen}
                    className="view-invoice-btn"
                  >
                    Upload File
                  </Index.Button>
                  <Index.Dialog
                    open={opensheetmodel}
                    onClose={handleSheetClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <Index.DialogContent>
                      <Index.Box className="title-main mb-10">
                        <Formik
                          enableReinitialize
                          initialValues={initialValues}
                          validationSchema={validationSchema}
                          onSubmit={handleFormSubmit}
                        >
                          {({
                            values,
                            errors,
                            touched,
                            setFieldValue,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                          }) => (
                            <form onSubmit={handleSubmit}>
                              <Index.Box
                                className="title-main"
                                sx={{ marginTop: "10px" }}
                              >
                                <Index.ClearIcon
                                  className="model-close-icon"
                                  onClick={handleSheetClose}
                                />
                                <Index.Typography
                                  variant="label"
                                  component="label"
                                  className="input-label"
                                >
                                  Upload File
                                </Index.Typography>
                                <Index.Grid container spacing={2}>
                                  <Index.Grid item xs={6} sm={6} md={6}>
                                    <Index.Box className="input-design-div with-border position-relative">
                                      <Index.Box className="pdf-design">
                                        Upload File
                                      </Index.Box>
                                      <Index.TextField
                                        fullWidth
                                        type="file"
                                        name="image"
                                        multiple
                                        id="filled-hidden-label-normal"
                                        variant="filled"
                                        className="admin-input-design input-placeholder pdf-upload-design"
                                        onChange={(event) => {
                                          const files = Array.from(
                                            event.target.files
                                          );
                                          setFieldValue("image", [...files]);
                                        }}
                                        inputProps={{
                                          multiple: true,
                                        }}
                                        FormHelperTextProps={{
                                          className: "input_label_props",
                                        }}
                                      />
                                    </Index.Box>
                                    {errors.image && (
                                      <Index.FormHelperText error>
                                        {errors.image}
                                      </Index.FormHelperText>
                                    )}
                                  </Index.Grid>
                                  <Index.Grid item xs={6} sm={6} md={6}>
                                    <Index.Box
                                      className="common-button blue-button flex-end save-btn"
                                      sx={{
                                        display: "flex",
                                        alignItems: "flex-end",
                                      }}
                                    >
                                      <Index.Button
                                        variant="contained"
                                        type="submit"
                                        className="Particular_model_submit"
                                      >
                                        Submit
                                      </Index.Button>
                                    </Index.Box>
                                  </Index.Grid>
                                  <Index.Grid item xs={6} sm={6} md={6}>
                                    {values?.image?.map((items, index) => {
                                      return (
                                        <Index.Typography>
                                          {items.name}
                                        </Index.Typography>
                                      );
                                    })}
                                  </Index.Grid>
                                </Index.Grid>

                                <Index.Box
                                  className="line"
                                  mt={3}
                                  mb={3}
                                ></Index.Box>
                              </Index.Box>
                            </form>
                          )}
                        </Formik>
                      </Index.Box>
                    </Index.DialogContent>
                  </Index.Dialog>
                  <Index.Button
                    variant="contained"
                    type="submit"
                    className="Particular_model_submit edit-invoice-btn"
                    onClick={() => handleEdit(viewData)}
                  >
                    {/* Edit */}
                    <Index.EditIcon />
                  </Index.Button>
                </Index.Box>
              </Index.Box>

              <Index.Box className="invoice-total">
                <Index.Box className="input-label opacity-60">
                  Invoice total :
                </Index.Box>
                <Index.Typography
                  variant="p"
                  component="p"
                  className="invoice-total-amount"
                >
                  ₹{" "}
                  {viewData?.invoiceAmount
                    ? viewData?.invoiceAmount?.toLocaleString("en-IN", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : 0}
                </Index.Typography>
              </Index.Box>

              <Index.Box className="mt-10">
                <Index.Box className="mb-5 invoice-detail-item">
                  <Index.Box className="input-label opacity-60">
                    Invoice ID :
                  </Index.Box>
                  <Index.Box className="input-label">
                    {viewData?.invoiceNo}
                  </Index.Box>
                </Index.Box>

                <Index.Box className="mb-5 invoice-detail-item">
                  <Index.Box className="input-label opacity-60">
                    Invoice Date :
                  </Index.Box>
                  <Index.Box className="input-label">
                    {Index.moment(viewData?.invoiceDate).format("DD/MM/YYYY")}
                  </Index.Box>
                </Index.Box>

                <Index.Box className="mb-5 invoice-detail-item">
                  <Index.Box className="input-label opacity-60">
                    Party Name :
                  </Index.Box>
                  <Index.Box className="input-label">
                    {viewData?.name?.partyName}
                  </Index.Box>
                </Index.Box>

                <Index.Box className="mb-5 invoice-detail-item">
                  <Index.Box className="input-label opacity-60">
                    Port :
                  </Index.Box>
                  <Index.Box className="input-label">
                    {viewData?.port
                      ? Array.prototype.map
                          .call(viewData?.port, function (item) {
                            return item?.portName;
                          })
                          .join(", ")
                      : ""}
                  </Index.Box>
                </Index.Box>

                <Index.Box className="invoice-detail-item">
                  <Index.Box className="input-label opacity-60">
                    Exmi Or Delivered :
                  </Index.Box>
                  <Index.Box className="input-label">
                    {viewData?.exmiOrDelivered}
                  </Index.Box>
                </Index.Box>

                <Index.Box className="invoice-detail-item">
                  <Index.Box className="input-label opacity-60">
                    Credit Note Status :
                  </Index.Box>
                  <Index.Box className="input-label">
                    {viewData?.creditNote ? "True" : "False"}
                  </Index.Box>
                </Index.Box>

                <Index.Box className="mb-5 invoice-detail-item">
                  <Index.Box className="input-label opacity-60">
                    Payment Status :
                  </Index.Box>
                  <Index.Box className="input-label">
                    <Index.Switch
                      {...label}
                      checked={viewData?.isPaid}
                      onClick={handlePaymentstatus}
                      color="success"
                    />
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>

            <Index.Box className="mb-20">
              {/* Design Tab */}
              <Index.Box sx={{ width: "100%" }}>
                <Index.Box
                  className="tab-button-box"
                  sx={{ borderBottom: 1, borderColor: "divider" }}
                >
                  <Index.Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    className="dashboard-tab"
                  >
                    <Index.Tab
                      className="tab-button"
                      label="Details"
                      {...a11yProps(0)}
                    />
                    <Index.Tab
                      className="tab-button"
                      label="Services"
                      {...a11yProps(1)}
                    />
                    <Index.Tab
                      className="tab-button"
                      label="Files"
                      {...a11yProps(2)}
                    />
                    <Index.Tab
                      className="tab-button"
                      label="Activity"
                      {...a11yProps(3)}
                    />
                  </Index.Tabs>
                </Index.Box>

                <SingleInvoiceTab
                  value={value}
                  index={0}
                  className="barge-common-box tab-content-box"
                >
                  <Index.Grid container spacing={1}>
                    <Index.Grid item xs={12} sm={6}>
                      <Index.Box className="invoice-detail-item">
                        <Index.Box className="input-label opacity-60">
                          BDN :
                        </Index.Box>
                        <Index.Box className="input-label">
                          {viewData?.BDN}
                        </Index.Box>
                      </Index.Box>
                    </Index.Grid>

                    <Index.Grid item xs={12} sm={6}>
                      <Index.Box className="invoice-detail-item">
                        <Index.Box className="input-label opacity-60">
                          Tankers Or Pipeline :
                        </Index.Box>
                        <Index.Box className="input-label">
                          {viewData?.takersOrPipeline}
                        </Index.Box>
                      </Index.Box>
                    </Index.Grid>

                    <Index.Grid item xs={12} sm={6}>
                      <Index.Box className="invoice-detail-item">
                        <Index.Box className="input-label opacity-60">
                          PI_No :
                        </Index.Box>
                        <Index.Box className="input-label">
                          {viewData?.PI_No}
                        </Index.Box>
                      </Index.Box>
                    </Index.Grid>

                    <Index.Grid item xs={12} sm={6}>
                      <Index.Box className="invoice-detail-item">
                        <Index.Box className="input-label opacity-60">
                          Name Of Vessel :
                        </Index.Box>
                        <Index.Box className="input-label">
                          {viewData?.vesselName}
                        </Index.Box>
                      </Index.Box>
                    </Index.Grid>

                    <Index.Grid item xs={12} sm={6}>
                      <Index.Box className="invoice-detail-item">
                        <Index.Box className="input-label opacity-60">
                          PO_No :
                        </Index.Box>
                        <Index.Box className="input-label">
                          {viewData?.PO_No}
                        </Index.Box>
                      </Index.Box>
                    </Index.Grid>

                    {/* <Index.Grid item xs={12} sm={6}>
                  <Index.Box className="invoice-detail-item">
                    <Index.Box className="input-label opacity-60">
                      Month :
                    </Index.Box>
                    <Index.Box className="input-label">
                      {viewData?.month}
                    </Index.Box>
                  </Index.Box>
                </Index.Grid> */}

                    <Index.Grid item xs={12} sm={6}>
                      <Index.Box className="invoice-detail-item">
                        <Index.Box className="input-label opacity-60">
                          Over Due (InDays) :
                        </Index.Box>
                        <Index.Box className="input-label">
                          {viewData?.isPaid === false
                            ? getOverDueDays(viewData?.invoiceDate) + " Days"
                            : "-"}
                        </Index.Box>
                      </Index.Box>
                    </Index.Grid>
                  </Index.Grid>
                </SingleInvoiceTab>

                <SingleInvoiceTab
                  value={value}
                  index={1}
                  className="barge-common-box tab-content-box"
                >
                  <Index.Box className="">
                    <Index.TableContainer
                      component={Index.Paper}
                      className="table-container"
                    >
                      <Index.Table
                        aria-label="simple table"
                        className="table-design-main addinvoice-table signleinvoices-view  signleinvoices-view-set"
                      >
                        <Index.TableHead>
                          <Index.TableRow>
                            <Index.TableCell>Items</Index.TableCell>
                            <Index.TableCell>
                              Particulars (Services)
                            </Index.TableCell>
                            <Index.TableCell>GST Rate</Index.TableCell>
                            <Index.TableCell>
                              Interstate/Intrastate
                            </Index.TableCell>
                            <Index.TableCell>Qty In MT</Index.TableCell>
                            <Index.TableCell>Taxable Value</Index.TableCell>
                          </Index.TableRow>
                        </Index.TableHead>
                        <Index.TableBody>
                          {viewData.invoiceManagement?.length > 0 &&
                            viewData.invoiceManagement.map((row, index) => (
                              <>
                                <Index.TableRow key={index}>
                                  <Index.TableCell>
                                    <Index.Box>
                                      <Index.Box className="input-design-div with-border ">
                                        <Index.Typography
                                          variant="label"
                                          component="label"
                                          className="input-label-invoice"
                                        >
                                          {row?.items ? row?.items : "-"}
                                        </Index.Typography>
                                      </Index.Box>
                                    </Index.Box>
                                  </Index.TableCell>

                                  <Index.TableCell>
                                    <Index.Box className="input-design-div with-border">
                                      <Index.Typography
                                        variant="label"
                                        component="label"
                                        className="input-label-invoice"
                                      >
                                        {row?.particulars?.particular}
                                      </Index.Typography>
                                    </Index.Box>
                                  </Index.TableCell>

                                  <Index.TableCell>
                                    <Index.Box className="input-design-div with-border">
                                      <Index.Typography
                                        variant="label"
                                        component="label"
                                        className="input-label-invoice"
                                      >
                                        {row?.rate}
                                      </Index.Typography>
                                    </Index.Box>
                                  </Index.TableCell>

                                  <Index.TableCell>
                                    <Index.Box className="input-design-div with-border">
                                      <Index.Typography
                                        variant="label"
                                        component="label"
                                        className="input-label-invoice"
                                      >
                                        {row?.interState_intraState}
                                      </Index.Typography>
                                    </Index.Box>
                                  </Index.TableCell>

                                  <Index.TableCell>
                                    <Index.Box className="input-design-div with-border">
                                      <Index.Typography
                                        variant="label"
                                        component="label"
                                        className="input-label-invoice"
                                      >
                                        {row?.qtyInMT
                                          ? row?.qtyInMT.toLocaleString(
                                              "en-IN",
                                              {
                                                minimumFractionDigits: 3,
                                                maximumFractionDigits: 3,
                                              }
                                            )
                                          : 0?.toLocaleString("en-IN", {
                                              minimumFractionDigits: 3,
                                              maximumFractionDigits: 3,
                                            })}
                                      </Index.Typography>
                                    </Index.Box>
                                  </Index.TableCell>

                                  <Index.TableCell>
                                    <Index.Box className="input-design-div with-border">
                                      <Index.Typography
                                        variant="label"
                                        component="label"
                                        className="input-label-invoice"
                                      >
                                        {row?.taxableValue?.toLocaleString(
                                          "en-IN",
                                          {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                          }
                                        )}
                                      </Index.Typography>
                                    </Index.Box>
                                  </Index.TableCell>
                                </Index.TableRow>
                              </>
                            ))}
                        </Index.TableBody>
                      </Index.Table>
                    </Index.TableContainer>
                  </Index.Box>

                  <Index.Box
                    className="line dark-line"
                    mt={2}
                    mb={3}
                  ></Index.Box>

                  <Index.Box className="total-service res-total-view-invoice">
                    <Index.Box className="service-left">
                      <Index.Box className="single-invoice-taxable">
                        <Index.Box className="mb-15 service-left-row">
                          <Index.Typography
                            variant="label"
                            component="label"
                            className="input-label"
                          >
                            Date Of Supply
                          </Index.Typography>
                          <Index.Box className="input-design-div">
                            <Index.Box className="set-date set-date-picker">
                              <Index.Typography
                                variant="label"
                                component="label"
                                className="input-label-invoice"
                              >
                                {Index.moment(viewData?.dateOfSupply).format(
                                  "DD/MM/YYYY"
                                )}
                              </Index.Typography>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>

                        <Index.Box className="service-left-row">
                          <Index.Typography
                            variant="label"
                            component="label"
                            className="input-label"
                          >
                            Barge
                          </Index.Typography>
                          <Index.Box className="input-design-div with-border">
                            <Index.Typography
                              variant="label"
                              component="label"
                              className="input-label"
                            >
                              {viewData?.barges
                                ? Array.prototype.map
                                    .call(viewData?.barges, function (item) {
                                      return item.bargeName;
                                    })
                                    .join(", ")
                                : ""}
                            </Index.Typography>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>

                    <Index.Box className="service-right">
                      <Index.Box className="payment-row">
                        <Index.Typography
                          varient="h6"
                          component="h6"
                          className="amount-title"
                        >
                          Taxable Value
                        </Index.Typography>
                        <Index.Typography
                          varient="h6"
                          component="h6"
                          className="amount-number"
                        >
                          ₹{" "}
                          {viewData?.totalTaxableValue
                            ? viewData?.totalTaxableValue.toLocaleString(
                                "en-IN",
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }
                              )
                            : 0}
                        </Index.Typography>
                      </Index.Box>

                      {viewData.invoiceManagement?.length > 0 &&
                        viewData.invoiceManagement.map((row, index) => (
                          <>
                            <Index.Box
                              key={index}
                              className="service-total-div"
                            >
                              <Index.Box className="payment-row">
                                <Index.Typography
                                  varient="h6"
                                  component="h6"
                                  className="amount-title"
                                >
                                  CGST (
                                  {row?.interState_intraState === "Intrastate"
                                    ? row?.rate / 2
                                    : 0}
                                  %)
                                </Index.Typography>
                                <Index.Typography
                                  varient="h6"
                                  component="h6"
                                  className="amount-number"
                                >
                                  ₹{" "}
                                  {row?.CGST
                                    ? row?.CGST.toLocaleString("en-IN", {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      })
                                    : 0?.toLocaleString("en-IN", {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      })}
                                </Index.Typography>
                              </Index.Box>

                              <Index.Box className="payment-row">
                                <Index.Typography
                                  varient="h6"
                                  component="h6"
                                  className="amount-title"
                                >
                                  SGST (
                                  {row?.interState_intraState === "Intrastate"
                                    ? row?.rate / 2
                                    : 0}
                                  %)
                                </Index.Typography>
                                <Index.Typography
                                  varient="h6"
                                  component="h6"
                                  className="amount-number"
                                >
                                  ₹{" "}
                                  {row?.SGST
                                    ? row?.SGST.toLocaleString("en-IN", {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      })
                                    : 0?.toLocaleString("en-IN", {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      })}
                                </Index.Typography>
                              </Index.Box>

                              <Index.Box className="payment-row">
                                <Index.Typography
                                  varient="h6"
                                  component="h6"
                                  className="amount-title"
                                >
                                  IGST (
                                  {row?.interState_intraState === "Interstate"
                                    ? row?.rate
                                    : 0}
                                  %)
                                </Index.Typography>
                                <Index.Typography
                                  varient="h6"
                                  component="h6"
                                  className="amount-number"
                                >
                                  ₹{" "}
                                  {row?.IGST
                                    ? row?.IGST.toLocaleString("en-IN", {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      })
                                    : 0?.toLocaleString("en-IN", {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      })}
                                </Index.Typography>
                              </Index.Box>
                            </Index.Box>
                          </>
                        ))}

                      <Index.Box className="payment-row service-main-total">
                        <Index.Typography
                          varient="h6"
                          component="h6"
                          className="amount-title"
                        >
                          Invoice Amount
                        </Index.Typography>
                        <Index.Typography
                          varient="h6"
                          component="h6"
                          className="amount-number"
                        >
                          ₹{" "}
                          {viewData?.invoiceAmount
                            ? viewData?.invoiceAmount.toLocaleString("en-IN", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })
                            : 0?.toLocaleString("en-IN", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                        </Index.Typography>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </SingleInvoiceTab>

                <SingleInvoiceTab
                  value={value}
                  index={2}
                  className="barge-common-box tab-content-box"
                >
                  {viewData?.image?.length === 0 ? (
                    <Index.Box className="invoice-file-empty-data">
                      No Data Available
                    </Index.Box>
                  ) : (
                    <Index.ImageList cols={4}>
                      {viewData?.image &&
                        viewData?.image.map((item, index) => {
                          let fileExtension = item
                            ?.split(".")
                            .pop()
                            .toLowerCase();
                          return fileExtension == "pdf" ? (
                            <Index.ImageListItem key={index}>
                              <a
                                href={`${
                                  process.env.REACT_APP_IMAGE_URL
                                }/invoices/${item ? item : ""}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Click PDF
                              </a>
                            </Index.ImageListItem>
                          ) : (
                            <Index.ImageListItem key={index}>
                              <img
                                src={`${
                                  process.env.REACT_APP_IMAGE_URL
                                }/invoices/${item ? item : ""}`}
                                alt={"Image Preview"}
                              />
                            </Index.ImageListItem>
                          );
                        })}
                    </Index.ImageList>
                  )}
                </SingleInvoiceTab>

                <SingleInvoiceTab
                  value={value}
                  index={3}
                  className="barge-common-box tab-content-box"
                >
                  <Index.TableContainer>
                    {/* <Index.Typography component="h1" className="activity-log-header">
                  <b>Activity Log</b>
                </Index.Typography> */}

                    <Index.Table className="invoice-activity-log">
                      <Index.TableHead>
                        <Index.TableRow>
                          <Index.TableCell>Activity Type</Index.TableCell>
                          <Index.TableCell>Details</Index.TableCell>
                          <Index.TableCell>Date & Time</Index.TableCell>
                        </Index.TableRow>
                      </Index.TableHead>
                      <Index.TableBody>
                        {filterChallanLogData &&
                          filterChallanLogData?.map((items, index) => {
                            return (
                              <Index.TableRow>
                                <Index.TableCell>
                                  <Index.Typography>
                                    {items?.activityType}
                                  </Index.Typography>
                                </Index.TableCell>
                                <Index.TableCell>
                                  <Index.Typography>
                                    {items?.details}
                                  </Index.Typography>
                                </Index.TableCell>
                                <Index.TableCell>
                                  <Index.Typography>
                                    {Index.moment(items?.createdAt).format(
                                      "DD/MM/YYYY h:mm:ss a"
                                    )}
                                  </Index.Typography>
                                </Index.TableCell>
                              </Index.TableRow>
                            );
                          })}
                      </Index.TableBody>
                    </Index.Table>
                  </Index.TableContainer>
                </SingleInvoiceTab>
              </Index.Box>
              {/* Design Tab */}
            </Index.Box>
          </Index.Box>
        </>
      )}

      <OrderStatusModel
        updateOpen={updateOpen}
        handleClose={handleClose}
        inquiryStatusUpdate={addCreditNotInvoiceBtn}
        modalType={"Remark"}
      />
    </>
  );
}

export default SingleInvoice;
