export const terminalData = [
  {
    id: 1,
    item: "OLEO",
  },
  {
    id: 2,
    item: "IOCL",
  },
  {
    id: 3,
    item: "BPCL",
  },
  {
    id: 4,
    item: "HPCL",
  },
  {
    id: 5,
    item: "LINK",
  },
  {
    id: 6,
    item: "BC",
  },
  {
    id: 7,
    item: "NAYARA",
  },
  {
    id: 8,
    item: "RELIANCE",
  },
];