import { useEffect, useState } from "react";
import Index from "../../../Index";
import { getDocumentsRemarkHistoryLog } from "../../../../redux/admin/action";

function RemarkHistory() {
  const dispatch = Index.useDispatch();
  const navigate = Index.useNavigate();
  const params = Index.useParams();

  const [loading, setLoading] = useState(false);
  const [remarkHistoryList, setRemarkHistoryList] = useState([]);

  // when the user get the remark history then used...
  const handleGetRemarkHistory = () => {
    setLoading(true);
    // getDocumentsRemarkHistoryLog
    dispatch(getDocumentsRemarkHistoryLog(params?.id))
      .then((res) => {
        if (res.status === 200) {
          setRemarkHistoryList(res.data);
          setLoading(false);
        } else {
          setRemarkHistoryList([]);
          setLoading(false);
        }
      })
      .catch((error) => {
        setRemarkHistoryList([]);
        setLoading(false);
        console.error(error);
      });
  };

  // when the page first time load then used...
  useEffect(() => {
    handleGetRemarkHistory();
  }, []);

  const backBtn = () => {
    navigate("/admin/documentCenter");
  };

  return (
    <>
      <Index.DynamicTitle title="Remark History" />
      <Index.Box className="page-content-box">
        <Index.Box className="barge-common-box">
          <Index.Box className="title-header">
            <Index.Box className="title-header-flex res-title-header-flex">
              <Index.Box className="title-main document-center-title">
                <Index.Typography
                  variant="p"
                  component="p"
                  className="page-title"
                >
                  Remark History
                </Index.Typography>
              </Index.Box>
              <Index.Box className="common-button blue-button save-btn ">
                <Index.Button
                  variant="contained"
                  type="submit"
                  className="Particular_model_submit ml-0"
                  onClick={backBtn}
                >
                  Back
                </Index.Button>
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Index.Box className="">
            <Index.TableContainer
              component={Index.Paper}
              className="table-container"
            >
              <Index.Table
                aria-label="simple table"
                className="table-design-main barge-table"
              >
                <Index.TableHead>
                  <Index.TableRow>
                    <Index.TableCell align="left">Barge / Tugs</Index.TableCell>
                    <Index.TableCell align="left">Remark</Index.TableCell>
                    <Index.TableCell align="left">Date</Index.TableCell>
                    <Index.TableCell align="left">Name</Index.TableCell>
                  </Index.TableRow>
                </Index.TableHead>
                {loading ? (
                  <Index.TableBody>
                    <Index.TableRow>
                      <Index.TableCell
                        colSpan={12}
                        align="center"
                        className="loading-purchase"
                      >
                        <Index.CircularProgress color="secondary" size={20} />
                      </Index.TableCell>
                    </Index.TableRow>
                  </Index.TableBody>
                ) : (
                  <Index.TableBody className="tanker-table-body">
                    {remarkHistoryList?.length ? (
                      remarkHistoryList?.map((row, index) => (
                        <Index.TableRow key={row?._id + (index + 1)}>
                          <Index.TableCell>
                            {row?.craftDocumentId?.title
                              ? row?.craftDocumentId?.title
                              : "-"}
                          </Index.TableCell>
                          <Index.TableCell align="left">
                            {row?.status ? row?.status : "-"}
                          </Index.TableCell>
                          <Index.TableCell>
                            {row?.date
                              ? Index.moment(row.date).format(
                                  "DD/MM/YYYY hh:mm"
                                )
                              : "-"}
                          </Index.TableCell>
                          <Index.TableCell>
                            {row?.addedBy
                              ? row.addedBy?.firstName +
                                " " +
                                row.addedBy?.lastName
                              : "-"}
                          </Index.TableCell>
                        </Index.TableRow>
                      ))
                    ) : (
                      <Index.TableRow>
                        <Index.TableCell
                          component="td"
                          variant="td"
                          scope="row"
                          className="no-data-in-list"
                          colSpan={12}
                          align="center"
                        >
                          No data available
                        </Index.TableCell>
                      </Index.TableRow>
                    )}
                  </Index.TableBody>
                )}
              </Index.Table>
            </Index.TableContainer>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
}

export default RemarkHistory;
