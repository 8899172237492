import React, { useEffect, useRef, useState } from "react";
import Index from "../../../Index";
import { styled, alpha } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContentText from "@mui/material/DialogContentText";
import MenuIcon from "@mui/icons-material/MoreVert";
import InputBase from "@mui/material/InputBase";
import { useDispatch, useSelector } from "react-redux";
import { DialogContent, DialogTitle, IconButton, Menu, MenuItem, SwipeableDrawer } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { Formik, useFormikContext } from "formik";
import { toast } from "react-toastify";
import APIService from "../../../../redux/APIService";
import * as Yup from "yup";
import { IntegrationInstructions } from "@mui/icons-material";

const ActionItem = styled(MenuItem)(() => ({
  fontFamily: "poppins",
  lineHeight: "15px",
  fontSize: "14px",
  fontWeight: "400",
  color: "#595F69",
}));

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

function ParticularModel({ particularOpen, handleClose }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [actionIndex, setActionIndex] = useState();
  const [open, setOpen] = React.useState(false);

  // edit state
  const [editdata, setEditdata] = useState();
  const [particularsData, setParticularDate] = useState([]);
  const [rowId, setRowId] = useState("");

  // redux data
  const particularList = useSelector(
    (state) => state.AdminReducer.particularList
  );

  // initialValues
  let initialValues = {
    particular: "",
    rate: "",
  };

  // handle edit button
  const handleEdit = (row) => {
    setEditdata(row);
    setAnchorEl(null);
  };
  // edit value
  if (editdata) {
    initialValues = editdata;
  } else {
    initialValues = {
      particular: "",
      rate: "",
    };
  }
  // Validation schema
const digitsOnly = (value) =>
  /^\d*(?:\.\d*)?$/.test(value) || value?.length === 0;

  const validationSchema = Yup.object().shape({
    particular: Yup.string()
      .required("Particular is required")
      .max(30, "Particular allows maximum 30 charaters"),
    rate: Yup.string()
      .required("Rate is required")
      .test("rate", "Rate should have digits only", digitsOnly),
  });
  const handleClickMenu = (e, index) => {
    setActionIndex(index);
    setAnchorEl(e.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  // initialValues = {
  //   particular: "",
  //   rate: "",
  // };
  const handleDeleteOpen = () => {
    setAnchorEl(null);
    setDeleteOpen(true);
  };

  const handleDeleteClose = () => {
    setDeleteOpen(false);
  };

  const handleDeleteRecord = () => {
    setDeleteOpen(false);
    deleteParticular(rowId);
  };

  const [deleteOpen, setDeleteOpen] = useState(false);

  // rate model submit handler
  const handleFormSubmit = (values) => {
    let data = new URLSearchParams();
    data.append("particular", values.particular);
    data.append("rate", values.rate);

    if (editdata) {
      data.append("id", editdata?._id, data);
    }
    addParticular(data);
    values.particular = "";
    values.rate = "";
    
  };

  // add particular
  const addParticular = async (data) => {
    const response = await APIService.doPost(
      "/admin/add-edit-particular",
      data
    );
    if (response?.data?.status === 200 || response?.data?.status === 201) {
      toast.success(response.data.message);
      getParticular();
    } else {
      toast.error(response.response.data.message);
    }
  };

  // delete particular
  const deleteParticular = async (rowId) => {
    const response = await APIService.doPost("/admin/delete-particular", {
      id: rowId,
    });
    if (response?.data?.status === 200 || response?.data?.status === 201) {
      toast.success(response.data.message);
      getParticular();
    } else {
      toast.error(response.response.data.message);
    }
  };

  // get particular
  const getParticular = async () => {
    const response = await APIService.doGet("/admin/getall-particular");
    if (response?.data?.status === 200 || response?.data?.status === 201) {
      setParticularDate(response.data.data);
    } else {
      toast.error(response.response.data.message);
    }
  };

  useEffect(() => {
    getParticular();
    return () => {
    };
  }, []);

  return (
    <div>
      <Dialog
        open={particularOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" className="title-main">
          <Index.Typography variant="p" component="p" className="page-title">
            GST Rate Management
          </Index.Typography>
        </DialogTitle>
        <DialogContent>
          <Index.Box className="title-main mb-10">
            <Formik
              enableReinitialize
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleFormSubmit}
            >
              {({
                values,
                errors,
                touched,
                setFieldValue,
                handleChange,
                handleBlur,
                handleSubmit,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Index.Box className="title-main" sx={{ marginTop: "10px" }}>
                    <Index.Grid container spacing={2}>
                      <Index.Grid item xs={12} sm={6} md={6}>
                        <Index.Typography
                          variant="label"
                          component="label"
                          className="input-label"
                        >
                          Particulars
                        </Index.Typography>
                        <Index.Box className="input-design-div with-border">
                          <Index.TextField
                            hiddenLabel
                            id="filled-hidden-label-normal"
                            placeholder="Particulars"
                            variant="filled"
                            className="admin-input-design input-placeholder"
                            name="particular"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values?.particular}
                            error={Boolean(
                              errors.particular ? touched.particular : undefined
                            )}
                            helperText={
                              touched.particular ? errors.particular : undefined
                            }
                          />
                        </Index.Box>
                      </Index.Grid>

                      <Index.Grid item xs={12} sm={6} md={6}>
                        <Index.Typography
                          variant="label"
                          component="label"
                          className="input-label"
                        >
                          Rate
                        </Index.Typography>
                        <Index.Box className="input-design-div with-border">
                          {}
                          <Index.TextField
                            hiddenLabel
                            type="number"
                            id="filled-hidden-label-normal"
                            placeholder=" Rate"
                            variant="filled"
                            name="rate"
                            className="admin-input-design input-placeholder"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values?.rate}
                            defaultValue={values?.rate}
                            error={Boolean(
                              errors.rate ? touched.rate : undefined
                            )}
                            helperText={touched.rate ? errors.rate : undefined}
                          />
                        </Index.Box>
                      </Index.Grid>
                      <Index.Grid item xs={12} sm={12} md={12}>
                        <Index.Box
                          className="common-button blue-button flex-end save-btn"
                          sx={{ display: "flex", alignItems: "flex-end" }}
                        >
                          <Index.Button
                            variant="contained"
                            type="submit"
                            className="Particular_model_submit"
                          >
                            Submit
                          </Index.Button>
                        </Index.Box>
                      </Index.Grid>
                    </Index.Grid>

                    <Index.Box className="line" mt={3} mb={3}></Index.Box>
                  </Index.Box>
                </form>
              )}
            </Formik>
          </Index.Box>
          <Index.Box className="barge-common-box">
            <Index.TableContainer
              component={Index.Paper}
              className="table-container"
            >
              <Index.Table
                aria-label="simple table"
                className="table-design-main "
              >
                <Index.TableHead>
                  <Index.TableRow>
                    <Index.TableCell className="invoice_table_header">
                      Particulars
                    </Index.TableCell>
                    <Index.TableCell
                      align="left"
                      className="invoice_table_header"
                    >
                      Rate
                    </Index.TableCell>

                    <Index.TableCell
                      align="left"
                      className="invoice_table_header"
                    >
                      Action
                    </Index.TableCell>
                  </Index.TableRow>
                </Index.TableHead>
                <Index.TableBody>
                  {particularsData?.length ? (
                    particularsData?.map((row, index) => (
                      <Index.TableRow>
                        <Index.TableCell>{row?.particular}</Index.TableCell>
                        <Index.TableCell>{row?.rate}</Index.TableCell>
                        <Index.TableCell sx={{ display: "flex" }}>
                          <IconButton
                            onClick={(e) => {
                              handleClickMenu(e, index);
                            }}
                          >
                            <MenuIcon className="action-menu-icon" />
                          </IconButton>
                          <Menu
                            id="demo-positioned-menu"
                            aria-labelledby="demo-positioned-button"
                            anchorEl={anchorEl}
                            keepMounted
                            elevation={2}
                            open={Boolean(anchorEl)}
                            onClose={handleCloseMenu}
                            anchorOrigin={{
                              vertical: "top",
                              horizontal: "left",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "left",
                            }}
                          >
                            <ActionItem
                              className="action-text"
                              onClick={() =>
                                handleEdit(particularsData?.[actionIndex])
                              }
                            >
                              Edit
                            </ActionItem>

                            <ActionItem
                              className="action-text"
                              onClick={() => {
                                setRowId(particularsData?.[actionIndex]?._id);
                                handleDeleteOpen();
                              }}
                            >
                              Delete
                            </ActionItem>
                          </Menu>
                        </Index.TableCell>
                      </Index.TableRow>
                    ))
                  ) : (
                    <Index.TableRow>
                      <Index.TableCell
                        component="td"
                        variant="td"
                        scope="row"
                        className="no-data-in-list"
                        colSpan={6}
                        align="center"
                      >
                        No data available
                      </Index.TableCell>
                    </Index.TableRow>
                  )}
                </Index.TableBody>
              </Index.Table>
            </Index.TableContainer>
          </Index.Box>
        </DialogContent>
        <Index.Box p={"20px"}>
          <Index.Box className="common-button blue-button flex-end save-btn">
            <Index.Button
              variant="contained"
              type="submit"
              className="Particular_model_submit"
              onClick={() => {
                handleClose();
                // values.particular = "";
                // values.rate = "";
               
                // if (fromRef.current) {
                //   // fromRef.current.clearform();
                //    resetForm();
                // }
              }}
            >
              Cancel
            </Index.Button>
          </Index.Box>
        </Index.Box>
      </Dialog>
      <Index.DeleteModal
        deleteOpen={deleteOpen}
        handleDeleteRecord={handleDeleteRecord}
        handleDeleteClose={handleDeleteClose}
      />
    </div>
  );
}
export default ParticularModel;
