import { useEffect, useState } from "react";
import Index from "../../../../Index";
import { getAllPreventionSubHeadList } from "../../../../../redux/admin/action";

const Search = Index.styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: Index.alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: Index.alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const StyledInputBase = Index.styled(Index.InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

function PreventiveSubHeadList() {
  const dispatch = Index.useDispatch();
  const [preventiveSubHeadList, setPreventiveSubHeadList] = useState([]);
  const [loading, setLoading] = useState(false);

  //  when the user get the subHead listing then used...
  const handleGetSubHeadList = () => {
    setLoading(true);
    dispatch(getAllPreventionSubHeadList())
      .then((res) => {
        if (res.status === 200) {
          setPreventiveSubHeadList(res.data);
          setLoading(false);
        } else {
          setLoading(false);
          setPreventiveSubHeadList([]);
        }
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
        setPreventiveSubHeadList([]);
      });
  };

  // when the page first time load then used...
  useEffect(() => {
    handleGetSubHeadList();
  }, []);

  return (
    <>
      <Index.DynamicTitle title="Users" />
      <Index.Box className="">
        <Index.Box className="barge-common-box">
          <Index.Box className="title-header">
            <Index.Box className="title-header-flex res-title-header-flex">
              <Index.Box className="title-main">
                <Index.Typography
                  variant="p"
                  component="p"
                  className="page-title"
                >
                  Users
                </Index.Typography>
              </Index.Box>
              <Index.Box className="d-flex align-items-center res-set-search">
                {/* <Search className="search ">
                  <StyledInputBase
                    placeholder="Search"
                    inputProps={{ "aria-label": "search" }}
                    // onChange={(e) =>
                    //   setTimeout(() => {
                    //     requestSearch(e.target.value.trim());
                    //   }, 1000)
                    // }
                  />
                </Search> */}

                <Index.Box className="common-button blue-button res-blue-button">
                  <Index.RouteLink
                    to="/admin/preventiveSubhead/add"
                    className="no-text-decoration"
                  >
                    {" "}
                    <Index.Button variant="contained" disableRipple>
                      Add Preventive Sub Head
                    </Index.Button>
                  </Index.RouteLink>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Index.Box className="">
            <Index.TableContainer
              component={Index.Paper}
              className="table-container"
            >
              <Index.Table
                aria-label="simple table"
                className="table-design-main supply-table one-line-table invoice-table-set user-management-table "
              >
                <Index.TableHead>
                  <Index.TableRow>
                    <Index.TableCell>Sr. No(Unique No)</Index.TableCell>
                    <Index.TableCell>Sub Head Name</Index.TableCell>
                  </Index.TableRow>
                </Index.TableHead>
                {loading ? (
                  <Index.TableBody>
                    <Index.TableRow>
                      <Index.TableCell
                        colSpan={12}
                        align="center"
                        className="loading-purchase"
                      >
                        <Index.CircularProgress color="secondary" size={20} />
                      </Index.TableCell>
                    </Index.TableRow>
                  </Index.TableBody>
                ) : (
                  <Index.TableBody>
                    {preventiveSubHeadList?.length ? (
                      preventiveSubHeadList?.map((item, index) => (
                        <Index.TableRow key={item?._id}>
                          <Index.TableCell>{index + 1}</Index.TableCell>
                          <Index.TableCell>
                            {item?.name ? item?.name : "-"}
                          </Index.TableCell>
                        </Index.TableRow>
                      ))
                    ) : (
                      <Index.TableRow>
                        <Index.TableCell
                          component="td"
                          variant="td"
                          scope="row"
                          className="no-data-in-list"
                          colSpan={15}
                          align="center"
                        >
                          No data available
                        </Index.TableCell>
                      </Index.TableRow>
                    )}
                  </Index.TableBody>
                )}
              </Index.Table>
            </Index.TableContainer>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
}

export default PreventiveSubHeadList;
