import { useEffect, useState } from "react";
import Index from "../../../../Index";
import { Formik } from "formik";
import {
  uploadCraftDocumentValidationSchema,
  uploadCraftDocumentHistoryValidationSchema,
} from "../../../../../validation/AdminValidation";
import {
  addEditCraftDocument,
  deleteCraftDocument,
  getAllCraftDocumentList,
  deleteCurrentOrderDocument,
  addDocumentHistory,
} from "../../../../../redux/admin/action";

// style for model
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function CraftDocument({ craftId }) {
  const dispatch = Index.useDispatch();
  const navigate = Index.useNavigate();
  const [craftDocumentsList, setCraftDocumentsList] = useState([]);
  const [openDocumentModel, setOpenDocumentModel] = useState(false);
  const [uploadDocumentName, setUploadDocumentName] = useState("");
  const [openImage, setOpenImage] = useState(false);
  const [documentImage, setDocumentImg] = useState("");

  const [loading, setLoading] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [deleteDocumentId, setDeleteDocumentId] = useState("");
  const [editDocumentData, setEditDocumentData] = useState();

  // State for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState(0);
  const [paginationPerPage, setPaginationPerPage] = useState(50);
  const [currentRows, setCurrentRows] = useState([]);

  const [openDocumentHistoryModal, setOpenDocumentHistoryModal] =
    useState(false);
  const [docuemntHistoryTimeData, setDocumentHistoryTimeData] = useState("");

  const documentHistoryInitialValues = {
    craftDocuments: [],
    expireDate: "",
    expireCheckBox: false,
  };

  // Delete craft document
  const handleDeleteOpen = () => {
    setDeleteOpen(true);
  };

  const handleDeleteClose = () => {
    setDeleteDocumentId("");
    setDeleteOpen(false);
  };

  //Add document model
  const handleModelOpen = () => {
    setOpenDocumentModel(true);
  };

  //show image model

  const handleOpenImage = (img) => {
    setOpenImage(true);
    setDocumentImg(img);
  };
  const handleCloseImage = () => setOpenImage(false);

  let initialValues;
  if (editDocumentData) {
    console.log("editDocumentData", editDocumentData);
    initialValues = editDocumentData;
    if (editDocumentData?.expireCheckBox) {
      initialValues = {
        ...initialValues,
        expireDate: "",
      };
    }
  } else {
    initialValues = {
      title: "",
      craftDocuments: [],
      expireCheckBox: false,
      expireDate: "",
      remainingExpiryDays: 0,
    };
  }

  // when the user add the history side document then used...
  const handleAddHistoryOfDocument = (documentData) => {
    setOpenDocumentHistoryModal(true);
    setDocumentHistoryTimeData(documentData);
  };

  // when the user want to get document's history then used..
  const handleGoToHistoryOfDocuments = (documentDetails, craftId) => {
    navigate(`/admin/craft/document-history/${documentDetails?._id}`, {
      state: { craftId },
    });
  };

  // when the user close the document history modal then used...
  const handleCloseDocumentHistoryModal = () => {
    setOpenDocumentHistoryModal(false);
  };

  const handleFormSubmit = (values) => {
    console.log("form submit event is called....");
    const formData = new FormData();
    if (editDocumentData) {
      formData.append("id", editDocumentData?.id);
    }
    formData.append("craftId", craftId);
    formData.append("title", values?.title);
    formData.append("expireDate", values?.expireDate);
    formData.append("expireCheckBox", values?.expireCheckBox);
    formData.append("remainingExpiryDays", values?.remainingExpiryDays);
    if (!editDocumentData) {
      values?.craftDocuments?.forEach((ele) => {
        formData.append("craftDocuments", ele);
      });
    } else {
      values?.craftDocuments?.forEach((ele) => {
        if (ele instanceof Blob) {
          formData.append("craftDocuments", ele);
        }
      });
    }

    formData.forEach((obj, key) => {
      console.log(obj, ":::", key);
    });

    dispatch(addEditCraftDocument(formData)).then((res) => {
      if (res?.status === 200) {
        fetchAllDocumentsList(craftId);
      }
    });
    handleModelClose();
  };

  const handleFormSubmitForDocumentHistory = (values) => {
    const formData = new FormData();
    if (docuemntHistoryTimeData) {
      formData.append("id", docuemntHistoryTimeData?._id);
    }
    formData.append("craftId", craftId);
    formData.append("expireDate", values?.expireDate);
    formData.append("expireCheckBox", values?.expireCheckBox);

    values?.craftDocuments?.forEach((ele) => {
      if (ele instanceof Blob) {
        formData.append("craftDocuments", ele);
      }
    });

    dispatch(addDocumentHistory(formData))
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          handleCloseDocumentHistoryModal();
          fetchAllDocumentsList(craftId);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  //handle delete document
  const handleDeleteRecord = () => {
    const urlencoded = new URLSearchParams();
    urlencoded.append("id", deleteDocumentId);
    try {
      dispatch(deleteCraftDocument(urlencoded)).then((res) => {
        if (res?.status === 200) {
          fetchAllDocumentsList(craftId);
          setDeleteDocumentId("");
        }
      });
    } catch (error) {}

    handleDeleteClose();
  };

  //get all documents list
  const fetchAllDocumentsList = (id) => {
    setLoading(true);
    const urlencoded = new URLSearchParams();
    urlencoded.append("craftId", id);
    try {
      dispatch(getAllCraftDocumentList(urlencoded)).then((res) => {
        if (res?.status === 200) {
          setCraftDocumentsList(res?.data);
          setTimeout(() => {
            setLoading(false);
          }, 1000);
        } else {
          setCraftDocumentsList([]);
          setTimeout(() => {
            setLoading(false);
          }, 1000);
        }
      });
    } catch (error) {}
  };

  const handleModelClose = () => {
    if (editDocumentData) {
      fetchAllDocumentsList(craftId);
    }
    setOpenDocumentModel(false);
    setEditDocumentData("");
  };

  // Pagination calculation start

  const PER_PAGE = paginationPerPage;
  useEffect(() => {
    //Page count
    const count = Math.ceil(craftDocumentsList?.length / PER_PAGE);
    setCount(count);
    const indexOfLastRow = currentPage * PER_PAGE;
    const indexOfFirstRow = indexOfLastRow - PER_PAGE;
    const modifiedRows = craftDocumentsList?.slice(
      indexOfFirstRow,
      indexOfLastRow
    );
    const serialNumber = (currentPage - 1) * PER_PAGE + 1;
    // setCurrentRows(modifiedRows);
    setCurrentRows(
      modifiedRows &&
        modifiedRows.map((ele, index) => {
          return { ...ele, sNo: serialNumber + index };
        })
    );
    if (modifiedRows?.length === 0 && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  }, [currentPage, craftDocumentsList, paginationPerPage, PER_PAGE]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  // Pagination calculation end

  useEffect(() => {
    fetchAllDocumentsList(craftId);
  }, []);

  // when the user remove image loacally then used...
  const handleRemoveImageLocally = (fileName, values, setFieldValue) => {
    setFieldValue(
      "craftDocuments",
      values.craftDocuments.filter((res) => res !== fileName)
    );
  };

  // when the user remove image db through then used...
  const handleRemoveImage = (fileName, values, setFieldValue) => {
    const newValues = {
      id: editDocumentData?.id,
      file: fileName,
      craftDocuments: "craftDocuments",
    };

    dispatch(deleteCurrentOrderDocument(newValues))
      .then((res) => {
        if (res.status === 200) {
          setFieldValue(
            "craftDocuments",
            values.craftDocuments.filter((res) => res !== fileName)
          );
        }
      })
      .catch((error) => {});
  };

  return (
    <>
      <Index.Box className="barge-common-box">
        <Index.Box className="title-header">
          <Index.Box className="title-header-flex res-title-header-flex">
            <Index.Box className="title-main">
              <Index.Typography
                variant="p"
                component="p"
                className="page-title"
              >
                {/* Document */}
              </Index.Typography>
            </Index.Box>
            <Index.Box className="common-button blue-button res-blue-button">
              <Index.Button
                variant="outlined"
                onClick={() => {
                  handleModelOpen();
                }}
                className="view-invoice-btn"
              >
                Upload Document
              </Index.Button>
            </Index.Box>
          </Index.Box>
        </Index.Box>
        <Index.Box className="">
          <Index.TableContainer
            component={Index.Paper}
            className="table-container"
          >
            <Index.Table
              aria-label="simple table"
              className="table-design-main barge-table"
            >
              <Index.TableHead>
                <Index.TableRow>
                  <Index.TableCell>S.No</Index.TableCell>
                  <Index.TableCell>Days</Index.TableCell>
                  <Index.TableCell>Remaining Days</Index.TableCell>
                  <Index.TableCell>Image/PDF</Index.TableCell>
                  <Index.TableCell>Document Title</Index.TableCell>
                  <Index.TableCell align="left">Expiry Date</Index.TableCell>
                  <Index.TableCell align="left">Created Date</Index.TableCell>
                  <Index.TableCell align="left">
                    Do Not Have Expiry Date
                  </Index.TableCell>
                  <Index.TableCell align="left">Action</Index.TableCell>
                </Index.TableRow>
              </Index.TableHead>
              {loading ? (
                <Index.TableBody>
                  <Index.TableRow>
                    <Index.TableCell
                      colSpan={12}
                      align="center"
                      className="loading-purchase"
                    >
                      <Index.CircularProgress color="secondary" size={20} />
                    </Index.TableCell>
                  </Index.TableRow>
                </Index.TableBody>
              ) : (
                <Index.TableBody>
                  {currentRows?.length ? (
                    currentRows?.map((item, index) => {
                      const fileExtension = item?.craftDocuments?.[0]
                        ?.split(".")
                        .pop()
                        .toLowerCase();
                      return (
                        <Index.TableRow key={item?._id}>
                          <Index.TableCell>
                            <Index.Typography>{item?.sNo}</Index.Typography>
                          </Index.TableCell>
                          <Index.TableCell align="left">
                            <Index.Typography>
                              {!item?.expireCheckBox
                                ? item?.differenceInDays
                                : "-"}
                            </Index.Typography>
                          </Index.TableCell>
                          <Index.TableCell align="left">
                            {item?.remainingDays ? item?.remainingDays : "-"}
                          </Index.TableCell>
                          <Index.TableCell>
                            {item?.craftDocuments?.map((documentData) => (
                              <li className="table-li">
                                <Index.Button
                                  onClick={() => {
                                    window.open(
                                      `${process.env.REACT_APP_IMAGE_URL}/craft/${documentData}`
                                    );
                                  }}
                                >
                                  {documentData
                                    ?.split("-")
                                    ?.slice(1)
                                    ?.join("_")}
                                </Index.Button>
                              </li>
                            ))}
                          </Index.TableCell>

                          <Index.TableCell align="left">
                            <Index.Typography>{item?.title}</Index.Typography>
                          </Index.TableCell>

                          <Index.TableCell>
                            <Index.Typography>
                              {item?.expireDate
                                ? Index.moment(item?.expireDate).format(
                                    "DD/MM/YYYY"
                                  )
                                : "-"}
                            </Index.Typography>
                          </Index.TableCell>

                          <Index.TableCell align="left">
                            <Index.Typography>
                              {Index.moment(item?.createdAt).format(
                                "DD/MM/YYYY"
                              )}
                            </Index.Typography>
                          </Index.TableCell>
                          <Index.TableCell>
                            <Index.Typography>
                              {item?.expireCheckBox ? "No" : "Yes"}
                            </Index.Typography>
                          </Index.TableCell>
                          <Index.TableCell>
                            <Index.IconButton>
                              <Index.EditIcon
                                className="edit-icon-set"
                                onClick={() => {
                                  handleModelOpen();
                                  const editData = {
                                    id: item?._id,
                                    title: item?.title,
                                    craftDocuments: item?.craftDocuments,
                                    expireDate: item?.expireDate,
                                    expireCheckBox: item?.expireCheckBox,
                                    remainingExpiryDays:
                                      item?.remainingExpiryDays,
                                  };
                                  setEditDocumentData(editData);
                                }}
                              />
                            </Index.IconButton>
                            <Index.IconButton>
                              <Index.DeleteIcon
                                color="error"
                                onClick={() => {
                                  setDeleteDocumentId(item?._id);
                                  handleDeleteOpen();
                                }}
                              />
                            </Index.IconButton>
                            {/* <Index.IconButton>
                              <Index.AddCircleIcon
                                color="primary"
                                onClick={() => {
                                  handleAddHistoryOfDocument(item);
                                }}
                              />
                            </Index.IconButton> */}
                            <Index.IconButton>
                              <Index.HistoryIcon
                                color="primary"
                                onClick={() => {
                                  handleGoToHistoryOfDocuments(item, craftId);
                                }}
                              />
                            </Index.IconButton>
                          </Index.TableCell>
                        </Index.TableRow>
                      );
                    })
                  ) : (
                    <Index.TableRow>
                      <Index.TableCell
                        component="td"
                        variant="td"
                        scope="row"
                        className="no-data-in-list"
                        colSpan={12}
                        align="center"
                      >
                        No data available
                      </Index.TableCell>
                    </Index.TableRow>
                  )}
                </Index.TableBody>
              )}
            </Index.Table>
          </Index.TableContainer>
        </Index.Box>
        <Index.Box className="pagination-design flex-end">
          <Index.Stack spacing={2}>
            <Index.Box className="pagination-count">
              <Index.Box className="input-design-div with-border">
                <Index.Select
                  fullWidth
                  value={paginationPerPage}
                  onChange={(e) => {
                    setPaginationPerPage(e.target.value);
                  }}
                >
                  <Index.MenuItem value={10}>10</Index.MenuItem>
                  <Index.MenuItem value={25}>25</Index.MenuItem>
                  <Index.MenuItem value={50}>50</Index.MenuItem>
                  <Index.MenuItem value={100}>100</Index.MenuItem>
                </Index.Select>
              </Index.Box>
              <Index.Pagination
                count={count}
                page={currentPage}
                onChange={handlePageChange}
              />
            </Index.Box>
          </Index.Stack>
        </Index.Box>
      </Index.Box>

      {/* Show Image */}
      <Index.Modal
        open={openImage}
        onClose={handleCloseImage}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Index.Box sx={style}>
          <Index.ClearIcon
            className="model-close-icon"
            onClick={handleCloseImage}
          />
          <Index.Typography id="modal-modal-title" variant="h6" component="h2">
            <Index.Box>
              <img
                src={`${process.env.REACT_APP_IMAGE_URL}/craft/${documentImage}`}
                alt=""
                className="challan-img"
              />
            </Index.Box>
          </Index.Typography>
        </Index.Box>
      </Index.Modal>

      <Index.DeleteModal
        deleteOpen={deleteOpen}
        handleDeleteRecord={handleDeleteRecord}
        handleDeleteClose={handleDeleteClose}
        customMessage={"Do you want to delete this document"}
      />

      {/* Craft Crew Document Upload */}
      <Index.Dialog
        open={openDocumentModel}
        onClose={handleModelClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Index.DialogContent className="expense-model-main  expense-model-main-box-set">
          <Index.Box className="title-main">
            <Formik
              enableReinitialize
              initialValues={initialValues}
              validationSchema={uploadCraftDocumentValidationSchema}
              onSubmit={handleFormSubmit}
            >
              {({
                values,
                errors,
                touched,
                setFieldValue,
                handleChange,
                handleSubmit,
                handleBlur,
                setFieldTouched,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Index.Box className="title-main mt-0">
                    <Index.Box className="jus-between">
                      <Index.Typography
                        variant="h6"
                        component="h6"
                        className="input-label input-label-bold"
                        sx={{ marginBottom: "15px" }}
                      >
                        Upload Document
                      </Index.Typography>
                      <Index.ClearIcon
                        className="model-close-icon"
                        onClick={handleModelClose}
                      />
                    </Index.Box>
                    <Index.Grid container spacing={2}>
                      <Index.Grid item xs={12} sm={6} md={12}>
                        <Index.Typography
                          variant="label"
                          component="label"
                          className="input-label"
                        >
                          Document Title
                        </Index.Typography>
                        <Index.Box className="input-design-div with-border">
                          <Index.TextField
                            hiddenLabel
                            id="filled-hidden-label-normal"
                            placeholder="Enter Document Title"
                            variant="filled"
                            className="admin-input-design input-placeholder"
                            name="title"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values?.title}
                          />
                          {touched?.title && errors?.title && (
                            <Index.FormHelperText error>
                              {errors?.title}
                            </Index.FormHelperText>
                          )}
                        </Index.Box>
                      </Index.Grid>

                      <Index.Grid item xs={12} sm={6} md={12}>
                        <Index.Typography
                          variant="label"
                          component="label"
                          className="input-label"
                        >
                          Document Upload
                        </Index.Typography>
                        <Index.Box className="input-design-div with-border position-relative">
                          <Index.Box className="pdf-design">
                            Upload File
                          </Index.Box>
                          <Index.TextField
                            fullWidth
                            type="file"
                            name="craftDocuments"
                            multiple
                            id="filled-hidden-label-normal"
                            variant="filled"
                            className="admin-input-design input-placeholder pdf-upload-design"
                            onChange={(event) => {
                              // const files = event.target.files;

                              // const existingBlobFiles =
                              //   values?.craftDocuments?.filter(
                              //     (file) => file instanceof Blob
                              //   );

                              // setFieldValue("craftDocuments", [
                              //   ...values.craftDocuments,
                              //   // ...existingBlobFiles,
                              //   ...files,
                              // ]);

                              // setFieldTouched("craftDocuments", true);
                              const newFiles = Array.from(event.target.files); // Convert FileList to array
                              const existingFiles =
                                values?.craftDocuments || []; // Current files in the state

                              // Get existing files that are of type Blob (to handle already uploaded ones)
                              const existingBlobFiles = existingFiles.filter(
                                (file) => file instanceof Blob
                              );

                              // Filter out files that are duplicates (same name or lastModified timestamp)
                              const filteredNewFiles = newFiles.filter(
                                (newFile) =>
                                  !existingFiles.some(
                                    (existingFile) =>
                                      existingFile.name === newFile.name &&
                                      existingFile.lastModified ===
                                        newFile.lastModified
                                  )
                              );

                              // Append non-duplicate files to the current state
                              setFieldValue("craftDocuments", [
                                ...existingFiles,
                                ...filteredNewFiles,
                              ]);
                              setFieldTouched("craftDocuments", true);

                              // Reset the file input
                              event.target.value = null;
                            }}
                            onBlur={() => {
                              if (!values.craftDocuments.length) {
                                setFieldTouched("craftDocuments", false); // Manually set touched to false if files were not selected
                              }
                            }}
                            // onBlur={handleBlur}
                            inputProps={{
                              multiple: true,
                              accept: "application/pdf",
                            }}
                            FormHelperTextProps={{
                              className: "input_label_props",
                            }}
                          />
                          {touched?.craftDocuments &&
                            errors?.craftDocuments &&
                            errors?.craftDocuments === "Please select PDF" && (
                              <Index.FormHelperText error>
                                {errors?.craftDocuments}
                              </Index.FormHelperText>
                            )}
                        </Index.Box>
                      </Index.Grid>

                      <Index.Grid item xs={12} sm={6} md={12}>
                        <Index.Box className="select-view-document-name">
                          {values?.craftDocuments?.map((file, index) => (
                            <Index.Box key={index}>
                              <Index.Box className="document-name" key={index}>
                                <Index.Typography
                                  style={{
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    window.open(
                                      file instanceof Blob
                                        ? URL.createObjectURL(file)
                                        : `${process.env.REACT_APP_IMAGE_URL}/craft/${file}`
                                    );
                                  }}
                                >
                                  {file instanceof Blob ? file.name : file}
                                </Index.Typography>
                                {/* <Index.IconButton> */}
                                {editDocumentData &&
                                editDocumentData?.craftDocuments?.find(
                                  (res) => res === file
                                ) ? (
                                  <>
                                    <Index.DeleteIcon
                                      color="error"
                                      size="large"
                                      onClick={() =>
                                        handleRemoveImage(
                                          file,
                                          values,
                                          setFieldValue
                                        )
                                      }
                                    />
                                  </>
                                ) : (
                                  <>
                                    <Index.DeleteIcon
                                      color="error"
                                      size="large"
                                      onClick={() =>
                                        handleRemoveImageLocally(
                                          file,
                                          values,
                                          setFieldValue
                                        )
                                      }
                                    />
                                  </>
                                )}

                                {/* </Index.IconButton> */}
                                {/* <Index.IconButton
                                  onClick={() => {
                                    const updatedDocuments =
                                      values.craftDocuments.filter(
                                        (_, i) => i !== index
                                      );
                                    setFieldValue(
                                      "craftDocuments",
                                      updatedDocuments
                                    );
                                  }}
                                >
                                  <Index.ClearIcon className="muiico-cancel" />
                                </Index.IconButton> */}
                              </Index.Box>
                              {errors?.craftDocuments?.length > 0 &&
                                errors?.craftDocuments?.[index] && (
                                  <Index.FormHelperText error>
                                    {errors?.craftDocuments?.[index]}
                                  </Index.FormHelperText>
                                )}
                            </Index.Box>
                          ))}
                        </Index.Box>
                      </Index.Grid>

                      {console.log("editDocumentData", editDocumentData)}

                      {console.log("errors", errors)}

                      <Index.Grid item xs={12} sm={6} md={12}>
                        <Index.Box className="input-design-div with-border">
                          <Index.FormControlLabel
                            control={
                              <Index.Checkbox
                                name="expireCheckBox"
                                checked={values?.expireCheckBox}
                                onChange={(event) => {
                                  setFieldValue(
                                    "expireCheckBox",
                                    event.target.checked
                                  );
                                  setFieldValue("expireDate", "");
                                }}
                              />
                            }
                            label="Exp"
                          />
                        </Index.Box>
                      </Index.Grid>
                      {!values.expireCheckBox ? (
                        <>
                          <Index.Grid item xs={12} sm={6} md={12}>
                            <Index.Typography
                              variant="label"
                              component="label"
                              className="input-label"
                            >
                              Expiry Date
                            </Index.Typography>
                            <Index.Box className="input-design-div">
                              <Index.Box className="set-date set-date-picker">
                                <Index.LocalizationProvider
                                  dateAdapter={Index.AdapterDayjs}
                                >
                                  <Index.DemoContainer
                                    components={["DatePicker"]}
                                  >
                                    <Index.DemoItem label="">
                                      <Index.DatePicker
                                        format="DD/MM/YYYY"
                                        disablePast
                                        disabled={values?.expireCheckBox}
                                        name="expireDate"
                                        slotProps={{
                                          textField: {
                                            readOnly: true,
                                          },
                                        }}
                                        value={
                                          values?.expireDate
                                            ? Index.dayjs(
                                                Index.moment(
                                                  values?.expireDate
                                                ).format("YYYY-MM-DD")
                                              )
                                            : ""
                                        }
                                        onChange={(value) => {
                                          const formatedDate = Index.moment(
                                            value.$d
                                          ).format("YYYY-MM-DD");
                                          setFieldValue(
                                            "expireDate",
                                            formatedDate
                                          );
                                        }}
                                        onBlur={() => {
                                          setFieldTouched(
                                            "expireDate",
                                            true,
                                            false
                                          ); // Manually set touched without triggering validation
                                        }}
                                      />
                                    </Index.DemoItem>
                                  </Index.DemoContainer>
                                </Index.LocalizationProvider>
                              </Index.Box>
                              {touched?.expireDate &&
                                errors?.expireDate &&
                                !values?.expireCheckBox && (
                                  <Index.FormHelperText error>
                                    {errors?.expireDate}
                                  </Index.FormHelperText>
                                )}
                            </Index.Box>
                          </Index.Grid>
                        </>
                      ) : (
                        <></>
                      )}

                      {!values.expireCheckBox ? (
                        <>
                          <Index.Grid item xs={12} sm={6} md={12}>
                            <Index.Typography
                              variant="label"
                              component="label"
                              className="input-label"
                            >
                              Reminder Before Days
                            </Index.Typography>
                            <Index.Box className="input-design-div with-border">
                              <Index.TextField
                                hiddenLabel
                                id="filled-hidden-label-normal"
                                placeholder="Enter quantity"
                                variant="filled"
                                inputProps={{
                                  step: "0.001",
                                  // This limits to three decimal places
                                }}
                                className="admin-input-design input-placeholder"
                                name={`remainingExpiryDays`}
                                value={values.remainingExpiryDays}
                                autoComplete="off"
                                onBlur={handleBlur}
                                // defaultValue=""
                                onChange={handleChange}
                                helperText={
                                  touched &&
                                  touched.remainingExpiryDays &&
                                  errors &&
                                  errors.remainingExpiryDays
                                }
                                error={Boolean(
                                  errors &&
                                    errors.remainingExpiryDays &&
                                    touched &&
                                    touched.remainingExpiryDays
                                )}
                              />
                            </Index.Box>
                          </Index.Grid>
                        </>
                      ) : (
                        <></>
                      )}

                      <Index.Grid item xs={12} sm={6} md={12}>
                        <Index.Box
                          className="common-button blue-button flex-end save-btn"
                          sx={{
                            display: "flex",
                            alignItems: "flex-end",
                          }}
                        >
                          <Index.Button
                            variant="contained"
                            type="submit"
                            className="Particular_model_submit"
                          >
                            {editDocumentData ? "Update" : "Submit"}
                          </Index.Button>
                        </Index.Box>
                      </Index.Grid>
                      {console.log("errors", errors)}
                      {console.log("values", values)}
                      {console.log("touched", touched)}

                      <Index.Grid item xs={12} sm={12} md={12}>
                        <Index.Typography>
                          {uploadDocumentName && (
                            <Index.Typography
                              variant="label"
                              component="label"
                              className="input-label"
                            >
                              {uploadDocumentName}
                            </Index.Typography>
                          )}
                        </Index.Typography>
                      </Index.Grid>
                    </Index.Grid>

                    {/* <Index.Box className="line" mt={3} mb={3}></Index.Box> */}
                  </Index.Box>
                </form>
              )}
            </Formik>
          </Index.Box>
        </Index.DialogContent>
      </Index.Dialog>

      <Index.Dialog
        open={openDocumentHistoryModal}
        onClose={handleCloseDocumentHistoryModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Index.DialogContent className="expense-model-main  expense-model-main-box-set">
          <Index.Box className="title-main">
            <Formik
              enableReinitialize
              initialValues={documentHistoryInitialValues}
              validationSchema={uploadCraftDocumentHistoryValidationSchema}
              onSubmit={handleFormSubmitForDocumentHistory}
            >
              {({
                values,
                errors,
                touched,
                setFieldValue,
                handleChange,
                handleSubmit,
                handleBlur,
                setFieldTouched,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Index.Box className="title-main" sx={{ marginTop: "0px" }}>
                    <Index.ClearIcon
                      className="model-close-icon"
                      onClick={handleCloseDocumentHistoryModal}
                    />
                    <Index.Typography
                      variant="h6"
                      component="h6"
                      className="input-label input-label-bold"
                      sx={{ marginBottom: "15px" }}
                    >
                      Upload Document
                    </Index.Typography>
                    <Index.Grid container spacing={2}>
                      {/* <Index.Grid item xs={12} sm={6} md={12}>
                        <Index.Typography
                          variant="label"
                          component="label"
                          className="input-label"
                        >
                          Document Title
                        </Index.Typography>
                        <Index.Box className="input-design-div with-border">
                          <Index.TextField
                            hiddenLabel
                            id="filled-hidden-label-normal"
                            placeholder="Enter Document Title"
                            variant="filled"
                            className="admin-input-design input-placeholder"
                            name="title"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values?.title}
                          />
                          {touched?.title && errors?.title && (
                            <Index.FormHelperText error>
                              {errors?.title}
                            </Index.FormHelperText>
                          )}
                        </Index.Box>
                      </Index.Grid> */}

                      <Index.Grid item xs={12} sm={6} md={12}>
                        <Index.Typography
                          variant="label"
                          component="label"
                          className="input-label"
                        >
                          Document Upload
                        </Index.Typography>
                        <Index.Box className="input-design-div with-border position-relative">
                          <Index.Box className="pdf-design">
                            Upload File
                          </Index.Box>
                          <Index.TextField
                            fullWidth
                            type="file"
                            name="craftDocuments"
                            multiple
                            id="filled-hidden-label-normal"
                            variant="filled"
                            className="admin-input-design input-placeholder pdf-upload-design"
                            onChange={(event) => {
                              const files = event.target.files;

                              const existingBlobFiles =
                                values?.craftDocuments?.filter(
                                  (file) => file instanceof Blob
                                );

                              setFieldValue("craftDocuments", [
                                ...values.craftDocuments,
                                // ...existingBlobFiles,
                                ...files,
                              ]);

                              setFieldTouched("craftDocuments", true);
                            }}
                            onBlur={() => {
                              if (!values.craftDocuments.length) {
                                setFieldTouched("craftDocuments", false); // Manually set touched to false if files were not selected
                              }
                            }}
                            // onBlur={handleBlur}
                            inputProps={{
                              multiple: true,
                              accept: "application/pdf",
                            }}
                            FormHelperTextProps={{
                              className: "input_label_props",
                            }}
                          />
                          {touched?.craftDocuments &&
                            errors?.craftDocuments &&
                            errors?.craftDocuments === "Please select PDF" && (
                              <Index.FormHelperText error>
                                {errors?.craftDocuments}
                              </Index.FormHelperText>
                            )}
                        </Index.Box>
                      </Index.Grid>

                      <Index.Grid item xs={12} sm={6} md={12}>
                        <Index.Box className="select-view-document-name">
                          {values?.craftDocuments?.map((file, index) => (
                            <Index.Box key={index}>
                              <Index.Box className="document-name" key={index}>
                                <Index.Typography
                                  style={{
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    window.open(
                                      file instanceof Blob
                                        ? URL.createObjectURL(file)
                                        : `${process.env.REACT_APP_IMAGE_URL}/craft/${file}`
                                    );
                                  }}
                                >
                                  {file instanceof Blob ? file.name : file}
                                </Index.Typography>
                                {editDocumentData &&
                                editDocumentData?.craftDocuments?.find(
                                  (res) => res === file
                                ) ? (
                                  <>
                                    <Index.DeleteIcon
                                      color="error"
                                      size="large"
                                      onClick={() =>
                                        handleRemoveImage(
                                          file,
                                          values,
                                          setFieldValue
                                        )
                                      }
                                    />
                                  </>
                                ) : (
                                  <>
                                    <Index.DeleteIcon
                                      color="error"
                                      size="large"
                                      onClick={() =>
                                        handleRemoveImageLocally(
                                          file,
                                          values,
                                          setFieldValue
                                        )
                                      }
                                    />
                                  </>
                                )}
                              </Index.Box>
                              {errors?.craftDocuments?.length > 0 &&
                                errors?.craftDocuments?.[index] && (
                                  <Index.FormHelperText error>
                                    {errors?.craftDocuments?.[index]}
                                  </Index.FormHelperText>
                                )}
                            </Index.Box>
                          ))}
                        </Index.Box>
                      </Index.Grid>

                      <Index.Grid item xs={12} sm={6} md={12}>
                        <Index.Typography
                          variant="label"
                          component="label"
                          className="input-label"
                        >
                          Expiry Date
                        </Index.Typography>
                        <Index.Box className="input-design-div">
                          <Index.Box className="set-date set-date-picker">
                            <Index.LocalizationProvider
                              dateAdapter={Index.AdapterDayjs}
                            >
                              <Index.DemoContainer components={["DatePicker"]}>
                                <Index.DemoItem label="">
                                  <Index.DatePicker
                                    format="DD/MM/YYYY"
                                    disablePast
                                    name="expireDate"
                                    slotProps={{
                                      textField: {
                                        readOnly: true,
                                      },
                                    }}
                                    value={
                                      values?.expireDate
                                        ? Index.dayjs(
                                            Index.moment(
                                              values?.expireDate
                                            ).format("YYYY-MM-DD")
                                          )
                                        : ""
                                    }
                                    onChange={(value) => {
                                      const formatedDate = Index.moment(
                                        value.$d
                                      ).format("YYYY-MM-DD");
                                      setFieldValue("expireDate", formatedDate);
                                    }}
                                    onBlur={() => {
                                      setFieldTouched(
                                        "expireDate",
                                        true,
                                        false
                                      ); // Manually set touched without triggering validation
                                    }}
                                  />
                                </Index.DemoItem>
                              </Index.DemoContainer>
                            </Index.LocalizationProvider>
                          </Index.Box>
                          {touched?.expireDate &&
                            errors?.expireDate &&
                            !values?.expireCheckBox && (
                              <Index.FormHelperText error>
                                {errors?.expireDate}
                              </Index.FormHelperText>
                            )}
                        </Index.Box>
                      </Index.Grid>

                      <Index.Grid item xs={12} sm={6} md={12}>
                        <Index.Box className="input-design-div with-border">
                          <Index.FormControlLabel
                            control={
                              <Index.Checkbox
                                name="expireCheckBox"
                                checked={values?.expireCheckBox}
                                onChange={(event) => {
                                  setFieldValue(
                                    "expireCheckBox",
                                    event.target.checked
                                  );
                                  setFieldValue("expireDate", "");
                                  setFieldValue("expireDate", "");
                                }}
                              />
                            }
                            label="Do not have expiry date"
                          />
                        </Index.Box>
                      </Index.Grid>
                      <Index.Grid item xs={12} sm={6} md={12}>
                        <Index.Box
                          className="common-button blue-button flex-end save-btn"
                          sx={{
                            display: "flex",
                            alignItems: "flex-end",
                          }}
                        >
                          <Index.Button
                            variant="contained"
                            type="submit"
                            className="Particular_model_submit"
                          >
                            {editDocumentData ? "Update" : "Submit"}
                          </Index.Button>
                        </Index.Box>
                      </Index.Grid>

                      <Index.Grid item xs={12} sm={12} md={12}>
                        <Index.Typography>
                          {uploadDocumentName && (
                            <Index.Typography
                              variant="label"
                              component="label"
                              className="input-label"
                            >
                              {uploadDocumentName}
                            </Index.Typography>
                          )}
                        </Index.Typography>
                      </Index.Grid>
                    </Index.Grid>

                    <Index.Box className="line" mt={3} mb={3}></Index.Box>
                  </Index.Box>
                </form>
              )}
            </Formik>
          </Index.Box>
        </Index.DialogContent>
      </Index.Dialog>
    </>
  );
}

export default CraftDocument;
