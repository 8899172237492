import { useEffect, useState } from "react";
import Index from "../../../../Index";
import {
  getAllCompanyList,
  getBankDashboardDetails,
} from "../../../../../redux/admin/action";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function BankDashboard() {
  const dispatch = Index.useDispatch();
  const [companyList, setCompanyList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(
    "Ms. Bapus Shipping Jamnagar Pvt. Ltd. (15-18)"
  );
  const [bankDetails, setBankDetails] = useState([]);

  // when the get all the company list then used...
  const handleGetAllCompanyList = () => {
    dispatch(getAllCompanyList())
      .then((res) => {
        if (res.status === 200) {
          setCompanyList(res.data);
        } else {
          setCompanyList([]);
        }
      })
      .catch((error) => {
        setCompanyList([]);
        console.error(error);
      });
  };

  //   when the get bank details then used...
  const handleGetBankDetails = (values) => {
    setLoading(true);
    dispatch(getBankDashboardDetails(values))
      .then((res) => {
        if (res.status === 200) {
          setBankDetails(res.data);
          setLoading(false);
        } else {
          setBankDetails([]);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error(error);
        setBankDetails([]);
        setLoading(false);
      });
  };

  //   when the page first time load then used...
  useEffect(() => {
    handleGetAllCompanyList();
    const values = {
      companyName: selectedCompany,
    };
    handleGetBankDetails(values);
  }, []);

  //   when the user change the company then used...
  const handleChangeCompany = (value) => {
    setSelectedCompany(value);
    const values = {
      companyName: value,
    };
    handleGetBankDetails(values);
  };

  return (
    <>
      <Index.DynamicTitle title="Bank Dashboard" />
      <Index.Box className="barge-common-box">
        <Index.Box className="title-header">
          <Index.Box className="title-header-flex  res-title-header-flex">
            <Index.Box className="title-main">
              <Index.Typography
                variant="p"
                component="p"
                className="page-title"
              >
                Bank Dashboard
              </Index.Typography>
            </Index.Box>
            <Index.Box className="d-flex align-items-center res-set-search">
              <Index.Box>
                <Index.Box className="input-design-div with-border">
                  <Index.Select
                    className="placeholder-set-text-box"
                    fullWidth
                    name="companyId"
                    displayEmpty
                    inputProps={{ "aria-label": "without label" }}
                    value={selectedCompany}
                    MenuProps={MenuProps}
                    onChange={(event) => {
                      const {
                        target: { value },
                      } = event;
                      setSelectedCompany(value);
                      handleChangeCompany(value);
                    }}
                  >
                    <Index.MenuItem value={""}>
                      <Index.MenuItem disabled className="all-select-label">
                        Select Company
                      </Index.MenuItem>
                    </Index.MenuItem>

                    {companyList?.map((item) => (
                      <Index.MenuItem
                        key={item?._id}
                        value={item?.tallyCompanyName}
                      >
                        {item?.tallyCompanyName}
                      </Index.MenuItem>
                    ))}
                  </Index.Select>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
        {loading ? (
          <>
            <Index.Box className="bank-dash-loader">
              <Index.CircularProgress color="secondary" size={20} />
            </Index.Box>
          </>
        ) : (
          <>
            <Index.Box>
              {bankDetails?.length > 0 ? (
                <>
                  {bankDetails?.map((bankData) => (
                    <Index.Box className="barge-common-box box-margin">
                      <Index.Box className="bank-box">
                        <Index.Typography className="bank-name">
                          {bankData?.name}
                        </Index.Typography>
                      </Index.Box>
                      <Index.Box className="mini-card-main mini-card-main-bank-details card-wrapper">
                        <Index.Box className="mini-card-bank-details">
                          <Index.Box className="card-right-content">
                            <Index.Typography
                              className="card-total"
                              component="p"
                            >
                              Opening Balance
                            </Index.Typography>
                            <Index.Typography className="card-price">
                              ₹
                              {bankData?.openingBalance.toLocaleString(
                                "en-IN",
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }
                              )}
                            </Index.Typography>
                          </Index.Box>
                        </Index.Box>
                        <Index.Box className="mini-card-bank-details">
                          <Index.Box className="card-right-content">
                            <Index.Typography
                              className="card-total"
                              component="p"
                            >
                              Total Payment
                            </Index.Typography>
                            <Index.Typography className="card-price">
                              ₹
                              {parseInt(bankData?.paymentTotal).toLocaleString(
                                "en-IN",
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }
                              )}
                            </Index.Typography>
                          </Index.Box>
                        </Index.Box>
                        <Index.Box className="mini-card-bank-details">
                          <Index.Box className="card-right-content">
                            <Index.Typography
                              className="card-total"
                              component="p"
                            >
                              Total Receipt
                            </Index.Typography>
                            <Index.Typography className="card-price">
                              ₹
                              {bankData?.receiptTotal.toLocaleString("en-IN", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            </Index.Typography>
                          </Index.Box>
                        </Index.Box>
                        <Index.Box className="mini-card-bank-details">
                          <Index.Box className="card-right-content">
                            <Index.Typography
                              className="card-total"
                              component="p"
                            >
                              Total Contra
                            </Index.Typography>
                            <Index.Typography className="card-price">
                              ₹
                              {bankData?.contraTotal.toLocaleString("en-IN", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            </Index.Typography>
                          </Index.Box>
                        </Index.Box>
                        <Index.Box className="mini-card-bank-details">
                          <Index.Box className="card-right-content">
                            <Index.Typography
                              className="card-total"
                              component="p"
                            >
                              Closing Balance
                            </Index.Typography>
                            <Index.Typography className="card-price">
                              ₹
                              {bankData?.closingBalance.toLocaleString(
                                "en-IN",
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }
                              )}
                            </Index.Typography>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  ))}
                </>
              ) : (
                <>
                  <Index.Box className="no-data-box">
                    No data available
                  </Index.Box>
                </>
              )}
            </Index.Box>
          </>
        )}
      </Index.Box>
    </>
  );
}
export default BankDashboard;
