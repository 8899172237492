import { useEffect, useState } from "react";
import Index from "../../../Index";
import { Formik } from "formik";
import { taskMasterValidationSchema } from "../../../../validation/AdminValidation";
import {
  addEditTask,
  getAllAssigneeUsersList,
  getSingleTask,
} from "../../../../redux/admin/action";

const priorityList = [
  { id: 1, value: "Low" },
  { id: 2, value: "Medium" },
  { id: 3, value: "High" },
];
const statusList = [
  { id: 1, value: "Pending" },
  { id: 2, value: "In Progress" },
  { id: 3, value: "Completed" },
];

function AddTask() {
  const dispatch = Index.useDispatch();
  const navigate = Index.useNavigate();
  const { id } = Index.useParams();
  const [loading, setLoading] = useState(false);
  const [assigneeList, setAssigneeList] = useState([]);
  const [singleTask, setSingleTask] = useState();

  let initialValues = {};
  if (singleTask) {
    initialValues = {
      title: singleTask ? singleTask?.title : "",
      description: singleTask ? singleTask?.description : "",
      assignTo: singleTask ? singleTask?.assignTo?._id : "",
      status: singleTask ? singleTask?.status : "",
      priority: singleTask ? singleTask?.priority : "",
      startDate: singleTask ? singleTask?.startDate : "",
      deadline: singleTask ? singleTask?.deadline : "",
    };
  } else {
    initialValues = {
      title: "",
      description: "",
      assignTo: "",
      status: "",
      priority: "",
      startDate: "",
      deadline: "",
    };
  }

  const handleFormSubmit = (values, { setSubmitting }) => {
    const urlencoded = new URLSearchParams();
    if (singleTask) {
      urlencoded.append("id", singleTask?._id);
    }
    for (let keys in values) {
      urlencoded.append(keys, values[keys]);
    }

    dispatch(addEditTask(urlencoded)).then((res) => {
      if (res?.status === 200 || res?.status === 201) {
        navigate("/admin/tasks");
      } else {
        setTimeout(() => {
          setSubmitting(false);
        }, 2000);
      }
    });
  };

  const BackBtn = () => {
    navigate("/admin/tasks");
  };

  // Get assignee List
  const getUsers = () => {
    dispatch(getAllAssigneeUsersList()).then((res) => {
      if (res?.status === 200) {
        setAssigneeList(res?.data);
      } else {
        setAssigneeList([]);
      }
    });
  };

  const fetchSingleTask = (data) => {
    setLoading(true);
    try {
      dispatch(getSingleTask(data)).then((res) => {
        if (res?.status === 200) {
          setSingleTask(res?.data);
          setTimeout(() => {
            setLoading(false);
          }, 1000);
        } else {
          setTimeout(() => {
            setLoading(false);
          }, 1000);
          setSingleTask();
        }
      });
    } catch (error) {}
  };

  useEffect(() => {
    if (id) {
      fetchSingleTask({ id: id });
    }
  }, [id]);
  useEffect(() => {
    getUsers();
  }, []);

  return (
    <>
      <Index.Box className="">
        <Index.Box className="barge-common-box">
          {loading && id ? (
            <Index.Box className="">
              <Index.Grid container spacing={2}>
                <Index.Grid item xs={5} sm={5} md={5}></Index.Grid>
                <Index.Grid item xs={2} sm={2} md={2}>
                  <Index.CircularProgress color="secondary" size={20} />
                </Index.Grid>
                <Index.Grid item xs={5} sm={5} md={5}></Index.Grid>
              </Index.Grid>
            </Index.Box>
          ) : (
            <>
              <Index.Box className="title-header">
                <Index.Box className="title-header-flex">
                  <Index.Box className="title-main mb-10">
                    <Index.Typography
                      variant="p"
                      component="p"
                      className="page-title"
                    >
                      {singleTask ? "Edit" : "Add"} Task
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="common-button blue-button save-btn ">
                    <Index.Button
                      variant="contained"
                      type="submit"
                      className="Particular_model_submit ml-0"
                      onClick={BackBtn}
                    >
                      Back
                    </Index.Button>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box>
                <Formik
                  // enableReinitialize
                  initialValues={initialValues}
                  validationSchema={taskMasterValidationSchema}
                  onSubmit={handleFormSubmit}
                >
                  {({
                    values,
                    errors,
                    touched,
                    setFieldValue,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      {/* <Index.Box className="add-task-form-main"> */}
                      <Index.Box className="">
                        <Index.Grid container spacing={2}>
                          <Index.Grid item xs={12} sm={6} md={6}>
                            <Index.Typography
                              variant="label"
                              component="label"
                              className="input-label"
                            >
                              Title
                            </Index.Typography>
                            <Index.Box className="input-design-div with-border">
                              <Index.TextField
                                hiddenLabel
                                id="filled-hidden-label-normal"
                                placeholder="Title"
                                variant="filled"
                                className="admin-input-design input-placeholder"
                                name="title"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values?.title}
                                error={Boolean(
                                  errors.title ? touched.title : undefined
                                )}
                                helperText={
                                  touched.title ? errors.title : undefined
                                }
                              />
                            </Index.Box>
                          </Index.Grid>

                          <Index.Grid item xs={12} sm={6} md={6}>
                            <Index.Typography
                              variant="label"
                              component="label"
                              className="input-label"
                            >
                              Assigned To
                            </Index.Typography>
                            <Index.Box className="input-design-div with-border">
                              <Index.Autocomplete
                                disablePortal
                                className="invoice-select-drop"
                                id="combo-box-demo"
                                options={assigneeList || []}
                                getOptionLabel={(option) =>
                                  option?.employeeName
                                }
                                renderOption={(props, option) => (
                                  <Index.Box
                                    component="li"
                                    sx={{
                                      "& > img": { mr: 2, flexShrink: 0 },
                                    }}
                                    {...props}
                                  >
                                    {option?.employeeName}
                                  </Index.Box>
                                )}
                                value={
                                  assigneeList && assigneeList?.length > 0
                                    ? assigneeList?.find(
                                        (value) =>
                                          value?._id === values?.assignTo
                                      )
                                    : ""
                                }
                                onChange={(event, newValue) => {
                                  setFieldValue("assignTo", newValue?._id);
                                }}
                                renderInput={(params) => (
                                  <Index.TextField
                                    {...params}
                                    placeholder="Select Assignee"
                                    variant="outlined"
                                  />
                                )}
                              />
                              {touched?.assignTo && errors?.assignTo && (
                                <Index.FormHelperText error>
                                  {errors?.assignTo}
                                </Index.FormHelperText>
                              )}
                            </Index.Box>
                          </Index.Grid>

                          <Index.Grid item xs={12} sm={12} md={12}>
                            <Index.Typography
                              variant="label"
                              component="label"
                              className="input-label"
                            >
                              Description
                            </Index.Typography>
                            <Index.Box className="input-design-div with-border">
                              <Index.TextareaAutosize
                                hiddenLabel
                                minRows={4}
                                id="filled-hidden-label-normal"
                                className="admin-input-design input-placeholder input-placeholder-box  input-placeholder-box-set "
                                placeholder="Description"
                                variant="filled"
                                name="description"
                                value={values.description}
                                onChange={handleChange}
                              />
                              {touched?.description && errors?.description && (
                                <Index.FormHelperText error>
                                  {errors?.description}
                                </Index.FormHelperText>
                              )}
                            </Index.Box>
                          </Index.Grid>

                          <Index.Grid item xs={12} sm={6} md={6}>
                            <Index.Typography
                              variant="label"
                              component="label"
                              className="input-label"
                            >
                              Status
                            </Index.Typography>
                            <Index.Box className="input-design-div with-border">
                              <Index.Autocomplete
                                disablePortal
                                className="invoice-select-drop"
                                id="combo-box-demo"
                                options={statusList}
                                getOptionLabel={(option) => option?.value}
                                value={
                                  statusList
                                    ? statusList?.find(
                                        (ele) => ele?.value === values?.status
                                      )
                                    : ""
                                }
                                renderOption={(props, option) => (
                                  <Index.Box
                                    component="li"
                                    sx={{
                                      "& > img": { mr: 2, flexShrink: 0 },
                                    }}
                                    {...props}
                                  >
                                    {option?.value}
                                  </Index.Box>
                                )}
                                onChange={(event, newValue) => {
                                  setFieldValue("status", newValue?.value);
                                }}
                                renderInput={(params) => (
                                  <Index.TextField
                                    {...params}
                                    placeholder="Select Status"
                                    variant="outlined"
                                  />
                                )}
                              />
                              {touched?.status && errors?.status && (
                                <Index.FormHelperText error>
                                  {errors?.status}
                                </Index.FormHelperText>
                              )}
                            </Index.Box>
                          </Index.Grid>
                          <Index.Grid item xs={12} sm={6} md={6}>
                            <Index.Typography
                              variant="label"
                              component="label"
                              className="input-label"
                            >
                              Priority
                            </Index.Typography>
                            <Index.Box className="input-design-div with-border">
                              <Index.Autocomplete
                                disablePortal
                                className="invoice-select-drop"
                                id="combo-box-demo"
                                options={priorityList}
                                getOptionLabel={(option) => option?.value}
                                value={
                                  priorityList
                                    ? priorityList?.find(
                                        (ele) => ele?.value === values?.priority
                                      )
                                    : ""
                                }
                                renderOption={(props, option) => (
                                  <Index.Box
                                    component="li"
                                    sx={{
                                      "& > img": { mr: 2, flexShrink: 0 },
                                    }}
                                    {...props}
                                  >
                                    {option?.value}
                                  </Index.Box>
                                )}
                                onChange={(event, newValue) => {
                                  setFieldValue("priority", newValue?.value);
                                }}
                                renderInput={(params) => (
                                  <Index.TextField
                                    {...params}
                                    placeholder="Select priority"
                                    variant="outlined"
                                  />
                                )}
                              />
                              {touched?.priority && errors?.priority && (
                                <Index.FormHelperText error>
                                  {errors?.priority}
                                </Index.FormHelperText>
                              )}
                            </Index.Box>
                          </Index.Grid>
                          <Index.Grid item xs={12} sm={6} md={6}>
                            <Index.Typography
                              variant="label"
                              component="label"
                              className="input-label"
                            >
                              Start Date
                            </Index.Typography>
                            <Index.Box className="input-design-div">
                              <Index.Box className="set-date set-date-picker">
                                <Index.LocalizationProvider
                                  dateAdapter={Index.AdapterDayjs}
                                >
                                  <Index.DemoContainer
                                    components={["DatePicker"]}
                                  >
                                    <Index.DemoItem label="">
                                      <Index.DatePicker
                                        format="DD/MM/YYYY"
                                        defaultValue={
                                          singleTask
                                            ? Index.dayjs(
                                                singleTask?.startDate
                                                  ? Index.moment(
                                                      singleTask?.startDate
                                                    ).format("YYYY-MM-DD")
                                                  : "-"
                                              )
                                            : ""
                                        }
                                        onChange={(value) => {
                                          const formatedDate = Index.moment(
                                            value.$d
                                          ).format("YYYY-MM-DD");
                                          setFieldValue(
                                            "startDate",
                                            formatedDate
                                          );
                                        }}
                                      />
                                      {touched?.startDate &&
                                        errors?.startDate && (
                                          <Index.FormHelperText error>
                                            {errors?.startDate}
                                          </Index.FormHelperText>
                                        )}
                                    </Index.DemoItem>
                                  </Index.DemoContainer>
                                </Index.LocalizationProvider>
                              </Index.Box>
                            </Index.Box>
                          </Index.Grid>
                          <Index.Grid item xs={12} sm={6} md={6}>
                            <Index.Typography
                              variant="label"
                              component="label"
                              className="input-label"
                            >
                              Deadline
                            </Index.Typography>
                            <Index.Box className="input-design-div">
                              <Index.Box className="set-date set-date-picker">
                                <Index.LocalizationProvider
                                  dateAdapter={Index.AdapterDayjs}
                                >
                                  <Index.DemoContainer
                                    components={["DatePicker"]}
                                  >
                                    <Index.DemoItem label="">
                                      <Index.DatePicker
                                        format="DD/MM/YYYY"
                                        defaultValue={
                                          singleTask
                                            ? Index.dayjs(
                                                singleTask?.deadline
                                                  ? Index.moment(
                                                      singleTask?.deadline
                                                    ).format("YYYY-MM-DD")
                                                  : "-"
                                              )
                                            : ""
                                        }
                                        onChange={(value) => {
                                          const formatedDate = Index.moment(
                                            value.$d
                                          ).format("YYYY-MM-DD");
                                          setFieldValue(
                                            "deadline",
                                            formatedDate
                                          );
                                        }}
                                      />
                                      {touched?.deadline &&
                                        errors?.deadline && (
                                          <Index.FormHelperText error>
                                            {errors?.deadline}
                                          </Index.FormHelperText>
                                        )}
                                    </Index.DemoItem>
                                  </Index.DemoContainer>
                                </Index.LocalizationProvider>
                              </Index.Box>
                            </Index.Box>
                          </Index.Grid>
                        </Index.Grid>
                      </Index.Box>
                      {/* </Index.Box> */}
                      <Index.Box className="common-button blue-button flex-end save-btn user-submit-btn">
                        <Index.Button
                          variant="contained"
                          type="submit"
                          disabled={isSubmitting}
                        >
                          {singleTask ? "Update" : "Submit"}
                        </Index.Button>
                      </Index.Box>
                    </form>
                  )}
                </Formik>
              </Index.Box>
            </>
          )}
        </Index.Box>
      </Index.Box>
    </>
  );
}

export default AddTask;
