import React from "react";
import Index from "../../../Index";

const InvoiceModelPopup = ({ handleClose, setOpen, open }) => {
  return (
    <div>
      <Index.Dialog
        open={open}
        onclose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="expense-add-main"
      >
        <Index.DialogContent className="expense-model-main">
          <Index.Box className="main-pending-enquiry">
            <Index.Box className="title-main">
              <Index.Typography
                variant="p"
                component="p"
                className="page-title"
              >
                Invoice
              </Index.Typography>
            </Index.Box>
            <Index.Button className="modal-circle-main user-circle-icon-btn">
              <img
                src={Index.Svg.close}
                onClick={() => handleClose(setOpen(false))}
                className="user-circle-img user-circle-icon"
                alt="icon"
                sx={{ cursor: "pointer" }}
              />
            </Index.Button>
          </Index.Box>

          <Index.Box className="invoice-popup-main">
            <Index.Typography variant="p" component="p" className="invoive-para">
              You have not generated invoice from last 3 days. 
            </Index.Typography>
            <Index.Typography variant="p" component="p" className="invoive-para">Please
              check your invoice</Index.Typography>
          </Index.Box>
        </Index.DialogContent>
      </Index.Dialog>
    </div>
  );
};

export default InvoiceModelPopup;
