import Index from "../../../Index";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { NEW_EXPORT_EXCEL } from "../ExportAll";
import { useSearchParams } from "react-router-dom";
import React, { useState } from "react";
import { getCraftWiseBreakDownList } from "../../../../redux/admin/action";

function CraftWiseMaintenance() {
  const navigate = Index.useNavigate();
  const dispatch = Index.useDispatch();
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [craftMaintenanceRecords, setCraftMaintenanceRecords] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [paginationPerPage, setPaginationPerPage] = useState(50);
  const [totalRecordsCount, setTotalRecordsCount] = React.useState(0);

  const backBtn = () => {
    navigate("/admin/companyDashboard");
  };

  // when the page first time load then used...
  const getCategoryWiseInvoiceListing = (values) => {
    setLoading(true);
    console.log(values, "values");
    dispatch(getCraftWiseBreakDownList(values))
      .then((res) => {
        if (res?.status === 200) {
          setLoading(false);
          setCraftMaintenanceRecords(res?.data);
          setTotalRecordsCount(res?.totalCount);
          setCurrentPage(res?.currentPage);
        } else {
          setCraftMaintenanceRecords([]);
          setLoading(false);
          setTotalRecordsCount(0);
          setCurrentPage(1);
        }
      })
      .catch((err) => {
        setCraftMaintenanceRecords([]);
        setLoading(false);
        setTotalRecordsCount(0);
        setCurrentPage(1);
      });
  };

  React.useEffect(() => {
    const values = {
      barge: searchParams.get("bargeId"),
      page: 1,
      size: 50,
    };
    getCategoryWiseInvoiceListing(values);
  }, []);

  //   when user change the per page count then used...
  const handleChangePaginationPerPage = React.useCallback(
    (e) => {
      const values = {
        barge: searchParams.get("bargeId"),
      };
      getCategoryWiseInvoiceListing(values);
      setPaginationPerPage(e.target.value);
    },
    [currentPage, setPaginationPerPage]
  );

  //   when the user want to change the page then used....
  const handlePageChange = React.useCallback(
    (event, value) => {
      const values = {
        barge: searchParams.get("bargeId"),
      };
      getCategoryWiseInvoiceListing(values);
      setCurrentPage(value);
    },
    [setCurrentPage, paginationPerPage]
  );

  const getOverDueDays = (NewDate) => {
    const startDate = Index.moment(new Date());
    var currentDate = Index.moment(NewDate);
    var days = startDate.diff(currentDate, "days");
    return days;
  };

  return (
    <>
      <Index.DynamicTitle title="Invoice Management" />
      <Index.Box className="">
        <Index.Box className="barge-common-box">
          {/* Filter */}
          <Index.Box className="invoice-details title-header">
            <Index.Box className="title-header-flex  res-title-header-flex">
              <Index.Box className="invoice-back-title title-main">
                <Index.Button
                  type="submit"
                  className="Particular_model_submit back-invoice-btn"
                  onClick={backBtn}
                >
                  <ArrowBackIcon />
                </Index.Button>

                <Index.Typography
                  variant="p"
                  component="p"
                  className="page-title"
                >
                  {searchParams.get("craftName")}
                </Index.Typography>
              </Index.Box>
              <Index.Box className="common-button grey-button ">
                <Index.Button
                  variant="contained"
                  onClick={() => {
                    let curDate = Index.moment(new Date()).format("DD-MM-YYYY");
                    NEW_EXPORT_EXCEL(
                      "/admin/export-maintenance",
                      "CRAFT_BREAKDOWN_LIST",
                      {
                        barge: searchParams.get("bargeId"),
                      }
                    );
                  }}
                >
                  Export
                </Index.Button>
              </Index.Box>
            </Index.Box>
          </Index.Box>

          {/* Listing */}
          <Index.Box className="">
            <Index.TableContainer
              component={Index.Paper}
              className="table-container"
            >
              <Index.Table
                aria-label="simple table"
                className="table-design-main invoice-table invoice-table-set  "
              >
                <Index.TableHead>
                  <Index.TableRow>
                    <Index.TableCell>S. No</Index.TableCell>
                    <Index.TableCell align="left">Craft Name</Index.TableCell>
                    <Index.TableCell align="left">
                      Reported Date
                    </Index.TableCell>
                    <Index.TableCell align="left">Working Team</Index.TableCell>
                    <Index.TableCell align="left">Job Type</Index.TableCell>
                    <Index.TableCell align="left">Planned Date</Index.TableCell>
                    <Index.TableCell align="left">Start Date</Index.TableCell>
                    <Index.TableCell align="left">Remark</Index.TableCell>
                    <Index.TableCell align="left">What Is Done</Index.TableCell>
                    <Index.TableCell align="left">
                      Pending(In Days)
                    </Index.TableCell>
                    <Index.TableCell align="left">Type</Index.TableCell>
                    <Index.TableCell align="left">
                      Resolve | Dispatch
                    </Index.TableCell>
                    <Index.TableCell align="left">
                      Dispatched User
                    </Index.TableCell>
                    <Index.TableCell align="left">
                      Received User
                    </Index.TableCell>
                  </Index.TableRow>
                </Index.TableHead>
                {loading ? (
                  <Index.TableBody>
                    <Index.TableRow>
                      <Index.TableCell
                        colSpan={12}
                        align="center"
                        className="loading-purchase"
                      >
                        <Index.CircularProgress color="secondary" size={20} />
                      </Index.TableCell>
                    </Index.TableRow>
                  </Index.TableBody>
                ) : (
                  <Index.TableBody>
                    {craftMaintenanceRecords?.length ? (
                      craftMaintenanceRecords &&
                      craftMaintenanceRecords?.map((item, index) => (
                        <Index.TableRow key={item?._id}>
                          <Index.TableCell align="left">
                            {item?.maintenanceNumber || "-"}
                          </Index.TableCell>
                          <Index.TableCell align="left">
                            {item?.bargeName || "-"}
                          </Index.TableCell>
                          <Index.TableCell align="left">
                            {item?.createdAt
                              ? Index.moment(item?.createdAt).format(
                                  "DD/MM/YYYY hh:mm A"
                                )
                              : "-"}
                          </Index.TableCell>
                          <Index.TableCell align="left">
                            {item?.workingUser && item?.workingUser?.length > 0
                              ? Array.prototype.map
                                  .call(item?.workingUser, function (item) {
                                    return item.firstName;
                                  })
                                  .join(", ")
                              : "-"}
                          </Index.TableCell>
                          <Index.TableCell align="left">
                            {item?.jobType || "-"}
                          </Index.TableCell>
                          <Index.TableCell align="left">
                            {item?.planedDate
                              ? Index.moment(item?.planedDate).format(
                                  "DD/MM/YYYY"
                                )
                              : "-"}
                          </Index.TableCell>
                          <Index.TableCell align="left">
                            {item?.startDate
                              ? Index.moment(item?.startDate).format(
                                  "DD/MM/YYYY"
                                )
                              : "-"}
                          </Index.TableCell>
                          <Index.TableCell
                            align="left"
                            sx={{
                              cursor:
                                item?.remark && item?.remark?.length > 10
                                  ? "pointer"
                                  : "",
                            }}
                          >
                            {item?.remark
                              ? item?.remark?.length > 10
                                ? `${item?.remark?.slice(0, 9)}...`
                                : item?.remark
                              : "-"}
                          </Index.TableCell>

                          <Index.TableCell align="left">
                            {item?.whatIsDone || "-"}
                          </Index.TableCell>
                          <Index.TableCell align="left">
                            {item?.resolveDate
                              ? "-"
                              : getOverDueDays(item?.createdAt) + " Days"}
                          </Index.TableCell>
                          <Index.TableCell align="left">
                            {item?.type || "-"}
                          </Index.TableCell>

                          {item?.jobType === "Procurement" &&
                          item?.hasOwnProperty("dispatchDate") &&
                          !item?.hasOwnProperty("receivedDate") ? (
                            <>
                              <Index.TableCell>
                                <Index.Box className="common-button blue-button res-blue-button">
                                  Dispatched{" "}
                                  {Index.moment(item?.dispatchDate).format(
                                    "DD/MM/YYYY hh:mm A"
                                  )}
                                </Index.Box>
                              </Index.TableCell>
                            </>
                          ) : item?.jobType === "Procurement" &&
                            item?.hasOwnProperty("receivedDate") ? (
                            <>
                              <Index.TableCell>
                                <Index.Box className="common-button blue-button res-blue-button">
                                  Received{" "}
                                  {Index.moment(item?.receivedDate).format(
                                    "DD/MM/YYYY hh:mm A"
                                  )}
                                </Index.Box>
                              </Index.TableCell>
                            </>
                          ) : (
                            <Index.TableCell>
                              {item?.resolveRemark ? item?.resolveRemark : "-"}
                            </Index.TableCell>
                          )}
                          <Index.TableCell align="left">
                            {item?.disptachedBy?.hasOwnProperty("firstName")
                              ? item?.disptachedBy?.firstName +
                                " " +
                                item?.disptachedBy?.lastName
                              : "-"}
                          </Index.TableCell>
                          <Index.TableCell align="left">
                            {item?.receivedBy?.hasOwnProperty("firstName")
                              ? item?.receivedBy?.firstName +
                                " " +
                                item?.receivedBy?.lastName
                              : "-"}
                          </Index.TableCell>
                        </Index.TableRow>
                      ))
                    ) : (
                      <Index.TableRow>
                        <Index.TableCell
                          component="td"
                          variant="td"
                          scope="row"
                          className="no-data-in-list empty-record-align"
                          colSpan={15}
                          align="center"
                        >
                          No data available
                        </Index.TableCell>
                      </Index.TableRow>
                    )}
                  </Index.TableBody>
                )}
              </Index.Table>
            </Index.TableContainer>
          </Index.Box>

          <Index.Box className="pagination-design flex-end">
            <Index.Stack spacing={2}>
              <Index.Box className="pagination-count pagination-loader">
                <Index.Box className="input-design-div with-border">
                  <Index.Select
                    fullWidth
                    value={paginationPerPage}
                    onChange={(e) =>
                      //   {
                      //   setPaginationPerPage(e.target.value);
                      // }
                      handleChangePaginationPerPage(e)
                    }
                  >
                    <Index.MenuItem value={10}>10</Index.MenuItem>
                    <Index.MenuItem value={25}>25</Index.MenuItem>
                    <Index.MenuItem value={50}>50</Index.MenuItem>
                    <Index.MenuItem value={100}>100</Index.MenuItem>
                  </Index.Select>
                </Index.Box>
                {loading ? (
                  <Index.CircularProgress color="secondary" size={26} />
                ) : (
                  <Index.Pagination
                    count={Math.ceil(totalRecordsCount / paginationPerPage)}
                    page={currentPage}
                    onChange={handlePageChange}
                  />
                )}
              </Index.Box>
            </Index.Stack>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
}

export default CraftWiseMaintenance;
