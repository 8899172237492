import { useEffect, useState } from "react";
import Index from "../../../../Index";
import {
  getKotakBankList,
  getDashboardKOTAKBankList,
} from "../../../../../redux/admin/action";

function KotakBankDataDetails() {
  const dispatch = Index.useDispatch();
  const [loading, setLoading] = useState(false);
  const [kotakBankDataList, setKotakBankDataList] = useState([]);

  // when the user get the kotak bank data then used....
  const handleGetKotakBankData = () => {
    setLoading(true);
    dispatch(getDashboardKOTAKBankList())
      .then((res) => {
        if (res.status === 200) {
          setKotakBankDataList(res.data);
          setLoading(false);
        } else {
          setKotakBankDataList([]);
          setLoading(false);
        }
      })
      .catch((error) => {
        setKotakBankDataList([]);
        setLoading(false);
        console.error("Kotak bank data fetch time error", error);
      });
  };

  // when the page first time load then used...
  useEffect(() => {
    handleGetKotakBankData();
  }, []);

  console.log("kotakBankDataList", kotakBankDataList);

  const FirstCapitalLetter = (string) => {
    // capitalize the first letter
    let result = string?.charAt(0).toUpperCase() + string?.slice(1);
    return result;
  };

  return (
    <>
      <Index.Box className="cate-table-main cate-table-main-box">
        <Index.TableContainer component={Index.Paper}>
          <Index.Table sx={{ minWidth: 650 }} aria-label="cate-table-container">
            <Index.TableHead>
              <Index.TableRow className="tanker-dashboard-div">
                <Index.TableCell width="15%">No.</Index.TableCell>
                <Index.TableCell align="left" width="45%">
                  Party / Date
                </Index.TableCell>
                <Index.TableCell align="left" width="25%">
                  Vou. Type / Number
                </Index.TableCell>
                <Index.TableCell align="right" width="30%">
                  Receipt / Payment
                </Index.TableCell>
              </Index.TableRow>
            </Index.TableHead>
            <Index.TableBody>
              {loading ? (
                <Index.TableRow>
                  <Index.TableCell
                    colSpan={12}
                    align="center"
                    className="loading-purchase"
                  >
                    <Index.CircularProgress color="secondary" size={20} />
                  </Index.TableCell>
                </Index.TableRow>
              ) : (
                <>
                  {kotakBankDataList?.map((row, index) => {
                    return (
                      <Index.TableRow
                        sx={{ cursor: "pointer" }}
                        // onClick={(e) => {
                        //   handleGoToDashboardDocumentList(items?.craftId);
                        // }}
                        key={row?._id}
                      >
                        <Index.TableCell>{index + 1}</Index.TableCell>
                        <Index.TableCell>
                          {FirstCapitalLetter(row?.partyName)} <br />
                          <Index.Box className="tanker-child-vessel">
                            {row?.date
                              ? Index.moment(row?.date).format("DD/MM/YYYY")
                              : "-"}
                          </Index.Box>
                        </Index.TableCell>
                        <Index.TableCell>
                          {row?.vchType
                            ? FirstCapitalLetter(row?.vchType)
                            : "-"}
                          <br />
                          <Index.Box className="tanker-child-vessel">
                            {row?.vchNo ? `(${row?.vchNo})` : "-"}
                          </Index.Box>
                        </Index.TableCell>
                        <Index.TableCell>
                          <Index.Box className="tanker-child-vessel">
                            {row?.amount < 0
                              ? Math.abs(row?.amount)?.toLocaleString("en-IN", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : // )
                                0?.toLocaleString("en-IN", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                          </Index.Box>
                          <Index.Box className="tanker-child-vessel">
                            {row?.amount > 0
                              ? row?.amount?.toLocaleString("en-IN", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : 0?.toLocaleString("en-IN", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                          </Index.Box>
                        </Index.TableCell>
                        {/* <Index.TableCell>
                          {row?.amount > 0
                            ? row?.amount?.toLocaleString("en-IN", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })
                            : 0?.toLocaleString("en-IN", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                        </Index.TableCell> */}
                      </Index.TableRow>
                    );
                  })}
                </>
              )}
            </Index.TableBody>
          </Index.Table>
        </Index.TableContainer>
      </Index.Box>
    </>
  );
}

export default KotakBankDataDetails;
