import { useEffect, useState } from "react";
import Index from "../../../Index";
import { getDashboardAllTransactionsData } from "../../../../redux/admin/action";

function DashboardBankTransactions() {
  const dispatch = Index.useDispatch();
  const [loading, setLoading] = useState(false);
  const [dashboardAllTransactions, setDashboardAllTransactions] = useState([]);

  // capitalize the first letter
  const FirstCapitalLetter = (string) => {
    let result = string?.charAt(0).toUpperCase() + string?.slice(1);
    return result;
  };

  //   when the dashboard side get all the transactions data then used...
  const handleGetAllTransactionsData = () => {
    setLoading(true);
    dispatch(getDashboardAllTransactionsData())
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          setDashboardAllTransactions(res.data);
        } else {
          setDashboardAllTransactions([]);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error(error);
        setDashboardAllTransactions([]);
        setLoading(false);
      });
  };

  //   when the page first time called then used...
  useEffect(() => {
    handleGetAllTransactionsData();
  }, []);

  return (
    <>
      <Index.Box className="cate-table-main cate-table-main-box">
        <Index.TableContainer component={Index.Paper}>
          <Index.Table sx={{ minWidth: 650 }} aria-label="cate-table-container">
            <Index.TableHead>
              <Index.TableRow className="tanker-dashboard-div">
                <Index.TableCell width="15%">No.</Index.TableCell>
                <Index.TableCell align="left" width="45%">
                  Party / Date
                </Index.TableCell>
                <Index.TableCell align="left" width="25%">
                  Vou. Type / Number
                </Index.TableCell>
                <Index.TableCell align="right" width="30%">
                  Receipt / Payment
                </Index.TableCell>
              </Index.TableRow>
            </Index.TableHead>
            <Index.TableBody>
              {loading ? (
                <Index.TableRow>
                  <Index.TableCell
                    colSpan={12}
                    align="center"
                    className="loading-purchase"
                  >
                    <Index.CircularProgress color="secondary" size={20} />
                  </Index.TableCell>
                </Index.TableRow>
              ) : (
                <>
                  {dashboardAllTransactions?.map((row, index) => {
                    return (
                      <Index.TableRow
                        sx={{ cursor: "pointer" }}
                        // onClick={(e) => {
                        //   handleGoToDashboardDocumentList(items?.craftId);
                        // }}
                        key={row?._id}
                      >
                        <Index.TableCell>{index + 1}</Index.TableCell>
                        <Index.TableCell>
                          {FirstCapitalLetter(row?.party_name)} <br />
                          <Index.Box className="tanker-child-vessel">
                            {row?.date
                              ? Index.moment(row?.date).format("DD/MM/YYYY")
                              : "-"}
                          </Index.Box>
                        </Index.TableCell>
                        <Index.TableCell>
                          {row?.voucher_type
                            ? FirstCapitalLetter(row?.voucher_type)
                            : "-"}
                          <br />
                          <Index.Box className="tanker-child-vessel">
                            {row?.voucher_number ? `(${row?.voucher_number})` : "-"}
                          </Index.Box>
                        </Index.TableCell>
                        <Index.TableCell>
                          <Index.Box className="tanker-child-vessel">
                            {row?.amount < 0
                              ? Math.abs(row?.amount)?.toLocaleString("en-IN", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : // )
                                0?.toLocaleString("en-IN", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                          </Index.Box>
                          <Index.Box className="tanker-child-vessel">
                            {row?.amount > 0
                              ? row?.amount?.toLocaleString("en-IN", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : 0?.toLocaleString("en-IN", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                          </Index.Box>
                        </Index.TableCell>
                      </Index.TableRow>
                    );
                  })}
                </>
              )}
            </Index.TableBody>
          </Index.Table>
        </Index.TableContainer>
      </Index.Box>
    </>
  );
}
export default DashboardBankTransactions;
