import React, { useEffect, useState } from "react";
import Index from "../../../container/Index";

const MenuItemList = ({ webRoleMaster, permissions, setOpen, roleType }) => {
  console.log("webRoleMaster", webRoleMaster);
  const location = Index.useLocation();
  const [openDropdownIndex, setOpenDropdownIndex] = useState(null);
  const handleClickAccount = (index) => {
    if (openDropdownIndex === index) {
      setOpenDropdownIndex(null); // Close the currently open dropdown
    } else {
      setOpenDropdownIndex(index); // Open the clicked dropdown
    }
  };

  useEffect(() => {
    if (
      location?.pathname === "/admin/users" ||
      location?.pathname === "/admin/craft" ||
      location?.pathname === "/admin/port" ||
      location?.pathname === "/admin/rate" ||
      location?.pathname === "/admin/party" ||
      location?.pathname === "/admin/expense" ||
      location?.pathname === "/admin/roles" ||
      location?.pathname === "/admin/traders" ||
      location?.pathname === "/admin/tankers" ||
      location?.pathname === "/admin/purchase" ||
      location?.pathname === "/admin/bunker" ||
      location?.pathname === "/admin/bunker2" ||
      location?.pathname === "/admin/wharfage" ||
      location?.pathname === "/admin/notification" ||
      location?.pathname === "/admin/gstr" ||
      location?.pathname === "/admin/gstr2" ||
      location?.pathname === "/admin/salesMaster" ||
      location?.pathname === "/admin/tds" ||
      location?.pathname === "/admin/craftPreventive" ||
      location.pathname === "/admin/documentCenter" ||
      location.pathname === "/admin/preventiveSubhead" ||
      location.pathname === "/admin/tallyInvoice" ||
      location.pathname === "/admin/bankHistory" ||
      location.pathname === "/admin/purchaseBeta"
    ) {
      // Determine the index of the dropdown that should be open based on the location
      const indexToOpen = webRoleMaster.findIndex(
        (e) =>
          e?.isChild === true &&
          e?.child?.some((e1) => e1?.link === location?.pathname)
      );
      setOpenDropdownIndex(indexToOpen);
    }
  }, [location?.pathname, webRoleMaster]);

  return (
    <>
      {webRoleMaster &&
        permissions &&
        webRoleMaster?.map((e, i) => {
          return (
            <>
              {e?.isChild === false ? (
                permissions[e?.tag] ? (
                  <Index.Box
                    className={`sidebar-li ${
                      location.pathname === e?.link ? "active" : ""
                    }`}
                  >
                    <Index.RouteLink
                      to={e?.link}
                      onClick={(e) => setOpen(false)}
                    >
                      <img src={Index.SideMenuIcon[e?.tag]} alt="" /> {e?.name}
                    </Index.RouteLink>
                  </Index.Box>
                ) : (
                  <></>
                )
              ) : (
                <>
                  {e?.child &&
                  e?.child.some((childItem) => permissions[childItem?.tag]) ? ( // Check if e?.child exists and has elements
                    <Index.Box>
                      <Index.ListItem
                        className="admin-sidebar-listitem admin-submenu-listitem-main"
                        onClick={() => handleClickAccount(i)}
                      >
                        <Index.RouteLink className="admin-sidebar-link admin-sidebar-link-box">
                          <Index.Box className="set-sidebar-menu-drop">
                            <Index.Box className="drop-main">
                              {" "}
                              <img src={Index.SideMenuIcon[e?.tag]} alt="" />
                              {e?.name}
                            </Index.Box>

                            {openDropdownIndex === i ? (
                              <Index.ExpandLess className="expandless-icon" />
                            ) : (
                              <Index.ExpandMore className="expandmore-icon" />
                            )}
                          </Index.Box>
                          <Index.Box className="submenu-main">
                            <Index.Collapse
                              in={openDropdownIndex === i}
                              timeout="auto"
                              unmountOnExit
                              className="submenu-collapse"
                            >
                              <Index.List
                                component="div"
                                disablePadding
                                className="admin-sidebar-submenulist"
                              >
                                {e?.child &&
                                  e?.child.map((e1) => {
                                    return permissions[e1?.tag] ? (
                                      <>
                                        <Index.ListItem
                                          className={`admin-sidebar-listitem sidebar-li ${
                                            location.pathname === e1?.link
                                              ? "active"
                                              : ""
                                          }`}
                                        >
                                          <img
                                            src={Index.SideMenuIcon[e1?.tag]}
                                            alt=""
                                          />
                                          <Index.RouteLink
                                            className={`admin-sidebar-link admin-sidebar-link-sub `}
                                            to={e1?.link}
                                          >
                                            {e1?.name}
                                          </Index.RouteLink>
                                        </Index.ListItem>
                                      </>
                                    ) : (
                                      <> </>
                                    );
                                  })}

                                {roleType === "Super Admin" &&
                                  e?.tag === "setup" && (
                                    <Index.ListItem className="admin-sidebar-listitem sidebar-li">
                                      <img
                                        src={Index.SideMenuIcon["roles"]}
                                        alt=""
                                      />
                                      <Index.RouteLink
                                        className={`admin-sidebar-link admin-sidebar-link-sub ${
                                          location.pathname === "/admin/roles"
                                            ? "active"
                                            : ""
                                        }`}
                                        to={"/admin/roles"}
                                      >
                                        Role Master
                                      </Index.RouteLink>
                                    </Index.ListItem>
                                  )}
                              </Index.List>
                            </Index.Collapse>
                          </Index.Box>
                        </Index.RouteLink>
                      </Index.ListItem>
                    </Index.Box>
                  ) : (
                    <></> // If e?.child is empty or undefined, don't render the dropdown
                  )}
                </>
              )}
            </>
          );
        })}
    </>
  );
};

export default MenuItemList;
