import { useEffect, useState } from "react";
import Index from "../../../../Index";
import DirectionsBoatFilledIcon from "@mui/icons-material/DirectionsBoatFilled";
import { getDashboardDocumentList } from "../../../../../redux/admin/action";
import { createSearchParams } from "react-router-dom";

function DocumentDetails() {
  const dispatch = Index.useDispatch();
  const navigate = Index.useNavigate();
  const [loading, setLoading] = useState(true);

  const [dashboardDocumentList, setDashboardDocumentList] = useState([]);

  const handleGetDashboardDocumentList = () => {
    setLoading(true);
    dispatch(getDashboardDocumentList())
      .then((res) => {
        if (res.status === 200) {
          setDashboardDocumentList(res.data);
          setLoading(false);
        } else {
          setDashboardDocumentList([]);
          setLoading(false);
        }
      })
      .catch((error) => {
        setDashboardDocumentList([]);
        setLoading(false);
        console.error(error);
      });
  };

  useEffect(() => {
    handleGetDashboardDocumentList();
  }, []);

  const handleGoToDashboardDocumentList = (craftId) => {
    navigate({
      pathname: "/admin/document/due-documents",
      search: createSearchParams({
        bargeId: craftId,
      }).toString(),
    });
  };

  return (
    <>
      <Index.Box className="cate-table-main cate-table-main-box">
        <Index.TableContainer component={Index.Paper}>
          <Index.Table sx={{ minWidth: 650 }} aria-label="cate-table-container">
            <Index.TableHead>
              <Index.TableRow className="tanker-dashboard-div">
                <Index.TableCell>No.</Index.TableCell>
                <Index.TableCell align="left">Document</Index.TableCell>
                <Index.TableCell align="left">Due Days</Index.TableCell>
                <Index.TableCell align="left">Remaining Days</Index.TableCell>
              </Index.TableRow>
            </Index.TableHead>
            <Index.TableBody>
              {loading ? (
                <Index.TableRow>
                  <Index.TableCell
                    colSpan={12}
                    align="center"
                    className="loading-purchase"
                  >
                    <Index.CircularProgress color="secondary" size={20} />
                  </Index.TableCell>
                </Index.TableRow>
              ) : (
                <>
                  {dashboardDocumentList?.map((items, index) => {
                    return (
                      <Index.TableRow
                        sx={{ cursor: "pointer" }}
                        onClick={(e) => {
                          // need to remove
                          // handleGoToDashboardDocumentList(items?.craftId);
                          navigate("/admin/documentCenter");
                        }}
                      >
                        <Index.TableCell>{index + 1}</Index.TableCell>
                        <Index.TableCell>
                          {items?.title ? items?.title : "-"}

                          <Index.Box className="tanker-child-vessel">
                            {items?.bargeName ? items?.bargeName : "-"}
                          </Index.Box>
                        </Index.TableCell>
                        <Index.TableCell>
                          {items?.differenceInDays}
                        </Index.TableCell>
                        <Index.TableCell>
                          {items?.remainingDays}
                        </Index.TableCell>
                      </Index.TableRow>
                    );
                  })}
                </>
              )}
            </Index.TableBody>
          </Index.Table>
        </Index.TableContainer>
      </Index.Box>
    </>
  );
}

export default DocumentDetails;
