import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import { Formik } from "formik";
import {
  assignTanker,
  getLoadingOrderTankerDetailsRequest,
  getSameTerminalOrder,
  getSingleOrder,
} from "../../../../redux/admin/action";
import { tankerAssignValidationSchema } from "../../../../validation/AdminValidation";

const style = {
  width: 600,
  height: 300,
};

const TankerShiftModel = ({
  shiftTankerHandleOpen,
  shiftTankerHandleClose,
  tankerOpen,
  setTankerOpen,
  partyModelData,
  orderId,
  status,
  getSingleOrderList,
  setIsShifted,
  tankerCheckId,
  setTankerCheckId,
}) => {
  const dispatch = Index.useDispatch();
  const navigate = Index.useNavigate();
  const [sameTerminalOrder, setSameTerminalOrder] = useState([]);

  // initialValues
  let initialValues = {
    newId: "",
  };

  // get same terminal tanker
  useEffect(() => {
    if (status === "Cancel" || status === "" || status === "Confirm") {
      dispatch(getSameTerminalOrder({ orderId })).then((res) => {
        setSameTerminalOrder(res?.data);
      });
    }
  }, [orderId, status]);

  // assign tanker model submit handler
  const handleFormSubmit = (values) => {
    const id = orderId;
    let data = new URLSearchParams();
    data.append("newId", values?.newId);
    data.append("oldId", orderId);
    data.append("type", "web");
    tankerCheckId?.forEach((items) => {
      data.append("tankerIds[]", items);
    });
    dispatch(assignTanker(data)).then((res) => {
      console.log("assign tanker res...", res);
      if (res.status === 200 || res.status === 201) {
        setTankerCheckId([]);
      }
    });
    shiftTankerHandleClose();
    getSingleOrderList(orderId);
    setIsShifted(true);
  };

  return (
    <Index.Box className="shit-tanker-modal">
      <Index.Dialog
        open={tankerOpen}
        onclose={shiftTankerHandleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="sift-tanker-modal-dialogBox"
      >
        <Index.Box sx={style}>
          <Index.DialogContent>
            <Index.Box className="title-main mb-10">
              <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={tankerAssignValidationSchema}
                onSubmit={handleFormSubmit}
              >
                {({
                  values,
                  errors,
                  touched,
                  setFieldValue,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Index.Box className="title-main">
                      <Index.Grid container spacing={2}>
                        <Index.Grid item xs={12} sm={12} md={12}>
                          <Index.Typography
                            variant="label"
                            component="label"
                            className="input-label"
                          >
                            Assign Truck to Other
                          </Index.Typography>
                          <Index.Box className="input-design-div with-border">
                            <Index.Autocomplete
                              disablePortal
                              className="invoice-select-drop"
                              id="combo-box-demo"
                              options={sameTerminalOrder}
                              getOptionLabel={(option) =>
                                `${option?.vesselName}`
                              }
                              // renderOption={(props, option) => (
                              //   <Index.Box
                              //     component="li"
                              //     sx={{
                              //       "& > img": { mr: 2, flexShrink: 0 },
                              //     }}
                              //     {...props}
                              //   >
                              //     <div>
                              //       {`VesselName: ${option?.vesselName}`} <br />
                              //       {`Terminal: ${option?.terminal}`} Product:{" "}
                              //       {option?.product} <br />
                              //       {`Quantity: ${option?.lowerQuantity}-${option?.higherQuantity}`}{" "}
                              //       <br />
                              //       {`Port: ${option?.port?.portName}`} <br />
                              //       {`Date: ${Index.moment(
                              //         option?.createdAt
                              //       ).format("DD/MM/YYYY")}`}
                              //     </div>
                              //   </Index.Box>
                              // )}

                              renderOption={(props, option) => (
                                <Index.Box
                                  component="li"
                                  sx={{
                                    "& > img": { mr: 2, flexShrink: 0 },
                                  }}
                                  {...props}
                                >
                                  <div>
                                    {`VesselName: ${
                                      option?.vesselName
                                        ? option?.vesselName
                                        : "-"
                                    }`}{" "}
                                    <br />
                                    {`Terminal: ${
                                      option?.terminal ? option?.terminal : "-"
                                    }`}{" "}
                                    <br />
                                    {`Product: ${
                                      option?.product ? option?.product : "-"
                                    }`}
                                    <br />
                                    {`Quantity: ${
                                      option?.lowerQuantity === "0" &&
                                      option?.higherQuantity === "0"
                                        ? "-"
                                        : option?.lowerQuantity &&
                                          option?.higherQuantity !== "0"
                                        ? `${option?.lowerQuantity}-${option?.higherQuantity}`
                                        : option?.lowerQuantity
                                    }`}
                                    <br />
                                    {`Port: ${
                                      option?.port?.portName
                                        ? option?.port?.portName
                                        : "-"
                                    }`}{" "}
                                    <br />
                                    {`Date: ${
                                      option?.createdAt
                                        ? Index.moment(
                                            option?.createdAt
                                          ).format("DD/MM/YYYY")
                                        : "-"
                                    }`}
                                  </div>
                                </Index.Box>
                              )}
                              onChange={(event, newValue) => {
                                setFieldValue("newId", newValue?._id);
                              }}
                              renderInput={(params) => (
                                <Index.TextField
                                  {...params}
                                  placeholder="Assign truck to other"
                                  variant="outlined"
                                />
                              )}
                            />
                            {touched?.newId && errors?.newId && (
                              <Index.FormHelperText error>
                                {errors?.newId}
                              </Index.FormHelperText>
                            )}
                          </Index.Box>
                        </Index.Grid>
                        <Index.Grid item xs={12} sm={12} md={12}>
                          <Index.Box className="common-button blue-button flex-end save-btn party_btn">
                            <Index.Button
                              variant="contained"
                              type="submit"
                              className="Particular_model_submit party_submit_btn shift-tanker-submit-btn"
                            >
                              {partyModelData?._id ? "Update" : "Submit"}
                            </Index.Button>
                            <Index.Button
                              variant="contained"
                              type="button"
                              className="Particular_model_submit shift-tanker-submit-btn"
                              onClick={() =>
                                shiftTankerHandleClose(setTankerOpen(false))
                              }
                            >
                              Cancel
                            </Index.Button>
                          </Index.Box>
                        </Index.Grid>
                      </Index.Grid>

                      {/* <Index.Box className="line" mt={3} mb={3}></Index.Box> */}
                    </Index.Box>
                  </form>
                )}
              </Formik>
            </Index.Box>
          </Index.DialogContent>
        </Index.Box>
      </Index.Dialog>
    </Index.Box>
  );
};

export default TankerShiftModel;
