import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";

// client
const firebaseConfig = {
  apiKey: "AIzaSyBJCZOsFslYaLiWMBRwlTZFSUfUEyi8Da8",
  authDomain: "barge-ae24b.firebaseapp.com",
  projectId: "barge-ae24b",
  storageBucket: "barge-ae24b.appspot.com",
  messagingSenderId: "471226568421",
  appId: "1:471226568421:web:44edd0ff6478330aca8e0c",
  measurementId: "G-3K5Q2MBP8T",
};
export const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);



