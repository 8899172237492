import React, { useState } from "react";
import Index from "../../../Index";
import { changePassword } from "../../../../redux/admin/action";
import * as Yup from "yup";
import { Formik } from "formik";

function ChangePasswordComponent() {
  const dispatch = Index.useDispatch();
  const navigate = Index.useNavigate();
  const [showOldPassword, setShowOldPassword] = useState(false);
  const handleClickShowOldPassword = () => setShowOldPassword((show) => !show);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const handleClickShowNewPassword = () => setShowNewPassword((show) => !show);

  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword((show) => !show);

  // Initital values
  let initialValues = {
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  };

  // Validation schema
  const validationSchema = Yup.object().shape({
    oldPassword: Yup.string().required("Old Password is required"),
    newPassword: Yup.string()
      .required("Password is required")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        "Password length should be minimum 8 character including one capital letter, one small letter, one number, and one special character, ex. 99@Inchroad"
      ),
    confirmPassword: Yup.string()
      .required("Confirm Password is required")
      .oneOf([Yup.ref("newPassword"), null], "Passwords must match"),
  });

  // Forgot password
  const handleFormSubmit = async (values) => {
    // let data = { forgot_id: forgotId, newPassword: values.newPassword };
    dispatch(changePassword(values, navigate));
  };

  return (
    <div>
      <Index.Box className="p-15 background-ed profile-content flex-center ">
        <Index.Box className="h-100">
          <Index.Box className="card-center">
            <Index.Box class="card-main change-password-main">
              <Index.Box className="title-main change-password-text">
                <Index.Typography
                  variant="p"
                  component="p"
                  className="page-title"
                >
                  Change Password
                </Index.Typography>
              </Index.Box>
              <Index.Box className="input-design-div admin-design-div input-design-div-set-back change-password-icon ">
                <Formik
                  enableReinitialize
                  onSubmit={handleFormSubmit}
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                  }) => (
                    <Index.Stack
                      component="form"
                      spacing={2}
                      noValidate
                      autoComplete="off"
                      onSubmit={handleSubmit}
                    >
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="change-input-label"
                      >
                        Old Password
                      </Index.Typography>
                      <Index.TextField
                        hiddenLabel
                        id="filled-hidden-label-normal"
                        variant="filled"
                        placeholder="Old Password"
                        className="admin-input-design input-placeholder password-set"
                        name="oldPassword"
                        type={showOldPassword ? "text" : "password"}
                        autoComplete="off"
                        inputProps={{
                          className: "input_props  ",
                        }}
                        InputLabelProps={{ className: "add-formlabel" }}
                        FormHelperTextProps={{
                          className: "input_label_props ",
                        }}
                        onBlur={handleBlur}
                        value={values?.oldPassword}
                        onChange={handleChange}
                        helperText={touched?.oldPassword && errors?.oldPassword}
                        error={Boolean(
                          errors?.oldPassword && touched?.oldPassword
                        )}
                        // sx={{ mb: 3 }}
                        InputProps={{
                          // <-- This is where the toggle button is added.
                          endAdornment: (
                            <Index.InputAdornment position="end">
                              <Index.IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowOldPassword}
                                edge="end"
                              >
                                {showOldPassword ? (
                                  <Index.VisibilityOff />
                                ) : (
                                  <Index.Visibility />
                                )}
                              </Index.IconButton>
                            </Index.InputAdornment>
                          ),
                        }}
                      />
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="change-input-label label-set "
                      >
                        New Password
                      </Index.Typography>
                      <Index.TextField
                        hiddenLabel
                        id="filled-hidden-label-normal"
                        variant="filled"
                        placeholder="New Password"
                        className="admin-input-design input-placeholder password-set"
                        name="newPassword"
                        type={showNewPassword ? "text" : "password"}
                        autoComplete="off"
                        inputProps={{
                          className: "input_props",
                        }}
                        InputLabelProps={{ className: "add-formlabel" }}
                        FormHelperTextProps={{
                          className: "input_label_props",
                        }}
                        onBlur={handleBlur}
                        value={values.newPassword}
                        onChange={handleChange}
                        helperText={touched?.newPassword && errors?.newPassword}
                        error={Boolean(
                          errors?.newPassword && touched?.newPassword
                        )}
                        // sx={{ mb: 3 }}
                        InputProps={{
                          // <-- This is where the toggle button is added.
                          endAdornment: (
                            <Index.InputAdornment position="end">
                              <Index.IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowNewPassword}
                                edge="end"
                              >
                                {showNewPassword ? (
                                  <Index.VisibilityOff />
                                ) : (
                                  <Index.Visibility />
                                )}
                              </Index.IconButton>
                            </Index.InputAdornment>
                          ),
                        }}
                      />
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="change-input-label label-set "
                      >
                        Confirm Password
                      </Index.Typography>
                      <Index.TextField
                        hiddenLabel
                        id="filled-hidden-label-normal"
                        variant="filled"
                        placeholder="Confirm Password"
                        className="admin-input-design input-placeholder password-set-box"
                        name="confirmPassword"
                        type={showConfirmPassword ? "text" : "password"}
                        autoComplete="off"
                        inputProps={{
                          className: "input_props",
                        }}
                        InputLabelProps={{ className: "add-formlabel" }}
                        FormHelperTextProps={{
                          className: "input_label_props",
                        }}
                        onBlur={handleBlur}
                        value={values.confirmPassword}
                        onChange={handleChange}
                        helperText={
                          touched?.confirmPassword && errors?.confirmPassword
                        }
                        error={Boolean(
                          errors?.confirmPassword && touched?.confirmPassword
                        )}
                        sx={{ mb: 3 }}
                        InputProps={{
                          // <-- This is where the toggle button is added.
                          endAdornment: (
                            <Index.InputAdornment position="end">
                              <Index.IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowConfirmPassword}
                                edge="end"
                              >
                                {showConfirmPassword ? (
                                  <Index.VisibilityOff />
                                ) : (
                                  <Index.Visibility />
                                )}
                              </Index.IconButton>
                            </Index.InputAdornment>
                          ),
                        }}
                      />
                      <Index.Box
                        className="common-button blue-button change-password-btn"
                        sx={{ mt: 4 }}
                      >
                        <Index.Button
                          variant="contained"
                          type="submit"
                          disableRipple
                        >
                          Submit
                        </Index.Button>
                      </Index.Box>
                    </Index.Stack>
                  )}
                </Formik>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </div>
  );
}

export default ChangePasswordComponent;
