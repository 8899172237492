import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import { FieldArray, Formik } from "formik";
import {
  addInquiry,
  getNameOfVessel,
  getPortList,
  getSingleEnquiry,
  updateMultipleInquiryStatus,
  getSalesTraderList,
} from "../../../../redux/admin/action";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { terminalData } from "../../../../config/CommonTerminalItems";
import { productData } from "../../../../config/CommonProductsItem";
import { commonCFData } from "../../../../config/CommonCF";

// vessle auto suggest
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { enquiryValidationSchema } from "../../../../validation/AdminValidation";
import CurrentEnquiryStatusModal from "./CurrentEnquiryStatusModal";
import CurrentEnquiryLostStatusModal from "./CurrentEnquiryLostStatusModal";

function InquiryAdd() {
  const [row, setRow] = useState({});
  const [loadingEditEnq, setLoadingEditEnq] = useState(false);
  let { state } = Index.useLocation();
  const { id } = Index.useParams();

  let editData = id ? row : state?.row;
  if (editData) {
    editData.id = editData?._id;
    let portId = editData?.port?._id;
    if (portId) {
      editData.port = portId;
    }
  }

  const dispatch = Index.useDispatch();
  const navigate = Index.useNavigate();
  const [loading, setLoading] = useState(false);
  const [vesselNameList, setVesselNameList] = useState([]);
  const [allPortList, setAllPortList] = useState([]);
  const [allTerminalData, setAllTerminalData] = useState([]);
  const [allProductData, setAllProductData] = useState([]);

  const [updateOpen, setUpdateOpen] = useState(false);

  const portList = Index.useSelector((state) => state.AdminReducer.portList);
  const { nameOfVesselList, salesTraderList } = Index.useSelector(
    (state) => state.AdminReducer
  );
  const login = Index.useSelector((state) => state.AdminReducer.adminDetails);

  // lost enquiry basis
  const [openLostModal, setOpenLostModal] = useState(false);

  // nameOfVessele filter
  const filter = createFilterOptions();
  //getAll port
  useEffect(() => {
    dispatch(getPortList());
    dispatch(getNameOfVessel());
    dispatch(getSalesTraderList());
  }, [dispatch]);

  useEffect(() => {
    const vesselData = nameOfVesselList?.filter((d) => d?.vesselName);
    const uniqueArray = vesselData?.filter(
      (v, i, a) => a?.findIndex((t) => t?.vesselName === v?.vesselName) === i
    );
    if (login?.role === "Operation") {
      // Role Operation then vesselName show in uppercase
      const newData = uniqueArray?.sort((a, b) =>
        a?.vesselName?.localeCompare(b?.vesselName)
      );
      setVesselNameList(
        newData?.map((e) => ({
          ...e,
          vesselName: e?.vesselName?.toUpperCase(),
        }))
      );
    } else {
      setVesselNameList(
        uniqueArray?.sort((a, b) => a?.vesselName?.localeCompare(b?.vesselName))
      );
    }
    setAllPortList(
      portList?.sort((a, b) => a?.portName?.localeCompare(b?.portName))
    );
    setAllTerminalData(
      terminalData?.sort((a, b) => a?.item?.localeCompare(b?.item))
    );
    setAllProductData(
      productData?.sort((a, b) => a?.item?.localeCompare(b?.item))
    );
  }, [nameOfVesselList, portList, terminalData, productData]);

  let initialValues = {
    vesselName: null,
    port: "",
    terminal: "",
    CF: "",
    eta: "",
    laycam: ["", ""],
    // laycam: [Index.dayjs(""), Index.dayjs("")],
    etb: "",
    etd: "",
    stayHours: "",
    remark: "",
    orderDetails: [{ product: "", quantity: "" }],
    traderIds: [],
    bunkerCall: false,
  };
  // condition for manage Add/Edit together
  if (editData) {
    initialValues = editData;
    initialValues.laycam = [
      Index.dayjs(editData?.laycanStartDate),
      Index.dayjs(editData?.laycanEndDate),
    ];
    const orderDetailsModified = editData?.orderDetails?.map((item) => {
      return {
        ...item,
        quantity: item?.rangeQty,
      };
    });
    initialValues.orderDetails = orderDetailsModified;
  }
  const handleFormSubmit = async (values) => {
    setLoading(true);
    const data = new URLSearchParams();
    if (values?.vesselName) {
      data.append("vesselName", values?.vesselName);
    }
    if (values?.eta) {
      data.append("eta", values?.eta);
    }
    if (values?.etb) {
      data.append("etb", values?.etb);
    }
    if (values?.etd) {
      data.append("etd", values?.etd);
    }
    if (values?.edd) {
      data.append("edd", values?.edd);
    }
    if (values?.stayHours) {
      data.append("stayHours", values?.stayHours);
    }
    if (values?.port) {
      data.append("port", values?.port);
    }
    if (values?.terminal) {
      data.append("terminal", values?.terminal);
    }
    if (values?.bunkerCall) {
      data.append("bunkerCall", values?.bunkerCall);
    }
    if (editData?.type) {
      data.append("type", editData?.type);
    }
    if (values?.CF) {
      data.append("CF", values?.CF);
    }
    if (values.remark) {
      data.append("remark", values?.remark);
    }
    if (values?.traderIds?.length) {
      values?.traderIds?.forEach((items) => {
        data.append("traderIds[]", items);
      });
    }

    if (values?.laycam) {
      const laycamStartDate = values.laycam[0]["$D"];
      const laycamEndDate = values.laycam[1]["$D"];
      if (!isNaN(laycamStartDate) && !isNaN(laycamEndDate)) {
        // if (!isNaN(laycamStartDate)) {
        data.append(
          "laycam",
          values?.laycam?.map((date) => {
            return Index.moment(date.$d).format("YYYY-MM-DD");
          })
        );
      }
    }

    if (editData) {
      data.append("id", editData?._id);
    }
    if (values?.orderDetails?.length) {
      values?.orderDetails.forEach((element, i) => {
        if (element?.product?.length && element?.quantity?.length) {
          data.append(`orderDetails[${i}][product]`, element.product);
          data.append(`orderDetails[${i}][quantity]`, element.quantity);
        }
      });
    }

    dispatch(addInquiry(data, navigate))
      .then((res) => {
        if (res?.status == 200) {
          // setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch((err) => {});
  };

  const backBtn = () => {
    navigate("/admin/enquiry");
  };

  // get single enq edit code
  const getSingleEnquiryData = () => {
    setLoadingEditEnq(true);
    const data = {
      id: id,
    };
    dispatch(getSingleEnquiry(data)).then((res) => {
      if (res) {
        setRow(res?.data);
        setTimeout(() => {
          setLoadingEditEnq(false);
        }, 1000);
      }
    });
  };
  useEffect(() => {
    if (id) {
      getSingleEnquiryData();
    }
  }, []);

  // when the admin confim the enquiry then used..
  const handleConfirmEnquiry = React.useCallback(() => {
    const data = new URLSearchParams();
    // enquiryCheckId?.forEach((items) => {
    //   data.append("id[]", items);
    // });
    data.append("id[]", editData.id);
    data.append("inquiryStatus", "Confirm");
    data.append("comment", "");
    dispatch(updateMultipleInquiryStatus(data)).then((res) => {
      if (res.status === 200) {
        navigate("/admin/enquiry");
      }
    });
  }, [editData]);

  const handleOpen = () => {
    setUpdateOpen(true);
  };

  // when the open lost enquiry modal then used...
  const handleOpenLostModal = () => {
    setOpenLostModal(true);
  };

  const handleClose = () => {
    setUpdateOpen(false);

    navigate("/admin/enquiry");
    // setStatusId("");
    // setEnquiryCheckId([]);
    // setEnquiryCheckIdsData([])
  };

  const inquiryMultipleStatusUpdate = (status, comment) => {
    const data = new URLSearchParams();

    data.append("id[]", editData?._id);
    data.append("inquiryStatus", status);
    data.append("comment", comment);
    dispatch(updateMultipleInquiryStatus(data)).then((res) => {
      if (res?.status === 200) {
        navigate("/admin/enquiry");
      }
    });
    handleClose();
  };

  // when the user change the bunker call then used...
  const handleChangeBunkerCall = (e) => {
    const { checked } = e.target;
  };

  // when the user close the lost enquiry modal then used...
  const handleCloseLostEnquiryModal = () => {
    setOpenLostModal(false);
  };

  // when the user lost the enquiry then used....
  const handleLostEnquiry = () => {
    const data = new URLSearchParams();
    data.append("id[]", editData?._id);
    data.append("inquiryStatus", "Lost");
    dispatch(updateMultipleInquiryStatus(data)).then((res) => {
      if (res?.status === 200) {
        handleCloseLostEnquiryModal();
      }
    });
  };

  return (
    <>
      {loadingEditEnq ? (
        <Index.Table>
          <Index.TableBody>
            <Index.TableRow>
              <Index.TableCell colSpan={14} align="center">
                <Index.CircularProgress color="secondary" size={20} />
              </Index.TableCell>
            </Index.TableRow>
          </Index.TableBody>
        </Index.Table>
      ) : (
        <>
          <Index.Box className="">
            <Index.Box className="barge-common-box">
              <Index.Box className="title-header">
                <Index.Box className="title-header-flex">
                  <Index.Box className="title-main mb-10">
                    <Index.Typography
                      variant="p"
                      component="p"
                      className="page-title"
                    >
                      {editData ? "Edit" : "Add"} Enquiry{" "}
                      {editData?.hasOwnProperty("traderId") &&
                      editData?.traderIds?.length <= 0 ? (
                        <>({editData?.traderName})</>
                      ) : (
                        <></>
                      )}
                    </Index.Typography>
                  </Index.Box>
                  <Index.Box className="d-flex">
                    {editData !== undefined && editData !== null ? (
                      <>
                        <Index.Box className="common-button blue-button save-btn">
                          <Index.Button
                            variant="contained"
                            disableRipple
                            className="Particular_model_submit ml-0"
                            onClick={(e) => {
                              handleOpen();
                            }}
                            sx={{ marginRight: "5px" }}
                          >
                            Confirm
                          </Index.Button>
                          <Index.Button
                            variant="contained"
                            disableRipple
                            className="Particular_model_submit ml-0"
                            onClick={(e) => {
                              handleOpenLostModal();
                            }}
                            sx={{ marginRight: "5px" }}
                          >
                            Lost
                          </Index.Button>
                        </Index.Box>
                      </>
                    ) : (
                      <></>
                    )}

                    <Index.Box className="common-button blue-button save-btn ">
                      <Index.Button
                        variant="contained"
                        type="submit"
                        className="Particular_model_submit ml-0"
                        onClick={backBtn}
                      >
                        Back
                      </Index.Button>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={enquiryValidationSchema}
                onSubmit={handleFormSubmit}
              >
                {({
                  values,
                  errors,
                  touched,
                  setFieldValue,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  getFieldProps,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Index.Box className="">
                      <Index.Grid container spacing={2}>
                        <Index.Grid item xs={12} sm={6} md={4}>
                          <Index.Typography
                            variant="label"
                            component="label"
                            className="input-label"
                          >
                            Vessel Name
                          </Index.Typography>
                          <Index.Box className="input-design-div with-border">
                            <Index.Autocomplete
                              className="invoice-select-drop enquiry-vessel-autocomplete"
                              fullWidth
                              value={values?.vesselName}
                              onChange={(event, newValue) => {
                                if (typeof newValue === "string") {
                                  setFieldValue("vesselName", newValue);
                                } else if (newValue && newValue?.inputValue) {
                                  setFieldValue(
                                    "vesselName",
                                    newValue?.inputValue
                                  );
                                } else {
                                  setFieldValue(
                                    "vesselName",
                                    newValue?.vesselName
                                  );
                                }
                              }}
                              onBlur={(event, newValue) => {
                                setFieldValue("vesselName", event.target.value);
                              }}
                              filterOptions={(options, params) => {
                                const filtered = filter(options, params);
                                const { inputValue } = params;
                                // Suggest the creation of a new value
                                const isExisting = options?.some(
                                  (option) => inputValue === option?.vesselName
                                );

                                if (inputValue !== "" && !isExisting) {
                                  filtered.push({
                                    inputValue,
                                    vesselName: `Add "${inputValue}"`,
                                  });
                                }

                                return filtered;
                              }}
                              selectOnFocus
                              clearOnBlur
                              handleHomeEndKeys
                              id="free-solo-with-text-demo"
                              options={vesselNameList}
                              getOptionLabel={(option) => {
                                // Value selected with enter, right from the input
                                if (typeof option === "string") {
                                  return option;
                                }
                                // Add "xyz" option created dynamically
                                if (option?.inputValue) {
                                  return option?.inputValue;
                                }
                                // Regular option
                                return option?.vesselName;
                              }}
                              renderOption={(props, option) => (
                                <li {...props}>{option?.vesselName}</li>
                              )}
                              freeSolo
                              renderInput={(params) => (
                                <Index.TextField
                                  className="admin-input-design input-placeholder"
                                  {...params}
                                  fullWidth
                                  placeholder="Vessel name"
                                  // label="Free solo with text demo"
                                />
                              )}
                            />
                            {touched?.vesselName && errors?.vesselName && (
                              <Index.FormHelperText error>
                                {errors?.vesselName}
                              </Index.FormHelperText>
                            )}
                          </Index.Box>
                        </Index.Grid>

                        <Index.Grid item xs={12} sm={6} md={4}>
                          <Index.Typography
                            variant="label"
                            component="label"
                            className="input-label"
                          >
                            Port
                          </Index.Typography>
                          <Index.Box className="input-design-div with-border">
                            {editData?._id && values?.port ? (
                              allPortList?.length && (
                                <Index.Autocomplete
                                  disablePortal
                                  className="invoice-select-drop"
                                  id="combo-box-demo"
                                  options={allPortList}
                                  getOptionLabel={(option) => option?.portName}
                                  value={
                                    allPortList[
                                      allPortList.findIndex(
                                        (e) => e._id === values?.port
                                      )
                                    ]
                                  }
                                  renderOption={(props, option) => (
                                    <Index.Box
                                      component="li"
                                      sx={{
                                        "& > img": { mr: 2, flexShrink: 0 },
                                      }}
                                      {...props}
                                    >
                                      {option?.portName}
                                    </Index.Box>
                                  )}
                                  onChange={(event, newValue) => {
                                    setFieldValue("port", newValue?._id);
                                  }}
                                  renderInput={(params) => (
                                    <Index.TextField
                                      {...params}
                                      placeholder="Port name"
                                      variant="outlined"
                                    />
                                  )}
                                />
                              )
                            ) : (
                              <Index.Autocomplete
                                disablePortal
                                className="invoice-select-drop"
                                id="combo-box-demo"
                                options={allPortList}
                                getOptionLabel={(option) => option?.portName}
                                renderOption={(props, option) => (
                                  <Index.Box
                                    component="li"
                                    sx={{
                                      "& > img": { mr: 2, flexShrink: 0 },
                                    }}
                                    {...props}
                                  >
                                    {option?.portName}
                                  </Index.Box>
                                )}
                                onChange={(event, newValue) => {
                                  setFieldValue("port", newValue?._id);
                                }}
                                renderInput={(params) => (
                                  <Index.TextField
                                    {...params}
                                    placeholder="Port name"
                                    variant="outlined"
                                  />
                                )}
                              />
                            )}
                            {touched?.port && errors?.port && (
                              <Index.FormHelperText error>
                                {errors?.port}
                              </Index.FormHelperText>
                            )}
                          </Index.Box>
                        </Index.Grid>
                        <Index.Grid item xs={12} sm={6} md={4}>
                          <Index.Typography
                            variant="label"
                            component="label"
                            className="input-label"
                          >
                            Terminal
                          </Index.Typography>
                          <Index.Box className="input-design-div with-border">
                            {editData?._id && values?.terminal ? (
                              allTerminalData?.length && (
                                <Index.Autocomplete
                                  disablePortal
                                  className="invoice-select-drop"
                                  id="combo-box-demo"
                                  options={allTerminalData}
                                  getOptionLabel={(option) => option?.item}
                                  value={
                                    allTerminalData[
                                      allTerminalData.findIndex(
                                        (e) => e.item === values?.terminal
                                      )
                                    ]
                                  }
                                  renderOption={(props, option) => (
                                    <Index.Box
                                      component="li"
                                      sx={{
                                        "& > img": { mr: 2, flexShrink: 0 },
                                      }}
                                      {...props}
                                    >
                                      {option?.item}
                                    </Index.Box>
                                  )}
                                  onChange={(event, newValue) => {
                                    setFieldValue("terminal", newValue?.item);
                                  }}
                                  renderInput={(params) => (
                                    <Index.TextField
                                      {...params}
                                      placeholder="Terminal"
                                      variant="outlined"
                                    />
                                  )}
                                />
                              )
                            ) : (
                              <Index.Autocomplete
                                disablePortal
                                className="invoice-select-drop"
                                id="combo-box-demo"
                                options={allTerminalData}
                                getOptionLabel={(option) => option?.item}
                                renderOption={(props, option) => (
                                  <Index.Box
                                    component="li"
                                    sx={{
                                      "& > img": { mr: 2, flexShrink: 0 },
                                    }}
                                    {...props}
                                  >
                                    {option?.item}
                                  </Index.Box>
                                )}
                                onChange={(event, newValue) => {
                                  setFieldValue("terminal", newValue?.item);
                                }}
                                renderInput={(params) => (
                                  <Index.TextField
                                    {...params}
                                    placeholder="Terminal"
                                    variant="outlined"
                                  />
                                )}
                              />
                            )}
                            {touched?.terminal && errors?.terminal && (
                              <Index.FormHelperText error>
                                {errors?.terminal}
                              </Index.FormHelperText>
                            )}
                          </Index.Box>
                        </Index.Grid>

                        <Index.Grid item xs={12} sm={6} md={4}>
                          <Index.Typography
                            variant="label"
                            component="label"
                            className="input-label"
                          >
                            ETA
                          </Index.Typography>
                          <Index.Box className="input-design-div">
                            <Index.Box className="set-date set-date-picker">
                              <Index.LocalizationProvider
                                dateAdapter={Index.AdapterDayjs}
                              >
                                <Index.DemoContainer
                                  components={["DatePicker"]}
                                >
                                  <Index.DemoItem label="">
                                    <Index.DatePicker
                                      format="DD/MM/YYYY"
                                      defaultValue={
                                        editData
                                          ? Index.dayjs(
                                              editData?.eta
                                                ? editData?.eta
                                                : "-"
                                            )
                                          : ""
                                      }
                                      onChange={(value) => {
                                        const formatedDate = Index.moment(
                                          value.$d
                                        ).format("YYYY-MM-DD");
                                        setFieldValue("eta", formatedDate);
                                      }}
                                    />
                                    {touched?.eta && errors?.eta && (
                                      <Index.FormHelperText error>
                                        {errors?.eta}
                                      </Index.FormHelperText>
                                    )}
                                  </Index.DemoItem>
                                </Index.DemoContainer>
                              </Index.LocalizationProvider>
                            </Index.Box>
                          </Index.Box>
                        </Index.Grid>
                        <Index.Grid item xs={12} sm={6} md={4}>
                          <Index.Typography
                            variant="label"
                            component="label"
                            className="input-label"
                          >
                            ETB
                          </Index.Typography>
                          <Index.Box className="input-design-div">
                            <Index.Box className="set-date set-date-picker">
                              <Index.LocalizationProvider
                                dateAdapter={Index.AdapterDayjs}
                              >
                                <Index.DemoContainer
                                  components={["DatePicker"]}
                                >
                                  <Index.DemoItem label="">
                                    <Index.DatePicker
                                      format="DD/MM/YYYY"
                                      defaultValue={
                                        editData
                                          ? Index.dayjs(
                                              editData?.etb
                                                ? editData?.etb
                                                : "-"
                                            )
                                          : ""
                                      }
                                      onChange={(value) => {
                                        const formatedDate = Index.moment(
                                          value.$d
                                        ).format("YYYY-MM-DD");
                                        setFieldValue("etb", formatedDate);
                                      }}
                                    />
                                  </Index.DemoItem>
                                </Index.DemoContainer>
                              </Index.LocalizationProvider>
                              {touched?.etb && errors?.etb && (
                                <Index.FormHelperText error>
                                  {errors?.etb}
                                </Index.FormHelperText>
                              )}
                            </Index.Box>
                          </Index.Box>
                        </Index.Grid>

                        <Index.Grid item xs={12} sm={6} md={4}>
                          <Index.Typography
                            variant="label"
                            component="label"
                            className="input-label"
                          >
                            ETD
                          </Index.Typography>
                          <Index.Box className="input-design-div">
                            <Index.Box className="set-date set-date-picker">
                              <Index.LocalizationProvider
                                dateAdapter={Index.AdapterDayjs}
                              >
                                <Index.DemoContainer
                                  components={["DatePicker"]}
                                >
                                  <Index.DemoItem label="">
                                    <Index.DatePicker
                                      format="DD/MM/YYYY"
                                      defaultValue={
                                        editData
                                          ? Index.dayjs(
                                              editData?.etd
                                                ? editData?.etd
                                                : "-"
                                            )
                                          : ""
                                      }
                                      onChange={(value) => {
                                        const formatedDate = Index.moment(
                                          value.$d
                                        ).format("YYYY-MM-DD");
                                        setFieldValue("etd", formatedDate);
                                      }}
                                    />
                                  </Index.DemoItem>
                                </Index.DemoContainer>
                              </Index.LocalizationProvider>
                              {touched?.etd && errors?.etd && (
                                <Index.FormHelperText error>
                                  {errors?.etd}
                                </Index.FormHelperText>
                              )}
                            </Index.Box>
                          </Index.Box>
                        </Index.Grid>

                        <Index.Grid item xs={12} sm={6} md={4}>
                          <Index.Typography
                            variant="label"
                            component="label"
                            className="input-label"
                          >
                            C/F
                          </Index.Typography>
                          <Index.Box className="input-design-div with-border">
                            {editData?._id && values?.CF ? (
                              commonCFData?.length && (
                                <Index.Autocomplete
                                  disablePortal
                                  className="invoice-select-drop"
                                  id="combo-box-demo"
                                  options={commonCFData}
                                  getOptionLabel={(option) => option?.name}
                                  value={
                                    commonCFData[
                                      commonCFData.findIndex(
                                        (e) => e.name === values?.CF
                                      )
                                    ]
                                  }
                                  renderOption={(props, option) => (
                                    <Index.Box
                                      component="li"
                                      sx={{
                                        "& > img": { mr: 2, flexShrink: 0 },
                                      }}
                                      {...props}
                                    >
                                      {option?.name}
                                    </Index.Box>
                                  )}
                                  onChange={(event, newValue) => {
                                    setFieldValue("CF", newValue?.name);
                                  }}
                                  renderInput={(params) => (
                                    <Index.TextField
                                      {...params}
                                      placeholder="C/F"
                                      variant="outlined"
                                    />
                                  )}
                                />
                              )
                            ) : (
                              <Index.Autocomplete
                                disablePortal
                                className="invoice-select-drop"
                                id="combo-box-demo"
                                options={commonCFData}
                                getOptionLabel={(option) => option?.name}
                                renderOption={(props, option) => (
                                  <Index.Box
                                    component="li"
                                    sx={{
                                      "& > img": { mr: 2, flexShrink: 0 },
                                    }}
                                    {...props}
                                  >
                                    {option?.name}
                                  </Index.Box>
                                )}
                                onChange={(event, newValue) => {
                                  setFieldValue("CF", newValue?.name);
                                }}
                                onBlur={handleBlur}
                                renderInput={(params) => (
                                  <Index.TextField
                                    {...params}
                                    placeholder="C/F"
                                    variant="outlined"
                                  />
                                )}
                              />
                            )}
                            {touched?.CF && errors?.CF && (
                              <Index.FormHelperText error>
                                {errors?.CF}
                              </Index.FormHelperText>
                            )}
                          </Index.Box>
                        </Index.Grid>

                        <Index.Grid item xs={12} sm={6} md={4}>
                          <Index.Typography
                            variant="label"
                            component="label"
                            className="input-label"
                          >
                            Stay Hours
                          </Index.Typography>
                          <Index.Box className="input-design-div with-border">
                            <Index.TextField
                              hiddenLabel
                              id="filled-hidden-label-normal"
                              placeholder="Stay hours"
                              variant="filled"
                              className="admin-input-design input-placeholder"
                              name="stayHours"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values?.stayHours}
                            />
                            {touched?.stayHours && errors?.stayHours && (
                              <Index.FormHelperText error>
                                {errors?.stayHours}
                              </Index.FormHelperText>
                            )}
                          </Index.Box>
                        </Index.Grid>

                        <Index.Grid item xs={12} sm={6} md={4}>
                          <Index.Typography
                            variant="label"
                            component="label"
                            className="input-label"
                          >
                            Laycan From to To
                          </Index.Typography>
                          <Index.Box className="input-design-div">
                            <Index.Box className="set-date set-date-picker remove-name">
                              <Index.LocalizationProvider
                                dateAdapter={Index.AdapterDayjs}
                              >
                                <Index.DemoContainer
                                  components={[
                                    "DateRangePicker",
                                    "DateRangePicker",
                                  ]}
                                >
                                  <DateRangePicker
                                    format="DD/MM/YYYY"
                                    defaultValue={[
                                      Index.dayjs(
                                        Index.moment(
                                          values?.laycam?.[0]
                                        ).format("YYYY-MM-DD")
                                      ),
                                      Index.dayjs(
                                        Index.moment(
                                          values?.laycam?.[1]
                                        ).format("YYYY-MM-DD")
                                      ),
                                    ]}
                                    value={values?.laycam}
                                    onChange={(newValue) => {
                                      setFieldValue("laycam", newValue);
                                    }}
                                  />
                                </Index.DemoContainer>
                              </Index.LocalizationProvider>
                            </Index.Box>
                            {touched?.laycam && errors?.laycam && (
                              <Index.FormHelperText error>
                                {errors?.laycam}
                              </Index.FormHelperText>
                            )}
                          </Index.Box>
                        </Index.Grid>
                        {editData?.hasOwnProperty("traderId") &&
                        editData?.traderIds?.length <= 0 ? (
                          <>
                            {/* <Index.Box>{editData?.traderName}</Index.Box> */}
                          </>
                        ) : (
                          <>
                            <Index.Grid item xs={12} sm={6} md={4}>
                              <Index.Typography
                                variant="label"
                                component="label"
                                className="input-label"
                              >
                                Trader Name
                              </Index.Typography>
                              <Index.Box className="trader-selection">
                                {editData?._id && values?.traderIds ? (
                                  salesTraderList?.length && (
                                    <>
                                      <Index.Autocomplete
                                        disablePortal
                                        multiple
                                        className=""
                                        id="tags-outlined"
                                        options={salesTraderList.sort((a, b) =>
                                          a?.traderName?.localeCompare(
                                            b?.traderName
                                          )
                                        )}
                                        getOptionLabel={(option) =>
                                          option?.traderName
                                        }
                                        value={salesTraderList?.filter((res) =>
                                          values?.traderIds?.includes(res._id)
                                        )}
                                        renderOption={(props, option) => (
                                          <Index.Box
                                            component="li"
                                            sx={{
                                              "& > img": {
                                                mr: 2,
                                                flexShrink: 0,
                                              },
                                            }}
                                            {...props}
                                          >
                                            {option?.traderName}
                                          </Index.Box>
                                        )}
                                        onChange={(event, newValue) => {
                                          setFieldValue(
                                            "traderIds",
                                            newValue?.map((el) => el._id)
                                          );
                                        }}
                                        renderInput={(params) => (
                                          <Index.TextField
                                            {...params}
                                            placeholder="Trader name"
                                            variant="outlined"
                                          />
                                        )}
                                      />
                                    </>
                                  )
                                ) : (
                                  <Index.Autocomplete
                                    disablePortal
                                    className="invoice-select-drop"
                                    id="tags-outlined"
                                    multiple
                                    options={salesTraderList.sort((a, b) =>
                                      a?.traderName?.localeCompare(
                                        b?.traderName
                                      )
                                    )}
                                    getOptionLabel={(option) =>
                                      option?.traderName
                                    }
                                    renderOption={(props, option) => (
                                      <Index.Box
                                        component="li"
                                        sx={{
                                          "& > img": { mr: 2, flexShrink: 0 },
                                        }}
                                        {...props}
                                      >
                                        {option?.traderName}
                                      </Index.Box>
                                    )}
                                    onChange={(event, newValue) => {
                                      setFieldValue(
                                        "traderIds",
                                        newValue?.map((el) => el._id)
                                      );
                                    }}
                                    renderInput={(params) => (
                                      <Index.TextField
                                        {...params}
                                        placeholder="Trader name"
                                        variant="outlined"
                                      />
                                    )}
                                  />
                                )}

                                {touched.traderIds && errors.traderIds && (
                                  <Index.FormHelperText error>
                                    {errors.traderIds}
                                  </Index.FormHelperText>
                                )}
                              </Index.Box>
                            </Index.Grid>
                          </>
                        )}
                        <Index.Grid item xs={12} sm={6} md={4}>
                          <Index.Typography
                            variant="label"
                            component="label"
                            className="input-label"
                          >
                            Bunker Call
                          </Index.Typography>
                          <Index.Box className="input-design-div with-border">
                            <Index.FormControlLabel
                              // label="Bunker call"
                              control={
                                <Index.Checkbox
                                  checked={values?.bunkerCall}
                                  onChange={(event) => {
                                    setFieldValue(
                                      "bunkerCall",
                                      event.target.checked
                                    );
                                  }}
                                />
                              }
                            />
                            {touched?.stayHours && errors?.stayHours && (
                              <Index.FormHelperText error>
                                {errors?.stayHours}
                              </Index.FormHelperText>
                            )}
                          </Index.Box>
                        </Index.Grid>
                        {editData !== undefined ? (
                          <>
                            <Index.Grid item xs={12} sm={6} md={4}>
                              <Index.Typography
                                variant="label"
                                component="label"
                                className="input-label"
                              >
                                EDD
                              </Index.Typography>
                              <Index.Box className="input-design-div">
                                <Index.Box className="set-date set-date-picker">
                                  <Index.LocalizationProvider
                                    dateAdapter={Index.AdapterDayjs}
                                  >
                                    <Index.DemoContainer
                                      components={["DatePicker"]}
                                    >
                                      <Index.DemoItem label="">
                                        <Index.DatePicker
                                          format="DD/MM/YYYY"
                                          defaultValue={
                                            editData
                                              ? Index.dayjs(
                                                  editData?.edd
                                                    ? editData?.edd
                                                    : "-"
                                                )
                                              : ""
                                          }
                                          onChange={(value) => {
                                            const formatedDate = Index.moment(
                                              value.$d
                                            ).format("YYYY-MM-DD");
                                            setFieldValue("edd", formatedDate);
                                          }}
                                        />
                                      </Index.DemoItem>
                                    </Index.DemoContainer>
                                  </Index.LocalizationProvider>
                                  {touched?.edd && errors?.edd && (
                                    <Index.FormHelperText error>
                                      {errors?.edd}
                                    </Index.FormHelperText>
                                  )}
                                </Index.Box>
                              </Index.Box>
                            </Index.Grid>
                          </>
                        ) : (
                          <></>
                        )}

                        <Index.Grid item xs={12} sm={12} md={12}>
                          <Index.Typography
                            variant="label"
                            component="label"
                            className="input-label"
                          >
                            Remark
                          </Index.Typography>
                          <Index.Box className="input-design-div with-border">
                            <Index.TextareaAutosize
                              hiddenLabel
                              minRows={3}
                              id="filled-hidden-label-normal"
                              placeholder="Remark"
                              variant="filled"
                              className="admin-input-design input-placeholder input-placeholder-box  input-placeholder-box-set"
                              name="remark"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values?.remark}
                            />
                            {touched?.remark && errors?.remark && (
                              <Index.FormHelperText error>
                                {errors?.remark}
                              </Index.FormHelperText>
                            )}
                          </Index.Box>
                        </Index.Grid>
                        <Index.Grid item xs={12} sm={12} md={12}>
                          {values.orderDetails?.length > 0 && (
                            <FieldArray name="orderDetails">
                              {({ push, remove }) => (
                                <>
                                  {values.orderDetails.map((row, index) => (
                                    <Index.Grid
                                      container
                                      spacing={2}
                                      mt={2}
                                      key={index}
                                    >
                                      <Index.Grid item xs={12} sm={6} md={4}>
                                        <Index.Typography
                                          variant="label"
                                          component="label"
                                          className="input-label"
                                        >
                                          Product Name
                                        </Index.Typography>
                                        <Index.Box className="input-design-div with-border">
                                          <Index.Select
                                            className="placeholder-set-text-box"
                                            fullWidth
                                            name={`orderDetails.${index}.product`}
                                            value={
                                              values.orderDetails[index].product
                                            }
                                            key={index}
                                            displayEmpty
                                            inputProps={{
                                              "aria-label": "Without label",
                                            }}
                                            onChange={handleChange}
                                          >
                                            <Index.MenuItem value={""}>
                                              <Index.MenuItem
                                                disabled
                                                className="all-select-label select-label-font"
                                              >
                                                Select product name
                                              </Index.MenuItem>
                                            </Index.MenuItem>
                                            {allProductData?.map(
                                              (items, index) => (
                                                <Index.MenuItem
                                                  value={items?.item}
                                                  key={items?._id}
                                                >
                                                  {items?.item}
                                                </Index.MenuItem>
                                              )
                                            )}
                                          </Index.Select>
                                          {touched &&
                                            touched.orderDetails &&
                                            touched.orderDetails[index] &&
                                            touched.orderDetails[index]
                                              .product &&
                                            errors &&
                                            errors.orderDetails &&
                                            errors.orderDetails[index] &&
                                            errors.orderDetails[index]
                                              .product &&
                                            errors &&
                                            errors.orderDetails &&
                                            errors.orderDetails[index] &&
                                            errors.orderDetails[index]
                                              .product &&
                                            touched &&
                                            touched.orderDetails &&
                                            touched.orderDetails[index] &&
                                            touched.orderDetails[index]
                                              .product && (
                                              <Index.FormHelperText error>
                                                {
                                                  errors.orderDetails[index]
                                                    .product
                                                }
                                              </Index.FormHelperText>
                                            )}
                                        </Index.Box>
                                      </Index.Grid>

                                      <Index.Grid item xs={12} sm={6} md={4}>
                                        <Index.Typography
                                          variant="label"
                                          component="label"
                                          className="input-label"
                                        >
                                          Quantity
                                        </Index.Typography>
                                        <Index.Box className="input-design-div with-border">
                                          <Index.TextField
                                            hiddenLabel
                                            id="filled-hidden-label-normal"
                                            placeholder="Enter quantity"
                                            variant="filled"
                                            inputProps={{
                                              step: "0.001",
                                              // This limits to three decimal places
                                            }}
                                            key={index}
                                            className="admin-input-design input-placeholder"
                                            name={`orderDetails.${index}.quantity`}
                                            value={
                                              values.orderDetails[index]
                                                .quantity
                                            }
                                            autoComplete="off"
                                            onBlur={handleBlur}
                                            // defaultValue=""
                                            onChange={handleChange}
                                            helperText={
                                              touched &&
                                              touched.orderDetails &&
                                              touched.orderDetails[index] &&
                                              touched.orderDetails[index]
                                                .quantity &&
                                              errors &&
                                              errors.orderDetails &&
                                              errors.orderDetails[index] &&
                                              errors.orderDetails[index]
                                                .quantity
                                            }
                                            error={Boolean(
                                              errors &&
                                                errors.orderDetails &&
                                                errors.orderDetails[index] &&
                                                errors.orderDetails[index]
                                                  .quantity &&
                                                touched &&
                                                touched.orderDetails &&
                                                touched.orderDetails[index] &&
                                                touched.orderDetails[index]
                                                  .quantity
                                            )}
                                          />
                                        </Index.Box>
                                      </Index.Grid>

                                      <Index.Grid item xs={12} sm={6} md={4}>
                                        <Index.Typography
                                          variant="label"
                                          component="label"
                                          className="input-label"
                                        ></Index.Typography>

                                        <Index.Box className="incre-btn-main">
                                          {index ===
                                          values.orderDetails?.length - 1 ? (
                                            <>
                                              <Index.Button
                                                sx={{ fontSize: "20px" }}
                                                className="button-add inc-dic-btn"
                                                type="button"
                                                onClick={() =>
                                                  push({
                                                    product: "",
                                                    quantity: "",
                                                  })
                                                }
                                              >
                                                +
                                              </Index.Button>
                                            </>
                                          ) : (
                                            <></>
                                          )}
                                          {console.log("values", values)}

                                          {values?.orderDetails?.length > 1 && (
                                            <Index.Button
                                              sx={{ fontSize: "20px" }}
                                              type="button"
                                              className="button-remove inc-dic-btn"
                                              disabled={
                                                values?.orderDetails?.length > 1
                                                  ? false
                                                  : true
                                              }
                                              onClick={() => remove(index)}
                                            >
                                              -
                                            </Index.Button>
                                          )}
                                        </Index.Box>
                                      </Index.Grid>
                                    </Index.Grid>
                                  ))}
                                </>
                              )}
                            </FieldArray>
                          )}
                        </Index.Grid>
                      </Index.Grid>
                    </Index.Box>
                    <Index.Box className="common-button blue-button flex-end save-btn inquiry-submit-btn">
                      <Index.Button
                        variant="contained"
                        type="submit"
                        disabled={loading}
                      >
                        {loading ? (
                          <Index.CircularProgress color="secondary" size={20} />
                        ) : editData ? (
                          "Update"
                        ) : (
                          "Submit"
                        )}
                      </Index.Button>
                    </Index.Box>
                  </form>
                )}
              </Formik>
            </Index.Box>
          </Index.Box>
        </>
      )}

      <CurrentEnquiryStatusModal
        updateOpen={updateOpen}
        handleClose={handleClose}
        inquiryStatusUpdate={inquiryMultipleStatusUpdate}
        // currentEnquiry={checkTabvalue}
        enquiriesData={[{ ...editData }]}
      />

      <CurrentEnquiryLostStatusModal
        openLostModal={openLostModal}
        handleLostEnquiry={handleLostEnquiry}
        handleCloseLostEnquiryModal={handleCloseLostEnquiryModal}
        customMessage={"Do you want to lost this enquiry ?"}
      />
    </>
  );
}

export default InquiryAdd;
