import React, { useEffect, useState } from "react";
import Index from "../../../../Index";
import { getSlopeSludgeChallanList } from "../../../../../redux/admin/action";
import { useDispatch } from "react-redux";

const SlopeAndSludgeTiming = ({ id, value }) => {
  const dispatch = useDispatch();
  const navigate = Index.useNavigate();
  const [challanData, setChallanData] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleEditBunkerTiming = (row) => {
    row[0].orderId = id;
    navigate("/admin/slopeSludgeOrder/edit-timing", {
      state: { row, type: "editSlopeSludge", value: value },
    });
  };

  const getAllChallanList = () => {
    setLoading(true);
    dispatch(getSlopeSludgeChallanList(id)).then((res) => {
      if (res?.status === 200) {
        setChallanData(res?.data);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      } else {
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      }
    });
  };

  useEffect(() => {
    getAllChallanList();
  }, []);
  return (
    <>
      {loading ? (
        <Index.Table>
          <Index.TableBody>
            <Index.TableRow>
              <Index.TableCell
                colSpan={6}
                align="center"
                className="loading-purchase"
              >
                <Index.CircularProgress color="secondary" size={20} />
              </Index.TableCell>
            </Index.TableRow>
          </Index.TableBody>
        </Index.Table>
      ) : (
        <>
          <Index.Box className="common-button blue-button save-btn res-button-width res-button-add-set">
            {challanData?.[0]?._id ? (
              <>
                <Index.Button
                  className="set-active-btn"
                  variant="contained"
                  type="submit"
                  onClick={() => handleEditBunkerTiming(challanData)}
                >
                  Edit Slope & Sludge Timing
                </Index.Button>
              </>
            ) : (
              ""
            )}
          </Index.Box>
          <Index.Box className="">
            <Index.Grid container spacing={2}>
              {challanData?.length ? (
                challanData &&
                challanData?.map((items, index) => {
                  return (
                    <>
                      <Index.Grid item xs={12} sm={6} md={4}>
                        <Index.Box>
                          <Index.Box>
                            <Index.Typography
                              variant="label"
                              component="label"
                              className="input-label opacity-60"
                            >
                              Anchor Awigh
                            </Index.Typography>
                          </Index.Box>
                        </Index.Box>

                        <Index.Box className="input-design-div with-border">
                          <Index.Typography
                            variant="label"
                            component="label"
                            className="input-label"
                          >
                            {items?.anchorAweighForSupply
                              ? Index.moment(
                                  items?.anchorAweighForSupply
                                ).format("DD/MM/YYYY HH:mm")
                              : "-"}
                          </Index.Typography>
                        </Index.Box>
                      </Index.Grid>

                      <Index.Grid item xs={12} sm={6} md={4}>
                        <Index.Box>
                          <Index.Box>
                            <Index.Typography
                              variant="label"
                              component="label"
                              className="input-label opacity-60"
                            >
                              Alongside On Vessel
                            </Index.Typography>
                          </Index.Box>
                        </Index.Box>

                        <Index.Box className="input-design-div with-border">
                          <Index.Typography
                            variant="label"
                            component="label"
                            className="input-label"
                          >
                            {items?.alongsideOnVessel
                              ? Index.moment(items?.alongsideOnVessel).format(
                                  "DD/MM/YYYY HH:mm"
                                )
                              : "-"}
                          </Index.Typography>
                        </Index.Box>
                      </Index.Grid>
                      <Index.Grid item xs={12} sm={6} md={4}>
                        <Index.Box>
                          <Index.Box>
                            <Index.Typography
                              variant="label"
                              component="label"
                              className="input-label opacity-60"
                            >
                              Pumping Start
                            </Index.Typography>
                          </Index.Box>
                        </Index.Box>

                        <Index.Box className="input-design-div with-border">
                          <Index.Typography
                            variant="label"
                            component="label"
                            className="input-label"
                          >
                            {items?.pumpingStart
                              ? Index.moment(items?.pumpingStart).format(
                                  "DD/MM/YYYY HH:mm"
                                )
                              : "-"}
                          </Index.Typography>
                        </Index.Box>
                      </Index.Grid>
                      <Index.Grid item xs={12} sm={6} md={4}>
                        <Index.Box>
                          <Index.Box>
                            <Index.Typography
                              variant="label"
                              component="label"
                              className="input-label opacity-60"
                            >
                              Pumping Completed
                            </Index.Typography>
                          </Index.Box>
                        </Index.Box>

                        <Index.Box className="input-design-div with-border">
                          <Index.Typography
                            variant="label"
                            component="label"
                            className="input-label"
                          >
                            {items?.pumpingCompleted
                              ? Index.moment(items?.pumpingCompleted).format(
                                  "DD/MM/YYYY HH:mm"
                                )
                              : "-"}
                          </Index.Typography>
                        </Index.Box>
                      </Index.Grid>

                      <Index.Grid item xs={12} sm={6} md={4}>
                        <Index.Box>
                          <Index.Box>
                            <Index.Typography
                              variant="label"
                              component="label"
                              className="input-label opacity-60"
                            >
                              Cast Off
                            </Index.Typography>
                          </Index.Box>
                        </Index.Box>

                        <Index.Box className="input-design-div with-border">
                          <Index.Typography
                            variant="label"
                            component="label"
                            className="input-label"
                          >
                            {/* {items?.alongsideOnVessel} */}
                            {items?.castOff
                              ? Index.moment(items?.castOff).format(
                                  "DD/MM/YYYY HH:mm"
                                )
                              : "-"}
                          </Index.Typography>
                        </Index.Box>
                      </Index.Grid>
                      <Index.Grid item xs={12} sm={6} md={4}>
                        <Index.Box>
                          <Index.Box>
                            <Index.Typography
                              variant="label"
                              component="label"
                              className="input-label opacity-60"
                            >
                              Supplied Quantity
                            </Index.Typography>
                          </Index.Box>
                        </Index.Box>

                        <Index.Box className="input-design-div with-border">
                          <Index.Typography
                            variant="label"
                            component="label"
                            className="input-label"
                          >
                            {/* {items?.pumpingStart} */}
                            {items?.suppliedQty ? items?.suppliedQty : "-"}
                          </Index.Typography>
                        </Index.Box>
                      </Index.Grid>
                      <Index.Grid item xs={12} sm={6} md={4}>
                        <Index.Box>
                          <Index.Box>
                            <Index.Typography
                              variant="label"
                              component="label"
                              className="input-label opacity-60"
                            >
                              Issue Challan Number
                            </Index.Typography>
                          </Index.Box>
                        </Index.Box>

                        <Index.Box className="input-design-div with-border">
                          <Index.Typography
                            variant="label"
                            component="label"
                            className="input-label"
                          >
                            {/* {items?.pumpingStart} */}
                            {items?.issuedChallanNumber
                              ? items?.issuedChallanNumber
                              : "-"}
                          </Index.Typography>
                        </Index.Box>
                      </Index.Grid>
                      <Index.Grid item xs={12} sm={6} md={4}>
                        <Index.Box>
                          <Index.Box>
                            <Index.Typography
                              variant="label"
                              component="label"
                              className="input-label opacity-60"
                            >
                              Issue Challan Date
                            </Index.Typography>
                          </Index.Box>
                        </Index.Box>

                        <Index.Box className="input-design-div with-border">
                          <Index.Typography
                            variant="label"
                            component="label"
                            className="input-label"
                          >
                            {/* {items?.pumpingCompleted} */}
                            {items?.issuedChallanDate
                              ? Index.moment(items?.issuedChallanDate).format(
                                  "DD/MM/YYYY HH:mm"
                                )
                              : "-"}
                          </Index.Typography>
                        </Index.Box>
                      </Index.Grid>

                      <Index.Grid item xs={12} sm={6} md={4}>
                        <Index.Box>
                          <Index.Box>
                            <Index.Typography
                              variant="label"
                              component="label"
                              className="input-label opacity-60"
                            >
                              Anchor Dropped At Area Bunder
                            </Index.Typography>
                          </Index.Box>
                        </Index.Box>

                        <Index.Box className="input-design-div with-border">
                          <Index.Typography
                            variant="label"
                            component="label"
                            className="input-label"
                          >
                            {/* {items?.issuedChallanDate} */}
                            {items?.anchorDroppedAtAreaBunder
                              ? Index.moment(
                                  items?.anchorDroppedAtAreaBunder
                                ).format("DD/MM/YYYY HH:mm")
                              : "-"}
                          </Index.Typography>
                        </Index.Box>
                      </Index.Grid>
                      
                      <Index.Grid item xs={12} sm={6} md={4}>
                        <Index.Box>
                          <Index.Box>
                            <Index.Typography
                              variant="label"
                              component="label"
                              className="input-label opacity-60"
                            >
                              RCVD BDN
                            </Index.Typography>
                          </Index.Box>
                        </Index.Box>

                        <Index.Box className="input-design-div with-border">
                          <Index.Typography
                            variant="label"
                            component="label"
                            className="input-label"
                          >
                            {/* {items?.castOff} */}
                            {items?.rcvdBdn
                              ? Index.moment(items?.rcvdBdn).format(
                                  "DD/MM/YYYY HH:mm"
                                )
                              : "-"}
                          </Index.Typography>
                        </Index.Box>
                      </Index.Grid>
                      <Index.Grid item xs={12} sm={6} md={4}>
                        <Index.Box>
                          <Index.Box>
                            <Index.Typography
                              variant="label"
                              component="label"
                              className="input-label opacity-60"
                            >
                              Pumping Rate (In Hours)
                            </Index.Typography>
                          </Index.Box>
                        </Index.Box>

                        <Index.Box className="input-design-div with-border">
                          <Index.Typography
                            variant="label"
                            component="label"
                            className="input-label"
                          >
                            {items?.pumpingRate ? items?.pumpingRate : "-"}
                          </Index.Typography>
                        </Index.Box>
                      </Index.Grid>
                      <Index.Grid item xs={12} sm={6} md={4}>
                        <Index.Box>
                          <Index.Box>
                            <Index.Typography
                              variant="label"
                              component="label"
                              className="input-label opacity-60"
                            >
                              Craft Speed (Nautical Mileage)
                            </Index.Typography>
                          </Index.Box>
                        </Index.Box>

                        <Index.Box className="input-design-div with-border">
                          <Index.Typography
                            variant="label"
                            component="label"
                            className="input-label"
                          >
                            {items?.craftSpeed ? items?.craftSpeed : "-"}
                          </Index.Typography>
                        </Index.Box>
                      </Index.Grid>
                      <Index.Grid item xs={12} sm={6} md={4}>
                        <Index.Box>
                          <Index.Box>
                            <Index.Typography
                              variant="label"
                              component="label"
                              className="input-label opacity-60"
                            >
                              Distance (In Nautical Miles)
                            </Index.Typography>
                          </Index.Box>
                        </Index.Box>

                        <Index.Box className="input-design-div with-border">
                          <Index.Typography
                            variant="label"
                            component="label"
                            className="input-label"
                          >
                            {items?.distance ? items?.distance : "-"}
                          </Index.Typography>
                        </Index.Box>
                      </Index.Grid>
                      <Index.Grid item xs={12} sm={6} md={4}>
                        <Index.Box>
                          <Index.Box>
                            <Index.Typography
                              variant="label"
                              component="label"
                              className="input-label opacity-60"
                            >
                              Diesel Consumption
                            </Index.Typography>
                          </Index.Box>
                        </Index.Box>

                        <Index.Box className="input-design-div with-border">
                          <Index.Typography
                            variant="label"
                            component="label"
                            className="input-label"
                          >
                            {/* {items?.issuedChallanDate} */}
                            {items?.dieselConsumption
                              ? items?.dieselConsumption
                              : "-"}
                          </Index.Typography>
                        </Index.Box>
                      </Index.Grid>
                      <Index.Grid item xs={12} sm={6} md={4}>
                        <Index.Box>
                          <Index.Box>
                            <Index.Typography
                              variant="label"
                              component="label"
                              className="input-label opacity-60"
                            >
                              Lop Issue
                            </Index.Typography>
                          </Index.Box>
                        </Index.Box>

                        <Index.Box className="input-design-div with-border">
                          <Index.Typography
                            variant="label"
                            component="label"
                            className="input-label"
                          >
                            {items?.lopIssue ? items?.lopIssue : "-"}
                          </Index.Typography>
                        </Index.Box>
                      </Index.Grid>

                      <Index.Grid item xs={12} sm={6} md={4}>
                        <Index.Box>
                          <Index.Box>
                            <Index.Typography
                              variant="label"
                              component="label"
                              className="input-label opacity-60"
                            >
                              Lop Issue (Remark)
                            </Index.Typography>
                          </Index.Box>
                        </Index.Box>

                        <Index.Box className="input-design-div with-border">
                          <Index.Typography
                            variant="label"
                            component="label"
                            className="input-label"
                          >
                            {items?.remarkOfLop ? items?.remarkOfLop : "-"}
                          </Index.Typography>
                        </Index.Box>
                      </Index.Grid>

                      <Index.Grid item xs={12} sm={6} md={4}>
                        <Index.Box>
                          <Index.Box>
                            <Index.Typography
                              variant="label"
                              component="label"
                              className="input-label opacity-60"
                            >
                              Slope Challan Image
                            </Index.Typography>
                          </Index.Box>
                        </Index.Box>

                        <Index.Box className="input-design-div with-border">
                          <Index.Typography
                            variant="label"
                            component="label"
                            className="input-label"
                          >
                            <img
                              src={`${
                                process.env.REACT_APP_IMAGE_URL
                              }/slopeChallan/${
                                items?.slopeChallanImage
                                  ? items?.slopeChallanImage
                                  : "-"
                              }`}
                              alt="Image Preview"
                              className="challan-img-model"
                            />
                          </Index.Typography>
                        </Index.Box>
                      </Index.Grid>
                    </>
                  );
                })
              ) : (
                <Index.Table>
                  <Index.TableRow>
                    <Index.TableCell
                      component="td"
                      variant="td"
                      scope="row"
                      className="no-data-in-list loading-purchase"
                      colSpan={6}
                      align="center"
                    >
                      No data available
                    </Index.TableCell>
                  </Index.TableRow>
                </Index.Table>
              )}
            </Index.Grid>
            {challanData?.length > 0 ? (
              <>
                <Index.Grid
                  container
                  spacing={2}
                  className="set-edit-timing-box"
                >
                  {challanData?.length ? (
                    challanData &&
                    challanData?.map((items, index) => {
                      return (
                        <>
                          {/* <Index.Box sx={{border:"2px red"}}> */}
                          <Index.Grid item xs={12} sm={6} md={4}>
                            <Index.Box>
                              <Index.Box>
                                <Index.Typography
                                  variant="label"
                                  component="label"
                                  className="input-label opacity-60"
                                >
                                  Return Type
                                </Index.Typography>
                              </Index.Box>
                            </Index.Box>

                            <Index.Box className="input-design-div with-border">
                              <Index.Typography
                                variant="label"
                                component="label"
                                className="input-label"
                              >
                                {items?.returnType ? items?.returnType : "-"}
                              </Index.Typography>
                            </Index.Box>
                          </Index.Grid>

                          <Index.Grid item xs={12} sm={6} md={4}>
                            <Index.Box>
                              <Index.Box>
                                <Index.Typography
                                  variant="label"
                                  component="label"
                                  className="input-label opacity-60"
                                >
                                  Return Cast Off
                                </Index.Typography>
                              </Index.Box>
                            </Index.Box>

                            <Index.Box className="input-design-div with-border">
                              <Index.Typography
                                variant="label"
                                component="label"
                                className="input-label"
                              >
                                {items?.returnData?.returnCastOff
                                  ? Index.moment(
                                      items?.returnData?.returnCastOff
                                    ).format("DD/MM/YYYY HH:mm")
                                  : "-"}
                              </Index.Typography>
                            </Index.Box>
                          </Index.Grid>

                          <Index.Grid item xs={12} sm={6} md={4}>
                            <Index.Box>
                              <Index.Box>
                                <Index.Typography
                                  variant="label"
                                  component="label"
                                  className="input-label opacity-60"
                                >
                                  Return Anchor Drop
                                </Index.Typography>
                              </Index.Box>
                            </Index.Box>

                            <Index.Box className="input-design-div with-border">
                              <Index.Typography
                                variant="label"
                                component="label"
                                className="input-label"
                              >
                                {items?.returnData?.returnAnchorDrop
                                  ? Index.moment(
                                      items?.returnData?.returnAnchorDrop
                                    ).format("DD/MM/YYYY HH:mm")
                                  : "-"}
                              </Index.Typography>
                            </Index.Box>
                          </Index.Grid>

                          <Index.Grid item xs={12} sm={6} md={4}>
                            <Index.Box>
                              <Index.Box>
                                <Index.Typography
                                  variant="label"
                                  component="label"
                                  className="input-label opacity-60"
                                >
                                  Return Diesel Consumption
                                </Index.Typography>
                              </Index.Box>
                            </Index.Box>

                            <Index.Box className="input-design-div with-border">
                              <Index.Typography
                                variant="label"
                                component="label"
                                className="input-label"
                              >
                                {items?.returnData?.returnDieselConsumption
                                  ? items?.returnData?.returnDieselConsumption
                                  : "-"}
                              </Index.Typography>
                            </Index.Box>
                          </Index.Grid>
                          <Index.Grid item xs={12} sm={6} md={4}>
                            <Index.Box>
                              <Index.Box>
                                <Index.Typography
                                  variant="label"
                                  component="label"
                                  className="input-label opacity-60"
                                >
                                  Return Reason
                                </Index.Typography>
                              </Index.Box>
                            </Index.Box>

                            <Index.Box className="input-design-div with-border">
                              <Index.Typography
                                variant="label"
                                component="label"
                                className="input-label"
                              >
                                {items?.returnData?.returnReason
                                  ? items?.returnData?.returnReason
                                  : "-"}
                              </Index.Typography>
                            </Index.Box>
                          </Index.Grid>
                        </>
                      );
                    })
                  ) : (
                    <Index.Table>
                      <Index.TableRow>
                        <Index.TableCell
                          component="td"
                          variant="td"
                          scope="row"
                          className="no-data-in-list loading-purchase"
                          colSpan={6}
                          align="center"
                        >
                          No data available
                        </Index.TableCell>
                      </Index.TableRow>
                    </Index.Table>
                  )}
                </Index.Grid>
              </>
            ) : (
              ""
            )}
            {challanData?.length > 0 ? (
              <>
                <Index.Grid
                  container
                  spacing={2}
                  className="set-edit-timing-box"
                >
                  {challanData?.length ? (
                    challanData &&
                    challanData?.map((items, index) => {
                      return (
                        <>
                          {/* <Index.Box sx={{border:"2px red"}}> */}

                          {/* </Index.Box> */}
                          <Index.Grid item xs={12} sm={6} md={4}>
                            <Index.Box>
                              <Index.Box>
                                <Index.Typography
                                  variant="label"
                                  component="label"
                                  className="input-label opacity-60"
                                >
                                  Waiting
                                </Index.Typography>
                              </Index.Box>
                            </Index.Box>

                            <Index.Box className="input-design-div with-border">
                              <Index.Typography
                                variant="label"
                                component="label"
                                className="input-label"
                              >
                                {items?.waiting === true ? "Yes" : "No"}
                              </Index.Typography>
                            </Index.Box>
                          </Index.Grid>

                          <Index.Grid item xs={12} sm={6} md={4}>
                            <Index.Box>
                              <Index.Box>
                                <Index.Typography
                                  variant="label"
                                  component="label"
                                  className="input-label opacity-60"
                                >
                                  Drop Anchor At Anchorage
                                </Index.Typography>
                              </Index.Box>
                            </Index.Box>

                            <Index.Box className="input-design-div with-border">
                              <Index.Typography
                                variant="label"
                                component="label"
                                className="input-label"
                              >
                                {items?.dropAnchorAtAnchorage
                                  ? Index.moment(
                                      items?.dropAnchorAtAnchorage
                                    ).format("DD/MM/YYYY HH:mm")
                                  : "-"}
                              </Index.Typography>
                            </Index.Box>
                          </Index.Grid>

                          <Index.Grid item xs={12} sm={6} md={4}>
                            <Index.Box>
                              <Index.Box>
                                <Index.Typography
                                  variant="label"
                                  component="label"
                                  className="input-label opacity-60"
                                >
                                  Anchor Awigh From Anchorage
                                </Index.Typography>
                              </Index.Box>
                            </Index.Box>

                            <Index.Box className="input-design-div with-border">
                              <Index.Typography
                                variant="label"
                                component="label"
                                className="input-label"
                              >
                                {items?.anchorAwighFromAnchorage
                                  ? Index.moment(
                                      items?.anchorAwighFromAnchorage
                                    ).format("DD/MM/YYYY HH:mm")
                                  : "-"}
                              </Index.Typography>
                            </Index.Box>
                          </Index.Grid>
                        </>
                      );
                    })
                  ) : (
                    <Index.Table>
                      <Index.TableRow>
                        <Index.TableCell
                          component="td"
                          variant="td"
                          scope="row"
                          className="no-data-in-list loading-purchase"
                          colSpan={6}
                          align="center"
                        >
                          No data available
                        </Index.TableCell>
                      </Index.TableRow>
                    </Index.Table>
                  )}
                </Index.Grid>
              </>
            ) : (
              ""
            )}
          </Index.Box>
        </>
      )}
    </>
  );
};

export default SlopeAndSludgeTiming;
