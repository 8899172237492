import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import {
  changePurchaseDirectIndirectType,
  getAllTransferDetails,
} from "../../../../redux/admin/action";
import { NEW_EXPORT_EXCEL } from "../ExportAll";
import dayjs from "dayjs";
import { SingleInputDateRangeField } from "@mui/x-date-pickers-pro/SingleInputDateRangeField";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";

const Search = Index.styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: Index.alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: Index.alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const StyledInputBase = Index.styled(Index.InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

const shortcutsItems = [
  {
    label: "This Week",
    getValue: () => {
      const today = dayjs();
      return [today.startOf("week"), today.endOf("week")];
    },
  },
  {
    label: "Last Week",
    getValue: () => {
      const today = dayjs();
      const prevWeek = today.subtract(7, "day");
      return [prevWeek.startOf("week"), prevWeek.endOf("week")];
    },
  },
  {
    label: "Last 7 Days",
    getValue: () => {
      const today = dayjs();
      return [today.subtract(7, "day"), today];
    },
  },
  {
    label: "Current Month",
    getValue: () => {
      const today = dayjs();
      return [today.startOf("month"), today.endOf("month")];
    },
  },
  {
    label: "Next Month",
    getValue: () => {
      const today = dayjs();
      const startOfNextMonth = today.endOf("month").add(1, "day");
      return [startOfNextMonth, startOfNextMonth.endOf("month")];
    },
  },
  { label: "Reset", getValue: () => [null, null] },
];

const Transfer = () => {
  const dispatch = Index.useDispatch();
  const [loading, setLoading] = useState(true);
  const [purchaseExpenseType, setPurchaseExpenseType] = React.useState([]);

  // State for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [paginationPerPage, setPaginationPerPage] = useState(50);
  const [totalRecordCount, setTotalRecordCount] = useState(0);
  const [searchingText, setSearchingText] = useState("");
  const [transferList, setTransferList] = useState([]);
  const [serachedDateRange, setSearchedDateRange] = useState("");

  // get purchase list
  const getTransferData = (values) => {
    setLoading(true);

    dispatch(getAllTransferDetails(values)).then((res) => {
      if (res?.status === 200) {
        setTotalRecordCount(res?.totalRecordCount);
        setLoading(false);
        setCurrentPage(parseInt(res?.currentPage));
        setTransferList(res.data);
      } else {
        setLoading(false);
        setCurrentPage(1);
        setTransferList([]);
      }
    });
  };
  useEffect(() => {
    const values = {
      page: 1,
      search: "",
      size: paginationPerPage,
      to:
        serachedDateRange?.length > 0
          ? serachedDateRange[0] !== null
            ? serachedDateRange[0].format("YYYY-MM-DD")
            : ""
          : "",
      from:
        serachedDateRange?.length > 0
          ? serachedDateRange[1] !== null
            ? serachedDateRange[1].format("YYYY-MM-DD")
            : ""
          : "",
    };
    getTransferData(values);
  }, []);

  const handlePageChange = React.useCallback(
    (event, value) => {
      setCurrentPage(value);
      const values = {
        page: value,
        search: searchingText,
        size: paginationPerPage,
        to:
          serachedDateRange?.length > 0
            ? serachedDateRange[0] !== null
              ? serachedDateRange[0].format("YYYY-MM-DD")
              : ""
            : "",
        from:
          serachedDateRange?.length > 0
            ? serachedDateRange[1] !== null
              ? serachedDateRange[1].format("YYYY-MM-DD")
              : ""
            : "",
      };
      getTransferData(values);
    },
    [paginationPerPage, searchingText, serachedDateRange, setCurrentPage]
  );

  // when the user search the purchase records then used...
  const handleSearchPurchaseRecord = React.useCallback(
    (e) => {
      const { value } = e.target;
      if (value?.length > 0) {
        setSearchingText(value);
        const values = {
          page: 1,
          search: value.trim(),
          size: paginationPerPage,
          to:
            serachedDateRange?.length > 0
              ? serachedDateRange[0] !== null
                ? serachedDateRange[0].format("YYYY-MM-DD")
                : ""
              : "",
          from:
            serachedDateRange?.length > 0
              ? serachedDateRange[1] !== null
                ? serachedDateRange[1].format("YYYY-MM-DD")
                : ""
              : "",
        };
        getTransferData(values);
      } else {
        setSearchingText("");
        const values = {
          page: 1,
          search: "",
          size: paginationPerPage,
          to:
            serachedDateRange?.length > 0
              ? serachedDateRange[0] !== null
                ? serachedDateRange[0].format("YYYY-MM-DD")
                : ""
              : "",
          from:
            serachedDateRange?.length > 0
              ? serachedDateRange[1] !== null
                ? serachedDateRange[1].format("YYYY-MM-DD")
                : ""
              : "",
        };
        getTransferData(values);
      }
    },
    [paginationPerPage, serachedDateRange, setSearchingText]
  );

  // when the user change the per page record change then used..
  const handleChangePaginationPerChange = React.useCallback(
    (e) => {
      const { value } = e.target;
      setPaginationPerPage(value);
      const values = {
        page: 1,
        search: searchingText,
        size: value,
        to:
          serachedDateRange?.length > 0
            ? serachedDateRange[0] !== null
              ? serachedDateRange[0].format("YYYY-MM-DD")
              : ""
            : "",
        from:
          serachedDateRange?.length > 0
            ? serachedDateRange[1] !== null
              ? serachedDateRange[1].format("YYYY-MM-DD")
              : ""
            : "",
      };
      getTransferData(values);
    },
    [serachedDateRange, searchingText, setPaginationPerPage]
  );

  // when the use change the date then used...
  const handleChangeDate = React.useCallback(
    (data) => {
      setSearchedDateRange(data);
      if (data[0] !== null && data[1] !== null) {
        const values = {
          page: 1,
          search: searchingText,
          size: paginationPerPage,
          to: data[0] !== null ? data[0].format("YYYY-MM-DD") : "",
          from: data[1] !== null ? data[1].format("YYYY-MM-DD") : "",
        };
        getTransferData(values);
      } else {
        const values = {
          page: 1,
          search: searchingText,
          size: paginationPerPage,
          to:
            serachedDateRange?.length > 0
              ? serachedDateRange[0] !== null
                ? serachedDateRange[0].format("YYYY-MM-DD")
                : ""
              : "",
          from:
            serachedDateRange?.length > 0
              ? serachedDateRange[1] !== null
                ? serachedDateRange[1].format("YYYY-MM-DD")
                : ""
              : "",
        };
        getTransferData(values);
      }
    },
    [setSearchedDateRange, searchingText, paginationPerPage]
  );

  return (
    <div>
      <Index.DynamicTitle title="Transfer" />
      <Index.Box className="">
        <Index.Box className="barge-common-box">
          <Index.Box className="title-header">
            <Index.Box className="title-header-flex  res-title-header-flex">
              <Index.Box className="title-main">
                <Index.Typography
                  variant="p"
                  component="p"
                  className="page-title"
                >
                  Transfer
                </Index.Typography>
              </Index.Box>
              <Index.Box className="d-flex align-items-center res-set-search">
                {/* Date range start */}
                <Index.Box className="input-design-div">
                  <Index.Box className="set-date set-date-picker remove-name">
                    <Index.LocalizationProvider
                      dateAdapter={Index.AdapterDayjs}
                    >
                      <Index.DemoContainer
                        components={["SingleInputDateRangeField"]}
                      >
                        <DateRangePicker
                          slots={{ field: SingleInputDateRangeField }}
                          sx={false}
                          slotProps={{
                            shortcuts: {
                              items: shortcutsItems,
                            },
                            actionBar: { actions: [] },
                          }}
                          disableFuture
                          onChange={(value) => handleChangeDate(value)}
                          name="allowedRange"
                          format="DD/MM/YYYY"
                        />
                      </Index.DemoContainer>
                    </Index.LocalizationProvider>
                  </Index.Box>
                </Index.Box>
                {/* Date range end */}

                <Search className="search">
                  <StyledInputBase
                    placeholder="Search"
                    inputProps={{ "aria-label": "search" }}
                    onChange={(e) => handleSearchPurchaseRecord(e)}
                  />
                </Search>
                {transferList?.length ? (
                  <Index.Box className="common-button grey-button">
                    <Index.Button
                      variant="contained"
                      onClick={() => {
                        let curDate = Index.moment(new Date()).format(
                          "YYYY-MM-DD"
                        );
                        NEW_EXPORT_EXCEL(
                          "/admin/export-transfer",
                          `Transfer_List_${curDate}`,
                          {
                            search: searchingText,
                            to:
                              serachedDateRange?.length > 0
                                ? serachedDateRange[0] !== null
                                  ? serachedDateRange[0].format("YYYY-MM-DD")
                                  : ""
                                : "",
                            from:
                              serachedDateRange?.length > 0
                                ? serachedDateRange[1] !== null
                                  ? serachedDateRange[1].format("YYYY-MM-DD")
                                  : ""
                                : "",
                          }
                        );
                      }}
                    >
                      Export
                    </Index.Button>
                  </Index.Box>
                ) : (
                  ""
                )}
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Index.Box className="">
            <Index.TableContainer
              component={Index.Paper}
              className="table-container"
            >
              <Index.Table
                aria-label="simple table"
                className="table-design-main invoice-table  "
              >
                <Index.TableHead>
                  <Index.TableRow>
                    <Index.TableCell className="invoice_table_header">
                      Transfer OUT craft name
                    </Index.TableCell>
                    <Index.TableCell
                      align="left"
                      className="invoice_table_header"
                    >
                      Transfer IN craft Name
                    </Index.TableCell>
                    <Index.TableCell
                      align="left"
                      className="invoice_table_header"
                    >
                      Time Stamp
                    </Index.TableCell>
                    <Index.TableCell
                      align="left"
                      className="invoice_table_header"
                    >
                      Vessel Name
                    </Index.TableCell>
                    <Index.TableCell
                      align="left"
                      className="invoice_table_header"
                    >
                      QTY
                    </Index.TableCell>
                    <Index.TableCell
                      align="left"
                      className="invoice_table_header"
                    >
                      Transfer Start time
                    </Index.TableCell>
                    <Index.TableCell
                      align="left"
                      className="invoice_table_header"
                    >
                      Transfer completed time
                    </Index.TableCell>
                  </Index.TableRow>
                </Index.TableHead>
                {loading ? (
                  <Index.TableBody>
                    <Index.TableRow>
                      <Index.TableCell
                        colSpan={12}
                        align="center"
                        className="loading-purchase"
                      >
                        <Index.CircularProgress color="secondary" size={20} />
                      </Index.TableCell>
                    </Index.TableRow>
                  </Index.TableBody>
                ) : (
                  <Index.TableBody>
                    {transferList?.length ? (
                      transferList?.map((row, index) => (
                        <Index.TableRow key={row?._id}>
                          <Index.TableCell>
                            {row?.transferOutBarge?.hasOwnProperty("bargeName")
                              ? row?.transferOutBarge?.bargeName
                              : "-"}
                          </Index.TableCell>
                          <Index.TableCell>
                            {row?.transferInBarge?.hasOwnProperty("bargeName")
                              ? row?.transferInBarge?.bargeName
                              : "-"}
                          </Index.TableCell>
                          <Index.TableCell>
                            {row?.createdAt
                              ? Index.moment(row?.createdAt).format(
                                  "DD/MM/YYYY hh:mm:ss"
                                )
                              : "-"}
                          </Index.TableCell>

                          <Index.TableCell>
                            {row?.order?.vesselName
                              ? row?.order?.vesselName
                              : "-"}
                          </Index.TableCell>
                          <Index.TableCell>
                            {row?.quantity ? row?.quantity : "-"}
                          </Index.TableCell>
                          <Index.TableCell>
                            {row?.transferStart
                              ? Index.moment(row?.transferStart).format(
                                  "DD/MM/YYYY hh:mm:ss"
                                )
                              : "-"}
                          </Index.TableCell>
                          <Index.TableCell>
                            {row?.transferComplete
                              ? Index.moment(row?.transferComplete).format(
                                  "DD/MM/YYYY hh:mm:ss"
                                )
                              : "-"}
                          </Index.TableCell>
                        </Index.TableRow>
                      ))
                    ) : (
                      <Index.TableRow>
                        <Index.TableCell
                          component="td"
                          variant="td"
                          scope="row"
                          className="no-data-in-list empty-record-align"
                          colSpan={14}
                          align="center"
                        >
                          No data available
                        </Index.TableCell>
                      </Index.TableRow>
                    )}
                  </Index.TableBody>
                )}
              </Index.Table>
            </Index.TableContainer>
          </Index.Box>
          <Index.Box className="pagination-design flex-end">
            <Index.Stack spacing={2}>
              <Index.Box className="pagination-count pagination-loader">
                <Index.Box className="input-design-div with-border">
                  <Index.Select
                    fullWidth
                    value={paginationPerPage}
                    onChange={(e) => handleChangePaginationPerChange(e)}
                  >
                    <Index.MenuItem value={10}>10</Index.MenuItem>
                    <Index.MenuItem value={25}>25</Index.MenuItem>
                    <Index.MenuItem value={50}>50</Index.MenuItem>
                    <Index.MenuItem value={100}>100</Index.MenuItem>
                  </Index.Select>
                </Index.Box>
                {loading ? (
                  <Index.CircularProgress color="secondary" size={26} />
                ) : (
                  <>
                    <Index.Pagination
                      count={Math.ceil(totalRecordCount / paginationPerPage)}
                      page={currentPage}
                      onChange={handlePageChange}
                    />
                  </>
                )}
              </Index.Box>
            </Index.Stack>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </div>
  );
};

export default Transfer;
