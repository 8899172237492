import React from "react";
import Index from "../../../../Index";
import * as XLSX from "xlsx";
import * as XlsxPopulate from "xlsx-populate/browser/xlsx-populate";

const ExcelExportHelper = ({ data, loading }) => {
  const createDownLoadData = () => {
    handleExport().then((url) => {
      const downloadAnchorNode = document.createElement("a");
      downloadAnchorNode.setAttribute("href", url);
      downloadAnchorNode.setAttribute("download", "BunkerOrderDashboard.xlsx");
      downloadAnchorNode.click();
      downloadAnchorNode.remove();
    });
  };

  const s2ab = (s) => {
    // The ArrayBuffer() constructor is used to create ArrayBuffer objects.
    // create an ArrayBuffer with a size in bytes
    const buf = new ArrayBuffer(s.length);
    //create a 8 bit integer array
    const view = new Uint8Array(buf);
    //charCodeAt The charCodeAt() method returns an integer between 0 and 65535 representing the UTF-16 code
    for (let i = 0; i !== s.length; ++i) {
      view[i] = s.charCodeAt(i);
    }
    return buf;
  };

  const workbook2blob = (workbook) => {
    const wopts = {
      bookType: "xlsx",
      bookSST: false,
      type: "binary",
    };

    const wbout = XLSX.write(workbook, wopts);

    // The application/octet-stream MIME type is used for unknown binary files.
    // It preserves the file contents, but requires the receiver to determine file type,
    // for example, from the filename extension.
    const blob = new Blob([s2ab(wbout)], {
      type: "application/octet-stream",
    });

    return blob;
  };

  const handleExport = () => {
    const title = [{ A: "BUNKER ORDER DASHBOARD" }, {}];

    let table1 = [
      // Transportation table
      {
        A: "Sr.No",
        B: "TRANSPORTATION",
        C: "Qty (in MT)",
      },
    ];

    let table2 = [
      // Barge table
      {
        A: "Sr.No",
        B: "AS PER BARGE",
        C: "Qty (in MT)",
      },
    ];

    let table3 = [
      // Terminal table
      {
        A: "Sr.No",
        B: "AS PER TERMINAL",
        C: "Qty (in MT)",
      },
    ];

    let table4 = [
      // Product table
      {
        A: "Sr.No",
        B: "AS PER PRODUCT",
        C: "Qty (in MT)",
      },
    ];

    let table5 = [
      // Total Qty table
      {
        A: "",
        B: "",
        C: "",
      },
    ];

    let table6 = [
      // Port table
      {
        A: "Sr.No",
        B: "AS PER PORT",
        C: "Qty (in MT)",
      },
    ];

    let table7 = [
      // Type table
      {
        A: "Sr.No",
        B: "AS PER TYPE",
        C: "Qty (in MT)",
      },
    ];

    let table8 = [
      // Vessel run table
      {
        A: "Sr.No",
        B: "AS PER VESSEL RUN",
        C: "Qty (in MT)",
      },
    ];

    let table9 = [
      // Trader table
      {
        A: "Sr.No",
        B: "AS PER TRADER",
        C: "Qty (in MT)",
      },
    ];

    let table10 = [
      // Bunker Order table
      {
        A: "Sr.No",
        B: "Craft",
        C: "Date",
        D: "Vessel Name",
        E: "Run",
        F: "Transportation",
        G: "Type",
        H: "Trader",
        I: "Terminal",
        J: "Agent",
        K: "Product",
        L: "Port",
        M: "Qty",
      },
    ];



    // 1. Transportation Data property
    if (data?.transportationData) {
      data?.transportationData.forEach((item, index) => {
        table1.push({
          A: index + 1,
          B: item?.transportation,
          C: item?.quantity && item?.quantity.toLocaleString("en-IN", {
            minimumFractionDigits: 3,
            maximumFractionDigits: 3,
          })
        });
      });
    }

    // 2. Barge Data property
    if (data?.bargeData) {
      data?.bargeData.forEach((item, index) => {
        table2.push({
          A: index + 1,
          B: item?.bargeName,
          C: item?.quantity && item?.quantity.toLocaleString("en-IN", {
            minimumFractionDigits: 3,
            maximumFractionDigits: 3,
          })
        });
      });
    }

    // 3. Terminal Data property
    if (data?.terminalData) {
      data?.terminalData.forEach((item, index) => {
        table3.push({
          A: index + 1,
          B: item?.terminal,
          C: item?.quantity && item?.quantity.toLocaleString("en-IN", {
            minimumFractionDigits: 3,
            maximumFractionDigits: 3,
          })
        });
      });
    }

    // 4. Product Data property
    if (data?.productData) {
      data?.productData.forEach((item, index) => {
        table4.push({
          A: index + 1,
          B: item?.product,
          C: item?.quantity && item?.quantity.toLocaleString("en-IN", {
            minimumFractionDigits: 3,
            maximumFractionDigits: 3,
          })
        });
      });
    }

    // 10. Total Qty Data property
    if (data?.productTotalQty) {
      table5.push({
        A: "",
        B: "TOTAL QTY",
        C: data?.productTotalQty?.toLocaleString("en-IN", {
          minimumFractionDigits: 3,
          maximumFractionDigits: 3,
        }),
      });
    }

    // 5. Port Data property
    if (data?.portData) {
      data?.portData.forEach((item, index) => {
        table6.push({
          A: index + 1,
          B: item?.portName,
          C: item?.quantity && item?.quantity.toLocaleString("en-IN", {
            minimumFractionDigits: 3,
            maximumFractionDigits: 3,
          })
        });
      });
    }

    // 6. Type Data property
    if (data?.exmiTypeData) {
      data?.exmiTypeData.forEach((item, index) => {
        table7.push({
          A: index + 1,
          B: item?.exmiOrDelivered,
          C: item?.quantity && item?.quantity.toLocaleString("en-IN", {
            minimumFractionDigits: 3,
            maximumFractionDigits: 3,
          })
        });
      });
    }

    // 7. Vessel Run Data property
    if (data?.vesselRun) {
      data?.vesselRun.forEach((item, index) => {
        table8.push({
          A: index + 1,
          B: item?.foreign,
          C: item?.quantity && item?.quantity.toLocaleString("en-IN", {
            minimumFractionDigits: 3,
            maximumFractionDigits: 3,
          })
        });
      });
    }

    // 8. Trader Data property
    if (data?.traderData) {
      data?.traderData.forEach((item, index) => {
        table9.push({
          A: index + 1,
          B: item?.traderName,
          C: item?.quantity && item?.quantity.toLocaleString("en-IN", {
            minimumFractionDigits: 3,
            maximumFractionDigits: 3,
          })
        });
      });
    }


    // 9. Bunker Order Data property
    if (data?.findOrder) {
      data?.findOrder.map((item, index) => {
        table10.push({
          A: index + 1,
          B: item?.proposedBarge?.length ? item?.proposedBarge[0]?.bargeName : "-",
          C: item?.createdAt ? Index.moment(item?.createdAt).format("DD/MM/YYYY") : "-",
          D: item?.vesselName ? item?.vesselName : "-",
          E: item?.foreign ? item?.foreign : "-",
          F: item?.transportation ? item?.transportation : "-",
          G: item?.exmiOrDelivered ? item?.exmiOrDelivered : "-",
          H: item?.trader ? item?.trader : "-",
          I: item?.terminal ? item?.terminal : "-",
          J: item?.agent ? item?.agent : "-",
          K: item?.product ? item?.product : "-",
          L: item?.port?.portName ? item?.port?.portName : "-",
          M: item?.lowerQuantity === "0" &&
            item?.higherQuantity === "0"
            ? "-"
            : item?.lowerQuantity &&
              item?.higherQuantity != "0"
              ? item?.lowerQuantity +
              " - " +
              item?.higherQuantity
              : item?.lowerQuantity
        });
      });
    }

    let sheet;

    table1 = [{ B: "TRANSPORTATIONS" }]
      .concat(table1)
      .concat([""])
      .concat([{ B: "BARGES" }])
      .concat(table2)
      .concat([""])
      .concat([{ B: "TERMINALS" }])
      .concat(table3)
      .concat([""])
      .concat([{ B: "PRODUCTS" }])
      .concat(table4)
      .concat(table5)
      .concat([""])
      .concat([""])
      .concat([{ B: "PORTS" }])
      .concat(table6)
      .concat([""])
      .concat([{ B: "TYPES" }])
      .concat(table7)
      .concat([""])
      .concat([{ B: "VESSEL RUN" }])
      .concat(table8)
      .concat([""])
      .concat([{ B: "TRADERS" }])
      .concat(table9)
      .concat([""])
      .concat([""])
      .concat([""])
      .concat([{ B: "BUNKER ORDERS" }])
      .concat(table10)
    const finalData = [...title, ...table1];

    //create a new workbook
    const wb = XLSX.utils.book_new();
    sheet = XLSX.utils.json_to_sheet(finalData, {
      skipHeader: true,
    });
    XLSX.utils.book_append_sheet(wb, sheet, "BunkerOrderDashboard");
    const workbookBlob = workbook2blob(wb);

    var headerIndexes = [];
    finalData.forEach((data, index) =>
      data["A"] === "Sr.No" || data["B"] === "TOTAL QTY" ? headerIndexes.push(index) : null
    );

    const totalRecords = data.length;
    const dataInfo = {
      titleCell: "A2",
      titleRange: "A1:M2",
      tbodyRange: `A3:M${finalData.length}`,
      theadRange1:
        headerIndexes?.length >= 1
          ? `A${headerIndexes[0] + 1}:C${headerIndexes[0] + 1}`
          : null,
      theadRange2:
        headerIndexes?.length >= 2
          ? `A${headerIndexes[1] + 1}:C${headerIndexes[1] + 1}`
          : null,
      theadRange3:
        headerIndexes?.length >= 3
          ? `A${headerIndexes[2] + 1}:C${headerIndexes[2] + 1}`
          : null,
      theadRange4:
        headerIndexes?.length >= 4
          ? `A${headerIndexes[3] + 1}:C${headerIndexes[3] + 1}`
          : null,
      theadRange5:
        headerIndexes?.length >= 5
          ? `A${headerIndexes[4] + 1}:C${headerIndexes[4] + 1}`
          : null,
      theadRange6:
        headerIndexes?.length >= 6
          ? `A${headerIndexes[5] + 1}:C${headerIndexes[5] + 1}`
          : null,
      theadRange7:
        headerIndexes?.length >= 7
          ? `A${headerIndexes[6] + 1}:C${headerIndexes[6] + 1}`
          : null,
      theadRange8:
        headerIndexes?.length >= 8
          ? `A${headerIndexes[7] + 1}:C${headerIndexes[7] + 1}`
          : null,
      theadRange9:
        headerIndexes?.length >= 9
          ? `A${headerIndexes[8] + 1}:C${headerIndexes[8] + 1}`
          : null,
      theadRange10:
        headerIndexes?.length >= 10
          ? `A${headerIndexes[9] + 1}:M${headerIndexes[9] + 1}`
          : null
    };
    return addStyle(workbookBlob, dataInfo);
  };

  const addStyle = (workbookBlob, dataInfo) => {
    return XlsxPopulate.fromDataAsync(workbookBlob).then((workbook) => {
      workbook.sheets().forEach((sheet) => {
        sheet.column("A").width(8);
        sheet.column("B").width(19);
        sheet.column("C").width(13);
        sheet.column("D").width(18);
        sheet.column("E").width(12);
        sheet.column("F").width(14);
        sheet.column("G").width(12);
        sheet.column("H").width(13);
        sheet.column("I").width(12);
        sheet.column("J").width(12);
        sheet.column("K").width(15);
        sheet.column("L").width(15);
        sheet.column("M").width(12);

        sheet.range(dataInfo.titleRange).merged(true).style({
          bold: true,
          horizontalAlignment: "center",
          verticalAlignment: "center",
        });

        if (dataInfo.tbodyRange) {
          sheet.range(dataInfo.tbodyRange).style({
            horizontalAlignment: "center",
          });
        }

        sheet.range(dataInfo.theadRange1).style({  // TRANSPORTATIONS Row Color
          fill: "808080",
          bold: true,
          horizontalAlignment: "center",
          fontColor: "ffffff",
        });

        if (dataInfo.theadRange2) {
          sheet.range(dataInfo.theadRange2).style({ // BARGES Row Color
            fill: "808080",
            bold: true,
            horizontalAlignment: "center",
            fontColor: "ffffff",
          });
        }

        if (dataInfo.theadRange3) { // TERMINALS Row Color
          sheet.range(dataInfo.theadRange3).style({
            fill: "808080",
            bold: true,
            horizontalAlignment: "center",
            fontColor: "ffffff",
          });
        }

        if (dataInfo.theadRange4) { // PRODUCTS Row Color
          sheet.range(dataInfo.theadRange4).style({
            fill: "808080",
            bold: true,
            horizontalAlignment: "center",
            fontColor: "ffffff",
          });
        }

        if (dataInfo.theadRange5) { // TOTAL QTY Yello Color
          sheet.range(dataInfo.theadRange5).style({
            fill: "eaac35",
            bold: true,
            horizontalAlignment: "center",
            fontColor: "000000",
          });
        }

        if (dataInfo.theadRange6) { // PORT Row Color
          sheet.range(dataInfo.theadRange6).style({
            fill: "808080",
            bold: true,
            horizontalAlignment: "center",
            fontColor: "ffffff",
          });
        }

        if (dataInfo.theadRange7) { // TYPES Row Color
          sheet.range(dataInfo.theadRange7).style({
            fill: "808080",
            bold: true,
            horizontalAlignment: "center",
            fontColor: "ffffff",
          });
        }

        if (dataInfo.theadRange8) { // VESSEL RUN Row Color
          sheet.range(dataInfo.theadRange8).style({
            fill: "808080",
            bold: true,
            horizontalAlignment: "center",
            fontColor: "ffffff",
          });
        }

        if (dataInfo.theadRange9) { // TRADERS Row Color
          sheet.range(dataInfo.theadRange9).style({
            fill: "808080",
            bold: true,
            horizontalAlignment: "center",
            fontColor: "ffffff",
          });
        }

        if (dataInfo.theadRange10) { // BUNKER ORDERS Row Color
          sheet.range(dataInfo.theadRange10).style({
            fill: "808080",
            bold: true,
            horizontalAlignment: "center",
            fontColor: "ffffff",
          });
        }

        const duplicatesMap = {};
        const dublicate_VesselName = [];
        data.findOrder.forEach((item) => {
          const vesselName = (item?.vesselName)?.toLowerCase();
          if (duplicatesMap[vesselName]) {
            dublicate_VesselName.push((item.vesselName).toLowerCase());
          } else {
            duplicatesMap[vesselName] = item?.vesselName;
          }
        });
        let match = dataInfo?.theadRange10?.match(/\d+/);
        let _theadRange10 = parseInt(match[0])
        
        // Loop through each cell in the "D" column and apply the style if needed
        data.findOrder.forEach((item, index) => {
          // "D" column, index + 1 to account for header row
          const cellAddress = XLSX.utils.encode_cell({ r: index + _theadRange10 ?? 67, c: 3 }); 
          if (dublicate_VesselName.includes((item?.vesselName).toLowerCase())) {
            //dublicate vesselName Red Color.
            sheet.cell(cellAddress).style({   
              fill: "FF0000",
              bold: true,
              horizontalAlignment: "center",
              fontColor: "ffffff",
            });
          }
        });
      });
      return workbook
        .outputAsync()
        .then((workbookBlob) => URL.createObjectURL(workbookBlob));
    });
  };

  return (
    <Index.Box className="common-button grey-button">
      <Index.Button
        variant="contained"
        onClick={() => {
          createDownLoadData();
        }}
        disabled={
          !loading && data?.findOrder?.length
            ? false
            : true
        }
      >
        Export
      </Index.Button>
    </Index.Box>
  );
};

export default ExcelExportHelper;
