import React from "react";
import Index from "../../../../Index";

const WaterSupplyOrderDetail = ({ viewData }) => {
  return (
    <>
      <Index.Grid container spacing={1}>
        <Index.Grid item xs={12} sm={6}>
          <Index.Box className="invoice-detail-item">
            <Index.Box className="input-label opacity-60">
              Agent Name:
            </Index.Box>
            <Index.Box className="input-label">
              {viewData?.agentName ? viewData?.agentName : "-"}
            </Index.Box>
          </Index.Box>
        </Index.Grid>

        <Index.Grid item xs={12} sm={6}>
          <Index.Box className="invoice-detail-item">
            <Index.Box className="input-label opacity-60">Port:</Index.Box>
            <Index.Box className="input-label">
              {viewData?.port?.supplyPortName
                ? viewData?.port?.supplyPortName
                : "-"}
            </Index.Box>
          </Index.Box>
        </Index.Grid>
        <Index.Grid item xs={12} sm={6}>
          <Index.Box className="invoice-detail-item">
            <Index.Box className="input-label opacity-60">Coastal:</Index.Box>
            <Index.Box className="input-label">
              {viewData?.coastal ? viewData?.coastal : "-"}
            </Index.Box>
          </Index.Box>
        </Index.Grid>
        <Index.Grid item xs={12} sm={6}>
          <Index.Box className="invoice-detail-item">
            <Index.Box className="input-label opacity-60">
              Water Type:
            </Index.Box>
            <Index.Box className="input-label">
              {viewData?.waterType ? viewData?.waterType : "-"}
            </Index.Box>
          </Index.Box>
        </Index.Grid>
        <Index.Grid item xs={12} sm={6}>
          <Index.Box className="invoice-detail-item">
            <Index.Box className="input-label opacity-60">Quantity:</Index.Box>
            <Index.Box className="input-label">
              {viewData?.qty ? viewData?.qty : "-"}
            </Index.Box>
          </Index.Box>
        </Index.Grid>
        <Index.Grid item xs={12} sm={6}>
          <Index.Box className="invoice-detail-item">
            <Index.Box className="input-label opacity-60">Order By:</Index.Box>
            <Index.Box className="input-label">
              {viewData?.orderBy ? viewData?.orderBy : "-"}
            </Index.Box>
          </Index.Box>
        </Index.Grid>
        <Index.Grid item xs={12} sm={6}>
          <Index.Box className="invoice-detail-item">
            <Index.Box className="input-label opacity-60">
              Vessel ETA:
            </Index.Box>
            <Index.Box className="input-label">
              {viewData?.vesselEta
                ? Index.moment(viewData.vesselEta).format("DD/MM/YYYY HH:mm")
                : "-"}
            </Index.Box>
          </Index.Box>
        </Index.Grid>
        <Index.Grid item xs={12} sm={6}>
          <Index.Box className="invoice-detail-item">
            <Index.Box className="input-label opacity-60">
              Vessel ETB:
            </Index.Box>
            <Index.Box className="input-label">
              {viewData?.vesselEtb
                ? Index.moment(viewData.vesselEtb).format("DD/MM/YYYY HH:mm")
                : "-"}
            </Index.Box>
          </Index.Box>
        </Index.Grid>
        <Index.Grid item xs={12} sm={6}>
          <Index.Box className="invoice-detail-item">
            <Index.Box className="input-label opacity-60">
              Vessel ETD:
            </Index.Box>
            <Index.Box className="input-label">
              {viewData?.vesselEtd
                ? Index.moment(viewData.vesselEtd).format("DD/MM/YYYY HH:mm")
                : "-"}
            </Index.Box>
          </Index.Box>
        </Index.Grid>
        <Index.Grid item xs={12} sm={6}>
          <Index.Box className="invoice-detail-item">
            <Index.Box className="input-label opacity-60">
              Supply Date & Time:
            </Index.Box>
            <Index.Box className="input-label">
              {viewData?.supplyDateTime
                ? Index.moment(viewData.supplyDateTime).format(
                    "DD/MM/YYYY HH:mm"
                  )
                : "-"}
            </Index.Box>
          </Index.Box>
        </Index.Grid>
        <Index.Grid item xs={12} sm={6}>
          <Index.Box className="invoice-detail-item">
            <Index.Box className="input-label opacity-60">
              Water Barge Name:
            </Index.Box>
            <Index.Box className="input-label">
              {viewData?.waterBarge?.bargeName
                ? viewData?.waterBarge?.bargeName
                : "-"}
            </Index.Box>
          </Index.Box>
        </Index.Grid>
      </Index.Grid>
    </>
  );
};

export default WaterSupplyOrderDetail;
