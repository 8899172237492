import React, {useEffect} from "react";
import Index from "../../../container/Index";
import MenuItemList from "./MenuItemList";
import { webRoleMaster } from "../../../config/CommonRoleMaster";
import { getLatestActivityTime } from "../../../redux/admin/action";

function Sidebar({ open, setOpen }) {
  const dispatch = Index.useDispatch();
  const { activityTime } = Index.useSelector((state) => state.AdminReducer);
  const loginRoleData = Index.useSelector((state) => state.AdminReducer.adminDetails);

  useEffect(()=> {
    if(loginRoleData?.permission?.roleName === "Super Admin"){
      dispatch(getLatestActivityTime());
    }
  }, [loginRoleData])

  return (
    <>
      <Index.Box
        className={`sidebar-main ${!open ? "active" : "sidebar-none"}`}
      >
        <Index.Box className="sidebar-logo">
            <img src={Index.Png.sidebar} alt="logo"></img>
           
              <Index.Box className="datetime">
             
                <Index.Typography variant="p">Last Updated Activity <br/>{Index.moment(activityTime).format("DD/MM/YYYY hh:mm A")}</Index.Typography>
              </Index.Box>
           
        </Index.Box>

        <Index.Box className="sidebar-links">
          <Index.Box className="sidebar-ul">
            <MenuItemList
              webRoleMaster={webRoleMaster}
              permissions={loginRoleData?.permission?.webPermission}
              setOpen={setOpen}
              roleType={loginRoleData?.permission?.roleName}
            />
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  );
}

export default Sidebar;
