import { useState } from "react";
import Index from "../../../../Index";
import APIService from "../../../../../redux/APIService";
import { CircularProgress } from "@mui/material";

function NewCustomSearchVessel({ ...rest }) {
  const { setFieldValue, name, apiEndpoint } = { ...rest };
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [isOpenOptions, setIsOpenOptions] = useState(false);
  console.log("name", name);
  const [searchedValue, setSearchedValue] = useState("");
  // when the user search the text then used...
  const handleChangeSearchText = async (e) => {
    const { value } = e.target;
    setSearchedValue(value);
    setFieldValue(name, value);
    setIsOpenOptions(true);
    if (value.length > 2) {
      // Trigger search after 3 characters
      setLoading(true);
      try {
        const response = await APIService.doGet(
          `${apiEndpoint}?search=${value}`
        );
        setOptions(response?.data?.data);
      } catch (error) {
        console.error("Error fetching data:", error);
        setOptions([]);
      } finally {
        setLoading(false);
      }
    } else {
      setOptions([]);
    }
  };
  return (
    <>
      <Index.Box className="custom-vessel-search">
        <Index.TextField
          //   value={searchedValue}
          {...rest}
          onChange={handleChangeSearchText}
          // onBlur={handleBlur}
          // onFocus={() => setLoading(false)}
          // value={values.email}
          // onChange={handleChange}
          // helperText={touched.email && errors.email}
          // error={Boolean(errors.email && touched.email)}
          sx={{ mb: 3 }}
        />
        {options?.length > 0 && isOpenOptions ? (
          <>
            <Index.Box className="cus-search-opt-box">
              {loading ? (
                <>
                  <Index.Box className="cus-search-circular">
                    <CircularProgress color="inherit" size={20} />
                  </Index.Box>
                </>
              ) : (
                <>
                  <Index.Box className="cust-search-box">
                    {options?.map((el) => (
                      <Index.Box
                        className="cust-search-item"
                        onClick={() => {
                          setSearchedValue(el[name]);
                          setFieldValue(name, el[name]);
                          setIsOpenOptions(false);
                        }}
                      >
                        {el[name]}
                      </Index.Box>
                    ))}
                  </Index.Box>
                </>
              )}
            </Index.Box>
          </>
        ) : (
          <></>
        )}
      </Index.Box>
    </>
  );
}
export default NewCustomSearchVessel;
