import React, { useEffect, useState } from "react";
import Index from "../../../../Index";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useSearchParams } from "react-router-dom";
import { getDueInvoiceDetailsByDateWise } from "../../../../../redux/admin/action";
import { NEW_EXPORT_EXCEL } from "../../ExportAll";
function DueInvoiceDetails() {
  const navigate = Index.useNavigate();
  let { state } = Index.useLocation();
  const [invoiceData, setInvoiceData] = useState({});
  const [invoiceList, setInvoiceList] = useState([]);
  const [currentRows, setCurrentRows] = useState([]);
  let editData = state?.row;
  let typeName = state?.name;
  let type = state?.type;
  useEffect(() => {
    setInvoiceData(editData);
    setInvoiceList(editData?.invoiceDetails?.reverse());
  }, [editData]);

  // State for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState(0);
  const [paginationPerPage, setPaginationPerPage] = useState(50);
  const [totalRecordsCount, setTotalRecordsCount] = React.useState(0);

  // sales invoice basis
  const dispatch = Index.useDispatch();
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = React.useState(false);
  const [salesInvoiceRecords, setSalesInvoiceRecords] = React.useState([]);

  // when the page first time load then used...
  const getSalesInvoiceRecords = (values) => {
    setLoading(true);
    dispatch(
      getDueInvoiceDetailsByDateWise({
        ...values,
        companyId: searchParams.get("companyId"),
      })
    )
      .then((res) => {
        if (res?.status === 200) {
          setLoading(false);
          setSalesInvoiceRecords(res?.data);
          setTotalRecordsCount(res?.totalCount);
          setCurrentPage(res?.currentPage);
        } else {
          setSalesInvoiceRecords([]);
          setLoading(false);
          setTotalRecordsCount(0);
          setCurrentPage(1);
        }
      })
      .catch((err) => {
        setSalesInvoiceRecords([]);
        setLoading(false);
        setTotalRecordsCount(0);
        setCurrentPage(1);
      });
  };

  React.useEffect(() => {
    const values = {
      to: searchParams.get("to"),
      from: searchParams.get("from"),
      page: 1,
      size: 50,
    };
    getSalesInvoiceRecords(values);
  }, []);

  // pagination
  // const PER_PAGE = paginationPerPage;
  // useEffect(() => {
  //   //Page count
  //   const count = Math.ceil(salesInvoiceRecords?.length / PER_PAGE);
  //   setCount(count);
  //   const indexOfLastRow = currentPage * PER_PAGE;
  //   const indexOfFirstRow = indexOfLastRow - PER_PAGE;
  //   const modifiedRows = salesInvoiceRecords?.slice(
  //     indexOfFirstRow,
  //     indexOfLastRow
  //   );
  //   setCurrentRows(modifiedRows);
  //   if (modifiedRows?.length === 0 && currentPage > 1) {
  //     setCurrentPage(currentPage - 1);
  //   }
  // }, [currentPage, salesInvoiceRecords, paginationPerPage]);

  const handlePageChange = React.useCallback(
    (event, value) => {
      const values = {
        to: searchParams.get("to"),
        from: searchParams.get("from"),
        page: value,
        size: paginationPerPage,
      };
      getSalesInvoiceRecords(values);
      setCurrentPage(value);
    },
    [setCurrentPage, paginationPerPage]
  );

  const backBtn = () => {
    navigate("/admin/companyDashboard");
  };

  const getOverDueDays = (NewDate) => {
    const startDate = Index.moment(new Date());
    var currentDate = Index.moment(NewDate);
    var days = startDate.diff(currentDate, "days");
    return days;
  };

  const handleChangePaginationPerPage = React.useCallback(
    (e) => {
      const values = {
        to: searchParams.get("to"),
        from: searchParams.get("from"),
        page: currentPage,
        size: e.target.value,
      };
      getSalesInvoiceRecords(values);
      setPaginationPerPage(e.target.value);
    },
    [currentPage, setPaginationPerPage]
  );

  return (
    <div>
      <Index.DynamicTitle title="Invoice Management" />
      <Index.Box className="">
        <Index.Box className="barge-common-box">
          {/* Filter */}
          <Index.Box className="invoice-details title-header">
            <Index.Box className="title-header-flex  res-title-header-flex">
              <Index.Box className="invoice-back-title title-main">
                <Index.Button
                  type="submit"
                  className="Particular_model_submit back-invoice-btn"
                  onClick={backBtn}
                >
                  <ArrowBackIcon />
                </Index.Button>

                <Index.Typography
                  variant="p"
                  component="p"
                  className="page-title"
                >
                  {searchParams.get("dueType")}
                </Index.Typography>
              </Index.Box>

              <Index.Box className="common-button grey-button ">
                <Index.Button
                  variant="contained"
                  onClick={() => {
                    let curDate = Index.moment(new Date()).format("DD-MM-YYYY");
                    NEW_EXPORT_EXCEL(
                      "/admin/barge-wise-sales-for-all-invoice",
                      "Due-Invoice-Details",
                      {
                        to: searchParams.get("to"),
                        from: searchParams.get("from"),
                      }
                    );
                  }}
                >
                  Export
                </Index.Button>
              </Index.Box>
            </Index.Box>
          </Index.Box>

          {/* Listing */}
          <Index.Box className="">
            <Index.TableContainer
              component={Index.Paper}
              className="table-container"
            >
              <Index.Table
                aria-label="simple table"
                className="table-design-main invoice-table invoice-table-set  "
              >
                <Index.TableHead>
                  <Index.TableRow>
                    <Index.TableCell
                      align="left"
                      className="invoice_table_header"
                    >
                      Invoice No
                    </Index.TableCell>
                    <Index.TableCell
                      align="left"
                      className="invoice_table_header"
                    >
                      Invoice Date
                    </Index.TableCell>
                    <Index.TableCell
                      align="left"
                      className="invoice_table_header"
                    >
                      Party Name
                    </Index.TableCell>
                    <Index.TableCell
                      align="left"
                      className="invoice_table_header"
                    >
                      Particulars
                    </Index.TableCell>
                    <Index.TableCell
                      align="left"
                      className="invoice_table_header"
                    >
                      Barge Name
                    </Index.TableCell>
                    <Index.TableCell
                      align="left"
                      className="invoice_table_header"
                    >
                      Invoice Amount
                    </Index.TableCell>
                    <Index.TableCell
                      align="left"
                      className="invoice_table_header"
                    >
                      Taxable Value
                    </Index.TableCell>
                    <Index.TableCell
                      align="left"
                      className="invoice_table_header"
                    >
                      Port
                    </Index.TableCell>
                    <Index.TableCell
                      align="left"
                      className="invoice_table_header"
                    >
                      Over Due (InDays)
                    </Index.TableCell>
                    <Index.TableCell
                      align="left"
                      className="invoice_table_header"
                    >
                      Payment Status
                    </Index.TableCell>
                  </Index.TableRow>
                </Index.TableHead>
                {loading ? (
                  <Index.TableBody>
                    <Index.TableRow>
                      <Index.TableCell
                        colSpan={12}
                        align="center"
                        className="loading-purchase"
                      >
                        <Index.CircularProgress color="secondary" size={20} />
                      </Index.TableCell>
                    </Index.TableRow>
                  </Index.TableBody>
                ) : (
                  <Index.TableBody>
                    {salesInvoiceRecords?.length ? (
                      salesInvoiceRecords &&
                      salesInvoiceRecords?.map((row, index) => (
                        <Index.TableRow
                          key={
                            row?._id +
                            new Date(row?.invoiceDate).getMilliseconds() +
                            index
                          }
                        >
                          <Index.TableCell>
                            {row?.invoiceNo ? row?.invoiceNo : "-"}
                          </Index.TableCell>
                          <Index.TableCell>
                            {row?.invoiceDate
                              ? Index.moment(row?.invoiceDate).format(
                                  "DD/MM/YYYY"
                                )
                              : "-"}
                          </Index.TableCell>{" "}
                          <Index.TableCell>
                            {row?.partyName ? row?.partyName : "-"}
                          </Index.TableCell>
                          <Index.TableCell>
                            {row?.particular ? row?.particular : "-"}
                          </Index.TableCell>
                          <Index.TableCell>
                            {row?.barge ? row?.barge : "-"}
                          </Index.TableCell>
                          <Index.TableCell>
                            {row?.invoiceAmount
                              ? row?.invoiceAmount?.toLocaleString("en-IN", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : 0?.toLocaleString("en-IN", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                          </Index.TableCell>
                          <Index.TableCell>
                            {row?.totalTaxableValue
                              ? row?.totalTaxableValue?.toLocaleString(
                                  "en-IN",
                                  {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  }
                                )
                              : 0?.toLocaleString("en-IN", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                          </Index.TableCell>
                          <Index.TableCell>
                            {row?.port ? row?.port : "-"}
                          </Index.TableCell>
                          <Index.TableCell>
                            {row?.paymentStatus === "Unpaid"
                              ? getOverDueDays(row?.invoiceDate) + " Days"
                              : "-"}
                          </Index.TableCell>
                          <Index.TableCell>
                            {row?.paymentStatus ? row?.paymentStatus : "-"}
                          </Index.TableCell>
                        </Index.TableRow>
                      ))
                    ) : (
                      <Index.TableRow>
                        <Index.TableCell
                          component="td"
                          variant="td"
                          scope="row"
                          className="no-data-in-list empty-record-align"
                          colSpan={12}
                          align="center"
                        >
                          No data available
                        </Index.TableCell>
                      </Index.TableRow>
                    )}
                  </Index.TableBody>
                )}
              </Index.Table>
            </Index.TableContainer>
          </Index.Box>

          <Index.Box className="pagination-design flex-end">
            <Index.Stack spacing={2}>
              <Index.Box className="pagination-count pagination-loader">
                <Index.Box className="input-design-div with-border">
                  <Index.Select
                    fullWidth
                    value={paginationPerPage}
                    onChange={(e) =>
                      //   {
                      //   setPaginationPerPage(e.target.value);
                      // }
                      handleChangePaginationPerPage(e)
                    }
                  >
                    <Index.MenuItem value={10}>10</Index.MenuItem>
                    <Index.MenuItem value={25}>25</Index.MenuItem>
                    <Index.MenuItem value={50}>50</Index.MenuItem>
                    <Index.MenuItem value={100}>100</Index.MenuItem>
                  </Index.Select>
                </Index.Box>
                {loading ? (
                  <Index.CircularProgress color="secondary" size={26} />
                ) : (
                  <Index.Pagination
                    count={Math.ceil(totalRecordsCount / paginationPerPage)}
                    page={currentPage}
                    onChange={handlePageChange}
                  />
                )}
              </Index.Box>
            </Index.Stack>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </div>
  );
}

export default DueInvoiceDetails;
