export const webRoleMaster = [
  {
    name: "Dashboard",
    link: "/admin/companyDashboard",
    tag: "companyDashboard",
    isChild: false,
    child: [
      {
        name: "Dashboard",
        link: "/admin/companyDashboard",
        tag: "companyDashboard",
      },
      {
        name: "Monthly Sales",
        link: "/admin/companyDashboard",
        tag: "dashboardMonthlySales",
      },
      {
        name: "Barge Wise",
        link: "/admin/companyDashboard",
        tag: "dashboardBargeWise",
      },
      {
        name: "Bank Data",
        link: "/admin/companyDashboard",
        tag: "dashboardBankData",
      },
      {
        name: "Bank Transactions",
        link: "/admin/companyDashboard",
        tag: "dashboardBankTransactions",
      },
      {
        name: "Due Invoice Details",
        link: "/admin/companyDashboard",
        tag: "dashboardDueInvoiceDetails",
      },
      {
        name: "Category Wise",
        link: "/admin/companyDashboard",
        tag: "dashboardCategoryWise",
      },
      {
        name: "Top 10 Sales Party Wise",
        link: "/admin/companyDashboard",
        tag: "dashboardTop10SalesPartyWise",
      },
      {
        name: "Order View",
        link: "/admin/companyDashboard",
        tag: "dashboardOrderView",
      },
      {
        name: "Order View Tab",
        link: "/admin/companyDashboard",
        tag: "dashboardOrderViewTab",
      },
      {
        name: "In Breakdown",
        link: "/admin/companyDashboard",
        tag: "dashboardInBreakdown",
      },
      {
        name: "Document Center",
        link: "/admin/companyDashboard",
        tag: "dashboardDocumentCenter",
      },
      {
        name: "HDFC BANK",
        link: "/admin/companyDashboard",
        tag: "dashboardHDFCBANK",
      },
      {
        name: "KOTAK BANK",
        link: "/admin/companyDashboard",
        tag: "dashboardKOTAKBANK",
      },
      {
        name: "Tanker Dashboard",
        link: "/admin/companyDashboard",
        tag: "dashboardTankerDashboard",
      },
      {
        name: "Order Dashboard",
        link: "/admin/companyDashboard",
        tag: "dashboardOrderDashboard",
      },
      {
        name: "Bank Dashboard",
        link: "/admin/companyDashboard",
        tag: "dashboardBankDashboard",
      },
      {
        name: "Dashboard Stats",
        link: "/admin/companyDashboard",
        tag: "dashboardstats",
      },
      {
        name: "Dashboard Company Selection Option",
        link: "/admin/companyDashboard",
        tag: "companySelectionOption",
      },
      {
        name: "Dashboard Electrical",
        link: "/admin/companyDashboard",
        tag: "dashboardElectrical",
      },
      {
        name: "Dashboard Mechanical",
        link: "/admin/companyDashboard",
        tag: "dashboardMechanical",
      },
      {
        name: "Dashboard Fabrication",
        link: "/admin/companyDashboard",
        tag: "dashboardFabrication",
      },
      {
        name: "Dashboard Procurement",
        link: "/admin/companyDashboard",
        tag: "dashboardProcurement",
      },
    ],
  },
  {
    name: "Tanker Dashboard",
    link: "/admin/tankerDashboard",
    tag: "tankerDashboard",
    isChild: false,
  },
  {
    name: "Enquiry Panel",
    link: "/admin/enquiry",
    tag: "enquiry",
    isChild: false,
  },
  // {
  //   name: "Direct And InDirect Panel",
  //   link: "/admin/directIndirectPurchase",
  //   tag: "directIndirectPurchase",
  //   isChild: false,
  // },
  {
    name: "Bunker Orders",
    link: "/admin/order",
    tag: "order",
    isChild: false,
  },
  {
    name: "Tugs Orders",
    link: "/admin/tugsOrder",
    tag: "tugsOrder",
    isChild: false,
  },
  {
    name: "Water Supply Orders",
    link: "/admin/waterSupply",
    tag: "waterSupply",
    isChild: false,
  },
  {
    name: "Slope & Sludge Supply Orders",
    link: "/admin/slopeSludgeOrder",
    tag: "slopeSludgeOrder",
    isChild: false,
  },
  {
    name: "Expense List",
    link: "/admin/expenseList",
    tag: "expenseList",
    isChild: false,
  },
  {
    name: "Invoices",
    link: "/admin/invoice",
    tag: "invoice",
    isChild: false,
  },
  {
    name: "Tally Invoice",
    link: "/admin/tallyInvoice",
    tag: "tallyInvoice",
    isChild: false,
  },
  {
    name: "Banks",
    link: "/admin/banks",
    tag: "banks",
    isChild: false,
  },
  {
    name: "Bank History",
    link: "/admin/bankHistory",
    tag: "bankHistory",
    isChild: false,
  },
  {
    name: "Tasks",
    link: "/admin/tasks",
    tag: "tasks",
    isChild: false,
  },
  {
    name: "Directory",
    link: "/admin/directory",
    tag: "directory",
    isChild: false,
  },
  {
    name: "Tanker Breakdown",
    link: "/admin/breakdowns",
    tag: "breakdowns",
    isChild: false,
  },
  {
    name: "Tanker Trips",
    link: "/admin/tankertrips",
    tag: "tankertrips",
    isChild: false,
  },
  {
    name: "Craft Maintenance",
    link: "/admin/maintenances",
    tag: "maintenances",
    isChild: false,
  },
  {
    name: "Preventive Allocation",
    link: "/admin/craftPreventive",
    tag: "craftPreventive",
    isChild: false,
  },
  {
    name: "Document Center",
    link: "/admin/documentCenter",
    tag: "documentCenter",
    isChild: false,
  },
  {
    name: "Transfer",
    link: "/admin/transfer",
    tag: "transfer",
    isChild: false,
  },
  {
    name: "Purchase",
    link: "/admin/#",
    tag: "purchase",
    isChild: true,
    child: [
      {
        name: "Purchase",
        link: "/admin/purchase",
        tag: "purchase",
      },
      {
        name: "Purchase Beta",
        link: "/admin/purchaseBeta",
        tag: "purchaseBeta",
      },
      {
        name: "Bunker Daily Exp (ADV)",
        link: "/admin/bunker",
        tag: "bunker",
      },
      {
        name: "Wharfage",
        link: "/admin/wharfage",
        tag: "wharfage",
      },
    ],
  },
  {
    name: "Report",
    link: "/admin/#",
    tag: "reports",
    isChild: true,
    child: [
      {
        name: "GSTR",
        link: "/admin/gstr",
        tag: "gstr",
      },
      {
        name: "GSTR 2B",
        link: "/admin/gstr2",
        tag: "gstr2",
      },
      {
        name: "TDS",
        link: "/admin/tds",
        tag: "tds",
      },
    ],
  },
  {
    name: "Setup",
    link: "/admin/#",
    tag: "setup",
    isChild: true,
    child: [
      {
        name: "Users",
        link: "/admin/users",
        tag: "users",
      },
      {
        name: "Active Users",
        link: "/admin/activeUsers",
        tag: "activeUsers",
      },
      {
        name: "Port",
        link: "/admin/port",
        tag: "port",
      },
      {
        name: "Craft",
        link: "/admin/craft",
        tag: "craft",
      },
      {
        name: "GST Rate",
        link: "/admin/rate",
        tag: "rate",
      },
      {
        name: "Party",
        link: "/admin/party",
        tag: "party",
      },
      {
        name: "Trader Master",
        link: "/admin/traders",
        tag: "traders",
      },
      {
        name: "Preventive Sub Head",
        link: "/admin/preventiveSubhead",
        tag: "preventiveSubhead",
      },
      {
        name: "Expense",
        link: "/admin/expense",
        tag: "expense",
      },
      {
        name: "Tanker Master",
        link: "/admin/tankers",
        tag: "tankers",
      },
      {
        name: "Sales Master",
        link: "/admin/salesMaster",
        tag: "salesMaster",
      },
      {
        name: "Notification",
        link: "/admin/notification",
        tag: "notification",
      },
      {
        name: "Companies",
        link: "/admin/companies",
        tag: "companies",
      },
    ],
  },
];

export const appRoleMaster = [
  {
    tag: "expense",
    name: "Expense",
  },
  {
    tag: "bunkerTiming",
    name: "Bunker Timing",
  },
  {
    tag: "loading",
    name: "Loading",
  },
  {
    tag: "supplyDocs",
    name: "Supply Docs",
  },
  {
    tag: "maintenance",
    name: "Maintenance",
  },
  {
    tag: "tankerList",
    name: "Tanker List",
  },
  {
    tag: "appBreakdown",
    name: "Breakdown",
  },
  {
    tag: "directory",
    name: "Directory",
  },
  {
    tag: "tankerDashboard",
    name: "Tanker Dashboard",
  },
  {
    tag: "tankerSetup",
    name: "Tanker Setup",
  },
  {
    tag: "tugsTimings",
    name: "Tugs Timings",
  },
  {
    tag: "waterSupplyTimings",
    name: "Water Supply Timings",
  },
  {
    tag: "slopeSludgeTimings",
    name: "Slope Sludge Timings",
  },
  {
    tag: "pipeline",
    name: "Pipeline Orders",
  },
  {
    tag: "transfer",
    name: "Transfer",
  },
  {
    tag: "surveyourSupplyOrder",
    name: "Surveyour Supply Order",
  },
  {
    tag: "pipelineSurveyourSupplyOrder",
    name: "Pipeline Surveyour Supply Order",
  },
];
