import { useEffect, useState } from "react";
import Index from "../../../Index";
import { getPreventiveLogDetails } from "../../../../redux/admin/action";

function LogsCraftPrevention() {
  const dispatch = Index.useDispatch();
  const navigate = Index.useNavigate();
  const params = Index.useParams();
  const [loading, setLoading] = useState(false);
  const [preventiveLogsList, setPreventiveLogsList] = useState([]);

  const handleGetPreventiveLogs = () => {
    // getPreventiveLogDetails
    setLoading(true);
    dispatch(getPreventiveLogDetails({ id: params?.id }))
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          setPreventiveLogsList(res.data);
        } else {
          setLoading(false);
          setPreventiveLogsList([]);
        }
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
        setPreventiveLogsList([]);
      });
  };

  // when the page first time load then used...
  useEffect(() => {
    handleGetPreventiveLogs();
  }, []);

  // when the user want to back then used...
  const backBtn = () => {
    navigate("/admin/craftPreventive");
  };

  return (
    <>
      <Index.DynamicTitle title="Logs Craft Preventive" />
      <Index.Box className="">
        <Index.Box className="barge-common-box">
          <Index.Box className="title-header">
            <Index.Box className="title-header-flex res-title-header-flex">
              <Index.Box className="title-main">
                <Index.Typography
                  variant="p"
                  component="p"
                  className="page-title"
                >
                  Logs Craft Preventive
                </Index.Typography>
              </Index.Box>
              <Index.Box className="common-button blue-button save-btn ">
                <Index.Button
                  variant="contained"
                  type="submit"
                  className="Particular_model_submit ml-0"
                  onClick={backBtn}
                >
                  Back
                </Index.Button>
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Index.Box className="">
            <Index.TableContainer
              component={Index.Paper}
              className="table-container"
            >
              <Index.Table
                aria-label="simple table"
                className="table-design-main supply-table one-line-table"
              >
                <Index.TableHead>
                  <Index.TableRow>
                    <Index.TableCell>Sr No</Index.TableCell>

                    <Index.TableCell>Details</Index.TableCell>
                    <Index.TableCell align="left">Date</Index.TableCell>
                  </Index.TableRow>
                </Index.TableHead>

                {loading ? (
                  <Index.TableBody>
                    <Index.TableRow>
                      <Index.TableCell colSpan={14} align="center">
                        <Index.CircularProgress color="secondary" size={20} />
                      </Index.TableCell>
                    </Index.TableRow>
                  </Index.TableBody>
                ) : (
                  <Index.TableBody className="tanker-table-body">
                    {preventiveLogsList?.length ? (
                      preventiveLogsList?.map((item, index) => (
                        <Index.TableRow key={item?._id * (index + 1)}>
                          <Index.TableCell>{index + 1}</Index.TableCell>
                          <Index.TableCell align="left">
                            {item?.details ? item?.details : "-"}
                          </Index.TableCell>
                          <Index.TableCell align="left">
                            {item?.createdAt
                              ? Index.moment(item?.createdAt).format(
                                  "DD/MM/YYYY hh:mm A"
                                )
                              : "-"}
                          </Index.TableCell>
                        </Index.TableRow>
                      ))
                    ) : (
                      <Index.TableRow>
                        <Index.TableCell
                          component="td"
                          variant="td"
                          scope="row"
                          className="no-data-in-list"
                          colSpan={15}
                          align="center"
                        >
                          No data available
                        </Index.TableCell>
                      </Index.TableRow>
                    )}
                  </Index.TableBody>
                )}
              </Index.Table>
            </Index.TableContainer>
          </Index.Box>

          {/* <Index.Box className="pagination-design flex-end">
            <Index.Stack spacing={2}>
              <Index.Box className="pagination-count">
                <Index.Box className="input-design-div with-border">
                  <Index.Select
                    fullWidth
                    value={paginationPerPage}
                    onChange={(e) => handleChangePaginationPerChange(e)}
                  >
                    <Index.MenuItem value={10}>10</Index.MenuItem>
                    <Index.MenuItem value={25}>25</Index.MenuItem>
                    <Index.MenuItem value={50}>50</Index.MenuItem>
                    <Index.MenuItem value={100}>100</Index.MenuItem>
                  </Index.Select>
                </Index.Box>
                {loading ? (
                  <Index.CircularProgress
                    color="secondary"
                    sx={{ marginLeft: "10px" }}
                    size={26}
                  />
                ) : (
                  <Index.Pagination
                    count={Math.ceil(totalRecordCount / paginationPerPage)}
                    page={currentPage}
                    onChange={handlePageChange}
                  />
                )}
              </Index.Box>
            </Index.Stack>
          </Index.Box> */}
        </Index.Box>
      </Index.Box>
    </>
  );
}

export default LogsCraftPrevention;
