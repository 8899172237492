// import jwtDecode from "jwt-decode";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import axios from "../axios";
// import {messaging} from "../firebase"
// import { getToken } from "firebase/messaging";

import { useEffect, useState } from "react";
import Index from "../container/Index";
import { adminLogOutToken, adminLogout } from "../redux/admin/action";
import axiosInstance from "../axios";

const PrivateRoutes = () => {
  // Check token expiry
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [deviceToken, setDeviceToken] = useState("");
  const isValidToken = (accessToken) => {
    if (!accessToken) return false;

    axios.defaults.headers.common.auth = token;
    axios.defaults.headers.common.lat = latitude;
    axios.defaults.headers.common.long = longitude;
    return true;
  };

  // get location code
  const { latitude, longitude } = useSelector((state) => state.AdminReducer);
  // console.log(latitude, longitude, 117);

  // get location code end

  // Use navigate with a callback function
  // const handleNavigation = () => {
  //   navigate("/"); // login route
  // };

  // // jwt token code
  // axiosInstance.interceptors.response.use(
  //   (response) => response,
  //   (error) => {
  //     if (error?.response?.status === 401) {
  //      handleNavigation(); // login route
  //     }
  //   }
  // );

  // firebase code old
  // async function requestPermission() {
  //   const permission = await Notification.requestPermission();
  //   console.log(permission, 51);
  //   if (permission == "granted") {
  //     var token = await getToken(messaging, {
  //       vapidKey:
  //         "BBNCatD6oXli6KgK8rYHld4S2Q90gawF71k6bSiwgOfuN_FSaSiYDvj6RbAbqnEsISBOgjYJcqTvBsiGh8XBaKc",
  //     });
  //     setDeviceToken(token);
  //     console.log("token11", token);
  //     console.log("test1");

  //     // let tdata = token;
  //   } else if (permission === "denied") {
  //     alert("You denied for the notification");
  //   }
  // }

  //   useEffect(() => {
  //     requestPermission();
  //   }, [location?.pathname]);

  // useEffect(() => {
  //   dispatch(adminLogOutToken()).then((res) => {
  //      console.log(res,58);
  //      if (res?.response?.data?.status == 401) {
  //        dispatch(adminLogout());
  //        navigate("/admin/login");
  //      }
  //    });
  // },[location?.pathname])

  // new location jwt code
  // axiosInstance.interceptors.response.use(
  // (response) => response,
  // (error) => {
  //   const status = error.response?.status || 500;

  //   if (status === 401) {
  //     // Check the current route path
  //     const currentPath = window.location.pathname;

  //     // Define the path of login page
  //     const loginPagePath = "/";

  //     // Check if the current path is not the login page
  //     if (currentPath !== loginPagePath) {
  //       // Redirect to the login page
  //       window.location.href = loginPagePath;
  //     }
  //   } else {
  //     return error;
  //   }
  // }
  // );

  // jwt with navigate
  axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      const status = error.response?.status || 500;
      if (status === 401) {
        navigate("/");
        dispatch(adminLogout());
      } else {
        return error;
      }
    }
  );

  const token = localStorage.getItem("accessToken");
  const isLogin = useSelector((state) => state.AdminReducer.isAdminLogin);
  return isLogin && isValidToken(token) ? (
    <Outlet />
  ) : (
    <Navigate to="/" />
  );
};

export default PrivateRoutes;
