import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import PageIndex from "../../../PageIndex";
import { getSingleTask } from "../../../../redux/admin/action";

function ViewTaskDetail() {
  const dispatch = Index.useDispatch();
  const navigate = Index.useNavigate();
  const { id } = Index.useParams();
  const [loading, setLoading] = useState(false);
  const [assigneeList, setAssigneeList] = useState([]);
  const [singleTask, setSingleTask] = useState();

  const BackBtn = () => {
    navigate("/admin/tasks");
  };

  const fetchSingleTask = (data) => {
    setLoading(true);
    try {
      dispatch(getSingleTask(data)).then((res) => {
        if (res?.status === 200) {
          setSingleTask(res?.data);
          setTimeout(() => {
            setLoading(false);
          }, 1000);
        } else {
          setTimeout(() => {
            setLoading(false);
          }, 1000);
          setSingleTask();
        }
      });
    } catch (error) {}
  };

  useEffect(() => {
    if (id) {
      fetchSingleTask({ id: id });
    }
  }, [id]);
  return (
    <>
      <Index.Box className="">
        <Index.Box className="barge-common-box">
          <Index.Box className="title-header">
            <Index.Box className="title-header-flex">
              <Index.Box className="title-main mb-10">
                <Index.Typography
                  variant="p"
                  component="p"
                  className="page-title"
                ></Index.Typography>
              </Index.Box>
              <Index.Box className="common-button blue-button save-btn">
                <Index.Button
                  variant="contained"
                  type="submit"
                  className="Particular_model_submit ml-0"
                  onClick={BackBtn}
                >
                  Back
                </Index.Button>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          {loading && id ? (
            <Index.Box className="">
              <Index.Grid container spacing={2}>
                <Index.Grid item xs={5} sm={5} md={5}></Index.Grid>
                <Index.Grid item xs={2} sm={2} md={2}>
                  <Index.CircularProgress color="secondary" size={20} />
                </Index.Grid>
                <Index.Grid item xs={5} sm={5} md={5}></Index.Grid>
              </Index.Grid>
            </Index.Box>
          ) : (
            <Index.Box className="">
              <Index.Grid container spacing={3}>
                <Index.Grid item xs={12} sm={7} md={7}>
                  <Index.Box
                    className="mt-10"
                    sx={{ backgroundColor: "#fff", padding: "15px", borderRadius:"10px" }}
                  >
                    <Index.Box className="">
                      <Index.Box className="title-header">
                        <Index.Box className="title-header-flex">
                          <Index.Box className="title-main mb-10">
                            <Index.Typography
                              variant="p"
                              component="p"
                              className="page-title"
                            >
                              Comments
                            </Index.Typography>
                          </Index.Box>
                          <Index.Box className="common-button blue-button save-btn ">
                            <Index.Button
                              variant="contained"
                              type="submit"
                              className="Particular_model_submit ml-0"
                              //   onClick={BackBtn}
                            >
                              Start
                            </Index.Button>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box className="comments-list-main" sx={{paddingRight: "10px"}}>
                        <Index.Box className="comments-box-main">
                          <Index.Box className="comments-box-flex">
                            <Index.Box className="comments-img-main">
                              <img
                                src={Index.Png.usericon}
                                className="comment-img"
                              />
                            </Index.Box>
                            <Index.Box className="comments-content-main">
                              <Index.Box className="header-content-flex">
                                <Index.Box className="content-title-main">
                                  <Index.Typography
                                    variant="p"
                                    component="p"
                                    className="page-title"
                                  >
                                    Comments
                                  </Index.Typography>
                                </Index.Box>
                                <Index.Box className="content-title-main">
                                  <Index.Typography
                                    variant="p"
                                    component="p"
                                    className="comment-time"
                                  >
                                    19-12-2023 3:06:34 pm
                                  </Index.Typography>
                                </Index.Box>
                              </Index.Box>
                              <Index.Box className="comments-content-text-main">
                                <Index.Box className="content-title-main">
                                  <Index.Typography
                                    variant="p"
                                    component="p"
                                    className="page-title"
                                  >
                                    Lorem Ipsum is simply dummy text of the
                                    printing and typesetting industry. Lorem
                                    Ipsum has been the industry's standard dummy
                                    text ever since the 1500s, when an unknown
                                    printer took a galley of type and scrambled
                                    it to make a type specimen book.
                                  </Index.Typography>
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                        <Index.Box className="comments-box-main">
                          <Index.Box className="comments-box-flex">
                            <Index.Box className="comments-img-main">
                              <img
                                src={Index.Png.usericon}
                                className="comment-img"
                              />
                            </Index.Box>
                            <Index.Box className="comments-content-main">
                              <Index.Box className="header-content-flex">
                                <Index.Box className="content-title-main">
                                  <Index.Typography
                                    variant="p"
                                    component="p"
                                    className="page-title"
                                  >
                                    Comments
                                  </Index.Typography>
                                </Index.Box>
                                <Index.Box className="content-title-main">
                                  <Index.Typography
                                    variant="p"
                                    component="p"
                                    className="comment-time"
                                  >
                                    19-12-2023 3:06:34 pm
                                  </Index.Typography>
                                </Index.Box>
                              </Index.Box>
                              <Index.Box className="comments-content-text-main">
                                <Index.Box className="content-title-main">
                                  <Index.Typography
                                    variant="p"
                                    component="p"
                                    className="page-title"
                                  >
                                    Lorem Ipsum is simply dummy text of the
                                    printing and typesetting industry. Lorem
                                    Ipsum has been the industry's standard dummy
                                    text ever since the 1500s, when an unknown
                                    printer took a galley of type and scrambled
                                    it to make a type specimen book.
                                  </Index.Typography>
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                        <Index.Box className="comments-box-main">
                          <Index.Box className="comments-box-flex">
                            <Index.Box className="comments-img-main">
                              <img
                                src={Index.Png.usericon}
                                className="comment-img"
                              />
                            </Index.Box>
                            <Index.Box className="comments-content-main">
                              <Index.Box className="header-content-flex">
                                <Index.Box className="content-title-main">
                                  <Index.Typography
                                    variant="p"
                                    component="p"
                                    className="page-title"
                                  >
                                    Comments
                                  </Index.Typography>
                                </Index.Box>
                                <Index.Box className="content-title-main">
                                  <Index.Typography
                                    variant="p"
                                    component="p"
                                    className="comment-time"
                                  >
                                    19-12-2023 3:06:34 pm
                                  </Index.Typography>
                                </Index.Box>
                              </Index.Box>
                              <Index.Box className="comments-content-text-main">
                                <Index.Box className="content-title-main">
                                  <Index.Typography
                                    variant="p"
                                    component="p"
                                    className="page-title"
                                  >
                                    Lorem Ipsum is simply dummy text of the
                                    printing and typesetting industry. Lorem
                                    Ipsum has been the industry's standard dummy
                                    text ever since the 1500s, when an unknown
                                    printer took a galley of type and scrambled
                                    it to make a type specimen book.
                                  </Index.Typography>
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                        <Index.Box className="comments-box-main">
                          <Index.Box className="comments-box-flex">
                            <Index.Box className="comments-img-main">
                              <img
                                src={Index.Png.usericon}
                                className="comment-img"
                              />
                            </Index.Box>
                            <Index.Box className="comments-content-main">
                              <Index.Box className="header-content-flex">
                                <Index.Box className="content-title-main">
                                  <Index.Typography
                                    variant="p"
                                    component="p"
                                    className="page-title"
                                  >
                                    Comments
                                  </Index.Typography>
                                </Index.Box>
                                <Index.Box className="content-title-main">
                                  <Index.Typography
                                    variant="p"
                                    component="p"
                                    className="comment-time"
                                  >
                                    19-12-2023 3:06:34 pm
                                  </Index.Typography>
                                </Index.Box>
                              </Index.Box>
                              <Index.Box className="comments-content-text-main">
                                <Index.Box className="content-title-main">
                                  <Index.Typography
                                    variant="p"
                                    component="p"
                                    className="page-title"
                                  >
                                    Lorem Ipsum is simply dummy text of the
                                    printing and typesetting industry. Lorem
                                    Ipsum has been the industry's standard dummy
                                    text ever since the 1500s, when an unknown
                                    printer took a galley of type and scrambled
                                    it to make a type specimen book.
                                  </Index.Typography>
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                        <Index.Box className="comments-box-main">
                          <Index.Box className="comments-box-flex">
                            <Index.Box className="comments-img-main">
                              <img
                                src={Index.Png.usericon}
                                className="comment-img"
                              />
                            </Index.Box>
                            <Index.Box className="comments-content-main">
                              <Index.Box className="header-content-flex">
                                <Index.Box className="content-title-main">
                                  <Index.Typography
                                    variant="p"
                                    component="p"
                                    className="page-title"
                                  >
                                    Comments
                                  </Index.Typography>
                                </Index.Box>
                                <Index.Box className="content-title-main">
                                  <Index.Typography
                                    variant="p"
                                    component="p"
                                    className="comment-time"
                                  >
                                    19-12-2023 3:06:34 pm
                                  </Index.Typography>
                                </Index.Box>
                              </Index.Box>
                              <Index.Box className="comments-content-text-main">
                                <Index.Box className="content-title-main">
                                  <Index.Typography
                                    variant="p"
                                    component="p"
                                    className="page-title"
                                  >
                                    Lorem Ipsum is simply dummy text of the
                                    printing and typesetting industry. Lorem
                                    Ipsum has been the industry's standard dummy
                                    text ever since the 1500s, when an unknown
                                    printer took a galley of type and scrambled
                                    it to make a type specimen book.
                                  </Index.Typography>
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box className="comments-description-box-main">
                        <Index.Box className="input-design-div with-border">
                          <Index.TextareaAutosize
                            hiddenLabel
                            minRows={4}
                            id="filled-hidden-label-normal"
                            className="admin-input-design input-placeholder input-placeholder-box  input-placeholder-box-set "
                            placeholder="Type message..."
                            variant="filled"
                            name="description"
                            //   value={values.description}
                            //   onChange={handleChange}
                          />
                        </Index.Box>
                        <Index.Box className="common-button blue-button save-btn flex-end mt-10">
                          <Index.Button
                            variant="contained"
                            type="submit"
                            className="Particular_model_submit ml-0"
                            //   onClick={BackBtn}
                          >
                            Send
                          </Index.Button>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Grid>
                <Index.Grid item xs={12} sm={5} md={5}>
                  <Index.Box
                    className="mt-10"
                    sx={{ backgroundColor: "#fff", padding: "15px", borderRadius:"10px" }}
                  >
                    <Index.Box className="title-header">
                      <Index.Box className="title-header-flex">
                        <Index.Box className="title-main mb-10">
                          <Index.Typography
                            variant="p"
                            component="p"
                            className="page-title"
                          >
                            Task Details
                          </Index.Typography>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box
                      className="mb-5 invoice-detail-item"
                      sx={{ display: "flex" }}
                    >
                      <Index.Box className="input-label opacity-60">
                        Title :
                      </Index.Box>
                      <Index.Box className="input-label">
                        {singleTask?.title || "-"}
                      </Index.Box>
                    </Index.Box>

                    <Index.Box
                      className="mb-5 invoice-detail-item"
                      sx={{ display: "flex" }}
                    >
                      <Index.Box className="input-label opacity-60">
                        Start date :
                      </Index.Box>
                      <Index.Box className="input-label">
                        {singleTask?.startDate
                          ? Index.moment(singleTask?.startDate).format(
                              "DD/MM/YYYY"
                            )
                          : "-"}
                      </Index.Box>
                    </Index.Box>
                    <Index.Box
                      className="mb-5 invoice-detail-item"
                      sx={{ display: "flex" }}
                    >
                      <Index.Box className="input-label opacity-60">
                        Deadline :
                      </Index.Box>
                      <Index.Box className="input-label">
                        {singleTask?.deadline
                          ? Index.moment(singleTask?.deadline).format(
                              "DD/MM/YYYY"
                            )
                          : "-"}
                      </Index.Box>
                    </Index.Box>

                    <Index.Box
                      className="mb-5 invoice-detail-item"
                      sx={{ display: "flex" }}
                    >
                      <Index.Box className="input-label opacity-60">
                        Assigned To :
                      </Index.Box>
                      <Index.Box className="input-label">
                        {singleTask?.assignTo?.firstName
                          ? `${singleTask?.assignTo?.firstName} ${singleTask?.assignTo?.lastName}`
                          : "-"}
                      </Index.Box>
                    </Index.Box>

                    <Index.Box
                      className="invoice-detail-item"
                      sx={{ display: "flex" }}
                    >
                      <Index.Box className="input-label opacity-60">
                        Status :
                      </Index.Box>
                      <Index.Box className="input-label pdf-input">
                        {singleTask?.status || "-"}
                      </Index.Box>
                    </Index.Box>

                    <Index.Box
                      className="mb-5 invoice-detail-item"
                      sx={{ display: "flex" }}
                    >
                      <Index.Box className="input-label opacity-60">
                        Change Status :
                      </Index.Box>
                      <Index.Box className="input-label">Status</Index.Box>
                    </Index.Box>

                    <Index.Box
                      className="mb-5 invoice-detail-item"
                      sx={{ display: "flex" }}
                    >
                      <Index.Box className="input-label opacity-60">
                        Assign Employee :
                      </Index.Box>
                      <Index.Box className="input-label">Admin</Index.Box>
                    </Index.Box>

                    <Index.Box
                      className="mb-5 invoice-detail-item"
                      sx={{ display: "flex" }}
                    >
                      <Index.Box className="input-label opacity-60">
                        Description :
                      </Index.Box>
                      <Index.Box className="input-label">
                        {singleTask?.description || "-"}
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box
                    className="mt-10"
                    sx={{
                      backgroundColor: "#fff",
                      padding: "15px",
                      marginTop: "15px",
                      borderRadius:"10px",
                    }}
                  >
                    <Index.Box className="title-header">
                      <Index.Box className="title-header-flex">
                        <Index.Box className="title-main mb-10">
                          <Index.Typography
                            variant="p"
                            component="p"
                            className="page-title"
                          >
                            Employee Total Task Duration
                          </Index.Typography>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="" sx={{ display: "flex" }}>
                      <Index.Box
                        className="mb-5 invoice-detail-item"
                        sx={{ width: "50%" }}
                      >
                        <Index.Box className="input-label opacity-60">
                          Employee Name
                        </Index.Box>
                        <Index.Box className="input-label">Admin</Index.Box>
                      </Index.Box>
                      <Index.Box className="mb-5 invoice-detail-item">
                        <Index.Box className="input-label opacity-60">
                          Total Duration
                        </Index.Box>
                        <Index.Box className="input-label">
                          00 h 00 min 00 sec{" "}
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Grid>
              </Index.Grid>
            </Index.Box>
          )}
        </Index.Box>
      </Index.Box>
    </>
  );
}

export default ViewTaskDetail;
