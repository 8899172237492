import React, {useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useLocation } from "react-router-dom";
import TraderEnqList from "./TraderEnqList";
import TraderConfirmEnqList from "./TraderConfirmEnqList";
// import Invoice from "./Invoice";
// import CreditNotInvoice from "./CreditNotInvoice";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box className="tab-comman-padding">
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const TraderEnqTab = () => {
  let { state } = useLocation();
  const [value, setValue] = useState(state?.value ? state?.value : 0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Current Enquiry" {...a11yProps(0)} />
            <Tab label="Confirmed Order" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
                <TraderEnqList />
        </TabPanel>
        <TabPanel value={value} index={1}>
               <TraderConfirmEnqList />
        </TabPanel>
      </Box>
    </>
  );
};

export default TraderEnqTab;
