import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import { Formik } from "formik";
import {
  addUser,
  getAllCompanyList,
  getRoleMasterList,
} from "../../../../redux/admin/action";
import UserList from "./List";
import { userValidationSchema } from "../../../../validation/AdminValidation";
// end

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function UserAdd() {
  const [image, setImage] = useState("");
  const dispatch = Index.useDispatch();
  const navigate = Index.useNavigate();
  const { state } = Index.useLocation();
  const [loading, setLoading] = useState(false);
  const { roleMasters } = Index.useSelector((state) => state.AdminReducer);
  const [roleMasterList, setRoleMasterList] = useState([]);
  // password visibility
  const [showPassword, setShowPassword] = useState(false);
  const [companyList, setCompanyList] = useState([]);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  let editData = state?.values;

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  // when the get all the company list then used...
  const handleGetAllCompanyList = () => {
    dispatch(getAllCompanyList())
      .then((res) => {
        if (res.status === 200) {
          setCompanyList(res.data);
        } else {
          setCompanyList([]);
        }
      })
      .catch((error) => {
        setCompanyList([]);
        console.error(error);
      });
  };

  useEffect(() => {
    dispatch(getRoleMasterList());
    handleGetAllCompanyList();
  }, [dispatch]);

  useEffect(() => {
    setRoleMasterList(
      roleMasters?.sort((a, b) => a?.roleName?.localeCompare(b?.roleName))
    );
  }, [roleMasters]);

  // edit data
  if (editData) {
    editData.id = editData?._id;
  }

  let initialValues = {};
  if (editData) {
    initialValues = editData;
    initialValues.permissionId = editData?.permission?._id;
    initialValues.password = ""; // Edit then password will blank
    // if (editData?.companyId?.length) {
    initialValues = {
      ...initialValues,
      companyId: editData?.companyId?.length
        ? editData?.companyId?.map((el) => el?._id)
        : [],
    };
    // }
    // initialValues.companyId = editData?.companyId?.length
    //   ? editData?.companyId?.map((el) => el?._id)
    //   : [];
  } else {
    initialValues = {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      mobileNumber: "",
      address: "",
      image: "",
      permission: "",
      permissionId: "",
      companyId: [],
      isEdit: editData ? true : false,
    };
  }

  const handleFormSubmit = async (values) => {
    setLoading(true);
    const data = new FormData();
    if (values?.firstName) {
      data.append("firstName", values.firstName);
    }
    if (values?.lastName) {
      data.append("lastName", values.lastName);
    }
    if (values?.email) {
      data.append("email", values.email);
    }
    if (values?.password !== "" && values?.password !== null) {
      data.append("password", values.password);
    }
    if (values?.mobileNumber) {
      data.append("mobileNumber", values.mobileNumber);
    }
    data.append("address", values.address ? values.address : "");
    data.append("image", image);

    if (values?.permissionId) {
      data.append("permission", values.permissionId);
    }
    if (values.companyId?.length) {
      // values.companyId?.map((el) => {
      data.append("companyId", JSON.stringify(values.companyId));
      // });
    }

    if (editData) {
      data.append("id", editData?._id);
    }
    dispatch(addUser(data, navigate, setLoading));
    dispatch(UserList());
  };

  const BackBtn = () => {
    navigate("/admin/users");
  };

  return (
    <>
      <Index.Box className="">
        <Index.Box className="barge-common-box">
          <Index.Box className="title-header">
            <Index.Box className="title-header-flex">
              <Index.Box className="title-main mb-10">
                <Index.Typography
                  variant="p"
                  component="p"
                  className="page-title"
                >
                  {editData ? "Edit" : "Add"} User
                </Index.Typography>
              </Index.Box>
              <Index.Box className="common-button blue-button save-btn ">
                <Index.Button
                  variant="contained"
                  type="submit"
                  className="Particular_model_submit ml-0"
                  onClick={BackBtn}
                >
                  Back
                </Index.Button>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={userValidationSchema}
            onSubmit={handleFormSubmit}
          >
            {({
              values,
              errors,
              touched,
              setFieldValue,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <form onSubmit={handleSubmit}>
                <Index.Box className="">
                  <Index.Grid container spacing={2}>
                    <Index.Grid item xs={12} sm={6} md={3}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        First Name
                      </Index.Typography>
                      <Index.Box className="input-design-div with-border">
                        <Index.TextField
                          hiddenLabel
                          id="filled-hidden-label-normal"
                          placeholder="First name"
                          variant="filled"
                          className="admin-input-design input-placeholder"
                          name="firstName"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values?.firstName}
                          error={Boolean(
                            errors.firstName ? touched.firstName : undefined
                          )}
                          helperText={
                            touched.firstName ? errors.firstName : undefined
                          }
                        />
                      </Index.Box>
                    </Index.Grid>

                    <Index.Grid item xs={12} sm={6} md={3}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Last Name
                      </Index.Typography>
                      <Index.Box className="input-design-div with-border">
                        <Index.TextField
                          hiddenLabel
                          id="filled-hidden-label-normal"
                          placeholder="Last name"
                          variant="filled"
                          className="admin-input-design input-placeholder"
                          name="lastName"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values?.lastName}
                          error={Boolean(
                            errors.lastName ? touched.lastName : undefined
                          )}
                          helperText={
                            touched.lastName ? errors.lastName : undefined
                          }
                        />
                      </Index.Box>
                    </Index.Grid>

                    <Index.Grid item xs={12} sm={6} md={3}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Email
                      </Index.Typography>
                      <Index.Box className="input-design-div with-border">
                        <Index.TextField
                          className="admin-input-design input-placeholder user-email-field"
                          hiddenLabel
                          id="filled-hidden-label-normal"
                          placeholder="Email"
                          variant="filled"
                          autoComplete="off"
                          name="email"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values?.email}
                          error={Boolean(
                            errors?.email ? touched?.email : undefined
                          )}
                          helperText={
                            touched?.email ? errors?.email : undefined
                          }
                        />
                      </Index.Box>
                    </Index.Grid>

                    <Index.Grid item xs={12} sm={6} md={3}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Password
                      </Index.Typography>

                      <Index.Box className="input-design-div with-border">
                        <Index.Stack component="label" spacing={2} noValidate>
                          <Index.OutlinedInput
                            id="outlined-adornment-password"
                            className="user-password-field"
                            name="password"
                            onBlur={handleBlur}
                            placeholder="Password"
                            autoComplete="off"
                            type={showPassword ? "text" : "password"}
                            onChange={handleChange}
                            endAdornment={
                              <Index.InputAdornment
                                position="end"
                                className="pass_position"
                              >
                                <Index.IconButton
                                  className="icon_end_icon eye-btn-set"
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  {showPassword ? (
                                    <Index.VisibilityOff />
                                  ) : (
                                    <Index.Visibility />
                                  )}
                                </Index.IconButton>
                              </Index.InputAdornment>
                            }
                          />
                          {touched.password && errors.password && (
                            <Index.FormHelperText
                              error
                              style={{ marginTop: 0 }}
                            >
                              {errors.password}
                            </Index.FormHelperText>
                          )}
                        </Index.Stack>
                      </Index.Box>
                    </Index.Grid>

                    <Index.Grid item xs={12} sm={6} md={3}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Mobile Number
                      </Index.Typography>
                      <Index.Box className="input-design-div with-border">
                        <Index.TextField
                          fullWidth
                          id="filled-hidden-label-normal"
                          type="number"
                          variant="filled"
                          placeholder="Mobile number"
                          className="admin-input-design input-placeholder"
                          name="mobileNumber"
                          onBlur={handleBlur}
                          value={values.mobileNumber}
                          onChange={(e) => {
                            if (e.target.value.toString().length <= 10) {
                              setFieldValue("mobileNumber", e.target.value);
                            }
                          }}
                          startAdornment={
                            <Index.InputAdornment position="start"></Index.InputAdornment>
                          }
                          error={Boolean(
                            errors.mobileNumber
                              ? touched.mobileNumber
                              : undefined
                          )}
                          helperText={
                            touched.mobileNumber
                              ? errors.mobileNumber
                              : undefined
                          }
                        />
                      </Index.Box>
                    </Index.Grid>

                    <Index.Grid item xs={12} sm={6} md={3}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Address
                      </Index.Typography>
                      <Index.Box className="input-design-div with-border">
                        <Index.TextField
                          fullWidth
                          id="fullWidth"
                          // type="text"
                          placeholder="Address"
                          variant="filled"
                          className="form-control"
                          name="address"
                          // multiline
                          // rows={2}
                          onBlur={handleBlur}
                          value={values.address}
                          onChange={(e) => {
                            if (e.target.value.length <= 200) {
                              setFieldValue(
                                "address",
                                e.target.value.trimStart()
                              );
                            }
                          }}
                          helperText={touched.address && errors.address}
                          error={Boolean(errors.address && touched.address)}
                        />
                      </Index.Box>
                    </Index.Grid>

                    <Index.Grid item xs={12} sm={6} md={3}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Role
                      </Index.Typography>
                      <Index.Box className="input-design-div with-border">
                        <Index.Select
                          className="placeholder-set-text-box"
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          fullWidth
                          defaultValue={
                            values?.permissionId ? values?.permissionId : ""
                          }
                          MenuProps={MenuProps}
                          displayEmpty
                          inputProps={{ "aria-label": "Without label" }}
                          onChange={(e) => {
                            setFieldValue("permissionId", e.target.value);
                          }}
                        >
                          <Index.MenuItem value={""}>
                            Select role
                          </Index.MenuItem>
                          {roleMasterList?.map((items, index) => (
                            <Index.MenuItem value={items?._id} key={items?._id}>
                              {items?.roleName}
                            </Index.MenuItem>
                          ))}
                        </Index.Select>
                      </Index.Box>
                      {touched.permissionId && errors.permissionId && (
                        <Index.FormHelperText error>
                          {errors.permissionId}
                        </Index.FormHelperText>
                      )}
                    </Index.Grid>

                    <Index.Grid item xs={12} sm={6} md={3}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Image
                      </Index.Typography>
                      <Index.Box className="input-design-div with-border">
                        <Index.Grid container spacing={2}>
                          <Index.Grid item xs={6} sm={6} md={8}>
                            <Index.Box className="input-design-div with-border position-relative">
                              <Index.Box className="pdf-design">
                                Upload File
                              </Index.Box>
                              <Index.TextField
                                fullWidth
                                type="file"
                                name="image"
                                // accept=".xls, .xlsx"
                                id="filled-hidden-label-normal"
                                variant="filled"
                                className="admin-input-design input-placeholder pdf-upload-design"
                                onChange={(event) => {
                                  setImage(event.currentTarget.files[0]);
                                  setFieldValue(
                                    "image",
                                    event.currentTarget.files[0]
                                  );
                                }}
                                inputProps={{
                                  className: "input_props",
                                }}
                                FormHelperTextProps={{
                                  className: "input_label_props",
                                }}
                              />
                            </Index.Box>
                            {touched?.image && errors?.image && (
                              <Index.FormHelperText error>
                                {errors?.image}
                              </Index.FormHelperText>
                            )}
                          </Index.Grid>

                          <Index.Grid item xs={6} sm={6} md={4}>
                            <Index.Box className="user-img-main uploaded-user-img">
                              <img
                                src={
                                  image
                                    ? URL.createObjectURL(image)
                                    : editData?.image
                                    ? `${process.env.REACT_APP_IMAGE_URL}/users/${editData?.image}`
                                    : ""
                                }
                                alt=""
                                className="user-img"
                              />
                            </Index.Box>
                          </Index.Grid>
                        </Index.Grid>
                      </Index.Box>
                    </Index.Grid>

                    <Index.Grid item xs={12} sm={6} md={3}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Company
                      </Index.Typography>
                      <Index.Box className="input-design-div with-border">
                        <Index.Select
                          className="placeholder-set-text-box"
                          fullWidth
                          name="companyId"
                          multiple
                          displayEmpty
                          inputProps={{ "aria-label": "without label" }}
                          // defaultValue={values ? values?.portLimit : ""}
                          value={values?.companyId}
                          MenuProps={MenuProps}
                          onChange={(event) => {
                            const {
                              target: { value },
                            } = event;
                            const newValue =
                              value[value?.length - 1] === ""
                                ? []
                                : typeof value === "string"
                                ? value.split(",")
                                : value;
                            setFieldValue("companyId", newValue);
                          }}
                          renderValue={(selected, value) => {
                            if (selected.length === 0) {
                              return (
                                <Index.MenuItem
                                  disabled
                                  className="all-select-label"
                                >
                                  Select port
                                </Index.MenuItem>
                              );
                            }

                            const finalResult = [];
                            selected?.map((el) => {
                              let result = companyList.find(
                                (res) => res?._id === el
                              );
                              if (result !== undefined) {
                                finalResult.push(result?.name);
                              }
                            });

                            return finalResult.join(", ");
                          }}
                        >
                          <Index.MenuItem value={""}>
                            <Index.MenuItem
                              disabled
                              className="all-select-label"
                            >
                              Select Company
                            </Index.MenuItem>
                          </Index.MenuItem>

                          {companyList?.map((item) => (
                            <Index.MenuItem key={item?._id} value={item?._id}>
                              {item?.name}
                            </Index.MenuItem>
                          ))}
                        </Index.Select>
                        {touched?.companyId && errors?.companyId && (
                          <Index.FormHelperText error>
                            {errors?.companyId}
                          </Index.FormHelperText>
                        )}
                      </Index.Box>
                    </Index.Grid>
                  </Index.Grid>
                </Index.Box>
                <Index.Box className="common-button blue-button flex-end save-btn user-submit-btn">
                  <Index.Button
                    variant="contained"
                    type="submit"
                    disabled={loading}
                  >
                    {editData ? "Update" : "Submit"}
                  </Index.Button>
                </Index.Box>
              </form>
            )}
          </Formik>
        </Index.Box>
      </Index.Box>
    </>
  );
}

export default UserAdd;
