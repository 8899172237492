import React, { useEffect, useState } from 'react'
import Index from '../../../Index';
import { getSingleBunkerPipeLineDetails } from '../../../../redux/admin/action';

const PipeLineDetailsPage = ({ orderId }) => {
  const navigate = Index.useNavigate();
  const dispatch = Index.useDispatch();
  const [pipeLineData, setPipeLineData] = useState({});
  const [pipeLoading, setPipeLoading] = useState(false);

  const getSinglePipeLineDetails = (id) => {
    setPipeLoading(true)
    dispatch(getSingleBunkerPipeLineDetails(orderId)).then((res) => {
      if (res?.status === 200) {
        setPipeLineData(res?.data);
        setTimeout(() => {
          setPipeLoading(false)
        }, 1000);
      } else {
          setTimeout(() => {
            setPipeLoading(false);
          }, 1000);
      }
    });
  };

const handleEditPipeLineDetails = (row) => {
  navigate("/admin/order/pipeline-details", {
    state: { row },
  });
};

  useEffect(() => {
    if (orderId) {
      getSinglePipeLineDetails();
    } 
  }, []);
  return (
    <div>
      {pipeLoading ? (
        <Index.TableContainer>
          <Index.Table>
            <Index.TableBody>
              <Index.TableRow>
                <Index.TableCell align="center" className="loading-purchase">
                  <Index.CircularProgress color="secondary" size={20} />
                </Index.TableCell>
              </Index.TableRow>
            </Index.TableBody>
          </Index.Table>
        </Index.TableContainer>
      ) : (
        <Index.TableBody>
          {pipeLineData?._id ? (
            <>
              <Index.Box className="common-button blue-button save-btn res-button-width res-button-add-set">
                <Index.Button
                  className="set-active-btn"
                  variant="contained"
                  type="submit"
                  onClick={() => handleEditPipeLineDetails(pipeLineData)}
                >
                  Edit PipeLine Details
                </Index.Button>
              </Index.Box>

              <Index.Box className="set-edit-timing-box pd-loading-details">
                <Index.Grid container spacing={2}>
                  <Index.Grid item xs={12} sm={12} md={12}>
                    <Index.Box className="title-header-flex order-loading-details">
                      <Index.Box className="title-main order-loading-content mb-10">
                        <Index.Typography
                          variant="p"
                          component="p"
                          className="page-title order-loading-title"
                        >
                          Master Role
                        </Index.Typography>
                      </Index.Box>
                    </Index.Box>
                  </Index.Grid>

                  <Index.Grid item xs={12} sm={6} md={2}>
                    <Index.Box>
                      <Index.Box>
                        <Index.Typography
                          variant="label"
                          component="label"
                          className="input-label opacity-60"
                        >
                          Anchors Aweigh
                        </Index.Typography>
                      </Index.Box>
                    </Index.Box>

                    <Index.Box className="input-design-div with-border">
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        {pipeLineData.anchorsAweigh
                          ? Index.moment(pipeLineData?.anchorsAweigh).format(
                              "DD/MM/YYYY HH:mm"
                            )
                          : "-"}
                      </Index.Typography>
                    </Index.Box>
                  </Index.Grid>

                  <Index.Grid item xs={12} sm={6} md={2}>
                    <Index.Box>
                      <Index.Box>
                        <Index.Typography
                          variant="label"
                          component="label"
                          className="input-label opacity-60"
                        >
                          Alongside AT OJ 06
                        </Index.Typography>
                      </Index.Box>
                    </Index.Box>

                    <Index.Box className="input-design-div with-border">
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        {pipeLineData.alongsideAtOJ06
                          ? Index.moment(pipeLineData?.alongsideAtOJ06).format(
                              "DD/MM/YYYY HH:mm"
                            )
                          : "-"}
                      </Index.Typography>
                    </Index.Box>
                  </Index.Grid>

                  <Index.Grid item xs={12} sm={6} md={2}>
                    <Index.Box>
                      <Index.Box>
                        <Index.Typography
                          variant="label"
                          component="label"
                          className="input-label opacity-60"
                        >
                          Hose Connect
                        </Index.Typography>
                      </Index.Box>
                    </Index.Box>

                    <Index.Box className="input-design-div with-border">
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        {pipeLineData.hoseConnect
                          ? Index.moment(pipeLineData?.hoseConnect).format(
                              "DD/MM/YYYY HH:mm"
                            )
                          : "-"}
                      </Index.Typography>
                    </Index.Box>
                  </Index.Grid>

                  <Index.Grid item xs={12} sm={6} md={2}>
                    <Index.Box>
                      <Index.Box>
                        <Index.Typography
                          variant="label"
                          component="label"
                          className="input-label opacity-60"
                        >
                          Loading Start
                        </Index.Typography>
                      </Index.Box>
                    </Index.Box>

                    <Index.Box className="input-design-div with-border">
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        {pipeLineData.loadingStart
                          ? Index.moment(pipeLineData?.loadingStart).format(
                              "DD/MM/YYYY HH:mm"
                            )
                          : "-"}
                      </Index.Typography>
                    </Index.Box>
                  </Index.Grid>

                  <Index.Grid item xs={12} sm={6} md={2}>
                    <Index.Box>
                      <Index.Box>
                        <Index.Typography
                          variant="label"
                          component="label"
                          className="input-label opacity-60"
                        >
                          Loading Completed
                        </Index.Typography>
                      </Index.Box>
                    </Index.Box>

                    <Index.Box className="input-design-div with-border">
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        {pipeLineData.loadingCompleted
                          ? Index.moment(pipeLineData?.loadingCompleted).format(
                              "DD/MM/YYYY HH:mm"
                            )
                          : "-"}
                      </Index.Typography>
                    </Index.Box>
                  </Index.Grid>
                  <Index.Grid item xs={12} sm={6} md={2}>
                    <Index.Box>
                      <Index.Box>
                        <Index.Typography
                          variant="label"
                          component="label"
                          className="input-label opacity-60"
                        >
                          Loading Rate
                        </Index.Typography>
                      </Index.Box>
                    </Index.Box>

                    <Index.Box className="input-design-div with-border">
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        {pipeLineData?.loadingRate
                          ? pipeLineData?.loadingRate
                          : ""}
                      </Index.Typography>
                    </Index.Box>
                  </Index.Grid>
                  <Index.Grid item xs={12} sm={6} md={2}>
                    <Index.Box>
                      <Index.Box>
                        <Index.Typography
                          variant="label"
                          component="label"
                          className="input-label opacity-60"
                        >
                          Loading Quantity
                        </Index.Typography>
                      </Index.Box>
                    </Index.Box>

                    <Index.Box className="input-design-div with-border">
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        {pipeLineData?.loadedQty ? pipeLineData?.loadedQty : ""}
                      </Index.Typography>
                    </Index.Box>
                  </Index.Grid>
                  <Index.Grid item xs={12} sm={6} md={2}>
                    <Index.Box>
                      <Index.Box>
                        <Index.Typography
                          variant="label"
                          component="label"
                          className="input-label opacity-60"
                        >
                          Cast Off
                        </Index.Typography>
                      </Index.Box>
                    </Index.Box>

                    <Index.Box className="input-design-div with-border">
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        {pipeLineData.castOff
                          ? Index.moment(pipeLineData?.castOff).format(
                              "DD/MM/YYYY HH:mm"
                            )
                          : "-"}
                      </Index.Typography>
                    </Index.Box>
                  </Index.Grid>
                  <Index.Grid item xs={12} sm={6} md={2}>
                    <Index.Box>
                      <Index.Box>
                        <Index.Typography
                          variant="label"
                          component="label"
                          className="input-label opacity-60"
                        >
                          Drop Anchorage
                        </Index.Typography>
                      </Index.Box>
                    </Index.Box>

                    <Index.Box className="input-design-div with-border">
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        {pipeLineData.dropAnchorage
                          ? Index.moment(pipeLineData?.dropAnchorage).format(
                              "DD/MM/YYYY HH:mm"
                            )
                          : "-"}
                      </Index.Typography>
                    </Index.Box>
                  </Index.Grid>
                  <Index.Grid item xs={12} sm={6} md={4}>
                    <Index.Box>
                      <Index.Box>
                        <Index.Typography
                          variant="label"
                          component="label"
                          className="input-label opacity-60"
                        >
                          BDN Copy
                        </Index.Typography>
                      </Index.Box>
                    </Index.Box>

                    {pipeLineData?.bdnCopy &&
                      pipeLineData?.bdnCopy.map((item, index) => (
                        <>
                          <Index.Box className="input-label pdf-input Wharfage-pdf">
                            <Index.Button
                              className="download-pdf-pd"
                              key={index}
                              onClick={() => {
                                window.open(
                                  `${process.env.REACT_APP_IMAGE_URL}/pipelineDoc/${item}`
                                );
                              }}
                            >
                              {index + 1}. {item ? item : "-"}
                            </Index.Button>
                          </Index.Box>
                        </>
                      ))}
                  </Index.Grid>
                </Index.Grid>
              </Index.Box>

              <Index.Box className="set-edit-timing-box pd-loading-details">
                <Index.Grid container spacing={2}>
                  <Index.Grid item xs={12} sm={12} md={12}>
                    <Index.Box className="title-header-flex order-loading-details">
                      <Index.Box className="title-main order-loading-content mb-10">
                        <Index.Typography
                          variant="p"
                          component="p"
                          className="page-title order-loading-title"
                        >
                          Surveyor Role
                        </Index.Typography>
                      </Index.Box>
                    </Index.Box>
                  </Index.Grid>

                  <Index.Grid item xs={12} sm={6} md={3}>
                    <Index.Box>
                      <Index.Box>
                        <Index.Typography
                          variant="label"
                          component="label"
                          className="input-label opacity-60"
                        >
                          Surveyor Reached OJ 06
                        </Index.Typography>
                      </Index.Box>
                    </Index.Box>

                    <Index.Box className="input-design-div with-border">
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        {pipeLineData.surveyorReachedOJ06
                          ? Index.moment(
                              pipeLineData?.surveyorReachedOJ06
                            ).format("DD/MM/YYYY HH:mm")
                          : "-"}
                      </Index.Typography>
                    </Index.Box>
                  </Index.Grid>

                  <Index.Grid item xs={12} sm={6} md={3}>
                    <Index.Box>
                      <Index.Box>
                        <Index.Typography
                          variant="label"
                          component="label"
                          className="input-label opacity-60"
                        >
                          Barge Alongside
                        </Index.Typography>
                      </Index.Box>
                    </Index.Box>

                    <Index.Box className="input-design-div with-border">
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        {pipeLineData.bargeAlongside
                          ? Index.moment(pipeLineData?.bargeAlongside).format(
                              "DD/MM/YYYY HH:mm"
                            )
                          : "-"}
                      </Index.Typography>
                    </Index.Box>
                  </Index.Grid>

                  <Index.Grid item xs={12} sm={6} md={2}>
                    <Index.Box>
                      <Index.Box>
                        <Index.Typography
                          variant="label"
                          component="label"
                          className="input-label opacity-60"
                        >
                          Hose Connection
                        </Index.Typography>
                      </Index.Box>
                    </Index.Box>

                    <Index.Box className="input-design-div with-border">
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        {pipeLineData.hoseConnection
                          ? Index.moment(pipeLineData?.hoseConnection).format(
                              "DD/MM/YYYY HH:mm"
                            )
                          : "-"}
                      </Index.Typography>
                    </Index.Box>
                  </Index.Grid>
                  <Index.Grid item xs={12} sm={6} md={2}>
                    <Index.Box>
                      <Index.Box>
                        <Index.Typography
                          variant="label"
                          component="label"
                          className="input-label opacity-60"
                        >
                          Pumping Start From Terminal
                        </Index.Typography>
                      </Index.Box>
                    </Index.Box>

                    <Index.Box className="input-design-div with-border">
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        {pipeLineData.pumpingStartFromTerminal
                          ? Index.moment(
                              pipeLineData?.pumpingStartFromTerminal
                            ).format("DD/MM/YYYY HH:mm")
                          : "-"}
                      </Index.Typography>
                    </Index.Box>
                  </Index.Grid>
                  <Index.Grid item xs={12} sm={6} md={2}>
                    <Index.Box>
                      <Index.Box>
                        <Index.Typography
                          variant="label"
                          component="label"
                          className="input-label opacity-60"
                        >
                          Pumping Completed
                        </Index.Typography>
                      </Index.Box>
                    </Index.Box>

                    <Index.Box className="input-design-div with-border">
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        {pipeLineData.pumpingCompleted
                          ? Index.moment(pipeLineData?.pumpingCompleted).format(
                              "DD/MM/YYYY HH:mm"
                            )
                          : "-"}
                      </Index.Typography>
                    </Index.Box>
                  </Index.Grid>
                  <Index.Grid item xs={12} sm={6} md={2}>
                    <Index.Box>
                      <Index.Box>
                        <Index.Typography
                          variant="label"
                          component="label"
                          className="input-label opacity-60"
                        >
                          Barge Sounding
                        </Index.Typography>
                      </Index.Box>
                    </Index.Box>

                    <Index.Box className="input-design-div with-border">
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        {pipeLineData?.bargeSounding
                          ? pipeLineData?.bargeSounding
                          : ""}
                      </Index.Typography>
                    </Index.Box>
                  </Index.Grid>
                  <Index.Grid item xs={12} sm={6} md={2}>
                    <Index.Box>
                      <Index.Box>
                        <Index.Typography
                          variant="label"
                          component="label"
                          className="input-label opacity-60"
                        >
                          Loaded Quantity
                        </Index.Typography>
                      </Index.Box>
                    </Index.Box>

                    <Index.Box className="input-design-div with-border">
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        {pipeLineData?.loadedQtySurveyor
                          ? pipeLineData?.loadedQtySurveyor
                          : ""}
                      </Index.Typography>
                    </Index.Box>
                  </Index.Grid>
                  <Index.Grid item xs={12} sm={6} md={2}>
                    <Index.Box>
                      <Index.Box>
                        <Index.Typography
                          variant="label"
                          component="label"
                          className="input-label opacity-60"
                        >
                          Barge Cast Off
                        </Index.Typography>
                      </Index.Box>
                    </Index.Box>

                    <Index.Box className="input-design-div with-border">
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        {pipeLineData.bargeCastOff
                          ? Index.moment(pipeLineData?.bargeCastOff).format(
                              "DD/MM/YYYY HH:mm"
                            )
                          : "-"}
                      </Index.Typography>
                    </Index.Box>
                  </Index.Grid>
                  <Index.Grid item xs={12} sm={6} md={2}>
                    <Index.Box>
                      <Index.Box>
                        <Index.Typography
                          variant="label"
                          component="label"
                          className="input-label opacity-60"
                        >
                          BDN Quantity
                        </Index.Typography>
                      </Index.Box>
                    </Index.Box>

                    <Index.Box className="input-design-div with-border">
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        {pipeLineData?.bdnQty ? pipeLineData?.bdnQty : ""}
                      </Index.Typography>
                    </Index.Box>
                  </Index.Grid>
                </Index.Grid>
              </Index.Box>
            </>
          ) : (
            <Index.Table>
              <Index.TableRow>
                <Index.TableCell
                  component="td"
                  variant="td"
                  scope="row"
                  className="no-data-in-list loading-purchase"
                  // colSpan={6}
                  align="center"
                >
                  No data available
                </Index.TableCell>
              </Index.TableRow>
            </Index.Table>
          )}
        </Index.TableBody>
      )}
    </div>
  );
};

export default PipeLineDetailsPage