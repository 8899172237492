import React, { useEffect, useState, useRef } from "react";
import Index from "../../../../Index";
import { Formik } from "formik";
import { getBunkerOrderDashboardOrders } from "../../../../../redux/admin/action";
import ExcelExportHelper from "./ExcelExportHelper";
// import { useReactToPrint } from "react-to-print";
// import BunkerOrderPrintPDF from "./BunkerOrderPrintPDF";

const BunkerOrderDashboard = () => {
  const dispatch = Index.useDispatch();
  const { bunkerOrderDashboard } = Index.useSelector(
    (state) => state.AdminReducer
  );

  // PDF Download
  // const componentRef = useRef();
  // const handlePrint = useReactToPrint({
  //   content: () => componentRef.current,
  // });

  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState(0);
  const [paginationPerPage, setPaginationPerPage] = useState(50);
  const [bunkerOrderData, setBunkerOrderData] = useState([]);
  const [currentRows, setCurrentRows] = useState([]);

  // const [open, setOpen] = useState(false);
  const initialValues = {
    startDate: "",
    endDate: "",
    type: "Confirm",
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  useEffect(() => {
    dispatch(getBunkerOrderDashboardOrders(initialValues));
  }, [dispatch]);

  const handleFormSubmit = async (values) => {
    setLoading(true);
    const data = new URLSearchParams();
    data.append("type", values?.type);
    data.append("startDate", values?.startDate);
    data.append("endDate", values?.endDate);
    dispatch(getBunkerOrderDashboardOrders(data)).then((res) => {
      if (res) {
        setBunkerOrderData(res);
        setTimeout(() => {
          setLoading(false);
        }, [2000]);
      }
    });
  };

  // Pagination calculation start
  const PER_PAGE = paginationPerPage;
  useEffect(() => {
    //Page count
    const count = Math.ceil(bunkerOrderDashboard?.findOrder?.length / PER_PAGE);
    setCount(count);
    const indexOfLastRow = currentPage * PER_PAGE;
    const indexOfFirstRow = indexOfLastRow - PER_PAGE;
    const modifiedRows = bunkerOrderDashboard?.findOrder?.slice(
      indexOfFirstRow,
      indexOfLastRow
    );
    setCurrentRows(modifiedRows);
    setBunkerOrderData(bunkerOrderDashboard);
    if (modifiedRows?.length === 0 && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  }, [
    currentPage,
    bunkerOrderDashboard,
    bunkerOrderData,
    paginationPerPage,
    PER_PAGE,
  ]);

  const orderView = (id) => {
    window.open(`/admin/order/view-order/${id}`, "_blank");
  };

  return (
    <>
      <Index.DynamicTitle title="Bunker Order Dashboard" />

      <Index.Box className="">
        <Index.Box className="barge-common-box">
          <Index.Box className="title-header">
            <Index.Box className="title-header-flex res-title-header-flex cus-title-header banker-order-header">
              <Index.Box className="title-main">
                <Index.Typography
                  variant="p"
                  component="p"
                  className="page-title"
                >
                  Bunker Order Dashboard
                </Index.Typography>
              </Index.Box>

              <Formik
                enableReinitialize
                initialValues={initialValues}
                onSubmit={handleFormSubmit}
              >
                {({
                  values,
                  errors,
                  touched,
                  setFieldValue,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Index.Grid
                      container
                      spacing={2}
                      className="bunker-mt-grid"
                    >
                      <Index.Grid item xs={6} md={3}>
                        <Index.Typography
                          variant="label"
                          component="label"
                          className="input-label"
                        >
                          Order Type
                        </Index.Typography>
                        <Index.Box className="input-design-div with-border filter-date-overflow">
                          <Index.Select
                            className="placeholder-set-text-box"
                            fullWidth
                            name="type"
                            inputProps={{ "aria-label": "Without label" }}
                            defaultValue={values ? values?.type : ""}
                            onChange={(e) => {
                              setFieldValue("type", e.target.value);
                            }}
                          >
                            <Index.MenuItem value="Confirm">
                              Current
                            </Index.MenuItem>
                            <Index.MenuItem value="Cancel">
                              Cancelled
                            </Index.MenuItem>
                            <Index.MenuItem value="Completed">
                              Completed
                            </Index.MenuItem>
                          </Index.Select>
                        </Index.Box>
                      </Index.Grid>
                      <Index.Grid item xs={6} md={3}>
                        <Index.Typography
                          variant="label"
                          component="label"
                          className="input-label"
                        >
                          Start Date
                        </Index.Typography>
                        <Index.Box className="input-design-div filter-date-overflow">
                          <Index.Box className="set-date set-date-picker picker-box-set">
                            <Index.LocalizationProvider
                              dateAdapter={Index.AdapterDayjs}
                              className="fliter-date-local"
                            >
                              <Index.DemoContainer components={["DatePicker"]}>
                                <Index.DemoItem className="fliter-date-item">
                                  <Index.DatePicker
                                    className="filter-datepicker"
                                    fullWidth
                                    format="DD/MM/YYYY"
                                    name="startDate"
                                    defaultValue={values?.startDate}
                                    onChange={(value) => {
                                      const formatedDate = Index.moment(
                                        value.$d
                                      ).format("YYYY/MM/DD");
                                      setFieldValue("startDate", formatedDate);
                                    }}
                                  />
                                </Index.DemoItem>
                              </Index.DemoContainer>
                            </Index.LocalizationProvider>
                          </Index.Box>
                        </Index.Box>
                      </Index.Grid>

                      <Index.Grid item xs={6} md={3}>
                        <Index.Typography
                          variant="label"
                          component="label"
                          className="input-label"
                        >
                          End Date
                        </Index.Typography>
                        <Index.Box className="input-design-div filter-date-overflow">
                          <Index.Box className="set-date set-date-picker picker-box-set">
                            <Index.LocalizationProvider
                              dateAdapter={Index.AdapterDayjs}
                              className="fliter-date-local"
                            >
                              <Index.DemoContainer components={["DatePicker"]}>
                                <Index.DemoItem className="fliter-date-item">
                                  <Index.DatePicker
                                    className="filter-datepicker"
                                    fullWidth
                                    format="DD/MM/YYYY"
                                    name="endDate"
                                    defaultValue={values?.endDate}
                                    onChange={(value) => {
                                      const formatedDate = Index.moment(
                                        value.$d
                                      ).format("YYYY/MM/DD");
                                      setFieldValue("endDate", formatedDate);
                                    }}
                                  />
                                </Index.DemoItem>
                              </Index.DemoContainer>
                            </Index.LocalizationProvider>
                          </Index.Box>
                        </Index.Box>
                      </Index.Grid>
                      <Index.Grid item xs={6} md={3}>
                        <Index.Box className="flex-filter-content">
                          <Index.Box className="common-button blue-button res-blue-button">
                            <Index.Button
                              variant="contained"
                              type="submit"
                              disabled={loading}
                            >
                              Filter
                            </Index.Button>
                          </Index.Box>
                          <Index.Box className="filer-bulker">
                            {bunkerOrderDashboard?.findOrder?.length && (
                              <>
                                <Index.Box className="common-button grey-button">
                                  <ExcelExportHelper
                                    data={bunkerOrderDashboard}
                                    loading={loading}
                                  />
                                </Index.Box>
                              </>
                            )}
                          </Index.Box>

                          {/* <Index.Box className="filer-bulker">
                            <Index.Button className="print-expense"
                                onClick={async () => {
                                  handlePrint();
                                }}
                              >
                                PDF
                              </Index.Button>
                          </Index.Box> */}
                        </Index.Box>
                      </Index.Grid>
                    </Index.Grid>
                  </form>
                )}
              </Formik>
            </Index.Box>
          </Index.Box>
          <Index.Box></Index.Box>
        </Index.Box>

        <Index.Box className="chart-main-wrapp category-sec-main chart-main-wrapp">
          {/* 1. Transportation */}
          <Index.Box className="bunker-order-chart-box">
            <Index.Box className="chart-header">
              <Index.Typography className="chart-title" component="p">
                TRANSPORTATION
              </Index.Typography>
            </Index.Box>

            <Index.Box className="bunker-order-chart-graph-devider cus-bunker-order-chart">
              <Index.Box className="chart-header chart-inner-head 123">
                <Index.Typography className="chart-title" component="p">
                  TRANSPORTATION
                </Index.Typography>
                <Index.Typography className="chart-title" component="p">
                  Qty (in MT)
                </Index.Typography>
              </Index.Box>
              <Index.Box className="total-invoice new-total-invoice">
                <Index.Box className="card-flex-invoice">
                  {bunkerOrderData?.transportationData?.map((item, ind) => {
                    return (
                      <Index.Box
                        key={ind}
                        className="card-right-content card-graph-content-inner card-graph-content-inner-new"
                      >
                        <Index.Typography
                          className="card-total invoice-col-1"
                          component="p"
                        >
                          {item?.transportation}
                        </Index.Typography>
                        <Index.Typography className="card-price invoice-amount invoice-col-3">
                          {item?.quantity > 0
                            ? item?.quantity.toLocaleString("en-IN", {
                                minimumFractionDigits: 3,
                                maximumFractionDigits: 3,
                              })
                            : 0?.toLocaleString("en-IN", {
                                minimumFractionDigits: 3,
                                maximumFractionDigits: 3,
                              })}
                        </Index.Typography>
                      </Index.Box>
                    );
                  })}
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>

          {/* 2. Barge Tab */}
          <Index.Box className="bunker-order-chart-box">
            <Index.Box className="chart-header">
              <Index.Typography className="chart-title" component="p">
                AS PER BARGE
              </Index.Typography>
            </Index.Box>

            <Index.Box className="bunker-order-chart-graph-devider cus-bunker-order-chart">
              <Index.Box className="chart-header chart-inner-head 123">
                <Index.Typography className="chart-title" component="p">
                  AS PER BARGE
                </Index.Typography>
                <Index.Typography className="chart-title" component="p">
                  Qty (in MT)
                </Index.Typography>
              </Index.Box>
              <Index.Box className="total-invoice new-total-invoice">
                <Index.Box className="card-flex-invoice">
                  {bunkerOrderData?.bargeData?.map((item, ind) => {
                    return (
                      <Index.Box
                        key={ind}
                        className="card-right-content card-graph-content-inner card-graph-content-inner-new"
                      >
                        <Index.Typography
                          className="card-total invoice-col-1"
                          component="p"
                        >
                          {item?.bargeName}
                        </Index.Typography>
                        <Index.Typography className="card-price invoice-amount invoice-col-3">
                          {item?.quantity > 0
                            ? item?.quantity.toLocaleString("en-IN", {
                                minimumFractionDigits: 3,
                                maximumFractionDigits: 3,
                              })
                            : 0?.toLocaleString("en-IN", {
                                minimumFractionDigits: 3,
                                maximumFractionDigits: 3,
                              })}
                        </Index.Typography>
                      </Index.Box>
                    );
                  })}
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>

          {/* 3. Terminal Tab */}
          <Index.Box className="bunker-order-chart-box">
            <Index.Box className="chart-header">
              <Index.Typography className="chart-title" component="p">
                AS PER TERMINAL
              </Index.Typography>
            </Index.Box>

            <Index.Box className="bunker-order-chart-graph-devider cus-bunker-order-chart">
              <Index.Box className="chart-header chart-inner-head 123">
                <Index.Typography className="chart-title" component="p">
                  AS PER TERMINAL
                </Index.Typography>
                <Index.Typography className="chart-title" component="p">
                  Qty (in MT)
                </Index.Typography>
              </Index.Box>
              <Index.Box className="total-invoice new-total-invoice">
                <Index.Box className="card-flex-invoice">
                  {bunkerOrderData?.terminalData?.map((item, ind) => {
                    return (
                      <Index.Box
                        key={ind}
                        className="card-right-content card-graph-content-inner card-graph-content-inner-new"
                      >
                        <Index.Typography
                          className="card-total invoice-col-1"
                          component="p"
                        >
                          {item?.terminal}
                        </Index.Typography>
                        <Index.Typography className="card-price invoice-amount invoice-col-3">
                          {item?.quantity > 0
                            ? item?.quantity.toLocaleString("en-IN", {
                                minimumFractionDigits: 3,
                                maximumFractionDigits: 3,
                              })
                            : 0?.toLocaleString("en-IN", {
                                minimumFractionDigits: 3,
                                maximumFractionDigits: 3,
                              })}
                        </Index.Typography>
                      </Index.Box>
                    );
                  })}
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>

          {/* 4. Product Tab */}
          <Index.Box className="bunker-order-chart-box">
            <Index.Box className="chart-header">
              <Index.Typography className="chart-title" component="p">
                AS PER PRODUCT
              </Index.Typography>
            </Index.Box>

            <Index.Box className="bunker-order-chart-graph-devider cus-bunker-order-chart">
              <Index.Box className="chart-header chart-inner-head 123">
                <Index.Typography className="chart-title" component="p">
                  AS PER PRODUCT
                </Index.Typography>
                <Index.Typography className="chart-title" component="p">
                  Qty (in MT)
                </Index.Typography>
              </Index.Box>
              <Index.Box className="total-invoice new-total-invoice">
                <Index.Box className="card-flex-invoice">
                  {bunkerOrderData?.productData?.map((item, ind) => {
                    return (
                      <Index.Box
                        key={ind}
                        className="card-right-content card-graph-content-inner card-graph-content-inner-new"
                      >
                        <Index.Typography
                          className="card-total invoice-col-1"
                          component="p"
                        >
                          {item?.product}
                        </Index.Typography>
                        <Index.Typography className="card-price invoice-amount invoice-col-3">
                          {item?.quantity > 0
                            ? item?.quantity.toLocaleString("en-IN", {
                                minimumFractionDigits: 3,
                                maximumFractionDigits: 3,
                              })
                            : 0?.toLocaleString("en-IN", {
                                minimumFractionDigits: 3,
                                maximumFractionDigits: 3,
                              })}
                        </Index.Typography>
                      </Index.Box>
                    );
                  })}
                  {/* Total Product Qty */}
                  <Index.Box className="card-right-content card-graph-content-inner card-graph-content-inner-new">
                    <Index.Typography
                      className="card-total invoice-col-1"
                      component="p"
                    >
                      Total Qty
                    </Index.Typography>
                    <Index.Typography className="card-price invoice-amount invoice-col-3">
                      {bunkerOrderData?.productTotalQty > 0
                        ? bunkerOrderData?.productTotalQty?.toLocaleString(
                            "en-IN",
                            {
                              minimumFractionDigits: 3,
                              maximumFractionDigits: 3,
                            }
                          )
                        : 0?.toLocaleString("en-IN", {
                            minimumFractionDigits: 3,
                            maximumFractionDigits: 3,
                          })}
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>

          {/* 5. Port Tab */}
          <Index.Box className="bunker-order-chart-box">
            <Index.Box className="chart-header">
              <Index.Typography className="chart-title" component="p">
                AS PER PORT
              </Index.Typography>
            </Index.Box>

            <Index.Box className="bunker-order-chart-graph-devider cus-bunker-order-chart">
              <Index.Box className="chart-header chart-inner-head 123">
                <Index.Typography className="chart-title" component="p">
                  AS PER PORT
                </Index.Typography>
                <Index.Typography className="chart-title" component="p">
                  Qty (in MT)
                </Index.Typography>
              </Index.Box>
              <Index.Box className="total-invoice new-total-invoice">
                <Index.Box className="card-flex-invoice">
                  {bunkerOrderData?.portData?.map((item, ind) => {
                    return (
                      <Index.Box
                        key={ind}
                        className="card-right-content card-graph-content-inner card-graph-content-inner-new"
                      >
                        <Index.Typography
                          className="card-total invoice-col-1"
                          component="p"
                        >
                          {item?.portName}
                        </Index.Typography>
                        <Index.Typography className="card-price invoice-amount invoice-col-3">
                          {item?.quantity > 0
                            ? item?.quantity.toLocaleString("en-IN", {
                                minimumFractionDigits: 3,
                                maximumFractionDigits: 3,
                              })
                            : 0?.toLocaleString("en-IN", {
                                minimumFractionDigits: 3,
                                maximumFractionDigits: 3,
                              })}
                        </Index.Typography>
                      </Index.Box>
                    );
                  })}
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>

          {/* 6. Type Tab */}
          <Index.Box className="bunker-order-chart-box">
            <Index.Box className="chart-header">
              <Index.Typography className="chart-title" component="p">
                AS PER TYPE
              </Index.Typography>
            </Index.Box>
            <Index.Box className="bunker-order-chart-graph-devider cus-bunker-order-chart">
              <Index.Box className="chart-header chart-inner-head 123">
                <Index.Typography className="chart-title" component="p">
                  AS PER TYPE
                </Index.Typography>
                <Index.Typography className="chart-title" component="p">
                  Qty (in MT)
                </Index.Typography>
              </Index.Box>
              <Index.Box className="total-invoice new-total-invoice">
                <Index.Box className="card-flex-invoice">
                  {bunkerOrderData?.exmiTypeData?.map((item, ind) => {
                    return (
                      <Index.Box
                        key={ind}
                        className="card-right-content card-graph-content-inner card-graph-content-inner-new"
                      >
                        <Index.Typography
                          className="card-total invoice-col-1"
                          component="p"
                        >
                          {item?.exmiOrDelivered}
                        </Index.Typography>
                        <Index.Typography className="card-price invoice-amount invoice-col-3">
                          {item?.quantity > 0
                            ? item?.quantity.toLocaleString("en-IN", {
                                minimumFractionDigits: 3,
                                maximumFractionDigits: 3,
                              })
                            : 0?.toLocaleString("en-IN", {
                                minimumFractionDigits: 3,
                                maximumFractionDigits: 3,
                              })}
                        </Index.Typography>
                      </Index.Box>
                    );
                  })}
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>

          {/* 7. Vessel Run Tab */}
          <Index.Box className="bunker-order-chart-box">
            <Index.Box className="chart-header">
              <Index.Typography className="chart-title" component="p">
                AS PER VESSEL RUN
              </Index.Typography>
            </Index.Box>
            <Index.Box className="bunker-order-chart-graph-devider cus-bunker-order-chart">
              <Index.Box className="chart-header chart-inner-head 123">
                <Index.Typography className="chart-title" component="p">
                  AS PER VESSEL RUN
                </Index.Typography>
                <Index.Typography className="chart-title" component="p">
                  Qty (in MT)
                </Index.Typography>
              </Index.Box>
              <Index.Box className="total-invoice new-total-invoice">
                <Index.Box className="card-flex-invoice">
                  {bunkerOrderData?.vesselRun?.map((item, ind) => {
                    return (
                      <Index.Box
                        key={ind}
                        className="card-right-content card-graph-content-inner card-graph-content-inner-new"
                      >
                        <Index.Typography
                          className="card-total invoice-col-1"
                          component="p"
                        >
                          {item?.foreign}
                        </Index.Typography>
                        <Index.Typography className="card-price invoice-amount invoice-col-3">
                          {item?.quantity > 0
                            ? item?.quantity.toLocaleString("en-IN", {
                                minimumFractionDigits: 3,
                                maximumFractionDigits: 3,
                              })
                            : 0?.toLocaleString("en-IN", {
                                minimumFractionDigits: 3,
                                maximumFractionDigits: 3,
                              })}
                        </Index.Typography>
                      </Index.Box>
                    );
                  })}
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>

          {/* 8. Trader Tab */}
          <Index.Box className="bunker-order-chart-box">
            <Index.Box className="chart-header">
              <Index.Typography className="chart-title" component="p">
                AS PER TRADER
              </Index.Typography>
            </Index.Box>
            <Index.Box className="bunker-order-chart-graph-devider cus-bunker-order-chart">
              <Index.Box className="chart-header chart-inner-head 123">
                <Index.Typography className="chart-title" component="p">
                  AS PER TRADER
                </Index.Typography>
                <Index.Typography className="chart-title" component="p">
                  Qty (in MT)
                </Index.Typography>
              </Index.Box>
              <Index.Box className="total-invoice new-total-invoice">
                <Index.Box className="card-flex-invoice">
                  {bunkerOrderData?.traderData?.map((item, ind) => {
                    return (
                      <Index.Box
                        key={ind}
                        className="card-right-content card-graph-content-inner card-graph-content-inner-new"
                      >
                        <Index.Typography
                          className="card-total invoice-col-1"
                          component="p"
                        >
                          {item?.traderName}
                        </Index.Typography>
                        <Index.Typography className="card-price invoice-amount invoice-col-3">
                          {item?.quantity > 0
                            ? item?.quantity.toLocaleString("en-IN", {
                                minimumFractionDigits: 3,
                                maximumFractionDigits: 3,
                              })
                            : 0?.toLocaleString("en-IN", {
                                minimumFractionDigits: 3,
                                maximumFractionDigits: 3,
                              })}
                        </Index.Typography>
                      </Index.Box>
                    );
                  })}
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>

          {/* 9. Company wise Tab */}
          <Index.Box className="bunker-order-chart-box">
            <Index.Box className="chart-header">
              <Index.Typography className="chart-title" component="p">
                AS PER Company
              </Index.Typography>
            </Index.Box>

            <Index.Box className="bunker-order-chart-graph-devider cus-bunker-order-chart">
              <Index.Box className="chart-header chart-inner-head 123">
                <Index.Typography className="chart-title" component="p">
                  AS PER Company
                </Index.Typography>
                <Index.Typography className="chart-title" component="p">
                  Qty (in MT)
                </Index.Typography>
              </Index.Box>
              <Index.Box className="total-invoice new-total-invoice">
                <Index.Box className="card-flex-invoice">
                  {bunkerOrderData?.companyWiseQty
                    ?.filter(
                      (res) =>
                        res.companyName === "BSJPL" ||
                        res.companyName === "SKQPL"
                    )
                    ?.map((item, ind) => {
                      return (
                        <Index.Box
                          key={ind}
                          className="card-right-content card-graph-content-inner card-graph-content-inner-new"
                        >
                          <Index.Typography
                            className="card-total invoice-col-1"
                            component="p"
                          >
                            {item?.companyName}
                          </Index.Typography>
                          <Index.Typography className="card-price invoice-amount invoice-col-3">
                            {item?.quantity > 0
                              ? item?.quantity.toLocaleString("en-IN", {
                                  minimumFractionDigits: 3,
                                  maximumFractionDigits: 3,
                                })
                              : 0?.toLocaleString("en-IN", {
                                  minimumFractionDigits: 3,
                                  maximumFractionDigits: 3,
                                })}
                          </Index.Typography>
                        </Index.Box>
                      );
                    })}
                  {/* Total Product Qty
                  <Index.Box className="card-right-content card-graph-content-inner card-graph-content-inner-new">
                    <Index.Typography
                      className="card-total invoice-col-1"
                      component="p"
                    >
                      Total Qty
                    </Index.Typography>
                    <Index.Typography className="card-price invoice-amount invoice-col-3">
                      {bunkerOrderData?.companyWiseQty?.le > 0
                        ? bunkerOrderData?.companyWiseQty?.toLocaleString(
                            "en-IN",
                            {
                              minimumFractionDigits: 3,
                              maximumFractionDigits: 3,
                            }
                          )
                        : 0?.toLocaleString("en-IN", {
                            minimumFractionDigits: 3,
                            maximumFractionDigits: 3,
                          })}
                    </Index.Typography>
                  </Index.Box> */}
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>

          {/* 10. HSD wise tab */}
          <Index.Box className="bunker-order-chart-box">
            <Index.Box className="chart-header">
              <Index.Typography className="chart-title" component="p">
                AS PER HSD
              </Index.Typography>
            </Index.Box>

            <Index.Box className="bunker-order-chart-graph-devider cus-bunker-order-chart">
              <Index.Box className="chart-header chart-inner-head 123">
                <Index.Typography className="chart-title" component="p">
                  AS PER HSD
                </Index.Typography>
                <Index.Typography className="chart-title" component="p">
                  Qty (in MT)
                </Index.Typography>
              </Index.Box>
              <Index.Box className="total-invoice new-total-invoice">
                <Index.Box className="card-flex-invoice">
                  {bunkerOrderData?.HSDData?.map((item, ind) => {
                    return (
                      <Index.Box
                        key={ind}
                        className="card-right-content card-graph-content-inner card-graph-content-inner-new"
                      >
                        <Index.Typography
                          className="card-total invoice-col-1"
                          component="p"
                        >
                          {item?.terminal}
                        </Index.Typography>
                        <Index.Typography className="card-price invoice-amount invoice-col-3">
                          {item?.quantity > 0
                            ? item?.quantity.toLocaleString("en-IN", {
                                minimumFractionDigits: 3,
                                maximumFractionDigits: 3,
                              })
                            : 0?.toLocaleString("en-IN", {
                                minimumFractionDigits: 3,
                                maximumFractionDigits: 3,
                              })}
                        </Index.Typography>
                      </Index.Box>
                    );
                  })}
                  {/* <Index.Box className="card-right-content card-graph-content-inner card-graph-content-inner-new">
                    <Index.Typography
                      className="card-total invoice-col-1"
                      component="p"
                    >
                      Total Qty
                    </Index.Typography>
                    <Index.Typography className="card-price invoice-amount invoice-col-3">
                      {bunkerOrderData?.HSDData > 0
                        ? bunkerOrderData?.HSDData?.toLocaleString("en-IN", {
                            minimumFractionDigits: 3,
                            maximumFractionDigits: 3,
                          })
                        : 0?.toLocaleString("en-IN", {
                            minimumFractionDigits: 3,
                            maximumFractionDigits: 3,
                          })}
                    </Index.Typography>
                  </Index.Box> */}
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>

          {/* Bunker Order List */}
          <Index.Box className="bunker-order-dashboard">
            <Index.Box className="barge-common-box">
              <Index.Box className="title-header">
                <Index.Box className="title-header-flex res-title-header-flex ">
                  <Index.Box className="title-main">
                    <Index.Typography
                      variant="p"
                      component="p"
                      className="page-title"
                    >
                      Bunker Order
                    </Index.Typography>
                  </Index.Box>
                </Index.Box>
              </Index.Box>

              <Index.Box className="">
                <Index.TableContainer
                  component={Index.Paper}
                  className="table-container"
                >
                  <Index.Table
                    aria-label="simple table"
                    className="table-design-main supply-table one-line-table invoice-table-set "
                  >
                    <Index.TableHead>
                      <Index.TableRow>
                        <Index.TableCell> Sr.No</Index.TableCell>
                        <Index.TableCell align="left">Craft</Index.TableCell>
                        <Index.TableCell align="left">
                          Loading Planned Date
                        </Index.TableCell>
                        <Index.TableCell align="left">
                          Vessel Name
                        </Index.TableCell>
                        <Index.TableCell align="left">Run</Index.TableCell>
                        <Index.TableCell align="left">
                          Transportation
                        </Index.TableCell>
                        <Index.TableCell align="left">Type</Index.TableCell>
                        <Index.TableCell align="left">Trader</Index.TableCell>
                        <Index.TableCell align="left">Terminal</Index.TableCell>
                        <Index.TableCell align="left">Agent</Index.TableCell>
                        <Index.TableCell align="left">Product</Index.TableCell>
                        <Index.TableCell align="left">Port</Index.TableCell>
                        <Index.TableCell align="left">Qty</Index.TableCell>
                        <Index.TableCell align="left">
                          Loading Completed Qty
                        </Index.TableCell>
                      </Index.TableRow>
                    </Index.TableHead>
                    {loading ? (
                      <Index.TableBody>
                        <Index.TableRow>
                          <Index.TableCell colSpan={14} align="center">
                            <Index.CircularProgress
                              color="secondary"
                              size={20}
                            />
                          </Index.TableCell>
                        </Index.TableRow>
                      </Index.TableBody>
                    ) : (
                      <Index.TableBody>
                        {currentRows?.length ? (
                          currentRows?.map((item, index) => (
                            <Index.TableRow
                              key={item?._id}
                              sx={{ cursor: "pointer" }}
                            >
                              <Index.TableCell
                                align="left"
                                onClick={() => {
                                  orderView(item?._id);
                                }}
                              >
                                {1 + index}
                              </Index.TableCell>

                              <Index.TableCell
                                align="left"
                                onClick={() => {
                                  orderView(item?._id);
                                }}
                              >
                                {item?.proposedBarge?.length
                                  ? item?.proposedBarge[0]?.bargeName
                                  : "-"}
                              </Index.TableCell>

                              <Index.TableCell
                                align="left"
                                onClick={() => {
                                  orderView(item?._id);
                                }}
                              >
                                {item?.loadingDate
                                  ? Index.moment(item?.loadingDate).format(
                                      "DD/MM/YYYY"
                                    )
                                  : "-"}
                              </Index.TableCell>

                              <Index.TableCell
                                align="left"
                                onClick={() => {
                                  orderView(item?._id);
                                }}
                              >
                                {item?.vesselName ? item?.vesselName : "-"}
                              </Index.TableCell>

                              <Index.TableCell
                                align="left"
                                onClick={() => {
                                  orderView(item?._id);
                                }}
                              >
                                {item?.foreign ? item?.foreign : "-"}
                              </Index.TableCell>

                              <Index.TableCell
                                align="left"
                                onClick={() => {
                                  orderView(item?._id);
                                }}
                              >
                                {item?.transportation
                                  ? item?.transportation
                                  : "-"}
                              </Index.TableCell>

                              <Index.TableCell
                                align="left"
                                onClick={() => {
                                  orderView(item?._id);
                                }}
                              >
                                {item?.exmiOrDelivered
                                  ? item?.exmiOrDelivered
                                  : "-"}
                              </Index.TableCell>

                              <Index.TableCell
                                align="left"
                                onClick={() => {
                                  orderView(item?._id);
                                }}
                              >
                                {item?.traderId?.traderName
                                  ? item?.traderId?.traderName
                                  : "-"}
                              </Index.TableCell>

                              <Index.TableCell
                                align="left"
                                onClick={() => {
                                  orderView(item?._id);
                                }}
                              >
                                {item?.terminal ? item?.terminal : "-"}
                              </Index.TableCell>

                              <Index.TableCell
                                align="left"
                                onClick={() => {
                                  orderView(item?._id);
                                }}
                              >
                                {item?.agent ? item?.agent : "-"}
                              </Index.TableCell>

                              <Index.TableCell
                                align="left"
                                onClick={() => {
                                  orderView(item?._id);
                                }}
                              >
                                {item?.product ? item?.product : "-"}
                              </Index.TableCell>

                              <Index.TableCell
                                align="left"
                                onClick={() => {
                                  orderView(item?._id);
                                }}
                              >
                                {item?.port?.portName
                                  ? item?.port?.portName
                                  : "-"}
                              </Index.TableCell>

                              <Index.TableCell
                                align="left"
                                onClick={() => {
                                  orderView(item?._id);
                                }}
                              >
                                {item?.lowerQuantity === "0" &&
                                item?.higherQuantity === "0"
                                  ? "-"
                                  : item?.lowerQuantity &&
                                    item?.higherQuantity != "0"
                                  ? item?.lowerQuantity +
                                    " - " +
                                    item?.higherQuantity
                                  : item?.lowerQuantity}
                              </Index.TableCell>
                              <Index.TableCell
                                align="left"
                                onClick={() => {
                                  orderView(item?._id);
                                }}
                              >
                                {item?.loadingCompletedQuantity
                                  ? item?.loadingCompletedQuantity
                                  : "-"}
                              </Index.TableCell>
                            </Index.TableRow>
                          ))
                        ) : (
                          <Index.TableRow>
                            <Index.TableCell
                              component="td"
                              variant="td"
                              scope="row"
                              className="no-data-in-list empty-record-align"
                              colSpan={18}
                              align="center"
                            >
                              No data available
                            </Index.TableCell>
                          </Index.TableRow>
                        )}
                      </Index.TableBody>
                    )}
                  </Index.Table>
                </Index.TableContainer>
              </Index.Box>
              <Index.Box className="pagination-design flex-end">
                <Index.Stack spacing={2}>
                  <Index.Box className="pagination-count">
                    <Index.Box className="input-design-div with-border">
                      <Index.Select
                        fullWidth
                        value={paginationPerPage}
                        onChange={(e) => {
                          setPaginationPerPage(e.target.value);
                        }}
                      >
                        <Index.MenuItem value={10}>10</Index.MenuItem>
                        <Index.MenuItem value={25}>25</Index.MenuItem>
                        <Index.MenuItem value={50}>50</Index.MenuItem>
                        <Index.MenuItem value={100}>100</Index.MenuItem>
                      </Index.Select>
                    </Index.Box>
                    <Index.Pagination
                      count={count}
                      page={currentPage}
                      onChange={handlePageChange}
                    />
                  </Index.Box>
                </Index.Stack>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
      {/* <Index.Box className="pdfDownload" sx={{ display: "none" }}>
        <BunkerOrderPrintPDF
          expenseData={exportDetails}
          componentRef={componentRef}
        />
      </Index.Box> */}
    </>
  );
};

export default BunkerOrderDashboard;
