import { DateTimePicker } from "@mui/x-date-pickers";
import Index from "../../container/Index";
import { Formik } from "formik";
import dayjs from "dayjs";

function SingleUpdateTextFieldPopUp({
  openUpdateDatePopUp,
  handleCloseDateEditModal,
  editTimeOrderId,
  handleUpdateDateViaApi,
  editTimeDateData,
  isSingleFieldUpdate,
}) {
  return (
    <>
      <Index.Dialog
        open={openUpdateDatePopUp}
        onClose={handleCloseDateEditModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Index.DialogContent className="expense-model-main  modal-update-main">
          <Index.Box className="title-main">
            <Formik
              enableReinitialize
              initialValues={editTimeDateData?.fieldInitialValues}
              // validationSchema={vesselValidationSchema}
              onSubmit={(values) =>
                handleUpdateDateViaApi(values, editTimeDateData)
              }
            >
              {({
                values,
                errors,
                touched,
                setFieldValue,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldTouched,
                handleFocus,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Index.Box className="title-main">
                    <Index.Box className="jus-between">
                      <Index.Typography
                        variant="h6"
                        component="h6"
                        className="input-label"
                      >
                        {editTimeDateData?.popUpTitle}
                      </Index.Typography>
                      <Index.ClearIcon
                        className="model-close-icon"
                        onClick={handleCloseDateEditModal}
                      />
                    </Index.Box>
                    <Index.Box className="update-model-main-box-set">
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        {editTimeDateData?.fieldLabel}
                      </Index.Typography>
                      <Index.Box className="input-design-div with-border">
                        <Index.TextField
                          hiddenLabel
                          id="filled-hidden-label-normal"
                          placeholder={editTimeDateData?.fieldPlaceholder}
                          variant="filled"
                          className="admin-input-design input-placeholder"
                          name={editTimeDateData?.fieldName}
                          onFocus={handleFocus}
                          onChange={(e, value) => {
                            setFieldValue(
                              editTimeDateData?.fieldName,
                              e.target.value
                            );
                          }}
                          onBlur={handleBlur}
                          value={values[editTimeDateData?.fieldName]}
                        />
                      </Index.Box>

                      <Index.Box className="common-button blue-button flex-end save-btn  modal-update-btn">
                        <Index.Button
                          variant="contained"
                          type="submit"
                          className="Particular_model_submit"
                          disabled={isSingleFieldUpdate ? true : false}
                        >
                          Update
                        </Index.Button>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </form>
              )}
            </Formik>
          </Index.Box>
        </Index.DialogContent>
      </Index.Dialog>
    </>
  );
}
export default SingleUpdateTextFieldPopUp;
