import React, { useEffect, useState, useRef } from "react";
import Index from "../../../Index";
import { Formik } from "formik";
import { addParticular, deleteParticular, getParticulars } from "../../../../redux/admin/action";
import { rateValidationSchema } from "../../../../validation/AdminValidation";

const Search = Index.styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: Index.alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: Index.alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const StyledInputBase = Index.styled(Index.InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

const Rate = () => {
  const particularInputRef = useRef(null);
  const dispatch = Index.useDispatch();
  const { particularList } = Index.useSelector((state) => state.AdminReducer);
  // edit state
  const [loading, setLoading] = useState(false);
  const [editdata, setEditdata] = useState();
  const [rowId, setRowId] = useState("");
  // State for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState(0);
  const [paginationPerPage, setPaginationPerPage] = useState(50);

  // State for searching and set data
  const [searchedData, setSearchedData] = useState([]);
  const [currentRows, setCurrentRows] = useState([]);

  //state for delete model open 
   const [deleteOpen, setDeleteOpen] = useState(false);

  // initialValues
  let initialValues = {
    particular: "",
    rate: "",
  };

  // handle edit button
  const handleEdit = (row) => {
    setEditdata(row);
    // focus on first field
      if (particularInputRef.current) {
        particularInputRef.current.focus();
      }
  };

  // edit value
  if (editdata) {
    initialValues = editdata;
  } else {
    initialValues = {
      particular: "",
      rate: "",
    };
  }

  const PER_PAGE = paginationPerPage;
  useEffect(() => {
    //Page count
    const count = Math.ceil(searchedData?.length / PER_PAGE);
    setCount(count);
    const indexOfLastRow = currentPage * PER_PAGE;
    const indexOfFirstRow = indexOfLastRow - PER_PAGE;
    const modifiedRows = searchedData?.slice(indexOfFirstRow, indexOfLastRow);
    modifiedRows.sort((a, b) => a?.particular?.localeCompare(b?.particular));
    
    setCurrentRows(modifiedRows);
    if (modifiedRows?.length === 0 && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  }, [currentPage, searchedData, paginationPerPage, PER_PAGE]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };
  // Pagination calculation end

  // Copy original data to searched data
  useEffect(() => {
    setSearchedData(particularList?.reverse());
  }, [particularList]);

  // Search on table
  const requestSearch = (searched) => {
    if (!searched) return setSearchedData(particularList);
    setSearchedData(
      particularList?.filter(
        (item) =>
          item?.particular
            ?.toString()
            .toLowerCase()
            .includes(searched.toLowerCase()) ||
          item?.rate
            ?.toString()
            .toLowerCase()
            .includes(searched.toLowerCase())
      )
    );
    setCurrentPage(1);
  };

  const handleDeleteOpen = () => {
    setDeleteOpen(true);
  };
  const handleDeleteClose = () => {
    setDeleteOpen(false);
  };
  const handleDeleteRecord = () => {
    setDeleteOpen(false);
    dispatch(deleteParticular(rowId));
  };

  // rate model submit handler
  const handleFormSubmit = (values, { resetForm }) => {
    let data = new URLSearchParams();
    data.append("particular", values?.particular);
    data.append("rate", values?.rate);
    if (editdata) {
      data.append("id", editdata?._id, data);
      resetForm({ values: { particular: "", rate: "" } });
    }
    dispatch(addParticular(data));
    resetForm();
  };

  // get particular list
  const getParticularsList = () => {
    setLoading(true);
    dispatch(getParticulars()).then((res) => {
      if (res?.status === 200) {
        setTimeout(() => {
    setLoading(false); 
        },2000)
      } else {
        setTimeout(() => {
          setLoading(false);
        }, 2000);
      }
    });
    
  }
  useEffect(() => {
    getParticularsList();
  }, []);

  // empty filed on edit canceled
  const handleCancel = () => {
    setEditdata("");
  };

  return (
        <div>
      <Index.DynamicTitle title="GST Rate Management" />
      <div>
        <Index.Box className="barge-common-box mb-20">
        <Index.Box className="title-main">
          <Index.Typography variant="p" component="p" className="page-title">
            GST Rate Management
          </Index.Typography>
        </Index.Box>

        <Index.Box className="title-main mb-10">
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={rateValidationSchema}
            onSubmit={handleFormSubmit}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <form onSubmit={handleSubmit}>
                <Index.Box className="title-main" sx={{ marginTop: "10px" }}>
                  <Index.Grid container spacing={2} className="grid-flex-start">
                    <Index.Grid item xs={12} sm={6} md={3}>
                      {/* <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Particulars
                      </Index.Typography> */}
                      <Index.Box className="input-design-div with-border">
                        <Index.TextField
                          hiddenLabel
                          id="filled-hidden-label-normal"
                          placeholder="Particulars"
                          variant="filled"
                          className="admin-input-design input-placeholder"
                          name="particular"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values?.particular}
                          inputRef={particularInputRef}
                          error={Boolean(
                            errors?.particular ? touched?.particular : undefined
                          )}
                          helperText={
                            touched?.particular ? errors?.particular : undefined
                          }
                        />
                      </Index.Box>
                    </Index.Grid>

                    <Index.Grid item xs={12} sm={6} md={3}>
                      {/* <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        GST Rate
                      </Index.Typography> */}
                      <Index.Box className="input-design-div with-border">
                        <Index.TextField
                          hiddenLabel
                          type="number"
                          id="filled-hidden-label-normal"
                          placeholder="GST Rate"
                          variant="filled"
                          name="rate"
                          className="admin-input-design input-placeholder"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values?.rate}
                          defaultValue={values?.rate}
                          error={Boolean(
                            errors?.rate ? touched?.rate : undefined
                          )}
                          helperText={touched?.rate ? errors?.rate : undefined}
                        />
                      </Index.Box>
                    </Index.Grid>
                    <Index.Grid item xs={12} sm={12} md={6} className="submit-btn-sec">
                      <Index.Box className="common-button blue-button flex-end save-btn flex-col">
                      {/* <Index.Box className="d-flex"> */}
                        <Index.Button
                          variant="contained"
                          type="submit"
                          className="Particular_model_submit"
                        >
                          Submit
                        </Index.Button>

                        {editdata && (
                          <Index.Button
                            variant="contained"
                            type="button"
                            className="Particular_model_submit"
                            onClick={handleCancel}
                          >
                            Cancel
                          </Index.Button>
                        )}
                        {/* <Index.Typography>&nbsp;</Index.Typography> */}
                        </Index.Box>
                      {/* </Index.Box> */}
                    </Index.Grid>
                  </Index.Grid>
                  {/* <Index.Box className="line" mt={3} mb={3}></Index.Box> */}
                </Index.Box>
              </form>
            )}
          </Formik>
        </Index.Box>
        </Index.Box>
        <Index.Box className="barge-common-box">
          <Index.Box className="barge-search-main">
            <Search className="search">
              <StyledInputBase
                placeholder="Search"
                inputProps={{ "aria-label": "search" }}
                onChange={(e) =>
                  setTimeout(() => {
                    requestSearch(e.target.value.trim());
                  }, 1000)
                }
              />
            </Search>
          </Index.Box>
          <Index.TableContainer
            component={Index.Paper}
            className="table-container"
          >
            <Index.Table
              aria-label="simple table"
              className="table-design-main rate-table-design"
            >
              <Index.TableHead>
                <Index.TableRow>
                  <Index.TableCell className="invoice_table_header">
                    Particulars
                  </Index.TableCell>
                  <Index.TableCell
                    align="left"
                    className="invoice_table_header"
                  >
                    Rate
                  </Index.TableCell>

                  <Index.TableCell
                    align="left"
                    className="invoice_table_header"
                  >
                    Action
                  </Index.TableCell>
                </Index.TableRow>
              </Index.TableHead>
              {loading ? (
                <Index.TableBody>
                  <Index.TableRow>
                    <Index.TableCell
                      colSpan={12}
                      align="center"
                      className="loading-purchase"
                    >
                      <Index.CircularProgress color="secondary" size={20} />
                    </Index.TableCell>
                  </Index.TableRow>
                </Index.TableBody>
              ) : (
                <Index.TableBody>
                  {currentRows?.length ? (
                    currentRows?.map((row, index) => (
                      <Index.TableRow key={row?._id}>
                        <Index.TableCell>
                          {row?.particular ? row?.particular : "-"}
                        </Index.TableCell>
                        <Index.TableCell align="left">
                          {row?.rate ? row?.rate : 0}
                        </Index.TableCell>
                        <Index.TableCell>
                          <Index.IconButton
                          // onClick={(e) => {
                          //   handleClickMenu(e, index);
                          // }}
                          >
                            <Index.EditIcon
                              color="primary"
                              onClick={() => handleEdit(row)}
                            />
                            <Index.DeleteIcon
                              color="error"
                              onClick={() => {
                                setRowId(row?._id);
                                handleDeleteOpen();
                              }}
                            />
                          </Index.IconButton>
                        </Index.TableCell>
                      </Index.TableRow>
                    ))
                  ) : (
                    <Index.TableRow>
                      <Index.TableCell
                        component="td"
                        variant="td"
                        scope="row"
                        className="no-data-in-list"
                        colSpan={12}
                        align="center"
                      >
                        No data available
                      </Index.TableCell>
                    </Index.TableRow>
                  )}
                </Index.TableBody>
              )}
            </Index.Table>
          </Index.TableContainer>
        </Index.Box>
        <Index.Box className="pagination-design flex-end">
          <Index.Stack spacing={2}>
            <Index.Box className="pagination-count">
              <Index.Box className="input-design-div with-border">
                <Index.Select
                  fullWidth
                  value={paginationPerPage}
                  onChange={(e) => {
                    setPaginationPerPage(e.target.value);
                  }}
                >
                  <Index.MenuItem value={10}>10</Index.MenuItem>
                  <Index.MenuItem value={25}>25</Index.MenuItem>
                  <Index.MenuItem value={50}>50</Index.MenuItem>
                  <Index.MenuItem value={100}>100</Index.MenuItem>
                </Index.Select>
              </Index.Box>
              <Index.Pagination
                count={count}
                page={currentPage}
                onChange={handlePageChange}
              />
            </Index.Box>
          </Index.Stack>
        </Index.Box>
        <Index.DeleteModal
          deleteOpen={deleteOpen}
          handleDeleteRecord={handleDeleteRecord}
          handleDeleteClose={handleDeleteClose}
        />
      </div>
    </div>
  );
};

export default Rate;
