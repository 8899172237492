import React, { useEffect, useState, useRef, useMemo } from "react";
import Index from "../../../Index";
import { Formik, FieldArray } from "formik";
import * as Yup from "yup";
import AddPartyModel from "../Party/AddPartyModel";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
// import { productData } from "../../../../config/CommonProductsItem";
import {
  getBargeList,
  getParticulars,
  getPortList,
  addInvoice,
  getPartyName,
  getNameOfVessel,
  getNewInvoiceNumber,
} from "../../../../redux/admin/action";
const ITEM_HEIGHT = 50;
const ITEM_PADDING_TOP = 10;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 2.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function InvoiceAdd() {
  const portData = Index.useSelector((state) => state.AdminReducer.portList);
  const bargeData = Index.useSelector((state) => state.AdminReducer.bargeList);
  const login = Index.useSelector((state) => state.AdminReducer.adminDetails);
  const { nameOfVesselList, activeCompany } = Index.useSelector(
    (state) => state.AdminReducer
  );

  const formikRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [vesselNameList, setVesselNameList] = useState([]);
  const [removeRow, setRemoveRow] = useState(false);
  const [qtyStatus, setQtyStatus] = useState(false);
  const [portStatus, setPortStatus] = useState(false);
  const [transportationStatus, setTransportationStatus] = useState(false);
  const [taxableValueStatus, setTaxableValueStatus] = useState(false);
  const [interStateStatus, setInterStateStatus] = useState(false);
  const [particularStatus, setParticularStatus] = useState(false);
  const [partyList, setPartyList] = useState([]);
  const [portList, setPortList] = useState([]);
  const [bargeList, setBargeList] = useState([]);
  const [particularList, setParticularList] = useState([]);
  const [value, setValue] = useState(null);
  const [submitStatus, setSubmitStatus] = useState(true);
  const [latestInvoiceNumber, setLatestInvoiceNumber] = useState("");
  // const [allProductData, setAllProductData] = useState([]);

  useEffect(() => {
    // Add Partyname show selected
    if (formikRef.current) {
      formikRef.current.setFieldValue("name", value ? value?._id : "");
      formikRef.current.setFieldValue("partyId", value ? value?._id : "");
    }
  }, [value]);

  const [partyName, setPartyName] = useState("");
  const [portName, setPortName] = useState("");
  const [transportation, setTransportation] = useState("");

  const dispatch = Index.useDispatch();
  const navigate = Index.useNavigate();
  let { state } = Index.useLocation();
  let editData = state?.row;

  useEffect(() => {
    console.log(editData, "editdata");
    if (editData) {
      editData.id = editData?._id;
      let portIds = editData ? editData?.port?.map((item) => item?._id) : [];
      if (portIds && portIds[0]) {
        editData.port = portIds;
      }

      let bargeIds = editData ? editData?.barges?.map((item) => item?._id) : [];
      if (bargeIds && bargeIds[0]) {
        editData.barges = bargeIds;
      }

      let particularId = editData?.invoiceManagement;
      // console.log(particularId, "particularId");
      if (particularId) {
        particularId?.map((item, index) => {
          // Particular id value set for validation
          return formikRef.current.setFieldValue(
            `invoiceManagement.${index}.particularId`,
            item?.particulars?._id
          );
        });
      }

      setValue(
        partyList[partyList.findIndex((e) => e?._id === editData?.name?._id)]
      );
      setTransportation(editData?.takersOrPipeline);
      setPartyName(editData?.name?.partyName?.toLocaleLowerCase());
      const portDataResult = portData?.filter(
        (item) => item?._id === editData?.port[0]
      ); // Get port name
      if (portDataResult) {
        setPortName(portDataResult[0]?.portName);
      }
    }
  }, [editData, partyList]);

  // initialValues
  let initialValues = {
    invoiceDate: new Date(),
    name: "",
    partyId: "",
    totalTaxableValue: "",
    overDue: "",
    paymentStatus: "Unpaid",
    barges: [],
    port: [],
    month: "",
    vesselName: "",
    PO_No: "",
    PI_No: "",
    dateOfSupply: new Date(),
    BDN: "",
    takersOrPipeline: "",
    exmiOrDelivered: "",
    totalCGSTAmount: "",
    totalSGSTAmount: "",
    totalIGSTAmount: "",
    invoiceAmount: "",
    invoiceManagement: [
      {
        items: "",
        particulars: "",
        particularId: "",
        rate: "",
        interState_intraState: "",
        qtyInMT: "",
        taxableValue: "",
        CGST: "",
        SGST: "",
        IGST: "",
        isEditedQtyMT: false,
        isEditedTaxableValue: false,
        isEditedInterIntraState: false,
        isEditedParticular: false,
      },
    ],
  };
  console.log(editData, "EditData top");
  // condition for manage Add/Edit together
  if (editData) {
    initialValues = editData;
    initialValues.partyId = editData?.name?._id;
    initialValues.port = editData?.port;
    initialValues.totalTaxableValue = editData?.totalTaxableValue;
    initialValues.invoiceManagement =
      editData?.invoiceManagement?.map((item, index) => {
        return {
          ...item,
          isEditedQtyMT: false,
          isEditedTaxableValue: false,
          isEditedInterIntraState: false,
          isEditedParticular: false,
        };
      }) || [];
  } else {
    initialValues = {
      invoiceDate: new Date(),
      name: "",
      partyId: "",
      vesselName: "",
      totalTaxableValue: "",
      overDue: "",
      paymentStatus: "Unpaid",
      barges: [],
      port: [],
      month: "",
      PO_No: "",
      PI_No: "",
      dateOfSupply: new Date(),
      BDN: "",
      takersOrPipeline: "",
      exmiOrDelivered: "",
      totalCGSTAmount: "",
      totalSGSTAmount: "",
      totalIGSTAmount: "",
      invoiceAmount: "",
      invoiceManagement: [
        {
          items: "",
          particulars: "",
          particularId: "",
          rate: "",
          interState_intraState: "",
          qtyInMT: "",
          taxableValue: "",
          CGST: "",
          SGST: "",
          IGST: "",
          isEditedQtyMT: false,
          isEditedTaxableValue: false,
          isEditedInterIntraState: false,
          isEditedParticular: false,
        },
      ],
    };
  }

  const filter = createFilterOptions();

  useEffect(() => {
    const vesselData = nameOfVesselList?.filter((d) =>
      d?.vesselName?.toUpperCase()
    );
    const uniqueArray = vesselData?.filter(
      (v, i, a) => a?.findIndex((t) => t?.vesselName === v?.vesselName) === i
    );
    if (login?.role === "Operation") {
      // Role Operation then vesselName show in uppercase
      const newData = uniqueArray?.sort((a, b) =>
        a?.vesselName?.localeCompare(b?.vesselName)
      );
      setVesselNameList(
        newData?.map((e) => ({
          ...e,
          vesselName: e?.vesselName?.toUpperCase(),
        }))
      );
    } else {
      setVesselNameList(
        uniqueArray?.sort((a, b) => a?.vesselName?.localeCompare(b?.vesselName))
      );
    }
  }, [nameOfVesselList]);

  const handleClose = () => {
    setOpen(false);
  };

  const digitsOnly = (value) =>
    /^\d*(?:\.\d*)?$/.test(value) || value?.length === 0;

  // Validation schema
  const validationSchema = Yup.object().shape({
    invoiceDate: Yup.date()
      .required("Invoice date is required")
      .max(new Date(), "Selected date must be before or equal to today"),
    dateOfSupply: Yup.date()
      .required("Supply date is required")
      .max(new Date(), "Selected date must be before or equal to today"),
    partyId: Yup.string().required("Party Name is required"),
    barges: Yup.array().min(1, "Barge is required"),
    port: Yup.array().min(1, "Port is required"),
    vesselName: Yup.string()
      .required("Vessel Name is required")
      .matches(/^[^\s].*$/, "Space allowed between character")
      .max(30, "Vessel Name allows maximum 30 characters"),
    PO_No: Yup.string()
      .required("PO_No is required")
      .matches(/^\S+$/, "Space not allowed")
      .matches(/^[^0].*/, " PO_No must be more than 0 "),
    PI_No: Yup.string()
      .required("PI_No is required")
      .matches(/^\S+$/, "Space not allowed"),
    BDN: Yup.string()
      .required("BDN is required")
      .matches(
        /^[a-zA-Z0-9/]+$/,
        "Invalid input. Only numbers, characters, and '/' are allowed."
      ),
    takersOrPipeline: Yup.string().required("Tankers or Pipeline is required"),
    exmiOrDelivered: Yup.string().required("Exmi or Delivered is required"),
    invoiceManagement: Yup.array().of(
      Yup.object().shape({
        items: Yup.string().required("Items is required"),
        particularId: Yup.string().required("Particulars is required"),
        interState_intraState: Yup.string().required(
          "Interstate or Intrastate is required"
        ),
        qtyInMT: Yup.string()
          .required("Qty in MT is required")
          .test("qtyInMT", "Qty in MT should have digits only", digitsOnly),
        taxableValue: Yup.number()
          .required("Taxable Value is required")
          .test("taxable", "Taxable Value should have digits only", digitsOnly),
      })
    ),
  });

  const BackBtn = () => {
    navigate("/admin/invoice");
  };

  const handleFormSubmit = (values) => {
    console.log(values, "submit");
    const data = {
      invoiceDate: values?.invoiceDate,
      name: values?.partyId,
      totalTaxableValue: values?.totalTaxableValue,
      invoiceAmount: Math.round(values?.invoiceAmount),

      // totalIGSTAmount: values?.totalIGSTAmount,
      totalIGSTAmount: values?.invoiceManagement?.reduce((start, current) => {
        if (current?.IGST) {
          return start + current?.IGST;
        } else {
          return start + 0;
        }
      }, 0),

      // totalCGSTAmount: values?.totalCGSTAmount,
      totalCGSTAmount: values?.invoiceManagement?.reduce((start, current) => {
        if (current?.CGST) {
          return start + current?.CGST;
        } else {
          return start + 0;
        }
      }, 0),

      // totalSGSTAmount: values?.totalSGSTAmount,
      totalSGSTAmount: values?.invoiceManagement?.reduce((start, current) => {
        if (current?.SGST) {
          return start + current?.SGST;
        } else {
          return start + 0;
        }
      }, 0),

      // totalIGSTAmount: updateAllGST?.IGST,
      // totalCGSTAmount: updateAllGST?.CGST,
      // totalSGSTAmount: updateAllGST?.SGST,
      overDue: Number(values?.overDue),
      paymentStatus: values?.paymentStatus,
      barges: values?.barges,
      invoiceManagement: values?.invoiceManagement,
      port: values?.port,
      month: Index.moment(values?.invoiceDate).format("MMMM YYYY"),
      vesselName: values?.vesselName,
      PO_No: values?.PO_No,
      PI_No: values?.PI_No,
      dateOfSupply: values?.dateOfSupply,
      BDN: values?.BDN,
      takersOrPipeline: values?.takersOrPipeline,
      exmiOrDelivered: values?.exmiOrDelivered,
      companyId: activeCompany?._id,
    };
    if (editData) {
      data.id = editData?._id;
    }
    dispatch(addInvoice(data, navigate));
  };

  // Get Port List
  useEffect(() => {
    dispatch(getParticulars()); // get particulars
    dispatch(getPortList());
    dispatch(getBargeList());
    dispatch(getPartyName()); // Get Party Name
    dispatch(getNameOfVessel()); // Get Party Name
  }, [dispatch]);

  const invoiceNumber = Index.useSelector(
    (state) => state.AdminReducer.invoiceList
  );
  const particularData = Index.useSelector(
    (state) => state.AdminReducer.particularList
  );
  const partyNameList = Index.useSelector(
    (state) => state.AdminReducer.partyNameList
  );

  const InvoiceSort = invoiceNumber;
  const number = Number(InvoiceSort[0]?.invoiceNo.split("/")[2]) + 1;
  const invoiceLatestNumber =
    InvoiceSort[InvoiceSort.length - 1]?.invoiceNo.split("/")[0] +
    "/" +
    InvoiceSort[InvoiceSort.length - 1]?.invoiceNo.split("/")[1] +
    "/" +
    String(number).padStart(3, "0");

  // when the get the inovice numer then used...
  const handleGetInvoiceNumber = (values) => {
    console.log("values", values);
    dispatch(getNewInvoiceNumber(values))
      .then((res) => {
        console.log("invoice number", res);
        if (res.status === 200) {
          setLatestInvoiceNumber(res?.data);
        } else {
          setLatestInvoiceNumber("");
        }
      })
      .catch((error) => {
        setLatestInvoiceNumber("");
        console.error("Invoice number get time error", error);
      });
  };


  // when the page first time load then used....
  useEffect(() => {
    const values = {
      invoiceDate: Index.moment().format("YYYY-MM-DD"),
      companyId: activeCompany?._id,
    };
    handleGetInvoiceNumber(values);
  }, []);

  useEffect(() => {
    setPortList(
      portData?.sort((a, b) => a?.portName?.localeCompare(b?.portName))
    );
    setBargeList(
      bargeData?.sort((a, b) => a?.bargeName?.localeCompare(b?.bargeName))
    );
    // setAllProductData(
    //   productData?.sort((a, b) => a?.item?.localeCompare(b?.item))
    // );
  }, [portData, bargeData]);

  // Get Barge List
  useEffect(() => {
    setPartyList(
      partyNameList?.sort((a, b) => a?.partyName?.localeCompare(b?.partyName))
    );
  }, [partyNameList]);

  useEffect(() => {
    setParticularList(
      particularData?.sort((a, b) =>
        a?.particular?.localeCompare(b?.particular)
      )
    );
  }, [particularData]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handlePoNumber = (setFieldValue, portName) => {
    if (portName) {
      // console.log(portName, 'portname')
      setPortName(portName);
      if (partyName === "iocl") {
        if (
          portName === "Kandla Inner" ||
          portName === "Kandla OTB" ||
          portName === "Tuna"
        ) {
          setFieldValue("PO_No", "70017924");
        } else if (
          portName === "Sikka" ||
          portName === "Vadinar" ||
          portName === "Bedi" ||
          portName === "Navlakhi Inner" ||
          portName === "Navlakhi Outer"
        ) {
          setFieldValue("PO_No", "70018042");
        } else {
          setFieldValue("PO_No", "");
        }
      } else {
        setFieldValue("PO_No", "");
      }
    }
  };

  const handleKeyPress = (event, setFieldValue) => {
    let { value } = event.target;
    if (value > -1 && typeof value === "string") {
      if (value?.split(".")?.length) {
        if (value?.split(".")[1]?.length > 2) {
          value = parseFloat(value).toFixed(2);
          setFieldValue("qtyInMT", value);
        }
      }
    }
  };

  const addZeroes = (event, setFieldValue, index) => {
    let { name, value } = event.target;
    let taxableNameChk = `invoiceManagement.${index}.taxableValue`;
    // console.log(name, value, index, 'zero');
    if (name === taxableNameChk) {
      // Taxable Value add 2 ziro after dot
      const dec = value.split(".")[1];
      const len = dec && dec.length > 2 ? dec.length : 2;
      const decNumber = Number(value).toFixed(len);
      setFieldValue(taxableNameChk, decNumber);
    }
  };

  const addThreeZeroes = (event, setFieldValue, index) => {
    let { name, value } = event.target;
    let qtyNameChk = `invoiceManagement.${index}.qtyInMT`;
    //console.log(name, value, index, qtyNameChk, 'dec');
    if (name === qtyNameChk) {
      // Taxable Value add 2 ziro after dot
      const dec = value.split(".")[1];
      const len = dec && dec.length > 3 ? dec.length : 3;
      const decNumber = Number(value).toFixed(len);
      setFieldValue(qtyNameChk, decNumber);
    }
  };

  const getIoclKanldaPortRate = (qtyInMT) => {
    if (qtyInMT < 101) {
      return 661.54;
    } else if (qtyInMT >= 100 && qtyInMT < 200) {
      return 661.54;
    } else if (qtyInMT >= 200 && qtyInMT < 500) {
      return 202.73;
    } else if (qtyInMT >= 500 && qtyInMT < 1000) {
      return 143.56;
    } else if (qtyInMT >= 1000) {
      return 95.06;
    }
  };

  const getIoclKanldaOTBTunaPortRate = (qtyInMT) => {
    if (qtyInMT < 101) {
      return 879.79;
    } else if (qtyInMT >= 100 && qtyInMT < 200) {
      return 879.79;
    } else if (qtyInMT >= 200 && qtyInMT < 500) {
      return 253.17;
    } else if (qtyInMT >= 500 && qtyInMT < 1000) {
      return 183.33;
    } else if (qtyInMT >= 1000) {
      return 127.07;
    }
  };

  const getIoclOnlyTunaPortRate = (qtyInMT) => {
    if (qtyInMT < 101) {
      return 1490.89;
    } else if (qtyInMT >= 100 && qtyInMT < 200) {
      return 1490.89;
    } else if (qtyInMT >= 200 && qtyInMT < 500) {
      return 253.17;
    } else if (qtyInMT >= 500 && qtyInMT < 1000) {
      return 183.33;
    } else if (qtyInMT >= 1000) {
      return 127.07;
    }
  };

  const getIoclSikkaBediNavlakhiVadinarPortRate = (qtyInMT) => {
    if (qtyInMT < 101) {
      return 2156.85;
    } else if (qtyInMT >= 100 && qtyInMT < 200) {
      return 2156.85;
    } else if (qtyInMT >= 200 && qtyInMT < 500) {
      return 868.74;
    } else if (qtyInMT >= 500 && qtyInMT < 1000) {
      return 676.01;
    } else if (qtyInMT >= 1000) {
      return 521.05;
    }
  };

  const handleTaxableValueCalculate = (
    values,
    setFieldValue,
    partyName = "",
    transportation = "",
    portName = ""
  ) => {
    // console.log(partyName, portName, transportation, "Bharant 476")
    // return false;
    let totalTaxableValue = 0;
    let totalInvoiceAmount = 0;
    let totalCGSTAmount = 0;
    let totalSGSTAmount = 0;
    let totalIGSTAmount = 0;

    if (partyName == "iocl") {
      // IOCL SHEET CALCULATION
      let rate = 0;
      let taxablePrice = 0;
      if (portName == "Kandla Inner") {
        // Kandla Inner
        // console.log('525 1', values?.invoiceManagement);

        values?.invoiceManagement?.map((item, ind) => {
          let qtyInMT = Number(item?.qtyInMT);
          // map function
          const result = particularList?.filter(
            (particularItem) => particularItem?._id === item?.particularId
          );

          let serviceName = "";
          if (result?.length) {
            serviceName = result[0]?.particular;
          }

          if (item?.qtyInMT) {
            // Quantity check and then calculate values
            if (serviceName === "Sea Transportation") {
              //  Service "Sea Transportation" then taxable vlaue is calculate
              let taxableValuePrice = 0;
              if (
                item?.isEditedQtyMT === false &&
                portStatus === false &&
                editData &&
                !transportationStatus
              ) {
                taxableValuePrice = Number(item?.taxableValue).toFixed(2);
              } else if (
                editData == undefined &&
                item?.isEditedTaxableValue === true &&
                !transportationStatus
              ) {
                taxableValuePrice = Number(item?.taxableValue).toFixed(2); // Manuly add taxable value
              } else {
                if (qtyInMT > 200) {
                  taxablePrice = 200 * 661.54;
                  rate = getIoclKanldaPortRate(qtyInMT);
                  taxablePrice = taxablePrice + (qtyInMT - 200) * rate;
                } else {
                  if (qtyInMT >= 100) {
                    taxablePrice = qtyInMT * 661.54;
                  } else {
                    taxablePrice = 100 * 661.54;
                  }
                }
                if (transportation == "Tankers") {
                  taxablePrice = taxablePrice + qtyInMT * 207.58;
                }
                // Calculated taxable value
                taxableValuePrice = Number(taxablePrice).toFixed(2);
              }
              // console.log(taxableValuePrice, 'taxableValuePrice', `invoiceManagement.${ind}.taxableValue`);

              if (item?.interState_intraState == "Intrastate") {
                setFieldValue(
                  `invoiceManagement.${ind}.taxableValue`,
                  taxableValuePrice
                );
                const taxVal = parseFloat(
                  (taxableValuePrice * item?.rate) / 100 / 2
                );
                setFieldValue(`invoiceManagement.${ind}.IGST`, "");
                setFieldValue(`invoiceManagement.${ind}.CGST`, taxVal);
                setFieldValue(`invoiceManagement.${ind}.SGST`, taxVal);
                totalCGSTAmount += Number(taxVal);
                totalSGSTAmount += Number(taxVal);
              }
              if (item?.interState_intraState == "Interstate") {
                setFieldValue(
                  `invoiceManagement.${ind}.taxableValue`,
                  taxableValuePrice
                );
                const taxVal = parseFloat(
                  (taxableValuePrice * item?.rate) / 100
                );
                setFieldValue(`invoiceManagement.${ind}.CGST`, "");
                setFieldValue(`invoiceManagement.${ind}.SGST`, "");
                setFieldValue(`invoiceManagement.${ind}.IGST`, taxVal);
                totalIGSTAmount += Number(taxVal);
              }
              totalTaxableValue += Number(taxableValuePrice); // taxable Value Calculation
            } else {
              // Other Services then taxable value is blank
              if (item?.interState_intraState == "Intrastate") {
                const taxVal = parseFloat(
                  (item?.taxableValue * item?.rate) / 100 / 2
                );
                setFieldValue(`invoiceManagement.${ind}.IGST`, "");
                setFieldValue(`invoiceManagement.${ind}.CGST`, taxVal);
                setFieldValue(`invoiceManagement.${ind}.SGST`, taxVal);
                totalCGSTAmount += Number(taxVal);
                totalSGSTAmount += Number(taxVal);
              }
              if (item?.interState_intraState == "Interstate") {
                const taxVal = parseFloat(
                  (item?.taxableValue * item?.rate) / 100
                );
                setFieldValue(`invoiceManagement.${ind}.CGST`, "");
                setFieldValue(`invoiceManagement.${ind}.SGST`, "");
                setFieldValue(`invoiceManagement.${ind}.IGST`, taxVal);
                totalIGSTAmount += Number(taxVal);
              }
              totalTaxableValue += Number(item?.taxableValue); // taxable Value Calculation
            }
          }
        });
      } else if (
        portName == "Kandla OTB"
        //  || portName == "Tuna"
      ) {
        // Kandla OTB Or Tuna
        values?.invoiceManagement?.map((item, ind) => {
          let qtyInMT = Number(item?.qtyInMT);
          // map function
          const result = particularList?.filter(
            (particularItem) => particularItem?._id === item?.particularId
          );

          let serviceName = "";
          if (result?.length) {
            serviceName = result[0]?.particular;
          }

          if (item?.qtyInMT) {
            // Quantity check and then calculate values
            if (serviceName === "Sea Transportation") {
              // Service "Sea Transportation" then taxable vlaue is calculate
              let taxableValuePrice = 0;
              if (
                item?.isEditedQtyMT === false &&
                portStatus === false &&
                editData &&
                !transportationStatus
              ) {
                taxableValuePrice = Number(item?.taxableValue).toFixed(2);
              } else if (
                editData == undefined &&
                item?.isEditedTaxableValue === true &&
                !transportationStatus
              ) {
                taxableValuePrice = Number(item?.taxableValue).toFixed(2); // Manuly add taxable value
              } else {
                if (qtyInMT > 200) {
                  taxablePrice = 200 * 879.79;
                  rate = getIoclKanldaOTBTunaPortRate(qtyInMT);
                  taxablePrice = taxablePrice + (qtyInMT - 200) * rate;
                } else {
                  if (qtyInMT >= 100) {
                    taxablePrice = qtyInMT * 879.79;
                  } else {
                    taxablePrice = 100 * 879.79;
                  }
                }
                if (transportation == "Tankers") {
                  taxablePrice = taxablePrice + qtyInMT * 207.58;
                }
                // Calculated taxable value
                taxableValuePrice = Number(taxablePrice).toFixed(2);
              }

              if (item?.interState_intraState == "Intrastate") {
                setFieldValue(
                  `invoiceManagement.${ind}.taxableValue`,
                  taxableValuePrice
                );
                const taxVal = parseFloat(
                  (taxableValuePrice * item?.rate) / 100 / 2
                ); // CGST OR SGST CALCULATION
                setFieldValue(`invoiceManagement.${ind}.IGST`, "");
                setFieldValue(`invoiceManagement.${ind}.CGST`, taxVal);
                setFieldValue(`invoiceManagement.${ind}.SGST`, taxVal);
                totalCGSTAmount += Number(taxVal);
                totalSGSTAmount += Number(taxVal);
              }
              if (item?.interState_intraState == "Interstate") {
                setFieldValue(
                  `invoiceManagement.${ind}.taxableValue`,
                  taxableValuePrice
                );
                const taxVal = parseFloat(
                  (taxableValuePrice * item?.rate) / 100
                ); // IGST CALCULATION
                setFieldValue(`invoiceManagement.${ind}.CGST`, "");
                setFieldValue(`invoiceManagement.${ind}.SGST`, "");
                setFieldValue(`invoiceManagement.${ind}.IGST`, taxVal);
                totalIGSTAmount += Number(taxVal);
              }
              totalTaxableValue += Number(taxableValuePrice); // taxable Value Calculation
            } else {
              // Other Services then taxable value is blank
              if (item?.interState_intraState == "Intrastate") {
                const taxVal = parseFloat(
                  (item?.taxableValue * item?.rate) / 100 / 2
                );
                setFieldValue(`invoiceManagement.${ind}.IGST`, "");
                setFieldValue(`invoiceManagement.${ind}.CGST`, taxVal);
                setFieldValue(`invoiceManagement.${ind}.SGST`, taxVal);
                totalCGSTAmount += Number(taxVal);
                totalSGSTAmount += Number(taxVal);
              }
              if (item?.interState_intraState == "Interstate") {
                const taxVal = parseFloat(
                  (item?.taxableValue * item?.rate) / 100
                );
                setFieldValue(`invoiceManagement.${ind}.CGST`, "");
                setFieldValue(`invoiceManagement.${ind}.SGST`, "");
                setFieldValue(`invoiceManagement.${ind}.IGST`, taxVal);
                totalIGSTAmount += Number(taxVal);
              }
              totalTaxableValue += Number(item?.taxableValue); // taxable Value Calculation
              console.log(totalTaxableValue, "totalTaxableValue 494");
            }
          }
        });
      } else if (portName == "Tuna") {
        // Only Tuna
        values?.invoiceManagement?.map((item, ind) => {
          let qtyInMT = Number(item?.qtyInMT);
          // map function
          const result = particularList?.filter(
            (particularItem) => particularItem?._id === item?.particularId
          );

          let serviceName = "";
          if (result?.length) {
            serviceName = result[0]?.particular;
          }

          if (item?.qtyInMT) {
            // Quantity check and then calculate values
            if (serviceName === "Sea Transportation") {
              // Service "Sea Transportation" then taxable vlaue is calculate
              let taxableValuePrice = 0;
              if (
                item?.isEditedQtyMT === false &&
                portStatus === false &&
                editData &&
                !transportationStatus
              ) {
                taxableValuePrice = Number(item?.taxableValue).toFixed(2);
              } else if (
                editData == undefined &&
                item?.isEditedTaxableValue === true &&
                !transportationStatus
              ) {
                taxableValuePrice = Number(item?.taxableValue).toFixed(2); // Manuly add taxable value
              } else {
                if (qtyInMT > 200) {
                  taxablePrice = 200 * 1490.89;
                  rate = getIoclOnlyTunaPortRate(qtyInMT);
                  taxablePrice = taxablePrice + (qtyInMT - 200) * rate;
                } else {
                  if (qtyInMT >= 100) {
                    taxablePrice = qtyInMT * 1490.89;
                  } else {
                    taxablePrice = 100 * 1490.89;
                  }
                }
                if (transportation == "Tankers") {
                  taxablePrice = taxablePrice + qtyInMT * 207.58;
                }
                // Calculated taxable value
                taxableValuePrice = Number(taxablePrice).toFixed(2);
              }

              if (item?.interState_intraState == "Intrastate") {
                setFieldValue(
                  `invoiceManagement.${ind}.taxableValue`,
                  taxableValuePrice
                );
                const taxVal = parseFloat(
                  (taxableValuePrice * item?.rate) / 100 / 2
                ); // CGST OR SGST CALCULATION
                setFieldValue(`invoiceManagement.${ind}.IGST`, "");
                setFieldValue(`invoiceManagement.${ind}.CGST`, taxVal);
                setFieldValue(`invoiceManagement.${ind}.SGST`, taxVal);
                totalCGSTAmount += Number(taxVal);
                totalSGSTAmount += Number(taxVal);
              }
              if (item?.interState_intraState == "Interstate") {
                setFieldValue(
                  `invoiceManagement.${ind}.taxableValue`,
                  taxableValuePrice
                );
                const taxVal = parseFloat(
                  (taxableValuePrice * item?.rate) / 100
                ); // IGST CALCULATION
                setFieldValue(`invoiceManagement.${ind}.CGST`, "");
                setFieldValue(`invoiceManagement.${ind}.SGST`, "");
                setFieldValue(`invoiceManagement.${ind}.IGST`, taxVal);
                totalIGSTAmount += Number(taxVal);
              }
              totalTaxableValue += Number(taxableValuePrice); // taxable Value Calculation
            } else {
              // Other Services then taxable value is blank
              if (item?.interState_intraState == "Intrastate") {
                const taxVal = parseFloat(
                  (item?.taxableValue * item?.rate) / 100 / 2
                );
                setFieldValue(`invoiceManagement.${ind}.IGST`, "");
                setFieldValue(`invoiceManagement.${ind}.CGST`, taxVal);
                setFieldValue(`invoiceManagement.${ind}.SGST`, taxVal);
                totalCGSTAmount += Number(taxVal);
                totalSGSTAmount += Number(taxVal);
              }
              if (item?.interState_intraState == "Interstate") {
                const taxVal = parseFloat(
                  (item?.taxableValue * item?.rate) / 100
                );
                setFieldValue(`invoiceManagement.${ind}.CGST`, "");
                setFieldValue(`invoiceManagement.${ind}.SGST`, "");
                setFieldValue(`invoiceManagement.${ind}.IGST`, taxVal);
                totalIGSTAmount += Number(taxVal);
              }
              totalTaxableValue += Number(item?.taxableValue); // taxable Value Calculation
              console.log(totalTaxableValue, "totalTaxableValue 494");
            }
          }
        });
      } else if (
        portName == "Sikka" ||
        portName == "Bedi" ||
        portName == "Navlakhi Inner" ||
        portName == "Navlakhi Outer" ||
        portName == "Vadinar"
      ) {
        values?.invoiceManagement?.map((item, ind) => {
          let qtyInMT = Number(item?.qtyInMT);
          // map function
          // console.log(particularList, 'particularList');
          const result = particularList?.filter(
            (particularItem) => particularItem?._id === item?.particularId
          );
          let serviceName = "";
          if (result?.length) {
            serviceName = result[0]?.particular;
          }

          if (item?.qtyInMT) {
            // Quantity check and then calculate values
            if (serviceName === "Sea Transportation") {
              //  Service "Sea Transportation" then taxable vlaue is calculate
              let taxableValuePrice = 0;
              if (
                item?.isEditedQtyMT === false &&
                portStatus === false &&
                editData &&
                !transportationStatus
              ) {
                taxableValuePrice = Number(item?.taxableValue).toFixed(2);
              } else if (
                editData == undefined &&
                item?.isEditedTaxableValue === true &&
                !transportationStatus
              ) {
                taxableValuePrice = Number(item?.taxableValue).toFixed(2); // Manuly add taxable value
              } else {
                if (qtyInMT > 200) {
                  taxablePrice = 200 * 2156.85;
                  // Sikka, Bedi, Navlakhi Inner, Navlakhi Outer, Vadinar
                  rate = getIoclSikkaBediNavlakhiVadinarPortRate(qtyInMT);
                  taxablePrice = taxablePrice + (qtyInMT - 200) * rate;
                } else {
                  if (qtyInMT >= 100) {
                    taxablePrice = qtyInMT * 2156.85;
                  } else {
                    taxablePrice = 100 * 2156.85;
                  }
                }
                if (transportation == "Tankers") {
                  taxablePrice = taxablePrice + qtyInMT * 207.26;
                }
                // Calculated taxable value
                taxableValuePrice = Number(taxablePrice).toFixed(2);
              }

              if (item?.interState_intraState == "Intrastate") {
                setFieldValue(
                  `invoiceManagement.${ind}.taxableValue`,
                  taxableValuePrice
                );
                const taxVal = parseFloat(
                  (taxableValuePrice * item?.rate) / 100 / 2
                );
                setFieldValue(`invoiceManagement.${ind}.IGST`, "");
                setFieldValue(`invoiceManagement.${ind}.CGST`, taxVal);
                setFieldValue(`invoiceManagement.${ind}.SGST`, taxVal);
                totalCGSTAmount += Number(taxVal);
                totalSGSTAmount += Number(taxVal);
              }
              if (item?.interState_intraState == "Interstate") {
                setFieldValue(
                  `invoiceManagement.${ind}.taxableValue`,
                  taxableValuePrice
                );
                const taxVal = parseFloat(
                  (taxableValuePrice * item?.rate) / 100
                );
                setFieldValue(`invoiceManagement.${ind}.CGST`, "");
                setFieldValue(`invoiceManagement.${ind}.SGST`, "");
                setFieldValue(`invoiceManagement.${ind}.IGST`, taxVal);
                totalIGSTAmount += Number(taxVal);
              }
              totalTaxableValue += Number(taxableValuePrice); // taxable Value Calculation
            } else {
              // Other Services then taxable value is blank
              if (item?.interState_intraState == "Intrastate") {
                const taxVal = parseFloat(
                  (item?.taxableValue * item?.rate) / 100 / 2
                );
                setFieldValue(`invoiceManagement.${ind}.IGST`, "");
                setFieldValue(`invoiceManagement.${ind}.CGST`, taxVal);
                setFieldValue(`invoiceManagement.${ind}.SGST`, taxVal);
                totalCGSTAmount += Number(taxVal);
                totalSGSTAmount += Number(taxVal);
              }
              if (item?.interState_intraState == "Interstate") {
                const taxVal = parseFloat(
                  (item?.taxableValue * item?.rate) / 100
                );
                setFieldValue(`invoiceManagement.${ind}.CGST`, "");
                setFieldValue(`invoiceManagement.${ind}.SGST`, "");
                setFieldValue(`invoiceManagement.${ind}.IGST`, taxVal);
                totalIGSTAmount += Number(taxVal);
              }
              totalTaxableValue += Number(item?.taxableValue); // taxable Value Calculation
            }
          }
        });
      }
    } else {
      console.log(partyName, "party else");
      // REST All EXML SHEET CALCULATION
      let rate = 0;
      let taxableAmount = 0;
      if (portName == "Kandla Inner") {
        // Kandla Inner
        values?.invoiceManagement?.map((item, ind) => {
          let qtyInMT = Number(item?.qtyInMT);
          // map function
          // console.log(particularList, 'particularList');
          const result = particularList?.filter(
            (particularItem) => particularItem?._id === item?.particularId
          );
          let serviceName = "";
          if (result?.length) {
            serviceName = result[0]?.particular;
          }

          if (item?.qtyInMT) {
            // Quantity check and then calculate values
            if (serviceName === "Sea Transportation") {
              // Service "Sea Transportation" then taxable vlaue is calculate
              let taxableValuePrice = 0;
              if (
                item?.isEditedQtyMT === false &&
                portStatus === false &&
                editData &&
                !transportationStatus
              ) {
                taxableValuePrice = Number(item?.taxableValue).toFixed(2);
              } else if (
                editData == undefined &&
                item?.isEditedTaxableValue === true &&
                !transportationStatus
              ) {
                taxableValuePrice = Number(item?.taxableValue).toFixed(2); // Manuly add taxable value
              } else {
                if (qtyInMT > 1000) {
                  taxableAmount = 200 * 892.224 + 300 * 352.464 + 500 * 281.976;
                  taxableAmount += (qtyInMT - 1000) * 225.576;
                } else if (qtyInMT > 500) {
                  taxableAmount = 200 * 892.224 + 300 * 352.464;
                  taxableAmount += (qtyInMT - 500) * 281.976;
                } else if (qtyInMT > 200) {
                  taxableAmount = 200 * 892.224;
                  taxableAmount += (qtyInMT - 200) * 352.464;
                } else {
                  if (qtyInMT >= 100) {
                    taxableAmount = qtyInMT * 892.224;
                  } else {
                    taxableAmount = 100 * 892.224;
                  }
                }
                if (transportation == "Tankers") {
                  taxableAmount = taxableAmount + qtyInMT * 253.296;
                }
                // Calculated taxable value
                taxableValuePrice = Number(taxableAmount).toFixed(2);
              }

              if (item?.interState_intraState == "Intrastate") {
                setFieldValue(
                  `invoiceManagement.${ind}.taxableValue`,
                  taxableValuePrice
                );
                const taxVal = parseFloat(
                  (taxableValuePrice * item?.rate) / 100 / 2
                );
                setFieldValue(`invoiceManagement.${ind}.IGST`, "");
                setFieldValue(`invoiceManagement.${ind}.CGST`, taxVal);
                setFieldValue(`invoiceManagement.${ind}.SGST`, taxVal);
                totalCGSTAmount += Number(taxVal);
                totalSGSTAmount += Number(taxVal);
              }
              if (item?.interState_intraState == "Interstate") {
                setFieldValue(
                  `invoiceManagement.${ind}.taxableValue`,
                  taxableValuePrice
                );
                const taxVal = parseFloat(
                  (taxableValuePrice * item?.rate) / 100
                );
                setFieldValue(`invoiceManagement.${ind}.CGST`, "");
                setFieldValue(`invoiceManagement.${ind}.SGST`, "");
                setFieldValue(`invoiceManagement.${ind}.IGST`, taxVal);
                totalIGSTAmount += Number(taxVal);
              }
              totalTaxableValue += Number(taxableValuePrice); // taxable Value Calculation
            } else {
              // Other Services then taxable value is blank
              if (item?.interState_intraState == "Intrastate") {
                const taxVal = parseFloat(
                  (item?.taxableValue * item?.rate) / 100 / 2
                );
                setFieldValue(`invoiceManagement.${ind}.IGST`, "");
                setFieldValue(`invoiceManagement.${ind}.CGST`, taxVal);
                setFieldValue(`invoiceManagement.${ind}.SGST`, taxVal);
                totalCGSTAmount += Number(taxVal);
                totalSGSTAmount += Number(taxVal);
              }
              if (item?.interState_intraState == "Interstate") {
                const taxVal = parseFloat(
                  (item?.taxableValue * item?.rate) / 100
                );
                setFieldValue(`invoiceManagement.${ind}.CGST`, "");
                setFieldValue(`invoiceManagement.${ind}.SGST`, "");
                setFieldValue(`invoiceManagement.${ind}.IGST`, taxVal);
                totalIGSTAmount += Number(taxVal);
              }
              totalTaxableValue += Number(item?.taxableValue); // taxable Value Calculation
            }
          }
        });
      } else if (portName == "Kandla OTB" || portName == "Tuna") {
        // Kandla OTB Or Tuna
        values?.invoiceManagement?.map((item, ind) => {
          let qtyInMT = Number(item?.qtyInMT);
          // map function
          const result = particularList?.filter(
            (particularItem) => particularItem?._id === item?.particularId
          );
          let serviceName = "";
          if (result?.length) {
            serviceName = result[0]?.particular;
          }

          if (item?.qtyInMT) {
            // Quantity check and then calculate values
            if (serviceName === "Sea Transportation") {
              // Service "Sea Transportation" then taxable vlaue is calculate
              let taxableValuePrice = 0;
              if (
                item?.isEditedQtyMT === false &&
                portStatus === false &&
                editData &&
                !transportationStatus
              ) {
                taxableValuePrice = Number(item?.taxableValue).toFixed(2);
              } else if (
                editData == undefined &&
                item?.isEditedTaxableValue === true &&
                !transportationStatus
              ) {
                taxableValuePrice = Number(item?.taxableValue).toFixed(2); // Manuly add taxable value
              } else {
                if (qtyInMT > 1000) {
                  taxableAmount = 200 * 1150.2 + 300 * 411.216 + 500 * 328.968;
                  taxableAmount += (qtyInMT - 1000) * 263.184;
                } else if (qtyInMT > 500) {
                  taxableAmount = 200 * 1150.2 + 300 * 411.216;
                  taxableAmount += (qtyInMT - 500) * 328.968;
                } else if (qtyInMT > 200) {
                  taxableAmount = 200 * 1150.2;
                  taxableAmount += (qtyInMT - 200) * 411.216;
                } else {
                  if (qtyInMT >= 100) {
                    taxableAmount = qtyInMT * 1150.2;
                  } else {
                    taxableAmount = 100 * 1150.2;
                  }
                }
                if (transportation == "Tankers") {
                  taxableAmount = taxableAmount + qtyInMT * 253.296;
                }
                // Calculated taxable value
                taxableValuePrice = Number(taxableAmount).toFixed(2);
              }
              if (item?.interState_intraState == "Intrastate") {
                setFieldValue(
                  `invoiceManagement.${ind}.taxableValue`,
                  taxableValuePrice
                );
                const taxVal = parseFloat(
                  (taxableValuePrice * item?.rate) / 100 / 2
                );
                setFieldValue(`invoiceManagement.${ind}.IGST`, "");
                setFieldValue(`invoiceManagement.${ind}.CGST`, taxVal);
                setFieldValue(`invoiceManagement.${ind}.SGST`, taxVal);
                totalCGSTAmount += Number(taxVal);
                totalSGSTAmount += Number(taxVal);
              }
              if (item?.interState_intraState == "Interstate") {
                setFieldValue(
                  `invoiceManagement.${ind}.taxableValue`,
                  taxableValuePrice
                );
                const taxVal = parseFloat(
                  (taxableValuePrice * item?.rate) / 100
                );
                setFieldValue(`invoiceManagement.${ind}.CGST`, "");
                setFieldValue(`invoiceManagement.${ind}.SGST`, "");
                setFieldValue(`invoiceManagement.${ind}.IGST`, taxVal);
                totalIGSTAmount += Number(taxVal);
              }
              totalTaxableValue += Number(taxableValuePrice); // taxable Value Calculation
            } else {
              // Other Services then taxable value is blank
              if (item?.interState_intraState == "Intrastate") {
                const taxVal = parseFloat(
                  (item?.taxableValue * item?.rate) / 100 / 2
                );
                setFieldValue(`invoiceManagement.${ind}.IGST`, "");
                setFieldValue(`invoiceManagement.${ind}.CGST`, taxVal);
                setFieldValue(`invoiceManagement.${ind}.SGST`, taxVal);
                totalCGSTAmount += Number(taxVal);
                totalSGSTAmount += Number(taxVal);
              }
              if (item?.interState_intraState == "Interstate") {
                const taxVal = parseFloat(
                  (item?.taxableValue * item?.rate) / 100
                );
                setFieldValue(`invoiceManagement.${ind}.CGST`, "");
                setFieldValue(`invoiceManagement.${ind}.SGST`, "");
                setFieldValue(`invoiceManagement.${ind}.IGST`, taxVal);
                totalIGSTAmount += Number(taxVal);
              }
              totalTaxableValue += Number(item?.taxableValue); // taxable Value Calculation
            }
          }
        });
      } else if (
        portName == "Sikka" ||
        portName == "Bedi" ||
        portName == "Navlakhi Inner" ||
        portName == "Navlakhi Outer" ||
        portName == "Vadinar"
      ) {
        // Sikka, Bedi, Navlakhi Inner, Navlakhi Outer, Vadinar
        values?.invoiceManagement?.map((item, ind) => {
          let qtyInMT = Number(item?.qtyInMT);
          // map function
          // console.log(particularList, 'particularList');
          const result = particularList?.filter(
            (particularItem) => particularItem?._id === item?.particularId
          );
          let serviceName = "";
          if (result?.length) {
            serviceName = result[0]?.particular;
          }

          if (item?.qtyInMT) {
            // Quantity check and then calculate values
            if (serviceName === "Sea Transportation") {
              // Service Road Transp. then taxable vlaue is blank
              let taxableValuePrice = 0;
              if (
                item?.isEditedQtyMT === false &&
                portStatus === false &&
                editData &&
                !transportationStatus
              ) {
                taxableValuePrice = Number(item?.taxableValue).toFixed(2);
              } else if (
                editData == undefined &&
                item?.isEditedTaxableValue === true &&
                !transportationStatus
              ) {
                taxableValuePrice = Number(item?.taxableValue).toFixed(2); // Manuly add taxable value
              } else {
                if (qtyInMT > 1000) {
                  taxableAmount =
                    200 * 2657.736 + 300 * 1138.224 + 500 * 910.572;
                  taxableAmount += (qtyInMT - 1000) * 728.46;
                } else if (qtyInMT > 500) {
                  taxableAmount = 200 * 2657.736 + 300 * 1138.224;
                  taxableAmount += (qtyInMT - 500) * 910.572;
                } else if (qtyInMT > 200) {
                  taxableAmount = 200 * 2657.736;
                  taxableAmount += (qtyInMT - 200) * 1138.224;
                } else {
                  if (qtyInMT >= 100) {
                    taxableAmount = qtyInMT * 2657.736;
                  } else {
                    taxableAmount = 100 * 2657.736;
                  }
                }
                if (transportation == "Tankers") {
                  taxableAmount = taxableAmount + qtyInMT * 253.296;
                }
                // Calculated taxable value
                taxableValuePrice = Number(taxableAmount).toFixed(2);
              }

              if (item?.interState_intraState == "Intrastate") {
                setFieldValue(
                  `invoiceManagement.${ind}.taxableValue`,
                  taxableValuePrice
                );
                const taxVal = parseFloat(
                  (taxableValuePrice * item?.rate) / 100 / 2
                );
                setFieldValue(`invoiceManagement.${ind}.IGST`, "");
                setFieldValue(`invoiceManagement.${ind}.CGST`, taxVal);
                setFieldValue(`invoiceManagement.${ind}.SGST`, taxVal);
                totalCGSTAmount += Number(taxVal);
                totalSGSTAmount += Number(taxVal);
              }
              if (item?.interState_intraState == "Interstate") {
                setFieldValue(
                  `invoiceManagement.${ind}.taxableValue`,
                  taxableValuePrice
                );
                const taxVal = parseFloat(
                  (taxableValuePrice * item?.rate) / 100
                );
                setFieldValue(`invoiceManagement.${ind}.CGST`, "");
                setFieldValue(`invoiceManagement.${ind}.SGST`, "");
                setFieldValue(`invoiceManagement.${ind}.IGST`, taxVal);
                totalIGSTAmount += Number(taxVal);
              }
              totalTaxableValue += Number(taxableValuePrice); // taxable Value Calculation
            } else {
              // Other Services then taxable value is blank
              if (item?.interState_intraState == "Intrastate") {
                const taxVal = parseFloat(
                  (item?.taxableValue * item?.rate) / 100 / 2
                );
                setFieldValue(`invoiceManagement.${ind}.IGST`, "");
                setFieldValue(`invoiceManagement.${ind}.CGST`, taxVal);
                setFieldValue(`invoiceManagement.${ind}.SGST`, taxVal);
                totalCGSTAmount += Number(taxVal);
                totalSGSTAmount += Number(taxVal);
              }
              if (item?.interState_intraState == "Interstate") {
                const taxVal = parseFloat(
                  (item?.taxableValue * item?.rate) / 100
                );
                setFieldValue(`invoiceManagement.${ind}.CGST`, "");
                setFieldValue(`invoiceManagement.${ind}.SGST`, "");
                setFieldValue(`invoiceManagement.${ind}.IGST`, taxVal);
                totalIGSTAmount += Number(taxVal);
              }
              totalTaxableValue += Number(item?.taxableValue); // taxable Value Calculation
            }
          }
        });
      }
    }

    if (
      values?.invoiceManagement?.length &&
      values?.invoiceManagement[0]?.taxableValue != ""
    ) {
      // console.log('1019', values?.invoiceManagement, formikRef?.current?.values.invoiceManagement);
      // Total Invoice Amount Calculation
      // for (let i = 0; i < values?.invoiceManagement?.length; i++) {

      //   // Interstate Calculation
      //   totalIGSTAmount += values?.invoiceManagement[i]?.IGST
      //     ? Number(values?.invoiceManagement[i]?.IGST.toFixed(2))
      //     : 0;
      //   // Intrastate Calculation
      //   totalCGSTAmount += values?.invoiceManagement[i]?.CGST
      //     ? Number(values?.invoiceManagement[i]?.CGST.toFixed(2))
      //     : 0;
      //   totalSGSTAmount += values?.invoiceManagement[i]?.SGST
      //     ? Number(values?.invoiceManagement[i]?.SGST.toFixed(2))
      //     : 0;

      //   // if (
      //   //   values?.invoiceManagement[i]?.interState_intraState == "Interstate"
      //   // ) {
      //   //   // Interstate Calculation
      //   //   totalIGSTAmount += values?.invoiceManagement[i]?.IGST
      //   //     ? Number(values?.invoiceManagement[i]?.IGST.toFixed(2))
      //   //     : 0;
      //   // }
      //   // if (
      //   //   values?.invoiceManagement[i]?.interState_intraState == "Intrastate"
      //   // ) {
      //   //   // Intrastate Calculation
      //   //   totalCGSTAmount += values?.invoiceManagement[i]?.CGST
      //   //     ? Number(values?.invoiceManagement[i]?.CGST.toFixed(2))
      //   //     : 0;
      //   //   totalSGSTAmount += values?.invoiceManagement[i]?.SGST
      //   //     ? Number(values?.invoiceManagement[i]?.SGST.toFixed(2))
      //   //     : 0;
      //   // }
      // }

      setFieldValue("totalTaxableValue", totalTaxableValue.toFixed(2));
      setFieldValue("totalIGSTAmount", totalIGSTAmount);
      setFieldValue("totalCGSTAmount", totalCGSTAmount);
      setFieldValue("totalSGSTAmount", totalSGSTAmount);
      setSubmitStatus(true);
      totalInvoiceAmount =
        parseFloat(totalTaxableValue.toFixed(2)) +
        parseFloat(totalIGSTAmount) +
        parseFloat(totalCGSTAmount) +
        parseFloat(totalSGSTAmount);
      setFieldValue("invoiceAmount", Math.round(totalInvoiceAmount));
    }
    // console.log(
    //   values,
    //   "bharat 123",
    //   values?.invoiceManagement?.length,
    //   formikRef
    // );
  };

  useEffect(() => {
    //Invoice row removed then function called and calculation updated
    setRemoveRow(false);
    if (
      taxableValueStatus ||
      interStateStatus ||
      particularStatus ||
      qtyStatus
    ) {
      setTaxableValueStatus(false);
      setInterStateStatus(false);
      setParticularStatus(false);
      setQtyStatus(false);
      setPortStatus(false);
      setTransportationStatus(false);
    }

    // console.log(transportationStatus, formikRef?.current?.values.invoiceManagement, '1071')
    if (portStatus || transportationStatus) {
      // When port update then every time there fields are false and calculation start from first
      // console.log('Inside if', '1071')
      formikRef?.current?.values.invoiceManagement.map((item, index) => {
        formikRef?.current?.setFieldValue(
          `invoiceManagement.${index}.isEditedQtyMT`,
          false
        );
        formikRef?.current?.setFieldValue(
          `invoiceManagement.${index}.isEditedTaxableValue`,
          false
        );
        formikRef?.current?.setFieldValue(
          `invoiceManagement.${index}.isEditedInterIntraState`,
          false
        );
        formikRef?.current?.setFieldValue(
          `invoiceManagement.${index}.isEditedParticular`,
          false
        );
      });
    }
    // console.log(portName, " = portName =", formikRef?.current?.values);
    handleTaxableValueCalculate(
      formikRef?.current?.values,
      formikRef?.current?.setFieldValue,
      partyName,
      transportation,
      portName
    );
  }, [
    removeRow,
    qtyStatus,
    taxableValueStatus,
    portName,
    transportation,
    interStateStatus,
    particularStatus,
  ]);

  return (
    <div>
      <Index.Box className="barge-common-box">
        <Index.Box className="">
          <Index.Box className="title-header">
            <Index.Box className="title-header-flex">
              <Index.Box className="title-main mb-10 invoice_number">
                <Index.Box>
                  <Index.Typography
                    variant="p"
                    component="p"
                    className="page-title"
                  >
                    {editData?._id ? "Edit" : "Add"} Invoice :
                  </Index.Typography>
                </Index.Box>
                <Index.Box>
                  <Index.Typography
                    variant="p"
                    component="p"
                    className="page-title invoice_number_align"
                  >
                    {/* {editData?._id ? editData?.invoiceNo : invoiceLatestNumber} */}
                    {editData?._id ? editData?.invoiceNo : latestInvoiceNumber}
                  </Index.Typography>
                </Index.Box>
              </Index.Box>
              <Index.Box className="common-button blue-button save-btn ">
                <Index.Button
                  variant="contained"
                  type="submit"
                  className="Particular_model_submit ml-0"
                  onClick={BackBtn}
                >
                  Back
                </Index.Button>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleFormSubmit}
            innerRef={formikRef} // This line binds the formikRef to the Formik instance
          >
            {({
              values,
              errors,
              touched,
              setFieldValue,
              setFieldTouched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <form onSubmit={handleSubmit}>
                {console.log(values, "valuesss")}
                <Index.Box className="">
                  <Index.Grid container spacing={2}>
                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Invoice Date
                      </Index.Typography>
                      <Index.Box className="input-design-div">
                        <Index.Box className="set-date set-date-picker">
                          <Index.LocalizationProvider
                            dateAdapter={Index.AdapterDayjs}
                          >
                            <Index.DemoContainer
                              components={["DatePicker", "DatePicker"]}
                            >
                              <Index.DemoItem label="">
                                <Index.DatePicker
                                  fullWidth
                                  format="DD/MM/YYYY"
                                  name="invoiceDate"
                                  disableFuture
                                  // maxDate={Index.dayjs(new Date())}
                                  // defaultValue={Index.dayjs(
                                  //   values?.invoiceDate
                                  // )}
                                  value={Index.dayjs(
                                    values?.invoiceDate
                                      ? values?.invoiceDate
                                      : new Date()
                                  )}
                                  onChange={(value) => {
                                    const NewDate = Index.moment(
                                      value.$d
                                    ).format("YYYY-MM-DD");
                                    const currentDate = Index.moment(
                                      new Date()
                                    ).format("YYYY-MM-DD");
                                    const diff = Index.moment(currentDate).diff(
                                      NewDate,
                                      "days"
                                    );
                                    setFieldValue("invoiceDate", NewDate);

                                    // here is calling api for getting new invoice number

                                    handleGetInvoiceNumber({
                                      invoiceDate: NewDate,
                                    });

                                    setFieldValue("overDue", diff);
                                    let data = {
                                      target: {
                                        name: "invoiceDate",
                                        value: NewDate,
                                      },
                                    };
                                    handleChange(data);
                                  }}
                                />
                              </Index.DemoItem>
                            </Index.DemoContainer>
                          </Index.LocalizationProvider>
                          {touched.invoiceDate && errors.invoiceDate && (
                            <Index.FormHelperText error>
                              {errors.invoiceDate}
                            </Index.FormHelperText>
                          )}
                        </Index.Box>
                      </Index.Box>
                    </Index.Grid>

                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Box sx={{ display: "flex" }}>
                        <Index.Box>
                          <Index.Typography
                            variant="label"
                            component="label"
                            className="input-label"
                          >
                            Party Name
                          </Index.Typography>
                        </Index.Box>
                        <Index.Box>
                          <Index.Button
                            type="button"
                            sx={{ marginTop: "-13px" }}
                            tabIndex="-1"
                            onClick={() => handleOpen()}
                          >
                            <Index.AddCircleIcon />
                          </Index.Button>
                        </Index.Box>
                      </Index.Box>
                      <Index.Box className="input-design-div with-border">
                        <Index.Autocomplete
                          disablePortal
                          className="invoice-select-drop"
                          id="combo-box-demo"
                          options={partyList}
                          value={value}
                          getOptionLabel={(option) => option.partyName}
                          renderOption={(props, option) => (
                            <Index.Box
                              component="li"
                              sx={{
                                "& > img": { mr: 2, flexShrink: 0 },
                              }}
                              {...props}
                            >
                              {option?.partyName}
                            </Index.Box>
                          )}
                          onChange={(event, newValue) => {
                            setFieldValue("port", []);
                            setPortName("");
                            setFieldValue("PO_No", "");
                            setFieldValue("partyId", newValue?._id);
                            setFieldValue("name", newValue?._id);
                            setPartyName(
                              newValue?.partyName.toLocaleLowerCase()
                            );
                            setValue(newValue);
                          }}
                          renderInput={(params) => (
                            <Index.TextField
                              {...params}
                              placeholder="Party name"
                              variant="outlined"
                            />
                          )}
                        />
                        {touched.partyId && errors.partyId && (
                          <Index.FormHelperText error>
                            {errors.partyId}
                          </Index.FormHelperText>
                        )}
                      </Index.Box>
                    </Index.Grid>

                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        BDN
                      </Index.Typography>
                      <Index.Box className="input-design-div with-border">
                        <Index.TextField
                          hiddenLabel
                          id="filled-hidden-label-normal"
                          placeholder="BDN"
                          variant="filled"
                          className="admin-input-design input-placeholder"
                          name="BDN"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values?.BDN}
                          error={Boolean(errors.BDN ? touched.BDN : undefined)}
                          helperText={touched.BDN ? errors.BDN : undefined}
                        />
                      </Index.Box>
                    </Index.Grid>
                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        PI_No
                      </Index.Typography>
                      <Index.Box className="input-design-div with-border">
                        <Index.TextField
                          hiddenLabel
                          id="filled-hidden-label-normal"
                          placeholder="PI_no"
                          variant="filled"
                          className="admin-input-design input-placeholder"
                          name="PI_No"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values?.PI_No}
                          error={Boolean(
                            errors.PI_No ? touched.PI_No : undefined
                          )}
                          helperText={touched.PI_No ? errors.PI_No : undefined}
                        />
                      </Index.Box>
                    </Index.Grid>
                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Exmi Or Delivered
                      </Index.Typography>
                      <Index.Box className="input-design-div with-border">
                        <Index.Select
                          className="placeholder-set-text-box"
                          fullWidth
                          name="exmiOrDelivered"
                          displayEmpty
                          inputProps={{ "aria-label": "Without label" }}
                          defaultValue={
                            values?.exmiOrDelivered
                              ? values?.exmiOrDelivered
                              : ""
                          }
                          onChange={(e) => {
                            setFieldValue("exmiOrDelivered", e.target.value);
                          }}
                        >
                          <Index.MenuItem value="">
                            <Index.MenuItem
                              disabled
                              className="all-select-label"
                            >
                              Select exmi or delivered
                            </Index.MenuItem>
                          </Index.MenuItem>
                          <Index.MenuItem value="Delivered">
                            Delivered
                          </Index.MenuItem>
                          <Index.MenuItem value="Exmi">Exmi</Index.MenuItem>
                          <Index.MenuItem value="NA">NA</Index.MenuItem>
                        </Index.Select>
                        {touched?.exmiOrDelivered &&
                          errors?.exmiOrDelivered && (
                            <Index.FormHelperText error>
                              {errors?.exmiOrDelivered}
                            </Index.FormHelperText>
                          )}
                      </Index.Box>
                    </Index.Grid>
                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Tankers Or Pipeline
                      </Index.Typography>
                      <Index.Box className="input-design-div with-border">
                        <Index.Select
                          className="placeholder-set-text-box"
                          fullWidth
                          displayEmpty
                          name="takersOrPipeline"
                          defaultValue={
                            values?.takersOrPipeline
                              ? values?.takersOrPipeline
                              : ""
                          }
                          onChange={(e) => {
                            setTransportationStatus(true);
                            setFieldValue("takersOrPipeline", e.target.value);
                            setTransportation(e.target.value);
                          }}
                        >
                          <Index.MenuItem value="">
                            <Index.MenuItem
                              disabled
                              className="all-select-label"
                            >
                              Select tankers or pipeline
                            </Index.MenuItem>
                          </Index.MenuItem>
                          <Index.MenuItem value="Pipeline">
                            Pipeline
                          </Index.MenuItem>
                          <Index.MenuItem value="Tankers">
                            Tankers
                          </Index.MenuItem>
                          <Index.MenuItem value="NA">NA</Index.MenuItem>
                        </Index.Select>
                        {touched.takersOrPipeline &&
                          errors.takersOrPipeline && (
                            <Index.FormHelperText error>
                              {errors.takersOrPipeline}
                            </Index.FormHelperText>
                          )}
                      </Index.Box>
                    </Index.Grid>

                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Port
                      </Index.Typography>
                      <Index.Box className="input-design-div with-border">
                        {editData?._id && values?.port ? (
                          portList?.length && (
                            <Index.Autocomplete
                              disablePortal
                              className="invoice-select-drop"
                              id="combo-box-demo"
                              options={portList}
                              getOptionLabel={(option) => option?.portName}
                              value={
                                portList[
                                  portList.findIndex(
                                    (e) => e._id === values?.port[0]
                                  )
                                ]
                              }
                              renderOption={(props, option) => (
                                <Index.Box
                                  component="li"
                                  sx={{
                                    "& > img": { mr: 2, flexShrink: 0 },
                                  }}
                                  {...props}
                                >
                                  {option?.portName}
                                </Index.Box>
                              )}
                              onChange={(event, newValue) => {
                                setPortStatus(true);
                                setFieldValue("port", [newValue?._id]);
                                handlePoNumber(
                                  setFieldValue,
                                  newValue?.portName
                                );
                              }}
                              renderInput={(params) => (
                                <Index.TextField
                                  {...params}
                                  placeholder="Port name"
                                  variant="outlined"
                                />
                              )}
                            />
                          )
                        ) : (
                          <Index.Autocomplete
                            disablePortal
                            className="invoice-select-drop"
                            id="combo-box-demo"
                            options={portList}
                            value={
                              portList[
                                portList.findIndex(
                                  (e) => e._id === values?.port[0]
                                )
                              ] || null
                            }
                            getOptionLabel={(option) => option?.portName}
                            renderOption={(props, option) => (
                              <Index.Box
                                component="li"
                                sx={{
                                  "& > img": { mr: 2, flexShrink: 0 },
                                }}
                                {...props}
                              >
                                {option?.portName}
                              </Index.Box>
                            )}
                            onChange={(event, newValue) => {
                              setPortStatus(true);
                              setFieldValue("port", [newValue?._id]);
                              handlePoNumber(setFieldValue, newValue?.portName);
                            }}
                            renderInput={(params) => (
                              <Index.TextField
                                {...params}
                                placeholder="Port name"
                                variant="outlined"
                              />
                            )}
                          />
                        )}

                        {touched.port && errors.port && (
                          <Index.FormHelperText error>
                            {errors.port}
                          </Index.FormHelperText>
                        )}
                      </Index.Box>
                    </Index.Grid>
                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        PO_No
                      </Index.Typography>
                      <Index.Box className="input-design-div with-border">
                        <Index.TextField
                          hiddenLabel
                          id="filled-hidden-label-normal"
                          placeholder="PO_no"
                          variant="filled"
                          className="admin-input-design input-placeholder"
                          name="PO_No"
                          // type="number"
                          // onChange={handleChange}
                          onChange={(event) => {
                            if (event.target.value.length <= 10) {
                              let data = {
                                target: {
                                  name: "PO_No",
                                  value: event.target.value,
                                },
                              };
                              handleChange(data);
                            }
                          }}
                          onBlur={handleBlur}
                          value={values?.PO_No}
                          error={Boolean(
                            errors.PO_No ? touched.PO_No : undefined
                          )}
                          helperText={touched.PO_No ? errors.PO_No : undefined}
                        />
                      </Index.Box>
                    </Index.Grid>

                    {/* <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Name Of Vessel
                      </Index.Typography>
                      <Index.Box className="input-design-div with-border">
                        <Index.TextField
                          hiddenLabel
                          id="filled-hidden-label-normal"
                          placeholder="Name of vessel"
                          variant="filled"
                          className="admin-input-design input-placeholder"
                          name="vesselName"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values?.vesselName}
                          error={Boolean(
                            errors.vesselName ? touched.vesselName : undefined
                          )}
                          helperText={
                            touched.vesselName ? errors.vesselName : undefined
                          }
                        />
                      </Index.Box>
                    </Index.Grid> */}

                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Vessel Name
                      </Index.Typography>
                      <Index.Box className="input-design-div with-border">
                        <Autocomplete
                          className="invoice-select-drop enquiry-vessel-autocomplete"
                          fullWidth
                          value={values?.vesselName}
                          onChange={(event, newValue) => {
                            if (typeof newValue === "string") {
                              setFieldValue("vesselName", newValue);
                            } else if (newValue && newValue?.inputValue) {
                              setFieldValue("vesselName", newValue?.inputValue);
                            } else {
                              setFieldValue("vesselName", newValue?.vesselName);
                            }
                          }}
                          onBlur={(event, newValue) => {
                            setFieldValue("vesselName", event.target.value);
                          }}
                          filterOptions={(options, params) => {
                            const filtered = filter(options, params);
                            const { inputValue } = params;

                            // Suggest the creation of a new value
                            const isExisting = options?.some(
                              (option) => inputValue === option?.vesselName
                            );

                            if (inputValue !== "" && !isExisting) {
                              filtered.push({
                                inputValue,
                                vesselName: `Add "${inputValue}"`,
                              });
                            }

                            return filtered;
                          }}
                          selectOnFocus
                          clearOnBlur
                          handleHomeEndKeys
                          id="free-solo-with-text-demo"
                          options={vesselNameList}
                          getOptionLabel={(option) => {
                            // Value selected with enter, right from the input
                            if (typeof option === "string") {
                              return option;
                            }
                            // Add "xyz" option created dynamically
                            if (option?.inputValue) {
                              return option?.inputValue;
                            }
                            // Regular option
                            return option?.vesselName;
                          }}
                          renderOption={(props, option) => (
                            <li {...props}>{option?.vesselName}</li>
                          )}
                          freeSolo
                          renderInput={(params) => (
                            <Index.TextField
                              className="admin-input-design input-placeholder"
                              {...params}
                              fullWidth
                              placeholder="Vessel name"
                              // label="Free solo with text demo"
                            />
                          )}
                        />
                        {touched?.vesselName && errors?.vesselName && (
                          <Index.FormHelperText error>
                            {errors?.vesselName}
                          </Index.FormHelperText>
                        )}
                      </Index.Box>
                    </Index.Grid>
                  </Index.Grid>

                  <Index.Box className="mt-20">
                    <Index.TableContainer
                      component={Index.Paper}
                      className="table-container"
                    >
                      <Index.Table
                        aria-label="simple table"
                        className="table-design-main addinvoice-table"
                      >
                        <Index.TableHead>
                          <Index.TableRow>
                            <Index.TableCell> Items</Index.TableCell>
                            <Index.TableCell>
                              {" "}
                              Particulars (Services)
                            </Index.TableCell>
                            <Index.TableCell>GST Rate</Index.TableCell>

                            <Index.TableCell>
                              Interstate/Intrastate
                            </Index.TableCell>
                            <Index.TableCell> Qty In MT</Index.TableCell>
                            <Index.TableCell> Taxable Value</Index.TableCell>
                            <Index.TableCell className="last-auto">
                              {" "}
                              Action
                            </Index.TableCell>
                          </Index.TableRow>
                        </Index.TableHead>

                        <Index.TableBody>
                          {values?.invoiceManagement?.length > 0 && (
                            <FieldArray name="invoiceManagement">
                              {({ push, remove }) => (
                                <>
                                  {values?.invoiceManagement.map(
                                    (row, index) => (
                                      <Index.TableRow key={index}>
                                        <Index.TableCell>
                                          <Index.Box>
                                            <Index.Box className="input-design-div with-border">
                                              <Index.Select
                                                className="placeholder-set-text-box"
                                                fullWidth
                                                displayEmpty
                                                disabled={
                                                  values?.port?.length
                                                    ? false
                                                    : true
                                                }
                                                defaultValue={
                                                  values
                                                    ? values.invoiceManagement[
                                                        index
                                                      ].items
                                                    : ""
                                                }
                                                onChange={(e) => {
                                                  setFieldValue(
                                                    `invoiceManagement.${index}.items`,
                                                    e.target.value
                                                  );
                                                }}
                                              >
                                                <Index.MenuItem value="">
                                                  <Index.MenuItem
                                                    disabled
                                                    className="all-select-label"
                                                  >
                                                    Select item
                                                  </Index.MenuItem>
                                                </Index.MenuItem>
                                                <Index.MenuItem value="VLSFO(0.5)">
                                                  VLSFO(0.5)
                                                </Index.MenuItem>
                                                <Index.MenuItem value="HSFO(3.5)">
                                                  HSFO(3.5)
                                                </Index.MenuItem>
                                                <Index.MenuItem value="HSD">
                                                  HSD
                                                </Index.MenuItem>
                                                <Index.MenuItem value="FW">
                                                  FW
                                                </Index.MenuItem>
                                                <Index.MenuItem value="NA">
                                                  NA
                                                </Index.MenuItem>
                                              </Index.Select>
                                              {errors &&
                                                errors.invoiceManagement &&
                                                errors.invoiceManagement[
                                                  index
                                                ] &&
                                                errors.invoiceManagement[index]
                                                  .items &&
                                                touched &&
                                                touched.invoiceManagement &&
                                                touched.invoiceManagement[
                                                  index
                                                ] &&
                                                touched.invoiceManagement[index]
                                                  .items && (
                                                  <Index.FormHelperText error>
                                                    {
                                                      errors.invoiceManagement[
                                                        index
                                                      ].items
                                                    }
                                                  </Index.FormHelperText>
                                                )}
                                            </Index.Box>
                                          </Index.Box>
                                        </Index.TableCell>

                                        <Index.TableCell>
                                          <Index.Box className="input-design-div with-border">
                                            {editData?._id &&
                                            values?.invoiceManagement ? (
                                              particularList?.length && (
                                                <Index.Autocomplete
                                                  disablePortal
                                                  className="invoice-particulars-select-drop"
                                                  id="combo-box-demo"
                                                  options={particularList}
                                                  getOptionLabel={(option) =>
                                                    option &&
                                                    option?.particular !=
                                                      undefined
                                                      ? option?.particular
                                                      : ""
                                                  }
                                                  defaultValue={
                                                    values
                                                      ? values
                                                          ?.invoiceManagement[
                                                          index
                                                        ]?.particulars
                                                      : ""
                                                  }
                                                  renderOption={(
                                                    props,
                                                    option
                                                  ) => (
                                                    <Index.Box
                                                      component="li"
                                                      sx={{
                                                        "& > img": {
                                                          mr: 2,
                                                          flexShrink: 0,
                                                        },
                                                      }}
                                                      {...props}
                                                    >
                                                      {option?.particular}
                                                    </Index.Box>
                                                  )}
                                                  onChange={(e, newValue) => {
                                                    let data = {
                                                      target: {
                                                        name: `invoiceManagement.${index}.particulars`,
                                                        value: newValue?._id,
                                                      },
                                                    };
                                                    handleChange(data);
                                                    setParticularStatus(true);
                                                    setFieldValue(
                                                      `invoiceManagement.${index}.isEditedParticular`,
                                                      true
                                                    );
                                                    setFieldValue(
                                                      `invoiceManagement.${index}.particularId`,
                                                      newValue?._id
                                                    );
                                                    setFieldValue(
                                                      `invoiceManagement.${index}.rate`,
                                                      newValue?.rate
                                                    );
                                                  }}
                                                  renderInput={(params) => (
                                                    <Index.TextField
                                                      {...params}
                                                      placeholder="Select services"
                                                      variant="outlined"
                                                    />
                                                  )}
                                                />
                                              )
                                            ) : (
                                              <Index.Autocomplete
                                                disablePortal
                                                className="invoice-particulars-select-drop"
                                                id="combo-box-demo"
                                                disabled={
                                                  values?.port?.length
                                                    ? false
                                                    : true
                                                }
                                                options={particularList}
                                                getOptionLabel={(option) =>
                                                  option.particular
                                                }
                                                renderOption={(
                                                  props,
                                                  option
                                                ) => (
                                                  <Index.Box
                                                    component="li"
                                                    sx={{
                                                      "& > img": {
                                                        mr: 2,
                                                        flexShrink: 0,
                                                      },
                                                    }}
                                                    {...props}
                                                  >
                                                    {option?.particular}
                                                  </Index.Box>
                                                )}
                                                onChange={(e, newValue) => {
                                                  let data = {
                                                    target: {
                                                      name: `invoiceManagement.${index}.particulars`,
                                                      value: newValue?._id,
                                                    },
                                                  };
                                                  handleChange(data);
                                                  setParticularStatus(true);
                                                  setFieldValue(
                                                    `invoiceManagement.${index}.isEditedParticular`,
                                                    true
                                                  );
                                                  setFieldValue(
                                                    `invoiceManagement.${index}.particularId`,
                                                    newValue?._id
                                                  );
                                                  setFieldValue(
                                                    `invoiceManagement.${index}.rate`,
                                                    newValue?.rate
                                                  );
                                                }}
                                                renderInput={(params) => (
                                                  <Index.TextField
                                                    {...params}
                                                    placeholder="Select services"
                                                    variant="outlined"
                                                  />
                                                )}
                                              />
                                            )}
                                            {errors &&
                                              errors.invoiceManagement &&
                                              errors.invoiceManagement[index] &&
                                              errors.invoiceManagement[index]
                                                .particularId &&
                                              touched &&
                                              touched.invoiceManagement &&
                                              touched.invoiceManagement[
                                                index
                                              ] &&
                                              touched.invoiceManagement[index]
                                                .particularId && (
                                                <Index.FormHelperText error>
                                                  {
                                                    errors.invoiceManagement[
                                                      index
                                                    ].particularId
                                                  }
                                                </Index.FormHelperText>
                                              )}
                                          </Index.Box>
                                        </Index.TableCell>

                                        <Index.TableCell>
                                          <Index.Box className="input-design-div with-border">
                                            <Index.TextField
                                              hiddenLabel
                                              type="number"
                                              disabled
                                              id="filled-hidden-label-normal"
                                              placeholder="Rate"
                                              variant="filled"
                                              className="admin-input-design input-placeholder disable-input-rate"
                                              onChange={handleChange}
                                              onBlur={handleBlur}
                                              name={`invoiceManagement.${index}.rate`}
                                              value={
                                                values.invoiceManagement[index]
                                                  .rate
                                              }
                                              // defaultValue={
                                              //   values.invoiceManagement[index]
                                              //     .rate
                                              // }
                                              error={Boolean(
                                                errors &&
                                                  errors.invoiceManagement &&
                                                  errors.invoiceManagement[
                                                    index
                                                  ] &&
                                                  errors.invoiceManagement[
                                                    index
                                                  ].particulars &&
                                                  touched &&
                                                  touched.invoiceManagement &&
                                                  touched.invoiceManagement[
                                                    index
                                                  ] &&
                                                  touched.invoiceManagement[
                                                    index
                                                  ].particulars
                                              )}
                                              helperText={
                                                touched &&
                                                touched.invoiceManagement &&
                                                touched.invoiceManagement[
                                                  index
                                                ] &&
                                                touched.invoiceManagement[index]
                                                  .particulars &&
                                                errors &&
                                                errors.invoiceManagement &&
                                                errors.invoiceManagement[
                                                  index
                                                ] &&
                                                errors.invoiceManagement[index]
                                                  .particulars
                                              }
                                            />
                                          </Index.Box>
                                        </Index.TableCell>

                                        <Index.TableCell>
                                          <Index.Box className="input-design-div with-border">
                                            <Index.Select
                                              className="admin-input-design placeholder-set-text-box"
                                              fullWidth
                                              displayEmpty
                                              disabled={
                                                values?.port?.length
                                                  ? false
                                                  : true
                                              }
                                              defaultValue={
                                                values
                                                  ? values.invoiceManagement[
                                                      index
                                                    ].interState_intraState
                                                  : ""
                                              }
                                              onChange={(e) => {
                                                setFieldValue(
                                                  `invoiceManagement.${index}.interState_intraState`,
                                                  e.target.value
                                                );
                                                setInterStateStatus(true);
                                                setFieldValue(
                                                  `invoiceManagement.${index}.isEditedInterIntraState`,
                                                  true
                                                );
                                              }}
                                            >
                                              <Index.MenuItem value="">
                                                <Index.MenuItem
                                                  disabled
                                                  className="all-select-label"
                                                >
                                                  Select state
                                                </Index.MenuItem>
                                              </Index.MenuItem>
                                              <Index.MenuItem value="Interstate">
                                                Interstate
                                              </Index.MenuItem>
                                              <Index.MenuItem value="Intrastate">
                                                Intrastate
                                              </Index.MenuItem>
                                            </Index.Select>
                                            {errors &&
                                              errors.invoiceManagement &&
                                              errors.invoiceManagement[index] &&
                                              errors.invoiceManagement[index]
                                                .interState_intraState &&
                                              touched &&
                                              touched.invoiceManagement &&
                                              touched.invoiceManagement[
                                                index
                                              ] &&
                                              touched.invoiceManagement[index]
                                                .interState_intraState && (
                                                <Index.FormHelperText error>
                                                  {
                                                    errors.invoiceManagement[
                                                      index
                                                    ].interState_intraState
                                                  }
                                                </Index.FormHelperText>
                                              )}
                                          </Index.Box>
                                        </Index.TableCell>

                                        <Index.TableCell className="taxable-qty-adjust-input">
                                          <Index.Box className="input-design-div with-border">
                                            <Index.TextField
                                              hiddenLabel
                                              disabled={
                                                values?.port?.length
                                                  ? false
                                                  : true
                                              }
                                              type="number"
                                              id="filled-hidden-label-normal"
                                              variant="filled"
                                              placeholder="Qty In MT"
                                              className="admin-input-design input-placeholder"
                                              name={`invoiceManagement.${index}.qtyInMT`}
                                              value={
                                                values.invoiceManagement[index]
                                                  .qtyInMT
                                              }
                                              onKeyPress={(event) =>
                                                handleKeyPress(
                                                  event,
                                                  setFieldValue
                                                )
                                              }
                                              onChange={(event) => {
                                                setFieldValue(
                                                  `invoiceManagement.${index}.isEditedQtyMT`,
                                                  true
                                                );
                                                setFieldValue(
                                                  `invoiceManagement.${index}.isEditedTaxableValue`,
                                                  false
                                                );
                                                if (
                                                  event.target.value.length <=
                                                  10
                                                ) {
                                                  let data = {
                                                    target: {
                                                      name: `invoiceManagement.${index}.qtyInMT`,
                                                      value: event.target.value,
                                                    },
                                                  };
                                                  handleChange(data);
                                                }
                                              }}
                                              onBlur={(event) => {
                                                setQtyStatus(true);
                                                addThreeZeroes(
                                                  event,
                                                  setFieldValue,
                                                  index
                                                );
                                              }}
                                              error={Boolean(
                                                errors &&
                                                  errors.invoiceManagement &&
                                                  errors.invoiceManagement[
                                                    index
                                                  ] &&
                                                  errors.invoiceManagement[
                                                    index
                                                  ].qtyInMT &&
                                                  touched &&
                                                  touched.invoiceManagement &&
                                                  touched.invoiceManagement[
                                                    index
                                                  ] &&
                                                  touched.invoiceManagement[
                                                    index
                                                  ].qtyInMT
                                              )}
                                              helperText={
                                                touched &&
                                                touched.invoiceManagement &&
                                                touched.invoiceManagement[
                                                  index
                                                ] &&
                                                touched.invoiceManagement[index]
                                                  .qtyInMT &&
                                                errors &&
                                                errors.invoiceManagement &&
                                                errors.invoiceManagement[
                                                  index
                                                ] &&
                                                errors.invoiceManagement[index]
                                                  .qtyInMT
                                              }
                                            />
                                          </Index.Box>
                                        </Index.TableCell>

                                        <Index.TableCell className="taxable-qty-adjust-input">
                                          <Index.Box className="input-design-div with-border">
                                            <Index.TextField
                                              hiddenLabel
                                              type="number"
                                              disabled={
                                                values?.port?.length
                                                  ? false
                                                  : true
                                              }
                                              id="filled-hidden-label-normal"
                                              placeholder="Taxable Value"
                                              variant="filled"
                                              className="admin-input-design input-placeholder"
                                              onWheel={(event) =>
                                                event.target.blur()
                                              }
                                              onChange={(event) => {
                                                setFieldValue(
                                                  `invoiceManagement.${index}.isEditedTaxableValue`,
                                                  true
                                                );
                                                if (
                                                  event.target.value.length <=
                                                  10
                                                ) {
                                                  let data = {
                                                    target: {
                                                      name: `invoiceManagement.${index}.taxableValue`,
                                                      value: event.target.value,
                                                    },
                                                  };
                                                  handleChange(data);
                                                }
                                              }}
                                              onBlur={(event) => {
                                                setTaxableValueStatus(true);
                                                setSubmitStatus(false);
                                                addZeroes(
                                                  event,
                                                  setFieldValue,
                                                  index
                                                );
                                              }}
                                              name={`invoiceManagement.${index}.taxableValue`}
                                              value={
                                                values.invoiceManagement[index]
                                                  .taxableValue
                                              }
                                              error={Boolean(
                                                errors &&
                                                  errors.invoiceManagement &&
                                                  errors.invoiceManagement[
                                                    index
                                                  ] &&
                                                  errors.invoiceManagement[
                                                    index
                                                  ].taxableValue &&
                                                  touched &&
                                                  touched.invoiceManagement &&
                                                  touched.invoiceManagement[
                                                    index
                                                  ] &&
                                                  touched.invoiceManagement[
                                                    index
                                                  ].taxableValue
                                              )}
                                              helperText={
                                                touched &&
                                                touched.invoiceManagement &&
                                                touched.invoiceManagement[
                                                  index
                                                ] &&
                                                touched.invoiceManagement[index]
                                                  .taxableValue &&
                                                errors &&
                                                errors.invoiceManagement &&
                                                errors.invoiceManagement[
                                                  index
                                                ] &&
                                                errors.invoiceManagement[index]
                                                  .taxableValue
                                              }
                                            />
                                          </Index.Box>
                                        </Index.TableCell>

                                        <Index.TableCell className="last-auto">
                                          <Index.Box className="input-design-div with-border">
                                            <Index.Box className="incre-btn-main">
                                              <Index.Button
                                                sx={{ fontSize: "20px" }}
                                                className="button-add inc-dic-btn"
                                                type="button"
                                                onClick={() => {
                                                  push({
                                                    items: "",
                                                    particulars: "",
                                                    particularId: "",
                                                    rate: "",
                                                    interState_intraState: "",
                                                    qtyInMT: "",
                                                    taxableValue: "",
                                                    CGST: "",
                                                    SGST: "",
                                                    IGST: "",
                                                    // totalCGSTAmount: "",
                                                    // totalSGSTAmount: "",
                                                    // totalIGSTAmount: "",
                                                  });
                                                }}
                                              >
                                                +
                                              </Index.Button>
                                              {values.invoiceManagement.length >
                                                1 && (
                                                <Index.Button
                                                  sx={{ fontSize: "20px" }}
                                                  type="button"
                                                  className="button-remove inc-dic-btn"
                                                  disabled={
                                                    values.invoiceManagement
                                                      .length > 1
                                                      ? false
                                                      : true
                                                  }
                                                  //onClick={() => remove(index) }
                                                  onClick={async () => {
                                                    setRemoveRow(true);
                                                    await remove(index);
                                                  }}
                                                >
                                                  -
                                                </Index.Button>
                                              )}
                                            </Index.Box>
                                          </Index.Box>
                                        </Index.TableCell>
                                      </Index.TableRow>
                                    )
                                  )}
                                </>
                              )}
                            </FieldArray>
                          )}
                        </Index.TableBody>
                      </Index.Table>
                    </Index.TableContainer>
                  </Index.Box>

                  <Index.Box
                    className="line dark-line"
                    mt={2}
                    mb={3}
                  ></Index.Box>
                  <Index.Box className="total-service">
                    <Index.Box className="service-left">
                      <Index.Box>
                        <Index.Box className="mb-15 service-left-row">
                          <Index.Typography
                            variant="label"
                            component="label"
                            className="input-label"
                          >
                            Date Of Supply
                          </Index.Typography>
                          <Index.Box className="input-design-div">
                            <Index.Box className="set-date set-date-picker">
                              <Index.LocalizationProvider
                                dateAdapter={Index.AdapterDayjs}
                              >
                                <Index.DemoContainer
                                  components={["DatePicker"]}
                                >
                                  <Index.DemoItem label="">
                                    <Index.DatePicker
                                      fullWidth
                                      format="DD/MM/YYYY"
                                      name="dateOfSupply"
                                      // maxDate={Index.dayjs(new Date())}
                                      // defaultValue={Index.dayjs(
                                      //   Index.dayjs(values?.dateOfSupply)
                                      // )}
                                      value={Index.dayjs(
                                        values?.dateOfSupply
                                          ? values?.dateOfSupply
                                          : new Date()
                                      )}
                                      onChange={(value) => {
                                        const formatedDate = Index.moment(
                                          value.$d
                                        ).format("YYYY/MM/DD");
                                        setFieldValue(
                                          "dateOfSupply",
                                          formatedDate
                                        );
                                      }}
                                    />
                                  </Index.DemoItem>
                                </Index.DemoContainer>
                              </Index.LocalizationProvider>
                              {touched.dateOfSupply && errors.dateOfSupply && (
                                <Index.FormHelperText error>
                                  {errors.dateOfSupply}
                                </Index.FormHelperText>
                              )}
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>

                        <Index.Box className="service-left-row">
                          <Index.Typography
                            variant="label"
                            component="label"
                            className="input-label"
                          >
                            Barge
                          </Index.Typography>
                          <Index.Box className="input-design-div with-border">
                            {editData?._id && values?.barges ? (
                              bargeList?.length && (
                                <Index.Select
                                  fullWidth
                                  name="barges"
                                  multiple
                                  MenuProps={MenuProps}
                                  value={values?.barges ? values?.barges : []}
                                  onChange={(event) => {
                                    const {
                                      target: { value },
                                    } = event;
                                    setFieldValue(
                                      "barges",
                                      // On autofill we get a stringified value.
                                      typeof value === "string"
                                        ? value.split(",")
                                        : value
                                    );
                                  }}
                                >
                                  {/* <Index.MenuItem value="0">
                                    Select barges
                                  </Index.MenuItem> */}
                                  {bargeList?.map((item) => (
                                    <Index.MenuItem
                                      key={item?._id}
                                      value={item?._id}
                                    >
                                      {item?.bargeName}
                                    </Index.MenuItem>
                                  ))}
                                </Index.Select>
                              )
                            ) : (
                              <Index.Select
                                fullWidth
                                name="barges"
                                multiple
                                MenuProps={MenuProps}
                                value={values?.barges}
                                onChange={(event) => {
                                  const {
                                    target: { value },
                                  } = event;
                                  setFieldValue(
                                    "barges",
                                    // On autofill we get a stringified value.
                                    typeof value === "string"
                                      ? value.split(",")
                                      : value
                                  );
                                }}
                              >
                                {/* <Index.MenuItem value="">
                                  <Index.MenuItem
                                    disabled
                                    className="all-select-label"
                                  >
                                      Select barges
                                  </Index.MenuItem>
                                </Index.MenuItem> */}

                                {bargeList?.map((item) => (
                                  <Index.MenuItem
                                    key={item?._id}
                                    value={item?._id}
                                  >
                                    {item?.bargeName}
                                  </Index.MenuItem>
                                ))}
                              </Index.Select>
                            )}

                            {touched.barges && errors.barges && (
                              <Index.FormHelperText error>
                                {errors.barges}
                              </Index.FormHelperText>
                            )}
                          </Index.Box>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="service-right">
                      <Index.Box className="payment-row">
                        <Index.Typography
                          varient="h6"
                          component="h6"
                          className="amount-title"
                        >
                          Taxable Value
                        </Index.Typography>
                        <Index.Typography
                          varient="h6"
                          component="h6"
                          className="amount-number"
                        >
                          ₹{" "}
                          {values?.totalTaxableValue
                            ? values?.totalTaxableValue?.toLocaleString(
                                "en-IN",
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }
                              )
                            : 0}
                        </Index.Typography>
                      </Index.Box>
                      {values.invoiceManagement.length > 0 &&
                        values.invoiceManagement.map((item, index) => (
                          <Index.Box key={index} className="service-total-div">
                            <Index.Box className="payment-row">
                              <Index.Typography
                                varient="h6"
                                component="h6"
                                className="amount-title"
                              >
                                CGST (
                                {item?.interState_intraState == "Intrastate"
                                  ? item?.rate / 2
                                  : 0}
                                %)
                              </Index.Typography>
                              <Index.Typography
                                varient="h6"
                                component="h6"
                                className="amount-number"
                              >
                                ₹{" "}
                                {item?.CGST
                                  ? item?.CGST?.toLocaleString("en-IN", {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    })
                                  : 0}
                              </Index.Typography>
                            </Index.Box>

                            <Index.Box className="payment-row">
                              <Index.Typography
                                varient="h6"
                                component="h6"
                                className="amount-title"
                              >
                                SGST (
                                {item?.interState_intraState == "Intrastate"
                                  ? item?.rate / 2
                                  : 0}
                                %)
                              </Index.Typography>
                              <Index.Typography
                                varient="h6"
                                component="h6"
                                className="amount-number"
                              >
                                ₹{" "}
                                {item?.SGST
                                  ? item?.SGST?.toLocaleString("en-IN", {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    })
                                  : 0}
                              </Index.Typography>
                            </Index.Box>

                            <Index.Box className="payment-row">
                              <Index.Typography
                                varient="h6"
                                component="h6"
                                className="amount-title"
                              >
                                IGST (
                                {item?.interState_intraState == "Interstate"
                                  ? item?.rate
                                  : 0}
                                %)
                              </Index.Typography>
                              <Index.Typography
                                varient="h6"
                                component="h6"
                                className="amount-number"
                              >
                                ₹{" "}
                                {item?.IGST
                                  ? item?.IGST?.toLocaleString("en-IN", {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    })
                                  : 0}
                              </Index.Typography>
                            </Index.Box>
                          </Index.Box>
                        ))}

                      <Index.Box className="payment-row service-main-total">
                        <Index.Typography
                          varient="h6"
                          component="h6"
                          className="amount-title"
                        >
                          Invoice Amount
                        </Index.Typography>
                        <Index.Typography
                          varient="h6"
                          component="h6"
                          className="amount-number"
                        >
                          {/* ₹{" "}
                          {values?.invoiceAmount
                            ? values?.invoiceAmount?.toLocaleString("en-IN", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })
                            : 0} */}
                          ₹{" "}
                          {values?.invoiceAmount
                            ? Math.round(values?.invoiceAmount).toLocaleString(
                                "en-IN",
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }
                              )
                            : 0}
                        </Index.Typography>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="line" mt={3} mb={3}></Index.Box>
                </Index.Box>

                {/* {console.log(isSubmitting, "isSubmitting")} */}

                <Index.Box className="common-button blue-button flex-end save-btn">
                  <Index.Button
                    variant="contained"
                    type="submit"
                    disabled={isSubmitting && !submitStatus}
                  >
                    {editData ? "Update" : "Submit"}
                  </Index.Button>
                </Index.Box>
              </form>
            )}
          </Formik>
        </Index.Box>
      </Index.Box>
      <AddPartyModel
        setValue={setValue}
        handleClose={handleClose}
        setOpen={setOpen}
        open={open}
      />
    </div>
  );
}

export default InvoiceAdd;
