import React from "react";
import Index from "../container/Index";

const SuperAdminRoute = ({ login }) => {
  const location = Index.useLocation();
  const routLink = location?.pathname;
  const permissions = login?.permission?.webPermission;
  const roleName = login?.permission?.roleName;
  const innerRouteLink = routLink.split("/"); // route link split and check url 
  const childRoutLink = innerRouteLink ? innerRouteLink[2] : null; 
  const permissionsArray = Object.entries(permissions);
  console.log(permissions, ' = webPermission', permissionsArray);

  const isLinkMatch = permissionsArray.some(([key, val]) => {
    console.log(key, '===', childRoutLink, val, 44444);
    if (key === childRoutLink && val === true) { // Check only roles route
      return true; // Match found in parent item
    }
    else if (("/admin/roles" === routLink && roleName === "Super Admin") || ("/admin/roles" === `/admin/${childRoutLink}` && roleName === "Super Admin")) { // Check only roles route
      return true; // Match found in parent item
    }
    else{
      return false;
    }
  });
  // console.log(isLinkMatch, 'abcd');
  return isLinkMatch ? <Index.Outlet /> : <Index.Navigate to="/404" />;
};

export default SuperAdminRoute;
