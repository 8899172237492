import React, { useState } from "react";
import Index from "../../../Index";
import { Formik } from "formik";
import { addPort } from "../../../../redux/admin/action";
import { portValidationSchema } from "../../../../validation/AdminValidation";

function AddPortComponent() {
  const dispatch = Index.useDispatch();
  const navigate = Index.useNavigate();
  const [loading, setLoading] = useState(false);

  let { state } = Index.useLocation();
  let editData = state?.row;
  if (editData) {
    editData.id = editData?._id;
  }

  // condition for manage Add/Edit together
  let initialValues = {
    portName: "",
  };

  if (editData) {
    initialValues = editData;
  } else {
    initialValues = {
      portName: "",
      latitude: "",
      longitude: "",
    };
  }

  // Add Barge
  const handleFormSubmit = async (values) => {
    setLoading(true);
    let data = new URLSearchParams();
    data.append("portName", values.portName);
    data.append("longitude", values.longitude);
    data.append("latitude", values.latitude);
    if (editData) {
      data.append("id", editData?._id);
    }
    dispatch(addPort(data, navigate, setLoading));
  };

  const backBtn = () => {
    navigate("/admin/port");
  };

  return (
    <>
      <Index.Box className="page-content-box">
        <Index.Box className="barge-common-box">
          <Index.Box className="title-header">
            <Index.Box className="title-header-flex">
              <Index.Box className="title-main mb-10">
                <Index.Typography
                  variant="p"
                  component="p"
                  className="page-title"
                >
                  {editData?._id ? "Edit" : "Add"} Port
                </Index.Typography>
              </Index.Box>
              <Index.Box className="common-button blue-button save-btn ">
                <Index.Button
                  variant="contained"
                  type="submit"
                  className="Particular_model_submit ml-0"
                  onClick={backBtn}
                >
                  Back
                </Index.Button>
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Formik
            enableReinitialize
            onSubmit={handleFormSubmit}
            initialValues={initialValues}
            validationSchema={portValidationSchema}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <form onSubmit={handleSubmit}>
                <Index.Box className="title-main">
                  <Index.Grid container spacing={2} className="grid-flex-start">
                    <Index.Grid item xs={12} sm={6} md={3}>
                      {/* <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Port Name
                      </Index.Typography> */}
                      <Index.Box className="input-design-div with-border">
                        <Index.TextField
                          hiddenLabel
                          id="filled-hidden-label-normal"
                          placeholder="Enter port name"
                          variant="filled"
                          className="admin-input-design input-placeholder"
                          name="portName"
                          autoComplete="off"
                          onBlur={handleBlur}
                          value={values.portName}
                          onChange={handleChange}
                          onFocus={() => setLoading(false)}
                          helperText={touched.portName && errors.portName}
                          error={Boolean(errors.portName && touched.portName)}
                        />
                      </Index.Box>
                    </Index.Grid>
                    <Index.Grid item xs={12} sm={6} md={3}>
                      {/* <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Latitude
                      </Index.Typography> */}
                      <Index.Box className="input-design-div with-border">
                        <Index.TextField
                          hiddenLabel
                          id="filled-hidden-label-normal"
                          placeholder=" Enter latitude"
                          variant="filled"
                          className="admin-input-design input-placeholder"
                          name="latitude"
                          autoComplete="off"
                          onBlur={handleBlur}
                          value={values.latitude}
                          type="Number"
                          onChange={handleChange}
                          onFocus={() => setLoading(false)}
                          helperText={touched.latitude && errors.latitude}
                          error={Boolean(errors.latitude && touched.latitude)}
                        />
                      </Index.Box>
                    </Index.Grid>
                    <Index.Grid item xs={12} sm={6} md={3}>
                      {/* <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Longitude
                      </Index.Typography> */}
                      <Index.Box className="input-design-div with-border">
                        <Index.TextField
                          hiddenLabel
                          id="filled-hidden-label-normal"
                          placeholder="Enter longitude"
                          variant="filled"
                          type="Number"
                          className="admin-input-design input-placeholder"
                          name="longitude"
                          autoComplete="off"
                          onBlur={handleBlur}
                          value={values.longitude}
                          onChange={handleChange}
                          onFocus={() => setLoading(false)}
                          helperText={touched.longitude && errors.longitude}
                          error={Boolean(errors.longitude && touched.longitude)}
                        />
                      </Index.Box>
                    </Index.Grid>
                    <Index.Grid item xs={12} sm={6} md={3} className="submit-btn-sec">
                    <Index.Box className="common-button blue-button flex-end save-btn flex-col">
                    <Index.Button
                      variant="contained"
                      type="submit"
                      disabled={loading}
                    >
                      {editData?._id ? "Update" : "Save"}
                    </Index.Button>
                  </Index.Box>
                    </Index.Grid>
                  </Index.Grid>
                  
                  
                </Index.Box>
              </form>
            )}
          </Formik>
        </Index.Box>
      </Index.Box>
    </>
  );
}

export default AddPortComponent;
