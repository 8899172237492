import moment from "moment";
import type from "./type";

const initialState = {
  isAdminLogin: false,
  adminDetails: {},
  activeCompany: {},
  bargeList: [],
  orderList: [],
  confirmOrderList: [],
  portList: [],
  tugOrderPortList: [],
  portListTrader: [],
  salesTraderList: [],
  invoiceList: [],
  creditNotInvoiceList: [],
  userList: [],
  invoiceActivityLogList: [],
  tankerActivityLogList: [],
  particularList: [],
  challanList: [],
  totalSalseDashboard: 0,
  totalPurchaseDashboard: 0,
  invoiceDetailsDashboard: {},
  categoryWiseDashboard: [],
  salsePartywise: [],
  purchaseList: [],
  inquiryList: [],
  traderEnquiryList: [],
  bargeExpenseList: [],
  lostEnquiryList: [],
  journalList: [],
  hdfcBankList: [],
  kotakBankList: [],
  partyNameList: [],
  totalHdfcSales: {},
  totalKotakSales: {},
  expenseList: [],
  nameOfVesselList: [],
  tugsNameOfVesselList: [],
  nameOfVesselListForTrader: [],
  expenseRequestList: [],
  traderMasterList: [],
  roleMasters: [],
  completedOrderList: [],
  notificationList: [],
  salesMasterList: [],
  bunkerOrderDashboard: [],
  activityTime: {},
  invoiceActivityTime: {},
  latitude: null,
  longitude: null,
  directoryList: [],
  tankerDashboardList: [],
  tripDashboardList: [],
  freeBreakdownTankers: [],
  orderTraderList: [],
  expenseTotal: {
    directExpenseTotal: 0,
    indirectExpenseTotal: 0,
    currentYear:
      new Date().getFullYear() + "-" + (new Date().getFullYear() - 1),
  },
  traderLostInquiryList: [],
  traderInquiryList: [],
  traderConfirmInquiryList: [],
  traderInquiryListById: [],
  dashboardBankAmounts: [],
};

const AdminReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.ADMIN_LOGIN_SUCCESS:
      return {
        ...state,
        adminDetails: action.payload,
        isAdminLogin: true,
      };
    case type.ADMIN_ACTIVE_COMPANY_NAME:
      return {
        ...state,
        activeCompany: action.payload,
      };
    case type.BARGE_LIST_SUCCESS:
      return {
        ...state,
        bargeList: action.payload,
      };
    case type.PORT_LIST_SUCCESS:
      return {
        ...state,
        portList: action.payload,
      };
    case type.TUG_ORDER_PORT_LIST_SUCCESS:
      return {
        ...state,
        tugOrderPortList: action.payload,
      };
    case type.GET_ORDER_TRADER_LIST_SUCCESS:
      return {
        ...state,
        orderTraderList: action.payload,
      };
    case type.PORT_LIST_TRADER_SUCCESS:
      return {
        ...state,
        portListTrader: action.payload,
      };
    case type.SALES_TRADER_LIST_SUCCESS:
      return {
        ...state,
        salesTraderList: action.payload,
      };
    case type.ADMIN_LOGOUT:
      return initialState;
    case type.GET_LOCATION:
      return {
        ...state,
        latitude: action?.payload?.lat,
        longitude: action?.payload?.long,
      };
    case type.GET_ORDER_LIST_SUCCESS:
      return {
        ...state,
        orderList: action.payload,
      };
    case type.GET_CONFIRM_ORDER_LIST_SUCCESS:
      return {
        ...state,
        confirmOrderList: action.payload,
      };
    case type.GET_CHALLAN_LIST_SUCCESS:
      return {
        ...state,
        challanList: action.payload,
      };
    case type.GET_INVOICE_LIST_SUCCESS:
      return {
        ...state,
        invoiceList: action.payload,
      };
    case type.GET_CREDIT_NOT_INVOICE:
      return {
        ...state,
        creditNotInvoiceList: action.payload,
      };
    case type.GET_INVOICE_ACTIVITY_LOG:
      return {
        ...state,
        invoiceActivityLogList: action.payload,
      };
    case type.GET_PARTICULAR_LIST:
      return {
        ...state,
        particularList: action.payload,
      };
    case type.GET_USER_LIST_SUCCESS:
      return {
        ...state,
        userList: action.payload,
      };
    case type.GET_PARTYLIST_SUCCESS:
      return {
        ...state,
        partyNameList: action.payload,
      };
    case type.GET_NAME_OF_VESSEL_LIST_SUCCESS:
      return {
        ...state,
        nameOfVesselList: action.payload,
      };
    case type.GET_TUG_NAME_OF_VESSEL_LIST_SUCCESS:
      return {
        ...state,
        tugsNameOfVesselList: action.payload,
      };
    case type.GET_JOURNAL_LIST_SUCCESS:
      return {
        ...state,
        journalList: action.payload,
      };
    case type.GET_TOTAL_SALSE_DASHBOARD:
      return {
        ...state,
        totalSalseDashboard: action.payload,
      };
    case type.GET_TOTAL_PURCHASE_DASHBOARD:
      return {
        ...state,
        totalPurchaseDashboard: action.payload,
      };
    case type.GET_INVOICE_DETAILS_DASHBOARD:
      return {
        ...state,
        invoiceDetailsDashboard: action.payload,
      };
    case type.GET_CATEGORY_WISE_DASHBOARD:
      return {
        ...state,
        categoryWiseDashboard: action.payload,
      };
    case type.GET_SALSE_PARTY_WISE:
      return {
        ...state,
        salsePartywise: action.payload,
      };
    case type.GET_EXPENSE_REQUEST_LIST_SUCCESS:
      return {
        ...state,
        expenseRequestList: action.payload,
      };
    case type.GET_PURCHASE_LIST_SUCCESS:
      return {
        ...state,
        purchaseList: action.payload,
      };

    case type.GET_INQUIRY_lIST_SUCCESS:
      return {
        ...state,
        inquiryList: action.payload,
      };

    case type.GET_TRADER_INQUIRY_lIST_SUCCESS:
      return {
        ...state,
        traderInquiryList: action.payload,
      };
    case type.GET_TRADER_INQUIRY_lIST_BY_ID_SUCCESS:
      return {
        ...state,
        traderInquiryListById: action.payload,
      };
    case type.GET_TRADER_LOST_INQUIRY_lIST_SUCCESS:
      return {
        ...state,
        traderLostInquiryList: action.payload,
      };
    case type.GET_TRADER_CONFIRM_INQUIRY_lIST_SUCCESS:
      return {
        ...state,
        traderConfirmInquiryList: action.payload,
      };
    case type.GET_BARGE_EXPENSE_LIST_SUCCESS:
      return {
        ...state,
        bargeExpenseList: action.payload,
      };
    case type.GET_LOST_ENQUIRY_SUCCESS:
      return {
        ...state,
        lostEnquiryList: action.payload,
      };
    case type.GET_HDFC_BANK_LIST_SUCCESS:
      return {
        ...state,
        hdfcBankList: action.payload,
      };
    case type.GET_KOTAK_BANK_LIST_SUCCESS:
      return {
        ...state,
        kotakBankList: action.payload,
      };
    case type.ADMIN_UPDATE_PROFILE:
      return {
        ...state,
        adminDetails: action.payload,
      };
    case type.GET_HDFC_TOTAL_DASHBOARD:
      return {
        ...state,
        totalHdfcSales: action.payload,
      };
    case type.GET_KOTAK_TOTAL_DASHBOARD:
      return {
        ...state,
        totalKotakSales: action.payload,
      };
    case type.GET_EXPENSE_LIST_SUCCESS:
      return {
        ...state,
        expenseList: action.payload,
      };
    case type.GET_NOTIFICATION_LIST_SUCCESS:
      return {
        ...state,
        notificationList: action.payload,
      };
    case type.GET_TRADER_MASTER_LIST_SUCCESS:
      return {
        ...state,
        traderMasterList: action.payload,
      };
    case type.GET_ROLE_MASTER_SUCCESS:
      return {
        ...state,
        roleMasters: action.payload,
      };
    case type.GET_COMPLETED_ORDER_LIST_SUCCESS:
      return {
        ...state,
        completedOrderList: action.payload,
      };
    case type.GET_SALES_MASTER_LIST_SUCCESS:
      return {
        ...state,
        salesMasterList: action.payload,
      };
    case type.GET_TANKER_ACTIVITY_LOG:
      return {
        ...state,
        tankerActivityLogList: action.payload,
      };
    case type.GET_LATEST_ACTIVITY_TIME_SUCCESS:
      return {
        ...state,
        activityTime: action.payload,
      };
    case type.GET_LATEST_INVOICE_ACTIVITY_TIME_SUCCESS:
      return {
        ...state,
        invoiceActivityTime: action.payload,
      };
    case type.GET_BUNKER_ORDER_DASHBOARD_SUCCESS:
      return {
        ...state,
        bunkerOrderDashboard: action.payload,
      };
    case type.GET_DIRECTORY_LIST_SUCCESS:
      return {
        ...state,
        directoryList: action.payload,
      };
    case type.GET_TANKER_DASHBOARD_LIST:
      return {
        ...state,
        tankerDashboardList: action.payload,
      };
    case type.SET_FREE_BREAKDOWN_TANKERS:
      return {
        ...state,
        freeBreakdownTankers: action.payload,
      };
    case type.GET_TRIP_DASHBOARD_LIST:
      return {
        ...state,
        tripDashboardList: action.payload,
      };
    case type.GET_EXPENSE_TOTAL:
      return {
        ...state,
        expenseTotal: {
          directExpenseTotal: Number(
            parseFloat(action.payload.directExpenseTotal).toFixed(2)
          ),
          indirectExpenseTotal: Number(
            parseFloat(action.payload.indirectExpenseTotal).toFixed(2)
          ),
        },
      };
    case type.GET_DASHBOARD_BANK_AMOUNTS:
      return {
        ...state,
        dashboardBankAmounts: action.payload,
      };
    default:
      return state;
  }
};

export default AdminReducer;
