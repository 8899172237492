import React from "react";
import Index from "../../../../Index";

const SlopeAndSludgeDetails = ({ viewData }) => {
  return (
    <>
      <Index.Grid container spacing={1}>
        <Index.Grid item xs={12} sm={6}>
          <Index.Box className="invoice-detail-item">
            <Index.Box className="input-label opacity-60">
              Vessel ETA:
            </Index.Box>
            <Index.Box className="input-label">
              {viewData?.vesselEta
                ? Index.moment(viewData.vesselEta).format("DD/MM/YYYY HH:mm")
                : "-"}
            </Index.Box>
          </Index.Box>
        </Index.Grid>

        <Index.Grid item xs={12} sm={6}>
          <Index.Box className="invoice-detail-item">
            <Index.Box className="input-label opacity-60">
              Vessel ETB:
            </Index.Box>
            <Index.Box className="input-label">
              {viewData?.vesselEtb
                ? Index.moment(viewData.vesselEtb).format("DD/MM/YYYY HH:mm")
                : "-"}
            </Index.Box>
          </Index.Box>
        </Index.Grid>
        <Index.Grid item xs={12} sm={6}>
          <Index.Box className="invoice-detail-item">
            <Index.Box className="input-label opacity-60">
              Vessel ETD:
            </Index.Box>
            <Index.Box className="input-label">
              {viewData?.vesselEtd
                ? Index.moment(viewData.vesselEtd).format("DD/MM/YYYY HH:mm")
                : "-"}
            </Index.Box>
          </Index.Box>
        </Index.Grid>
        <Index.Grid item xs={12} sm={6}>
          <Index.Box className="invoice-detail-item">
            <Index.Box className="input-label opacity-60">C/F:</Index.Box>
            <Index.Box className="input-label">
              {viewData?.coastal ? viewData?.coastal : "-"}
            </Index.Box>
          </Index.Box>
        </Index.Grid>
        <Index.Grid item xs={12} sm={6}>
          <Index.Box className="invoice-detail-item">
            <Index.Box className="input-label opacity-60">Quantity:</Index.Box>
            <Index.Box className="input-label">
              {viewData?.qty ? viewData?.qty : "-"}
            </Index.Box>
          </Index.Box>
        </Index.Grid>
        <Index.Grid item xs={12} sm={6}>
          <Index.Box className="invoice-detail-item">
            <Index.Box className="input-label opacity-60">
              Product Name:
            </Index.Box>
            <Index.Box className="input-label">
              {viewData?.product ? viewData?.product : "-"}
            </Index.Box>
          </Index.Box>
        </Index.Grid>
        <Index.Grid item xs={12} sm={6}>
          <Index.Box className="invoice-detail-item">
            <Index.Box className="input-label opacity-60">Agent:</Index.Box>
            <Index.Box className="input-label">
              {viewData?.agentName ? viewData?.agentName : "-"}
            </Index.Box>
          </Index.Box>
        </Index.Grid>
        <Index.Grid item xs={12} sm={6}>
          <Index.Box className="invoice-detail-item">
            <Index.Box className="input-label opacity-60">Port:</Index.Box>
            <Index.Box className="input-label">
              {viewData?.port?.slopePortName
                ? viewData?.port?.slopePortName
                : "-"}
            </Index.Box>
          </Index.Box>
        </Index.Grid>
        <Index.Grid item xs={12} sm={6}>
          <Index.Box className="invoice-detail-item">
            <Index.Box className="input-label opacity-60">Order By:</Index.Box>
            <Index.Box className="input-label">
              {viewData?.orderBy ? viewData?.orderBy : "-"}
            </Index.Box>
          </Index.Box>
        </Index.Grid>
        <Index.Grid item xs={12} sm={6}>
          <Index.Box className="invoice-detail-item">
            <Index.Box className="input-label opacity-60">Trip Date:</Index.Box>
            <Index.Box className="input-label">
              {viewData?.tripDateTime
                ? Index.moment(viewData.tripDateTime).format("DD/MM/YYYY HH:mm")
                : "-"}
            </Index.Box>
          </Index.Box>
        </Index.Grid>
        <Index.Grid item xs={12} sm={6}>
          <Index.Box className="invoice-detail-item">
            <Index.Box className="input-label opacity-60">
              Slope Document:
            </Index.Box>
            <Index.Box className="input-label">
              {viewData?.image ? viewData?.image : "-"}
            </Index.Box>
          </Index.Box>
        </Index.Grid>
      </Index.Grid>
    </>
  );
};

export default SlopeAndSludgeDetails;
