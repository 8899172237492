import React from "react";
import Index from "../../../Index";

const InvoiceReminderPopUp = ({ handleClose, setOpen, open, currentRows }) => {
  return (
    <div>
      <Index.Dialog
        open={open}
        onclose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="expense-add-main"
      >
        <Index.DialogContent className="expense-model-main">
          <Index.Box className="main-pending-enquiry">
            <Index.Box className="title-main">
              <Index.Typography
                variant="p"
                component="p"
                className="page-title"
              >
                Invoice Reminder
              </Index.Typography>
            </Index.Box>
            <Index.Button className="modal-circle-main user-circle-icon-btn">
              <img
                src={Index.Svg.close}
                onClick={() => handleClose(setOpen(false))}
                className="user-circle-img user-circle-icon"
                alt="icon"
                sx={{ cursor: "pointer" }}
              />
            </Index.Button>
          </Index.Box>

          <Index.Box className="title-main">
            <Index.Box className="">
              <Index.Grid container spacing={3}>
                <Index.Grid item xs={12} sm={12} md={12}>
                  <Index.Typography>
                    {currentRows?.length
                      ? currentRows
                      : "No Invoices is pending"}
                  </Index.Typography>
                </Index.Grid>
              </Index.Grid>
            </Index.Box>
          </Index.Box>
        </Index.DialogContent>
      </Index.Dialog>
    </div>
  );
};

export default InvoiceReminderPopUp;
