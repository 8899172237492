import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import { Formik } from "formik";
import * as Yup from "yup";
import {
  addEditWaterSupplyOrder,
  addTugOrder,
  getFreshWaterBargeList,
  getTugNameOfVessel,
  getTugOrderPortList,
  getWaterSupplyPortList,
  getWaterSupplyVesselNameList,
} from "../../../../redux/admin/action";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { DateRangePicker } from "@mui/x-date-pickers-pro";
import dayjs from "dayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

const waterTypeList = [
  { id: 1, type: "Freshwater" },
  { id: 2, type: "Mix" },
  { id: 3, type: "RO" },
];

const coastalList = [
  { id: 1, name: "Coastal" },
  { id: 1, name: "Foreign" },
];

function WaterSupplyAdd() {
  const dispatch = Index.useDispatch();
  const navigate = Index.useNavigate();
  const [vesselNameList, setVesselNameList] = useState([]);
  const [allPortList, setAllPortList] = useState([]);
  const [freshWateredBarges, setFreshWateredBarges] = useState([]);

  const login = Index.useSelector((state) => state.AdminReducer.adminDetails);
  const { state } = Index.useLocation();
  const [loading, setLoading] = useState(false);

  //edit order
  let editData = state?.row;
  const tabType = state?.type;

  const filter = createFilterOptions();

  if (editData) {
    editData.id = editData?._id;
    let portId = editData?.port?._id;
    if (portId) {
      editData.port = portId;
    }
  }

  // initial value

  let initialValues = {
    vesselName: "",
    agentName: "",
    port: "",
    vesselEta: "",
    vesselEtb: "",
    vesselEtd: "",
    coastal: "",
    qty: "",
    waterType: "",
    orderBy: "",
    supplyDateTime: "",
    waterBarge: "",
  };

  if (editData) {
    initialValues = editData;
  }

  const handleFormSubmit = async (values) => {
    const data = new URLSearchParams();
    if (values?.vesselName) {
      data.append("vesselName", values?.vesselName?.trim());
    }
    if (values?.port) {
      data.append("port", values?.port);
    }
    data.append("agentName", values?.agentName ? values?.agentName : "");
    data.append("coastal", values?.coastal ? values?.coastal : "");
    data.append("qty", values?.qty ? values?.qty : "");
    data.append("waterType", values?.waterType ? values?.waterType : "");
    data.append("orderBy", values?.orderBy ? values?.orderBy : "");
    if (values?.waterBarge) {
      data.append("waterBarge", values?.waterBarge ? values?.waterBarge : "");
    }

    if (values?.vesselEta) {
      data.append(
        "vesselEta",
        Index.moment(values?.vesselEta).format("YYYY-MM-DDTHH:mm:ssZZ")
      );
    }
    if (values?.vesselEtb) {
      data.append(
        "vesselEtb",
        Index.moment(values?.vesselEtb).format("YYYY-MM-DDTHH:mm:ssZZ")
      );
    }
    if (values?.vesselEtd) {
      data.append(
        "vesselEtd",
        Index.moment(values?.vesselEtd).format("YYYY-MM-DDTHH:mm:ssZZ")
      );
    }
    if (values?.supplyDateTime) {
      data.append(
        "supplyDateTime",
        Index.moment(values?.supplyDateTime).format("YYYY-MM-DDTHH:mm:ssZZ")
      );
    }

    setLoading(true);
    if (editData) {
      data.append("id", editData?._id);
    }
    dispatch(addEditWaterSupplyOrder(data)).then((res) => {
      if (tabType === "cancelOrder") {
        navigate("/admin/waterSupply", { state: { value: 1 } });
      } else if (tabType === "completedOrder") {
        navigate("/admin/waterSupply", { state: { value: 2 } });
      } else {
        navigate("/admin/waterSupply");
      }
      //   navigate("/admin/tugsOrder");
    });
  };

  // focus handler for disabled submit button

  const handleFocus = () => {
    setLoading(false);
  };

  //get port list
  const fetchWaterSupplyPortList = () => {
    dispatch(getWaterSupplyPortList()).then((res) => {
      if (res?.status !== 200) setAllPortList([]);
      const sortedData = res?.data?.sort((a, b) =>
        a?.supplyPortName?.localeCompare(b?.supplyPortName)
      );
      if (!sortedData?.length) setAllPortList([]);
      setAllPortList(sortedData);
    });
  };

  //get vessel name list
  const fetchWaterSupplyVesselNameList = () => {
    dispatch(getWaterSupplyVesselNameList()).then((res) => {
      if (res?.status !== 200) setVesselNameList([]);
      const vesselData = res?.data?.filter((d) => d?.vesselName?.toUpperCase());
      const uniqueArray = vesselData?.filter(
        (v, i, a) => a?.findIndex((t) => t?.vesselName === v?.vesselName) === i
      );
      if (login?.role === "Operation") {
        // Role Operation then vesselName show in uppercase
        const newData = uniqueArray?.sort((a, b) =>
          a?.vesselName?.localeCompare(b?.vesselName)
        );
        setVesselNameList(
          newData?.map((e) => ({
            ...e,
            vesselName: e?.vesselName?.toUpperCase(),
          }))
        );
      } else {
        setVesselNameList(
          uniqueArray?.sort((a, b) =>
            a?.vesselName?.localeCompare(b?.vesselName)
          )
        );
      }
    });
  };

  // when the get the fresh water barges then used...
  const handleGetFreshWaterBargeList = () => {
    dispatch(getFreshWaterBargeList())
      .then((res) => {
        if (res.status === 200) {
          setFreshWateredBarges(res.data);
        } else {
          setFreshWateredBarges([]);
        }
      })
      .catch((error) => {
        console.error(error);
        setFreshWateredBarges([]);
      });
  };

  //getAll port and barge
  useEffect(() => {
    fetchWaterSupplyPortList();
    fetchWaterSupplyVesselNameList();
    dispatch(getTugOrderPortList());
    dispatch(getTugNameOfVessel());
    handleGetFreshWaterBargeList();
  }, []);

  const backBtn = () => {
    navigate("/admin/waterSupply");
    if (tabType === "cancelOrder") {
      navigate("/admin/waterSupply", { state: { value: 1 } });
    }
    if (tabType === "completedOrder") {
      navigate("/admin/waterSupply", { state: { value: 2 } });
    }
  };
  return (
    <>
      <Index.Box className="">
        <Index.Box className="barge-common-box">
          <Index.Box className="title-header">
            <Index.Box className="title-header-flex">
              <Index.Box className="title-main mb-10">
                <Index.Typography
                  variant="p"
                  component="p"
                  className="page-title"
                >
                  {editData
                    ? "Edit Water Supply Order"
                    : "Add Water Supply Order"}
                </Index.Typography>
              </Index.Box>
              <Index.Box className="common-button blue-button save-btn ">
                <Index.Button
                  variant="contained"
                  type="submit"
                  className="Particular_model_submit ml-0 cancel-order-btn"
                  onClick={backBtn}
                >
                  Back
                </Index.Button>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Formik
            // enableReinitialize
            enableReinitialize={editData ? true : false}
            initialValues={initialValues}
            onSubmit={handleFormSubmit}
          >
            {({
              values,
              errors,
              touched,
              setFieldValue,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <form onSubmit={handleSubmit}>
                <Index.Box className="">
                  <Index.Grid container spacing={2}>
                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Name Of Vessel
                      </Index.Typography>
                      <Index.Box className="input-design-div with-border">
                        <Autocomplete
                          className="invoice-select-drop enquiry-vessel-autocomplete"
                          fullWidth
                          value={values?.vesselName}
                          onChange={(event, newValue) => {
                            if (typeof newValue === "string") {
                              setFieldValue("vesselName", newValue);
                            } else if (newValue && newValue?.inputValue) {
                              setFieldValue("vesselName", newValue?.inputValue);
                            } else {
                              setFieldValue("vesselName", newValue?.vesselName);
                            }
                          }}
                          onBlur={(event, newValue) => {
                            setFieldValue("vesselName", event.target.value);
                          }}
                          filterOptions={(options, params) => {
                            const filtered = filter(options, params);
                            const { inputValue } = params;

                            // Suggest the creation of a new value
                            const isExisting = options?.some(
                              (option) => inputValue === option?.vesselName
                            );

                            if (inputValue !== "" && !isExisting) {
                              filtered.push({
                                inputValue,
                                vesselName: `Add "${inputValue}"`,
                              });
                            }

                            return filtered;
                          }}
                          selectOnFocus
                          clearOnBlur
                          handleHomeEndKeys
                          id="free-solo-with-text-demo"
                          options={vesselNameList}
                          getOptionLabel={(option) => {
                            // Value selected with enter, right from the input
                            if (typeof option === "string") {
                              return option;
                            }
                            // Add "xyz" option created dynamically
                            if (option?.inputValue) {
                              return option?.inputValue;
                            }
                            // Regular option
                            return option?.vesselName;
                          }}
                          renderOption={(props, option) => (
                            <li {...props}>{option?.vesselName}</li>
                          )}
                          freeSolo
                          renderInput={(params) => (
                            <Index.TextField
                              className="admin-input-design input-placeholder"
                              {...params}
                              fullWidth
                              placeholder="Name of vessel"
                              // label="Free solo with text demo"
                            />
                          )}
                        />
                        {/* {touched?.vesselName && errors?.vesselName && (
                          <Index.FormHelperText error>
                            {errors?.vesselName}
                          </Index.FormHelperText>
                        )} */}
                      </Index.Box>
                    </Index.Grid>

                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Vessel ETA
                      </Index.Typography>
                      <Index.Box className="input-design-div">
                        <Index.Box className="set-date set-date-picker">
                          <Index.LocalizationProvider
                            dateAdapter={Index.AdapterDayjs}
                          >
                            <Index.DemoContainer
                              components={[
                                "Index.DateTimePicker",
                                "Index.DateTimePicker",
                                "Index.DateTimePicker",
                              ]}
                            >
                              <DateTimePicker
                                views={[
                                  "year",
                                  "month",
                                  "day",
                                  "hours",
                                  "minutes",
                                ]}
                                timeSteps={{
                                  hours: 1,
                                  minutes: 1,
                                }}
                                id="vesselEta"
                                defaultValue={
                                  values?.vesselEta
                                    ? dayjs(
                                        Index.moment(
                                          editData?.vesselEta
                                        ).format("YYYY-MM-DDTHH:mm")
                                      )
                                    : ""
                                }
                                onChange={(value) => {
                                  const formatedDate = Index.moment(
                                    value.$d
                                  ).format("YYYY-MM-DDTHH:mm:ssZZ");
                                  setFieldValue("vesselEta", formatedDate);
                                }}
                                slotProps={{
                                  textField: {
                                    readOnly: true,
                                  },
                                }}
                                format="DD/MM/YYYY HH:mm"
                              />
                            </Index.DemoContainer>
                          </Index.LocalizationProvider>
                        </Index.Box>
                      </Index.Box>
                    </Index.Grid>

                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Vessel ETB
                      </Index.Typography>
                      <Index.Box className="input-design-div">
                        <Index.Box className="set-date set-date-picker">
                          <Index.LocalizationProvider
                            dateAdapter={Index.AdapterDayjs}
                          >
                            <Index.DemoContainer
                              components={[
                                "Index.DateTimePicker",
                                "Index.DateTimePicker",
                                "Index.DateTimePicker",
                              ]}
                            >
                              <DateTimePicker
                                views={[
                                  "year",
                                  "month",
                                  "day",
                                  "hours",
                                  "minutes",
                                ]}
                                timeSteps={{
                                  hours: 1,
                                  minutes: 1,
                                }}
                                id="vesselEtb"
                                defaultValue={
                                  values?.vesselEtb
                                    ? dayjs(
                                        Index.moment(
                                          editData?.vesselEtb
                                        ).format("YYYY-MM-DDTHH:mm")
                                      )
                                    : ""
                                }
                                onChange={(value) => {
                                  const formatedDate = Index.moment(
                                    value.$d
                                  ).format("YYYY-MM-DDTHH:mm:ssZZ");
                                  setFieldValue("vesselEtb", formatedDate);
                                }}
                                slotProps={{
                                  textField: {
                                    readOnly: true,
                                  },
                                }}
                                format="DD/MM/YYYY HH:mm"
                              />
                            </Index.DemoContainer>
                          </Index.LocalizationProvider>
                        </Index.Box>
                      </Index.Box>
                    </Index.Grid>

                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Vessel ETD
                      </Index.Typography>
                      <Index.Box className="input-design-div">
                        <Index.Box className="set-date set-date-picker">
                          <Index.LocalizationProvider
                            dateAdapter={Index.AdapterDayjs}
                          >
                            <Index.DemoContainer
                              components={[
                                "Index.DateTimePicker",
                                "Index.DateTimePicker",
                                "Index.DateTimePicker",
                              ]}
                            >
                              <DateTimePicker
                                views={[
                                  "year",
                                  "month",
                                  "day",
                                  "hours",
                                  "minutes",
                                ]}
                                timeSteps={{
                                  hours: 1,
                                  minutes: 1,
                                }}
                                id="vesselEtd"
                                defaultValue={
                                  values?.vesselEtd
                                    ? dayjs(
                                        Index.moment(
                                          editData?.vesselEtd
                                        ).format("YYYY-MM-DDTHH:mm")
                                      )
                                    : ""
                                }
                                onChange={(value) => {
                                  const formatedDate = Index.moment(
                                    value.$d
                                  ).format("YYYY-MM-DDTHH:mm:ssZZ");
                                  setFieldValue("vesselEtd", formatedDate);
                                }}
                                slotProps={{
                                  textField: {
                                    readOnly: true,
                                  },
                                }}
                                format="DD/MM/YYYY HH:mm"
                              />
                            </Index.DemoContainer>
                          </Index.LocalizationProvider>
                        </Index.Box>
                      </Index.Box>
                    </Index.Grid>

                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        C/F
                      </Index.Typography>
                      <Index.Box className="input-design-div with-border">
                        <Index.Select
                          className="placeholder-set-text-box"
                          fullWidth
                          name="coastal"
                          value={values?.coastal}
                          displayEmpty
                          inputProps={{ "aria-label": "Without label" }}
                          defaultValue={values ? values?.coastal : ""}
                          onChange={(e) => {
                            setFieldValue("coastal", e.target.value);
                          }}
                        >
                          <Index.MenuItem value={""}>
                            <Index.MenuItem
                              disabled
                              className="all-select-label select-label-font"
                            >
                              Select C/F
                            </Index.MenuItem>
                          </Index.MenuItem>
                          {coastalList?.map((items, index) => (
                            <Index.MenuItem value={items?.name} key={items?.id}>
                              {items?.name}
                            </Index.MenuItem>
                          ))}
                        </Index.Select>
                      </Index.Box>
                    </Index.Grid>

                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Quantity
                      </Index.Typography>
                      <Index.Box className="input-design-div with-border">
                        <Index.TextField
                          hiddenLabel
                          id="filled-hidden-label-normal"
                          placeholder="Quantity"
                          variant="filled"
                          type="number"
                          className="admin-input-design input-placeholder"
                          name="qty"
                          onFocus={handleFocus}
                          // onChange={handleChange}
                          onChange={(event) => {
                            const qtyData = event?.target?.value?.replace(
                              /^(\d*\.\d{0,3}|\.\d{1,3})\d*$/,
                              "$1"
                            );
                            setFieldValue("qty", qtyData);
                          }}
                          onBlur={handleBlur}
                          value={values?.qty}
                        />
                      </Index.Box>
                    </Index.Grid>

                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Water Type
                      </Index.Typography>
                      <Index.Box className="input-design-div with-border">
                        <Index.Select
                          className="placeholder-set-text-box"
                          fullWidth
                          name="waterType"
                          value={values?.waterType}
                          displayEmpty
                          inputProps={{ "aria-label": "Without label" }}
                          defaultValue={values ? values?.waterType : ""}
                          onChange={(e) => {
                            setFieldValue("waterType", e.target.value);
                          }}
                        >
                          <Index.MenuItem value={""}>
                            <Index.MenuItem
                              disabled
                              className="all-select-label select-label-font"
                            >
                              Select water type
                            </Index.MenuItem>
                          </Index.MenuItem>
                          {waterTypeList?.map((items, index) => (
                            <Index.MenuItem value={items?.type} key={items?.id}>
                              {items?.type}
                            </Index.MenuItem>
                          ))}
                        </Index.Select>
                      </Index.Box>
                    </Index.Grid>

                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Agent Name
                      </Index.Typography>
                      <Index.Box className="input-design-div with-border">
                        <Index.TextField
                          hiddenLabel
                          id="filled-hidden-label-normal"
                          placeholder="Agent name"
                          variant="filled"
                          className="admin-input-design input-placeholder"
                          name="agentName"
                          onFocus={handleFocus}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values?.agentName}
                        />
                      </Index.Box>
                    </Index.Grid>

                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Port
                      </Index.Typography>
                      <Index.Box className="input-design-div with-border">
                        {editData?._id && values?.port ? (
                          allPortList?.length && (
                            <Index.Autocomplete
                              disablePortal
                              className="invoice-select-drop"
                              id="combo-box-demo"
                              options={allPortList}
                              getOptionLabel={(option) =>
                                option?.supplyPortName
                              }
                              value={
                                allPortList[
                                  allPortList.findIndex(
                                    (e) => e._id === values?.port
                                  )
                                ]
                              }
                              renderOption={(props, option) => (
                                <Index.Box
                                  component="li"
                                  sx={{
                                    "& > img": { mr: 2, flexShrink: 0 },
                                  }}
                                  {...props}
                                >
                                  {option?.supplyPortName}
                                </Index.Box>
                              )}
                              onChange={(event, newValue) => {
                                setFieldValue("port", newValue?._id);
                              }}
                              renderInput={(params) => (
                                <Index.TextField
                                  {...params}
                                  placeholder="Port"
                                  variant="outlined"
                                />
                              )}
                            />
                          )
                        ) : (
                          <Index.Autocomplete
                            disablePortal
                            className="invoice-select-drop"
                            id="combo-box-demo"
                            options={allPortList}
                            getOptionLabel={(option) => option?.supplyPortName}
                            renderOption={(props, option) => (
                              <Index.Box
                                component="li"
                                sx={{
                                  "& > img": { mr: 2, flexShrink: 0 },
                                }}
                                {...props}
                              >
                                {option?.supplyPortName}
                              </Index.Box>
                            )}
                            onChange={(event, newValue) => {
                              setFieldValue("port", newValue?._id);
                            }}
                            renderInput={(params) => (
                              <Index.TextField
                                {...params}
                                placeholder="Port"
                                variant="outlined"
                              />
                            )}
                          />
                        )}
                      </Index.Box>
                    </Index.Grid>

                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Order By
                      </Index.Typography>
                      <Index.Box className="input-design-div with-border">
                        <Index.TextField
                          hiddenLabel
                          id="filled-hidden-label-normal"
                          placeholder="Order by"
                          variant="filled"
                          className="admin-input-design input-placeholder"
                          name="orderBy"
                          onFocus={handleFocus}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values?.orderBy}
                        />
                      </Index.Box>
                    </Index.Grid>

                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Supply Date & Time
                      </Index.Typography>
                      <Index.Box className="input-design-div">
                        <Index.Box className="set-date set-date-picker">
                          <Index.LocalizationProvider
                            dateAdapter={Index.AdapterDayjs}
                          >
                            <Index.DemoContainer
                              components={[
                                "Index.DateTimePicker",
                                "Index.DateTimePicker",
                                "Index.DateTimePicker",
                              ]}
                            >
                              <DateTimePicker
                                views={[
                                  "year",
                                  "month",
                                  "day",
                                  "hours",
                                  "minutes",
                                ]}
                                timeSteps={{
                                  hours: 1,
                                  minutes: 1,
                                }}
                                id="supplyDateTime"
                                defaultValue={
                                  values?.supplyDateTime
                                    ? dayjs(
                                        Index.moment(
                                          editData?.supplyDateTime
                                        ).format("YYYY-MM-DDTHH:mm")
                                      )
                                    : ""
                                }
                                onChange={(value) => {
                                  const formatedDate = Index.moment(
                                    value.$d
                                  ).format("YYYY-MM-DDTHH:mm:ssZZ");
                                  setFieldValue("supplyDateTime", formatedDate);
                                }}
                                slotProps={{
                                  textField: {
                                    readOnly: true,
                                  },
                                }}
                                format="DD/MM/YYYY HH:mm"
                              />
                            </Index.DemoContainer>
                          </Index.LocalizationProvider>
                        </Index.Box>
                      </Index.Box>
                    </Index.Grid>
                    {values?.waterType === "Freshwater" ? (
                      <>
                        <Index.Grid item xs={12} sm={6} md={4}>
                          <Index.Typography
                            variant="label"
                            component="label"
                            className="input-label"
                          >
                            Water Barge Name
                          </Index.Typography>
                          <Index.Box className="input-design-div">
                            <Index.Box className="set-date set-date-picker">
                              <Index.Select
                                className="placeholder-set-text-box"
                                fullWidth
                                name="waterBarge"
                                value={values?.waterBarge}
                                displayEmpty
                                inputProps={{ "aria-label": "Without label" }}
                                defaultValue={values?.waterBarge}
                                onChange={handleChange}
                              >
                                <Index.MenuItem value={""}>
                                  <Index.MenuItem
                                    disabled
                                    className="all-select-label select-label-font"
                                  >
                                    Select Water Barge Name
                                  </Index.MenuItem>
                                </Index.MenuItem>
                                {freshWateredBarges?.map((items, index) => (
                                  <Index.MenuItem
                                    value={items?._id}
                                    key={items?._id}
                                  >
                                    {items?.bargeName}
                                  </Index.MenuItem>
                                ))}
                              </Index.Select>
                            </Index.Box>
                          </Index.Box>
                        </Index.Grid>
                      </>
                    ) : (
                      <></>
                    )}
                  </Index.Grid>

                  <Index.Box className="line" mt={3} mb={4}></Index.Box>
                </Index.Box>
                <Index.Box className="common-button blue-button flex-end save-btn">
                  <Index.Button
                    variant="contained"
                    type="submit"
                    disabled={loading}
                  >
                    {editData ? "Update" : "Submit"}
                  </Index.Button>
                </Index.Box>
              </form>
            )}
          </Formik>
        </Index.Box>
      </Index.Box>
    </>
  );
}

export default WaterSupplyAdd;
