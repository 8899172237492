import { useEffect, useState } from "react";
import Index from "../../../Index";
import { getPipelineSurveyourSuppliesList } from "../../../../redux/admin/action";

function PipelineSurveyourSupplyTiming({ id }) {
  const dispatch = Index.useDispatch();
  const [pipelineSupplyData, setPipelineSupplyData] = useState("");

  // when the get pipeline surveyour listing then used...
  const handleGetPipelinSurveyourSupplies = () => {
    dispatch(getPipelineSurveyourSuppliesList({ orderId: id }))
      .then((res) => {
        if (res.status === 200) {
          setPipelineSupplyData(res.data);
        } else {
          setPipelineSupplyData("");
        }
      })
      .catch((error) => {
        console.error(error);
        setPipelineSupplyData("");
      });
  };

  // when the page first time get then used...
  useEffect(() => {
    handleGetPipelinSurveyourSupplies();
  }, []);

  return (
    <>
      <Index.Grid container spacing={1}>
        <Index.Grid item xs={12} sm={6}>
          <Index.Box className="invoice-detail-item">
            <Index.Box className="input-label opacity-60">
              Along Side Pipeline:
            </Index.Box>
            <Index.Box className="input-label">
              {pipelineSupplyData?.alongsidePipeline
                ? Index.moment(pipelineSupplyData?.alongsidePipeline).format(
                    "DD/MM/YYYY hh:mm:ss A"
                  )
                : "-"}
            </Index.Box>
          </Index.Box>
        </Index.Grid>
        <Index.Grid item xs={12} sm={6}>
          <Index.Box className="invoice-detail-item">
            <Index.Box className="input-label opacity-60">
              Hose Connect:
            </Index.Box>
            <Index.Box className="input-label">
              {pipelineSupplyData?.hoseConnect
                ? Index.moment(pipelineSupplyData?.hoseConnect).format(
                    "DD/MM/YYYY hh:mm:ss A"
                  )
                : "-"}
            </Index.Box>
          </Index.Box>
        </Index.Grid>
        <Index.Grid item xs={12} sm={6}>
          <Index.Box className="invoice-detail-item">
            <Index.Box className="input-label opacity-60">
              Loading Start:
            </Index.Box>
            <Index.Box className="input-label">
              {pipelineSupplyData?.loadingStart
                ? Index.moment(pipelineSupplyData?.loadingStart).format(
                    "DD/MM/YYYY hh:mm:ss A"
                  )
                : "-"}
            </Index.Box>
          </Index.Box>
        </Index.Grid>
        <Index.Grid item xs={12} sm={6}>
          <Index.Box className="invoice-detail-item">
            <Index.Box className="input-label opacity-60">
              Loading Completed:
            </Index.Box>
            <Index.Box className="input-label">
              {pipelineSupplyData?.loadingCompleted
                ? Index.moment(pipelineSupplyData?.loadingCompleted).format(
                    "DD/MM/YYYY hh:mm:ss A"
                  )
                : "-"}
            </Index.Box>
          </Index.Box>
        </Index.Grid>
        <Index.Grid item xs={12} sm={6}>
          <Index.Box className="invoice-detail-item">
            <Index.Box className="input-label opacity-60">
              Loading Rate:
            </Index.Box>
            <Index.Box className="input-label">
              {pipelineSupplyData?.loadingRate
                ? pipelineSupplyData?.loadingRate
                : "-"}
            </Index.Box>
          </Index.Box>
        </Index.Grid>
        <Index.Grid item xs={12} sm={6}>
          <Index.Box className="invoice-detail-item">
            <Index.Box className="input-label opacity-60">
              Loaded Qty:
            </Index.Box>
            <Index.Box className="input-label">
              {pipelineSupplyData?.loadedQty
                ? pipelineSupplyData?.loadedQty
                : "-"}
            </Index.Box>
          </Index.Box>
        </Index.Grid>
        <Index.Grid item xs={12} sm={6}>
          <Index.Box className="invoice-detail-item">
            <Index.Box className="input-label opacity-60">Cast Off:</Index.Box>
            <Index.Box className="input-label">
              {pipelineSupplyData?.castOff
                ? Index.moment(pipelineSupplyData?.castOff).format(
                    "DD/MM/YYYY hh:mm:ss A"
                  )
                : "-"}
            </Index.Box>
          </Index.Box>
        </Index.Grid>
        <Index.Grid item xs={12} sm={6}>
          <Index.Box className="invoice-detail-item">
            <Index.Box className="input-label opacity-60">BDN:</Index.Box>
            <Index.Box className="input-label">
              {pipelineSupplyData?.loadingRate?.length
                ? pipelineSupplyData?.loadingRate?.map((el) => {
                    <li>{el}</li>;
                  })
                : "-"}
            </Index.Box>
          </Index.Box>
        </Index.Grid>
      </Index.Grid>
    </>
  );
}
export default PipelineSurveyourSupplyTiming;
