import React, { useEffect, useState } from "react";
import Index from "../../../../Index";
import {
  cancelSingleSlopeSludgeOrder,
  getSingleSlopeSludgeOrder,
} from "../../../../../redux/admin/action";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useLocation } from "react-router-dom";
import OrderStatusModel from "../../../../../component/common/OrderStatusModel";
import TankerShiftModel from "../../orderadd/TankerShiftModel";
import SlopeAndSludgeDetails from "./SlopeAndSludgeDetails";
import SlopeAndSludgeChallanList from "./SlopeAndSludgeChallanList";
import SlopeAndSludgeChallanEditRequest from "./SlopeAndSludgeChallanEditRequest";
import SlopeAndSludgeActivityLog from "./SlopeAndSludgeActivityLog";
import SlopeAndSludgeTiming from "./SlopeAndSludgeTiming";
// import EditSlopeAndSludgeTiming from "../EditSlopeAndSludgeTiming";

// Design Tab
function SingleOrderTab(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Index.Box sx={{ p: 3 }}>
          <Index.Typography>{children}</Index.Typography>
        </Index.Box>
      )}
    </div>
  );
}

SingleOrderTab.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function SingleSlopeAndSludgeTab() {
  const navigate = Index.useNavigate();
  const dispatch = Index.useDispatch();
  const { id } = Index.useParams();
  const { state } = useLocation();
  const tabType = state?.type;
  const { invoiceActivityLogList } = Index.useSelector(
    (state) => state.AdminReducer
  );

  // state
  const [viewData, setViewData] = useState({});
  const [challanData, setChallanData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingRowId, setLoadingRowId] = useState();
  const [value, setValue] = useState(state?.value ? state?.value : 0);
  const [challanRequestData, setChallanRequestData] = useState([]);

  const [showModal, setShowModal] = useState(true);
  const [isShifted, setIsShifted] = useState(false);
  const [tankerLoadingDetail, setTankerLoadingDetail] = useState({});
  const [loadingTankers, setLoadingTankers] = useState([]);
  const [openModalId, setOpenModalId] = useState();
  const [cancelOpen, setCancelOpen] = useState(false);
  const [status, setStatus] = useState(true);

  const [tankerOpen, setTankerOpen] = useState(false);
  const [loadingSingleData, setLoadingSingleData] = useState(false);

  // state for checkbox
  const [tankerCheckId, setTankerCheckId] = useState([]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // let singleData = state?.row;
  const backBtn = () => {
    let tabValue = 0;
    if (tabType === "cancelOrder") {
      tabValue = 1;
    } else if (tabType === "completedOrder") {
      tabValue = 2;
    }
    navigate("/admin/slopeSludgeOrder", { state: { value: tabValue } });
  };

   const handleEdit = (row) => {
     navigate("/admin/slopeSludgeOrder/add", {
       state: { row },
     });
     if (tabType === "cancelOrder") {
       navigate("/admin/slopeSludgeOrder/add", {
         state: { row, type: "cancelOrder" },
       });
     }
     if (tabType === "completedOrder") {
       navigate("/admin/slopeSludgeOrder/add", {
         state: { row, type: "completedOrder" },
       });
     }
   };

  const getSingleOrderList = () => {
    setLoadingSingleData(true);
    dispatch(getSingleSlopeSludgeOrder({ id })).then((res) => {
      setViewData(res?.data);
      if (res?.status === 200) {
        setTimeout(() => {
          setLoadingSingleData(false);
        }, 1000);
      } else {
        setTimeout(() => {
          setLoadingSingleData(false);
        }, 1000);
      }
    });
  };


 

 

  // get order list
  useEffect(() => {
    getSingleOrderList();
  }, [isShifted]);

  // filter for activity log
  const filterOrderLogData = invoiceActivityLogList.filter((item) => {
    return item.activityTypeId === viewData?._id;
  });




  // useEffect(() => {
  //   if (challanData?.length > 0) {
  //     singleChallanEditRequest();
  //   }
  // }, [challanData]);

  // const handleEditBunkerTiming = (row) => {
  //   console.log(row, 324);
  //   row[0].orderId = id;
  //   navigate("/admin/waterSupply/edit-timing", {
  //     state: { row, type: "editTug", value: value },
  //   });
  //   // navigate("/admin/tugsOrder/tug-timing");
  // };

  // cancel order popup
  const cancelOrderModelOpen = () => {
    // setAnchorEl(null);
    setCancelOpen(true);
  };

  const cancelOrderModelClose = () => {
    setCancelOpen(false);
    // setStatus(true);
  };

  // cancel Order button
  const cancelOrderBtn = (status, comment) => {
    const data = new URLSearchParams();
    data.append("id", id);
    data.append("cancelStatus", "Cancel");
    data.append("comment", comment);

    dispatch(cancelSingleSlopeSludgeOrder(data)).then((res) => {
      if (res?.status === 200) {
        getSingleOrderList();
      }
    });
  };

  // model open for shift tanker
  const shiftTankerHandleOpen = (id) => {
    setTankerOpen(true);
  };
  const shiftTankerHandleClose = () => {
    setTankerOpen(false);
  };

  // tanker dahboard view page
  // const handleTankerDashboardView = (id) => {
  //   navigate(`/admin/tankers/dashboard/view/${id}`);
  // };
  return (
    <>
      {loadingSingleData ? (
        <>
          <Index.Table>
            <Index.TableBody>
              <Index.TableRow>
                <Index.TableCell
                  colSpan={13}
                  align="center"
                  className="loading-purchase"
                >
                  <Index.CircularProgress color="secondary" size={20} />
                </Index.TableCell>
              </Index.TableRow>
            </Index.TableBody>
          </Index.Table>
        </>
      ) : (
        <>
          <Index.Box className="invoice-details">
            <Index.Box className="barge-common-box mb-20">
              <Index.Box className="title-header-flex flex-column-res">
                <Index.Box className="invoice-back-title">
                  <Index.Button
                    type="submit"
                    className="Particular_model_submit back-invoice-btn"
                    onClick={backBtn}
                  >
                    <Index.ArrowBackIcon />
                  </Index.Button>
                  <Index.Typography
                    variant="span"
                    component="span"
                    className="invoice-title"
                  >
                    {/* Tug Order Detail */}
                    {tabType === "cancelOrder"
                      ? "Slope & Sludge Cancelled Order Details"
                      : tabType === "completedOrder"
                      ? "Slope & Sludge Completed Order Details"
                      : "Slope & Sludge Order Details"}
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="common-button blue-button save-btn res-button-width">
                  <Index.Button
                    variant="contained"
                    type="submit"
                    className="Particular_model_submit edit-invoice-btn"
                    onClick={() => handleEdit(viewData)}
                  >
                    <Index.EditIcon />
                  </Index.Button>
                  {viewData?.slopeOrderStatus !== "Cancel" ? (
                    <Index.Button
                      variant="contained"
                      disableRipple
                      onClick={(e) => {
                        cancelOrderModelOpen();
                      }}
                    >
                      Cancel Order
                    </Index.Button>
                  ) : (
                    ""
                  )}
                </Index.Box>
              </Index.Box>

              <Index.Box className="mt-10">
                <Index.Box className="mb-5 invoice-detail-item">
                  <Index.Box className="input-label opacity-60">
                    Vessel Name:
                  </Index.Box>
                  <Index.Box className="input-label">
                    {viewData?.vesselName ? viewData?.vesselName : "-"}
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box className="invoice-detail-item">
                <Index.Box className="input-label opacity-60">
                  Slope Documents(Image/PDF):
                </Index.Box>

                {viewData?.slopeDocument &&
                  viewData?.slopeDocument.map((item, index) => (
                    <>
                      <Index.Box className="input-label pdf-input Wharfage-pdf">
                        <Index.Button
                          className="download-pdf-pd"
                          key={index}
                          onClick={() => {
                            window.open(
                              `${process.env.REACT_APP_IMAGE_URL}/slopeSludgeDoc/${item}`
                            );
                          }}
                        >
                          {index + 1}. {item ? item : "-"}
                        </Index.Button>
                      </Index.Box>
                    </>
                  ))}
              </Index.Box>
            </Index.Box>

            <Index.Box className="mb-20">
              {/* Design Tab */}
              <Index.Box sx={{ width: "100%" }}>
                <Index.Box
                  className="tab-button-box"
                  sx={{ borderBottom: 1, borderColor: "divider" }}
                >
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    className="dashboard-tab"
                  >
                    <Tab
                      className="tab-button"
                      label="Details"
                      {...a11yProps(0)}
                    />

                    <Tab
                      className="tab-button"
                      label="Challan List"
                      {...a11yProps(1)}
                    />

                    <Tab
                      className="tab-button"
                      label="Slope & Sludge Timing"
                      {...a11yProps(2)}
                    />

                    <Tab
                      className="tab-button"
                      label="Challan Edit Request"
                      {...a11yProps(3)}
                    />

                    <Tab
                      className="tab-button"
                      label="Activity"
                      {...a11yProps(4)}
                    />
                  </Tabs>
                </Index.Box>

                <SingleOrderTab
                  value={value}
                  index={0}
                  className="barge-common-box tab-content-box"
                >
                  <SlopeAndSludgeDetails viewData={viewData} />
                </SingleOrderTab>

                <SingleOrderTab
                  value={value}
                  index={1}
                  className="barge-common-box tab-content-box"
                >
                  <SlopeAndSludgeChallanList id={id} />
                </SingleOrderTab>

                <SingleOrderTab
                  value={value}
                  index={2}
                  className="barge-common-box tab-content-box"
                >
                  <SlopeAndSludgeTiming id={id} value={value} />
                </SingleOrderTab>

                <SingleOrderTab
                  value={value}
                  index={3}
                  className="barge-common-box tab-content-box"
                >
                  <SlopeAndSludgeChallanEditRequest id={id} />
                </SingleOrderTab>

                <SingleOrderTab
                  value={value}
                  index={4}
                  className="barge-common-box tab-content-box"
                >
                  <SlopeAndSludgeActivityLog id={id} />
                </SingleOrderTab>
              </Index.Box>
              {/* Design Tab */}
            </Index.Box>
          </Index.Box>
        </>
      )}
      <OrderStatusModel
        updateOpen={cancelOpen}
        handleClose={cancelOrderModelClose}
        inquiryStatusUpdate={cancelOrderBtn}
        modalType={status ? "Comment" : ""}
      />
      <TankerShiftModel
        shiftTankerHandleOpen={shiftTankerHandleOpen}
        shiftTankerHandleClose={shiftTankerHandleClose}
        tankerOpen={tankerOpen}
        setTankerOpen={setTankerOpen}
        orderId={id}
        status={viewData?.orderStatus}
        shiftTankerStatus={viewData?.tankerShifted}
        getSingleOrderList={getSingleOrderList}
        setIsShifted={setIsShifted}
        tankerCheckId={tankerCheckId}
        // setUpdateTanker={setUpdateTanker}
      />
    </>
  );
}

export default SingleSlopeAndSludgeTab;
