import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import { getUserList, deleteUser } from "../../../../redux/admin/action";

const Search = Index.styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: Index.alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: Index.alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));
const ActionItem = Index.styled(Index.MenuItem)(() => ({
  fontFamily: "poppins",
  lineHeight: "15px",
  fontSize: "14px",
  fontWeight: "400",
  color: "#595F69",
}));
const StyledInputBase = Index.styled(Index.InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

// style for model
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function UserList() {
  const dispatch = Index.useDispatch();
  const navigate = Index.useNavigate();
  const { userList } = Index.useSelector((state) => state.AdminReducer);
  const [loading, setLoading] = useState(false);
  const [userImg, setUserImg] = useState("");
  const [nominationFromDate, setNominationFromDate] = useState();
  const [nominationToDate, setNominationToDate] = useState();
  const [nominationFromError, setNominationFromError] = useState();
  const [nominationToError, setNominationToError] = useState();

  // Delete record state
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [rowId, setRowId] = useState("");

  // State for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState(0);
  const [paginationPerPage, setPaginationPerPage] = useState(50);

  // State for searching and set data
  const [searchedData, setSearchedData] = useState([]);
  const [currentRows, setCurrentRows] = useState([]);

  const [anchorEl, setAnchorEl] = useState(null);
  const [actionIndex, setActionIndex] = useState();

  const handleClickMenu = (e, index) => {
    setActionIndex(index);
    setAnchorEl(e.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  // console.log(userList,'userList')
  // get user
  const getUser = () => {
    setLoading(true);
    dispatch(getUserList()).then((res) => {
      if (res?.status === 200) {
        setTimeout(() => {
          setLoading(false);
        }, 2000);
      } else {
        setTimeout(() => {
          setLoading(false);
        }, 2000);
      }
    });
  };
  useEffect(() => {
    getUser();
  }, []);

  function isValidDateFormat(input) {
    // Regular expression for YYYY-MM-DD format
    const dateFormat = /^\d{4}-\d{2}-\d{2}$/;
    return dateFormat.test(input);
  }

  const handleFilterSubmit = (anchor) => {
    if (!nominationFromDate) {
      setNominationFromError("Please select date");
    }

    if (!nominationToDate) {
      setNominationToError("Please select data");
    }

    if (
      nominationFromDate &&
      nominationToDate &&
      isValidDateFormat(nominationFromDate) &&
      isValidDateFormat(nominationToDate)
    ) {
      const filterData = userList?.filter((ele) => {
        return (
          Index.moment(ele?.createdAt, "YYYY-MM-DD").isSameOrAfter(
            Index.moment(nominationFromDate, "YYYY-MM-DD"),
            "day"
          ) &&
          Index.moment(ele?.createdAt, "YYYY-MM-DD").isSameOrBefore(
            Index.moment(nominationToDate, "YYYY-MM-DD"),
            "day"
          )
        );
      });
      setSearchedData(filterData);
      setState({ ...state, ["right"]: false });
    }
  };

  const handleFilterClear = (anchor) => {
    toggleDrawer(anchor, false);
    setNominationFromDate(null);
    setNominationToDate(null);
    setSearchedData(userList);
    setState({ ...state, ["right"]: false });
  };

  // Copy original data to searched data
  useEffect(() => {
    setSearchedData(userList?.reverse());
  }, [userList]);

  // Search on table
  const requestSearch = (searched) => {
    if (!searched) return setSearchedData(userList);
    setSearchedData(
      userList?.filter(
        (item) =>
          item?.firstName
            ?.toString()
            .toLowerCase()
            .includes(searched.toLowerCase()) ||
          item?.lastName
            ?.toString()
            .toLowerCase()
            .includes(searched.toLowerCase()) ||
          item?.email
            ?.toString()
            ?.toLowerCase()
            .includes(searched.toLowerCase()) ||
          item?.mobileNumber
            ?.toString()
            .toLowerCase()
            .includes(searched?.toLowerCase()) ||
          item?.address?.toString().includes(searched.toLowerCase()) ||
          item?.permission?.roleName
            ?.toString()
            ?.toLowerCase()
            .includes(searched.toLowerCase())
      )
    );
    setCurrentPage(1);
  };

  // Pagination calculation start
  const PER_PAGE = paginationPerPage;
  useEffect(() => {
    //Page count
    const count = Math.ceil(searchedData?.length / PER_PAGE);
    setCount(count);
    const indexOfLastRow = currentPage * PER_PAGE;
    const indexOfFirstRow = indexOfLastRow - PER_PAGE;
    const modifiedRows = searchedData?.slice(indexOfFirstRow, indexOfLastRow);
    setCurrentRows(modifiedRows);
    if (modifiedRows?.length === 0 && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  }, [currentPage, searchedData, paginationPerPage, PER_PAGE]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };
  // Pagination calculation end

  // filter
  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const handleDeleteOpen = () => {
    setAnchorEl(null);
    setDeleteOpen(true);
  };

  const handleDeleteClose = () => {
    setDeleteOpen(false);
  };

  const handleDeleteRecord = () => {
    const data = {
      id: rowId,
    };
    dispatch(deleteUser(data));
    handleDeleteClose();
  };

  const handleEdit = (row) => {
    // console.log("edit-user-item", item);
    const values = {
      _id: row?._id,
      firstName: row?.firstName,
      lastName: row?.lastName,
      email: row?.email,
      password: row?.password,
      mobileNumber: row?.mobileNumber,
      address: row?.address,
      image: row?.image,
      permission: row?.permission,
      permissionId: row?.permission?._id,
      companyId: row?.companyId,
    };

    // console.log("values", values);
    // console.log("!!!!!!!!!", {
    //   ...values,
    //   companyId: item?.companyId?.map((el) => el?._id),
    // });

    // navigate("/admin/users/add", {
    //   state: {
    //     item },
    //   },
    // });
    console.log("row", row);
    navigate("/admin/users/add", {
      state: { values },
    });
  };

  const userView = (id) => {
    navigate(`/admin/users/view-users/${id}`);
  };
  // image model for Zoom -out
  const [open, setOpen] = useState(false);
  const handleOpen = (img) => {
    setOpen(true);
    setUserImg(img);
  };
  const handleClose = () => setOpen(false);
  return (
    <>
      <Index.DynamicTitle title="Users" />
      <Index.Box className="">
        <Index.Box className="barge-common-box">
          <Index.Box className="title-header">
            <Index.Box className="title-header-flex res-title-header-flex">
              <Index.Box className="title-main">
                <Index.Typography
                  variant="p"
                  component="p"
                  className="page-title"
                >
                  Users
                </Index.Typography>
              </Index.Box>
              <Index.Box className="d-flex align-items-center res-set-search">
                <Search className="search ">
                  <StyledInputBase
                    placeholder="Search"
                    inputProps={{ "aria-label": "search" }}
                    onChange={(e) =>
                      setTimeout(() => {
                        requestSearch(e.target.value.trim());
                      }, 1000)
                    }
                  />
                </Search>

                <Index.Box className="common-button blue-button res-blue-button">
                  <Index.RouteLink
                    to="/admin/users/add"
                    className="no-text-decoration"
                  >
                    {" "}
                    <Index.Button variant="contained" disableRipple>
                      Add User
                    </Index.Button>
                  </Index.RouteLink>
                </Index.Box>
                <Index.Box className="filter-main">
                  {["right"].map((anchor) => (
                    <React.Fragment key={anchor}>
                      <Index.Box className="filter-btn-main common-button filter-btn blue-button ">
                        <Index.Button
                          className="filter-btn"
                          onClick={toggleDrawer(anchor, true)}
                          disableRipple
                        >
                          <img src={Index.Svg.filter} alt="" />
                        </Index.Button>
                      </Index.Box>
                      <Index.SwipeableDrawer
                        className="filter-main"
                        anchor={anchor}
                        open={state[anchor]}
                        onClose={toggleDrawer(anchor, false)}
                        onOpen={toggleDrawer(anchor, true)}
                      >
                        <Index.Box className="filter-header">
                          <Index.Box className="d-flex align-items-center filter-header-inner">
                            <Index.Typography className="filter-title">
                              Users
                            </Index.Typography>
                            <Index.Box className="close-btn filter-close-btn">
                              <img
                                src={Index.Svg.close}
                                onClick={toggleDrawer(anchor, false)}
                                alt=""
                              />
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                        <Index.Box className="filter-inner-main">
                          <Index.Box>
                            <Index.Box>
                              <Index.Box>
                                <Index.Typography
                                  variant="label"
                                  component="label"
                                  className="input-label"
                                >
                                  Nomination From
                                </Index.Typography>
                                <Index.Box className="input-design-div with-border filter-date-overflow">
                                  <Index.LocalizationProvider
                                    dateAdapter={Index.AdapterDayjs}
                                  >
                                    <Index.DemoContainer
                                      components={["DatePicker"]}
                                      className="filter-demo-item"
                                    >
                                      <Index.DemoItem label="">
                                        <Index.DatePicker
                                          fullWidth
                                          disableFuture
                                          format="DD/MM/YYYY"
                                          value={nominationFromDate}
                                          className="filter-datepicker"
                                          // defaultValue={nominationFromDate}
                                          onChange={(value) => {
                                            const formatedDate = Index.moment(
                                              value.$d
                                            ).format("YYYY-MM-DD");
                                            setNominationFromError("");
                                            setNominationFromDate(formatedDate);
                                          }}
                                        />
                                      </Index.DemoItem>
                                    </Index.DemoContainer>
                                  </Index.LocalizationProvider>
                                  <Index.FormHelperText error>
                                    {nominationFromError}
                                  </Index.FormHelperText>
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                          <Index.Box className="mt-10">
                            <Index.Box>
                              <Index.Box>
                                <Index.Typography
                                  variant="label"
                                  component="label"
                                  className="input-label"
                                >
                                  Nomination To
                                </Index.Typography>
                                <Index.Box className="input-design-div with-border filter-date-overflow">
                                  <Index.LocalizationProvider
                                    dateAdapter={Index.AdapterDayjs}
                                  >
                                    <Index.DemoContainer
                                      components={["DatePicker"]}
                                    >
                                      <Index.DemoItem label="">
                                        <Index.DatePicker
                                          fullWidth
                                          disableFuture
                                          format="DD/MM/YYYY"
                                          value={nominationToDate}
                                          name="nomination_rec_date"
                                          className="filter-datepicker"
                                          onChange={(value) => {
                                            const formatedDate = Index.moment(
                                              value.$d
                                            ).format("YYYY-MM-DD");
                                            setNominationToError("");
                                            setNominationToDate(formatedDate);
                                          }}
                                        />
                                      </Index.DemoItem>
                                    </Index.DemoContainer>
                                  </Index.LocalizationProvider>
                                  <Index.FormHelperText error>
                                    {nominationToError}
                                  </Index.FormHelperText>
                                </Index.Box>
                              </Index.Box>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                        <Index.Box className="filter-footer ">
                          <Index.Box className="flex-end">
                            <Index.Box className="common-button blue-button mr-15">
                              <Index.Button
                                variant="contained"
                                disableRipple
                                onClick={() => handleFilterSubmit(anchor)}
                              >
                                Filter
                              </Index.Button>
                            </Index.Box>
                            <Index.Box className="common-button outline-button ">
                              <Index.Button
                                variant="contained"
                                disableRippl
                                onClick={() => handleFilterClear(anchor)}
                              >
                                Clear All
                              </Index.Button>
                            </Index.Box>
                          </Index.Box>
                        </Index.Box>
                      </Index.SwipeableDrawer>
                    </React.Fragment>
                  ))}
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Index.Box className="">
            <Index.TableContainer
              component={Index.Paper}
              className="table-container"
            >
              <Index.Table
                aria-label="simple table"
                className="table-design-main supply-table one-line-table invoice-table-set user-management-table "
              >
                <Index.TableHead>
                  <Index.TableRow>
                    <Index.TableCell>Image</Index.TableCell>
                    <Index.TableCell>First Name</Index.TableCell>
                    <Index.TableCell align="left">Last Name</Index.TableCell>
                    <Index.TableCell align="left">Email</Index.TableCell>
                    <Index.TableCell align="left">
                      Mobile Number
                    </Index.TableCell>
                    <Index.TableCell align="left">Role</Index.TableCell>
                    <Index.TableCell align="left">Company</Index.TableCell>
                    <Index.TableCell align="left">Date</Index.TableCell>
                    <Index.TableCell align="left">Address</Index.TableCell>
                    <Index.TableCell align="left">Action</Index.TableCell>
                  </Index.TableRow>
                </Index.TableHead>
                {loading ? (
                  <Index.TableBody>
                    <Index.TableRow>
                      <Index.TableCell
                        colSpan={12}
                        align="center"
                        className="loading-purchase"
                      >
                        <Index.CircularProgress color="secondary" size={20} />
                      </Index.TableCell>
                    </Index.TableRow>
                  </Index.TableBody>
                ) : (
                  <Index.TableBody>
                    {currentRows?.length ? (
                      currentRows?.map((item, index) => (
                        <Index.TableRow key={item?._id}>
                          <Index.TableCell>
                            <Index.Box
                              className="use-img-td"
                              style={{ padding: 0 }}
                            >
                              {/* <div> */}
                              <Index.Button
                                className="p-0"
                                onClick={() => {
                                  handleOpen(item?.image);
                                }}
                              >
                                <Index.Box>
                                  {item?.image ? (
                                    <img
                                      src={`${
                                        process.env.REACT_APP_IMAGE_URL
                                      }/users/${
                                        item?.image ? item?.image : ""
                                      }`}
                                      alt=""
                                      className="challan-img-model"
                                    />
                                  ) : (
                                    <Index.Box className="user-no-img">
                                      <img
                                        src={Index.Png.NoImage}
                                        alt="No_Image"
                                        className="challan-img-model"
                                      />
                                    </Index.Box>
                                  )}
                                </Index.Box>
                              </Index.Button>
                              <Index.Modal
                                open={open}
                                onClose={handleClose}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                              >
                                <Index.Box sx={style}>
                                  <Index.ClearIcon
                                    className="model-close-icon"
                                    onClick={handleClose}
                                  />
                                  <Index.Typography
                                    id="modal-modal-title"
                                    variant="h6"
                                    component="h2"
                                  >
                                    <Index.Box>
                                      <img
                                        src={`${process.env.REACT_APP_IMAGE_URL}/users/${userImg}`}
                                        alt=""
                                        className="challan-img"
                                      />
                                    </Index.Box>
                                  </Index.Typography>
                                </Index.Box>
                              </Index.Modal>
                              {/* </div> */}
                            </Index.Box>
                          </Index.TableCell>
                          <Index.TableCell>
                            {item?.firstName ? item?.firstName : "-"}
                          </Index.TableCell>

                          <Index.TableCell align="left">
                            {item?.lastName ? item?.lastName : "-"}
                          </Index.TableCell>

                          <Index.TableCell align="left">
                            {item?.email ? item?.email : "-"}
                          </Index.TableCell>
                          <Index.TableCell align="left">
                            {item?.mobileNumber ? item?.mobileNumber : "-"}
                          </Index.TableCell>
                          <Index.TableCell align="left">
                            {item?.permission?.roleName
                              ? item?.permission?.roleName
                              : "-"}
                          </Index.TableCell>
                          <Index.TableCell align="left">
                            {item?.companyId?.length
                              ? item?.companyId?.map((el) => (
                                  <li>{el?.name}</li>
                                ))
                              : "-"}
                          </Index.TableCell>
                          <Index.TableCell align="left">
                            {item?.createdAt
                              ? Index.moment(item?.createdAt).format(
                                  "DD/MM/YYYY"
                                )
                              : "-"}
                          </Index.TableCell>
                          <Index.TableCell align="left">
                            {item.address
                              ? item.address?.length > 35
                                ? `${item.address.substring(0, 30)}...`
                                : item.address
                              : "-"}
                          </Index.TableCell>
                          <Index.TableCell sx={{ display: "flex" }}>
                            <Index.IconButton
                              onClick={(e) => {
                                handleClickMenu(e, index);
                              }}
                            >
                              <Index.MenuIcon className="action-menu-icon" />
                            </Index.IconButton>
                            <Index.Menu
                              id="demo-positioned-menu"
                              aria-labelledby="demo-positioned-button"
                              anchorEl={anchorEl}
                              keepMounted
                              elevation={2}
                              open={Boolean(anchorEl)}
                              onClose={handleCloseMenu}
                              anchorOrigin={{
                                vertical: "top",
                                horizontal: "left",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "left",
                              }}
                            >
                              <ActionItem
                                className="action-text"
                                onClick={() => {
                                  handleEdit(currentRows?.[actionIndex]);
                                }}
                              >
                                Edit
                              </ActionItem>
                              <ActionItem
                                className="action-text"
                                onClick={() => {
                                  userView(currentRows?.[actionIndex]?._id);
                                }}
                              >
                                View
                              </ActionItem>
                              <ActionItem
                                className="action-text"
                                onClick={() => {
                                  setRowId(currentRows?.[actionIndex]?._id);
                                  handleDeleteOpen();
                                }}
                              >
                                Delete
                              </ActionItem>
                            </Index.Menu>
                          </Index.TableCell>
                        </Index.TableRow>
                      ))
                    ) : (
                      <Index.TableRow>
                        <Index.TableCell
                          component="td"
                          variant="td"
                          scope="row"
                          className="no-data-in-list"
                          colSpan={15}
                          align="center"
                        >
                          No data available
                        </Index.TableCell>
                      </Index.TableRow>
                    )}
                  </Index.TableBody>
                )}
              </Index.Table>
            </Index.TableContainer>
          </Index.Box>
          <Index.Box className="pagination-design flex-end">
            <Index.Stack spacing={2}>
              <Index.Box className="pagination-count">
                <Index.Box className="input-design-div with-border">
                  <Index.Select
                    fullWidth
                    value={paginationPerPage}
                    onChange={(e) => {
                      setPaginationPerPage(e.target.value);
                    }}
                  >
                    <Index.MenuItem value={10}>10</Index.MenuItem>
                    <Index.MenuItem value={25}>25</Index.MenuItem>
                    <Index.MenuItem value={50}>50</Index.MenuItem>
                    <Index.MenuItem value={100}>100</Index.MenuItem>
                  </Index.Select>
                </Index.Box>
                <Index.Pagination
                  count={count}
                  page={currentPage}
                  onChange={handlePageChange}
                />
              </Index.Box>
            </Index.Stack>
          </Index.Box>
        </Index.Box>
      </Index.Box>

      <Index.DeleteModal
        deleteOpen={deleteOpen}
        handleDeleteRecord={handleDeleteRecord}
        handleDeleteClose={handleDeleteClose}
      />
    </>
  );
}

export default UserList;
