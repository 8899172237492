import dashboard from './images/svg/dashboard.svg';
import logo from './images/svg/logo.svg';
import filter from './images/svg/filter.svg';
import close from './images/svg/close.svg';
import cancel from './images/svg/cancel.svg';
import righticon from './images/svg/righticon.svg';
import righticonblue from './images/svg/righticonblue.svg';

import sidebar1 from './images/svg/sidebar1.svg';
import sidebar2 from './images/svg/sidebar2.svg';
import sidebar3 from './images/svg/sidebar3.svg';
import sidebar4 from './images/svg/sidebar4.svg';
import sidebar5 from './images/svg/sidebar5.svg';
import sidebar6 from './images/svg/sidebar6.svg';
import sidebar7 from './images/svg/sidebar7.svg';
import sidebar8 from './images/svg/sidebar8.svg';
import setting from './images/svg/setting.svg';
import purchase from './images/svg/purchase.svg';
import purchase1 from './images/svg/purchase1.svg';
import invoices from './images/svg/invoices.svg';
import expense from './images/svg/expense.svg';
import expense1 from './images/svg/expense1.svg';
import banks from './images/svg/banks.svg';
import orders from './images/svg/orders.svg';
import enquiry from './images/svg/enquiry.svg';
import dashboard2 from './images/svg/dashboard2.svg';
import bunkerExpense from './images/svg/bunker-expense.svg';
import tds from './images/svg/tds.svg';
import gst from './images/svg/gst.svg';
import port from './images/svg/port.svg';
import craft from './images/svg/craft.svg';
import gstRate from './images/svg/gstRate.svg';
import bell from './images/svg/bell.svg';
import thirdParty from './images/svg/third-party.svg';
import traderMaster from './images/svg/trader.svg';
import search from './images/svg/search.svg';
import collapse from './images/svg/collapse.svg';
import headprofile from './images/svg/head-profile.svg';
import closeold from './images/svg/closeold.svg';
import expenses from './images/svg/expenses.svg';
import tankers from './images/svg/tanker-truck.svg';
import notifications from './images/svg/notifications.svg';
import roleMaster from './images/svg/roleMaster.svg';
import salesMaster from './images/svg/salesMaster.svg';
import maintenance from './images/svg/maintenance.svg';
import breakDowns from './images/svg/breakDowns.svg';
import directory from './images/svg/directory.svg';

const Svg = {
    headprofile,
    bell,
    search,
    dashboard,
    collapse,
    logo,
    filter,
    close,
    cancel,
    righticon,
    righticonblue,
    sidebar1,
    sidebar2,
    sidebar3,
    sidebar4,
    sidebar5,
    sidebar6,
    sidebar7,
    sidebar8,
    dashboard2,
    setting,
    purchase,
    purchase1,
    invoices,
    expense,
    orders,
    banks,
    enquiry,
    closeold,
    expense1,
    bunkerExpense,
    tds,
    gst,
    port,
    craft,
    gstRate,
    thirdParty,
    traderMaster,
    expenses,
    tankers,
    notifications,
    roleMaster,
    salesMaster,
    maintenance,
    breakDowns,
    directory,
}

export default Svg;