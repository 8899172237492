import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import {
  getDirectoryList,
  deleteDirectory,
} from "../../../../redux/admin/action";
import { NEW_EXPORT_EXCEL } from "../ExportAll";

const Search = Index.styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: Index.alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: Index.alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));
const ActionItem = Index.styled(Index.MenuItem)(() => ({
  fontFamily: "poppins",
  lineHeight: "15px",
  fontSize: "14px",
  fontWeight: "400",
  color: "#595F69",
}));
const StyledInputBase = Index.styled(Index.InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

// style for model
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function DirectoryList() {
  const dispatch = Index.useDispatch();
  const navigate = Index.useNavigate();
  const { directoryList } = Index.useSelector((state) => state.AdminReducer);
  const [loading, setLoading] = useState(false);
  const [nominationFromDate, setNominationFromDate] = useState();
  const [nominationToDate, setNominationToDate] = useState();
  const [nominationFromError, setNominationFromError] = useState();
  const [nominationToError, setNominationToError] = useState();

  // Delete record state
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [rowId, setRowId] = useState("");

  // State for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState(0);
  const [paginationPerPage, setPaginationPerPage] = useState(50);

  // State for searching and set data
  const [searchingText, setSearchingText] = useState("");
  const [directoriesData, setDirectoriesData] = useState([]);
  const [totalRecordCount, setTotalRecordCount] = useState(0);

  const [anchorEl, setAnchorEl] = useState(null);
  const [actionIndex, setActionIndex] = useState();

  const handleClickMenu = (e, index) => {
    setActionIndex(index);
    setAnchorEl(e.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  // get user
  const getDirectorys = (values) => {
    setLoading(true);
    dispatch(getDirectoryList(values))
      .then((res) => {
        if (res?.status === 200) {
          setDirectoriesData(res.data);
          setTotalRecordCount(res?.totalCount > 0 ? res?.totalCount : 1);
          setCurrentPage(res?.currentPage);
          setLoading(false);
        } else {
          setDirectoriesData([]);
          setCurrentPage(1);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setDirectoriesData([]);
        setCurrentPage(1);
        setLoading(false);
      });
  };
  useEffect(() => {
    const values = {
      page: 1,
      search: searchingText,
      size: paginationPerPage,
    };
    getDirectorys(values);
  }, []);

  // when the search directory then used...
  const handleSearchDirectory = (e) => {
    const { value } = e.target;
    setSearchingText(value);
    if (value?.length > 0) {
      const values = {
        page: 1,
        search: value,
        size: paginationPerPage,
      };
      getDirectorys(values);
    } else {
      const values = {
        page: 1,
        search: "",
        size: paginationPerPage,
      };
      getDirectorys(values);
    }
  };

  const handlePageChange = React.useCallback(
    (event, value) => {
      setCurrentPage(value);
      const values = {
        page: value,
        search: searchingText,
        size: paginationPerPage,
      };
      getDirectorys(values);
    },
    [searchingText, paginationPerPage, setCurrentPage]
  );

  const handleDeleteOpen = () => {
    setAnchorEl(null);
    setDeleteOpen(true);
  };

  const handleDeleteClose = () => {
    setDeleteOpen(false);
  };

  const handleDeleteRecord = () => {
    const data = {
      id: rowId,
    };
    dispatch(deleteDirectory(data));
    handleDeleteClose();
  };

  const handleEdit = (item) => {
    navigate("/admin/directory/add", {
      state: { item: item },
    });
  };

  // when the user change the per page record change then used..
  const handleChangePaginationPerChange = (e) => {
    const { value } = e.target;
    setPaginationPerPage(value);
    const values = {
      page: 1,
      search: searchingText,
      size: value,
    };
    getDirectorys(values);
  };

  return (
    <>
      <Index.DynamicTitle title="Directory" />
      <Index.Box className="">
        <Index.Box className="barge-common-box">
          <Index.Box className="title-header">
            <Index.Box className="title-header-flex res-title-header-flex">
              <Index.Box className="title-main">
                <Index.Typography
                  variant="p"
                  component="p"
                  className="page-title"
                >
                  Directory
                </Index.Typography>
              </Index.Box>
              <Index.Box className="d-flex align-items-center res-set-search">
                <Search className="search ">
                  <StyledInputBase
                    placeholder="Search"
                    inputProps={{ "aria-label": "search" }}
                    onChange={(e) => handleSearchDirectory(e)}
                  />
                </Search>

                <Index.Box className="common-button grey-button">
                  <Index.Button
                    variant="contained"
                    onClick={() => {
                      let curDate = Index.moment(new Date()).format(
                        "DD-MM-YYYY"
                      );
                      NEW_EXPORT_EXCEL(
                        "/admin/all-directory-export",
                        `Directory_List_${curDate}`,
                        {
                          search: searchingText,
                        }
                      );
                    }}
                  >
                    Export
                  </Index.Button>
                </Index.Box>

                <Index.Box className="common-button blue-button res-blue-button">
                  <Index.RouteLink
                    to="/admin/directory/add"
                    className="no-text-decoration"
                  >
                    <Index.Button variant="contained" disableRipple>
                      Add
                    </Index.Button>
                  </Index.RouteLink>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Index.Box className="">
            <Index.TableContainer
              component={Index.Paper}
              className="table-container"
            >
              <Index.Table
                aria-label="simple table"
                className="table-design-main supply-table one-line-table invoice-table-set user-management-table "
              >
                <Index.TableHead>
                  <Index.TableRow>
                    <Index.TableCell>Sr.No</Index.TableCell>
                    <Index.TableCell>Name</Index.TableCell>
                    <Index.TableCell align="left">Contact No</Index.TableCell>
                    <Index.TableCell align="left">Role</Index.TableCell>
                    {/* <Index.TableCell align="left">Department</Index.TableCell> */}
                    {/* <Index.TableCell align="left">Created Date</Index.TableCell> */}
                    <Index.TableCell align="left">Action</Index.TableCell>
                  </Index.TableRow>
                </Index.TableHead>
                {loading ? (
                  <Index.TableBody>
                    <Index.TableRow>
                      <Index.TableCell
                        colSpan={12}
                        align="center"
                        className="loading-purchase"
                      >
                        <Index.CircularProgress color="secondary" size={20} />
                      </Index.TableCell>
                    </Index.TableRow>
                  </Index.TableBody>
                ) : (
                  <Index.TableBody>
                    {directoriesData?.length ? (
                      directoriesData?.map((item, index) => (
                        <Index.TableRow key={item?._id}>
                          <Index.TableCell>{index + 1}</Index.TableCell>

                          <Index.TableCell align="left">
                            {item?.name ? item?.name : "-"}
                          </Index.TableCell>

                          <Index.TableCell align="left">
                            {item?.contactNumber ? item?.contactNumber : "-"}
                          </Index.TableCell>

                          <Index.TableCell align="left">
                            {item?.supervisor ? item?.supervisor : "-"}
                          </Index.TableCell>

                          {/* <Index.TableCell align="left">
                            {item?.department ? item?.department : "-"}
                          </Index.TableCell> */}

                          {/* <Index.TableCell align="left">
                            {item?.createdAt
                              ? Index.moment(item?.createdAt).format(
                                  "DD/MM/YYYY"
                                )
                              : "-"}
                          </Index.TableCell> */}

                          <Index.TableCell sx={{ display: "flex" }}>
                            <Index.IconButton
                              onClick={(e) => {
                                handleClickMenu(e, index);
                              }}
                            >
                              <Index.MenuIcon className="action-menu-icon" />
                            </Index.IconButton>
                            <Index.Menu
                              id="demo-positioned-menu"
                              aria-labelledby="demo-positioned-button"
                              anchorEl={anchorEl}
                              keepMounted
                              elevation={2}
                              open={Boolean(anchorEl)}
                              onClose={handleCloseMenu}
                              anchorOrigin={{
                                vertical: "top",
                                horizontal: "left",
                              }}
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "left",
                              }}
                            >
                              <ActionItem
                                className="action-text"
                                onClick={() => {
                                  handleEdit(directoriesData?.[actionIndex]);
                                }}
                              >
                                Edit
                              </ActionItem>
                              <ActionItem
                                className="action-text"
                                onClick={() => {
                                  setRowId(directoriesData?.[actionIndex]?._id);
                                  handleDeleteOpen();
                                }}
                              >
                                Delete
                              </ActionItem>
                            </Index.Menu>
                          </Index.TableCell>
                        </Index.TableRow>
                      ))
                    ) : (
                      <Index.TableRow>
                        <Index.TableCell
                          component="td"
                          variant="td"
                          scope="row"
                          className="no-data-in-list"
                          colSpan={15}
                          align="center"
                        >
                          No data available
                        </Index.TableCell>
                      </Index.TableRow>
                    )}
                  </Index.TableBody>
                )}
              </Index.Table>
            </Index.TableContainer>
          </Index.Box>
          <Index.Box className="pagination-design flex-end">
            <Index.Stack spacing={2}>
              <Index.Box className="pagination-count">
                <Index.Box className="input-design-div with-border">
                  <Index.Select
                    fullWidth
                    value={paginationPerPage}
                    onChange={(e) => {
                      handleChangePaginationPerChange(e);
                    }}
                  >
                    <Index.MenuItem value={10}>10</Index.MenuItem>
                    <Index.MenuItem value={25}>25</Index.MenuItem>
                    <Index.MenuItem value={50}>50</Index.MenuItem>
                    <Index.MenuItem value={100}>100</Index.MenuItem>
                  </Index.Select>
                </Index.Box>
                <Index.Pagination
                  count={Math.ceil(totalRecordCount / paginationPerPage)}
                  page={currentPage}
                  onChange={handlePageChange}
                />
              </Index.Box>
            </Index.Stack>
          </Index.Box>
        </Index.Box>
      </Index.Box>

      <Index.DeleteModal
        deleteOpen={deleteOpen}
        handleDeleteRecord={handleDeleteRecord}
        handleDeleteClose={handleDeleteClose}
      />
    </>
  );
}

export default DirectoryList;
