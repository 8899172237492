import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import { Badge } from "@mui/material";
import { adminReadNotification, getNotifications } from "../../../../redux/admin/action";

function NotificationList() {
  const dispatch = Index.useDispatch();
  const navigate = Index.useNavigate();
  const [notification, setNotification] = useState([]);

  // redux data
  const { notificationList } = Index.useSelector((state) => state.AdminReducer);

  const getNotificationsList = () => {
    dispatch(getNotifications()).then((res) => {
      if (res?.status === 200) {
        setNotification(res?.data);
      }
    });
  };

  // get notificaion list
  useEffect(() => {
    getNotificationsList();
  }, []);
  
// read notification
  const notificationNavigation = (items) => {
    const id = items?._id
    const type = items?.type;
    const enquiryId = items?.typeId;
    const data = {
      notificationId: items?._id,
    };
    dispatch(adminReadNotification(data)); 
    if (type === "enquiry") {
      navigate(`/admin/enquiry/edit/${enquiryId}`);
    }
  }

  return (
    <>
      <Index.DynamicTitle title="Notification" />
      <Index.Box className="">
        <Index.Box className="barge-common-box">
          <Index.Box className="title-header">
            <Index.Box className="title-header-flex res-title-header-flex">
              <Index.Box className="title-main">
                <Index.Typography
                  variant="p"
                  component="p"
                  className="page-title"
                >
                  Notifications
                </Index.Typography>
              </Index.Box>
            </Index.Box>
          </Index.Box>

          {notificationList &&
            notificationList?.map((items, index) => {
              return (
                <>
                  <Index.Box className="notification-main-box">
                    <Index.Box className="notification-box">
                      <Index.Box className="notification-img">
                        <img
                          src={Index.Png.profileimg}
                          alt="logo"
                          className="img-notification"
                        />
                      </Index.Box>
                      <Index.Box
                        onClick={() => notificationNavigation(items)}
                        sx={{ cursor: "pointer" }}
                      >
                        <Index.Box>
                          <Index.Typography
                            variant="p"
                            component="p"
                            className="notification-text"
                          >
                            {items?.title ? items?.title : "-"}
                          </Index.Typography>
                        </Index.Box>
                        <Index.Box>
                          <Index.Typography
                            variant="p"
                            component="p"
                            className="notification-sub-text"
                          >
                            {items?.message ? items?.message : "-"}
                          </Index.Typography>
                        </Index.Box>
                        <Index.Box>
                          <Index.Typography
                            variant="p"
                            component="p"
                            className="notification-time"
                          >
                            {items?.createdAt
                              ? Index.moment(items?.createdAt).format(
                                  "DD/MM/YYYY hh:mm:ss A"
                                )
                              : "-"}
                          </Index.Typography>
                        </Index.Box>
                      </Index.Box>
                    
                    </Index.Box>
                    <Index.Box className="admin-header-right top-right-notification">
                          {items?.readUnread === false && 
                            (
                              <Badge
                              color={"secondary"}
                              variant={"dot"}
                            >
                            </Badge>
                            )
                          }
                      </Index.Box>
                  </Index.Box>
                </>
              );
            })}
        </Index.Box>
      </Index.Box>
    </>
  );
}

export default NotificationList;
