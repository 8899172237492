import React from "react";
import Index from "../../../Index";
import moment from "moment";

const ExpensePrintPDF = ({ expenseData, componentRef }) => {
  console.log(expenseData);
  const totalAmount = expenseData?.details?.reduce((start, current) => {
    if (current?.amount) {
      return start + current?.amount;
    } else {
      return start + 0;
    }
  }, 0);

  const totalExtra = expenseData?.details?.reduce((start, current) => {
    if (current?.extra) {
      return start + current?.extra;
    } else {
      return start + 0;
    }
  }, 0);
  const grandTotal = totalAmount + totalExtra;

  return (
    <Index.Box ref={componentRef}>
      <table
        style={{
          maxWidth: 600,
          margin: "auto",
          borderCollapse: "collapse",
          width: "100%",
          marginTop: 50,
          marginBottom: 50,
        }}
      >
        <tbody>
          <tr>
            <td
              colSpan={2}
              style={{
                color: "#000000",
                fontSize: 15,
                lineHeight: 2,
                fontWeight: 700,
                fontFamily: '"Poppins", sans-serif',
                verticalAlign: "baseline",
                borderCollapse: "collapse",
                border: "2px solid black",
                padding: 5,
              }}
            >
              BILL NO.
            </td>
            <td
              colSpan={2}
              style={{
                width: "40%",
                height: 18,
                color: "#000000",
                fontSize: 15,
                lineHeight: 2,
                fontWeight: 500,
                fontFamily: '"Poppins", sans-serif',
                verticalAlign: "baseline",
                borderCollapse: "collapse",
                border: "2px solid black",
                padding: 5,
              }}
            >
              {expenseData?.serialNumber ? expenseData?.serialNumber : "-"}
            </td>
            <td
              colSpan={2}
              rowSpan={12}
              style={{
                width: "40%",
                verticalAlign: "baseline",
                borderCollapse: "collapse",
                border: "2px solid black",
                padding: 5,
              }}
            >
              -
            </td>
          </tr>
          <tr>
            <td
              colSpan={2}
              style={{
                color: "#000000",
                fontSize: 15,
                lineHeight: 2,
                fontWeight: 700,
                fontFamily: '"Poppins", sans-serif',
                verticalAlign: "baseline",
                borderCollapse: "collapse",
                border: "2px solid black",
                padding: 5,
              }}
            >
              NOMINATION NO.
            </td>
            <td
              colSpan={2}
              style={{
                width: "40%",
                height: 18,
                color: "#000000",
                fontSize: 15,
                lineHeight: 2,
                fontWeight: 500,
                fontFamily: '"Poppins", sans-serif',
                verticalAlign: "baseline",
                borderCollapse: "collapse",
                border: "2px solid black",
                padding: 5,
              }}
            >
              {expenseData?.nominationNo?.nominationNo
                ? expenseData?.nominationNo?.nominationNo
                : "-"}
            </td>
          </tr>
          <tr>
            <td
              colSpan={2}
              style={{
                color: "#000000",
                fontSize: 15,
                lineHeight: 2,
                fontWeight: 700,
                fontFamily: '"Poppins", sans-serif',
                verticalAlign: "baseline",
                borderCollapse: "collapse",
                border: "2px solid black",
                padding: 5,
              }}
            >
              COMPANY NAME
            </td>
            <td
              colSpan={2}
              style={{
                width: "40%",
                height: 18,
                color: "#000000",
                fontSize: 15,
                lineHeight: 2,
                fontWeight: 500,
                fontFamily: '"Poppins", sans-serif',
                verticalAlign: "baseline",
                borderCollapse: "collapse",
                border: "2px solid black",
                padding: 5,
              }}
            >
              {expenseData?.nominationNo?.companyName
                ? expenseData?.nominationNo?.companyName
                : "-"}
            </td>
          </tr>
          <tr>
            <td
              colSpan={2}
              style={{
                color: "#000000",
                fontSize: 15,
                lineHeight: 2,
                fontWeight: 700,
                fontFamily: '"Poppins", sans-serif',
                verticalAlign: "baseline",
                borderCollapse: "collapse",
                border: "2px solid black",
                padding: 5,
              }}
            >
              VESSEL NAME
            </td>
            <td
              colSpan={2}
              style={{
                width: "40%",
                height: 18,
                color: "#000000",
                fontSize: 15,
                lineHeight: 2,
                fontWeight: 500,
                fontFamily: '"Poppins", sans-serif',
                verticalAlign: "baseline",
                borderCollapse: "collapse",
                border: "2px solid black",
                padding: 5,
              }}
            >
              {expenseData?.nominationNo?.vesselName
                ? expenseData?.nominationNo?.vesselName
                : "-"}
            </td>
          </tr>

          <tr>
            <td
              colSpan={2}
              style={{
                color: "#000000",
                fontSize: 15,
                lineHeight: 2,
                fontWeight: 700,
                fontFamily: '"Poppins", sans-serif',
                verticalAlign: "baseline",
                borderCollapse: "collapse",
                border: "2px solid black",
                padding: 5,
              }}
            >
              PORT
            </td>
            <td
              colSpan={2}
              style={{
                width: "40%",
                height: 18,
                color: "#000000",
                fontSize: 15,
                lineHeight: 2,
                fontWeight: 500,
                fontFamily: '"Poppins", sans-serif',
                verticalAlign: "baseline",
                borderCollapse: "collapse",
                border: "2px solid black",
                padding: 5,
              }}
            >
              {expenseData?.nominationNo?.port?.portName
                ? expenseData?.nominationNo?.port?.portName
                : "-"}
            </td>
          </tr>

          <tr>
            <td
              colSpan={2}
              style={{
                color: "#000000",
                fontSize: 15,
                lineHeight: 2,
                fontWeight: 700,
                fontFamily: '"Poppins", sans-serif',
                verticalAlign: "baseline",
                borderCollapse: "collapse",
                border: "2px solid black",
                padding: 5,
              }}
            >
              PRODUCT
            </td>
            <td
              colSpan={2}
              style={{
                width: "40%",
                height: 18,
                color: "#000000",
                fontSize: 15,
                lineHeight: 2,
                fontWeight: 500,
                fontFamily: '"Poppins", sans-serif',
                verticalAlign: "baseline",
                borderCollapse: "collapse",
                border: "2px solid black",
                padding: 5,
              }}
            >
              {expenseData?.nominationNo?.product
                ? expenseData?.nominationNo?.product
                : "-"}
            </td>
          </tr>

          <tr>
            <td
              colSpan={2}
              style={{
                color: "#000000",
                fontSize: 15,
                lineHeight: 2,
                fontWeight: 700,
                fontFamily: '"Poppins", sans-serif',
                verticalAlign: "baseline",
                borderCollapse: "collapse",
                border: "2px solid black",
                padding: 5,
              }}
            >
              QTY
            </td>
            <td
              colSpan={2}
              style={{
                width: "40%",
                height: 18,
                color: "#000000",
                fontSize: 15,
                lineHeight: 2,
                fontWeight: 500,
                fontFamily: '"Poppins", sans-serif',
                verticalAlign: "baseline",
                borderCollapse: "collapse",
                border: "2px solid black",
                padding: 5,
              }}
            >
              {expenseData?.expenseType === "Other"
                ? " - "
                : Number(expenseData?.nominationNo?.lowerQuantity) &&
                  Number(expenseData?.nominationNo?.higherQuantity) === 0
                ? Number(expenseData?.nominationNo?.lowerQuantity)
                : Number(expenseData?.nominationNo?.lowerQuantity) ===
                  Number(expenseData?.nominationNo?.higherQuantity)
                ? Number(expenseData?.nominationNo?.lowerQuantity)
                : Number(expenseData?.nominationNo?.lowerQuantity) +
                  "-" +
                  Number(expenseData?.nominationNo?.higherQuantity)}
            </td>
          </tr>

          <tr>
            <td
              colSpan={2}
              style={{
                color: "#000000",
                fontSize: 15,
                lineHeight: 2,
                fontWeight: 700,
                fontFamily: '"Poppins", sans-serif',
                verticalAlign: "baseline",
                borderCollapse: "collapse",
                border: "2px solid black",
                padding: 5,
              }}
            >
              RUN
            </td>
            <td
              colSpan={2}
              style={{
                width: "40%",
                height: 18,
                color: "#000000",
                fontSize: 15,
                lineHeight: 2,
                fontWeight: 500,
                fontFamily: '"Poppins", sans-serif',
                verticalAlign: "baseline",
                borderCollapse: "collapse",
                border: "2px solid black",
                padding: 5,
              }}
            >
              {expenseData?.nominationNo?.foreign
                ? expenseData?.nominationNo?.foreign
                : "-"}
            </td>
          </tr>
          <tr>
            <td
              colSpan={2}
              style={{
                color: "#000000",
                fontSize: 15,
                lineHeight: 2,
                fontWeight: 700,
                fontFamily: '"Poppins", sans-serif',
                verticalAlign: "baseline",
                borderCollapse: "collapse",
                border: "2px solid black",
                padding: 5,
              }}
            >
              CREATED BY
            </td>
            <td
              colSpan={2}
              style={{
                width: "40%",
                height: 18,
                color: "#000000",
                fontSize: 15,
                lineHeight: 2,
                fontWeight: 500,
                fontFamily: '"Poppins", sans-serif',
                verticalAlign: "baseline",
                borderCollapse: "collapse",
                border: "2px solid black",
                padding: 5,
              }}
            >
              {expenseData?.createdBy?.firstName
                ? expenseData?.createdBy?.firstName
                : "-"}
            </td>
          </tr>
          <tr>
            <td
              colSpan={2}
              style={{
                color: "#000000",
                fontSize: 15,
                lineHeight: 2,
                fontWeight: 700,
                fontFamily: '"Poppins", sans-serif',
                verticalAlign: "baseline",
                borderCollapse: "collapse",
                border: "2px solid black",
                padding: 5,
              }}
            >
              STATUS
            </td>
            <td
              colSpan={2}
              style={{
                width: "40%",
                height: 18,
                color: "#000000",
                fontSize: 15,
                lineHeight: 2,
                fontWeight: 500,
                fontFamily: '"Poppins", sans-serif',
                verticalAlign: "baseline",
                borderCollapse: "collapse",
                border: "2px solid black",
                padding: 5,
              }}
            >
              {expenseData?.status ? expenseData?.status : "-"}
            </td>
          </tr>
          <tr>
            <td
              colSpan={2}
              style={{
                color: "#000000",
                fontSize: 15,
                lineHeight: 2,
                fontWeight: 700,
                fontFamily: '"Poppins", sans-serif',
                verticalAlign: "baseline",
                borderCollapse: "collapse",
                border: "2px solid black",
                padding: 5,
              }}
            >
              Approved By
            </td>
            <td
              colSpan={2}
              style={{
                width: "40%",
                height: 18,
                color: "#000000",
                fontSize: 15,
                lineHeight: 2,
                fontWeight: 500,
                fontFamily: '"Poppins", sans-serif',
                verticalAlign: "baseline",
                borderCollapse: "collapse",
                border: "2px solid black",
                padding: 5,
              }}
            >
              {expenseData?.actionBy?.firstName
                ? expenseData?.actionBy?.firstName +
                  " " +
                  expenseData?.actionBy?.lastName
                : "-"}
            </td>
          </tr>
          <tr>
            <td
              colSpan={2}
              style={{
                color: "#000000",
                fontSize: 15,
                lineHeight: 2,
                fontWeight: 700,
                fontFamily: '"Poppins", sans-serif',
                verticalAlign: "baseline",
                borderCollapse: "collapse",
                border: "2px solid black",
                padding: 5,
              }}
            >
              Approved By Time
            </td>
            <td
              colSpan={2}
              style={{
                width: "40%",
                height: 18,
                color: "#000000",
                fontSize: 15,
                lineHeight: 2,
                fontWeight: 500,
                fontFamily: '"Poppins", sans-serif',
                verticalAlign: "baseline",
                borderCollapse: "collapse",
                border: "2px solid black",
                padding: 5,
              }}
            >
              {expenseData?.actionByTimeStamps
                ? moment(expenseData?.actionByTimeStamps).format("DD/MM/YYYY hh:mm:ss A")
                : "-"}
            </td>
          </tr>

          <tr>
            <th
              style={{
                color: "#000000",
                fontSize: 18,
                lineHeight: 2,
                fontWeight: 700,
                fontFamily: '"Poppins", sans-serif',
                verticalAlign: "baseline",
                borderCollapse: "collapse",
                border: "2px solid black",
                padding: 5,
              }}
            >
              S. No
            </th>
            <th
              colSpan={2}
              style={{
                color: "#000000",
                fontSize: 18,
                lineHeight: 2,
                fontWeight: 700,
                fontFamily: '"Poppins", sans-serif',
                verticalAlign: "baseline",
                borderCollapse: "collapse",
                border: "2px solid black",
                padding: 5,
              }}
            >
              Detail
            </th>
            <th
              style={{
                color: "#000000",
                fontSize: 18,
                lineHeight: 2,
                fontWeight: 700,
                fontFamily: '"Poppins", sans-serif',
                verticalAlign: "baseline",
                borderCollapse: "collapse",
                border: "2px solid black",
                padding: 5,
              }}
            >
              Amount
            </th>
            <th
              style={{
                color: "#000000",
                fontSize: 18,
                lineHeight: 2,
                fontWeight: 700,
                fontFamily: '"Poppins", sans-serif',
                verticalAlign: "baseline",
                borderCollapse: "collapse",
                border: "2px solid black",
                padding: 5,
              }}
            >
              Extra
            </th>
            <th
              style={{
                color: "#000000",
                fontSize: 18,
                lineHeight: 2,
                fontWeight: 700,
                fontFamily: '"Poppins", sans-serif',
                verticalAlign: "baseline",
                borderCollapse: "collapse",
                border: "2px solid black",
                padding: 5,
              }}
            >
              Remark
            </th>
          </tr>

          {expenseData?.details &&
            expenseData?.details?.map((items, index) => {
              return (
                <>
                  <tr>
                    <td
                      style={{
                        textAlign: "center",
                        fontSize: 13,
                        color: "black",
                        lineHeight: 3,
                        fontWeight: 600,
                        fontFamily: '"Poppins", sans-serif',
                        verticalAlign: "baseline",
                        borderCollapse: "collapse",
                        border: "2px solid black",
                        padding: 5,
                      }}
                    >
                      {index + 1}
                    </td>
                    <td
                      colSpan={2}
                      style={{
                        textAlign: "center",
                        fontSize: 13,
                        color: "black",
                        lineHeight: 3,
                        fontWeight: 600,
                        fontFamily: '"Poppins", sans-serif',
                        verticalAlign: "baseline",
                        borderCollapse: "collapse",
                        border: "2px solid black",
                        padding: 5,
                        textTransform: "uppercase",
                      }}
                    >
                      {items?.expenseID?.expenseName
                        ? items?.expenseID?.expenseName
                        : "-"}
                    </td>
                    <td
                      style={{
                        textAlign: "center",
                        fontSize: 13,
                        color: "black",
                        lineHeight: 3,
                        fontWeight: 600,
                        fontFamily: '"Poppins", sans-serif',
                        verticalAlign: "baseline",
                        borderCollapse: "collapse",
                        border: "2px solid black",
                        padding: 5,
                      }}
                    >
                      {items?.amount ? `₹ ${items.amount}` : "-"}
                    </td>
                    <td
                      style={{
                        textAlign: "center",
                        fontSize: 13,
                        color: "black",
                        lineHeight: 3,
                        fontWeight: 600,
                        fontFamily: '"Poppins", sans-serif',
                        verticalAlign: "baseline",
                        borderCollapse: "collapse",
                        border: "2px solid black",
                        padding: 5,
                        textTransform: "uppercase",
                      }}
                    >
                      {items?.extra ? `₹ ${items.extra}` : "-"}
                    </td>
                    <td
                      style={{
                        textAlign: "center",
                        fontSize: 13,
                        color: "black",
                        lineHeight: 3,
                        fontWeight: 600,
                        fontFamily: '"Poppins", sans-serif',
                        verticalAlign: "baseline",
                        borderCollapse: "collapse",
                        border: "2px solid black",
                        padding: 5,
                        // textTransform: "uppercase",
                      }}
                    >
                      {items?.remark ? items?.remark : "-"}
                    </td>
                  </tr>
                </>
              );
            })}
          <tr>
            <td
              colSpan={3}
              style={{
                color: "#000000",
                fontSize: 15,
                lineHeight: 2,
                fontWeight: 700,
                fontFamily: '"Poppins", sans-serif',
                verticalAlign: "baseline",
                borderCollapse: "collapse",
                border: "2px solid black",
                padding: 5,
                textAlign: "center",
              }}
            >
              TOTAL
            </td>
            <td
              style={{
                textAlign: "center",
                fontSize: 13,
                lineHeight: 2,
                color: "black",
                fontWeight: 600,
                fontFamily: '"Poppins", sans-serif',
                verticalAlign: "baseline",
                borderCollapse: "collapse",
                border: "2px solid black",
                padding: 5,
                textTransform: "uppercase",
              }}
            >
              {totalAmount ? `₹ ${totalAmount}` : "-"}
            </td>
            <td
              style={{
                textAlign: "center",
                fontSize: 13,
                lineHeight: 2,
                color: "black",
                fontWeight: 600,
                fontFamily: '"Poppins", sans-serif',
                verticalAlign: "baseline",
                borderCollapse: "collapse",
                border: "2px solid black",
                padding: 5,
                textTransform: "uppercase",
              }}
            >
              {totalExtra ? `₹ ${totalExtra}` : "-"}
            </td>
            <td
              style={{
                textAlign: "center",
                fontSize: 13,
                lineHeight: 2,
                color: "black",
                fontWeight: 600,
                fontFamily: '"Poppins", sans-serif',
                verticalAlign: "baseline",
                borderCollapse: "collapse",
                border: "2px solid black",
                padding: 5,
                textTransform: "uppercase",
              }}
            />
          </tr>

          <tr>
            <td
              colSpan={6}
              style={{
                color: "#000000",
                fontSize: 15,
                lineHeight: 2,
                fontWeight: 700,
                fontFamily: '"Poppins", sans-serif',
                verticalAlign: "baseline",
                borderCollapse: "collapse",
                border: "2px solid black",
                padding: 5,
                textAlign: "center",
              }}
            >
              GRAND TOTAL : {grandTotal ? `₹ ${grandTotal}` : "-"}
            </td>
          </tr>
        </tbody>
      </table>
    </Index.Box>
  );
};

export default ExpensePrintPDF;
