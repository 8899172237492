import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import {
  addPurchase,
  deletePurchase,
  deletePurchaseSheet,
  getTDS,
} from "../../../../redux/admin/action";

const Search = Index.styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: Index.alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: Index.alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const StyledInputBase = Index.styled(Index.InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

const TDSList = () => {
  const dispatch = Index.useDispatch();
  const [loading, setLoading] = useState(false);
  const [tdsData, setTDSData] = useState([]);
  const [rowId, setRowId] = useState("");
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [deleteSheetModelOpen, setDeleteSheetModelOpen] = useState(false);

  // State for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState(0);
  const [paginationPerPage, setPaginationPerPage] = useState(50);

  // State for searching and set data
  const [searchedData, setSearchedData] = useState([]);
  const [currentRows, setCurrentRows] = useState([]);


  // delete model close
  const handleDeleteClose = () => {
    setDeleteOpen(false);
  };

  // delete record
  const handleDeleteRecord = () => {
    setDeleteOpen(false);
    dispatch(deletePurchase({ id: rowId }, handleDeleteClose));
  };

  // delete sheet model close
  const handleSheetDeleteClose = () => {
    setDeleteSheetModelOpen(false);
  };

  const PER_PAGE = paginationPerPage;
  useEffect(() => {
    //Page count
    const count = Math.ceil(searchedData?.length / PER_PAGE);
    setCount(count);
    const indexOfLastRow = currentPage * PER_PAGE;
    const indexOfFirstRow = indexOfLastRow - PER_PAGE;
    const modifiedRows = searchedData?.slice(indexOfFirstRow, indexOfLastRow);
    setCurrentRows(modifiedRows);
    if (modifiedRows?.length === 0 && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  }, [currentPage, searchedData, paginationPerPage, PER_PAGE]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };
  // Pagination calculation end

  // Copy original data to searched data
  useEffect(() => {
    setSearchedData(tdsData);
  }, [tdsData]);

  // Search on table
  const requestSearch = (searched) => {
    if (!searched) return setSearchedData(tdsData);
    setSearchedData(
      tdsData?.filter(
        (item) =>
          Index.moment(item?.date)
            .format("DD-MM-YYYY")
            ?.toString()
            ?.toLowerCase()
            ?.includes(searched?.toString().toLowerCase()) ||
          item?.ledger
            ?.toString()
            ?.toLowerCase()
            .includes(searched?.toLowerCase()) ||
          item?.party_name
            ?.toString()
            .toLowerCase()
            .includes(searched?.toLowerCase()) ||
          item?.party_pan
            ?.toString()
            .toLowerCase()
            .includes(searched?.toLowerCase()) ||
          item?.party_gst_number
            ?.toString()
            .toLowerCase()
            .includes(searched?.toLowerCase()) ||
          item?.taxable_Value
            ?.toString()
            .toLowerCase()
            .includes(searched?.toLowerCase())
      )
    );
    setCurrentPage(1);
  };

  const deleteSheet = () => {
    const data = new URLSearchParams();
    data.append("type", "purchase");
    dispatch(deletePurchaseSheet(data));
    handleSheetDeleteClose();
  };

  // get purchase list
  const getTDSList = () => {
    setLoading(true);
    dispatch(getTDS()).then((res) => {
      if (res?.status === 200) {
        setTDSData(res?.data);
        setTimeout(() => {
          setLoading(false);
        }, 2000);
      } else {
        setTimeout(() => {
          setLoading(false);
        }, 2000);
      }
    });
  };
  useEffect(() => {
    getTDSList();
  }, []);

  console.log(currentRows, 'currentRows')

  return (
    <div>
      <Index.DynamicTitle title="TDS" />
      <Index.Box className="">
        <Index.Box className="barge-common-box">
          <Index.Box className="title-header">
            <Index.Box className="title-header-flex  res-title-header-flex">
              <Index.Box className="title-main">
                <Index.Typography
                  variant="p"
                  component="p"
                  className="page-title"
                >
                  TDS
                </Index.Typography>
              </Index.Box>
              <Index.Box className="d-flex align-items-center res-set-search">
                <Search className="search">
                  <StyledInputBase
                    placeholder="Search"
                    inputProps={{ "aria-label": "search" }}
                    onChange={(e) =>
                      setTimeout(() => {
                        requestSearch(e.target.value.trim());
                      }, 1000)
                    }
                  />
                </Search>
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Index.Box className="">
            <Index.TableContainer
              component={Index.Paper}
              className="table-container"
            >
              <Index.Table
                aria-label="simple table"
                className="table-design-main invoice-table  "
              >
                <Index.TableHead>
                  <Index.TableRow>
                    <Index.TableCell className="invoice_table_header">
                      Sr No
                    </Index.TableCell>
                    <Index.TableCell
                      align="left"
                      className="invoice_table_header"
                    >
                      Section
                    </Index.TableCell>
                    <Index.TableCell
                      align="left"
                      className="invoice_table_header"
                    >
                      Party
                    </Index.TableCell>
                    <Index.TableCell
                      align="left"
                      className="invoice_table_header"
                    >
                      PAN
                    </Index.TableCell>
                    <Index.TableCell
                      align="left"
                      className="invoice_table_header"
                    >
                      GST No
                    </Index.TableCell>

                    <Index.TableCell
                      align="left"
                      className="invoice_table_header"
                    >
                      Date
                    </Index.TableCell>
                    <Index.TableCell
                      align="left"
                      className="invoice_table_header"
                    >
                      Taxable Value
                    </Index.TableCell>
                    {/* <Index.TableCell
                      align="left"
                      className="invoice_table_header"
                    >
                      TDS Amt
                    </Index.TableCell>
                    <Index.TableCell
                      align="left"
                      className="invoice_table_header"
                    >
                      Rate
                    </Index.TableCell>
                    <Index.TableCell
                      align="left"
                      className="invoice_table_header"
                    >
                      Month
                    </Index.TableCell>
                    <Index.TableCell
                      align="left"
                      className="invoice_table_header"
                    >
                      Challan No
                    </Index.TableCell>
                    <Index.TableCell
                      align="left"
                      className="invoice_table_header"
                    >
                      Challan Date
                    </Index.TableCell>
                    <Index.TableCell
                      align="left"
                      className="invoice_table_header"
                    >
                      BSR Code
                    </Index.TableCell> */}
                  </Index.TableRow>
                </Index.TableHead>
                {loading ? (
                  <Index.TableBody>
                    <Index.TableRow>
                      <Index.TableCell
                        colSpan={12}
                        align="center"
                        className="loading-purchase"
                      >
                        <Index.CircularProgress color="secondary" size={20} />
                      </Index.TableCell>
                    </Index.TableRow>
                  </Index.TableBody>
                ) : (
                  <Index.TableBody>
                    {currentRows?.length ? (
                      currentRows?.map((row, index) => (
                        <Index.TableRow key={row?._id}>
                          <Index.TableCell>
                            {(index+1)}
                          </Index.TableCell>
                          <Index.TableCell>
                            {row?.ledger ? row?.ledger : "-"}
                          </Index.TableCell>
                          <Index.TableCell>
                            {row?.party_name ? row?.party_name : "-"}
                          </Index.TableCell>
                          <Index.TableCell>
                            {row?.party_pan ? row?.party_pan : "-"}
                          </Index.TableCell>
                          <Index.TableCell>
                            {row?.party_gst_number ? row?.party_gst_number : "-"}
                          </Index.TableCell>
                          <Index.TableCell>
                            {row?.date ?  Index.moment(row?.date).format("DD-MM-YYYY") : "-"}
                          </Index.TableCell>
                          <Index.TableCell>
                            {row?.taxable_Value
                              ? row?.taxable_Value?.toLocaleString("en-IN", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : 0?.toLocaleString("en-IN", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                          </Index.TableCell>
                          {/* <Index.TableCell>
                            {row?.TDSAmt
                              ? row?.TDSAmt?.toLocaleString("en-IN", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : 0?.toLocaleString("en-IN", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                          </Index.TableCell>
                          <Index.TableCell>
                            {row?.rate
                              ? row?.rate?.toLocaleString("en-IN", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : 0?.toLocaleString("en-IN", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                          </Index.TableCell>
                          <Index.TableCell>
                            {row?.month ? row?.month : "-"}
                          </Index.TableCell>
                          <Index.TableCell>
                            {"-"}
                          </Index.TableCell>
                          <Index.TableCell>
                            {"-"}
                          </Index.TableCell>
                          <Index.TableCell>
                            {"-"}
                          </Index.TableCell>                        */}
                        </Index.TableRow>
                      ))
                    ) : (
                      <Index.TableRow>
                        <Index.TableCell
                          component="td"
                          variant="td"
                          scope="row"
                          className="no-data-in-list empty-record-align"
                          colSpan={14}
                          align="center"
                        >
                          No data available
                        </Index.TableCell>
                      </Index.TableRow>
                    )}
                  </Index.TableBody>
                )}
              </Index.Table>
            </Index.TableContainer>
          </Index.Box>
          <Index.Box className="pagination-design flex-end">
            <Index.Stack spacing={2}>
              <Index.Box className="pagination-count">
                <Index.Box className="input-design-div with-border">
                  <Index.Select
                    fullWidth
                    value={paginationPerPage}
                    onChange={(e) => {
                      setPaginationPerPage(e.target.value);
                    }}
                  >
                    <Index.MenuItem value={10}>10</Index.MenuItem>
                    <Index.MenuItem value={25}>25</Index.MenuItem>
                    <Index.MenuItem value={50}>50</Index.MenuItem>
                    <Index.MenuItem value={100}>100</Index.MenuItem>
                  </Index.Select>
                </Index.Box>
                <Index.Pagination
                  count={count}
                  page={currentPage}
                  onChange={handlePageChange}
                />
              </Index.Box>
            </Index.Stack>
          </Index.Box>
        </Index.Box>
      </Index.Box>
      <Index.DeleteModal
        deleteOpen={deleteOpen}
        handleDeleteRecord={handleDeleteRecord}
        handleDeleteClose={handleDeleteClose}
      />
      <Index.DeleteSheetModal
        deleteSheetModelOpen={deleteSheetModelOpen}
        handleSheetDeleteClose={handleSheetDeleteClose}
        deleteSheet={deleteSheet}
      />
    </div>
  );
};

export default TDSList;
