import { Formik } from "formik";
import { terminalData } from "../../../../../config/CommonTerminalItems";
import { useEffect, useState } from "react";
import Index from "../../../../Index";
import { commonCFData } from "../../../../../config/CommonCF";

function UpdateOrderNominationQTYModal({
  openUpdateDatePopUp,
  handleCloseDateEditModal,
  handleUpdateDateViaApi,
  editTimeDateData,
  editTimeNominationQTYData,
  isSingleFieldUpdate,
}) {
  const initialValues = {
    nominatedQty: editTimeNominationQTYData,
  };

  return (
    <>
      <Index.Dialog
        open={openUpdateDatePopUp}
        onClose={handleCloseDateEditModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Index.DialogContent className="expense-model-main  modal-update-main">
          <Index.Box className="title-main">
            <Formik
              enableReinitialize
              initialValues={initialValues}
              // validationSchema={vesselValidationSchema}
              onSubmit={(values) =>
                handleUpdateDateViaApi(values, editTimeDateData)
              }
            >
              {({
                values,
                errors,
                touched,
                setFieldValue,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldTouched,
                handleFocus,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Index.Box className="title-main">
                    <Index.Box className="jus-between">
                      <Index.Typography
                        variant="h6"
                        component="h6"
                        className="input-label"
                      >
                        Edit Nominated Qty (In MT)
                      </Index.Typography>
                      <Index.ClearIcon
                        className="model-close-icon"
                        onClick={handleCloseDateEditModal}
                      />
                    </Index.Box>
                    <Index.Box className="update-model-main-box-set">
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Nominated Qty (In MT)
                      </Index.Typography>
                      <Index.Box className="input-design-div with-border">
                        <Index.TextField
                          hiddenLabel
                          id="filled-hidden-label-normal"
                          placeholder="Nominated qty (In MT)"
                          variant="filled"
                          className="admin-input-design input-placeholder"
                          name="nominatedQty"
                          onBlur={handleBlur}
                          onFocus={handleFocus}
                          value={values?.nominatedQty}
                          // inputProps={}
                          onChange={(e, value) => {
                            setFieldValue("nominatedQty", e.target.value);
                          }}
                          step={1.0}
                          error={Boolean(
                            errors?.nominatedQty
                              ? touched?.nominatedQty
                              : undefined
                          )}
                          helperText={
                            touched?.nominatedQty
                              ? errors?.nominatedQty
                              : undefined
                          }
                        />
                      </Index.Box>

                      <Index.Box className="common-button blue-button flex-end save-btn  modal-update-btn">
                        <Index.Button
                          variant="contained"
                          type="submit"
                          className="Particular_model_submit"
                          disabled={isSingleFieldUpdate ? true : false}
                        >
                          Update
                        </Index.Button>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </form>
              )}
            </Formik>
          </Index.Box>
        </Index.DialogContent>
      </Index.Dialog>
    </>
  );
}
export default UpdateOrderNominationQTYModal;
