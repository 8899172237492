import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import { Formik } from "formik";
import * as Yup from "yup";
import {
  addTugOrder,
  getTugNameOfVessel,
  getTugOrderPortList,
  getBargeListForTugs,
} from "../../../../redux/admin/action";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { DateRangePicker } from "@mui/x-date-pickers-pro";
import dayjs from "dayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { useDateTimePicker } from "../../../../context/DatePickerContext";
import { purposeOfTugs } from "../../../../config/CommonProductsItem";
import { tugOrderValidationSchema } from "../../../../validation/AdminValidation";

function TugOrderAdd() {
  const dispatch = Index.useDispatch();
  const navigate = Index.useNavigate();

  const { openPicker, closePicker } = useDateTimePicker();

  const [vesselNameList, setVesselNameList] = useState([]);
  const [allPortList, setAllPortList] = useState([]);
  const [allBargeListForTugs, setAllBargeListForTugs] = useState([]);

  //redux data
  const { tugOrderPortList } = Index.useSelector((state) => state.AdminReducer);

  // const bargeList = Index.useSelector((state) => state.AdminReducer.bargeList);
  const { tugsNameOfVesselList } = Index.useSelector(
    (state) => state.AdminReducer
  );
  const login = Index.useSelector((state) => state.AdminReducer.adminDetails);
  const { state } = Index.useLocation();
  const [loading, setLoading] = useState(false);

  //edit order
  let editData = state?.row;
  const tabType = state?.type;

  useEffect(() => {
    dispatch(getBargeListForTugs())
      .then((res) => {
        if (res.status === 200) {
          setAllBargeListForTugs(res.data);
        } else {
          setAllBargeListForTugs([]);
        }
      })
      .catch((error) => {
        console.error(error);
        setAllBargeListForTugs([]);
      });
  }, []);

  useEffect(() => {
    const vesselData = tugsNameOfVesselList?.filter((d) =>
      d?.vesselName?.toUpperCase()
    );
    const uniqueArray = vesselData?.filter(
      (v, i, a) => a?.findIndex((t) => t?.vesselName === v?.vesselName) === i
    );
    if (login?.role === "Operation") {
      // Role Operation then vesselName show in uppercase
      const newData = uniqueArray?.sort((a, b) =>
        a?.vesselName?.localeCompare(b?.vesselName)
      );
      setVesselNameList(
        newData?.map((e) => ({
          ...e,
          vesselName: e?.vesselName?.toUpperCase(),
        }))
      );
    } else {
      setVesselNameList(
        uniqueArray?.sort((a, b) => a?.vesselName?.localeCompare(b?.vesselName))
      );
    }
    setAllPortList(
      tugOrderPortList?.sort((a, b) =>
        a?.tugPortName?.localeCompare(b?.tugPortName)
      )
    );
  }, [tugsNameOfVesselList, tugOrderPortList]);

  const filter = createFilterOptions();

  useEffect(() => {
    if (editData) {
      editData.id = editData?._id;
      let portId = editData?.port?._id;
      if (portId) {
        editData.port = portId;
      }
      let allocateId = state?.row?.allocatedTugs[0];
      if (allocateId) {
        editData.allocatedTugs = allocateId;
      }

      // editData.allocatedTugs = editData?.allocatedTugs;
    }
  }, [state]);

  let initialValues = {
    vesselName: "",
    agentName: "",
    port: "",
    bookingDate: "",
    sailingDate: "",
    allocatedTugs: "",
    orderBy: "",
    purpose: "",
    other: "",
  };

  console.log("editData", editData);

  if (editData) {
    initialValues = editData;
  }

  const handleFormSubmit = async (values) => {
    const data = new URLSearchParams();
    if (values?.vesselName) {
      data.append("vesselName", values?.vesselName?.trim());
    }
    if (values?.port) {
      data.append("port", values?.port);
    }
    if (values?.allocatedTugs) {
      data.append("allocatedTugs", values?.allocatedTugs);
    }
    data.append("agentName", values?.agentName ? values?.agentName : "");
    data.append("orderBy", values?.orderBy ? values?.orderBy : "");
    data.append("purpose", values?.purpose ? values?.purpose : "");
    if (values?.orderBy === "OTHER" && values?.other?.length) {
      data.append("other", values?.other ? values?.other : "");
    }

    if (values?.bookingDate) {
      data.append(
        "bookingDate",
        Index.moment(values?.bookingDate).format("YYYY-MM-DDTHH:mm:ssZZ")
      );
    }
    if (values?.sailingDate) {
      data.append(
        "sailingDate",
        Index.moment(values?.sailingDate).format("YYYY-MM-DDTHH:mm:ssZZ")
      );
    }

    setLoading(true);
    if (editData) {
      data.append("id", editData?._id);
    }

    data.forEach((obj, key) => {
      console.log(obj, ":::::::", key);
    });

    dispatch(addTugOrder(data, navigate)).then((res) => {
      if (tabType === "cancelOrder") {
        navigate("/admin/tugsOrder", { state: { value: 1 } });
      } else if (tabType === "completedOrder") {
        navigate("/admin/tugsOrder", { state: { value: 2 } });
      } else {
        navigate("/admin/tugsOrder");
      }
      //   navigate("/admin/tugsOrder");
    });
  };

  // focus handler for disabled submit button

  const handleFocus = () => {
    setLoading(false);
  };

  //getAll port and barge
  useEffect(() => {
    dispatch(getTugOrderPortList());
    dispatch(getTugNameOfVessel());
  }, [dispatch]);

  const backBtn = () => {
    console.log("editData", editData);
    if (editData) {
      navigate(`/admin/tugsOrder/view-order/${editData?.id}`);
    } else {
      navigate("/admin/tugsOrder");
    }
    // if (tabType === "cancelOrder") {
    //   navigate("/admin/tugsOrder", { state: { value: 1 } });
    // }
    // if (tabType === "completedOrder") {
    //   navigate("/admin/tugsOrder", { state: { value: 2 } });
    // }
  };
  return (
    <>
      <Index.Box className="">
        <Index.Box className="barge-common-box">
          <Index.Box className="title-header">
            <Index.Box className="title-header-flex">
              <Index.Box className="title-main mb-10">
                <Index.Typography
                  variant="p"
                  component="p"
                  className="page-title"
                >
                  {editData ? "Edit Order" : "Add tug order"}
                </Index.Typography>
              </Index.Box>
              <Index.Box className="common-button blue-button save-btn ">
                <Index.Button
                  variant="contained"
                  type="submit"
                  className="Particular_model_submit ml-0 cancel-order-btn"
                  onClick={backBtn}
                >
                  Back
                </Index.Button>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Formik
            // enableReinitialize
            enableReinitialize={editData ? true : false}
            initialValues={initialValues}
            onSubmit={handleFormSubmit}
            validationSchema={tugOrderValidationSchema}
          >
            {({
              values,
              errors,
              touched,
              setFieldValue,
              setFieldTouched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <form onSubmit={handleSubmit}>
                <Index.Box className="">
                  <Index.Grid container spacing={2}>
                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Name Of Vessel
                      </Index.Typography>
                      <Index.Box className="input-design-div with-border">
                        <Autocomplete
                          className="invoice-select-drop enquiry-vessel-autocomplete"
                          fullWidth
                          value={values?.vesselName}
                          onChange={(event, newValue) => {
                            if (typeof newValue === "string") {
                              setFieldValue("vesselName", newValue);
                            } else if (newValue && newValue?.inputValue) {
                              setFieldValue("vesselName", newValue?.inputValue);
                            } else {
                              setFieldValue("vesselName", newValue?.vesselName);
                            }
                          }}
                          onBlur={(event, newValue) => {
                            setFieldValue("vesselName", event.target.value);
                          }}
                          filterOptions={(options, params) => {
                            const filtered = filter(options, params);
                            const { inputValue } = params;

                            // Suggest the creation of a new value
                            const isExisting = options?.some(
                              (option) => inputValue === option?.vesselName
                            );

                            if (inputValue !== "" && !isExisting) {
                              filtered.push({
                                inputValue,
                                vesselName: `Add "${inputValue}"`,
                              });
                            }

                            return filtered;
                          }}
                          selectOnFocus
                          clearOnBlur
                          handleHomeEndKeys
                          id="free-solo-with-text-demo"
                          options={vesselNameList}
                          getOptionLabel={(option) => {
                            // Value selected with enter, right from the input
                            if (typeof option === "string") {
                              return option;
                            }
                            // Add "xyz" option created dynamically
                            if (option?.inputValue) {
                              return option?.inputValue;
                            }
                            // Regular option
                            return option?.vesselName;
                          }}
                          renderOption={(props, option) => (
                            <li {...props}>{option?.vesselName}</li>
                          )}
                          freeSolo
                          renderInput={(params) => (
                            <Index.TextField
                              className="admin-input-design input-placeholder"
                              {...params}
                              fullWidth
                              placeholder="Name Of Vessel"
                              // label="Free solo with text demo"
                            />
                          )}
                        />
                        {/* {touched?.vesselName && errors?.vesselName && (
                          <Index.FormHelperText error>
                            {errors?.vesselName}
                          </Index.FormHelperText>
                        )} */}
                      </Index.Box>
                    </Index.Grid>

                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Agent
                      </Index.Typography>
                      <Index.Box className="input-design-div with-border">
                        <Index.TextField
                          hiddenLabel
                          id="filled-hidden-label-normal"
                          placeholder="Agent"
                          variant="filled"
                          className="admin-input-design input-placeholder"
                          name="agentName"
                          onFocus={handleFocus}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values?.agentName}
                          // error={Boolean(
                          //   errors?.agentName ? touched?.agentName : undefined
                          // )}
                          // helperText={
                          //   touched?.agentName ? errors?.agentName : undefined
                          // }
                        />
                      </Index.Box>
                    </Index.Grid>

                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Port
                      </Index.Typography>
                      <Index.Box className="input-design-div with-border">
                        {editData?._id && values?.port ? (
                          allPortList?.length && (
                            <Index.Autocomplete
                              disablePortal
                              className="invoice-select-drop"
                              id="combo-box-demo"
                              options={allPortList}
                              getOptionLabel={(option) => option?.tugPortName}
                              value={
                                allPortList[
                                  allPortList.findIndex(
                                    (e) => e._id === values?.port
                                  )
                                ]
                              }
                              renderOption={(props, option) => (
                                <Index.Box
                                  component="li"
                                  sx={{
                                    "& > img": { mr: 2, flexShrink: 0 },
                                  }}
                                  {...props}
                                >
                                  {option?.tugPortName}
                                </Index.Box>
                              )}
                              onChange={(event, newValue) => {
                                setFieldValue("port", newValue?._id);
                              }}
                              renderInput={(params) => (
                                <Index.TextField
                                  {...params}
                                  placeholder="Port"
                                  variant="outlined"
                                />
                              )}
                            />
                          )
                        ) : (
                          <Index.Autocomplete
                            disablePortal
                            className="invoice-select-drop"
                            id="combo-box-demo"
                            options={allPortList}
                            getOptionLabel={(option) => option?.tugPortName}
                            renderOption={(props, option) => (
                              <Index.Box
                                component="li"
                                sx={{
                                  "& > img": { mr: 2, flexShrink: 0 },
                                }}
                                {...props}
                              >
                                {option?.tugPortName}
                              </Index.Box>
                            )}
                            onChange={(event, newValue) => {
                              setFieldValue("port", newValue?._id);
                            }}
                            renderInput={(params) => (
                              <Index.TextField
                                {...params}
                                placeholder="Port"
                                variant="outlined"
                              />
                            )}
                          />
                        )}

                        {/* {touched?.port && errors?.port && (
                          <Index.FormHelperText error>
                            {errors?.port}
                          </Index.FormHelperText>
                        )} */}
                      </Index.Box>
                    </Index.Grid>

                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Booking Date
                      </Index.Typography>
                      <Index.Box className="input-design-div">
                        <Index.Box className="set-date set-date-picker">
                          <Index.LocalizationProvider
                            dateAdapter={Index.AdapterDayjs}
                          >
                            <Index.DemoContainer
                              components={[
                                "Index.DateTimePicker",
                                "Index.DateTimePicker",
                                "Index.DateTimePicker",
                              ]}
                            >
                              <DateTimePicker
                                views={[
                                  "year",
                                  "month",
                                  "day",
                                  "hours",
                                  "minutes",
                                ]}
                                timeSteps={{
                                  hours: 1,
                                  minutes: 1,
                                }}
                                id="bookingDate"
                                defaultValue={
                                  values?.bookingDate
                                    ? dayjs(
                                        Index.moment(
                                          editData?.bookingDate
                                        ).format("YYYY-MM-DDTHH:mm")
                                      )
                                    : ""
                                }
                                onOpen={() => {
                                  openPicker();
                                }}
                                onClose={() => {
                                  closePicker();
                                }}
                                onChange={(value) => {
                                  const formatedDate = Index.moment(
                                    value.$d
                                  ).format("YYYY-MM-DDTHH:mm:ssZZ");
                                  setFieldValue("bookingDate", formatedDate);
                                }}
                                slotProps={{
                                  textField: {
                                    readOnly: true,
                                  },
                                }}
                                format="DD/MM/YYYY HH:mm"
                              />
                            </Index.DemoContainer>
                          </Index.LocalizationProvider>
                        </Index.Box>
                      </Index.Box>
                    </Index.Grid>

                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Sailing Date
                      </Index.Typography>
                      <Index.Box className="input-design-div">
                        <Index.Box className="set-date set-date-picker">
                          <Index.LocalizationProvider
                            dateAdapter={Index.AdapterDayjs}
                          >
                            <Index.DemoContainer
                              components={[
                                "Index.DateTimePicker",
                                "Index.DateTimePicker",
                                "Index.DateTimePicker",
                              ]}
                            >
                              <DateTimePicker
                                views={[
                                  "year",
                                  "month",
                                  "day",
                                  "hours",
                                  "minutes",
                                ]}
                                timeSteps={{
                                  hours: 1,
                                  minutes: 1,
                                }}
                                id="sailingDate"
                                defaultValue={
                                  values?.sailingDate
                                    ? dayjs(
                                        Index.moment(
                                          editData?.sailingDate
                                        ).format("YYYY-MM-DDTHH:mm")
                                      )
                                    : ""
                                }
                                onOpen={() => {
                                  openPicker();
                                }}
                                onClose={() => {
                                  closePicker();
                                }}
                                onChange={(value) => {
                                  const formatedDate = Index.moment(
                                    value.$d
                                  ).format("YYYY-MM-DDTHH:mm:ssZZ");
                                  setFieldValue("sailingDate", formatedDate);
                                }}
                                slotProps={{
                                  textField: {
                                    readOnly: true,
                                  },
                                }}
                                format="DD/MM/YYYY HH:mm"
                              />
                            </Index.DemoContainer>
                          </Index.LocalizationProvider>
                        </Index.Box>
                      </Index.Box>
                    </Index.Grid>

                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Allocate Tug
                      </Index.Typography>
                      <Index.Box className="input-design-div with-border">
                        {editData?._id && values?.allocatedTugs ? (
                          allBargeListForTugs?.length && (
                            <Index.Autocomplete
                              disablePortal
                              className="invoice-select-drop"
                              id="combo-box-demo"
                              options={allBargeListForTugs}
                              getOptionLabel={(option) => option?.bargeName}
                              value={
                                allBargeListForTugs[
                                  allBargeListForTugs.findIndex(
                                    (e) => e._id === values?.allocatedTugs
                                  )
                                ]
                              }
                              renderOption={(props, option) => (
                                <Index.Box
                                  component="li"
                                  sx={{
                                    "& > img": { mr: 2, flexShrink: 0 },
                                  }}
                                  {...props}
                                >
                                  {option?.bargeName}
                                </Index.Box>
                              )}
                              onChange={(event, newValue) => {
                                setFieldValue("allocatedTugs", newValue?._id);
                              }}
                              renderInput={(params) => (
                                <Index.TextField
                                  {...params}
                                  placeholder="Allocate Tug"
                                  variant="outlined"
                                />
                              )}
                            />
                          )
                        ) : (
                          <Index.Autocomplete
                            disablePortal
                            className="invoice-select-drop"
                            id="combo-box-demo"
                            options={allBargeListForTugs}
                            getOptionLabel={(option) => option?.bargeName}
                            renderOption={(props, option) => (
                              <Index.Box
                                component="li"
                                sx={{
                                  "& > img": { mr: 2, flexShrink: 0 },
                                }}
                                {...props}
                              >
                                {option?.bargeName}
                              </Index.Box>
                            )}
                            onChange={(event, newValue) => {
                              setFieldValue("allocatedTugs", newValue?._id);
                            }}
                            renderInput={(params) => (
                              <Index.TextField
                                {...params}
                                placeholder="Allocate Tug"
                                variant="outlined"
                              />
                            )}
                          />
                        )}

                        {/* {touched?.port && errors?.port && (
                          <Index.FormHelperText error>
                            {errors?.port}
                          </Index.FormHelperText>
                        )} */}
                      </Index.Box>
                    </Index.Grid>

                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Order By
                      </Index.Typography>
                      <Index.Box className="input-design-div with-border">
                        <Index.TextField
                          hiddenLabel
                          id="filled-hidden-label-normal"
                          placeholder="Order By"
                          variant="filled"
                          className="admin-input-design input-placeholder"
                          name="orderBy"
                          onFocus={handleFocus}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values?.orderBy}
                        />
                      </Index.Box>
                    </Index.Grid>

                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Purpose
                      </Index.Typography>
                      <Index.Box className="input-design-div with-border">
                        <Index.Select
                          className="placeholder-set-text-box"
                          fullWidth
                          name="terminal"
                          value={values?.purpose}
                          displayEmpty
                          inputProps={{ "aria-label": "Without label" }}
                          defaultValue={values ? values?.purpose : ""}
                          onChange={(e) => {
                            console.log("purpose Value", e.target.value);
                            setFieldValue("purpose", e.target.value);
                            if (e.target.value !== "Other") {
                              setFieldValue("other", ""); // Reset otherPurpose if not "OTHER"
                              setFieldTouched("other", false);
                            }
                            if (e.target.value === "Other") {
                              setFieldTouched("other", false);
                            }
                          }}
                        >
                          <Index.MenuItem value={""}>
                            <Index.MenuItem
                              disabled
                              className="all-select-label select-label-font"
                            >
                              Select Purpose
                            </Index.MenuItem>
                          </Index.MenuItem>
                          {purposeOfTugs?.map((items, index) => (
                            <Index.MenuItem
                              value={items?.name}
                              key={items?._id}
                            >
                              {items?.name}
                            </Index.MenuItem>
                          ))}
                        </Index.Select>
                      </Index.Box>
                    </Index.Grid>
                    {console.log("errors", errors)}
                    {console.log("values", values)}
                    {values?.purpose === "Other" ? (
                      <>
                        <Index.Grid item xs={12} sm={6} md={4}>
                          <Index.Typography
                            variant="label"
                            component="label"
                            className="input-label"
                          >
                            Other
                          </Index.Typography>
                          <Index.Box className="input-design-div with-border">
                            <Index.TextField
                              hiddenLabel
                              id="filled-hidden-label-normal"
                              placeholder="Other"
                              variant="filled"
                              className="admin-input-design input-placeholder"
                              name="other"
                              onFocus={handleFocus}
                              onChange={(e) => {
                                if (e.target.value?.length <= 50) {
                                  setFieldValue("other", e.target.value);
                                }
                              }}
                              onBlur={handleBlur}
                              // onChange={(e) => {
                              //   setFieldValue("other", e.target.value);
                              //   setFieldTouched("other", true); // Mark 'other' field as touched
                              // }}
                              // onBlur={() => setFieldTouched("other", true)}
                              value={values?.other}
                            />
                            {touched?.other && errors?.other && (
                              <Index.FormHelperText error>
                                {errors?.other}
                              </Index.FormHelperText>
                            )}
                          </Index.Box>
                        </Index.Grid>
                      </>
                    ) : (
                      <></>
                    )}
                  </Index.Grid>

                  {/* <Index.Box className="line" mt={3} mb={4}></Index.Box> */}
                </Index.Box>
                <Index.Box className="common-button blue-button flex-end save-btn mt-20">
                  <Index.Button
                    variant="contained"
                    type="submit"
                    disabled={loading}
                  >
                    {editData ? "Update" : "Submit"}
                  </Index.Button>
                </Index.Box>
              </form>
            )}
          </Formik>
        </Index.Box>
      </Index.Box>
    </>
  );
}

export default TugOrderAdd;
