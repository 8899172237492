import { Formik } from "formik";
import Index from "../../../Index";
import {
  dashboarKOTAKdBankDataSchema,
  dashboarHDFCdBankDataSchema,
} from "../../../../validation/AdminValidation";
import {
  addBankDashboardBankAmount,
  getDashboardBankAmountDetails,
  getNewBankList,
} from "../../../../redux/admin/action";
import { toast } from "react-toastify";
import React from "react";
function DashboardBankData() {
  const dispatch = Index.useDispatch();
  const [HDFCBankRecords, setHDFCBankRecords] = React.useState(0);
  const [KOTAKBankRecords, setKOTAKBankRecords] = React.useState(0);
  const [newBankListData, setNewBankListData] = React.useState([]);

  let hdfcInitialValues = {
    HDFCBankAmount: HDFCBankRecords,
  };
  let kotakInitialValues = {
    KOTAKBankAmount: KOTAKBankRecords,
  };

  const getDashboardBankAmounts = () => {
    dispatch(getDashboardBankAmountDetails())
      .then((res) => {
        if (res.status === 200) {
          if (res.data?.length) {
            res.data.map((el) => {
              if (el.name === "hdfc") {
                setHDFCBankRecords(el.opening_balance);
              } else if (el.name === "kotak") {
                setKOTAKBankRecords(el.opening_balance);
              }
            });
          } else {
            setHDFCBankRecords(0);
            setKOTAKBankRecords(0);
          }
        }
      })
      .catch((error) =>
        console.log("error occurred while fetching bank amount", error)
      );
  };

  // when the get new bank list data then used...
  const handleGetBankListData = () => {
    dispatch(getNewBankList())
      .then((res) => {
        if (res.status === 200) {
          setNewBankListData(res.data);
        } else {
          setNewBankListData([]);
        }
      })
      .catch((error) => {
        setNewBankListData([]);
        console.error(error);
      });
  };

  // when the page first time load then used....
  React.useEffect(() => {
    handleGetBankListData();
    // getDashboardBankAmounts();
  }, []);

  // when the user submit the dashboard for HDFC bank data submission then used...
  const handleHDFCBankDataSubmit = async (values) => {
    const finalValues = {
      type: "hdfc",
      amount: values.HDFCBankAmount,
    };

    try {
      dispatch(addBankDashboardBankAmount(finalValues)).then((res) => {
        if (res?.status === 200) {
          toast.success(res.message);
        } else {
          toast.error(res.message);
        }
      });
    } catch (error) {
      console.log("hdfc bank data add time error", error);
    }
  };

  // when the user submit the kotak data then used...
  const handleKOTAKBankDataSubmit = async (values) => {
    const finalValues = {
      type: "kotak",
      amount: values.KOTAKBankAmount,
    };
    try {
      dispatch(addBankDashboardBankAmount(finalValues)).then((res) => {
        if (res?.status === 200) {
          toast.success(res.message);
        } else {
          toast.error(res.message);
        }
      });
    } catch (error) {
      console.log("hdfc bank data add time error", error);
    }
  };
  return (
    <>
      <Index.Box className="">
        <Index.Box className="barge-common-box">
          <Index.Box className="title-header">
            <Index.Box className="title-header-flex">
              <Index.Box className="title-main mb-10">
                <Index.Typography
                  variant="p"
                  component="p"
                  className="page-title"
                >
                  Add Dashboard Bank Data
                </Index.Typography>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          {/* <Formik
            initialValues={hdfcInitialValues}
            onSubmit={handleHDFCBankDataSubmit}
            enableReinitialize
          >
            {({
              values,
              errors,
              touched,
              setFieldValue,
              handleChange,
              handleSubmit,
            }) => (
              <form onSubmit={handleSubmit} style={{ marginBottom: "25px" }}>
                <Index.Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    width: "60%",
                    gap:"20px"
                  }}
                >
                  <Index.Typography
                    variant="label"
                    component="label"
                    className="input-label"
                    sx={{paddingBottom: '0px !important'}}
                  >
                    HDFC Bank
                  </Index.Typography>

                  <Index.Box
                    className="input-design-div with-border"
                    sx={{ width: "300px" }}
                  >
                    <Index.TextField
                      hiddenLabel
                      id="filled-hidden-label-normal"
                      placeholder="HDFC Bank"
                      variant="filled"
                      className="admin-input-design input-placeholder"
                      name="HDFCBankAmount"
                      onChange={handleChange}
                      value={values.HDFCBankAmount}
                    />
                  </Index.Box>

                  <Index.Box className="common-button blue-button flex-end save-btn ">
                    <Index.Button variant="contained" type="submit">
                      Submit
                    </Index.Button>
                  </Index.Box>
                </Index.Box>
              </form>
            )}
          </Formik>

          <Formik
            initialValues={kotakInitialValues}
            onSubmit={handleKOTAKBankDataSubmit}
            enableReinitialize
          >
            {({
              values,
              errors,
              touched,
              setFieldValue,
              handleChange,
              handleSubmit,
            }) => (
              <form onSubmit={handleSubmit}>
                <Index.Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    width: "60%",
                    gap:"20px"
                  }}
                >
                  <Index.Typography
                    variant="label"
                    component="label"
                    className="input-label"
                    sx={{paddingBottom: '0px !important'}}
                  >
                    Kotak Bank
                  </Index.Typography>
                  <Index.Box
                    className="input-design-div with-border"
                    sx={{ width: "300px" }}
                  >
                    <Index.TextField 
                      hiddenLabel
                      id="filled-hidden-label-normal"
                      placeholder="Kotak Bank"
                      variant="filled"
                      className="admin-input-design input-placeholder"
                      name="KOTAKBankAmount"
                      onChange={handleChange}
                      value={values?.KOTAKBankAmount}
                      type="number"
                      inputProps={{ maxLength: 2 }}
                    />
                  </Index.Box>

                  <Index.Box className="common-button blue-button flex-end save-btn ">
                    <Index.Button variant="contained" type="submit">
                      Submit
                    </Index.Button>
                  </Index.Box>
                </Index.Box>
              </form>
            )}
          </Formik> */}

          {newBankListData?.length ? (
            <>
              {newBankListData?.map((item) => (
                <Index.Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "60%",
                    gap: "20px",
                    marginBottom: "10px",
                  }}
                >
                  <Index.Typography
                    variant="label"
                    component="label"
                    className="input-label"
                    sx={{ paddingBottom: "0px !important" }}
                  >
                    {item?.name}
                  </Index.Typography>
                  <Index.Box
                    className="input-design-div with-border"
                    sx={{ width: "300px" }}
                  >
                    <Index.TextField
                      hiddenLabel
                      id="filled-hidden-label-normal"
                      placeholder="Kotak Bank"
                      variant="filled"
                      className="admin-input-design input-placeholder"
                      name="KOTAKBankAmount"
                      disabled
                      value={item?.opening_balance}
                      type="number"
                      inputProps={{ maxLength: 2 }}
                    />
                  </Index.Box>
                </Index.Box>
              ))}
            </>
          ) : (
            <>No Data Found</>
          )}
        </Index.Box>
      </Index.Box>
    </>
  );
}

export default DashboardBankData;
