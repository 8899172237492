
import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import { Formik } from "formik";
import * as Yup from "yup";
import {
  addOrderSlopeSludge,
  getSlopeSludgeOfVessel,
  getSlopeSludgeOrderPortList,
} from "../../../../redux/admin/action";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { DateRangePicker } from "@mui/x-date-pickers-pro";
import dayjs from "dayjs";
import {
  SlopeSludgeProductData,
} from "../../../../config/CommonProductsItem";
import { commonCFData } from "../../../../config/CommonCF";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

function SlopeAndSludgeOrderAdd() {
  const dispatch = Index.useDispatch();
  const navigate = Index.useNavigate();
  const [vesselNameList, setVesselNameList] = useState([]);
  const [allPortList, setAllPortList] = useState([]);
  const [allProductData, setAllProductData] = useState([]);
  const [pdfName, setPdfName] = useState("");
  const [wharfagePdfName, setWharfagePdfName] = useState("");
  const [pdfFilesName, setPdfFilesName] = useState([]);

    // const [portID, setPortID] = useState("");

  //redux data
  const login = Index.useSelector((state) => state.AdminReducer.adminDetails);
  const { state } = Index.useLocation();
  const [loading, setLoading] = useState(false);
  const validationSchema = Yup.object().shape({
    slopeDocument: Yup.array()
      // .required("Please upload at least one image")
      .min(1, "Please select at least one image/pdf")
      .test(
        "fileType",
        "Unsupported file format please upload only image or pdf files",
        (value) => {
          const supportedFormats = [
            "image/jpg",
            "image/jpeg",
            "image/png",
            "application/pdf",
          ];

          if (value?.length) {
            for (let i = 0; i < value.length; i++) {
              const file = value[i];

              if (typeof file === "string") {
                const extension = file.split(".").pop().toLowerCase();
                // console.log(extension, "extension179");
                if (!["jpg", "jpeg", "png", "pdf"].includes(extension)) {
                  return false;
                }
              } else if (file?.name) {
                const extension = file.name.split(".").pop().toLowerCase();
                // console.log(extension, "extension");
                if (!supportedFormats.includes(file.type)) {
                  return false;
                }
              }
            }
            return true;
          }

          return false;
        }
      ),
  });

  //edit order
  let editData = state?.row;
  const tabType = state?.type;


  const filter = createFilterOptions();

  if (editData) {
    editData.id = editData?._id;
    let portId = editData?.port?._id;
    if (portId) {
      editData.port = portId;
    }
  }

  // initial value

  let initialValues = {
    vesselName: "",
    vesselEta: "",
    vesselEtb: "",
    vesselEtd: "",
    coastal: "",
    qty: "",
    product: "",
    agentName: "",
    port: "",
    orderBy: "",
    tripDateTime: "",
    slopeDocument: [],
  };
 if (editData) {
   initialValues = editData;
 }
 

  useEffect(() => {
    if (pdfFilesName) {
      setWharfagePdfName(pdfFilesName);
    }
  }, [pdfFilesName]);

  useEffect(() => {
    setPdfName(editData?.nominationPdf);
    if (editData?.slopeDocument?.length) {
      setWharfagePdfName(editData?.slopeDocument);
    }
  }, [editData]);

  useEffect(() => {
    setAllProductData(
      SlopeSludgeProductData?.sort((a, b) => a?.item?.localeCompare(b?.item))
    );
  }, [SlopeSludgeProductData]);

  //get Slope and Sludge vessel name list
  const getSlopeSludgeOfVesselList = () => {
    dispatch(getSlopeSludgeOfVessel()).then((res) => {
      if (res?.status !== 200) setVesselNameList([]);
      const vesselData = res?.data?.filter((d) => d?.vesselName?.toUpperCase());
      const uniqueArray = vesselData?.filter(
        (v, i, a) => a?.findIndex((t) => t?.vesselName === v?.vesselName) === i
      );
      if (login?.role === "Operation") {
        // Role Operation then vesselName show in uppercase
        const newData = uniqueArray?.sort((a, b) =>
          a?.vesselName?.localeCompare(b?.vesselName)
        );
        setVesselNameList(
          newData?.map((e) => ({
            ...e,
            vesselName: e?.vesselName?.toUpperCase(),
          }))
        );
      } else {
        setVesselNameList(
          uniqueArray?.sort((a, b) =>
            a?.vesselName?.localeCompare(b?.vesselName)
          )
        );
      }
    });
  };

  //get sludge port list
  const getSlopeSludgePortList = () => {
    dispatch(getSlopeSludgeOrderPortList()).then((res) => {
      if (res?.status !== 200) setAllPortList([]);
      const sortedData = res?.data?.sort((a, b) =>
        a?.slopePortName?.localeCompare(b?.slopePortName)
      );
      if (!sortedData?.length) setAllPortList([]);
      setAllPortList(sortedData);
    });
  };

  useEffect(() => {
      getSlopeSludgeOfVesselList();
      getSlopeSludgePortList();
  }, []);
    
  const handleFormSubmit = async (values) => {
    // console.log(values,"158val");
    const data = new FormData();
    if (values?.vesselName) {
      data.append("vesselName", values?.vesselName?.trim());
    }
    if (values?.vesselEta) {
      data.append(
        "vesselEta",
        Index.moment(values?.vesselEta).format("YYYY-MM-DDTHH:mm:ssZZ")
      );
    }
    if (values?.vesselEtb) {
      data.append(
        "vesselEtb",
        Index.moment(values?.vesselEtb).format("YYYY-MM-DDTHH:mm:ssZZ")
      );
    }
    if (values?.vesselEtd) {
      data.append(
        "vesselEtd",
        Index.moment(values?.vesselEtd).format("YYYY-MM-DDTHH:mm:ssZZ")
      );
    }
    if (values?.coastal) {
      data.append("coastal", values?.coastal);
    }
    if (values?.qty) {
      data.append("qty", values?.qty);
    }
    if (values?.product) {
      data.append("product", values?.product);
    }
    if (values?.agentName) {
      data.append("agentName", values?.agentName);
    }
    if (values?.port) {
      data.append("port", values?.port);
    }
    if (values?.orderBy) {
      data.append("orderBy", values?.orderBy);
    }
    if (values?.tripDateTime) {
      data.append(
        "tripDateTime",
        Index.moment(values?.tripDateTime).format("YYYY-MM-DDTHH:mm:ssZZ")
      );
    }

    if (pdfFilesName?.length > 0) {
      // console.log('abcd')
      values.slopeDocument.forEach((ele, i) => {
        data.append("slopeDocument", ele);
      });
    }

    setLoading(true);
    if (editData) {
      data.append("id", editData?._id);
    }
    dispatch(addOrderSlopeSludge(data, navigate)).then((res) => {
      if (tabType === "cancelOrder") {
        navigate("/admin/slopeSludgeOrder", { state: { value: 1 } });
      } else if (tabType === "completedOrder") {
        navigate("/admin/slopeSludgeOrder", { state: { value: 2 } });
      } else {
        navigate("/admin/slopeSludgeOrder");
      }
    });
  };

  // focus handler for disabled submit button

  const handleFocus = () => {
    setLoading(false);
  };

  const backBtn = () => {
    navigate("/admin/slopeSludgeOrder");
    if (tabType === "cancelOrder") {
      navigate("/admin/slopeSludgeOrder", { state: { value: 1 } });
    }
    if (tabType === "completedOrder") {
      navigate("/admin/slopeSludgeOrder", { state: { value: 2 } });
    }
  };
  // const cancelOrderBtn = () => {
  //   const data = new URLSearchParams();
  //   data.append("id", editData?.id);
  //   data.append("cancelStatus", "Cancel");
  //   dispatch(cancelOrder(data, navigate));
  // };

  // useEffect(() => {
  //   bargeListByCategory();
  // }, [portID]);

  return (
    <>
      <Index.Box className="">
        <Index.Box className="barge-common-box">
          <Index.Box className="title-header">
            <Index.Box className="title-header-flex">
              <Index.Box className="title-main mb-10">
                <Index.Typography
                  variant="p"
                  component="p"
                  className="page-title"
                >
                  {editData
                    ? "Edit slope and sludge order"
                    : "Slope and Sludge Add order"}
                </Index.Typography>
              </Index.Box>
              <Index.Box className="common-button blue-button save-btn ">
                {/* {editData?.orderStatus !== "Cancel" ? (
                  <Index.Button
                    variant="contained"
                    type="submit"
                    className="Particular_model_submit ml-0"
                    onClick={cancelOrderBtn}
                  >
                    Cancel Order
                  </Index.Button>
                ) : (
                  ""
                )} */}
                <Index.Button
                  variant="contained"
                  type="submit"
                  className="Particular_model_submit ml-0 cancel-order-btn"
                  onClick={backBtn}
                >
                  Back
                </Index.Button>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Formik
            // enableReinitialize
            enableReinitialize={editData ? true : false}
            initialValues={initialValues}
            // validationSchema={nomintorValidationSchema}
            validationSchema={validationSchema}
            onSubmit={handleFormSubmit}
          >
            {({
              values,
              errors,
              touched,
              setFieldValue,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldTouched
            }) => (
              <form onSubmit={handleSubmit}>
                <Index.Box className="">
                  <Index.Grid container spacing={2}>
                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Vessel Name
                      </Index.Typography>
                      <Index.Box className="input-design-div with-border">
                        <Autocomplete
                          className="invoice-select-drop enquiry-vessel-autocomplete"
                          fullWidth
                          value={values?.vesselName}
                          onChange={(event, newValue) => {
                            if (typeof newValue === "string") {
                              setFieldValue("vesselName", newValue);
                            } else if (newValue && newValue?.inputValue) {
                              setFieldValue("vesselName", newValue?.inputValue);
                            } else {
                              setFieldValue("vesselName", newValue?.vesselName);
                            }
                          }}
                          onBlur={(event, newValue) => {
                            setFieldValue("vesselName", event.target.value);
                          }}
                          filterOptions={(options, params) => {
                            const filtered = filter(options, params);
                            const { inputValue } = params;

                            // Suggest the creation of a new value
                            const isExisting = options?.some(
                              (option) => inputValue === option?.vesselName
                            );

                            if (inputValue !== "" && !isExisting) {
                              filtered.push({
                                inputValue,
                                vesselName: `Add "${inputValue}"`,
                              });
                            }

                            return filtered;
                          }}
                          selectOnFocus
                          clearOnBlur
                          handleHomeEndKeys
                          id="free-solo-with-text-demo"
                          options={vesselNameList}
                          getOptionLabel={(option) => {
                            // Value selected with enter, right from the input
                            if (typeof option === "string") {
                              return option;
                            }
                            // Add "xyz" option created dynamically
                            if (option?.inputValue) {
                              return option?.inputValue;
                            }
                            // Regular option
                            return option?.vesselName;
                          }}
                          renderOption={(props, option) => (
                            <li {...props}>{option?.vesselName}</li>
                          )}
                          freeSolo
                          renderInput={(params) => (
                            <Index.TextField
                              className="admin-input-design input-placeholder"
                              {...params}
                              fullWidth
                              placeholder="Vessel name"
                              // label="Free solo with text demo"
                            />
                          )}
                        />
                        {touched?.vesselName && errors?.vesselName && (
                          <Index.FormHelperText error>
                            {errors?.vesselName}
                          </Index.FormHelperText>
                        )}
                      </Index.Box>
                    </Index.Grid>

                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Vessel ETA
                      </Index.Typography>
                      <Index.Box className="input-design-div">
                        <Index.Box className="set-date set-date-picker">
                          <Index.LocalizationProvider
                            dateAdapter={Index.AdapterDayjs}
                          >
                            <Index.DemoContainer
                              components={[
                                "Index.DateTimePicker",
                                "Index.DateTimePicker",
                                "Index.DateTimePicker",
                              ]}
                            >
                              <DateTimePicker
                                views={[
                                  "year",
                                  "month",
                                  "day",
                                  "hours",
                                  "minutes",
                                ]}
                                timeSteps={{
                                  hours: 1,
                                  minutes: 1,
                                }}
                                id="vesselEta"
                                defaultValue={
                                  values?.vesselEta
                                    ? dayjs(
                                        Index.moment(
                                          editData?.vesselEta
                                        ).format("YYYY-MM-DDTHH:mm")
                                      )
                                    : ""
                                }
                                onChange={(value) => {
                                  const formatedDate = Index.moment(
                                    value.$d
                                  ).format("YYYY-MM-DDTHH:mm:ssZZ");
                                  setFieldValue("vesselEta", formatedDate);
                                }}
                                slotProps={{
                                  textField: {
                                    readOnly: true,
                                  },
                                }}
                                format="DD/MM/YYYY HH:mm"
                              />
                            </Index.DemoContainer>
                          </Index.LocalizationProvider>
                        </Index.Box>
                      </Index.Box>
                    </Index.Grid>

                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Vessel ETB
                      </Index.Typography>
                      <Index.Box className="input-design-div">
                        <Index.Box className="set-date set-date-picker">
                          <Index.LocalizationProvider
                            dateAdapter={Index.AdapterDayjs}
                          >
                            <Index.DemoContainer
                              components={[
                                "Index.DateTimePicker",
                                "Index.DateTimePicker",
                                "Index.DateTimePicker",
                              ]}
                            >
                              <DateTimePicker
                                views={[
                                  "year",
                                  "month",
                                  "day",
                                  "hours",
                                  "minutes",
                                ]}
                                timeSteps={{
                                  hours: 1,
                                  minutes: 1,
                                }}
                                id="vesselEtb"
                                defaultValue={
                                  values?.vesselEtb
                                    ? dayjs(
                                        Index.moment(
                                          editData?.vesselEtb
                                        ).format("YYYY-MM-DDTHH:mm")
                                      )
                                    : ""
                                }
                                onChange={(value) => {
                                  const formatedDate = Index.moment(
                                    value.$d
                                  ).format("YYYY-MM-DDTHH:mm:ssZZ");
                                  setFieldValue("vesselEtb", formatedDate);
                                }}
                                slotProps={{
                                  textField: {
                                    readOnly: true,
                                  },
                                }}
                                format="DD/MM/YYYY HH:mm"
                              />
                            </Index.DemoContainer>
                          </Index.LocalizationProvider>
                        </Index.Box>
                      </Index.Box>
                    </Index.Grid>

                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Vessel ETD
                      </Index.Typography>
                      <Index.Box className="input-design-div">
                        <Index.Box className="set-date set-date-picker">
                          <Index.LocalizationProvider
                            dateAdapter={Index.AdapterDayjs}
                          >
                            <Index.DemoContainer
                              components={[
                                "Index.DateTimePicker",
                                "Index.DateTimePicker",
                                "Index.DateTimePicker",
                              ]}
                            >
                              <DateTimePicker
                                views={[
                                  "year",
                                  "month",
                                  "day",
                                  "hours",
                                  "minutes",
                                ]}
                                timeSteps={{
                                  hours: 1,
                                  minutes: 1,
                                }}
                                id="vesselEtd"
                                defaultValue={
                                  values?.vesselEtd
                                    ? dayjs(
                                        Index.moment(
                                          editData?.vesselEtd
                                        ).format("YYYY-MM-DDTHH:mm")
                                      )
                                    : ""
                                }
                                onChange={(value) => {
                                  const formatedDate = Index.moment(
                                    value.$d
                                  ).format("YYYY-MM-DDTHH:mm:ssZZ");
                                  setFieldValue("vesselEtd", formatedDate);
                                }}
                                slotProps={{
                                  textField: {
                                    readOnly: true,
                                  },
                                }}
                                format="DD/MM/YYYY HH:mm"
                              />
                            </Index.DemoContainer>
                          </Index.LocalizationProvider>
                        </Index.Box>
                      </Index.Box>
                    </Index.Grid>

                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        C/F
                      </Index.Typography>
                      <Index.Box className="input-design-div with-border">
                        {editData?._id && values?.coastal ? (
                          commonCFData?.length && (
                            <Index.Autocomplete
                              disablePortal
                              className="invoice-select-drop"
                              id="combo-box-demo"
                              options={commonCFData}
                              getOptionLabel={(option) => option?.name}
                              value={
                                commonCFData[
                                  commonCFData.findIndex(
                                    (e) => e?.name === values?.coastal
                                  )
                                ]
                              }
                              renderOption={(props, option) => (
                                <Index.Box
                                  component="li"
                                  sx={{
                                    "& > img": { mr: 2, flexShrink: 0 },
                                  }}
                                  {...props}
                                >
                                  {option?.name}
                                </Index.Box>
                              )}
                              onChange={(event, newValue) => {
                                setFieldValue("coastal", newValue?.name);
                              }}
                              renderInput={(params) => (
                                <Index.TextField
                                  {...params}
                                  placeholder="C/F"
                                  variant="outlined"
                                />
                              )}
                            />
                          )
                        ) : (
                          <Index.Autocomplete
                            disablePortal
                            className="invoice-select-drop"
                            id="combo-box-demo"
                            options={commonCFData}
                            getOptionLabel={(option) => option?.name}
                            renderOption={(props, option) => (
                              <Index.Box
                                component="li"
                                sx={{
                                  "& > img": { mr: 2, flexShrink: 0 },
                                }}
                                {...props}
                              >
                                {option?.name}
                              </Index.Box>
                            )}
                            onChange={(event, newValue) => {
                              setFieldValue("coastal", newValue?.name);
                            }}
                            renderInput={(params) => (
                              <Index.TextField
                                {...params}
                                placeholder="C/F"
                                variant="outlined"
                              />
                            )}
                          />
                        )}
                        {touched?.coastal && errors?.coastal && (
                          <Index.FormHelperText error>
                            {errors?.coastal}
                          </Index.FormHelperText>
                        )}
                      </Index.Box>
                    </Index.Grid>

                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Quantity
                      </Index.Typography>
                      <Index.Box className="input-design-div with-border">
                        <Index.TextField
                          hiddenLabel
                          id="filled-hidden-label-normal"
                          placeholder="Quantity"
                          variant="filled"
                          className="admin-input-design input-placeholder"
                          name="qty"
                          // onChange={handleChange}
                          onChange={(event) => {
                            const qtyData = event?.target?.value?.replace(
                              /^(\d*\.\d{0,3}|\.\d{1,3})\d*$/,
                              "$1"
                            );
                            setFieldValue("qty", qtyData);
                          }}
                          onBlur={handleBlur}
                          onFocus={handleFocus}
                          value={values?.qty}
                          // inputProps={}
                          step={1.0}
                          error={Boolean(
                            errors?.qty ? touched?.qty : undefined
                          )}
                          helperText={touched?.qty ? errors?.qty : undefined}
                        />
                      </Index.Box>
                    </Index.Grid>
                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Product Name
                      </Index.Typography>
                      <Index.Box className="input-design-div with-border">
                        <Index.Select
                          className="placeholder-set-text-box"
                          fullWidth
                          name="terminal"
                          value={values?.product}
                          displayEmpty
                          inputProps={{ "aria-label": "Without label" }}
                          defaultValue={values ? values?.product : ""}
                          onChange={(e) => {
                            setFieldValue("product", e.target.value);
                          }}
                        >
                          <Index.MenuItem value={""}>
                            <Index.MenuItem
                              disabled
                              className="all-select-label select-label-font"
                            >
                              Select product
                            </Index.MenuItem>
                          </Index.MenuItem>
                          {allProductData?.map((items, index) => (
                            <Index.MenuItem
                              value={items?.item}
                              key={items?._id}
                            >
                              {items?.item}
                            </Index.MenuItem>
                          ))}
                        </Index.Select>
                        {touched.product && errors.product && (
                          <Index.FormHelperText error>
                            {errors.product}
                          </Index.FormHelperText>
                        )}
                      </Index.Box>
                    </Index.Grid>
                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Agent
                      </Index.Typography>
                      <Index.Box className="input-design-div with-border">
                        <Index.TextField
                          hiddenLabel
                          id="filled-hidden-label-normal"
                          placeholder="Agent"
                          variant="filled"
                          className="admin-input-design input-placeholder"
                          name="agentName"
                          onFocus={handleFocus}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values?.agentName}
                          error={Boolean(
                            errors?.agentName ? touched?.agentName : undefined
                          )}
                          helperText={
                            touched?.agentName ? errors?.agentName : undefined
                          }
                        />
                      </Index.Box>
                    </Index.Grid>

                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Port
                      </Index.Typography>
                      <Index.Box className="input-design-div with-border">
                        {editData?._id && values?.port ? (
                          allPortList?.length && (
                            <Index.Autocomplete
                              disablePortal
                              className="invoice-select-drop"
                              id="combo-box-demo"
                              options={allPortList}
                              getOptionLabel={(option) => option?.slopePortName}
                              value={
                                allPortList[
                                  allPortList.findIndex(
                                    (e) => e._id === values?.port
                                  )
                                ]
                              }
                              renderOption={(props, option) => (
                                <Index.Box
                                  component="li"
                                  sx={{
                                    "& > img": { mr: 2, flexShrink: 0 },
                                  }}
                                  {...props}
                                >
                                  {option?.slopePortName}
                                </Index.Box>
                              )}
                              onChange={(event, newValue) => {
                                setFieldValue("port", newValue?._id);
                              }}
                              renderInput={(params) => (
                                <Index.TextField
                                  {...params}
                                  placeholder="Port name"
                                  variant="outlined"
                                />
                              )}
                            />
                          )
                        ) : (
                          <Index.Autocomplete
                            disablePortal
                            className="invoice-select-drop"
                            id="combo-box-demo"
                            options={allPortList}
                            getOptionLabel={(option) => option?.slopePortName}
                            renderOption={(props, option) => (
                              <Index.Box
                                component="li"
                                sx={{
                                  "& > img": { mr: 2, flexShrink: 0 },
                                }}
                                {...props}
                              >
                                {option?.slopePortName}
                              </Index.Box>
                            )}
                            onChange={(event, newValue) => {
                              setFieldValue("port", newValue?._id);
                            }}
                            renderInput={(params) => (
                              <Index.TextField
                                {...params}
                                placeholder="Port name"
                                variant="outlined"
                              />
                            )}
                          />
                        )}

                        {touched?.port && errors?.port && (
                          <Index.FormHelperText error>
                            {errors?.port}
                          </Index.FormHelperText>
                        )}
                      </Index.Box>
                    </Index.Grid>

                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Order By
                      </Index.Typography>
                      <Index.Box className="input-design-div with-border">
                        <Index.TextField
                          hiddenLabel
                          id="filled-hidden-label-normal"
                          placeholder="Order By"
                          variant="filled"
                          className="admin-input-design input-placeholder"
                          name="orderBy"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          onFocus={handleFocus}
                          value={values?.orderBy}
                          error={Boolean(
                            errors?.orderBy ? touched?.orderBy : undefined
                          )}
                          helperText={
                            touched?.orderBy ? errors?.orderBy : undefined
                          }
                        />
                      </Index.Box>
                    </Index.Grid>

                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Trip Date
                      </Index.Typography>
                      <Index.Box className="input-design-div">
                        <Index.Box className="set-date set-date-picker">
                          <Index.LocalizationProvider
                            dateAdapter={Index.AdapterDayjs}
                          >
                            <Index.DemoContainer
                              components={[
                                "Index.DateTimePicker",
                                "Index.DateTimePicker",
                                "Index.DateTimePicker",
                              ]}
                            >
                              <DateTimePicker
                                views={[
                                  "year",
                                  "month",
                                  "day",
                                  "hours",
                                  "minutes",
                                ]}
                                timeSteps={{
                                  hours: 1,
                                  minutes: 1,
                                }}
                                id="tripDateTime"
                                defaultValue={
                                  values?.tripDateTime
                                    ? dayjs(
                                        Index.moment(
                                          values?.tripDateTime
                                        ).format("YYYY-MM-DDTHH:mm")
                                      )
                                    : ""
                                }
                                onChange={(value) => {
                                  const formatedDate = Index.moment(
                                    value.$d
                                  ).format("YYYY-MM-DDTHH:mm:ssZZ");
                                  setFieldValue("tripDateTime", formatedDate);
                                }}
                                slotProps={{
                                  textField: {
                                    readOnly: true,
                                  },
                                }}
                                format="DD/MM/YYYY HH:mm"
                              />
                            </Index.DemoContainer>
                          </Index.LocalizationProvider>
                        </Index.Box>
                      </Index.Box>
                    </Index.Grid>

                    <Index.Grid item xs={12} sm={6} md={4}>
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Slope Documents(Image/PDF)
                      </Index.Typography>
                      <Index.Box className="input-design-div with-border position-relative">
                        <Index.Box className="pdf-design">
                          Upload PDF :
                        </Index.Box>
                        <Index.TextField
                          fullWidth
                          type="file"
                          name="slopeDocument"
                          multiple
                          id="filled-hidden-label-normal"
                          variant="filled"
                          onFocus={handleFocus}
                          onBlur={()=>{
                            if(!values.slopeDocument?.length){
                              setFieldTouched("slopeDocument",false)
                            }
                          }}
                          className="admin-input-design input-placeholder pdf-upload-design"
                          onChange={(event) => {
                            const files = Array.from(event.target.files);
                            setFieldTouched("slopeDocument",true)
                            setFieldValue("slopeDocument", [...files]);
                            const fileNames = files.map((item) => item.name);
                            // console.log(files, 'files', fileNames);
                            setPdfFilesName(fileNames);
                          }}
                          inputProps={{
                            multiple: true,
                          }}
                          FormHelperTextProps={{
                            className: "input_label_props",
                          }}
                        />
                        {touched?.slopeDocument && errors?.slopeDocument && (
                          <Index.FormHelperText error>
                            {errors?.slopeDocument}
                          </Index.FormHelperText>
                        )}
                        <br />
                        {wharfagePdfName?.length > 0 &&
                          wharfagePdfName?.map((item, i) => {
                            return (
                              <>
                                <Index.Typography
                                  key={i}
                                  variant="label"
                                  component="label"
                                  className="input-label"
                                >
                                  {i + 1}. {item}
                                </Index.Typography>
                              </>
                            );
                          })}
                      </Index.Box>
                    </Index.Grid>
                  </Index.Grid>

                  {/* <Index.Box className="line" mt={3} mb={4}></Index.Box> */}
                </Index.Box>
                <Index.Box className="common-button blue-button flex-end save-btn mt-20">
                  <Index.Button
                    variant="contained"
                    type="submit"
                    disabled={loading}
                  >
                    {editData ? "Update" : "Submit"}
                  </Index.Button>
                </Index.Box>
              </form>
            )}
          </Formik>
        </Index.Box>
      </Index.Box>
    </>
  );
}

export default SlopeAndSludgeOrderAdd;
