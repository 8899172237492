import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import InquiryStatusModal from "../../../../component/common/InquiryStatusModel";
import {
  deleteEnquiry,
  getLostEnquiriesList,
  lostEnquiryPutBack,
} from "../../../../redux/admin/action";
import { Export_excel, NEW_EXPORT_EXCEL } from "../ExportAll";

const Search = Index.styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: Index.alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: Index.alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const StyledInputBase = Index.styled(Index.InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

function LostEnquiry() {
  const dispatch = Index.useDispatch();
  // const [exportDetails, setExportDetails] = useState([]);
  // State for pagination
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [paginationPerPage, setPaginationPerPage] = useState(50);

  // State for searching and set data
  const [updateOpen, setUpdateOpen] = useState(false);
  const [enquiryPutBackId, setEnquiryPutBackId] = useState([]);
  const [checkTabValue, setCheckTabValue] = useState("lost");
  const [rowId, setRowId] = useState("");
  const [deleteOpen, setDeleteOpen] = useState(false);

  const [searchingText, setSearchingText] = useState("");
  const [totalRecordCount, setTotalRecordCount] = useState(0);
  const [lostEnquiriesData, setLostEnquiriesData] = useState([]);

  // enquiry put back function
  const lostEnquiryPutBackOption = (status, comment) => {
    const data = {
      id: enquiryPutBackId,
      inquiryStatus: status,
      comment: comment,
    };
    dispatch(lostEnquiryPutBack(data, handleClose)).then((res) => {
      if (res?.status === 200) {
        setEnquiryPutBackId([]);
      }
    });
  };

  // get lost enquiry list
  const getLostEnquiryList = (values) => {
    setLoading(true);
    dispatch(getLostEnquiriesList(values)).then((res) => {
      if (res?.status === 200) {
        setTotalRecordCount(res?.totalCount);
        setCurrentPage(res?.currentPage);
        setLostEnquiriesData(res?.data);
        setLoading(false);
      } else {
        setLoading(false);
        setTotalRecordCount(0);
        setCurrentPage(1);
        setLostEnquiriesData([]);
      }
    });
  };
  // get lost enquiry list
  useEffect(() => {
    const values = {
      page: 1,
      search: "",
      size: paginationPerPage,
      // to:
      //   serachedDateRange?.length > 0
      //     ? serachedDateRange[0].format("YYYY-MM-DD")
      //     : "",
      // from:
      //   serachedDateRange?.length > 0
      //     ? serachedDateRange[1].format("YYYY-MM-DD")
      //     : "",
    };
    getLostEnquiryList(values);
  }, []);

  const handlePageChange = React.useCallback(
    (event, value) => {
      setCurrentPage(value);
      const values = {
        page: value,
        search: searchingText,
        size: paginationPerPage,
        // to:
        //   serachedDateRange?.length > 0
        //     ? serachedDateRange[0].format("YYYY-MM-DD")
        //     : "",
        // from:
        //   serachedDateRange?.length > 0
        //     ? serachedDateRange[1].format("YYYY-MM-DD")
        //     : "",
      };
      getLostEnquiryList(values);
    },
    [searchingText, paginationPerPage, setCurrentPage]
  );

  const handleOpen = () => {
    setUpdateOpen(true);
  };

  const handleClose = () => {
    setUpdateOpen(false);
    setEnquiryPutBackId([]);
  };

  const handleDeleteOpen = () => {
    setDeleteOpen(true);
  };

  const handleDeleteClose = () => {
    setDeleteOpen(false);
  };

  const handleDeleteRecord = () => {
    dispatch(deleteEnquiry({ id: rowId })).then((res) => {
      if (res?.status === 200) {
        getLostEnquiryList();
      }
    });
    handleDeleteClose();
  };

  const handleSearchLostEqnuiriesData = React.useCallback(
    (e) => {
      const { value } = e.target;
      setSearchingText(value);
      if (value?.length > 0) {
        const values = {
          page: 1,
          search: value,
          size: paginationPerPage,
          // to:
          //   serachedDateRange?.length > 0
          //     ? serachedDateRange[0].format("YYYY-MM-DD")
          //     : "",
          // from:
          //   serachedDateRange?.length > 0
          //     ? serachedDateRange[1].format("YYYY-MM-DD")
          //     : "",
        };
        getLostEnquiryList(values);
      } else {
        const values = {
          page: 1,
          search: "",
          size: paginationPerPage,
          // to:
          //   serachedDateRange?.length > 0
          //     ? serachedDateRange[0].format("YYYY-MM-DD")
          //     : "",
          // from:
          //   serachedDateRange?.length > 0
          //     ? serachedDateRange[1].format("YYYY-MM-DD")
          //     : "",
        };
        getLostEnquiryList(values);
      }
    },
    [paginationPerPage, setSearchingText]
  );

  // when the user change the per page record change then used..
  const handleChangePaginationPerChange = (e) => {
    const { value } = e.target;
    setPaginationPerPage(value);
    const values = {
      page: 1,
      search: searchingText,
      size: value,
      // to:
      //   serachedDateRange?.length > 0
      //     ? serachedDateRange[0].format("YYYY-MM-DD")
      //     : "",
      // from:
      //   serachedDateRange?.length > 0
      //     ? serachedDateRange[1].format("YYYY-MM-DD")
      //     : "",
    };
    getLostEnquiryList(values);
  };

  return (
    <>
      <Index.DynamicTitle title="Enquiry" />
      <Index.Box className="">
        <Index.Box className="barge-common-box">
          <Index.Box className="title-header">
            <Index.Box className="title-header-flex res-title-header-flex">
              <Index.Box className="title-main">
                <Index.Typography
                  variant="p"
                  component="p"
                  className="page-title"
                >
                  Lost Enquiry
                </Index.Typography>
              </Index.Box>
              <Index.Box className="d-flex align-items-center res-set-search">
                <Search className="search ">
                  <StyledInputBase
                    placeholder="Search"
                    inputProps={{ "aria-label": "search" }}
                    onChange={(e) => handleSearchLostEqnuiriesData(e)}
                  />
                </Search>
                {lostEnquiriesData?.length ? (
                  <>
                    <Index.Box className="common-button grey-button">
                      <Index.Button
                        variant="contained"
                        // disabled={exportDetails?.length ? false : true}
                        onClick={() => {
                          let curDate = Index.moment(new Date()).format(
                            "DD-MM-YYYY"
                          );
                          NEW_EXPORT_EXCEL(
                            "/admin/export-lost-enquiries",
                            `Lost_Enquiries_List_${curDate}`,
                            {
                              search: searchingText,
                              // to:
                              //   serachedDateRange?.length > 0
                              //     ? serachedDateRange[0].format("YYYY-MM-DD")
                              //     : "",
                              // from:
                              //   serachedDateRange?.length > 0
                              //     ? serachedDateRange[1].format("YYYY-MM-DD")
                              //     : "",
                            }
                          );
                        }}
                      >
                        Export
                      </Index.Button>
                    </Index.Box>
                  </>
                ) : (
                  ""
                )}

                <Index.Box className="common-button blue-button res-blue-button">
                  {lostEnquiriesData?.length && enquiryPutBackId?.length ? (
                    <Index.Button
                      variant="contained"
                      disableRipple
                      onClick={(e) => {
                        handleOpen();
                      }}
                    >
                      Put Back
                    </Index.Button>
                  ) : (
                    ""
                  )}

                  {/* <Index.RouteLink
                    to="/admin/enquiry/add"
                    className="no-text-decoration"
                  >
                    {" "}
                    <Index.Button variant="contained" disableRipple>
                      Add Enquiry
                    </Index.Button>
                  </Index.RouteLink> */}
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Index.Box className="">
            <Index.TableContainer
              component={Index.Paper}
              className="table-container table-max-width"
            >
              <Index.Table
                stickyHeader
                aria-label="sticky table"
                className="table-design-main supply-table one-line-table"
              >
                <Index.TableHead>
                  <Index.TableRow>
                    <Index.TableCell></Index.TableCell>

                    <Index.TableCell>Enquiry No</Index.TableCell>
                    <Index.TableCell align="left">
                      Name of Vessel
                    </Index.TableCell>
                    <Index.TableCell align="left">Sales Person</Index.TableCell>
                    <Index.TableCell align="left">Trader Name</Index.TableCell>

                    <Index.TableCell align="left">Port</Index.TableCell>
                    <Index.TableCell align="left">Terminal</Index.TableCell>
                    <Index.TableCell align="left">C/F</Index.TableCell>
                    <Index.TableCell align="left">Quantity(MT)</Index.TableCell>
                    <Index.TableCell align="left">Product</Index.TableCell>
                    <Index.TableCell align="left">Lost Date</Index.TableCell>
                    <Index.TableCell align="left">ETA</Index.TableCell>
                    <Index.TableCell align="left">Laycan</Index.TableCell>
                    <Index.TableCell align="left">Created Date</Index.TableCell>
                    <Index.TableCell align="left">Status</Index.TableCell>
                    <Index.TableCell align="left">Action</Index.TableCell>
                  </Index.TableRow>
                </Index.TableHead>
                {loading ? (
                  <Index.TableBody>
                    <Index.TableRow>
                      <Index.TableCell colSpan={15} align="center">
                        <Index.CircularProgress color="secondary" size={20} />
                      </Index.TableCell>
                    </Index.TableRow>
                  </Index.TableBody>
                ) : (
                  <Index.TableBody>
                    {lostEnquiriesData?.length ? (
                      lostEnquiriesData?.map((item, index) => (
                        <Index.TableRow key={item?._id}>
                          <Index.TableCell>
                            <Index.FormControlLabel
                              control={
                                <Index.Checkbox
                                  onChange={(event) => {
                                    if (event.target.checked === true) {
                                      setEnquiryPutBackId([
                                        ...enquiryPutBackId,
                                        item?._id,
                                      ]);
                                    } else {
                                      setEnquiryPutBackId(
                                        enquiryPutBackId?.filter((ele) => {
                                          return ele !== item?._id;
                                        })
                                      );
                                    }
                                  }}
                                />
                              }
                              // label="Shipping Bill"
                            />
                          </Index.TableCell>
                          <Index.TableCell>
                            {item?.inquiryNo ? item?.inquiryNo : "-"}
                          </Index.TableCell>
                          <Index.TableCell align="left">
                            {item?.vesselName ? item?.vesselName : "-"}
                          </Index.TableCell>
                          <Index.TableCell align="left">
                            {item?.salesName ? item?.salesName : "-"}
                          </Index.TableCell>
                          <Index.TableCell align="left">
                            {item?.traderName ? item?.traderName : "-"}
                          </Index.TableCell>
                          <Index.TableCell align="left">
                            {item?.portName ? item?.portName : "-"}
                          </Index.TableCell>
                          <Index.TableCell align="left">
                            {item?.terminal ? item?.terminal : "-"}
                          </Index.TableCell>
                          <Index.TableCell align="left">
                            {item?.CF ? item?.CF : "-"}
                          </Index.TableCell>
                          <Index.TableCell align="left">
                            {item?.orderDetails?.length > 0
                              ? item?.orderDetails
                                  ?.map((items, index) => {
                                    if (
                                      items.lowerQuantity &&
                                      items.higherQuantity != 0
                                    ) {
                                      return `${items.lowerQuantity} - ${items.higherQuantity}`;
                                    } else if (items.lowerQuantity) {
                                      return items.lowerQuantity.toString();
                                    } else {
                                      return "-";
                                    }
                                  })
                                  .join(" ")
                              : "-"}
                          </Index.TableCell>
                          <Index.TableCell align="left">
                            {item?.orderDetails?.length
                              ? item?.orderDetails &&
                                item?.orderDetails?.map((items, index) => {
                                  if (items?.product) {
                                    return items?.product + " ";
                                  } else {
                                    return "-";
                                  }
                                })
                              : "-"}
                          </Index.TableCell>
                          <Index.TableCell align="left">
                            {item?.lostEnquiryDate
                              ? Index.moment(item?.lostEnquiryDate).format(
                                  "DD/MM/YYYY hh:mm:ss A"
                                )
                              : "-"}
                          </Index.TableCell>
                          <Index.TableCell align="left">
                            {item?.eta
                              ? Index.moment(item?.eta).format("DD/MM/YYYY")
                              : "-"}
                          </Index.TableCell>

                          <Index.TableCell align="left">
                            {item?.laycanStartDate || item?.laycanEndDate ? (
                              <>
                                {Index.moment(item?.laycanStartDate).format(
                                  "DD/MM/YYYY"
                                )}{" "}
                                -{" "}
                                {Index.moment(item?.laycanEndDate).format(
                                  "DD/MM/YYYY"
                                )}
                              </>
                            ) : (
                              "-"
                            )}
                          </Index.TableCell>
                          <Index.TableCell align="left">
                            {item?.createdAt
                              ? Index.moment(item?.createdAt).format(
                                  "hh:mm:ss DD/MM/YYYY"
                                )
                              : "-"}
                          </Index.TableCell>

                          <Index.TableCell align="left">
                            {item?.inquiryStatus === "Confirm" ? (
                              <span className="status-success">Confirm</span>
                            ) : item?.inquiryStatus === "Lost" ||
                              item?.inquiryStatus === "Cancel" ? (
                              <span className="status-error">Lost</span>
                            ) : (
                              <span className="">-</span>
                            )}
                          </Index.TableCell>
                          <Index.TableCell>
                            <Index.IconButton>
                              <Index.DeleteIcon
                                color="error"
                                onClick={() => {
                                  setRowId(item?._id);
                                  handleDeleteOpen();
                                }}
                              />
                            </Index.IconButton>
                          </Index.TableCell>
                        </Index.TableRow>
                      ))
                    ) : (
                      <Index.TableRow>
                        <Index.TableCell
                          component="td"
                          variant="td"
                          scope="row"
                          className="no-data-in-list"
                          colSpan={15}
                          align="center"
                        >
                          No data available
                        </Index.TableCell>
                      </Index.TableRow>
                    )}
                  </Index.TableBody>
                )}
              </Index.Table>
            </Index.TableContainer>
          </Index.Box>
          <Index.Box className="pagination-design flex-end">
            <Index.Stack spacing={2}>
              <Index.Box className="pagination-count">
                <Index.Box className="input-design-div with-border">
                  <Index.Select
                    fullWidth
                    value={paginationPerPage}
                    onChange={(e) => handleChangePaginationPerChange(e)}
                  >
                    <Index.MenuItem value={10}>10</Index.MenuItem>
                    <Index.MenuItem value={25}>25</Index.MenuItem>
                    <Index.MenuItem value={50}>50</Index.MenuItem>
                    <Index.MenuItem value={100}>100</Index.MenuItem>
                  </Index.Select>
                </Index.Box>
                {loading ? (
                  <Index.CircularProgress
                    color="secondary"
                    sx={{ marginLeft: "10px" }}
                    size={26}
                  />
                ) : (
                  <Index.Pagination
                    count={Math.ceil(totalRecordCount / paginationPerPage)}
                    page={currentPage}
                    onChange={handlePageChange}
                  />
                )}
              </Index.Box>
            </Index.Stack>
          </Index.Box>
        </Index.Box>
      </Index.Box>

      <InquiryStatusModal
        updateOpen={updateOpen}
        handleClose={handleClose}
        inquiryStatusUpdate={lostEnquiryPutBackOption}
        currentEnquiry={checkTabValue}
      />
      <Index.DeleteModal
        deleteOpen={deleteOpen}
        handleDeleteRecord={handleDeleteRecord}
        handleDeleteClose={handleDeleteClose}
      />
    </>
  );
}

export default LostEnquiry;
