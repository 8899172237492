import React, { useCallback, useEffect, useState } from "react";
import Index from "../../../Index";
import { Formik } from "formik";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import {
  addStatusOnDocumentSide,
  getDocumentCentersDocumentList,
} from "../../../../redux/admin/action";
import { NEW_EXPORT_EXCEL } from "../ExportAll";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { SingleInputDateRangeField } from "@mui/x-date-pickers-pro/SingleInputDateRangeField";
import dayjs from "dayjs";
import moment from "moment";
import { documentRemarkValidationSchema } from "../../../../validation/AdminValidation";

const Search = Index.styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: Index.alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: Index.alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    // marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const StyledInputBase = Index.styled(Index.InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

const HtmlTooltip = Index.styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}));

const shortcutsItems = [
  {
    label: "This Week",
    getValue: () => {
      const today = dayjs();
      return [today.startOf("week"), today.endOf("week")];
    },
  },
  {
    label: "Last Week",
    getValue: () => {
      const today = dayjs();
      const prevWeek = today.subtract(7, "day");
      return [prevWeek.startOf("week"), prevWeek.endOf("week")];
    },
  },
  {
    label: "Last 7 Days",
    getValue: () => {
      const today = dayjs();
      return [today.subtract(7, "day"), today];
    },
  },
  {
    label: "Current Month",
    getValue: () => {
      const today = dayjs();
      return [today.startOf("month"), today.endOf("month")];
    },
  },
  {
    label: "Next Month",
    getValue: () => {
      const today = dayjs();
      const startOfNextMonth = today.endOf("month").add(1, "day");
      return [startOfNextMonth, startOfNextMonth.endOf("month")];
    },
  },
  { label: "Reset", getValue: () => [null, null] },
];

function DocumentCenter() {
  const dispatch = Index.useDispatch();
  const navigate = Index.useNavigate();
  const [loading, setLoading] = useState(false);
  const [documentList, setDocumentList] = useState([]);
  const [statusData, setStatusData] = useState([]);
  const [openAssigneeModel, setOpenAssigneeModel] = useState(false);

  const [documentId, setDocumentId] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [paginationPerPage, setPaginationPerPage] = useState(50);
  const [searchingText, setSearchingText] = useState("");
  const [totalRecordsCount, setTotalRecordCount] = useState(0);
  const [dueDateRange, setDueDateRange] = useState("");
  const [updatedDateRange, setUpdatedDateRange] = useState("");

  let initialValues = {
    status: "",
  };
  if (statusData) {
    initialValues = {
      status: statusData,
    };
  }

  // when the first time page load then used...
  const handleGetDocumentList = async (values) => {
    setLoading(true);
    dispatch(getDocumentCentersDocumentList(values))
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          setDocumentList(res.data);
          setTotalRecordCount(res.totalCount);
          setCurrentPage(parseInt(res?.currentPage));
        } else {
          setLoading(false);
          setDocumentList([]);
          setTotalRecordCount(0);
          setCurrentPage(1);
        }
      })
      .catch((err) => {
        setLoading(false);
        setDocumentList([]);
        setCurrentPage(1);
        setTotalRecordCount(0);
        console.error(err);
      });
  };

  // when the page first time load then used....
  useEffect(() => {
    const values = {
      page: 1,
      search: searchingText,
      size: paginationPerPage,
      dueStartDate:
        dueDateRange?.length > 0 ? dueDateRange[0].format("YYYY-MM-DD") : "",
      dueEndDate:
        dueDateRange?.length > 0 ? dueDateRange[1].format("YYYY-MM-DD") : "",
      updateStartDate:
        updatedDateRange?.length > 0
          ? updatedDateRange[0].format("YYYY-MM-DD")
          : "",
      updateEndDate:
        updatedDateRange?.length > 0
          ? updatedDateRange[1].format("YYYY-MM-DD")
          : "",
    };
    handleGetDocumentList(values);
  }, []);

  //Assign to model
  const handleAssigneeModelOpen = () => {
    setOpenAssigneeModel(true);
  };

  const handleAssigneeModelClose = () => {
    setOpenAssigneeModel(false);
    setDocumentId("");
  };

  const handlePageChange = useCallback(
    (event, value) => {
      setCurrentPage(value);
      const values = {
        page: value,
        search: searchingText,
        size: paginationPerPage,
        dueStartDate:
          dueDateRange?.length > 0 ? dueDateRange[0].format("YYYY-MM-DD") : "",
        dueEndDate:
          dueDateRange?.length > 0 ? dueDateRange[1].format("YYYY-MM-DD") : "",
        updateStartDate:
          updatedDateRange?.length > 0
            ? updatedDateRange[0].format("YYYY-MM-DD")
            : "",
        updateEndDate:
          updatedDateRange?.length > 0
            ? updatedDateRange[1].format("YYYY-MM-DD")
            : "",
      };
      handleGetDocumentList(values);
    },
    [
      paginationPerPage,
      dueDateRange,
      updatedDateRange,
      searchingText,
      setCurrentPage,
    ]
  );

  const handleStatusOnDocument = (values) => {
    const urlencoded = new URLSearchParams();
    urlencoded.append("id", documentId);
    urlencoded.append("status", values?.status);
    try {
      dispatch(addStatusOnDocumentSide(urlencoded)).then((res) => {
        if (res?.status === 200) {
          // here get the prevention listing...
          const values = {
            page: 1,
            search: searchingText,
            size: paginationPerPage,
            dueStartDate:
              dueDateRange?.length > 0
                ? dueDateRange[0].format("YYYY-MM-DD")
                : "",
            dueEndDate:
              dueDateRange?.length > 0
                ? dueDateRange[1].format("YYYY-MM-DD")
                : "",
            updateStartDate:
              updatedDateRange?.length > 0
                ? updatedDateRange[0].format("YYYY-MM-DD")
                : "",
            updateEndDate:
              updatedDateRange?.length > 0
                ? updatedDateRange[1].format("YYYY-MM-DD")
                : "",
          };
          handleGetDocumentList(values);
        }
      });
    } catch (error) {}
    handleAssigneeModelClose();
  };

  // when the user change the per page record change then used..
  const handleChangePaginationPerChange = useCallback(
    (e) => {
      const { value } = e.target;
      setPaginationPerPage(value);
      const values = {
        page: 1,
        search: searchingText,
        size: value,
        dueStartDate:
          dueDateRange?.length > 0 ? dueDateRange[0].format("YYYY-MM-DD") : "",
        dueEndDate:
          dueDateRange?.length > 0 ? dueDateRange[1].format("YYYY-MM-DD") : "",
        updateStartDate:
          updatedDateRange?.length > 0
            ? updatedDateRange[0].format("YYYY-MM-DD")
            : "",
        updateEndDate:
          updatedDateRange?.length > 0
            ? updatedDateRange[1].format("YYYY-MM-DD")
            : "",
      };
      handleGetDocumentList(values);
    },
    [searchingText, dueDateRange, updatedDateRange, setPaginationPerPage]
  );

  // when the user search the purchase records then used...
  const handleSearchDocuments = useCallback(
    (e) => {
      const { value } = e.target;
      if (value?.length > 0) {
        setSearchingText(value);
        const values = {
          page: 1,
          search: value.trim(),
          size: paginationPerPage,
          dueStartDate:
            dueDateRange?.length > 0
              ? dueDateRange[0].format("YYYY-MM-DD")
              : "",
          dueEndDate:
            dueDateRange?.length > 0
              ? dueDateRange[1].format("YYYY-MM-DD")
              : "",
          updateStartDate:
            updatedDateRange?.length > 0
              ? updatedDateRange[0].format("YYYY-MM-DD")
              : "",
          updateEndDate:
            updatedDateRange?.length > 0
              ? updatedDateRange[1].format("YYYY-MM-DD")
              : "",
        };
        handleGetDocumentList(values);
      } else {
        setSearchingText("");
        const values = {
          page: 1,
          search: "",
          size: paginationPerPage,
          dueStartDate:
            dueDateRange?.length > 0
              ? dueDateRange[0].format("YYYY-MM-DD")
              : "",
          dueEndDate:
            dueDateRange?.length > 0
              ? dueDateRange[1].format("YYYY-MM-DD")
              : "",
          updateStartDate:
            updatedDateRange?.length > 0
              ? updatedDateRange[0].format("YYYY-MM-DD")
              : "",
          updateEndDate:
            updatedDateRange?.length > 0
              ? updatedDateRange[1].format("YYYY-MM-DD")
              : "",
        };
        handleGetDocumentList(values);
      }
    },
    [paginationPerPage, dueDateRange, updatedDateRange, setSearchingText]
  );

  // when the use change the date then used...
  const handleChangeDueDate = React.useCallback(
    (data) => {
      setDueDateRange(data);
      if (data[0] !== null && data[1] !== null) {
        const values = {
          page: 1,
          search: searchingText,
          size: paginationPerPage,
          dueStartDate: data[0] !== null ? data[0].format("YYYY-MM-DD") : "",
          dueEndDate: data[1] !== null ? data[1].format("YYYY-MM-DD") : "",
          updateStartDate:
            updatedDateRange?.length > 0
              ? updatedDateRange[0].format("YYYY-MM-DD")
              : "",
          updateEndDate:
            updatedDateRange?.length > 0
              ? updatedDateRange[1].format("YYYY-MM-DD")
              : "",
        };
        handleGetDocumentList(values);
      } else {
        const values = {
          page: 1,
          search: searchingText,
          size: paginationPerPage,
          dueStartDate:
            dueDateRange?.length > 0
              ? dueDateRange[0].format("YYYY-MM-DD")
              : "",
          dueEndDate:
            dueDateRange?.length > 0
              ? dueDateRange[1].format("YYYY-MM-DD")
              : "",
          updateStartDate:
            updatedDateRange?.length > 0
              ? updatedDateRange[0].format("YYYY-MM-DD")
              : "",
          updateEndDate:
            updatedDateRange?.length > 0
              ? updatedDateRange[1].format("YYYY-MM-DD")
              : "",
        };
        handleGetDocumentList(values);
      }
    },
    [setDueDateRange, searchingText, paginationPerPage]
  );

  // when the user chante the updated on date then used...
  const handleChangeUpdatedDate = React.useCallback(
    (data) => {
      setUpdatedDateRange(data);
      if (data[0] !== null && data[1] !== null) {
        const values = {
          page: 1,
          search: searchingText,
          size: paginationPerPage,
          dueStartDate:
            dueDateRange?.length > 0
              ? dueDateRange[0].format("YYYY-MM-DD")
              : "",
          dueEndDate:
            dueDateRange?.length > 0
              ? dueDateRange[1].format("YYYY-MM-DD")
              : "",
          updateStartDate: data[0] !== null ? data[0].format("YYYY-MM-DD") : "",
          updateEndDate: data[1] !== null ? data[1].format("YYYY-MM-DD") : "",
        };
        handleGetDocumentList(values);
      } else {
        const values = {
          page: 1,
          search: searchingText,
          size: paginationPerPage,
          dueStartDate:
            dueDateRange?.length > 0
              ? dueDateRange[0].format("YYYY-MM-DD")
              : "",
          dueEndDate:
            dueDateRange?.length > 0
              ? dueDateRange[1].format("YYYY-MM-DD")
              : "",
          updateStartDate:
            updatedDateRange?.length > 0
              ? updatedDateRange[0].format("YYYY-MM-DD")
              : "",
          updateEndDate:
            updatedDateRange?.length > 0
              ? updatedDateRange[1].format("YYYY-MM-DD")
              : "",
        };
        handleGetDocumentList(values);
      }
    },
    [setUpdatedDateRange, searchingText, paginationPerPage]
  );

  // when the user show the remark history then used...
  const handleRemarkHistory = (documentData) => {
    navigate(`/admin/documentCenter/remark-history/${documentData?._id}`);
  };

  return (
    <>
      <Index.DynamicTitle title="Document Center" />
      <Index.Box className="page-content-box">
        <Index.Box className="barge-common-box">
          <Index.Box className="title-header doc-center-header">
            <Index.Box className="title-header-flex res-title-header-flex">
              <Index.Box className="title-main document-center-title">
                <Index.Typography
                  variant="p"
                  component="p"
                  className="page-title"
                >
                  Document Center
                </Index.Typography>
              </Index.Box>
              <Index.Box className="d-flex align-items-center res-set-search document-center-filter-option">
                <Index.Box className="document-center-search doc-center-right">
                  <Search className="search">
                    <StyledInputBase
                      placeholder="Search"
                      inputProps={{ "aria-label": "search" }}
                      onChange={(e) => handleSearchDocuments(e)}
                    />
                  </Search>
                  <Index.Box className="input-design-div">
                    <Index.Typography
                      variant="label"
                      component="label"
                      className="input-label"
                    >
                      Due Date
                    </Index.Typography>
                    <Index.Box className="set-date set-date-picker remove-name">
                      <Index.LocalizationProvider
                        dateAdapter={Index.AdapterDayjs}
                      >
                        <Index.DemoContainer
                          components={["SingleInputDateRangeField"]}
                          label="1 calendar"
                        >
                          <DateRangePicker
                          className="cus-datepicker"
                            slots={{ field: SingleInputDateRangeField }}
                            sx={false}
                            slotProps={{
                              shortcuts: {
                                items: shortcutsItems,
                              },
                              textField: {
                                readOnly: true,
                              },
                              actionBar: { actions: [] },
                            }}
                            onChange={(value) => handleChangeDueDate(value)}
                            name="allowedRange"
                            format="DD/MM/YYYY"
                          />
                        </Index.DemoContainer>
                      </Index.LocalizationProvider>
                    </Index.Box>
                  </Index.Box>
                  {/* Date range start */}
                  <Index.Box className="input-design-div">
                    <Index.Typography
                      variant="label"
                      component="label"
                      className="input-label"
                    >
                      Updated On
                    </Index.Typography>
                    <Index.Box className="set-date set-date-picker remove-name">
                      <Index.LocalizationProvider
                        dateAdapter={Index.AdapterDayjs}
                      >
                        <Index.DemoContainer
                          components={["SingleInputDateRangeField"]}
                          label="1 calendar"
                        >
                          <DateRangePicker
                           className="cus-datepicker"
                            slots={{ field: SingleInputDateRangeField }}
                            disableFuture
                            sx={false}
                            slotProps={{
                              shortcuts: {
                                items: shortcutsItems,
                              },
                              textField: {
                                readOnly: true,
                              },
                              actionBar: { actions: [] },
                            }}
                            onChange={(value) => handleChangeUpdatedDate(value)}
                            name="allowedRange"
                            format="DD/MM/YYYY"
                          />
                        </Index.DemoContainer>
                      </Index.LocalizationProvider>
                    </Index.Box>
                  </Index.Box>
                  {/* Date range end */}
                  <Index.Box className="common-button grey-button">
                    <Index.Button
                      variant="contained"
                      onClick={() => {
                        let curDate = Index.moment(new Date()).format(
                          "YYYY-MM-DD"
                        );
                        NEW_EXPORT_EXCEL(
                          "/admin/export-document",
                          `Document_Center_${curDate}`,
                          {
                            search: searchingText,
                            dueStartDate:
                              dueDateRange?.length > 0
                                ? dueDateRange[0].format("YYYY-MM-DD")
                                : "",
                            dueEndDate:
                              dueDateRange?.length > 0
                                ? dueDateRange[1].format("YYYY-MM-DD")
                                : "",
                            updateStartDate:
                              updatedDateRange?.length > 0
                                ? updatedDateRange[0].format("YYYY-MM-DD")
                                : "",
                            updateEndDate:
                              updatedDateRange?.length > 0
                                ? updatedDateRange[1].format("YYYY-MM-DD")
                                : "",
                          }
                        );
                      }}
                    >
                      Export
                    </Index.Button>
                  </Index.Box>
                </Index.Box>
                {/* <Index.Box className="document-center-date-filter">
                  
                </Index.Box> */}
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Index.Box className="">
            <Index.TableContainer
              component={Index.Paper}
              className="table-container"
            >
              <Index.Table
                aria-label="simple table"
                className="table-design-main barge-table"
              >
                <Index.TableHead>
                  <Index.TableRow>
                    <Index.TableCell align="left">Days</Index.TableCell>
                    <Index.TableCell align="left">Barge / Tugs</Index.TableCell>
                    <Index.TableCell align="left">To be done</Index.TableCell>
                    <Index.TableCell align="left">Due Date</Index.TableCell>
                    <Index.TableCell align="left">Updated On</Index.TableCell>
                    <Index.TableCell align="left">Remark</Index.TableCell>
                    <Index.TableCell align="left">Name</Index.TableCell>
                  </Index.TableRow>
                </Index.TableHead>
                {loading ? (
                  <Index.TableBody>
                    <Index.TableRow>
                      <Index.TableCell
                        colSpan={12}
                        align="center"
                        className="loading-purchase"
                      >
                        <Index.CircularProgress color="secondary" size={20} />
                      </Index.TableCell>
                    </Index.TableRow>
                  </Index.TableBody>
                ) : (
                  <Index.TableBody className="tanker-table-body">
                    {console.log("documentList", documentList)}
                    {documentList?.length ? (
                      documentList?.map((row, index) => (
                        <Index.TableRow key={row?._id + (index + 1)}>
                          <Index.TableCell>
                            {!row?.expireCheckBox
                              ? row?.differenceInDays
                                ? row?.differenceInDays
                                : "-"
                              : "-"}
                          </Index.TableCell>
                          <Index.TableCell>
                            {/* craftId */}

                            {row?.craftId?.bargeName
                              ? row?.craftId?.bargeName
                              : "-"}
                          </Index.TableCell>
                          <Index.TableCell align="left">
                            {row?.title ? row?.title : "-"}
                          </Index.TableCell>
                          <Index.TableCell>
                            {row?.expireDate
                              ? moment(row?.expireDate).format("DD-MM-YYYY")
                              : "-"}
                          </Index.TableCell>
                          <Index.TableCell>
                            {row?.updatedOn
                              ? moment(row?.updatedOn).format("DD-MM-YYYY")
                              : "-"}
                          </Index.TableCell>

                          <Index.TableCell align="left">
                            {row?.status?.length ? (
                              <HtmlTooltip title={row?.status}>
                                <span
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  {row?.status?.length && (
                                    <>
                                      {row?.status?.length > 6 ? (
                                        <>
                                          <span>
                                            {`${row?.status.substring(
                                              0,
                                              5
                                            )}...`}
                                          </span>
                                        </>
                                      ) : (
                                        <>
                                          <span>{`${row?.status}`}</span>
                                        </>
                                      )}

                                      <Index.IconButton
                                        onClick={() => {
                                          setStatusData(row?.status);
                                          handleAssigneeModelOpen();
                                          setDocumentId(row?._id);
                                        }}
                                        style={{ marginLeft: "5px" }}
                                      >
                                        <Index.EditIcon
                                          color="primary"
                                          fontSize="small"
                                        />
                                      </Index.IconButton>

                                      <Index.IconButton
                                        onClick={() => {
                                          handleRemarkHistory(row);
                                        }}
                                        style={{ marginLeft: "5px" }}
                                      >
                                        <Index.HistoryIcon
                                          color="primary"
                                          fontSize="small"
                                        />
                                      </Index.IconButton>
                                    </>
                                  )}
                                </span>
                              </HtmlTooltip>
                            ) : (
                              <Index.Box className="common-button blue-button res-blue-button assign-btn-align">
                                <Index.Button
                                  variant="contained"
                                  className="assign-button-margin"
                                  disableRipple
                                  onClick={() => {
                                    setStatusData("");
                                    handleAssigneeModelOpen();
                                    setDocumentId(row?._id);
                                  }}
                                >
                                  Remark
                                </Index.Button>
                              </Index.Box>
                            )}
                          </Index.TableCell>
                          <Index.TableCell>
                            {row?.addedBy
                              ? row.addedBy?.firstName +
                                " " +
                                row.addedBy?.lastName
                              : "-"}
                          </Index.TableCell>
                        </Index.TableRow>
                      ))
                    ) : (
                      <Index.TableRow>
                        <Index.TableCell
                          component="td"
                          variant="td"
                          scope="row"
                          className="no-data-in-list"
                          colSpan={12}
                          align="center"
                        >
                          No data available
                        </Index.TableCell>
                      </Index.TableRow>
                    )}
                  </Index.TableBody>
                )}
              </Index.Table>
            </Index.TableContainer>
          </Index.Box>
          <Index.Box className="pagination-design flex-end">
            <Index.Stack spacing={2}>
              <Index.Box className="pagination-count">
                <Index.Box className="input-design-div with-border">
                  <Index.Select
                    fullWidth
                    value={paginationPerPage}
                    onChange={(e) => handleChangePaginationPerChange(e)}
                  >
                    <Index.MenuItem value={10}>10</Index.MenuItem>
                    <Index.MenuItem value={25}>25</Index.MenuItem>
                    <Index.MenuItem value={50}>50</Index.MenuItem>
                    <Index.MenuItem value={100}>100</Index.MenuItem>
                  </Index.Select>
                </Index.Box>
                {loading ? (
                  <Index.CircularProgress color="secondary" size={26} />
                ) : (
                  <>
                    <Index.Pagination
                      count={Math.ceil(totalRecordsCount / paginationPerPage)}
                      page={currentPage}
                      onChange={handlePageChange}
                    />
                  </>
                )}
              </Index.Box>
            </Index.Stack>
          </Index.Box>
        </Index.Box>
      </Index.Box>

      {/* assign Dialoag basis */}
      <Index.Dialog
        open={openAssigneeModel}
        onClose={handleAssigneeModelClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Index.DialogContent className="expense-model-main  expense-model-main-box-set">
          <Index.Box className="title-main">
            <Formik
              initialValues={initialValues}
              validationSchema={documentRemarkValidationSchema}
              onSubmit={handleStatusOnDocument}
            >
              {({
                values,
                errors,
                touched,
                setFieldValue,
                handleChange,
                handleSubmit,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Index.Box className="title-main">
                    <Index.Box className="jus-between">
                    <Index.Typography
                      variant="h6"
                      component="h6"
                      className="input-label"
                      sx={{ marginBottom: "15px" }}
                    >
                      Remark
                    </Index.Typography>
                    <Index.ClearIcon
                      className="model-close-icon"
                      onClick={handleAssigneeModelClose}
                    />
                    </Index.Box>
                    <Index.Grid container spacing={2}>
                      <Index.Grid item xs={12} sm={6} md={12}>
                        <Index.Typography
                          variant="label"
                          component="label"
                          className="input-label"
                        >
                          Remark
                        </Index.Typography>
                        <Index.Box className="input-design-div with-border">
                          <Index.TextareaAutosize
                            hiddenLabel
                            minRows={3}
                            id="filled-hidden-label-normal"
                            placeholder="Remark"
                            variant="filled"
                            className="admin-input-design input-placeholder input-placeholder-box  input-placeholder-box-set"
                            name="status"
                            onChange={handleChange}
                            value={values?.status}
                          />
                          {touched?.status && errors?.status && (
                            <Index.FormHelperText error>
                              {errors?.status}
                            </Index.FormHelperText>
                          )}
                        </Index.Box>
                      </Index.Grid>
                    </Index.Grid>
                    <Index.Grid
                      item
                      xs={12}
                      sm={6}
                      md={12}
                    >
                      <Index.Box
                        className="common-button blue-button flex-end save-btn mt-20"
                        sx={{
                          display: "flex",
                          alignItems: "flex-end",
                        }}
                      >
                        <Index.Button
                          variant="contained"
                          type="submit"
                          className="Particular_model_submit"
                        >
                          {statusData?.length ? "Update" : "Submit"}
                        </Index.Button>
                      </Index.Box>
                    </Index.Grid>

                    {/* <Index.Box className="line" mt={3} mb={3}></Index.Box> */}
                  </Index.Box>
                </form>
              )}
            </Formik>
          </Index.Box>
        </Index.DialogContent>
      </Index.Dialog>
    </>
  );
}

export default DocumentCenter;
