import axios from "../axios";

// All api calling is here
class APIService {
  // Post method
  async doPost(url, data) {
    return await axios.post(url, data, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
  }

  async doPostFormData(url, data) {
    // return await axios.post(url, data);
    return await axios.post(url, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  async doGet(url) {
    return await axios.get(url);
  }

  async doGetParams(url, data) {
    // return await axios.post(url, data);
    return await axios.get(`${url}${data}`);
  }

  async doGetQueryParams(url, data) {
    // return await axios.post(url, data);
    return await axios.get(url, data);
  }
}




export default new APIService();
