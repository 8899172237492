import { Formik } from "formik";
import { terminalData } from "../../../../../config/CommonTerminalItems";
import { useEffect, useState } from "react";
import Index from "../../../../Index";
import { commonCFData } from "../../../../../config/CommonCF";

function WaterSupplyUpdateCFModel({
  openUpdateDatePopUp,
  handleCloseDateEditModal,
  handleUpdateDateViaApi,
  editTimeDateData,
  editTimeCFData,
  isSingleFieldUpdate,
}) {
  const initialValues = {
    coastal: editTimeCFData,
  };

  return (
    <>
      <Index.Dialog
        open={openUpdateDatePopUp}
        onClose={handleCloseDateEditModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Index.DialogContent className="expense-model-main  modal-update-main">
          <Index.Box className="title-main">
            <Formik
              enableReinitialize
              initialValues={initialValues}
              // validationSchema={vesselValidationSchema}
              onSubmit={(values) =>
                handleUpdateDateViaApi(values, editTimeDateData)
              }
            >
              {({
                values,
                errors,
                touched,
                setFieldValue,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldTouched,
                handleFocus,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Index.Box className="title-main">
                    <Index.Box className="jus-between">
                      <Index.Typography
                        variant="h6"
                        component="h6"
                        className="input-label"
                      >
                        Edit C/F
                      </Index.Typography>
                      <Index.ClearIcon
                        className="model-close-icon"
                        onClick={handleCloseDateEditModal}
                      />
                    </Index.Box>
                    <Index.Box className="update-model-main-box-set">
                      <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        C/F
                      </Index.Typography>
                      <Index.Box className="input-design-div with-border">
                        <Index.Select
                          className="placeholder-set-text-box"
                          fullWidth
                          name="coastal"
                          value={values?.coastal}
                          displayEmpty
                          inputProps={{ "aria-label": "Without label" }}
                          defaultValue={values ? values?.coastal : ""}
                          onChange={(e) => {
                            setFieldValue("coastal", e.target.value);
                          }}
                        >
                          <Index.MenuItem value={""}>
                            <Index.MenuItem
                              disabled
                              className="all-select-label select-label-font"
                            >
                              Select C/F
                            </Index.MenuItem>
                          </Index.MenuItem>
                          {commonCFData?.map((items, index) => (
                            <Index.MenuItem value={items?.name} key={items?.id}>
                              {items?.name}
                            </Index.MenuItem>
                          ))}
                        </Index.Select>
                      </Index.Box>
                      <Index.Box className="common-button blue-button flex-end save-btn modal-update-btn">
                        <Index.Button
                          variant="contained"
                          type="submit"
                          className="Particular_model_submit"
                          disabled={isSingleFieldUpdate ? true : false}
                        >
                          Update
                        </Index.Button>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </form>
              )}
            </Formik>
          </Index.Box>
        </Index.DialogContent>
      </Index.Dialog>
    </>
  );
}
export default WaterSupplyUpdateCFModel;
