import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import { Formik } from "formik";
import {
  addTankerDashboardDetails,
  getSingleDashboardTankerDetails,
  getTankerDashboardBarge,
  resetTankerDate,
} from "../../../../redux/admin/action";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import dayjs from "dayjs";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { useLocation } from "react-router-dom";
import { useDateTimePicker } from "../../../../context/DatePickerContext";

function TankerDashboardView() {
  const dispatch = Index.useDispatch();
  const navigate = Index.useNavigate();
  const { openPicker, closePicker } = useDateTimePicker();
  const { adminDetails } = Index.useSelector((state) => state.AdminReducer);
  const [image, setImage] = useState("");
  const { id } = Index.useParams();
  const { state } = useLocation();
  const type = state?.type;
  const [loading, setLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [bargeListCatData, setBargeListCatData] = useState([]);
  const [viewData, setViewData] = useState([]);

  console.log("viewData", viewData);

  // initialValues
  let initialValues = {
    loadedTTIn: viewData?.loadedTTIn ? viewData?.loadedTTIn : "",
    loadingStart: viewData?.loadingStart ? viewData?.loadingStart : "",
    loadingCompleted: viewData?.loadingCompleted
      ? viewData?.loadingCompleted
      : "",
    loadedQty: viewData?.loadedQty ? viewData?.loadedQty : "",
    loadedTTOut: viewData?.loadedTTOut ? viewData?.loadedTTOut : "",
    kandalaIn: viewData?.kandalaIn ? viewData?.kandalaIn : "",
    unloadingStart: viewData?.unloadingStart ? viewData?.unloadingStart : "",
    bargeSelection: viewData?.bargeSelection?._id
      ? viewData?.bargeSelection?._id
      : "",
    unloadingCompleted: viewData?.unloadingCompleted
      ? viewData?.unloadingCompleted
      : "",
    loadingRemark: viewData?.loadingRemark ? viewData?.loadingRemark : "",
    kandalaRemark: viewData?.kandalaRemark ? viewData?.kandalaRemark : "",
    surveyorRemark: viewData?.surveyorRemark ? viewData?.surveyorRemark : "",

    dignityLoadingCompleted: viewData?.dignityLoadingCompleted
      ? viewData?.dignityLoadingCompleted
      : "",
    soundingCalculation: viewData?.soundingCalculation
      ? viewData?.soundingCalculation
      : "",
    document: [],
    kandalaOut: viewData?.kandalaOut ? viewData?.kandalaOut : "",
    rcvdBdn: viewData?.rcvdBdn ? viewData?.rcvdBdn : "",
  };

  // handleFormSubmit
  const handleFormSubmit = async (values) => {
    setBtnLoading(true);
    let data = new FormData();
    if (values?.loadedTTIn) {
      data.append(
        "loadedTTIn",
        values?.loadedTTIn.toLowerCase() !== "invalid date" &&
          !values?.loadedTTIn
          ? ""
          : Index.moment(values?.loadedTTIn).format("YYYY-MM-DDTHH:mm:ssZZ")
      );
    }
    if (values?.loadingStart) {
      data.append(
        "loadingStart",
        values?.loadingStart.toLowerCase() !== "invalid date" &&
          !values?.loadingStart
          ? ""
          : Index.moment(values?.loadingStart).format("YYYY-MM-DDTHH:mm:ssZZ")
      );
    }
    if (values?.loadingCompleted) {
      data.append(
        "loadingCompleted",
        values?.loadingCompleted.toLowerCase() !== "invalid date" &&
          !values?.loadingCompleted
          ? ""
          : Index.moment(values?.loadingCompleted).format(
              "YYYY-MM-DDTHH:mm:ssZZ"
            )
      );
    }
    if (values?.loadedQty) {
      data.append("loadedQty", values.loadedQty);
    }
    if (values?.loadedTTOut) {
      data.append(
        "loadedTTOut",
        values?.loadedTTOut.toLowerCase() !== "invalid date" &&
          !values?.loadedTTOut
          ? ""
          : Index.moment(values?.loadedTTOut).format("YYYY-MM-DDTHH:mm:ssZZ")
      );
    }
    if (values?.kandalaIn) {
      data.append(
        "kandalaIn",
        values?.kandalaIn.toLowerCase() !== "invalid date" && !values?.kandalaIn
          ? ""
          : Index.moment(values?.kandalaIn).format("YYYY-MM-DDTHH:mm:ssZZ")
      );
    }
    if (values?.unloadingStart) {
      data.append(
        "unloadingStart",
        values?.unloadingStart.toLowerCase() !== "invalid date" &&
          !values?.unloadingStart
          ? ""
          : Index.moment(values?.unloadingStart).format("YYYY-MM-DDTHH:mm:ssZZ")
      );
    }
    if (values?.bargeSelection) {
      data.append(
        "bargeSelection",
        values.bargeSelection ? values.bargeSelection : ""
      );
    }

    if (values?.unloadingCompleted) {
      data.append(
        "unloadingCompleted",
        values?.unloadingCompleted.toLowerCase() !== "invalid date" &&
          !values?.unloadingCompleted
          ? ""
          : Index.moment(values?.unloadingCompleted).format(
              "YYYY-MM-DDTHH:mm:ssZZ"
            )
      );
    }
    if (values?.loadingRemark) {
      data.append("loadingRemark", values.loadingRemark);
    }
    if (values?.kandalaRemark) {
      data.append("kandalaRemark", values.kandalaRemark);
    }
    if (values?.surveyorRemark) {
      data.append("surveyorRemark", values.surveyorRemark);
    }

    if (values?.dignityLoadingCompleted) {
      data.append(
        "dignityLoadingCompleted",
        values?.dignityLoadingCompleted.toLowerCase() !== "invalid date" &&
          !values?.dignityLoadingCompleted
          ? ""
          : Index.moment(values?.dignityLoadingCompleted).format(
              "YYYY-MM-DDTHH:mm:ssZZ"
            )
      );
    }
    if (values?.kandalaOut) {
      data.append(
        "kandalaOut",
        values?.kandalaOut.toLowerCase() !== "invalid date" &&
          !values?.kandalaOut
          ? ""
          : Index.moment(values?.kandalaOut).format("YYYY-MM-DDTHH:mm:ssZZ")
      );
    }
    // if (adminDetails?.role !== "Surveyor") {
    //   if (values?.rcvdBdn) {
    //     data.append(
    //       "rcvdBdn",
    //       values?.rcvdBdn.toLowerCase() !== "invalid date" && !values?.rcvdBdn
    //         ? ""
    //         : Index.moment(values?.rcvdBdn).format("YYYY-MM-DDTHH:mm:ssZZ")
    //     );
    //   }
    // }

    if (values?.soundingCalculation) {
      data.append("soundingCalculation", values.soundingCalculation);
    }
    values.document.forEach((ele, i) => {
      data.append("document", ele);
    });
    data.append("type", "web");
    if (id) {
      data.append("id", id);
    }
    dispatch(addTankerDashboardDetails(data, navigate)).then((res) => {
      setBtnLoading(false);
      if (type == "loadingTankerDashboard") {
        navigate(`/admin/order/view-order/${viewData?.orderId}`, {
          state: { value: 5 },
        });
      } else {
        navigate("/admin/companyDashboard", {
          state: { value: 1 },
        });
      }
    });
  };

  // get Tanker Barge List
  const getTankerBargeList = () => {
    dispatch(getTankerDashboardBarge()).then((res) => {
      if (res) {
        setBargeListCatData(
          res?.data?.sort((a, b) => a?.bargeName?.localeCompare(b?.bargeName))
        );
      }
    });
  };

  // get Single Dashboard TankerData
  const getSingleDashboardTankerData = () => {
    setLoading(true);
    const data = {
      loadingId: id,
    };
    dispatch(getSingleDashboardTankerDetails(data)).then((res) => {
      if (res) {
        setViewData(res?.data);
        setTimeout(() => {
          setLoading(false);
        }, 2000);
      }
    });
  };

  useEffect(() => {
    getTankerBargeList();
    getSingleDashboardTankerData();
  }, []);

  const backBtn = () => {
    window.history.back();
    // navigate("/admin/companyDashboard", {
    //   state: { value: 1 },
    // });
    // if (type == "loadingTankerDashboard") {
    //   navigate(`/admin/order/view-order/${viewData?.orderId}`, {
    //     state: { value: 5 },
    //   });
    // }
  };

  // resetDate function
  const resetDate = (parameter, type) => {
    const data = new URLSearchParams();
    data.append("resetType", parameter);
    data.append("type", type);

    if (id) {
      data.append("id", id);
    }
    dispatch(resetTankerDate(data))
      .then((res) => {
        getSingleDashboardTankerData();
      })
      .catch((err) => {});
  };
  return (
    <>
      {loading ? (
        <>
          <Index.Table>
            <Index.TableBody>
              <Index.TableRow>
                <Index.TableCell
                  colSpan={13}
                  align="center"
                  className="loading-purchase"
                >
                  <Index.CircularProgress color="secondary" size={20} />
                </Index.TableCell>
              </Index.TableRow>
            </Index.TableBody>
          </Index.Table>
        </>
      ) : (
        <Index.Box className="">
          <Index.Box className="barge-common-box">
            <Index.Box className="title-header">
              <Index.Box className="title-header-flex">
                <Index.Box className="title-main mb-10">
                  <Index.Typography
                    variant="p"
                    component="p"
                    className="page-title"
                  >
                    Tanker Dashboard Details{" "}
                    {`(${viewData?.tanker?.tankerNumber})`}
                  </Index.Typography>
                </Index.Box>
                <Index.Box className="common-button blue-button save-btn ">
                  <Index.Button
                    variant="contained"
                    type="submit"
                    className="Particular_model_submit ml-0"
                    onClick={backBtn}
                  >
                    Back
                  </Index.Button>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Formik
              // enableReinitialize
              initialValues={initialValues}
              onSubmit={handleFormSubmit}
            >
              {({
                values,
                setFieldValue,
                handleChange,
                handleBlur,
                handleSubmit,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Index.Box className="">
                    <Index.Grid container spacing={2}>
                      <Index.Grid item xs={12} sm={6} md={4}>
                        <Index.Typography
                          variant="label"
                          component="label"
                          className="input-label"
                        >
                          Loading TT In
                        </Index.Typography>
                        <Index.Box className="input-design-div">
                          <Index.Box className="set-date set-date-picker">
                            <Index.LocalizationProvider
                              dateAdapter={Index.AdapterDayjs}
                            >
                              <Index.DemoContainer
                                components={[
                                  "Index.DateTimePicker",
                                  "Index.DateTimePicker",
                                  "Index.DateTimePicker",
                                ]}
                              >
                                <DateTimePicker
                                  views={[
                                    "year",
                                    "month",
                                    "day",
                                    "hours",
                                    "minutes",
                                    "seconds",
                                  ]}
                                  timeSteps={{
                                    hours: 1,
                                    minutes: 1,
                                    seconds: 1,
                                  }}
                                  id="loadedTTIn"
                                  defaultValue={
                                    values?.loadedTTIn
                                      ? dayjs(
                                          Index.moment(
                                            viewData?.loadedTTIn
                                          ).format("YYYY-MM-DDTHH:mm:ss")
                                        )
                                      : ""
                                  }
                                  onOpen={() => {
                                    openPicker();
                                  }}
                                  onClose={() => {
                                    closePicker();
                                  }}
                                  onChange={(value) => {
                                    const formatedDate = Index.moment(
                                      value.$d
                                    ).format("YYYY-MM-DDTHH:mm:ssZZ");
                                    setFieldValue("loadedTTIn", formatedDate);
                                  }}
                                  slotProps={{
                                    textField: {
                                      readOnly: true,
                                    },
                                  }}
                                  format="DD/MM/YYYY HH:mm:ss"
                                />
                              </Index.DemoContainer>
                            </Index.LocalizationProvider>
                            {viewData?.loadedTTIn &&
                              viewData?.loadedTTIn != "Invalid date" && (
                                <Index.IconButton className="reset-icon">
                                  <RestartAltIcon
                                    onClick={() => {
                                      resetDate("loadedTTIn", "Loading TT In");
                                    }}
                                  />
                                </Index.IconButton>
                              )}
                          </Index.Box>
                        </Index.Box>
                      </Index.Grid>

                      <Index.Grid item xs={12} sm={6} md={4}>
                        <Index.Typography
                          variant="label"
                          component="label"
                          className="input-label"
                        >
                          Loading Start
                        </Index.Typography>
                        <Index.Box className="input-design-div">
                          <Index.Box className="set-date set-date-picker">
                            <Index.LocalizationProvider
                              dateAdapter={Index.AdapterDayjs}
                            >
                              <Index.DemoContainer
                                components={[
                                  "Index.DateTimePicker",
                                  "Index.DateTimePicker",
                                  "Index.DateTimePicker",
                                ]}
                              >
                                <DateTimePicker
                                  views={[
                                    "year",
                                    "month",
                                    "day",
                                    "hours",
                                    "minutes",
                                    "seconds",
                                  ]}
                                  timeSteps={{
                                    hours: 1,
                                    minutes: 1,
                                    seconds: 1,
                                  }}
                                  id="loadingStart"
                                  defaultValue={
                                    values?.loadingStart
                                      ? dayjs(
                                          Index.moment(
                                            viewData?.loadingStart
                                          ).format("YYYY-MM-DDTHH:mm:ss")
                                        )
                                      : ""
                                  }
                                  onOpen={() => {
                                    openPicker();
                                  }}
                                  onClose={() => {
                                    closePicker();
                                  }}
                                  onChange={(value) => {
                                    const formatedDate = Index.moment(
                                      value.$d
                                    ).format("YYYY-MM-DDTHH:mm:ssZZ");
                                    setFieldValue("loadingStart", formatedDate);
                                  }}
                                  slotProps={{
                                    textField: {
                                      readOnly: true,
                                    },
                                  }}
                                  format="DD/MM/YYYY HH:mm:ss"
                                />
                              </Index.DemoContainer>
                            </Index.LocalizationProvider>
                            {viewData?.loadingStart &&
                              viewData?.loadingStart != "Invalid date" && (
                                <Index.IconButton className="reset-icon">
                                  <RestartAltIcon
                                    onClick={() => {
                                      resetDate(
                                        "loadingStart",
                                        "Loading Start"
                                      );
                                    }}
                                  />
                                </Index.IconButton>
                              )}
                          </Index.Box>
                        </Index.Box>
                      </Index.Grid>
                      <Index.Grid item xs={12} sm={6} md={4}>
                        <Index.Typography
                          variant="label"
                          component="label"
                          className="input-label"
                        >
                          Loading Completed
                        </Index.Typography>
                        <Index.Box className="input-design-div">
                          <Index.Box className="set-date set-date-picker">
                            <Index.LocalizationProvider
                              dateAdapter={Index.AdapterDayjs}
                            >
                              <Index.DemoContainer
                                components={[
                                  "Index.DateTimePicker",
                                  "Index.DateTimePicker",
                                  "Index.DateTimePicker",
                                ]}
                              >
                                <DateTimePicker
                                  views={[
                                    "year",
                                    "month",
                                    "day",
                                    "hours",
                                    "minutes",
                                    "seconds",
                                  ]}
                                  timeSteps={{
                                    hours: 1,
                                    minutes: 1,
                                    seconds: 1,
                                  }}
                                  id="loadingCompleted"
                                  defaultValue={
                                    values?.loadingCompleted
                                      ? dayjs(
                                          Index.moment(
                                            viewData?.loadingCompleted
                                          ).format("YYYY-MM-DDTHH:mm:ss")
                                        )
                                      : ""
                                  }
                                  onOpen={() => {
                                    openPicker();
                                  }}
                                  onClose={() => {
                                    closePicker();
                                  }}
                                  onChange={(value) => {
                                    const formatedDate = Index.moment(
                                      value.$d
                                    ).format("YYYY-MM-DDTHH:mm:ssZZ");
                                    setFieldValue(
                                      "loadingCompleted",
                                      formatedDate
                                    );
                                  }}
                                  slotProps={{
                                    textField: {
                                      readOnly: true,
                                    },
                                  }}
                                  format="DD/MM/YYYY HH:mm:ss"
                                />
                              </Index.DemoContainer>
                            </Index.LocalizationProvider>
                            {viewData?.loadingCompleted &&
                              viewData?.loadingCompleted != "Invalid date" && (
                                <Index.IconButton className="reset-icon">
                                  <RestartAltIcon
                                    onClick={() => {
                                      resetDate(
                                        "loadingCompleted",
                                        "Loading Completed"
                                      );
                                    }}
                                  />
                                </Index.IconButton>
                              )}
                          </Index.Box>
                        </Index.Box>
                      </Index.Grid>
                      <Index.Grid item xs={12} sm={6} md={4}>
                        <Index.Typography
                          variant="label"
                          component="label"
                          className="input-label"
                        >
                          Loaded Quantity
                        </Index.Typography>
                        <Index.Box className="input-design-div with-border">
                          <Index.Box className="set-date set-date-picker">
                            <Index.TextField
                              hiddenLabel
                              type="number"
                              id="filled-hidden-label-normal"
                              placeholder="Loaded Quantity"
                              variant="filled"
                              className="admin-input-design input-placeholder"
                              name="loadedQty"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values?.loadedQty}
                            />
                            {viewData?.loadedQty &&
                              viewData?.loadedQty != "Invalid date" && (
                                <Index.IconButton className="reset-icon">
                                  <RestartAltIcon
                                    onClick={() => {
                                      resetDate("loadedQty", "Loaded Qty");
                                    }}
                                  />
                                </Index.IconButton>
                              )}
                          </Index.Box>
                        </Index.Box>
                      </Index.Grid>
                      <Index.Grid item xs={12} sm={6} md={4}>
                        <Index.Typography
                          variant="label"
                          component="label"
                          className="input-label"
                        >
                          Loaded TT Out
                        </Index.Typography>
                        <Index.Box className="input-design-div">
                          <Index.Box className="set-date set-date-picker">
                            <Index.LocalizationProvider
                              dateAdapter={Index.AdapterDayjs}
                            >
                              <Index.DemoContainer
                                components={[
                                  "Index.DateTimePicker",
                                  "Index.DateTimePicker",
                                  "Index.DateTimePicker",
                                ]}
                              >
                                <DateTimePicker
                                  views={[
                                    "year",
                                    "month",
                                    "day",
                                    "hours",
                                    "minutes",
                                    "seconds",
                                  ]}
                                  timeSteps={{
                                    hours: 1,
                                    minutes: 1,
                                    seconds: 1,
                                  }}
                                  id="loadedTTOut"
                                  defaultValue={
                                    values?.loadedTTOut
                                      ? dayjs(
                                          Index.moment(
                                            viewData?.loadedTTOut
                                          ).format("YYYY-MM-DDTHH:mm:ss")
                                        )
                                      : ""
                                  }
                                  onOpen={() => {
                                    openPicker();
                                  }}
                                  onClose={() => {
                                    closePicker();
                                  }}
                                  onChange={(value) => {
                                    const formatedDate = Index.moment(
                                      value.$d
                                    ).format("YYYY-MM-DDTHH:mm:ssZZ");
                                    setFieldValue("loadedTTOut", formatedDate);
                                  }}
                                  slotProps={{
                                    textField: {
                                      readOnly: true,
                                    },
                                  }}
                                  format="DD/MM/YYYY HH:mm:ss"
                                />
                              </Index.DemoContainer>
                            </Index.LocalizationProvider>
                            {viewData?.loadedTTOut &&
                              viewData?.loadedTTOut != "Invalid date" && (
                                <Index.IconButton className="reset-icon">
                                  <RestartAltIcon
                                    onClick={() => {
                                      resetDate("loadedTTOut", "Loaded TT Out");
                                    }}
                                  />
                                </Index.IconButton>
                              )}
                          </Index.Box>
                        </Index.Box>
                      </Index.Grid>

                      <Index.Grid item xs={12} sm={6} md={4}>
                        <Index.Typography
                          variant="label"
                          component="label"
                          className="input-label"
                        >
                          Loading Remark
                        </Index.Typography>
                        <Index.Box className="input-design-div with-border">
                          <Index.Box className="set-date set-date-picker">
                            <Index.TextField
                              hiddenLabel
                              id="filled-hidden-label-normal"
                              placeholder="Loading Remark"
                              variant="filled"
                              className="admin-input-design input-placeholder Lop Issue(Remark)"
                              name="loadingRemark"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values?.loadingRemark}
                            />
                          </Index.Box>
                        </Index.Box>
                      </Index.Grid>
                      <Index.Grid item xs={12} sm={6} md={4}>
                        <Index.Typography
                          variant="label"
                          component="label"
                          className="input-label"
                        >
                          Kandla In
                        </Index.Typography>
                        <Index.Box className="input-design-div">
                          <Index.Box className="set-date set-date-picker">
                            <Index.LocalizationProvider
                              dateAdapter={Index.AdapterDayjs}
                            >
                              <Index.DemoContainer
                                components={[
                                  "Index.DateTimePicker",
                                  "Index.DateTimePicker",
                                  "Index.DateTimePicker",
                                ]}
                              >
                                <DateTimePicker
                                  views={[
                                    "year",
                                    "month",
                                    "day",
                                    "hours",
                                    "minutes",
                                    "seconds",
                                  ]}
                                  timeSteps={{
                                    hours: 1,
                                    minutes: 1,
                                    seconds: 1,
                                  }}
                                  id="kandalaIn"
                                  defaultValue={
                                    values?.kandalaIn
                                      ? dayjs(
                                          Index.moment(
                                            viewData?.kandalaIn
                                          ).format("YYYY-MM-DDTHH:mm:ss")
                                        )
                                      : ""
                                  }
                                  onOpen={() => {
                                    openPicker();
                                  }}
                                  onClose={() => {
                                    closePicker();
                                  }}
                                  onChange={(value) => {
                                    const formatedDate = Index.moment(
                                      value.$d
                                    ).format("YYYY-MM-DDTHH:mm:ssZZ");
                                    setFieldValue("kandalaIn", formatedDate);
                                  }}
                                  slotProps={{
                                    textField: {
                                      readOnly: true,
                                    },
                                  }}
                                  format="DD/MM/YYYY HH:mm:ss"
                                />
                              </Index.DemoContainer>
                            </Index.LocalizationProvider>

                            {viewData?.kandalaIn &&
                              viewData?.kandalaIn != "Invalid date" && (
                                <Index.IconButton className="reset-icon">
                                  <RestartAltIcon
                                    onClick={() => {
                                      resetDate("kandalaIn", "Kandla In");
                                    }}
                                  />
                                </Index.IconButton>
                              )}
                          </Index.Box>
                        </Index.Box>
                      </Index.Grid>
                      <Index.Grid item xs={12} sm={6} md={4}>
                        <Index.Typography
                          variant="label"
                          component="label"
                          className="input-label"
                        >
                          Kandla Remark
                        </Index.Typography>
                        <Index.Box className="input-design-div with-border">
                          <Index.Box className="set-date set-date-picker">
                            <Index.TextField
                              hiddenLabel
                              id="filled-hidden-label-normal"
                              placeholder="Kandla Remark"
                              variant="filled"
                              className="admin-input-design input-placeholder Lop Issue(Remark)"
                              name="kandalaRemark"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values?.kandalaRemark}
                            />
                          </Index.Box>
                        </Index.Box>
                      </Index.Grid>
                      <Index.Grid item xs={12} sm={6} md={4}>
                        <Index.Typography
                          variant="label"
                          component="label"
                          className="input-label"
                        >
                          Unloading Start
                        </Index.Typography>
                        <Index.Box className="input-design-div">
                          <Index.Box className="set-date set-date-picker">
                            <Index.LocalizationProvider
                              dateAdapter={Index.AdapterDayjs}
                            >
                              <Index.DemoContainer
                                components={[
                                  "Index.DateTimePicker",
                                  "Index.DateTimePicker",
                                  "Index.DateTimePicker",
                                ]}
                              >
                                <DateTimePicker
                                  views={[
                                    "year",
                                    "month",
                                    "day",
                                    "hours",
                                    "minutes",
                                    "seconds",
                                  ]}
                                  timeSteps={{
                                    hours: 1,
                                    minutes: 1,
                                    seconds: 1,
                                  }}
                                  id="unloadingStart"
                                  defaultValue={
                                    values?.unloadingStart
                                      ? dayjs(
                                          Index.moment(
                                            viewData?.unloadingStart
                                          ).format("YYYY-MM-DDTHH:mm:ss")
                                        )
                                      : ""
                                  }
                                  onOpen={() => {
                                    openPicker();
                                  }}
                                  onClose={() => {
                                    closePicker();
                                  }}
                                  onChange={(value) => {
                                    const formatedDate = Index.moment(
                                      value.$d
                                    ).format("YYYY-MM-DDTHH:mm:ssZZ");
                                    setFieldValue(
                                      "unloadingStart",
                                      formatedDate
                                    );
                                  }}
                                  slotProps={{
                                    textField: {
                                      readOnly: true,
                                    },
                                  }}
                                  format="DD/MM/YYYY HH:mm:ss"
                                />
                              </Index.DemoContainer>
                            </Index.LocalizationProvider>
                            {viewData?.unloadingStart &&
                              viewData?.unloadingStart != "Invalid date" && (
                                <Index.IconButton className="reset-icon">
                                  <RestartAltIcon
                                    onClick={() => {
                                      resetDate(
                                        "unloadingStart",
                                        "Unloading Start"
                                      );
                                    }}
                                  />
                                </Index.IconButton>
                              )}
                          </Index.Box>
                        </Index.Box>
                      </Index.Grid>
                      <Index.Grid item xs={12} sm={6} md={4}>
                        <Index.Typography
                          variant="label"
                          component="label"
                          className="input-label"
                        >
                          Barge Selection
                        </Index.Typography>
                        <Index.Box className="input-design-div with-border set-date set-date-picker">
                          <Index.Select
                            fullWidth
                            name="bargeSelection"
                            displayEmpty
                            inputProps={{ "aria-label": "without label" }}
                            value={
                              values?.bargeSelection
                                ? values?.bargeSelection
                                : ""
                            }
                            renderValue={
                              values?.bargeSelection != ""
                                ? undefined
                                : () => "Please select barge"
                            }
                            onChange={(e) => {
                              setFieldValue("bargeSelection", e.target.value);
                            }}
                          >
                            <Index.MenuItem disabled value="">
                              {bargeListCatData?.length > 0 ? (
                                <em>Please select a barge</em>
                              ) : (
                                ""
                              )}
                            </Index.MenuItem>
                            {bargeListCatData?.length === 0 ? (
                              <Index.MenuItem disabled>
                                <Index.Box className="multiselect-item">
                                  No data found
                                </Index.Box>
                              </Index.MenuItem>
                            ) : (
                              bargeListCatData.map((item) => (
                                <Index.MenuItem
                                  key={item?._id}
                                  value={item?._id}
                                >
                                  <Index.Box className="multiselect-item">
                                    {item?.bargeName}
                                  </Index.Box>
                                </Index.MenuItem>
                              ))
                            )}
                          </Index.Select>
                          {viewData?.bargeSelection && (
                            <Index.IconButton className="reset-icon">
                              <RestartAltIcon
                                onClick={() => {
                                  resetDate(
                                    "bargeSelection",
                                    "Barge Selection"
                                  );
                                }}
                              />
                            </Index.IconButton>
                          )}
                        </Index.Box>
                      </Index.Grid>
                      <Index.Grid item xs={12} sm={6} md={4}>
                        <Index.Typography
                          variant="label"
                          component="label"
                          className="input-label"
                        >
                          Unloading Completed
                        </Index.Typography>
                        <Index.Box className="input-design-div">
                          <Index.Box className="set-date set-date-picker">
                            <Index.LocalizationProvider
                              dateAdapter={Index.AdapterDayjs}
                            >
                              <Index.DemoContainer
                                components={[
                                  "Index.DateTimePicker",
                                  "Index.DateTimePicker",
                                  "Index.DateTimePicker",
                                ]}
                              >
                                <DateTimePicker
                                  views={[
                                    "year",
                                    "month",
                                    "day",
                                    "hours",
                                    "minutes",
                                    "seconds",
                                  ]}
                                  timeSteps={{
                                    hours: 1,
                                    minutes: 1,
                                    seconds: 1,
                                  }}
                                  id="unloadingCompleted"
                                  defaultValue={
                                    values?.unloadingCompleted
                                      ? dayjs(
                                          Index.moment(
                                            viewData?.unloadingCompleted
                                          ).format("YYYY-MM-DDTHH:mm:ss")
                                        )
                                      : ""
                                  }
                                  onOpen={() => {
                                    openPicker();
                                  }}
                                  onClose={() => {
                                    closePicker();
                                  }}
                                  onChange={(value) => {
                                    const formatedDate = Index.moment(
                                      value.$d
                                    ).format("YYYY-MM-DDTHH:mm:ssZZ");
                                    setFieldValue(
                                      "unloadingCompleted",
                                      formatedDate
                                    );
                                  }}
                                  slotProps={{
                                    textField: {
                                      readOnly: true,
                                    },
                                  }}
                                  format="DD/MM/YYYY HH:mm:ss"
                                />
                              </Index.DemoContainer>
                            </Index.LocalizationProvider>

                            {viewData?.unloadingCompleted &&
                              viewData?.unloadingCompleted !=
                                "Invalid date" && (
                                <Index.IconButton className="reset-icon">
                                  <RestartAltIcon
                                    onClick={() => {
                                      resetDate(
                                        "unloadingCompleted",
                                        "Unloading Completed"
                                      );
                                    }}
                                  />
                                </Index.IconButton>
                              )}
                          </Index.Box>
                        </Index.Box>
                      </Index.Grid>
                      <Index.Grid item xs={12} sm={6} md={4}>
                        <Index.Typography
                          variant="label"
                          component="label"
                          className="input-label"
                        >
                          Surveyor Remark
                        </Index.Typography>
                        <Index.Box className="input-design-div with-border">
                          <Index.Box className="set-date set-date-picker">
                            <Index.TextField
                              hiddenLabel
                              id="filled-hidden-label-normal"
                              placeholder="Surveyor Remark"
                              variant="filled"
                              className="admin-input-design input-placeholder Lop Issue(Remark)"
                              name="surveyorRemark"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values?.surveyorRemark}
                            />
                          </Index.Box>
                        </Index.Box>
                      </Index.Grid>
                      <Index.Grid item xs={12} sm={6} md={4}>
                        <Index.Typography
                          variant="label"
                          component="label"
                          className="input-label"
                        >
                          Barge Loading Completed
                        </Index.Typography>
                        <Index.Box className="input-design-div">
                          <Index.Box className="set-date set-date-picker">
                            <Index.LocalizationProvider
                              dateAdapter={Index.AdapterDayjs}
                            >
                              <Index.DemoContainer
                                components={[
                                  "Index.DateTimePicker",
                                  "Index.DateTimePicker",
                                  "Index.DateTimePicker",
                                ]}
                              >
                                <DateTimePicker
                                  views={[
                                    "year",
                                    "month",
                                    "day",
                                    "hours",
                                    "minutes",
                                    "seconds",
                                  ]}
                                  timeSteps={{
                                    hours: 1,
                                    minutes: 1,
                                    seconds: 1,
                                  }}
                                  id="dignityLoadingCompleted"
                                  defaultValue={
                                    values?.dignityLoadingCompleted
                                      ? dayjs(
                                          Index.moment(
                                            viewData?.dignityLoadingCompleted
                                          ).format("YYYY-MM-DDTHH:mm:ss")
                                        )
                                      : ""
                                  }
                                  onOpen={() => {
                                    openPicker();
                                  }}
                                  onClose={() => {
                                    closePicker();
                                  }}
                                  onChange={(value) => {
                                    const formatedDate = Index.moment(
                                      value.$d
                                    ).format("YYYY-MM-DDTHH:mm:ssZZ");
                                    setFieldValue(
                                      "dignityLoadingCompleted",
                                      formatedDate
                                    );
                                  }}
                                  slotProps={{
                                    textField: {
                                      readOnly: true,
                                    },
                                  }}
                                  format="DD/MM/YYYY HH:mm:ss"
                                />
                              </Index.DemoContainer>
                            </Index.LocalizationProvider>
                            {viewData?.dignityLoadingCompleted &&
                              viewData?.dignityLoadingCompleted !=
                                "Invalid date" && (
                                <Index.IconButton className="reset-icon">
                                  <RestartAltIcon
                                    onClick={() => {
                                      resetDate(
                                        "dignityLoadingCompleted",
                                        "Barge Loading Completed"
                                      );
                                    }}
                                  />
                                </Index.IconButton>
                              )}
                          </Index.Box>
                        </Index.Box>
                      </Index.Grid>
                      <Index.Grid item xs={12} sm={6} md={4}>
                        <Index.Typography
                          variant="label"
                          component="label"
                          className="input-label"
                        >
                          Sounding Calculation Barge
                        </Index.Typography>
                        <Index.Box className="input-design-div with-border">
                          <Index.Box className="set-date set-date-picker">
                            <Index.TextField
                              hiddenLabel
                              type="number"
                              id="filled-hidden-label-normal"
                              placeholder="Sounding Calculation Barge"
                              variant="filled"
                              className="admin-input-design input-placeholder"
                              name="soundingCalculation"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values?.soundingCalculation}
                            />
                            {viewData?.soundingCalculation && (
                              <Index.IconButton className="reset-icon">
                                <RestartAltIcon
                                  onClick={() => {
                                    resetDate(
                                      "soundingCalculation",
                                      "Sounding Calculation"
                                    );
                                  }}
                                />
                              </Index.IconButton>
                            )}
                          </Index.Box>
                        </Index.Box>
                      </Index.Grid>
                      <Index.Grid item xs={12} sm={6} md={4}>
                        <Index.Typography
                          variant="label"
                          component="label"
                          className="input-label"
                        >
                          TT Invoice (Image / PDF)
                        </Index.Typography>
                        <Index.Box className="input-design-div with-border">
                          <Index.Box className="input-design-div with-border position-relative">
                            <Index.Box className="set-date set-date-picker">
                              <Index.Box className="pdf-design width-pdf-design w-100">
                                Upload File
                              </Index.Box>

                              <Index.TextField
                                fullWidth
                                type="file"
                                name="document"
                                multiple
                                id="filled-hidden-label-normal"
                                variant="filled"
                                onBlur={handleBlur}
                                className="admin-input-design input-placeholder pdf-upload-design"
                                onChange={(event) => {
                                  const files = Array.from(event.target.files);
                                  setImage([...files]);
                                  setFieldValue("document", [...files]);
                                }}
                                inputProps={{
                                  multiple: true,
                                }}
                                FormHelperTextProps={{
                                  className: "input_label_props",
                                }}
                              />
                              {viewData?.document?.length > 0 && (
                                <Index.IconButton className="reset-icon">
                                  <RestartAltIcon
                                    onClick={() => {
                                      resetDate("document", "Document");
                                    }}
                                  />
                                </Index.IconButton>
                              )}
                            </Index.Box>
                            <Index.Box className="upload-image-flex">
                              {/* edit image view */}

                              {/* <Index.Box className="upload-image-edit">
                                    {viewData?.document?.map((items, index) => {
                                      return (
                                        <>
                                          <Index.Box className="document-box-display">
                                            {
                                              <img
                                                alt="no image"
                                                src={`${process.env.REACT_APP_IMAGE_URL}/loadingDoc/${items}`}
                                              />
                                             
                                            }
                                          </Index.Box>
                                        </>
                                      );
                                    })}
                                </Index.Box> */}

                              {/* edit image view end */}
                            </Index.Box>
                          </Index.Box>
                          <Index.Box className="view-upload-image">
                            {values?.document?.map((items, index) => {
                              return (
                                <Index.Typography>
                                  <img
                                    src={
                                      items ? URL.createObjectURL(items) : ""
                                    }
                                    alt=""
                                    className="user-img"
                                  />
                                </Index.Typography>
                              );
                            })}
                          </Index.Box>
                          {/* <Index.Grid item xs={6} sm={6} md={4}>
                             
                              {values?.document?.map((items, index) => (
                                <Index.Box
                                  className="user-img-main"
                                  key={index}
                                >
                                  <img
                                    src={
                                      items ? URL.createObjectURL(items) : ""
                                    }
                                    alt=""
                                    className="user-img"
                                  />
                                </Index.Box>
                              ))}
                            </Index.Grid> */}
                        </Index.Box>
                      </Index.Grid>
                      {/* {adminDetails?.role !== "Surveyor" ? (
                        <>
                          <Index.Grid item xs={12} sm={6} md={4}>
                            <Index.Typography
                              variant="label"
                              component="label"
                              className="input-label"
                            >
                              RCVD BDN
                            </Index.Typography>
                            <Index.Box className="input-design-div">
                              <Index.Box className="set-date set-date-picker">
                                <Index.LocalizationProvider
                                  dateAdapter={Index.AdapterDayjs}
                                >
                                  <Index.DemoContainer
                                    components={[
                                      "Index.DateTimePicker",
                                      "Index.DateTimePicker",
                                      "Index.DateTimePicker",
                                    ]}
                                  >
                                    <DateTimePicker
                                      views={[
                                        "year",
                                        "month",
                                        "day",
                                        "hours",
                                        "minutes",
                                        "seconds",
                                      ]}
                                      timeSteps={{
                                        hours: 1,
                                        minutes: 1,
                                        seconds: 1,
                                      }}
                                      id="rcvdBdn"
                                      defaultValue={
                                        values?.rcvdBdn
                                          ? dayjs(
                                              Index.moment(
                                                viewData?.rcvdBdn
                                              ).format("YYYY-MM-DDTHH:mm:ss")
                                            )
                                          : ""
                                      }
                                      onChange={(value) => {
                                        const formatedDate = Index.moment(
                                          value.$d
                                        ).format("YYYY-MM-DDTHH:mm:ssZZ");
                                        setFieldValue("rcvdBdn", formatedDate);
                                      }}
                                      slotProps={{
                                        textField: {
                                          readOnly: true,
                                        },
                                      }}
                                      format="DD/MM/YYYY HH:mm:ss"
                                    />
                                  </Index.DemoContainer>
                                </Index.LocalizationProvider>
                                {viewData?.rcvdBdn &&
                                  viewData?.rcvdBdn != "Invalid date" && (
                                    <Index.IconButton className="reset-icon">
                                      <RestartAltIcon
                                        onClick={() => {
                                          resetDate("rcvdBdn", "RCVD BDN");
                                        }}
                                      />
                                    </Index.IconButton>
                                  )}
                              </Index.Box>
                            </Index.Box>
                          </Index.Grid>
                        </>
                      ) : (
                        <></>
                      )} */}

                      <Index.Grid item xs={12} sm={6} md={4}>
                        <Index.Typography
                          variant="label"
                          component="label"
                          className="input-label"
                        >
                          Kandla Out
                        </Index.Typography>
                        <Index.Box className="input-design-div">
                          <Index.Box className="set-date set-date-picker">
                            <Index.LocalizationProvider
                              dateAdapter={Index.AdapterDayjs}
                            >
                              <Index.DemoContainer
                                components={[
                                  "Index.DateTimePicker",
                                  "Index.DateTimePicker",
                                  "Index.DateTimePicker",
                                ]}
                              >
                                <DateTimePicker
                                  views={[
                                    "year",
                                    "month",
                                    "day",
                                    "hours",
                                    "minutes",
                                    "seconds",
                                  ]}
                                  timeSteps={{
                                    hours: 1,
                                    minutes: 1,
                                    seconds: 1,
                                  }}
                                  id="kandalaOut"
                                  defaultValue={
                                    values?.kandalaOut
                                      ? dayjs(
                                          Index.moment(
                                            viewData?.kandalaOut
                                          ).format("YYYY-MM-DDTHH:mm:ss")
                                        )
                                      : ""
                                  }
                                  onOpen={() => {
                                    openPicker();
                                  }}
                                  onClose={() => {
                                    closePicker();
                                  }}
                                  onChange={(value) => {
                                    const formatedDate = Index.moment(
                                      value.$d
                                    ).format("YYYY-MM-DDTHH:mm:ssZZ");
                                    setFieldValue("kandalaOut", formatedDate);
                                  }}
                                  slotProps={{
                                    textField: {
                                      readOnly: true,
                                    },
                                  }}
                                  format="DD/MM/YYYY HH:mm:ss"
                                />
                              </Index.DemoContainer>
                            </Index.LocalizationProvider>
                            {viewData?.kandalaOut &&
                              viewData?.kandalaOut != "Invalid date" && (
                                <Index.IconButton className="reset-icon">
                                  <RestartAltIcon
                                    onClick={() => {
                                      resetDate("kandalaOut", "Kandla Out");
                                    }}
                                  />
                                </Index.IconButton>
                              )}
                          </Index.Box>
                        </Index.Box>
                      </Index.Grid>
                    </Index.Grid>
                  </Index.Box>
                  <Index.Box className="common-button blue-button flex-end save-btn user-submit-btn">
                    <Index.Button
                      variant="contained"
                      type="submit"
                      disabled={btnLoading}
                    >
                      Submit
                    </Index.Button>
                  </Index.Box>
                </form>
              )}
            </Formik>
          </Index.Box>
        </Index.Box>
      )}
    </>
  );
}

export default TankerDashboardView;
