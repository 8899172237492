import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import Sidebar from "../../../../component/admin/defaulLayout/Sidebar";
import {
  adminLogout,
  getInquiryList,
  getInvoiceReminder,
  getNotifications,
  getAllCompanyList,
  adminSelectCompany,
} from "../../../../redux/admin/action";
import EnquiryPopupModel from "../enquiry/EnquiryPopupModel";
import CircleNotificationsIcon from "@mui/icons-material/CircleNotifications";
import { Badge } from "@mui/material";
import InvoiceRemiderPopUp from "./InvoiceReminderPopUp";
const DashboardLayout = () => {
  const dispatch = Index.useDispatch();
  const navigate = Index.useNavigate();
  const location = Index.useLocation();
  const login = Index.useSelector((state) => state.AdminReducer.adminDetails);
  const { inquiryList, notificationList, activeCompany } = Index.useSelector(
    (state) => state.AdminReducer
  );
  const { adminDetails } = Index.useSelector((state) => state.AdminReducer);
  const { webPermission } = adminDetails?.permission;
  const [anchorEl, setAnchorEl] = useState(null);
  const [openPopup, setOpenPopup] = useState(false);

  const [inquiryListData, setInquiryListData] = useState([]);
  const [openReminderInvoicePopUp, setOpenReminderInvoicePopUp] =
    useState(false);
  const [reminderInvoiceData, setReminderInvoiceData] = useState("");
  const [companyList, setCompanyList] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState("");

  const open2 = Boolean(anchorEl);
  const handleClick2 = (event) => {
    setAnchorEl(event.currentTarget);
    document.body.classList["add"]("menu-set-main");
  };
  const handleClose2 = () => {
    setAnchorEl(null);
    document.body.classList["remove"]("menu-set-main");
  };
  const [open, setOpen] = useState(false);
  useEffect(() => {
    document.body.classList[open ? "add" : "remove"]("body-no-class");
  }, [open]);

  // Popup functions
  const handleTimerPopupOpen = () => {
    setOpenPopup(true);
  };
  const handleTimerPopupClose = () => {
    setOpenPopup(false);
  };

  useEffect(() => {
    dispatch(getInquiryList());
  }, [dispatch]);

  useEffect(() => {
    setInquiryListData(inquiryList?.reverse());
  }, [inquiryList]);

  const handleInvoiceReminder = () => {
    dispatch(getInvoiceReminder()).then((res) => {
      setOpenReminderInvoicePopUp(true);
      if (res?.status === 200) {
        setReminderInvoiceData(res.data);
      } else {
        setReminderInvoiceData("");
      }
    });
  };

  useEffect(() => {
    setInterval(() => {
      // checkYesterdayPresent();
      const now = Index.moment();
      const Hours = now.format("HH:mm");
      if (Hours == "10:00" || Hours == "13:00" || Hours == "19:00") {
        handleTimerPopupOpen();
      }
      if (login?.permission?.roleName === "Finance") {
        if (Hours == "10:00" || Hours == "12:00" || Hours == "17:00") {
          handleInvoiceReminder();
        }
      }
    }, 50000);
  }, []);

  let roleClass = "";
  if (login?.permission?.roleName === "Operation") {
    // Role Operation then class added and all text-font show in Uppercase
    // roleClass = "admin-uppercase-role";
    roleClass = "";
  }
  const notificationHandle = () => {
    navigate("/admin/notification");
  };

  // when the get all the company list then used...
  const handleGetAllCompanyList = () => {
    dispatch(getAllCompanyList())
      .then((res) => {
        if (res.status === 200) {
          setCompanyList(res.data);
          if (activeCompany?.hasOwnProperty("_id")) {
            setSelectedCompany(
              res.data.find((res) => res._id === "6655c2cb793fa6e4f4ac4e93") !==
                undefined
                ? res.data.find((res) => res._id === "6655c2cb793fa6e4f4ac4e93")
                : ""
            );
          }
        } else {
          setCompanyList([]);
        }
      })
      .catch((error) => {
        setCompanyList([]);
        console.error(error);
      });
  };

  useEffect(() => {
    dispatch(getNotifications());
    handleGetAllCompanyList();
  }, []);

  const handleClosedInvoiceRemiderPopUp = () => {
    setOpenReminderInvoicePopUp(false);
    setReminderInvoiceData("");
  };

  // when the user change the company then used...
  const handleChangeCompany = (e) => {
    const { value } = e.target;
    setSelectedCompany(value);
    dispatch(
      adminSelectCompany({
        _id: value?._id,
        name: value?.name,
        fullName: value?.fullName,
        tallyCompanyName: value?.tallyCompanyName,
      })
    );
  };

  console.log("activeCompany", activeCompany);

  return (
    <div className={`main-dashboard ${roleClass} ${!open ? "" : "pl-none"}`}>
      <Index.Box
        className={`${open ? "overlay" : ""}`}
        onClick={() => setOpen((e) => !e)}
      ></Index.Box>
      <Sidebar setOpen={setOpen} open={open} />
      <Index.Box className="right-dashboard-content">
        <header>
          <Index.Box className={`main-header ${!open ? "" : "pl-none"}`}>
            <Index.Box
              className="collapse-btn"
              onClick={() => setOpen((e) => !e)}
            >
              <img src={Index.Svg.collapse} />
            </Index.Box>
            <Index.Box className="admin-header-right">
              {webPermission?.companySelectionOption ? (
                <>
                  {location?.pathname === "/admin/companyDashboard" ? (
                    <>
                      <Index.Box
                        className="input-design-div with-border"
                        sx={{ marginRight: "10px" }}
                      >
                        <Index.Select
                          className="placeholder-set-text-box"
                          fullWidth
                          // value={selectedCompany}
                          value={
                            companyList?.find(
                              (res) => res._id === selectedCompany?._id
                            ) !== undefined
                              ? companyList?.find(
                                  (res) => res._id === selectedCompany?._id
                                )
                              : ""
                          }
                          displayEmpty
                          inputProps={{
                            "aria-label": "Without label",
                          }}
                          onChange={(e) => handleChangeCompany(e)}
                        >
                          {companyList?.map((items, index) => (
                            <Index.MenuItem value={items} key={items?._id}>
                              {items?.name}
                            </Index.MenuItem>
                          ))}
                        </Index.Select>
                      </Index.Box>
                    </>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <></>
              )}

              <Badge
                // badgeContent={notificationList?.length}
                color={notificationList?.length ? "secondary" : "primary"}
                variant={notificationList?.length ? "dot" : "standard"}
              >
                <CircleNotificationsIcon
                  onClick={notificationHandle}
                  sx={{ cursor: "pointer" }}
                />
              </Badge>

              <Index.Box className="admin-header-drop-main">
                <Index.Button
                  className="drop-header-btn"
                  id="basic-button"
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick2}
                >
                  <Index.Box className="head-right">
                    <Index.Box className="header-user-detail">
                      <Index.Typography variant="p" className="head-user-title">
                        {login?.firstName}
                      </Index.Typography>
                      <Index.Typography variant="p" className="head-user-mail">
                        {login?.email}
                      </Index.Typography>
                    </Index.Box>
                    <img
                      src={
                        login?.image
                          ? `${process.env.REACT_APP_IMAGE_URL}/users/${login?.image}`
                          : Index.Svg.headprofile
                      }
                      className="headprofile"
                    />
                  </Index.Box>
                </Index.Button>
              </Index.Box>
              <Index.Menu
                className="drop-header-menu admin-header-profile-ul"
                id="basic-menu"
                anchorEl={anchorEl}
                open={open2}
                onClose={handleClose2}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <Index.RouteLink to="/admin/profile">
                  <Index.MenuItem
                    onClick={handleClose2}
                    className="drop-header-menuitem"
                  >
                    {" "}
                    <img
                      src={Index.Svg.profilegrey}
                      className="drop-header"
                    />{" "}
                    Profile
                  </Index.MenuItem>
                </Index.RouteLink>
                <Index.RouteLink to="/admin/changepassword">
                  <Index.MenuItem
                    onClick={handleClose2}
                    className="drop-header-menuitem"
                  >
                    {" "}
                    <img src={Index.Svg.settings} className="drop-header" />
                    Change Password
                  </Index.MenuItem>
                </Index.RouteLink>
                <Index.MenuItem
                  onClick={() => {
                    handleClose2();
                    // dispatch(adminLogout(navigate));
                    // toast.success("Logout successfully");
                    dispatch(adminLogout());
                    navigate("/");
                  }}
                  className="drop-header-menuitem logout-profile"
                >
                  {" "}
                  <img src={Index.Svg.edit} className="drop-header" /> Logout
                </Index.MenuItem>
              </Index.Menu>
            </Index.Box>
          </Index.Box>
        </header>
        <Index.Box className="admin-panel-content">
          <Index.Outlet />
        </Index.Box>

        <InvoiceRemiderPopUp
          handleClose={handleClosedInvoiceRemiderPopUp}
          setOpen={setOpenReminderInvoicePopUp}
          open={openReminderInvoicePopUp}
          currentRows={reminderInvoiceData}
        />

        <EnquiryPopupModel // Timer Popup show
          handleClose={handleTimerPopupClose}
          setOpen={setOpenPopup}
          open={openPopup}
          currentRows={inquiryListData}
        />
      </Index.Box>
    </div>
  );
};

export default DashboardLayout;
