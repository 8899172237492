import { createContext, useContext, useEffect, useState } from "react";

const DateTimePickerContext = createContext();

export const DatePickerProvider = ({ children }) => {
  const [openPickers, setOpenPickers] = useState(0);

  useEffect(() => {
    if (openPickers > 0) {
      document.body.classList.add("datepicker-redate");
    } else {
      document.body.classList.remove("datepicker-redate");
    }
  }, [openPickers]);

  const openPicker = () => setOpenPickers((prev) => prev + 1);
  const closePicker = () => setOpenPickers((prev) => prev - 1);

  return (
    <DateTimePickerContext.Provider value={{ openPicker, closePicker }}>
      {children}
    </DateTimePickerContext.Provider>
  );
};

export const useDateTimePicker = () => useContext(DateTimePickerContext);
