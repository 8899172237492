export default {
  ADMIN_LOGIN_SUCCESS: "ADMIN_LOGIN_SUCCESS",
  BARGE_LIST_SUCCESS: "BARGE_LIST_SUCCESS",
  GET_ORDER_LIST_SUCCESS: "GET_ORDER_LIST_SUCCESS",
  GET_CONFIRM_ORDER_LIST_SUCCESS: "GET_CONFIRM_ORDER_LIST_SUCCESS",
  PORT_LIST_SUCCESS: "PORT_LIST_SUCCESS",
  TUG_ORDER_PORT_LIST_SUCCESS: "TUG_ORDER_PORT_LIST_SUCCESS",
  PORT_LIST_TRADER_SUCCESS: "PORT_LIST_TRADER_SUCCESS",
  SALES_TRADER_LIST_SUCCESS: "SALES_TRADER_LIST_SUCCESS",
  ADMIN_LOGOUT: "ADMIN_LOGOUT",
  ADMIN_UPDATE_PROFILE: "ADMIN_UPDATE_PROFILE",
  GET_INVOICE_LIST_SUCCESS: "GET_INVOICE_LIST_SUCCESS",
  GET_CREDIT_NOT_INVOICE: "GET_CREDIT_NOT_INVOICE",
  GET_INVOICE_ACTIVITY_LOG: "GET_INVOICE_ACTIVITY_LOG",
  GET_PARTICULAR_LIST: "GET_PARTICULAR_LIST",
  GET_TOTAL_SALSE_DASHBOARD: "GET_TOTAL_SALSE_DASHBOARD",
  GET_TOTAL_PURCHASE_DASHBOARD: "GET_TOTAL_PURCHASE_DASHBOARD",
  GET_INVOICE_DETAILS_DASHBOARD: "GET_INVOICE_DETAILS_DASHBOARD",
  GET_CATEGORY_WISE_DASHBOARD: "GET_CATEGORY_WISE_DASHBOARD",
  GET_SALSE_PARTY_WISE: "GET_SALSE_PARTY_WISE",
  GET_PURCHASE_LIST_SUCCESS: "GET_PURCHASE_LIST_SUCCESS",
  GET_INQUIRY_lIST_SUCCESS: "GET_INQUIRY_lIST_SUCCESS",
  GET_BARGE_EXPENSE_LIST_SUCCESS: "GET_BARGE_EXPENSE_LIST_SUCCESS",
  GET_USER_LIST_SUCCESS: "GET_USER_LIST_SUCCESS",
  GET_LOST_ENQUIRY_SUCCESS: "GET_LOST_ENQUIRY_SUCCESS",
  GET_JOURNAL_LIST_SUCCESS: "GET_JOURNAL_LIST_SUCCESS",
  GET_HDFC_BANK_LIST_SUCCESS: "GET_HDFC_BANK_LIST_SUCCESS",
  GET_KOTAK_BANK_LIST_SUCCESS: "GET_KOTAK_BANK_LIST_SUCCESS",
  GET_PARTYLIST_SUCCESS: "GET_PARTYLIST_SUCCESS",
  GET_HDFC_TOTAL_DASHBOARD: "GET_HDFC_TOTAL_DASHBOARD",
  GET_KOTAK_TOTAL_DASHBOARD: "GET_KOTAK_TOTAL_DASHBOARD",
  GET_CHALLAN_LIST_SUCCESS: "GET_CHALLAN_LIST_SUCCESS",
  GET_EXPENSE_LIST_SUCCESS: "GET_EXPENSE_LIST_SUCCESS",
  GET_EXPENSE_REQUEST_LIST_SUCCESS: "GET_EXPENSE_REQUEST_LIST_SUCCESS",
  GET_NAME_OF_VESSEL_LIST_SUCCESS: "GET_NAME_OF_VESSEL_LIST_SUCCESS",
  GET_TRADER_MASTER_LIST_SUCCESS: "GET_TRADER_MASTER_LIST_SUCCESS",
  GET_ROLE_MASTER_SUCCESS: "GET_ROLE_MASTER_SUCCESS",
  GET_NOTIFICATION_LIST_SUCCESS: "GET_NOTIFICATION_LIST_SUCCESS",
  GET_COMPLETED_ORDER_LIST_SUCCESS: "GET_COMPLETED_ORDER_LIST_SUCCESS",
  GET_SALES_MASTER_LIST_SUCCESS: "GET_SALES_MASTER_LIST_SUCCESS",
  GET_TANKER_ACTIVITY_LOG: "GET_TANKER_ACTIVITY_LOG",
  GET_LATEST_ACTIVITY_TIME_SUCCESS: "GET_LATEST_ACTIVITY_TIME_SUCCESS",
  GET_LATEST_INVOICE_ACTIVITY_TIME_SUCCESS:
    "GET_LATEST_INVOICE_ACTIVITY_TIME_SUCCESS",
  GET_BUNKER_ORDER_DASHBOARD_SUCCESS: "GET_BUNKER_ORDER_DASHBOARD_SUCCESS",
  GET_LOCATION: "GET_LOCATION",
  GET_DIRECTORY_LIST_SUCCESS: "GET_DIRECTORY_LIST_SUCCESS",
  GET_TANKER_DASHBOARD_LIST: "GET_TANKER_DASHBOARD_LIST",
  GET_TRIP_DASHBOARD_LIST: "GET_TRIP_DASHBOARD_LIST",
  SET_FREE_BREAKDOWN_TANKERS: "SET_FREE_BREAKDOWN_TANKERS",
  GET_ORDER_TRADER_LIST_SUCCESS: "GET_ORDER_TRADER_LIST_SUCCESS",
  GET_TUG_NAME_OF_VESSEL_LIST_SUCCESS: "GET_TUG_NAME_OF_VESSEL_LIST_SUCCESS",
  GET_EXPENSE_TOTAL: "Get expense total",
  GET_TRADER_INQUIRY_lIST_SUCCESS: "GET_TRADER_INQUIRY_lIST_SUCCESS",
  GET_TRADER_LOST_INQUIRY_lIST_SUCCESS: "GET_TRADER_LOST_INQUIRY_lIST_SUCCESS",
  GET_TRADER_CONFIRM_INQUIRY_lIST_SUCCESS:
    "GET_TRADER_CONFIRM_INQUIRY_lIST_SUCCESS",
  GET_TRADER_INQUIRY_lIST_BY_ID_SUCCESS:
    "GET_TRADER_INQUIRY_lIST_BY_ID_SUCCESS",
  GET_DASHBOARD_BANK_AMOUNTS: "GET_DASHBOARD_BANK_AMOUNTS",
  ADMIN_ACTIVE_COMPANY_NAME: "ADMIN_ACTIVE_COMPANY_NAME",
};
