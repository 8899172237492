import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import InquiryStatusModal from "../../../../component/common/InquiryStatusModel";
import {
  deleteEnquiry,
  getInquiryList,
  getEnquiresList,
  updateMultipleInquiryStatus,
} from "../../../../redux/admin/action";
import { Export_excel, NEW_EXPORT_EXCEL } from "../ExportAll";
import CurrentEnquiryStatusModal from "./CurrentEnquiryStatusModal";

const Search = Index.styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: Index.alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: Index.alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const StyledInputBase = Index.styled(Index.InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

function Inquiry() {
  const navigate = Index.useNavigate();
  const dispatch = Index.useDispatch();
  // filter
  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  // const [exportDetails, setExportDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  // const [nominationFromDate, setNominationFromDate] = useState();
  // const [nominationToDate, setNominationToDate] = useState();
  // const [nominationFromError, setNominationFromError] = useState();
  // const [nominationToError, setNominationToError] = useState();
  // redux data;
  // const { inquiryList } = Index.useSelector((state) => state.AdminReducer);

  // state for checkbox
  const [enquiryCheckId, setEnquiryCheckId] = useState([]);
  const [enquiryCheckIdsData, setEnquiryCheckIdsData] = useState([]);
  // State for pagination
  const [currentPage, setCurrentPage] = useState(1);
  // const [count, setCount] = useState(0);
  const [paginationPerPage, setPaginationPerPage] = useState(50);

  // State for searching and set data
  // const [searchedData, setSearchedData] = useState([]);
  const [updateOpen, setUpdateOpen] = useState(false);
  // const [statusId, setStatusId] = useState("");
  const [checkTabvalue, setCheckTabvalue] = useState("current");

  const [rowId, setRowId] = useState("");
  const [deleteOpen, setDeleteOpen] = useState(false);

  const [searchingText, setSearchingText] = useState("");
  const [totalRecordCount, setTotalRecordCount] = useState(0);
  const [currentEnquiriesData, setCurrentEnquiriesData] = useState([]);

  const handleOpen = () => {
    setUpdateOpen(true);
  };

  const handleClose = () => {
    setUpdateOpen(false);
    // setStatusId("");
    setEnquiryCheckId([]);
    setEnquiryCheckIdsData([]);
  };

  // get enquiry list
  const getEnquiryList = (values) => {
    setLoading(true);
    dispatch(getEnquiresList(values)).then((res) => {
      if (res?.status === 200) {
        setTotalRecordCount(res?.totalCount);
        setCurrentPage(res?.currentPage);
        setCurrentEnquiriesData(res?.data);
        setLoading(false);
      } else {
        setTotalRecordCount(0);
        setCurrentPage(1);
        setCurrentEnquiriesData([]);
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    const values = {
      page: 1,
      search: "",
      size: paginationPerPage,
      // to:
      //   serachedDateRange?.length > 0
      //     ? serachedDateRange[0].format("YYYY-MM-DD")
      //     : "",
      // from:
      //   serachedDateRange?.length > 0
      //     ? serachedDateRange[1].format("YYYY-MM-DD")
      //     : "",
    };
    getEnquiryList(values);
  }, []);

  function isValidDateFormat(input) {
    // Regular expression for YYYY-MM-DD format
    const dateFormat = /^\d{4}-\d{2}-\d{2}$/;
    return dateFormat.test(input);
  }

  const inquiryMultipleStatusUpdate = (status, comment) => {
    const data = new URLSearchParams();
    enquiryCheckId?.forEach((items) => {
      data.append("id[]", items);
    });
    data.append("inquiryStatus", status);
    data.append("comment", comment);
    dispatch(updateMultipleInquiryStatus(data)).then((res) => {
      if (res?.status === 200) {
        setEnquiryCheckId([]);
        const values = {
          page: 1,
          search: "",
          size: paginationPerPage,
          // to:
          //   serachedDateRange?.length > 0
          //     ? serachedDateRange[0].format("YYYY-MM-DD")
          //     : "",
          // from:
          //   serachedDateRange?.length > 0
          //     ? serachedDateRange[1].format("YYYY-MM-DD")
          //     : "",
        };
        getEnquiryList(values);
      }
    });
    handleClose();
  };

  const handlePageChange = React.useCallback(
    (event, value) => {
      setCurrentPage(value);
      const values = {
        page: value,
        search: searchingText,
        size: paginationPerPage,
        // to:
        //   serachedDateRange?.length > 0
        //     ? serachedDateRange[0].format("YYYY-MM-DD")
        //     : "",
        // from:
        //   serachedDateRange?.length > 0
        //     ? serachedDateRange[1].format("YYYY-MM-DD")
        //     : "",
      };
      getEnquiryList(values);
    },
    [searchingText, paginationPerPage, setCurrentPage]
  );

  const handleEdit = (row) => {
    navigate("/admin/enquiry/add", {
      state: { row },
    });
  };

  const handleDeleteOpen = () => {
    setDeleteOpen(true);
  };

  const handleDeleteClose = () => {
    setDeleteOpen(false);
  };

  const handleDeleteRecord = () => {
    dispatch(deleteEnquiry({ id: rowId })).then((res) => {
      if (res?.status === 200) {
        getEnquiryList();
        setSearchingText("");
      }
    });
    handleDeleteClose();
  };

  // when the user change the per page record change then used..
  const handleChangePaginationPerChange = (e) => {
    const { value } = e.target;
    setPaginationPerPage(value);
    const values = {
      page: 1,
      search: searchingText,
      size: value,
      // to:
      //   serachedDateRange?.length > 0
      //     ? serachedDateRange[0].format("YYYY-MM-DD")
      //     : "",
      // from:
      //   serachedDateRange?.length > 0
      //     ? serachedDateRange[1].format("YYYY-MM-DD")
      //     : "",
    };
    getEnquiryList(values);
  };

  const handleSearchCurrentEqnuiriesData = React.useCallback(
    (e) => {
      const { value } = e.target;
      setSearchingText(value);
      if (value?.length > 0) {
        const values = {
          page: 1,
          search: value,
          size: paginationPerPage,
          // to:
          //   serachedDateRange?.length > 0
          //     ? serachedDateRange[0].format("YYYY-MM-DD")
          //     : "",
          // from:
          //   serachedDateRange?.length > 0
          //     ? serachedDateRange[1].format("YYYY-MM-DD")
          //     : "",
        };
        getEnquiryList(values);
      } else {
        const values = {
          page: 1,
          search: "",
          size: paginationPerPage,
          // to:
          //   serachedDateRange?.length > 0
          //     ? serachedDateRange[0].format("YYYY-MM-DD")
          //     : "",
          // from:
          //   serachedDateRange?.length > 0
          //     ? serachedDateRange[1].format("YYYY-MM-DD")
          //     : "",
        };
        getEnquiryList(values);
      }
    },
    [paginationPerPage, setSearchingText]
  );

  return (
    <>
      <Index.DynamicTitle title="Enquiry" />
      <Index.Box className="">
        <Index.Box className="barge-common-box">
          <Index.Box className="title-header">
            <Index.Box className="title-header-flex res-title-header-flex">
              <Index.Box className="title-main">
                <Index.Typography
                  variant="p"
                  component="p"
                  className="page-title"
                >
                  Current Enquiry
                </Index.Typography>
              </Index.Box>
              <Index.Box className="d-flex align-items-center res-set-search">
                <Search className="search ">
                  <StyledInputBase
                    placeholder="Search"
                    inputProps={{ "aria-label": "search" }}
                    onChange={(e) => handleSearchCurrentEqnuiriesData(e)}
                    value={searchingText}
                  />
                </Search>
                {currentEnquiriesData?.length ? (
                  <>
                    <Index.Box className="common-button grey-button">
                      <Index.Button
                        variant="contained"
                        disabled={currentEnquiriesData?.length ? false : true}
                        onClick={() => {
                          let curDate = Index.moment(new Date()).format(
                            "DD-MM-YYYY"
                          );
                          NEW_EXPORT_EXCEL(
                            "/admin/export-current-enquiries",
                            `Current_Enquiries_List_${curDate}`,
                            {
                              search: searchingText,
                              // to:
                              //   serachedDateRange?.length > 0
                              //     ? serachedDateRange[0].format("YYYY-MM-DD")
                              //     : "",
                              // from:
                              //   serachedDateRange?.length > 0
                              //     ? serachedDateRange[1].format("YYYY-MM-DD")
                              //     : "",
                            }
                          );
                        }}
                      >
                        Export
                      </Index.Button>
                    </Index.Box>
                  </>
                ) : (
                  ""
                )}

                <Index.Box className="common-button blue-button res-blue-button">
                  {currentEnquiriesData?.length && enquiryCheckId?.length ? (
                    <Index.Button
                      variant="contained"
                      disableRipple
                      onClick={(e) => {
                        handleOpen();
                      }}
                    >
                      Status Update
                    </Index.Button>
                  ) : (
                    ""
                  )}

                  <Index.RouteLink
                    to="/admin/enquiry/add"
                    className="no-text-decoration"
                  >
                    {" "}
                    <Index.Button variant="contained" disableRipple>
                      Add Enquiry
                    </Index.Button>
                  </Index.RouteLink>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Index.Box className="">
            <Index.TableContainer
              component={Index.Paper}
              className="table-container table-max-width"
            >
              <Index.Table
                stickyHeader
                aria-label="sticky table"
                // aria-label="simple table"
                className="table-design-main supply-table one-line-table"
              >
                <Index.TableHead>
                  <Index.TableRow>
                    <Index.TableCell></Index.TableCell>

                    <Index.TableCell>Enquiry No</Index.TableCell>
                    <Index.TableCell align="left">
                      Name of Vessel
                    </Index.TableCell>
                    <Index.TableCell align="left">Sales Person</Index.TableCell>
                    <Index.TableCell align="left">Trader Name</Index.TableCell>
                    <Index.TableCell align="left">Port</Index.TableCell>
                    <Index.TableCell align="left">Terminal</Index.TableCell>
                    <Index.TableCell align="left">C/F</Index.TableCell>
                    <Index.TableCell align="left">Quantity(MT)</Index.TableCell>
                    <Index.TableCell align="left">Product</Index.TableCell>
                    <Index.TableCell align="left">ETA</Index.TableCell>
                    <Index.TableCell align="left">Laycan </Index.TableCell>
                    <Index.TableCell align="left">Created Date</Index.TableCell>
                    <Index.TableCell align="left">Remark </Index.TableCell>
                    <Index.TableCell align="left">Action</Index.TableCell>
                  </Index.TableRow>
                </Index.TableHead>

                {loading ? (
                  <Index.TableBody>
                    <Index.TableRow>
                      <Index.TableCell colSpan={14} align="center">
                        <Index.CircularProgress color="secondary" size={20} />
                      </Index.TableCell>
                    </Index.TableRow>
                  </Index.TableBody>
                ) : (
                  <Index.TableBody className="tanker-table-body">
                    {currentEnquiriesData?.length ? (
                      currentEnquiriesData?.map((item, index) => (
                        <Index.TableRow
                          key={item?._id * (index + 1)}
                          className={
                            item?.bunkerCall === true ? "tanker-row-color" : ""
                          }
                        >
                          <Index.TableCell>
                            {item?.traderIds?.length <= 0 ? (
                              <>
                                <Index.FormControlLabel
                                  control={
                                    <Index.Checkbox
                                      onChange={(event) => {
                                        if (event.target.checked === true) {
                                          setEnquiryCheckId([
                                            ...enquiryCheckId,
                                            item?._id,
                                          ]);
                                          setEnquiryCheckIdsData([
                                            ...enquiryCheckIdsData,
                                            item,
                                          ]);
                                        } else {
                                          setEnquiryCheckId(
                                            enquiryCheckId?.filter((ele) => {
                                              return ele !== item?._id;
                                            })
                                          );
                                          setEnquiryCheckIdsData(
                                            enquiryCheckIdsData?.filter(
                                              (ele) => {
                                                return ele?._id !== item?._id;
                                              }
                                            )
                                          );
                                        }
                                      }}
                                    />
                                  }
                                />
                              </>
                            ) : (
                              <></>
                            )}
                          </Index.TableCell>

                          <Index.TableCell
                            onClick={() => {
                              handleEdit(item);
                            }}
                            sx={{ cursor: "pointer" }}
                          >
                            {item?.inquiryNo ? item?.inquiryNo : "-"}
                          </Index.TableCell>
                          <Index.TableCell
                            align="left"
                            onClick={() => {
                              handleEdit(item);
                            }}
                            sx={{ cursor: "pointer" }}
                          >
                            {item?.vesselName ? item?.vesselName : "-"}
                          </Index.TableCell>
                          <Index.TableCell
                            align="left"
                            onClick={() => {
                              handleEdit(item);
                            }}
                            sx={{ cursor: "pointer" }}
                          >
                            {item?.salesName ? item?.salesName : "-"}
                          </Index.TableCell>
                          <Index.TableCell
                            align="left"
                            onClick={() => {
                              handleEdit(item);
                            }}
                            sx={{ cursor: "pointer" }}
                          >
                            {item?.traderName ? item?.traderName : "-"}
                          </Index.TableCell>
                          <Index.TableCell
                            align="left"
                            onClick={() => {
                              handleEdit(item);
                            }}
                            sx={{ cursor: "pointer" }}
                          >
                            {item?.portName ? item?.portName : "-"}
                          </Index.TableCell>
                          <Index.TableCell
                            align="left"
                            onClick={() => {
                              handleEdit(item);
                            }}
                            sx={{ cursor: "pointer" }}
                          >
                            {item?.terminal ? item?.terminal : "-"}
                          </Index.TableCell>
                          <Index.TableCell
                            align="left"
                            onClick={() => {
                              handleEdit(item);
                            }}
                            sx={{ cursor: "pointer" }}
                          >
                            {item?.CF ? item?.CF : "-"}
                          </Index.TableCell>
                          <Index.TableCell
                            align="left"
                            onClick={() => {
                              handleEdit(item);
                            }}
                            sx={{ cursor: "pointer" }}
                          >
                            {item?.orderDetails?.length > 0
                              ? item?.orderDetails
                                  .map((items, index) => {
                                    if (
                                      items.lowerQuantity &&
                                      items.higherQuantity != 0
                                    ) {
                                      return `${items.lowerQuantity} - ${items.higherQuantity}`;
                                    } else if (items.lowerQuantity) {
                                      return items.lowerQuantity.toString();
                                    } else {
                                      return "-";
                                    }
                                  })
                                  .join(" ")
                              : "-"}
                          </Index.TableCell>
                          <Index.TableCell
                            align="left"
                            onClick={() => {
                              handleEdit(item);
                            }}
                            sx={{ cursor: "pointer" }}
                          >
                            {item?.orderDetails?.length
                              ? item?.orderDetails &&
                                item?.orderDetails?.map((items, index) => {
                                  if (items?.product) {
                                    return items?.product + " ";
                                  } else {
                                    return "-";
                                  }
                                })
                              : "-"}
                          </Index.TableCell>
                          <Index.TableCell
                            align="left"
                            onClick={() => {
                              handleEdit(item);
                            }}
                            sx={{ cursor: "pointer" }}
                          >
                            {item?.eta
                              ? Index.moment(item?.eta).format("DD/MM/YYYY")
                              : "-"}
                          </Index.TableCell>

                          <Index.TableCell
                            align="left"
                            onClick={() => {
                              handleEdit(item);
                            }}
                            sx={{ cursor: "pointer" }}
                          >
                            {item?.laycanStartDate || item?.laycanEndDate ? (
                              <>
                                {Index.moment(item?.laycanStartDate).format(
                                  "DD/MM/YYYY"
                                )}{" "}
                                -{" "}
                                {Index.moment(item?.laycanEndDate).format(
                                  "DD/MM/YYYY"
                                )}
                              </>
                            ) : (
                              "-"
                            )}
                          </Index.TableCell>

                          <Index.TableCell align="left">
                            {item?.createdAt
                              ? Index.moment(item?.createdAt).format(
                                  "hh:mm:ss DD/MM/YYYY"
                                )
                              : "-"}
                          </Index.TableCell>

                          <Index.TableCell
                            align="left"
                            onClick={() => {
                              handleEdit(item);
                            }}
                            sx={{ cursor: "pointer" }}
                          >
                            <Index.Tooltip title={item?.remark} arrow>
                              <Index.Button className="remark-text">
                                {item?.remark ? (
                                  <>
                                    {item.remark.length > 10 ? (
                                      <>{`${item.remark.substring(
                                        0,
                                        10
                                      )}...`}</>
                                    ) : (
                                      item.remark
                                    )}
                                  </>
                                ) : (
                                  "-"
                                )}
                              </Index.Button>
                            </Index.Tooltip>
                          </Index.TableCell>

                          <Index.TableCell>
                            <Index.IconButton>
                              <Index.DeleteIcon
                                color="error"
                                onClick={() => {
                                  setRowId(item?._id);
                                  handleDeleteOpen();
                                }}
                              />
                            </Index.IconButton>
                          </Index.TableCell>
                        </Index.TableRow>
                      ))
                    ) : (
                      <Index.TableRow>
                        <Index.TableCell
                          component="td"
                          variant="td"
                          scope="row"
                          className="no-data-in-list"
                          colSpan={15}
                          align="center"
                        >
                          No data available
                        </Index.TableCell>
                      </Index.TableRow>
                    )}
                  </Index.TableBody>
                )}
              </Index.Table>
            </Index.TableContainer>
          </Index.Box>

          <Index.Box className="pagination-design flex-end">
            <Index.Stack spacing={2}>
              <Index.Box className="pagination-count">
                <Index.Box className="input-design-div with-border">
                  <Index.Select
                    fullWidth
                    value={paginationPerPage}
                    onChange={(e) => handleChangePaginationPerChange(e)}
                  >
                    <Index.MenuItem value={10}>10</Index.MenuItem>
                    <Index.MenuItem value={25}>25</Index.MenuItem>
                    <Index.MenuItem value={50}>50</Index.MenuItem>
                    <Index.MenuItem value={100}>100</Index.MenuItem>
                  </Index.Select>
                </Index.Box>
                {loading ? (
                  <Index.CircularProgress
                    color="secondary"
                    sx={{ marginLeft: "10px" }}
                    size={26}
                  />
                ) : (
                  <Index.Pagination
                    count={Math.ceil(totalRecordCount / paginationPerPage)}
                    page={currentPage}
                    onChange={handlePageChange}
                  />
                )}
              </Index.Box>
            </Index.Stack>
          </Index.Box>
        </Index.Box>
      </Index.Box>

      <CurrentEnquiryStatusModal
        updateOpen={updateOpen}
        handleClose={handleClose}
        inquiryStatusUpdate={inquiryMultipleStatusUpdate}
        currentEnquiry={checkTabvalue}
        enquiriesData={enquiryCheckIdsData}
      />
      <Index.DeleteModal
        deleteOpen={deleteOpen}
        handleDeleteRecord={handleDeleteRecord}
        handleDeleteClose={handleDeleteClose}
        customMessage={"Do you want to delete this enquiry ?"}
      />
    </>
  );
}

export default Inquiry;
