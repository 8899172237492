import React from "react";
import Index from "../../../../Index";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";

//Marker icon
const myIcon = new L.Icon({
  iconUrl: Index.Png.location,
  iconRetinaUrl: Index.Png.location,
  popupAnchor: [-0, -0],
  iconSize: [20, 36],
});

const GoogleMap = ({ activeLocationData }) => {
  //Geo location center
  const center = [22.724521670075713, 75.84666352973338];

  return (
    <>
      <Index.Box className="google-map-main">
        <MapContainer
          center={center}
          zoom={8}
          scrollWheelZoom={true}
          maxZoom={18}
          className="geo-location-map"
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />

          {activeLocationData &&
            activeLocationData
              ?.filter((res) => res.is_deleted === 0)
              ?.map((ele, index) => {
                return (
                  <Marker
                    position={[ele?.lat, ele?.long]}
                    icon={myIcon}
                    key={ele?._id}
                  >
                    <Popup>
                      <Index.Box className="location-popup-main">
                        <Index.Typography
                          component="p"
                          className="location-popup-name"
                        >{`Name: ${ele?.userId?.employeeName}`}</Index.Typography>
                        <Index.Typography
                          component="p"
                          className="location-popup-time"
                        >{`Time: ${
                          ele?.updatedAt
                            ? Index.moment(ele?.updatedAt).format(
                                "DD/MM/YYYY hh:mm:ss A"
                              )
                            : "-"
                        }`}</Index.Typography>
                      </Index.Box>
                    </Popup>
                  </Marker>
                );
              })}
        </MapContainer>
      </Index.Box>
    </>
  );
};

export default GoogleMap;
