import React, { useEffect, useState } from "react";
import Index from "../../../Index";
import {
  getGSTRTwo,
  gstrRateStatusUpdate,
} from "../../../../redux/admin/action";
// import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";

const Search = Index.styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: Index.alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: Index.alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const StyledInputBase = Index.styled(Index.InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

const GstrTwo = () => {
  const dispatch = Index.useDispatch();
  const [loading, setLoading] = useState(false);
  const [gstrData, setGstrData] = useState();
  // State for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState(0);
  const [paginationPerPage, setPaginationPerPage] = useState(50);

  // State for searching and set data
  const [searchedData, setSearchedData] = useState([]);
  const [currentRows, setCurrentRows] = useState([]);

  // pagination logic
  const PER_PAGE = paginationPerPage;
  useEffect(() => {
    //Page count
    const count = Math.ceil(searchedData?.length / PER_PAGE);
    setCount(count);
    const indexOfLastRow = currentPage * PER_PAGE;
    const indexOfFirstRow = indexOfLastRow - PER_PAGE;
    const modifiedRows = searchedData?.slice(indexOfFirstRow, indexOfLastRow);
    setCurrentRows(modifiedRows);
    if (modifiedRows?.length === 0 && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  }, [currentPage, searchedData, paginationPerPage, PER_PAGE]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };
  // Pagination calculation end

  // Search on table
  const requestSearch = (searched) => {
    if (!searched) return setSearchedData(gstrData);
    setSearchedData(
      gstrData?.filter(
        (item) =>
          Index.moment(item?.date)
            .format("DD/MM/YYYY")
            ?.toString()
            ?.toLowerCase()
            ?.includes(searched?.toString().toLowerCase()) ||
          item?.voucher_number
            ?.toString()
            ?.toLowerCase()
            .includes(searched?.toLowerCase()) ||
          item?.party_name
            ?.toString()
            .toLowerCase()
            .includes(searched?.toLowerCase()) ||
          item?.invoice_amount
            ?.toString()
            .toLowerCase()
            .includes(searched?.toLowerCase()) ||
          item?.igst
            ?.toString()
            .toLowerCase()
            .includes(searched?.toLowerCase()) ||
          item?.cgst
            ?.toString()
            .toLowerCase()
            .includes(searched?.toLowerCase()) ||
          item?.sgst
            ?.toString()
            .toLowerCase()
            .includes(searched?.toLowerCase()) ||
          item?.taxable_value
            ?.toString()
            .toLowerCase()
            .includes(searched?.toLowerCase())
      )
    );
    setCurrentPage(1);
  };

  const handleRateStatusUpdate = (id, rate, status) => {
    // console.log(id, 'rate = ',rate, ' status ', status, 'uppppp');
    setLoading(true);
    let data = new URLSearchParams();
    data.append("guid", id);
    if (rate) {
      data.append("rate", rate);
    }
    if (status) {
      data.append("status", status);
    }
    dispatch(gstrRateStatusUpdate(data));
    setTimeout(() => {
      setLoading(false);
      getGSTR2List();
    }, 1000);
  };

  //  get gstr list
  const getGSTR2List = () => {
    setLoading(true);
    dispatch(getGSTRTwo()).then((res) => {
      if (res?.status === 200) {
        const filteredData = res?.data?.filter((obj) => {
          return Object.keys(obj).every((key) => {
            const value = obj[key];
            // Check the conditions for cgst, igst, and sgst
            return (
              key !== "cgst" ||
              (value !== "0.00" && value !== null && key !== "igst") ||
              (value !== "0.00" && value !== null && key !== "sgst") ||
              (value !== "0.00" && value !== null)
            );
          });
        });

        // console.log(filteredData, 'filteredData')
        setGstrData(filteredData);
        setSearchedData(filteredData);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      }else{
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      }
    });
  };

  useEffect(() => {
    getGSTR2List();
  }, []);

  return (
    <div>
      <Index.DynamicTitle title="GSTR" />
      <Index.Box className="">
        <Index.Box className="barge-common-box">
          <Index.Box className="title-header">
            <Index.Box className="title-header-flex  res-title-header-flex">
              <Index.Box className="title-main">
                <Index.Typography
                  variant="p"
                  component="p"
                  className="page-title"
                >
                  GSTR-2B
                </Index.Typography>
              </Index.Box>
              <Index.Box className="d-flex align-items-center res-set-search">
                <Search className="search">
                  <StyledInputBase
                    placeholder="Search"
                    inputProps={{ "aria-label": "search" }}
                    onChange={(e) =>
                      setTimeout(() => {
                        requestSearch(e.target.value.trim());
                      }, 1000)
                    }
                  />
                </Search>
              </Index.Box>
            </Index.Box>
          </Index.Box>

          <Index.Box className="">
            <Index.TableContainer
              component={Index.Paper}
              className="table-container"
            >
              <Index.Table
                aria-label="simple table"
                className="table-design-main invoice-table  "
              >
                <Index.TableHead>
                  <Index.TableRow>
                    <Index.TableCell className="invoice_table_header">
                      Sr No
                    </Index.TableCell>
                    <Index.TableCell className="invoice_table_header">
                      Trade/Legal name
                    </Index.TableCell>
                    <Index.TableCell
                      align="left"
                      className="invoice_table_header"
                    >
                      Voucher no
                    </Index.TableCell>
                    <Index.TableCell
                      align="left"
                      className="invoice_table_header"
                    >
                      Invoice date
                    </Index.TableCell>
                    <Index.TableCell
                      align="left"
                      className="invoice_table_header"
                    >
                      Invoice amount
                    </Index.TableCell>
                    <Index.TableCell
                      align="left"
                      className="invoice_table_header"
                    >
                      Taxable value
                    </Index.TableCell>
                    <Index.TableCell
                      align="left"
                      className="invoice_table_header"
                    >
                      IGST
                    </Index.TableCell>
                    <Index.TableCell
                      align="left"
                      className="invoice_table_header"
                    >
                      CGST
                    </Index.TableCell>
                    <Index.TableCell
                      align="left"
                      className="invoice_table_header"
                    >
                      SGST
                    </Index.TableCell>
                    <Index.TableCell
                      align="left"
                      className="invoice_table_header"
                    >
                      Rate(%)
                    </Index.TableCell>
                    <Index.TableCell
                      align="left"
                      className="invoice_table_header"
                    >
                      Status
                    </Index.TableCell>
                  </Index.TableRow>
                </Index.TableHead>
                {loading ? (
                  <Index.TableBody>
                    <Index.TableRow>
                      <Index.TableCell
                        colSpan={12}
                        align="center"
                        className="loading-purchase"
                      >
                        <Index.CircularProgress color="secondary" size={20} />
                      </Index.TableCell>
                    </Index.TableRow>
                  </Index.TableBody>
                ) : (
                  <Index.TableBody>
                    {currentRows?.length ? (
                      currentRows?.map((row, index) => (
                        <Index.TableRow key={row?._id}>
                          <Index.TableCell>{index + 1}</Index.TableCell>
                          <Index.TableCell>
                            {row?.party_name ? row?.party_name : "-"}
                          </Index.TableCell>
                          <Index.TableCell>
                            {row?.voucher_number ? row?.voucher_number : "-"}
                          </Index.TableCell>
                          <Index.TableCell>
                            {row?.date
                              ? Index.moment(row?.date).format("DD/MM/YYYY")
                              : "-"}
                          </Index.TableCell>
                          <Index.TableCell>
                            {row?.invoice_amount
                              ? row?.invoice_amount?.toLocaleString("en-IN", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : 0?.toLocaleString("en-IN", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                          </Index.TableCell>
                          <Index.TableCell>
                            {row?.taxable_value
                              ? row?.taxable_value?.toLocaleString("en-IN", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : 0?.toLocaleString("en-IN", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                          </Index.TableCell>
                          <Index.TableCell>
                            {row?.igst
                              ? row?.igst?.toLocaleString("en-IN", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : 0?.toLocaleString("en-IN", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                          </Index.TableCell>
                          <Index.TableCell>
                            {row?.cgst
                              ? row?.cgst?.toLocaleString("en-IN", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : 0?.toLocaleString("en-IN", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                          </Index.TableCell>
                          <Index.TableCell>
                            {row?.sgst
                              ? row?.sgst?.toLocaleString("en-IN", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : 0?.toLocaleString("en-IN", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                          </Index.TableCell>

                          <Index.TableCell>
                            <Index.Box>
                              <Index.Box className="input-design-div with-border">
                                <Index.Select
                                  className="placeholder-set-text-box"
                                  fullWidth
                                  name="rate"
                                  displayEmpty
                                  value={row?.rate ? row?.rate : ""}
                                  onChange={(e) => {
                                    handleRateStatusUpdate(
                                      row?.guid,
                                      e.target.value,
                                      ""
                                    );
                                  }}
                                >
                                  <Index.MenuItem value="">
                                    <Index.MenuItem
                                      disabled
                                      className="all-select-label"
                                    >
                                      Select rate
                                    </Index.MenuItem>
                                  </Index.MenuItem>
                                  <Index.MenuItem value="5%">5%</Index.MenuItem>
                                  <Index.MenuItem value="12%">
                                    12%
                                  </Index.MenuItem>
                                  <Index.MenuItem value="18%">
                                    18%
                                  </Index.MenuItem>
                                  <Index.MenuItem value="28%">
                                    28%
                                  </Index.MenuItem>
                                </Index.Select>
                              </Index.Box>
                            </Index.Box>
                          </Index.TableCell>

                          <Index.TableCell>
                            <Index.Box>
                              <Index.Box className="input-design-div with-border">
                                <Index.Select
                                  className="placeholder-set-text-box"
                                  fullWidth
                                  name="status"
                                  displayEmpty
                                  value={row?.status ? row?.status : ""}
                                  onChange={(e) => {
                                    handleRateStatusUpdate(
                                      row?.guid,
                                      "",
                                      e.target.value
                                    );
                                  }}
                                >
                                  <Index.MenuItem value="">
                                    <Index.MenuItem
                                      disabled
                                      className="all-select-label"
                                    >
                                      Select status
                                    </Index.MenuItem>
                                  </Index.MenuItem>
                                  <Index.MenuItem value="Matched">
                                    Matched
                                  </Index.MenuItem>
                                  <Index.MenuItem value="Not Booked">
                                    Not Booked
                                  </Index.MenuItem>
                                  <Index.MenuItem value="Unclaimed">
                                    Unclaimed
                                  </Index.MenuItem>
                                  <Index.MenuItem value="Others">
                                    Others
                                  </Index.MenuItem>
                                </Index.Select>
                              </Index.Box>
                            </Index.Box>
                          </Index.TableCell>
                        </Index.TableRow>
                      ))
                    ) : (
                      <Index.TableRow>
                        <Index.TableCell
                          component="td"
                          variant="td"
                          scope="row"
                          className="no-data-in-list empty-record-align"
                          colSpan={14}
                          align="center"
                        >
                          No data available
                        </Index.TableCell>
                      </Index.TableRow>
                    )}
                  </Index.TableBody>
                )}
              </Index.Table>
            </Index.TableContainer>
          </Index.Box>
          <Index.Box className="pagination-design flex-end">
            <Index.Stack spacing={2}>
              <Index.Box className="pagination-count">
                <Index.Box className="input-design-div with-border">
                  <Index.Select
                    fullWidth
                    value={paginationPerPage}
                    onChange={(e) => {
                      setPaginationPerPage(e.target.value);
                    }}
                  >
                    <Index.MenuItem value={10}>10</Index.MenuItem>
                    <Index.MenuItem value={25}>25</Index.MenuItem>
                    <Index.MenuItem value={50}>50</Index.MenuItem>
                    <Index.MenuItem value={100}>100</Index.MenuItem>
                  </Index.Select>
                </Index.Box>
                <Index.Pagination
                  count={count}
                  page={currentPage}
                  onChange={handlePageChange}
                />
              </Index.Box>
            </Index.Stack>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </div>
  );
};

export default GstrTwo;
