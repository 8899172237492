import React, { useState, useEffect } from "react";
import Index from "../../../Index";
import { Formik } from "formik";
import { addSalesMaster } from "../../../../redux/admin/action";
import { salesMasterValidationSchema } from "../../../../validation/AdminValidation";
import { terminalData } from "../../../../config/CommonTerminalItems";

function AddSalesMaster() {
  const dispatch = Index.useDispatch();
  const navigate = Index.useNavigate();
  const { state } = Index.useLocation();
  const [loading, setLoading] = useState(false);
  const [allTerminalData, setAllTerminalData] = useState([]);
  // edit data
  let editData = state?.row;
  if (editData) {
    editData.id = editData?._id;
  }
  let initialValues = {};
  if (editData) {
    initialValues = editData;
  } else {
    initialValues = {
      salesName: "",
      phoneNumber: "",
      salesEmail: "",
      terminal: ""
    };
  }

  useEffect(() => {
    setAllTerminalData(
      terminalData?.sort((a, b) => a?.item?.localeCompare(b?.item))
    );
  }, [terminalData]);

  const handleFormSubmit = async (values) => {
    setLoading(true);
    let data = new URLSearchParams();
    if (values?.salesName) {
      data.append("salesName", values.salesName);
    }
    if (values?.salesEmail) {
      data.append("salesEmail", values.salesEmail);
    }
    if (values?.phoneNumber) {
      data.append("phoneNumber", values.phoneNumber);
    }
    if (values?.terminal) {
      data.append("terminal", values.terminal);
    }
    if (editData) {
      data.append("id", editData?._id);
    }
    dispatch(addSalesMaster(data, navigate, setLoading));
  };
  const BackBtn = () => {
    navigate("/admin/salesMaster");
  };

  return (
    <>
      <Index.Box className="">
        <Index.Box className="barge-common-box">
          <Index.Box className="title-header">
            <Index.Box className="title-header-flex">
              <Index.Box className="title-main mb-10">
                <Index.Typography
                  variant="p"
                  component="p"
                  className="page-title"
                >
                  {editData ? "Edit" : "Add"} Sales
                </Index.Typography>
              </Index.Box>
              <Index.Box className="common-button blue-button save-btn ">
                <Index.Button
                  variant="contained"
                  type="submit"
                  className="Particular_model_submit ml-0"
                  onClick={BackBtn}
                >
                  Back
                </Index.Button>
              </Index.Box>
            </Index.Box>
          </Index.Box>
          <Formik
            // enableReinitialize
            initialValues={initialValues}
            validationSchema={salesMasterValidationSchema}
            onSubmit={handleFormSubmit}
          >
            {({
              values,
              errors,
              touched,
              setFieldValue,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <form onSubmit={handleSubmit}>
                <Index.Box className="">
                  <Index.Grid container spacing={2} className="grid-flex-start">
                    <Index.Grid item xs={12} sm={6} md={2}>
                      {/* <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Sales Name
                      </Index.Typography> */}
                      <Index.Box className="input-design-div with-border">
                        <Index.TextField
                          hiddenLabel
                          id="filled-hidden-label-normal"
                          placeholder="Sales name"
                          variant="filled"
                          className="admin-input-design input-placeholder"
                          name="salesName"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values?.salesName}
                          error={Boolean(
                            errors.salesName ? touched.salesName : undefined
                          )}
                          helperText={
                            touched.salesName ? errors.salesName : undefined
                          }
                        />
                      </Index.Box>
                    </Index.Grid>

                    <Index.Grid item xs={12} sm={6} md={2}>
                      {/* <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Email
                      </Index.Typography> */}
                      <Index.Box className="input-design-div with-border">
                        <Index.TextField
                          className="admin-input-design input-placeholder user-email-field"
                          hiddenLabel
                          id="filled-hidden-label-normal"
                          placeholder="Email"
                          variant="filled"
                          autoComplete="off"
                          name="salesEmail"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values?.salesEmail}
                          error={Boolean(
                            errors?.salesEmail ? touched?.salesEmail : undefined
                          )}
                          helperText={
                            touched?.salesEmail ? errors?.salesEmail : undefined
                          }
                        />
                      </Index.Box>
                    </Index.Grid>

                    <Index.Grid item xs={12} sm={6} md={2}>
                      {/* <Index.Typography
                        variant="label"
                        component="label"
                        className="input-label"
                      >
                        Phone Number
                      </Index.Typography> */}
                      <Index.Box className="input-design-div with-border">
                        <Index.TextField
                          fullWidth
                          id="fullWidth"
                          type="number"
                          placeholder="Phone number"
                          variant="filled"
                          className="form-control custom-control"
                          name="phoneNumber"
                          onBlur={handleBlur}
                          value={values?.phoneNumber}
                          onChange={(e) => {
                            if (e.target.value.toString().length <= 10) {
                              setFieldValue("phoneNumber", e.target.value);
                            }
                          }}
                          startAdornment={
                            <Index.InputAdornment position="start"></Index.InputAdornment>
                          }
                          error={Boolean(
                            errors.phoneNumber ? touched.phoneNumber : undefined
                          )}
                          helperText={
                            touched.phoneNumber ? errors.phoneNumber : undefined
                          }
                        />
                      </Index.Box>
                    </Index.Grid>

                    <Index.Grid item xs={12} sm={6} md={2}>
                          {/* <Index.Typography
                            variant="label"
                            component="label"
                            className="input-label"
                          >
                            Terminal
                          </Index.Typography> */}
                          <Index.Box className="input-design-div with-border">
                            {editData?._id && values?.terminal ? (
                              allTerminalData?.length && (
                                <Index.Autocomplete
                                  disablePortal
                                  className="invoice-select-drop"
                                  id="combo-box-demo"
                                  options={allTerminalData}
                                  getOptionLabel={(option) => option?.item}
                                  value={
                                    allTerminalData[
                                      allTerminalData.findIndex(
                                        (e) => e.item === values?.terminal
                                      )
                                    ]
                                  }
                                  renderOption={(props, option) => (
                                    <Index.Box
                                      component="li"
                                      sx={{
                                        "& > img": { mr: 2, flexShrink: 0 },
                                      }}
                                      {...props}
                                    >
                                      {option?.item}
                                    </Index.Box>
                                  )}
                                  onChange={(event, newValue) => {
                                    setFieldValue("terminal", newValue?.item);
                                  }}
                                  renderInput={(params) => (
                                    <Index.TextField
                                      {...params}
                                      placeholder="Terminal"
                                      variant="outlined"
                                    />
                                  )}
                                />
                              )
                            ) : (
                              <Index.Autocomplete
                                disablePortal
                                className="invoice-select-drop"
                                id="combo-box-demo"
                                options={allTerminalData}
                                getOptionLabel={(option) => option?.item}
                                renderOption={(props, option) => (
                                  <Index.Box
                                    component="li"
                                    sx={{
                                      "& > img": { mr: 2, flexShrink: 0 },
                                    }}
                                    {...props}
                                  >
                                    {option?.item}
                                  </Index.Box>
                                )}
                                onChange={(event, newValue) => {
                                  setFieldValue("terminal", newValue?.item);
                                }}
                                renderInput={(params) => (
                                  <Index.TextField
                                    {...params}
                                    placeholder="Terminal"
                                    variant="outlined"
                                  />
                                )}
                              />
                            )}
                            {touched?.terminal && errors?.terminal && (
                              <Index.FormHelperText error>
                                {errors?.terminal}
                              </Index.FormHelperText>
                            )}
                          </Index.Box>
                        </Index.Grid>
                        <Index.Grid item xs={12} sm={6} md={4} className="submit-btn-sec">
                        <Index.Box className="common-button blue-button save-btn flex-col">
                  <Index.Button
                    variant="contained"
                    type="submit"
                    disabled={loading}
                  >
                    {editData ? "Update" : "Submit"}
                  </Index.Button>
                </Index.Box>
                        </Index.Grid>
                  </Index.Grid>
                </Index.Box>
                
              </form>
            )}
          </Formik>
        </Index.Box>
      </Index.Box>
    </>
  );
}

export default AddSalesMaster;
